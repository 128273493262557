import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Input,
  IconButton,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  Alert,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import AddRole from "./AddRole";
import { useDispatch, useSelector } from "react-redux";
// Icons
import CircleIcon from "@mui/icons-material/Circle";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import PublishIcon from "@mui/icons-material/Publish";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { getRole } from "../../../../reduxToolkit/features/adminPanel/role";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import {
  createAdmin,
  getAdmins,
  uploadAdminPic,
} from "../../../../reduxToolkit/features/adminPanel/admin";
import { getNotification } from "../../../../reduxToolkit/features/adminPanel/notification";

export default function AddAdmin({
  addAdminDlg,
  setAddAdminDlg,
  translate5,
  translate6,
}) {
  // useStates
  const [loading, setLoading] = useState(false);
  // Handle Form
  const [formValid, setFormValid] = useState(false);
  const [formProcessed, setFormProcessed] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [creatingRole, SetCreatingRole] = useState(false);
  const [usernameExist, setUsernameExist] = useState("");
  const [emailExist, setEmailExist] = useState("");

  const [roleList, setRoleList] = useState([]);
  // Form fields
  const [adminPic, setAdminPic] = useState("");
  const [noAdminPic, setNoAdminPic] = useState(false);
  const [username, setUsername] = useState("");
  const [noUsername, setNoUsername] = useState(false);
  const [email, setEmail] = useState("");
  const [noEmail, setNoEmail] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [password, setPassword] = useState("");
  const [noPassword, setNoPassword] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [role, setRole] = useState(0);
  // Add Role Section
  const [addRoleSection, setAddRoleSection] = useState(false);

  // localData
  const localData = JSON.parse(localStorage.getItem("adminLoginInfo"));

  // Store
  const { roles } = useSelector((state) => state.role);
  const { pending, adminImgUploadResponse, createAdminResponse, error } =
    useSelector((state) => state.admin);

  // UseEffect
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getRole());
  }, []);
  useEffect(() => {
    if (roles.length > 0) {
      setRoleList(roles);
      setRole(roles[0]._id);
    }
  }, [roles]);

  useEffect(() => {
    addRoleSection ? SetCreatingRole(true) : SetCreatingRole(false);
  }, [addRoleSection]);

  // Form validation
  useEffect(() => {
    if (formProcessed) {
      adminPic === "" ? setNoAdminPic(true) : setNoAdminPic(false);
      username === "" ? setNoUsername(true) : setNoUsername(false);
      email === "" ? setNoEmail(true) : setNoEmail(false);
      email.includes("@") ? setInvalidEmail(false) : setInvalidEmail(true);
      password === "" ? setNoPassword(true) : setNoPassword(false);
      if (
        // noAdminPic === false &&
        noUsername === false &&
        noEmail === false &&
        invalidEmail === false &&
        noPassword === false
      ) {
        setFormValid(true);
        setDisableSubmit(false);
      } else {
        setFormValid(false);
        setDisableSubmit(true);
      }
    }
  }, [
    adminPic,
    email,
    formProcessed,
    invalidEmail,
    noAdminPic,
    noEmail,
    noPassword,
    noUsername,
    password,
    username,
  ]);
  useEffect(() => {
    if (password !== "") {
      setFormProcessed(true);
    }
  }, [password]);

  // check add admin response
  useEffect(() => {
    // if (createAdminResponse.addSuccess) {
    if (createAdminResponse.emailFailed) {
      setLoading(false);
      setEmailExist(true);
    } else if (createAdminResponse.usernameFailed) {
      setLoading(false);
      setUsernameExist(true);
    } else {
      setLoading(false);
      setAddAdminDlg(false);
      setUsername("");
      setEmail("");
      setPassword("");
      setAdminPic("");
      roles.length > 0 && setRole(roles[0]._id);
      setFormProcessed(false);
    }
    // }
  }, [createAdminResponse]);

  //   Functions
  const handleAddAdmin = () => {
    setFormProcessed(true);

    if (formValid) {
      setLoading(true);

      setTimeout(() => {
        let createdAdmin = {
          adminName: username,
          email,
          password,
          roleId: role,
          createdBy: localData._id,
        };
        if (adminPic !== "") {
          const data = new FormData();
          const ext = adminPic.name.split(".");
          const filename =
            Date.now() +
            "-" +
            username.replace(/\s+/g, "") +
            "-admin-pic." +
            ext.slice(-1);
          data.append("name", filename);
          data.append("adminPic", adminPic);
          createdAdmin.adminPic = filename;
          dispatch(uploadAdminPic(data));
          dispatch(createAdmin(createdAdmin));
        }
      }, 1700);
      setTimeout(() => {
        dispatch(getNotification());
        dispatch(getAdmins());
      }, 3000);
    }
  };

  const handleUserNameChange = (e) => {
    setUsername(e.target.value);
    setUsernameExist(false);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailExist(false);
    setInvalidEmail(false);
  };
  return (
    <Dialog
      open={addAdminDlg}
      fullWidth
      maxWidth="md"
      onClose={() => {
        setAddAdminDlg(false);
        setUsername("");
        setEmail("");
        setPassword("");
        setAdminPic("");
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {translate5("Add Admin")}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={0} flexDirection="column">
          <Grid item xs={12}>
            <Grid
              container
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <LazyLoadImage
                  effect="blur"
                  src={
                    adminPic !== ""
                      ? URL.createObjectURL(adminPic)
                      : "https://cdn2.iconfinder.com/data/icons/picons-basic-1/57/basic1-112_user_add_new-512.png"
                  }
                  alt="Halloween party"
                  style={{
                    objectFit: "cover",
                    width: "200px",
                  }}
                />
                <Typography variant="body2" align="center" color="secondary">
                  {translate5("Recommended")}: 🖼️(800x600)
                </Typography>
              </Grid>
              <Grid item position="relative">
                <label htmlFor="icon-button-file">
                  <Input
                    accept="image/*"
                    id="icon-button-file"
                    required
                    type="file"
                    sx={{ display: "none" }}
                    onChange={(e) => setAdminPic(e.target.files[0])}
                  />
                  <IconButton
                    color="primary"
                    aria-label="upload-picture"
                    component="span"
                  >
                    <PhotoCamera />
                  </IconButton>
                </label>
              </Grid>
              <Grid item>
                {adminPic && (
                  <Button
                    variant="contained"
                    size="small"
                    color="error"
                    onClick={() => setAdminPic("")}
                  >
                    {translate6("Remove Image")}
                  </Button>
                )}
              </Grid>
              {/* {noAdminPic && <Alert severity="error">Upload Image</Alert>} */}
            </Grid>
          </Grid>
          <Grid item lg={12}>
            <TextField
              margin="dense"
              fullWidth
              size="small"
              label={translate5("User Name")}
              variant="standard"
              type="text"
              required
              value={username}
              onChange={handleUserNameChange}
              error={usernameExist ? true : false}
              helperText={
                usernameExist &&
                translate6("Username") + " " + translate6("Already Exist")
              }
            />
            {/* {noUsername && <Alert severity="error">Enter username</Alert>} */}
          </Grid>
          <Grid item lg={12}>
            <TextField
              margin="normal"
              fullWidth
              size="small"
              label={translate5("Email")}
              variant="standard"
              type="text"
              required
              value={email}
              onChange={handleEmailChange}
              onBlur={() => {
                email.length > 0 && !email.includes("@")
                  ? setInvalidEmail(true)
                  : setInvalidEmail(false);
              }}
              error={emailExist ? true : false}
              helperText={
                email.length > 0 &&
                (emailExist ? (
                  <Typography variant="body2" color="error">
                    {translate6("Email") + " " + translate6("Already Exist")}
                  </Typography>
                ) : (
                  invalidEmail && (
                    <Typography variant="body2" color="error">
                      {translate6("Invalid") + " " + translate6("Email")}
                    </Typography>
                  )
                ))
              }
            />
            {/* {noEmail ? (
              <Alert severity="error">Enter email</Alert>
            ) : (
              invalidEmail && <Alert severity="error">Invalid email</Alert>
            )} */}
          </Grid>
          <Grid item lg={12}>
            <TextField
              margin="dense"
              fullWidth
              size="small"
              label={translate5("Password")}
              variant="standard"
              type={showPass ? "text" : "password"}
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      size="small"
                      onClick={() => setShowPass(!showPass)}
                      disabled={password.length > 0 ? false : true}
                    >
                      {showPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            {/* {noPassword && <Alert severity="error">Enter Password </Alert>} */}
          </Grid>

          <Grid item lg={12} sx={{ my: 1 }}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">
                {translate5("Role")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={role}
                label={translate5("Role")}
                required
                onChange={(e) => setRole(e.target.value)}
              >
                {roleList.map((x, i) => (
                  <MenuItem key={i} value={x._id}>
                    <IconButton>
                      <CircleIcon sx={{ color: x.color }} />
                    </IconButton>
                    {x.roleName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={12}>
            <Button
              variant="contained"
              size="small"
              color={addRoleSection ? "primary" : "warning"}
              fullWidth
              endIcon={
                addRoleSection ? (
                  <RemoveIcon sx={{ mb: 0 }} />
                ) : (
                  <AddIcon sx={{ mb: 0.5 }} />
                )
              }
              onClick={() => setAddRoleSection(!addRoleSection)}
            >
              {addRoleSection
                ? translate6("Minimize add role section")
                : translate5("Add Role")}
            </Button>
          </Grid>
          <Grid item>
            {addRoleSection && (
              <AddRole
                setAddRoleSection={setAddRoleSection}
                translate6={translate6}
              />
            )}
          </Grid>
        </Grid>
        {emailExist ? (
          <Alert severity="warning">
            {translate6("Email")} + " " + {translate6("Already Exist")}
          </Alert>
        ) : (
          usernameExist && (
            <Alert severity="warning">
              {translate6("Username")} + " " + {translate6("Already Exist")}
            </Alert>
          )
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setAddAdminDlg(false);
            setUsername("");
            setEmail("");
            setPassword("");
            setAdminPic("");
          }}
          autoFocus
          color="error"
        >
          {translate5("Close")}
        </Button>
        <LoadingButton
          onClick={handleAddAdmin}
          endIcon={<PublishIcon />}
          loading={loading}
          loadingPosition="end"
          disabled={disableSubmit || creatingRole}
          variant="contained"
          size="small"
        >
          {translate5("Create")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
