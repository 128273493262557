import {
  Button,
  Grid,
  IconButton,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PF } from "../../../../../config";
import {
  createNoti,
  uploadTempPic,
} from "../../../../../reduxToolkit/features/adminPanel/misc";

// Ck Editor
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import PhotoCamera from "@mui/icons-material/PhotoCamera";
import LoadingBox from "../../../../../components/misc/Loading";

export default function CompanyInfo({
  companyInfo,
  setCompanyInfo,
  //   companyLogo,
  //   setCompanyLogo,
}) {
  const [logoLoading, setLogoLoading] = useState(false);
  const [silLoading, setSilLoading] = useState(false);
  const [watermarkLoading, setWatermarkLoading] = useState(false);

  const handleCompanyDetails = (e, editor) => {
    let obj = { ...companyInfo };
    const data = editor.getData();
    obj.companyDetails = data;
    setCompanyInfo(obj);
  };
  // ! CK Editor Image Upload

  const dispatch = useDispatch();
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          let tempName = Math.floor(Math.random() * (10000 - 0) + 0).toString();
          loader.file
            .then((file) => {
              const data = new FormData();
              if (file) {
                tempName = tempName + "." + file.name.split(".").pop();
                data.append("name", tempName);
                data.append("tempImg", file);
                dispatch(uploadTempPic(data));
                dispatch(
                  createNoti({
                    message: "Pls click on the image Confirm Image Upload",
                    severity: "warning",
                  })
                );
              }
            })
            .then(() => {
              resolve({
                default: `${PF}temp/${tempName}`,
              });
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }
  // ! CK Editor Image Upload

  //! Translate
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate
  return (
    <>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          size="small"
          sx={{ pointerEvents: "none", mb: 1 }}
          fullWidth
          color="secondary"
        >
          {translate0("Company Logo")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            {logoLoading ? (
              <LoadingBox w={"100%"} />
            ) : (
              <LazyLoadImage
                effect="blur"
                src={
                  companyInfo.companyLogo
                    ? companyInfo.companyLogo
                    : PF +
                      "/misc/1666271485951-MedicineForWorld(MFW)-site-logo.png"
                }
                alt={companyInfo.companyName}
                style={{
                  objectFit: "cover",
                  width: "200px",
                }}
              />
            )}

            <Typography variant="body2" align="center" color="secondary">
              {translate0("Recommended")}: 🖼️(800x600)
            </Typography>
          </Grid>
          <Grid item position="relative">
            <label htmlFor="company-logo-upload-btn">
              <Input
                accept="image/*"
                id="company-logo-upload-btn"
                type="file"
                sx={{ display: "none" }}
                onChange={(e) => {
                  setLogoLoading(true);
                  let obj = { ...companyInfo };
                  obj.companyLogo = URL.createObjectURL(e.target.files[0]);
                  setCompanyInfo(obj);
                  setTimeout(() => setLogoLoading(false), 1000);
                }}
              />
              <IconButton
                color="primary"
                aria-label="company-logo-upload"
                component="span"
              >
                <PhotoCamera />
              </IconButton>
            </label>
          </Grid>
          <Grid item>
            {companyInfo.companyLogo && (
              <Button
                variant="contained"
                size="small"
                color="error"
                onClick={() => {
                  let obj = { ...companyInfo };
                  obj.companyLogo = undefined;
                  setCompanyInfo(obj);
                }}
              >
                {translate0("Remove Image")}
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          size="small"
          sx={{ pointerEvents: "none", mb: 1 }}
          fullWidth
          color="secondary"
        >
          {translate0("Company Info")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label={translate0("Company Name")}
          value={companyInfo.companyName}
          onChange={(e) => {
            let obj = { ...companyInfo };
            obj.companyName = e.target.value;
            setCompanyInfo(obj);
          }}
          size="small"
          fullWidth
          variant="standard"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label={translate0("Company Short Form")}
          value={companyInfo.companyShortForm}
          onChange={(e) => {
            let obj = { ...companyInfo };
            obj.companyShortForm = e.target.value;
            setCompanyInfo(obj);
          }}
          size="small"
          fullWidth
          variant="standard"
        />
      </Grid>

      <Grid item xs={12}>
        <CKEditor
          editor={Editor}
          data={companyInfo.companyDetails}
          config={{
            extraPlugins: [uploadPlugin],
          }}
          onBlur={(e, editor) => handleCompanyDetails(e, editor)}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          size="small"
          sx={{ pointerEvents: "none", mb: 1 }}
          fullWidth
          color="secondary"
        >
          {translate0("Company Sil")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            {silLoading ? (
              <LoadingBox w={"100%"} />
            ) : (
              <LazyLoadImage
                effect="blur"
                src={
                  companyInfo.companySil
                    ? companyInfo.companySil
                    : PF + "/misc/mfw-sil.png"
                }
                alt={companyInfo.companyName}
                style={{
                  objectFit: "cover",
                  width: "200px",
                }}
              />
            )}

            <Typography variant="body2" align="center" color="secondary">
              {translate0("Recommended")}: 🖼️(800x600)
            </Typography>
          </Grid>
          <Grid item position="relative">
            <label htmlFor="company-sil-upload-btn">
              <Input
                accept="image/*"
                id="company-sil-upload-btn"
                type="file"
                sx={{ display: "none" }}
                onChange={(e) => {
                  setSilLoading(true);
                  let obj = { ...companyInfo };
                  obj.companySil = URL.createObjectURL(e.target.files[0]);
                  setCompanyInfo(obj);
                  setTimeout(() => setSilLoading(false), 1000);
                }}
              />
              <IconButton
                color="primary"
                aria-label="company-sil-upload"
                component="span"
              >
                <PhotoCamera />
              </IconButton>
            </label>
          </Grid>
          <Grid item>
            {companyInfo.companySil && (
              <Button
                variant="contained"
                size="small"
                color="error"
                onClick={() => {
                  let obj = { ...companyInfo };
                  obj.companySil = undefined;
                  setCompanyInfo(obj);
                }}
              >
                {translate0("Remove Image")}
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          size="small"
          sx={{ pointerEvents: "none", mb: 1 }}
          fullWidth
          color="secondary"
        >
          {translate0("Company Watermark")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            {watermarkLoading ? (
              <LoadingBox w={"100%"} />
            ) : (
              <LazyLoadImage
                effect="blur"
                src={
                  companyInfo.companyWatermark
                    ? companyInfo.companyWatermark
                    : PF + "/misc/mfw-watermark.png"
                }
                alt={companyInfo.companyName}
                style={{
                  objectFit: "cover",
                  width: "200px",
                }}
              />
            )}

            <Typography variant="body2" align="center" color="secondary">
              {translate0("Recommended")}: 🖼️(800x600)
            </Typography>
          </Grid>
          <Grid item position="relative">
            <label htmlFor="company-watermark-upload-btn">
              <Input
                accept="image/*"
                id="company-watermark-upload-btn"
                type="file"
                sx={{ display: "none" }}
                onChange={(e) => {
                  setWatermarkLoading(true);
                  let obj = { ...companyInfo };
                  obj.companyWatermark = URL.createObjectURL(e.target.files[0]);
                  setCompanyInfo(obj);
                  setTimeout(() => setWatermarkLoading(false), 1000);
                }}
              />
              <IconButton
                color="primary"
                aria-label="company-watermark-upload"
                component="span"
              >
                <PhotoCamera />
              </IconButton>
            </label>
          </Grid>
          <Grid item>
            {companyInfo.companyWatermark && (
              <Button
                variant="contained"
                size="small"
                color="error"
                onClick={() => {
                  let obj = { ...companyInfo };
                  obj.companyWatermark = undefined;
                  setCompanyInfo(obj);
                }}
              >
                {translate0("Remove Image")}
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
