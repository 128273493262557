import AddShoppingCart from "@mui/icons-material/AddShoppingCart";
import Favorite from "@mui/icons-material/Favorite";
import {
  Badge,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Slide,
  Tooltip,
  Typography,
  Zoom,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Divider,
  TextField,
} from "@mui/material";
import React, { createRef, useEffect, useRef, useState } from "react";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { PF } from "../../config";
import { makeUrlFriendly } from "../../hooks/makeUrlFriendly";

// Icons
import VisibilityIcon from "@mui/icons-material/Visibility";

// Media Query
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  getDosages,
  getMedicines,
} from "../../reduxToolkit/features/adminPanel/medicine";

export default function ProductSmallBackend({
  x,
  i,
  view,
  catList,
  lang,
  wishListW,
  setWishOpen,
}) {
  const dispatch = useDispatch();
  // Responsive
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  // use State
  const [companyList, setCompanyList] = useState([]);
  const [dosageList, setDosageList] = useState([]);

  // Store
  const { medicines, medVariants, companies, dosages, viewCountArr } =
    useSelector((state) => state.medicine);
  // view Count
  const [viewCount, setViewCount] = useState(0);

  useEffect(() => {
    if (companies && companies.length > 0) {
      let C = companies.find((c) => c.lang === lang);
      if (C) setCompanyList(C.data);
    }
  }, [companies, lang]);

  // Functions
  const getDefaultImage = () => {
    const I = x.image.find((i) => i.default);
    if (I) return I;
    else return { src: "" };
  };

  const getCat = (subCatId) => {
    let cat = "";
    let subCat = "";
    catList.map((x) => {
      let Sc = x.subCatList.find(
        (sc) =>
          (lang && lang !== "en" ? sc.subCatDetectorId : sc.fixedSubCatId) ===
          subCatId
      );
      if (Sc) {
        subCat = Sc.subCatName;
        cat = x.catName;
      }
      return 0;
    });
    return { cat, subCat };
  };

  const getVariant = (variantId) => {
    let variant = {};
    if (medVariants.length > 0) {
      variant = medVariants.find((mv) => mv.fixedVariantId === variantId);
    }
    return variant;
  };

  const getCompany = (companyId) => {
    if (companyList.length > 0) {
      let cName = "";
      let cImage = "";
      let C = companyList.find(
        (c) =>
          (lang && lang === "en" ? c.fixedCompanyId : c.companyDetectorId) ===
          companyId
      );
      if (C) {
        cName = C.companyName;
        cImage = C.companyLogo;
      }
      return { cName, cImage };
    }
  };

  const getDosageInfo = (dosageId) => {
    let dosageName = "";
    const D = dosageList.find(
      (d) => (lang !== "en" ? d.dosageDetectorId : d.fixedDosageId) === dosageId
    );
    if (D && D.dosageName) dosageName = D.dosageName;

    return dosageName;
  };

  const getGenericBrand = (id) => {
    let genericBrand = "";
    const M = medicines.find((m) => m.lang === "en");
    if (M) {
      const med = M.data.find((x) => x.fixedMedicineId === id);
      if (med) genericBrand = med.genericBrand;
    }
    return genericBrand;
  };

  useEffect(() => {
    if (!(medicines && medicines.length > 0)) dispatch(getMedicines());
    if (!(dosages && dosages.length > 0)) dispatch(getDosages());
  }, []);

  // set Dosage List
  useEffect(() => {
    if (lang !== "" && dosages && dosages.length > 0) {
      const D = dosages.find((d) => d.lang === lang);
      if (D && D.data) setDosageList(D.data);
      else setDosageList([]);
    }
  }, [dosages, lang]);

  useEffect(() => {
    if (viewCountArr && viewCountArr.length > 0) {
      const C = viewCountArr.find(
        (c) =>
          c.medicineIdEn ===
          (lang !== "en" ? x.medicineDetectorId : x.fixedMedicineId)
      );
      if (C) {
        setViewCount(C.viewCount);
      }
    }
  }, [viewCountArr]);

  return (
    <>
      {x && (
        <Box>
          <Card
            sx={{
              mx: 1,
              maxWidth: wishListW ? wishListW : view === 1 ? "100%" : "100%",
              // width: "390px",
              // border: "1px solid black",
            }}
          >
            <Link
              to={`/our-medicines/${makeUrlFriendly(
                getGenericBrand(
                  lang !== "en" ? x.medicineDetectorId : x.fixedMedicineId
                )
              )}`}
              target="_blank"
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  minHeight: 140,
                }}
              >
                <LazyLoadImage
                  src={
                    getDefaultImage() && getDefaultImage().src !== ""
                      ? PF + "adminPanel/medicines/img/" + getDefaultImage().src
                      : PF + "adminPanel/medicines/no-medicine-image.jpg"
                  }
                  alt={getDefaultImage().alt}
                  effect="blur"
                  placeholderSrc="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png"
                  style={{
                    objectFit: "stretch",
                    height: view === 0 ? 140 : null,
                    width: view === 0 ? null : "100%",
                  }}
                />
              </Box>
            </Link>

            <CardContent>
              <Grid
                container
                spacing={0}
                flexDirection="column"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  minHeight: view === 0 && 300,
                }}
              >
                <Grid item>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    align="center"
                  >
                    {x.genericBrand.toUpperCase()}
                  </Typography>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    spacing={1}
                    justifyContent="center"
                    sx={{ my: 0.5 }}
                  >
                    {x.variants.map(
                      (y, j) =>
                        getDosageInfo(getVariant(y.variantId).dosageForm) && (
                          <Grid item key={j}>
                            <Button
                              variant="outlined"
                              size="small"
                              color="info"
                              sx={{
                                pointerEvents: "none",
                              }}
                            >
                              <Typography
                                variant="body2"
                                sx={{ fontSize: ".8rem" }}
                              >
                                {getDosageInfo(
                                  getVariant(y.variantId).dosageForm
                                )}{" "}
                                {getVariant(y.variantId).strength}{" "}
                                {getVariant(y.variantId).packTypes}
                              </Typography>
                            </Button>
                          </Grid>
                        )
                    )}
                  </Grid>
                </Grid>
                <Grid item>
                  {x.subCats.map(
                    (y, j) =>
                      getCat(y.subCatId).subCat && (
                        <Button
                          variant="text"
                          color="secondary"
                          size="small"
                          fullWidth
                          sx={{ pointerEvents: "none" }}
                          key={j}
                        >
                          <Typography
                            variant="body2"
                            color="initial"
                            sx={{ fontSize: ".7rem" }}
                          >
                            {getCat(y.subCatId).subCat +
                              " ( " +
                              getCat(y.subCatId).cat +
                              " )"}
                          </Typography>
                        </Button>
                      )
                  )}
                </Grid>
                <Grid item>
                  {x.originator && (
                    <Button
                      variant="text"
                      size="small"
                      color="secondary"
                      fullWidth
                      sx={{ my: 1, pointerEvents: "none" }}
                    >
                      {" "}
                      {x.originator}
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  {getCompany(x.companyId) && getCompany(x.companyId).cName && (
                    <Button
                      fullWidth
                      variant="text"
                      size="small"
                      color="error"
                      sx={{ pointerEvents: "none" }}
                      endIcon={
                        <img
                          src={
                            getCompany(x.companyId) &&
                            getCompany(x.companyId).cImage
                              ? PF +
                                "/adminPanel/companies/" +
                                getCompany(x.companyId).cImage
                              : "https://www.ncenet.com/wp-content/uploads/2020/04/No-image-found.jpg"
                          }
                          alt={
                            getCompany(x.companyId).cName &&
                            getCompany(x.companyId).cImage &&
                            getCompany(x.companyId).cName
                          }
                          style={{
                            height: "25px",
                            objectFit: "cover",
                            borderRadius: ".25rem",
                          }}
                        />
                      }
                    >
                      {getCompany(x.companyId).cName}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Grid container spacing={0} justifyContent="flex-end">
                <Grid item>
                  <Tooltip
                    arrow
                    placement="top"
                    title={`Viewed ${viewCount} time${
                      viewCount > 1 ? "'s" : ""
                    }`}
                  >
                    <Button
                      variant="outlined"
                      size="small"
                      startIcon={<VisibilityIcon color="primary" />}
                      sx={{ cursor: "default" }}
                    >
                      {viewCount}
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        </Box>
      )}
    </>
  );
}
