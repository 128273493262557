import { ExpandMore } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfoTut from "../../../components/InfoTut";
import { getNotification } from "../../../reduxToolkit/features/adminPanel/notification";

// Icons
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";

import LangNTitleBar from "./LangNTitleBar";
import {
  getManagedContentSettings,
  saveManagedContentSettings,
} from "../../../reduxToolkit/features/adminPanel/managedContentSettings";

export default function FooterText({ translate0 }) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [lang, setLang] = useState("");
  const [langId, setLangId] = useState("");

  const [footerText, setFooterText] = useState("");
  const [tagDetailsCursor, setTagDetailsCursor] = useState(0);

  // LocalData
  // const localLangData = JSON.parse(localStorage.getItem("lang"));
  const localData = JSON.parse(localStorage.getItem("adminLoginInfo"));

  //   Store
  const { managedContentSettings } = useSelector(
    (state) => state.managedContentSettings
  );

  const getCursorPosition = (e) => {
    setTagDetailsCursor(e.target.selectionStart);
  };

  const handleInsertCompanyName = () => {
    setFooterText(
      footerText.substring(0, tagDetailsCursor) +
        "{_company_name}" +
        footerText.substring(tagDetailsCursor, footerText.length)
    );
  };
  const handleInsertYear = () => {
    setFooterText(
      footerText.substring(0, tagDetailsCursor) +
        "{_year}" +
        footerText.substring(tagDetailsCursor, footerText.length)
    );
  };

  const handleSaveSiteSEO = () => {
    setLoading(true);
    dispatch(
      saveManagedContentSettings({
        langId,
        changedSection: "Footer Text",
        footerText,
        creatorId: localData._id,
      })
    );
    setTimeout(() => {
      setLoading(false);
      dispatch(getNotification());
      dispatch(getManagedContentSettings());
    }, 1000);
  };

  useEffect(() => {
    if (!(managedContentSettings && managedContentSettings.length > 0))
      dispatch(getManagedContentSettings());
  }, []);

  // Set Settings
  useEffect(() => {
    if (managedContentSettings && managedContentSettings.length > 0) {
      const M = managedContentSettings.find((m) => m.langId === langId);
      if (M && M.footerText) {
        setFooterText(M.footerText);
      } else {
        setFooterText("");
      }
    }
  }, [langId, managedContentSettings]);

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-label="Expand"
          aria-controls="Head-Title-content"
          id="Head-Title-header"
        >
          <Typography>{translate0("Footer Text")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <LangNTitleBar
            lang={lang}
            setLang={setLang}
            langId={langId}
            setLangId={setLangId}
            title={translate0("Footer Text")}
          />

          <Grid item>
            <Grid container spacing={1} justifyContent="center">
              <Grid item>
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleInsertCompanyName}
                  color="info"
                >
                  Company Name
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleInsertYear}
                  color="info"
                >
                  Year
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <TextField
            label={translate0("Footer Text")}
            value={footerText}
            onChange={(e) => setFooterText(e.target.value)}
            size="small"
            fullWidth
            variant="standard"
            onBlur={getCursorPosition}
          />

          <LoadingButton
            variant="contained"
            loading={loading}
            fullWidth
            size="small"
            color="success"
            onClick={handleSaveSiteSEO}
            sx={{ mt: 1 }}
          >
            {translate0("Save")}
          </LoadingButton>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
