export const formatMedicinePackSize = (string) => {
  //   if (string && string.length) {
  //     let returnString = string;
  //     if (string.includes("s Strip"))
  //       returnString = string.replace("s Strip", "'s Strip");
  //     else if (string.includes("s strip"))
  //       returnString = string.replace("s strip", "'s Strip");
  //     else if (string.includes("s Pot"))
  //       returnString = string.replace("s Pot", "'s Pot");
  //     else if (string.includes("s pot"))
  //       returnString = string.replace("s pot", "'s Pot");
  //     else if (string.includes("s Vial"))
  //       returnString = string.replace("s Vial", "'s Vial");
  //     else if (string.includes("s vial"))
  //       returnString = string.replace("s vial", "'s Vial");
  //     else if (string.includes("s Ampoules"))
  //       returnString = string.replace("s Ampoules", "'s Ampoules");
  //     else if (string.includes("s ampoules"))
  //       returnString = string.replace("s ampoules", "'s Ampoules");
  //     else if (string.includes("s Tube"))
  //       returnString = string.replace("s Tube", "'s Tube");
  //     else if (string.includes("s tube"))
  //       returnString = string.replace("s tube", "'s Tube");
  //     else if (string.includes("s Box"))
  //       returnString = string.replace("s Box", "'s Box");
  //     else if (string.includes("s box"))
  //       returnString = string.replace("s box", "'s Box");
  //     else if (string.includes("s Sachets"))
  //       returnString = string.replace("s Sachets", "'s Sachets");
  //     else if (string.includes("s sachets"))
  //       returnString = string.replace("s sachets", "'s Sachets");
  //     return returnString;
  //   }
  return string;
};
