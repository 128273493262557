import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Button,
  Box,
  Avatar,
  Tooltip,
  Divider,
  Input,
  IconButton,
  Badge,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  Autocomplete,
  createFilterOptions,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addPrescriptionImage,
  getAllPrescription,
  removePrescription,
  uploadPrescription,
} from "../../../reduxToolkit/features/frontend/checkout";

// Icons
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { AddCircle } from "@mui/icons-material";

// Lazy Load Image
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { PF } from "../../../config";
import {
  getDosages,
  getMedicines,
} from "../../../reduxToolkit/features/adminPanel/medicine";
import { getAllCustomers } from "../../../reduxToolkit/features/auth";
import { getDuplicates } from "../../../hooks/getDuplicates";
import LoadingBox from "../../../components/misc/Loading";
import ViewProductDetails from "../../adminPanel/medicines/components/ViewProductDetails";

// Media Query
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Limit options to show in autocomplete
const OPTIONS_LIMIT = 5;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) => {
  return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
};

export default function ManagePrescriptions() {
  const dispatch = useDispatch();

  // Media Query
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  // useState
  const [lang, setLang] = useState("");
  const [loadingMain, setLoadingMain] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchFromNoti, setSearchFromNoti] = useState([]);
  const [prescriptionList, setPrescriptionList] = useState([]);
  const [searchingPrescriptionList, setSearchingPrescriptionList] = useState(
    []
  );
  const [activeCustomer, setActiveCustomer] = useState([]);
  const [presImgLoadingBuffer, setPresImgLoadingBuffer] = useState("");
  const [addPresImgLoading, setAddPresImgLoading] = useState(false);
  const [addPresBuffer, setAddPresBuffer] = useState({});

  // dialogs
  const [viewMedDlg, setViewMedDlg] = useState(false);
  const [viewMedBuffer, setViewMedBuffer] = useState({});

  // LocalData
  const localLangData = () => {
    const l = JSON.parse(localStorage.getItem("lang"));
    return l ? l : {};
  };

  //   Store
  const { activeLang } = useSelector((state) => state.misc);
  const { medicines, medVariants, dosages } = useSelector(
    (state) => state.medicine
  );
  const { customers } = useSelector((state) => state.auth);
  const { allPrescriptions } = useSelector((state) => state.checkout);

  // Functions
  const getMedInfo = (medId) => {
    let obj = {
      medImg: "",
      genericBrand: "",
    };
    if (medicines && medicines.length > 0) {
      const M = medicines.find(
        (m) => m.lang === (localLangData().langCode || "en")
      );
      if (M && M.data) {
        const Med = M.data.find(
          (med) =>
            (localLangData().langCode !== "en"
              ? med.medicineDetectorId
              : med.fixedMedicineId) === medId
        );
        if (Med) {
          obj.genericBrand = Med.genericBrand;
          if (Med.fixedMedicineId) obj.fixedMedicineId = Med.fixedMedicineId;
          if (Med.medicineDetectorId)
            obj.medicineDetectorId = Med.medicineDetectorId;
          const medImg = Med.image.find((i) => i.default);
          if (medImg) obj.medImg = medImg;
        }
      }
    }
    return obj;
  };

  const getEngMedInfo = (medId) => {
    let genericBrandEn = "";
    if (medicines && medicines.length > 0) {
      const M = medicines.find((m) => m.lang === "en");
      if (M && M.data) {
        const Med = M.data.find((med) => med.fixedMedicineId === medId);
        if (Med) genericBrandEn = Med.genericBrand;
      }
    }
    return genericBrandEn;
  };

  const getDosageInfo = (dosageId, langCode) => {
    let dosageName = "";
    if (dosages && dosages.length > 0) {
      const dosageList = dosages.find((d) => d.lang === langCode);
      if (dosageList && dosageList.data) {
        const D = dosageList.data.find((d) =>
          langCode !== "en" ? d.dosageDetectorId : d.fixedDosageId === dosageId
        );
        if (D && D.dosageName) dosageName = D.dosageName;
      }
    }
    return dosageName;
  };

  const getVariantInfo = (varId) => {
    let obj = {
      dosage: "",
      strength: "",
      packTypes: "",
    };
    if (medVariants && medVariants.length > 0) {
      const V = medVariants.find(
        (v) =>
          (localLangData().langCode !== "en"
            ? v.variantDetectorId
            : v.fixedVariantId) === varId
      );
      if (V) {
        obj.dosage = getDosageInfo(V.dosageForm, localLangData().langCode);
        obj.strength = V.strength;
        obj.packTypes = V.packTypes;
      }
    }
    return obj;
  };

  const getCustomerInfo = (customerId) => {
    let obj = {};
    if (customers && customers.length > 0) {
      const C = customers.find((c) => c._id === customerId);
      if (C) obj = { ...C };
    }
    return obj;
  };

  const getActiveIndex = (arr, variantId) => {
    let index = 0;
    arr.forEach((c, i) => {
      const C = activeCustomer.find(
        (a) => a.customerId === c && a.variantId === variantId
      );
      if (C) index = i;
    });
    return index || 0;
  };

  const handleChangePrescription = (e, x, i, y, j) => {
    setPresImgLoadingBuffer(y.src);
    if (y.src) {
      const data = new FormData();
      const ext = e.target.files[0].name.split(".");
      const filename =
        Date.now() +
        "-" +
        x.medicineId.replace(/\s+/g, "") +
        "-prescription." +
        ext.slice(-1);
      data.append("name", filename);
      data.append("prescription", e.target.files[0]);
      dispatch(uploadPrescription(data));

      dispatch(
        removePrescription({
          medicineId: x.medicineId,
          customerId: x.customerIds[i],
          variantId: x.variantId,
          prevImg: y.src,
          newImg: filename,
        })
      );
      setTimeout(() => {
        setPresImgLoadingBuffer("");
        dispatch(getAllPrescription());
      }, 1000);
    }
  };

  const handleRemovePrescriptionImg = (x, i, y, j) => {
    setPresImgLoadingBuffer(y.src);
    if (y.src) {
      dispatch(
        removePrescription({
          medicineId: x.medicineId,
          customerId: x.customerIds[i],
          variantId: x.variantId,
          prevImg: y.src,
        })
      );
      setTimeout(() => {
        setPresImgLoadingBuffer("");
        dispatch(getAllPrescription());
      }, 1000);
    }
  };

  const handleAddPrescription = (e, x, i) => {
    x = addPresBuffer;
    setAddPresImgLoading(true);
    // if (prescribeArr[i].length > 0) {
    const data = new FormData();
    const ext = e.target.files[0].name.split(".");
    const filename =
      Date.now() +
      "-" +
      x.medicineId.replace(/\s+/g, "") +
      "-prescription." +
      ext.slice(-1);
    data.append("name", filename);
    data.append("prescription", e.target.files[0]);
    dispatch(uploadPrescription(data));
    dispatch(
      addPrescriptionImage({
        medicineId: x.medicineId,
        customerId: x.customerIds[i],
        variantId: x.variantId,
        newImg: filename,
      })
    );
    setTimeout(() => {
      dispatch(getAllPrescription());
      setAddPresImgLoading(false);
      setAddPresBuffer({});
    }, 1000);
  };

  const getRealCustomerIdsLength = (imageArr) => {
    let result = 0;
    if (imageArr.length > 0) {
      imageArr.forEach((x) => {
        if (x.length > 0) result++;
      });
    }
    return result;
  };

  const handleSearch = (e, newValue) => {
    setSearchingPrescriptionList(newValue);
  };

  const handleSearchQuery = (value) => {
    setSearchLoading(true);
    if (prescriptionList && prescriptionList.length > 0) {
      const P = prescriptionList.find((p) => p.variantId === value);
      if (P) {
        setSearchFromNoti([P]);
        setSearchingPrescriptionList([P]);
      }
    }
    setTimeout(() => {
      setSearchLoading(false);
    }, 1000);
  };

  // Use Effect
  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setLang(activeLang.langCode);
    } else localLangData().langCode && setLang(localLangData().langCode);
  }, [activeLang]);

  useEffect(() => {
    if (!(medicines && medicines.length > 0)) dispatch(getMedicines());
    if (!(dosages && dosages.length > 0)) dispatch(getDosages());
    dispatch(getAllPrescription());
    dispatch(getAllCustomers());
  }, []);

  useEffect(() => {
    if (allPrescriptions.length > 0) {
      let arr = [];
      allPrescriptions.forEach((x) => {
        const A = arr.find((a) => a.variantId === x.variantId);
        if (A) {
          const index = arr.findIndex((item) => item.variantId === x.variantId);
          arr[index].imageArr.push([...x.imageArr]);
          arr[index].customerIds.push(x.customerId);
        } else {
          let obj = {
            imageArr: [],
            medicineId: x.medicineId,
            variantId: x.variantId,
            customerIds: [],
          };
          obj.imageArr.push([...x.imageArr]);
          obj.customerIds.push(x.customerId);
          arr.push(obj);
        }
      });
      if (activeCustomer.length < 1) {
        let activeCustomerArr = [];
        arr.forEach((x, index) => {
          activeCustomerArr.push({
            customerId: x.customerIds[0],
            variantId: x.variantId,
            index,
          });
        });
        setActiveCustomer(activeCustomerArr);
      }
      setPrescriptionList(arr);
    }
  }, [allPrescriptions]);

  useEffect(() => {
    // Check Query Params
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
    let value = params.search; // "some_value"
    if (value) handleSearchQuery(value);
    else setSearchingPrescriptionList([]);
  }, [allPrescriptions]);

  useEffect(() => {
    setLoadingMain(true);
    setTimeout(() => {
      setLoadingMain(false);
    }, 1000);
  }, []);

  // ! translate
  // Store
  const { langs } = useSelector((state) => state.misc);
  const [langSettings1, setLangSettings1] = useState([]);
  const [langSettings2, setLangSettings2] = useState([]);
  const title1 = "Manage Prescription";
  const title2 = "Misc Words";

  useEffect(() => {
    if (langs && langs.length > 0 && lang !== "") {
      const L = langs.find((l) => l.langCode === lang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      const A = arr.find((l) => l._title === title1);
      if (A) setLangSettings1(A.data);
      const B = arr.find((l) => l._title === title2);
      if (B) setLangSettings2(B.data);
    }
  }, [lang, langs]);

  const translate1 = (String) => translation(String, title1, 1);
  const translate2 = (String) => translation(String, title2, 2);

  const translation = (String, title, index) => {
    let translation = "";
    let settings = [];
    if (index === 1) {
      settings = langSettings1;
    } else if (index === 2) {
      settings = langSettings2;
    }
    const T = settings.find((ls) => ls.title === String);
    if (T && T.value) translation = T.value;
    else {
      let arr = [];
      if (langs && langs.length > 0 && lang !== "") {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.settings && LEn.settings.length > 0) arr = LEn.settings;
        const S = arr.find((l) => l._title === title);
        if (S) {
          const T = S.data.find((ls) => ls.title === String);
          if (T && T.value) translation = T.value;
        }
      }
    }
    return translation;
  };
  // ! translate

  return (
    <>
      {searchLoading ? (
        <></>
      ) : (
        <Autocomplete
          multiple
          onChange={(e, newValue) => handleSearch(e, newValue)}
          disablePortal
          defaultValue={searchFromNoti}
          options={prescriptionList}
          filterOptions={filterOptions}
          getOptionLabel={(option) =>
            (lang !== "en"
              ? " (" + getEngMedInfo(option.medicineId) + ")"
              : getMedInfo(option.medicineId).genericBrand) +
            " (" +
            getVariantInfo(option.variantId).dosage +
            " - " +
            getVariantInfo(option.variantId).strength +
            ") "
          }
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <LazyLoadImage
                src={
                  getMedInfo(option.medicineId).medImg.src !== ""
                    ? PF +
                      "adminPanel/medicines/img/" +
                      getMedInfo(option.medicineId).medImg.src
                    : PF + "adminPanel/medicines/no-medicine-image.jpg"
                }
                alt={getMedInfo(option.medicineId).medImg.alt}
                effect="blur"
                placeholderSrc="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png"
                style={{
                  objectFit: "cover",
                  height: 30,
                }}
              />
              {getMedInfo(option.medicineId).genericBrand}
              {lang !== "en" && " (" + getEngMedInfo(option.medicineId) + ")"}
              <br />
              {" ("}
              {getVariantInfo(option.variantId).dosage}
              {" - "}
              {getVariantInfo(option.variantId).strength}
              {") "}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={translate1("Search") + "..."}
              variant="standard"
              size="small"
              fullWidth
            />
          )}
          sx={{ my: 1 }}
        />
      )}
      <Grid container spacing={1}>
        {loadingMain ? (
          <LoadingBox w={"100%"} />
        ) : (
          (searchingPrescriptionList.length > 0
            ? searchingPrescriptionList
            : prescriptionList
          ).map(
            (x, i) =>
              getRealCustomerIdsLength(x.imageArr) > 0 && (
                <Grid item key={i} xs={12} sm={6} md={4} xl={2}>
                  <Card sx={{ maxWidth: 345 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setViewMedDlg(true);
                        setViewMedBuffer({
                          medId: x.medicineId,
                          varId: x.variantId,
                        });
                      }}
                    >
                      <LazyLoadImage
                        src={
                          getMedInfo(x.medicineId).medImg.src !== ""
                            ? PF +
                              "adminPanel/medicines/img/" +
                              getMedInfo(x.medicineId).medImg.src
                            : PF + "adminPanel/medicines/no-medicine-image.jpg"
                        }
                        alt={getMedInfo(x.medicineId).medImg.alt}
                        effect="blur"
                        placeholderSrc="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png"
                        style={{
                          objectFit: "cover",
                          height: 140,
                        }}
                      />
                    </Box>
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        {getMedInfo(x.medicineId).genericBrand.toUpperCase()}
                      </Typography>
                      <Typography gutterBottom variant="body2" component="div">
                        {" ("}
                        {getVariantInfo(x.variantId).dosage}
                        {" - "}
                        {getVariantInfo(x.variantId).strength}
                        {" - "}
                        {getVariantInfo(x.variantId).packTypes}
                        {") "}
                      </Typography>
                      <Divider sx={{ my: 1 }} />
                      <Grid container spacing={1}>
                        {x.customerIds.length > 0 &&
                          x.customerIds.map(
                            (y, j) =>
                              x.imageArr[
                                getActiveIndex(x.customerIds, x.variantId)
                              ].length > 0 && (
                                <Grid item key={j} xs={12} sm={6}>
                                  <Badge
                                    badgeContent={
                                      x.imageArr[
                                        getActiveIndex(
                                          x.customerIds,
                                          x.variantId
                                        )
                                      ]
                                        ? x.imageArr[j].length
                                        : 0
                                    }
                                    color="error"
                                    sx={{ width: "100%" }}
                                  >
                                    <Button
                                      fullWidth
                                      variant={
                                        activeCustomer.find(
                                          (a) =>
                                            a.customerId === y &&
                                            a.variantId === x.variantId
                                        )
                                          ? "contained"
                                          : "outlined"
                                      }
                                      color="info"
                                      size="small"
                                      onClick={() => {
                                        let arr = [...activeCustomer];
                                        arr[i] = {
                                          customerId: y,
                                          variantId: x.variantId,
                                        };
                                        setActiveCustomer(arr);
                                      }}
                                      sx={{ fontSize: ".7rem" }}
                                    >
                                      {getCustomerInfo(y).name &&
                                        getCustomerInfo(
                                          y
                                        ).name.toUpperCase()}{" "}
                                    </Button>
                                  </Badge>
                                </Grid>
                              )
                          )}
                      </Grid>
                      <Divider sx={{ my: 1 }} />

                      <Box
                        sx={{
                          maxHeight: { xs: "60vh", sm: "30vh" },
                          overflowY: "auto",
                          overflowX: "hidden",
                        }}
                      >
                        {addPresImgLoading ? (
                          <LoadingBox w={"100%"} h={"30vh"} />
                        ) : (
                          x.imageArr[
                            getActiveIndex(x.customerIds, x.variantId)
                          ].map((y, j) => (
                            <Grid
                              container
                              spacing={0}
                              alignItems="center"
                              key={j}
                            >
                              <Grid item xs={8}>
                                {presImgLoadingBuffer.includes(y.src) ? (
                                  <LoadingBox w={"100%"} />
                                ) : (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      //   justifyContent: "center",
                                      alignItems: "center",
                                      m: 1,
                                    }}
                                  >
                                    <LazyLoadImage
                                      src={
                                        y.src && y.src !== ""
                                          ? PF +
                                            "adminPanel/prescription/" +
                                            y.src
                                          : URL.createObjectURL(y)
                                      }
                                      alt={`prescription-image-${i}-[${j}]`}
                                      effect="blur"
                                      placeholderSrc="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png"
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "contain",
                                      }}
                                    />
                                  </Box>
                                )}
                              </Grid>
                              <Grid item xs={4}>
                                <Grid
                                  container
                                  spacing={0}
                                  flexDirection="column"
                                >
                                  <Grid item>
                                    <label htmlFor={`change-prescription-${j}`}>
                                      <Input
                                        accept="image/*"
                                        id={`change-prescription-${j}`}
                                        inputProps={{ multiple: false }}
                                        type="file"
                                        sx={{ display: "none" }}
                                        onChange={(e) =>
                                          handleChangePrescription(
                                            e,
                                            x,
                                            getActiveIndex(
                                              x.customerIds,
                                              x.variantId
                                            ),
                                            y,
                                            j
                                          )
                                        }
                                      />
                                      <Tooltip
                                        arrow
                                        placement="left"
                                        title={translate1("Change")}
                                      >
                                        <IconButton
                                          size="medium"
                                          aria-label={`change-prescription-${i}`}
                                          component="span"
                                        >
                                          <ChangeCircleIcon color="warning" />
                                        </IconButton>
                                      </Tooltip>
                                    </label>
                                  </Grid>
                                  <Grid item>
                                    <Tooltip
                                      arrow
                                      placement="left"
                                      title={translate1("Remove")}
                                    >
                                      <IconButton
                                        size="medium"
                                        onClick={() =>
                                          handleRemovePrescriptionImg(
                                            x,
                                            getActiveIndex(
                                              x.customerIds,
                                              x.variantId
                                            ),
                                            y,
                                            j
                                          )
                                        }
                                      >
                                        <DeleteIcon color="error" />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          ))
                        )}
                        <Box sx={{ mb: 1 }}>
                          <label htmlFor={`add-new-prescription`}>
                            <Input
                              accept="image/*"
                              id={`add-new-prescription`}
                              inputProps={{ multiple: false }}
                              type="file"
                              sx={{ display: "none" }}
                              onChange={(e) =>
                                handleAddPrescription(
                                  e,
                                  x,
                                  getActiveIndex(x.customerIds, x.variantId)
                                )
                              }
                            />
                            <Button
                              variant="contained"
                              size="small"
                              color="success"
                              fullWidth
                              sx={{
                                width: "90%",
                              }}
                              startIcon={<AddCircle />}
                              aria-label={`add-new-prescription`}
                              component="span"
                              onClick={() => setAddPresBuffer(x)}
                            >
                              {translate1("Add")}
                            </Button>
                          </label>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              )
          )
        )}
      </Grid>
      {viewMedDlg && (
        <Dialog
          open={viewMedDlg}
          onClose={() => {
            setViewMedDlg(false);
            setViewMedBuffer({});
          }}
          TransitionComponent={Transition}
          keepMounted
          fullWidth
          maxWidth={"md"}
        >
          <DialogContent>
            <ViewProductDetails
              medId={viewMedBuffer.medId}
              varId={viewMedBuffer.varId}
              lang={lang}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
