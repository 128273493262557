import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import {
  Autocomplete,
  Box,
  Button,
  createFilterOptions,
  Divider,
  IconButton,
  TextField,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  Switch,
} from "@mui/material";

// Icon
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import SettingsIcon from "@mui/icons-material/Settings";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

// Media Query
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  changeLang,
  deleteLang,
  getLangs,
  manageLangActive,
} from "../../../reduxToolkit/features/adminPanel/misc";
import { getNotification } from "../../../reduxToolkit/features/adminPanel/notification";
import AddLangDialog from "../medicines/dialog/AddLangDialog";
import LoadingButton from "@mui/lab/LoadingButton";
import LangEditDlg from "./dilogs/LangEditDlg";

// Limit options to show in autocomplete
const OPTIONS_LIMIT = 3;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) => {
  return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
};

export default function LanguageSettings({ translate3, translate4 }) {
  // Media Query
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  // Use State
  const [lang, setLang] = useState("");
  const [langId, setLangId] = useState("");
  const [langList, setLangList] = useState([]);

  const [addLangDlg, setAddLangDlg] = useState(false);
  const [loadingDlt, setLoadingDlt] = useState(false);
  const [dltLangDlg, setDltLangDlg] = useState(false);
  const [dltBuffer, setDltBuffer] = useState({});

  const [manageLangDlg, setManageLangDlg] = useState(false);
  const [manageLangBuffer, setManageLangBuffer] = useState({});

  const [loadingLangActive, setLoadingLangActive] = useState(false);

  // local Data
  const localData = JSON.parse(localStorage.getItem("adminLoginInfo"));
  const localLangData = JSON.parse(localStorage.getItem("lang"));

  // store
  const { langs, activeLang } = useSelector((state) => state.misc);

  // Use Effects
  useEffect(() => {
    if (langs.length > 0) {
      setLangList([...langs]);
    }
  }, [langs]);

  // Functions
  const dispatch = useDispatch();
  const handleLangDelete = (x) => {
    setDltLangDlg(true);
    setDltBuffer(x);
  };

  const handleDltDlgClose = () => {
    setDltLangDlg(false);
    setDltBuffer({});
  };

  const handleDlt = () => {
    setLoadingDlt(true);
    if (langs.length > 0 && localLangData.langCode === dltBuffer.langCode) {
      const L = langs.find((l) => l.langCode === "en");
      if (L) {
        localStorage.setItem("lang", JSON.stringify(L));
        dispatch(changeLang(L));
      }
    }
    let l = { ...dltBuffer };
    l["creatorId"] = localData._id;
    dispatch(deleteLang(l));
    setTimeout(() => {
      setLoadingDlt(false);
      handleDltDlgClose();
      dispatch(getNotification());
      dispatch(getLangs());
    }, 1000);
  };

  const handleChangeLangActive = (obj) => {
    setLoadingLangActive(true);
    let object = { ...obj };
    if (localData._id) object.creatorId = localData._id;
    object.active = !object.active;
    object.langId = object._id;
    dispatch(manageLangActive(object));
    setTimeout(() => {
      setLoadingLangActive(false);
      dispatch(getNotification());
      dispatch(getLangs());
    }, 1000);
  };

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setLang(activeLang.langCode);
      setLangId(activeLang._id);
    } else {
      if (localLangData) {
        setLang(localLangData.langCode);
        setLangId(localLangData._id);
      }
    }
  }, [activeLang]);

  //! Translate
  // Store
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={3} sm={1} md={0.5}>
          <Tooltip title={translate3("Add Language")} placement="top" arrow>
            <IconButton sx={{ mt: 2 }}>
              <AddCircleOutlineOutlinedIcon
                color="info"
                size="large"
                onClick={() => setAddLangDlg(true)}
              />
              <AddLangDialog
                addLangDlg={addLangDlg}
                setAddLangDlg={setAddLangDlg}
                setLang={setLang}
              />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={9} sm={11} md={11.5}>
          <Autocomplete
            disablePortal
            options={langList}
            filterOptions={filterOptions}
            getOptionLabel={(option) => option.langName}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
                onClick={() => {}}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${
                    option.langCode === "en" ? "gb" : option.langCode
                  }.png`}
                  alt="admins"
                />
                {option.langName.toUpperCase()}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={translate3("Search") + "..."}
                variant="standard"
                size="small"
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
      <Button
        variant="contained"
        size="small"
        color="info"
        fullWidth
        sx={{ my: 1, pointerEvents: "none" }}
      >
        {translate3("Languages")} ( {langList.length} )
      </Button>

      <Box
        sx={{
          maxHeight: { xs: "40vh", sm: "20vh" },
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        {langList.length > 0 &&
          langList.map((x, i) => (
            <Grid
              key={i}
              container
              spacing={0}
              justifyContent="center"
              alignItems="center"
              sx={{ width: { xs: "90%" } }}
            >
              <Grid item xs={3} sm={1.5} md={1} xl={0.5} order={!smUp && 2}>
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <Tooltip
                      title={translate3("Manage")}
                      arrow
                      placement="left"
                    >
                      <IconButton
                        size="small"
                        onClick={() => {
                          setManageLangDlg(true);
                          setManageLangBuffer(x);
                        }}
                      >
                        <SettingsIcon color="info" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={6}>
                    <Tooltip
                      title={translate3("Delete")}
                      arrow
                      placement="right"
                    >
                      <IconButton
                        size="small"
                        onClick={() => handleLangDelete(x)}
                      >
                        <DeleteRoundedIcon color="error" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={9} sm={10.5} md={11} xl={11.5} order={!smUp && 1}>
                <Grid container spacing={0}>
                  <Grid item>
                    <Button
                      variant="text"
                      fullWidth
                      startIcon={
                        <img
                          src={`https://flagcdn.com/w20/${
                            x.langCode === "en" ? "gb" : x.langCode
                          }.png`}
                          alt={x.langName}
                        />
                      }
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        fontSize: { xs: ".7rem", sm: "1rem" },
                        pointerEvents: "none",
                      }}
                    >
                      {x.langName} ({x.langCode.toUpperCase()})
                    </Button>
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={x.active}
                          onChange={() => handleChangeLangActive(x)}
                          inputProps={{ "aria-label": "controlled" }}
                          disabled={loadingLangActive || x.langCode === "en"}
                        />
                      }
                      label={translate0("Active")}
                    />
                  </Grid>
                </Grid>
                <Divider />
              </Grid>
            </Grid>
          ))}
        {/* Delete Language Dialog */}
        {dltLangDlg && (
          <Dialog open={dltLangDlg}>
            <DialogTitle>
              {dltBuffer.langCode === "en"
                ? "You are prohibited from deleting 'English' Language"
                : `Do you really want to Delete '${dltBuffer.langName}' language ?`}
            </DialogTitle>

            <DialogActions>
              <Button onClick={handleDltDlgClose} color="primary">
                Close
              </Button>
              {dltBuffer.langCode !== "en" && (
                <LoadingButton
                  loading={loadingDlt}
                  onClick={handleDlt}
                  color="error"
                >
                  Delete
                </LoadingButton>
              )}
            </DialogActions>
          </Dialog>
        )}

        {/* Manage Language Dialog */}
        {manageLangDlg && (
          <LangEditDlg
            open={manageLangDlg}
            setOpen={setManageLangDlg}
            buffer={manageLangBuffer}
            langId={langId}
            lang={lang}
          />
        )}
      </Box>
    </>
  );
}
