import React, { Fragment, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Tooltip,
  Dialog,
  DialogContent,
  Slide,
  AppBar,
  Toolbar,
} from "@mui/material";
import { PF } from "../config";
// Icons
import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import EditIcon from "@mui/icons-material/Edit";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";

// Media Query
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useDispatch, useSelector } from "react-redux";
import { copyToClipBoardFront } from "../hooks/copyToClipBoard";
import EditCustomerProfile from "./EditCustomerProfile";
import { openOrderHistoryDialog } from "../reduxToolkit/features/miscFront";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ViewCustomerProfile({
  viewProfileDlg,
  setViewProfileDlg,
  localData,
  editProfileDlg,
  setEditProfileDlg,
}) {
  // Media Query
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  // useState
  const [ADDITIONAL_SOCIAL_LINK_ARR, SET_ADDITIONAL_SOCIAL_LINK_ARR] = useState(
    []
  );

  // useEffect
  useEffect(() => {
    if (localData.socialArr) {
      let arr = localData.socialArr.filter(
        (o) =>
          o.socialName !== "whatsApp" &&
          o.socialName !== "weChat" &&
          o.socialName !== "skype" &&
          o.socialName !== "facebook"
      );
      SET_ADDITIONAL_SOCIAL_LINK_ARR(arr);
    }
  }, [localData]);

  // Functions
  const dispatch = useDispatch();
  const getSocialLink = (platform) => {
    let link = "";
    const sl = localData.socialArr.find((o) => o.socialName === platform);
    if (sl) link = sl.socialLink;
    return link;
  };

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate

  return (
    <>
      {localData && (
        <Dialog
          open={viewProfileDlg}
          onClose={() => setViewProfileDlg(false)}
          aria-labelledby={"view-profile"}
          TransitionComponent={Transition}
          fullScreen={!smUp ? true : false}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setViewProfileDlg(false)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {translateFront("Profile")}
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <Grid
              container
              spacing={0}
              justifyContent="center"
              alignItems="center"
            >
              <Card
                sx={{
                  maxWidth: 345,
                  background:
                    localData.gender === "male" ? "lightgreen" : "#f7c6eb",
                }}
              >
                <CardMedia
                  component="img"
                  height="400"
                  image={
                    localData.customerPic
                      ? localData.customerPic
                      : localData.gender
                      ? localData.gender === "male"
                        ? PF + "frontEnd/default/man-default-customer-image.jpg"
                        : localData.gender === "female" &&
                          PF +
                            "frontEnd/default/women-default-customer-image.jpg"
                      : "https://picsum.photos/200/300"
                  }
                  alt="green iguana"
                />
                <CardActions>
                  <Button
                    variant="contained"
                    size="small"
                    fullWidth
                    startIcon={<WorkHistoryIcon />}
                    onClick={() => dispatch(openOrderHistoryDialog(true))}
                  >
                    {translateFront("Order History")}
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    color="error"
                    fullWidth
                    startIcon={<EditIcon />}
                    onClick={() => {
                      setViewProfileDlg(false);
                      setEditProfileDlg(true);
                    }}
                  >
                    {translateFront("Edit Profile")}
                  </Button>
                </CardActions>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {localData.name.toUpperCase()}
                  </Typography>
                  <Typography gutterBottom variant="body2" component="div">
                    {translateFront("Email")}:{" "}
                    <span style={{ fontWeight: 900 }}>{localData.email}</span>
                  </Typography>
                  <Typography gutterBottom variant="body2" component="div">
                    {translateFront("Gender")}:{" "}
                    {localData.gender && localData.gender === "male" ? (
                      <Tooltip
                        arrow
                        placement="right"
                        title={localData.gender.toUpperCase()}
                      >
                        <IconButton size="small">
                          <MaleIcon sx={{ color: "black" }} />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        arrow
                        placement="right"
                        title={localData.gender.toUpperCase()}
                      >
                        <IconButton size="small">
                          <FemaleIcon sx={{ color: "black" }} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Typography>
                  <Typography gutterBottom variant="body2" component="div">
                    {translateFront("Address")}:{" "}
                    <span style={{ fontWeight: 900 }}>{localData.address}</span>
                  </Typography>
                  <Typography gutterBottom variant="body2" component="div">
                    {translateFront("Phone")}:{" "}
                    <span style={{ fontWeight: 900 }}>{localData.phone}</span>
                  </Typography>
                  <Typography gutterBottom variant="body2" component="div">
                    {translateFront("Subscription Status")}:{" "}
                    {localData.subscribed ? (
                      <Tooltip
                        arrow
                        placement="top"
                        title="Subscribed"
                        sx={{ mt: -0.8 }}
                      >
                        <IconButton size="small">
                          <CheckCircleIcon color="success" />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        arrow
                        placement="top"
                        title="Unsubscribed"
                        sx={{ mt: -0.8 }}
                      >
                        <IconButton size="small">
                          <CancelIcon color="error" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    sx={{ textDecoration: "underline" }}
                  >
                    {translateFront("Social Media")}
                  </Typography>
                  <Button
                    variant="text"
                    startIcon={<WhatsAppIcon sx={{ color: "#4ccf7a" }} />}
                    size="small"
                    fullWidth
                    sx={{
                      justifyContent: "flex-start",
                      color: "inherit",
                      textAlign: "left",
                    }}
                    onClick={() =>
                      getSocialLink("whatsApp").includes("https://")
                        ? window.open(getSocialLink("whatsApp"), "_blank")
                        : copyToClipBoardFront(
                            dispatch,
                            getSocialLink("whatsApp")
                          )
                    }
                  >
                    {getSocialLink("whatsApp")}
                  </Button>
                  <Button
                    variant="text"
                    startIcon={
                      <img
                        src="https://img.icons8.com/doodle/48/000000/weixing.png"
                        alt="WeChat"
                        style={{ height: "1.15rem" }}
                      />
                    }
                    size="small"
                    fullWidth
                    sx={{
                      justifyContent: "flex-start",
                      color: "inherit",
                      textAlign: "left",
                    }}
                    onClick={() =>
                      getSocialLink("weChat").includes("https://")
                        ? window.open(getSocialLink("weChat"), "_blank")
                        : copyToClipBoardFront(
                            dispatch,
                            getSocialLink("weChat")
                          )
                    }
                  >
                    {getSocialLink("weChat")}
                  </Button>
                  <Button
                    variant="text"
                    startIcon={
                      <img
                        src="https://img.icons8.com/color/48/000000/skype--v1.png"
                        alt="Skype"
                        style={{ height: "1.15rem" }}
                      />
                    }
                    size="small"
                    fullWidth
                    sx={{
                      justifyContent: "flex-start",
                      color: "inherit",
                      textAlign: "left",
                    }}
                    onClick={() =>
                      getSocialLink("skype").includes("https://")
                        ? window.open(getSocialLink("skype"), "_blank")
                        : copyToClipBoardFront(dispatch, getSocialLink("skype"))
                    }
                  >
                    {getSocialLink("skype")}
                  </Button>
                  <Button
                    variant="text"
                    startIcon={<FacebookIcon sx={{ color: "navy" }} />}
                    size="small"
                    fullWidth
                    sx={{
                      justifyContent: "flex-start",
                      color: "inherit",
                      textAlign: "left",
                    }}
                    onClick={() =>
                      getSocialLink("facebook").includes("https://")
                        ? window.open(getSocialLink("facebook"), "_blank")
                        : copyToClipBoardFront(
                            dispatch,
                            getSocialLink("facebook")
                          )
                    }
                  >
                    {getSocialLink("facebook")}
                  </Button>
                  {ADDITIONAL_SOCIAL_LINK_ARR.map((x, i) => (
                    <Button
                      variant="text"
                      key={i}
                      size="small"
                      fullWidth
                      sx={{
                        justifyContent: "flex-start",
                        color: "inherit",
                        textAlign: "left",
                      }}
                      onClick={() =>
                        x.socialLink.includes("https://")
                          ? window.open(x.socialLink, "_blank")
                          : copyToClipBoardFront(dispatch, x.socialLink)
                      }
                    >
                      {x.socialName}: {x.socialLink}
                    </Button>
                  ))}
                </CardContent>
              </Card>
            </Grid>
          </DialogContent>
        </Dialog>
      )}
      {editProfileDlg && (
        <EditCustomerProfile
          editProfileDlg={editProfileDlg}
          setEditProfileDlg={setEditProfileDlg}
        />
      )}
    </>
  );
}
