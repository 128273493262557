import { Box, Button, Grid } from "@mui/material";
import React, { useState } from "react";
import ManualInvoiceForBismibEnterprise from "./ManualInvoiceForBismibEnterprise";
import ManualInvoice from "./ManualInvoice";
import ManualInvoiceForMfw2 from "./ManualInvoiceForMfw2";

export default function InvoiceVariantSelection() {
  const [variant, setVariant] = useState(undefined);
  return (
    <>
      <Box>
        <Grid container spacing={1} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={6}>
            <Button
              variant={variant === "mfw" ? "contained" : "outlined"}
              color="success"
              fullWidth
              onClick={() => setVariant("mfw")}
            >
              MFW Variant
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              variant={variant === "bismib" ? "contained" : "outlined"}
              color="success"
              fullWidth
              onClick={() => setVariant("bismib")}
            >
              BISMIB ENTERPRIZE Variant
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              variant={variant === "mfw2" ? "contained" : "outlined"}
              color="success"
              fullWidth
              onClick={() => setVariant("mfw2")}
            >
              MFW Variant 2
            </Button>
          </Grid>
        </Grid>
      </Box>
      {variant !== undefined && (
        <>
          {variant === "bismib" ? (
            <ManualInvoiceForBismibEnterprise />
          ) : variant === "mfw2" ? (
            <ManualInvoiceForMfw2 />
          ) : (
            <ManualInvoice />
          )}
        </>
      )}
    </>
  );
}
