import LoadingButton from "@mui/lab/LoadingButton";
import {
  AppBar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Rating,
  Slide,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  Grid,
  createFilterOptions,
  Autocomplete,
  Card,
  CardContent,
  Input,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import LoadingBox from "./Loading";

// Media Query
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// Icons
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import CasinoIcon from "@mui/icons-material/Casino";
import PhotoCamera from "@mui/icons-material/PhotoCamera";

import { useDispatch, useSelector } from "react-redux";
import {
  getReviews,
  publishRating,
  uploadReviewImg,
} from "../../reduxToolkit/features/customerOnProduct";
import { getMedicines } from "../../reduxToolkit/features/adminPanel/medicine";
import { PF } from "../../config";
import ViewProductDetails from "../../screens/adminPanel/medicines/components/ViewProductDetails";

// Lazy Load Image
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const labels = {
  0.5: "Useless",
  1: "Useless+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Limit options to show in autocomplete
const OPTIONS_LIMIT = 3;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) => {
  return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
};

export default function ReviewManualSubmission({ reviewDlg, setReviewDlg }) {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const dispatch = useDispatch();

  // use State
  const [review, setReview] = useState({
    review: "",
    rating: 5,
    medicineId: "",
    customerEmail: "",
    customerPhone: "",
    customerRegistered: false,
    shortComings: "",
    approval: true,
    manualReview: true,
  });

  const [loadingForm, setLoadingForm] = useState(false);
  const [medList, setMedList] = useState([]);
  const [medListEn, setMedListEn] = useState([]);
  const [loadingMed, setLoadingMed] = useState(false);
  const [viewMed, setViewMed] = useState(undefined);

  const [reviewImg, setReviewImg] = useState("");
  const [reviewImgLoading, setReviewImgLoading] = useState("");

  const [activeTab, setActiveTab] = useState(0);

  const [phone, setPhone] = useState("");
  const [ratingHover, setRatingHover] = useState(5);

  const [formDisable, setFormDisable] = useState(false);

  // Local Store
  const localLangData = JSON.parse(localStorage.getItem("langFront"));

  // Store
  const { medicines } = useSelector((state) => state.medicine);

  const handleManualReviewSubmission = () => {
    setLoadingForm(true);
    let obj = { ...review };
    if (reviewImg && reviewImg.name) {
      const data = new FormData();
      const ext = reviewImg.name.split(".");
      const filename =
        Date.now() +
        "-" +
        review.review.replace(/\s+/g, "") +
        "-blog-img." +
        ext.slice(-1);
      data.append("name", filename);
      data.append("reviewImg", reviewImg);
      obj.reviewImg = filename;
      dispatch(uploadReviewImg(data));
    } else {
      if (reviewImg) obj.reviewImg = reviewImg;
    }
    obj.langId = localLangData._id;
    dispatch(publishRating(obj));
    setTimeout(() => {
      setReviewDlg(false);
      setLoadingForm(false);
      dispatch(getReviews());
    }, 1000);
  };

  const handleSelectMed = (e, newValue) => {
    if (newValue) {
      setLoadingMed(true);
      if (localLangData.langCode === "en")
        setViewMed({ id: newValue.fixedMedicineId, obj: newValue });
      else setViewMed({ id: newValue.medicineDetectorId, obj: newValue });
      setTimeout(() => {
        setLoadingMed(false);
      }, 300);
    }
  };

  const handleRandomMed = () => {
    setLoadingMed(true);
    if (medList.length > 0) {
      const random = Math.floor(Math.random() * (medList.length - 0)) + 0;
      if (localLangData.langCode === "en") {
        setViewMed({
          id: medList[random].fixedMedicineId,
          obj: medList[random],
        });
      } else {
        setViewMed({
          id: medList[random].medicineDetectorId,
          obj: medList[random],
        });
      }
    }
    setTimeout(() => {
      setLoadingMed(false);
    }, 300);
  };

  // get MEd Name En
  const getMedNameEn = (id) => {
    const M = medListEn.find((m) => m.fixedMedicineId === id);
    if (M) return M.genericBrand;
  };

  const handleCustomImg = () => {
    setLoadingMed(true);
    setActiveTab(1);
    setViewMed(undefined);
    setTimeout(() => {
      setLoadingMed(false);
    }, 300);
  };

  useEffect(() => {
    if (phone !== "") {
      let obj = { ...review };
      obj.customerPhone = phone;
      setReview(obj);
    }
  }, [phone]);

  useEffect(() => {
    if (viewMed) {
      let obj = { ...review };
      obj.medicineId = viewMed.id;
      setReview(obj);
    } else {
      let obj = { ...review };
      obj.medicineId = "";
      setReview(obj);
    }
  }, [viewMed]);

  useEffect(() => {
    if (!(medicines && medicines.length > 0)) dispatch(getMedicines());
  }, []);

  useEffect(() => {
    if (medicines && medicines.length > 0) {
      const M = medicines.find((m) => m.lang === localLangData.langCode);
      if (M && M.data) {
        setMedList(M.data);
      }
      const MEn = medicines.find((m) => m.lang === "en");
      if (MEn && MEn.data) {
        setMedListEn(MEn.data);
      }
    }
  }, [medicines]);

  // validity handle
  useEffect(() => {
    let errCount = 0;
    if (review.review === "") {
      errCount++;
    }
    // if (review.customerEmail === "") {
    //   errCount++;
    // }
    // if (!review.customerEmail.includes("@")) {
    //   errCount++;
    // }
    // if (review.customerPhone === "") {
    //   errCount++;
    // }
    if (review.medicineId === "") {
      if (reviewImg === "") {
        errCount++;
      }
    }
    if (errCount > 0) {
      setFormDisable(true);
    } else {
      setFormDisable(false);
    }
  }, [
    review.customerEmail,
    review.customerPhone,
    review.medicineId,
    review.review,
    reviewImg,
  ]);

  // ! translate
  const [siteLang, setSiteLang] = useState("");
  // Store
  const { langs, activeLang } = useSelector((state) => state.misc);
  const [langSettings1, setLangSettings1] = useState([]);
  const [langSettings2, setLangSettings2] = useState([]);
  const title1 = "Manual Review Dialog";
  const title2 = "Misc Words";

  useEffect(() => {
    if (langs && langs.length > 0 && siteLang !== "") {
      const L = langs.find((l) => l.langCode === siteLang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      const A = arr.find((l) => l._title === title1);
      if (A) setLangSettings1(A.data);
      const B = arr.find((l) => l._title === title2);
      if (B) setLangSettings2(B.data);
    }
  }, [siteLang, langs]);

  const translate1 = (String) => translation(String, title1, 1);
  const translate2 = (String) => translation(String, title2, 2);

  const translation = (String, title, index) => {
    let translation = "";
    let settings = [];
    if (index === 1) {
      settings = langSettings1;
    } else if (index === 2) {
      settings = langSettings2;
    }
    const T = settings.find((ls) => ls.title === String);
    if (T && T.value) translation = T.value;
    else {
      let arr = [];
      if (langs && langs.length > 0 && siteLang !== "") {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.settings && LEn.settings.length > 0) arr = LEn.settings;
        const S = arr.find((l) => l._title === title);
        if (S) {
          const T = S.data.find((ls) => ls.title === String);
          if (T && T.value) translation = T.value;
        }
      }
    }
    return translation;
  };

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setSiteLang(activeLang.langCode);
    } else {
      if (localLangData) {
        setSiteLang(localLangData.langCode);
      }
    }
  }, [activeLang]);
  // ! translate

  return (
    <Dialog
      open={reviewDlg}
      aria-labelledby="review-dialog-title"
      aria-describedby="review-dialog-description"
      fullScreen={!smUp}
      fullWidth={smUp}
      maxWidth={"lg"}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }} color="primary">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              setReviewDlg(false);
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {translate1("Manual Review")}
          </Typography>
          <LoadingButton
            variant="contained"
            color="error"
            endIcon={<AddIcon />}
            // size="small"
            loading={loadingForm}
            loadingPosition="end"
            onClick={handleManualReviewSubmission}
            disabled={formDisable}
          >
            Add
          </LoadingButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <TextField
          label={translate1("Review")}
          value={review.review}
          multiline
          minRows={4}
          variant="standard"
          fullWidth
          onChange={(e) => {
            let obj = { ...review };
            obj.review = e.target.value;
            setReview(obj);
          }}
          sx={{ my: 1 }}
          helperText={
            <Typography variant="body2" color="primary" align="right">
              <span>{review.review.length}</span>/ 1000
            </Typography>
          }
        />

        <Grid container>
          <Grid item>
            <Rating
              value={review.rating}
              precision={0.5}
              onChange={(e, newValue) => {
                let obj = { ...review };
                obj.rating = newValue;
                setReview(obj);
              }}
              onChangeActive={(event, newHover) => {
                setRatingHover(newHover);
              }}
            />
          </Grid>
          <Grid item>
            {review.rating !== null && (
              <Box sx={{ ml: 2 }}>
                {labels[ratingHover !== -1 ? ratingHover : review.rating]}
              </Box>
            )}
          </Grid>
        </Grid>
        <Card>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <Button
                  variant={activeTab === 0 ? "contained" : "outlined"}
                  size="small"
                  fullWidth
                  onClick={() => {
                    setReviewImgLoading(true);
                    setActiveTab(0);
                    setReviewImg("");
                    setTimeout(() => {
                      setReviewImgLoading(false);
                    }, 1000);
                  }}
                >
                  {translate1("Select Medicine Img")}
                </Button>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Button
                  variant={activeTab === 1 ? "contained" : "outlined"}
                  size="small"
                  fullWidth
                  onClick={handleCustomImg}
                >
                  {translate1("Select Custom Img")}
                </Button>
              </Grid>
            </Grid>

            {activeTab === 1 ? (
              <>
                <Grid
                  container
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ mt: 1 }}
                >
                  <Grid item>
                    {reviewImgLoading ? (
                      <LoadingBox w={"100%"} />
                    ) : (
                      <LazyLoadImage
                        effect="blur"
                        src={
                          reviewImg && reviewImg.name
                            ? URL.createObjectURL(reviewImg)
                            : reviewImg && reviewImg !== ""
                            ? PF + "/adminPanel/blog/" + reviewImg
                            : PF + "/adminPanel/medicines/no-medicine-image.jpg"
                        }
                        alt={review.review}
                        style={{
                          objectFit: "cover",
                          width: "200px",
                        }}
                      />
                    )}
                    <Typography
                      variant="body2"
                      align="center"
                      color="secondary"
                    >
                      {translate1("Recommended")}: 🖼️(800x600)
                    </Typography>
                  </Grid>
                  <Grid item position="relative">
                    <Box>
                      <label htmlFor={`icon-button-file-1`}>
                        <Input
                          accept="image/*"
                          id={`icon-button-file-1`}
                          required
                          type="file"
                          sx={{ display: "none" }}
                          onChange={(e) => {
                            setReviewImg(e.target.files[0]);
                          }}
                        />
                        <IconButton
                          color="primary"
                          aria-label={`upload-picture-1`}
                          component="span"
                        >
                          <PhotoCamera />
                        </IconButton>
                      </label>
                    </Box>
                  </Grid>
                  <Grid item>
                    {reviewImg && (
                      <Button
                        variant="contained"
                        size="small"
                        color="error"
                        onClick={() => {
                          setReviewImg("");
                        }}
                      >
                        {translate2("Remove Image")}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                {/* Select Medicine */}
                <Grid
                  container
                  spacing={0}
                  justifyContent="center"
                  alignItems="center"
                  sx={{ my: 1 }}
                >
                  <Grid item sx={{ flex: 1 }}>
                    {loadingMed ? (
                      <LoadingBox w={"100%"} />
                    ) : (
                      <Autocomplete
                        disablePortal
                        options={medList}
                        onChange={(e, newValue) => handleSelectMed(e, newValue)}
                        filterOptions={filterOptions}
                        defaultValue={viewMed ? viewMed.obj : null}
                        getOptionLabel={(option) =>
                          `${option.genericBrand} ${
                            localLangData.langCode !== "en"
                              ? "(" +
                                getMedNameEn(option.medicineDetectorId) +
                                ")"
                              : ""
                          }`
                        }
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            <img
                              loading="lazy"
                              width="20"
                              src={
                                option.image.length > 0
                                  ? option.image
                                      .find((i) => i.default)
                                      .src.includes("http")
                                    ? option.image.find((i) => i.default).src
                                    : PF +
                                      "adminPanel/medicines/img/" +
                                      option.image.find((i) => i.default).src
                                  : "https://www.daveraine.com/img/products/no-image.jpg"
                              }
                              alt="admins"
                            />
                            {option.genericBrand}{" "}
                            {localLangData.langCode !== "en" &&
                              " (" +
                                getMedNameEn(option.medicineDetectorId) +
                                ")"}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={translate1("Select Medicine")}
                            variant="standard"
                            size="small"
                            fullWidth
                          />
                        )}
                      />
                    )}
                  </Grid>
                  <Grid item>
                    <Tooltip arrow placement="top" title={translate1("Random")}>
                      <IconButton size="small" onClick={handleRandomMed}>
                        <CasinoIcon color="secondary" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
                {viewMed && !loadingMed && (
                  <ViewProductDetails medId={viewMed.id} lang={siteLang} />
                )}
              </>
            )}
          </CardContent>
        </Card>

        <TextField
          label={translate1("Edit")}
          value={review.customerEmail}
          variant="standard"
          fullWidth
          onChange={(e) => {
            let obj = { ...review };
            obj.customerEmail = e.target.value;
            setReview(obj);
          }}
          helperText={
            <Typography variant="body2" color="primary">
              {translate1("Must Include ")} "@"
            </Typography>
          }
          sx={{ my: 1 }}
        />
        <Typography variant="body2" color="inherit" sx={{ mt: 1 }}>
          {translate1("Phone")} *
        </Typography>
        <PhoneInputWithCountrySelect
          placeholder="Enter Phone Number"
          international
          value={phone}
          defaultCountry="US"
          onChange={setPhone}
        />
      </DialogContent>
    </Dialog>
  );
}
