import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Fragment, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getShippingDetails } from "../../reduxToolkit/features/frontend/checkout";
import {
  Alert,
  Autocomplete,
  Box,
  Divider,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { countries } from "../../utils/countryList";
import LoadingBox from "../misc/Loading";
import { getAllDeliveryMethods } from "../../reduxToolkit/features/adminPanel/delivery";
import { getMedicines } from "../../reduxToolkit/features/adminPanel/medicine";
import getSymbolFromCurrency from "currency-symbol-map";
import { currencyFormatter } from "../../hooks/currencyFormatter";

export default function AddressForm({
  setCompletedStep,
  addressFormObj,
  setAddressFormObj,
  carts,
}) {
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postal, setPostal] = useState("");
  const [country, setCountry] = useState("");
  const [countryLoading, setCountryLoading] = useState(false);
  const [saveShipping, setSaveShipping] = useState(false);
  const [shippingCost, setShippingCost] = useState(0);

  // Currency
  const [exchangeRate, setExchangeRate] = useState(1);
  const [currencyName, setCurrencyName] = useState("USA");

  // Delivery Methods
  const [availableDeliveryMethod, setAvailableDeliveryMethods] = useState([]);
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState("");
  const [deliveryNote, setDeliveryNote] = useState("");

  // local Store
  const localLangData = () => {
    let langObj = {};
    const L = JSON.parse(localStorage.getItem("langFront"));
    if (L && L._id) langObj = L;
    return langObj;
  };

  // Store
  const { deliveryMethods } = useSelector((state) => state.delivery);
  const { activeCurrencyFront } = useSelector((state) => state.miscFront);
  const { medVariants } = useSelector((state) => state.medicine);

  // Functions
  const getDefaultCountry = (countryName) => {
    const C = countries.find((c) => c.label === countryName);
    if (C) return C;
    else return null;
  };

  const getDeliverySummery = (methodId) => {
    let arr = [];
    if (availableDeliveryMethod.length > 0) {
      const D = availableDeliveryMethod.find((d) => d._id === methodId);
      if (D) arr = [...D.deliverySummery];
    }
    return arr;
  };

  const handleCountrySelect = (e, newValue) => {
    if (deliveryMethods.length > 0) {
      const dArr = deliveryMethods.filter(
        (d) => d.langId === localLangData()._id
      );
      let finalArr = [];
      dArr.forEach((d) => {
        d.countryArr.forEach((c) => {
          if (c.label === newValue.label) finalArr.push(d);
        });
      });
      if (finalArr.length < 1) {
        setSelectedDeliveryMethod("");
        setDeliveryNote("");
        setSelectedDeliveryMethod("");
      }
    }
    setCountry(newValue.label);
  };

  // Set Shipping Cost
  useEffect(() => {
    let initialPrice = 0;
    let incrementPrice = 0;
    let weight = 0;
    let cost = 0;

    const D = availableDeliveryMethod.find(
      (d) =>
        (localLangData().langCode !== "en" ? d.deliveryDetectorId : d._id) ===
        selectedDeliveryMethod
    );
    if (D && D.countryArr) {
      const C = D.countryArr.find((c) => c.label === country);
      if (C) {
        initialPrice = C.initialPrice || C.initialPriceKG || 0;
        incrementPrice = C.incrementPrice || C.incrementPriceKG || 0;
      }
    }
    // get weight
    carts.forEach((c) => {
      const V = medVariants.find((v) => v.fixedVariantId === c.variantId);
      if (V) {
        if (V.weight > 0) {
          weight += Number(V.weight);
        } else weight += 20;
      }
    });
    if (weight < 100) {
      cost = initialPrice;
    } else {
      const ow = weight - 100;
      const m = ow / 100;
      cost = initialPrice + m * incrementPrice;
    }

    console.log("selectedDeliveryMethod", D);

    setShippingCost(cost);
  }, [
    availableDeliveryMethod,
    carts,
    country,
    medVariants,
    selectedDeliveryMethod,
  ]);

  // set currency
  useEffect(() => {
    setExchangeRate(activeCurrencyFront.exchangeRate);
    setCurrencyName(activeCurrencyFront.currencyName);
  }, [activeCurrencyFront]);

  useEffect(() => {
    let errCounter = 0;
    firstName === "" && errCounter++;
    lastName === "" && errCounter++;
    address1 === "" && errCounter++;
    city === "" && errCounter++;
    postal === "" && errCounter++;
    country === "" && errCounter++;
    (selectedDeliveryMethod === "" || !selectedDeliveryMethod) && errCounter++;
    if (errCounter > 0) {
      setCompletedStep(1);
    } else {
      setCompletedStep(2);
    }
  }, [
    address1,
    city,
    country,
    firstName,
    lastName,
    postal,
    selectedDeliveryMethod,
    setCompletedStep,
  ]);

  useEffect(() => {
    // const shippingCost = getShippingCost();
    let obj = {
      firstName,
      lastName,
      address1,
      address2,
      city,
      state,
      postal,
      country,
      saveShipping,
      selectedDeliveryMethod,
      deliveryNote,
      shippingCost,
    };
    setAddressFormObj(obj);
  }, [
    address1,
    address2,
    city,
    country,
    firstName,
    lastName,
    postal,
    saveShipping,
    selectedDeliveryMethod,
    deliveryNote,
    setAddressFormObj,
    state,
    shippingCost,
  ]);

  // set data from address Form
  useEffect(() => {
    addressFormObj.firstName && setFirstName(addressFormObj.firstName);
    addressFormObj.lastName && setLastName(addressFormObj.lastName);
    addressFormObj.address1 && setAddress1(addressFormObj.address1);
    addressFormObj.address2 && setAddress2(addressFormObj.address2);
    addressFormObj.city && setCity(addressFormObj.city);
    addressFormObj.state && setState(addressFormObj.state);
    addressFormObj.postal && setPostal(addressFormObj.postal);
    if (addressFormObj.country) setCountry(addressFormObj.country);
    addressFormObj.saveShipping && setSaveShipping(addressFormObj.saveShipping);
    addressFormObj.selectedDeliveryMethod &&
      setSelectedDeliveryMethod(addressFormObj.selectedDeliveryMethod);
    addressFormObj.deliveryNote && setDeliveryNote(addressFormObj.deliveryNote);
  }, []);

  useEffect(() => {
    setCountryLoading(true);
    // getShippingCost();
    dispatch(getAllDeliveryMethods());
    // if (!(medVariants && medVariants.length > 0)) dispatch(getMedicines());
    setTimeout(() => {
      setCountryLoading(false);
    }, 100);
  }, []);

  useEffect(() => {
    if (deliveryMethods.length > 0) {
      const dArr = deliveryMethods.filter(
        (d) => d.langId === localLangData()._id
      );
      let finalArr = [];
      dArr.forEach((d) => {
        d.countryArr.forEach((c) => {
          if (c.label === country) finalArr.push(d);
        });
      });
      if (selectedDeliveryMethod === "" && finalArr.length > 0)
        setSelectedDeliveryMethod(
          localLangData().langCode !== "en"
            ? finalArr[0].deliveryDetectorId
            : finalArr[0]._id
        );
      else setSelectedDeliveryMethod("");

      setAvailableDeliveryMethods(finalArr);
    }
  }, [country, deliveryMethods]);

  // useEffect(() => {
  //   if (availableDeliveryMethod.length < 1) {
  //     setSelectedDeliveryMethod("");
  //     setDeliveryNote("");
  //   }
  // }, [availableDeliveryMethod]);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate

  return (
    <Fragment>
      <Typography variant="h6" gutterBottom>
        {translateFront("Shipping Address")}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            label={translateFront("First Name")}
            fullWidth
            autoComplete="given-name"
            variant="standard"
            value={firstName}
            autoFocus
            onChange={(e) => setFirstName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            label={translateFront("Last Name")}
            fullWidth
            autoComplete="family-name"
            variant="standard"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            label={translateFront("Address")}
            fullWidth
            autoComplete="shipping address-line1"
            variant="standard"
            value={address1}
            onChange={(e) => setAddress1(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={translateFront("Address Line 2")}
            fullWidth
            autoComplete="shipping address-line2"
            variant="standard"
            value={address2}
            onChange={(e) => setAddress2(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            label={translateFront("City")}
            fullWidth
            autoComplete="shipping address-level2"
            variant="standard"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label={translateFront("State/Province/Region")}
            fullWidth
            variant="standard"
            value={state}
            onChange={(e) => setState(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            label={translateFront("Zip/Postal Code")}
            fullWidth
            autoComplete="shipping postal-code"
            variant="standard"
            value={postal}
            onChange={(e) => setPostal(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          {countryLoading ? (
            // <LoadingBox />
            <></>
          ) : (
            <Autocomplete
              id="country-select-demo"
              // sx={{ width: 300 }}
              defaultValue={getDefaultCountry(country)}
              options={countries}
              onChange={(e, newValue) => handleCountrySelect(e, newValue)}
              autoHighlight
              getOptionLabel={(option) => option.label}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    alt=""
                  />
                  {option.label} ({option.code})
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  required
                  label={translateFront("Choose a country")}
                  variant="standard"
                  error={country !== "" && availableDeliveryMethod.length < 1}
                  helperText={
                    country !== "" &&
                    availableDeliveryMethod.length < 1 && (
                      <Typography variant="body2" color="error">
                        {translateFront("Choose Another Country")}
                      </Typography>
                    )
                  }
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                checked={saveShipping}
                onChange={(e) => setSaveShipping(e.target.checked)}
              />
            }
            label={translateFront("Use this address for payment details")}
          />
        </Grid>
        {country !== "" && (
          <>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              {availableDeliveryMethod.length < 1 ? (
                <>
                  <Alert
                    severity="warning"
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    {translateFront("No Delivery Methods Available...")}
                  </Alert>
                </>
              ) : (
                <>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      {translateFront("Select Delivery Method")}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedDeliveryMethod}
                      label={translateFront("Select Delivery Method")}
                      onChange={(e) =>
                        setSelectedDeliveryMethod(e.target.value)
                      }
                    >
                      {availableDeliveryMethod.map((x, i) => (
                        <MenuItem
                          value={
                            localLangData().langCode !== "en"
                              ? x.deliveryDetectorId
                              : x._id
                          }
                          key={i}
                        >
                          {x.methodName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <List>
                    {getDeliverySummery(selectedDeliveryMethod).map(
                      (x, i) =>
                        x !== "" && (
                          <ListItem disablePadding>
                            <ListItemText primary={`${i + 1}.${x}`} />
                          </ListItem>
                        )
                    )}
                  </List>
                  {selectedDeliveryMethod !== "" && (
                    <Typography variant="h6" color="primary" align="right">
                      {translateFront("Shipping Cost")} :{" "}
                      {getSymbolFromCurrency(currencyName)}
                      {currencyFormatter(
                        currencyName,
                        (Number(shippingCost) * exchangeRate).toFixed(0)
                      )}
                    </Typography>
                  )}
                  <TextField
                    label={translateFront("Note")}
                    value={deliveryNote}
                    variant="standard"
                    size="small"
                    placeholder={translateFront(
                      "Write Any Note You Want To Add"
                    )}
                    fullWidth
                    multiline
                    minRows={3}
                    onChange={(e) => setDeliveryNote(e.target.value)}
                  />
                </>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Fragment>
  );
}
