import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
// Icons
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Alert, Button, IconButton, Typography } from "@mui/material";
import { useSelector } from "react-redux";
export default function CaptchaTikTakToe({ loginFunc, setCaptchaDlg }) {
  const [ttt, setTtt] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [response, setResponse] = useState(null);
  const [turnChange, setTurnChange] = useState(false);
  const [changeCounter, setChangeCounter] = useState(0);
  const [win, setWin] = useState(false);

  //   useEffect
  //   player win
  useEffect(() => {
    if (
      (ttt[0] === 1 && ttt[1] === 1 && ttt[2] === 1) ||
      (ttt[0] === 1 && ttt[4] === 1 && ttt[8] === 1) ||
      (ttt[0] === 1 && ttt[3] === 1 && ttt[6] === 1) ||
      (ttt[1] === 1 && ttt[4] === 1 && ttt[7] === 1) ||
      (ttt[2] === 1 && ttt[4] === 1 && ttt[6] === 1) ||
      (ttt[2] === 1 && ttt[5] === 1 && ttt[8] === 1) ||
      (ttt[3] === 1 && ttt[4] === 1 && ttt[5] === 1) ||
      (ttt[6] === 1 && ttt[7] === 1 && ttt[8] === 1)
    ) {
      setWin(true);
      setResponse({
        message:
          translateFront("Good Job!") !== ""
            ? translateFront("Good Job!")
            : "Good Job!",
        severity: "success",
      });
      setTimeout(() => {
        setTtt([0, 0, 0, 0, 0, 0, 0, 0, 0]);
        setResponse(null);
        setChangeCounter(0);
        setCaptchaDlg(false);
        loginFunc();
      }, 2000);
    }
  }, [ttt]);
  //   Cpu win
  useEffect(() => {
    if (
      (ttt[0] === 2 && ttt[1] === 2 && ttt[2] === 2) ||
      (ttt[0] === 2 && ttt[4] === 2 && ttt[8] === 2) ||
      (ttt[0] === 2 && ttt[3] === 2 && ttt[6] === 2) ||
      (ttt[1] === 2 && ttt[4] === 2 && ttt[7] === 2) ||
      (ttt[2] === 2 && ttt[4] === 2 && ttt[6] === 2) ||
      (ttt[2] === 2 && ttt[5] === 2 && ttt[8] === 2) ||
      (ttt[3] === 2 && ttt[4] === 2 && ttt[5] === 2) ||
      (ttt[6] === 2 && ttt[7] === 2 && ttt[8] === 2)
    ) {
      setResponse({
        message:
          translateFront("You Lost!") !== ""
            ? translateFront("You Lost!")
            : "You Lost!",
        severity: "error",
      });
      setTimeout(() => {
        setTtt([0, 0, 0, 0, 0, 0, 0, 0, 0]);
        setResponse(null);
        setChangeCounter(0);
      }, 2000);
    }
  }, [ttt]);
  //   Draw
  useEffect(() => {
    if (!response) {
      if (!ttt.includes(0)) {
        setResponse({
          message:
            translateFront("Draw!") !== "" ? translateFront("Draw!") : "Draw!",
          severity: "warning",
        });
        setTimeout(() => {
          setTtt([0, 0, 0, 0, 0, 0, 0, 0, 0]);
          setResponse(null);
          setChangeCounter(0);
        }, 2500);
      }
    }
  }, [response, ttt]);
  //   CPU Turn Generator
  useEffect(() => {
    if (turnChange) {
      if (!win) {
        let tempTtt = [...ttt];
        if (changeCounter < 2) {
          if (!(tempTtt[4] === 1)) {
            if (tempTtt[4] === 0) {
              // setTimeout(() => {
              tempTtt[4] = 2;
              setTtt(tempTtt);
              setTurnChange(false);
              // }, 500);
            }
          } else {
            tempTtt[1] = 2;
            setTtt(tempTtt);
            setTurnChange(false);
          }
        } else {
          var arr = [];
          tempTtt.map((x, i) => {
            x === 0 && arr.push(i);
          });
          const random = arr[Math.floor(Math.random() * arr.length)];
          // setTimeout(() => {
          tempTtt[random] = 2;
          setTtt(tempTtt);
          setTurnChange(false);
          // }, 500);
        }
      }
    }
  }, [win, turnChange]);

  //   functions
  const handleClick = (i) => {
    if (!turnChange) {
      let tempTtt = [...ttt];
      if (tempTtt[i] === 0) {
        tempTtt[i] = 1;
        setTtt(tempTtt);
        setTimeout(() => {
          !win && setTurnChange(true);
        }, 100);
        setChangeCounter(changeCounter + 1);
      }
    }
  };

  const resetTtt = () => {
    setTtt([0, 0, 0, 0, 0, 0, 0, 0, 0]);
    setResponse(null);
    setChangeCounter(0);
  };

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate
  return (
    <>
      <Typography variant="body2" color="primary" align="center" width="150px">
        {translateFront("Solve Tic-Tac-Toe Captcha")}{" "}
      </Typography>
      <Grid
        container
        spacing={0}
        width="150px"
        justifyContent="center"
        alignItems="center"
      >
        {[...Array(9)].map((x, i) => (
          <Grid
            key={i}
            item
            xs={4}
            sx={{
              borderRight: "1px solid black",
              borderBottom: "1px solid black",
              borderLeft: (i === 0 || i === 3 || i === 6) && "1px solid black",
              borderTop: (i === 0 || i === 1 || i === 2) && "1px solid black",
              minWidth: "10px",
              minHeight: "45px",
              cursor: "pointer",
            }}
            onClick={() => handleClick(i)}
          >
            <IconButton>
              {ttt[i] === 1 ? (
                <CircleOutlinedIcon sx={{ pl: 0, color: "black" }} />
              ) : (
                ttt[i] === 2 && (
                  <CloseOutlinedIcon sx={{ pr: 0, color: "black" }} />
                )
              )}
            </IconButton>
          </Grid>
        ))}
      </Grid>
      {response && (
        <Alert severity={response.severity} sx={{ my: 1 }}>
          {response.message}
        </Alert>
      )}
      <Button
        size="small"
        variant="text"
        color="error"
        onClick={resetTtt}
        fullWidth
        sx={{ width: "150px" }}
        disabled={response ? true : false}
      >
        {translateFront("Reset")}
      </Button>
    </>
  );
}
