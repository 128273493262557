import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import AskPriceListByCustomerDialog from "../../../customers/dialogs/AskPriceListByCustomerDialog";

export default function PriceInquiryReport({ startDate, endDate }) {
  const [askPriceList, setAskPriceList] = useState([]);

  // Store
  const { askAllPriceList } = useSelector((state) => state.checkout);

  useEffect(() => {
    if (askAllPriceList && askAllPriceList.length && startDate && endDate) {
      var resultProductData = askAllPriceList.filter((a) => {
        var date = new Date(a.createdAt);
        return date >= startDate && date <= endDate;
      });
      setAskPriceList(resultProductData);
    }
  }, []);

  return (
    <>
      {startDate && endDate && (
        <>
          <Box
            sx={{
              maxHeight: { xs: "40vh", sm: "40vh" },
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <AskPriceListByCustomerDialog askPriceList={askPriceList} />
          </Box>
        </>
      )}
    </>
  );
}
