import LocalHospital from "@mui/icons-material/LocalHospital";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Slide,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";
import HowToOrder from "../home/HowToOrder";

export default function MedicineDetails({ medicineAdditionInfo }) {
  const [activeTab, setActiveTab] = useState(0);
  const [additionalSecArr, setAdditionalSecArr] = useState([]);
  useEffect(() => {
    if (medicineAdditionInfo && medicineAdditionInfo.length > 0) {
      let arr = [];
      medicineAdditionInfo.forEach((x, i) => {
        let obj = { ...x };
        if (i < iconArr.length) obj.icon = iconArr[i];
        else
          obj.icon =
            "https://img.icons8.com/material-sharp/24/000000/plus--v1.png";
        arr.push(obj);
      });
      setAdditionalSecArr(arr);
      setActiveTab(medicineAdditionInfo[0]);
    }
  }, [medicineAdditionInfo]);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate
  return (
    <Slide direction="up" in={activeTab} mountOnEnter unmountOnExit>
      <Box>
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          {additionalSecArr.length > 0 &&
            additionalSecArr.map(
              (x, i) =>
                x.content !== "" && (
                  <Grid item key={i}>
                    <Button
                      startIcon={
                        <LazyLoadImage
                          src={x.icon}
                          alt={translateFront(x.sectionName)}
                          style={{ width: 25, height: 25 }}
                        />
                      }
                      variant={
                        activeTab._id === x._id ? "contained" : "outlined"
                      }
                      color="primary"
                      size="small"
                      onClick={() => setActiveTab(x)}
                    >
                      {translateFront(x.sectionName)}
                    </Button>
                  </Grid>
                )
            )}
        </Grid>
        <Grid container justifyContent="center">
          <Grid item xs={10}>
            <Card>
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${activeTab.content}`,
                    }}
                  ></div>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Slide>
  );
}

const iconArr = [
  "https://img.icons8.com/doodle/48/000000/hospital--v1.png",
  "https://img.icons8.com/external-flaticons-lineal-color-flat-icons/64/000000/external-side-effect-cbd-oil-flaticons-lineal-color-flat-icons.png",
  "https://img.icons8.com/external-wanicon-solid-wanicon/64/000000/external-prescription-health-checkup-wanicon-solid-wanicon.png",
  "https://img.icons8.com/emoji/48/000000/information-emoji.png",
  "https://img.icons8.com/external-bearicons-outline-color-bearicons/64/000000/external-faq-frequently-asked-questions-faq-bearicons-outline-color-bearicons-1.png",
  "https://cdn-icons-png.flaticon.com/512/4228/4228956.png",
];
