import { createNoti } from "../reduxToolkit/features/adminPanel/misc";
import { createNotiFront } from "../reduxToolkit/features/auth";

export const copyToClipBoard = (dispatch, text) => {
  navigator.clipboard.writeText(text);
  dispatch(createNoti({ message: "Copied To ClipBoard", severity: "success" }));
};

export const copyToClipBoardFront = (dispatch, text) => {
  navigator.clipboard.writeText(text);
  dispatch(
    createNotiFront({ message: "Copied To ClipBoard", severity: "success" })
  );
};

export const manualSnackNoti = (dispatch, text) => {
  dispatch(createNotiFront({ message: text, severity: "success" }));
};
