import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  createFilterOptions,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  Tooltip,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddLangDialog from "../../medicines/dialog/AddLangDialog";

// Icons
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import InfoIcon from "@mui/icons-material/Info";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { useDispatch, useSelector } from "react-redux";
import AddDeliveryMethods from "./AddDeliveryMethods";
import LoadingBox from "../../../../components/misc/Loading";
import {
  getAllDeliveryMethods,
  removeDeliveryMethods,
} from "../../../../reduxToolkit/features/adminPanel/delivery";
import EditDeliveryMethods from "./EditDeliveryMethods";
import { getAdmins } from "../../../../reduxToolkit/features/adminPanel/admin";
import { getCreated, getExactTime } from "../../../../hooks/getCreatedData";
import { countries } from "../../../../utils/countryList";
import { getNotification } from "../../../../reduxToolkit/features/adminPanel/notification";

// Limit options to show in autocomplete
const OPTIONS_LIMIT = 5;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) => {
  return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
};

export default function ManageDelivery() {
  const dispatch = useDispatch();

  const [loadingMain, setLoadingMain] = useState(false);
  const [lang, setLang] = useState("");
  const [langId, setLangId] = useState("");

  const [deliveryList, setDeliveryList] = useState([]);
  const [searchDeliveryList, setSearchDeliveryList] = useState([]);
  const [searchingDeliveryListByMethod, setSearchingDeliveryListByMethod] =
    useState([]);
  const [searchingDeliveryListByCountry, setSearchingDeliveryListByCountry] =
    useState([]);
  const [searchingDeliveryList, setSearchingDeliveryList] = useState([]);
  const [noMethodFound, setNoMethodFound] = useState(false);
  const [showDeliveryBuffer, setShowDeliveryBuffer] = useState([]);
  const [deliveryListEn, setDeliveryListEn] = useState([]);

  const [showPropertiesBuffer, setShowPropertiesBuffer] = useState([]);
  const [showCountriesBuffer, setShowCountriesBuffer] = useState([]);
  const [showCountryInfoBuffer, setShowCountryInfoBuffer] = useState([]);

  // Dialog
  const [addLangDlg, setAddLangDlg] = useState(false);
  const [addDeliveryDlg, setAddDeliveryDlg] = useState(false);

  const [deleteDlg, setDeleteDlg] = useState(false);
  const [deleteLoadingBuffer, setDeleteLoadingBuffer] = useState("");
  const [deleteBuffer, setDeleteBuffer] = useState({});

  const [editDlg, setEditDlg] = useState(false);
  const [editBuffer, setEditBuffer] = useState({});

  // LocalData
  const localLangData = JSON.parse(localStorage.getItem("lang"));
  const localAdminData = () => {
    let adminData = JSON.parse(localStorage.getItem("adminLoginInfo"));
    return adminData;
  };

  // store
  const { langs, activeLang } = useSelector((state) => state.misc);
  const { deliveryMethods } = useSelector((state) => state.delivery);
  let { admins } = useSelector((state) => state.admin);

  // Functions
  const handleChangeLang = (e) => {
    setLang(e.target.value);
    const L = langs.find((l) => l.langCode === e.target.value);
    setLangId(L._id);
  };

  const handleAddDelivery = () => {
    setAddDeliveryDlg(true);
  };

  const handleSearch = (e, newValue) => {
    setSearchingDeliveryListByMethod(newValue);
  };

  const handleCountrySearch = (e, newValue) => {
    let finalArr = [];
    newValue.forEach((n) => {
      let dArr = [];
      if (searchingDeliveryListByMethod.length > 0)
        dArr = [...searchingDeliveryListByMethod];
      else dArr = [...deliveryList];
      dArr.forEach((d) => {
        d.countryArr.forEach((c) => {
          if (c.label === n.label) finalArr.push(d);
        });
      });
    });
    setSearchingDeliveryListByCountry(finalArr);
    if (newValue.length > 0 && finalArr.length < 1) setNoMethodFound(true);
    else setNoMethodFound(false);
  };

  const getEnMethodName = (deliveryDetectorId) => {
    let enMethod = "";
    if (deliveryDetectorId) {
      const D = deliveryMethods.find((d) => d._id === deliveryDetectorId);
      if (D) {
        enMethod = D.methodName;
      }
    }
    return enMethod;
  };

  const getCountryArr = (countryArr) => {
    let arr = [];
    if (countryArr && countryArr.length > 0) {
      countryArr.forEach((c, index) => {
        arr.push({
          index,
          label: c.label,
          initialPrice: c.initialPrice || 0,
          incrementPrice: c.incrementPrice || 0,
          initialPriceKG: c.initialPriceKG || 0,
          incrementPriceKG: c.incrementPriceKG || 0,
        });
      });
    }
    return arr;
  };

  const handleDeliverySummeryShow = (id) => {
    let arr = [...showDeliveryBuffer];
    if (arr.includes(id)) {
      arr = arr.filter((a) => a !== id);
    } else {
      arr.push(id);
    }
    setShowDeliveryBuffer(arr);
  };

  const handlePropertiesShow = (id) => {
    let arr = [...showPropertiesBuffer];
    if (arr.includes(id)) {
      arr = arr.filter((a) => a !== id);
    } else {
      arr.push(id);
    }
    setShowPropertiesBuffer(arr);
  };

  const handleCountriesShow = (id) => {
    let arr = [...showCountriesBuffer];
    if (arr.includes(id)) {
      arr = arr.filter((a) => a !== id);
    } else {
      arr.push(id);
    }
    setShowCountriesBuffer(arr);
  };

  const handleShowCountryInfo = (id) => {
    let arr = [...showCountryInfoBuffer];
    if (arr.includes(id)) {
      arr = arr.filter((a) => a !== id);
    } else {
      arr.push(id);
    }
    setShowCountryInfoBuffer(arr);
  };

  const handleDeleteMethod = (id) => {
    setDeleteDlg(false);
    setDeleteLoadingBuffer(id);
    if (localAdminData()._id) {
      dispatch(
        removeDeliveryMethods({
          deliveryId: id,
          creatorId: localAdminData()._id,
        })
      );
    }
    setTimeout(() => {
      setDeleteLoadingBuffer("");
      setDeleteBuffer({});
      dispatch(getAllDeliveryMethods());
      dispatch(getNotification());
    }, 1000);
  };

  const getCreatorName = (id) => {
    let name = "";
    const A = admins.find((a) => a._id === id);
    if (A) name = A.adminName;
    return name;
  };

  // Fixed date
  const [showDate, setShowDate] = useState([]);

  const handleShowDate = (date) => {
    let arr = [...showDate];
    if (arr.includes(date)) {
      arr = arr.filter((a) => a !== date);
    } else {
      arr.push(date);
    }
    setShowDate(arr);
  };

  // UseEffect
  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setLang(activeLang.langCode);
      setLangId(activeLang._id);
    } else {
      if (localLangData) {
        setLang(localLangData.langCode);
        setLangId(localLangData._id);
      }
    }
  }, [activeLang]);

  // set Delivery Method
  useEffect(() => {
    if (deliveryMethods.length > 0 && langs.length > 0) {
      const D = deliveryMethods.filter((d) => d.langId === langId);
      setDeliveryList(D);
      setSearchDeliveryList([...D, ...countries]);
      if (lang !== "en") {
        const L = langs.find((l) => l.langCode === "en");
        const DEn = deliveryMethods.filter((d) => d.langId === L._id);
        setDeliveryListEn(DEn);
      }
    }
  }, [deliveryMethods, langId, lang, langs]);

  useEffect(() => {
    let arr = [
      ...searchingDeliveryListByCountry,
      ...searchingDeliveryListByMethod,
    ];
    const unique = [
      ...new Map(arr.map((item) => [item["_id"], item])).values(),
    ];
    setSearchingDeliveryList(unique);
  }, [searchingDeliveryListByCountry, searchingDeliveryListByMethod]);

  useEffect(() => {
    setLoadingMain(true);
    dispatch(getAllDeliveryMethods());
    dispatch(getAdmins());
    setTimeout(() => {
      setLoadingMain(false);
    }, 1000);
  }, []);

  // ! translate
  const [siteLang, setSiteLang] = useState("");
  const [langSettings1, setLangSettings1] = useState([]);
  const [langSettings2, setLangSettings2] = useState([]);
  const title1 = "Manage Delivery Method";
  const title2 = "Misc Words";

  useEffect(() => {
    if (langs && langs.length > 0 && siteLang !== "") {
      const L = langs.find((l) => l.langCode === siteLang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      const A = arr.find((l) => l._title === title1);
      if (A) setLangSettings1(A.data);
      const B = arr.find((l) => l._title === title2);
      if (B) setLangSettings2(B.data);
    }
  }, [siteLang, langs]);

  const translate1 = (String) => translation(String, title1, 1);
  const translate2 = (String) => translation(String, title2, 2);

  const translation = (String, title, index) => {
    let translation = "";
    let settings = [];
    if (index === 1) {
      settings = langSettings1;
    } else if (index === 2) {
      settings = langSettings2;
    }
    const T = settings.find((ls) => ls.title === String);
    if (T && T.value) translation = T.value;
    else {
      let arr = [];
      if (langs && langs.length > 0 && siteLang !== "") {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.settings && LEn.settings.length > 0) arr = LEn.settings;
        const S = arr.find((l) => l._title === title);
        if (S) {
          const T = S.data.find((ls) => ls.title === String);
          if (T && T.value) translation = T.value;
        }
      }
    }
    return translation;
  };

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setSiteLang(activeLang.langCode);
    } else {
      if (localLangData) {
        setSiteLang(localLangData.langCode);
      }
    }
  }, [activeLang]);
  // ! translate
  return (
    <>
      <Box>
        <Grid
          container
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
        >
          {/* Select Language */}
          <Grid item xs={12} md={4}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={2} sm={1} md={1.5}>
                <IconButton>
                  <AddCircleOutlineOutlinedIcon
                    color="info"
                    size="large"
                    onClick={() => setAddLangDlg(true)}
                  />
                </IconButton>
                <AddLangDialog
                  addLangDlg={addLangDlg}
                  setAddLangDlg={setAddLangDlg}
                  setLang={setLang}
                />
              </Grid>
              <Grid item xs={10} sm={4} md={5}>
                <FormControl fullWidth>
                  <Select
                    value={lang}
                    label="language"
                    variant="standard"
                    onChange={handleChangeLang}
                    size="small"
                  >
                    {langs.map((x, i) => (
                      <MenuItem key={i} value={x.langCode} dense>
                        <img
                          alt={x.langCode}
                          src={`https://flagcdn.com/w20/${
                            x.langCode === "en" ? "gb" : x.langCode
                          }.png`}
                        />
                        <Button color="inherit" size="small">
                          {x.langName}
                        </Button>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* Add Delivery Method */}
              <Grid item xs={12} sm={7} md={4.5}>
                <Button
                  variant="contained"
                  size="small"
                  color="error"
                  fullWidth
                  onClick={handleAddDelivery}
                >
                  {translate1("Add Method")}
                </Button>
                {addDeliveryDlg && (
                  <AddDeliveryMethods
                    lang={lang}
                    langId={langId}
                    addDeliveryDlg={addDeliveryDlg}
                    setAddDeliveryDlg={setAddDeliveryDlg}
                    deliveryListEn={deliveryListEn}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          {/* Search */}
          <Grid item xs={12} md={4} sx={{ mt: { md: -2, xs: 0 } }}>
            {loadingMain ? (
              <></>
            ) : (
              <Autocomplete
                multiple
                onChange={(e, newValue) => handleSearch(e, newValue)}
                disablePortal
                options={deliveryList}
                // options={searchDeliveryList}
                filterOptions={filterOptions}
                getOptionLabel={(option) =>
                  option.methodName
                    ? option.methodName +
                      (lang !== "en"
                        ? " (" +
                          getEnMethodName(option.deliveryDetectorId) +
                          ")"
                        : "")
                    : option.label
                }
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option.methodName}{" "}
                    {lang !== "en"
                      ? " (" + getEnMethodName(option.deliveryDetectorId) + ")"
                      : ""}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={translate1("Search by method") + "..."}
                    variant="standard"
                    size="small"
                    fullWidth
                  />
                )}
              />
            )}
          </Grid>
          {/* Country Search */}
          <Grid item xs={12} md={4} sx={{ mt: { md: -2, xs: 0 } }}>
            {loadingMain ? (
              <></>
            ) : (
              <Autocomplete
                multiple
                onChange={(e, newValue) => handleCountrySearch(e, newValue)}
                disablePortal
                options={countries}
                // options={searchDeliveryList}
                filterOptions={filterOptions}
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option.label}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={translate1("Search by country") + "..."}
                    variant="standard"
                    size="small"
                    fullWidth
                  />
                )}
              />
            )}
          </Grid>
        </Grid>
        {/* Delivery Method List */}
        <Button
          size="small"
          variant="contained"
          fullWidth
          sx={{ my: 1, pointerEvents: "none" }}
        >
          {translate2("Delivery Methods")} ({deliveryList.length})
        </Button>
      </Box>
      {loadingMain ? (
        <LoadingBox w={"100%"} />
      ) : deliveryList.length < 1 || noMethodFound ? (
        <Alert
          severity="warning"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          {translate1("No Data Available")}
        </Alert>
      ) : (
        <>
          <Box
            sx={{
              maxHeight: { xs: "60vh", sm: "57vh" },
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <Grid container spacing={0} xs={12}>
              {(searchingDeliveryList.length > 0
                ? searchingDeliveryList
                : deliveryList
              ).map((x, i) => (
                <Grid item key={i} xs={12} sm={6} md={3}>
                  {deleteLoadingBuffer === x._id ? (
                    <LoadingBox w={"100%"} h={"100%"} />
                  ) : (
                    <Card sx={{ m: 1 }}>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {x.methodName}
                          {lang !== "en"
                            ? " (" + getEnMethodName(x.deliveryDetectorId) + ")"
                            : ""}
                        </Typography>
                        <Button
                          variant={
                            showCountriesBuffer.includes(x._id)
                              ? "contained"
                              : "outlined"
                          }
                          size="small"
                          fullWidth
                          endIcon={
                            showCountriesBuffer.includes(x._id) ? (
                              <ArrowDropUpIcon />
                            ) : (
                              <ArrowDropDownCircleIcon />
                            )
                          }
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            my: 1,
                          }}
                          onClick={() => handleCountriesShow(x._id)}
                        >
                          {translate1("Countries")} (
                          {getCountryArr(x.countryArr).length})
                        </Button>
                        {showCountriesBuffer.includes(x._id) &&
                          getCountryArr(x.countryArr).map((y, j) => (
                            <>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                                key={j}
                              >
                                {y.index +
                                  1 +
                                  ". " +
                                  y.label +
                                  (y.initialPriceKG !== 0
                                    ? ` (kg)`
                                    : " (gram)") +
                                  " ($" +
                                  (y.initialPriceKG !== 0
                                    ? y.initialPriceKG
                                    : y.initialPrice) +
                                  "+$" +
                                  (y.incrementPriceKG !== 0
                                    ? y.incrementPriceKG
                                    : y.incrementPrice) +
                                  "...)"}
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    handleShowCountryInfo(i + "[" + j + "]")
                                  }
                                >
                                  <InfoIcon color="info" />
                                </IconButton>
                              </Typography>
                              {showCountryInfoBuffer.includes(
                                i + "[" + j + "]"
                              ) && (
                                <Typography variant="body2" color="primary">
                                  {y.initialPriceKG !== 0
                                    ? `Price upto 1kg = ${y.initialPriceKG}`
                                    : `${translate1("Price upto 100gm")}= $
                                  ${y.initialPrice}`}
                                  <br />
                                  {/* {translate1("Price increment /100gm")} = $ */}
                                  {/* {y.incrementPrice} */}
                                  {y.incrementPriceKG !== 0
                                    ? `Price increment /1kg = ${y.incrementPriceKG}`
                                    : `${translate1(
                                        "Price increment /100gm"
                                      )}= $
                                  ${y.incrementPrice}`}
                                </Typography>
                              )}
                            </>
                          ))}

                        <Button
                          variant={
                            showDeliveryBuffer.includes(x._id)
                              ? "contained"
                              : "outlined"
                          }
                          size="small"
                          fullWidth
                          endIcon={
                            showDeliveryBuffer.includes(x._id) ? (
                              <ArrowDropUpIcon />
                            ) : (
                              <ArrowDropDownCircleIcon />
                            )
                          }
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            my: 1,
                          }}
                          onClick={() => handleDeliverySummeryShow(x._id)}
                          disabled={x.deliverySummery.length < 1}
                        >
                          {translate1("Summary")} (
                          {x.deliverySummery ? x.deliverySummery.length : 0})
                        </Button>
                        {showDeliveryBuffer.includes(x._id) &&
                          x.deliverySummery.map((y, j) => (
                            <Typography variant="body2" color="text.secondary">
                              {j + 1}.{y}
                            </Typography>
                          ))}
                        <Divider sx={{ my: 1 }} />
                        {/* Properties */}
                        <Button
                          variant={
                            showPropertiesBuffer.includes(x._id)
                              ? "contained"
                              : "outlined"
                          }
                          size="small"
                          fullWidth
                          endIcon={
                            showPropertiesBuffer.includes(x._id) ? (
                              <ArrowDropUpIcon />
                            ) : (
                              <ArrowDropDownCircleIcon />
                            )
                          }
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            my: 1,
                          }}
                          onClick={() => handlePropertiesShow(x._id)}
                        >
                          {translate1("Creation Info")}
                        </Button>
                        {showPropertiesBuffer.includes(x._id) && (
                          <>
                            <Typography
                              gutterBottom
                              variant="body2"
                              color="primary"
                            >
                              {translate1("Created by")}:{" "}
                              {getCreatorName(x.creatorId)}
                            </Typography>
                            <Typography
                              gutterBottom
                              variant="body2"
                              color="error"
                            >
                              {translate1("Created")}: {getCreated(x.createdAt)}
                              <Tooltip
                                arrow
                                placement="top"
                                title={`Created: ${getExactTime(x.createdAt)}`}
                              >
                                <IconButton
                                  size="small"
                                  onClick={() => handleShowDate(x.createdAt)}
                                >
                                  <InfoIcon color="info" />
                                </IconButton>
                              </Tooltip>
                              <br />
                              {showDate.includes(x.createdAt) &&
                                getExactTime(x.createdAt)}
                            </Typography>
                            <Typography
                              gutterBottom
                              variant="body2"
                              sx={{ color: "orange" }}
                            >
                              {translate1("Updated")}: {getCreated(x.updatedAt)}
                              <Tooltip
                                arrow
                                placement="top"
                                title={`Created: ${getExactTime(x.updatedAt)}`}
                              >
                                <IconButton
                                  size="small"
                                  onClick={() => handleShowDate(x.updatedAt)}
                                >
                                  <InfoIcon color="info" />
                                </IconButton>
                              </Tooltip>
                              <br />
                              {showDate.includes(x.updatedAt) &&
                                getExactTime(x.updatedAt)}
                            </Typography>
                            <Divider sx={{ my: 1 }} />
                          </>
                        )}
                      </CardContent>
                      <CardActions>
                        <Button
                          size="small"
                          color="warning"
                          startIcon={<EditIcon />}
                          onClick={() => {
                            setEditDlg(true);
                            setEditBuffer(x);
                          }}
                        >
                          {translate1("Edit")}
                        </Button>
                        <Button
                          size="small"
                          color="error"
                          startIcon={<DeleteIcon />}
                          onClick={() => {
                            setDeleteDlg(true);
                            setDeleteBuffer(x);
                          }}
                        >
                          {translate1("Delete")}
                        </Button>
                      </CardActions>
                    </Card>
                  )}
                </Grid>
              ))}
            </Grid>
          </Box>
        </>
      )}
      <Dialog
        open={deleteDlg}
        onClose={() => {
          setDeleteDlg(false);
          setDeleteBuffer({});
        }}
      >
        <DialogTitle>
          Do you want to delete{" "}
          <span style={{ color: "red" }}>"{deleteBuffer.methodName}"</span> ?
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setDeleteDlg(false);
              setDeleteBuffer({});
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleDeleteMethod(deleteBuffer._id)}
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* Edit */}
      {editDlg && (
        <EditDeliveryMethods
          lang={lang}
          langId={langId}
          editDeliveryDlg={editDlg}
          setEditDeliveryDlg={setEditDlg}
          deliveryListEn={deliveryListEn}
          editBuffer={editBuffer}
        />
      )}
    </>
  );
}
