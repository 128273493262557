import { ExpandMore } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfoTut from "../../../components/InfoTut";
import { getNotification } from "../../../reduxToolkit/features/adminPanel/notification";

// Icons
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";

import LangNTitleBar from "./LangNTitleBar";
import {
  getManagedContentSettings,
  saveManagedContentSettings,
} from "../../../reduxToolkit/features/adminPanel/managedContentSettings";
import FooterText from "./FooterText";

export default function FooterContactInfo() {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [lang, setLang] = useState("");
  const [langId, setLangId] = useState("");

  const [contactInfo, setContactInfo] = useState({
    address: "",
    addressMapLink: "",
    contactNumber: [""],
    contactEmail: "",
    contactEmailLink: "",
  });

  // LocalData
  // const localLangData = JSON.parse(localStorage.getItem("lang"));
  const localData = JSON.parse(localStorage.getItem("adminLoginInfo"));

  //   Store
  const { managedContentSettings } = useSelector(
    (state) => state.managedContentSettings
  );

  const handleSaveSiteSEO = () => {
    setLoading(true);
    dispatch(
      saveManagedContentSettings({
        langId,
        changedSection: "Footer Contact Info",
        footerContactInfo: contactInfo,
        creatorId: localData._id,
      })
    );
    setTimeout(() => {
      setLoading(false);
      dispatch(getNotification());
      dispatch(getManagedContentSettings());
    }, 1000);
  };

  const addPhoneNumber = () => {
    let obj = { ...contactInfo };
    let arr = [...obj.contactNumber];
    arr.push("");
    obj.contactNumber = arr;
    setContactInfo(obj);
  };

  const removePhoneNumber = (index) => {
    let obj = { ...contactInfo };
    let arr = [...obj.contactNumber];
    arr = arr.filter((a, i) => i !== index);
    obj.contactNumber = arr;
    setContactInfo(obj);
  };

  useEffect(() => {
    if (!(managedContentSettings && managedContentSettings.length > 0))
      dispatch(getManagedContentSettings());
  }, []);

  // Set Settings
  useEffect(() => {
    if (managedContentSettings && managedContentSettings.length > 0) {
      const M = managedContentSettings.find((m) => m.langId === langId);
      if (
        M &&
        M.footerContactInfo &&
        M.footerContactInfo.contactNumber &&
        M.footerContactInfo.contactNumber.length > 0
      ) {
        setContactInfo(M.footerContactInfo);
      } else {
        setContactInfo({
          address: "",
          addressMapLink: "",
          contactNumber: [""],
          contactEmail: "",
          contactEmailLink: "",
        });
      }
    }
  }, [langId, managedContentSettings]);

  //! Translate
  // Store
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-label="Expand"
          aria-controls="Head-Title-content"
          id="Head-Title-header"
        >
          <Typography>{translate0("Contact Info")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <LangNTitleBar
            lang={lang}
            setLang={setLang}
            langId={langId}
            setLangId={setLangId}
            title={translate0("Contact Info")}
          />

          <TextField
            label={translate0("Address")}
            value={contactInfo.address}
            onChange={(e) => {
              let obj = { ...contactInfo };
              obj.address = e.target.value;
              setContactInfo(obj);
            }}
            size="small"
            fullWidth
            variant="standard"
          />

          <TextField
            label={translate0("Map Link")}
            value={contactInfo.addressMapLink}
            onChange={(e) => {
              let obj = { ...contactInfo };
              obj.addressMapLink = e.target.value;
              setContactInfo(obj);
            }}
            size="small"
            fullWidth
            variant="standard"
          />

          {contactInfo.contactNumber &&
            contactInfo.contactNumber.length > 0 &&
            contactInfo.contactNumber.map((x, i) => (
              <Grid container spacing={0} alignItems="center" key={i}>
                <Grid item sx={{ flex: 1 }}>
                  <TextField
                    label={`${i + 1}. ${translate0("Phone")}`}
                    value={x}
                    onChange={(e) => {
                      let obj = { ...contactInfo };
                      let arr = [...obj.contactNumber];
                      arr[i] = e.target.value;
                      obj.contactNumber = arr;
                      setContactInfo(obj);
                    }}
                    size="small"
                    fullWidth
                    variant="standard"
                  />
                </Grid>
                {contactInfo.contactNumber.length > 1 && (
                  <Grid item>
                    <IconButton
                      size="small"
                      onClick={() => removePhoneNumber(i)}
                    >
                      <CancelIcon color="error" />
                    </IconButton>
                  </Grid>
                )}
                {contactInfo.contactNumber.length - 1 === i && (
                  <Grid item>
                    <IconButton size="small" onClick={addPhoneNumber}>
                      <AddCircleIcon color="success" />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            ))}

          <TextField
            label={translate0("Contact Email View")}
            value={contactInfo.contactEmail}
            onChange={(e) => {
              let obj = { ...contactInfo };
              obj.contactEmail = e.target.value;
              setContactInfo(obj);
            }}
            size="small"
            fullWidth
            variant="standard"
          />

          <TextField
            label={translate0("Contact Email Link")}
            value={contactInfo.contactEmailLink}
            onChange={(e) => {
              let obj = { ...contactInfo };
              obj.contactEmailLink = e.target.value;
              setContactInfo(obj);
            }}
            size="small"
            fullWidth
            variant="standard"
          />

          <LoadingButton
            variant="contained"
            loading={loading}
            fullWidth
            size="small"
            color="success"
            onClick={handleSaveSiteSEO}
            sx={{ mt: 1 }}
          >
            {translate0("Save")}
          </LoadingButton>
        </AccordionDetails>
      </Accordion>
      <FooterText translate0={translate0} />
    </>
  );
}
