import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createNoti,
  uploadTempPic,
} from "../../../../../reduxToolkit/features/adminPanel/misc";

// Date Picker
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers";

// Ck Editor
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

import { PF } from "../../../../../config";
import { countries } from "../../../../../utils/countryList";
import { monthNameArr } from "../../data/monthNameArr";

export default function InvoiceRemarks({
  remarks,
  setRemarks,
  companyShortForm,
}) {
  const handleCreditTerms = (e, editor) => {
    let obj = { ...remarks };
    const data = editor.getData();
    obj.creditTerms = data;
    setRemarks(obj);
  };

  const handleDeclaration = (e, editor) => {
    let obj = { ...remarks };
    const data = editor.getData();
    obj.declaration = data;
    setRemarks(obj);
  };

  const handleExtraContent = (e, editor) => {
    let obj = { ...remarks };
    const data = editor.getData();
    obj.extraContent = data;
    setRemarks(obj);
  };

  useEffect(() => {
    let obj = { ...remarks };
    const d = new Date(remarks.date);
    let monthName = monthNameArr[d.getMonth()].shortName;
    let monthIndex = Number(d.getMonth()) + 1;
    if (Number(monthIndex) < 10) monthIndex = "0" + monthIndex;
    let date = d.getDate();
    if (Number(date) < 10) date = "0" + date;
    let fullYear = d.getFullYear();
    let year = fullYear.toString().slice(2, 4);
    let destination = remarks.finalDestination.slice(0, 3);
    const invoiceNo = `${monthName}/${date}/${monthIndex}/${year}/${companyShortForm}-${destination?.toUpperCase()}`;
    obj.invoiceNumber = invoiceNo;
    setRemarks(obj);
  }, [remarks.finalDestination, companyShortForm, remarks.date]);

  // ! CK Editor Image Upload

  const dispatch = useDispatch();
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          let tempName = Math.floor(Math.random() * (10000 - 0) + 0).toString();
          loader.file
            .then((file) => {
              const data = new FormData();
              if (file) {
                tempName = tempName + "." + file.name.split(".").pop();
                data.append("name", tempName);
                data.append("tempImg", file);
                dispatch(uploadTempPic(data));
                dispatch(
                  createNoti({
                    message: "Pls click on the image Confirm Image Upload",
                    severity: "warning",
                  })
                );
              }
            })
            .then(() => {
              resolve({
                default: `${PF}temp/${tempName}`,
              });
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }
  // ! CK Editor Image Upload

  //! Translate
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate

  return (
    <>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          size="small"
          sx={{ pointerEvents: "none", mb: 1 }}
          fullWidth
          color="secondary"
        >
          {translate0("Invoice Remarks")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1" color="initial">
          {translate0("Credit Terms")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CKEditor
          editor={Editor}
          data={remarks.creditTerms}
          config={{
            extraPlugins: [uploadPlugin],
          }}
          onBlur={(e, editor) => handleCreditTerms(e, editor)}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1" color="initial">
          {translate0("Declaration")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CKEditor
          editor={Editor}
          data={remarks.declaration}
          config={{
            extraPlugins: [uploadPlugin],
          }}
          onBlur={(e, editor) => handleDeclaration(e, editor)}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          id="country-select-demo"
          options={countries}
          onChange={(e, newValue) => {
            let obj = { ...remarks };
            obj.finalDestination = newValue.label;
            setRemarks(obj);
          }}
          getOptionLabel={(option) => `${option.label}`}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                alt="country-img"
              />
              {option.label}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label={translate0("Choose Destination")}
              variant="outlined"
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                label={translate0("Date")}
                value={remarks.date}
                onChange={(newValue) => {
                  let obj = { ...remarks };
                  obj.date = newValue;
                  setRemarks(obj);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item flex={1}>
            <TextField
              label={translate0("Invoice No")}
              value={remarks.invoiceNumber}
              onChange={(e) => {
                let obj = { ...remarks };
                obj.invoiceNumber = e.target.value;
                setRemarks(obj);
              }}
              size="large"
              fullWidth
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1" color="initial">
          {translate0("Extra Invoice Content")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CKEditor
          editor={Editor}
          data={remarks.extraContent}
          config={{
            extraPlugins: [uploadPlugin],
          }}
          onBlur={(e, editor) => handleExtraContent(e, editor)}
        />
      </Grid>
    </>
  );
}
