export const monthNameArr = [
  { shortName: "JAN", fullName: "January" },
  { shortName: "FEB", fullName: "February" },
  { shortName: "MAR", fullName: "March" },
  { shortName: "APR", fullName: "April" },
  { shortName: "MAY", fullName: "May" },
  { shortName: "JUN", fullName: "June" },
  { shortName: "JUL", fullName: "July" },
  { shortName: "AUG", fullName: "August" },
  { shortName: "SEP", fullName: "September" },
  { shortName: "OCT", fullName: "October" },
  { shortName: "NOV", fullName: "November" },
  { shortName: "DEC", fullName: "December" },
];
