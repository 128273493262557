import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getLangSettings,
  setAdminLangFromClient,
} from "../../reduxToolkit/features/adminPanel/misc";
import { setFrontendLangFromClient } from "../../reduxToolkit/features/miscFront";

export default function LangImport({ front }) {
  const dispatch = useDispatch();

  // ! translate
  const [siteLang, setSiteLang] = useState("");
  // Local Store
  const localLangData = JSON.parse(localStorage.getItem("lang"));
  // Store
  const { langs, activeLang } = useSelector((state) => state.misc);
  const [langSettings, setLangSettings] = useState([]);

  useEffect(() => {
    if (langs && langs.length > 0 && siteLang !== "") {
      const L = langs.find((l) => l.langCode === siteLang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0) {
        arr = L.langSettings;
      } else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      let arrLang = [];
      arr.forEach((x) => {
        if (x && x.data) {
          let arr2 = [];
          x.data.forEach((y) => {
            let obj = { ...y };
            obj._title = x._title;
            arr2.push(obj);
          });
          arrLang = [...arrLang, ...arr2];
        }
      });
      setLangSettings(arrLang);
    }
  }, [siteLang, langs]);

  useEffect(() => {
    if (langSettings.length > 0) {
      dispatch(setAdminLangFromClient(langSettings));
    }
  }, [langSettings]);

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) setSiteLang(activeLang.langCode);
    else if (localLangData) setSiteLang(localLangData.langCode);
  }, [activeLang]);
  // ! translate

  // ! translate
  const [siteLangFront, setSiteLangFront] = useState("");
  // Local Store
  const localLangDataFront = JSON.parse(localStorage.getItem("langFront"));
  // Store
  const { activeLangFront } = useSelector((state) => state.miscFront);
  const [langSettingsFront, setLangSettingsFront] = useState([]);

  useEffect(() => {
    if (langs && langs.length > 0 && siteLangFront !== "") {
      const L = langs.find((l) => l.langCode === siteLangFront);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      let arrLang = [];
      arr.forEach((x) => {
        if (x && x.data) arrLang = [...arrLang, ...x.data];
      });
      setLangSettingsFront(arrLang);
    }
  }, [siteLangFront, langs]);

  useEffect(() => {
    if (langSettingsFront.length > 0) {
      dispatch(setFrontendLangFromClient(langSettingsFront));
    }
  }, [langSettingsFront]);

  // Set Language
  useEffect(() => {
    if (activeLangFront && activeLangFront.langCode)
      setSiteLangFront(activeLangFront.langCode);
    else if (localLangDataFront) setSiteLangFront(localLangDataFront.langCode);
  }, [activeLangFront]);
  // ! translate
  return <></>;
}
