import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

// Icon
import RefreshIcon from "@mui/icons-material/Refresh";
import { useSelector } from "react-redux";

export default function EmailVerificationDialog({
  verifyDlg,
  setVerifyDlg,
  verifyCodeInput,
  setVerifyCodeInput,
  countDownStart,
  setCountDownStart,
  logic,
}) {
  // UseState
  // Count down
  const [countDown, setCountDown] = useState("");
  // Functions
  // Verify Code Input
  const handleVerifyCodeInput = (e) => {
    const result = e.target.value.replace(/\D/g, "");
    setVerifyCodeInput(result);
  };

  // Countdown
  var x = setInterval(function () {
    if (countDownStart !== "") {
      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownStart - now;

      // Time calculations for minutes and seconds
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Display the result in the element with id="demo"
      setCountDown(minutes + "m : " + seconds + "s ");

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x);
        setCountDownStart("");
        setCountDown(0);
      }
    }
  }, 1000);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate
  return (
    <>
      {countDown === "" ? (
        <></>
      ) : (
        // <Backdrop
        //   sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        //   open={countDown === "" && verifyDlg}
        // >
        //   <CircularProgress color="inherit" />
        // </Backdrop>
        <Dialog
          open={verifyDlg}
          onClose={() => {
            setVerifyDlg(false);
            setVerifyCodeInput("");
            setCountDownStart("");
            setCountDown(0);
          }}
          aria-labelledby={"email-verification-dialog"}
        >
          <DialogTitle>
            {translateFront("Enter Email Verification Code Sent To Your Email")}
          </DialogTitle>
          <DialogContent>
            <TextField
              label={translateFront("Verification Code...")}
              value={verifyCodeInput}
              onChange={handleVerifyCodeInput}
              variant="standard"
              size="small"
              fullWidth
              helperText={
                <Typography variant="body1" color="secondary" align="right">
                  {countDown !== 0 ? (
                    countDown
                  ) : (
                    <Tooltip
                      arrow
                      placement="top"
                      title={translateFront("Resend")}
                    >
                      <IconButton size="small" onClick={logic}>
                        <RefreshIcon color="secondary" />
                      </IconButton>
                    </Tooltip>
                  )}
                </Typography>
              }
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setVerifyDlg(false);
                setVerifyCodeInput("");
                setCountDownStart("");
                setCountDown(0);
              }}
              color="error"
            >
              {translateFront("Close")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
