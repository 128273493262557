import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Grid from "@mui/material/Grid";

import { Alert, Box, Button } from "@mui/material";
import {
  getMedCategories,
  getMedicines,
  getViewCount,
} from "../../reduxToolkit/features/adminPanel/medicine";
import LoadingBox from "../../components/misc/Loading";
import ProductSmallBackend from "../../components/product/ProductSmallBackend";

export default function LatestProducts() {
  const dispatch = useDispatch();

  const [medLoading, setMedLoading] = useState(false);
  const [medList, setMedList] = useState([]);
  const [catList, setCatList] = useState([]);
  const [lang, setLang] = useState("en");

  // Responsive
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));

  // LocalData
  const localLangData = JSON.parse(localStorage.getItem("lang"));

  // Store
  const { activeLang } = useSelector((state) => state.misc);
  const { medicines, categories, viewCountArr } = useSelector(
    (state) => state.medicine
  );

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setLang(activeLang.langCode);
    } else {
      if (localLangData) setLang(localLangData.langCode);
    }
  }, [activeLang]);

  // Set Medicine
  useEffect(() => {
    setMedLoading(true);
    if (lang !== "" && medicines && medicines.length > 0) {
      let M = medicines.find((m) => m.lang === lang);
      if (M) {
        let arr = [];
        M.data.forEach((x) => {
          let obj = { ...x };
          const C = viewCountArr.find(
            (c) =>
              c.medicineIdEn ===
              (lang !== "en" ? x.medicineDetectorId : x.fixedMedicineId)
          );
          if (C) obj.viewCount = C.viewCount;
          else obj.viewCount = 0;
          arr.push(obj);
        });

        arr = arr.sort((a, b) =>
          new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1
        );
        setMedList(arr.slice(0, !smUp ? 1 : !mdUp ? 1 : !xlUp ? 2 : 3));
      } else setMedList([]);
    }
    setTimeout(() => {
      setMedLoading(false);
    }, 1000);
  }, [medicines, lang, viewCountArr]);

  // set Category
  useEffect(() => {
    if (lang !== "" && categories && categories.length > 0) {
      let C = categories.find((c) => c.lang === lang);
      if (C) setCatList(C.data);
      else setCatList([]);
    }
  }, [categories, lang]);

  useEffect(() => {
    // if (!(medicines && medicines.length > 0)) dispatch(getMedicines());
    // if (!(categories && categories.length > 0)) dispatch(getMedCategories());
    // if (!(viewCountArr && viewCountArr.length > 0)) dispatch(getViewCount());
  }, []);

  return (
    <>
      {medLoading ? (
        <LoadingBox w={"100%"} h={"600px"} />
      ) : (
        <Grid container spacing={1}>
          {medList.length < 1 ? (
            <Grid item xs={12}>
              <Box>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    minHeight: 500,
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    pointerEvents: "none",
                  }}
                  color="warning"
                >
                  No Medicine Data...
                </Button>
              </Box>
            </Grid>
          ) : (
            medList.map((x, i) => (
              <Grid item key={i} xs={12} sm={12} md={6} xl={4}>
                <ProductSmallBackend
                  x={x}
                  i={i}
                  view={0}
                  catList={catList}
                  lang={lang}
                />
              </Grid>
            ))
          )}
        </Grid>
      )}
    </>
  );
}
