import React, { Fragment, useRef } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {
  Badge,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  Tooltip,
} from "@mui/material";

// Theme
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// CK Editor
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  createNoti,
  uploadTempPic,
} from "../../../../reduxToolkit/features/adminPanel/misc";
import { PF } from "../../../../config";
import LoadingBox from "../../../../components/misc/Loading";
// import  Editor from "../../../../utils/editor/Editor";
// import { useRef } from "react";
// import { color } from "@mui/system";

export default function AdditionalPage({
  additionalSectionList,
  setAdditionalSectionList,
  translate1,
}) {
  // Media Query
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  // use State
  const [activeBuffer, setActiveBuffer] = useState({});
  const [additionalPageLoading, setAdditionalPageLoading] = useState(false);

  // Use Effect
  useEffect(() => {
    additionalSectionList &&
      additionalSectionList.length > 0 &&
      !activeBuffer.sectionName &&
      setActiveBuffer(additionalSectionList[0]);
  }, [activeBuffer, additionalSectionList]);

  useEffect(() => {
    setAdditionalPageLoading(true);
    setTimeout(() => {
      setAdditionalPageLoading(false);
    }, 1000);
  }, []);

  // Functions
  const handleContent = (e, editor) => {
    let obj = { ...activeBuffer };
    const data = editor.getData();
    // const data = editor.getContent({ format: "text" });
    obj.content = data;
    setActiveBuffer(obj);
    let arr = [...additionalSectionList];
    if (arr && arr.length > 0 && activeBuffer.sectionName) {
      const index = arr.findIndex(
        (o) => o.sectionName === activeBuffer.sectionName
      );
      // setActiveBufferIndex(index);
      arr[index] = obj;
    }

    setAdditionalSectionList(arr);
  };

  const handleSelectTab = (x) => {
    setActiveBuffer(x);
  };

  // const handleHighlight = (e) => {
  //   if (e.target.selectionStart !== e.target.selectionEnd) {
  //     const obj = {
  //       start: e.target.selectionStart,
  //       end: e.target.selectionEnd,
  //     };
  //     setCursorPosition(obj);
  //   }
  // };

  // ! CK Editor Image Upload

  const dispatch = useDispatch();
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          let tempName = Math.floor(Math.random() * (10000 - 0) + 0).toString();
          loader.file
            .then((file) => {
              const data = new FormData();
              if (file) {
                tempName = tempName + "." + file.name.split(".").pop();
                data.append("name", tempName);
                data.append("tempImg", file);
                dispatch(uploadTempPic(data));
                dispatch(
                  createNoti({
                    message: "Pls click on the image Confirm Image Upload",
                    severity: "warning",
                  })
                );
              }
            })
            .then(() => {
              resolve({
                default: `${PF}temp/${tempName}`,
              });
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }
  // ! CK Editor Image Upload
  return (
    <>
      {additionalPageLoading ? (
        <LoadingBox w={"100%"} />
      ) : (
        additionalSectionList &&
        additionalSectionList.length > 0 && (
          <Fragment>
            <Grid container spacing={1} justifyContent="center">
              {additionalSectionList.map((x, i) => (
                <Grid item sx={{ minWidth: !smUp && "100%" }} key={i}>
                  <Badge
                    variant="dot"
                    badgeContent={
                      x.content !== "" ||
                      (activeBuffer &&
                        x.sectionName === activeBuffer.sectionName)
                        ? 0
                        : " "
                    }
                    color="error"
                  >
                    <Tooltip
                      arrow
                      placement="top"
                      title="content is empty"
                      disableHoverListener={x.content !== "" ? true : false}
                    >
                      <Button
                        variant={
                          activeBuffer &&
                          x.sectionName === activeBuffer.sectionName
                            ? "contained"
                            : "outlined"
                        }
                        color="primary"
                        size="small"
                        onClick={() => handleSelectTab(x)}
                      >
                        {/* {translate1(x.sectionName)} */}
                        {translate1(x.sectionName) !== ""
                          ? translate1(x.sectionName)
                          : x.sectionName}
                      </Button>
                    </Tooltip>
                  </Badge>
                </Grid>
              ))}
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Typography variant="h5" color="secondary">
                  {activeBuffer && translate1(activeBuffer.sectionName) !== ""
                    ? translate1(activeBuffer.sectionName)
                    : activeBuffer.sectionName}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {/* <Grid item xs={12}>
              <Editor
                cursorPosition={cursorPosition}
                setCursorPosition={setCursorPosition}
                activeBufferIndex={activeBufferIndex}
                additionalSectionList={additionalSectionList}
                setAdditionalSectionList={setAdditionalSectionList}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid> */}
              {/* <div contentEditable style={{ width: "100%" }}>
              {activeBuffer.content && activeBuffer.content}
            </div> */}
              <Grid item xs={12}>
                {/* // TODO CUSTOM EDITOR (Could not fix)*/}
                {/* <TextField
                label="Content"
                value={activeBuffer.content && activeBuffer.content}
                fullWidth
                sx={{ mt: 1 }}
                variant="standard"
                multiline
                minRows={4}
                size="small"
                focused
                // component="input"
                // contentEditable
                // inputProps={{ contenteditable: true }}
                ref={contentRef}
                onChange={handleContent}
                onMouseUp={handleHighlight}
                onKeyUp={handleHighlight}
                helperText={
                  <Typography variant="body2" color="primary" align="right">
                    {activeBuffer.content
                      ? activeBuffer.content.length + "/ 2000"
                      : 0 + "/ 2000"}
                  </Typography>
                }
              /> */}
                {/* // TODO CUSTOM EDITOR (Could not fix)*/}
                {/* // ! CK EDITOR IMAGE ISSUE UN RESLOVED */}
                <CKEditor
                  editor={Editor}
                  data={
                    activeBuffer && activeBuffer.content && activeBuffer.content
                  }
                  config={{
                    extraPlugins: [uploadPlugin],
                  }}
                  onBlur={(e, editor) => handleContent(e, editor)}
                />
                {/* // ! CK EDITOR IMAGE ISSUE UN RESLOVED */}
              </Grid>
            </Grid>
          </Fragment>
        )
      )}
    </>
  );
}
