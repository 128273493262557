import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Divider } from "@mui/material";
import { useEffect } from "react";
import { MobileDatePicker } from "@mui/x-date-pickers";
import ReviewReport from "./ReviewReport";
import CustomerReport from "./CustomerReport";
import OrderReport from "./OrderReport";
import LoadingBox from "../../../../components/misc/Loading";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllOrders,
  getAskAllPriceList,
} from "../../../../reduxToolkit/features/frontend/checkout";
import PriceInquiryReport from "./PriceInquiryReport";
import {
  getDosages,
  getMedicines,
} from "../../../../reduxToolkit/features/adminPanel/medicine";

// Media Query
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getAllCustomers } from "../../../../reduxToolkit/features/auth";
import { getReviews } from "../../../../reduxToolkit/features/customerOnProduct";

export default function ViewReports() {
  const dispatch = useDispatch();

  // Media Query
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState([true, true, true, true]);

  const handleChange = (panel) => (event, newExpanded) => {
    let arr = [...expanded];
    arr[panel] = !arr[panel];
    setExpanded(arr);
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [endDate, startDate]);

  useEffect(() => {
    dispatch(getAllOrders());
    dispatch(getAskAllPriceList());
    dispatch(getMedicines());
    dispatch(getDosages());
    dispatch(getAllCustomers());
    dispatch(getReviews());
    setEndDate(new Date());
    setTimeout(() => {
      if (!mdUp) setExpanded([true, false, false, false]);
    }, 100);
  }, []);

  // Store
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate

  return (
    <>
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item xs={12} sm={6} md={3} xl={1.5}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDatePicker
              label={translate0("From")}
              value={startDate}
              onChange={(newValue) => {
                setStartDate(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={3} xl={1.5}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDatePicker
              label={translate0("To")}
              value={endDate}
              onChange={(newValue) => {
                setEndDate(newValue);
              }}
              disabled={!startDate}
              minDate={new Date(startDate)}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Divider sx={{ my: 1 }} />
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Accordion expanded={expanded[0]} onChange={handleChange(0)}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-label="Expand"
              aria-controls="order-content"
              id="order-header"
            >
              <Typography>{translate0("Order Report")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {loading ? (
                <LoadingBox w={"100%"} />
              ) : (
                <OrderReport startDate={startDate} endDate={endDate} />
              )}
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} md={6}>
          <Accordion expanded={expanded[1]} onChange={handleChange(1)}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-label="Expand"
              aria-controls="Medicine-content"
              id="Medicine-header"
            >
              <Typography>{translate0("Price Inquiry Report")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {loading ? (
                <LoadingBox w={"100%"} />
              ) : (
                <PriceInquiryReport startDate={startDate} endDate={endDate} />
              )}
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} md={6}>
          <Accordion expanded={expanded[2]} onChange={handleChange(2)}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-label="Expand"
              aria-controls="Customer-content"
              id="Customer-header"
            >
              <Typography>{translate0("Customer Report")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {loading ? (
                <LoadingBox w={"100%"} />
              ) : (
                <CustomerReport startDate={startDate} endDate={endDate} />
              )}
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} md={6}>
          <Accordion expanded={expanded[3]} onChange={handleChange(3)}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-label="Expand"
              aria-controls="Review-content"
              id="Review-header"
            >
              <Typography>{translate0("Review Report")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {loading ? (
                <LoadingBox w={"100%"} />
              ) : (
                <ReviewReport startDate={startDate} endDate={endDate} />
              )}
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
}
