import { ExpandMore } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNotification } from "../../../reduxToolkit/features/adminPanel/notification";

import LangNTitleBar from "./LangNTitleBar";
import {
  getManagedContentSettings,
  saveManagedContentSettings,
} from "../../../reduxToolkit/features/adminPanel/managedContentSettings";

// Icons
import CancelIcon from "@mui/icons-material/Cancel";
import MedicineVideoUpload from "../../adminPanel/medicines/components/MedicineVideoUpload";

// Media Query
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// Icons
import CloseIcon from "@mui/icons-material/Close";
import InfoTut from "../../../components/InfoTut";
import { uploadLogo } from "../../../reduxToolkit/features/adminPanel/webSettings";

export default function HowToOrderSettings({
  expanded,
  handleChange,
  translate0,
}) {
  const dispatch = useDispatch();

  // Media Query
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  const [loading, setLoading] = useState(false);
  const [lang, setLang] = useState("");
  const [langId, setLangId] = useState("");

  const [howToOrderSteps, setHowToOrderSteps] = useState([""]);

  const [videos, setVideos] = useState([]);

  // Dialogs
  const [uploadVideosDlg, setUploadVideosDlg] = useState(false);

  // LocalData
  const localData = JSON.parse(localStorage.getItem("adminLoginInfo"));

  //   Store
  const { managedContentSettings } = useSelector(
    (state) => state.managedContentSettings
  );

  const handleSaveSiteSEO = () => {
    setLoading(true);
    // handle Video
    let arr = [...videos];
    videos.forEach((x, i) => {
      if (typeof x.file !== "undefined") {
        if (x.externalLink) {
          videos[i].src = x.file;
        } else {
          let data = new FormData();
          const ext = x.file.name.split(".");
          const filename =
            Date.now() + "-" + i + "how-to-order-vid." + ext.slice(-1);
          data.append("name", filename);
          data.append("logoImg", x.file);
          arr[i].src = filename;
          dispatch(uploadLogo(data));
        }
      }
    });
    dispatch(
      saveManagedContentSettings({
        langId,
        howToOrderSteps,
        changedSection: "How To Order",
        creatorId: localData._id,
        howToOrderVideos: [...arr],
      })
    );
    setTimeout(() => {
      setLoading(false);
      dispatch(getNotification());
      dispatch(getManagedContentSettings());
    }, 1000);
  };

  const handleAddTab = () => {
    let arr = [...howToOrderSteps];
    arr.push("");
    setHowToOrderSteps([...arr]);
  };

  const handleRemoveTab = (i) => {
    let arr = [...howToOrderSteps];
    arr = arr.filter((a, index) => i !== index);

    setHowToOrderSteps([...arr]);
  };

  const handleUploadVideosClose = () => {
    setUploadVideosDlg(false);
  };

  useEffect(() => {
    if (!(managedContentSettings && managedContentSettings.length > 0))
      dispatch(getManagedContentSettings());
  }, []);

  // Set Settings
  useEffect(() => {
    if (managedContentSettings && managedContentSettings.length > 0) {
      const M = managedContentSettings.find((m) => m.langId === langId);
      if (M && M.howToOrderSteps && M.howToOrderSteps.length > 0) {
        setHowToOrderSteps(M.howToOrderSteps);
      } else {
        setHowToOrderSteps([""]);
      }
      if (M && M.howToOrderVideos && M.howToOrderVideos.length > 0) {
        setVideos(M.howToOrderVideos);
      } else {
        setVideos([]);
      }
    }
  }, [langId, managedContentSettings]);

  return (
    <>
      <Accordion
        expanded={expanded === "how-to-order-settings"}
        onChange={handleChange("how-to-order-settings")}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-label="Expand"
          aria-controls="Head-Title-content"
          id="how-to-order-settings"
        >
          <Typography>{translate0("How To Order")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <LangNTitleBar
            lang={lang}
            setLang={setLang}
            langId={langId}
            setLangId={setLangId}
            title={translate0("How To Order")}
            infoImg="tut-how-to-order.png"
          />

          {/* Upload Video Start */}
          {!smUp ? (
            <Grid
              container
              spacing={0}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item sx={{ flex: 1 }}>
                <Button
                  fullWidth
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={() => setUploadVideosDlg(true)}
                >
                  {translate0("Upload How To Order Videos")}
                </Button>
              </Grid>
              <Grid item>
                <InfoTut img="tut-how-to-order-video.png" />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={1} justifyContent="center">
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    border: "1px solid lightblue",
                    p: 1,
                    m: 1,
                    borderRadius: ".25rem",
                  }}
                >
                  <Grid
                    container
                    spacing={0}
                    sx={{ mt: 1 }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item>
                      <Button
                        fullWidth
                        variant="outlined"
                        size="small"
                        color="primary"
                        sx={{ pointerEvents: "none" }}
                      >
                        {translate0("Upload How To Order Videos")}
                      </Button>
                    </Grid>
                    <Grid item>
                      <InfoTut img="tut-how-to-order-video.png" />
                    </Grid>
                  </Grid>
                  <MedicineVideoUpload
                    videos={videos}
                    setVideos={setVideos}
                    path="misc/"
                  />
                </Box>
              </Grid>
            </Grid>
          )}
          {uploadVideosDlg && (
            <Dialog open={uploadVideosDlg} fullScreen>
              <AppBar sx={{ position: "relative" }}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleUploadVideosClose}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography
                    sx={{ ml: 2, flex: 1 }}
                    variant="h6"
                    component="div"
                  >
                    {translate0("Upload How To Order Videos")}
                  </Typography>
                </Toolbar>
              </AppBar>
              <DialogContent>
                <MedicineVideoUpload
                  videos={videos}
                  setVideos={setVideos}
                  path="misc/"
                />
              </DialogContent>
            </Dialog>
          )}
          {/* Upload Video End */}
          {howToOrderSteps.length > 0 &&
            howToOrderSteps.map((x, i) => (
              // <Box
              //   key={i}
              //   sx={{
              //     border: "1px solid lightblue",
              //     p: 1,
              //     m: 1,
              //     borderRadius: ".25rem",
              //   }}
              // >
              <Grid container spacing={0} alignItems="center" key={i}>
                <Grid item sx={{ flex: 1 }}>
                  <TextField
                    label={`${translate0("Write Step")} ${i + 1}`}
                    value={x}
                    onChange={(e) => {
                      let arr = [...howToOrderSteps];
                      arr[i] = e.target.value;
                      setHowToOrderSteps(arr);
                    }}
                    size="small"
                    fullWidth
                    variant="standard"
                  />
                </Grid>
                <Grid item>
                  {howToOrderSteps.length > 1 && (
                    <Tooltip arrow placement="top" title={translate0("Delete")}>
                      <IconButton
                        size="small"
                        sx={{ mt: 2 }}
                        onClick={() => handleRemoveTab(i)}
                      >
                        <CancelIcon color="error" />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
              // </Box>
            ))}
          <Button
            fullWidth
            variant="contained"
            size="small"
            color="primary"
            sx={{ my: 1 }}
            onClick={handleAddTab}
          >
            {translate0("Add Tab")}
          </Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            fullWidth
            size="small"
            color="success"
            onClick={handleSaveSiteSEO}
          >
            {translate0("Save")}
          </LoadingButton>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
