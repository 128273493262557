import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getSettings,
  saveSiteSettings,
  updateSubEmailTemplate,
} from "../../../reduxToolkit/features/adminPanel/webSettings";

// Icons
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

// Ck Editor
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import {
  createNoti,
  uploadTempPic,
} from "../../../reduxToolkit/features/adminPanel/misc";
import { PF } from "../../../config";

export default function SubscriptionEmailTemplate({
  dialogOpen,
  setDialogOpen,
  buffer,
  setEditBuffer,
  translate1,
  translate2,
}) {
  const dispatch = useDispatch();
  const [showVar, setShowVar] = useState(false);
  //   Dialog
  const [loadingTemplateDlg, setLoadingTemplateDlg] = useState(false);
  //   const [addTemplateDlg, setAddTemplateDlg] = useState(false);
  const [newTemplateDisable, setNewTemplateDisable] = useState(false);
  const [newTemplate, setNewTemplate] = useState({
    title: "",
    subject: "",
    body: "",
  });

  // Local Data
  const localAdminInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));

  //   Functions
  const handleBody = (e, editor) => {
    let object = { ...newTemplate };
    object.body = editor.getData();
    setNewTemplate(object);
  };
  const handleAddTemplateClose = () => {
    setDialogOpen(false);
    setNewTemplate({
      title: "",
      subject: "",
      body: "",
    });
  };

  const handleAddTemplate = () => {
    let obj = {
      newSubscriptionEmailTemplate: newTemplate,
    };
    if (localAdminInfo._id) obj.creatorId = localAdminInfo._id;
    dispatch(saveSiteSettings(obj));
    setLoadingTemplateDlg(true);
    setTimeout(() => {
      setLoadingTemplateDlg(false);
      handleAddTemplateClose();
      dispatch(getSettings());
    }, 1000);
  };

  const handleUpdateTemplate = () => {
    let obj = {
      newSubscriptionEmailTemplate: newTemplate,
    };
    if (localAdminInfo._id) obj.creatorId = localAdminInfo._id;
    if (buffer && buffer._id) obj.id = buffer._id;
    dispatch(updateSubEmailTemplate(obj));
    setLoadingTemplateDlg(true);
    setTimeout(() => {
      setLoadingTemplateDlg(false);
      handleAddTemplateClose();
      setEditBuffer({});
      dispatch(getSettings());
    }, 1000);
  };

  useEffect(() => {
    let errCount = 0;
    if (newTemplate.title === "") errCount++;
    if (newTemplate.subject === "") errCount++;
    // if (newTemplate.body === "") errCount++;
    if (errCount > 0) setNewTemplateDisable(true);
    else setNewTemplateDisable(false);
  }, [newTemplate.body, newTemplate.subject, newTemplate.title]);

  useEffect(() => {
    if (buffer) {
      setNewTemplate({
        title: buffer.title,
        subject: buffer.subject,
        body: buffer.body,
      });
    }
  }, [buffer]);

  // ! CK Editor Image Upload

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          let tempName = Math.floor(Math.random() * (10000 - 0) + 0).toString();
          loader.file
            .then((file) => {
              const data = new FormData();
              if (file) {
                tempName = tempName + "." + file.name.split(".").pop();
                data.append("name", tempName);
                data.append("tempImg", file);
                dispatch(uploadTempPic(data));
                dispatch(
                  createNoti({
                    message: "Pls click on the image Confirm Image Upload",
                    severity: "warning",
                  })
                );
              }
            })
            .then(() => {
              resolve({
                default: `${PF}temp/${tempName}`,
              });
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }
  // ! CK Editor Image Upload

  return (
    <Dialog open={dialogOpen} fullWidth maxWidth={"md"}>
      <DialogTitle>
        {buffer
          ? translate1("Edit") + " " + translate1("Templates")
          : translate1("Add template")}{" "}
      </DialogTitle>
      <DialogContent>
        <Button
          variant={showVar ? "outlined" : "contained"}
          size="small"
          color="secondary"
          fullWidth
          sx={{ mb: 1, display: "flex", justifyContent: "space-between" }}
          endIcon={!showVar ? <ArrowDropDownCircleIcon /> : <ArrowDropUpIcon />}
          onClick={() => setShowVar(!showVar)}
        >
          {translate1("Variables")}
        </Button>
        <Box
          sx={{
            my: 1,
            p: 1,
            border: ".1rem solid pink",
            borderRadius: ".25rem",
          }}
        >
          <Typography
            variant="body2"
            color="initial"
          >{`Subscriber Name: {_subscriberName}`}</Typography>
          <Typography
            variant="body2"
            color="initial"
          >{`Subscriber Email: {_subscriberEmail}`}</Typography>
          <Typography
            variant="body2"
            color="initial"
          >{`Company Name: {_companyName}`}</Typography>
          <Typography
            variant="body2"
            color="initial"
          >{`Company Email: {_companyEmail}`}</Typography>
          <Typography
            variant="body2"
            color="initial"
          >{`Domain: {_domain}`}</Typography>
        </Box>

        <TextField
          label={translate1("Template Name")}
          value={newTemplate.title}
          onChange={(e) => {
            let obj = { ...newTemplate };
            obj.title = e.target.value;
            setNewTemplate(obj);
          }}
          sx={{ mb: 1 }}
          size="small"
          variant="standard"
          fullWidth
        />
        <TextField
          label={translate1("Email Subject")}
          value={newTemplate.subject}
          onChange={(e) => {
            let obj = { ...newTemplate };
            obj.subject = e.target.value;
            setNewTemplate(obj);
          }}
          sx={{ mb: 1 }}
          size="small"
          variant="standard"
          fullWidth
        />
        <Button
          variant="outlined"
          size="small"
          fullWidth
          sx={{ pointerEvents: "none", mt: 1, mb: 0.5 }}
        >
          {translate1("Body")}
        </Button>

        <CKEditor
          editor={Editor}
          data={newTemplate.body}
          config={{
            extraPlugins: [uploadPlugin],
          }}
          onBlur={(e, editor) => handleBody(e, editor)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAddTemplateClose} color="primary">
          {translate1("Close")}
        </Button>
        <LoadingButton
          loading={loadingTemplateDlg}
          onClick={buffer ? handleUpdateTemplate : handleAddTemplate}
          color={buffer ? "warning" : "success"}
          disabled={newTemplateDisable}
        >
          {buffer ? translate1("Update") : translate1("Save")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
