import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../config";

export const getNotification = createAsyncThunk(
  "notification/get",
  async () => {
    const res = await axiosInstance.get("/api/notification/");
    return res.data;
  }
);
// TODO makes Site slow for some reason
// export const getChangesMade = createAsyncThunk(
//   "notification/changes-made",
//   async (id) => {
//     const res = await axiosInstance.get(`/api/notification/changes-made/${id}`);
//     return res.data;
//   }
// );

export const readSingleNotification = createAsyncThunk(
  "notification/singleRead",
  async (data) => {
    const { id, ...others } = data;
    const res = await axiosInstance.put(`/api/notification/read/${id}`, others);
    return res.data;
  }
);

export const deleteAllNotification = createAsyncThunk(
  "notification/deleteAll",
  async () => {
    const res = await axiosInstance.delete("api/notification/delete");
    return res.data;
  }
);

// initial State
const initialState = {
  pending: false,
  pendingPrimary: false,
  pendingChanges: false,
  notifications: [],
  snackNoti: {},
  // changesMade: [],
  error: false,
  panel: "",
};

// Slice
export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setPanel(state, panel) {
      state.panel = panel.payload;
    },
  },
  extraReducers: {
    [getNotification.pending]: (state) => {
      state.pendingPrimary = true;
      state.error = false;
    },
    [getNotification.fulfilled]: (state, action) => {
      state.pendingPrimary = false;
      state.notifications = action.payload;
    },
    [getNotification.rejected]: (state) => {
      state.pendingPrimary = false;
      state.error = true;
    },
    // [getChangesMade.pending]: (state) => {
    //   state.pendingChanges = true;
    //   state.error = false;
    // },
    // [getChangesMade.fulfilled]: (state, action) => {
    //   state.pendingChanges = false;
    //   state.changesMade = action.payload;
    // },
    // [getChangesMade.rejected]: (state) => {
    //   state.pendingChanges = false;
    //   state.error = true;
    // },
    [readSingleNotification.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [readSingleNotification.fulfilled]: (state) => {
      state.pending = false;
      //   state.notifications = action.payload;
    },
    [readSingleNotification.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [deleteAllNotification.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [deleteAllNotification.fulfilled]: (state, action) => {
      state.pending = false;
      // state.notifications = action.payload;
      state.snackNoti["message"] = action.payload.message;
      state.snackNoti["severity"] = action.payload.severity;
    },
    [deleteAllNotification.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
  },
});

export const { setPanel } = notificationSlice.actions;
export default notificationSlice.reducer;
