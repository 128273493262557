export const adminMenuList = [
  {
    name: "Administration",
    icon: "https://img.icons8.com/external-filled-outline-wichaiwi/64/000000/external-administration-business-process-outsourcing-filled-outline-wichaiwi.png",
    link: "administration",
  },
  {
    name: "Customer",
    icon: "https://img.icons8.com/external-flaticons-flat-flat-icons/64/000000/external-customer-internet-marketing-service-flaticons-flat-flat-icons.png",
    link: "customer",
  },
  {
    name: "Medicines",
    icon: "https://img.icons8.com/external-justicon-lineal-color-justicon/64/000000/external-medicine-hospital-and-medical-justicon-lineal-color-justicon.png",
    link: "medicines",
  },
  {
    name: "Review",
    icon: "https://img.icons8.com/external-xnimrodx-lineal-xnimrodx/64/000000/external-review-social-media-xnimrodx-lineal-xnimrodx.png",
    link: "review",
  },
  {
    name: "Orders",
    icon: "https://img.icons8.com/external-inipagistudio-mixed-inipagistudio/64/000000/external-order-digital-marketing-strategy-inipagistudio-mixed-inipagistudio.png",
    link: "orders",
  },
  {
    name: "Delivery Methods",
    icon: "https://img.icons8.com/color/48/000000/delivery--v1.png",
    link: "delivery",
  },
  {
    name: "Payment Methods",
    icon: "https://img.icons8.com/color/48/000000/visa.png",
    link: "payment",
  },
  {
    name: "Reports",
    icon: "https://img.icons8.com/external-flaticons-lineal-color-flat-icons/64/000000/external-reports-productivity-flaticons-lineal-color-flat-icons.png",
    link: "reports",
  },

  {
    name: "Blog",
    icon: "https://img.icons8.com/external-justicon-lineal-color-justicon/64/000000/external-blog-graphic-design-justicon-lineal-color-justicon.png",
    link: "blog",
  },
  {
    name: "Supplier",
    icon: "https://img.icons8.com/fluency/48/000000/supplier.png",
    link: "supplier",
  },
  {
    name: "Expanse Calculation",
    icon: "https://img.icons8.com/external-flaticons-lineal-color-flat-icons/64/000000/external-expenses-digital-nomad-flaticons-lineal-color-flat-icons.png",
    link: "expense-calc",
  },
  {
    name: "Website Settings",
    icon: "https://img.icons8.com/material-two-tone/24/000000/domain.png",
    link: "web-settings",
  },
];
