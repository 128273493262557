import React, { Fragment, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {
  Alert,
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAllCustomers } from "../../reduxToolkit/features/auth";

import { axiosInstance, PF } from "../../config";

// Icons
// import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import LaunchIcon from "@mui/icons-material/Launch";

// Media Query
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { getCreated, getExactTime } from "../../hooks/getCreatedData";
import LoadingBox from "../../components/misc/Loading";
import LatestCustomers from "./LatestCustomers";
import LatestOrders from "./LatestOrders";
import LatestProducts from "./LatestProducts";
import LatestChangesMade from "./LatestChangesMade";
import KnownIssues from "./KnownIssues";
import ChangeLog from "./ChangeLog";
import { ExpandMore } from "@mui/icons-material";

export default function AdminHome() {
  const dispatch = useDispatch();

  // Media Query
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  const [loadingMain, setLoadingMain] = useState(false);

  const [latestCustomerList, setLatestCustomerList] = useState([]);

  const [adminUrl, setAdminUrl] = useState("");

  // Store
  const { customers } = useSelector((state) => state.auth);

  // Functions
  const getAdminUrl = async () => {
    const res = await axiosInstance.get("/api/adminUrl");
    setAdminUrl(res.data.url);
  };

  // UseEffect
  useEffect(() => {
    getAdminUrl();
    setLoadingMain(true);
    if (!(customers && customers.length > 0)) dispatch(getAllCustomers());
    setTimeout(() => {
      setLoadingMain(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (customers && customers.length > 0) {
      setLatestCustomerList(customers.slice(0, mdUp ? 10 : 5));
    }
  }, [customers]);

  //! Translate
  // Store
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate

  return (
    <Fragment>
      {loadingMain ? (
        <LoadingBox w={"100%"} h={"90vh"} />
      ) : (
        <Grid
          container
          spacing={1}
          sx={{
            maxWidth: !smUp ? "75vw" : !mdUp ? "85vw" : undefined,
          }}
        >
          <Grid item xs={12} sm={6} md={6} xl={6}>
            <Card>
              <CardContent>
                <Typography
                  variant="subtitle1"
                  sx={{ textDecoration: "underline" }}
                  color="initial"
                >
                  {translate0("Latest Users")}
                </Typography>
                {latestCustomerList.length < 1 ? (
                  <Box>
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{
                        minHeight: 500,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        pointerEvents: "none",
                      }}
                      color="warning"
                    >
                      {translate0("No Data Available")}
                    </Button>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      minHeight: 500,
                      width: "100%",
                    }}
                  >
                    <LatestCustomers
                      latestCustomerList={latestCustomerList}
                      adminUrl={adminUrl}
                    />
                  </Box>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={6} xl={6}>
            <Card>
              <CardContent>
                <Typography
                  variant="subtitle1"
                  sx={{ textDecoration: "underline" }}
                  color="initial"
                >
                  {translate0("Latest Orders")}
                </Typography>
                <LatestOrders />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={6} xl={6}>
            <Card>
              <CardContent>
                <Typography
                  variant="subtitle1"
                  sx={{ textDecoration: "underline" }}
                  color="initial"
                >
                  {translate0("Latest Products Added")}
                </Typography>
                <LatestProducts />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={6} xl={6}>
            <Card>
              <CardContent>
                <Typography
                  variant="subtitle1"
                  sx={{ textDecoration: "underline" }}
                  color="initial"
                >
                  {translate0("Latest Changes Made")}
                </Typography>
                <LatestChangesMade />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={6} xl={6}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-label="Expand"
                aria-controls="known-issue-content"
                id="known-issue-header"
              >
                <Typography>{translate0("Known Issue")}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <KnownIssues />
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} sm={12} md={6} xl={6}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-label="Expand"
                aria-controls="chagne-log-content"
                id="change-log-header"
              >
                <Typography>{translate0("Change Log")}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ChangeLog />
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
}
