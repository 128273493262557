import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  addPrescriptionImage,
  getAllPrescription,
  removePrescription,
  uploadPrescription,
} from "../../../reduxToolkit/features/frontend/checkout";
import { useState } from "react";
import Typography from "@mui/material/Typography";
import {
  Alert,
  AppBar,
  Box,
  Card,
  CardContent,
  IconButton,
  Slide,
  Toolbar,
  Grid,
  Input,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";

// Icons
import CloseIcon from "@mui/icons-material/Close";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { AddCircle, ExpandMore } from "@mui/icons-material";

import {
  getDosages,
  getMedicines,
} from "../../../reduxToolkit/features/adminPanel/medicine";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { PF } from "../../../config";
import ViewProductDetails from "../../adminPanel/medicines/components/ViewProductDetails";
import LoadingBox from "../../../components/misc/Loading";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ViewCustomerPrescription({
  prescriptionDlg,
  setPrescriptionDlg,
  prescriptionBuffer,
  lang,
}) {
  const dispatch = useDispatch();

  //   State
  const [loadingMain, setLoadingMain] = useState(false);
  const [presImgLoadingBuffer, setPresImgLoadingBuffer] = useState("");
  const [addPresImgLoading, setAddPresImgLoading] = useState(false);
  const [prescriptionList, setPrescriptionList] = useState([]);

  const [addPresBuffer, setAddPresBuffer] = useState({});

  // dialogs
  const [viewMedDlg, setViewMedDlg] = useState(false);
  const [viewMedBuffer, setViewMedBuffer] = useState({});

  //   Store
  const { allPrescriptions } = useSelector((state) => state.checkout);
  const { medicines, medVariants, dosages } = useSelector(
    (state) => state.medicine
  );

  // Functions
  const getMedInfo = (medId) => {
    let obj = {
      medImg: "",
      genericBrand: "",
    };
    if (medicines && medicines.length > 0) {
      const M = medicines.find((m) => m.lang === (lang || "en"));
      if (M && M.data) {
        const Med = M.data.find(
          (med) =>
            (lang !== "en" ? med.medicineDetectorId : med.fixedMedicineId) ===
            medId
        );
        if (Med) {
          obj.genericBrand = Med.genericBrand;
          if (Med.fixedMedicineId) obj.fixedMedicineId = Med.fixedMedicineId;
          if (Med.medicineDetectorId)
            obj.medicineDetectorId = Med.medicineDetectorId;
          const medImg = Med.image.find((i) => i.default);
          if (medImg) obj.medImg = medImg;
        }
      }
    }
    return obj;
  };

  const getDosageInfo = (dosageId, langCode) => {
    let dosageName = "";
    if (dosages && dosages.length > 0) {
      const dosageList = dosages.find((d) => d.lang === langCode);
      if (dosageList && dosageList.data) {
        const D = dosageList.data.find((d) =>
          langCode !== "en" ? d.dosageDetectorId : d.fixedDosageId === dosageId
        );
        if (D && D.dosageName) dosageName = D.dosageName;
      }
    }
    return dosageName;
  };

  const getVariantInfo = (varId) => {
    let obj = {
      dosage: "",
      strength: "",
      packTypes: "",
    };
    if (medVariants && medVariants.length > 0) {
      const V = medVariants.find(
        (v) =>
          (lang !== "en" ? v.variantDetectorId : v.fixedVariantId) === varId
      );
      if (V) {
        obj.dosage = getDosageInfo(V.dosageForm, lang);
        obj.strength = V.strength;
        obj.packTypes = V.packTypes;
      }
    }
    return obj;
  };

  const handleChangePrescription = (e, x, i, y, j) => {
    setPresImgLoadingBuffer(y.src);
    if (y.src) {
      const data = new FormData();
      const ext = e.target.files[0].name.split(".");
      const filename =
        Date.now() +
        "-" +
        x.medicineId.replace(/\s+/g, "") +
        "-prescription." +
        ext.slice(-1);
      data.append("name", filename);
      data.append("prescription", e.target.files[0]);
      dispatch(uploadPrescription(data));

      dispatch(
        removePrescription({
          medicineId: x.medicineId,
          customerId: prescriptionBuffer._id,
          variantId: x.variantId,
          prevImg: y.src,
          newImg: filename,
        })
      );
      setTimeout(() => {
        setPresImgLoadingBuffer("");
        dispatch(getAllPrescription());
      }, 1000);
    }
  };

  const handleRemovePrescriptionImg = (x, i, y, j) => {
    setPresImgLoadingBuffer(y.src);
    if (y.src) {
      dispatch(
        removePrescription({
          medicineId: x.medicineId,
          customerId: prescriptionBuffer._id,
          variantId: x.variantId,
          prevImg: y.src,
        })
      );
      setTimeout(() => {
        setPresImgLoadingBuffer("");
        dispatch(getAllPrescription());
      }, 1000);
    }
  };

  const handleAddPrescription = (e, x, i) => {
    setAddPresImgLoading(true);
    x = addPresBuffer;
    // if (prescribeArr[i].length > 0) {
    const data = new FormData();
    const ext = e.target.files[0].name.split(".");
    const filename =
      Date.now() +
      "-" +
      x.medicineId.replace(/\s+/g, "") +
      "-prescription." +
      ext.slice(-1);
    data.append("name", filename);
    data.append("prescription", e.target.files[0]);
    dispatch(uploadPrescription(data));
    dispatch(
      addPrescriptionImage({
        medicineId: x.medicineId,
        customerId: prescriptionBuffer._id,
        variantId: x.variantId,
        newImg: filename,
      })
    );
    setTimeout(() => {
      dispatch(getAllPrescription());
      setAddPresBuffer({});
      setAddPresImgLoading(false);
    }, 1000);
  };

  // useEffect
  useEffect(() => {
    setLoadingMain(true);
    if (!(medicines && medicines.length > 0)) dispatch(getMedicines());
    if (!(dosages && dosages.length > 0)) dispatch(getDosages());
    setTimeout(() => {
      setLoadingMain(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (prescriptionBuffer._id) {
      const P = allPrescriptions.filter(
        (p) => p.customerId === prescriptionBuffer._id
      );
      if (P.length > 0) {
        setPrescriptionList(P);
      }
    }
  }, [allPrescriptions, prescriptionBuffer._id]);

  // ! translate
  // Store
  const { langs } = useSelector((state) => state.misc);
  const [langSettings1, setLangSettings1] = useState([]);
  const [langSettings2, setLangSettings2] = useState([]);
  const title1 = "Manage Prescription";
  const title2 = "Misc Words";

  useEffect(() => {
    if (langs && langs.length > 0 && lang !== "") {
      const L = langs.find((l) => l.langCode === lang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      const A = arr.find((l) => l._title === title1);
      if (A) setLangSettings1(A.data);
      const B = arr.find((l) => l._title === title2);
      if (B) setLangSettings2(B.data);
    }
  }, [lang, langs]);

  const translate1 = (String) => translation(String, title1, 1);
  const translate2 = (String) => translation(String, title2, 2);

  const translation = (String, title, index) => {
    let translation = "";
    let settings = [];
    if (index === 1) {
      settings = langSettings1;
    } else if (index === 2) {
      settings = langSettings2;
    }
    const T = settings.find((ls) => ls.title === String);
    if (T && T.value) translation = T.value;
    else {
      let arr = [];
      if (langs && langs.length > 0 && lang !== "") {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.settings && LEn.settings.length > 0) arr = LEn.settings;
        const S = arr.find((l) => l._title === title);
        if (S) {
          const T = S.data.find((ls) => ls.title === String);
          if (T && T.value) translation = T.value;
        }
      }
    }
    return translation;
  };
  // ! translate

  return (
    <>
      <Dialog
        open={prescriptionDlg}
        onClose={() => setPrescriptionDlg(false)}
        fullWidth
        maxWidth={"sm"}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setPrescriptionDlg(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {translate1("Prescriptions")}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          {loadingMain ? (
            <LoadingBox w={"100%"} />
          ) : (
            <Grid container spacing={1}>
              {prescriptionList.length < 1 ? (
                <Grid item xs={12}>
                  <Alert severity="warning">
                    {translate2("No") + " " + translate1("Prescriptions")}
                  </Alert>
                </Grid>
              ) : (
                prescriptionList.map((x, i) => (
                  <Grid item key={i} xs={12} sm={6}>
                    <Card>
                      <CardContent>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setViewMedDlg(true);
                            setViewMedBuffer({
                              medId: x.medicineId,
                              varId: x.variantId,
                            });
                          }}
                        >
                          <LazyLoadImage
                            src={
                              getMedInfo(x.medicineId).medImg.src !== ""
                                ? PF +
                                  "adminPanel/medicines/img/" +
                                  getMedInfo(x.medicineId).medImg.src
                                : PF +
                                  "adminPanel/medicines/no-medicine-image.jpg"
                            }
                            alt={getMedInfo(x.medicineId).medImg.alt}
                            effect="blur"
                            placeholderSrc="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png"
                            style={{
                              objectFit: "cover",
                              height: 140,
                            }}
                          />
                        </Box>
                        <Typography gutterBottom variant="h6" component="div">
                          {getMedInfo(x.medicineId).genericBrand.toUpperCase()}
                        </Typography>
                        <Typography
                          gutterBottom
                          variant="body2"
                          component="div"
                        >
                          {" ("}
                          {getVariantInfo(x.variantId).dosage}
                          {" - "}
                          {getVariantInfo(x.variantId).strength}
                          {" - "}
                          {getVariantInfo(x.variantId).packTypes}
                          {") "}
                        </Typography>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-label="Expand"
                            aria-controls="-content"
                            id="-header"
                          >
                            <Typography>
                              {translate1("Prescriptions")}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box
                              sx={{
                                maxHeight: { xs: "60vh", sm: "30vh" },
                                overflowY: "auto",
                                overflowX: "hidden",
                              }}
                            >
                              {addPresImgLoading ? (
                                <LoadingBox w={"100%"} h={"30vh"} />
                              ) : (
                                x.imageArr.map((y, j) => (
                                  <Grid
                                    container
                                    spacing={0}
                                    alignItems="center"
                                    key={j}
                                  >
                                    <Grid item xs={8}>
                                      {presImgLoadingBuffer.includes(y.src) ? (
                                        <LoadingBox w={"100%"} />
                                      ) : (
                                        <Box
                                          sx={{
                                            display: "flex",
                                            //   justifyContent: "center",
                                            alignItems: "center",
                                            m: 1,
                                          }}
                                        >
                                          <LazyLoadImage
                                            src={
                                              y.src && y.src !== ""
                                                ? PF +
                                                  "adminPanel/prescription/" +
                                                  y.src
                                                : URL.createObjectURL(y)
                                            }
                                            alt={`prescription-image-${i}-[${j}]`}
                                            effect="blur"
                                            placeholderSrc="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png"
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                              objectFit: "contain",
                                            }}
                                          />
                                        </Box>
                                      )}
                                    </Grid>
                                    <Grid item xs={4}>
                                      <Grid
                                        container
                                        spacing={0}
                                        flexDirection="column"
                                      >
                                        <Grid item>
                                          <label
                                            htmlFor={`change-prescription-${j}`}
                                          >
                                            <Input
                                              accept="image/*"
                                              id={`change-prescription-${j}`}
                                              inputProps={{ multiple: false }}
                                              type="file"
                                              sx={{ display: "none" }}
                                              onChange={(e) =>
                                                handleChangePrescription(
                                                  e,
                                                  x,
                                                  0,
                                                  y,
                                                  j
                                                )
                                              }
                                            />
                                            <Tooltip
                                              arrow
                                              placement="left"
                                              title={translate1("Change")}
                                            >
                                              <IconButton
                                                size="medium"
                                                aria-label={`change-prescription-${i}`}
                                                component="span"
                                              >
                                                <ChangeCircleIcon color="warning" />
                                              </IconButton>
                                            </Tooltip>
                                          </label>
                                        </Grid>
                                        <Grid item>
                                          <Tooltip
                                            arrow
                                            placement="left"
                                            title={translate1("Remove")}
                                          >
                                            <IconButton
                                              size="medium"
                                              onClick={() =>
                                                handleRemovePrescriptionImg(
                                                  x,
                                                  0,
                                                  y,
                                                  j
                                                )
                                              }
                                            >
                                              <DeleteIcon color="error" />
                                            </IconButton>
                                          </Tooltip>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                ))
                              )}

                              <Box sx={{ mb: 1 }}>
                                <label htmlFor={`add-new-prescription`}>
                                  <Input
                                    accept="image/*"
                                    id={`add-new-prescription`}
                                    inputProps={{ multiple: false }}
                                    type="file"
                                    sx={{ display: "none" }}
                                    onChange={(e) =>
                                      handleAddPrescription(e, x, 0)
                                    }
                                  />
                                  <Button
                                    variant="contained"
                                    size="small"
                                    color="success"
                                    fullWidth
                                    sx={{
                                      width: "90%",
                                    }}
                                    startIcon={<AddCircle />}
                                    aria-label={`add-new-prescription`}
                                    component="span"
                                    onClick={() => setAddPresBuffer(x)}
                                  >
                                    {translate1("Add")}
                                  </Button>
                                </label>
                              </Box>
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                      </CardContent>
                    </Card>
                  </Grid>
                ))
              )}
            </Grid>
          )}
        </DialogContent>
      </Dialog>
      {viewMedDlg && (
        <Dialog
          open={viewMedDlg}
          onClose={() => {
            setViewMedDlg(false);
            setViewMedBuffer({});
          }}
          TransitionComponent={Transition}
          keepMounted
          fullWidth
          maxWidth={"md"}
        >
          <DialogContent>
            <ViewProductDetails
              medId={viewMedBuffer.medId}
              varId={viewMedBuffer.varId}
              lang={lang}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
