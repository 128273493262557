import React from "react";
import { Outlet } from "react-router-dom";
import SearchBar from "../components/appBar/SearchBar";
import TopBar from "../components/appBar/TopBar";
import Footer from "../components/footer/Footer";
import EmailBot from "../components/misc/EmailBot";
import GoToTopButton from "../components/misc/GoToTopButton";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuSection from "../components/appBar/MenuSection";
import MobileTopBar from "../components/MobileTopBar";
import ReviewBar from "../components/appBar/ReviewBar";

export default function FrontEndLayout({
  policyOpen,
  setPolicyOpen,
  policyName,
  setPolicyName,
}) {
  // Responsive
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <>
      {smUp ? (
        <>
          <TopBar />
          <SearchBar />
          {/* <ReviewBar /> */}
          {/* {!smUp && <MenuSection />} */}
          <GoToTopButton />
          <EmailBot />
          <Outlet />
          <Footer
            policyOpen={policyOpen}
            setPolicyOpen={setPolicyOpen}
            policyName={policyName}
            setPolicyName={setPolicyName}
          />
        </>
      ) : (
        <>
          <MobileTopBar />
          <MenuSection />
          {/* <ReviewBar /> */}
          <GoToTopButton />
          <EmailBot />
          <Outlet />
          <Footer
            policyOpen={policyOpen}
            setPolicyOpen={setPolicyOpen}
            policyName={policyName}
            setPolicyName={setPolicyName}
          />
        </>
      )}
    </>
  );
}
