import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  Rating,
  Typography,
  Grid,
  Tooltip,
  IconButton,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CardMedia,
  Divider,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Slide,
  AppBar,
  Toolbar,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useDispatch, useSelector } from "react-redux";
import LoadingBox from "../../../../components/misc/Loading";
import { PF } from "../../../../config";
import { getMedicines } from "../../../../reduxToolkit/features/adminPanel/medicine";
import { getAllCustomers } from "../../../../reduxToolkit/features/auth";
import { getReviews } from "../../../../reduxToolkit/features/customerOnProduct";
// Media Query
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// Icons
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";

import { getCreated, getExactTime } from "../../../../hooks/getCreatedData";
import {
  approveReview,
  deleteReview,
  disApproveReview,
  updateReview,
} from "../../../../reduxToolkit/features/adminPanel/misc";
import { getNotification } from "../../../../reduxToolkit/features/adminPanel/notification";
import LoadingButton from "@mui/lab/LoadingButton";
import ViewProductDetails from "../../medicines/components/ViewProductDetails";
import ReviewManualSubmission from "../../../../components/misc/ReviewManualSubmission";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ManageReview({ reviewArr }) {
  const dispatch = useDispatch();

  // Media Query
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  // useState
  const [loadingMain, setLoadingMain] = useState(false);
  const [reviewList, setReviewList] = useState([]);
  const [filteredReviewList, setFilteredReviewList] = useState([]);
  const [lang, setLang] = useState("");

  // Dialog
  const [viewMedDlg, setViewMedDlg] = useState(false);
  const [viewMedBuffer, setViewMedBuffer] = useState({});

  const [dltDlg, setDltDlg] = useState(false);
  const [dltBuffer, setDltBuffer] = useState({});

  const [editBuffer, setEditBuffer] = useState({});
  const [editReview, setEditReview] = useState("");
  const [editRating, setEditRating] = useState(0);

  const [reviewDlg, setReviewDlg] = useState(false);

  const [filterPointer, setFilterPointer] = useState(0);

  // local Store
  const localAdminData = JSON.parse(localStorage.getItem("adminLoginInfo"));
  const localLangData = JSON.parse(localStorage.getItem("lang"));

  // ? Store
  const { reviews } = useSelector((state) => state.customerOnProduct);
  const { customers } = useSelector((state) => state.auth);
  const { medicines } = useSelector((state) => state.medicine);
  const { activeLang } = useSelector((state) => state.misc);

  //   ? FUnction
  const handleCloseViewMed = () => {
    setViewMedBuffer({});
    setViewMedDlg(false);
  };

  const getMedInfo = (medId, language) => {
    const M = medicines.find((m) => m.lang === language);
    if (M && M.data) {
      const medInfo = M.data.find(
        (m) =>
          (lang !== "en" ? m.medicineDetectorId : m.fixedMedicineId) === medId
      );
      if (medInfo) return medInfo;
    }
    return {};
  };

  const getCustomerInfo = (customerId) => {
    const C = customers.find((c) => c._id === customerId);
    if (C) return C;
    return {};
  };

  const getComplainScope = (x) => {
    let str = "N/A";
    let arr = [];
    if (x.shortComings && x.shortComings.length > 0) {
      x.shortComings.forEach((x) => {
        arr.push(x);
      });
    }
    if (arr.length) str = arr.join(", ");
    return str;
  };

  const handleEdit = (obj) => {
    setEditBuffer(obj);
    setEditReview(obj.review);
    setEditRating(obj.rating);
  };

  const handleUpdate = () => {
    setLoadingMain(true);
    const obj = {
      id: editBuffer._id,
      creatorId: localAdminData._id,
      medId: editBuffer.medicineId,
      review: editReview,
      rating: editRating,
    };
    dispatch(updateReview(obj));
    setTimeout(() => {
      setEditBuffer({});
      setEditReview("");
      setEditRating("");
      dispatch(getReviews());
      dispatch(getNotification());
    }, 1000);
  };

  const handleApproval = (o) => {
    const obj = {
      id: o._id,
      creatorId: localAdminData._id,
      medId: o.medicineId,
      langId: localLangData._id,
    };
    setLoadingMain(true);
    o.approval ? dispatch(disApproveReview(obj)) : dispatch(approveReview(obj));

    setTimeout(() => {
      dispatch(getReviews());
      dispatch(getNotification());
    }, 1000);
  };

  const handleRemove = (o) => {
    setLoadingMain(true);
    const obj = {
      id: o._id,
      creatorId: localAdminData._id,
      medId: o.medicineId,
    };
    dispatch(deleteReview(obj));
    setTimeout(() => {
      setDltDlg(false);
      setDltBuffer({});
      dispatch(getReviews());
      dispatch(getNotification());
    }, 1000);
  };

  // ? useEffect
  useEffect(() => {
    if (!(medicines && medicines.length > 0)) dispatch(getMedicines());
    if (!(customers && customers.length > 0)) dispatch(getAllCustomers());
    if (!(reviews && reviews.length > 0)) dispatch(getReviews());
  }, []);

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setLang(activeLang.langCode);
    } else localLangData && setLang(localLangData.langCode);
  }, [activeLang]);

  useEffect(() => {
    if (
      reviews &&
      reviews.length > 0 &&
      customers &&
      customers.length &&
      medicines &&
      medicines.length
    ) {
      setLoadingMain(true);
      let arr = [];
      let R = [];
      if (reviewArr) {
        R = [...reviewArr];
      } else R = reviews;
      R.length > 0 &&
        R.forEach((x, i) => {
          let obj = { ...x };
          // Medicine portion
          if (x.medicineId !== "") {
            const m = getMedInfo(x.medicineId, lang !== "" ? lang : "en");
            if (m && m.genericBrand) {
              obj.medInfo = m;
              obj.medName = m.genericBrand;
            }
            if (m && m.image && m.image.length > 0) {
              obj.medImg = m.image.find((x) => x.default) || "";
            }
          }
          // Customer portion
          const c = getCustomerInfo(x.customerId);
          if (c && c.email) {
            obj.customerEmail = c.email;
            obj.customerPhone = c.phone;
          }

          // manual review portion
          if (x.manualReview) {
            obj.manualReview = x.manualReview;
            if (x.reviewImg) obj.reviewImg = x.reviewImg;
          }
          arr.push(obj);
        });
      setReviewList(arr.reverse());
      setTimeout(() => {
        setLoadingMain(false);
      }, 1000);
    }
  }, [customers, medicines, reviews, lang]);

  useEffect(() => {
    const getGoodList = () => reviewList.filter((r) => r.rating > 2);
    const getCriticalList = () => reviewList.filter((r) => r.rating < 2.5);
    const getApprovedList = () => reviewList.filter((r) => r.approval);
    const getDisapprovedList = () => reviewList.filter((r) => !r.approval);

    const arr = [
      {
        pointer: 0,
        list: [...reviewList],
      },
      {
        pointer: 1,
        list: [...getGoodList()],
      },
      {
        pointer: 2,
        list: [...getCriticalList()],
      },
      {
        pointer: 3,
        list: [...getApprovedList()],
      },
      {
        pointer: 4,
        list: [...getDisapprovedList()],
      },
    ];
    arr.forEach((x) => {
      if (filterPointer === x.pointer) setFilteredReviewList(x.list);
    });
  }, [filterPointer, reviewList]);

  // Fixed date
  const [showDate, setShowDate] = useState([]);

  const handleShowDate = (date) => {
    let arr = [...showDate];
    if (arr.includes(date)) {
      arr = arr.filter((a) => a !== date);
    } else {
      arr.push(date);
    }
    setShowDate(arr);
  };

  // ! translate
  const [siteLang, setSiteLang] = useState("");
  // Store
  const { langs } = useSelector((state) => state.misc);
  const [langSettings1, setLangSettings1] = useState([]);
  const [langSettings2, setLangSettings2] = useState([]);
  const title1 = "Manage Review";
  const title2 = "Misc Words";

  useEffect(() => {
    if (langs && langs.length > 0 && siteLang !== "") {
      const L = langs.find((l) => l.langCode === siteLang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      const A = arr.find((l) => l._title === title1);
      if (A) setLangSettings1(A.data);
      const B = arr.find((l) => l._title === title2);
      if (B) setLangSettings2(B.data);
    }
  }, [siteLang, langs]);

  const translate1 = (String) => translation(String, title1, 1);
  const translate2 = (String) => translation(String, title2, 2);

  const translation = (String, title, index) => {
    let translation = "";
    let settings = [];
    if (index === 1) {
      settings = langSettings1;
    } else if (index === 2) {
      settings = langSettings2;
    }
    const T = settings.find((ls) => ls.title === String);
    if (T && T.value) translation = T.value;
    else {
      let arr = [];
      if (langs && langs.length > 0 && siteLang !== "") {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.settings && LEn.settings.length > 0) arr = LEn.settings;
        const S = arr.find((l) => l._title === title);
        if (S) {
          const T = S.data.find((ls) => ls.title === String);
          if (T && T.value) translation = T.value;
        }
      }
    }
    return translation;
  };

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setSiteLang(activeLang.langCode);
    } else {
      if (localLangData) {
        setSiteLang(localLangData.langCode);
      }
    }
  }, [activeLang]);
  // ! translate

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{ my: 1, width: "100%" }}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12} sm={8}>
          <FormControl size="small" sx={{ width: "100%" }}>
            <InputLabel id="select-filter">{translate1("Filter")}</InputLabel>
            <Select
              labelId="select-filter"
              value={filterPointer}
              label="filter"
              onChange={(e) => setFilterPointer(e.target.value)}
            >
              <MenuItem value={0}>{translate2("All")}</MenuItem>
              <MenuItem value={1}>{translate1("Good")}</MenuItem>
              <MenuItem value={2}>{translate1("Critical")}</MenuItem>
              <MenuItem value={3}>{translate1("Approved")}</MenuItem>
              <MenuItem value={4}>{translate1("Not Approved")}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button
            variant="contained"
            fullWidth
            color="error"
            size="small"
            onClick={() => setReviewDlg(true)}
          >
            {translate1("Add Manual Review")}
          </Button>
          {reviewDlg && (
            <ReviewManualSubmission
              reviewDlg={reviewDlg}
              setReviewDlg={setReviewDlg}
            />
          )}
        </Grid>
      </Grid>
      {loadingMain ? (
        <LoadingBox w={"100%"} />
      ) : filteredReviewList.length < 1 ? (
        <Alert severity="warning">{translate1("No Data Available")}...</Alert>
      ) : (
        <>
          <Button
            variant="contained"
            size="small"
            fullWidth
            sx={{ pointerEvents: "none" }}
          >
            {translate1("Review")} ({filteredReviewList.length || 0})
          </Button>
          <Box
            sx={{
              maxHeight: { xs: "60vh", sm: "57vh" },
              overflowY: "auto",
              overflowX: "hidden",
              maxWidth: { xs: "100vw", sm: "100%" },
            }}
          >
            <Grid container spacing={1} flexDirection="column">
              {filteredReviewList.map((x, i) => (
                <Grid item key={i}>
                  <Card
                    sx={{
                      background: x.approval ? "lightgreen" : "pink",
                    }}
                  >
                    <CardContent>
                      {x.manualReview ? (
                        <Typography gutterBottom variant="text.secondary">
                          Custom Image:
                          <Tooltip arrow placement="top" title={x.medName}>
                            <IconButton
                              size="small"
                              onClick={() => {
                                setViewMedDlg(true);
                                setViewMedBuffer({
                                  medId: x.medicineId,
                                });
                              }}
                              sx={{
                                pointerEvents:
                                  !(x.medicineId && x.medicineId !== "") &&
                                  "none",
                              }}
                            >
                              <LazyLoadImage
                                effect="blur"
                                src={
                                  x.reviewImg && x.reviewImg !== ""
                                    ? PF + "adminPanel/review/" + x.reviewImg
                                    : x.medImg && x.medImg !== ""
                                    ? PF +
                                      "adminPanel/medicines/img/" +
                                      x.medImg.src
                                    : PF +
                                      "adminPanel/medicines/no-medicine-image.jpg"
                                }
                                alt="custom img"
                                style={{
                                  height: 30,
                                  objectFit: "cover",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Typography>
                      ) : (
                        <Typography gutterBottom variant="text.secondary">
                          {translate1("Medicine")}: {x.medName}
                          <Tooltip arrow placement="top" title={x.medName}>
                            <IconButton
                              size="small"
                              onClick={() => {
                                setViewMedDlg(true);
                                setViewMedBuffer({
                                  medId: x.medicineId,
                                });
                              }}
                            >
                              <LazyLoadImage
                                effect="blur"
                                src={
                                  x.medImg && x.medImg !== ""
                                    ? PF +
                                      "adminPanel/medicines/img/" +
                                      x.medImg.src
                                    : PF +
                                      "adminPanel/medicines/no-medicine-image.jpg"
                                }
                                alt={x.medImg && x.medImg.alt}
                                style={{
                                  height: 30,
                                  objectFit: "cover",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Typography>
                      )}
                      {editBuffer._id === x._id ? (
                        <TextField
                          label="Review"
                          value={editReview}
                          onChange={(e) => setEditReview(e.target.value)}
                          variant="standard"
                          size="small"
                          fullWidth
                          multiline
                          minRows={3}
                          autoFocus
                        />
                      ) : (
                        <Typography
                          gutterBottom
                          variant="subtitle2"
                          component="div"
                        >
                          {translate1("Review")}: {x.review}
                        </Typography>
                      )}

                      <Typography variant="body2" color="text.secondary">
                        {translate1("Email")}: {x.customerEmail || ""}{" "}
                        <sup
                          style={{
                            color: x.customerRegistered ? "green" : "red",
                          }}
                        >
                          {x.customerRegistered
                            ? "(REGISTERED)"
                            : "(NOT-REGISTERED)"}
                        </sup>
                      </Typography>

                      <Typography variant="body2" color="text.secondary">
                        {translate1("Phone")}: {x.customerPhone || ""}
                      </Typography>
                      {editBuffer._id === x._id ? (
                        <Rating
                          value={editRating}
                          precision={0.5}
                          sx={{ color: "black" }}
                          onChange={(e, newValue) => setEditRating(newValue)}
                        />
                      ) : (
                        <Rating
                          value={x.rating}
                          precision={0.5}
                          readOnly
                          sx={{ color: x.rating > 2.5 ? "orange" : "red" }}
                        />
                      )}
                      {
                        x.shortComings && (
                          // x.shortComings.map((x, i) => (
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                          >
                            {translate1("Complain Scope")}:{" "}
                            {getComplainScope(x) || ""}
                          </Typography>
                        )
                        // ))
                      }
                      {x.suggestion && (
                        <Typography variant="subtitle2" color="text.secondary">
                          {translate1("Suggestion")}: {x.suggestion || ""}
                        </Typography>
                      )}
                      <Typography
                        variant="body2"
                        color="navy"
                        sx={{ fontSize: ".8rem" }}
                      >
                        {translate1("Submitted")}:
                        <br /> {getCreated(x.createdAt) || ""}
                        <Tooltip
                          arrow
                          placement="top"
                          title={getExactTime(x.createdAt)}
                        >
                          <IconButton
                            size="small"
                            onClick={() => handleShowDate(x.createdAt)}
                          >
                            <InfoIcon color="info" />
                          </IconButton>
                        </Tooltip>
                        <br />
                        {showDate.includes(x.createdAt) &&
                          getExactTime(x.createdAt)}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="error"
                        sx={{ fontSize: ".8rem" }}
                      >
                        {translate1("Updated")}:
                        <br /> {getCreated(x.updatedAt) || ""}
                        <Tooltip
                          arrow
                          placement="top"
                          title={getExactTime(x.updatedAt)}
                        >
                          <IconButton
                            size="small"
                            onClick={() => handleShowDate(x.updatedAt)}
                          >
                            <InfoIcon color="info" />
                          </IconButton>
                        </Tooltip>
                        <br />
                        {showDate.includes(x.updatedAt) &&
                          getExactTime(x.updatedAt)}
                      </Typography>
                    </CardContent>
                    <Divider />
                    <CardActions>
                      <Grid
                        container
                        spacing={0}
                        justifyContent={!smUp ? "space-between" : "flex-end"}
                      >
                        {editBuffer._id === x._id ? (
                          <Grid item>
                            <LoadingButton
                              size="small"
                              variant="contained"
                              color="warning"
                              onClick={handleUpdate}
                              loading={loadingMain}
                            >
                              {translate1("Update")}
                            </LoadingButton>
                          </Grid>
                        ) : (
                          <>
                            <Grid item>
                              <Tooltip arrow placement="top" title="Edit">
                                <IconButton
                                  size="small"
                                  onClick={() => handleEdit(x)}
                                >
                                  <EditIcon color="primary" />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                            <Grid item>
                              <Tooltip arrow placement="top" title="Delete">
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    setDltDlg(true);
                                    setDltBuffer(x);
                                  }}
                                >
                                  <DeleteIcon color="error" />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                            <Grid item>
                              <Button
                                size="small"
                                variant="contained"
                                color={x.approval ? "error" : "success"}
                                onClick={() => handleApproval(x)}
                              >
                                {x.approval
                                  ? translate1("Disapprove")
                                  : translate1("Approve")}
                              </Button>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
            {viewMedDlg && (
              <Dialog
                open={viewMedDlg}
                fullScreen={!smUp ? true : false}
                fullWidth={!smUp ? false : true}
                maxWidth={"md"}
                TransitionComponent={Transition}
              >
                <AppBar sx={{ position: "relative" }}>
                  <Toolbar>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={handleCloseViewMed}
                      aria-label="close"
                    >
                      <CloseIcon />
                    </IconButton>
                    <Typography
                      sx={{ ml: 2, flex: 1 }}
                      variant="h6"
                      component="div"
                    >
                      {translate2("Product Details")}
                    </Typography>
                  </Toolbar>
                </AppBar>
                <DialogContent>
                  <ViewProductDetails medId={viewMedBuffer.medId} lang={lang} />
                </DialogContent>
              </Dialog>
            )}
          </Box>
        </>
      )}
      <Dialog
        open={dltDlg}
        onClose={() => {
          setDltDlg(false);
          setDltBuffer({});
        }}
      >
        <DialogTitle>
          {`${translate1("Do you really want to delete")}${
            dltBuffer.medName || "this review"
          }?`}
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setDltDlg(false);
              setDltBuffer({});
            }}
            color="primary"
          >
            {translate2("Close")}
          </Button>
          <LoadingButton
            onClick={() => handleRemove(dltBuffer)}
            color="error"
            loading={loadingMain}
          >
            {translate2("Delete")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
