export const fakeTypingCaptchaText = [
  { verificationCode: "456", text: "Luck never strikes" },
  { verificationCode: "725", text: "Honesty is great" },
  { verificationCode: "549", text: "Count your blessings" },
  { verificationCode: "734", text: "Against all odds" },
  { verificationCode: "425", text: "Time is money." },
  { verificationCode: "232", text: "Go for it" },
  { verificationCode: "344", text: "Got your back" },
  { verificationCode: "323", text: "Let us fly" },
  { verificationCode: "325", text: "Now or never" },
  { verificationCode: "322", text: "Let it go" },
  { verificationCode: "746", text: "Protect your health" },
  { verificationCode: "536", text: "Enjoy the moment" },
  { verificationCode: "624", text: "Change is good" },
  { verificationCode: "754", text: "Winners never quit" },
  { verificationCode: "767", text: "Success breeds success" },
  { verificationCode: "544", text: "Never look back" },
  { verificationCode: "444", text: "Feed your soul" },
  { verificationCode: "626", text: "Nobody is perfect" },
  { verificationCode: "424", text: "Keep it cool" },
  { verificationCode: "549", text: "Learn from yesterday" },
  { verificationCode: "949", text: "Celebrate your victories" },
  { verificationCode: "444", text: "This will pass" },
  { verificationCode: "535", text: "Speak the truth" },
  { verificationCode: "449", text: "Live your potential" },
  { verificationCode: "447", text: "Love your parents" },
  { verificationCode: "849", text: "Maintain your integrity" },
  { verificationCode: "744", text: "Nurture your best" },
];
