import React, { useEffect, useState } from "react";
import BreadcrumbsTemplate from "../components/misc/BreadcrumbsTemplate";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Card,
  CardContent,
  CardMedia,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Pagination,
  Stack,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from "@mui/material";
import ProductSmall from "../components/product/ProductSmall";
import { ExpandMore } from "@mui/icons-material";

export default function Category() {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  // Use state
  const [productViewStart, setProductViewStart] = useState(1);
  const [pageNum, setPageNum] = useState(1);
  const [subCat, setSubCat] = useState(0);

  // Use Effect
  useEffect(() => {
    if (pageNum > 1) setProductViewStart((pageNum - 1) * 8);
    else setProductViewStart(1);
  }, [pageNum]);

  // Functions
  const handlePageChange = async (event, value) => {
    await setPageNum(value);
    setTimeout(() => {
      scroll();
    }, 250);
  };
  const scroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleSubCategory = (i) => {
    setSubCat(i);
  };
  // Pagination
  let productCount = [...Array(64)];
  let totalPage = productCount.length / 8;
  return (
    <>
      <BreadcrumbsTemplate
        b2="our-medicines"
        l2="/our-medicines"
        b3="Category"
      />
      <Grid container spacing={1} justifyContent="center">
        {mdUp && (
          <Grid item lg={1}>
            <List
              dense
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  Other Sub Categories
                </ListSubheader>
              }
            >
              {[...Array(4)].map((x, i) => (
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() => handleSubCategory(i)}
                  selected={i === subCat ? true : false}
                  key={i}
                >
                  <ListItemText primary={`Cat ${i + 1} `} />
                </ListItemButton>
              ))}
            </List>
          </Grid>
        )}
        <Grid item xs={12} lg={10.5}>
          <Grid
            container
            spacing={2}
            flexDirection="column"
            alignItems="center"
          >
            {mdUp ? (
              <>
                <Grid item md={11.5}>
                  <Card>
                    <CardMedia
                      component="img"
                      alt="green iguana"
                      height="140"
                      image="https://media.istockphoto.com/photos/healthy-lifestyle-on-ketogenic-diet-eating-clean-keto-food-good-in-picture-id1311936090?s=2048x2048"
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        align="center"
                      >
                        Category Name
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        align="center"
                      >
                        Category Desc: Lorem ipsum, dolor sit amet consectetur
                        adipisicing elit. Labore, earum! Esse perferendis cumque
                        blanditiis quaerat inventore quos nesciunt officia est
                        voluptas? Molestias, minima dignissimos numquam illo
                        necessitatibus placeat harum fuga.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item md={11.5}>
                  <Card>
                    <CardMedia
                      component="img"
                      alt="green iguana"
                      height="140"
                      image="https://images.unsplash.com/photo-1576671081837-49000212a370?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1098&q=80"
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        align="center"
                      >
                        Sub Category Name
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        align="center"
                      >
                        Sub Category Desc: Lorem ipsum, dolor sit amet
                        consectetur adipisicing elit. Labore, earum! Esse
                        perferendis cumque blanditiis quaerat inventore quos
                        nesciunt officia est voluptas? Molestias, minima
                        dignissimos numquam illo necessitatibus placeat harum
                        fuga.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </>
            ) : (
              <>
                <Grid item sx={{ mt: 0.5 }} xs={11} sm={12}>
                  <Typography
                    variant="subtitle2"
                    color="initial"
                    align="center"
                  >
                    Category &rarr; Sub Category
                  </Typography>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={11}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-label="other-sub-categories"
                      aria-controls="-content"
                      id="-header"
                    >
                      <Typography variant="body2">
                        Other Sub Categories
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <List dense>
                        {[...Array(4)].map((x, i) => (
                          <ListItemButton
                            sx={{ pl: 4 }}
                            onClick={() => handleSubCategory(i)}
                            selected={i === subCat ? true : false}
                            key={i}
                          >
                            <ListItemText primary={`Cat ${i + 1} `} />
                          </ListItemButton>
                        ))}
                      </List>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </>
            )}
            <Grid item xs={12} lg={12}>
              <Grid
                container
                spacing={1}
                flexDirection={smUp ? "row" : "column"}
                alignItems="center"
                justifyContent="center"
              >
                {productCount
                  .slice(productViewStart, productViewStart + 8)
                  .map((x, i) => (
                    <Grid item key={i} xs={12} lg={3} sm={4}>
                      <ProductSmall i={i} productViewStart={productViewStart} />
                    </Grid>
                  ))}
                <Grid item xs={12} sm={6}>
                  <Stack spacing={2}>
                    <Pagination
                      count={totalPage}
                      color="primary"
                      onChange={handlePageChange}
                      size={smUp ? "medium" : "small"}
                      hideNextButton={smUp ? false : true}
                      hidePrevButton={smUp ? false : true}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
