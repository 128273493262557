import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMore from "@mui/icons-material/ExpandMore";
import TextField from "@mui/material/TextField";
import {
  AppBar,
  IconButton,
  Slide,
  Toolbar,
  Grid,
  Badge,
  Autocomplete,
  Box,
  createFilterOptions,
  Divider,
} from "@mui/material";

// Icons
import CloseIcon from "@mui/icons-material/Close";
// import { langData } from "../data/langObject";
import { useDispatch, useSelector } from "react-redux";
import {
  getLangs,
  manageLang,
} from "../../../../reduxToolkit/features/adminPanel/misc";
import { useEffect } from "react";
import { getNotification } from "../../../../reduxToolkit/features/adminPanel/notification";
import LoadingBox from "../../../../components/misc/Loading";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Limit options to show in autocomplete
const OPTIONS_LIMIT = 5;
const defaultFilterOptions = createFilterOptions();

// const filterOptions = (options, state) => {
//   return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
// };

export default function LangEditDlg({ open, setOpen, buffer, langId, lang }) {
  const dispatch = useDispatch();
  // const langSettingsInit = () => langData;
  const langData = [
    {
      _title: "Dashboard Top Bar",
      _section: "Dashboard",
      data: [
        { title: "MFW", value: "" },
        { title: "Dashboard", value: "" },
        { title: "Search", value: "" },
        { title: "Frontend", value: "" },
        { title: "All", value: "" },
        { title: "Unread", value: "" },
        { title: "Mark all read", value: "" },
        { title: "View profile", value: "" },
        { title: "Edit profile", value: "" },
        { title: "Changes Made", value: "" },
        { title: "Logout", value: "" },
      ],
    },

    {
      _title: "Manage Admin",
      _section: "Administration",
      data: [
        { title: "Administration", value: "" },
        { title: "Manage Admin", value: "" },
        { title: "Add/ Remove Admin, Select Role", value: "" },
        { title: "Add Admin", value: "" },
        { title: "Manage Role", value: "" },
        { title: "Search", value: "" },
        { title: "Sort", value: "" },
        { title: "Role", value: "" },
        { title: "Creation", value: "" },
        { title: "Changes Made", value: "" },
        { title: "Admins", value: "" },
        { title: "Created", value: "" },
      ],
    },
    {
      _title: "Advanced Settings",
      data: [
        { title: "Advanced Settings", value: "" },
        { title: "Drop/ Handle Entire Data", value: "" },
        { title: "Type the sentence", value: "" },
        { title: "Type Here", value: "" },
        { title: "Submit", value: "" },
        { title: "Change Admin Url", value: "" },
        { title: "Admin Url", value: "" },
        { title: "Hint", value: "" },
        { title: "Change", value: "" },
        { title: "Backup", value: "" },
        { title: "Backup Database", value: "" },
        { title: "Backup /Uploads Folder", value: "" },
        { title: "Cancel", value: "" },
        { title: "Delete All Notification", value: "" },
        { title: "Delete All Admins", value: "" },
        { title: "Delete All Roles", value: "" },
        { title: "Do You Want To Delete All Notification?", value: "" },
        { title: "Do You Want To Delete All Admins?", value: "" },
        { title: "Do You Want To Delete All Roles?", value: "" },
        { title: "Confirm", value: "" },
      ],
    },
    {
      _title: "Language Settings",
      data: [
        { title: "Language Settings", value: "" },
        { title: "Manage Language", value: "" },
        { title: "Add Language", value: "" },
        { title: "Search", value: "" },
        { title: "Languages", value: "" },
        { title: "Manage", value: "" },
        { title: "Delete", value: "" },
        { title: "Manage Language", value: "" },
        { title: "Save", value: "" },
      ],
    },
    {
      _title: "Customer List",
      _section: "Customer",
      data: [
        { title: "Customer List", value: "" },
        { title: "Manage Customer info ", value: "" },
        { title: "Filter", value: "" },
        { title: "All", value: "" },
        { title: "Blocked", value: "" },
        { title: "Unblocked", value: "" },
        { title: "Search", value: "" },
        { title: "Online", value: "" },
        { title: "Email", value: "" },
        { title: "Address", value: "" },
        { title: "Phone", value: "" },
        { title: "Copy", value: "" },
        { title: "Go to location in map", value: "" },
        { title: "Block", value: "" },
        { title: "Confirm", value: "" },
        { title: "Cancel", value: "" },
        { title: "Prescription", value: "" },
        { title: "Order History", value: "" },
        { title: "Cart", value: "" },
        { title: "Price Requests", value: "" },
        { title: "Additional Info", value: "" },
        { title: "Subscription Status", value: "" },
        { title: "Subscribed", value: "" },
        { title: "Unsubscribed", value: "" },
        { title: "Gender", value: "" },
        { title: "Male", value: "" },
        { title: "Female", value: "" },
        { title: "Client Info", value: "" },
        { title: "Ip", value: "" },
        { title: "Country", value: "" },
        { title: "Browser", value: "" },
        { title: "Social Media", value: "" },
        { title: "Login Information", value: "" },
        { title: "Last Login", value: "" },
        { title: "Last Log Out", value: "" },
        { title: "Current Login Status", value: "" },
        { title: "Online", value: "" },
        { title: "Offline", value: "" },
        { title: "Total Logged In Count", value: "" },
        { title: "Total Logged In Time", value: "" },
      ],
    },
    {
      _title: "Manage Prescription",
      data: [
        { title: "Prescriptions", value: "" },
        { title: "Change", value: "" },
        { title: "Remove", value: "" },
        { title: "Add", value: "" },
        { title: "Manage Prescription", value: "" },
        { title: "Search", value: "" },
      ],
    },
    {
      _title: "Manage Subscription",
      data: [
        { title: "Email Templates", value: "" },
        { title: "Add template", value: "" },
        { title: "Templates", value: "" },
        { title: "Variables", value: "" },
        { title: "Template Name", value: "" },
        { title: "Email Subject", value: "" },
        { title: "Body", value: "" },
        { title: "Close", value: "" },
        { title: "Save", value: "" },
        { title: "Edit", value: "" },
        { title: "Delete", value: "" },
        { title: "Update", value: "" },
        { title: "Edit Template", value: "" },
        { title: "Do you really want to delete", value: "" },
        { title: "Cancel", value: "" },
        { title: "Confirm", value: "" },
        { title: "Run Campaign", value: "" },
        { title: "Subscribers", value: "" },
        { title: "Add Subscriber", value: "" },
        { title: "Name", value: "" },
        { title: "Email", value: "" },
        { title: "Import ", value: "" },
        { title: "Excel File", value: "" },
        { title: "CSV File", value: "" },
        { title: "Send Email", value: "" },
        { title: "Start Campaign", value: "" },
        { title: "Campaign Name", value: "" },
        { title: "Keep empty to auto generate", value: "" },
        { title: "Recipient List", value: "" },
        { title: "Name ", value: "" },
        { title: "Email", value: "" },
        { title: "Campaign Management", value: "" },
        { title: "Campaigns", value: "" },
        { title: "Email List", value: "" },
        { title: "Rerun Campaign", value: "" },
      ],
    },
    {
      _title: "Cart",
      data: [
        { title: "Select Currency", value: "" },
        { title: "Update Currency", value: "" },
        { title: "Remove", value: "" },
      ],
    },
    {
      _title: "Price Request List",
      data: [
        { title: "Assign Price", value: "" },
        { title: "Cancel Request", value: "" },
        { title: "Modify Assigned Price", value: "" },
        { title: "Unassign Price", value: "" },
        { title: "Submitted From ", value: "" },
        { title: "Page", value: "" },
        { title: "Submitted", value: "" },
        { title: "View Currency", value: "" },
        { title: "Update Currency", value: "" },
        { title: "Selling Price", value: "" },
        { title: "Minimum Order Qty", value: "" },
        { title: "Discount", value: "" },
        { title: "Note", value: "" },
        { title: "Cancel", value: "" },
        { title: "Confirm", value: "" },
        { title: "Modify Price", value: "" },
        { title: "Reset to default", value: "" },
        { title: "USD", value: "" },
        { title: "Update", value: "" },
      ],
    },
    {
      _title: "Manage Medicine",
      _section: "Medicine",
      data: [
        { title: "Medicines", value: "" },
        { title: "Add Medicine", value: "" },
        { title: "Search", value: "" },
        { title: "Video", value: "" },
      ],
    },
    {
      _title: "Identification",
      data: [
        { title: "Identification", value: "" },
        { title: "Generic", value: "" },
        { title: "Generic Brand", value: "" },
        { title: "Category", value: "" },
        { title: "Sub Category", value: "" },
        { title: "Local Company", value: "" },
        { title: "Originator", value: "" },
      ],
    },
    {
      _title: "Variation",
      data: [
        { title: "Variations", value: "" },
        { title: "Dosage Form", value: "" },
        { title: "Strength", value: "" },
        { title: "Pack Types", value: "" },
        { title: "Stock", value: "" },
        { title: "Available", value: "" },
        { title: "Out of stock", value: "" },
        { title: "Update", value: "" },
      ],
    },
    {
      _title: "Price Info",
      data: [
        { title: "Price Info", value: "" },
        { title: "Price Information ", value: "" },
        { title: "Select Currency", value: "" },
        { title: "Update Currency", value: "" },
        { title: "Strength", value: "" },
        { title: "Pack Types", value: "" },
        { title: "Average Price of Originator", value: "" },
        { title: "Selling Price", value: "" },
        { title: "VIP Selling  Price", value: "" },
        { title: "Company Patient Selling Price Updated", value: "" },
        { title: "Company Patient Selling Price Old", value: "" },
        { title: "Company MRP", value: "" },
        { title: "Minimum Order Qty ", value: "" },
        { title: "Weight Per Unit", value: "" },
        { title: "gm", value: "" },
      ],
    },
    {
      _title: "SEO",
      data: [
        { title: "Meta Keyword", value: "" },
        { title: "Meta Title", value: "" },
        { title: "Meta Description", value: "" },
        { title: "Tags", value: "" },
      ],
    },
    {
      _title: "Actions",
      data: [
        { title: "Created", value: "" },
        { title: "Are you sure you want to delete this medicine?", value: "" },
      ],
    },

    {
      _title: "Additional Section",
      data: [
        { title: "Indication & Dosage", value: "" },
        { title: "Side Effects", value: "" },
        { title: "Prescribing Information", value: "" },
        { title: "Additional Information", value: "" },
        { title: "FAQ", value: "" },
        { title: "Suggestions", value: "" },
      ],
    },
    {
      _title: "Manage Generic Company",
      data: [
        { title: "Manage Generic Company", value: "" },
        { title: "Add/ Manage Generic Companies", value: "" },
        { title: "Add Company", value: "" },
        { title: "Search", value: "" },
        { title: "Companies", value: "" },
        { title: "Edit", value: "" },
        { title: "Delete", value: "" },
        { title: "Edit Company", value: "" },
      ],
    },
    {
      _title: "Manage Category",
      data: [
        { title: "Manage Category", value: "" },
        { title: "Add/ Manage Generic Categories", value: "" },
        { title: "Add Tags", value: "" },
        { title: "Add Category", value: "" },
        { title: "Search", value: "" },
        { title: "Categories", value: "" },
        { title: "Edit", value: "" },
        { title: "Delete", value: "" },
        { title: "Edit Category", value: "" },
        { title: "Created", value: "" },
        { title: "Created By", value: "" },
        { title: "Update", value: "" },
      ],
    },
    {
      _title: "Manage Dosage Form",
      data: [
        { title: "Manage Dosage Form", value: "" },
        { title: "Add/ Manage Dosages", value: "" },
        { title: "Add Tags", value: "" },
        { title: "Add Dosage", value: "" },
        { title: "Search", value: "" },
        { title: "Dosage Forms", value: "" },
        { title: "Edit", value: "" },
        { title: "Delete", value: "" },
        { title: "Edit Dosage", value: "" },
        { title: "Update", value: "" },
      ],
    },
    {
      _title: "Manage SEO Tags",
      data: [
        { title: "Manage SEO Tags", value: "" },
        { title: "Add/ Manage SEO Tags", value: "" },
        { title: "Add Tags", value: "" },
        { title: "Search", value: "" },
        { title: "Tags", value: "" },
        { title: "Edit", value: "" },
        { title: "Delete", value: "" },
        { title: "Edit Tag", value: "" },
        { title: "Update", value: "" },
      ],
    },
    {
      _title: "Advanced Med Settings",
      data: [
        { title: "Advanced Med Settings", value: "" },
        { title: "Manage Medicines", value: "" },
        { title: "Type the sentence", value: "" },
        { title: "Type Here", value: "" },
        { title: "Submit", value: "" },
        { title: "Unused Images", value: "" },
        { title: "Unused Videos", value: "" },
        { title: "uploaded Files", value: "" },
        { title: "Remove all unused images", value: "" },
        { title: "Remove all unused videos", value: "" },
        { title: "Remove all uploaded files", value: "" },
        { title: "Collective remove", value: "" },
        { title: "Delete all medicine info", value: "" },
        { title: "Remove junk", value: "" },
        { title: "Remove All Medicines", value: "" },
        { title: "Remove All Companies", value: "" },
        { title: "Remove All Categories", value: "" },
        { title: "Remove All Dosages", value: "" },
        { title: "Remove All Seo Tags", value: "" },
        { title: "Remove All Supplier", value: "" },
      ],
    },
    {
      _title: "Manage Brochure",
      data: [
        { title: "Manage Brochure", value: "" },
        { title: "Handle Brochure/PDF", value: "" },
      ],
    },

    {
      _title: "Suppliers",
      _section: "Suppliers",
      data: [
        { title: "Suppliers", value: "" },
        { title: "Add Suppliers", value: "" },
        { title: "Select Currency", value: "" },
        { title: "Update Currency", value: "" },
      ],
    },

    {
      _title: "Manage Review",
      _section: "Review",
      data: [
        { title: "Manage Review", value: "" },
        { title: "View/ Manage Review", value: "" },
        { title: "Filter", value: "" },
        { title: "Good", value: "" },
        { title: "Critical", value: "" },
        { title: "Approved", value: "" },
        { title: "Not Approved", value: "" },
        { title: "Add Manual Review", value: "" },
        { title: "Medicine", value: "" },
        { title: "Review", value: "" },
        { title: "Email", value: "" },
        { title: "Phone", value: "" },
        { title: "Complain Scope", value: "" },
        { title: "Submitted", value: "" },
        { title: "Updated", value: "" },
        { title: "Edit", value: "" },
        { title: "Delete", value: "" },
        { title: "Disapprove", value: "" },
        { title: "Approve", value: "" },
        { title: "Update", value: "" },
      ],
    },

    {
      _title: "Review Settings",
      data: [
        { title: "Review Settings", value: "" },
        { title: "Complain Scope", value: "" },
        { title: "Add Scope", value: "" },
        { title: "Scope Name", value: "" },
        { title: "Add", value: "" },
        { title: "Edit", value: "" },
        { title: "Remove", value: "" },
      ],
    },

    {
      _title: "Reviewer Info Request",
      data: [
        { title: "View Requests", value: "" },
        { title: "Request Rules", value: "" },
        { title: "Remove", value: "" },
        { title: "Send Email", value: "" },
        { title: "Rule", value: "" },
        { title: "Write Rule", value: "" },
        { title: "Edit Rule", value: "" },
        { title: "Delete Rule", value: "" },
      ],
    },

    {
      _title: "Manage Orders",
      _section: "Orders",
      data: [
        { title: "Manage Orders", value: "" },
        { title: "Edit Order", value: "" },
        { title: "Reset To Default", value: "" },
      ],
    },
    {
      _title: "Manage Delivery Method",
      _section: "Delivery Method",
      data: [
        { title: "Manage Delivery Method", value: "" },
        { title: "Add/ Manage Delivery Method", value: "" },
        { title: "Add Method", value: "" },
        { title: "Search by method", value: "" },
        { title: "Search by country", value: "" },
        { title: "Countries", value: "" },
        { title: "Price upto 100gm", value: "" },
        { title: "Price increment /100gm", value: "" },
        { title: "Summary", value: "" },
        { title: "Creation Info", value: "" },
        { title: "Created by", value: "" },
        { title: "Created", value: "" },
        { title: "Updated", value: "" },
        { title: "Edit", value: "" },
        { title: "Delete", value: "" },
      ],
    },

    {
      _title: "Manage Payment Method",
      _section: "Payment Method",
      data: [
        { title: "Manage Payment Method", value: "" },
        { title: "Add/ Manage Payment Method", value: "" },
        { title: "Add Method", value: "" },
        { title: "Search by method", value: "" },
        { title: "Search by country", value: "" },
        { title: "Countries", value: "" },
        { title: "Instructions", value: "" },
        { title: "Accounts", value: "" },
        { title: "Active", value: "" },
        { title: "Created by", value: "" },
        { title: "Created", value: "" },
        { title: "Updated", value: "" },
        { title: "Edit", value: "" },
        { title: "Delete", value: "" },
      ],
    },
    {
      _title: "Payment Settings",
      data: [
        { title: "Payment Settings", value: "" },
        { title: "Configure Payment Settings", value: "" },
        { title: "Payment Receipt Upload Instructions", value: "" },
        { title: "Instruction", value: "" },
        { title: "Save", value: "" },
      ],
    },

    {
      _title: "View Reports",
      _section: "Reports",
      data: [
        { title: "View Reports", value: "" },
        { title: "From", value: "" },
        { title: "To", value: "" },
        { title: "Order Report", value: "" },
        { title: "Price Inquiry Report", value: "" },
        { title: "Customer Report", value: "" },
        { title: "Review Report", value: "" },
        { title: "Medicine Popularity Report", value: "" },
      ],
    },

    {
      _title: "Manage Blog",
      _section: "Blog",
      data: [
        { title: "Manage Blog", value: "" },
        { title: "Create/ Manage Blog", value: "" },
        { title: "Add Blog", value: "" },
        { title: "Search", value: "" },
        { title: "Blog", value: "" },
        { title: "Author", value: "" },
        { title: "Published", value: "" },
        { title: "Updated", value: "" },
        { title: "Edit", value: "" },
        { title: "Remove", value: "" },
      ],
    },

    {
      _title: "Manage Expenses",
      _section: "Expenses",
      data: [
        { title: "Manage Expanse", value: "" },
        { title: "View/ Manage Expense", value: "" },
      ],
    },

    {
      _title: "Auth Settings",
      _section: "Web Settings",
      data: [
        { title: "Auth Settings", value: "" },
        { title: "Manage Auth Process", value: "" },
        { title: "Vertical Interval", value: "" },
        { title: "Un remember Login Time", value: "" },
        { title: "Maximum Block Request ", value: "" },
      ],
    },

    {
      _title: "Email Settings",
      data: [
        { title: "Email Settings", value: "" },
        { title: "Manage Email Templates & More", value: "" },
        { title: "Admin Name", value: "" },
        { title: "Admin Email", value: "" },
        { title: "Invoice Table Html", value: "" },
        { title: "Save", value: "" },
        { title: "Email Verification", value: "" },
        { title: "Registration Success", value: "" },
        { title: "Ask Price", value: "" },
        { title: "Price Assigned", value: "" },
        { title: "Assigned Price Updated", value: "" },
        { title: "Order Created", value: "" },
        { title: "Payment Account Assigned", value: "" },
        { title: "Payment Receipt Rejected", value: "" },
        { title: "Payment Receipt Uploaded", value: "" },
        { title: "Payment Confirmed & Shipping", value: "" },
        { title: "Shipping Complete", value: "" },
        { title: "Shipping Confirmed", value: "" },
        { title: "Review Submitted", value: "" },
        { title: "Review Approved", value: "" },
        { title: "Reviewer Info Request", value: "" },
        { title: "New Subscription", value: "" },
        { title: "Contact Us Form Submitted ", value: "" },
        { title: "Customer Unblock Request", value: "" },
        { title: "No", value: "" },
      ],
    },

    {
      _title: "Site Design",
      data: [
        { title: "Site Design", value: "" },
        { title: "Change Site Looks", value: "" },
        { title: "Set Color Theme", value: "" },
        { title: "Save", value: "" },
        { title: "Close", value: "" },
      ],
    },

    {
      _title: "Head Title",
      _section: "Manage Content",
      data: [
        { title: "Manage Content", value: "" },
        { title: "Modify Content", value: "" },
        { title: "Head Title", value: "" },
        { title: "Manage Head Title", value: "" },
        { title: "Head Title", value: "" },
        { title: "Save", value: "" },
      ],
    },

    {
      _title: "Site Identity",
      data: [
        { title: "Site identity", value: "" },
        { title: "Logo", value: "" },
        { title: "Recommended", value: "" },
        { title: "Site Name", value: "" },
        { title: "Save", value: "" },
      ],
    },

    {
      _title: "Site SEO",
      data: [
        { title: "Site SEO", value: "" },
        { title: "Home Page", value: "" },
        { title: "Blog Page", value: "" },
        { title: "Site Name", value: "" },
        { title: "Meta Desc", value: "" },
        { title: "Author Name for Twitter", value: "" },
        { title: "Save", value: "" },
      ],
    },

    {
      _title: "About Us",
      data: [
        { title: "About Us", value: "" },
        { title: "Upload About Us Videos", value: "" },
        { title: "Video", value: "" },
        { title: "Supports", value: "" },
        { title: "Url", value: "" },
        { title: "Remove All", value: "" },
        { title: "Make Default", value: "" },
        { title: "Edit Alt Tag", value: "" },
        { title: "Change", value: "" },
        { title: "Delete", value: "" },
        { title: "Add Video", value: "" },
        { title: "Remove Tab", value: "" },
        { title: "Tab Title", value: "" },
        { title: "Tab Details", value: "" },
        { title: "Add Tab", value: "" },
        { title: "Save", value: "" },
      ],
    },

    {
      _title: "Disclaimer",
      data: [
        { title: "Disclaimer", value: "" },
        { title: "Remove Tab", value: "" },
        { title: "Tab Title", value: "" },
        { title: "Tab Details", value: "" },
        { title: "Add Tab", value: "" },
        { title: "Save", value: "" },
      ],
    },

    {
      _title: "Terms & Conditions",
      data: [
        { title: "Terms & Conditions", value: "" },
        { title: "Remove Tab", value: "" },
        { title: "Tab Title", value: "" },
        { title: "Tab Details", value: "" },
        { title: "Add Tab", value: "" },
        { title: "Save", value: "" },
      ],
    },

    {
      _title: "Shipping Policy",
      data: [
        { title: "Shipping Policy", value: "" },
        { title: "Remove Tab", value: "" },
        { title: "Tab Title", value: "" },
        { title: "Tab Details", value: "" },
        { title: "Add Tab", value: "" },
        { title: "Save", value: "" },
      ],
    },

    {
      _title: "Review Policy",
      data: [
        { title: "Review Policy", value: "" },
        { title: "Remove Tab", value: "" },
        { title: "Tab Title", value: "" },
        { title: "Tab Details", value: "" },
        { title: "Add Tab", value: "" },
        { title: "Save", value: "" },
      ],
    },
    {
      _title: "Privacy Policy",
      data: [
        { title: "Privacy Policy", value: "" },
        { title: "Remove Tab", value: "" },
        { title: "Tab Title", value: "" },
        { title: "Tab Details", value: "" },
        { title: "Add Tab", value: "" },
        { title: "Save", value: "" },
      ],
    },

    {
      _title: "How To Order",
      data: [
        { title: "How To Order", value: "" },
        { title: "Upload How To Order Videos", value: "" },
        { title: "Video", value: "" },
        { title: "Supports", value: "" },
        { title: "Url", value: "" },
        { title: "Remove All", value: "" },
        { title: "Make Default", value: "" },
        { title: "Edit Alt Tag", value: "" },
        { title: "Change", value: "" },
        { title: "Delete", value: "" },
        { title: "Add Video", value: "" },
        { title: "Remove Step", value: "" },
        { title: "Write Step", value: "" },
        { title: "Add Step", value: "" },
        { title: "Save", value: "" },
      ],
    },

    {
      _title: "Social Media Page Link",
      _section: "Social Media",
      data: [
        { title: "Social Media Page Link", value: "" },
        { title: "Social Media", value: "" },
        { title: "Modify Social Media", value: "" },
        { title: "Facebook Page", value: "" },
        { title: "Twitter Page", value: "" },
        { title: "LinkedIn Page", value: "" },
        { title: "Telegram Page", value: "" },
        { title: "Instagram Page", value: "" },
        { title: "Add Social Media", value: "" },
        { title: "Icon Img Url", value: "" },
        { title: "Page Name", value: "" },
        { title: "Page Link", value: "" },
        { title: "Add", value: "" },
        { title: "Close", value: "" },
        { title: "Save", value: "" },
      ],
    },
    {
      _title: "Social Media Contact Medium",
      data: [
        { title: "Social Media Contact Medium", value: "" },
        { title: "WhatsApp", value: "" },
        { title: "Twitter", value: "" },
        { title: "Telegram", value: "" },
        { title: "Instagram", value: "" },
        { title: "Email", value: "" },
        { title: "Local Phone", value: "" },
        { title: "Add Social Contact", value: "" },
        { title: "Icon Img Url", value: "" },
        { title: "Platform Name", value: "" },
        { title: "Link", value: "" },
        { title: "Add", value: "" },
        { title: "Close", value: "" },
        { title: "Save", value: "" },
      ],
    },
    {
      _title: "Contact Info",
      _section: "Footer",
      data: [
        { title: "Contact Info", value: "" },
        { title: "Footer", value: "" },
        { title: "Manage Footer", value: "" },
        { title: "Address", value: "" },
        { title: "Map Link", value: "" },
        { title: "Phone", value: "" },
        { title: "Contact Email View", value: "" },
        { title: "Contact Email Link", value: "" },
        { title: "Save", value: "" },
      ],
    },

    {
      _title: "Footer Text",
      data: [
        { title: "Footer Text", value: "" },
        { title: "Save", value: "" },
      ],
    },
    {
      _title: "Chat Bot",
      _section: "Chat Bot",
      data: [
        { title: "Chat Bot", value: "" },
        { title: "Manage Chat Agents", value: "" },
        { title: "Add Agent", value: "" },
        { title: "Agents", value: "" },
        { title: "Edit", value: "" },
        { title: "Delete", value: "" },
        { title: "Sat", value: "" },
        { title: "Sun", value: "" },
        { title: "Mon", value: "" },
        { title: "Tue", value: "" },
        { title: "Wed", value: "" },
        { title: "Thu", value: "" },
        { title: "Fri", value: "" },
        { title: "Add Chat Agent", value: "" },
        { title: "Agent Name", value: "" },
        { title: "Language Code", value: "" },
        { title: "Icon Url", value: "" },
        { title: "Contact Link", value: "" },
        { title: "Add Contact Platform", value: "" },
        { title: "Add", value: "" },
        { title: "Cancel", value: "" },
      ],
    },
    {
      _title: "Backend Home",
      _section: "Backend Home",
      data: [
        { title: "Latest Users", value: "" },
        { title: "Latest Orders", value: "" },
        { title: "Latest Products Added", value: "" },
        { title: "Latest Changes Made", value: "" },
        { title: "Known Issue", value: "" },
        { title: "Change Log", value: "" },
      ],
    },

    {
      _title: "Add Language Dialog",
      _section: "Dialogs",
      data: [
        { title: "Add Language Dialog", value: "" },
        { title: "Language Code", value: "" },
        { title: "Language Name", value: "" },
        { title: "Create Language", value: "" },
        { title: "Close", value: "" },
      ],
    },
    {
      _title: "Add Admin Dialog",
      data: [
        { title: "Add Admin", value: "" },
        { title: "Recommended", value: "" },
        { title: "User Name", value: "" },
        { title: "Email", value: "" },
        { title: "Password", value: "" },
        { title: "Role", value: "" },
        { title: "Add Role", value: "" },
        { title: "Close", value: "" },
        { title: "Create", value: "" },
      ],
    },
    {
      _title: "Manage Role",
      data: [
        { title: "Role Management", value: "" },
        { title: "Manage Roles", value: "" },
        { title: "Create Role", value: "" },
        { title: "Roles", value: "" },
        { title: "Close", value: "" },
        { title: "Role Name", value: "" },
        { title: "Pick A Color", value: "" },
        { title: "Role Scope", value: "" },
        { title: "keep empty to give all access", value: "" },
        { title: "Set priority", value: "" },
        { title: "Create", value: "" },
      ],
    },
    {
      _title: "Add Medicine Dialog",
      data: [
        { title: "Image", value: "" },
        { title: "supports", value: "" },
        { title: "Make Default", value: "" },
        { title: "Edit Alt tag", value: "" },
        { title: "Change", value: "" },
        { title: "Delete", value: "" },
        { title: "Remove All", value: "" },
        { title: "Add Image", value: "" },
        { title: "Video", value: "" },
        { title: "Url", value: "" },
        { title: "Add Url", value: "" },
        { title: "Add", value: "" },
        { title: "Close", value: "" },
        { title: "Identification", value: "" },
        { title: "Generic Brand", value: "" },
        { title: "English Variant", value: "" },
        { title: "Generic ", value: "" },
        { title: "Originator", value: "" },
        { title: "Local Company", value: "" },
        { title: "Sub Category", value: "" },
        { title: "Visible", value: "" },
        { title: "Prescribed", value: "" },
        { title: "Best Seller", value: "" },
        { title: "Deal of the week", value: "" },
        { title: "New", value: "" },
        { title: "In Stock", value: "" },
        { title: "Stock Count", value: "" },
        { title: "Add Variation", value: "" },
        { title: "Remove", value: "" },
        { title: "Dosage Form", value: "" },
        { title: "Strength", value: "" },
        { title: "Pack Types", value: "" },
        { title: "Avg Price Of Originator", value: "" },
        { title: "Selling Price", value: "" },
        { title: "VIP Selling Price", value: "" },
        { title: "Company Patient Price Updated", value: "" },
        { title: "Company Patient Price Old", value: "" },
        { title: "Minimum Order Qty", value: "" },
        { title: "Weight (gram) Per Unit", value: "" },
        { title: "Dealers", value: "" },
        { title: "Meta Keyword", value: "" },
        { title: "Page Title", value: "" },
        { title: "Meta Title", value: "" },
        { title: "Tags", value: "" },
        { title: "Meta Description", value: "" },
        { title: "Additional Section", value: "" },
        { title: "Choose from uploaded", value: "" },
        { title: "File upload", value: "" },
        { title: "Indication & Dosage", value: "" },
        { title: "Side Effects", value: "" },
        { title: "Prescribing Information", value: "" },
        { title: "Additional Information", value: "" },
        { title: "FAQ", value: "" },
        { title: "Suggestions", value: "" },
        { title: "Add Section", value: "" },
        { title: "Company Retail Price", value: "" },
        { title: "Section Name", value: "" },
        { title: "Meta Keyword String", value: "" },
        { title: "Meta Title String", value: "" },
        { title: "Page Title String", value: "" },
        { title: "Meta Description String", value: "" },
      ],
    },
    {
      _title: "Edit Medicine Dialog",
      data: [
        { title: "Edit Medicine", value: "" },
        { title: "Update", value: "" },
      ],
    },
    {
      _title: "Add Company Dialog",
      data: [
        { title: "Add Company", value: "" },
        { title: "Recommended", value: "" },
        { title: "Company Name", value: "" },
        { title: "English Variant", value: "" },
        { title: "Close", value: "" },
      ],
    },
    {
      _title: "Add Dosage Form Dialog",
      data: [
        { title: "Dosage", value: "" },
        { title: "English Variant", value: "" },
        { title: "Cancel", value: "" },
        { title: "Add", value: "" },
      ],
    },
    {
      _title: "Add Supplier Dialog",
      data: [
        { title: "Add Supplier", value: "" },
        { title: "Dealer Name", value: "" },
        { title: "Dealer Minimum Order Qty", value: "" },
        { title: "Selling Price", value: "" },
        { title: "Add", value: "" },
        { title: "Close", value: "" },
      ],
    },
    {
      _title: "Add Sub Category Dialog",
      data: [
        { title: "Add Subcategory under existing category", value: "" },
        { title: "add subcategory with new category", value: "" },
        { title: "Add Category", value: "" },
        { title: "Search", value: "" },
        { title: "Categories", value: "" },
        { title: "Category Name", value: "" },
        { title: "Add Sub Category", value: "" },
        { title: "English Variant", value: "" },
        { title: "Confirm", value: "" },
        { title: "Add", value: "" },
        { title: "Close", value: "" },
      ],
    },
    {
      _title: "Add SEO Tag Dialog",
      data: [
        { title: "Add Seo Tag", value: "" },
        { title: "Add Tag", value: "" },
        { title: "Tag Name", value: "" },
        { title: "Tag Details", value: "" },
        { title: "Color", value: "" },
        { title: "English Variant", value: "" },
        { title: "Add", value: "" },
        { title: "Close", value: "" },
      ],
    },
    {
      _title: "Manual Review Dialog",
      data: [
        { title: "Review", value: "" },
        { title: "Select Medicine Img", value: "" },
        { title: "Select Medicine", value: "" },
        { title: "Random", value: "" },
        { title: "Select Custom Img", value: "" },
        { title: "Recommended", value: "" },
        { title: "Email", value: "" },
        { title: "Must Include ", value: "" },
        { title: "Phone", value: "" },
        { title: "Add", value: "" },
      ],
    },
    {
      _title: "Add Delivery Method Dialog",
      data: [
        { title: "Add Delivery Method", value: "" },
        { title: "Delivery Method", value: "" },
        { title: "Delivery Summery", value: "" },
        { title: "Choose Countries", value: "" },
        { title: "Choose a country", value: "" },
        { title: "All", value: "" },
        { title: "Cancel", value: "" },
        { title: "Add", value: "" },
      ],
    },
    {
      _title: "Edit Delivery Method Dialog",
      data: [
        { title: "Edit Delivery Method", value: "" },
        { title: "Update", value: "" },
      ],
    },

    {
      _title: "Add Payment Method Dialog",
      data: [
        { title: "Add Payment Method", value: "" },
        { title: "Payment Method", value: "" },
        { title: "Payment Instruction", value: "" },
        { title: "Choose Countries", value: "" },
        { title: "Choose a country", value: "" },
        { title: "All", value: "" },
        { title: "Transaction fee", value: "" },
        { title: "Percent", value: "" },
        { title: "Flat", value: "" },
        { title: "Account Detials", value: "" },
        { title: "Add Account", value: "" },
        { title: "Remove Account", value: "" },
        { title: "Recommended", value: "" },
        { title: "Account Title", value: "" },
        { title: "Account Details", value: "" },
        { title: "Active", value: "" },
        { title: "Cancel", value: "" },
        { title: "Add", value: "" },
      ],
    },
    {
      _title: "Edit Payment Method Dialog",
      data: [
        { title: "Edit Payment Method", value: "" },
        { title: "Update", value: "" },
      ],
    },

    {
      _title: "Add Blog Dialog",
      data: [
        { title: "Add", value: "" },
        { title: "Recommended", value: "" },
        { title: "Title", value: "" },
        { title: "Meta Keyword", value: "" },
        { title: "Meta Title", value: "" },
        { title: "Meta Description", value: "" },
        { title: "Content", value: "" },
      ],
    },

    {
      _title: "Edit Blog Dialog",
      data: [{ title: "Update", value: "" }],
    },

    {
      _title: "Product Details Card",
      _section: "Misc BackEnd",
      data: [
        { title: "Product Details", value: "" },
        { title: "Price", value: "" },
        { title: "Minimum Order Qty", value: "" },
        { title: "Identification Info", value: "" },
        { title: "Generic", value: "" },
        { title: "Originator", value: "" },
        { title: "Mfg Brand", value: "" },
        { title: "Category", value: "" },
        { title: "Price Info", value: "" },
        { title: "Select Currency", value: "" },
        { title: "Update Currency", value: "" },
        { title: "Dosage Form", value: "" },
        { title: "Pack Types", value: "" },
        { title: "Strength", value: "" },
        { title: "Selling Price", value: "" },
        { title: "Minimum Order Qty", value: "" },
        { title: "Weight", value: "" },
        { title: "Avg Price of originator", value: "" },
        { title: "Company MRP", value: "" },
        { title: "Company Patient Selling Price Old", value: "" },
        { title: "Company Patient Selling Price Updated", value: "" },
        { title: "VIP Selling Price", value: "" },
        { title: "More", value: "" },
        { title: "Visible", value: "" },
        { title: "Prescribed", value: "" },
        { title: "Best Seller", value: "" },
        { title: "Deal of the week", value: "" },
        { title: "New", value: "" },
        { title: "In Stock", value: "" },
      ],
    },
    {
      _title: "Order Card Backend",
      data: [
        { title: "Delivery", value: "" },
        { title: "Preferred Delivery", value: "" },
        { title: "Delivery Note", value: "" },
        { title: "Payment", value: "" },
        { title: "Payment Note", value: "" },
        { title: "Shipping Address", value: "" },
        { title: "Total", value: "" },
        { title: "Shipping Cost", value: "" },
        { title: "Transaction Fee", value: "" },
        { title: "Total With Shipping Cost", value: "" },
        { title: "Product Details", value: "" },
        { title: "Payment Receipt", value: "" },
        { title: "Tracking ID", value: "" },
        { title: "Customer Confirmed", value: "" },
        { title: "Delivery Image", value: "" },
      ],
    },
    {
      _title: "Misc Words",
      data: [
        { title: "Invalid", value: "" },
        { title: "Already Exist", value: "" },
        { title: "Does not Exist", value: "" },
        { title: "Wrong", value: "" },
        { title: "Remove Image", value: "" },
        { title: "Email", value: "" },
        { title: "Username", value: "" },
        { title: "Password", value: "" },
        { title: "Minimize add role section", value: "" },
        { title: "Remove Image", value: "" },
        { title: "Priority", value: "" },
        { title: "Confirm", value: "" },
        { title: "Transfer Role", value: "" },
        { title: "Delete", value: "" },
        { title: "Role Scope", value: "" },
        { title: "All", value: "" },
        { title: "Close", value: "" },
        { title: "Created", value: "" },
        { title: "Updated", value: "" },
        { title: "Send Email", value: "" },
        { title: "Copy", value: "" },
        { title: "Unblock", value: "" },
        { title: "View Unblock request Messages", value: "" },
        { title: "Preferred Payment", value: "" },
        { title: "First Name", value: "" },
        { title: "Last Name", value: "" },
        { title: "Address", value: "" },
        { title: "City", value: "" },
        { title: "State", value: "" },
        { title: "Postal Code", value: "" },
        { title: "Awaiting Delivery Confirmation", value: "" },
        { title: "Shipping Complete", value: "" },
        { title: "Reject & re-request", value: "" },
        { title: "Confirm Payment & Ship", value: "" },
        { title: "Awaiting Payment Receipt", value: "" },
        { title: "Set Payment Account", value: "" },
        { title: "Account", value: "" },
        { title: "No Account Available...", value: "" },
        { title: "Confirm Order", value: "" },
        { title: "Download", value: "" },
        { title: "Customers", value: "" },
        { title: "Customer List", value: "" },
        { title: "Manage Prescriptions", value: "" },
        { title: "Manage Subscription", value: "" },
        { title: "Do you really want to delete", value: "" },
        { title: "Customer", value: "" },
        { title: "Action", value: "" },
        { title: "Select All", value: "" },
        { title: "Serial", value: "" },
        { title: "Selected", value: "" },
        { title: "Add", value: "" },
        { title: "Country", value: "" },
        { title: "Medicines", value: "" },
        { title: "Add/Manage Generics/ Generic Brands", value: "" },
        { title: "Manage Generic Company", value: "" },
        { title: "Add/Manage Generic Companies", value: "" },
        { title: "Manage Category", value: "" },
        { title: "Add/Manage Categories/ SubCategories", value: "" },
        { title: "Manage Dosage Form", value: "" },
        { title: "Add/Manage Dosage Forms", value: "" },
        { title: "Manage SEO Tags", value: "" },
        { title: "Add/Manage SEO Tags", value: "" },
        { title: "Advanced Med Settings", value: "" },
        { title: "Manage Medicines", value: "" },
        { title: "Manage Brochure", value: "" },
        { title: "Handle Brochure/PDF", value: "" },
        { title: "No Medicine Available", value: "" },
        { title: "SEO", value: "" },
        { title: "Created By", value: "" },
        { title: "Additional Section", value: "" },
        { title: "Dealer Info", value: "" },
        { title: "Dealer Name", value: "" },
        { title: "No Data Available", value: "" },
        { title: "Edit", value: "" },
        { title: "Update", value: "" },
        { title: "Specific Remove", value: "" },
        { title: "Search", value: "" },
        { title: "Add/Manage Suppliers", value: "" },
        { title: "Manage Review", value: "" },
        { title: "View/ Manage Review", value: "" },
        { title: "Review Settings", value: "" },
        { title: "Review", value: "" },
        { title: "Product Details", value: "" },
        { title: "Reviewer Info Request", value: "" },
        { title: "Delivery Methods", value: "" },
        { title: "Verification Interval (min)", value: "" },
      ],
    },

    {
      _title: "Top Bar",
      _section: "Top Bar",
      _divider: "Front-End",
      data: [
        { title: "Logout", value: "" },
        { title: "Login", value: "" },
        { title: "You Are Blocked For Unfair Usage", value: "" },
        { title: "Maximum Limit Reached For Unblock Request", value: "" },
        {
          title: "Please Check Email For Reviewing Reason For Blocking",
          value: "",
        },
        { title: "Request Unblock", value: "" },
      ],
    },
    {
      _title: "Sign in",
      data: [
        { title: "Sign In", value: "" },
        { title: "Email Address", value: "" },
        { title: "Password", value: "" },
        { title: "Remember Me", value: "" },
        { title: "Forgot Password", value: "" },
        {
          title: "Type the email you want to receive recovery code",
          value: "",
        },
        { title: "Cancel", value: "" },
        { title: "Submit", value: "" },
        { title: "Invalid Email", value: "" },
        { title: "Email Does Not Exist", value: "" },
        { title: "Wrong Password", value: "" },
        { title: "Recovery Code", value: "" },
        { title: "New Password", value: "" },
        { title: "Confirm Password", value: "" },
        { title: "Password Not Matched", value: "" },
      ],
    },

    {
      _title: "Sign Up",
      data: [
        { title: "Sign up", value: "" },
        { title: "Name", value: "" },
        { title: "Email Address", value: "" },
        { title: "Password", value: "" },
        { title: "Confirm Password", value: "" },
        { title: "Male", value: "" },
        { title: "Female", value: "" },
        { title: "Address", value: "" },
        { title: "Social Media", value: "" },
        { title: "WhatsApp", value: "" },
        { title: "WeChat", value: "" },
        { title: "Skype", value: "" },
        { title: "Facebook", value: "" },
        { title: "Add Social Media", value: "" },
        { title: "Platform Name", value: "" },
        { title: "Link", value: "" },
        { title: "Cancel", value: "" },
        { title: "Confirm", value: "" },
        { title: "Phone", value: "" },
        {
          title:
            "I want to receive inspiration, marketing promotions and updates via email.",
          value: "",
        },
        {
          title: "I agree with the ",
          value: "",
        },

        { title: "&", value: "" },
        { title: "Already have an account? Sign in", value: "" },
        { title: "Name Already Exist", value: "" },
        { title: "Email Already Exist", value: "" },
        { title: "Choose Your Gender", value: "" },
        { title: "Enter Phone Number", value: "" },
      ],
    },
    {
      _title: "Search Bar",
      _section: "Search Bar",
      data: [
        { title: "Something Went Wrong", value: "" },
        { title: "Search Products...", value: "" },
        { title: "Profile", value: "" },
        { title: "Wishlist", value: "" },
        { title: "Comparison", value: "" },
        { title: "Cart", value: "" },
        { title: "Home", value: "" },
        { title: "Our Medicine", value: "" },
        { title: "Blog", value: "" },
        { title: "Testimonial", value: "" },
        { title: "Contact Us", value: "" },
        { title: "How To Order", value: "" },
      ],
    },
    {
      _title: "Profile Drawer",
      data: [
        { title: "Hello", value: "" },
        { title: "View Profile", value: "" },
        { title: "Notifications", value: "" },
        { title: "Edit Profile", value: "" },
        { title: "Order History", value: "" },
      ],
    },
    {
      _title: "View Profile Dialog",
      data: [{ title: "Profile", value: "" }],
    },
    {
      _title: "Edit Profile Dialog",
      data: [
        { title: "Name Already Exist", value: "" },
        { title: "Email Already Exist", value: "" },
        { title: "New email will require verification on update", value: "" },
      ],
    },
    {
      _title: "Change Password Portion",
      data: [
        { title: "Change Password", value: "" },
        { title: "Old Password", value: "" },
        { title: "Change", value: "" },
        { title: "New Password", value: "" },
        { title: "Confirm Password", value: "" },
        { title: "Password Not Matched", value: "" },
        { title: "Update Password", value: "" },
      ],
    },
    {
      _title: "Order Card Front",
      data: [
        { title: "Review Order", value: "" },
        { title: "Awaiting Approval", value: "" },
        { title: "Upload Payment Receipt Image", value: "" },
        { title: "Order Status", value: "" },
        {
          title:
            "Your delivery has been shipped. Please confirm if you have received properly",
          value: "",
        },
        { title: "Upload image of the Delivery Package (Optional)", value: "" },
        { title: "Received Image", value: "" },
      ],
    },
    {
      _title: "Tik Tak Captcha",
      data: [
        { title: "Solve Tic-Tac-Toe Captcha", value: "" },
        { title: "Reset", value: "" },
        { title: "Good Job!", value: "" },
        { title: "You Lost!", value: "" },
        { title: "Draw!", value: "" },
      ],
    },
    {
      _title: "Email Verification Dialog",
      data: [
        {
          title: "Enter Email Verification Code Sent To Your Email",
          value: "",
        },
        { title: "Verification Code...", value: "" },
        { title: "Resend", value: "" },
      ],
    },

    {
      _title: "Wishlist Drawer",
      data: [
        {
          title: "Wishlist",
          value: "",
        },
        { title: "Clear All", value: "" },
        { title: "Empty Wishlist?", value: "" },
        { title: "Yes", value: "" },
        { title: "No", value: "" },
        { title: "Go To Customer", value: "" },
      ],
    },

    {
      _title: "Comparison Drawer",
      data: [
        {
          title: "Comparison",
          value: "",
        },
        { title: "Nothing To Compare...", value: "" },
      ],
    },
    {
      _title: "Product Large",
      data: [
        {
          title: "Safety Advice",
          value: "",
        },
        { title: "Remove From Compare List", value: "" },
        { title: "Add To Compare", value: "" },
        { title: "Remove From Wishlist", value: "" },
        { title: "Add To Wishlist", value: "" },
        { title: "Ask For Price", value: "" },
        { title: "Add To Cart", value: "" },
        { title: "Variant", value: "" },
        { title: "Ask Price", value: "" },
        { title: "Price Request Has Been Submitted", value: "" },
        {
          title: "Please Wait, You will receive price information in email ",
          value: "",
        },
        { title: "You can also keep an eye in notification", value: "" },
      ],
    },
    {
      _title: "Cart Drawer",
      data: [
        {
          title: "Checkout",
          value: "",
        },
        { title: "Qty", value: "" },
        { title: "Empty Cart?", value: "" },
      ],
    },

    {
      _title: "Checkout",
      data: [
        {
          title: "Thank you for your order",
          value: "",
        },
        { title: "Your order number is", value: "" },
        {
          title:
            "We will review your order and you will be notified through email and notification with payment submission details",
          value: "",
        },
        { title: "Contact Us", value: "" },
        { title: "Back", value: "" },
        { title: "Next", value: "" },
        { title: "Place Order", value: "" },
      ],
    },
    {
      _title: "Contact Us Form",
      data: [
        {
          title: "Full Name",
          value: "",
        },
        { title: "Message", value: "" },
        { title: "Ask Away", value: "" },
      ],
    },
    {
      _title: "Prescription",
      data: [
        {
          title: "Upload Prescription",
          value: "",
        },
      ],
    },

    {
      _title: "Shipping",
      data: [
        {
          title: "Address Line 2",
          value: "",
        },
        {
          title: "State/Province/Region",
          value: "",
        },
        {
          title: "Zip/Postal Code",
          value: "",
        },
        {
          title: "Choose Another Country",
          value: "",
        },
        {
          title: "Use this address for payment details",
          value: "",
        },
        {
          title: "No Delivery Methods Available...",
          value: "",
        },
        {
          title: "Select Delivery Method",
          value: "",
        },
        {
          title: "Shipping Cost",
          value: "",
        },
        {
          title: "Write Any Note You Want To Add",
          value: "",
        },
      ],
    },

    {
      _title: "Payment Form",
      data: [
        {
          title: "Payment Method",
          value: "",
        },
        {
          title: "No Payment Method Available For",
          value: "",
        },
        {
          title: "Select Payment Method",
          value: "",
        },
      ],
    },

    {
      _title: "Order Review",
      data: [
        {
          title: "Order Summary",
          value: "",
        },
        {
          title: "Grand Total",
          value: "",
        },
        {
          title: "Shipping",
          value: "",
        },
        {
          title: "Delivery Method",
          value: "",
        },
        {
          title: "Payment Details",
          value: "",
        },
      ],
    },
    {
      _title: "Checkout Steps",
      data: [
        {
          title: "Prescription",
          value: "",
        },
        {
          title: "Shipping",
          value: "",
        },
        {
          title: "Payment",
          value: "",
        },
        {
          title: "Review",
          value: "",
        },
      ],
    },

    {
      _title: "Product Small",
      data: [
        {
          title: "Update Cart",
          value: "",
        },
        {
          title: "Remove From Cart",
          value: "",
        },
        {
          title: "Add To Cart",
          value: "",
        },
        {
          title: "Price Requested",
          value: "",
        },
      ],
    },
    {
      _title: "Product Small (Left Outs)",
      data: [
        {
          title: "Minimum Order Qty",
          value: "",
        },
      ],
    },
    {
      _title: "Maintenance Front end",
      _section: "Maintenance",
      data: [
        {
          title: "Site Is Down For Maintenance",
          value: "",
        },
        {
          title: "Please Come Back After",
          value: "",
        },
        {
          title: "We Will Be Back Soon!",
          value: "",
        },
      ],
    },
    {
      _title: "Maintenance Back end",
      data: [
        {
          title: "Maintenance Mode",
          value: "",
        },
        {
          title: "Maintenance Time (Minute)",
          value: "",
        },
        {
          title: "keep at 0 to view no time",
          value: "",
        },
        {
          title: "Set Expire Date & Time",
          value: "",
        },
        {
          title: "Schedule",
          value: "",
        },
      ],
    },
    {
      _title: "Go To Top",
      _section: "Overlay",
      data: [
        {
          title: "Go To Top",
          value: "",
        },
      ],
    },
    {
      _title: "Chat Bot",
      data: [
        {
          title: "Chat Bot",
          value: "",
        },
      ],
    },
    {
      _title: "Social Media Pages",
      _section: "Footer",
      data: [
        {
          title: "Social Media",
          value: "",
        },
        {
          title: "Facebook",
          value: "",
        },
        {
          title: "Twitter",
          value: "",
        },
        {
          title: "LinkedIn",
          value: "",
        },
        {
          title: "Telegram",
          value: "",
        },
        {
          title: "Instagram",
          value: "",
        },
      ],
    },
    {
      _title: "Information",
      data: [
        {
          title: "Information",
          value: "",
        },
        {
          title: "Site Map",
          value: "",
        },
      ],
    },
    {
      _title: "Short Story Of Us",
      data: [
        {
          title: "Short Story Of Us",
          value: "",
        },
      ],
    },
    {
      _title: "Contact Us",
      data: [
        {
          title: "Get Updated...",
          value: "",
        },
        {
          title: "Enter your email...",
          value: "",
        },
        {
          title: "I agree to receive updates & promotional emails",
          value: "",
        },
        {
          title: "Subscribe",
          value: "",
        },
      ],
    },
    {
      _title: "Banner",
      _section: "Home Page",
      data: [
        {
          title: "Mfg Company",
          value: "",
        },
        {
          title: "View",
          value: "",
        },
      ],
    },
    {
      _title: "Focus",
      data: [
        {
          title: "Learn More",
          value: "",
        },
        {
          title: "Watch Overview",
          value: "",
        },
      ],
    },

    {
      _title: "Most Viewed",
      data: [
        {
          title: "Most Viewed",
          value: "",
        },
        {
          title: "Watch Video",
          value: "",
        },
      ],
    },

    {
      _title: "Body",
      data: [
        {
          title: "View All",
          value: "",
        },
        {
          title: "Just Arrived",
          value: "",
        },
        {
          title: "Blogs",
          value: "",
        },
        {
          title: "No Blogs...",
          value: "",
        },
        {
          title: "Our Partners",
          value: "",
        },
        {
          title: "Word From Our Valued Customers",
          value: "",
        },
        {
          title: "For Any Inquiry",
          value: "",
        },
      ],
    },
    {
      _title: "Our Medicine Page",
      _section: "Our Medicine Page",
      data: [
        {
          title: "By Generic Name",
          value: "",
        },
        {
          title: "By Brand Name",
          value: "",
        },
        {
          title: "By Company Name",
          value: "",
        },
        {
          title: "By Popularity",
          value: "",
        },
        {
          title: "No Products Available...",
          value: "",
        },
        {
          title: "Show More",
          value: "",
        },
        {
          title: "Show Less",
          value: "",
        },
        {
          title: "Brand",
          value: "",
        },
      ],
    },
    {
      _title: "Single Medicine Page",
      data: [
        {
          title: "This Medicine is not yet Available in '",
          value: "",
        },
        {
          title: "Go Back",
          value: "",
        },
        {
          title: "Write A Review",
          value: "",
        },
        {
          title:
            "We value your opinion more than anything. Please feel free to write us your experience with us or about the product.",
          value: "",
        },
        {
          title: "Email is required",
          value: "",
        },
        {
          title: "Verified",
          value: "",
        },
        {
          title: "Verify Email",
          value: "",
        },
        {
          title: "You are not allowed to post links",
          value: "",
        },
        {
          title: "We Are very sorry for your bad experience.",
          value: "",
        },
        {
          title:
            "Your satisfaction is our goal. Please let us know our shortcomings",
          value: "",
        },
        {
          title: "Tell us how can we improve your experience",
          value: "",
        },
        {
          title: "Please tell us how can we improve your experience",
          value: "",
        },
        {
          title: "Suggest Improvement",
          value: "",
        },
        {
          title: "Share",
          value: "",
        },
        {
          title: "Share On Twitter",
          value: "",
        },
        {
          title: "Share On WhatsApp",
          value: "",
        },
        {
          title: "Share On Email",
          value: "",
        },
        {
          title: "Share On LinkedIn",
          value: "",
        },
        {
          title: "Copy to clipboard",
          value: "",
        },
        {
          title: "Related Products",
          value: "",
        },
        {
          title: "No Related Products...",
          value: "",
        },
        {
          title: "Write Review",
          value: "",
        },
        {
          title: "Share Your Experience",
          value: "",
        },
        {
          title: "Request Reviewer Information",
          value: "",
        },
        {
          title: "Contact Reviewer",
          value: "",
        },
        {
          title: "Your Email",
          value: "",
        },
        {
          title: "Your Phone",
          value: "",
        },
        {
          title: "Enter Your Phone",
          value: "",
        },
        {
          title: "Back",
          value: "",
        },
        {
          title: "Your Phone",
          value: "",
        },
        {
          title: "Agree & Submit",
          value: "",
        },
        {
          title: "Request",
          value: "",
        },
        {
          title: "Post",
          value: "",
        },
      ],
    },

    {
      _title: "Blogs",
      _section: "Blog",
      data: [
        {
          title: "Search Blogs...",
          value: "",
        },
        {
          title: "No Blogs...",
          value: "",
        },
      ],
    },
    {
      _title: "Admin Menu",
      _section: "Admin Menu",
      _divider: "Left Out Things",
      data: [
        { title: "Administration", value: "" },
        { title: "Customer", value: "" },
        { title: "Medicines", value: "" },
        { title: "Supplier", value: "" },
        { title: "Review", value: "" },
        { title: "Orders", value: "" },
        { title: "Delivery Methods", value: "" },
        { title: "Payment Methods", value: "" },
        { title: "Reports", value: "" },
        { title: "Blog", value: "" },
        { title: "Expanse Calculation", value: "" },
        { title: "Website Settings", value: "" },
      ],
    },
    {
      _title: "Left Out Things 2",
      data: [
        { title: "Shipping Document", value: "" },
        { title: "Customer Payment Receipt", value: "" },
        { title: "Upload Shipping Document (Optional)", value: "" },
      ],
    },
    {
      _title: "Medicine Leaflet",
      data: [
        { title: "Manage Brochure/ Leaflet", value: "" },
        { title: "Leaflet", value: "" },
        { title: "Brochure", value: "" },
        { title: "Brochures", value: "" },
        { title: "No Data Available", value: "" },
        { title: "Settings", value: "" },
        { title: "Header Text", value: "" },
        { title: "Footer Text", value: "" },
        { title: "Fields To View", value: "" },
        { title: "Availability", value: "" },
        { title: "Select Medicines", value: "" },
        { title: "Selection Type", value: "" },
        { title: "Manual Selection", value: "" },
        { title: "By Category", value: "" },
        { title: "By Dosage Form", value: "" },
        { title: "Preview", value: "" },
        { title: "Available", value: "" },
        { title: "Not Available", value: "" },
        { title: "PRODUCTS LIST OF Elifesaving Medicine", value: "" },
        { title: "Thank you", value: "" },
        { title: "MEDICNE FOR WORLD (MFW)", value: "" },
        { title: "Phone/ WhatsApp/ Wechat/ Skype/ Viber/ Telegram", value: "" },
        { title: "Date", value: "" },
      ],
    },
    {
      _title: "Medicine Invoice",
      data: [
        { title: "Manual Invoice", value: "" },
        { title: "Consignee Info", value: "" },
        { title: "Name", value: "" },
        { title: "Post Code", value: "" },
        { title: "Invoice Remarks", value: "" },
        { title: "Credit Terms", value: "" },
        { title: "Declaration", value: "" },
        { title: "Choose Destination", value: "" },
        { title: "Invoice No", value: "" },
        { title: "Company Logo", value: "" },
        { title: "Company Info", value: "" },
        { title: "Company Short Form", value: "" },
        { title: "Company Sil", value: "" },
        { title: "Company Watermark", value: "" },
        { title: "Total", value: "" },
        { title: "Total In USD", value: "" },
        { title: "Secondary Total", value: "" },
        { title: "In Text", value: "" },
        { title: "Preview", value: "" },
        { title: "Manual Proforma Invoice", value: "" },
        { title: "Additional Cost", value: "" },
        { title: "Add Additional Cost", value: "" },
        { title: "Payment Info", value: "" },
        { title: "Payment Media", value: "" },
        { title: "Add Field", value: "" },
        { title: "Add Payment Field", value: "" },
        { title: "Field Name", value: "" },
      ],
    },
  ];

  const [expanded, setExpanded] = React.useState(false);

  const [searchList, setSearchList] = useState([]);
  const [searchingLangs, setSearchingLangs] = useState([]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [loadingMain, setLoadingMain] = useState(false);
  const [loadingLangSave, setLoadingLangSave] = useState(false);
  const [langSettings, setLangSettings] = useState([]);

  // Local Store
  const localAdminData = JSON.parse(localStorage.getItem("adminLoginInfo"));

  // Store
  const { langs } = useSelector((state) => state.misc);

  const handleLangSettings = (e, index1, index2) => {
    let arr = [...langSettings];
    arr[index1].data[index2].value = e.target.value;
    setLangSettings(arr);
  };

  const handleSearch = (e, newValue) => {
    setSearchingLangs(newValue);
  };

  const handleSearchingLangSettings = (e, obj, i) => {
    // get i index
    let indexes = {
      indexI: undefined,
      indexJ: undefined,
    };
    indexes.indexI = langSettings.findIndex((l) => l._title === obj._title);
    // Get j index
    const L = langSettings.find((l) => l._title === obj._title);
    if (L && L.data) {
      indexes.indexJ = L.data.findIndex((l) => l.title === obj.title);
    }
    // Change Searching Arr
    let arr = [...searchingLangs];
    let object = { ...arr[i] };
    object.value = e.target.value;
    arr[i] = object;
    setSearchingLangs(arr);
    // Change Main Lang Settings Arr
    handleLangSettings(e, indexes.indexI, indexes.indexJ);
  };

  const handleSaveLangSettings = () => {
    dispatch(
      manageLang({
        langId: buffer._id,
        langSettings,
        creatorId: localAdminData._id,
      })
    );
    setLoadingLangSave(true);
    setTimeout(() => {
      setLoadingLangSave(false);
      setOpen(false);
      setLangSettings(langData);
      dispatch(getLangs());
      dispatch(getNotification());
    }, 1000);
  };

  useEffect(() => {
    setLangSettings(langData);
  }, []);

  useEffect(() => {
    if (langs && langs.length > 0) {
      setLoadingMain(true);
      const L = langs.find((l) => l._id === buffer._id);
      let arr = [];
      if (L.langSettings && L.langSettings.length > 0) {
        langData.forEach((x, i) => {
          if (L.langSettings[i]) {
            let obj1 = { ...L.langSettings[i] };
            if (obj1.data && obj1.data.length > 0) {
              let arr1 = [];
              obj1.data.forEach((y) => {
                let obj2 = { ...y };
                if (obj2.value === "") {
                  obj2.value = obj2.title;
                }
                if (obj2.title !== "") {
                  arr1.push(obj2);
                }
                // }
              });
              obj1.data = arr1;
              arr.push(obj1);
            } else arr.push({ ...x });
          } else arr.push({ ...x });
        });
      } else arr = langData;
      setLangSettings(arr);
      setTimeout(() => {
        setLoadingMain(false);
      }, 1000);
    }
  }, [buffer._id, langs]);

  // ! translate
  // const [lang, setLang] = useState("");
  // const [langId, setLangId] = useState("");
  // Local Store
  const localLangData = JSON.parse(localStorage.getItem("lang"));
  // Store
  const { activeLang } = useSelector((state) => state.misc);
  const [langSettings1, setLangSettings1] = useState([]);
  const title1 = "Language Settings";

  useEffect(() => {
    if (langs && langs.length > 0 && lang !== "") {
      const L = langs.find((l) => l.langCode === lang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      const A = arr.find((l) => l._title === title1);
      if (A) setLangSettings1(A.data);
    }
  }, [lang, langs]);

  const translate1 = (String) => translation(String, title1, 1);

  const translation = (String, title, index) => {
    let translation = "";
    let settings = [];
    if (index === 1) {
      settings = langSettings1;
    }
    const T = settings.find((ls) => ls.title === String);
    if (T && T.value) translation = T.value;
    else {
      let arr = [];
      if (langs && langs.length > 0 && lang !== "") {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.settings && LEn.settings.length > 0) arr = LEn.settings;
        const S = arr.find((l) => l._title === title);
        if (S) {
          const T = S.data.find((ls) => ls.title === String);
          if (T && T.value) translation = T.value;
        }
      }
    }
    return translation;
  };
  // ! translate

  //? Search
  useEffect(() => {
    if (buffer && buffer.langCode && buffer.langCode !== "") {
      const L = langs.find((l) => l.langCode === buffer.langCode);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      let arrLang = [];
      arr.forEach((x) => {
        if (x && x.data) {
          let arr2 = [];
          x.data.forEach((y) => {
            let obj = { ...y };
            obj._title = x._title;
            arr2.push(obj);
          });
          arrLang = [...arrLang, ...arr2];
        }
      });
      setSearchList(arrLang);
    }
  }, [buffer, langs]);
  //? Search
  return (
    <>
      <Dialog open={open} TransitionComponent={Transition} fullScreen>
        <AppBar sx={{ position: "relative" }} color="warning">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setOpen(false);
                setLangSettings([]);
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {translate1("Manage Language")} ({buffer.langCode})
            </Typography>
            <LoadingButton
              loading={loadingLangSave}
              onClick={handleSaveLangSettings}
              color="success"
              variant="contained"
            >
              {translate1("Save")}
            </LoadingButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          {loadingMain ? (
            <LoadingBox w={"100%"} />
          ) : (
            <>
              <Autocomplete
                multiple
                onChange={(e, newValue) => handleSearch(e, newValue)}
                disablePortal
                options={searchList}
                // options={searchDeliveryList}
                // filterOptions={filterOptions}
                filterSelectedOptions={true}
                getOptionLabel={(option) =>
                  `${option.title} (${option._title})`
                }
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option.title} ({option._title})
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search..."
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                )}
              />
              <Divider sx={{ my: 1 }} />

              {searchingLangs.length > 0 ? (
                <>
                  <Grid
                    container
                    spacing={0}
                    sx={{
                      border: "1px solid pink",
                      borderRadius: ".25rem",
                      p: 1,
                      my: 1,
                    }}
                  >
                    {searchingLangs.map((x, i) => (
                      <Grid item key={i} xs={12}>
                        <TextField
                          label={`${x.title} (${x._title})`}
                          value={x.value === "" ? x.title : x.value}
                          // onChange={(e) => handleLangSettings(e, i, j)}
                          onChange={(e) => handleSearchingLangSettings(e, x, i)}
                          fullWidth
                          variant="standard"
                          size="small"
                        />
                      </Grid>
                    ))}
                  </Grid>
                </>
              ) : (
                langSettings.map((x, i) => (
                  <>
                    {x._divider && (
                      <Button
                        variant="contained"
                        color="warning"
                        sx={{
                          my: 1,
                          pointerEvents: "none",
                          justifyContent: "flex-start",
                        }}
                        fullWidth
                        size="small"
                      >
                        {x._divider}
                      </Button>
                    )}
                    {x._section && (
                      <Button
                        variant="outlined"
                        color="warning"
                        sx={{
                          my: 1,
                          pointerEvents: "none",
                          justifyContent: "flex-start",
                        }}
                        fullWidth
                        size="small"
                      >
                        {x._section}
                      </Button>
                    )}
                    <Accordion
                      key={i}
                      expanded={expanded === x._title}
                      onChange={handleChange(x._title)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-label={x._title}
                        aria-controls={x._title}
                        id={x._title}
                      >
                        <Typography>{x._title}</Typography>
                      </AccordionSummary>
                      {expanded === x._title && (
                        <AccordionDetails>
                          <Grid container spacing={1}>
                            {x.data.map((y, j) => (
                              <Grid item key={j} xs={12} md={6} xl={4}>
                                <TextField
                                  label={y.title}
                                  value={y.value === "" ? y.title : y.value}
                                  onChange={(e) => handleLangSettings(e, i, j)}
                                  fullWidth
                                  variant="standard"
                                  size="small"
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </AccordionDetails>
                      )}
                    </Accordion>
                  </>
                ))
              )}
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
