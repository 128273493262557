import React, { Fragment, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import {
  Autocomplete,
  Avatar,
  Badge,
  Box,
  Button,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// Redux
import { useDispatch, useSelector } from "react-redux";

// Icon
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import InfoIcon from "@mui/icons-material/Info";

import AddAdmin from "./dilogs/AddAdmin";
import ManageRole from "./dilogs/ManageRole";
import EditAdmin from "./dilogs/EditAdmin";
import DeleteAdmin from "./dilogs/DeleteAdmin";
import ChangesMade from "./dilogs/ChangesMade";
import ViewAdmin from "./dilogs/ViewAdmin";
import { getAdmins } from "../../../reduxToolkit/features/adminPanel/admin";
import { format } from "date-fns";
import { getRole } from "../../../reduxToolkit/features/adminPanel/role";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { PF } from "../../../config";
import { getCreated, getExactTime } from "../../../hooks/getCreatedData";

// Limit options to show in autocomplete
const OPTIONS_LIMIT = 3;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) => {
  return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
};

export default function ManageAdmins({
  translate1,
  translate2,
  translate5,
  translate6,
}) {
  // Responsive
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  // Use State
  const [adminList, setAdminList] = useState([]);
  const [sortParam, setSortParam] = useState(0);
  const [sortAsc, setSortAsc] = useState(false);
  // Admin Edit
  const [editAdminDlg, setEditAdminDlg] = useState(false);
  const [editAdminBuffer, setEditAdminBuffer] = useState("");

  // Admin View
  const [viewAdminDlg, setViewAdminDlg] = useState(false);
  const [viewAdminBuffer, setViewAdminBuffer] = useState(false);

  //dialogs
  const [addAdminDlg, setAddAdminDlg] = useState(false);
  const [addRoleDlg, setAddRoleDlg] = useState(false);
  const [manageRoleDlg, setManageRoleDlg] = useState(false);
  // Delete ADmin
  const [deleteAdminDlg, setDeleteAdminDlg] = useState(false);
  const [deleteAdminBuffer, setDeleteAdminBuffer] = useState("");
  const [transferAdminBuffer, setTransferAdminBuffer] = useState("");
  // Changes Made
  const [changesMadeDlg, setChangesMadeDlg] = useState(false);
  const [changesMadeBuffer, setChangesMadeBuffer] = useState(0);

  // localData
  // const localData = JSON.parse(localStorage.getItem("adminLoginInfo"));

  // store
  let { admins, error } = useSelector((state) => state.admin);
  const { roles } = useSelector((state) => state.role);
  const { notifications } = useSelector((state) => state.notification);

  // Use Effect
  const dispatch = useDispatch();

  // get admin Model
  useEffect(() => {
    dispatch(getAdmins());
    dispatch(getRole());
  }, [dispatch]);

  // get Role Model
  useEffect(() => {
    const R = admins.map((x, i) => {
      return roles.find((r) => r._id === x.roleId);
    });
    let arr1 = [];
    admins.map((x, i) => arr1.push({ ...R[i], ...admins[i] }));
    if (notifications.length > 0) {
      const N = admins.map((x, i) => {
        const na = notifications.filter((n) => n.adminWhoCreatedId === x._id);
        const naJson = { changesMade: na.length };
        return naJson;
      });
      let arr2 = [];
      admins.map((x, i) => arr2.push({ ...N[i], ...arr1[i] }));
      arr2.sort((a, b) => a.priority - b.priority);

      setAdminList(arr2);
    } else setAdminList(arr1);
  }, [admins, notifications, roles]);

  // Functions
  const toggleSortDirection = () => {
    setSortAsc(!sortAsc);
    adminList.reverse();
  };

  const handleAdminSort = (e) => {
    if (e.target.value === 0) {
      adminList.sort((a, b) => a.priority - b.priority);
      setSortAsc(false);
    } else if (e.target.value === 1) {
      adminList.sort((a, b) => {
        const A = format(new Date(a.createdAt), "T");
        const B = format(new Date(b.createdAt), "T");
        return B - A;
      });
      setSortAsc(false);
    } else {
      adminList.sort((a, b) => b.changesMade - a.changesMade);
      setSortAsc(false);
    }
    setSortParam(e.target.value);
  };

  const handleAdminEdit = (id) => {
    const ae = adminList.find((o) => o._id === id);
    setEditAdminBuffer(ae);
    setEditAdminDlg(true);
  };

  const handleDeleteAdmin = (id) => {
    const ad = adminList.find((o) => o._id === id);
    setDeleteAdminBuffer(ad);
    setDeleteAdminDlg(true);
  };

  const handleChangesMade = (id) => {
    setChangesMadeBuffer(id);
    setChangesMadeDlg(true);
  };

  // Notification Dialog handler
  const handleChangesMadeClose = () => {
    setChangesMadeBuffer("");
    setChangesMadeDlg(false);
  };

  // View Admin FUnctions
  const handleViewAdmin = (id) => {
    setViewAdminBuffer(id);
    setViewAdminDlg(true);
  };

  // Fixed date
  const [showDate, setShowDate] = useState([]);

  const handleShowDate = (date) => {
    let arr = [...showDate];
    if (arr.includes(date)) {
      arr = arr.filter((a) => a !== date);
    } else {
      arr.push(date);
    }
    setShowDate(arr);
  };
  return (
    <>
      {!error && (
        <>
          <Grid
            container
            spacing={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid
              item
              xs={12}
              sm={3}
              md={2.5}
              lg={1.5}
              xl={1}
              sx={{ order: { xs: 2, sm: 1 } }}
            >
              <Button
                variant="contained"
                size="small"
                color="success"
                fullWidth
                onClick={() => setAddAdminDlg(true)}
                startIcon={
                  <img
                    src="https://img.icons8.com/ios/50/000000/add-administrator.png"
                    alt="add admin"
                    style={{ height: "20px" }}
                  />
                }
              >
                {translate1("Add Admin")}
              </Button>
              <AddAdmin
                addAdminDlg={addAdminDlg}
                setAddAdminDlg={setAddAdminDlg}
                translate5={translate5}
                translate6={translate6}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              md={3}
              lg={2}
              xl={1.5}
              sx={{ order: { xs: 2, sm: 1 } }}
            >
              <Button
                variant="contained"
                size="small"
                color="primary"
                fullWidth
                onClick={() => setManageRoleDlg(true)}
                startIcon={
                  <img
                    src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/64/000000/external-role-model-job-search-flaticons-lineal-color-flat-icons-3.png"
                    alt="add Role"
                    style={{ height: "20px" }}
                  />
                }
              >
                {translate1("Manage Role")}
              </Button>
              {/* {roles && roles.length > 0 && ( */}
              <ManageRole
                addRoleDlg={addRoleDlg}
                setAddRoleDlg={setAddRoleDlg}
                manageRoleDlg={manageRoleDlg}
                setManageRoleDlg={setManageRoleDlg}
                translate6={translate6}
              />
              {/* )} */}
            </Grid>
            <Grid
              item
              xs={11}
              sm={6}
              md={6.5}
              lg={7.5}
              xl={9.5}
              sx={{ order: { xs: 1, sm: 2 } }}
            >
              {/* Search Bar */}
              <Autocomplete
                disablePortal
                options={adminList}
                filterOptions={filterOptions}
                getOptionLabel={(option) => option.adminName}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                    onClick={() => {}}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      src={
                        option.adminPic.includes("http")
                          ? option.adminPic
                          : PF + "adminPanel/admins/" + option.adminPic
                      }
                      alt="admins"
                    />
                    {option.adminName}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={translate1("Search") + "..."}
                    variant="standard"
                    size="small"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={9} sm={11.5} md={11.5} sx={{ order: 3 }}>
              <FormControl fullWidth sx={{ mb: 2 }} size="small">
                <InputLabel id="demo-simple-select-label">
                  {translate1("Sort")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={sortParam}
                  label={translate1("Sort")}
                  onChange={handleAdminSort}
                >
                  <MenuItem value={0}>{translate1("Role")} </MenuItem>
                  <MenuItem value={1}>{translate1("Creation")}</MenuItem>
                  <MenuItem value={2}>{translate1("Changes Made")}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={0.5} md={0.4} lg={0.5} sx={{ order: 4, mb: 2 }}>
              <IconButton size="small" onClick={toggleSortDirection}>
                {sortAsc ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
              </IconButton>
            </Grid>
          </Grid>
          <Button
            variant="contained"
            color="info"
            fullWidth
            sx={{ pointerEvents: "none" }}
          >
            {translate1("Admins")} ({adminList.length})
          </Button>
          {/* admins */}
          <Box
            sx={{
              maxHeight: { xs: "60vh", sm: "40vh" },
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {adminList.map((x, i) => (
              <Box
                key={i}
                sx={{
                  background: x.color,
                  borderRadius: ".25rem",
                  my: 1,
                  pb: 1,
                }}
              >
                <Grid
                  container
                  spacing={0.5}
                  justifyContent={!smUp ? "center" : "space-around"}
                  alignItems="center"
                >
                  {/* Admin Image */}
                  {!smUp ? (
                    <Grid item xs={4}>
                      <IconButton
                        size="small"
                        onClick={() => handleViewAdmin(x._id)}
                      >
                        <LazyLoadImage
                          effect="blur"
                          src={
                            !x.adminPic.includes("http")
                              ? PF + "/adminPanel/admins/" + x.adminPic
                              : "https://cdn.commercialandlegal-legalservices.com.au/wp-content/uploads/media/2018/03/Unknown-Person-Image-1-e1562653526174.png"
                          }
                          alt={x.adminName}
                          style={{
                            height: 30,
                            width: 30,
                            objectFit: "cover",
                            borderRadius: "20rem",
                          }}
                        />
                      </IconButton>
                    </Grid>
                  ) : (
                    <Grid item sm={1} md={1}>
                      <IconButton
                        size="small"
                        onClick={() => handleViewAdmin(x._id)}
                      >
                        <Avatar
                          alt={x.adminName}
                          src={
                            !x.adminPic.includes("http")
                              ? PF + "/adminPanel/admins/" + x.adminPic
                              : "https://cdn.commercialandlegal-legalservices.com.au/wp-content/uploads/media/2018/03/Unknown-Person-Image-1-e1562653526174.png"
                          }
                          sx={{ width: 24, height: 24 }}
                        />
                      </IconButton>
                    </Grid>
                  )}

                  <Grid item xs={8} sm={2.5} md={3}>
                    <Typography
                      variant="subtitle1"
                      color="initial"
                      // align="center"
                      sx={{
                        maxWidth: { xs: "150px" },
                      }}
                    >
                      {x.adminName}
                    </Typography>
                  </Grid>
                  <Grid item xs={10} sm={2.5} md={2}>
                    <Button
                      variant="outlined"
                      size="small"
                      color="inherit"
                      fullWidth
                      sx={{ pointerEvents: "none" }}
                    >
                      {x.roleName}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={3} md={2}>
                    <Typography variant="body2" color="inherit" align="center">
                      {translate1("Created")}: {getCreated(x.createdAt)}
                      <Tooltip
                        arrow
                        placement="top"
                        title={getExactTime(x.createdAt)}
                      >
                        <IconButton
                          size="small"
                          onClick={() => handleShowDate(x.createdAt)}
                        >
                          <InfoIcon color="inherit" />
                        </IconButton>
                      </Tooltip>
                      <br />
                      {showDate.includes(x.createdAt) &&
                        getExactTime(x.createdAt)}
                      {!smUp && <Divider sx={{ my: 1 }} />}
                    </Typography>
                  </Grid>
                  {/* Changes Made */}
                  <Grid item xs={2} sm={1} md={2}>
                    <Badge
                      badgeContent={x.changesMade || 0}
                      color="error"
                      showZero
                      max={9999}
                    >
                      {!mdUp ? (
                        <IconButton
                          size="small"
                          title="Changes Made"
                          onClick={() => handleChangesMade(x._id, i)}
                        >
                          <img
                            src="https://img.icons8.com/external-flat-icons-inmotus-design/67/000000/external-customize-player-flat-icons-inmotus-design.png"
                            alt="changes made"
                            style={{ height: "20px" }}
                          />
                        </IconButton>
                      ) : (
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => handleChangesMade(x._id, i)}
                          startIcon={
                            <img
                              src="https://img.icons8.com/external-flat-icons-inmotus-design/67/000000/external-customize-player-flat-icons-inmotus-design.png"
                              alt="changes made"
                              style={{ height: "20px" }}
                            />
                          }
                        >
                          {translate1("Changes Made")}
                        </Button>
                      )}
                    </Badge>
                  </Grid>
                  <Grid item xs={5} sm={1.5} md={1}>
                    <IconButton
                      size="small"
                      onClick={() => handleAdminEdit(x._id)}
                    >
                      <ModeEditIcon color="warning" />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleDeleteAdmin(x._id)}
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
            ))}
            {/* Dialogs */}
            {/* View Admin Dialog */}
            <ViewAdmin
              viewProfileDlg={viewAdminDlg}
              setViewProfileDlg={setViewAdminDlg}
              adminId={viewAdminBuffer}
              adminList={adminList}
            />
            {/* Delete Admin Dialog */}
            <DeleteAdmin
              deleteAdminDlg={deleteAdminDlg}
              setDeleteAdminDlg={setDeleteAdminDlg}
              deleteAdminBuffer={deleteAdminBuffer}
              setDeleteAdminBuffer={setDeleteAdminBuffer}
              transferAdminBuffer={transferAdminBuffer}
              setTransferAdminBuffer={setTransferAdminBuffer}
              adminList={adminList}
            />
            {/* changes Made Dialog */}
            <Dialog
              open={changesMadeDlg}
              onClose={handleChangesMadeClose}
              fullWidth
              maxWidth={"sm"}
            >
              <DialogTitle>{translate1("Changes Made")}</DialogTitle>
              <DialogContent>
                <ChangesMade changesMadeBuffer={changesMadeBuffer} />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleChangesMadeClose} color="error">
                  {translate2("Cancel")}
                </Button>
              </DialogActions>
            </Dialog>
            {/* Edit Admin */}
            <EditAdmin
              editAdminDlg={editAdminDlg}
              setEditAdminDlg={setEditAdminDlg}
              singleAdmin={editAdminBuffer}
            />
          </Box>
        </>
      )}
    </>
  );
}
