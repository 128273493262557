import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../config";

// Create BLogs
export const createBlog = createAsyncThunk("blog/createBlog", async (data) => {
  const res = await axiosInstance.post("/api/blog/create", data);
  return res.data;
});

// upload Blog Image
export const uploadBlogImg = createAsyncThunk(
  "blog/uploadBlogImg",
  async (data) => {
    const res = await axiosInstance.post("/api/multer/singleBlogImg/", data);
    return res.data;
  }
);

// Get Blogs
export const getBlogs = createAsyncThunk("blog/getBlogs", async () => {
  const res = await axiosInstance.get("/api/blog/");
  return res.data;
});

// upload Blog Image
export const updateBlogImg = createAsyncThunk(
  "blog/updateBlogImg",
  async (data) => {
    const res = await axiosInstance.post("/api/blog/update/", data);
    return res.data;
  }
);

// Remove Blog
export const removeBlog = createAsyncThunk("blog/removeBlog", async (data) => {
  const res = await axiosInstance.post("/api/blog/remove/", data);
  return res.data;
});

// Initial State
const initialState = {
  pending: false,
  error: false,
  blogs: [],
  snackNotiBlog: {},
};

// Slice
export const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {
    resetSnkBlog(state) {
      state.snackNotiBlog = {};
    },
  },
  extraReducers: {
    [createBlog.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [createBlog.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiBlog = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [createBlog.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [getBlogs.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getBlogs.fulfilled]: (state, action) => {
      state.pending = false;
      state.blogs = action.payload.data;
    },
    [getBlogs.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [uploadBlogImg.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [uploadBlogImg.fulfilled]: (state) => {
      state.pending = false;
    },
    [uploadBlogImg.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [updateBlogImg.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [updateBlogImg.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiBlog = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [updateBlogImg.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [removeBlog.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [removeBlog.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiBlog = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [removeBlog.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
  },
});

export const { resetSnkBlog } = blogSlice.actions;
export default blogSlice.reducer;
