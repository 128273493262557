import React from "react";
import "./Missing.css";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
// Icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
export default function Missing() {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate("/");
  };
  return (
    <>
      <Button
        variant="contained"
        sx={{ width: "100%" }}
        fullWidth
        startIcon={<ArrowBackIcon />}
        onClick={handleGoBack}
      >
        Go Back Home
      </Button>
      <img
        src="https://indocosmo.com/images/404-page.svg"
        alt=""
        style={{ width: "100vw", height: "80vh", objectFit: "contain" }}
      />
    </>
  );
}
