import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// react-redux
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleAdmin,
  loginAdmin,
} from "../../reduxToolkit/features/adminPanel/admin";
import {
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputAdornment,
  IconButton,
} from "@mui/material";
import CaptchaTikTakToe from "../../components/misc/CaptchaTikTakToe";
// Icons
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const theme = createTheme();

export default function AdminSignIn({ setLoginStatus }) {
  const [email, setEmail] = React.useState("");
  const [noEmail, setNoEmail] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [noPassword, setNoPassword] = React.useState(false);
  const [formClicked, setFormClicked] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState(null);
  const [showPass, setShowPass] = React.useState(false);
  // dialog
  const [captchaDlg, setCaptchaDlg] = React.useState(false);

  //   Store
  const { singleAdmin, loginResponse } = useSelector((state) => state.admin);

  // localData
  const localData = JSON.parse(localStorage.getItem("adminLoginInfo"));

  const dispatch = useDispatch();
  //   Use Effect
  React.useEffect(() => {
    if (loginResponse && loginResponse.admin) {
      localStorage.setItem(
        "adminLoginInfo",
        JSON.stringify(loginResponse.admin)
      );
      dispatch(getSingleAdmin(loginResponse.admin._id));
    } else {
      setAlertMsg(loginResponse.message);
    }
  }, [dispatch, loginResponse, loginResponse.admin]);

  React.useEffect(() => {
    if (singleAdmin._id && singleAdmin._id === localData._id) {
      localStorage.setItem("adminLoginInfo", JSON.stringify(singleAdmin));
      setTimeout(() => {
        setLoginStatus(true);
      }, 1000);
    }
  }, [localData, singleAdmin]);

  React.useEffect(() => {
    if (formClicked) {
      if (email === "") {
        setNoEmail(true);
      } else {
        setNoEmail(false);
        setAlertMsg(null);
      }
      if (password === "") {
        setNoPassword(true);
      } else {
        setNoPassword(false);
        setAlertMsg(null);
      }
    }
  }, [email, formClicked, password]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormClicked(true);
    if (email !== "" && password !== "") {
      setCaptchaDlg(true);
    }
  };

  const adminLogin = () => {
    dispatch(loginAdmin({ email, password }));
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage:
              "url(https://okcredit-blog-images-prod.storage.googleapis.com/2021/04/pharmacy1.jpg)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Admin Panel
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={(e) => setEmail(e.target.value)}
                autoFocus
                error={noEmail && true}
                helperText={noEmail && "Enter Email"}
              />
              {/* {noEmail && <Alert severity="error">Enter Email</Alert>} */}
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPass ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
                error={noPassword && true}
                helperText={noPassword && "Enter Password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        size="small"
                        onClick={() => setShowPass(!showPass)}
                      >
                        {showPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {/* {noPassword && <Alert severity="error">Enter Password</Alert>} */}
              {alertMsg && <Alert severity="warning">{alertMsg}</Alert>}
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Dialog
                open={captchaDlg}
                onClose={() => setCaptchaDlg(false)}
                aria-labelledby={"captcha-dlg"}
              >
                <DialogContent>
                  <CaptchaTikTakToe
                    loginFunc={adminLogin}
                    setCaptchaDlg={setCaptchaDlg}
                  />
                </DialogContent>
              </Dialog>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Login
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="tel:+8801315686147" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
