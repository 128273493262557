import { Box, Card, CardContent, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIdToScroll } from "../../reduxToolkit/features/miscFront";
import ContactUsForm from "../forms/ContactUsForm";
import Testimonials from "../testimonial/Testimonials";
export default function TestimonialsNContactUs() {
  const testimonialRef = useRef();
  const contactRef = useRef();
  const dispatch = useDispatch();
  // Store
  const { webSettings } = useSelector((state) => state.webSettings);

  // Scroll functionality
  const { idToScroll } = useSelector((state) => state.miscFront);

  useEffect(() => {
    if (idToScroll === "testimonial") {
      setTimeout(() => {
        testimonialRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        dispatch(setIdToScroll(""));
      }, 250);
    }
    if (idToScroll === "contact-us") {
      setTimeout(() => {
        contactRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        dispatch(setIdToScroll(""));
      }, 250);
    }
  }, [idToScroll]);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate
  return (
    <Grid
      container
      spacing={2}
      justifyContent="space-around"
      // sx={{ mx: 1 }}
      alignItems="center"
    >
      <Grid item xs={11.5} md={6} sx={{ position: "relative" }}>
        <Box
          ref={testimonialRef}
          sx={{
            position: "absolute",
            // zIndex: -20,
            top: -200,
            // width: "100%",
            height: 300,
            // background: "black",
          }}
        />
        <Card>
          <CardContent>
            <Typography
              variant="h4"
              align="center"
              sx={{
                background: webSettings.siteColorTheme || "lightblue",
                fontSize: { xs: "1.8rem", sm: "2rem" },
                mb: 1,
                pb: 1,
              }}
            >
              {translateFront("Word From Our Valued Customers")}
            </Typography>
            <Testimonials />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={11.5} md={6} sx={{ position: "relative" }}>
        <Box
          ref={contactRef}
          sx={{
            position: "absolute",
            // zIndex: -20,
            top: -100,
            // width: "100%",
            height: 300,
            // background: "black",
          }}
        />
        <Card>
          <CardContent>
            <Typography
              variant="h4"
              align="center"
              sx={{
                background: webSettings.siteColorTheme || "lightblue",
                fontSize: { xs: "1.8rem", sm: "2rem" },
                mb: 1,
                pb: 1,
              }}
            >
              {translateFront("For Any Inquiry")}
            </Typography>
            <ContactUsForm />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
