import Facebook from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TelegramIcon from "@mui/icons-material/Telegram";
import InstagramIcon from "@mui/icons-material/Instagram";
import LaunchIcon from "@mui/icons-material/Launch";
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

export default function SocialMediaPages() {
  // Store
  const { webSettings } = useSelector((state) => state.webSettings);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate

  return (
    <>
      <Typography
        variant="h6"
        color="initial"
        sx={{
          borderBottom: "2px solid black",
          mt: 1,
        }}
      >
        {translateFront("Social Media")}
      </Typography>
      <List dense>
        {webSettings.socialMediaPages &&
          webSettings.socialMediaPages.fb !== "" && (
            <ListItem disablePadding>
              <ListItemButton
                onClick={() =>
                  window.open(webSettings.socialMediaPages.fb, "_blank")
                }
              >
                <ListItemIcon>
                  <Facebook sx={{ color: "navy" }} />
                </ListItemIcon>
                {/* <ListItemText
                  // primary={webSettings.socialMediaPages.fb || ""}
                  primary={
                    
                  }
                /> */}
                <Typography variant="body2" color="initial">
                  {translateFront("Facebook")}{" "}
                  <IconButton size="small" sx={{ pointerEvents: "none" }}>
                    <LaunchIcon color="info" />
                  </IconButton>
                </Typography>
              </ListItemButton>
            </ListItem>
          )}
        {webSettings.socialMediaPages &&
          webSettings.socialMediaPages.twitter !== "" && (
            <ListItem disablePadding>
              <ListItemButton
                onClick={() =>
                  window.open(webSettings.socialMediaPages.twitter, "_blank")
                }
              >
                <ListItemIcon>
                  <TwitterIcon color="info" />
                </ListItemIcon>
                {/* <ListItemText
                  primary={webSettings.socialMediaPages.twitter || ""}
                /> */}
                <Typography variant="body2" color="initial">
                  {translateFront("Twitter")}{" "}
                  <IconButton size="small" sx={{ pointerEvents: "none" }}>
                    <LaunchIcon color="info" />
                  </IconButton>
                </Typography>
              </ListItemButton>
            </ListItem>
          )}
        {webSettings.socialMediaPages &&
          webSettings.socialMediaPages.linkedIn !== "" && (
            <ListItem disablePadding>
              <ListItemButton
                onClick={() =>
                  window.open(webSettings.socialMediaPages.linkedIn, "_blank")
                }
              >
                <ListItemIcon>
                  <LinkedInIcon sx={{ color: "black" }} />
                </ListItemIcon>
                {/* <ListItemText
                  primary={webSettings.socialMediaPages.linkedIn || ""}
                /> */}
                <Typography variant="body2" color="initial">
                  {translateFront("LinkedIn")}{" "}
                  <IconButton size="small" sx={{ pointerEvents: "none" }}>
                    <LaunchIcon color="info" />
                  </IconButton>
                </Typography>
              </ListItemButton>
            </ListItem>
          )}
        {webSettings.socialMediaPages &&
          webSettings.socialMediaPages.telegram !== "" && (
            <ListItem disablePadding>
              <ListItemButton
                onClick={() =>
                  window.open(webSettings.socialMediaPages.telegram, "_blank")
                }
              >
                <ListItemIcon>
                  <TelegramIcon color="info" />
                </ListItemIcon>
                {/* <ListItemText
                  primary={webSettings.socialMediaPages.telegram || ""}
                /> */}
                <Typography variant="body2" color="initial">
                  {translateFront("Telegram")}{" "}
                  <IconButton size="small" sx={{ pointerEvents: "none" }}>
                    <LaunchIcon color="info" />
                  </IconButton>
                </Typography>
              </ListItemButton>
            </ListItem>
          )}
        {webSettings.socialMediaPages &&
          webSettings.socialMediaPages.instagram !== "" && (
            <ListItem disablePadding>
              <ListItemButton
                onClick={() =>
                  window.open(webSettings.socialMediaPages.instagram, "_blank")
                }
              >
                <ListItemIcon>
                  <InstagramIcon color="error" />
                </ListItemIcon>
                {/* <ListItemText
                  primary={webSettings.socialMediaPages.instagram || ""}
                /> */}
                <Typography variant="body2" color="initial">
                  {translateFront("Instagram")}{" "}
                  <IconButton size="small" sx={{ pointerEvents: "none" }}>
                    <LaunchIcon color="info" />
                  </IconButton>
                </Typography>
              </ListItemButton>
            </ListItem>
          )}

        {webSettings.additionalSocialPages &&
          webSettings.additionalSocialPages.length > 0 &&
          webSettings.additionalSocialPages.map((x, i) => (
            <ListItem disablePadding key={i}>
              <ListItemButton onClick={() => window.open(x.pageLink, "_blank")}>
                <ListItemIcon>
                  <img
                    src={x.iconUrl}
                    alt={x.pageName}
                    style={{ height: "1.5rem" }}
                  />
                </ListItemIcon>
                {/* <ListItemText primary={x.pageName || ""} /> */}
                <Typography variant="body2" color="initial">
                  {x.pageName}{" "}
                  <IconButton size="small" sx={{ pointerEvents: "none" }}>
                    <LaunchIcon color="info" />
                  </IconButton>
                </Typography>
              </ListItemButton>
            </ListItem>
          ))}
      </List>
    </>
  );
}
