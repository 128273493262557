import React, { useEffect } from "react";

export default function SelectionAll({
  medList,
  siteLang,
  setSelectedForPreview,
}) {
  useEffect(() => {
    let arr = [...medList];
    const unique = [
      ...new Map(arr.map((item) => [item["_id"], item])).values(),
    ];
    setSelectedForPreview(unique);
  }, [medList, siteLang]);
  return <></>;
}
