import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  Typography,
  Card,
  CardActions,
  CardContent,
  Alert,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { useSelector } from "react-redux";

// Icons
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import PreviewBrochureDlg from "./PreviewBrochureDlg";
import BrochureSelectionSection from "./BrochureSelectionSection";

export default function BrochureSection({ siteLang }) {
  //! Translate
  // Store
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate

  const [viewBrochures, setViewBrochures] = useState(false);
  const [brochures, setBrochures] = useState([]);
  const [selectionType, setSelectionType] = useState(0);

  const [date, setDate] = useState(new Date());
  const [headerText, setHeaderText] = useState(
    translate0(`PRODUCTS LIST OF MEDICINE FOR WORLD (MFW)`)
  );
  const [footerText, setFooterText] = useState(
    `
    <p>${translate0("Thank you")}</p>
    <p>${translate0("MEDICNE FOR WORLD (MFW)")}</p>
    <p>https://www.mfw.com.bd</p>
    <p>info@medicineforworld.com.bd</p>
    <p>medicineforworld@gmail.com</p>
    <p>${translate0(
      "Phone/ WhatsApp/ Wechat/ Skype/ Viber/ Telegram"
    )}: +880191694234; +8801764000174</p>`
  );

  const [fieldsToView, setFieldsToView] = useState({
    genericBrand: true,
    generic: true,
    image: true,
    category: true,
    subCategory: true,
    company: true,
    originator: false,
    dosage: true,
    strength: true,
    packSize: true,
    price: false,
    availability: false,
  });

  const [pageGap, setPageGap] = useState(235);

  const [selectedForPreview, setSelectedForPreview] = useState([]);

  const [previewDlg, setPreviewDlg] = useState(false);

  // Functions
  const handleSelectionChange = (e) => {
    setSelectionType(e.target.value);
  };

  return (
    <Container>
      <Grid container spacing={1} sx={{ mt: 1 }}>
        <Grid item xs={12} md={12}>
          <Grid container flexDirection={"column"} spacing={2}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                size="small"
                sx={{ display: "flex", justifyContent: "space-between" }}
                fullWidth
                onClick={() => setViewBrochures(!viewBrochures)}
                endIcon={
                  viewBrochures ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownCircleIcon />
                  )
                }
              >
                {translate0("Brochures")}
              </Button>
            </Grid>
            {viewBrochures && (
              <Grid item>
                {brochures.length < 1 ? (
                  <Alert severity="warning">
                    {translate0("No Data Available")} ...
                  </Alert>
                ) : (
                  <Box
                    sx={{
                      border: "1px solid lightblue",
                      borderRadius: ".25rem",
                      minHeight: "200px",
                    }}
                  >
                    {brochures.map((x, i) => (
                      <Card>
                        <CardContent>
                          <Typography variant="body2" color="initial">
                            {x.fileName}
                          </Typography>
                        </CardContent>
                        <CardActions></CardActions>
                      </Card>
                    ))}
                  </Box>
                )}
              </Grid>
            )}
            <Grid item>
              <Button
                variant="outlined"
                size="small"
                fullWidth
                sx={{ pointerEvents: "none" }}
              >
                {translate0("Settings")}
              </Button>
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item xs={12} md={2}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      label={translate0("From")}
                      value={date}
                      onChange={(newValue) => {
                        setDate(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      fullWidth
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={10}>
                  <TextField
                    label={translate0("Header Text")}
                    value={headerText}
                    onChange={(e) => setHeaderText(e.target.value)}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <TextField
                label={translate0("Footer Text")}
                value={footerText}
                onChange={(e) => setFooterText(e.target.value)}
                multiline
                minRows={4}
                fullWidth
              />
            </Grid>
            <Grid item>
              <Typography
                variant="subtitle"
                sx={{
                  fontSize: "1.2rem",
                  textDecoration: "underline",
                }}
                color="initial"
              >
                {translate0("Fields To View")}
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={4} sm={3} md={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={fieldsToView.genericBrand}
                        onChange={(e) => {
                          let obj = { ...fieldsToView };
                          obj.genericBrand = e.target.checked;
                          setFieldsToView(obj);
                        }}
                      />
                    }
                    label={translate0("Generic Brand")}
                  />
                </Grid>
                <Grid item xs={4} sm={3} md={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={fieldsToView.generic}
                        onChange={(e) => {
                          let obj = { ...fieldsToView };
                          obj.generic = e.target.checked;
                          setFieldsToView(obj);
                        }}
                      />
                    }
                    label={translate0("Generic")}
                  />
                </Grid>

                <Grid item xs={4} sm={3} md={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={fieldsToView.image}
                        onChange={(e) => {
                          let obj = { ...fieldsToView };
                          obj.image = e.target.checked;
                          setFieldsToView(obj);
                        }}
                      />
                    }
                    label={translate0("Image")}
                  />
                </Grid>

                <Grid item xs={4} sm={3} md={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={fieldsToView.category}
                        onChange={(e) => {
                          let obj = { ...fieldsToView };
                          obj.category = e.target.checked;
                          setFieldsToView(obj);
                        }}
                      />
                    }
                    label={translate0("Category")}
                  />
                </Grid>

                <Grid item xs={4} sm={3} md={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={fieldsToView.subCategory}
                        onChange={(e) => {
                          let obj = { ...fieldsToView };
                          obj.subCategory = e.target.checked;
                          setFieldsToView(obj);
                        }}
                      />
                    }
                    label={translate0("Sub Category")}
                  />
                </Grid>

                <Grid item xs={4} sm={3} md={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={fieldsToView.company}
                        onChange={(e) => {
                          let obj = { ...fieldsToView };
                          obj.company = e.target.checked;
                          setFieldsToView(obj);
                        }}
                      />
                    }
                    label={translate0("Mfg Company")}
                  />
                </Grid>

                <Grid item xs={4} sm={3} md={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={fieldsToView.originator}
                        onChange={(e) => {
                          let obj = { ...fieldsToView };
                          obj.originator = e.target.checked;
                          setFieldsToView(obj);
                        }}
                      />
                    }
                    label={translate0("Originator")}
                  />
                </Grid>

                <Grid item xs={4} sm={3} md={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={fieldsToView.dosage}
                        onChange={(e) => {
                          let obj = { ...fieldsToView };
                          obj.dosage = e.target.checked;
                          setFieldsToView(obj);
                        }}
                      />
                    }
                    label={translate0("Dosage Form")}
                  />
                </Grid>

                <Grid item xs={4} sm={3} md={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={fieldsToView.strength}
                        onChange={(e) => {
                          let obj = { ...fieldsToView };
                          obj.strength = e.target.checked;
                          setFieldsToView(obj);
                        }}
                      />
                    }
                    label={translate0("Strength")}
                  />
                </Grid>

                <Grid item xs={4} sm={3} md={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={fieldsToView.packSize}
                        onChange={(e) => {
                          let obj = { ...fieldsToView };
                          obj.packSize = e.target.checked;
                          setFieldsToView(obj);
                        }}
                      />
                    }
                    label={translate0("Pack Types")}
                  />
                </Grid>

                <Grid item xs={4} sm={3} md={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={fieldsToView.price}
                        onChange={(e) => {
                          let obj = { ...fieldsToView };
                          obj.price = e.target.checked;
                          setFieldsToView(obj);
                        }}
                      />
                    }
                    label={translate0("Price")}
                  />
                </Grid>

                <Grid item xs={4} sm={3} md={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={fieldsToView.availability}
                        onChange={(e) => {
                          let obj = { ...fieldsToView };
                          obj.availability = e.target.checked;
                          setFieldsToView(obj);
                        }}
                      />
                    }
                    label={translate0("Availability")}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <TextField
                label="Page Gap"
                value={pageGap}
                onChange={(e) => setPageGap(e.target.value.replace(/\D/g, ""))}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <Button
            variant="outlined"
            size="small"
            fullWidth
            sx={{ pointerEvents: "none" }}
          >
            {translate0("Select Medicines")}
          </Button>
          <FormControl sx={{ mt: 2 }} size="small" fullWidth>
            <InputLabel id="demo-select-small">
              {translate0("Selection Type")}
            </InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={selectionType}
              label={translate0("Selection Type")}
              onChange={handleSelectionChange}
            >
              <MenuItem value={0}>{translate0("Manual Selection")}</MenuItem>
              <MenuItem value={1}>{translate0("By Category")}</MenuItem>
              <MenuItem value={2}>{translate0("By Dosage Form")}</MenuItem>
              <MenuItem value={3}>{translate0("All")}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12}>
          <BrochureSelectionSection
            selectionType={selectionType}
            siteLang={siteLang}
            setSelectedForPreview={setSelectedForPreview}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            size="small"
            fullWidth
            color="success"
            onClick={() => setPreviewDlg(true)}
          >
            {translate0("Preview")}
          </Button>
          {/* Preview Dialog */}
          <PreviewBrochureDlg
            open={previewDlg}
            setOpen={setPreviewDlg}
            selectedForPreview={selectedForPreview}
            settings={{ date, headerText, footerText, fieldsToView, pageGap }}
            siteLang={siteLang}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
