import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  createFilterOptions,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ProductListEntry from "./ProductListEntry";
import { PF } from "../../../../config";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useDispatch, useSelector } from "react-redux";
import {
  getDosages,
  getMedicines,
} from "../../../../reduxToolkit/features/adminPanel/medicine";
import CourierListEntry from "./CourierListEntry";
import getSymbolFromCurrency from "currency-symbol-map";
import { getCurrency } from "../../../../reduxToolkit/features/adminPanel/misc";

// Limit options to show in autocomplete
const OPTIONS_LIMIT = 10;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) => {
  return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
};

const EditCourierList = ({ courierListEntries, setCourierListEntries }) => {
  const dispatch = useDispatch();

  //   Store
  const { currencyList } = useSelector((state) => state.misc);

  useEffect(() => {
    if (!(currencyList && currencyList.length > 0)) dispatch(getCurrency());
  }, []);

  const handleCurrencyChange = (e, newValue) => {
    let obj = { ...newEntry };
    if (newValue && newValue._id) obj.currencyTypeId = newValue._id;
    setNewEntry(obj);
  };

  const initialNewEntry = {
    productName: "",
    productQty: "",
    weight: "",
    amount: "",
    shippingMethod: "",
    destination: "",
    currencyTypeId: "",
    isPaid: true,
    date: new Date(),
  };
  const [newEntry, setNewEntry] = useState(initialNewEntry);

  const handleNewEntryChange = (e, key, isNumeric) => {
    let obj = { ...newEntry };
    if (isNumeric) obj[key] = e.target.value.replace(/\D/g, "");
    else obj[key] = e.target.value;
    setNewEntry(obj);
  };

  const addNewEntry = () => {
    let entryArr = [...courierListEntries];
    entryArr.push(newEntry);
    entryArr = entryArr.sort((a, b) =>
      new Date(a.date) > new Date(b.date) ? 1 : -1
    );
    setCourierListEntries(entryArr);
    setNewEntry(initialNewEntry);
  };

  return (
    <>
      <Box sx={{ mb: 2, border: "1px solid gray", borderRadius: ".25rem" }}>
        <CourierListEntry
          courierListEntries={courierListEntries}
          setCourierListEntries={setCourierListEntries}
          editable={true}
        />
      </Box>

      <Box>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MobileDatePicker
            label="Date"
            value={newEntry.date}
            onChange={(newValue) => {
              let obj = { ...newEntry };
              obj.date = newValue;
              setNewEntry(obj);
            }}
            renderInput={(params) => (
              <TextField {...params} size="small" fullWidth sx={{ my: 1 }} />
            )}
          />
        </LocalizationProvider>
      </Box>

      <TextField
        label="Destination"
        value={newEntry.destination}
        onChange={(e) => handleNewEntryChange(e, "destination")}
        size="small"
        variant="outlined"
        fullWidth
        sx={{ my: 1 }}
      />

      <TextField
        label="Shipping Method"
        value={newEntry.shippingMethod}
        onChange={(e) => handleNewEntryChange(e, "shippingMethod")}
        size="small"
        variant="outlined"
        fullWidth
        sx={{ my: 1 }}
      />

      <TextField
        label="Product Name"
        value={newEntry.productName}
        onChange={(e) => handleNewEntryChange(e, "productName")}
        size="small"
        variant="outlined"
        fullWidth
        sx={{ my: 1 }}
      />

      <TextField
        label="Weight"
        value={newEntry.weight}
        onChange={(e) => handleNewEntryChange(e, "weight")}
        size="small"
        variant="outlined"
        fullWidth
        sx={{ my: 1 }}
      />

      <TextField
        label="Qty"
        value={newEntry.productQty}
        onChange={(e) => handleNewEntryChange(e, "productQty", true)}
        size="small"
        variant="outlined"
        fullWidth
        sx={{ my: 1 }}
      />

      <Grid container spacing={1}>
        <Grid item xs={12} lg={6}>
          <TextField
            label="Amount"
            value={newEntry.amount}
            onChange={(e) => handleNewEntryChange(e, "amount", true)}
            size="small"
            variant="outlined"
            fullWidth
            sx={{ my: 1 }}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box>
            {currencyList && currencyList.length > 0 && (
              <Autocomplete
                size="small"
                onChange={(e, newValue) => handleCurrencyChange(e, newValue)}
                disablePortal
                options={currencyList}
                defaultValue={currencyList.find(
                  (c) => c.currencyName === "BDT"
                )}
                filterOptions={filterOptions}
                getOptionLabel={(option) =>
                  "(" +
                  getSymbolFromCurrency(option.currencyName) +
                  ") " +
                  option.currencyName
                }
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{
                      "& > img": { mr: 2, flexShrink: 0 },
                    }}
                    {...props}
                  >
                    ({getSymbolFromCurrency(option.currencyName)}){" "}
                    {option.currencyName}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Currency"
                    variant="outlined"
                    size="small"
                    fullWidth
                    sx={{ my: 1 }}
                  />
                )}
              />
            )}
          </Box>
        </Grid>
      </Grid>

      <FormControlLabel
        control={
          <Checkbox
            checked={newEntry.isPaid}
            onChange={(e) => {
              let obj = { ...newEntry };
              obj.isPaid = e.target.checked;
              setNewEntry(obj);
            }}
          />
        }
        label="Paid"
      />

      <Button
        color="success"
        variant="contained"
        size="small"
        fullWidth
        onClick={addNewEntry}
      >
        Add Entry
      </Button>
    </>
  );
};

export default EditCourierList;
