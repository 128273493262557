import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../config";

// Create Delivery Method
export const createDeliveryMethod = createAsyncThunk(
  "delivery/createDeliveryMethod",
  async (data) => {
    const res = await axiosInstance.post("/api/delivery/create", data);
    return res.data;
  }
);

// get all delivery Methods
export const getAllDeliveryMethods = createAsyncThunk(
  "delivery/getAllDeliveryMethods",
  async () => {
    const res = await axiosInstance.get("/api/delivery/get-all");
    return res.data;
  }
);

// remove delivery method
export const removeDeliveryMethods = createAsyncThunk(
  "delivery/removeDeliveryMethods",
  async (data) => {
    const res = await axiosInstance.post("/api/delivery/remove", data);
    return res.data;
  }
);

// Update Delivery Method
export const updateDeliveryMethod = createAsyncThunk(
  "delivery/updateDeliveryMethod",
  async (data) => {
    const res = await axiosInstance.post("/api/delivery/update", data);
    return res.data;
  }
);

// Initial State
const initialState = {
  pending: false,
  error: false,
  deliveryMethods: [],
  snackNotiDelivery: {},
};

// Slice
export const deliverySlice = createSlice({
  name: "delivery",
  initialState,
  reducers: {
    resetSnkDelivery(state) {
      state.snackNotiDelivery = {};
    },
  },
  extraReducers: {
    [createDeliveryMethod.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [createDeliveryMethod.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiDelivery = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [createDeliveryMethod.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [getAllDeliveryMethods.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getAllDeliveryMethods.fulfilled]: (state, action) => {
      state.pending = false;
      state.deliveryMethods = action.payload;
    },
    [getAllDeliveryMethods.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [removeDeliveryMethods.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [removeDeliveryMethods.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiDelivery = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [removeDeliveryMethods.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [updateDeliveryMethod.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [updateDeliveryMethod.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiDelivery = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [updateDeliveryMethod.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
  },
});

export const { resetSnkDelivery } = deliverySlice.actions;
export default deliverySlice.reducer;
