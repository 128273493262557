import React, { Fragment } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import {
  AppBar,
  Autocomplete,
  Checkbox,
  createFilterOptions,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
// Icon
import UpgradeIcon from "@mui/icons-material/Upgrade";
import CancelIcon from "@mui/icons-material/Cancel";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveIcon from "@mui/icons-material/Remove";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddIcon from "@mui/icons-material/Add";

import LoadingButton from "@mui/lab/LoadingButton";
import MedicineImageUpload from "../components/MedicineImageUpload";
import { useEffect } from "react";
import MedicineVideoUpload from "../components/MedicineVideoUpload";
import { useDispatch, useSelector } from "react-redux";
import {
  createMedicine,
  getCompanies,
  getDosages,
  getMedCategories,
  getMedicines,
  getSeoTags,
  updateMedicine,
  uploadMedicineImages,
  uploadMedicineVideos,
} from "../../../../reduxToolkit/features/adminPanel/medicine";
import { Box } from "@mui/system";
import { PF } from "../../../../config";
import { getSuppliers } from "../../../../reduxToolkit/features/adminPanel/supplier";
import AddCompanyDialog from "./AddCompanyDialog";
import AddCategoryDialog from "./AddCategoryDialog";
import AddSeoTags from "./AddSeoTags";
import AddSupplier from "../../Supplier/dialog/AddSupplier";
import { Info } from "@mui/icons-material";
import AdditionalPage from "../components/AdditionalPage";
import { additionalPages } from "../data/additionalPages";
import ManageMedicineCategory from "../components/ManageMedicineCategory";
import { useRef } from "react";
import {
  removeTempPic,
  resetGenericFileResponse,
} from "../../../../reduxToolkit/features/adminPanel/misc";
import { getNotification } from "../../../../reduxToolkit/features/adminPanel/notification";
// Theme
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import FileUpload from "../components/FileUpload";
import AddDosageDialog from "./AddDosageDialog";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import LoadingBox from "../../../../components/misc/Loading";
import InfoTut from "../../../../components/InfoTut";
// Transition
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Limit options to show in autocomplete
const OPTIONS_LIMIT = 5;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) => {
  return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
};

export default function EditMedicineDialog({
  editMedicineDlg,
  setEditMedicineDlg,
  lang,
  langId,
  medListEn,
  editMedicineBuffer,
  setEditMedicineBuffer,
}) {
  // Use Ref
  const additionalSectionBtnRef = useRef();
  // Media Query
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  // Use states
  const [formLoading, setFormLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [disableForm, setDisableForm] = useState(false);
  const [visible, setVisible] = useState(true);
  const [availability, setAvailability] = useState(0);
  const [fieldDisabled, setFieldDisabled] = useState(false);
  // identification
  const [englishVariantId, setEnglishVariantId] = useState("");
  const [englishVariantLoading, setEnglishVariantLoading] = useState(false);
  const [englishVariant, setEnglishVariant] = useState(null);
  const [engVarAutoCompleteLoader, setEngVarAutoCompleteLoader] =
    useState(false);
  const [genericBrand, setGenericBrand] = useState("");
  const [generic, setGeneric] = useState("");
  const [companyLoading, setCompanyLoading] = useState(false);
  const [companyId, setCompanyId] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [nonEngCompany, setNonEngCompany] = useState(null);

  const [companyList, setCompanyList] = useState([]);
  const [companyListEn, setCompanyListEn] = useState([]);
  const [addCompanyDlg, setAddCompanyDlg] = useState(false);
  const [originator, setOriginator] = useState("");
  const [subCatId, setSubCatId] = useState([]);
  const [nonEngSubCat, setNonEngSubCat] = useState([]);
  const [subCatName, setSubCatName] = useState([]);
  const [subCatList, setSubCatList] = useState([]);
  const [subCatListEn, setSubCatListEn] = useState([]);
  const [catList, setCatList] = useState([]);
  const [catListEn, setCatListEn] = useState([]);
  const [manageCatDlg, setManageCatDlg] = useState(false);
  const [existingCatDlg, setExistingCatDlg] = useState(false);
  const [addCatDlg, setAddCatDlg] = useState(false);
  const [prescribed, setPrescribed] = useState(true);
  const [topSeller, setTopSeller] = useState(false);
  const [dealOfTheWeek, setDealOfTheWeek] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [stock, setStock] = useState(false);
  const [stockCount, setStockCount] = useState(0);
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);

  // SEO
  const [metaKeyword, setMetaKeyword] = useState("");
  const [pageTitle, setPageTitle] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [tags, setTags] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [nonEngTags, setNonEngTags] = useState([]);
  const [tagListEn, setTagListEn] = useState([]);
  const [addTagDlg, setAddTagDlg] = useState(false);

  // Variance
  const [variants, setVariants] = useState([]);
  const [addVariantSection, setAddVariantSection] = useState(0);
  const [dosageList, setDosageList] = useState([]);
  const [dosageListEn, setDosageListEn] = useState([]);
  const [variantDetectorId, setVariantDetectorId] = useState([]);
  const [addDosageDlg, setAddDosageDlg] = useState(false);
  const [variantArr, setVariantIdArr] = useState([]);
  const [dosageForm, setDosageForm] = useState([]);
  const [strength, setStrength] = useState([]);
  const [packTypes, setPackTypes] = useState([]);
  const [avgPriceOfOriginator, setAvgPriceOfOriginator] = useState([]);
  const [sellingPrice, setSellingPrice] = useState([]);
  const [discount, setDiscount] = useState([]);
  const [vipSellingPrice, setVipSellingPrice] = useState([]);
  const [weight, setWeight] = useState([]);
  const [
    companyPatientSellingPriceUpdated,
    setCompanyPatientSellingPriceUpdated,
  ] = useState([]);
  const [companyPatientSellingPriceOld, setCompanyPatientSellingPriceOld] =
    useState([]);
  const [companyMRP, setCompanyMRP] = useState([]);
  const [showInFront, setShowInFront] = useState([]);
  const [minimumOrderQty, setMinimumOrderQty] = useState([]);

  // Additional Section
  const [additionalSectionPrompt, setAdditionalSectionPrompt] = useState(false);
  const [addSectionName, setAddSectionName] = useState("");
  const [additionalSectionList, setAdditionalSectionList] = useState([]);

  // store
  const { companies, categories, seoTags, updateMedicineResponse, dosages } =
    useSelector((state) => state.medicine);

  // localData
  const localAdminData = JSON.parse(localStorage.getItem("adminLoginInfo"));

  // Use Effect
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCompanies());
    dispatch(getMedCategories());
    dispatch(getSuppliers());
    dispatch(getSeoTags());
    dispatch(getMedicines());
    dispatch(getDosages());
  }, []);

  // Check Create medicine response
  useEffect(() => {
    if (updateMedicineResponse.message) {
      if (updateMedicineResponse.failed) {
        setTimeout(() => {
          setFormLoading(false);
        }, 500);
      } else {
        dispatch(getNotification());
        dispatch(getCompanies());
        dispatch(getMedCategories());
        dispatch(getSuppliers());
        dispatch(getSeoTags());
        dispatch(getMedicines());
        setTimeout(() => {
          setFormLoading(false);
          handleEditMedicineDlgClose();
        }, 2000);
      }
    }
  }, [updateMedicineResponse]);

  useEffect(() => {
    // if (lang !== "en") {
    setFieldDisabled(true);
    // } else setFieldDisabled(false);
  }, [lang]);

  // Check Form Data
  useEffect(() => {
    if (genericBrand === "" || companyId === "" || subCatId.length < 1)
      setDisableForm(true);
    else if (addVariantSection > 0 && dosageForm < 1) setDisableForm(true);
    else if (variants.length > 0) {
      let counter = 0;
      variants.map((x, i) => x.dosageForm === "" && counter++);
      counter > 0 ? setDisableForm(true) : setDisableForm(false);
    } else if (lang !== "en" && englishVariantId === "") setDisableForm(true);
    else setDisableForm(false);
  }, [
    addVariantSection,
    companyId,
    dosageForm,
    englishVariantId,
    genericBrand,
    lang,
    subCatId.length,
    variants,
  ]);

  // set Company List
  useEffect(() => {
    if (lang !== "" && companies && companies.length > 0) {
      const C = companies.find((c) => c.lang === lang);
      C && setCompanyList(C.data);
      const CEn = companies.find((c) => c.lang === "en");
      CEn && setCompanyListEn(CEn.data);
    }
  }, [companies, lang]);

  // set Category List
  useEffect(() => {
    if (lang !== "" && categories && categories.length > 0) {
      const C = categories.find((c) => c.lang === lang);
      const CEn = categories.find((c) => c.lang === "en");
      let arr = [];
      if (C && C.data.length > 0) {
        setCatList(C.data);
        C.data.map((x, i) => {
          if (x.subCatList) {
            let arr2 = [];
            x.subCatList.map((y, j) => {
              let obj = {
                ...y,
                catName: x.catName,
              };
              arr2.push(obj);
            });
            arr = [...arr, ...arr2];
          }
          setSubCatList(arr);
        });
      } else setSubCatList([]);
      arr = [];
      if (CEn && CEn.data.length > 0) {
        setCatListEn(CEn.data);
        CEn.data.map((x, i) => {
          if (x.subCatList) {
            let arr2 = [];
            x.subCatList.map((y, j) => {
              let obj = {
                ...y,
                catName: x.catName,
              };
              arr2.push(obj);
            });
            arr = [...arr, ...arr2];
          }
          setSubCatListEn(arr);
        });
      } else setSubCatListEn([]);
    }
  }, [categories, lang]);

  // set Dosage List
  useEffect(() => {
    if (lang !== "" && dosages && dosages.length > 0) {
      const D = dosages.find((d) => d.lang === lang);
      if (D) setDosageList(D.data);
      else setDosageList([]);
      const DEn = dosages.find((d) => d.lang === "en");
      if (DEn && DEn.data) setDosageListEn(DEn.data);
      else setDosageListEn([]);
    }
  }, [dosages, lang]);

  // Set seo tag lists
  useEffect(() => {
    if (lang !== "" && seoTags && seoTags.length > 0) {
      const S = seoTags.find((s) => s.lang === lang);
      const SEn = seoTags.find((s) => s.lang === "en");
      S && setTagList(S.data);
      SEn && SEn.data && setTagListEn(SEn.data);
    }
  }, [lang, seoTags]);

  // set default Seo values
  // useEffect(() => {
  //   let str = "";
  //   if (strength && strength.length > 0) {
  //     strength.map((x, i) => {
  //       if (i === 0) str = x;
  //       else str = str + ", " + x;
  //       return 0;
  //     });
  //   }

  //   let dosage = "";
  //   if (dosageForm && dosageForm.length > 0) {
  //     dosageForm.map((x, i) => {
  //       if (i === 0) dosage = getDosageName(x) + "(" + strength[i] + ")";
  //       else
  //         dosage = dosage + ", " + getDosageName(x) + "(" + strength[i] + ")";
  //       return 0;
  //     });
  //   }

  //   let subCat = "";
  //   if (subCatName && subCatName.length > 0) {
  //     subCatName.map((x, i) => {
  //       if (i === 0) subCat = x;
  //       else subCat = subCat + ", " + x;
  //       return 0;
  //     });
  //   }

  //   setMetaKeyword(genericBrand !== "" ? genericBrand : "{_genBrand}");

  //   setPageTitle(
  //     "Buy" +
  //       (genericBrand !== "" ? " " + genericBrand : " {_genBrand}") +
  //       (str !== "" ? " " + str : " {_str}") +
  //       " online- Generic" +
  //       (generic !== "" ? " " + generic : " {_gen}") +
  //       "- " +
  //       (companyName !== "" ? " " + companyName : " {_company}")
  //   );
  //   setMetaTitle(
  //     "Buy" +
  //       (genericBrand !== "" ? " " + genericBrand : " {_genBrand}") +
  //       (str !== "" ? " " + str : " {_str}") +
  //       " online- Generic" +
  //       (generic !== "" ? " " + generic : " {_gen}") +
  //       "- " +
  //       (companyName !== "" ? " " + companyName : " {_company}")
  //   );
  //   setMetaDesc(
  //     "Buy Generic" +
  //       (generic !== "" ? " " + generic : " {_gen}") +
  //       " prescription medicine for" +
  //       (subCat !== "" ? " " + subCat + "." : " {_subCat}.") +
  //       (genericBrand !== "" ? " " + genericBrand : " {_genBrand}") +
  //       (dosage !== "" ? " " + dosage : " {_dosage}") +
  //       " ask price online- info@elifesavingmedicine.com. Bangladesh "
  //   );
  // }, [companyName, dosageForm, generic, genericBrand, strength, subCatName]);

  // set Up additional Sections
  useEffect(() => {
    setAdditionalSectionList(additionalPages);
  }, []);

  // Functions
  const handleEditMedicineDlgClose = () => {
    setEditMedicineBuffer("");
    setFormLoading(false);
    setDisableForm(false);
    setEnglishVariant(null);
    setVisible(true);
    setAvailability(0);
    setGenericBrand("");
    setGeneric("");
    setCompanyId("");
    setCompanyName("");
    setOriginator("");
    setSubCatId([]);
    setPrescribed(true);
    setStock(true);
    setStockCount(0);
    setImages([]);
    setVideos([]);
    setAddVariantSection(0);
    setVariants([]);
    setVariantDetectorId([]);
    setDosageForm([]);
    setStrength([]);
    setPackTypes([]);
    setAvgPriceOfOriginator([]);
    setSellingPrice([]);
    setCompanyPatientSellingPriceUpdated([]);
    setCompanyPatientSellingPriceOld([]);
    setCompanyMRP([]);
    setShowInFront([]);
    setMinimumOrderQty([]);
    setAdditionalSectionList(additionalPages);
    setTopSeller(false);
    setDealOfTheWeek(false);
    setIsNew(false);
    setTags([]);
    // Non Eng
    setNonEngCompany(null);
    setNonEngSubCat([]);
    setNonEngTags([]);
    setEnglishVariantId("");

    dispatch(resetGenericFileResponse());
    setEditMedicineDlg(false);
  };

  const handleCompanySelection = (e, newValue) => {
    if (newValue) {
      lang === "en"
        ? setCompanyId(newValue.fixedCompanyId)
        : setCompanyId(newValue.companyDetectorId);
      setCompanyName(newValue.companyName);
    } else {
      setCompanyId("");
      setCompanyName("");
    }
  };

  const handleCategory = () => {
    setManageCatDlg(true);
  };

  const handleCategorySelection = (e, newValue) => {
    if (newValue.length > 0) {
      let arrId = [];
      let arrName = [];

      newValue.forEach((x, i) => {
        lang === "en"
          ? arrId.push(x.fixedSubCatId)
          : arrId.push(x.subCatDetectorId);
        arrName.push(x.subCatName);
      });
      setSubCatId(arrId);
      setSubCatName(arrName);
    } else {
      setSubCatId([]);
      setSubCatName([]);
    }
  };

  const handleStockCount = (event) => {
    const result = event.target.value.replace(/\D/g, "");
    setStockCount(result);
  };
  // Variation
  useEffect(() => {
    const arr = [];
    dosageForm.map((x, i) => {
      const obj = {};
      if (lang !== "en") {
        obj.variantDetectorId = variantDetectorId[i];
      }
      obj.fixedVariantId = variantArr[i];
      // obj.fixedVariantId = variantDetectorId[i];
      obj.dosageForm = dosageForm[i];
      obj.strength = strength[i];
      obj.packTypes = packTypes[i];
      obj.avgPriceOfOriginator = avgPriceOfOriginator[i];
      obj.sellingPrice = sellingPrice[i];
      obj.discount = discount[i];
      obj.vipSellingPrice = vipSellingPrice[i];
      obj.companyPatientSellingPriceUpdated =
        companyPatientSellingPriceUpdated[i];
      obj.companyPatientSellingPriceOld = companyPatientSellingPriceOld[i];
      obj.companyMRP = companyMRP[i];
      obj.showInFront = showInFront[i];
      obj.minimumOrderQty = minimumOrderQty[i];
      obj.weight = weight[i] || 0;
      arr.push(obj);
      return 0;
    });
    setVariants(arr);
  }, [
    avgPriceOfOriginator,
    companyMRP,
    showInFront,
    companyPatientSellingPriceOld,
    companyPatientSellingPriceUpdated,
    variantDetectorId,
    dosageForm,
    minimumOrderQty,
    packTypes,
    sellingPrice,
    discount,
    strength,
    variantArr,
    vipSellingPrice,
    weight,
  ]);

  const handleAddVariation = () => {
    setAddVariantSection(addVariantSection + 1);
    let arr = [...dosageForm];
    arr.push("");
    setDosageForm(arr);
    if (lang !== "en") {
      arr = [...variantDetectorId];
      arr.push("");
      setVariantDetectorId(arr);
    }
    arr = [...strength];
    arr.push("");
    setStrength(arr);
    arr = [...packTypes];
    arr.push("");
    setPackTypes(arr);
    arr = [...avgPriceOfOriginator];
    arr.push(0);
    setAvgPriceOfOriginator(arr);
    arr = [...sellingPrice];
    arr.push(0);
    setSellingPrice(arr);
    arr = [...discount];
    arr.push(0);
    setDiscount(arr);
    arr = [...vipSellingPrice];
    arr.push(0);
    setVipSellingPrice(arr);
    arr = [...companyPatientSellingPriceUpdated];
    arr.push(0);
    setCompanyPatientSellingPriceUpdated(arr);
    arr = [...companyPatientSellingPriceOld];
    arr.push(0);
    setCompanyPatientSellingPriceOld(arr);

    arr = [...companyMRP];
    arr.push(0);
    setCompanyMRP(arr);

    arr = [...showInFront];
    arr.push(true);
    setShowInFront(arr);

    arr = [...minimumOrderQty];
    arr.push(0);
    setMinimumOrderQty(arr);
    arr = [...weight];
    arr.push(0);
    setWeight(arr);
  };

  const handleDosageForm = (dosageName, i) => {
    let arr = [...dosageForm];
    arr[i] = dosageName;
    setDosageForm(arr);
  };

  // const handleDosageSelect = (e, newValue, i) =>
  //   newValue
  //     ? handleDosageForm(newValue.dosageName, i)
  //     : handleDosageForm("", i);

  // Handle Dosage Form
  const handleDosageSelect = (e, newValue, i) => {
    newValue &&
      handleDosageForm(
        lang !== "en" ? newValue.dosageDetectorId : newValue.fixedDosageId,
        i
      );
  };

  const getDosageName = (dosageId) => {
    let dosageName = "";
    const D = dosageList.find((d) =>
      lang !== "en" ? d.dosageDetectorId : d.fixedDosageId === dosageId
    );
    if (D && D.dosageName) dosageName = D.dosageName;
    if (lang !== "en") {
      const DEn = dosageListEn.find((d) => d.fixedDosageId === dosageId);
      if (DEn && DEn.dosageName)
        dosageName = dosageName + " - " + DEn.dosageName;
    }
    return dosageName;
  };

  const handleStrength = (e, i) => {
    let arr = [...strength];
    arr[i] = e.target.value;
    setStrength(arr);
  };
  const handlePackTypes = (e, i) => {
    let arr = [...packTypes];
    arr[i] = e.target.value;
    setPackTypes(arr);
  };
  const handleAvgPriceOfOriginator = (e, i) => {
    let arr = [...avgPriceOfOriginator];
    arr[i] = e.target.value.replace(/\D/g, "");
    setAvgPriceOfOriginator(arr);
  };
  const handleSellingPrice = (e, i) => {
    let arr = [...sellingPrice];
    arr[i] = e.target.value.replace(/\D/g, "");
    setSellingPrice(arr);
  };

  const handleDiscount = (e, i) => {
    let arr = [...discount];
    arr[i] = e.target.value.replace(/\D/g, "");
    setDiscount(arr);
  };
  const handleVipSellingPrice = (e, i) => {
    let arr = [...vipSellingPrice];
    arr[i] = e.target.value.replace(/\D/g, "");
    setVipSellingPrice(arr);
  };
  const handleCompanyPatientSellingPriceUpdated = (e, i) => {
    let arr = [...companyPatientSellingPriceUpdated];
    arr[i] = e.target.value.replace(/\D/g, "");
    setCompanyPatientSellingPriceUpdated(arr);
  };
  const handleCompanyPatientSellingPriceOld = (e, i) => {
    let arr = [...companyPatientSellingPriceOld];
    arr[i] = e.target.value.replace(/\D/g, "");
    setCompanyPatientSellingPriceOld(arr);
  };
  const handleCompanyMrp = (e, i) => {
    let arr = [...companyMRP];
    arr[i] = e.target.value.replace(/\D/g, "");
    setCompanyMRP(arr);
  };

  const handleShowInFront = (e, i) => {
    let arr = [...showInFront];
    arr[i] = e.target.checked;
    setShowInFront(arr);
  };

  const handleMinimumOrderQty = (e, i) => {
    let arr = [...minimumOrderQty];
    arr[i] = e.target.value.replace(/\D/g, "");
    setMinimumOrderQty(arr);
  };
  const handleWeight = (e, i) => {
    let arr = [...weight];
    arr[i] = e.target.value.replace(/\D/g, "");
    setWeight(arr);
  };

  const handleRemoveVariant = (i) => {
    let arr = [...dosageForm];
    arr.splice(i, 1);
    setDosageForm(arr);
    if (lang !== "en") {
      arr = [...variantDetectorId];
      arr.splice(i, 1);
      setVariantDetectorId(arr);
    }
    arr = [...strength];
    arr.splice(i, 1);
    setStrength(arr);
    arr = [...packTypes];
    arr.splice(i, 1);
    setPackTypes(arr);
    arr = [...avgPriceOfOriginator];
    arr.splice(i, 1);
    setAvgPriceOfOriginator(arr);
    arr = [...sellingPrice];
    arr.splice(i, 1);
    setSellingPrice(arr);
    arr = [...discount];
    arr.splice(i, 1);
    setDiscount(arr);
    arr = [...vipSellingPrice];
    arr.splice(i, 1);
    setVipSellingPrice(arr);
    arr = [...companyPatientSellingPriceUpdated];
    arr.splice(i, 1);
    setCompanyPatientSellingPriceUpdated(arr);
    arr = [...companyPatientSellingPriceOld];
    arr.splice(i, 1);
    setCompanyPatientSellingPriceOld(arr);

    arr = [...companyMRP];
    arr.splice(i, 1);
    setCompanyMRP(arr);

    arr = [...showInFront];
    arr.splice(i, 1);
    setShowInFront(arr);

    arr = [...minimumOrderQty];
    arr.splice(i, 1);
    setMinimumOrderQty(arr);
    arr = [...weight];
    arr.splice(i, 1);
    setWeight(arr);
    setAddVariantSection(addVariantSection - 1);
  };

  const handleTags = (e, newValue) => {
    newValue && setTags(newValue.fixedId);
    if (newValue) {
      let tagIdArr = [];
      newValue.map((x, i) => {
        lang === "en"
          ? tagIdArr.push(x.fixedId)
          : tagIdArr.push(x.tagDetectorId);
      });
      setTags(tagIdArr);
    }
  };

  // Additional Section
  const handleCreateAdditionalSection = () => {
    const obj = {
      sectionName: addSectionName,
      content: "",
      contentWithHtml: "",
    };
    const arr = [...additionalSectionList];
    arr.push(obj);
    setAdditionalSectionList(arr);
    setAddSectionName("");
    setAdditionalSectionPrompt(false);
  };

  // update Medicine Final Button
  const handleUpdateMedicine = () => {
    setFormLoading(true);
    let obj = {};
    if (editMedicineBuffer.createdAt)
      obj.createdAt = editMedicineBuffer.createdAt;
    if (editMedicineBuffer.fixedMedicineId)
      obj.fixedMedicineId = editMedicineBuffer.fixedMedicineId;
    obj.prevImg = editMedicineBuffer.image;
    obj.images = images;
    obj.prevVid = editMedicineBuffer.video;
    obj.videos = videos;
    obj.genericBrand = genericBrand;
    obj.generic = generic;
    obj.originator = originator;
    obj.companyId = companyId;
    obj.subCatId = subCatId;
    obj.variants = variants;
    obj.metaKeyword = metaKeyword;
    obj.pageTitle = pageTitle;
    obj.metaTitle = metaTitle;
    obj.metaDesc = metaDesc;
    obj.tags = tags;
    obj.additionalSectionList = additionalSectionList;
    obj.visible = visible;
    obj.availability = availability;
    obj.prescribed = prescribed;
    obj.topSeller = topSeller;
    obj.dealOfTheWeek = dealOfTheWeek;
    obj.isNew = isNew;
    obj.stock = stock;
    obj.stockCount = stockCount;
    // Non Eng
    obj.englishVariantId = englishVariantId;
    // Img
    obj.images.map((x, i) => {
      if (typeof x.file !== "undefined") {
        let data = new FormData();
        const ext = x.file.name.split(".");
        const filename =
          Date.now() +
          "-" +
          genericBrand.replace(/\s+/g, "") +
          "-medicine-pic." +
          ext.slice(-1);
        data.append("name", filename);
        data.append("medicineImg", x.file);
        obj.images[i].src = filename;
        dispatch(uploadMedicineImages(data));
      }
    });
    // Vid
    obj.videos.map((x, i) => {
      if (typeof x.file !== "undefined") {
        if (x.externalLink) {
          obj.videos[i].src = x.file;
        } else {
          let data = new FormData();
          const ext = x.file.name.split(".");
          const filename =
            Date.now() +
            "-" +
            genericBrand.replace(/\s+/g, "") +
            "-medicine-vid." +
            ext.slice(-1);
          data.append("name", filename);
          data.append("medicineVid", x.file);
          obj.videos[i].src = filename;
          dispatch(uploadMedicineVideos(data));
        }
      }
    });

    dispatch(
      updateMedicine({
        id: editMedicineBuffer._id,
        creatorId: localAdminData._id,
        lang,
        data: [obj],
        langId,
      })
    );
  };

  // handle English Variant
  const handleEnglishVariant = (e, newValue, fromBuffer) => {
    setEnglishVariantLoading(true);
    if (newValue) {
      setFieldDisabled(false);
      const M = { ...newValue };
      setEnglishVariantId(
        newValue.medicineDetectorId
          ? newValue.medicineDetectorId
          : newValue.fixedMedicineId
      );
      const engV = medListEn.find(
        (m) =>
          m.fixedMedicineId ===
          (newValue.medicineDetectorId
            ? newValue.medicineDetectorId
            : newValue.fixedMedicineId)
      );
      engV && setEnglishVariant(engV);
      if (M) {
        // Company Selector
        const C = companyList.find((c) => c.companyDetectorId === M.companyId);
        if (C) {
          setNonEngCompany(C);
          setCompanyId(C.companyDetectorId);
          setCompanyName(C.companyName);
        }
        // SubCategory Selector
        if (M.subCats && M.subCats.length > 0) {
          let ScArr = [];

          M.subCats.map((x, i) => {
            const Sc = subCatList.find(
              (sc) => sc.subCatDetectorId === x.subCatId
            );
            if (Sc) ScArr.push(Sc);
            return 0;
          });
          let arrId = [];
          let arrName = [];
          ScArr.map((x, i) => {
            arrId.push(x.subCatDetectorId);
            arrName.push(x.subCatName);
          });
          setSubCatId(arrId);
          setSubCatName(arrName);
          setNonEngSubCat([...ScArr]);
        }
        // Set checklists
        setVisible(M.visible);
        setAvailability(M.availability || 0);
        setPrescribed(M.prescribed || true);
        setTopSeller(M.topSeller);
        setDealOfTheWeek(M.dealOfTheWeek);
        setIsNew(M.new);
        if (M.stock > 0) {
          setStock(true);
          setStockCount(M.stock);
        }

        setImages(M.image);
        setVideos(M.video);

        // Set Variant
        if (M.variantInfo && M.variantInfo.length > 0) {
          setAddVariantSection(M.variantInfo.length);
          // let dosageArr = [];
          let variantDetectorArr = [];
          let dosageArr = [];
          let strengthArr = [];
          let packTypesArr = [];
          let avgPriceArr = [];
          let sellingArr = [];
          let discountArr = [];
          let vipSellingArr = [];
          let companyPatientUpdatedArr = [];
          let companyPatientOldArr = [];
          let mrpArr = [];
          let showInFrontArr = [];
          let moqArr = [];
          let weightArr = [];
          M.variantInfo.map((x, i) => {
            variantDetectorArr[i] = fromBuffer
              ? x.variantDetectorId
              : x.fixedVariantId;
            dosageArr[i] = x.dosageForm;
            strengthArr[i] = x.strength;
            if (
              newValue.fixedMedicineId ===
                editMedicineBuffer.medicineDetectorId ||
              newValue.medicineDetectorId ===
                editMedicineBuffer.medicineDetectorId
            ) {
              // dosageArr[i] = x.dosageForm;
              strengthArr[i] = x.strength;
              packTypesArr[i] = x.packTypes;
            }
            avgPriceArr[i] = x.avgPriceOfOriginator;
            sellingArr[i] = x.sellingPrice;
            discountArr[i] = x.discount;
            vipSellingArr[i] = x.vipSellingPrice;
            companyPatientUpdatedArr[i] = x.companyPatientSellingPriceUpdated;
            companyPatientOldArr[i] = x.companyPatientSellingPriceOld;
            mrpArr[i] = x.companyMRP;
            showInFrontArr[i] = x.showInFront;
            moqArr[i] = x.minimumOrderQty;
            weightArr[i] = x.weight || 0;

            return 0;
          });
          // setDosageForm(dosageArr);
          setVariantDetectorId(variantDetectorArr);
          setDosageForm(dosageArr);

          setStrength(strengthArr);
          setPackTypes(packTypesArr);
          setAvgPriceOfOriginator(avgPriceArr);
          setSellingPrice(sellingArr);
          setDiscount(discountArr);
          setVipSellingPrice(vipSellingArr);
          setCompanyPatientSellingPriceUpdated(companyPatientUpdatedArr);
          setCompanyPatientSellingPriceOld(companyPatientOldArr);
          setCompanyMRP(mrpArr);
          setShowInFront(showInFrontArr);
          setMinimumOrderQty(moqArr);
          setWeight(weight);
        }

        // Set Tags
        if (M.tagsArr && M.tagsArr.length > 0) {
          let nonTArr = [];
          let tArr = [];
          M.tagsArr.map((x, i) => {
            let T = null;
            if (x.fixedId && x.fixedId !== "") {
              T = tagList.find((t) => t.tagDetectorId === x.fixedId);
            } else {
              T = tagList.find((t) => t.tagDetectorId === x.tagDetectorId);
            }
            if (T) {
              nonTArr.push(T);
              tArr.push(T.fixedId ? T.fixedId : T.tagDetectorId);
            }
            return 0;
          });
          setNonEngTags([...nonTArr]);
          setTags([...tArr]);
        }
      }
    } else {
      setFieldDisabled(true);
      setNonEngCompany(null);
      setNonEngSubCat([]);
      setEnglishVariantId("");
      setEnglishVariant(null);
      setVisible(true);
      setAvailability(0);
      setPrescribed(true);
      setTopSeller(false);
      setDealOfTheWeek(false);
      setIsNew(false);
      setStock(false);
      setStockCount(0);
      setAddVariantSection(0);
      setVariants([]);
      setVariantDetectorId([]);
      setDosageForm([]);
      setStrength([]);
      setPackTypes([]);
      setAvgPriceOfOriginator([]);
      setSellingPrice([]);
      setCompanyPatientSellingPriceUpdated([]);
      setCompanyPatientSellingPriceOld([]);
      setCompanyMRP([]);
      setShowInFront([]);
      setMinimumOrderQty([]);
      setNonEngTags([]);
      setImages([]);
      setTags([]);
      setVideos([]);
    }
    setTimeout(() => {
      setEnglishVariantLoading(false);
    }, 1000);
  };

  const getEngCompany = (companyName) => {
    const C = companyList.find((c) => c.companyName === companyName);
    if (C && C.companyDetectorId) {
      const CEn = companyListEn.find(
        (c) => c.fixedCompanyId === C.companyDetectorId
      );
      if (CEn && CEn.companyName) return CEn.companyName;
    }
    return "";
  };

  const getEngSubCat = (subCatName) => {
    const Sc = subCatList.find((sc) => sc.subCatName === subCatName);
    if (Sc && Sc.subCatDetectorId) {
      const ScEn = subCatListEn.find(
        (sc) => sc.fixedSubCatId === Sc.subCatDetectorId
      );
      if (ScEn && ScEn.subCatName) {
        return ScEn.subCatName;
      }
    }
    return "";
  };

  const getEngCat = (catName) => {
    const C = catList.find((c) => c.catName === catName);
    if (C && C.categoryDetectorId) {
      const CEn = catListEn.find((c) => c.fixedCatId === C.categoryDetectorId);
      if (CEn && CEn.catName) {
        return CEn.catName;
      }
    }
    return "";
  };

  const getEngDosage = (dosageName) => {
    const D = dosageList.find((d) => d.dosageName === dosageName);
    if (D && D.dosageDetectorId) {
      const DEn = dosageListEn.find(
        (d) => d.fixedDosageId === D.dosageDetectorId
      );
      if (DEn && DEn.dosageName) {
        return DEn.dosageName;
      }
    }
    return "";
  };

  const getEngTag = (tagName) => {
    const T = tagList.find((t) => t.tagName === tagName);
    if (T && T.tagDetectorId) {
      const TEn = tagListEn.find((t) => t.fixedId === T.tagDetectorId);
      if (TEn && TEn.tagName) {
        return TEn.tagName;
      }
    }
    return "";
  };

  // Set Edit Data from Buffer
  useEffect(() => {
    setPageLoading(true);
    if (editMedicineBuffer !== "" && companyList.length > 0) {
      setFieldDisabled(false);
      setEngVarAutoCompleteLoader(true);
      const a = { ...editMedicineBuffer };

      // identification
      setGenericBrand(a.genericBrand);
      setGeneric(a.generic);
      setOriginator(a.originator);
      setVisible(a.visible);
      setAvailability(a.availability);
      setPrescribed(a.prescribed);
      setTopSeller(a.topSeller);
      setDealOfTheWeek(a.dealOfTheWeek);
      setIsNew(a.isNew);
      setStockCount(a.stock);
      setMetaKeyword(a.metaKeyword);
      setMetaTitle(a.metaTitle);
      setPageTitle(a.pageTitle);
      setMetaDesc(a.metaDesc);
      a.stock > 0 ? setStock(true) : setStock(false);

      // Image  & video Selector
      setImages(a.image);
      setVideos(a.video);

      // Company Selector
      if (companyList.length > 0) {
        const C = companyList.find((c) => c.fixedCompanyId === a.companyId);

        if (C) {
          setNonEngCompany(C);
          setCompanyId(C.fixedCompanyId);
          setCompanyName(C.companyName);
        }
      }

      // SubCategory Selector
      if (a.subCats && a.subCats.length > 0) {
        let ScArr = [];
        a.subCats.map((x, i) => {
          const Sc = subCatList.find((sc) => sc.fixedSubCatId === x.subCatId);
          if (Sc) ScArr.push(Sc);
          return 0;
        });
        let arrId = [];
        let arrName = [];
        ScArr.map((x, i) => {
          arrId.push(x.fixedSubCatId);
          arrName.push(x.subCatName);
        });
        setSubCatId(arrId);
        setSubCatName(arrName);
        setNonEngSubCat([...ScArr]);
      }

      // Set Variant
      if (a.variantInfo && a.variantInfo.length > 0) {
        setAddVariantSection(a.variantInfo.length);
        let idArr = [];
        // let variantDetectorArr = [];
        let dosageArr = [];
        let strengthArr = [];
        let packTypesArr = [];
        let avgPriceArr = [];
        let sellingArr = [];
        let vipSellingArr = [];
        let companyPatientUpdatedArr = [];
        let companyPatientOldArr = [];
        let mrpArr = [];
        let showInFrontArr = [];
        let moqArr = [];
        a.variantInfo.map((x, i) => {
          idArr[i] = x.fixedVariantId;
          dosageArr[i] = x.dosageForm;
          strengthArr[i] = x.strength;
          packTypesArr[i] = x.packTypes;
          avgPriceArr[i] = x.avgPriceOfOriginator;
          sellingArr[i] = x.sellingPrice;
          discount[i] = x.discount;
          vipSellingArr[i] = x.vipSellingPrice;
          companyPatientUpdatedArr[i] = x.companyPatientSellingPriceUpdated;
          companyPatientOldArr[i] = x.companyPatientSellingPriceOld;
          mrpArr[i] = x.companyMRP;
          showInFrontArr[i] = x.showInFront;
          moqArr[i] = x.minimumOrderQty;
          weight[i] = x.weight || 0;
          return 0;
        });
        setVariantIdArr(idArr);
        // setVariantDetectorId(idArr);
        setDosageForm(dosageArr);
        setStrength(strengthArr);
        setPackTypes(packTypesArr);
        setAvgPriceOfOriginator(avgPriceArr);
        setSellingPrice(sellingArr);
        setDiscount(discount);
        setVipSellingPrice(vipSellingArr);
        setCompanyPatientSellingPriceUpdated(companyPatientUpdatedArr);
        setCompanyPatientSellingPriceOld(companyPatientOldArr);
        setCompanyMRP(mrpArr);
        setShowInFront(showInFrontArr);
        setMinimumOrderQty(moqArr);
        setWeight(weight);
      }
      // Set Tags
      if (a.tagsArr && a.tagsArr.length > 0) {
        let nonTArr = [];
        let tArr = [];
        a.tagsArr.map((x, i) => {
          const T = tagList.find((t) =>
            lang === "en"
              ? t.fixedId === x.fixedId
              : t.tagDetectorId === x.tagDetectorId
          );
          if (T) {
            nonTArr.push(T);
            lang === "en" ? tArr.push(x.fixedId) : tArr.push(x.tagDetectorId);
          }
          return 0;
        });
        setNonEngTags([...nonTArr]);
        setTags([...tArr]);
      }

      // Set Additional Section
      setAdditionalSectionList([...a.additionalSections]);

      // For Non EnglIsh
      if (lang !== "en") handleEnglishVariant(null, a, true);
    } else {
      setFieldDisabled(true);
      setEngVarAutoCompleteLoader(false);
    }
    setTimeout(() => {
      setPageLoading(false);
    }, 2500);
  }, [editMedicineBuffer, companyList]);

  // ! translate
  const [siteLang, setSiteLang] = useState("en");
  // Local Store
  const localLangData = JSON.parse(localStorage.getItem("lang"));
  // Store
  const { langs, activeLang } = useSelector((state) => state.misc);
  const [langSettings1, setLangSettings1] = useState([]);
  const [langSettings2, setLangSettings2] = useState([]);
  const [langSettings3, setLangSettings3] = useState([]);
  const [langSettings4, setLangSettings4] = useState([]);
  const [langSettings5, setLangSettings5] = useState([]);
  const [langSettings6, setLangSettings6] = useState([]);
  const title1 = "Add Medicine Dialog";
  const title2 = "Misc Words";
  const title3 = "Manage Medicine";
  const title4 = "Add Company Dialog";
  const title5 = "Add Sub Category Dialog";
  const title6 = "Edit Medicine Dialog";

  useEffect(() => {
    if (langs && langs.length > 0 && siteLang !== "") {
      const L = langs.find((l) => l.langCode === siteLang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      const A = arr.find((l) => l._title === title1);
      if (A) {
        setLangSettings1(A.data);
      }
      const B = arr.find((l) => l._title === title2);
      if (B) setLangSettings2(B.data);
      const C = arr.find((l) => l._title === title3);
      if (C) setLangSettings3(C.data);
      const D = arr.find((l) => l._title === title4);
      if (D) setLangSettings4(D.data);
      const E = arr.find((l) => l._title === title5);
      if (E) setLangSettings5(E.data);
      const F = arr.find((l) => l._title === title6);
      if (F) setLangSettings6(F.data);
    }
  }, [siteLang, langs]);

  const translate1 = (String) => translation(String, title1, 1);
  const translate2 = (String) => translation(String, title2, 2);
  const translate3 = (String) => translation(String, title3, 3);
  const translate4 = (String) => translation(String, title4, 4);
  const translate5 = (String) => translation(String, title5, 5);
  const translate6 = (String) => translation(String, title6, 6);

  const translation = (String, title, index) => {
    let translation = "";
    let settings = [];
    if (index === 1) {
      settings = langSettings1;
    } else if (index === 2) {
      settings = langSettings2;
    } else if (index === 3) {
      settings = langSettings3;
    } else if (index === 4) {
      settings = langSettings4;
    } else if (index === 5) {
      settings = langSettings5;
    } else if (index === 6) {
      settings = langSettings6;
    }
    const T = settings.find((ls) => ls.title === String);
    if (T && T.value) translation = T.value;
    else {
      let arr = [];
      if (langs && langs.length > 0 && siteLang !== "") {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.settings && LEn.settings.length > 0) arr = LEn.settings;
        const S = arr.find((l) => l._title === title);
        if (S) {
          const T = S.data.find((ls) => ls.title === String);
          if (T && T.value) translation = T.value;
        }
      }
    }
    return translation;
  };
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setSiteLang(activeLang.langCode);
    } else {
      if (localLangData) {
        setSiteLang(localLangData.langCode);
      }
    }
  }, [activeLang]);
  // ! translate

  //! Translate
  // Store
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate

  return (
    <Dialog
      fullScreen
      open={editMedicineDlg}
      onClose={() => setEditMedicineDlg(false)}
      aria-labelledby={"add-medicine-dialog"}
      TransitionComponent={Transition}
      disableEnforceFocus
    >
      <AppBar sx={{ position: "relative" }} color="info">
        <Toolbar>
          <Grid
            container
            spacing={0}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleEditMedicineDlgClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            {smUp && (
              <Grid item sx={{ flexGrow: 1 }}>
                <Typography
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                  component="div"
                >
                  {translate6("Edit Medicine")}
                </Typography>
              </Grid>
            )}
            <Grid item xs={10} sm={3} xl={2} sx={{ alignSelf: "flex-end" }}>
              <LoadingButton
                loading={formLoading ? true : false}
                loadingPosition="end"
                endIcon={<UpgradeIcon />}
                color="warning"
                variant="contained"
                onClick={handleUpdateMedicine}
                disabled={disableForm}
                fullWidth={!smUp ? true : true}
              >
                {translate6("Update")}
              </LoadingButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Grid container spacing={1}>
          {pageLoading ? (
            <Grid item xs={12} sx={{ minHeight: "90vh" }}>
              {" "}
              <LoadingBox w={"100%"} />{" "}
            </Grid>
          ) : (
            <>
              <Grid item xs={12} sm={6}>
                <MedicineImageUpload
                  images={images}
                  setImages={setImages}
                  translate1={translate1}
                  genericBrand={genericBrand}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MedicineVideoUpload
                  videos={videos}
                  setVideos={setVideos}
                  translate1={translate1}
                />
              </Grid>
              {/* Identification */}
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  size="small"
                  fullWidth
                  color="secondary"
                  sx={{ pointerEvents: "none" }}
                >
                  {translate1("Identification")}
                </Button>
              </Grid>
              <Grid item xs={lang !== "en" ? 6 : 12}>
                <TextField
                  label={translate1("Generic Brand")}
                  value={genericBrand}
                  onChange={(e) => {
                    setGenericBrand(e.target.value);
                  }}
                  fullWidth
                  variant="standard"
                  required
                  autoFocus
                  error={
                    lang !== "en" && !fieldDisabled && genericBrand.length < 1
                      ? true
                      : false
                  }
                />
              </Grid>
              {lang !== "en" && engVarAutoCompleteLoader && (
                <Grid item xs={6}>
                  <Autocomplete
                    onChange={(e, newValue) =>
                      handleEnglishVariant(e, newValue)
                    }
                    disablePortal
                    defaultValue={englishVariant ? englishVariant : null}
                    options={medListEn}
                    filterOptions={filterOptions}
                    getOptionLabel={(option) => option.genericBrand}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <LazyLoadImage
                          // <img
                          // loading="lazy"
                          // <img
                          loading="lazy"
                          src={
                            option.image.length > 0
                              ? option.image
                                  .find((i) => i.default)
                                  .src.includes("http")
                                ? option.image.find((i) => i.default).src
                                : PF +
                                  "adminPanel/medicines/img/" +
                                  option.image.find((i) => i.default).src
                              : "https://www.daveraine.com/img/products/no-image.jpg"
                          }
                          alt="genericBrand"
                          style={{
                            objectFit: "contain",
                            width: "40px",
                            height: "30px",
                          }}
                          effect="blur"
                        />{" "}
                        {option.genericBrand}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={translate1("English Variant")}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
              )}

              {englishVariantLoading ? (
                <Grid item xs={12}>
                  <LoadingBox w={"100%"} h={"100%"} />
                </Grid>
              ) : (
                <>
                  <Grid item xs={12}>
                    <TextField
                      label={translate1("Generic")}
                      value={generic}
                      onChange={(e) => setGeneric(e.target.value)}
                      fullWidth
                      variant="standard"
                      disabled={fieldDisabled}
                      error={
                        lang !== "en" && !fieldDisabled && generic.length < 1
                          ? true
                          : false
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={translate1("Originator")}
                      value={originator}
                      onChange={(e) => setOriginator(e.target.value)}
                      fullWidth
                      variant="standard"
                      disabled={fieldDisabled}
                      error={
                        lang !== "en" && !fieldDisabled && originator.length < 1
                          ? true
                          : false
                      }
                    />
                  </Grid>
                  {!fieldDisabled && (
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={0}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item xs={11} xl={11.75}>
                          <Autocomplete
                            onChange={(e, newValue) =>
                              handleCompanySelection(e, newValue)
                            }
                            disablePortal
                            defaultValue={nonEngCompany}
                            disabled={fieldDisabled}
                            options={companyList}
                            filterOptions={filterOptions}
                            getOptionLabel={(option) =>
                              option.companyName +
                              (lang !== "en"
                                ? "(" + getEngCompany(option.companyName) + ")"
                                : "")
                            }
                            renderOption={(props, option) => (
                              <Box
                                component="li"
                                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                {...props}
                              >
                                <img
                                  loading="lazy"
                                  src={
                                    option.companyLogo.includes("http")
                                      ? option.companyLogo
                                      : PF +
                                        "adminPanel/companies/" +
                                        option.companyLogo
                                  }
                                  alt="companies"
                                  style={{
                                    objectFit: "contain",
                                    width: "40px",
                                    height: "30px",
                                  }}
                                />
                                {option.companyName}
                                {lang !== "en"
                                  ? "(" +
                                    getEngCompany(option.companyName) +
                                    ")"
                                  : ""}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={translate1("Local Company")}
                                required
                                variant="standard"
                                size="small"
                                fullWidth
                                error={
                                  lang !== "en" &&
                                  !fieldDisabled &&
                                  !nonEngCompany
                                    ? true
                                    : false
                                }
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={1} xl={0.25}>
                          <Tooltip
                            title={translate4("Add Company")}
                            arrow
                            placement="top"
                          >
                            <IconButton
                              size="small"
                              sx={{ mt: 1 }}
                              onClick={() => setAddCompanyDlg(true)}
                            >
                              <AddCircleIcon color="success" />
                            </IconButton>
                          </Tooltip>
                          <AddCompanyDialog
                            addCompanyDlg={addCompanyDlg}
                            setAddCompanyDlg={setAddCompanyDlg}
                            lang={lang}
                            companyListEn={companyListEn}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {!fieldDisabled && (
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={0}
                        justifyContent="space-between"
                      >
                        <Grid item xs={11} xl={11.75}>
                          <Autocomplete
                            onChange={(e, newValue) =>
                              handleCategorySelection(e, newValue)
                            }
                            disablePortal
                            multiple
                            defaultValue={
                              nonEngSubCat.length > 0 ? nonEngSubCat : []
                            }
                            disabled={fieldDisabled}
                            options={subCatList}
                            filterOptions={filterOptions}
                            getOptionLabel={(option) =>
                              option.subCatName + "( " + option.catName + " )"
                            }
                            renderOption={(props, option) => (
                              <Box
                                component="li"
                                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                {...props}
                              >
                                {option.subCatName + "-"}{" "}
                                {lang !== "en" &&
                                  getEngSubCat(option.subCatName)}
                                {"( " + option.catName + "-"}
                                {lang !== "en" &&
                                  getEngCat(option.catName)}{" "}
                                {" )"}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={translate1("Sub Category")}
                                required
                                variant="standard"
                                size="small"
                                fullWidth
                                error={
                                  lang !== "en" &&
                                  !fieldDisabled &&
                                  nonEngSubCat.length < 1
                                    ? true
                                    : false
                                }
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={1} xl={0.25}>
                          <Tooltip
                            title={translate5("Add Sub Category")}
                            arrow
                            placement="top"
                          >
                            <IconButton
                              size="small"
                              sx={{ mt: 1 }}
                              onClick={handleCategory}
                            >
                              <AddCircleIcon color="success" />
                            </IconButton>
                          </Tooltip>
                          <AddCategoryDialog
                            lang={lang}
                            addCategoryDlg={addCatDlg}
                            setAddCategoryDlg={setAddCatDlg}
                            categoryListEn={catListEn}
                          />
                          {/* Select sub cat manage option */}
                          <Dialog
                            open={manageCatDlg}
                            onClose={() => setManageCatDlg(false)}
                            aria-labelledby={"manage-category"}
                          >
                            <DialogContent>
                              <Button
                                size="small"
                                variant="contained"
                                fullWidth
                                sx={{ mb: 1 }}
                                onClick={() => {
                                  setExistingCatDlg(true);
                                  setManageCatDlg(false);
                                }}
                              >
                                {translate5(
                                  "Add Sub Category Under Existing Category"
                                )}
                              </Button>
                              <Button
                                size="small"
                                variant="contained"
                                fullWidth
                                color="warning"
                                onClick={() => {
                                  setAddCatDlg(true);
                                  setManageCatDlg(false);
                                }}
                              >
                                {translate5(
                                  "add subcategory with new category"
                                )}
                              </Button>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={() => setManageCatDlg(false)}
                                color="error"
                              >
                                {translate5("Close")}
                              </Button>
                            </DialogActions>
                          </Dialog>
                          {/* Manage Existing Sub Category/ Category */}
                          <Dialog
                            open={existingCatDlg}
                            onClose={() => setExistingCatDlg(false)}
                            fullWidth
                            maxWidth={"lg"}
                          >
                            <DialogContent>
                              <ManageMedicineCategory langFromPrev={lang} />
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={() => setExistingCatDlg(false)}
                                color="error"
                              >
                                {translate5("Close")}
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Grid container alignItems="center">
                      <Grid item xs={12} xl={2}>
                        <FormControl
                          size="small"
                          fullWidth
                          sx={{ mt: 1, pr: 2 }}
                        >
                          <InputLabel id="select-availability">
                            {translate0("Availability")}
                          </InputLabel>
                          <Select
                            labelId="select-availability"
                            id="select-availability"
                            value={availability}
                            label={translate0("Availability")}
                            onChange={(e) => setAvailability(e.target.value)}
                          >
                            <MenuItem value={0}>
                              {translate0("Available")}
                            </MenuItem>
                            <MenuItem value={1}>
                              {translate0("Available Upon Inquiry")}
                            </MenuItem>
                            <MenuItem value={2}>
                              {translate0("Not Available")}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} xl={1.5}>
                        <FormControlLabel
                          disabled={fieldDisabled}
                          control={
                            <Checkbox
                              checked={visible}
                              onChange={(e) => setVisible(e.target.checked)}
                              color="success"
                            />
                          }
                          label={translate1("Visible")}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} xl={1.5}>
                        <FormControlLabel
                          disabled={fieldDisabled}
                          control={
                            <Checkbox
                              checked={prescribed}
                              onChange={(e) => setPrescribed(e.target.checked)}
                              color="error"
                            />
                          }
                          label={translate1("Prescribed")}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} xl={1.5}>
                        <FormControlLabel
                          disabled={fieldDisabled}
                          control={
                            <Checkbox
                              checked={topSeller}
                              onChange={(e) => setTopSeller(e.target.checked)}
                              color="info"
                            />
                          }
                          label={
                            <Typography
                              variant="subtitle1"
                              color={fieldDisabled ? "gray" : "initial"}
                            >
                              {translate1("Best Seller")}
                              {!fieldDisabled && (
                                <InfoTut img="tut_best_seller.png" />
                              )}
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} xl={1.5}>
                        <FormControlLabel
                          disabled={fieldDisabled}
                          control={
                            <Checkbox
                              checked={dealOfTheWeek}
                              onChange={(e) =>
                                setDealOfTheWeek(e.target.checked)
                              }
                              color="error"
                            />
                          }
                          label={
                            <Typography
                              variant="subtitle1"
                              color={fieldDisabled ? "gray" : "initial"}
                            >
                              {translate1("Deal of the week")}
                              {!fieldDisabled && (
                                <InfoTut img="tut_deals.png" />
                              )}
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} xl={1.5}>
                        <FormControlLabel
                          disabled={fieldDisabled}
                          control={
                            <Checkbox
                              checked={isNew}
                              onChange={(e) => setIsNew(e.target.checked)}
                              color="warning"
                            />
                          }
                          label={
                            <Typography
                              variant="subtitle1"
                              color={fieldDisabled ? "gray" : "initial"}
                            >
                              {translate1("New")}
                              {!fieldDisabled && (
                                <InfoTut img="tut_new_med.png" />
                              )}
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} xl={1.5}>
                        <FormControlLabel
                          disabled={fieldDisabled}
                          control={
                            <Checkbox
                              checked={stock}
                              onChange={(e) => setStock(e.target.checked)}
                              color="secondary"
                            />
                          }
                          label={translate1("In Stock")}
                        />
                      </Grid>
                      {stock && (
                        <Grid item xs={12}>
                          <Grid container spacing={0} alignItems="flex-end">
                            <Grid item>
                              <Tooltip
                                arrow
                                title="Keep it at 0 to keep it hidden from the user"
                                placement="right"
                              >
                                <IconButton>
                                  <InfoIcon
                                    color={fieldDisabled ? "inherit" : "info"}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                            <Grid item sx={{ flexGrow: 1 }}>
                              <TextField
                                label={translate1("Stock Count")}
                                type="text"
                                value={stockCount}
                                onChange={handleStockCount}
                                variant="standard"
                                disabled={fieldDisabled}
                                fullWidth
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  {/* Variations */}
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      size="small"
                      fullWidth
                      color="warning"
                      startIcon={<AddIcon />}
                      onClick={handleAddVariation}
                      disabled={fieldDisabled}
                    >
                      {translate1("Add Variation")}
                    </Button>
                  </Grid>
                  {addVariantSection > 0 &&
                    [...Array(addVariantSection)].map((x, i) => (
                      <Fragment key={i}>
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            size="small"
                            fullWidth
                            color="error"
                            startIcon={<RemoveIcon />}
                            onClick={() => handleRemoveVariant(i)}
                          >
                            {translate1("Remove")}{" "}
                            {dosageForm[i] &&
                              `( ${getDosageName(dosageForm[i])} )`}
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                            disabled={fieldDisabled}
                            control={
                              <Checkbox
                                checked={showInFront[i]}
                                onChange={(e) => handleShowInFront(e, i)}
                                color="success"
                              />
                            }
                            label="Show In Front-end"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Grid container spacing={0}>
                            <Grid item xs={11} xl={11.5}>
                              <Autocomplete
                                size="small"
                                onChange={(e, newValue) =>
                                  handleDosageSelect(e, newValue, i)
                                }
                                disablePortal
                                defaultValue={dosageList.find(
                                  (d) =>
                                    (lang !== "en"
                                      ? d.dosageDetectorId
                                      : d.fixedDosageId) === dosageForm[i]
                                )}
                                options={dosageList}
                                filterOptions={filterOptions}
                                filterSelectedOptions
                                getOptionLabel={(option) =>
                                  `${option.dosageName} ${
                                    lang !== "en"
                                      ? "(" +
                                        getEngDosage(option.dosageName) +
                                        ")"
                                      : ""
                                  }`
                                }
                                renderOption={(props, option) => (
                                  <Box
                                    component="li"
                                    sx={{
                                      "& > img": { mr: 2, flexShrink: 0 },
                                    }}
                                    {...props}
                                  >
                                    {option.dosageName}
                                    {lang !== "en"
                                      ? "(" +
                                        getEngDosage(option.dosageName) +
                                        ")"
                                      : ""}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={translate1("Dosage Form")}
                                    variant="standard"
                                    size="small"
                                    fullWidth
                                    required
                                    error={
                                      lang !== "en" &&
                                      !fieldDisabled &&
                                      !dosageForm[i]
                                        ? true
                                        : false
                                    }
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={1} xl={0.25}>
                              <Tooltip
                                title={translate1("Dosage Form")}
                                arrow
                                placement="top"
                              >
                                <IconButton
                                  size="small"
                                  sx={{ mt: 1 }}
                                  onClick={() => setAddDosageDlg(true)}
                                >
                                  <AddCircleIcon color="success" />
                                </IconButton>
                              </Tooltip>
                              {addDosageDlg && (
                                <AddDosageDialog
                                  addDosageDlg={addDosageDlg}
                                  setAddDosageDlg={setAddDosageDlg}
                                  lang={lang}
                                />
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            label={translate1("Strength")}
                            value={strength[i]}
                            onChange={(e) => handleStrength(e, i)}
                            variant="standard"
                            size="small"
                            fullWidth
                            autoFocus
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            label={translate1("Pack Types")}
                            value={packTypes[i]}
                            onChange={(e) => handlePackTypes(e, i)}
                            variant="standard"
                            size="small"
                            fullWidth
                            error={
                              lang !== "en" && !fieldDisabled && !packTypes[i]
                                ? true
                                : false
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            label={translate1("Avg Price Of Originator")}
                            value={avgPriceOfOriginator[i]}
                            onChange={(e) => handleAvgPriceOfOriginator(e, i)}
                            variant="standard"
                            size="small"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            label={translate1("Selling Price")}
                            value={sellingPrice[i]}
                            onChange={(e) => handleSellingPrice(e, i)}
                            variant="standard"
                            size="small"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            label={translate0("Discount")}
                            value={discount[i]}
                            onChange={(e) => handleDiscount(e, i)}
                            variant="standard"
                            size="small"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            label={translate1("VIP Selling Price")}
                            value={vipSellingPrice[i]}
                            onChange={(e) => handleVipSellingPrice(e, i)}
                            variant="standard"
                            size="small"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            label={translate1("Company Patient Price Updated")}
                            value={companyPatientSellingPriceUpdated[i]}
                            onChange={(e) =>
                              handleCompanyPatientSellingPriceUpdated(e, i)
                            }
                            variant="standard"
                            size="small"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            label={translate1("Company Patient Price Old")}
                            value={companyPatientSellingPriceOld[i]}
                            onChange={(e) =>
                              handleCompanyPatientSellingPriceOld(e, i)
                            }
                            variant="standard"
                            size="small"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            label={translate1("Company Retail Price")}
                            value={companyMRP[i]}
                            onChange={(e) => handleCompanyMrp(e, i)}
                            variant="standard"
                            size="small"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            label={translate1("Minimum Order Qty")}
                            value={minimumOrderQty[i]}
                            onChange={(e) => handleMinimumOrderQty(e, i)}
                            variant="standard"
                            size="small"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            label={translate1("Weight (gram) Per Unit")}
                            value={weight[i]}
                            onChange={(e) => handleWeight(e, i)}
                            variant="standard"
                            size="small"
                            fullWidth
                          />
                        </Grid>
                      </Fragment>
                    ))}
                  {/* SEO Section */}
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      size="small"
                      fullWidth
                      color="info"
                      sx={{ pointerEvents: "none" }}
                    >
                      SEO Section
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={translate1("Meta Keyword")}
                      value={metaKeyword}
                      onChange={(e) => setMetaKeyword(e.target.value)}
                      variant="standard"
                      size="small"
                      fullWidth
                      disabled={fieldDisabled}
                      multiline
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={translate1("Page Title")}
                      value={pageTitle}
                      onChange={(e) => setPageTitle(e.target.value)}
                      variant="standard"
                      size="small"
                      fullWidth
                      multiline
                      disabled={fieldDisabled}
                      required
                      helperText={
                        <Typography
                          variant="body2"
                          color={pageTitle.length < 60 ? "primary" : "error"}
                          align="right"
                        >
                          {pageTitle.length}/60
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={translate1("Meta Title")}
                      value={metaTitle}
                      onChange={(e) => setMetaTitle(e.target.value)}
                      variant="standard"
                      size="small"
                      fullWidth
                      disabled={fieldDisabled}
                      multiline
                      required
                      helperText={
                        <Typography
                          variant="body2"
                          color={metaTitle.length < 60 ? "primary" : "error"}
                          align="right"
                        >
                          {metaTitle.length}/60
                        </Typography>
                      }
                    />
                  </Grid>
                  {!fieldDisabled && (
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={0}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item xs={11} xl={11.75}>
                          <Autocomplete
                            disablePortal
                            multiple
                            defaultValue={
                              nonEngTags.length > 0 ? nonEngTags : []
                            }
                            disabled={fieldDisabled}
                            onChange={(e, newValue) => handleTags(e, newValue)}
                            options={tagList}
                            filterOptions={filterOptions}
                            getOptionLabel={(option) =>
                              option.tagName +
                              (lang !== "en"
                                ? `( ${getEngTag(option.tagName)} )`
                                : "")
                            }
                            renderOption={(props, option) => (
                              <Box
                                component="li"
                                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                {...props}
                              >
                                {option.tagName}{" "}
                                {lang !== "en"
                                  ? `( ${getEngTag(option.tagName)} )`
                                  : ""}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Tags"
                                size="small"
                                fullWidth
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={1} xl={0.25}>
                          <Tooltip
                            title={translate1("Tags")}
                            arrow
                            placement="top"
                          >
                            <IconButton
                              size="small"
                              onClick={() => setAddTagDlg(true)}
                            >
                              <AddCircleIcon color="success" />
                            </IconButton>
                          </Tooltip>
                          <AddSeoTags
                            lang={lang}
                            addTagDlg={addTagDlg}
                            setAddTagDlg={setAddTagDlg}
                            tagListEn={tagListEn}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <TextField
                      label={translate1("Meta Description")}
                      value={metaDesc}
                      onChange={(e) => setMetaDesc(e.target.value)}
                      variant="standard"
                      size="small"
                      multiline
                      minRows={3}
                      fullWidth
                      disabled={fieldDisabled}
                      required
                      helperText={
                        <Typography
                          variant="body2"
                          color={metaDesc.length < 151 ? "primary" : "error"}
                          align="right"
                        >
                          {metaDesc.length}/150
                        </Typography>
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      size="small"
                      fullWidth
                      color="error"
                      sx={{ pointerEvents: "none" }}
                    >
                      {translate1("Additional Section")}
                    </Button>
                  </Grid>
                  {!fieldDisabled && (
                    <>
                      {/* File Upload */}
                      <Grid item xs={12}>
                        <FileUpload translate1={translate1} />
                      </Grid>

                      {/* Additional Section */}
                      <Grid item xs={12}>
                        <AdditionalPage
                          additionalSectionList={additionalSectionList}
                          setAdditionalSectionList={setAdditionalSectionList}
                          translate1={translate1}
                        />
                      </Grid>
                    </>
                  )}
                  {additionalSectionPrompt && (
                    <Grid item xs={12}>
                      <Grid container spacing={0}>
                        <Grid item xs={9} sm={10.5} md={11.25} xl={11.5}>
                          <TextField
                            label={translate1("Section Name")}
                            value={addSectionName}
                            onChange={(e) => setAddSectionName(e.target.value)}
                            fullWidth
                            required
                            size="small"
                            color="warning"
                            focused
                          />
                        </Grid>
                        <Grid item xs={3} sm={1.5} md={0.75} xl={0.5}>
                          <Grid container spacing={0}>
                            <Grid item>
                              <Tooltip
                                title={translate1("Confirm")}
                                arrow
                                placement="left"
                              >
                                <IconButton
                                  size="small"
                                  disabled={
                                    addSectionName !== "" ? false : true
                                  }
                                  onClick={handleCreateAdditionalSection}
                                >
                                  <CheckCircleIcon
                                    color={
                                      addSectionName === ""
                                        ? "inherit"
                                        : "success"
                                    }
                                  />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                            <Grid item>
                              <Tooltip title="Cancel" arrow placement="left">
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    setAdditionalSectionPrompt(false)
                                  }
                                >
                                  <CancelIcon color="error" />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item xs={12} ref={additionalSectionBtnRef}>
                    <Button
                      variant="contained"
                      size="small"
                      fullWidth
                      disabled={fieldDisabled || additionalSectionPrompt}
                      color="success"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        setAdditionalSectionPrompt(!additionalSectionPrompt);
                        setTimeout(() => {
                          additionalSectionBtnRef.current.scrollIntoView({
                            behavior: "smooth",
                          });
                        }, 500);
                      }}
                    >
                      {translate1("Additional Section")}
                    </Button>
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
