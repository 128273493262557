import { Button, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";

// Ck Editor
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { useDispatch } from "react-redux";
import {
  createNoti,
  uploadTempPic,
} from "../../../../../reduxToolkit/features/adminPanel/misc";
import { PF } from "../../../../../config";
import CompanyInfo from "./CompanyInfo";
import { Fragment } from "react";
import PreviewInvoiceMfw2 from "./PreviewInvoiceMfw2";

function ManualInvoiceForMfw2() {
  const [companyInfo, setCompanyInfo] = useState({
    companyName: "Medicine For World (MFW)",
    companyShortForm: "MFW",
    companyLogo: undefined,
    companyDetails: `
    <p>H# DCC 1, Momin Shoroni</p>
    <p>Road, North Ibrahimpur, Mirpur-14, Dhaka-1206,</p>
    <p>Dhaka, Bangladesh</p>
    <p>Phone: +8801916942634, +8801764000174</p>
    <p>Emails: info@medicineforworld.com.bd</p>
    <p>medicineforworld@gmail.com</p>
    <p>Website: www.medicineforworld.com.bd</p>
    `,
    companySil: undefined,
    companyWatermark: undefined,
  });

  const [invoiceDate, setInvoiceDate] = useState("");
  const [invoiceNo, setInvoiceNo] = useState("");

  // ! CK Editor Image Upload

  const dispatch = useDispatch();
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          let tempName = Math.floor(Math.random() * (10000 - 0) + 0).toString();
          loader.file
            .then((file) => {
              const data = new FormData();
              if (file) {
                tempName = tempName + "." + file.name.split(".").pop();
                data.append("name", tempName);
                data.append("tempImg", file);
                dispatch(uploadTempPic(data));
                dispatch(
                  createNoti({
                    message: "Pls click on the image Confirm Image Upload",
                    severity: "warning",
                  })
                );
              }
            })
            .then(() => {
              resolve({
                default: `${PF}temp/${tempName}`,
              });
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }
  // ! CK Editor Image Upload

  const [clientInfo, setClientInfo] = useState("");

  const handleClientInfo = (e, editor) => {
    const data = editor.getData();

    setClientInfo(data);
  };

  const [rows, setRows] = useState([
    {
      description: "Product & Service Payment",
      qty: 1,
      unitPrice: "USD ",
      totalPrice: "USD ",
    },
  ]);

  const handleRowAdd = () => {
    let arr = [...rows];
    arr.push({
      description: "Product & Service Payment",
      qty: 1,
      unitPrice: "USD ",
      totalPrice: "USD ",
    });
    setRows(arr);
  };

  const handleRowRemove = (index) => {
    let arr = [...rows];
    arr = arr.filter((x, i) => i !== index);
    setRows(arr);
  };

  const [total, setTotal] = useState("USD ");
  const [totalInWord, setTotalInWord] = useState("USD ");

  const [prevDlg, setPrevDlg] = useState(false);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={1.5}>
        <TextField
          label="Date"
          value={invoiceDate}
          onChange={(e) => setInvoiceDate(e.target.value)}
          size="large"
          fullWidth
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} sm={10.5}>
        <TextField
          label="Invoice No"
          value={invoiceNo}
          onChange={(e) => setInvoiceNo(e.target.value)}
          size="large"
          fullWidth
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body1" fullWidth sx={{ mb: 1 }}>
          Consignee Info
        </Typography>
        <CKEditor
          editor={Editor}
          data={clientInfo}
          config={{
            extraPlugins: [uploadPlugin],
          }}
          onBlur={(e, editor) => handleClientInfo(e, editor)}
        />
      </Grid>

      {rows.map((row, index) => (
        <Fragment key="index">
          <Grid item xs={12}>
            <Button
              variant="outlined"
              size="small"
              sx={{ pointerEvents: "none", mb: 1 }}
              fullWidth
              color="info"
            >
              Row {index + 1}
            </Button>
          </Grid>
          {rows.length > 1 && (
            <Grid item xs={12}>
              <Button
                variant="contained"
                size="small"
                fullWidth
                color="error"
                onClick={() => handleRowRemove(index)}
              >
                Remove Row {index + 1}
              </Button>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Description"
              value={row.description}
              onChange={(e) => {
                let arr = [...rows];
                arr[index].description = e.target.value;
                setRows(arr);
              }}
              size="large"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Qty"
              value={row.qty}
              onChange={(e) => {
                let arr = [...rows];
                arr[index].qty = e.target.value;
                setRows(arr);
              }}
              size="large"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Unit Price"
              value={row.unitPrice}
              onChange={(e) => {
                let arr = [...rows];
                arr[index].unitPrice = e.target.value;
                setRows(arr);
              }}
              size="large"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Total Price"
              value={row.totalPrice}
              onChange={(e) => {
                let arr = [...rows];
                arr[index].totalPrice = e.target.value;
                setRows(arr);
              }}
              size="large"
              fullWidth
              variant="outlined"
            />
          </Grid>
        </Fragment>
      ))}

      <Grid item xs={12}>
        <Button
          variant="contained"
          size="small"
          fullWidth
          color="success"
          onClick={handleRowAdd}
        >
          Add Row
        </Button>
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          label="Total"
          value={total}
          onChange={(e) => setTotal(e.target.value)}
          size="large"
          fullWidth
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          label="Total In Words"
          value={totalInWord}
          onChange={(e) => setTotalInWord(e.target.value)}
          size="large"
          fullWidth
          variant="outlined"
        />
      </Grid>

      <CompanyInfo companyInfo={companyInfo} setCompanyInfo={setCompanyInfo} />

      <Grid item xs={12}>
        <Button
          variant="contained"
          size="small"
          sx={{ mb: 1 }}
          fullWidth
          color="error"
          onClick={() => setPrevDlg(true)}
        >
          Preview
        </Button>
      </Grid>

      {prevDlg && (
        <PreviewInvoiceMfw2
          open={prevDlg}
          setOpen={setPrevDlg}
          invoiceDate={invoiceDate}
          invoiceNo={invoiceNo}
          rows={rows}
          total={total}
          totalInWord={totalInWord}
          clientInfo={clientInfo}
          companyInfo={companyInfo}
        />
      )}
    </Grid>
  );
}

export default ManualInvoiceForMfw2;
