import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Icons
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AddLangDialog from "../dialog/AddLangDialog";
import AddMedicineDialog from "../dialog/AddMedicineDialog";
import { getMedicines } from "../../../../reduxToolkit/features/adminPanel/medicine";
import BrochureSection from "./brochure/BrochureSection";
import LeafletSection from "./leaflet/LeafletSection";
import { changeLang } from "../../../../reduxToolkit/features/adminPanel/misc";
import LoadingBox from "../../../../components/misc/Loading";

export default function ManageBrochure() {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [siteLang, setSiteLang] = useState("");
  const [siteLangId, setSiteLangId] = useState("");
  const [addMedicineDlg, setAddMedicineDlg] = useState(false);
  const [medListEn, setMedListEn] = useState([]);

  const [activeTab, setActiveTab] = useState(0);

  // Dialog
  const [addLangDlg, setAddLangDlg] = useState(false);

  // Local Store
  const localLangData = JSON.parse(localStorage.getItem("lang"));
  // Store
  const { langs, activeLang } = useSelector((state) => state.misc);
  const { medicines } = useSelector((state) => state.medicine);

  // Functions
  const handleChangeLang = (e) => {
    setSiteLang(e.target.value);
    const L = langs.find((l) => l.langCode === e.target.value);
    setSiteLangId(L._id);
  };

  useEffect(() => {
    if (!(medicines && medicines.length > 0)) dispatch(getMedicines());
  }, []);

  // Set Language
  useEffect(() => {
    setLoading(true);
    if (activeLang && activeLang.langCode) {
      setSiteLang(activeLang.langCode);
      setSiteLangId(activeLang._id);
    } else {
      if (localLangData) {
        setSiteLang(localLangData.langCode);
        setSiteLangId(localLangData._id);
      }
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [activeLang]);

  useEffect(() => {
    if (medicines && medicines.length > 0) {
      const mEn = medicines.find((m) => m.lang === "en");
      setMedListEn(mEn.data);
    }
  }, [medicines]);

  //! Translate
  // Store
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate

  return (
    <>
      {loading ? (
        <LoadingBox w={"100%"} />
      ) : (
        <>
          <Grid
            container
            spacing={1}
            justifyContent="space-between"
            alignItems="center"
          >
            {/* Select Language */}
            <Grid item xs={12} md={4}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={2} sm={1} md={1.5}>
                  <IconButton>
                    <AddCircleOutlineOutlinedIcon
                      color="info"
                      size="large"
                      onClick={() => setAddLangDlg(true)}
                    />
                  </IconButton>
                  <AddLangDialog
                    addLangDlg={addLangDlg}
                    setAddLangDlg={setAddLangDlg}
                    setLang={setSiteLang}
                  />
                </Grid>
                <Grid item xs={10} sm={4} md={5}>
                  <FormControl fullWidth>
                    <Select
                      value={siteLang}
                      label="language"
                      variant="standard"
                      onChange={handleChangeLang}
                      size="small"
                      disabled
                    >
                      {langs.map((x, i) => (
                        <MenuItem key={i} value={x.langCode} dense>
                          <img
                            alt={x.langCode}
                            src={`https://flagcdn.com/w20/${
                              x.langCode === "en" ? "gb" : x.langCode
                            }.png`}
                          />
                          <Button color="inherit" size="small">
                            {x.langName}
                          </Button>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {/* Add Medicine */}
                <Grid item xs={12} sm={7} md={4.5}>
                  <Button
                    variant="contained"
                    size="small"
                    color="error"
                    fullWidth
                    onClick={() => setAddMedicineDlg(true)}
                  >
                    {translate0("Add Medicine")}
                  </Button>
                  {addMedicineDlg && (
                    <AddMedicineDialog
                      addMedicineDlg={addMedicineDlg}
                      setAddMedicineDlg={setAddMedicineDlg}
                      lang={siteLang || "en"}
                      langId={siteLangId}
                      medListEn={medListEn || []}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={8}>
              <Button
                variant="contained"
                size="small"
                sx={{ pointerEvents: "none" }}
                fullWidth
              >
                {translate0("Manage Brochure/ Leaflet")}{" "}
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ mt: 0.5 }}>
            <Grid item xs={12} md={6}>
              <Button
                variant={activeTab === 0 ? "contained" : "outlined"}
                size="small"
                fullWidth
                color="secondary"
                onClick={() => setActiveTab(0)}
              >
                {translate0("Leaflet")}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                variant={activeTab === 1 ? "contained" : "outlined"}
                size="small"
                fullWidth
                onClick={() => setActiveTab(1)}
                color="secondary"
              >
                {translate0("Brochure")}
              </Button>
            </Grid>
          </Grid>
          {activeTab === 0 && <LeafletSection siteLang={siteLang} />}
          {activeTab === 1 && <BrochureSection siteLang={siteLang} />}
        </>
      )}
    </>
  );
}
