import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Divider,
  Card,
  CardContent,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { Fragment, useEffect, useState } from "react";
import AddLangDialog from "../../medicines/dialog/AddLangDialog";

// Icons
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";

import { useDispatch, useSelector } from "react-redux";
import { ExpandMore } from "@mui/icons-material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { PF } from "../../../../config";
import LoadingBox from "../../../../components/misc/Loading";

// Media Query
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  getPaymentSettings,
  savePayRcptInstruction,
} from "../../../../reduxToolkit/features/adminPanel/webSettings";
import { getNotification } from "../../../../reduxToolkit/features/adminPanel/notification";

export default function PaymentSettings() {
  const dispatch = useDispatch();
  // Media Query
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  const [loadingMain, setLoadingMain] = useState(false);
  const [lang, setLang] = useState("");
  const [langId, setLangId] = useState("");
  const [viewImgDlg, setViewImgDlg] = useState(false);
  const [payRcptInstructionArr, setPayRcptInstructionArr] = useState([]);
  const [instruction, setInstruction] = useState("");
  const [editBuffer, setEditBuffer] = useState("");
  const [editInstruction, setEditInstruction] = useState("");

  // Dialog
  const [addLangDlg, setAddLangDlg] = useState(false);

  // LocalData
  const localLangData = JSON.parse(localStorage.getItem("lang"));
  const localAdminData = () => {
    let adminData = JSON.parse(localStorage.getItem("adminLoginInfo"));
    return adminData;
  };

  // store
  const { langs, activeLang } = useSelector((state) => state.misc);
  const { paymentSettings } = useSelector((state) => state.webSettings);

  // Functions
  const handleChangeLang = (e) => {
    setLang(e.target.value);
    const L = langs.find((l) => l.langCode === e.target.value);
    setLangId(L._id);
  };

  const handleAddPayRcptInstruction = () => {
    let arr = [...payRcptInstructionArr];
    arr.push({
      instruction,
    });
    setPayRcptInstructionArr(arr);
    setInstruction("");
  };

  const handleDeletePayRcptInstruction = (index) => {
    let arr = [...payRcptInstructionArr];
    arr = arr.filter((a, i) => i !== index);
    setPayRcptInstructionArr(arr);
  };

  const handleSavePayRcptInstr = () => {
    let arr = [];
    payRcptInstructionArr.forEach((x) => arr.push(x.instruction));
    let obj = {
      langId,
      creatorId: localAdminData()._id,
      payRcptInstructionArr: arr,
    };
    dispatch(savePayRcptInstruction(obj));
    setTimeout(() => {
      dispatch(getNotification());
      dispatch(getPaymentSettings());
    }, 1000);
  };

  const handleEditInstruction = (i) => {
    let arr = [...payRcptInstructionArr];
    arr[i] = { instruction: editInstruction };
    setPayRcptInstructionArr(arr);
    setEditInstruction("");
    setEditBuffer("");
  };

  // UseEffect
  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setLang(activeLang.langCode);
      setLangId(activeLang._id);
    } else {
      if (localLangData) {
        setLang(localLangData.langCode);
        setLangId(localLangData._id);
      }
    }
  }, [activeLang]);

  useEffect(() => {
    setLoadingMain(true);
    dispatch(getPaymentSettings());
    setTimeout(() => {
      setLoadingMain(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (paymentSettings && paymentSettings.length > 0) {
      const P = paymentSettings.find((p) => p.langId === langId);
      let arr = [];
      if (P && P.payRcptInstructionArr)
        P.payRcptInstructionArr.forEach((i) => arr.push({ instruction: i }));
      setPayRcptInstructionArr(arr);
    }
  }, [paymentSettings, langId]);

  // ! Translate
  // Store
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate

  return (
    <>
      {loadingMain ? (
        <LoadingBox w={"100%"} />
      ) : (
        <>
          <Grid
            container
            spacing={1}
            justifyContent="space-between"
            alignItems="center"
          >
            {/* Select Language */}
            <Grid item xs={12} md={12}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={2} sm={1} md={0.5} xl={0.25}>
                  <IconButton>
                    <AddCircleOutlineOutlinedIcon
                      color="info"
                      size="large"
                      onClick={() => setAddLangDlg(true)}
                    />
                  </IconButton>
                  <AddLangDialog
                    addLangDlg={addLangDlg}
                    setAddLangDlg={setAddLangDlg}
                    setLang={setLang}
                  />
                </Grid>
                <Grid item xs={10} sm={4} md={1.5} xl={1}>
                  <FormControl fullWidth>
                    <Select
                      value={lang}
                      label="language"
                      variant="standard"
                      onChange={handleChangeLang}
                      size="small"
                    >
                      {langs.map((x, i) => (
                        <MenuItem key={i} value={x.langCode} dense>
                          <img
                            alt={x.langCode}
                            src={`https://flagcdn.com/w20/${
                              x.langCode === "en" ? "gb" : x.langCode
                            }.png`}
                          />
                          <Button color="inherit" size="small">
                            {x.langName}
                          </Button>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {/* Title */}
                {smUp && (
                  <Grid item xs={12} sm={7} md={10} xl={10.75}>
                    <Button
                      variant="outlined"
                      size="small"
                      color="error"
                      fullWidth
                      sx={{ pointerEvents: "none" }}
                    >
                      {translate0("Configure Payment Settings")}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Accordion sx={{ mt: 1 }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-label="Expand"
              aria-controls="-content"
              id="-header"
            >
              <Typography>
                {translate0("Payment Receipt Upload Instructions")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={0}>
                <Grid item xs={12} sm={4} md={2}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                    onClick={() => setViewImgDlg(true)}
                  >
                    <LazyLoadImage
                      effect="blur"
                      src={PF + "/tut/tut-payment-instructions.png"}
                      alt={"payment instruction"}
                      style={{
                        objectFit: "cover",
                        width: 150,
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={10}>
                  <Grid container spacing={0}>
                    <Grid item sx={{ flex: 1 }}>
                      <TextField
                        label={translate0("Instruction")}
                        value={instruction}
                        onChange={(e) => setInstruction(e.target.value)}
                        size="small"
                        variant="standard"
                        fullWidth
                        onKeyPress={(e) =>
                          e.key === "Enter" &&
                          instruction !== "" &&
                          handleAddPayRcptInstruction()
                        }
                      />
                    </Grid>
                    <Grid item>
                      <IconButton>
                        <AddCircleOutlineOutlinedIcon
                          color="info"
                          size="large"
                          onClick={handleAddPayRcptInstruction}
                        />
                      </IconButton>
                    </Grid>
                  </Grid>
                  {payRcptInstructionArr.map((x, i) => (
                    <Fragment key={i}>
                      {editBuffer === i ? (
                        <Grid container spacing={0} alignItems="center">
                          <Grid item sx={{ flex: 1 }}>
                            <TextField
                              label={translate0("Instruction")}
                              value={editInstruction}
                              onChange={(e) =>
                                setEditInstruction(e.target.value)
                              }
                              onKeyPress={(e) =>
                                e.key === "Enter" &&
                                editInstruction !== "" &&
                                handleEditInstruction(i)
                              }
                              variant="standard"
                              size="small"
                              fullWidth
                            />
                          </Grid>
                          <Grid item>
                            <IconButton
                              size="small"
                              onClick={() => handleEditInstruction(i)}
                            >
                              <CheckCircleIcon color="success" />
                            </IconButton>
                          </Grid>
                          <Grid item>
                            <IconButton
                              size="small"
                              onClick={() => {
                                setEditInstruction("");
                                setEditBuffer("");
                              }}
                            >
                              <CloseIcon color="error" />
                            </IconButton>
                          </Grid>
                        </Grid>
                      ) : (
                        <>
                          <Grid container spacing={0}>
                            <Grid item sx={{ flex: 1 }}>
                              <Button
                                variant="text"
                                size="small"
                                fullWidth
                                sx={{
                                  justifyContent: "flex-start",
                                  pointerEvents: "none",
                                }}
                              >
                                {i + 1}
                                {". "} {x.instruction}
                              </Button>
                            </Grid>
                            <Grid item>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setEditBuffer(i);
                                  setEditInstruction(x.instruction);
                                }}
                              >
                                <EditIcon color="primary" />
                              </IconButton>
                            </Grid>
                            <Grid item>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  handleDeletePayRcptInstruction(i)
                                }
                              >
                                <DeleteIcon color="error" />
                              </IconButton>
                            </Grid>
                          </Grid>
                          <Divider />
                        </>
                      )}
                    </Fragment>
                  ))}
                  <Button
                    color="success"
                    variant="contained"
                    size="small"
                    fullWidth
                    sx={{ my: 1 }}
                    disabled={
                      payRcptInstructionArr.length < 1 || editBuffer !== ""
                    }
                    onClick={handleSavePayRcptInstr}
                  >
                    {translate0("Save")}
                  </Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </>
      )}
      {viewImgDlg && (
        <Dialog open={viewImgDlg} onClose={() => setViewImgDlg(false)}>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
              onClick={() => setViewImgDlg(true)}
            >
              <LazyLoadImage
                effect="blur"
                src={PF + "/tut/tut-payment-instructions.png"}
                alt={"payment instruction"}
                style={{
                  objectFit: "cover",
                  width: "100%",
                }}
              />
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
