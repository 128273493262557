import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Input,
  IconButton,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
// Icons
import CircleIcon from "@mui/icons-material/Circle";
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import PublishIcon from "@mui/icons-material/Publish";
import AddRole from "./AddRole";
import { useDispatch, useSelector } from "react-redux";
import { ExpandMore } from "@mui/icons-material";
import {
  checkPassword,
  getAdmins,
  updateAdmin,
  uploadAdminPic,
} from "../../../../reduxToolkit/features/adminPanel/admin";
// Lazy load Image
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import LoadingBox from "../../../../components/misc/Loading";
import { PF } from "../../../../config";
import { getNotification } from "../../../../reduxToolkit/features/adminPanel/notification";
import { getRole } from "../../../../reduxToolkit/features/adminPanel/role";

export default function EditAdmin({
  editAdminDlg,
  setEditAdminDlg,
  singleAdmin,
}) {
  // useStates
  const [formValid, setFormValid] = useState(false);
  const [creatingRole, SetCreatingRole] = useState(false);
  const [uploadedAdminPic, setUploadedAdminPic] = useState("");
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [passwordChecked, setPasswordChecked] = useState(false);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [adminPic, setAdminPic] = useState("");
  const [role, setRole] = useState(0);
  const [addRoleSection, setAddRoleSection] = useState(false);

  // local Data
  const localData = JSON.parse(localStorage.getItem("adminLoginInfo"));

  // store
  const { roles } = useSelector((state) => state.role);
  const { pending, checkPasswordResponse } = useSelector(
    (state) => state.admin
  );

  // Use Effect
  useEffect(() => {
    if (singleAdmin) {
      setUsername(singleAdmin.adminName);
      setEmail(singleAdmin.email);
      setAdminPic(singleAdmin.adminPic);
      setRole(singleAdmin.priority);
    }
  }, [singleAdmin]);

  useEffect(() => {
    addRoleSection ? SetCreatingRole(true) : SetCreatingRole(false);
  }, [addRoleSection]);

  // Check Form Validity
  useEffect(() => {
    if (adminPic !== "") {
      if (username === "" || email === "" || !email.includes("@")) {
        setFormValid(false);
      } else {
        setFormValid(true);
      }
    } else setFormValid(false);
  }, [adminPic, email, username]);

  const checkPass = () => {
    setPasswordChecked(true);
    let body = { id: singleAdmin._id, pass: password };
    dispatch(checkPassword(body));
  };

  //   Functions
  const removeImage = () => {
    // setUploadedAdminPic("");
    setAdminPic("");
  };

  const handleEditAdmin = () => {
    if (formValid) {
      setLoading(true);
      let formBody = {
        _id: singleAdmin._id,
        adminName: username,
        email,
        rolePriority: role,
        updatedBy: localData._id,
      };
      if (newPassword !== "") formBody.password = newPassword;
      if (uploadedAdminPic !== "") {
        const data = new FormData();
        const ext = uploadedAdminPic.name.split(".");
        const filename =
          Date.now() +
          "-" +
          username.replace(/\s+/g, "") +
          "-admin-pic." +
          ext.slice(-1);
        data.append("name", filename);
        data.append("adminPic", uploadedAdminPic);
        formBody.adminPic = filename;
        dispatch(uploadAdminPic(data));
      }
      dispatch(updateAdmin(formBody));
      setTimeout(() => {
        dispatch(getNotification());
        dispatch(getAdmins());
        dispatch(getRole());
        setEditAdminDlg(false);
        setLoading(false);
      }, 1000);
    }
  };

  const dispatch = useDispatch();
  const handleOldPassword = (e) => {
    setPassword(e.target.value);
  };

  const handleImageUpload = (e) => {
    // setAdminPic("");
    setAdminPic(URL.createObjectURL(e.target.files[0]));
    setUploadedAdminPic(e.target.files[0]);
  };
  return (
    <Dialog
      open={editAdminDlg}
      fullWidth
      maxWidth="md"
      onClose={() => setEditAdminDlg(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Edit Admin"}</DialogTitle>
      {username === "" ? (
        <LoadingBox />
      ) : (
        <DialogContent>
          <Grid container spacing={0} flexDirection="column">
            <Grid item xs={12}>
              <Grid
                container
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <LazyLoadImage
                    effect="blur"
                    src={
                      // uploadedAdminPic
                      // ? uploadedAdminPic
                      adminPic !== ""
                        ? adminPic.includes("http")
                          ? adminPic
                          : PF + "adminPanel/admins/" + adminPic
                        : "https://cdn2.iconfinder.com/data/icons/picons-basic-1/57/basic1-112_user_add_new-512.png"
                    }
                    alt="Halloween party"
                    style={{
                      objectFit: "cover",
                      width: "200px",
                    }}
                  />
                  <Typography variant="body2" align="center" color="secondary">
                    Recommended: 🖼️(1200x600)
                  </Typography>
                </Grid>
                {/* // TODO Tried to open upload image with icon but couldnt  */}
                <Grid item position="relative">
                  <label htmlFor={"edit-admin-pic"}>
                    <Input
                      accept="image/*"
                      id={"edit-admin-pic"}
                      type="file"
                      onChange={handleImageUpload}
                    />
                  </label>
                </Grid>
                <Grid item>
                  {adminPic !== "" && (
                    <Button
                      variant="contained"
                      size="small"
                      color="error"
                      onClick={removeImage}
                      sx={{ my: 1 }}
                    >
                      Remove Image
                    </Button>
                  )}
                </Grid>
                {adminPic === "" && (
                  <Alert severity="error">Upload Image</Alert>
                )}
              </Grid>
            </Grid>
            <Grid item lg={12}>
              <TextField
                margin="dense"
                fullWidth
                size="small"
                label="User Name"
                variant="standard"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Grid>
            {username === "" && <Alert severity="error">Type Name</Alert>}
            <Grid item lg={12}>
              <TextField
                margin="normal"
                fullWidth
                size="small"
                label="Email"
                variant="standard"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            {email === "" && <Alert severity="error">Type Email</Alert>}
            {email !== "" && !email.includes("@") && (
              <Alert severity="error">Invalid Email</Alert>
            )}
            <Grid item lg={12}>
              <Accordion size="small">
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-label="Expand"
                  aria-controls="-content"
                  id="-header"
                >
                  <Typography>Change Password</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {!checkPasswordResponse.matched && (
                    <TextField
                      margin="dense"
                      fullWidth
                      size="small"
                      label="Old Password"
                      variant="standard"
                      value={password}
                      onChange={handleOldPassword}
                    />
                  )}
                  <LoadingButton
                    variant="contained"
                    endIcon={
                      checkPasswordResponse.matched && passwordChecked ? (
                        <CheckIcon />
                      ) : (
                        <img
                          src="https://img.icons8.com/external-outline-geotatah/64/undefined/external-analyse-stock-market-outline-geotatah.png"
                          alt="check-pass"
                          style={{ height: 20 }}
                        />
                      )
                    }
                    loading={pending}
                    loadingPosition="end"
                    size="small"
                    onClick={checkPass}
                    fullWidth
                    color={checkPasswordResponse.matched ? "success" : "info"}
                    sx={{
                      pointerEvents: checkPasswordResponse.matched && "none",
                    }}
                  >
                    {checkPasswordResponse.matched ? "Matched" : "Check Pass"}
                  </LoadingButton>

                  {checkPasswordResponse.matched && (
                    <>
                      <TextField
                        margin="dense"
                        fullWidth
                        size="small"
                        label="New Password"
                        variant="standard"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                    </>
                  )}
                  {!checkPasswordResponse.matched && passwordChecked && (
                    <Alert
                      severity="warning"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      Not Matched!
                    </Alert>
                  )}
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item lg={12} sx={{ my: 1 }}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={role}
                  label="Age"
                  onChange={(e) => setRole(e.target.value)}
                >
                  {roles.map((x, i) => (
                    <MenuItem key={i} value={x.priority}>
                      <IconButton>
                        <CircleIcon sx={{ color: x.color }} />
                      </IconButton>
                      {x.roleName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={12}>
              <Button
                variant="contained"
                size="small"
                color={addRoleSection ? "primary" : "warning"}
                fullWidth
                endIcon={
                  addRoleSection ? (
                    <RemoveIcon sx={{ mb: 0 }} />
                  ) : (
                    <AddIcon sx={{ mb: 0.5 }} />
                  )
                }
                onClick={() => setAddRoleSection(!addRoleSection)}
              >
                {addRoleSection ? "Minimize add role section" : " Add Role"}
              </Button>
            </Grid>
            <Grid item>
              {addRoleSection && (
                <AddRole setAddRoleSection={setAddRoleSection} />
              )}
            </Grid>
          </Grid>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={() => setEditAdminDlg(false)} autoFocus color="error">
          Close
        </Button>
        <LoadingButton
          onClick={handleEditAdmin}
          endIcon={<PublishIcon />}
          loading={loading}
          loadingPosition="end"
          variant="contained"
          size="small"
          disabled={!formValid || creatingRole}
        >
          Update
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
