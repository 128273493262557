import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import {
  Autocomplete,
  Badge,
  Box,
  Button,
  CardMedia,
  createFilterOptions,
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert,
  Tooltip,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
  Pagination,
  InputLabel,
} from "@mui/material";
// Icon
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import InfoIcon from "@mui/icons-material/Info";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CallMadeIcon from "@mui/icons-material/CallMade";
// react-lazy-load-image
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { PF } from "../../../../config";
import ReactPlayer from "react-player";
// Media Query
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import MedicineVideoDialog from "../dialog/MedicineVideoDialog";
import { ExpandMore, Info } from "@mui/icons-material";
import PriceInfoDialog from "../dialog/PriceInfoDialog";
import AddLangDialog from "../dialog/AddLangDialog";
// import { medData } from "../data/medData";
import AddMedicineDialog from "../dialog/AddMedicineDialog";
import {
  getCompanies,
  getDosages,
  getMedCategories,
  getMedicines,
  getSeoTags,
  removeSingleMedicine,
} from "../../../../reduxToolkit/features/adminPanel/medicine";
import { getSuppliers } from "../../../../reduxToolkit/features/adminPanel/supplier";
import { getNotification } from "../../../../reduxToolkit/features/adminPanel/notification";
import { formatDistance } from "date-fns";
import EditMedicineDialog from "../dialog/EditMedicineDialog";
import LoadingBox from "../../../../components/misc/Loading";
import { getExactTime } from "../../../../hooks/getCreatedData";
import { makeUrlFriendly } from "../../../../hooks/makeUrlFriendly";
import { formatMedicinePackSize } from "../../../../hooks/formatMedicinePackSize";

// Limit options to show in autocomplete
const OPTIONS_LIMIT = 50;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) => {
  return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
};

export default function ManageMedicine() {
  // Media Query
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));
  // use States
  const [loading, setLoading] = useState(false);
  const [searchBarLoading, setSearchBarLoading] = useState(false);
  const [lang, setLang] = useState("");
  const [langId, setLangId] = useState("");
  const [medList, setMedList] = useState([]);
  const [medListEn, setMedListEn] = useState([]);
  const [searchMedList, setSearchMedList] = useState([]);
  const [searchingMedList, setSearchingMedList] = useState([]);
  const [activeImg, setActiveImg] = useState();
  const [activeVid, setActiveVid] = useState();
  const [viewVidArr, setViewVidArr] = useState([]);
  const [dosageList, setDosageList] = useState([]);
  const [dosageListEn, setDosageListEn] = useState([]);

  const [sortBy, setSortBy] = useState(1);
  // Dialog
  const [addLangDlg, setAddLangDlg] = useState(false);
  const [mobileVideoDlg, setMobileVideoDlg] = useState(false);
  const [mobileVideoX, setMobileVideoX] = useState([]);
  const [priceInfoDlg, setPriceInfoDlg] = useState(false);
  const [priceInfoData, setPriceInfoData] = useState([]);
  const [addMedicineDlg, setAddMedicineDlg] = useState(false);
  const [deleteMedicineDlg, setDeleteMedicineDlg] = useState(false);
  const [deleteMedicineBuffer, setDeleteMedicineBuffer] = useState("");
  const [editMedicineDlg, setEditMedicineDlg] = useState(false);
  const [editMedicineBuffer, setEditMedicineBuffer] = useState("");

  // LocalData
  const localLangData = JSON.parse(localStorage.getItem("lang"));
  const localAdminData = JSON.parse(localStorage.getItem("adminLoginInfo"));
  // store
  const { langs, activeLang } = useSelector((state) => state.misc);
  const { medicines, categories, companies, medVariants, seoTags, dosages } =
    useSelector((state) => state.medicine);
  const { suppliers } = useSelector((state) => state.supplier);
  const { admins } = useSelector((state) => state.admin);

  // UseEffect
  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setLang(activeLang.langCode);
      setLangId(activeLang._id);
    } else {
      if (localLangData) {
        setLang(localLangData.langCode);
        setLangId(localLangData._id);
      }
    }
  }, [activeLang]);

  // Get medicines
  const dispatch = useDispatch();
  // useEffect(() => {
  //   lang !== "" && dispatch(getMedicines());
  // }, [lang]);

  useEffect(() => {
    if (!(medicines && medicines.length > 0)) dispatch(getMedicines());
    dispatch(getMedCategories());
    dispatch(getCompanies());
    dispatch(getSuppliers());
    dispatch(getSeoTags());
    dispatch(getDosages());
  }, []);

  // set Dosage List
  useEffect(() => {
    if (lang !== "" && dosages && dosages.length > 0) {
      const D = dosages.find((d) => d.lang === lang);
      if (D && D.data) setDosageList(D.data);
      else setDosageList([]);
      const DEn = dosages.find((d) => d.lang === "en");
      if (DEn && DEn.data) setDosageListEn(DEn.data);
      else setDosageListEn([]);
    }
  }, [dosages, lang]);

  // set medData
  useEffect(() => {
    setLoading(true);
    if (lang !== "" && medicines && medicines.length > 0) {
      let M = medicines.find((m) => m.lang === lang);
      let MEn = medicines.find((men) => men.lang === "en");
      let searchArr = [];
      if (M && M.data) {
        let arr = [];
        let arrImg = [];
        let arrVid = [];
        let tempArr = [...M.data];
        tempArr.map((x, i) => {
          let obj = { ...x };
          // Setup Image
          if (obj.image && obj.image.length > 0) {
            const I = obj.image.find((i) => i.default === true);
            I ? arrImg.push(I) : arrImg.push("");
          } else arrImg.push("");
          // Setup Video
          if (obj.video && obj.video.length > 0) {
            const V = obj.video.find((i) => i.default === true);
            V ? arrVid.push(V) : arrVid.push("");
          } else arrVid.push("");
          // Setup Category & Sub Category
          let catsArr = [];
          let subCatsArr = [];
          obj.subCats.map((z, k) => {
            if (categories.length > 0) {
              const Cat = categories.find((c) => c.lang === lang);
              if (Cat) {
                Cat.data.map((y, j) => {
                  const Sc = y.subCatList.find((sc) =>
                    lang === "en"
                      ? sc.fixedSubCatId === z.subCatId
                      : sc.subCatDetectorId === z.subCatId
                  );
                  Sc && catsArr.push(y);
                  Sc && subCatsArr.push(Sc.subCatName);
                  return 0;
                });
              }
            }
            return 0;
          });
          var cA = [...catsArr];
          if (cA.length > 0) {
            const unique = [
              ...new Map(cA.map((item) => [item["fixedCatId"], item])).values(),
            ];
            obj.catsArr = unique;
          }

          obj.subCatsArr = subCatsArr;
          // Setup Company
          if (companies.length > 0) {
            const Comp = companies.find((c) => c.lang === lang);
            if (Comp) {
              const C = Comp.data.find((c) =>
                lang === "en"
                  ? c.fixedCompanyId === x.companyId
                  : c.companyDetectorId === x.companyId
              );
              if (C) obj.company = C;
            }
          }
          // Setup Variants
          let variantArr = [];
          if (medVariants.length > 0) {
            x.variants.map((y, j) => {
              let V = medVariants.find((v) => v.fixedVariantId === y.variantId);
              if (V) {
                let vArr = { ...V };
                variantArr.push(vArr);
              }
            });
          }
          // Setup Tags
          let tagsArr = [];
          if (seoTags.length > 0) {
            const STag = seoTags.find((st) => st.lang === lang);
            if (STag) {
              x.tags.map((y, j) => {
                const T = STag.data.find((t) =>
                  lang === "en"
                    ? t.fixedId === y.tagsId
                    : t.tagDetectorId === y.tagsId
                );
                T && tagsArr.push(T);
              });
            }
          }
          obj.tagsArr = tagsArr;
          obj.variantInfo = variantArr;

          // Setup Active Tab
          obj.activeAdditionalSectionTab = x.additionalSections[0];

          // search Name
          obj.searchName = x.genericBrand;
          if (admins.length > 0) {
            let { adminName } = admins.find((a) => a._id === x.creatorId);
            if (adminName) obj.creatorName = adminName;
          }
          arr.push(obj);
          return 0;
        });
        setActiveImg(arrImg);
        setActiveVid(arrVid);
        searchArr = [...searchArr, ...arr];
        let searchEngVariants = [];
        if (MEn && MEn.data && lang !== "en") {
          // Search
          MEn.data.map((x, i) => {
            const M = arr.find(
              (m) => m.medicineDetectorId === x.fixedMedicineId
            );
            if (M) {
              const { searchName, ...others } = M;
              searchEngVariants.push({ searchName: x.genericBrand, ...others });
            }
            return 0;
          });
        }
        searchArr = [...searchArr, ...searchEngVariants];
        const unique = [
          ...new Map(searchArr.map((item) => [item["_id"], item])).values(),
        ];

        setSearchMedList(unique);
        setMedList(unique);
      } else {
        setMedList([]);
        setSearchMedList([]);
      }
      if (MEn && MEn.data) {
        let arr = [];
        MEn.data.map((x, i) => {
          let obj = { ...x };

          // Setup Category & Sub Category
          let catsArr = [];
          let subCatsArr = [];
          obj.subCats.map((z, k) => {
            if (categories.length > 0) {
              const Cat = categories.find((c) => c.lang === "en");
              if (Cat) {
                Cat.data.map((y, j) => {
                  const Sc = y.subCatList.find(
                    (sc) => sc.fixedSubCatId === z.subCatId
                  );
                  Sc && catsArr.push(y);
                  Sc && subCatsArr.push(Sc.subCatName);
                  return 0;
                });
              }
            }
            return 0;
          });
          var cA = [...catsArr];
          if (cA.length > 0) {
            const unique = [
              ...new Map(cA.map((item) => [item["fixedCatId"], item])).values(),
            ];
            obj.catsArr = unique;
          }

          obj.subCatsArr = subCatsArr;
          // Setup Company
          if (companies.length > 0) {
            const Comp = companies.find((c) => c.lang === "en");
            if (Comp) {
              const C = Comp.data.find((c) => c.fixedCompanyId === x.companyId);
              if (C) obj.company = C;
            }
          }
          // Setup Variants
          let variantArr = [];
          if (medVariants.length > 0) {
            x.variants.map((y, j) => {
              let V = medVariants.find((v) => v.fixedVariantId === y.variantId);
              if (V) {
                let vArr = { ...V };
                variantArr.push(vArr);
              }
            });
          }
          // Setup Tags
          let tagsArr = [];
          if (seoTags.length > 0) {
            const STag = seoTags.find((st) => st.lang === "en");
            if (STag) {
              x.tags.map((y, j) => {
                const T = STag.data.find((t) => t.fixedId === y.tagsId);
                T && tagsArr.push(T);
              });
            }
          }
          obj.tagsArr = tagsArr;
          obj.variantInfo = variantArr;
          arr.push(obj);
          return 0;
        });
        // setActiveImgEn(arrImg);
        // setActiveVid(arrVid);
        setTotalPage(Math.ceil(M.data.length / 12));

        setMedListEn(arr);
      } else {
        setMedListEn([]);
        setTotalPage(0);
      }
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [
    categories,
    // companies,
    lang,
    medicines,
    // medVariants,
    // suppliers,
    // seoTags,
    // admins,
  ]);

  // Functions
  const handleChangeImage = (i, y) => {
    let arr = [...activeImg];
    arr[i] = y;
    setActiveImg(arr);
  };

  const handleChangeVid = (i, y) => {
    let arr = [...activeVid];
    arr[i] = y;
    setActiveVid(arr);
  };

  const handleChangeLang = (e) => {
    // Pagination Stuff
    setSearchingMedList([]);
    setProductViewStart(0);
    setPageNum(1);
    setPaginateLoading(true);

    setSearchBarLoading(true);
    const L = langs.find((l) => l.langCode === e.target.value);
    if (L) setLangId(L._id);
    setLang(e.target.value);
    setTimeout(() => {
      setSearchBarLoading(false);
      setSearchingMedList([]);
      setPaginateLoading(false);
    }, 100);
  };

  const handleMobileVideo = (vid) => {
    setMobileVideoDlg(true);
    setMobileVideoX(vid);
  };

  // Handle Non Mobile Video
  const handleNonMobileVideo = (id) => {
    let arr = [...viewVidArr];
    if (arr.includes(id)) {
      const index = arr.indexOf(id);
      arr.splice(index, 1);
    } else arr.push(id);
    setViewVidArr(arr);
  };

  // Handle Price information
  const handlePriceInfo = (priceData) => {
    setPriceInfoDlg(true);
    setPriceInfoData(priceData);
  };

  // handle Medicine Delete
  const handleMedDelete = (id) => {
    setDeleteMedicineBuffer(id);
    setDeleteMedicineDlg(true);
  };

  // handle Confirm Delete
  const handleConfirmDelete = () => {
    dispatch(
      removeSingleMedicine({
        id: deleteMedicineBuffer,
        creatorId: localAdminData._id,
        lang,
      })
    );
    setDeleteMedicineDlg(false);
    setTimeout(() => {
      dispatch(getMedicines());
      dispatch(getNotification());
    }, 1000);
  };

  // handle Medicine Edit
  const handleMedEdit = (id) => {
    setEditMedicineDlg(true);
    const M = medList.find((m) => m._id === id);
    M && setEditMedicineBuffer(M);
  };

  // Handle Search
  const handleSearch = (e, newValue) => {
    let arr = [...newValue];
    const unique = [
      ...new Map(arr.map((item) => [item["_id"], item])).values(),
    ];
    let arrImg = [];
    let arrVid = [];
    (unique.length > 0 ? unique : searchMedList).forEach((x) => {
      let obj = { ...x };
      // Setup Image
      if (obj.image && obj.image.length > 0) {
        const I = obj.image.find((i) => i.default === true);
        I ? arrImg.push(I) : arrImg.push("");
      } else arrImg.push("");
      // Setup Video
      if (obj.video && obj.video.length > 0) {
        const V = obj.video.find((i) => i.default === true);
        V ? arrVid.push(V) : arrVid.push("");
      } else arrVid.push("");
    });
    setActiveImg(arrImg);
    setActiveVid(arrVid);

    setSearchingMedList([...unique]);
  };

  // Handle Active Tab
  const handleSelectTab = (x, id) => {
    let arr = [...medList];
    const index = arr.indexOf(arr.find((a) => a.fixedMedicineId === id));
    arr[index].activeAdditionalSectionTab = x;
    setMedList(arr);
  };

  // get MEd Name En
  const getMedNameEn = (id) => {
    const M = medListEn.find((m) => m.fixedMedicineId === id);
    if (M) return M.genericBrand;
  };

  // get MEd Generic Name En
  const getMedGenericNameEn = (id) => {
    const M = medListEn.find((m) => m.fixedMedicineId === id);
    if (M) return M.generic;
  };

  // Fixed date
  const [showDate, setShowDate] = useState([]);

  const handleShowDate = (date) => {
    let arr = [...showDate];
    if (arr.includes(date)) {
      arr = arr.filter((a) => a !== date);
    } else {
      arr.push(date);
    }
    setShowDate(arr);
  };

  const getDosageName = (dosageId) => {
    let dosageName = "";
    const D = dosageList.find(
      (d) => (lang !== "en" ? d.dosageDetectorId : d.fixedDosageId) === dosageId
    );
    if (D && D.dosageName) dosageName = D.dosageName;
    return dosageName;
  };

  // ! translate
  const [siteLang, setSiteLang] = useState("en");
  const [langSettings1, setLangSettings1] = useState([]);
  const [langSettings2, setLangSettings2] = useState([]);
  const [langSettings3, setLangSettings3] = useState([]);
  const [langSettings4, setLangSettings4] = useState([]);
  const [langSettings5, setLangSettings5] = useState([]);
  const [langSettings6, setLangSettings6] = useState([]);
  const [langSettings7, setLangSettings7] = useState([]);
  const [langSettings8, setLangSettings8] = useState([]);
  const title1 = "Manage Medicine";
  const title2 = "Misc Words";
  const title3 = "Identification";
  const title4 = "Variation";
  const title5 = "Price Info";
  const title6 = "SEO";
  const title7 = "Actions";
  const title8 = "Additional Section";

  useEffect(() => {
    if (langs && langs.length > 0 && siteLang !== "") {
      const L = langs.find((l) => l.langCode === siteLang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      const A = arr.find((l) => l._title === title1);
      if (A) setLangSettings1(A.data);
      const B = arr.find((l) => l._title === title2);
      if (B) setLangSettings2(B.data);
      const C = arr.find((l) => l._title === title3);
      if (C) setLangSettings3(C.data);
      const D = arr.find((l) => l._title === title4);
      if (D) setLangSettings4(D.data);
      const E = arr.find((l) => l._title === title5);
      if (E) setLangSettings5(E.data);
      const F = arr.find((l) => l._title === title6);
      if (F) setLangSettings6(F.data);
      const G = arr.find((l) => l._title === title7);
      if (G) setLangSettings7(G.data);
      const H = arr.find((l) => l._title === title8);
      if (H) setLangSettings8(H.data);
    }
  }, [siteLang, langs]);

  const translate1 = (String) => translation(String, title1, 1);
  const translate2 = (String) => translation(String, title2, 2);
  const translate3 = (String) => translation(String, title3, 3);
  const translate4 = (String) => translation(String, title4, 4);
  const translate5 = (String) => translation(String, title5, 5);
  const translate6 = (String) => translation(String, title6, 6);
  const translate7 = (String) => translation(String, title7, 7);
  const translate8 = (String) => translation(String, title8, 8);

  const translation = (String, title, index) => {
    let translation = "";
    let settings = [];
    if (index === 1) {
      settings = langSettings1;
    } else if (index === 2) {
      settings = langSettings2;
    } else if (index === 3) {
      settings = langSettings3;
    } else if (index === 4) {
      settings = langSettings4;
    } else if (index === 5) {
      settings = langSettings5;
    } else if (index === 6) {
      settings = langSettings6;
    } else if (index === 7) {
      settings = langSettings7;
    } else if (index === 8) {
      settings = langSettings8;
    }
    const T = settings.find((ls) => ls.title === String);
    if (T && T.value) translation = T.value;
    else {
      let arr = [];
      if (langs && langs.length > 0 && siteLang !== "") {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.settings && LEn.settings.length > 0) arr = LEn.settings;
        const S = arr.find((l) => l._title === title);
        if (S) {
          const T = S.data.find((ls) => ls.title === String);
          if (T && T.value) translation = T.value;
        }
      }
    }
    return translation;
  };

  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setSiteLang(activeLang.langCode);
      setSearchingMedList([]);
      setProductViewStart(0);
      setPageNum(1);
    } else {
      if (localLangData) {
        setSiteLang(localLangData.langCode);
        setPageNum(1);
        setSearchingMedList([]);
        setProductViewStart(0);
      }
    }
    setPaginateLoading(true);
    setTimeout(() => {
      setPaginateLoading(false);
    }, 100);
  }, [activeLang]);
  // ! translate

  // Pagination
  const [totalPage, setTotalPage] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [productViewStart, setProductViewStart] = useState(0);
  const [paginateLoading, setPaginateLoading] = useState(false);

  const handlePageChange = (event, value) => {
    setPageNum(value);
    // setTimeout(() => {
    // scroll();
    // }, 250);
  };

  useEffect(() => {
    if (pageNum > 1) setProductViewStart((pageNum - 1) * 12);
    else setProductViewStart(0);
  }, [pageNum]);

  // handle Sort
  useEffect(() => {
    let sortedArr = [];
    if (sortBy === 1) {
      sortedArr = [...medList].sort((a, b) =>
        new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime()
          ? -1
          : 1
      );
    } else {
      sortedArr = [...searchMedList].sort((a, b) =>
        a.genericBrand.toLowerCase().replace(/\s/g, "") >
        b.genericBrand.toLowerCase().replace(/\s/g, "")
          ? 1
          : -1
      );
    }
    // setMedList(sortedArr)
    setSearchMedList(sortedArr);
  }, [sortBy, medList]);

  //! Translate
  // Store
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate

  return (
    <Box sx={{ maxWidth: !smUp ? 250 : "100%" }}>
      {langs.length > 0 && (
        <>
          <Grid
            container
            spacing={1}
            justifyContent="space-between"
            alignItems="center"
          >
            {/* Select Language */}
            <Grid item xs={12} md={4}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={2} sm={1} md={1.5}>
                  <IconButton>
                    <AddCircleOutlineOutlinedIcon
                      color="info"
                      size="large"
                      onClick={() => setAddLangDlg(true)}
                    />
                  </IconButton>
                  <AddLangDialog
                    addLangDlg={addLangDlg}
                    setAddLangDlg={setAddLangDlg}
                    setLang={setLang}
                  />
                </Grid>
                <Grid item xs={10} sm={4} md={5}>
                  <FormControl fullWidth>
                    <Select
                      value={lang}
                      label="language"
                      variant="standard"
                      onChange={handleChangeLang}
                      size="small"
                    >
                      {langs.map((x, i) => (
                        <MenuItem key={i} value={x.langCode} dense>
                          <img
                            alt={x.langCode}
                            src={`https://flagcdn.com/w20/${
                              x.langCode === "en" ? "gb" : x.langCode
                            }.png`}
                          />
                          <Button color="inherit" size="small">
                            {x.langName}
                          </Button>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {/* Add medicine */}
                <Grid item xs={12} sm={7} md={4.5}>
                  <Button
                    variant="contained"
                    size="small"
                    color="error"
                    fullWidth
                    onClick={() => setAddMedicineDlg(true)}
                  >
                    {translate1("Add Medicine")}
                  </Button>
                  {addMedicineDlg && (
                    <AddMedicineDialog
                      addMedicineDlg={addMedicineDlg}
                      setAddMedicineDlg={setAddMedicineDlg}
                      lang={lang || "en"}
                      langId={langId}
                      medListEn={medListEn || []}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            {/* Search */}
            <Grid item xs={12} md={8} sx={{ mt: { md: -2, xs: 0 } }}>
              {!searchBarLoading && (
                <Autocomplete
                  disablePortal
                  multiple
                  options={searchMedList}
                  onChange={(e, newValue) => handleSearch(e, newValue)}
                  filterOptions={filterOptions}
                  // getOptionLabel={(option) => option.searchName}
                  getOptionLabel={(option) =>
                    `${option.genericBrand} (${option.generic}) ${
                      lang !== "en"
                        ? " - " +
                          getMedNameEn(option.medicineDetectorId) +
                          " (" +
                          getMedGenericNameEn(option.medicineDetectorId) +
                          ")"
                        : ""
                    }`
                  }
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        src={
                          option.image.length > 0
                            ? option.image
                                .find((i) => i.default)
                                .src.includes("http")
                              ? option.image.find((i) => i.default).src
                              : PF +
                                "adminPanel/medicines/img/" +
                                option.image.find((i) => i.default).src
                            : PF + "adminPanel/medicines/no-medicine-image.jpg"
                        }
                        alt="admins"
                      />
                      {/* {option.genericBrand}{" "}
                      {lang !== "en" &&
                        " (" + getMedNameEn(option.medicineDetectorId) + ")"} */}
                      {option.genericBrand} {" (" + option.generic + ") "}
                      {lang !== "en"
                        ? " - " + getMedNameEn(option.medicineDetectorId)
                        : ""}
                      {lang !== "en"
                        ? " (" +
                          getMedGenericNameEn(option.medicineDetectorId) +
                          ")"
                        : ""}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={translate1("Search") + "..."}
                      variant="standard"
                      size="small"
                      fullWidth
                    />
                  )}
                />
              )}
            </Grid>
          </Grid>
          {/* Medicine List */}
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6}>
              <Button
                size="small"
                variant="contained"
                fullWidth
                sx={{ mt: 1, pointerEvents: "none" }}
              >
                {translate1("Medicines")} ({medList.length})
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth sx={{ mb: 2 }} size="small">
                <InputLabel id="demo-simple-select-label">
                  {translate0("Sort")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={sortBy}
                  label={translate0("Sort")}
                  onChange={(e) => setSortBy(e.target.value)}
                >
                  <MenuItem value={1}>{translate0("Creation")}</MenuItem>
                  <MenuItem value={2}>{translate0("Generic Brand")}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {!paginateLoading &&
            totalPage >= 1 &&
            // !medList.includes("null") &&
            !searchingMedList.length > 0 &&
            medList.length > 0 && (
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  mt: 2,
                }}
              >
                <Stack spacing={2}>
                  <Pagination
                    count={totalPage}
                    color="primary"
                    onChange={handlePageChange}
                    size={smUp ? "medium" : "small"}
                    hideNextButton={smUp ? false : true}
                    hidePrevButton={smUp ? false : true}
                  />
                </Stack>
              </Grid>
            )}
          <Box
            sx={{
              maxHeight: { xs: "60vh", sm: "53vh" },
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {loading ? (
              <LoadingBox w={"100%"} h={300} />
            ) : medList.length < 1 ? (
              <Alert
                severity="warning"
                sx={{ display: "flex", justifyContent: "center" }}
              >
                {translate2("No Medicine Available")}
              </Alert>
            ) : (
              (searchingMedList.length > 0
                ? searchingMedList
                : searchMedList.slice(productViewStart, productViewStart + 12)
              ).map((x, i) => (
                <Grid
                  container
                  key={i}
                  spacing={1}
                  sx={{
                    border: ".1rem solid pink",
                    borderRadius: ".25rem",
                    m: 1,
                    px: 1,
                    width: !smUp ? "95%" : "98%",
                  }}
                  justifyContent="center"
                >
                  {/* image section */}
                  <Grid item xs={12} sm={6} md={2}>
                    <Grid
                      container
                      spacing={0}
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {smUp && !mdUp && (
                        <Button
                          variant="outlined"
                          size="small"
                          fullWidth
                          sx={{ mb: 1 }}
                        >
                          {translate1("Image")}
                        </Button>
                      )}
                      <Grid item sx={{ mt: { md: 2 } }}>
                        <Box
                          sx={{
                            height: 150,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <LazyLoadImage
                            effect="blur"
                            src={
                              x.image && x.image.length > 0
                                ? x.image[0]?.src !== "" &&
                                  PF +
                                    "adminPanel/medicines/img/" +
                                    x.image[0].src
                                : PF + "adminPanel/medicines/blank.jpg"
                            }
                            alt={
                              x.image && x.image.length > 0
                                ? x.image[0]?.alt !== "" && x.image[0]?.alt
                                : "no-image"
                            }
                            title={
                              x.image && x.image.length > 0
                                ? x.image[0]?.alt !== "" && x.image[0]?.alt
                                : "no-image"
                            }
                            style={{
                              width: !xlUp ? "150px" : "150px",
                              // height: "150px",
                              objectFit: "cover",
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item>
                        <Grid container spacing={1} justifyContent="center">
                          {x.image.map((y, j) => (
                            <Grid item key={j}>
                              <>
                                {j < 4 && (
                                  <Box
                                    sx={{
                                      height: 30,
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <LazyLoadImage
                                      src={
                                        PF + "adminPanel/medicines/img/" + y.src
                                      }
                                      alt={y.alt}
                                      title={y.alt}
                                      onClick={() => handleChangeImage(i, y)}
                                      style={{
                                        width: !mdUp ? "30px" : "30px",
                                        height: "30px",
                                        objectFit: "cover",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </Box>
                                )}
                              </>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Video Section */}
                  <Grid item xs={12} sm={6} md={2.5}>
                    {!smUp ? (
                      //  Mobile
                      <Button
                        variant="contained"
                        fullWidth
                        size="small"
                        color="secondary"
                        onClick={() => handleMobileVideo(x)}
                      >
                        {translate1("Video")}
                      </Button>
                    ) : (
                      // Pc
                      <Grid
                        container
                        spacing={0}
                        flexDirection="column"
                        alignItems="center"
                      >
                        {smUp && (
                          <Button
                            variant={
                              viewVidArr.includes(x._id)
                                ? "outlined"
                                : "contained"
                            }
                            size="small"
                            fullWidth
                            onClick={() => handleNonMobileVideo(x._id)}
                            endIcon={
                              viewVidArr.includes(x._id) ? (
                                <ArrowDropUpIcon />
                              ) : (
                                <ArrowDropDownCircleIcon />
                              )
                            }
                          >
                            {translate1("Video")}
                          </Button>
                        )}
                        {viewVidArr.includes(x._id) &&
                          activeVid[i] &&
                          activeVid[i] !== "" && (
                            <Grid item sx={{ my: 1 }}>
                              {activeVid[i].externalLink ? (
                                <ReactPlayer
                                  url={activeVid[i] !== "" && activeVid[i].src}
                                  controls
                                  height={!mdUp ? "150px" : "190px"}
                                  width={!xlUp ? "150px" : "197px"}
                                />
                              ) : (
                                <CardMedia
                                  component="video"
                                  height={!xlUp ? 155 : 197}
                                  image={
                                    PF +
                                    "adminPanel/medicines/vid/" +
                                    (activeVid[i] !== "" && activeVid[i].src)
                                  }
                                  controls
                                />
                              )}
                            </Grid>
                          )}
                        {viewVidArr.includes(x._id) && (
                          <Grid item sx={{ mb: 1 }}>
                            <Grid container spacing={1} justifyContent="center">
                              {x.video &&
                                x.video.map((y, j) => (
                                  <Grid item key={j}>
                                    <>
                                      {j < 4 && (
                                        <>
                                          {y.externalLink ? (
                                            <ReactPlayer
                                              url={y.src}
                                              controls
                                              muted
                                              height="40px"
                                              width={!mdUp ? "40px" : "50px"}
                                              onPlay={() =>
                                                handleChangeVid(i, y)
                                              }
                                            />
                                          ) : (
                                            <CardMedia
                                              component="video"
                                              sx={{
                                                height: "40px",
                                                width: !mdUp ? "40px" : "80px",
                                              }}
                                              image={
                                                PF +
                                                "adminPanel/medicines/vid/" +
                                                y.src
                                              }
                                              // controls
                                              muted
                                              onClick={() =>
                                                handleChangeVid(i, y)
                                              }
                                            />
                                          )}{" "}
                                        </>
                                      )}
                                    </>
                                  </Grid>
                                ))}
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    )}
                  </Grid>
                  {/* Identification */}
                  <Grid item xs={12} md={2.5}>
                    <Button
                      variant="outlined"
                      size="small"
                      fullWidth
                      color="secondary"
                      sx={{ pointerEvents: "none" }}
                    >
                      {translate3("Identification")}
                    </Button>
                    <Grid
                      container
                      spacing={0}
                      flexDirection="column"
                      sx={{ ml: 1 }}
                    >
                      <Grid item>
                        <Badge
                          badgeContent={x.prescribed ? "Rx" : 0}
                          color="error"
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        >
                          <Typography variant="subtitle1">
                            {translate3("Generic")}:{" "}
                            <Typography
                              variant="subtitle1"
                              color="initial"
                              component={smUp && "span"}
                              sx={{ fontWeight: "600" }}
                            >
                              {x.generic.toUpperCase()}
                            </Typography>
                          </Typography>
                        </Badge>
                      </Grid>
                      <Divider />
                      <Grid item>
                        <Grid container spacing={0} alignItems="center">
                          <Grid item>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {translate3("Generic Brand")}:{" "}
                              <Typography
                                variant="subtitle1"
                                color="initial"
                                component={smUp && "span"}
                                sx={{ fontWeight: "600", marginLeft: 1 }}
                              >
                                {x.genericBrand.toUpperCase()}
                              </Typography>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  window.open(
                                    window.location.hostname === "localhost"
                                      ? "http://localhost:3000" +
                                          "/our-medicines/" +
                                          makeUrlFriendly(x.genericBrand)
                                      : "https://" +
                                          window.location.hostname +
                                          "/our-medicines/" +
                                          makeUrlFriendly(x.genericBrand),
                                    "_blank"
                                  )
                                }
                              >
                                <CallMadeIcon fontSize="small" color="info" />
                              </IconButton>
                            </Typography>
                          </Grid>
                          {lang !== "en" && (
                            <Grid item>
                              <Tooltip
                                arrow
                                title={`English Variant: ${getMedNameEn(
                                  x.medicineDetectorId
                                )}`}
                                placement="top"
                              >
                                <IconButton size="small">
                                  <Info color="info" />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      <Divider />
                      <Grid item>
                        <Typography variant="subtitle1">
                          {translate3("Category")}:{" "}
                          <Typography
                            variant="subtitle1"
                            color="initial"
                            component={smUp && "span"}
                            sx={{ fontWeight: "600" }}
                          >
                            {x.catsArr &&
                              x.catsArr.length > 0 &&
                              x.catsArr.map(
                                (y, j) => y.catName && y.catName.toUpperCase()
                              )}
                          </Typography>
                        </Typography>
                      </Grid>
                      <Divider />
                      <Grid item>
                        <Typography variant="subtitle1">
                          {translate3("Sub Category")}:{" "}
                          <Typography
                            variant="subtitle1"
                            color="initial"
                            component={smUp && "span"}
                            sx={{ fontWeight: "600" }}
                          >
                            {x.subCatsArr &&
                              x.subCatsArr.length > 0 &&
                              x.subCatsArr.map(
                                (y, j) =>
                                  y.toUpperCase() +
                                  (x.subCatsArr.length > 1 &&
                                  j < x.subCatsArr.length - 1
                                    ? ", "
                                    : "")
                              )}
                          </Typography>
                        </Typography>
                      </Grid>
                      <Divider />
                      <Grid item>
                        <Typography variant="subtitle1">
                          {translate3("Local Company")}:{" "}
                          <Typography
                            variant="subtitle1"
                            color="initial"
                            component={smUp && "span"}
                            sx={{
                              fontWeight: "600",
                            }}
                          >
                            {x.company && x.company.companyName.toUpperCase()}{" "}
                            {x.company && (
                              <img
                                src={
                                  PF +
                                  "/adminPanel/companies/" +
                                  x.company.companyLogo
                                }
                                alt={x.company && x.company.companyName}
                                style={{
                                  height: "18px",
                                  // width: "54px",
                                  objectFit: "cover",
                                  borderRadius: ".25rem",
                                }}
                              />
                            )}
                          </Typography>
                        </Typography>
                      </Grid>
                      <Divider />

                      <Grid item>
                        <Typography variant="subtitle1">
                          {translate3("Originator")}:{" "}
                          <Typography
                            variant="subtitle1"
                            color="initial"
                            component={smUp && "span"}
                            sx={{ fontWeight: "600" }}
                          >
                            {x.originator.toUpperCase()}
                          </Typography>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Variation info */}
                  <Grid item xs={12} md={2}>
                    <Button
                      variant="outlined"
                      size="small"
                      fullWidth
                      color="warning"
                      sx={{ pointerEvents: "none" }}
                    >
                      {translate4("Variations")}
                    </Button>
                    <Grid container spacing={0} flexDirection="column">
                      <Grid item>
                        <Typography variant="subtitle1">
                          {translate4("Dosage Form")}:{" "}
                          <Typography
                            variant="subtitle1"
                            color="initial"
                            component={smUp && "span"}
                            sx={{ fontWeight: "600" }}
                          >
                            {x.variantInfo &&
                              x.variantInfo.length > 0 &&
                              x.variantInfo.map(
                                (y, j) =>
                                  getDosageName(y.dosageForm).toUpperCase() +
                                  (x.variantInfo.length > 1 &&
                                  j < x.variantInfo.length - 1
                                    ? ", "
                                    : "")
                              )}
                          </Typography>
                        </Typography>
                      </Grid>

                      <Divider />
                      <Grid item>
                        <Typography variant="subtitle1">
                          {translate4("Strength")}:{" "}
                          <Typography
                            variant="subtitle1"
                            color="initial"
                            component={smUp && "span"}
                            sx={{ fontWeight: "600" }}
                          >
                            {x.variantInfo &&
                              x.variantInfo.length > 0 &&
                              x.variantInfo.map(
                                (y, j) =>
                                  y.strength &&
                                  y.strength.toUpperCase() +
                                    (x.variantInfo.length > 1 &&
                                    j < x.variantInfo.length - 1
                                      ? ", "
                                      : "")
                              )}
                          </Typography>
                        </Typography>
                      </Grid>
                      <Divider />
                      <Grid item>
                        <Typography variant="subtitle1">
                          {translate4("Pack Types")}:{" "}
                          <Typography
                            variant="subtitle1"
                            color="initial"
                            component={smUp && "span"}
                            sx={{ fontWeight: "600" }}
                          >
                            {x.variantInfo &&
                              x.variantInfo.length > 0 &&
                              x.variantInfo.map(
                                (y, j) =>
                                  y.packTypes &&
                                  formatMedicinePackSize(y.packTypes) +
                                    (x.variantInfo.length > 1 &&
                                    j < x.variantInfo.length - 1
                                      ? ", "
                                      : "")
                              )}
                          </Typography>
                        </Typography>
                      </Grid>
                      <Divider />
                      <Grid item>
                        <Badge badgeContent={x.stock} color="error">
                          <Typography
                            variant="subtitle1"
                            sx={{ color: x.stock ? "green" : "red" }}
                          >
                            {translate4("Stock")}:{" "}
                            {x.stock > 0
                              ? translate4("Available")
                              : translate4("Out Of Stock")}
                          </Typography>
                        </Badge>
                      </Grid>
                      {x.variantInfo && x.variantInfo.length > 0 && (
                        <>
                          <Divider />
                          <Grid item sx={{ my: 1 }}>
                            <Button
                              variant="contained"
                              size="small"
                              color="warning"
                              fullWidth
                              startIcon={<MonetizationOnIcon />}
                              onClick={() => handlePriceInfo(x.variantInfo)}
                            >
                              {translate5("Price Info")}
                            </Button>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                  {/* SEO Section */}
                  <Grid item xs={12} md={1.75}>
                    <Button
                      variant="outlined"
                      size="small"
                      fullWidth
                      color="info"
                      sx={{ pointerEvents: "none" }}
                    >
                      {translate2("SEO")}
                    </Button>
                    <Grid container spacing={0} flexDirection="column">
                      <Grid item>
                        <Typography variant="subtitle1">
                          {translate6("Meta Keyword")}:
                          <Typography
                            variant="body2"
                            color="initial"
                            component={smUp && "span"}
                            sx={{ fontSize: ".8rem", fontWeight: "300" }}
                          >
                            {" "}
                            {x.metaKeyword && x.metaKeyword}
                          </Typography>
                        </Typography>
                      </Grid>
                      <Divider />
                      <Grid item>
                        <Typography variant="subtitle1">
                          {translate6("Meta Title")}:
                          <Typography
                            variant="body2"
                            color="initial"
                            component={smUp && "span"}
                            sx={{ fontSize: ".8rem", fontWeight: "300" }}
                          >
                            {" "}
                            {x.metaTitle && x.metaTitle}
                          </Typography>
                        </Typography>
                      </Grid>
                      <Divider />
                      <Grid item>
                        <Typography variant="subtitle1">
                          {translate6("Meta Description")}:{" "}
                          <Typography
                            variant="body2"
                            color="initial"
                            component={smUp && "span"}
                            sx={{ fontSize: ".8rem", fontWeight: "300" }}
                          >
                            {x.metaDesc &&
                              x.metaDesc.replace(
                                "medicineforworld@gmail.com",
                                "info@elifesavingmedicine.com"
                              )}
                          </Typography>
                        </Typography>
                      </Grid>
                      <Divider />
                      <Grid item>
                        <Grid container spacing={1}>
                          {x.tags && x.tags.length > 2 ? (
                            <Grid item xs={12} sx={{ my: 1 }}>
                              <Accordion disableGutters>
                                <AccordionSummary
                                  expandIcon={<ExpandMore />}
                                  aria-label="Expand"
                                  aria-controls="-content"
                                  id="-header"
                                >
                                  <Typography>{translate6("Tags")}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Grid container spacing={0}>
                                    {x.tagsArr &&
                                      x.tagsArr.map((y, j) => (
                                        <Grid item key={j}>
                                          <Tooltip
                                            title={y.tagDetails}
                                            arrow
                                            placement="top"
                                          >
                                            <Button
                                              variant="text"
                                              size="small"
                                              color={y.severity}
                                            >
                                              {y.tagName}
                                            </Button>
                                          </Tooltip>
                                        </Grid>
                                      ))}
                                  </Grid>
                                </AccordionDetails>
                              </Accordion>
                            </Grid>
                          ) : (
                            <>
                              {x.tagsArr &&
                                x.tagsArr.map((y, j) => (
                                  <Grid item key={j}>
                                    <Tooltip
                                      title={y.tagDetails}
                                      arrow
                                      placement="top"
                                    >
                                      <Button
                                        variant="text"
                                        size="small"
                                        color={y.severity}
                                      >
                                        {y.tagName}
                                      </Button>
                                    </Tooltip>
                                  </Grid>
                                ))}
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Action Section */}
                  <Grid item xs={12} md={1.25}>
                    <Button
                      variant="outlined"
                      size="small"
                      fullWidth
                      color="success"
                      sx={{ pointerEvents: "none" }}
                    >
                      {translate2("Action")}
                    </Button>
                    <Grid container spacing={0} justifyContent="center">
                      <Grid item>
                        <IconButton onClick={() => handleMedEdit(x._id)}>
                          <EditRoundedIcon color="primary" />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <IconButton onClick={() => handleMedDelete(x._id)}>
                          <DeleteRoundedIcon color="error" />
                        </IconButton>
                      </Grid>
                      {x.creatorName && (
                        <Grid item xs={12}>
                          <Typography
                            variant="body2"
                            align={xlUp ? "left" : "center"}
                          >
                            {mdUp && !xlUp
                              ? ""
                              : `${translate2("Created By")}: `}
                            <Typography
                              variant="body2"
                              color="initial"
                              component={smUp && !xlUp && "span"}
                              sx={{ fontWeight: "600" }}
                            >
                              {x.creatorName.toUpperCase()}
                            </Typography>
                          </Typography>
                        </Grid>
                      )}
                      {x.createdAt && (
                        <Grid item xs={12}>
                          <Typography
                            variant="body2"
                            sx={{ fontSize: ".8rem" }}
                            align={xlUp ? "left" : "center"}
                          >
                            {mdUp && !xlUp ? "" : `${translate7("Created")}: `}
                            <Typography
                              variant="body2"
                              color="primary"
                              component={smUp && !xlUp && "span"}
                              sx={{ fontWeight: "600", fontSize: ".8rem" }}
                            >
                              {formatDistance(
                                new Date(x.createdAt),
                                new Date(),
                                {
                                  addSuffix: true,
                                }
                              )}
                              <Tooltip
                                arrow
                                placement="top"
                                title={getExactTime(x.createdAt)}
                              >
                                <IconButton
                                  size="small"
                                  onClick={() => handleShowDate(x.createdAt)}
                                >
                                  <InfoIcon color="info" />
                                </IconButton>
                              </Tooltip>
                              <br />
                              {showDate.includes(x.createdAt) &&
                                getExactTime(x.createdAt)}
                            </Typography>
                          </Typography>
                        </Grid>
                      )}
                      {x.updatedAt && (
                        <Grid item xs={12}>
                          <Typography
                            variant="body2"
                            sx={{ fontSize: ".8rem" }}
                            align={xlUp ? "left" : "center"}
                          >
                            {mdUp && !xlUp ? "" : `${translate2("Updated")}: `}
                            <Typography
                              variant="body2"
                              color="error"
                              component={smUp && !xlUp && "span"}
                              sx={{ fontWeight: "600", fontSize: ".8rem" }}
                            >
                              {formatDistance(
                                new Date(x.updatedAt),
                                new Date(),
                                {
                                  addSuffix: true,
                                }
                              )}
                              <Tooltip
                                arrow
                                placement="top"
                                title={getExactTime(x.updatedAt)}
                              >
                                <IconButton
                                  size="small"
                                  onClick={() => handleShowDate(x.updatedAt)}
                                >
                                  <InfoIcon color="info" />
                                </IconButton>
                              </Tooltip>
                              <br />
                              {showDate.includes(x.updatedAt) &&
                                getExactTime(x.updatedAt)}
                            </Typography>
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>

                  {/* Addition Section */}
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      size="small"
                      fullWidth
                      color="error"
                      sx={{ pointerEvents: "none" }}
                    >
                      {translate2("Additional Section")}
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1} justifyContent="center">
                      {x.additionalSections.map((y, j) => (
                        <Grid item sx={{ minWidth: !smUp && "100%" }} key={j}>
                          <Button
                            variant={
                              x.activeAdditionalSectionTab.sectionName ===
                              y.sectionName
                                ? "contained"
                                : "outlined"
                            }
                            color="primary"
                            size="small"
                            onClick={() =>
                              handleSelectTab(y, x.fixedMedicineId)
                            }
                          >
                            {translate8(y.sectionName) !== ""
                              ? translate8(y.sectionName)
                              : y.sectionName}
                          </Button>
                        </Grid>
                      ))}

                      <Grid item xs={12}>
                        <Typography variant="h5" color="primary">
                          {translate8(
                            x.activeAdditionalSectionTab.sectionName
                          ) !== ""
                            ? translate8(
                                x.activeAdditionalSectionTab.sectionName
                              )
                            : x.activeAdditionalSectionTab.sectionName}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12} sx={{ pb: 4 }}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: `${x.activeAdditionalSectionTab.content}`,
                          }}
                        ></div>
                        <Accordion sx={{ maxWidth: "90vw" }}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography>Source Code</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                              {x.activeAdditionalSectionTab.content}
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))
            )}
          </Box>
        </>
      )}
      {/* mobile video dialog */}
      <MedicineVideoDialog
        mobileVideoDlg={mobileVideoDlg}
        setMobileVideoDlg={setMobileVideoDlg}
        x={mobileVideoX}
      />
      <PriceInfoDialog
        priceInfoDlg={priceInfoDlg}
        setPriceInfoDlg={setPriceInfoDlg}
        data={priceInfoData}
        lang={lang}
      />
      {/* Delete Medicine Dialog */}
      <Dialog
        open={deleteMedicineDlg}
        onClose={() => {
          setDeleteMedicineDlg(false);
          setDeleteMedicineBuffer("");
        }}
      >
        <DialogContent>
          <DialogContentText>
            {translate7("Are you sure you want to delete this medicine?")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDeleteMedicineDlg(false);
              setDeleteMedicineBuffer("");
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="error">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {/* Edit Medicine Dialog */}
      {editMedicineDlg && (
        <EditMedicineDialog
          editMedicineDlg={editMedicineDlg}
          setEditMedicineDlg={setEditMedicineDlg}
          lang={lang}
          langId={langId}
          medListEn={medListEn}
          editMedicineBuffer={editMedicineBuffer}
          setEditMedicineBuffer={setEditMedicineBuffer}
        />
      )}
    </Box>
  );
}
