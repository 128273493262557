import React, { useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import {
  AppBar,
  IconButton,
  Slide,
  Toolbar,
  Typography,
  Grid,
  Input,
  TextField,
  InputAdornment,
  Stack,
  Tooltip,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { pink } from "@mui/material/colors";
import Switch from "@mui/material/Switch";

// Media Query
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// Icons
import CloseIcon from "@mui/icons-material/Close";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import AddIcon from "@mui/icons-material/Add";

// Lazy Image Load
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { PF } from "../config";
import LoadingButton from "@mui/lab/LoadingButton";
import PhoneInputWithCountrySelect, {
  formatPhoneNumberIntl,
} from "react-phone-number-input";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChangePassPortion from "./ChangePassPortion";
import {
  emailVerifyFromEditPage,
  getNotificationByCustomer,
  resetCheckPassResponse,
  resetUpdateProfileResponse,
  updateProfileFromEdit,
} from "../reduxToolkit/features/miscFront";
import AddSocialDialog from "./AddSocialDialog";
import CaptchaTikTakToe from "./misc/CaptchaTikTakToe";
import EmailVerificationDialog from "./EmailVerificationDialog";

// Transition
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Customized Switch
const GreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: pink[600],
    "&:hover": {
      backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: pink[600],
  },
}));

export default function EditCustomerProfile({
  editProfileDlg,
  setEditProfileDlg,
}) {
  // Media Query
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  // Refs
  const nameRef = useRef();
  const emailRef = useRef();

  const dispatch = useDispatch();

  //   Use State
  const [customerPic, setCustomerPic] = useState("");
  const [formEnable, setFormEnable] = React.useState(false);
  const [formLoading, setFormLoading] = React.useState(false);
  const [captchaDlg, setCaptchaDlg] = React.useState(false);
  const [name, setName] = React.useState("");
  const [nameFailed, setNameFailed] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [invalidEmail, setInvalidEmail] = React.useState(false);
  const [emailFailed, setEmailFailed] = React.useState(false);

  const [gender, setGender] = React.useState(false);
  const [genderChanged, setGenderChanged] = useState(false);

  const [address, setAddress] = React.useState("");
  const [whatsApp, setWhatsApp] = React.useState("");
  const [weChat, setWeChat] = React.useState("");
  const [skype, setSkype] = React.useState("");
  const [fb, setFb] = React.useState("");
  const [prevWhatsApp, setPrevWhatsApp] = React.useState("");
  const [prevWeChat, setPrevWeChat] = React.useState("");
  const [prevSkype, setPrevSkype] = React.useState("");
  const [prevFb, setPrevFb] = React.useState("");
  const [additionalSocialMedia, setAdditionalSocialMedia] = React.useState([]);
  const [additionalSocialMediaChanged, setAdditionalSocialMediaChanged] =
    React.useState(0);

  const [phone, setPhone] = React.useState("");
  const [subscribe, setSubscribe] = React.useState(true);
  const [vCode, setVCode] = useState("");

  // Dialogs
  const [addSocialLoading, setAddSocialLoading] = React.useState(false);
  const [addSocialDlg, setAddSocialDlg] = useState(false);

  const [verifyDlg, setVerifyDlg] = useState(false);
  const [verifyCodeInput, setVerifyCodeInput] = useState("");

  // Count down
  const [countDownStart, setCountDownStart] = React.useState("");

  //   Local Data
  const localData = JSON.parse(localStorage.getItem("loginInfo"));

  // Store
  const { webSettings } = useSelector((state) => state.webSettings);
  const { updateProfileResponse, emailVerifyFromEditPageResponse } =
    useSelector((state) => state.miscFront);

  //   useEffect
  // Set Edit Data
  useEffect(() => {
    if (localData) {
      localData.name && setName(localData.name);
      localData.email && setEmail(localData.email);
      localData.gender && localData.gender === "male"
        ? setGender(false)
        : setGender(true);
      localData.address && setAddress(localData.address);
      localData.phone && setPhone(localData.phone);
      setSubscribe(localData.subscribed || false);
      setWhatsApp(getSocialLink("whatsApp"));
      setPrevWhatsApp(getSocialLink("whatsApp"));
      setWeChat(getSocialLink("weChat"));
      setPrevWeChat(getSocialLink("weChat"));
      setSkype(getSocialLink("skype"));
      setPrevSkype(getSocialLink("skype"));
      setFb(getSocialLink("facebook"));
      setPrevFb(getSocialLink("facebook"));
      updateAdditionSocialArr();
    }
  }, []);

  // Check if edited
  const getSocialLink = (platform) => {
    let link = "";
    const sl = localData.socialArr.find((o) => o.socialName === platform);
    if (sl) link = sl.socialLink;
    return link;
  };

  // Form Enable
  useEffect(() => {
    if (localData) {
      if (
        name !== localData.name ||
        email !== localData.email ||
        address !== localData.address ||
        phone !== localData.phone ||
        subscribe !== localData.subscribed ||
        additionalSocialMediaChanged > 0 ||
        genderChanged ||
        whatsApp !== prevWhatsApp ||
        weChat !== prevWeChat ||
        skype !== prevSkype ||
        fb !== prevFb
      )
        setFormEnable(true);
      else setFormEnable(false);
    }
  }, [
    address,
    email,
    fb,
    localData,
    name,
    phone,
    skype,
    subscribe,
    weChat,
    whatsApp,
    additionalSocialMediaChanged,
  ]);

  // Verifycation
  useEffect(() => {
    if (vCode === Number(verifyCodeInput)) {
      setVerifyDlg(false);
      setVerifyCodeInput("");
      updateProfile();
    }
  }, [vCode, verifyCodeInput]);

  // check Email Response
  useEffect(() => {
    if (emailVerifyFromEditPageResponse.message) {
      if (!emailVerifyFromEditPageResponse.failed) setVerifyDlg(true);
      setFormLoading(false);
    }
  }, [emailVerifyFromEditPageResponse]);

  // Check update response
  useEffect(() => {
    if (updateProfileResponse.message) {
      if (!updateProfileResponse.failed) {
        dispatch(resetUpdateProfileResponse());
        setTimeout(() => setEditProfileDlg(false), 1000);
        // setEditProfileDlg(true);
      }
      setTimeout(() => setFormLoading(false), 1000);
    }
  }, [updateProfileResponse]);

  //   Functions

  const updateAdditionSocialArr = () => {
    setAdditionalSocialMedia(() =>
      [...localData.socialArr].filter(
        (o) =>
          o.socialName !== "whatsApp" &&
          o.socialName !== "weChat" &&
          o.socialName !== "skype" &&
          o.socialName !== "facebook"
      )
    );
  };

  // handle Add Social
  const handleAddSocial = () => {
    setAddSocialLoading(true);
    setTimeout(() => {
      setAddSocialDlg(true);
      setAddSocialLoading(false);
    }, 1000);
  };

  // Remove from social media
  const removeSocial = (index) => {
    setAdditionalSocialMediaChanged(additionalSocialMediaChanged - 1);
    let arr = [...additionalSocialMedia];
    arr.splice(index, 1);
    setAdditionalSocialMedia(arr);
  };

  // Email Verification
  const emailVerification = () => {
    setCountDownStart(
      new Date().getTime() + (webSettings.verificationInterval || 1) * 60000
    );
    let v = Math.floor(Math.random() * (999999 - 100000 + 1) + 100000);
    setVCode(v);
    v = v * 3875412698;
    dispatch(emailVerifyFromEditPage({ v, email }));
    // setVerifyDlg(true);
    setFormLoading(true);
  };

  const updateProfile = () => {
    setFormLoading(true);
    const obj = {
      name,
      email,
      address,
      phone,
      subscribed: subscribe,
      id: localData._id,
    };
    gender ? (obj.gender = "female") : (obj.gender = "male");
    let socialArr = [...additionalSocialMedia];
    let socialObj = {};
    if (whatsApp !== "") {
      socialObj.socialName = "whatsApp";
      socialObj.socialLink = whatsApp;
      socialArr.push(socialObj);
    }
    socialObj = {};
    if (weChat !== "") {
      socialObj.socialName = "weChat";
      socialObj.socialLink = weChat;
      socialArr.push(socialObj);
    }
    socialObj = {};
    if (skype !== "") {
      socialObj.socialName = "skype";
      socialObj.socialLink = skype;
      socialArr.push(socialObj);
    }
    socialObj = {};
    if (fb !== "") {
      socialObj.socialName = "facebook";
      socialObj.socialLink = fb;
      socialArr.push(socialObj);
    }
    obj.socialArr = socialArr;
    if (localData.expire) obj.expire = localData.expire;
    dispatch(updateProfileFromEdit(obj));
    setTimeout(() => {
      dispatch(getNotificationByCustomer(localData._id));
    }, 1000);
  };

  // handle Update Confirm
  const handleUpdateConfirm = () => {
    if (email !== localData.email) emailVerification();
    else updateProfile();
  };

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate

  return (
    <>
      {localData && (
        <Dialog
          open={editProfileDlg}
          onClose={() => setEditProfileDlg(false)}
          aria-labelledby={"edit-profile-dialog"}
          TransitionComponent={Transition}
          fullScreen={!smUp ? true : false}
        >
          <AppBar sx={{ position: "relative" }} color="secondary">
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  setEditProfileDlg(false);
                  dispatch(resetCheckPassResponse());
                }}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {translateFront("Edit Profile")}
              </Typography>
              <LoadingButton
                variant="contained"
                color="warning"
                size="small"
                endIcon={<UpgradeIcon />}
                loading={formLoading}
                loadingPosition="end"
                disabled={!formEnable}
                onClick={() => setCaptchaDlg(true)}
              >
                {translateFront("Update")}
              </LoadingButton>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <Grid container spacing={0} flexDirection="column">
              <Grid item>
                <Grid
                  container
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  {/* <Grid item>
                    <LazyLoadImage
                      effect="blur"
                      src={
                        customerPic !== ""
                          ? URL.createObjectURL(customerPic)
                          : localData.customerPic
                          ? localData.customerPic
                          : localData.gender
                          ? localData.gender === "male"
                            ? PF +
                              "frontend/default/man-default-customer-image.jpg"
                            : localData.gender === "female" &&
                              PF +
                                "frontend/default/women-default-customer-image.jpg"
                          : "https://picsum.photos/200/300"
                      }
                      alt={name}
                      style={{
                        objectFit: "cover",
                        width: "200px",
                      }}
                    />
                    <Typography
                      variant="body2"
                      align="center"
                      color="secondary"
                    >
                      Recommended: 🖼️(800x600)
                    </Typography>
                  </Grid> */}
                  {/* <Grid item position="relative">
                    <label htmlFor="icon-button-file">
                      <Input
                        accept="image/*"
                        id="icon-button-file"
                        required
                        type="file"
                        sx={{ display: "none" }}
                        onChange={(e) => setCustomerPic(e.target.files[0])}
                      />
                      <IconButton
                        color="primary"
                        aria-label="upload-picture"
                        component="span"
                      >
                        <PhotoCamera />
                      </IconButton>
                    </label>
                  </Grid> */}
                  {/* <Grid item>
                    {customerPic && (
                      <Button
                        variant="contained"
                        size="small"
                        color="error"
                        onClick={() => setCustomerPic("")}
                      >
                        Undo Change
                      </Button>
                    )}
                  </Grid> */}
                </Grid>
              </Grid>
              <Grid item sx={{ mt: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} ref={nameRef}>
                    <TextField
                      autoComplete="name"
                      required
                      fullWidth
                      label={translateFront("Name")}
                      autoFocus
                      size="small"
                      error={nameFailed ? true : false}
                      value={name}
                      helperText={
                        nameFailed && (
                          <Typography variant="body2" color="error">
                            {translateFront("Name Already Exist")}
                          </Typography>
                        )
                      }
                      onChange={(e) => {
                        setName(e.target.value);
                        setNameFailed(false);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} ref={emailRef}>
                    <TextField
                      required
                      fullWidth
                      label={translateFront("Email")}
                      error={
                        email !== "" &&
                        (invalidEmail || emailFailed ? true : false)
                      }
                      autoComplete="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setInvalidEmail(false);
                        setEmailFailed(false);
                      }}
                      size="small"
                      onBlur={() => {
                        email !== "" &&
                          (email.includes("@")
                            ? setInvalidEmail(false)
                            : setInvalidEmail(true));
                      }}
                      helperText={
                        email !== "" && invalidEmail ? (
                          "Email Not Valid"
                        ) : emailFailed ? (
                          <Typography variant="body2" color="error">
                            {translateFront("Email Already Exist")}
                          </Typography>
                        ) : (
                          email !== localData.email && (
                            <Typography
                              variant="body2"
                              sx={{ color: "#ff8c00" }}
                            >
                              {translateFront(
                                "New email will require verification on update"
                              )}
                            </Typography>
                          )
                        )
                      }
                    />
                  </Grid>

                  <ChangePassPortion />
                  <Grid item xs={12}>
                    <Grid container spacing={0}></Grid>
                    <Stack
                      direction="row"
                      spacing={1}
                      alignItems="center"
                      sx={{ mb: { xs: 1 } }}
                    >
                      <Button
                        variant="text"
                        size="small"
                        color="success"
                        startIcon={<MaleIcon />}
                        sx={{ pointerEvents: "none" }}
                      >
                        {" "}
                        {translateFront("Male")}{" "}
                      </Button>
                      <Tooltip arrow title="Choose Your Gender" placement="top">
                        <GreenSwitch
                          checked={gender}
                          onChange={(e) => {
                            setGender(e.target.checked);
                            setGenderChanged(!genderChanged);
                          }}
                        />
                      </Tooltip>
                      <Button
                        variant="text"
                        size="small"
                        color="secondary"
                        startIcon={<FemaleIcon />}
                        sx={{ pointerEvents: "none" }}
                      >
                        {translateFront("Female")}{" "}
                      </Button>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      multiline
                      minRows={3}
                      fullWidth
                      label={translateFront("Address")}
                      type="text"
                      autoComplete="address"
                      size="small"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container justifyContent="center">
                      <Grid item xs={12}>
                        <TextField
                          label={translateFront("WhatsApp")}
                          sx={{ mb: 1 }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <WhatsAppIcon sx={{ color: "#4ccf7a" }} />
                              </InputAdornment>
                            ),
                          }}
                          size="small"
                          variant="outlined"
                          fullWidth
                          value={whatsApp}
                          onChange={(e) => setWhatsApp(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label={translateFront("WeChat")}
                          sx={{ mb: 1 }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img
                                  src="https://img.icons8.com/doodle/48/000000/weixing.png"
                                  alt="WeChat"
                                  style={{ height: "1.5rem" }}
                                />
                              </InputAdornment>
                            ),
                          }}
                          size="small"
                          variant="outlined"
                          fullWidth
                          value={weChat}
                          onChange={(e) => setWeChat(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label={translateFront("Skype")}
                          sx={{ mb: 1 }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img
                                  src="https://img.icons8.com/color/48/000000/skype--v1.png"
                                  alt="Skype"
                                  style={{ height: "1.5rem" }}
                                />
                              </InputAdornment>
                            ),
                          }}
                          size="small"
                          variant="outlined"
                          fullWidth
                          value={skype}
                          onChange={(e) => setSkype(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label={translateFront("Facebook")}
                          sx={{ mb: 1 }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <FacebookIcon sx={{ color: "navy" }} />
                              </InputAdornment>
                            ),
                          }}
                          size="small"
                          variant="outlined"
                          fullWidth
                          value={fb}
                          onChange={(e) => setFb(e.target.value)}
                        />
                      </Grid>
                      {additionalSocialMedia.length > 0 &&
                        additionalSocialMedia.map((x, i) => (
                          <Grid item xs={12} key={i}>
                            <Grid container spacing={0}>
                              <Grid item sx={{ flexGrow: 2 }}>
                                <TextField
                                  label={x.socialName}
                                  value={x.socialLink}
                                  disabled
                                  sx={{ mb: 1 }}
                                  size="small"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item>
                                <Tooltip
                                  arrow
                                  title={translateFront("Remove")}
                                  placement="top"
                                >
                                  <IconButton
                                    size="small"
                                    onClick={() => removeSocial(i)}
                                  >
                                    <NotInterestedIcon color="error" />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                      <Grid item xs={12}>
                        <LoadingButton
                          fullWidth
                          variant="contained"
                          color="success"
                          size="small"
                          endIcon={<AddIcon />}
                          onClick={handleAddSocial}
                          loading={addSocialLoading}
                          loadingPosition="end"
                        >
                          {translateFront("Add Social Media")}
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="inherit">
                      {translateFront("Phone")}
                    </Typography>
                    <PhoneInputWithCountrySelect
                      international
                      withCountryCallingCode
                      placeholder={translateFront("Enter Phone Number")}
                      value={formatPhoneNumberIntl(phone)}
                      defaultCountry="US"
                      onChange={setPhone}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={subscribe}
                          color="warning"
                          onChange={(e) => setSubscribe(e.target.checked)}
                        />
                      }
                      label={
                        <Typography variant="body2">
                          {translateFront(
                            "I want to receive inspiration, marketing promotions and updates via email."
                          )}
                        </Typography>
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          {additionalSocialMedia && (
            <AddSocialDialog
              additionalSocialMedia={additionalSocialMedia}
              additionalSocialMediaChanged={additionalSocialMediaChanged}
              setAdditionalSocialMediaChanged={setAdditionalSocialMediaChanged}
              setAdditionalSocialMedia={setAdditionalSocialMedia}
              addSocialDlg={addSocialDlg}
              setAddSocialDlg={setAddSocialDlg}
            />
          )}
          {/* Captcha Dialog */}
          <Dialog
            open={captchaDlg}
            onClose={() => setCaptchaDlg(false)}
            aria-labelledby={"register-user"}
          >
            <DialogContent>
              <CaptchaTikTakToe
                loginFunc={handleUpdateConfirm}
                setCaptchaDlg={setCaptchaDlg}
              />
            </DialogContent>
          </Dialog>
          {/* Email Verification Dialog */}
          <EmailVerificationDialog
            verifyDlg={verifyDlg}
            setVerifyDlg={setVerifyDlg}
            verifyCodeInput={verifyCodeInput}
            setVerifyCodeInput={setVerifyCodeInput}
            countDownStart={countDownStart}
            setCountDownStart={setCountDownStart}
            logic={emailVerification}
          />
        </Dialog>
      )}
    </>
  );
}
