import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../config";

export const setPaymentAccount = createAsyncThunk(
  "order/setPaymentAccount",
  async (data) => {
    const res = await axiosInstance.post(
      `/api/order/set-payment-account/`,
      data
    );
    return res.data;
  }
);

export const getAllOrderBackEnd = createAsyncThunk(
  "order/getAllOrderBackend",
  async () => {
    const res = await axiosInstance.get(`/api/order/get-all-order-backend/`);
    return res.data;
  }
);

export const getOrderBackEndByCustomer = createAsyncThunk(
  "order/getOrderBackEndByCustomer",
  async (data) => {
    const res = await axiosInstance.get(
      `/api/order/get-order-backend-by-customer/${data.customerId}`,
      data
    );
    return res.data;
  }
);

// UpLoad Payment receipt image
export const uploadPaymentReceiptImg = createAsyncThunk(
  "order/uploadPaymentReceiptImg",
  async (data) => {
    const res = await axiosInstance.post(
      "/api/multer/singlePaymentReceiptImg/",
      data
    );
    return res.data;
  }
);

// submit payment receipt image
export const submitPaymentReceiptImg = createAsyncThunk(
  "order/submitPaymentReceiptImg",
  async (data) => {
    const res = await axiosInstance.post(
      "/api/order/submit-payment-receipt/",
      data
    );
    return res.data;
  }
);

// Reject payment
export const rejectPayment = createAsyncThunk(
  "order/rejectPayment",
  async (data) => {
    const res = await axiosInstance.post("/api/order/reject-payment/", data);
    return res.data;
  }
);

// Confirm payment
export const confirmPayment = createAsyncThunk(
  "order/confirmPayment",
  async (data) => {
    const res = await axiosInstance.post("/api/order/confirm-payment/", data);
    return res.data;
  }
);

// Shipped
export const shippedOrder = createAsyncThunk(
  "order/shippedOrder",
  async (data) => {
    const res = await axiosInstance.post("/api/order/shipped-order/", data);
    return res.data;
  }
);

// UpLoad Delivery Received image
export const uploadDeliveryReceivedImg = createAsyncThunk(
  "order/uploadDeliveryReceivedImg",
  async (data) => {
    const res = await axiosInstance.post(
      "/api/multer/singleDeliveryReceivedImg/",
      data
    );
    return res.data;
  }
);

// confirm customer order complete
export const confirmCustomerOrderComplete = createAsyncThunk(
  "order/confirmCustomerOrderComplete",
  async (data) => {
    const res = await axiosInstance.post(
      "/api/order/confirm-customer-order-complete/",
      data
    );
    return res.data;
  }
);

// edit Order
export const editOrder = createAsyncThunk("order/editOrder", async (data) => {
  const res = await axiosInstance.post("/api/order/edit-order/", data);
  return res.data;
});

// delete order
export const deleteOrder = createAsyncThunk(
  "order/deleteOrder",
  async (data) => {
    const res = await axiosInstance.post("/api/order/delete-order/", data);
    return res.data;
  }
);

// Initial State
const initialState = {
  pending: false,
  error: false,
  orderBackends: [],
  orderBackendsByCustomer: [],
  snackNotiOrderBackend: {},
};

// Slice
export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    resetSnkOrderBackend(state) {
      state.snackNotiOrderBackend = {};
    },
  },
  extraReducers: {
    [setPaymentAccount.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [setPaymentAccount.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiOrderBackend = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [setPaymentAccount.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [getAllOrderBackEnd.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getAllOrderBackEnd.fulfilled]: (state, action) => {
      state.pending = false;
      state.orderBackends = action.payload;
    },
    [getAllOrderBackEnd.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [getOrderBackEndByCustomer.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getOrderBackEndByCustomer.fulfilled]: (state, action) => {
      state.pending = false;
      state.orderBackendsByCustomer = action.payload;
    },
    [getOrderBackEndByCustomer.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [uploadPaymentReceiptImg.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [uploadPaymentReceiptImg.fulfilled]: (state, action) => {
      state.pending = false;
    },
    [uploadPaymentReceiptImg.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [submitPaymentReceiptImg.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [submitPaymentReceiptImg.fulfilled]: (state, action) => {
      state.pending = false;
    },
    [submitPaymentReceiptImg.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [rejectPayment.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [rejectPayment.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiOrderBackend = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [rejectPayment.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [confirmPayment.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [confirmPayment.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiOrderBackend = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [confirmPayment.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [shippedOrder.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [shippedOrder.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiOrderBackend = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [shippedOrder.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [uploadDeliveryReceivedImg.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [uploadDeliveryReceivedImg.fulfilled]: (state, action) => {
      state.pending = false;
      // state.orderBackends = action.payload;
    },
    [uploadDeliveryReceivedImg.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [confirmCustomerOrderComplete.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [confirmCustomerOrderComplete.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiOrderBackend = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [confirmCustomerOrderComplete.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [editOrder.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [editOrder.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiOrderBackend = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [editOrder.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [deleteOrder.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [deleteOrder.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiOrderBackend = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [deleteOrder.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
  },
});
//
export const { resetSnkOrderBackend } = orderSlice.actions;
export default orderSlice.reducer;
