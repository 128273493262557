import { generateRandomNumberInRange } from "./generateRandomNumberInRange";

export const generateOrderNumber = () => {
  let str = "";
  const rand = generateRandomNumberInRange(999, 9999);
  const D = new Date();
  let month = D.getMonth();
  let day = D.getDate();
  let year = D.getFullYear();
  if (month < 10) month = "0" + month;
  if (day < 10) day = "0" + day;
  year = year.toString().slice(2, 4);
  str = day + month + year + rand;
  return str;
};
