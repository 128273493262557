import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {
  Autocomplete,
  Box,
  Button,
  createFilterOptions,
  FormControl,
  InputAdornment,
  InputLabel,
  Select,
  Typography,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import PhoneInput from "react-phone-number-input";

import { useDispatch, useSelector } from "react-redux";
import {
  createNoti,
  uploadTempPic,
} from "../../../../../reduxToolkit/features/adminPanel/misc";
import { PF } from "../../../../../config";
import { countries } from "../../../../../utils/countryList";
import { paymentMedia } from "../../data/paymentMedia";
import {
  getCompanies,
  getDosages,
  getMedicines,
} from "../../../../../reduxToolkit/features/adminPanel/medicine";
import LoadingBox from "../../../../../components/misc/Loading";
import ViewProductDetails from "../ViewProductDetails";
import ConsigneeInfo from "./ConsigneeInfo";
import PaymentInfo from "./PaymentInfo";
import ChooseItems from "./ChooseItems";
import AdditionalCost from "./AdditionalCost";
import TotalCost from "./TotalCost";
import InvoiceRemarks from "./InvoiceRemarks";
import CompanyInfo from "./CompanyInfo";
import PreviewInvoice from "./PreviewInvoice";
import DiscountsCost from "./DiscountsCost";
import PreviewInvoiceNew from "./PreviewInvoiceNew";

export default function ManualInvoice() {
  // LocalData
  const localLangData = JSON.parse(localStorage.getItem("lang"));
  const localManualInvoiceData = JSON.parse(
    localStorage.getItem("manual-invoice-objects")
  );
  // store
  const { langs, activeLang } = useSelector((state) => state.misc);

  const [lang, setLang] = useState("");
  const [langId, setLangId] = useState("");
  const [consigneeInfoObj, setConsigneeInfoObj] = useState({
    name: "",
    address: "",
    email: "",
    city: "",
    postCode: "",
    country: "",
    phoneCode: "",
    phone: "",
    additionalConsigneeInfo: "",
  });

  const [paymentInfoObj, setPaymentInfoObj] = useState(paymentMedia[0]);
  const [paymentInfoArr, setPaymentInfoArr] = useState(paymentMedia);
  const [paymentInfo, setPaymentInfo] = useState("");

  const [selectedVariantArr, setSelectedVariantArr] = useState([]);

  const [additionalCostArr, setAdditionalCostArr] = useState([]);
  const [discountsArr, setDiscountsArr] = useState([]);

  const [total, setTotal] = useState({
    usdTotal: undefined,
    usdTotalInText: "",
    variantTotal: undefined,
    variantTotalWithSymbol: "",
    variantTotalInText: "",
  });

  const [remarks, setRemarks] = useState({
    creditTerms: `
    <p>Country of Origin: Bangladesh</p>
    <p>Port of Shipment: From Dhaka to </p>
    <p>Delivery: Immediately</p>
    <p>Payment: 100% in advance before shipment.</p>
    <p>Payment Charges: Inside and outside charge must be bearded by importer Packing: Export Standard</p>
    <p>Shipment: Courier providers Terms and Conditions applicable</p>
    `,
    declaration: `<p>We declare that this Proforma Invoice shows the actual price of the goods described and that all particulars are true and correct.</p>`,
    finalDestination: "",
    invoiceNumber: "",
    date: new Date(),
    extraContent: ``,
  });

  const [companyInfo, setCompanyInfo] = useState({
    companyName: "Medicine For World (MFW)",
    companyShortForm: "MFW",
    companyLogo: undefined,
    companyDetails: `
    <p>H# DCC 1, Momin Shoroni</p>
    <p>Road, North Ibrahimpur, Mirpur-14, Dhaka-1206,</p>
    <p>Dhaka, Bangladesh</p>
    <p>Phone: +8801916942634, +8801764000174</p>
    <p>Emails: info@medicineforworld.com.bd</p>
    <p>medicineforworld@gmail.com</p>
    <p>Website: www.medicineforworld.com.bd</p>
    `,
    companySil: undefined,
    companyWatermark: undefined,
  });

  const [customerSignature, setCustomerSignature] = useState("");

  const [previewDlg, setPreviewDlg] = useState(false);

  // Calculate Total
  useEffect(() => {
    let total = 0;
    additionalCostArr.forEach((ac) => {
      total += Number(ac.cost);
    });

    discountsArr.forEach((dis) => {
      total -= Number(dis.cost);
    });

    selectedVariantArr.forEach((sv) => {
      let itemCost = Number(sv.unitPrice) * Number(sv.qty);
      total += itemCost;
    });
    setTotal({ usdTotal: total, variantTotal: undefined });
  }, [selectedVariantArr, additionalCostArr, discountsArr]);

  // UseEffect
  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setLang(activeLang.langCode);
      setLangId(activeLang._id);
    } else {
      if (localLangData) {
        setLang(localLangData.langCode);
        setLangId(localLangData._id);
      }
    }
  }, [activeLang]);

  useEffect(() => {
    if (localManualInvoiceData) {
      const local = localManualInvoiceData;
      if (local.additionalCostArr) {
        setAdditionalCostArr(local.additionalCostArr);
      }
      if (local.companyInfo) {
        setCompanyInfo(local.companyInfo);
      }
      if (local.consigneeInfoObj) {
        setConsigneeInfoObj(local.consigneeInfoObj);
      }
      if (local.discountsArr) {
        setDiscountsArr(local.discountsArr);
      }
      if (local.paymentInfo) {
        setPaymentInfo(local.paymentInfo);
      }
      if (local.paymentInfoObj) {
        setPaymentInfoObj(local.paymentInfoObj);
      }
      // if (local.remarks) {
      //   setRemarks(local.remarks);
      // }
      if (local.selectedVariantArr) {
        setSelectedVariantArr(local.selectedVariantArr);
      }
      if (local.total) {
        setTotal(local.total);
      }
    }
  }, []);

  //! Translate
  // Store
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate

  return (
    <>
      <Grid
        container
        spacing={1}
        justifyContent="flex-start"
        // alignItems="center"
      >
        <Grid item xs={12}>
          <Button
            variant="contained"
            size="small"
            fullWidth
            sx={{ pointerEvents: "none", mb: 1 }}
          >
            {translate0("Manual Proforma Invoice")}
          </Button>
        </Grid>

        {/* Consignee Info */}
        <ConsigneeInfo
          consigneeInfoObj={consigneeInfoObj}
          setConsigneeInfoObj={setConsigneeInfoObj}
        />

        {/* PaymentInfo */}
        <PaymentInfo
          paymentInfoObj={paymentInfoObj}
          setPaymentInfoObj={setPaymentInfoObj}
          paymentInfoArr={paymentInfoArr}
          setPaymentInfoArr={setPaymentInfoArr}
          paymentInfo={paymentInfo}
          setPaymentInfo={setPaymentInfo}
        />

        <ChooseItems
          lang={lang}
          localLangData={localLangData}
          selectedVariantArr={selectedVariantArr}
          setSelectedVariantArr={setSelectedVariantArr}
        />

        <AdditionalCost
          additionalCostArr={additionalCostArr}
          setAdditionalCostArr={setAdditionalCostArr}
        />

        <DiscountsCost
          additionalCostArr={discountsArr}
          setAdditionalCostArr={setDiscountsArr}
        />
        <TotalCost total={total} setTotal={setTotal} />
        <InvoiceRemarks
          remarks={remarks}
          setRemarks={setRemarks}
          companyShortForm={companyInfo.companyShortForm}
        />

        <Grid item xs={12}>
          <TextField
            label="Override Customer Signature"
            value={customerSignature}
            onChange={(e) => setCustomerSignature(e.target.value)}
            size="small"
            fullWidth
            variant="standard"
          />
        </Grid>

        <CompanyInfo
          companyInfo={companyInfo}
          setCompanyInfo={setCompanyInfo}
          // companyLogo={companyLogo}
          // setCompanyLogo={setCompanyLogo}
        />

        <Grid item xs={12}>
          <Button
            variant="contained"
            size="small"
            sx={{ mb: 1 }}
            fullWidth
            color="error"
            onClick={() => setPreviewDlg(true)}
          >
            {translate0("Preview")}
          </Button>
        </Grid>
      </Grid>

      {previewDlg && (
        <PreviewInvoice
          open={previewDlg}
          setOpen={setPreviewDlg}
          consigneeInfoObj={consigneeInfoObj}
          paymentInfoObj={paymentInfoObj}
          paymentInfo={paymentInfo}
          selectedVariantArr={selectedVariantArr}
          additionalCostArr={additionalCostArr}
          discountsArr={discountsArr}
          total={total}
          remarks={remarks}
          companyInfo={companyInfo}
          customerSignature={customerSignature}
        />
      )}
    </>
  );
}
