import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import AddressForm from "./AddressForm";
import PaymentForm from "./PaymentForm";
import OrderReview from "./OrderReview";
import {
  Dialog,
  IconButton,
  Slide,
  Grid,
  Divider,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// Icons
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import {
  createPrescription,
  getLatestOrder,
  getPrescriptionByCustomer,
  getShippingDetails,
  saveShippingDetails,
  updateOrder,
  uploadPrescription,
} from "../../reduxToolkit/features/frontend/checkout";
import { useEffect } from "react";
import Prescriptions from "./Prescriptions";
import {
  getCartInfoByCustomer,
  removeFromCartByCustomer,
} from "../../reduxToolkit/features/frontend/cart";
import { generateRandomNumberInRange } from "../../hooks/generateRandomNumberInRange";
import LoadingBox from "../misc/Loading";
import { generateOrderNumber } from "../../hooks/generateOrderNumber";
import { useNavigate } from "react-router-dom";
import {
  getNotificationByCustomer,
  openOrderHistoryDialog,
  openProfileDrawerDialog,
} from "../../reduxToolkit/features/miscFront";
import getSymbolFromCurrency from "currency-symbol-map";
import ContactUsForm from "../forms/ContactUsForm";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const steps = ["Prescription", "Shipping", "Payment", "Review"];

function getStepContent(
  step,
  setCompletedStep,
  addressFormObj,
  setAddressFormObj,
  prescribeData,
  setPrescribeData,
  paymentData,
  setPaymentData,
  carts
) {
  switch (step) {
    case 0:
      return (
        <Prescriptions
          prescribeData={prescribeData}
          setPrescribeData={setPrescribeData}
        />
      );
    case 1:
      return (
        <AddressForm
          setCompletedStep={setCompletedStep}
          addressFormObj={addressFormObj}
          setAddressFormObj={setAddressFormObj}
          carts={carts}
        />
      );
    case 2:
      return (
        <PaymentForm
          setCompletedStep={setCompletedStep}
          addressFormObj={addressFormObj}
          paymentData={paymentData}
          setPaymentData={setPaymentData}
        />
      );
    case 3:
      return (
        <OrderReview
          addressFormObj={addressFormObj}
          paymentData={paymentData}
        />
      );
    default:
      throw new Error("Unknown step");
  }
}

export default function CheckoutMain({ checkoutDlg, setCheckoutDlg }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Responsive
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  const [activeStep, setActiveStep] = React.useState(0);
  const [completedStep, setCompletedStep] = useState(1);
  const [loadingMain, setLoadingMain] = useState(false);
  const [loadingBack, setLoadingBack] = useState(false);
  const [loadingNext, setLoadingNext] = useState(false);
  const [orderNumber, setOrderNumber] = useState("");

  const [contactDlg, setContactDlg] = useState(false);

  // Currency
  const [exchangeRate, setExchangeRate] = React.useState(1);
  const [currencyName, setCurrencyName] = React.useState("USA");

  // Local Store
  const localCustomerData = () => {
    const c = JSON.parse(localStorage.getItem("loginInfo"));
    return c ? c : {};
  };

  const localLangData = () => {
    const c = JSON.parse(localStorage.getItem("langFront"));
    return c ? c : {};
  };

  // Store
  const { shippingDetails, latestOrder } = useSelector(
    (state) => state.checkout
  );
  const { carts } = useSelector((state) => state.cart);
  const { activeCurrencyFront } = useSelector((state) => state.miscFront);

  // set currency
  React.useEffect(() => {
    setExchangeRate(activeCurrencyFront.exchangeRate);
    setCurrencyName(activeCurrencyFront.currencyName);
  }, [activeCurrencyFront]);

  // Prescription Page
  const [prescribeData, setPrescribeData] = useState([]);

  // Address Form
  const [addressFormObj, setAddressFormObj] = useState({});

  // Payment Page
  const [paymentData, setPaymentData] = useState({});

  const handleNext = () => {
    setLoadingNext(true);

    if (activeStep === 0) {
      prescribeData.forEach((x) => {
        if (x.imageArr.length > 0) {
          let { imageArr, ...obj } = x;
          let arr = [];
          x.imageArr.forEach((i) => {
            if (i !== "") {
              const data = new FormData();
              const ext = i.name.split(".");
              const filename =
                Date.now() +
                "-" +
                x.medicineId.replace(/\s+/g, "") +
                "-prescription." +
                ext.slice(-1);
              data.append("name", filename);
              data.append("prescription", i);
              arr.push({ src: filename });
              dispatch(uploadPrescription(data));
            }
          });
          obj.imageArr = arr;
          dispatch(createPrescription(obj));
        }
      });
      setTimeout(() => {
        dispatch(
          getPrescriptionByCustomer({ customerId: localCustomerData()._id })
        );
        dispatch(
          updateOrder({
            customerId: localCustomerData()._id,
            cartArr: carts,
            stepsCompleted: 0,
          })
        );
      }, 1000);
    }

    // Address Form
    if (activeStep === 1) {
      const { selectedDeliveryMethod, deliveryNote, shippingCost, ...others } =
        addressFormObj;
      if (addressFormObj.saveShipping === true) {
        const obj = { ...others, customerId: localCustomerData()._id };
        dispatch(saveShippingDetails(obj));
      }
      dispatch(
        updateOrder({
          customerId: localCustomerData()._id,
          cartArr: carts,
          stepsCompleted: 1,
          deliveryMethodId: selectedDeliveryMethod,
          deliveryNote: deliveryNote,
          shippingDetails: { ...others },
          shippingCost,
        })
      );
    }

    // Payment Form
    if (activeStep === 2) {
      dispatch(
        updateOrder({
          customerId: localCustomerData()._id,
          cartArr: carts,
          stepsCompleted: 2,
          paymentMethodId: paymentData.selectedPaymentMethod,
          paymentNote: paymentData.paymentNote,
        })
      );
    }

    // Order Review
    if (activeStep === 3) {
      const o = generateOrderNumber();
      setOrderNumber(o);
      dispatch(
        updateOrder({
          customerId: localCustomerData()._id,
          cartArr: carts,
          stepsCompleted: 3,
          orderedItems: carts,
          orderNumber: o,
          langId: localLangData()._id,
          exchangeRate,
          currencySymbol: getSymbolFromCurrency(currencyName),
        })
      );
      setTimeout(() => {
        if (localCustomerData()._id)
          dispatch(
            removeFromCartByCustomer({ customerId: localCustomerData()._id })
          );
      }, 1000);
    }
    setTimeout(() => {
      setActiveStep(activeStep + 1);
      setLoadingNext(false);
      if (activeStep === 3) {
        if (localCustomerData()._id)
          dispatch(getNotificationByCustomer(localCustomerData()._id));
      }
    }, 1000);
  };

  const handleBack = () => {
    setLoadingBack(true);
    setTimeout(() => {
      setActiveStep(activeStep - 1);
      setLoadingBack(false);
    }, 1000);
  };

  const handleCheckoutClose = () => {
    setCheckoutDlg(false);
  };

  useEffect(() => {
    if (localCustomerData()._id) {
      dispatch(getShippingDetails(localCustomerData()._id));
      dispatch(getCartInfoByCustomer(localCustomerData()._id));
      dispatch(getLatestOrder({ customerId: localCustomerData()._id }));
    }
  }, []);

  React.useEffect(() => {
    if (shippingDetails.firstName !== "" && !(latestOrder && latestOrder._id)) {
      setAddressFormObj({ ...shippingDetails });
    }
  }, [shippingDetails, latestOrder]);

  useEffect(() => {
    // setLoadingMain(true);
    if (latestOrder && latestOrder._id) {
      if (latestOrder.shippingDetails) {
        let addressObj = {
          ...latestOrder.shippingDetails,
        };
        addressObj.selectedDeliveryMethod = latestOrder.deliveryMethodId;
        addressObj.deliveryNote = latestOrder.deliveryNote;
        addressObj.shippingCost = latestOrder.shippingCost;
        setAddressFormObj(addressObj);
      }
      if (latestOrder.paymentMethodId) {
        let paymentObj = {
          selectedPaymentMethod: latestOrder.paymentMethodId,
          paymentNote: latestOrder.paymentNote,
        };
        setPaymentData(paymentObj);
      }
      setCompletedStep(latestOrder.stepsCompleted + 2);
      setActiveStep(latestOrder.stepsCompleted + 1);
    }
    // setTimeout(() => {
    //   setLoadingMain(false);
    // }, 1000);
  }, [latestOrder, checkoutDlg]);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate

  return (
    <>
      <Dialog
        fullScreen={mdUp ? false : true}
        open={checkoutDlg}
        onClose={handleCheckoutClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCheckoutClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {translateFront("Checkout")}
            </Typography>
          </Toolbar>
        </AppBar>

        {loadingMain ? (
          <></>
        ) : (
          <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
            <Paper
              variant="outlined"
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
            >
              <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>
                      <span
                        style={{
                          fontSize: ".8rem",
                          display: !smUp ? "none" : "block",
                        }}
                      >
                        {translateFront(label)}
                      </span>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
              <React.Fragment>
                {activeStep === steps.length ? (
                  <React.Fragment>
                    <Typography variant="h5" gutterBottom>
                      {translateFront("Thank you for your order")}.
                    </Typography>
                    <Typography variant="subtitle1">
                      {translateFront("Your order number is")}{" "}
                      <span style={{ color: "navy" }}>#{orderNumber}</span>.{" "}
                      {translateFront(
                        "We will review your order and you will be notified through email and notification with payment submission details"
                      )}
                      .
                    </Typography>
                    <Divider sx={{ my: 1 }} />
                    <Grid container spacing={0} justifyContent="space-around">
                      <Grid item>
                        <Button
                          variant="text"
                          color="primary"
                          size="small"
                          onClick={() => {
                            handleCheckoutClose();
                            setContactDlg(true);
                          }}
                        >
                          {translateFront("Contact Us")}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="text"
                          color="warning"
                          size="small"
                          onClick={() => {
                            handleCheckoutClose();
                            // dispatch(openProfileDrawerDialog(true));
                            dispatch(openOrderHistoryDialog(true));
                          }}
                        >
                          {translateFront("Order History")}
                        </Button>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {getStepContent(
                      activeStep,
                      setCompletedStep,
                      addressFormObj,
                      setAddressFormObj,
                      prescribeData,
                      setPrescribeData,
                      paymentData,
                      setPaymentData,
                      carts
                    )}
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      {activeStep !== 0 && (
                        <LoadingButton
                          onClick={handleBack}
                          sx={{ mt: 3, ml: 1 }}
                          loading={loadingBack}
                        >
                          {translateFront("Back")}
                        </LoadingButton>
                      )}

                      <LoadingButton
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 3, ml: 1 }}
                        disabled={activeStep >= completedStep}
                        loading={loadingNext}
                      >
                        {activeStep === steps.length - 1
                          ? translateFront("Place Order")
                          : translateFront("Next")}
                      </LoadingButton>
                    </Box>
                  </React.Fragment>
                )}
              </React.Fragment>
            </Paper>
          </Container>
        )}
      </Dialog>
      {contactDlg && (
        <Dialog open={contactDlg} aria-labelledby={"contact-dlg"}>
          <DialogTitle>{translateFront("Contact Us")}</DialogTitle>
          <DialogContent>
            <ContactUsForm />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setContactDlg(false)} color="primary">
              {translateFront("Close")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
