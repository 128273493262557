import { ExpandMore } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNotification } from "../../../reduxToolkit/features/adminPanel/notification";

import LangNTitleBar from "./LangNTitleBar";
import {
  getManagedContentSettings,
  saveManagedContentSettings,
} from "../../../reduxToolkit/features/adminPanel/managedContentSettings";

// Media Query
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import InfoTut from "../../../components/InfoTut";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

import {
  createNoti,
  uploadTempPic,
} from "../../../reduxToolkit/features/adminPanel/misc";
import { PF } from "../../../config";

export default function ShippingPolicy({ expanded, handleChange, translate0 }) {
  const dispatch = useDispatch();
  // Media Query
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  const [loading, setLoading] = useState(false);
  const [lang, setLang] = useState("");
  const [langId, setLangId] = useState("");

  const [shippingPolicy, setShippingPolicy] = useState([
    {
      tabTitle: "",
      tabDetails: "",
    },
  ]);

  // LocalData
  const localData = JSON.parse(localStorage.getItem("adminLoginInfo"));

  //   Store
  const { managedContentSettings } = useSelector(
    (state) => state.managedContentSettings
  );

  // ! CK Editor Image Upload
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          let tempName = Math.floor(Math.random() * (10000 - 0) + 0).toString();
          loader.file
            .then((file) => {
              const data = new FormData();
              if (file) {
                tempName = tempName + "." + file.name.split(".").pop();
                data.append("name", tempName);
                data.append("tempImg", file);
                dispatch(uploadTempPic(data));
                dispatch(
                  createNoti({
                    message: "Pls click on the image Confirm Image Upload",
                    severity: "warning",
                  })
                );
              }
            })
            .then(() => {
              resolve({
                default: `${PF}temp/${tempName}`,
              });
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }
  // ! CK Editor Image Upload

  const handleContent = (e, editor, i) => {
    let arr = [...shippingPolicy];
    let obj = { ...arr[i] };
    const data = editor.getData();
    obj.tabDetails = data;
    arr[i] = obj;
    setShippingPolicy(arr);

    // let obj = { ...blog };
    // const data = editor.getData();
    // obj.content = data;
    // setBlog(obj);
  };

  const handleSaveSiteSEO = () => {
    setLoading(true);
    dispatch(
      saveManagedContentSettings({
        langId,
        shippingPolicy,
        changedSection: "Shipping Policy",
        creatorId: localData._id,
      })
    );
    setTimeout(() => {
      setLoading(false);
      dispatch(getNotification());
      dispatch(getManagedContentSettings());
    }, 1000);
  };

  const handleAddTab = () => {
    let arr = [...shippingPolicy];
    arr.push({
      tabTitle: "",
      tabDetails: "",
    });
    setShippingPolicy([...arr]);
  };

  const handleRemoveTab = (i) => {
    let arr = [...shippingPolicy];
    arr = arr.filter((a, index) => i !== index);

    setShippingPolicy([...arr]);
  };

  useEffect(() => {
    if (!(managedContentSettings && managedContentSettings.length > 0))
      dispatch(getManagedContentSettings());
  }, []);

  // Set Settings
  useEffect(() => {
    if (managedContentSettings && managedContentSettings.length > 0) {
      const M = managedContentSettings.find((m) => m.langId === langId);
      if (M && M.shippingPolicy && M.shippingPolicy.length > 0) {
        setShippingPolicy(M.shippingPolicy);
      } else {
        setShippingPolicy([
          {
            tabTitle: "",
            tabDetails: "",
          },
        ]);
      }
    }
  }, [langId, managedContentSettings]);

  return (
    <>
      <Accordion
        expanded={expanded === "shipping-policy"}
        onChange={handleChange("shipping-policy")}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-label="Expand"
          aria-controls="Head-Title-content"
          id="shipping-policy"
        >
          <Typography>{translate0("Shipping Policy")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <LangNTitleBar
            lang={lang}
            setLang={setLang}
            langId={langId}
            setLangId={setLangId}
            title={translate0("Shipping Policy")}
            // infoImg="tut-about-us.png"
          />

          {shippingPolicy.length > 0 &&
            shippingPolicy.map((x, i) => (
              <Box
                key={i}
                sx={{
                  border: "1px solid lightblue",
                  p: 1,
                  m: 1,
                  borderRadius: ".25rem",
                  width: !smUp ? "50vw" : mdUp ? "100%" : "80vw",
                }}
              >
                {shippingPolicy.length > 1 && (
                  <Button
                    fullWidth
                    variant="contained"
                    size="small"
                    color="error"
                    sx={{ my: 1 }}
                    onClick={() => handleRemoveTab(i)}
                  >
                    {translate0("Remove Tab")}
                  </Button>
                )}
                <TextField
                  label={translate0("Tab Title")}
                  value={x.tabTitle}
                  onChange={(e) => {
                    let arr = [...shippingPolicy];
                    let obj = { ...arr[i] };
                    obj.tabTitle = e.target.value;
                    arr[i] = obj;
                    setShippingPolicy(arr);
                  }}
                  size="small"
                  fullWidth
                  variant="standard"
                />
                <Typography variant="h6">
                  {translate0("Tab Details")}
                </Typography>
                <CKEditor
                  editor={Editor}
                  data={x.tabDetails}
                  config={{
                    extraPlugins: [uploadPlugin],
                  }}
                  onBlur={(e, editor) => handleContent(e, editor, i)}
                />
              </Box>
            ))}
          <Button
            fullWidth
            variant="contained"
            size="small"
            color="primary"
            sx={{ my: 1 }}
            onClick={handleAddTab}
          >
            {translate0("Add Tab")}
          </Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            fullWidth
            size="small"
            color="success"
            onClick={handleSaveSiteSEO}
          >
            {translate0("Save")}
          </LoadingButton>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
