import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSingleRole } from "../../../../reduxToolkit/features/adminPanel/role";
import { adminMenuList } from "../../data/adminMenuList";

export default function EditScope({ scopeBuffer, roleScope, setRoleScope }) {
  //   store
  const { singleRoleResponse } = useSelector((state) => state.role);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSingleRole(scopeBuffer));
  }, []);

  useEffect(() => {
    singleRoleResponse._id && setRoleScope(singleRoleResponse.roleScope);
  }, [singleRoleResponse]);

  return (
    <FormControl fullWidth sx={{ my: 1 }}>
      <InputLabel id="demo-simple-select-label">Role Scope</InputLabel>
      <Select
        multiple
        value={roleScope ? roleScope : []}
        label="Age"
        onChange={(e) => setRoleScope(e.target.value)}
        variant="standard"
        size="small"
      >
        {adminMenuList.map((x, i) => (
          <MenuItem key={i} value={x.name}>
            {x.name}
          </MenuItem>
        ))}
      </Select>
      <Typography variant="body2" color="secondary" align="center">
        Keep empty to give all access
      </Typography>
    </FormControl>
  );
}
