import { Dialog, DialogContent, IconButton } from "@mui/material";
import React, { useState } from "react";

// Icons
import InfoIcon from "@mui/icons-material/Info";

import { LazyLoadImage } from "react-lazy-load-image-component";
import { PF } from "../config";

export default function InfoTut({ img }) {
  const [infoDlg, setInfoDlg] = useState(false);
  //   const [img, setImg] = useState("tut-theme-color.png");
  return (
    <>
      <IconButton size="small" onClick={() => setInfoDlg(true)}>
        <InfoIcon color="info" />
      </IconButton>
      <Dialog open={infoDlg} onClose={() => setInfoDlg(false)}>
        <DialogContent>
          <LazyLoadImage
            effect="blur"
            src={
              img
                ? PF + "/tut/" + img
                : PF + "/adminPanel/medicines/no-medicine-image.jpg"
            }
            alt={""}
            style={{
              objectFit: "cover",
              width: "100%",
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
