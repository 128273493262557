import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  Grid,
  Divider,
  Avatar,
  ListItemAvatar,
  Alert,
  Box,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotification,
  readSingleNotification,
  setPanel,
} from "../../../../reduxToolkit/features/adminPanel/notification";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { getCreated, getExactTime } from "../../../../hooks/getCreatedData";
import { useNavigate } from "react-router-dom";
import LoadingBox from "../../../../components/misc/Loading";
import {
  getDosages,
  getMedicines,
} from "../../../../reduxToolkit/features/adminPanel/medicine";
import { getAdmins } from "../../../../reduxToolkit/features/adminPanel/admin";
import { useRef } from "react";
import useLazyLoad from "../../../../hooks/useLazyLoad";
import { getAllCustomers } from "../../../../reduxToolkit/features/auth";

// Icons

export default function NotificationDlg({
  unreadNotifications,
  notifications,
  error,
  setNotificationDlg,
  adminUrl,
}) {
  // Ref
  const triggerRef = useRef();

  const [activeTab, setActiveTab] = useState("all");
  const [loading, setLoading] = useState(false);
  const [notiList, setNotiList] = useState([]);

  const [lang, setLang] = useState("");
  const [langId, setLangId] = useState("");

  // local Store
  const localData = JSON.parse(localStorage.getItem("adminLoginInfo"));
  const localLangData = JSON.parse(localStorage.getItem("lang"));

  // Store
  const { medicines, medVariants, dosages } = useSelector(
    (state) => state.medicine
  );
  const { langs, activeLang } = useSelector((state) => state.misc);

  const { admins } = useSelector((state) => state.admin);
  const { customers } = useSelector((state) => state.auth);

  // Functions
  const dispatch = useDispatch();

  const getDosageInfo = (dosageId, langCode) => {
    let dosageName = "";
    if (dosages && dosages.length > 0) {
      const dosageList = dosages.find((d) => d.lang === langCode);
      if (dosageList && dosageList.data) {
        const D = dosageList.data.find((d) =>
          langCode !== "en" ? d.dosageDetectorId : d.fixedDosageId === dosageId
        );
        if (D && D.dosageName) dosageName = D.dosageName;
      }
    }
    return dosageName;
  };

  const handleNotiTitle = (x) => {
    let str = "";
    if (x.change) {
      // Assigned Price
      if (
        x.change.title === "assigned-price" ||
        x.change.title === "unassigned-price" ||
        x.change.title === "cancel-price" ||
        x.change.title === "update-assigned-price" ||
        x.change.title === "prescription-upload" ||
        x.change.title === "prescription-change"
      ) {
        if (
          localLangData &&
          localLangData.langCode &&
          medicines &&
          medicines.length > 0
        ) {
          if (x.change.title === "assigned-price")
            str += "Assigned price successfully for  ";
          else if (x.change.title === "cancel-price")
            str += "Cancelled price request successfully for  ";
          else if (x.change.title === "update-assigned-price")
            str += "Updated price request successfully for  ";
          else if (x.change.title === "prescription-upload")
            str += "Prescription added for ";
          else if (x.change.title === "prescription-change")
            str += "Prescription updated for ";
          else str += "Unassigned price successfully for  ";

          const M = medicines.find((m) => m.lang === localLangData.langCode);
          if (M && M.data) {
            const Med = M.data.find(
              (med) =>
                (localLangData.langCode !== "en"
                  ? med.medicineDetectorId
                  : med.fixedMedicineId) === x.change.medId
            );
            if (Med) {
              str += Med.genericBrand || "";
              if (medVariants && medVariants.length > 0) {
                const V = medVariants.find(
                  (v) =>
                    (localLangData.langCode !== "en"
                      ? v.variantDetectorId
                      : v.fixedVariantId) === x.change.variantId
                );
                if (V) {
                  const dosageInfo = `(${getDosageInfo(
                    V.dosageForm,
                    localLangData.langCode
                  )}-${V.strength}-${V.packTypes})`;

                  str += dosageInfo || "";

                  if (admins && admins.length > 0 && x.change.creatorId) {
                    const A = admins.find((a) => a._id === x.change.creatorId);
                    if (A) str += " by " + (A.adminName || "");
                  }
                  if (
                    x.change.customerId &&
                    customers &&
                    customers.length > 0
                  ) {
                    const C = customers.find(
                      (c) => c._id === x.change.customerId
                    );
                    if (C)
                      str +=
                        " by " + (C.name || "") + " (" + (C.email || "") + ")";
                  }
                }
              }
            }
          }
        }
      }
    } else {
      str = x.title;
    }
    return str;
  };

  const handleReadAllNotification = () => {
    setLoading(true);
    const admin = { adminId: localData._id };
    notifications.forEach((x) => {
      if (!x.adminsWhoRead.includes(admin.adminId))
        dispatch(readSingleNotification({ id: x._id, adminId: admin.adminId }));
    });
    setTimeout(() => {
      setLoading(false);
      dispatch(getNotification());
    }, 1000);
  };

  const navigate = useNavigate();
  const handleNotiClick = (link, panel, searchValue, id) => {
    setLoading(true);
    dispatch(setPanel(""));
    // Read Notification
    const isNotificationRead = () => {
      return unreadNotifications.some((el) => {
        return el._id === id;
      });
    };
    if (isNotificationRead()) {
      dispatch(readSingleNotification({ id, adminId: localData._id }));
      setTimeout(() => {
        dispatch(getNotification());
      }, 100);
    }
    // Navigate
    setTimeout(() => {
      navigate(`/${adminUrl}/`);
    }, 500);
    setTimeout(() => {
      navigate(
        `/${adminUrl}/${link}${
          searchValue !== "" ? "?search=" + searchValue : ""
        }`
      );
      dispatch(setPanel(panel));
    }, 1000);
    setTimeout(() => {
      setNotificationDlg(false);
      setLoading(false);
    }, 2000);
  };

  // Handle lazy load
  const NUM_PER_PAGE = 13;
  const noti = (
    activeTab === "all" ? notifications : unreadNotifications
  ).slice(0, NUM_PER_PAGE);
  const TOTAL_PAGES =
    (activeTab === "all" ? notifications : unreadNotifications).length /
    NUM_PER_PAGE;

  const onGrabData = (currentPage) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const noti = activeTab === "all" ? notifications : unreadNotifications;
        const notiData = noti.slice(
          ((currentPage - 1) % TOTAL_PAGES) * NUM_PER_PAGE,
          NUM_PER_PAGE * (currentPage % TOTAL_PAGES)
        );
        resolve(notiData);
      }, 2000);
    });
  };

  const { data, loading: loadingNoti } = useLazyLoad({
    triggerRef,
    onGrabData,
  });

  useEffect(() => {
    if (
      data.length <=
      (activeTab === "all" ? notifications : unreadNotifications).length
    ) {
      if (data && data.length > 0) {
        setNotiList(data);
      } else setNotiList(noti);
    }
  }, [data]);

  useEffect(() => {
    if (!(medicines && medicines.length > 0)) dispatch(getMedicines());
    dispatch(getDosages());
    dispatch(getAdmins());
    dispatch(getAllCustomers());
  }, []);

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setLang(activeLang.langCode);
      setLangId(activeLang._id);
    } else {
      if (localLangData) {
        setLang(localLangData.langCode);
        setLangId(localLangData._id);
      }
    }
  }, [activeLang]);

  // translate

  const [langSettings, setLangSettings] = useState([]);
  const titleDashboard = "Dashboard Top Bar";

  useEffect(() => {
    if (langs && langs.length > 0 && lang !== "") {
      const L = langs.find((l) => l.langCode === lang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      const dashboard = arr.find((l) => l._title === titleDashboard);
      if (dashboard) setLangSettings(dashboard.data);
    }
  }, [lang, langs]);

  const translate = (String) => {
    let translation = "";
    const T = langSettings.find((ls) => ls.title === String);
    if (T && T.value) translation = T.value;
    else {
      let arr = [];
      if (langs && langs.length > 0 && lang !== "") {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
        const dashboard = arr.find((l) => l._title === titleDashboard);
        if (dashboard) {
          const T = dashboard.data.find((ls) => ls.title === String);
          if (T && T.value) translation = T.value;
        }
      }
    }
    return translation;
  };

  return (
    <>
      {error ? (
        <Alert severity="error">Something Went Wrong</Alert>
      ) : (
        <>
          <Grid
            container
            spacing={0}
            justifyContent="space-around"
            sx={{ position: "sticky", top: 0, zIndex: 1 }}
          >
            <Grid
              item
              sx={{
                cursor: "pointer",
                background: activeTab === "all" ? "lightblue" : "white",
              }}
              xs={6}
              onClick={() => setActiveTab("all")}
            >
              <Typography variant="subtitle1" color="initial" align="center">
                {translate("All")} ( {notifications.length})
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                cursor: "pointer",
                background: activeTab === "unread" ? "lightblue" : "white",
              }}
              xs={6}
              onClick={() => setActiveTab("unread")}
            >
              <Typography variant="subtitle1" color="initial" align="center">
                {translate("Unread")} ({unreadNotifications.length})
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          {/* Notifications */}
          {activeTab === "all" ? (
            <>
              <List dense sx={{ width: "100%", bgcolor: "background.paper" }}>
                {loading ? (
                  <Box
                    sx={{
                      minWidth: { sm: "600px" },
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <LoadingBox w={"100%"} />
                  </Box>
                ) : notiList.length > 0 ? (
                  notiList.map((x, i) => (
                    <Fragment key={i}>
                      <ListItem
                        sx={{
                          background: x.adminsWhoRead.includes(localData._id)
                            ? "white"
                            : "lightgrey",
                          // opacity: loadingNoti ? 0.2 : 1,
                        }}
                        ref={triggerRef}
                      >
                        <ListItemButton
                          onClick={() =>
                            handleNotiClick(
                              x.link || "administration",
                              x.panel || "manage-admins",
                              x.searchValue || "",
                              x._id
                            )
                          }
                          // disabled={loadingNoti}
                        >
                          <ListItemAvatar>
                            <Avatar sx={{ background: "white" }}>
                              <LazyLoadImage
                                effect="blur"
                                src={x.notificationImage}
                                alt={x.title}
                                style={{
                                  width: 32,
                                  height: 32,
                                  objectFit: "fill",
                                }}
                              />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={` ${handleNotiTitle(x)} `}
                            secondary={
                              getCreated(x.createdAt) +
                              ` ` +
                              getExactTime(x.createdAt)
                            }
                          />
                        </ListItemButton>
                      </ListItem>

                      <Divider />
                    </Fragment>
                  ))
                ) : (
                  <Alert
                    severity="warning"
                    sx={{
                      minWidth: { sm: "600px" },
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    No notifications available
                  </Alert>
                )}
              </List>
              {loadingNoti && (
                <Box>
                  <LoadingBox w={"100%"} />
                </Box>
              )}
              {unreadNotifications.length > 0 && (
                <Typography
                  variant="body1"
                  align="center"
                  color="primary"
                  onClick={handleReadAllNotification}
                  sx={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    position: "sticky",
                    bottom: 0,
                    background: "white",
                    zIndex: 1,
                    pb: 1,
                  }}
                >
                  {translate("Mark all read")}
                </Typography>
              )}
            </>
          ) : (
            <>
              <List dense sx={{ width: "100%", bgcolor: "background.paper" }}>
                {unreadNotifications.length > 0 ? (
                  unreadNotifications.map((x, i) => (
                    <Fragment key={i}>
                      <ListItem>
                        <ListItemButton
                          onClick={() =>
                            handleNotiClick(
                              x.link || "administration",
                              x.panel || "manage-admins",
                              x.searchValue || "",
                              x._id
                            )
                          }
                          disabled={loadingNoti}
                        >
                          <ListItemAvatar>
                            <Avatar sx={{ background: "white" }}>
                              <LazyLoadImage
                                effect="blur"
                                src={x.notificationImage}
                                alt={x.title}
                                style={{
                                  width: 32,
                                  height: 32,
                                  objectFit: "fill",
                                }}
                              />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={` ${handleNotiTitle(x)} `}
                            secondary={
                              getCreated(x.createdAt) +
                              ` ` +
                              getExactTime(x.createdAt)
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                      <Divider />
                    </Fragment>
                  ))
                ) : (
                  <Alert
                    severity="warning"
                    sx={{
                      minWidth: { sm: "600px" },
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    No Unread Notification
                  </Alert>
                )}
              </List>
              {unreadNotifications.length > 0 && (
                <Typography
                  variant="body1"
                  align="center"
                  color="primary"
                  onClick={handleReadAllNotification}
                  sx={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    position: "sticky",
                    bottom: 0,
                    background: "white",
                    zIndex: 1,
                    pb: 1,
                  }}
                >
                  Mark All Read
                </Typography>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
