import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import React from "react";

export default function InqTestimonialForm() {
  return (
    <Box component="form">
      <Typography
        variant="h2"
        align="center"
        sx={{
          fontSize: { xs: "1.5rem", sm: "2.5rem" },
          textDecoration: "underline",
        }}
      >
        Contact With Buyer
      </Typography>
      <TextField
        id=""
        label="Name"
        fullWidth
        // value={}
        // onChange={}
        sx={{ mb: 1 }}
        variant="standard"
      />
      <FormControl fullWidth variant="standard">
        <InputLabel id="demo-simple-select-label">Select Buyer</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          //   value={age}
          label="Age"
          // defaultValue="Select Buyer"
          //   onChange={handleChange}
          variant="standard"
          sx={{ mb: 1 }}
          size="small"
        >
          {[...Array(12)].map((x, i) => (
            <MenuItem value={i + 1} key={i}>
              {i + 1}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button variant="contained" size="small" fullWidth type="submit">
        Request
      </Button>
    </Box>
  );
}
