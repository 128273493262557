import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";

import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Card,
  CardContent,
  Box,
  CardActions,
  DialogContentText,
} from "@mui/material";
import { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSubEmailTemplate,
  getSettings,
} from "../../../reduxToolkit/features/adminPanel/webSettings";
import SubscriptionEmailTemplate from "../dialogs/SubscriptionEmailTemplate";
import TemplateCard from "./TemplateCard";

export default function SubscriptionEmail({
  templates,
  setTemplates,
  translate1,
  translate2,
}) {
  const dispatch = useDispatch();

  // const [templates, setTemplates] = useState([]);

  //   Dialog
  const [addTemplateDlg, setAddTemplateDlg] = useState(false);

  const [editTemplateDlg, setEditTemplateDlg] = useState(false);
  const [editBuffer, setEditBuffer] = useState({});

  const [deleteTemplateDlg, setDeleteTemplateDlg] = useState(false);
  const [deleteBuffer, setDeleteBuffer] = useState({});
  const [loadingDlt, setLoadingDlt] = useState(false);

  // Local Data
  const localAdminInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));

  //   Store
  const { webSettings } = useSelector((state) => state.webSettings);

  const handleEditClick = (x) => {
    setEditTemplateDlg(true);
    setEditBuffer(x);
  };

  const handleDeleteClick = (x) => {
    setDeleteTemplateDlg(true);
    setDeleteBuffer(x);
  };

  const handleDltTemplate = () => {
    setLoadingDlt(true);
    let obj = {
      id: deleteBuffer._id,
    };
    if (localAdminInfo._id) obj.creatorId = localAdminInfo._id;
    dispatch(deleteSubEmailTemplate(obj));
    setTimeout(() => {
      setLoadingDlt(false);
      handleDeleteDlgClose();
      dispatch(getSettings());
    }, 1000);
  };

  const handleDeleteDlgClose = () => {
    setDeleteTemplateDlg(false);
    setDeleteBuffer({});
  };

  useEffect(() => {
    if (
      webSettings &&
      webSettings.subscriptionEmailTemplate &&
      webSettings.subscriptionEmailTemplate.length > 0
    )
      setTemplates(webSettings.subscriptionEmailTemplate);
    else setTemplates([]);
  }, [webSettings]);

  useEffect(() => {
    if (!(webSettings && webSettings.subscriptionEmailTemplate))
      dispatch(getSettings());
  }, []);

  return (
    <>
      <Button
        variant="contained"
        size="small"
        fullWidth
        color="success"
        onClick={() => setAddTemplateDlg(true)}
      >
        {translate1("Add template")}
      </Button>
      <Box
        sx={{
          p: 1,
          my: 1,
          border: ".1rem solid pink ",
          borderRadius: ".25rem",
        }}
      >
        <Button
          variant="outlined"
          size="small"
          fullWidth
          color="primary"
          sx={{ pointerEvents: "none", my: 1 }}
        >
          {translate1("Templates")} ({templates.length})
        </Button>
        <Grid container spacing={1}>
          {templates.map((x, i) => (
            <Grid item xs={12} sm={6} md={4} xl={3} key={i}>
              <TemplateCard
                x={x}
                handleEditClick={handleEditClick}
                handleDeleteClick={handleDeleteClick}
                editable={true}
                translate1={translate1}
                translate2={translate2}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      {addTemplateDlg && (
        <SubscriptionEmailTemplate
          dialogOpen={addTemplateDlg}
          setDialogOpen={setAddTemplateDlg}
          translate1={translate1}
          translate2={translate2}
        />
      )}
      {editTemplateDlg && (
        <SubscriptionEmailTemplate
          dialogOpen={editTemplateDlg}
          setDialogOpen={setEditTemplateDlg}
          buffer={editBuffer}
          setEditBuffer={setEditBuffer}
          translate1={translate1}
          translate2={translate2}
        />
      )}

      {deleteTemplateDlg && (
        <Dialog open={deleteTemplateDlg}>
          <DialogContent>
            <DialogContentText>
              {translate2("Do you really want to delete")}{" "}
              <span style={{ color: "red" }}> {deleteBuffer.title}</span>?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteDlgClose} color="primary">
              {translate1("Close")}
            </Button>
            <LoadingButton
              loading={loadingDlt}
              onClick={handleDltTemplate}
              color="error"
            >
              {translate1("Confirm")}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
