import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { axiosInstance } from "../../config";

//
export const loginUser = createAsyncThunk("auth/login", async (data) => {
  const { remember, expireInterval, ...others } = data;
  let D = { ...others };
  const browserInfo = navigator.userAgent;
  if (browserInfo) D.browserInfo = browserInfo;
  const res = await axiosInstance.post("/api/customerAuth/login", D);
  if (res.data && res.data.customerInfo) {
    if (!remember) {
      const expire = new Date().getTime() + expireInterval * 60000;
      res.data.customerInfo.expire = expire;
    }
    localStorage.setItem("loginInfo", JSON.stringify(res.data.customerInfo));
  }
  return res.data;
});

export const registerUser = createAsyncThunk("auth/register", async (data) => {
  // let geo;
  // try {
  //   geo = await axios.get(
  //     "https://geolocation-db.com/json/4bdf2390-d062-11ec-81c2-0baa51ec38e1",
  //     { timeout: 10000 }
  //   );
  // } catch (error) {
  // }
  // if (geo) data.geoInfo = geo.data;
  const browserInfo = navigator.userAgent;
  if (browserInfo) data.browserInfo = browserInfo;
  const res = await axiosInstance.post("/api/customerAuth/register", data);
  res.data &&
    res.data.customerInfo &&
    localStorage.setItem("loginInfo", JSON.stringify(res.data.customerInfo));
  return res.data;
});

export const emailVerify = createAsyncThunk(
  "auth/emailVerify",
  async (data) => {
    const res = await axiosInstance.post(
      "/api/customerAuth/email-verify",
      data
    );
    return res.data;
  }
);

//LogOut
export const logOutUser = createAsyncThunk("auth/logout", async (data) => {
  const res = await axiosInstance.put("/api/customerAuth/logout", data);
  return res.data;
});

// Forgot Password
export const forgotPass = createAsyncThunk("auth/forgotPass", async (data) => {
  const res = await axiosInstance.post("/api/customerAuth/forgot-pass", data);
  return res.data;
});

// Update Password
export const updateCustomerPass = createAsyncThunk(
  "auth/updateCustomerPass",
  async (data) => {
    const res = await axiosInstance.put("/api/customerAuth/update-pass", data);
    res.data &&
      res.data.customerInfo &&
      localStorage.setItem("loginInfo", JSON.stringify(res.data.customerInfo));
    return res.data;
  }
);

// Get All Customers
export const getAllCustomers = createAsyncThunk(
  "auth/getAllCustomers",
  async () => {
    const res = await axiosInstance.get("/api/customerAuth");
    return res.data;
  }
);

// Check if Blocked Customer
export const checkIfBlockCustomer = createAsyncThunk(
  "auth/checkBlock",
  async (id) => {
    const res = await axiosInstance.get(
      `/api/customerAuth/check-blocked/${id}`
    );
    return res.data;
  }
);

// unblock request
export const unblockRequest = createAsyncThunk(
  "auth/unblockReq",
  async (data) => {
    const { id, ...others } = data;
    const res = await axiosInstance.post(
      `/api/customerAuth/unblock-req/${id}`,
      others
    );
    return res.data;
  }
);

// get unblock req count
export const unblockReqCount = createAsyncThunk(
  "auth/unblockReqCount",
  async (id) => {
    const res = await axiosInstance.get(
      `/api/customerAuth/unblock-req-count/${id}`
    );
    return res.data;
  }
);

// save subs
export const saveSubscribeEmail = createAsyncThunk(
  "auth/subscribeEmail",
  async (data) => {
    const res = await axiosInstance.post(
      `/api/customerAuth/subscribe-email/`,
      data
    );
    return res.data;
  }
);

// edit sub
export const editSubscriber = createAsyncThunk(
  "auth/editSubscriber",
  async (data) => {
    const res = await axiosInstance.post(
      `/api/customerAuth/edit-subscriber/`,
      data
    );
    return res.data;
  }
);

// add sub
export const addSubscriber = createAsyncThunk(
  "auth/addSubscriber",
  async (data) => {
    const res = await axiosInstance.post(
      `/api/customerAuth/add-subscriber/`,
      data
    );
    return res.data;
  }
);

// delete sub
export const deleteSubscriber = createAsyncThunk(
  "auth/deleteSubscriber",
  async (data) => {
    const res = await axiosInstance.post(
      `/api/customerAuth/delete-subscriber/`,
      data
    );
    return res.data;
  }
);

// bulk Email Send
export const subscriberBulkEmail = createAsyncThunk(
  "auth/subscriberBulkEmail",
  async (data) => {
    const res = await axiosInstance.post(
      `/api/customerAuth/subscriber-bulk-email/`,
      data
    );
    return res.data;
  }
);

// get subs
export const getSubscriber = createAsyncThunk(
  "auth/getSubscriber",
  async () => {
    const res = await axiosInstance.get(`/api/customerAuth/get-subscribers`);
    return res.data;
  }
);

// get Campaign
export const getCampaigns = createAsyncThunk("auth/getCampaigns", async () => {
  const res = await axiosInstance.get(`/api/customerAuth/get-campaigns`);
  return res.data;
});

// Delete Campaign
export const removeCampaign = createAsyncThunk(
  "auth/removeCampaign",
  async (data) => {
    const res = await axiosInstance.post(
      `/api/customerAuth/remove-campaign`,
      data
    );
    return res.data;
  }
);

// Initial State
const initialState = {
  pending: false,
  authData: {},
  error: false,
  forgotPassResponse: {},
  customers: {},
  subscribers: [],
  campaigns: [],
  checkBlockResponse: false,
  unblockReqCountResponse: 0,
  emailResponse: {},
  loginPrompt: false,
  snackNotiCustomer: {},
  snackNotiCustomerBackend: {},
};
// Slice
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.authData = initialState.authData;
      state.checkBlockResponse = initialState.checkBlockResponse;
      state.snackNotiCustomer = {
        message: "Logged Out!",
        severity: "error",
      };
    },
    promptLogin: (state) => {
      state.loginPrompt = true;
    },
    closePromptLogin: (state) => {
      state.loginPrompt = false;
    },
    resetSnkCustomer(state) {
      state.snackNotiCustomer = {};
      state.emailResponse = {};
      state.snackNotiCustomerBackend = {};
    },
    resetForgotPassResponse(state) {
      state.forgotPassResponse = {};
    },
    resetUnblockReqCount(state) {
      state.unblockReqCountResponse = 0;
    },
    createNotiFront(state, action) {
      state.snackNotiCustomer = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
  },
  extraReducers: {
    [logOutUser.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [logOutUser.fulfilled]: (state) => {
      state.pending = false;
    },
    [logOutUser.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [loginUser.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [loginUser.fulfilled]: (state, action) => {
      state.pending = false;
      state.authData = action.payload;
      if (state.authData.customerInfo) {
        state.snackNotiCustomer = {
          message: "Login Successful!",
          severity: "success",
        };
      }
    },
    [loginUser.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [registerUser.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [registerUser.fulfilled]: (state, action) => {
      state.pending = false;
      state.authData = action.payload;
      if (state.authData.customerInfo) {
        state.snackNotiCustomer = {
          message: "Registration Successful!",
          severity: "success",
        };
      }
    },
    [registerUser.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [emailVerify.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [emailVerify.fulfilled]: (state, action) => {
      state.pending = false;
      state.emailResponse = action.payload;
      state.snackNotiCustomer = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [emailVerify.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [forgotPass.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [forgotPass.fulfilled]: (state, action) => {
      state.pending = false;
      state.forgotPassResponse = action.payload;
      if (state.forgotPassResponse.failed) {
        state.snackNotiCustomer = {
          message: action.payload.message,
          severity: action.payload.severity,
        };
      }
    },
    [forgotPass.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [updateCustomerPass.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [updateCustomerPass.fulfilled]: (state, action) => {
      state.pending = false;
      if (action.payload.customerInfo) {
        state.authData = action.payload;
        state.snackNotiCustomer = {
          message: action.payload.message,
          severity: action.payload.severity,
        };
      }
      if (action.payload.failed) {
        state.snackNotiCustomer = {
          message: action.payload.message,
          severity: action.payload.severity,
        };
      }
    },
    [updateCustomerPass.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [getAllCustomers.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getAllCustomers.fulfilled]: (state, action) => {
      state.pending = false;
      state.customers = action.payload;
    },
    [getAllCustomers.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [checkIfBlockCustomer.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [checkIfBlockCustomer.fulfilled]: (state, action) => {
      state.pending = false;
      if (action.payload.failed) {
        state.checkBlockResponse = true;
        state.snackNotiCustomer = {
          message: action.payload.message,
          severity: action.payload.severity,
        };
      }
    },
    [checkIfBlockCustomer.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [unblockRequest.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [unblockRequest.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiCustomer = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [unblockRequest.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [unblockReqCount.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [unblockReqCount.fulfilled]: (state, action) => {
      state.pending = false;
      if (!action.payload.failed) {
        state.unblockReqCountResponse = action.payload.count;
      }
    },
    [unblockReqCount.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [saveSubscribeEmail.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [saveSubscribeEmail.fulfilled]: (state, action) => {
      state.pending = false;
      if (action.payload.authData) {
        state.authData = action.payload.authData;
      }
      state.snackNotiCustomer = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [saveSubscribeEmail.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [getSubscriber.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getSubscriber.fulfilled]: (state, action) => {
      state.pending = false;
      state.subscribers = action.payload;
    },
    [getSubscriber.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [editSubscriber.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [editSubscriber.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiCustomerBackend = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [editSubscriber.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [addSubscriber.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [addSubscriber.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiCustomerBackend = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [addSubscriber.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [deleteSubscriber.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [deleteSubscriber.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiCustomerBackend = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [deleteSubscriber.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [subscriberBulkEmail.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [subscriberBulkEmail.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiCustomerBackend = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [subscriberBulkEmail.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [getCampaigns.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getCampaigns.fulfilled]: (state, action) => {
      state.pending = false;
      state.campaigns = action.payload;
    },
    [getCampaigns.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [removeCampaign.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [removeCampaign.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiCustomerBackend = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [removeCampaign.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
  },
});
//
export const {
  logout,
  promptLogin,
  closePromptLogin,
  resetSnkCustomer,
  resetForgotPassResponse,
  createNotiFront,
  resetUnblockReqCount,
} = authSlice.actions;
export default authSlice.reducer;
