import { Button, Grid } from "@mui/material";
import React from "react";
import CallIcon from "@mui/icons-material/Call";
import MessageIcon from "@mui/icons-material/Message";

export default function MoreCallOptions({ contactMedium }) {
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          startIcon={contactMedium === "Call" ? <CallIcon /> : <MessageIcon />}
          fullWidth
          sx={{ mb: 1 }}
        >
          {contactMedium} Us On Phone
        </Button>
        <Button
          variant="contained"
          color="error"
          startIcon={
            <img
              src="https://img.icons8.com/color/48/000000/facebook-messenger--v1.png"
              alt="messenger"
              height="20px"
            />
          }
          fullWidth
          sx={{ mb: 1 }}
        >
          {contactMedium} On Messenger
        </Button>
        <Button
          variant="contained"
          color="secondary"
          startIcon={
            <img
              src="https://img.icons8.com/color/48/000000/skype--v1.png"
              alt="skype"
              height="20px"
            />
          }
          fullWidth
          sx={{ mb: 1 }}
        >
          {contactMedium} Us on Skype
        </Button>
        <Button
          variant="contained"
          color="inherit"
          startIcon={
            <img
              src="https://img.icons8.com/color-glass/48/000000/whatsapp.png"
              alt="whatsapp"
              height="20px"
            />
          }
          sx={{ mb: 1 }}
          fullWidth
        >
          {contactMedium} Us On WhatsApp
        </Button>
      </Grid>
    </Grid>
  );
}
