import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {
  Alert,
  Autocomplete,
  Box,
  createFilterOptions,
  Grid,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import LoadingBox from "../../../../components/misc/Loading";
import {
  editDosage,
  getDosages,
} from "../../../../reduxToolkit/features/adminPanel/medicine";
import { getNotification } from "../../../../reduxToolkit/features/adminPanel/notification";
import LoadingButton from "@mui/lab/LoadingButton";

// Limit options to show in autocomplete
const OPTIONS_LIMIT = 3;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) => {
  return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
};

export default function EditDosageDialog({
  editDosageDlg,
  setEditDosageDlg,
  editBuffer,
  setEditBuffer,
  lang,
}) {
  const dispatch = useDispatch();

  // useState
  const [editDosageError, setEditDosageError] = useState(false);
  const [editDosageLoading, setEditDosageLoading] = useState(false);
  const [dosageName, setDosageName] = useState("");
  const [dosageDetector, setDosageDetector] = useState("");
  const [dosageListEn, setDosageListEn] = useState([]);
  const [prevDosageEn, setPrevDosageEn] = useState({});
  const [prevDosageEnLoading, setPrevDosageEnLoading] = useState(false);

  const [editButtonEnable, setEditButtonEnable] = useState(false);

  //   Local Data
  const localAdminData = JSON.parse(localStorage.getItem("adminLoginInfo"));

  //   Store
  const { dosages, editDosagesResponse } = useSelector(
    (state) => state.medicine
  );

  // function
  const handleDosageEnglishVariant = (e, newValue) => {
    setDosageDetector(newValue.fixedDosageId);
  };

  const handleEditConfirm = () => {
    setEditDosageLoading(true);
    const data = {
      fixedDosageId: editBuffer.fixedDosageId,
      dosageName,
      creatorId: localAdminData._id,
    };
    if (dosageDetector.length > 0) data.dosageDetectorId = dosageDetector;
    dispatch(editDosage({ lang, data }));
    setTimeout(() => {
      dispatch(getDosages());
      dispatch(getNotification());
    }, 1000);
  };

  //   useEffect
  //   Set English Dosage List
  useEffect(() => {
    if (lang !== "" && dosages && dosages.length > 0) {
      const D = dosages.find((d) => d.lang === "en");
      if (D) setDosageListEn(D.data);
      else setDosageListEn([]);
    }
  }, [dosages, lang]);

  //   get data from buffer
  useEffect(() => {
    if (editBuffer && editBuffer.dosageName) {
      setPrevDosageEnLoading(true);
      setDosageName(editBuffer.dosageName);
      if (dosageListEn && dosageListEn.length > 0) {
        const obj = dosageListEn.find(
          (d) => d.fixedDosageId === editBuffer.dosageDetectorId
        );
        if (obj) {
          setDosageDetector(obj.fixedDosageId);
          setPrevDosageEn(obj);
        }
        setTimeout(() => {
          setPrevDosageEnLoading(false);
        }, 500);
      }
    }
  }, [dosageListEn, editBuffer]);

  //   Submit enable
  useEffect(() => {
    if (
      lang !== "en"
        ? editBuffer.dosageName === dosageName &&
          editBuffer.dosageDetectorId === dosageDetector
        : editBuffer.dosageName === dosageName
    ) {
      setEditButtonEnable(false);
    } else {
      if (dosageName.length > 0) {
        if (lang !== "en" && dosageDetector.length < 1)
          setEditButtonEnable(false);
        else setEditButtonEnable(true);
      } else setEditButtonEnable(false);
    }
  }, [dosageDetector.length, dosageName.length, lang]);

  //   Check Response
  useEffect(() => {
    if (editDosagesResponse.message) {
      if (editDosagesResponse.failed) {
        setEditDosageLoading(false);
        setEditDosageError(true);
      } else {
        setTimeout(() => {
          setEditDosageLoading(false);
          setEditDosageDlg(false);
        }, 1000);
      }
    }
  }, [editDosagesResponse, editDosagesResponse]);

  // ! translate
  const [siteLang, setSiteLang] = useState("");
  // Local Store
  const localLangData = JSON.parse(localStorage.getItem("lang"));
  // Store
  const { langs, activeLang } = useSelector((state) => state.misc);
  const [langSettings1, setLangSettings1] = useState([]);
  const [langSettings2, setLangSettings2] = useState([]);
  const [langSettings3, setLangSettings3] = useState([]);
  const title1 = "Add Dosage Form Dialog";
  const title2 = "Misc Words";
  const title3 = "Manage Dosage Form";

  useEffect(() => {
    if (langs && langs.length > 0 && siteLang !== "") {
      const L = langs.find((l) => l.langCode === siteLang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      const A = arr.find((l) => l._title === title1);
      if (A) setLangSettings1(A.data);
      const B = arr.find((l) => l._title === title2);
      if (B) setLangSettings2(B.data);
      const C = arr.find((l) => l._title === title3);
      if (C) setLangSettings3(C.data);
    }
  }, [siteLang, langs]);

  const translate1 = (String) => translation(String, title1, 1);
  const translate2 = (String) => translation(String, title2, 2);
  const translate3 = (String) => translation(String, title3, 3);

  const translation = (String, title, index) => {
    let translation = "";
    let settings = [];
    if (index === 1) {
      settings = langSettings1;
    } else if (index === 2) {
      settings = langSettings2;
    } else if (index === 3) {
      settings = langSettings3;
    }
    const T = settings.find((ls) => ls.title === String);
    if (T && T.value) translation = T.value;
    else {
      let arr = [];
      if (langs && langs.length > 0 && siteLang !== "") {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.settings && LEn.settings.length > 0) arr = LEn.settings;
        const S = arr.find((l) => l._title === title);
        if (S) {
          const T = S.data.find((ls) => ls.title === String);
          if (T && T.value) translation = T.value;
        }
      }
    }
    return translation;
  };

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setSiteLang(activeLang.langCode);
    } else {
      if (localLangData) {
        setSiteLang(localLangData.langCode);
      }
    }
  }, [activeLang]);
  // ! translate

  return (
    <>
      <Dialog
        open={editDosageDlg}
        onClose={() => {
          setEditDosageDlg(false);
          setEditBuffer({});
        }}
      >
        <DialogTitle>{translate3("Edit Dosage")}</DialogTitle>
        <DialogContent>
          {editDosageError ? (
            <Alert severity="error">Something Went Wrong...</Alert>
          ) : (
            <Grid container spacing={3} flexDirection="column" sx={{ pt: 2 }}>
              {prevDosageEnLoading ? (
                <Grid item>
                  <LoadingBox />
                </Grid>
              ) : (
                lang !== "en" && (
                  <Grid item>
                    <Autocomplete
                      size="small"
                      onChange={(e, newValue) =>
                        handleDosageEnglishVariant(e, newValue)
                      }
                      disablePortal
                      defaultValue={prevDosageEn}
                      options={dosageListEn}
                      filterOptions={filterOptions}
                      filterSelectedOptions
                      getOptionLabel={(option) => option.dosageName}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{
                            "& > img": { mr: 2, flexShrink: 0 },
                          }}
                          {...props}
                        >
                          {option.dosageName}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={translate1("English Variant")}
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                )
              )}
              <Grid item>
                <TextField
                  label={translate3("Dosage")}
                  value={dosageName}
                  onChange={(e) => setDosageName(e.target.value)}
                  fullWidth
                  variant="standard"
                  size="small"
                />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setEditDosageDlg(false);
              setEditBuffer({});
            }}
            color="primary"
          >
            {translate1("Cancel")}
          </Button>
          <LoadingButton
            onClick={handleEditConfirm}
            color="error"
            disabled={!editButtonEnable}
            loading={editDosageLoading}
          >
            {translate2("Confirm")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
