import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {
  AppBar,
  IconButton,
  Slide,
  Toolbar,
  Typography,
  Grid,
  Alert,
} from "@mui/material";

// Icons
import CloseIcon from "@mui/icons-material/Close";

import { useDispatch, useSelector } from "react-redux";
import medicine, {
  getMedCategories,
  getMedicines,
} from "../../reduxToolkit/features/adminPanel/medicine";
import { getCompareList } from "../../reduxToolkit/features/frontend/compare";
import LoadingBox from "../misc/Loading";
import ProductLarge from "../product/ProductLarge";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

export default function CompareDlg({ compareOpen, setCompareOpen, lang }) {
  // use State
  //   const [catListLoading, setCatListLoading] = useState(false);
  const [catList, setCatList] = useState([]);
  const [medLoading, setMedLoading] = useState(false);
  const [medList, setMedList] = useState([]);

  // store
  const { medicines, categories } = useSelector((state) => state.medicine);
  const { compareList } = useSelector((state) => state.compare);

  const handleCompareDlgClose = () => {
    setCompareOpen(false);
  };

  // Use Effect
  const dispatch = useDispatch();
  useEffect(() => {
    // if (!(medicines && medicines.length > 0)) dispatch(getMedicines());
    if (!(categories && categories.length > 0)) dispatch(getMedCategories());
    if (!(compareList && compareList.length > 0)) dispatch(getCompareList());
  }, []);

  //   set medicines
  useEffect(() => {
    setMedLoading(true);
    if (
      lang !== "" &&
      medicines &&
      medicines.length > 0 &&
      compareList &&
      compareList.length > 0
    ) {
      let M = medicines.find((m) => m.lang === lang);
      if (M && M.data) {
        let arr = [...M.data];
        let arr2 = [];
        compareList.forEach((x) => {
          const A = arr.find(
            (a) =>
              (lang !== "en" ? a.medicineDetectorId : a.fixedMedicineId) === x
          );
          if (A) arr2.push(A);
        });

        setMedList(arr2);
      } else {
        setMedList([]);
      }
    }
    setTimeout(() => {
      setMedLoading(false);
    }, 1000);
  }, [compareList, medicines]);

  // set Category
  useEffect(() => {
    if (lang !== "" && categories && categories.length > 0) {
      let C = categories.find((c) => c.lang === lang);
      if (C) setCatList(C.data);
      else setCatList([]);
    }
  }, [categories, lang]);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate

  return (
    <Dialog
      open={compareOpen}
      onClose={handleCompareDlgClose}
      aria-labelledby={"compare"}
      TransitionComponent={Transition}
      fullScreen
    >
      <AppBar sx={{ position: "sticky" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCompareDlgClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6">
            {translateFront("Comparison")}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Grid container spacing={1}>
          {medLoading ? (
            <LoadingBox w={"100%"} />
          ) : medList.length < 1 ? (
            <Grid item xs={12}>
              <Alert severity="warning" fullWidth>
                {translateFront("Nothing To Compare...")}
              </Alert>
            </Grid>
          ) : (
            medList.map((x, i) => (
              <Grid item xs={12} sm={6} md={4} xl={3}>
                <ProductLarge
                  x={x}
                  i={i}
                  view={0}
                  catList={catList}
                  lang={lang}
                  setCompareOpen={setCompareOpen}
                />
              </Grid>
            ))
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
