import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { getSingleRole } from "../../../../reduxToolkit/features/adminPanel/role";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

export default function ViewRole({
  viewRoleDlg,
  setViewRoleDlg,
  viewRoleBuffer,
  translate6,
}) {
  // store
  const { pending, singleRoleResponse } = useSelector((state) => state.role);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSingleRole(viewRoleBuffer));
  }, [viewRoleDlg]);
  return (
    <Dialog
      open={viewRoleDlg}
      onClose={() => setViewRoleDlg(false)}
      aria-labelledby={"view-role"}
    >
      <DialogTitle></DialogTitle>
      {!pending && singleRoleResponse._id && (
        <DialogContent>
          <DialogContentText>
            <Typography variant="h6" color="primary" align="center">
              {singleRoleResponse._id && singleRoleResponse.roleName}
            </Typography>
            <Button
              variant="outlined"
              color="secondary"
              sx={{ display: "flex", justifyContent: "center" }}
              fullWidth
            >
              {translate6("Priority")}:{" "}
              {singleRoleResponse._id && singleRoleResponse.priority}
            </Button>
            {singleRoleResponse.roleScope &&
            singleRoleResponse.roleScope.length > 0 ? (
              <>
                <Typography
                  variant="body2"
                  color="initial"
                  align="center"
                  sx={{ borderBottom: "1px solid black" }}
                >
                  {translate6("Role Scope")}
                </Typography>
                <Grid container spacing={1} alignItems="center">
                  {singleRoleResponse.roleScope &&
                    singleRoleResponse.roleScope.map((x, i) => (
                      <Grid item>
                        <Typography
                          variant="body2"
                          color="primary"
                          align="center"
                        >
                          {" "}
                          {x +
                            (i !== singleRoleResponse.roleScope.length - 1
                              ? " ,"
                              : "")}
                        </Typography>
                      </Grid>
                    ))}
                </Grid>
              </>
            ) : (
              <>
                <Typography
                  variant="body2"
                  color="initial"
                  align="center"
                  sx={{ borderBottom: "1px solid black" }}
                >
                  {translate6("Role Scope")}
                </Typography>
                <Typography variant="body2" color="primary" align="center">
                  {translate6("All")}
                </Typography>
              </>
            )}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={() => setViewRoleDlg(false)} color="primary">
          {translate6("Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
