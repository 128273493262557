import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Box, Divider, Grid } from "@mui/material";
import SocialMediaPages from "./SocialMediaPages";
import Information from "./Information";
import ContactUs from "./ContactUs";
import ShortVideo from "./ShortVideos";
import Policy from "../Policy/Policy";
import { useSelector } from "react-redux";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function Footer({
  policyOpen,
  setPolicyOpen,
  policyName,
  setPolicyName,
}) {
  // Responsive
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  // Copyright
  const d = new Date();
  let year = d.getFullYear();
  const [innerPolicyDlg, setInnerPolicyDlg] = useState(false);
  const [innerPolicyName, setInnerPolicyName] = useState(false);
  const [contentSettings, setContentSettings] = useState([]);
  const [footerText, setFooterText] = useState("");
  const [langId, setLangId] = useState("");

  // Store
  const { managedContentSettings } = useSelector(
    (state) => state.managedContentSettings
  );
  const { activeLangFront } = useSelector((state) => state.miscFront);
  const { webSettings } = useSelector((state) => state.webSettings);

  // Set Language
  useEffect(() => {
    if (activeLangFront) setLangId(activeLangFront._id);
  }, [activeLangFront]);

  // Set Settings
  useEffect(() => {
    if (
      managedContentSettings &&
      managedContentSettings.length > 0 &&
      langId !== ""
    ) {
      const M = managedContentSettings.find((m) => m.langId === langId);
      if (M) {
        setContentSettings(M);
        if (M.footerText && M.siteSeoSettings && M.siteSeoSettings.length > 0) {
          let str = M.footerText;
          str = str.replace(
            /{_company_name}/g,
            M.siteSeoSettings[0].siteCompanyName
          );
          const year = new Date().getFullYear();
          str = str.replace(/{_year}/g, year);
          setFooterText(str);
        }
      }
    }
  }, [langId, managedContentSettings]);

  return (
    <>
      <Box sx={{ background: webSettings.siteColorTheme || "lightblue" }}>
        <Grid
          container
          spacing={1}
          justifyContent="space-around"
          sx={{ mt: 1, px: 1 }}
        >
          <Grid item xs={12} sm={6} md={2.5}>
            <SocialMediaPages />
          </Grid>
          <Grid item xs={12} sm={6} md={1.5}>
            <Information
              setPolicyName={setInnerPolicyName}
              setPolicyOpen={setInnerPolicyDlg}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <ShortVideo />
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <ContactUs contentSettings={contentSettings} />
          </Grid>
        </Grid>
      </Box>
      <Divider />
      {!smUp ? (
        <Typography
          variant="body2"
          sx={{
            background: webSettings.siteColorTheme || "lightblue",
            color: "black",
            pl: 2,
          }}
        >
          {footerText}
        </Typography>
      ) : (
        <Typography
          variant="body2"
          sx={{
            background: webSettings.siteColorTheme || "lightblue",
            color: "black",
            pl: 2,
          }}
        >
          {footerText}
          {/* Elifesaving Medicine © Copyright {year} . All Rights Reserved. */}
        </Typography>
      )}
      {(policyOpen || innerPolicyDlg) && (
        <Policy
          policyOpen={policyOpen}
          setPolicyOpen={setPolicyOpen}
          policyName={policyName}
          contentSettings={contentSettings}
          innerPolicyDlg={innerPolicyDlg}
          setInnerPolicyDlg={setInnerPolicyDlg}
          innerPolicyName={innerPolicyName}
        />
      )}
    </>
  );
}
