import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";

import { currencyFormatter } from "../../../../hooks/currencyFormatter";
import { PF } from "../../../../config";
import { useDispatch, useSelector } from "react-redux";
import { expensePdfDownload } from "../../../../reduxToolkit/features/adminPanel/medicine";
import LoadingButton from "@mui/lab/LoadingButton";

// Media Query
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import "table2excel";

const PreviewOfficeExpenseSheet = ({ buffer, onClose }) => {
  // Media Query
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  const [loadingPdfGenerate, setLoadingPdfGenerate] = useState(false);
  const [loadingExcelGenerate, setLoadingExcelGenerate] = useState(false);
  const [template, setTemplate] = useState("");
  const [templatePdf, setTemplatePdf] = useState("");

  const dispatch = useDispatch();

  const { expensePdfDownloadResponse } = useSelector((state) => state.medicine);

  // Download pdf
  const handleDownloadPDF = () => {
    setLoadingPdfGenerate(true);
    dispatch(expensePdfDownload({ html: templatePdf }));
    setTimeout(() => {
      setLoadingPdfGenerate(false);
    }, 2000);
  };

  // Download Excel
  const handleDownloadExcel = () => {
    setLoadingExcelGenerate(true);

    // Excel
    const Table2Excel = window.Table2Excel;
    const options = { defaultFileName: buffer.monthName + " Expense Sheet" };
    const table2excel = new Table2Excel(options);
    table2excel.export(document.querySelectorAll("table"));
    setTimeout(() => {
      setLoadingExcelGenerate(false);
    }, 1000);
  };

  useEffect(() => {
    if (expensePdfDownloadResponse && expensePdfDownloadResponse.filename) {
      setTimeout(() => {
        window.open(
          PF + "files/PDFs/" + expensePdfDownloadResponse.filename,
          "_blank"
        );
      }, 1000);
      if (!smUp) {
        onClose();
      }
    }
  }, [expensePdfDownloadResponse]);

  useEffect(() => {
    let head = `
            <p class="text-center">
                <strong>
                    Office Expenditure Sheet
                </strong>
            </p>
            <p class="text-center" style="margin-top: -15px">${buffer.monthName}</p>
    `;
    let tableBody = "";

    buffer.entries.forEach((entry) => {
      let dateTableRow = "";

      entry.dateEntries.forEach((expense) => {
        dateTableRow += `
        <tr>
            <td class="text-nowrap">${format(
              new Date(expense.date),
              "dd-MM-y"
            )}</td>
            <td>${expense.desc}</td>
            <td style="word-break: break-word;">${expense.calculation}</td>
            <td class="text-right text-nowrap" style="color:${
              expense.isPlus ? "green" : "red"
            }">BDT 
            ${expense.isPlus ? "" : "- "} 
            ${currencyFormatter("bdt", expense.total)}
            </td>
        </tr>
                    `;
      });
      tableBody +=
        dateTableRow +
        `<tr>
            <th colspan="2"  class="text-right"></th>
            <th class="text-right">In Hand</th>
            <th  class="text-right text-nowrap">BDT ${currencyFormatter(
              "bdt",
              entry.inHand
            )}</th>
        </tr>
            `;
    });

    let html =
      style +
      head +
      `
       <table>
          <tbody>
            <tr>
              <th >Date</th>
              <th>Description</th>
              <th>Calculation</th>
              <th style="text-align: right">Total</th>
            </tr>
            ${tableBody}
            </tbody>
        </table>
        `;
    setTemplate(html);

    // -------------------
    let tableBodyPdf = "";
    buffer.entries.forEach((entry) => {
      let dateTableRow = "";

      entry.dateEntries.forEach((expense) => {
        dateTableRow += `
        <tr>
            <td class="text-nowrap">${format(
              new Date(expense.date),
              "dd-MM-y"
            )}</td>
            <td>${expense.desc}</td>
            <td style="word-break: break-word;">${expense.calculation}</td>
            <td class="text-right text-nowrap">BDT 
            ${expense.isPlus ? "" : "- "} 
            ${currencyFormatter("bdt", expense.total)}
            </td>
        </tr>
                    `;
      });
      tableBodyPdf +=
        dateTableRow +
        `<tr>
            <th colspan="2"  class="text-right"></th>
            <th class="text-right">In Hand</th>
            <th  class="text-right text-nowrap">BDT ${currencyFormatter(
              "bdt",
              entry.inHand
            )}</th>
        </tr>
            `;
    });

    let htmlPdf =
      style +
      head +
      `
       <table>
          <tbody>
            <tr>
              <th >Date</th>
              <th>Description</th>
              <th>Calculation</th>
              <th style="text-align: right">Total</th>
            </tr>
            ${tableBodyPdf}
            </tbody>
        </table>
        `;
    setTemplatePdf(htmlPdf);
  }, [buffer]);
  return (
    <>
      <Dialog
        open={buffer}
        onClose={onClose}
        aria-labelledby={"preview-expense-sheet"}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          {!smUp ? (
            <Typography variant="body2" color="initial">
              Preview Not Available In Mobile
            </Typography>
          ) : (
            <>
              <div dangerouslySetInnerHTML={{ __html: template }} />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
          <LoadingButton
            loading={loadingPdfGenerate}
            disabled={loadingExcelGenerate || loadingPdfGenerate}
            onClick={handleDownloadPDF}
            color="error"
            variant="outlined"
          >
            Download PDF
          </LoadingButton>
          <LoadingButton
            loading={loadingExcelGenerate}
            disabled={loadingExcelGenerate || loadingPdfGenerate}
            onClick={handleDownloadExcel}
            color="secondary"
            variant="outlined"
          >
            Download Excel
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PreviewOfficeExpenseSheet;

const style = `
<style>

table{
    border-collapse: collapse;
    margin: 0 auto;
    width: 100%;
    border: 1px solid black;
    font-size: 11px;

  }
  
  td,
  th {
    border: 1px solid black;
    text-align: left;  
    font-size: 14px;
    table-layout: auto;
    padding: .4rem .8rem;
  }

  .text-right{
    text-align: right;
  }

  .text-center{
    text-align: center;
  }

  .text-nowrap{
    white-space: nowrap;
  }
</style>
`;
