import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { additionalPages } from "../data/additionalPages";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import {
  getMedicines,
  replaceTextInMedicineAdditionalSection,
} from "../../../../reduxToolkit/features/adminPanel/medicine";
import { copyToClipBoard } from "../../../../hooks/copyToClipBoard";

const ManageCollectiveMedicineAdditionalSection = () => {
  const dispatch = useDispatch();

  const [siteLang, setSiteLang] = useState("en");
  // Local Store
  const localLangData = JSON.parse(localStorage.getItem("lang"));
  // Store

  // Store
  const { replaceTextInMedicineAdditionalSectionResponse } = useSelector(
    (state) => state.medicine
  );

  const { activeLang } = useSelector((state) => state.misc);

  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);
  const [replaceText, setReplaceText] = useState("");
  const [replaceWithText, setReplaceWithText] = useState("");

  const [sectionToChange, setSectionToChange] = useState(
    additionalPages[0].sectionName
  );

  const handleApply = () => {
    setLoading(true);
    dispatch(
      replaceTextInMedicineAdditionalSection({
        sectionToChange,
        replaceText,
        replaceWithText,
        lang: siteLang,
      })
    );
  };

  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setSiteLang(activeLang.langCode);
    } else {
      if (localLangData) {
        setSiteLang(localLangData.langCode);
      }
    }
  }, [activeLang]);

  useEffect(() => {
    if (
      replaceTextInMedicineAdditionalSectionResponse &&
      replaceTextInMedicineAdditionalSectionResponse.message
    ) {
      setLoading(false);

      if (replaceTextInMedicineAdditionalSectionResponse.failed) {
      } else {
      }
      setTimeout(() => {
        dispatch(getMedicines());
      }, 1000);
    }
  }, [replaceTextInMedicineAdditionalSectionResponse]);

  useEffect(() => {
    if (replaceText !== "" && sectionToChange) setDisable(false);
    else setDisable(true);
  }, [replaceText, sectionToChange]);

  return (
    <Grid container flexDirection="column" gap={1} sx={{ maxWidth: "100%" }}>
      <Grid item>
        <Button
          variant="contained"
          color="warning"
          size="small"
          fullWidth
          sx={{ pointerEvents: "none" }}
        >
          Collectively Manage All Medicine Additional Info
        </Button>
        <Typography variant="subtitle2" sx={{ mt: 2 }}>
          Rules
        </Typography>
        <Typography variant="subtitle2" color="gray">
          - Write{" "}
          <span
            style={{
              padding: "2px 4px",
              backgroundColor: "indigo",
              color: "white",
              borderRadius: "0.25rem",
              marginRight: "2px",
              cursor: "pointer",
            }}
            onClick={() => copyToClipBoard(dispatch, `{_generic}`)}
          >{`{_generic} `}</span>{" "}
          in places where you want generic name
        </Typography>
        <Typography variant="subtitle2" color="gray">
          - Write{" "}
          <span
            style={{
              padding: "2px 4px",
              backgroundColor: "indigo",
              color: "white",
              borderRadius: "0.25rem",
              marginRight: "2px",
              cursor: "pointer",
            }}
            onClick={() => copyToClipBoard(dispatch, `{_genericBrand}`)}
          >{`{_genericBrand} `}</span>{" "}
          in places where you want generic Brand name
        </Typography>
        <Typography variant="subtitle2" sx={{ mb: 2 }} color="gray">
          - Write{" "}
          <span
            style={{
              padding: "2px 4px",
              backgroundColor: "indigo",
              color: "white",
              borderRadius: "0.25rem",
              marginRight: "2px",
              cursor: "pointer",
            }}
            onClick={() => copyToClipBoard(dispatch, `<br/>`)}
          >{`<br/> `}</span>{" "}
          for new line
        </Typography>
      </Grid>

      <Divider />
      <FormControl fullWidth size="small">
        <InputLabel id="demo-simple-select-label">Section To Change</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={sectionToChange}
          label="Section To Change"
          onChange={(e) => setSectionToChange(e.target.value)}
        >
          {additionalPages.map((section, index) => (
            <MenuItem key={index} value={section.sectionName}>
              {section.sectionName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Grid item>
        <Grid container justifyContent={"space-between"}>
          <Grid item lg={12}>
            <TextField
              label="Replace Text"
              value={replaceText}
              onChange={(e) => setReplaceText(e.target.value)}
              variant="outlined"
              fullWidth
              sx={{ mb: 1 }}
              multiline
              minRows={4}
            />
          </Grid>
          <Grid item lg={12}>
            <TextField
              label="Replace With Text"
              value={replaceWithText}
              onChange={(e) => setReplaceWithText(e.target.value)}
              variant="outlined"
              fullWidth
              sx={{ mb: 1 }}
              multiline
              minRows={4}
            />
          </Grid>
        </Grid>
      </Grid>
      <LoadingButton
        loading={loading ? true : false}
        loadingPosition="end"
        variant="contained"
        disabled={disable}
        color="info"
        size={"small"}
        onClick={handleApply}
      >
        Replace All
      </LoadingButton>
    </Grid>
  );
};

export default ManageCollectiveMedicineAdditionalSection;
