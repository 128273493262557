import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Grid,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { siteMapMenu } from "../../utils/siteMapMenuList";

export default function SiteMap() {
  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate
  return (
    <>
      <Grid
        container
        spacing={0}
        flexDirection="column"
        justifyContent="center"
        minHeight="50vh"
      >
        {siteMapMenu.map((x, i) => (
          <Grid item key={i}>
            <List>
              <ListItem disablePadding>
                <Link
                  to={`${x.link}`}
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <ListItemButton>
                    <ListItemText primary={`${translateFront(x.name)}`} />
                  </ListItemButton>
                </Link>
              </ListItem>
            </List>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
