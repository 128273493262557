import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  createFilterOptions,
  Typography,
  Alert,
} from "@mui/material";

// TODO Tried to update priority in accordance with the name change but couldn't

import React, { useEffect, useRef, useState } from "react";
import { ChromePicker } from "react-color";
import InvertColorsIcon from "@mui/icons-material/InvertColors";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import RolePrioritySelector from "../RolePrioritySelector";
import LoadingButton from "@mui/lab/LoadingButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useDispatch, useSelector } from "react-redux";
import { adminMenuList } from "../../data/adminMenuList";
import {
  createRole,
  getRole,
} from "../../../../reduxToolkit/features/adminPanel/role";
import { getNotification } from "../../../../reduxToolkit/features/adminPanel/notification";

export default function AddRole({
  setAddRoleSection,
  setAddRoleDlg,
  translate6,
}) {
  // Ref
  const roleNameRef = useRef();
  // Color Picker
  const [color, setColor] = useState("lightgrey");
  const [showColorPicker, setShowColorPicker] = useState(false);

  // other states
  const [loading, setLoading] = useState(false);
  const [roleName, setRoleName] = useState("");
  const [dupRoleName, setDupRoleName] = useState("");
  const [disableEdit, setDisableEdit] = useState(false);

  const [updatedRoleListMain, setUpdatedRoleListMain] = useState(null);
  const [roleScope, setRoleScope] = useState([]);

  const [createClicked, setCreateClicked] = useState(false);

  const [lang, setLang] = useState("");
  const [langId, setLangId] = useState("");

  // localData
  const localData = JSON.parse(localStorage.getItem("adminLoginInfo"));
  const localLangData = JSON.parse(localStorage.getItem("lang"));

  // Store
  const { createRoleResponse, roles } = useSelector((state) => state.role);
  const { langs, activeLang } = useSelector((state) => state.misc);

  const dispatch = useDispatch();
  // UseEffect
  useEffect(() => {
    if (createClicked) {
      if (!createRoleResponse.failed) {
        setDupRoleName("");
        setAddRoleDlg && setAddRoleDlg(false);
        setAddRoleSection && setAddRoleSection(false);
        dispatch(getRole());
        dispatch(getNotification());
      } else {
        setDupRoleName(roleName);
        setRoleName("");
        roleNameRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [createClicked, createRoleResponse]);

  //   Functions
  const handleAddRole = () => {
    setLoading(true);
    dispatch(
      createRole({
        lang: "en",
        roleList: updatedRoleListMain,
        roleName,
        roleScope,
        color: color.hex || color,
        creatorId: localData._id,
      })
    );
    setTimeout(() => {
      setLoading(false);
      setCreateClicked(true);
    }, 2000);
  };

  // translate
  const [langSettings1, setLangSettings1] = useState([]);
  const title1 = "Manage Role";

  useEffect(() => {
    if (langs && langs.length > 0 && lang !== "") {
      const L = langs.find((l) => l.langCode === lang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      const manageRole = arr.find((l) => l._title === title1);
      if (manageRole) setLangSettings1(manageRole.data);
    }
  }, [lang, langs]);

  const translate1 = (String) => translation(String, title1, 1);

  const translation = (String, title, index) => {
    let translation = "";
    let settings = [];
    if (index === 1) {
      settings = langSettings1;
    }
    const T = settings.find((ls) => ls.title === String);
    if (T && T.value) translation = T.value;
    else {
      let arr = [];
      if (langs && langs.length > 0 && lang !== "") {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.settings && LEn.settings.length > 0) arr = LEn.settings;
        const dashboard = arr.find((l) => l._title === title);
        if (dashboard) {
          const T = dashboard.data.find((ls) => ls.title === String);
          if (T && T.value) translation = T.value;
        }
      }
    }
    return translation;
  };

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setLang(activeLang.langCode);
      setLangId(activeLang._id);
    } else {
      if (localLangData) {
        setLang(localLangData.langCode);
        setLangId(localLangData._id);
      }
    }
  }, [activeLang]);

  return (
    <Grid container spacing={0} flexDirection="column">
      <Grid item ref={roleNameRef}>
        <TextField
          fullWidth
          margin="normal"
          size="small"
          label={translate1("Role Name")}
          variant="standard"
          color={dupRoleName !== "" && "error"}
          disabled={disableEdit}
          value={roleName}
          required
          onChange={(e) => setRoleName(e.target.value)}
        />
        {dupRoleName !== "" && (
          <Alert severity="error" sx={{ my: 1 }}>
            {translate1("Role Name")}: "{dupRoleName}"{" "}
            {translate6("Already Exist")}!!
          </Alert>
        )}
      </Grid>
      {/* Color Picker */}
      <Grid item>
        <Button
          fullWidth
          variant="contained"
          size="small"
          color="inherit"
          disabled={disableEdit}
          sx={{ background: color.hex }}
          onClick={() => setShowColorPicker(true)}
          startIcon={<InvertColorsIcon />}
        >
          {translate1("Pick A Color")}
        </Button>
        <Dialog
          open={showColorPicker}
          onClose={() => setShowColorPicker(false)}
        >
          <DialogContent>
            <ChromePicker
              color={color}
              disableAlpha
              onChange={(updatedColor) => setColor(updatedColor)}
            />
          </DialogContent>
          <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
            <Button onClick={() => setShowColorPicker(false)} autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>

      <Grid item>
        <FormControl fullWidth sx={{ my: 1 }}>
          <InputLabel id="demo-simple-select-label">
            {translate1("Role Scope")}
          </InputLabel>
          <Select
            multiple
            value={roleScope}
            label={translate1("Role Scope")}
            onChange={(e) => setRoleScope(e.target.value)}
            variant="standard"
            size="small"
          >
            {adminMenuList.map((x, i) => (
              <MenuItem key={i} value={x.name}>
                {x.name}
              </MenuItem>
            ))}
          </Select>
          <Typography variant="body2" color="secondary" align="center">
            {translate1("keep empty to give all access")}
          </Typography>
        </FormControl>
      </Grid>
      {roles.length > 0 && (
        <Grid item>
          <Button
            size="small"
            variant="text"
            fullWidth
            startIcon={<MilitaryTechIcon />}
            sx={{ pointerEvents: "none" }}
          >
            {translate1("Set priority")}
          </Button>
          <RolePrioritySelector
            color={color.hex || color}
            roleName={roleName}
            setDisableEdit={setDisableEdit}
            updatedRoleListMain={updatedRoleListMain}
            setUpdatedRoleListMain={setUpdatedRoleListMain}
            translate6={translate6}
          />
        </Grid>
      )}
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <LoadingButton
          onClick={handleAddRole}
          endIcon={<AddCircleOutlineIcon sx={{ mb: 0.5 }} />}
          loading={loading}
          loadingPosition="end"
          variant="contained"
          size="small"
          color="secondary"
          fullWidth
          disabled={roleName === ""}
        >
          {translate1("Create")}
        </LoadingButton>
      </Grid>
    </Grid>
  );
}
