import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompanies,
  getDosages,
  getMedCategories,
  getMedicines,
} from "../../../../../reduxToolkit/features/adminPanel/medicine";
import SelectionAll from "./SelectionAll";
import SelectionByCategory from "./SelectionByCategory";
import SelectionDosage from "./SelectionDosage";

import SelectionManual from "./SelectionManual";

export default function LeafletSelectionSection({
  selectionType,
  siteLang,
  setSelectedForPreview,
}) {
  const dispatch = useDispatch();
  const [medList, setMedList] = useState([]);
  const [medListEn, setMedListEn] = useState([]);

  //   Store
  const { medicines, categories, companies, medVariants, dosages } =
    useSelector((state) => state.medicine);

  useEffect(() => {
    if (!(medicines && medicines.length > 0)) dispatch(getMedicines());
    if (!(categories && categories.length > 0)) dispatch(getMedCategories());
    if (!(companies && companies.length > 0)) dispatch(getCompanies());
    if (!(dosages && dosages.length > 0)) dispatch(getDosages());
  }, []);

  useEffect(() => {
    if (medicines && medicines.length > 0) {
      const m = medicines.find((m) => {
        return m.lang === siteLang;
      });
      const mEn = medicines.find((m) => m.lang === "en");

      if (m && m.data.length > 0) {
        let arr = [...m.data].sort((a, b) =>
          a.genericBrand.toLowerCase().replace(/\s/g, "") >
          b.genericBrand.toLowerCase().replace(/\s/g, "")
            ? 1
            : -1
        );
        setMedList(arr);
      }
      if (mEn && mEn.data.length > 0) {
        let arrEn = [...mEn.data].sort((a, b) =>
          a.genericBrand.toLowerCase().replace(/\s/g, "") >
          b.genericBrand.toLowerCase().replace(/\s/g, "")
            ? 1
            : -1
        );
        setMedListEn(arrEn);
      }
    }
  }, [medicines, siteLang]);

  useEffect(() => {
    setSelectedForPreview([]);
  }, [selectionType, siteLang]);

  return (
    <>
      {selectionType === 0 ? (
        <SelectionManual
          medList={medList}
          medListEn={medListEn}
          siteLang={siteLang}
          setSelectedForPreview={setSelectedForPreview}
        />
      ) : selectionType === 1 ? (
        <SelectionByCategory
          categories={categories}
          medList={medList}
          siteLang={siteLang}
          setSelectedForPreview={setSelectedForPreview}
        />
      ) : selectionType === 2 ? (
        <SelectionDosage
          medList={medList}
          siteLang={siteLang}
          setSelectedForPreview={setSelectedForPreview}
          dosages={dosages}
          medVariants={medVariants}
        />
      ) : (
        selectionType === 3 && (
          <SelectionAll
            medList={medList}
            siteLang={siteLang}
            setSelectedForPreview={setSelectedForPreview}
          />
        )
      )}
    </>
  );
}
