import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Typography,
  IconButton,
  Divider,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
// import { roleList } from "../data/roleList";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ChromePicker } from "react-color";
// Icon
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CloseIcon from "@mui/icons-material/Close";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import InvertColorsIcon from "@mui/icons-material/InvertColors";
import CheckIcon from "@mui/icons-material/Check";
import AddRole from "./AddRole";
import RolePrioritySelectorFromEdit from "../RolePrioritySelectorFromEdit";
// react-redux
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  deleteRole,
  getRole,
  updateRole,
} from "../../../../reduxToolkit/features/adminPanel/role";
import { getNotification } from "../../../../reduxToolkit/features/adminPanel/notification";
import ViewRole from "./ViewRole";
import EditScope from "./EditScope";
import { getAdmins } from "../../../../reduxToolkit/features/adminPanel/admin";

// AntSwitch
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

export default function ManageRole({
  addRoleDlg,
  setAddRoleDlg,
  manageRoleDlg,
  setManageRoleDlg,
  translate6,
}) {
  // Responsive
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  // localData
  const localData = JSON.parse(localStorage.getItem("adminLoginInfo"));

  // Store
  const { roles } = useSelector((state) => state.role);

  // Use State
  const [loading, setLoading] = useState(false);
  const [viewRoleDlg, setViewRoleDlg] = useState(false);
  const [viewRoleBuffer, setViewRoleBuffer] = useState("");
  const [newRoleName, setNewRoleName] = useState("");
  const [roleList, setRoleList] = useState([]);

  // Color Picker
  const [color, setColor] = useState("lightgrey");
  const [showColorPicker, setShowColorPicker] = useState(false);

  // Role Edit
  const [editBar, setEditBar] = useState(0);
  const [deleteRoleDlg, setDeleteRoleDlg] = useState(false);
  const [deleteRoleBuffer, setDeleteRoleBuffer] = useState("");
  const [transferRoleBuffer, setTransferRoleBuffer] = useState("");
  const [scopeBuffer, setScopeBuffer] = useState("");
  const [scopeDlg, setScopeDlg] = useState(false);
  const [roleScope, setRoleScope] = useState(null);

  // Priority Change From Edit
  const [priorityDlg, SetPriorityDlg] = useState(false);
  const [updatedRoleListMain, setUpdatedRoleListMain] = useState(null);

  const [lang, setLang] = useState("");
  const [langId, setLangId] = useState("");

  // localData
  const localLangData = JSON.parse(localStorage.getItem("lang"));

  // Store
  const { langs, activeLang } = useSelector((state) => state.misc);

  // UseEffect
  useEffect(() => {
    setRoleList([...roles]);
  }, [roles]);

  useEffect(() => {
    roleList.sort((a, b) => a.priority - b.priority);
  }, [roleList]);

  // Functions
  const dispatch = useDispatch();
  const handleRoleEdit = (index, color, roleName, roleId) => {
    setEditBar(index + 1);
    setColor(color);
    setNewRoleName(roleName);
  };

  const handleRoleDelete = (roleName) => {
    setDeleteRoleDlg(true);
    setDeleteRoleBuffer(roleName);
  };

  const handleRoleDeleteCancel = () => {
    setDeleteRoleDlg(false);
    setTransferRoleBuffer("");
    setDeleteRoleBuffer("");
  };

  const handleRoleDeleteConfirm = () => {
    const dr = roleList.find((o) => o.roleName === deleteRoleBuffer);
    setLoading(true);
    dispatch(
      deleteRole({
        _id: dr._id,
        transferRoleId: transferRoleBuffer,
        creator: localData._id,
      })
    );
    setTimeout(() => {
      dispatch(getRole());
      dispatch(getNotification());
      dispatch(getAdmins());
      setDeleteRoleDlg(false);
      setTransferRoleBuffer("");
      setDeleteRoleBuffer("");
      setLoading(false);
    }, 2000);
  };

  // view role
  const handleViewRole = (id) => {
    setViewRoleBuffer(id);
    setViewRoleDlg(true);
  };

  // HandleScope
  const handleScope = (id) => {
    setScopeBuffer(id);
    setScopeDlg(true);
  };

  // Handle Role Edit
  const handleRoleEditConfirm = (roleId) => {
    dispatch(
      updateRole({
        lang: "en",
        roleId,
        roleName: newRoleName,
        roleScope,
        roleList: updatedRoleListMain,
        color: color.hex || color,
        creatorId: localData._id,
      })
    );

    setTimeout(() => {
      setNewRoleName("");
      setRoleScope(null);
      setUpdatedRoleListMain(null);
      setEditBar(0);
      dispatch(getNotification());
      dispatch(getRole());
      dispatch(getAdmins());
    }, 1000);
  };

  // translate
  const [langSettings1, setLangSettings1] = useState([]);
  const title1 = "Manage Role";

  useEffect(() => {
    if (langs && langs.length > 0 && lang !== "") {
      const L = langs.find((l) => l.langCode === lang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      const manageRole = arr.find((l) => l._title === title1);
      if (manageRole) setLangSettings1(manageRole.data);
    }
  }, [lang, langs]);

  const translate1 = (String) => translation(String, title1, 1);

  const translation = (String, title, index) => {
    let translation = "";
    let settings = [];
    if (index === 1) {
      settings = langSettings1;
    }
    const T = settings.find((ls) => ls.title === String);
    if (T && T.value) translation = T.value;
    else {
      let arr = [];
      if (langs && langs.length > 0 && lang !== "") {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.settings && LEn.settings.length > 0) arr = LEn.settings;
        const dashboard = arr.find((l) => l._title === title);
        if (dashboard) {
          const T = dashboard.data.find((ls) => ls.title === String);
          if (T && T.value) translation = T.value;
        }
      }
    }
    return translation;
  };

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setLang(activeLang.langCode);
      setLangId(activeLang._id);
    } else {
      if (localLangData) {
        setLang(localLangData.langCode);
        setLangId(localLangData._id);
      }
    }
  }, [activeLang]);

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open={manageRoleDlg}
        onClose={() => setManageRoleDlg(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {translate1("Role Management")}
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={0}
            justifyContent="space-between"
            alignItems="center"
          >
            {smUp && (
              <Grid item xs={7}>
                <Typography
                  variant="subtitle1"
                  color="initial"
                  sx={{ borderBottom: ".4rem solid black", mb: 2 }}
                >
                  {!addRoleDlg
                    ? translate1("Role List")
                    : translate1("Create Role")}
                </Typography>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={5}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{ mb: { xs: 1 } }}
              >
                <Typography>{translate1("Manage Roles")} </Typography>
                <AntSwitch
                  color="warning"
                  checked={addRoleDlg ? true : false}
                  inputProps={{ "aria-label": "manage-roles" }}
                  onChange={() => setAddRoleDlg(!addRoleDlg)}
                />
                <Typography>{translate1("Create Role")}</Typography>
              </Stack>
            </Grid>
          </Grid>
          <Button
            variant="contained"
            fullWidth
            color="secondary"
            sx={{ mb: 1 }}
          >
            {translate1("Roles")} ({roleList.length})
          </Button>
          {!addRoleDlg &&
            roleList.length > 0 &&
            roleList.map((x, i) => (
              <Fragment key={i}>
                <Grid
                  container
                  spacing={0}
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ background: x.color }}
                >
                  <Grid item xs={1} sm={1}>
                    <IconButton
                      size="small"
                      onClick={() => handleViewRole(x._id)}
                    >
                      <PreviewIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={5.5} sm={8}>
                    <Typography variant="subtitle2" color="initial">
                      {x.priority} | {x.roleName}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sm={1.5}>
                    <IconButton
                      size="small"
                      onClick={() =>
                        handleRoleEdit(i, x.color, x.roleName, x._id)
                      }
                      disabled={editBar === 0 ? false : true}
                    >
                      <ModeEditIcon
                        color={editBar === 0 ? "warning" : "inherit"}
                      />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleRoleDelete(x.roleName)}
                      disabled={editBar === 0 ? false : true}
                    >
                      <DeleteIcon color={editBar === 0 ? "error" : "inherit"} />
                    </IconButton>
                  </Grid>
                </Grid>
                <Divider />

                {editBar === i + 1 && (
                  <Grid
                    container
                    spacing={0}
                    sx={{
                      my: 1,
                      border: "1px solid green",
                      p: 0.5,
                      borderRadius: ".25rem",
                    }}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs={2} sm={0.5} sx={{ order: { xs: 2, sm: 1 } }}>
                      <IconButton
                        size="small"
                        onClick={() => SetPriorityDlg(true)}
                      >
                        <img
                          src="https://img.icons8.com/external-xnimrodx-lineal-color-xnimrodx/64/000000/external-rank-seo-xnimrodx-lineal-color-xnimrodx.png"
                          alt="rank"
                          style={{ height: "20px" }}
                        />
                      </IconButton>
                    </Grid>

                    <Grid item xs={4} sm={1.5} sx={{ order: { xs: 3, sm: 2 } }}>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => handleScope(x._id)}
                      >
                        {translate1("Role Scope")}
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={5}
                      md={6.5}
                      sx={{ order: { xs: 1, sm: 3 } }}
                    >
                      <TextField
                        id=""
                        label={translate1("Role Name")}
                        value={newRoleName}
                        size="small"
                        onChange={(e) => setNewRoleName(e.target.value)}
                        variant="standard"
                        fullWidth
                      />
                    </Grid>
                    {/* Color Picker */}
                    <Grid item xs={2} sm={3} md={2} sx={{ order: { xs: 4 } }}>
                      {smUp ? (
                        <Button
                          fullWidth
                          variant="contained"
                          size="small"
                          color="inherit"
                          sx={{ background: color.hex || color }}
                          onClick={() => setShowColorPicker(true)}
                          startIcon={<InvertColorsIcon />}
                        >
                          {translate6("Pick A Color")}
                        </Button>
                      ) : (
                        <IconButton
                          size="small"
                          onClick={() => setShowColorPicker(true)}
                        >
                          <InvertColorsIcon
                            sx={{ color: color.hex || color }}
                          />
                        </IconButton>
                      )}
                      <Dialog
                        open={showColorPicker}
                        onClose={() => setShowColorPicker(false)}
                      >
                        <DialogContent>
                          <ChromePicker
                            color={color}
                            disableAlpha
                            onChange={(updatedColor) => setColor(updatedColor)}
                          />
                        </DialogContent>
                        <DialogActions
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Button
                            onClick={() => setShowColorPicker(false)}
                            autoFocus
                          >
                            {translate1("Close")}
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Grid>
                    <Grid item xs={4} sm={1.5} sx={{ order: { xs: 5 } }}>
                      <IconButton
                        size="small"
                        onClick={() => handleRoleEditConfirm(x._id)}
                      >
                        <CheckIcon color="success" />
                      </IconButton>
                      <IconButton size="small" onClick={() => setEditBar(0)}>
                        <CloseIcon color="error" />
                      </IconButton>
                    </Grid>
                    {/* Change Priority From Edit Page */}
                    <Dialog
                      open={priorityDlg}
                      onClose={() => SetPriorityDlg(false)}
                      aria-labelledby="priority-change-from-edit"
                      fullWidth
                      maxWidth={"sm"}
                    >
                      <DialogContent>
                        <RolePrioritySelectorFromEdit
                          color={x.color.hex || x.color}
                          roleName={x.roleName}
                          updatedRoleListMain={updatedRoleListMain}
                          setUpdatedRoleListMain={setUpdatedRoleListMain}
                        />
                      </DialogContent>
                      <DialogActions
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          onClick={() => SetPriorityDlg(false)}
                          color="success"
                        >
                          {translate6("Confirm")}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Grid>
                )}
              </Fragment>
            ))}
          {addRoleDlg && (
            <AddRole setAddRoleDlg={setAddRoleDlg} translate6={translate6} />
          )}
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={() => setManageRoleDlg(false)}
            autoFocus
            color="error"
          >
            {translate1("Close")}
          </Button>
        </DialogActions>
      </Dialog>
      {/* Dialogs */}
      {/* Delete Role Dialog */}
      <Dialog
        open={deleteRoleDlg}
        onClose={() => setDeleteRoleDlg(false)}
        aria-labelledby="delete-role-dialog"
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle>
          <Typography variant="subtitle2" color="initial">
            {translate6("Delete")} "{deleteRoleBuffer}"
          </Typography>
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth size="small" sx={{ my: 1 }}>
            <InputLabel id="demo-simple-select-label">
              {translate6("Transfer Role")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={transferRoleBuffer}
              label={translate6("Transfer Role")}
              onChange={(e) => setTransferRoleBuffer(e.target.value)}
            >
              {roleList.length > 0 &&
                roleList.map(
                  (x, i) =>
                    deleteRoleBuffer !== x.roleName && (
                      <MenuItem key={i} value={x._id}>
                        {x.roleName}
                      </MenuItem>
                    )
                )}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRoleDeleteCancel} color="primary">
            Cancel
          </Button>
          <LoadingButton
            endIcon={<DeleteOutlineIcon sx={{ mb: 0.5 }} />}
            loading={loading}
            loadingPosition="end"
            onClick={handleRoleDeleteConfirm}
            disabled={transferRoleBuffer === "" ? true : false}
            color="error"
          >
            {translate6("Confirm")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ViewRole
        viewRoleDlg={viewRoleDlg}
        setViewRoleDlg={setViewRoleDlg}
        viewRoleBuffer={viewRoleBuffer}
        translate6={translate6}
      />
      {/* Scope */}
      <Dialog
        open={scopeDlg}
        onClose={() => setScopeDlg(false)}
        aria-labelledby={"scope-dlg"}
      >
        <DialogContent>
          <EditScope
            scopeBuffer={scopeBuffer}
            roleScope={roleScope}
            setRoleScope={setRoleScope}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setScopeDlg(false)} color="primary">
            {translate6("Confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
