import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import NoticeBoard from "../Policy/NoticeBoard";
import { useSelector } from "react-redux";
// Icon

export default function Information({ setPolicyOpen, setPolicyName }) {
  const [dOpen, dSetOpen] = useState(false);
  const [dName, dSetName] = useState("");

  // functions
  const handlePolicy = (policyName) => {
    setPolicyOpen(true);
    setPolicyName(policyName);
  };

  const handleNotice = (noticeName) => {
    dSetOpen(true);
    dSetName(noticeName);
  };

  const navigate = useNavigate();
  const handleSiteMap = async () => {
    await navigate("/sitemap");
    setTimeout(() => {
      scroll();
    }, 250);
  };

  const scroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate
  return (
    <>
      <Typography
        variant="h6"
        color="initial"
        sx={{ borderBottom: "2px solid black", mt: 1 }}
      >
        {translateFront("Information")}
      </Typography>
      <List dense>
        <ListItem disablePadding>
          <ListItemButton size="small" onClick={() => handlePolicy("about-us")}>
            <ListItemText primary={translateFront("About Us")} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            size="small"
            onClick={() => handlePolicy("disclaimer")}
          >
            <ListItemText primary={translateFront("Disclaimer")} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            size="small"
            onClick={() => handlePolicy("terms-and-conditions")}
          >
            <ListItemText primary={translateFront("Terms & Conditions")} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton size="small">
            <ListItemText
              primary={translateFront("Shipping Policy")}
              onClick={() => handlePolicy("shipping-policy")}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton size="small">
            <ListItemText
              primary={translateFront("Review Policy")}
              onClick={() => handlePolicy("review-policy")}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton size="small">
            <ListItemText
              primary={translateFront("Privacy Policy")}
              onClick={() => handlePolicy("privacy-policy")}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton size="small" onClick={handleSiteMap}>
            <ListItemText primary={translateFront("Site Map")} />
          </ListItemButton>
        </ListItem>
      </List>
      <NoticeBoard dOpen={dOpen} dSetOpen={dSetOpen} dName={dName} />
    </>
  );
}
