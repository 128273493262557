import React, { Fragment, useEffect, useState } from "react";
import { Alert, Box, Divider, List, ListItem } from "@mui/material";
import { useSelector } from "react-redux";
import LoadingBox from "../../../../components/misc/Loading";
import useLazyLoad from "../../../../hooks/useLazyLoad";
import { useRef } from "react";
import { getExactTime } from "../../../../hooks/getCreatedData";

export default function ChangesMade({ changesMadeBuffer }) {
  const triggerRef = useRef();
  const [changes, setChanges] = useState([]);
  const [changeList, setChangeList] = useState([]);
  const [pending, setPending] = useState(true);
  const [counter, setCounter] = useState(0);

  // Store
  const { pendingChanges, changesMade, notifications } = useSelector(
    (state) => state.notification
  );

  // const dispatch = useDispatch();
  // useEffect(() => {
  // dispatch(getNotification());
  // }, []);
  // const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(getChangesMade(changesMadeBuffer));
    if (counter < 2) {
      setPending(true);
      let arr = [...notifications];
      arr.sort((a, b) => b.createdAt - a.createdAt);
      const a = arr.filter((n) => n.adminWhoCreatedId === changesMadeBuffer);
      setChanges(a);
      setPending(false);
      setCounter(counter + 1);
    }
  }, [changes, changesMadeBuffer, counter, notifications]);

  // Handle lazy load
  const NUM_PER_PAGE = 16;
  const noti = changes.slice(0, NUM_PER_PAGE);
  const TOTAL_PAGES = changes.length / NUM_PER_PAGE;

  const onGrabData = (currentPage) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const noti = changes;
        const notiData = noti.slice(
          ((currentPage - 1) % TOTAL_PAGES) * NUM_PER_PAGE,
          NUM_PER_PAGE * (currentPage % TOTAL_PAGES)
        );
        resolve(notiData);
      }, 2000);
    });
  };

  const { data, loading: loadingNoti } = useLazyLoad({
    triggerRef,
    onGrabData,
  });

  useEffect(() => {
    if (changes.length < NUM_PER_PAGE) {
      setChangeList(noti);
    } else {
      if (data.length <= changes.length) {
        if (data && data.length > 0) {
          setChangeList(data);
        } else {
          setChangeList(noti);
        }
      }
    }
  }, [changes.length, data]);

  return (
    <>
      {pending ? (
        <LoadingBox w={"100%"} />
      ) : (
        <>
          {changeList.length > 0 ? (
            <>
              <List>
                {changeList.map((x, i) => (
                  <Fragment key={i}>
                    <ListItem disablePadding ref={triggerRef}>
                      <Alert
                        severity={x.severity || "info"}
                        sx={{
                          width: "100%",
                        }}
                      >
                        {`${changes.length - i}. ${x.title}`} <br />
                        {`${getExactTime(x.createdAt)}`}
                      </Alert>
                    </ListItem>
                    <Divider />
                  </Fragment>
                ))}
              </List>
              {loadingNoti && (
                <Box>
                  <LoadingBox w={"100%"} />
                </Box>
              )}
            </>
          ) : (
            <Alert severity="warning">No Changes</Alert>
          )}
        </>
      )}
    </>
  );
}
