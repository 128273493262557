import {
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography,
  DialogContentText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { currencyFormatter } from "../../../../../hooks/currencyFormatter";

export default function AdditionalCost({
  additionalCostArr,
  setAdditionalCostArr,
}) {
  const [open, setOpen] = useState(false);
  // const [variant, setVariant] = useState(0);
  const [newCost, setNewCost] = useState({
    title: "",
    cost: 0,
    text: "",
    variant: 0,
    id: new Date().getTime(),
  });

  const [editCost, setEditCost] = useState(undefined);

  // Dialogs
  const [deleteDlg, setDeleteDlg] = useState(false);
  const [deleteBuffer, setDeleteBuffer] = useState(undefined);

  const [editDlg, setEditDlg] = useState(false);
  const [editBuffer, setEditBuffer] = useState(undefined);

  const handleAddCost = () => {
    let arr = [...additionalCostArr];
    arr.push(newCost);
    setAdditionalCostArr(arr);
    setOpen(false);
    setNewCost({
      title: "",
      cost: 0,
      id: new Date().getTime(),
    });
  };

  const handleDeleteCost = () => {
    let arr = [...additionalCostArr];
    arr = arr.filter((a) => a.id !== deleteBuffer.id);
    setAdditionalCostArr(arr);
    setDeleteDlg(false);
    setDeleteBuffer(undefined);
  };

  const handleEditCost = () => {
    let arr = [...additionalCostArr];
    let editableIndex = arr.findIndex((a) => a.id === editBuffer.id);
    arr[editableIndex].title = editCost.title;
    arr[editableIndex].cost = editCost.cost;
    setAdditionalCostArr(arr);
    setEditDlg(false);
    setEditBuffer(undefined);
    setEditCost(undefined);
  };

  //! Translate
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate

  return (
    <>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          size="small"
          sx={{ pointerEvents: "none", mb: 1 }}
          fullWidth
          color="info"
        >
          {translate0("Additional Cost")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          size="small"
          sx={{ mb: 1 }}
          fullWidth
          color="success"
          onClick={() => setOpen(true)}
        >
          {translate0("Add Additional Cost")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          {additionalCostArr.map((cost, index) => (
            <Grid item xs={2}>
              <Card>
                <CardContent>
                  <Grid
                    container
                    spacing={2}
                    key={index}
                    sx={{ px: 2 }}
                    alignItems="center"
                  >
                    <Grid item flex={1}>
                      <Typography variant="h5" color="initial">
                        {cost.title}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="h6" color="error">
                        ${currencyFormatter("en", cost.cost)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item flex={1} sx={{ px: 2 }}>
                    <Typography variant="body2" color="gray">
                      {cost.text}
                    </Typography>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Grid container spacing={1} justifyContent="flex-end">
                    <Grid item>
                      <Button
                        onClick={() => {
                          setEditDlg(true);
                          setEditBuffer(cost);
                          setEditCost(cost);
                        }}
                        color="info"
                        variant="outlined"
                        size="small"
                        fullWidth
                      >
                        {translate0("Edit")}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        onClick={() => {
                          setDeleteDlg(true);
                          setDeleteBuffer(cost);
                        }}
                        color="error"
                        variant="outlined"
                        size="small"
                        fullWidth
                      >
                        {translate0("Delete")}
                      </Button>
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
      {open && (
        <Dialog open={open}>
          <DialogTitle>{translate0("Add Additional Cost")}</DialogTitle>
          <DialogContent>
            <TextField
              sx={{ mt: 1 }}
              label={translate0("Title")}
              value={newCost.title}
              onChange={(e) => {
                let obj = { ...newCost };
                obj.title = e.target.value;
                setNewCost(obj);
              }}
              size="small"
              fullWidth
              variant="standard"
            />
            <FormControl fullWidth size="small" sx={{ mt: 1 }}>
              <InputLabel id="demo-simple-select-label">Variant</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={newCost.variant}
                label="Variant"
                onChange={(e) => {
                  let obj = { ...newCost };
                  obj.variant = e.target.value;
                  setNewCost(obj);
                }}
              >
                <MenuItem value={0}>Cost</MenuItem>
                <MenuItem value={1}>Text</MenuItem>
              </Select>
            </FormControl>
            {newCost.variant === 0 ? (
              <TextField
                sx={{ mt: 1 }}
                label={translate0("Cost (USD)")}
                value={newCost.cost}
                onChange={(e) => {
                  let obj = { ...newCost };
                  obj.cost = e.target.value.replace(/\D/g, "");
                  setNewCost(obj);
                }}
                size="small"
                fullWidth
                variant="standard"
              />
            ) : (
              <TextField
                sx={{ mt: 1 }}
                label={translate0("Text")}
                value={newCost.text}
                onChange={(e) => {
                  let obj = { ...newCost };
                  obj.text = e.target.value;
                  setNewCost(obj);
                }}
                size="small"
                fullWidth
                variant="standard"
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="error">
              {translate0("Close")}
            </Button>
            <Button onClick={handleAddCost} color="success">
              {translate0("Add")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {/* Delete Dialog */}
      {deleteDlg && (
        <Dialog open={deleteDlg}>
          <DialogTitle>
            {translate0("Do you really want to delete")} {deleteBuffer.title} ?
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() => {
                setDeleteBuffer(undefined);
                setDeleteDlg(false);
              }}
              color="primary"
            >
              {translate0("Close")}
            </Button>
            <Button onClick={handleDeleteCost} color="error">
              {translate0("Delete")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {/* Edit Dialog */}
      {editDlg && (
        <Dialog open={editDlg}>
          <DialogTitle>
            {translate0("Edit Additional Cost")} - {editBuffer.title}
          </DialogTitle>
          <DialogContent>
            <TextField
              sx={{ mt: 1 }}
              label={translate0("Title")}
              value={editCost.title}
              onChange={(e) => {
                let obj = { ...editCost };
                obj.title = e.target.value;
                setEditCost(obj);
              }}
              size="small"
              fullWidth
              variant="standard"
            />
            <FormControl fullWidth size="small" sx={{ mt: 1 }}>
              <InputLabel id="demo-simple-select-label">Variant</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={editCost.variant}
                label="Variant"
                onChange={(e) => {
                  let obj = { ...editCost };
                  obj.variant = e.target.value;
                  setNewCost(obj);
                }}
              >
                <MenuItem value={0}>Cost</MenuItem>
                <MenuItem value={1}>Text</MenuItem>
              </Select>
            </FormControl>

            {newCost.variant === 0 ? (
              <TextField
                sx={{ mt: 1 }}
                label={translate0("Cost (USD)")}
                value={editCost.cost}
                onChange={(e) => {
                  let obj = { ...editCost };
                  obj.cost = e.target.value.replace(/\D/g, "");
                  setEditCost(obj);
                }}
                size="small"
                fullWidth
                variant="standard"
              />
            ) : (
              <TextField
                sx={{ mt: 1 }}
                label={translate0("Cost (USD)")}
                value={editCost.text}
                onChange={(e) => {
                  let obj = { ...editCost };
                  obj.text = e.target.value;
                  setEditCost(obj);
                }}
                size="small"
                fullWidth
                variant="standard"
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setEditDlg(false);
                setEditBuffer(undefined);
                setEditCost(undefined);
              }}
              color="error"
            >
              {translate0("Close")}
            </Button>
            <Button onClick={handleEditCost} color="warning">
              {translate0("Update")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
