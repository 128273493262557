import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import {
  getGoogleReviewCount,
  getSettings,
  setGoogleReviewCount,
} from "../../../../reduxToolkit/features/adminPanel/webSettings";

// Icon
import CheckIcon from "@mui/icons-material/Check";
import { Grid, IconButton, Tooltip } from "@mui/material";

export default function ManageGoogleReview() {
  const dispatch = useDispatch();
  const { googleReviewCount } = useSelector((state) => state.webSettings);

  const [reviewCount, setReviewCount] = useState(20);

  const handleReviewCountChange = (e) => {
    setReviewCount(e.target.value.replace(/\D/g, ""));
  };

  const handleReviewCountConfirm = () => {
    dispatch(setGoogleReviewCount({ reviewCount }));
    setTimeout(() => {
      dispatch(getGoogleReviewCount());
    }, 1000);
  };

  // useEffect
  useEffect(() => {
    dispatch(getGoogleReviewCount());
  }, []);

  // Store

  useEffect(() => {
    if (googleReviewCount !== undefined) setReviewCount(googleReviewCount);
  }, [googleReviewCount]);
  return (
    <>
      <Grid container>
        <Grid item>
          <TextField
            label="Google Review Count"
            value={reviewCount}
            onChange={handleReviewCountChange}
            size="small"
            variant="standard"
          />
        </Grid>
        <Grid item>
          <Tooltip arrow placement="top" title="Confirm">
            <IconButton
              size="'small"
              disabled={reviewCount !== "" ? false : true}
              onClick={handleReviewCountConfirm}
              sx={{ mt: 0.8 }}
            >
              <CheckIcon color={reviewCount !== "" ? "success" : "inherit"} />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  );
}
