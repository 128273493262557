import {
  Button,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AddLangDialog from "../../adminPanel/medicines/dialog/AddLangDialog";

// Icons
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import InfoTut from "../../../components/InfoTut";

export default function LangNTitleBar({
  lang,
  setLang,
  infoImg,
  setLangId,
  title,
}) {
  const { langs, activeLang } = useSelector((state) => state.misc);
  // Dialog
  const [addLangDlg, setAddLangDlg] = useState(false);

  // LocalData
  const localLangData = JSON.parse(localStorage.getItem("lang"));

  // Functions
  const handleChangeLang = (e) => {
    setLang(e.target.value);
    const L = langs.find((l) => l.langCode === e.target.value);
    setLangId(L._id);
  };

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setLang(activeLang.langCode);
      setLangId(activeLang._id);
    } else {
      if (localLangData) {
        setLang(localLangData.langCode);
        setLangId(localLangData._id);
      }
    }
  }, [activeLang]);

  return (
    <Grid
      container
      spacing={1}
      justifyContent="space-between"
      alignItems="center"
    >
      {/* Select Language */}
      <Grid item xs={12} md={2} xl={1.5}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={2} md={3} xl={2}>
            <IconButton>
              <AddCircleOutlineOutlinedIcon
                color="info"
                size="large"
                onClick={() => setAddLangDlg(true)}
              />
            </IconButton>
            <AddLangDialog
              addLangDlg={addLangDlg}
              setAddLangDlg={setAddLangDlg}
              setLang={setLang}
            />
          </Grid>
          <Grid item xs={10} md={9} xl={10}>
            <FormControl fullWidth>
              <Select
                value={lang}
                label="language"
                variant="standard"
                onChange={handleChangeLang}
                size="small"
              >
                {langs.map((x, i) => (
                  <MenuItem key={i} value={x.langCode} dense>
                    <img
                      alt={x.langCode}
                      src={`https://flagcdn.com/w20/${
                        x.langCode === "en" ? "gb" : x.langCode
                      }.png`}
                    />
                    <Button color="inherit" size="small">
                      {x.langName}
                    </Button>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={10} xl={10.5}>
        <Grid container spacing={0}>
          <Grid item sx={{ flex: 1 }}>
            <Button
              fullWidth
              variant="outlined"
              size="small"
              color="primary"
              sx={{ pointerEvents: "none" }}
            >
              {title}
            </Button>
          </Grid>
          {infoImg && (
            <Grid item>
              <InfoTut img={infoImg} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
