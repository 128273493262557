import {
  Autocomplete,
  Box,
  InputAdornment,
  TextField,
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import { countries } from "../../utils/countryList";
import LoadingButton from "@mui/lab/LoadingButton";
import PhoneInput from "react-phone-number-input";
import React, { useEffect, useState } from "react";
import "react-phone-number-input/style.css";
import "./contactUsForm.css";
// import Captcha from "../misc/Captcha";
import CaptchaTikTakToe from "../misc/CaptchaTikTakToe";
import { useDispatch, useSelector } from "react-redux";
import { contactUsFront } from "../../reduxToolkit/features/miscFront";
import EmailVerificationDialog from "../EmailVerificationDialog";
import { emailVerify } from "../../reduxToolkit/features/auth";

export default function ContactUsForm() {
  const dispatch = useDispatch();
  const [phone, setPhone] = useState();
  // const [message, setMessage] = useState("");
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    country: "",
    whatsApp: "",
    weChat: "",
    skype: "",
    fb: "",
    msg: "",
  });

  // Store
  const { webSettings } = useSelector((state) => state.webSettings);
  const { emailResponse } = useSelector((state) => state.auth);

  const [captchaDlg, setCaptchaDlg] = useState(false);

  const [emailVerified, setEmailVerified] = useState(false);
  const [countryLoading, setCountryLoading] = useState(false);
  // const [skipEmailVerify, setSkipEmailVerify] = useState(false);
  const [verifyEmailLoading, setVerifyEmailLoading] = useState(false);
  const [vCode, setVCode] = useState("");
  const [verifyDlg, setVerifyDlg] = useState(false);
  const [verifyCodeInput, setVerifyCodeInput] = useState("");

  // Count down
  const [countDownStart, setCountDownStart] = React.useState("");

  // ! Lang Id Assign
  const [langId, setLangId] = useState("");
  const { activeLangFront } = useSelector((state) => state.miscFront);
  // Set Language
  useEffect(() => {
    if (activeLangFront) setLangId(activeLangFront._id);
  }, [activeLangFront]);
  // ! Lang Id Assign

  const handleVerifyEmail = () => {
    setCountDownStart(
      new Date().getTime() + (webSettings.verificationInterval || 1) * 60000
    );
    let v = Math.floor(Math.random() * (999999 - 100000 + 1) + 100000);
    setVCode(v);
    v = v * 3875412698;
    dispatch(emailVerify({ v, email: formData.email, langId }));
    setVerifyEmailLoading(true);
  };

  // Function
  const handleSubmit = (e) => {
    e.preventDefault();
    handleVerifyEmail();
    // setCaptchaDlg(true);
  };

  // const handleCaptcha = () => {
  // };

  const contactUsFunc = () => {
    setCountryLoading(true);
    setEmailVerified(false);
    dispatch(
      contactUsFront({
        ...formData,
        phone,
        browserInfo: navigator.userAgent,
        langId,
      })
    );
    setTimeout(() => {
      setFormData({
        fullName: "",
        email: "",
        country: "",
        whatsApp: "",
        weChat: "",
        skype: "",
        fb: "",
        msg: "",
      });
      setPhone("");

      setCountryLoading(false);
    }, 1000);
  };

  useEffect(() => {
    let obj = { ...formData };
    obj.phone = phone;
    setFormData(obj);
  }, [phone]);

  // Check Verification Code
  useEffect(() => {
    setVerifyEmailLoading(true);
    if (vCode === Number(verifyCodeInput)) {
      setVerifyDlg(false);
      setEmailVerified(true);
      // setCaptchaDlg(true);
      setVerifyCodeInput("");
    }
    setVerifyEmailLoading(false);
  }, [verifyCodeInput]);

  useEffect(() => {
    if (emailVerified) contactUsFunc();
  }, [emailVerified]);

  // check Email Response
  useEffect(() => {
    if (emailResponse.message) {
      if (!emailResponse.failed) setVerifyDlg(true);
      setVerifyEmailLoading(false);
    }
  }, [emailResponse]);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate

  return (
    <Box sx={{ mx: 1 }}>
      <TextField
        label={translateFront("Full Name")}
        variant="standard"
        required
        fullWidth
        sx={{ mb: 0.5 }}
        value={formData.fullName}
        onChange={(e) => {
          let obj = { ...formData };
          obj.fullName = e.target.value;
          setFormData(obj);
        }}
      />
      <TextField
        required
        label={translateFront("Email")}
        type="email"
        variant="standard"
        fullWidth
        sx={{ mb: 1 }}
        value={formData.email}
        onChange={(e) => {
          let obj = { ...formData };
          obj.email = e.target.value;
          setFormData(obj);
        }}
      />
      {!countryLoading && (
        <Autocomplete
          id="country-select"
          options={countries}
          autoHighlight
          getOptionLabel={(option) => option.label}
          onChange={(e, newValue) => {
            let obj = { ...formData };
            obj.country = newValue.label;
            setFormData(obj);
          }}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                alt=""
              />
              {option.label}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              // required
              fullWidth
              required
              sx={{ mb: 1 }}
              label={translateFront("Choose a country")}
              variant="standard"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-country",
              }}
            />
          )}
        />
      )}
      <Typography variant="body2" color="inherit">
        {translateFront("Phone")} *
      </Typography>
      <PhoneInput
        placeholder={translateFront("Enter Phone Number")}
        international
        defaultCountry="US"
        value={phone}
        onChange={setPhone}
      />
      <Grid container justifyContent="center">
        <Grid item xs={6}>
          <TextField
            label={translateFront("WhatsApp")}
            sx={{ mb: 1 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <WhatsAppIcon sx={{ color: "#4ccf7a" }} />
                </InputAdornment>
              ),
            }}
            variant="standard"
            value={formData.whatsApp}
            onChange={(e) => {
              let obj = { ...formData };
              obj.whatsApp = e.target.value;
              setFormData(obj);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={translateFront("WeChat")}
            sx={{ mb: 1 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    src="https://img.icons8.com/doodle/48/000000/weixing.png"
                    alt="WeChat"
                    style={{ height: "1.5rem" }}
                  />
                </InputAdornment>
              ),
            }}
            variant="standard"
            value={formData.weChat}
            onChange={(e) => {
              let obj = { ...formData };
              obj.weChat = e.target.value;
              setFormData(obj);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={translateFront("Skype")}
            sx={{ mb: 1 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    src="https://img.icons8.com/color/48/000000/skype--v1.png"
                    alt="Skype"
                    style={{ height: "1.5rem" }}
                  />
                </InputAdornment>
              ),
            }}
            variant="standard"
            value={formData.skype}
            onChange={(e) => {
              let obj = { ...formData };
              obj.skype = e.target.value;
              setFormData(obj);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={translateFront("Facebook")}
            sx={{ mb: 1 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FacebookIcon sx={{ color: "navy" }} />
                </InputAdornment>
              ),
            }}
            variant="standard"
            value={formData.fb}
            onChange={(e) => {
              let obj = { ...formData };
              obj.fb = e.target.value;
              setFormData(obj);
            }}
          />
        </Grid>
      </Grid>
      <TextField
        required
        label={translateFront("Message")}
        multiline
        minRows={5}
        type="text"
        variant="standard"
        fullWidth
        sx={{ mb: 1 }}
        value={formData.msg}
        onChange={(e) => {
          let obj = { ...formData };
          obj.msg = e.target.value;
          setFormData(obj);
        }}
        helperText={
          <Typography align="right" variant="body2" color="primary">
            {formData.msg.length}/2000
          </Typography>
        }
      />
      <LoadingButton
        variant="contained"
        size="small"
        fullWidth
        color="success"
        // type="submit"
        onClick={handleSubmit}
        // loading={false}
        loading={verifyEmailLoading}
        disabled={
          !(
            formData.fullName !== "" &&
            formData.email !== "" &&
            formData.country !== "" &&
            formData.msg !== "" &&
            phone !== ""
          )
        }
        startIcon={
          <img
            src="https://img.icons8.com/external-smashingstocks-glyph-smashing-stocks/66/000000/external-inquiry-tech-support-smashingstocks-glyph-smashing-stocks.png"
            alt="Inquiry"
            style={{ height: "1.5rem" }}
          />
        }
      >
        {translateFront("Ask Away")}
      </LoadingButton>

      {verifyDlg && (
        <EmailVerificationDialog
          verifyDlg={verifyDlg}
          setVerifyDlg={setVerifyDlg}
          verifyCodeInput={verifyCodeInput}
          setVerifyCodeInput={setVerifyCodeInput}
          countDownStart={countDownStart}
          setCountDownStart={setCountDownStart}
          logic={handleVerifyEmail}
        />
      )}

      {/* <Dialog
        open={captchaDlg}
        onClose={() => setCaptchaDlg(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <CaptchaTikTakToe
            setCaptchaDlg={setCaptchaDlg}
            loginFunc={contactUsFunc}
          />
        </DialogContent>
      </Dialog> */}
    </Box>
  );
}
