import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../config";

// Login Admin
export const loginAdmin = createAsyncThunk("admin/login", async (data) => {
  const res = await axiosInstance.post("/api/admin/login", data);
  return res.data;
});

// Delete Admin
export const deleteAdmin = createAsyncThunk("admin/delete", async (data) => {
  const { _id, ...others } = data;
  const res = await axiosInstance.put(`/api/admin/remove/${_id}`, others);
  return res.data;
});

// Delete All Admin
export const deleteAllAdmin = createAsyncThunk(
  "admin/deleteAll",
  async (data) => {
    const res = await axiosInstance.put("/api/admin/remove-all/", data);
    return res.data;
  }
);

// get admins
export const getAdmins = createAsyncThunk("admin/get", async () => {
  const res = await axiosInstance.get("/api/admin/");
  return res.data;
});
// get single Admin
export const getSingleAdmin = createAsyncThunk(
  "admin/single/get",
  async (id) => {
    const res = await axiosInstance.get(`/api/admin/${id}`);
    return res.data;
  }
);

// check password
export const checkPassword = createAsyncThunk(
  "admin/check-pass",
  async (data) => {
    const res = await axiosInstance.put(
      `/api/admin/check-pass/${data.id}`,
      data
    );
    return res.data;
  }
);

// upload Admin Image
export const uploadAdminPic = createAsyncThunk(
  "admin/uploadAdminPic",
  async (data) => {
    const res = await axiosInstance.post("/api/multer/adminProfilePic/", data);
    return res.data;
  }
);

// create Admin
export const createAdmin = createAsyncThunk("admin/create", async (data) => {
  const res = await axiosInstance.post("/api/admin/create", data);
  return res.data;
});

// update Admin
export const updateAdmin = createAsyncThunk("admin/update", async (data) => {
  const { _id, ...others } = data;
  const res = await axiosInstance.put(`/api/admin/${data._id}`, others);
  return res.data;
});

// initial State
const initialState = {
  pending: false,
  loginResponse: {},
  admins: [],
  singleAdmin: {},
  checkPasswordResponse: {},
  createAdminResponse: {},
  updateAdminResponse: {},
  deleteAdminResponse: {},
  adminImgUploadResponse: {},
  snackNotiAdmin: {},
  error: false,
};

// Slice
export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    logout: () => initialState,
    resetSnkAdmin(state) {
      state.snackNotiAdmin = {};
    },
  },
  extraReducers: {
    [loginAdmin.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [loginAdmin.fulfilled]: (state, action) => {
      state.pending = false;
      state.loginResponse = action.payload;
      state.snackNotiAdmin = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [loginAdmin.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [getAdmins.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getAdmins.fulfilled]: (state, action) => {
      state.pending = false;
      state.admins = action.payload;
    },
    [getAdmins.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [getSingleAdmin.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getSingleAdmin.fulfilled]: (state, action) => {
      state.pending = false;
      state.singleAdmin = action.payload;
    },
    [getSingleAdmin.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [checkPassword.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [checkPassword.fulfilled]: (state, action) => {
      state.pending = false;
      state.checkPasswordResponse = action.payload;
      state.snackNotiAdmin = {
        message: "Password Matched",
        severity: "success",
      };
    },
    [checkPassword.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [uploadAdminPic.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [uploadAdminPic.fulfilled]: (state, action) => {
      state.pending = false;
      state.adminImgUploadResponse = action.payload;
    },
    [uploadAdminPic.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [createAdmin.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [createAdmin.fulfilled]: (state, action) => {
      state.pending = false;
      state.createAdminResponse = action.payload;
      state.snackNotiAdmin = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [createAdmin.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [deleteAdmin.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [deleteAdmin.fulfilled]: (state, action) => {
      state.pending = false;
      state.deleteAdminResponse = action.payload;
      state.snackNotiAdmin = {
        message: "Admin Deleted Successfully",
        severity: "error",
      };
    },
    [deleteAdmin.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [deleteAllAdmin.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [deleteAllAdmin.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiAdmin = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [deleteAllAdmin.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [updateAdmin.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [updateAdmin.fulfilled]: (state, action) => {
      state.pending = false;
      state.updateAdminResponse = action.payload;
      state.snackNotiAdmin = {
        message: "Admin updated Successfully",
        severity: "warning",
      };
    },
    [updateAdmin.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
  },
});

export const { logout, resetSnkAdmin } = adminSlice.actions;
export default adminSlice.reducer;
