import {
  Alert,
  Autocomplete,
  Box,
  Button,
  createFilterOptions,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Slide,
  TextField,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// Icon
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import AddLangDialog from "../dialog/AddLangDialog";
import AddDosageDialog from "../dialog/AddDosageDialog";
import {
  getDosages,
  removeDosage,
} from "../../../../reduxToolkit/features/adminPanel/medicine";
import LoadingBox from "../../../../components/misc/Loading";
import { getNotification } from "../../../../reduxToolkit/features/adminPanel/notification";
import LoadingButton from "@mui/lab/LoadingButton";
import EditDosageDialog from "../dialog/EditDosageDialog";

// Limit options to show in autocomplete
const OPTIONS_LIMIT = 6;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) => {
  return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
};

export default function ManageDosageForm() {
  // ! use Ref
  const dosageButtonRef = useRef();

  const dispatch = useDispatch();
  // ! Use State

  const [lang, setLang] = useState("");
  const [dosageLoading, setDosageLoading] = useState(false);
  const [dosageList, setDosageList] = useState([]);
  const [dosageListEn, setDosageListEn] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [searchingList, setSearchingList] = useState([]);

  //   CLick events
  const [clickedDosageBuffer, setClickedDosageBuffer] = useState([]);

  //   Dialog
  const [addLangDlg, setAddLangDlg] = useState(false);

  const [addDosageDlg, setAddDosageDlg] = useState(false);

  const [confirmRemoveDlg, setConfirmRemoveDlg] = useState(false);
  const [removeBuffer, setRemoveBuffer] = useState({});
  const [removeLoading, setRemoveLoading] = useState();

  const [editDosageDlg, setEditDosageDlg] = useState(false);
  const [editBuffer, setEditBuffer] = useState({});

  // LocalData
  const localLangData = JSON.parse(localStorage.getItem("lang"));
  const localAdminData = JSON.parse(localStorage.getItem("adminLoginInfo"));

  // store
  const { langs, activeLang } = useSelector((state) => state.misc);
  const { dosages, removeDosageResponse } = useSelector(
    (state) => state.medicine
  );

  // ! Functions
  const handleChangeLang = (e) => {
    setLang(e.target.value);
    setSearchingList([]);
    setClickedDosageBuffer([]);
  };

  const handleSearch = (e, newValue) => {
    if (newValue.length > 0) {
      var searchArr = [...newValue];
      const unique = [
        ...new Map(
          searchArr.map((item) => [item["dosageName"], item])
        ).values(),
      ];
      setSearchingList(unique);
    } else {
      setSearchingList([]);
    }
  };

  const handleDosageManage = (index) => {
    if (clickedDosageBuffer.includes(index)) {
      setClickedDosageBuffer(
        [...clickedDosageBuffer].filter((d) => d !== index)
      );
    } else setClickedDosageBuffer([...clickedDosageBuffer, index]);
  };

  // Remove
  const handleDosageRemove = (index) => {
    setConfirmRemoveDlg(true);
    const D = [...dosageList][index];
    if (D) setRemoveBuffer(D);
  };

  const handleDosageRemoveConfirm = () => {
    setRemoveLoading(true);
    dispatch(
      removeDosage({
        lang,
        fixedDosageId: removeBuffer.fixedDosageId,
        dosageName: removeBuffer.dosageName,
        creatorId: localAdminData._id,
      })
    );
  };

  // Edit
  const handleDosageEdit = (obj) => {
    setEditDosageDlg(true);
    setEditBuffer(obj);
  };

  // Get English Variant
  const getEngVar = (obj) => {
    if (dosageListEn.length > 0) {
      const D = dosageListEn.find(
        (d) => d.fixedDosageId === obj.dosageDetectorId
      );
      if (D) return D.dosageName;
    }
    return "";
  };

  // ! useEffect
  useEffect(() => {
    dispatch(getDosages());
  }, []);

  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setLang(activeLang.langCode);
    } else localLangData && setLang(localLangData.langCode);
  }, [activeLang]);

  useEffect(() => {
    setDosageLoading(true);
    if (lang !== "" && dosages && dosages.length > 0) {
      const D = dosages.find((d) => d.lang === lang);
      if (D) {
        setDosageList(D.data);
        let DSearch = D.data.map((x) => ({ ...x, searchName: x.dosageName }));
        if (lang !== "en") {
          const DEn = dosages.find((d) => d.lang === "en");
          if (DEn) {
            setDosageListEn(DEn.data);
            const arr = [];
            DSearch.map((d) =>
              DEn.data.map((x) => {
                if (d.dosageDetectorId === x.fixedDosageId)
                  arr.push({ ...d, searchName: x.dosageName });
                return 0;
              })
            );
            var searchArr = [...arr, ...DSearch];
            const unique = [
              ...new Map(
                searchArr.map((item) => [item["searchName"], item])
              ).values(),
            ];
            setSearchList(unique);
          }
        } else setSearchList(D.data);
      } else setDosageList([]);
    }
    setTimeout(() => {
      setDosageLoading(false);
    }, 1000);
  }, [dosages, lang]);

  // Check remove response
  useEffect(() => {
    if (removeDosageResponse.message) {
      if (!removeDosageResponse.failed) {
        dispatch(getDosages());
        dispatch(getNotification());
      }
      setConfirmRemoveDlg(false);
      setRemoveBuffer({});
      setRemoveLoading(false);
    }
  }, [removeDosageResponse]);

  // ! translate
  const [siteLang, setSiteLang] = useState("");
  // Store
  const [langSettings1, setLangSettings1] = useState([]);
  const [langSettings2, setLangSettings2] = useState([]);
  const title1 = "Manage Dosage Form";
  const title2 = "Misc Words";

  useEffect(() => {
    if (langs && langs.length > 0 && siteLang !== "") {
      const L = langs.find((l) => l.langCode === siteLang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      const A = arr.find((l) => l._title === title1);
      if (A) setLangSettings1(A.data);
      const B = arr.find((l) => l._title === title2);
      if (B) setLangSettings2(B.data);
    }
  }, [siteLang, langs]);

  const translate1 = (String) => translation(String, title1, 1);
  const translate2 = (String) => translation(String, title2, 2);

  const translation = (String, title, index) => {
    let translation = "";
    let settings = [];
    if (index === 1) {
      settings = langSettings1;
    } else if (index === 2) {
      settings = langSettings2;
    }
    const T = settings.find((ls) => ls.title === String);
    if (T && T.value) translation = T.value;
    else {
      let arr = [];
      if (langs && langs.length > 0 && siteLang !== "") {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.settings && LEn.settings.length > 0) arr = LEn.settings;
        const S = arr.find((l) => l._title === title);
        if (S) {
          const T = S.data.find((ls) => ls.title === String);
          if (T && T.value) translation = T.value;
        }
      }
    }
    return translation;
  };

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setSiteLang(activeLang.langCode);
    } else {
      if (localLangData) {
        setSiteLang(localLangData.langCode);
      }
    }
  }, [activeLang]);
  // ! translate

  return (
    <>
      {langs.length > 0 && (
        <>
          <Grid
            container
            spacing={1}
            justifyContent="space-between"
            alignItems="center"
          >
            {/* Select Language */}
            <Grid item xs={12} md={4}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={2} sm={1} md={1.5}>
                  <IconButton>
                    <AddCircleOutlineOutlinedIcon
                      color="info"
                      size="large"
                      onClick={() => setAddLangDlg(true)}
                    />
                  </IconButton>
                  <AddLangDialog
                    addLangDlg={addLangDlg}
                    setAddLangDlg={setAddLangDlg}
                    setLang={setLang}
                  />
                </Grid>
                <Grid item xs={10} sm={4} md={5}>
                  <FormControl fullWidth>
                    <Select
                      value={lang}
                      label="language"
                      variant="standard"
                      onChange={handleChangeLang}
                      size="small"
                    >
                      {langs.map((x, i) => (
                        <MenuItem key={i} value={x.langCode} dense>
                          <img
                            alt={x.langCode}
                            src={`https://flagcdn.com/w20/${
                              x.langCode === "en" ? "gb" : x.langCode
                            }.png`}
                          />
                          <Button color="inherit" size="small">
                            {x.langName}
                          </Button>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {/* Add Dosage Form */}
                <Grid item xs={12} sm={7} md={4.5}>
                  <Button
                    variant="contained"
                    size="small"
                    color="error"
                    fullWidth
                    onClick={() => setAddDosageDlg(true)}
                  >
                    {translate1("Add Dosage")}
                  </Button>
                  {/* Add Dosage Dialog */}
                  {addDosageDlg && (
                    <AddDosageDialog
                      addDosageDlg={addDosageDlg}
                      setAddDosageDlg={setAddDosageDlg}
                      lang={lang}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            {/* Search */}
            <Grid item xs={12} md={8} sx={{ mt: { xs: 0, sm: -2 } }}>
              <Autocomplete
                multiple
                options={searchList}
                onChange={(e, newValue) => handleSearch(e, newValue)}
                filterOptions={filterOptions}
                getOptionLabel={(option) => option.searchName}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option.searchName}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={translate1("Search") + "..."}
                    variant="standard"
                    size="small"
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
          {/* Medicine List */}
          <Button
            size="small"
            variant="contained"
            fullWidth
            sx={{ my: 1, pointerEvents: "none" }}
          >
            {translate1("Dosage Forms")}({dosageList.length})
          </Button>
          {dosageLoading ? (
            <LoadingBox w={"100%"} />
          ) : dosageList.length < 1 ? (
            <Alert
              severity="warning"
              sx={{ display: "flex", justifyContent: "center" }}
            >
              {translate1("No Data Available") + "..."}
            </Alert>
          ) : (
            <>
              <Grid container spacing={2}>
                {(searchingList.length > 0 ? searchingList : dosageList).map(
                  (x, i) => (
                    <Grid item xs={12} sm={6} md={4} xl={3}>
                      <Grid container spacing={0} alignItems="center">
                        <Grid
                          item
                          xs={12}
                          md={clickedDosageBuffer.includes(i) ? 9.5 : 12}
                          xl={clickedDosageBuffer.includes(i) ? 10 : 12}
                        >
                          <Button
                            variant="outlined"
                            color={i % 2 === 0 ? "warning" : "secondary"}
                            endIcon={<MoreVertIcon />}
                            onClick={() => handleDosageManage(i)}
                            ref={dosageButtonRef}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            fullWidth
                          >
                            {x.dosageName}{" "}
                            {lang !== "en" && "( " + getEngVar(x) + " )"}
                          </Button>
                        </Grid>
                        {clickedDosageBuffer.includes(i) && (
                          <Grid item xs={12} md={2.5} xl={2}>
                            <Grid
                              container
                              spacing={0}
                              sx={{
                                border:
                                  i % 2 === 0
                                    ? ".1rem solid orange"
                                    : ".1rem solid violet",
                                borderRadius: ".25rem",
                              }}
                              justifyContent="center"
                            >
                              <Grid item>
                                <Slide
                                  direction="right"
                                  in={clickedDosageBuffer.includes(i)}
                                  mountOnEnter
                                  unmountOnExit
                                  container={dosageButtonRef.current}
                                >
                                  <Tooltip
                                    arrow
                                    placement="left"
                                    title={translate1("Edit")}
                                  >
                                    <IconButton
                                      size="small"
                                      onClick={() => handleDosageEdit(x)}
                                    >
                                      <ModeEditIcon color="primary" />
                                    </IconButton>
                                  </Tooltip>
                                </Slide>
                              </Grid>
                              <Grid item>
                                <Slide
                                  direction="right"
                                  in={clickedDosageBuffer.includes(i)}
                                  mountOnEnter
                                  unmountOnExit
                                  container={dosageButtonRef.current}
                                >
                                  <Tooltip
                                    arrow
                                    placement="right"
                                    title={translate1("Delete")}
                                  >
                                    <IconButton
                                      size="small"
                                      onClick={() => handleDosageRemove(i)}
                                    >
                                      <DeleteIcon color="error" />
                                    </IconButton>
                                  </Tooltip>
                                </Slide>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  )
                )}
              </Grid>
            </>
          )}
        </>
      )}
      {/* Remove Dosage */}
      <Dialog
        open={confirmRemoveDlg}
        onClose={() => {
          setConfirmRemoveDlg(false);
          setRemoveBuffer({});
        }}
      >
        <DialogTitle>
          {translate2("Do you really want to delete")} "
          <span style={{ color: "red" }}>{removeBuffer.dosageName}</span>"
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setConfirmRemoveDlg(false);
              setRemoveBuffer({});
            }}
            color="primary"
          >
            {translate2("Close")}
          </Button>
          <LoadingButton
            onClick={handleDosageRemoveConfirm}
            color="error"
            loading={removeLoading}
          >
            {translate2("Confirm")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {/* Edit Dosage */}
      {editDosageDlg && (
        <EditDosageDialog
          editDosageDlg={editDosageDlg}
          setEditDosageDlg={setEditDosageDlg}
          editBuffer={editBuffer}
          setEditBuffer={setEditBuffer}
          lang={lang}
        />
      )}
    </>
  );
}
