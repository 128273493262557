import {
  Alert,
  Box,
  Checkbox,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Slide,
  Tooltip,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingBox from "../misc/Loading";

// Media Query
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getDosages } from "../../reduxToolkit/features/adminPanel/medicine";

export default function CategoryList({
  lang,
  catList,
  companyList,
  dosageList,
  medList,
  setMedList,
  filteredMedList,
  setFilteredMedList,

  selectedCategory,
  setSelectedCategory,
  selectedSubCat,
  setSelectedSubCat,
  selectedDFList,
  setSelectedDFList,
  selectedBrandList,
  setSelectedBrandList,

  filterTags,
  setFilterTags,
}) {
  // Responsive
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  // Use State

  const [catOpenList, setCatOpenList] = useState([]);
  const [catOpenListLoading, setCatOpenListLoading] = useState(false);

  // const [selectedCategory, setSelectedCategory] = useState([]);
  // const [selectedSubCat, setSelectedSubCat] = useState([]);

  // const [dosageList, setDosageList] = useState([]);

  // const [selectedDFList, setSelectedDFList] = useState([]);
  // const [selectedBrandList, setSelectedBrandList] = useState([]);

  const [selectAll, setSelectAll] = useState(false);
  const [allCatSelected, setAllCatSelected] = useState(false);
  const [allDosageSelected, setAllDosageSelected] = useState(false);
  const [allCompanySelected, setAllCompanySelected] = useState(false);

  // const [filterTags, setFilterTags] = useState({
  //   subcategory: [],
  //   dosage: [],
  //   brand: [],
  // });

  // Store
  const { medVariants, dosages } = useSelector((state) => state.medicine);
  const { webSettings } = useSelector((state) => state.webSettings);

  //   Functions
  const handleCatExpand = (index) => {
    setCatOpenListLoading(true);
    if (catOpenList.includes(index))
      setCatOpenList([...catOpenList].filter((c) => c !== index));
    else setCatOpenList([...catOpenList, index]);
    setTimeout(() => setCatOpenListLoading(false), 10);
  };

  const handleCategory = (obj) => {
    let filterObj = { ...filterTags };
    obj.subCatList.forEach((sc) => {
      if (filterObj.subcategory.includes(sc._id)) {
        filterObj.subcategory = filterObj.subcategory.filter(
          (o) => o !== sc._id
        );
      } else {
        filterObj.subcategory.push(sc._id);
      }
    });
    setFilterTags(filterObj);

    if (selectedCategory.includes(obj._id)) {
      setSelectedCategory([...selectedCategory].filter((c) => c !== obj._id));
      setSelectedSubCat([]);
      setSelectAll(false);
    } else {
      setSelectedCategory([...selectedCategory, obj._id]);
      let arr = [...selectedSubCat];
      obj.subCatList.map((x) => arr.push(x._id));
      setSelectedSubCat(arr);
    }
  };

  const handleSubCategory = (subCatId, catObj) => {
    let obj = { ...filterTags };
    if (obj.subcategory.includes(subCatId)) {
      obj.subcategory = obj.subcategory.filter((o) => o !== subCatId);
    } else {
      obj.subcategory.push(subCatId);
    }
    setFilterTags(obj);

    let arr = [...selectedSubCat];
    if (arr.includes(subCatId)) {
      arr = arr.filter((sc) => sc !== subCatId);
      setSelectAll(false);
    } else arr.push(subCatId);
    setSelectedSubCat(arr);
    let counter = 0;
    catObj.subCatList.map((x) => {
      let A = arr.find((a) => a === x._id);
      if (!A) counter++;
      return 0;
    });
    if (counter > 0)
      setSelectedCategory(
        [...selectedCategory].filter((c) => c !== catObj._id)
      );
    else setSelectedCategory([...selectedCategory, catObj._id]);
  };

  const handleAllCategory = () => {
    setFilterTags({
      subcategory: [],
      dosage: [],
      brand: [],
    });

    if (selectAll) {
      setSelectedDFList([]);
      setSelectedBrandList([]);
      setSelectedCategory([]);
      setSelectedSubCat([]);
    } else {
      // Category
      const C = catList.map((c) => c._id);
      setSelectedCategory(C);
      // Sub Category
      let Sc = [];
      catList.map((c) => (Sc = Sc.concat(c.subCatList)));
      if (Sc.length > 0) {
        let arr = [];
        Sc.map((sc) => arr.push(sc._id));
        setSelectedSubCat(arr);
      }
      // Dosage
      const D = dosageList.map((d) => d._id);
      setSelectedDFList(D);
      // Brand
      const B = companyList.map((b) => b._id);
      setSelectedBrandList(B);
    }
    setSelectAll(!selectAll);
  };

  const getVariantDetails = (id) => {
    const Mv = medVariants.find((v) => v.fixedVariantId === id);
    if (Mv) return Mv;
    else return {};
  };

  const getSubCatDetails = (id) => {
    let Sc = {};
    catList.forEach((c) => {
      if (c.subCatList.length > 0) {
        const a = c.subCatList.find((sc) => sc._id === id);
        if (a) {
          Sc = a;
        }
      }
    });
    return Sc;
  };

  const getDosageInfo = (dosageId) => {
    let dosageName = "";

    if (dosages && dosages.length > 0) {
      const DList = dosages.find((d) => d.lang === lang);
      const D = DList.data.find(
        (d) =>
          (lang !== "en" ? d.dosageDetectorId : d.fixedDosageId) === dosageId
      );
      if (D && D.dosageName) dosageName = D.dosageName;
    }

    return dosageName;
  };

  const handleDF = (id, dosageForm) => {
    let obj = { ...filterTags };
    if (obj.dosage.includes(dosageForm)) {
      obj.dosage = obj.dosage.filter((o) => o !== dosageForm);
    } else {
      obj.dosage.push(dosageForm);
    }
    setFilterTags(obj);

    if (selectedDFList.includes(id)) {
      setSelectedDFList([...selectedDFList].filter((df) => df !== id));
      setSelectAll(false);
    } else setSelectedDFList([...selectedDFList, id]);
  };

  const handleBrand = (id, companyId) => {
    let obj = { ...filterTags };
    if (obj.brand.includes(companyId)) {
      obj.brand = obj.brand.filter((o) => o !== companyId);
    } else {
      obj.brand.push(companyId);
    }
    setFilterTags(obj);

    if (selectedBrandList.includes(id)) {
      setSelectedBrandList([...selectedBrandList].filter((b) => b !== id));
      setSelectAll(false);
    } else setSelectedBrandList([...selectedBrandList, id]);
  };

  // // get Dosage List
  // useEffect(() => {
  //   let dosageArr = [];
  //   if (medVariants.length > 0 && medList.length > 0) {
  //     medList.map((x) => {
  //       if (x.variants.length > 0) {
  //         x.variants.map((y) => {
  //           let M = medVariants.find((m) => m.fixedVariantId === y.variantId);
  //           if (M) dosageArr.push(M);
  //           return 0;
  //         });
  //       }
  //       return 0;
  //     });
  //   }
  //   if (dosageArr.length > 0) {
  //     const unique = [
  //       ...new Map(
  //         dosageArr.map((item) => [item["dosageForm"], item])
  //       ).values(),
  //     ];
  //     setDosageList(unique);
  //   } else setDosageList([]);
  // }, [medList, medVariants]);

  // All Selection Determiner
  useEffect(() => {
    if (selectedBrandList.length === companyList.length)
      setAllCompanySelected(true);
    else setAllCompanySelected(false);
    if (selectedDFList.length === dosageList.length) setAllDosageSelected(true);
    else setAllDosageSelected(false);
    if (selectedCategory.length === catList.length) setAllCatSelected(true);
    else setAllCatSelected(false);
  }, [
    catList.length,
    companyList.length,
    dosageList.length,
    selectedBrandList.length,
    selectedCategory.length,
    selectedDFList.length,
  ]);

  // All Selection handler
  useEffect(() => {
    if (allCompanySelected && allDosageSelected && allCatSelected)
      setSelectAll(true);
  }, [allCatSelected, allCompanySelected, allDosageSelected]);

  // Filter By Selection
  useEffect(() => {
    let arr = [];
    if (
      filterTags.dosage.length > 0 ||
      filterTags.brand.length > 0 ||
      filterTags.subcategory.length > 0
    ) {
      // By Dosage
      filterTags.dosage.forEach((f) => {
        if (medList.length > 0) {
          medList.forEach((x) => {
            if (x.variants.length > 0) {
              const X = x.variants.find(
                (v) => getVariantDetails(v.variantId).dosageForm === f
              );
              if (X) arr.push(x);
            }
          });
        }
      });
      // By Brand
      filterTags.brand.forEach((b) => {
        if (medList.length > 0) {
          const M = medList.filter((x) => x.companyId === b);
          if (M && M.length > 0) {
            M.forEach((m) => arr.push(m));
          }
        }
      });
      // By Sub Category
      filterTags.subcategory.forEach((f) => {
        if (medList.length > 0) {
          medList.forEach((x) => {
            if (x.subCats.length > 0) {
              const Sc = x.subCats.find(
                (sc) =>
                  sc.subCatId ===
                  (lang !== "en"
                    ? getSubCatDetails(f).subCatDetectorId
                    : getSubCatDetails(f).fixedSubCatId)
              );
              if (Sc) arr.push(x);
            }
          });
        }
      });
      if (arr.length > 0) {
        setFilteredMedList(arr);
      } else setFilteredMedList(["null"]);
    } else {
      setFilteredMedList([]);
    }
  }, [filterTags]);

  // initial use Effect
  const dispatch = useDispatch();
  useEffect(() => {
    if (!(dosages && dosages.length > 0)) dispatch(getDosages());
  }, []);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate

  return (
    <Slide direction="right" in={true} mountOnEnter unmountOnExit>
      <Box>
        <List dense>
          <ListItem disablePadding dense>
            <ListItemButton size="small" onClick={handleAllCategory}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selectAll}
                  tabIndex={-1}
                  color="secondary"
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary={translateFront("All")} />
            </ListItemButton>
          </ListItem>
        </List>

        {/* Category List */}
        {/* <Box
        sx={
          mdUp && {
            maxHeight: { lg: "45vh", xl: "30vh" },
            overflow: "auto",
          }
        }
      > */}
        <List
          dense
          subheader={
            <ListSubheader>{translateFront("Categories")}</ListSubheader>
          }
        >
          {catList.length < 1 ? (
            <Alert severity="warning">
              {translateFront("No Data Available")}
            </Alert>
          ) : (
            catList.map((x, i) => (
              <Fragment key={i}>
                <ListItem
                  disablePadding
                  dense
                  sx={{
                    background:
                      catOpenList.includes(i) &&
                      (webSettings.siteColorTheme || "lightblue"),
                  }}
                >
                  <ListItemButton
                    size="small"
                    onClick={() => handleCategory(x)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={selectedCategory.includes(x._id)}
                        tabIndex={-1}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemText primary={x.catName} />
                  </ListItemButton>
                  {x.subCatList.length > 0 && (
                    <Tooltip
                      arrow
                      placement="top"
                      title={
                        catOpenList.includes(i)
                          ? translateFront("Show Less")
                          : translateFront("Show More")
                      }
                    >
                      <IconButton onClick={() => handleCatExpand(i)}>
                        {catOpenList.includes(i) ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </IconButton>
                    </Tooltip>
                  )}
                </ListItem>
                {catOpenList.includes(i) && (
                  <Collapse
                    in={catOpenList.includes(i) && !catOpenListLoading}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding dense>
                      {x.subCatList.length > 0 &&
                        x.subCatList.map((y, j) => (
                          <ListItemButton
                            sx={{ pl: 4 }}
                            onClick={() => handleSubCategory(y._id, x)}
                            key={j}
                          >
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                checked={selectedSubCat.includes(y._id)}
                                tabIndex={-1}
                                disableRipple
                              />
                            </ListItemIcon>
                            <ListItemText primary={y.subCatName} />
                          </ListItemButton>
                        ))}
                    </List>
                  </Collapse>
                )}
              </Fragment>
            ))
          )}
        </List>
        {/* </Box> */}
        {/* Dosage Form */}
        {/* <Box
        sx={
          mdUp && {
            maxHeight: { lg: "45vh", xl: "30vh" },
            overflow: "auto",
          }
        }
      > */}
        <List
          dense
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              {translateFront("Dosage Form")}
            </ListSubheader>
          }
        >
          {dosageList.length < 1 ? (
            <Alert severity="warning">
              {translateFront("No Data Available")}
            </Alert>
          ) : (
            dosageList.map((x, i) => (
              <ListItem disablePadding dense key={i}>
                <ListItemButton
                  size="small"
                  onClick={() => handleDF(x._id, x.dosageForm)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={selectedDFList.includes(x._id)}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText primary={getDosageInfo(x.dosageForm)} />
                </ListItemButton>
              </ListItem>
            ))
          )}
        </List>
        {/* </Box> */}
        {/* Brand */}
        <Box
          sx={
            mdUp && {
              maxHeight: { lg: "45vh", xl: "30vh" },
              overflow: "auto",
            }
          }
        >
          <List
            dense
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                {translateFront("Brand")}
              </ListSubheader>
            }
          >
            {companyList.length < 1 ? (
              <Alert severity="warning">
                {translateFront("No Data Available")}
              </Alert>
            ) : (
              companyList.map((x, i) => (
                <ListItem disablePadding dense key={i}>
                  <ListItemButton
                    size="small"
                    onClick={() =>
                      handleBrand(
                        x._id,
                        lang !== "en" ? x.companyDetectorId : x.fixedCompanyId
                      )
                    }
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={selectedBrandList.includes(x._id)}
                        tabIndex={-1}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemText primary={x.companyName} />
                  </ListItemButton>
                </ListItem>
              ))
            )}
          </List>
        </Box>
      </Box>
    </Slide>
  );
}
