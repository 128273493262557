import React from "react";
import { useDispatch } from "react-redux";
import {
  createNoti,
  uploadTempPic,
} from "../../../../../reduxToolkit/features/adminPanel/misc";
import { Grid } from "@mui/material";

// Ck Editor
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { PF } from "../../../../../config";

function ConsigneeAdditionalInfo({ consigneeInfoObj, setConsigneeInfoObj }) {
  // ! CK Editor Image Upload

  const dispatch = useDispatch();
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          let tempName = Math.floor(Math.random() * (10000 - 0) + 0).toString();
          loader.file
            .then((file) => {
              const data = new FormData();
              if (file) {
                tempName = tempName + "." + file.name.split(".").pop();
                data.append("name", tempName);
                data.append("tempImg", file);
                dispatch(uploadTempPic(data));
                dispatch(
                  createNoti({
                    message: "Pls click on the image Confirm Image Upload",
                    severity: "warning",
                  })
                );
              }
            })
            .then(() => {
              resolve({
                default: `${PF}temp/${tempName}`,
              });
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }
  // ! CK Editor Image Upload

  const handleAdditionalConsigneeInfo = (e, editor) => {
    let obj = { ...consigneeInfoObj };
    const data = editor.getData();
    obj.additionalConsigneeInfo = data;
    setConsigneeInfoObj(obj);
  };

  return (
    <Grid item xs={12}>
      <CKEditor
        editor={Editor}
        data={consigneeInfoObj.additionalConsigneeInfo}
        config={{
          extraPlugins: [uploadPlugin],
        }}
        onBlur={(e, editor) => handleAdditionalConsigneeInfo(e, editor)}
      />
    </Grid>
  );
}

export default ConsigneeAdditionalInfo;
