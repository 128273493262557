import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../config";

const localWishlistData = () =>
  JSON.parse(localStorage.getItem("localWishlistData"));

const localCustomerData = () => JSON.parse(localStorage.getItem("loginInfo"));

//   Shift local Wishlist data to customerdb
export const wishlistShiftLocalToCustomer = createAsyncThunk(
  "wishlist/shiftLocalToCustomer",
  async (customerId) => {
    let arr = [];
    if (localWishlistData()) {
      localWishlistData().forEach((x) => {
        let obj = { medId: x, customerId };
        arr.push(obj);
      });
    }
    const res = await axiosInstance.post("/api/wishlist/merge-with-local", arr);
    localStorage.removeItem("localWishlistData");
    return res.data;
  }
);

// Add to wishlist
export const addToWishlist = createAsyncThunk(
  "wishlist/addToWishlist",
  async (data) => {
    if (data.customerId) {
      const res = await axiosInstance.post(
        "/api/wishlist/add-to-wishlist",
        data
      );
      return res.data;
    } else {
      let arr = [];
      if (!localWishlistData()) {
        arr.push(data.medId);
      } else {
        arr = localWishlistData();
        arr.push(data.medId);
      }
      localStorage.setItem("localWishlistData", JSON.stringify(arr));
      return {
        message: "Added to wishlist",
        severity: "success",
        data: arr,
      };
    }
  }
);

export const removeFromWishlist = createAsyncThunk(
  "wishlist/removeFromWishlist",
  async (data) => {
    if (data.customerId) {
      const res = await axiosInstance.post(
        "/api/wishlist/remove-from-wishlist",
        data
      );
      return res.data;
    } else {
      let arr = [...localWishlistData()];
      if (localWishlistData()) {
        arr = arr.filter((a) => a !== data.medId);
      }
      localStorage.setItem("localWishlistData", JSON.stringify(arr));
      return {
        message: "Removed from wishlist",
        severity: "error",
        data: arr,
      };
    }
  }
);

export const getWishlist = createAsyncThunk(
  "wishlist/getWishlist",
  async () => {
    if (localCustomerData() && localCustomerData()._id) {
      const res = await axiosInstance.get(
        `/api/wishlist/get-wishlist-from-db/${localCustomerData()._id}`
      );
      return {
        message: "Fetched wishlist",
        severity: "success",
        data: res.data,
      };
    } else {
      let arr = [];
      if (localWishlistData()) {
        arr = localWishlistData();
      }
      return {
        message: "Fetched wishlist",
        severity: "success",
        data: [...arr],
      };
    }
  }
);

export const clearWishlist = createAsyncThunk(
  "wishlist/getWishlist",
  async () => {
    if (localCustomerData() && localCustomerData()._id) {
      const res = await axiosInstance.get(
        `/api/wishlist/clear-wishlist-from-db/${localCustomerData()._id}`
      );
      return {
        message: "Fetched wishlist",
        severity: "success",
        data: res.data,
      };
    } else {
      localStorage.removeItem("localWishlistData");
      let arr = [];
      if (localWishlistData()) {
        arr = localWishlistData();
      }
      return {
        message: "Fetched wishlist",
        severity: "success",
        data: [...arr],
      };
    }
  }
);

// Initial State
const initialState = {
  pending: false,
  error: false,
  wishlist: [],
  snackNotiWishlist: {},
};

// Slice
export const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    resetSnkWishlist(state) {
      state.snackNotiWishlist = {};
    },
  },
  extraReducers: {
    [wishlistShiftLocalToCustomer.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [wishlistShiftLocalToCustomer.fulfilled]: (state, action) => {
      state.pending = false;
      state.wishlist = action.payload.data;
      //   state.snackNotiCompare = {
      //     message: action.payload.message,
      //     severity: action.payload.severity,
      //   };
    },
    [wishlistShiftLocalToCustomer.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [addToWishlist.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [addToWishlist.fulfilled]: (state, action) => {
      state.pending = false;
      state.wishlist = action.payload.data;
      state.snackNotiWishlist = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [addToWishlist.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [removeFromWishlist.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [removeFromWishlist.fulfilled]: (state, action) => {
      state.pending = false;
      state.wishlist = action.payload.data;
      state.snackNotiWishlist = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [removeFromWishlist.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [getWishlist.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getWishlist.fulfilled]: (state, action) => {
      state.pending = false;
      state.wishlist = action.payload.data;
    },
    [getWishlist.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [clearWishlist.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [clearWishlist.fulfilled]: (state, action) => {
      state.pending = false;
      state.wishlist = action.payload.data;
    },
    [clearWishlist.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
  },
});
//
export const { resetSnkWishlist } = wishlistSlice.actions;
export default wishlistSlice.reducer;
