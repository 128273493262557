import { Alert, Box, Button, Grid, IconButton, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Icon
import AddCircleSharpIcon from "@mui/icons-material/AddCircleSharp";

import "react-lazy-load-image-component/src/effects/blur.css";
import {
  createSupplier,
  getSuppliers,
} from "../../../../reduxToolkit/features/adminPanel/supplier";
import { getMedicines } from "../../../../reduxToolkit/features/adminPanel/medicine";
import LoadingBox from "../../../../components/misc/Loading";
import { getNotification } from "../../../../reduxToolkit/features/adminPanel/notification";
import SupplierCard from "./card/SupplierCard";

export default function ManageSupplier() {
  // use States
  const [loadingMain, setLoadingMain] = useState(false);
  const [supplierList, setSupplierList] = useState([]);

  // local data
  const localData = JSON.parse(localStorage.getItem("adminLoginInfo"));

  // store
  const { suppliers } = useSelector((state) => state.supplier);
  const { medicines } = useSelector((state) => state.medicine);

  // UseEffect
  const dispatch = useDispatch();

  // Get Suppliers
  useEffect(() => {
    dispatch(getSuppliers());
    if (!(medicines && medicines.length > 0)) dispatch(getMedicines());
  }, []);

  // Set Supplier Data
  useEffect(() => {
    setLoadingMain(true);
    setSupplierList(suppliers);
    setTimeout(() => {
      setLoadingMain(false);
    }, 3000);
  }, [suppliers]);

  // Add Dealer
  const [dealerName, setDealerName] = useState("");

  const handleAddDealer = () => {
    setLoadingMain(true);
    if (!loadingMain) {
      const obj = {
        creatorId: localData._id,
        dealerName,
      };
      dispatch(createSupplier(obj));
      setDealerName("");
      setTimeout(() => {
        dispatch(getNotification());
        dispatch(getSuppliers());
      }, 3000);
    }
  };

  //! Translate
  // Store
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate

  return (
    <>
      {supplierList && (
        <>
          {/* Suppliers List */}
          <Button
            size="small"
            variant="contained"
            fullWidth
            sx={{ my: 1, pointerEvents: "none" }}
          >
            {translate0("Suppliers")} ({supplierList.length})
          </Button>

          {/* Add Dealer */}
          <Grid
            container
            justifyContent="space-between"
            spacing={2}
            alignItems="center"
          >
            <Grid item flex={1}>
              <TextField
                label={translate0("Dealer Name")}
                value={dealerName}
                variant="standard"
                size="small"
                sx={{ my: 2 }}
                disabled={loadingMain}
                required
                onChange={(e) => setDealerName(e.target.value)}
                onKeyPress={(e) => e.key === "Enter" && handleAddDealer()}
                autoFocus
                fullWidth
              />
            </Grid>
            <Grid item>
              <IconButton
                color={dealerName && !loadingMain ? "success" : "gray"}
                size="small"
                onClick={handleAddDealer}
                sx={{ mt: 2, pointerEvents: !dealerName && "none" }}
              >
                <AddCircleSharpIcon
                  size="large"
                  sx={{ height: "1.8rem", width: "1.8rem" }}
                />
              </IconButton>
            </Grid>
          </Grid>

          {loadingMain ? (
            <LoadingBox w={"100%"} />
          ) : supplierList.length < 1 ? (
            <Alert
              severity="warning"
              sx={{ display: "flex", justifyContent: "center" }}
            >
              {translate0("No Data Available")}
            </Alert>
          ) : (
            <>
              <Box
                sx={{
                  // maxHeight: { xs: "60vh", sm: "57vh" },
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                <Grid container spacing={0} alignItems="stretch">
                  {supplierList.map((supplier, index) => (
                    <Grid xs={12} xl={6} item key={index}>
                      <SupplierCard supplier={supplier} medicines={medicines} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
}
