import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  AppBar,
  Badge,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  TextField,
  Toolbar,
  Tooltip,
} from "@mui/material";
import { Link } from "react-router-dom";
// Media Query
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// Icons
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import CloseIcon from "@mui/icons-material/Close";
import BackspaceIcon from "@mui/icons-material/Backspace";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
// import CancelSharpIcon from "@mui/icons-material/CancelSharp";
// import DeleteForeverSharpIcon from "@mui/icons-material/DeleteForeverSharp";
import RemoveShoppingCartSharpIcon from "@mui/icons-material/RemoveShoppingCartSharp";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCartInfoByCustomer,
  removeFromCart,
  removeFromCartByCustomer,
  updateCart,
} from "../../../reduxToolkit/features/frontend/cart";
import { PF } from "../../../config";
import {
  getDosages,
  getMedicines,
} from "../../../reduxToolkit/features/adminPanel/medicine";
import getSymbolFromCurrency from "currency-symbol-map";
import {
  currencyFormatter,
  getDecimal,
} from "../../../hooks/currencyFormatter";
import LoadingBox from "../../misc/Loading";
import { makeUrlFriendly } from "../../../hooks/makeUrlFriendly";
import LoadingButton from "@mui/lab/LoadingButton";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function CartDrawer({ setCartOpen, setCheckoutDlg }) {
  const dispatch = useDispatch();
  // Media Query
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  //   UseState
  const [loadingMain, setLoadingMain] = useState(false);
  const [cartList, setCartList] = useState([]);
  const [moqArr, setMoqArr] = useState([]);
  const [moqChange, setMoqChange] = useState([]);
  const [cartTotal, setCartTotal] = useState(0);
  const [cartLoading, setCartLoading] = useState("");
  const [checkoutLoading, setCheckoutLoading] = useState(false);

  // Currency
  const [exchangeRate, setExchangeRate] = useState(1);
  const [currencyName, setCurrencyName] = useState("USA");

  // dialogs
  const [emptyCartDlg, setEmptyCartDlg] = useState(false);

  // Local Store
  const localCustomerData = () => {
    const c = JSON.parse(localStorage.getItem("loginInfo"));
    return c ? c : {};
  };
  const localLangData = () => {
    const l = JSON.parse(localStorage.getItem("langFront"));
    return l ? l : {};
  };
  // Store
  const { medicines, medVariants, dosages } = useSelector(
    (state) => state.medicine
  );
  const { carts } = useSelector((state) => state.cart);
  const { activeCurrencyFront } = useSelector((state) => state.miscFront);

  // Functions

  const handleCartClose = () => {
    if (moqChange.length > 0) {
      setLoadingMain(true);
      cartList.forEach((c, i) => {
        handleUpdateCart(i, c._id, c.price, c.minMoq, c.variantId, c.medId);
      });
      setTimeout(() => {
        setCartOpen(false);
        setLoadingMain(false);
      }, 1000);
    } else setCartOpen(false);
  };

  const handleEmptyCartClose = () => {
    setEmptyCartDlg(false);
  };

  const handleEmptyCart = () => {
    setLoadingMain(true);
    handleEmptyCartClose();
    if (localCustomerData()._id)
      dispatch(
        removeFromCartByCustomer({ customerId: localCustomerData()._id })
      );
    setTimeout(() => {
      setLoadingMain(false);
    }, 1000);
  };

  const getMedInfo = (medId) => {
    let obj = {
      medImg: "",
      genericBrand: "",
    };
    if (medicines && medicines.length > 0) {
      const M = medicines.find(
        (m) => m.lang === (localLangData().langCode || "en")
      );
      if (M && M.data) {
        const Med = M.data.find(
          (med) =>
            (localLangData().langCode !== "en"
              ? med.medicineDetectorId
              : med.fixedMedicineId) === medId
        );
        if (Med) {
          obj.genericBrand = Med.genericBrand;
          if (Med.fixedMedicineId) obj.fixedMedicineId = Med.fixedMedicineId;
          if (Med.medicineDetectorId)
            obj.medicineDetectorId = Med.medicineDetectorId;
          const medImg = Med.image.find((i) => i.default);
          if (medImg) obj.medImg = medImg;
        }
      }
    }
    return obj;
  };

  const getMedUrl = (medObj) => {
    let genericBrand = "";
    if (medicines && medicines.length > 0) {
      const M = medicines.find((m) => m.lang === "en");
      if (M) {
        const med = M.data.find(
          (x) =>
            x.fixedMedicineId ===
            (localLangData.langCode !== "en"
              ? medObj.medicineDetectorId
              : medObj.fixedMedicineId)
        );
        if (med) genericBrand = med.genericBrand;
      }
    }
    return genericBrand;
  };

  const getDosageInfo = (dosageId, langCode) => {
    let dosageName = "";
    if (dosages && dosages.length > 0) {
      const dosageList = dosages.find((d) => d.lang === langCode);
      if (dosageList && dosageList.data) {
        const D = dosageList.data.find((d) =>
          langCode !== "en" ? d.dosageDetectorId : d.fixedDosageId === dosageId
        );
        if (D && D.dosageName) dosageName = D.dosageName;
      }
    }
    return dosageName;
  };

  const getVariantInfo = (varId) => {
    let obj = {
      dosage: "",
      strength: "",
      packTypes: "",
    };
    if (medVariants && medVariants.length > 0) {
      const V = medVariants.find(
        (v) =>
          (localLangData().langCode !== "en"
            ? v.variantDetectorId
            : v.fixedVariantId) === varId
      );
      if (V) {
        obj.dosage = getDosageInfo(V.dosageForm, localLangData().langCode);
        obj.strength = V.strength;
        obj.packTypes = V.packTypes;
      }
    }
    return obj;
  };

  const handleFixNonEngCart = (variantId) => {
    if (localLangData.langCode !== "en" && medVariants && medVariants.length) {
      const Mv = medVariants.find((mv) => mv.fixedVariantId === variantId);
      if (Mv && Mv.variantDetectorId) variantId = Mv.variantDetectorId;
    }
    return variantId;
  };

  const handleUpdateCart = (index, cartId, price, minMoq, variantId, medId) => {
    variantId = handleFixNonEngCart(variantId);
    let arr2 = [...moqChange];
    if (arr2.includes(cartId)) arr2 = arr2.filter((a) => a !== cartId);

    setMoqChange(arr2);
    let obj = {
      price,
      medId,
      minMoq,
      moq: Number(moqArr[index]),
    };
    if (localCustomerData()._id) obj.customerId = localCustomerData()._id;
    obj.variantId = variantId;
    localCustomerData()._id && dispatch(updateCart(obj));
  };

  const handleRemoveFromCart = (variantId) => {
    variantId = handleFixNonEngCart(variantId);
    localCustomerData() &&
      dispatch(
        removeFromCart({ customerId: localCustomerData()._id, variantId })
      );
  };

  // Use Effect
  useEffect(() => {
    setLoadingMain(true);
    // if (!(medicines && medicines.length > 0)) dispatch(getMedicines());
    if (localCustomerData()._id) {
      dispatch(getCartInfoByCustomer(localCustomerData()._id));
    }
    dispatch(getDosages());
    setTimeout(() => {
      setLoadingMain(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (carts && carts.length > 0) {
      setCartList(carts);
      let arr = [];
      carts.forEach((c) => arr.push(c.moq));
      setMoqArr(arr);
    } else {
      setCartList([]);
      setMoqArr([]);
    }
  }, [carts]);

  // set currency
  useEffect(() => {
    setExchangeRate(activeCurrencyFront.exchangeRate);
    setCurrencyName(activeCurrencyFront.currencyName);
  }, [activeCurrencyFront]);

  // Set Cart Total
  useEffect(() => {
    let total = 0;
    cartList.forEach((c, i) => {
      total += Number(c.price) * exchangeRate * Number(moqArr[i]);
    });
    setCartTotal(Math.ceil(total.toFixed(2)));
  }, [cartList, moqArr]);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate

  return (
    <>
      {/* <Dialog
        open={true}
        aria-labelledby={"cart"}
        TransitionComponent={Transition}
        fullScreen={!smUp}
      > */}
      <AppBar sx={{ position: "sticky" }}>
        <Toolbar>
          <Grid
            container
            spacing={0}
            justifyContent="space-between"
            alignItems="center"
            sx={{ minWidth: 300 }}
          >
            <Grid item>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCartClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Grid>

            <Grid item sx={{ flexGrow: 1 }}>
              <Typography variant="h6">{translateFront("Cart")}</Typography>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                endIcon={<BackspaceIcon />}
                size="small"
                color="error"
                fullWidth
                onClick={() => setEmptyCartDlg(true)}
                disabled={cartList.length < 1 ? true : false}
              >
                {translateFront("Clear All")}
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {loadingMain ? (
        <Box sx={{ minWidth: !smUp ? "100vw" : 700 }}>
          <LoadingBox w={"100%"} />
        </Box>
      ) : (
        <Box sx={{ minWidth: !smUp ? "100vw" : 700 }}>
          <Grid
            container
            spacing={1}
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            // sx={{ px: 1 }}
          >
            {cartList.length > 0 && (
              <Grid
                item
                sx={{
                  mt: 2,
                  position: "sticky",
                  top: "47px",
                  background: "white",
                  zIndex: 2,
                  width: "100%",
                }}
              >
                {/* Intro */}
                <Grid
                  container
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={11} sm={5}>
                    <Button
                      variant="outlined"
                      size="small"
                      color="success"
                      fullWidth={!smUp ? true : true}
                      sx={{ pointerEvents: "none" }}
                    >
                      {translateFront("Total")}:{" "}
                      {getSymbolFromCurrency(currencyName)}
                      {currencyFormatter("USA", cartTotal)}
                    </Button>
                  </Grid>
                  <Grid item xs={11} sm={5}>
                    <LoadingButton
                      variant="outlined"
                      endIcon={<ArrowCircleRightIcon />}
                      size="small"
                      fullWidth={!smUp ? true : true}
                      loading={checkoutLoading}
                      onClick={() => {
                        setCheckoutLoading(true);
                        setTimeout(() => {
                          handleCartClose();
                          setCheckoutDlg(true);
                          setCheckoutLoading(false);
                        }, 1000);
                      }}
                    >
                      {translateFront("Checkout")}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid item>
              <Box sx={{ maxWidth: 720, mx: 1 }}>
                <Grid container spacing={1}>
                  {cartList.map((x, i) => (
                    <Grid item key={i} sx={{ width: !smUp ? "100%" : 345 }}>
                      {cartLoading.includes(x._id) ? (
                        <LoadingBox w={"100%"} h={120} />
                      ) : (
                        <Card>
                          <CardContent>
                            <Grid container spacing={0} alignItems="center">
                              <Grid item sx={{ flexGrow: 1 }}>
                                <Typography gutterBottom variant="subtitle1">
                                  {getMedInfo(
                                    x.medId
                                  ).genericBrand.toUpperCase()}{" "}
                                </Typography>
                              </Grid>

                              <Grid item>
                                <Tooltip
                                  arrow
                                  placement="top"
                                  title={translateFront("Remove")}
                                >
                                  <IconButton
                                    size="small"
                                    onClick={() =>
                                      handleRemoveFromCart(x.variantId)
                                    }
                                  >
                                    <RemoveShoppingCartSharpIcon color="error" />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            </Grid>
                            <Divider />
                            <Grid
                              container
                              spacing={0}
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Grid item xs={!mdUp ? 7 : 5.5}>
                                <Grid
                                  container
                                  spacing={0}
                                  flexDirection="column"
                                >
                                  <Grid item>
                                    <Grid
                                      container
                                      spacing={0}
                                      alignItems="center"
                                    >
                                      <Grid item>
                                        <Link
                                          to={`/our-medicines/${makeUrlFriendly(
                                            getMedUrl(getMedInfo(x.medId))
                                          )}`}
                                          onClick={handleCartClose}
                                        >
                                          <Box
                                            sx={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            <LazyLoadImage
                                              src={
                                                getMedInfo(x.medId).medImg
                                                  .src !== ""
                                                  ? PF +
                                                    "adminPanel/medicines/img/" +
                                                    getMedInfo(x.medId).medImg
                                                      .src
                                                  : PF +
                                                    "adminPanel/medicines/no-medicine-image.jpg"
                                              }
                                              alt={
                                                getMedInfo(x.medId).medImg.alt
                                              }
                                              effect="blur"
                                              placeholderSrc="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png"
                                              style={{
                                                objectFit: "stretch",
                                                width: 50,
                                              }}
                                            />
                                          </Box>
                                        </Link>
                                      </Grid>
                                      <Grid item>
                                        <Typography
                                          variant="body2"
                                          color="initial"
                                          sx={{ fontSize: ".75rem" }}
                                        >
                                          {getVariantInfo(x.variantId).dosage}
                                          <br />
                                          {getVariantInfo(x.variantId).strength}
                                          <br />
                                          {
                                            getVariantInfo(x.variantId)
                                              .packTypes
                                          }
                                          <br />
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                              {!mdUp ? (
                                <Grid item xs={5}>
                                  <Grid
                                    container
                                    spacing={1}
                                    alignItems="center"
                                    justifyContent="flex-end"
                                  >
                                    <Grid item>
                                      <IconButton
                                        size="small"
                                        onClick={() => {
                                          let arr = [...moqArr];
                                          arr[i] = Number(arr[i]) - 1;
                                          setMoqArr(arr);
                                          let arr2 = [...moqChange];
                                          if (!arr2.includes(x._id)) {
                                            arr2.push(x._id);
                                          }
                                          setMoqChange(arr2);
                                        }}
                                        disabled={
                                          Number(moqArr[i]) - 1 <
                                          Number(x.minMoq || 1)
                                        }
                                      >
                                        <RemoveCircleOutlineIcon
                                          color={
                                            Number(moqArr[i]) - 1 <
                                            Number(x.minMoq || 1)
                                              ? "inherit"
                                              : "error"
                                          }
                                        />
                                      </IconButton>
                                    </Grid>
                                    <Grid item>
                                      <Typography
                                        variant="body2"
                                        color="initial"
                                        sx={{ fontSize: "1rem" }}
                                      >
                                        {moqArr[i]}
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <IconButton
                                        size="small"
                                        onClick={() => {
                                          let arr = [...moqArr];
                                          arr[i] = Number(arr[i]) + 1;
                                          setMoqArr(arr);
                                          let arr2 = [...moqChange];
                                          if (!arr2.includes(x._id)) {
                                            arr2.push(x._id);
                                          }
                                          setMoqChange(arr2);
                                        }}
                                      >
                                        <AddCircleOutlineIcon color="success" />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              ) : (
                                <Grid item xs={1.5}>
                                  <TextField
                                    label={translateFront("Qty")}
                                    type="number"
                                    variant="standard"
                                    size="small"
                                    // inputRef={qtyRef.current[j]}
                                    InputProps={{
                                      inputProps: {
                                        min: Number(x.minMoq || 1),
                                      },
                                    }}
                                    defaultValue={Number(x.moq || 0)}
                                    onChange={(e) => {
                                      let arr = [...moqArr];
                                      arr[i] = Number(e.target.value);
                                      setMoqArr(arr);
                                      let arr2 = [...moqChange];
                                      if (!arr2.includes(x._id)) {
                                        arr2.push(x._id);
                                      }
                                      setMoqChange(arr2);
                                    }}
                                    onKeyPress={(e) => {
                                      e.preventDefault();
                                    }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    // helperText={`Minimum Order Qty ${Number(x.moq)}`}
                                  />
                                </Grid>
                              )}
                              {!mdUp && (
                                <Box
                                  sx={{
                                    borderBottom: "1px solid black",
                                    width: "100%",
                                  }}
                                ></Box>
                              )}
                              <Grid item xs={!mdUp ? 12 : 5}>
                                <Typography
                                  variant="body2"
                                  color="inherit"
                                  align="right"
                                >
                                  <span
                                    style={{
                                      fontSize: "1.2rem",
                                      fontWeight: 100,
                                    }}
                                  >
                                    {getSymbolFromCurrency(currencyName)}
                                    {currencyFormatter(
                                      currencyName,
                                      Math.round(
                                        Number(x.price) *
                                          exchangeRate *
                                          moqArr[i]
                                      )
                                    )}
                                  </span>
                                  <sup
                                    style={{
                                      fontSize: ".6rem",
                                      fontWeight: 100,
                                      position: "relative",
                                      top: -2,
                                    }}
                                  >
                                    {getDecimal(Number(x.price) * exchangeRate)}
                                  </sup>
                                </Typography>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      )}
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
      {emptyCartDlg && (
        <Dialog
          open={emptyCartDlg}
          onClose={handleEmptyCartClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {translateFront("Empty Cart?")}
          </DialogTitle>

          <DialogActions>
            <Button onClick={handleEmptyCartClose}>
              {translateFront("No")}
            </Button>
            <Button onClick={handleEmptyCart} color="error">
              {translateFront("Yes")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {/* </Dialog> */}
    </>
  );
}
