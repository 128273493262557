import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import LoadingBox from "../../../../../components/misc/Loading";
import { PF } from "../../../../../config";
import { format } from "date-fns";
// import { currencyFormatter } from "../../../../../hooks/currencyFormatter";
import { useDispatch, useSelector } from "react-redux";
import { Button, Divider, Grid } from "@mui/material";
import { invoicePdfDownload } from "../../../../../reduxToolkit/features/adminPanel/medicine";
import LoadingButton from "@mui/lab/LoadingButton";

export default function PreviewInvoice({
  open,
  setOpen,
  consigneeInfoObj,
  paymentInfoObj,
  paymentInfo,
  selectedVariantArr,
  additionalCostArr,
  discountsArr,
  total,
  remarks,
  companyInfo,
  customerSignature,
}) {
  const [loading, setLoading] = useState(false);
  const [htmlToPreview, setHtmlToPreview] = useState(``);
  const [loadingPdfGenerate, setLoadingPdfGenerate] = useState(false);

  const dispatch = useDispatch();

  const { invoicePdfDownloadResponse } = useSelector((state) => state.medicine);

  // Download pdf
  const handleDownloadPDF = () => {
    setLoadingPdfGenerate(true);
    dispatch(invoicePdfDownload({ html: htmlToPreview }));
    setTimeout(() => {
      setLoadingPdfGenerate(false);
    }, 2000);
  };

  useEffect(() => {
    if (invoicePdfDownloadResponse && invoicePdfDownloadResponse.filename) {
      setTimeout(() => {
        window.open(
          PF + "files/PDFs/" + invoicePdfDownloadResponse.filename,
          "_blank"
        );
      }, 2000);
    }
  }, [invoicePdfDownloadResponse]);

  //! Translate
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate

  useEffect(() => {
    let html =
      templateStyle +
      `
      .container::before {
        opacity: .1;
        content: "";
        position: absolute;
        top: 150px;
        right: 0px;
        bottom: 0px;
        background-image: url(${
          companyInfo.companyWatermark
            ? companyInfo.companyWatermark
            : PF + "/misc/mfw-watermark.png"
        });
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        left: 0px;
      }
      </style>
      <div class="container">
      <div class="topbar">
        <div>
          <img src=${
            companyInfo.companyLogo
              ? companyInfo.companyLogo
              : PF + "misc/1666271485951-MedicineForWorld(MFW)-site-logo.png"
          } alt="company-logo" class="topbar__company-logo" />
        </div>
        <div class="topbar__vertical-divider" />
        <div class="topbar__company-info">
          ${companyInfo.companyDetails}
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <td class='table__heading'>
              Proforma Invoice
            </td>
          </tr>
        </thead>
      </table>
      <table>
        <tbody>
          <tr>
            <td class="table__consignee-block table__extra-padding">
              <p class="table__large-text">Consignee: </p>
              ${
                consigneeInfoObj.name !== ""
                  ? `<p>Name: ${consigneeInfoObj.name}</p>`
                  : ""
              }
              ${
                consigneeInfoObj.address !== ""
                  ? `<p>${consigneeInfoObj.address}</p>`
                  : ""
              }
              ${
                consigneeInfoObj.city !== ""
                  ? `<p>City: ${consigneeInfoObj.city}</p>`
                  : ""
              }
              ${
                consigneeInfoObj.postCode !== ""
                  ? `<p>Post Code: ${consigneeInfoObj.postCode}</p>`
                  : ""
              }
              ${
                consigneeInfoObj.country !== ""
                  ? `<p>Country: ${consigneeInfoObj.country}</p>`
                  : ""
              }
              ${
                consigneeInfoObj.phone !== ""
                  ? `<p>Phone: ${consigneeInfoObj.phone}</p>`
                  : ""
              }
              ${
                consigneeInfoObj.email !== ""
                  ? `<p>Email: ${consigneeInfoObj.email}</p>`
                  : ""
              }
              ${
                consigneeInfoObj.additionalConsigneeInfo !== ""
                  ? `<p>${consigneeInfoObj.additionalConsigneeInfo}</p>`
                  : ""
              }
            </td>
            <td class="table__consignee-block table__extra-padding">
              <p class="table__large-text">Trader: </p>
              <p class="table__large-text">${companyInfo.companyName}</p>
              
              ${companyInfo.companyDetails}
            </td>
          </tr>
          <tr>
            <td class="table__consignee-block table__extra-padding">
              <div>
                <div class="normal-line-height">
                  <p >
                    ${remarks.extraContent}
                  </p>
                </div>
                <p>
                  <span class="bold-text w-fixed">Invoice No </span>
                  : ${remarks.invoiceNumber}
                </p>
                <p>
                  <span class="bold-text w-fixed">Date </span>
                  : ${format(remarks.date, "PPP")}
                </p>
              </div>
            </td>
            <td class="table__consignee-block table__extra-padding">
              ${paymentInfo}
            </td>
          </tr>
        </tbody>
      </table>
      <table>
        <thead>
          <tr>
            <th>Description</th>
            <th class="text-center">Packing</th>
            <th class="text-center">Quantity</th>
            <th class="text-center">FOB Unit Price/ box</th>
            <th class="text-align-left">Amount</th>
          </tr>
        </thead>
        <tbody>
          {items}
          {additionalCosts} 
          {discountCosts}           
          <tr>
            <td colspan="4" class="table__total-title table__extra-padding">
              FOB TOTAL (ROUND)
            </td>
            <td >
                ${`
                      <p class="text-align-left">
                        USD ${total.usdTotal}
                      </p>
                      <p class="text-align-left">
                      ${
                        total.variantTotalWithSymbol.includes("USD")
                          ? ""
                          : total.variantTotalWithSymbol
                      }
                      </p>
                    `}
              
            </td>
          </tr>
          <tr>
            <td colspan="5" class="table__consignee-block ">
              <div class="flex text-right">
                <p>
                  Amount In Words:
                </p>
                <div>
                  <p>
                  ${total.usdTotalInText || ""}.
                  </p>
                  ${
                    total.variantTotalInText
                      ? `<p>${total.variantTotalInText}.</p>`
                      : ""
                  }
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="table__consignee-block table__extra-padding">
            ${remarks.creditTerms}
            </td>
            <td class="text-center">${remarks.finalDestination}</td>
          </tr>
          <tr>
            <td colspan="5" class="table__consignee-block table__extra-padding">
              <b>Declaration:</b>
              <p style="height: 100% !important">
              ${remarks.declaration}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <table>
        <tbody>
          <tr>
            <td class="table__consignee-block table__extra-padding">
              <p>For</p>
              <b>
              ${companyInfo.companyName}
              </b>
              <div>
                <img src=${
                  companyInfo.companySil
                    ? companyInfo.companySil
                    : PF + "misc/mfw-sil.png"
                } alt="company-logo" class="company-sil" />
              </div>
              <p>
                Authorized Signature
              </p>
            </td>
            <td class="table__consignee-block table__extra-padding">
              <p class="text-center bold-text">Accepted</p>
              <b>
                For
              </b>
              <p>
              ${customerSignature || consigneeInfoObj.name}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      </div>            
        `;
    // Payment Info
    // let paymentInfo = "";
    // paymentInfoObj.fields.forEach((field) =>
    //   field.value !== ""
    //     ? (paymentInfo = paymentInfo + `<p>${field.title}: ${field.value}</p>`)
    //     : ``
    // );
    // html = html.replace("{paymentInfo}", paymentInfo);

    // Additional Cost
    let additionalCosts = "";
    additionalCostArr.forEach(
      (cost) =>
        (additionalCosts =
          additionalCosts +
          `<tr>
        <td colspan="4">${cost.title}</td>
        <td class="text-align-left"> ${
          cost.variant === 0 ? "USD " + cost.cost : cost.text
        }</td>
      </tr>`)
    );
    html = html.replace("{additionalCosts} ", additionalCosts);

    // Discount Cost
    let discountCosts = "";
    discountsArr.forEach(
      (cost) =>
        (discountCosts =
          discountCosts +
          `<tr>
        <td colspan="4">${cost.title}</td>
        <td class="text-align-left"> ${
          cost.variant === 0 ? "USD " + cost.cost : cost.text
        }</td>
      </tr>`)
    );
    html = html.replace("{discountCosts} ", discountCosts);

    // Items
    let items = "";
    selectedVariantArr.forEach(
      (item) =>
        (items =
          items +
          `<tr>
                <td>${item.genericBrand} (${item.generic} ${
            item.strength
          }) manufactured by ${item.company} </td>
                <td class="text-center">${item.packType}</td>
                <td class="text-center">${item.qty}</td>
                <td class="text-center">USD ${item.unitPrice}</td>
                <td class="text-align-left">USD ${
                  Number(item.unitPrice) * Number(item.qty)
                }</td>
               </tr>`)
    );
    html = html.replace("{items}", items);

    setHtmlToPreview(html);
  }, []);

  useEffect(() => {
    setLoading(true);
    const manualInvoiceObjects = {
      consigneeInfoObj,
      paymentInfoObj,
      paymentInfo,
      selectedVariantArr,
      additionalCostArr,
      discountsArr,
      total,
      remarks,
      companyInfo,
    };

    localStorage.setItem(
      "manual-invoice-objects",
      JSON.stringify(manualInvoiceObjects)
    );
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullScreen>
        {loading ? (
          <LoadingBox w={"100%"} h="100vh" />
        ) : (
          <DialogContent>
            <Grid
              container
              sx={{ p: 0 }}
              style={{ margin: "auto", width: "50%" }}
              justifyContent="center"
              alignItems="center"
              spacing={4}
            >
              <Grid item xs={6}>
                <Button
                  onClick={() => setOpen(false)}
                  // sx={{ m: 0, p: 0 }}
                  color="error"
                  variant="outlined"
                  fullWidth
                  size="small"
                >
                  Back
                </Button>
              </Grid>
              <Grid item xs={6}>
                <LoadingButton
                  loading={loadingPdfGenerate}
                  onClick={handleDownloadPDF}
                  // sx={{ m: 0, p: 0 }}
                  color="warning"
                  variant="contained"
                  fullWidth
                  size="small"
                >
                  Download PDF
                </LoadingButton>
              </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />

            {loading ? (
              <LoadingBox w={"100%"} />
            ) : (
              <div style={{ margin: "auto", width: "50%" }}>
                <div dangerouslySetInnerHTML={{ __html: htmlToPreview }} />
              </div>
            )}
          </DialogContent>
        )}
      </Dialog>
    </>
  );
}

const templateStyle = `
<style>
  .container {
    position: relative;
    z-index:10;
    margin-top: 40px;
    margin-bottom: 50px;
    margin-left: 5px;
    margin-right: 5px;
  }
  
  .topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .topbar__company-info {
    line-height: 1.5px;
    font-size: 11px;
    flex: 1;
    padding: 0 10px;
    border-left: 3px solid green;
  }
  
  .topbar__company-logo {
    height: 100px;
    width: 450px;
    object-fit: contain;
    padding-right: 10px;
    object-position: left;
    flex: 1;
  }
  
  table{
    border-collapse: collapse;
    width: 100%;
    border: 1px solid black;
  }
  
  td,
  th {
    border: 1px solid black;
    text-align: left;  
    font-size: 11px;
    table-layout: auto;
    padding: .4rem;
  }
  
  .table__extra-padding{
    padding: 8px;
  }
  
  .table__heading{
    text-align: center;
    font-weight: 500;
    font-size: 16px;
  }
  
  .table__consignee-block{
    line-height: 1.5px;  
    vertical-align: top;
  }
  
  .table__large-text{
    font-size:13px;
    font-weight: 800;
  }
  
  .bold-text{
    font-weight:800;
  }
  
  .w-fixed{
    display:inline-block;
    width: 70px;
  }
  
  .table__total-title{
    text-align:right;
  }
  
  .flex{
    display:flex;
    gap:10px;
  }
  
  
  .text-right{
    float: right
  }
  
  .text-center{
    text-align: center;
  }  

  .text-align-left{
    text-align: left;
  }

  .company-sil{
    height: 80px;
    width: 80px;
    object-fit: contain;
    margin-left:30px;
  }

  .normal-line-height{
    line-height: normal !important;
  }
`;
