import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import { useState } from "react";
import ViewReviewerRequests from "./ViewReviewerRequests";
import ReviewerRequestRules from "./ReviewerRequestRules";
import { useSelector } from "react-redux";

export default function ReviewerInfoRequest() {
  const [activeTab, setActiveTab] = useState(0);

  // ! translate
  const [siteLang, setSiteLang] = useState("");
  // Local Store
  const localLangData = JSON.parse(localStorage.getItem("lang"));
  // Store
  const { langs, activeLang } = useSelector((state) => state.misc);
  const [langSettings1, setLangSettings1] = useState([]);
  const [langSettings2, setLangSettings2] = useState([]);
  const title1 = "Reviewer Info Request";
  const title2 = "Misc Words";

  useEffect(() => {
    if (langs && langs.length > 0 && siteLang !== "") {
      const L = langs.find((l) => l.langCode === siteLang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      const A = arr.find((l) => l._title === title1);
      if (A) setLangSettings1(A.data);
      const B = arr.find((l) => l._title === title2);
      if (B) setLangSettings2(B.data);
    }
  }, [siteLang, langs]);

  const translate1 = (String) => translation(String, title1, 1);
  const translate2 = (String) => translation(String, title2, 2);

  const translation = (String, title, index) => {
    let translation = "";
    let settings = [];
    if (index === 1) {
      settings = langSettings1;
    } else if (index === 2) {
      settings = langSettings2;
    }
    const T = settings.find((ls) => ls.title === String);
    if (T && T.value) translation = T.value;
    else {
      let arr = [];
      if (langs && langs.length > 0 && siteLang !== "") {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.settings && LEn.settings.length > 0) arr = LEn.settings;
        const S = arr.find((l) => l._title === title);
        if (S) {
          const T = S.data.find((ls) => ls.title === String);
          if (T && T.value) translation = T.value;
        }
      }
    }
    return translation;
  };

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setSiteLang(activeLang.langCode);
    } else {
      if (localLangData) {
        setSiteLang(localLangData.langCode);
      }
    }
  }, [activeLang]);
  // ! translate

  return (
    <>
      <Grid container spacing={1} justifyContent="center">
        <Grid item xs={12} sm={8}>
          <Button
            variant={activeTab === 0 ? "contained" : "outlined"}
            color="primary"
            size="small"
            FullWidth
            sx={{ width: "100%" }}
            onClick={() => setActiveTab(0)}
          >
            {translate1("View Requests")}
          </Button>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button
            variant={activeTab === 1 ? "contained" : "outlined"}
            color="secondary"
            size="small"
            FullWidth
            sx={{ width: "100%" }}
            onClick={() => setActiveTab(1)}
          >
            {translate1("Request Rules")}
          </Button>
        </Grid>
        <Grid item xs={12}>
          {activeTab === 0 ? (
            <ViewReviewerRequests />
          ) : (
            <ReviewerRequestRules />
          )}
        </Grid>
      </Grid>
    </>
  );
}
