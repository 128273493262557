import React, { useState } from "react";
import LangNTitleBar from "./LangNTitleBar";

// Ck Editor
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import {
  Typography,
  Grid,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  Checkbox,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  createNoti,
  uploadTempPic,
} from "../../../reduxToolkit/features/adminPanel/misc";
import { PF } from "../../../config";

// Icons
import SettingsIcon from "@mui/icons-material/Settings";
import InfoIcon from "@mui/icons-material/Info";

import LoadingButton from "@mui/lab/LoadingButton";
import { useEffect } from "react";
import {
  getManagedContentSettings,
  saveManagedContentSettings,
} from "../../../reduxToolkit/features/adminPanel/managedContentSettings";
import { getNotification } from "../../../reduxToolkit/features/adminPanel/notification";
import LoadingBox from "../../../components/misc/Loading";
import { ExpandMore } from "@mui/icons-material";

export default function EmailSettings() {
  const [loading, setLoading] = useState(false);
  const [loadingMain, setLoadingMain] = useState(false);
  const [lang, setLang] = useState("");
  const [langId, setLangId] = useState("");

  const [viewTemplateBuffer, setViewTemplateBuffer] = useState({});
  const [tempEmail, setTempEmail] = useState({
    customerEmail: "",
    adminEmail: "",
  });
  const [tempEmailCheckBox, setTempEmailCheckBox] = useState({
    customerEmailEnable: false,
    adminEmailEnable: false,
  });
  const [tempEmailTitle, setTempEmailTitle] = useState({
    customerEmailTitle: "",
    adminEmailTitle: "",
  });

  const [adminInfo, setAdminInfo] = useState({
    adminName: "E-Lifesaving Team",
    adminEmail: "info@elifesavinemedicine.com",
    invoiceTableHtml: "",
  });

  const [emailTemplates, setEmailTemplates] = useState({
    emailVerificationEmail: {
      title: "Email Verification ",
      customerEmailTitle: "",
      customerEmail: "",
      customerEmailEnable: false,
      adminEmailTitle: "",
      adminEmail: "",
      adminEmailEnable: false,
      variablesArr: [
        "{_verifyCode}",
        "{_customerEmail}",
        "{_adminEmail}",
        "{_adminName}",
      ],
    },
    registrationEmail: {
      title: "Registration Success",
      customerEmailTitle: "",
      customerEmail: "",
      customerEmailEnable: false,
      adminEmailTitle: "",
      adminEmail: "",
      adminEmailEnable: false,
      variablesArr: [
        "{_customerEmail}",
        "{_customerName}",
        "{_adminEmail}",
        "{_adminName}",
      ],
    },
    askPriceEmail: {
      title: "Ask Price",
      customerEmailTitle: "",
      customerEmail: "",
      customerEmailEnable: false,
      adminEmailTitle: "",
      adminEmail: "",
      adminEmailEnable: false,
      variablesArr: [
        "{_customerEmail}",
        "{_customerName}",
        "{_adminEmail}",
        "{_adminName}",
        "{_medName}",
      ],
    },
    priceAssignEmail: {
      title: "Price Assigned",
      customerEmailTitle: "",
      customerEmail: "",
      customerEmailEnable: false,
      adminEmailTitle: "",
      adminEmail: "",
      adminEmailEnable: false,
      variablesArr: [
        "{_customerEmail}",
        "{_customerName}",
        "{_adminEmail}",
        "{_adminName}",
        "{_medName}",
        "{_medUrl}",
      ],
    },
    AssignedPriceUpdatedEmail: {
      title: "Assigned Price Updated",
      customerEmailTitle: "",
      customerEmail: "",
      customerEmailEnable: false,
      adminEmailTitle: "",
      adminEmail: "",
      adminEmailEnable: false,
      variablesArr: [
        "{_customerEmail}",
        "{_customerName}",
        "{_adminEmail}",
        "{_adminName}",
        "{_medName}",

        "{_medUrl}",
      ],
    },
    orderCreationEmail: {
      title: "Order Created",
      customerEmailTitle: "",
      customerEmail: "",
      customerEmailEnable: false,
      adminEmailTitle: "",
      adminEmail: "",
      adminEmailEnable: false,
      variablesArr: [
        "{_customerEmail}",
        "{_customerName}",
        "{_adminEmail}",
        "{_adminName}",
        "{_invoice}",
      ],
    },
    orderUpdateEmail: {
      title: "Order Updated",
      customerEmailTitle: "",
      customerEmail: "",
      customerEmailEnable: false,
      adminEmailTitle: "",
      adminEmail: "",
      adminEmailEnable: false,
      variablesArr: [
        "{_customerEmail}",
        "{_customerName}",
        "{_adminEmail}",
        "{_adminName}",
        "{_invoice}",
      ],
    },
    paymentAccountAssignedEmail: {
      title: "Payment Account Assigned",
      customerEmailTitle: "",
      customerEmail: "",
      customerEmailEnable: false,
      adminEmailTitle: "",
      adminEmail: "",
      adminEmailEnable: false,
      variablesArr: [
        "{_customerEmail}",
        "{_customerName}",
        "{_adminEmail}",
        "{_adminName}",
        "{_invoice}",
      ],
    },
    paymentReceiptRejectedEmail: {
      title: "Payment Receipt Rejected",
      customerEmailTitle: "",
      customerEmail: "",
      customerEmailEnable: false,
      adminEmailTitle: "",
      adminEmail: "",
      adminEmailEnable: false,
      variablesArr: [
        "{_customerEmail}",
        "{_customerName}",
        "{_adminEmail}",
        "{_adminName}",
        "{_invoice}",
      ],
    },
    paymentReceiptUploadedEmail: {
      title: "payment Receipt Uploaded",
      customerEmailTitle: "",
      customerEmail: "",
      customerEmailEnable: false,
      adminEmailTitle: "",
      adminEmail: "",
      adminEmailEnable: false,
      variablesArr: [
        "{_customerEmail}",
        "{_customerName}",
        "{_adminEmail}",
        "{_adminName}",
        "{_invoice}",
      ],
    },
    paymentConfirmedNShippingEmail: {
      title: "Payment Confirmed & Shipping",
      customerEmailTitle: "",
      customerEmail: "",
      customerEmailEnable: false,
      adminEmailTitle: "",
      adminEmail: "",
      adminEmailEnable: false,
      variablesArr: [
        "{_customerEmail}",
        "{_customerName}",
        "{_adminEmail}",
        "{_adminName}",
        "{_invoice}",
      ],
    },
    shippingCompleteEmail: {
      title: "Shipping Complete",
      customerEmailTitle: "",
      customerEmail: "",
      customerEmailEnable: false,
      adminEmailTitle: "",
      adminEmail: "",
      adminEmailEnable: false,
      variablesArr: [
        "{_customerEmail}",
        "{_customerName}",
        "{_adminEmail}",
        "{_adminName}",
        "{_invoice}",
      ],
    },
    shippingConfirmationEmail: {
      title: "Shipping Confirmed",
      customerEmailTitle: "",
      customerEmail: "",
      customerEmailEnable: false,
      adminEmailTitle: "",
      adminEmail: "",
      adminEmailEnable: false,
      variablesArr: [
        "{_customerEmail}",
        "{_customerName}",
        "{_adminEmail}",
        "{_adminName}",
        "{_invoice}",
      ],
    },
    reviewSubmittedEmail: {
      title: "Review Submitted",
      customerEmailTitle: "",
      customerEmail: "",
      customerEmailEnable: false,
      adminEmailTitle: "",
      adminEmail: "",
      adminEmailEnable: false,
      variablesArr: [
        "{_customerEmail}",
        "{_adminEmail}",
        "{_adminName}",
        "{_medName}",
        "{_medUrl}",
      ],
    },
    reviewApprovedEmail: {
      title: "Review Approved",
      customerEmailTitle: "",
      customerEmail: "",
      customerEmailEnable: false,
      adminEmailTitle: "",
      adminEmail: "",
      adminEmailEnable: false,
      variablesArr: [
        "{_customerEmail}",
        "{_adminEmail}",
        "{_adminName}",
        "{_medName}",

        "{_medUrl}",
      ],
    },
    reviewerInfoRequestEmail: {
      title: "Reviewer Info Request",
      customerEmailTitle: "",
      customerEmail: "",
      customerEmailEnable: false,
      adminEmailTitle: "",
      adminEmail: "",
      adminEmailEnable: false,
      variablesArr: ["{_customerEmail}", "{_adminEmail}", "{_adminName}"],
    },
    newSubscriptionEmail: {
      title: "New Subscription",
      customerEmailTitle: "",
      customerEmail: "",
      customerEmailEnable: false,
      adminEmailTitle: "",
      adminEmail: "",
      adminEmailEnable: false,
      variablesArr: ["{_customerEmail}", "{_adminEmail}", "{_adminName}"],
    },
    contactUsFormSubmittedEmail: {
      title: "Contact Us Form Submitted",
      customerEmailTitle: "",
      customerEmail: "",
      customerEmailEnable: false,
      adminEmailTitle: "",
      adminEmail: "",
      adminEmailEnable: false,
      variablesArr: ["{_customerEmail}", "{_adminEmail}", "{_adminName}"],
    },
    customerUnblockRequestEmail: {
      title: "Customer Unblock request",
      customerEmailTitle: "",
      customerEmail: "",
      customerEmailEnable: false,
      adminEmailTitle: "",
      adminEmail: "",
      adminEmailEnable: false,
      variablesArr: [
        "{_customerEmail}",
        "{_customerName}",
        "{_adminEmail}",
        "{_adminName}",
      ],
    },
  });

  const [showRules, setShowRules] = useState(false);

  // LocalData
  // const localLangData = JSON.parse(localStorage.getItem("lang"));
  const localData = JSON.parse(localStorage.getItem("adminLoginInfo"));

  //   Store
  const { managedContentSettings } = useSelector(
    (state) => state.managedContentSettings
  );

  const handleCustomerEmail = (e, editor) => {
    let object = { ...tempEmail };
    object.customerEmail = editor.getData();
    setTempEmail(object);
  };

  const handleAdminEmail = (e, editor) => {
    let object = { ...tempEmail };
    object.adminEmail = editor.getData();
    setTempEmail(object);
  };

  const handleViewTemplateBufferClose = () => {
    setViewTemplateBuffer({});
    setTempEmail({
      customerEmail: "",
      adminEmail: "",
    });
    setTempEmailTitle({
      customerEmailTitle: "",
      adminEmailTitle: "",
    });
    setShowRules(false);
    setLoading(false);
  };

  const handleTemplateSave = () => {
    setLoading(true);
    let obj = { ...emailTemplates };
    let tempObj = { ...obj[viewTemplateBuffer.objectKey] };
    tempObj.customerEmail = tempEmail.customerEmail;
    tempObj.adminEmail = tempEmail.adminEmail;

    tempObj.customerEmailEnable = tempEmailCheckBox.customerEmailEnable;
    tempObj.adminEmailEnable = tempEmailCheckBox.adminEmailEnable;

    tempObj.customerEmailTitle = tempEmailTitle.customerEmailTitle;
    tempObj.adminEmailTitle = tempEmailTitle.adminEmailTitle;

    // tempObj.variablesArr = [
    //   "{_customerEmail}",
    //   "{_adminName}",
    //   "{_adminEmail}",
    //   "{_customerName}",
    // ];

    obj[viewTemplateBuffer.objectKey] = { ...tempObj };
    setEmailTemplates(obj);

    dispatch(
      saveManagedContentSettings({
        langId,
        changedSection: "Email Templates",
        emailTemplates: obj,
        creatorId: localData._id,
      })
    );
    setTimeout(() => {
      handleViewTemplateBufferClose();
      setLoading(false);
      dispatch(getNotification());
      dispatch(getManagedContentSettings());
    }, 1500);
  };

  const saveAdminInfo = () => {
    setLoading(true);
    dispatch(
      saveManagedContentSettings({
        langId,
        changedSection: "Admin Info",
        adminInfo,
        creatorId: localData._id,
      })
    );
    setTimeout(() => {
      handleViewTemplateBufferClose();
      setLoading(false);
      dispatch(getNotification());
      dispatch(getManagedContentSettings());
    }, 1500);
  };

  useEffect(() => {
    if (viewTemplateBuffer.customerEmail !== undefined) {
      setTempEmailCheckBox({
        customerEmailEnable: viewTemplateBuffer.customerEmailEnable,
        adminEmailEnable: viewTemplateBuffer.adminEmailEnable,
      });
      setTempEmailTitle({
        customerEmailTitle: viewTemplateBuffer.customerEmailTitle,
        adminEmailTitle: viewTemplateBuffer.adminEmailTitle,
      });
      setTempEmail({
        customerEmail: viewTemplateBuffer.customerEmail,
        adminEmail: viewTemplateBuffer.adminEmail,
      });
    }
  }, [viewTemplateBuffer]);

  useEffect(() => {
    setLoadingMain(true);
    if (!(managedContentSettings && managedContentSettings.length > 0))
      dispatch(getManagedContentSettings());
    setTimeout(() => {
      setLoadingMain(false);
    }, 1000);
  }, []);

  // Set Settings
  useEffect(() => {
    if (managedContentSettings && managedContentSettings.length > 0) {
      const M = managedContentSettings.find((m) => m.langId === langId);
      if (M && M.emailTemplates) {
        setEmailTemplates(M.emailTemplates);
      } else {
        setEmailTemplates({
          emailVerificationEmail: {
            title: "Email Verification ",
            customerEmailTitle: "",
            customerEmail: "",
            customerEmailEnable: false,
            adminEmailTitle: "",
            adminEmail: "",
            adminEmailEnable: false,
            variablesArr: [
              "{_verifyCode}",
              "{_customerEmail}",
              "{_adminEmail}",
              "{_adminName}",
            ],
          },
          registrationEmail: {
            title: "Registration Success",
            customerEmailTitle: "",
            customerEmail: "",
            customerEmailEnable: false,
            adminEmailTitle: "",
            adminEmail: "",
            adminEmailEnable: false,
            variablesArr: [
              "{_customerEmail}",
              "{_customerName}",
              "{_adminEmail}",
              "{_adminName}",
            ],
          },
          askPriceEmail: {
            title: "Ask Price",
            customerEmailTitle: "",
            customerEmail: "",
            customerEmailEnable: false,
            adminEmailTitle: "",
            adminEmail: "",
            adminEmailEnable: false,
            variablesArr: [
              "{_customerEmail}",
              "{_customerName}",
              "{_adminEmail}",
              "{_adminName}",
              "{_medName}",
            ],
          },
          priceAssignEmail: {
            title: "Price Assigned",
            customerEmailTitle: "",
            customerEmail: "",
            customerEmailEnable: false,
            adminEmailTitle: "",
            adminEmail: "",
            adminEmailEnable: false,
            variablesArr: [
              "{_customerEmail}",
              "{_customerName}",
              "{_adminEmail}",
              "{_adminName}",
              "{_medName}",
              "{_medUrl}",
            ],
          },
          paymentReceiptRejectedEmail: {
            title: "Payment Receipt Rejected",
            customerEmailTitle: "",
            customerEmail: "",
            customerEmailEnable: false,
            adminEmailTitle: "",
            adminEmail: "",
            adminEmailEnable: false,
            variablesArr: [
              "{_customerEmail}",
              "{_customerName}",
              "{_adminEmail}",
              "{_adminName}",
              "{_invoice}",
            ],
          },
          AssignedPriceUpdatedEmail: {
            title: "Assigned Price Updated",
            customerEmailTitle: "",
            customerEmail: "",
            customerEmailEnable: false,
            adminEmailTitle: "",
            adminEmail: "",
            adminEmailEnable: false,
            variablesArr: [
              "{_customerEmail}",
              "{_customerName}",
              "{_adminEmail}",
              "{_adminName}",
              "{_medName}",
              "{_medUrl}",
            ],
          },
          orderCreationEmail: {
            title: "Order Created",
            customerEmailTitle: "",
            customerEmail: "",
            customerEmailEnable: false,
            adminEmailTitle: "",
            adminEmail: "",
            adminEmailEnable: false,
            variablesArr: [
              "{_customerEmail}",
              "{_customerName}",
              "{_adminEmail}",
              "{_adminName}",
              "{_invoice}",
            ],
          },
          paymentAccountAssignedEmail: {
            title: "Payment Account Assigned",
            customerEmailTitle: "",
            customerEmail: "",
            customerEmailEnable: false,
            adminEmailTitle: "",
            adminEmail: "",
            adminEmailEnable: false,
            variablesArr: [
              "{_customerEmail}",
              "{_customerName}",
              "{_adminEmail}",
              "{_adminName}",
              "{_invoice}",
            ],
          },
          paymentReceiptUploadedEmail: {
            title: "payment Receipt Uploaded",
            customerEmailTitle: "",
            customerEmail: "",
            customerEmailEnable: false,
            adminEmailTitle: "",
            adminEmail: "",
            adminEmailEnable: false,
            variablesArr: [
              "{_customerEmail}",
              "{_customerName}",
              "{_adminEmail}",
              "{_adminName}",
              "{_invoice}",
            ],
          },
          paymentConfirmedNShippingEmail: {
            title: "Payment Confirmed & Shipping",
            customerEmailTitle: "",
            customerEmail: "",
            customerEmailEnable: false,
            adminEmailTitle: "",
            adminEmail: "",
            adminEmailEnable: false,
            variablesArr: [
              "{_customerEmail}",
              "{_customerName}",
              "{_adminEmail}",
              "{_adminName}",
              "{_invoice}",
            ],
          },
          shippingCompleteEmail: {
            title: "Shipping Complete",
            customerEmailTitle: "",
            customerEmail: "",
            customerEmailEnable: false,
            adminEmailTitle: "",
            adminEmail: "",
            adminEmailEnable: false,
            variablesArr: [
              "{_customerEmail}",
              "{_customerName}",
              "{_adminEmail}",
              "{_adminName}",
              "{_invoice}",
            ],
          },
          shippingConfirmationEmail: {
            title: "Shipping Confirmed",
            customerEmailTitle: "",
            customerEmail: "",
            customerEmailEnable: false,
            adminEmailTitle: "",
            adminEmail: "",
            adminEmailEnable: false,
            variablesArr: [
              "{_customerEmail}",
              "{_customerName}",
              "{_adminEmail}",
              "{_adminName}",
              "{_invoice}",
            ],
          },
          reviewSubmittedEmail: {
            title: "Review Submitted",
            customerEmailTitle: "",
            customerEmail: "",
            customerEmailEnable: false,
            adminEmailTitle: "",
            adminEmail: "",
            adminEmailEnable: false,
            variablesArr: [
              "{_customerEmail}",
              "{_adminEmail}",
              "{_adminName}",
              "{_medName}",
              "{_medUrl}",
            ],
          },
          reviewApprovedEmail: {
            title: "Review Approved",
            customerEmailTitle: "",
            customerEmail: "",
            customerEmailEnable: false,
            adminEmailTitle: "",
            adminEmail: "",
            adminEmailEnable: false,
            variablesArr: [
              "{_customerEmail}",
              "{_adminEmail}",
              "{_adminName}",
              "{_medName}",
              "{_medUrl}",
            ],
          },
          reviewerInfoRequestEmail: {
            title: "Reviewer Info Request",
            customerEmailTitle: "",
            customerEmail: "",
            customerEmailEnable: false,
            adminEmailTitle: "",
            adminEmail: "",
            adminEmailEnable: false,
            variablesArr: ["{_customerEmail}", "{_adminEmail}", "{_adminName}"],
          },
          newSubscriptionEmail: {
            title: "New Subscription",
            customerEmailTitle: "",
            customerEmail: "",
            customerEmailEnable: false,
            adminEmailTitle: "",
            adminEmail: "",
            adminEmailEnable: false,
            variablesArr: ["{_customerEmail}", "{_adminEmail}", "{_adminName}"],
          },
          contactUsFormSubmittedEmail: {
            title: "Contact Us Form Submitted",
            customerEmailTitle: "",
            customerEmail: "",
            customerEmailEnable: false,
            adminEmailTitle: "",
            adminEmail: "",
            adminEmailEnable: false,
            variablesArr: ["{_customerEmail}", "{_adminEmail}", "{_adminName}"],
          },
          customerUnblockRequestEmail: {
            title: "Customer Unblock request",
            customerEmailTitle: "",
            customerEmail: "",
            customerEmailEnable: false,
            adminEmailTitle: "",
            adminEmail: "",
            adminEmailEnable: false,
            variablesArr: [
              "{_customerEmail}",
              "{_customerName}",
              "{_adminEmail}",
              "{_adminName}",
            ],
          },
        });
      }
      if (M && M.adminInfo) {
        setAdminInfo(M.adminInfo);
      } else {
        setAdminInfo({
          adminName: "MFW TEAM",
          adminEmail: "info@medicineforworld.com.bd",
          invoiceTableHtml: "",
        });
      }
    }
  }, [langId, managedContentSettings]);

  // ! CK Editor Image Upload
  const dispatch = useDispatch();
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          let tempName = Math.floor(Math.random() * (10000 - 0) + 0).toString();
          loader.file
            .then((file) => {
              const data = new FormData();
              if (file) {
                tempName = tempName + "." + file.name.split(".").pop();
                data.append("name", tempName);
                data.append("tempImg", file);
                dispatch(uploadTempPic(data));
                dispatch(
                  createNoti({
                    message: "Pls click on the image Confirm Image Upload",
                    severity: "warning",
                  })
                );
              }
            })
            .then(() => {
              resolve({
                default: `${PF}temp/${tempName}`,
              });
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }
  // ! CK Editor Image Upload

  // Store
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate

  return (
    <>
      <LangNTitleBar
        lang={lang}
        setLang={setLang}
        langId={langId}
        setLangId={setLangId}
        title={translate0("Email Templates")}
      />
      {loadingMain ? (
        <LoadingBox w={"100%"} h={200} />
      ) : (
        <Box sx={{ my: 1 }}>
          <Box
            sx={{
              border: "1px solid lightblue",
              borderRadius: ".25rem",
              p: 1,
              mb: 1,
            }}
          >
            <TextField
              variant="outlined"
              label={translate0("Admin Name")}
              value={adminInfo.adminName}
              onChange={(e) => {
                let obj = { ...adminInfo };
                obj.adminName = e.target.value;
                setAdminInfo(obj);
              }}
              size="small"
              fullWidth
              sx={{ my: 1 }}
            />
            <TextField
              variant="outlined"
              label={translate0("Admin Email")}
              value={adminInfo.adminEmail}
              onChange={(e) => {
                let obj = { ...adminInfo };
                obj.adminEmail = e.target.value;
                setAdminInfo(obj);
              }}
              size="small"
              fullWidth
              sx={{ my: 1 }}
            />
            <Accordion sx={{ mb: 1 }}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-label="Expand"
                aria-controls="invoice-content"
                id="invoice-header"
              >
                <Typography>{translate0("Invoice Table Html")}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TextField
                  variant="outlined"
                  label={translate0("Invoice Table Html")}
                  value={adminInfo.invoiceTableHtml}
                  onChange={(e) => {
                    let obj = { ...adminInfo };
                    obj.invoiceTableHtml = e.target.value;
                    setAdminInfo(obj);
                  }}
                  multiline
                  minRows={3}
                  size="small"
                  fullWidth
                  sx={{ my: 1 }}
                />
              </AccordionDetails>
            </Accordion>

            <LoadingButton
              loading={loading}
              variant="contained"
              size="small"
              fullWidth
              color="success"
              onClick={saveAdminInfo}
            >
              {translate0("Save")}
            </LoadingButton>
          </Box>
          <Grid container spacing={1}>
            {Object.keys(emailTemplates).length > 0 &&
              Object.keys(emailTemplates).map((x, i) => (
                <Grid item xs={12} sm={6} key={i}>
                  <Button
                    variant="outlined"
                    size="small"
                    fullWidth
                    color="secondary"
                    endIcon={<SettingsIcon />}
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    onClick={() =>
                      setViewTemplateBuffer({
                        ...emailTemplates[x],
                        objectKey: x,
                      })
                    }
                  >
                    {translate0(emailTemplates[x].title)
                      ? translate0(emailTemplates[x].title)
                      : emailTemplates[x].title}
                  </Button>
                </Grid>
              ))}
          </Grid>
        </Box>
      )}
      {/* Dialog */}
      {viewTemplateBuffer.customerEmail !== undefined && (
        <Dialog
          open={viewTemplateBuffer.customerEmail !== undefined}
          fullWidth
          maxWidth={"md"}
        >
          <DialogTitle>
            {translate0("Manage")} "{viewTemplateBuffer.title}" Template
          </DialogTitle>
          <DialogContent>
            <Button
              variant="contained"
              size="small"
              fullWidth
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 1,
                mb: 0.5,
              }}
              color="info"
              endIcon={<InfoIcon />}
              onClick={() => setShowRules(!showRules)}
            >
              {translate0("Variables")}
            </Button>
            {viewTemplateBuffer.variablesArr &&
              viewTemplateBuffer.variablesArr.length > 0 &&
              showRules && (
                <Box
                  sx={{
                    p: 1,
                    border: "1px solid pink",
                    borderRadius: ".25rem",
                  }}
                >
                  {viewTemplateBuffer.variablesArr.includes(
                    "{_verifyCode}"
                  ) && (
                    <Typography
                      variant="body2"
                      color="initial"
                    >{`verify Code = {_verifyCode}`}</Typography>
                  )}

                  {viewTemplateBuffer.variablesArr.includes(
                    "{_customerName}"
                  ) && (
                    <Typography
                      variant="body2"
                      color="initial"
                    >{`Customer Name = {_customerName}`}</Typography>
                  )}

                  {viewTemplateBuffer.variablesArr.includes(
                    "{_customerEmail}"
                  ) && (
                    <Typography
                      variant="body2"
                      color="initial"
                    >{`customerEmail = {_customerEmail}`}</Typography>
                  )}

                  {viewTemplateBuffer.variablesArr.includes("{_adminName}") && (
                    <Typography
                      variant="body2"
                      color="initial"
                    >{`Admin Name = {_adminName}`}</Typography>
                  )}

                  {viewTemplateBuffer.variablesArr.includes(
                    "{_adminEmail}"
                  ) && (
                    <Typography
                      variant="body2"
                      color="initial"
                    >{`Admin Email = {_adminEmail}`}</Typography>
                  )}

                  {viewTemplateBuffer.variablesArr.includes("{_invoice}") && (
                    <Typography
                      variant="body2"
                      color="initial"
                    >{`Invoice Table = {_invoice}`}</Typography>
                  )}

                  <Typography
                    variant="body2"
                    color="initial"
                  >{`Domain Without https:// = {_domain}`}</Typography>
                  <Typography
                    variant="body2"
                    color="initial"
                  >{`Backend Url Without https:// = {_backendUrl}`}</Typography>

                  {viewTemplateBuffer.variablesArr.includes("{_medName}") && (
                    <Typography
                      variant="body2"
                      color="initial"
                    >{`Medicine Name = {_medName}`}</Typography>
                  )}
                </Box>
              )}
            <Button
              variant="outlined"
              size="small"
              fullWidth
              sx={{ pointerEvents: "none", mt: 1, mb: 0.5 }}
            >
              Customer Email Template
            </Button>

            <TextField
              variant="outlined"
              label="Customer Email Title"
              value={tempEmailTitle.customerEmailTitle}
              onChange={(e) => {
                let obj = { ...tempEmailTitle };
                obj.customerEmailTitle = e.target.value;
                setTempEmailTitle(obj);
              }}
              size="small"
              fullWidth
              sx={{ my: 1 }}
            />
            <CKEditor
              editor={Editor}
              data={viewTemplateBuffer.customerEmail}
              config={{
                extraPlugins: [uploadPlugin],
              }}
              onBlur={(e, editor) => handleCustomerEmail(e, editor)}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={tempEmailCheckBox.customerEmailEnable}
                  onChange={(e) => {
                    let obj = { ...tempEmailCheckBox };
                    obj.customerEmailEnable = e.target.checked;
                    setTempEmailCheckBox(obj);
                  }}
                />
              }
              label={translate0("Active")}
            />
            <Button
              variant="outlined"
              size="small"
              fullWidth
              sx={{ pointerEvents: "none", mt: 1, mb: 0.5 }}
            >
              Admin Email Template
            </Button>
            <TextField
              variant="outlined"
              label="Admin Email Title"
              value={tempEmailTitle.adminEmailTitle}
              onChange={(e) => {
                let obj = { ...tempEmailTitle };
                obj.adminEmailTitle = e.target.value;
                setTempEmailTitle(obj);
              }}
              size="small"
              fullWidth
              sx={{ my: 1 }}
            />
            <CKEditor
              editor={Editor}
              data={viewTemplateBuffer.adminEmail}
              config={{
                extraPlugins: [uploadPlugin],
              }}
              onBlur={(e, editor) => handleAdminEmail(e, editor)}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={tempEmailCheckBox.adminEmailEnable}
                  onChange={(e) => {
                    let obj = { ...tempEmailCheckBox };
                    obj.adminEmailEnable = e.target.checked;
                    setTempEmailCheckBox(obj);
                  }}
                />
              }
              label={translate0("Active")}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleViewTemplateBufferClose} color="error">
              {translate0("Close")}
            </Button>
            <LoadingButton
              loading={loading}
              onClick={handleTemplateSave}
              color="success"
            >
              {translate0("Save")}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
