import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import {
  AppBar,
  Badge,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

// Icons
import CloseIcon from "@mui/icons-material/Close";
import BackspaceIcon from "@mui/icons-material/Backspace";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";

import { useDispatch, useSelector } from "react-redux";
import { getMedicines } from "../../../reduxToolkit/features/adminPanel/medicine";
import LoadingBox from "../../misc/Loading";
import { makeUrlFriendly } from "../../../hooks/makeUrlFriendly";
import ProductSmall from "../../product/ProductSmall";
import { clearWishlist } from "../../../reduxToolkit/features/frontend/wishlist";
import LoadingButton from "@mui/lab/LoadingButton";

// Media Query
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

export default function WishListDrawer({ wishOpen, setWishOpen }) {
  // Media Query
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const dispatch = useDispatch();
  //   UseState
  const [emptyWish, setEmptyWish] = useState(false);

  const [loadingMain, setLoadingMain] = useState(false);
  const [lang, setLang] = useState("");

  const [wishMedArr, setWishMedArr] = useState([]);

  const [catList, setCatList] = useState([]);

  // LocalData
  const localCustomerData = JSON.parse(localStorage.getItem("loginInfo"));

  // Store
  const { medicines, categories } = useSelector((state) => state.medicine);
  const { activeLangFront } = useSelector((state) => state.miscFront);
  const { wishlist } = useSelector((state) => state.wishlist);

  // Function
  const removeAllWishList = () => {
    dispatch(clearWishlist());
    setLoadingMain(true);
    setTimeout(() => {
      setEmptyWish(false);
      setLoadingMain(false);
    }, 1000);
  };

  // Initialization
  useEffect(() => {
    // if (!(medicines && medicines.length > 0)) dispatch(getMedicines());
  }, []);

  // Set Language
  useEffect(() => {
    if (activeLangFront) setLang(activeLangFront.langCode);
  }, [activeLangFront]);

  // set Category
  useEffect(() => {
    if (lang !== "" && categories && categories.length > 0) {
      let C = categories.find((c) => c.lang === lang);
      if (C) setCatList(C.data);
      else setCatList([]);
    }
  }, [categories, lang]);

  useEffect(() => {
    setLoadingMain(true);
    if (medicines && medicines.length > 0) {
      let arr = [];
      const M = medicines.find((x) => x.lang === lang);
      if (M && M.data) {
        if (wishlist && wishlist.length > 0) {
          wishlist.forEach((x) => {
            const med = M.data.find(
              (y) =>
                (lang !== "en" ? y.medicineDetectorId : y.fixedMedicineId) === x
            );
            if (med) {
              arr.push(med);
            }
          });
        }
      }
      setWishMedArr(arr);
    }
    setTimeout(() => {
      setLoadingMain(false);
    }, 1000);
  }, [medicines, wishlist, lang]);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate

  return (
    <>
      {/* <Dialog
        open={wishOpen}
        aria-labelledby={"compare"}
        TransitionComponent={Transition}
        fullScreen={!smUp}
      > */}
      <Box sx={{ minWidth: !smUp ? "100vw" : "700px" }}>
        <AppBar sx={{ position: "sticky" }}>
          <Toolbar>
            <Grid
              container
              spacing={0}
              justifyContent="space-between"
              alignItems="center"
              sx={{ minWidth: 300 }}
            >
              <Grid item>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => setWishOpen(false)}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Grid>

              <Grid item sx={{ flexGrow: 1 }}>
                <Typography variant="h6">
                  {translateFront("Wishlist")}
                </Typography>
              </Grid>

              <Grid item>
                <Button
                  variant="contained"
                  endIcon={<BackspaceIcon />}
                  size="small"
                  color="error"
                  fullWidth
                  onClick={() => setEmptyWish(true)}
                  disabled={wishlist.length < 1 ? true : false}
                >
                  {translateFront("Clear All")}
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>

        <Grid
          container
          spacing={2}
          flexDirection="column"
          alignItems="center"
          sx={{ px: 1, mt: 1 }}
        >
          {loadingMain ? (
            <LoadingBox />
          ) : (
            <Grid item xs={12}>
              <Grid container spacing={1} flexDirection="row">
                {wishMedArr.map((x, i) => (
                  <Grid item key={i} xs={12} sm={6} md={4}>
                    <ProductSmall
                      x={x}
                      i={i}
                      view={0}
                      catList={catList}
                      lang={lang}
                      wishListW={300}
                      setWishOpen={setWishOpen}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>

      <Dialog
        open={emptyWish}
        onClose={() => setEmptyWish(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {translateFront("Empty Wishlist?")}
        </DialogTitle>

        <DialogActions>
          <Button onClick={() => setEmptyWish(false)}>
            {translateFront("No")}
          </Button>
          <LoadingButton
            onClick={() => removeAllWishList()}
            loading={loadingMain}
            color="error"
          >
            {translateFront("Yes")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {/* </Dialog> */}
    </>
  );
}
