import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../config";

export const assignPriceByCustomer = createAsyncThunk(
  "assignPrice/assignPriceListByCustomer",
  async (data) => {
    const res = await axiosInstance.post("/api/assign-price/assign", data);
    return res.data;
  }
);

export const unassignPriceByCustomer = createAsyncThunk(
  "assignPrice/unassignPriceListByCustomer",
  async (data) => {
    const res = await axiosInstance.post("/api/assign-price/unassign", data);
    return res.data;
  }
);

export const getAssignedPriceByCustomer = createAsyncThunk(
  "assignPrice/getAssignedPriceListByCustomer",
  async (customerId) => {
    const res = await axiosInstance.get(
      `/api/assign-price/assigned-price-by-customer/${customerId}`
    );
    return res.data;
  }
);

export const getAssignedPriceByVariant = createAsyncThunk(
  "assignPrice/getAssignedPriceListByVariant",
  async (variantId) => {
    const res = await axiosInstance.get(
      `/api/assign-price/assigned-price-by-variant/${variantId}`
    );
    return res.data;
  }
);

//   Cancel Ask price
export const cancelAskPrice = createAsyncThunk(
  "askPrice/cancelAskPrice",
  async (data) => {
    const res = await axiosInstance.post(
      "/api/ask-price/cancel-price-req",
      data
    );
    return res.data;
  }
);

// Initial State
const initialState = {
  pending: false,
  error: false,
  snackNotiAssignPrice: {},
  assignPriceResponse: {},
  unassignPriceResponse: {},
  assignedPriceList: [],
  cancelAskPriceResponse: {},
  assignedPriceByVariant: { sellingPrice: 0, minimumOrderQty: 0 },
};

// Slice
export const assignPriceSlice = createSlice({
  name: "assignPrice",
  initialState,
  reducers: {
    resetSnkAssignPrice(state) {
      state.snackNotiAssignPrice = {};
      state.assignPriceResponse = {};
      state.unassignPriceResponse = {};
      state.cancelAskPriceResponse = {};
    },
  },
  extraReducers: {
    [assignPriceByCustomer.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [assignPriceByCustomer.fulfilled]: (state, action) => {
      state.pending = false;
      state.assignPriceResponse = action.payload;
      state.snackNotiAssignPrice = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [assignPriceByCustomer.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [unassignPriceByCustomer.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [unassignPriceByCustomer.fulfilled]: (state, action) => {
      state.pending = false;
      state.unassignPriceResponse = action.payload;
      state.snackNotiAssignPrice = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [unassignPriceByCustomer.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [getAssignedPriceByCustomer.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getAssignedPriceByCustomer.fulfilled]: (state, action) => {
      state.pending = false;
      state.assignedPriceList = action.payload;
    },
    [getAssignedPriceByCustomer.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [getAssignedPriceByVariant.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getAssignedPriceByVariant.fulfilled]: (state, action) => {
      state.pending = false;
      state.assignedPriceByVariant = action.payload;
    },
    [getAssignedPriceByVariant.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [cancelAskPrice.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [cancelAskPrice.fulfilled]: (state, action) => {
      state.pending = false;
      state.cancelAskPriceResponse = action.payload;
      state.snackNotiAssignPrice = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [cancelAskPrice.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
  },
});
//
export const { resetSnkAssignPrice } = assignPriceSlice.actions;
export default assignPriceSlice.reducer;
