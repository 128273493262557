import * as React from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Alert, Slide, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import { resetSnkAdmin } from "../../reduxToolkit/features/adminPanel/admin";
import { resetSnkRole } from "../../reduxToolkit/features/adminPanel/role";
import { resetSnkMisc } from "../../reduxToolkit/features/adminPanel/misc";
import { resetSnkMedicine } from "../../reduxToolkit/features/adminPanel/medicine";
import { resetSnkSupplier } from "../../reduxToolkit/features/adminPanel/supplier";
import { resetSnkCustomer } from "../../reduxToolkit/features/auth";
import { resetSnkWebSettings } from "../../reduxToolkit/features/adminPanel/webSettings";
import { resetSnkMiscFront } from "../../reduxToolkit/features/miscFront";
import { resetSnkCustomerOnProduct } from "../../reduxToolkit/features/customerOnProduct";
import { resetSnkReview } from "../../reduxToolkit/features/adminPanel/review";
import { resetSnkCompare } from "../../reduxToolkit/features/frontend/compare";
import { resetSnkWishlist } from "../../reduxToolkit/features/frontend/wishlist";
import { resetSnkCheckout } from "../../reduxToolkit/features/frontend/checkout";
import { resetSnkAssignPrice } from "../../reduxToolkit/features/adminPanel/assignPrice";
import { resetSnkCart } from "../../reduxToolkit/features/frontend/cart";
import { useEffect } from "react";
import { resetSnkDelivery } from "../../reduxToolkit/features/adminPanel/delivery";
import { resetSnkPayment } from "../../reduxToolkit/features/adminPanel/payment";
import { resetSnkOrderBackend } from "../../reduxToolkit/features/adminPanel/order";
import { resetSnkBlog } from "../../reduxToolkit/features/adminPanel/blog";
import { resetSnkManagedContentSettings } from "../../reduxToolkit/features/adminPanel/managedContentSettings";
import { resetSnkExpense } from "../../reduxToolkit/features/adminPanel/expense";

export default function SnackNotification({
  message,
  severity,
  openSnk,
  setOpenSnk,
}) {
  const dispatch = useDispatch();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    // dispatch(resetSnkAdmin());
    // dispatch(resetSnkRole());
    // dispatch(resetSnkMisc());
    // dispatch(resetSnkAssignPrice());
    // dispatch(resetSnkMedicine());
    // dispatch(resetSnkSupplier());
    // dispatch(resetSnkReview());
    // dispatch(resetSnkWebSettings());

    // // Frontend
    // dispatch(resetSnkCustomer());
    // dispatch(resetSnkMiscFront());
    // dispatch(resetSnkCustomerOnProduct());
    // dispatch(resetSnkCompare());
    // dispatch(resetSnkWishlist());
    // dispatch(resetSnkCheckout());
    // dispatch(resetSnkCart());

    setOpenSnk(false);
  };

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetSnkAdmin());
      dispatch(resetSnkRole());
      dispatch(resetSnkMisc());
      dispatch(resetSnkAssignPrice());
      dispatch(resetSnkMedicine());
      dispatch(resetSnkSupplier());
      dispatch(resetSnkReview());
      dispatch(resetSnkWebSettings());
      dispatch(resetSnkDelivery());
      dispatch(resetSnkPayment());
      dispatch(resetSnkBlog());
      dispatch(resetSnkOrderBackend());
      dispatch(resetSnkExpense());

      // Frontend
      dispatch(resetSnkCustomer());
      dispatch(resetSnkMiscFront());
      dispatch(resetSnkCustomerOnProduct());
      dispatch(resetSnkCompare());
      dispatch(resetSnkWishlist());
      dispatch(resetSnkCheckout());
      dispatch(resetSnkCart());
      dispatch(resetSnkManagedContentSettings());
    }, 500);
  }, [openSnk]);

  const action = (
    <React.Fragment>
      <Tooltip arrow placement="top" title="close">
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </React.Fragment>
  );

  return (
    <div>
      <Slide direction="up" in={openSnk} mountOnEnter unmountOnExit>
        <Snackbar
          open={openSnk}
          autoHideDuration={6000}
          onClose={handleClose}
          // message={message}
          action={action}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
            color={severity}
          >
            {message}
          </Alert>
        </Snackbar>
      </Slide>
    </div>
  );
}
