import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getNotification } from "../../../reduxToolkit/features/adminPanel/notification";
import {
  getCampaigns,
  subscriberBulkEmail,
} from "../../../reduxToolkit/features/auth";
import TemplateCard from "../component/TemplateCard";

export default function SendBulkEmailDialog({
  openDlg,
  setOpenDlg,
  templates,
  subs,
  selectedSubs,
  rerunCampaign,
  campaignBuffer,
  translate1,
  translate2,
}) {
  const dispatch = useDispatch();

  const [loadingEmailSend, setLoadingEmailSend] = useState(false);
  const [newCampaign, setNewCampaign] = useState({
    campaignName: "",
    templateId: "",
    emailArr: [],
  });

  // Local Data
  const localAdminInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));

  const getTemplateInfo = (id) => {
    let obj = {};
    const T = templates.find((t) => t._id === id);
    if (T) obj = T;
    return obj;
  };

  const handleSendEmailToSubs = () => {
    setLoadingEmailSend(true);
    let emailArr = [];
    if (rerunCampaign) {
      emailArr = newCampaign.emailArr;
    } else {
      selectedSubs.forEach((selected) => {
        subs.forEach((s) => {
          if (s.id === selected) {
            let emailObj = { name: s.name, email: s.email };
            emailArr.push(emailObj);
          }
        });
      });
    }

    let obj = { ...newCampaign };
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = today.getFullYear();
    const date = dd + "-" + mm + "-" + yyyy;

    const min = 1000;
    const max = 9999;
    const random = Math.floor(Math.random() * (max - min) + min);

    let templateName = "";
    if (obj.templateId !== "") {
      const T = templates.find((t) => t._id === obj.templateId);
      if (T) templateName = T.title;
    }
    if (obj.campaignName === "")
      obj.campaignName = `Batch-${random}-${templateName}-(${date})`;
    obj.emailArr = emailArr;
    if (localAdminInfo && localAdminInfo._id)
      obj.creatorId = localAdminInfo._id;

    dispatch(subscriberBulkEmail(obj));
    setTimeout(() => {
      dispatch(getNotification());
      dispatch(getCampaigns());
      handleCloseSendEmailToSubs();
      setLoadingEmailSend(false);
    }, 1000);
  };

  const handleCloseSendEmailToSubs = () => {
    setOpenDlg(false);
    setNewCampaign({
      campaignName: "",
      templateId: "",
      emailArr: [],
    });
  };

  useEffect(() => {
    if (campaignBuffer)
      setNewCampaign({
        campaignName: campaignBuffer.campaignName,
        templateId: campaignBuffer.templateId,
        emailArr: [...campaignBuffer.emailArr],
      });
  }, [campaignBuffer]);

  useEffect(() => {
    if (!rerunCampaign && selectedSubs && subs) {
      let emailArr = [];
      selectedSubs.forEach((selected) => {
        subs.forEach((s) => {
          if (s.id === selected) {
            let emailObj = { name: s.name, email: s.email };
            emailArr.push(emailObj);
          }
        });
      });
      let obj = { ...newCampaign };
      obj.emailArr = emailArr;
      setNewCampaign(obj);
    }
  }, []);
  return (
    <>
      <Dialog open={openDlg} fullWidth maxWidth={"md"}>
        <DialogTitle>{translate1("Start Campaign")}</DialogTitle>
        <DialogContent>
          <TextField
            label={translate1("Campaign Name")}
            value={newCampaign.campaignName}
            onChange={(e) => {
              let obj = { ...newCampaign };
              obj.campaignName = e.target.value;
              setNewCampaign(obj);
            }}
            size="small"
            variant="standard"
            fullWidth
            helperText={
              <Typography variant="body2" color="primary">
                {translate1("Keep empty to auto generate")} *
              </Typography>
            }
          />
          <FormControl
            fullWidth
            size="small"
            variant="standard"
            sx={{ my: 1 }}
            required
          >
            <InputLabel id="demo-simple-select-label" variant="standard">
              {translate1("Email Templates")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={newCampaign.templateId}
              label={translate1("Email Templates")}
              onChange={(e) => {
                let obj = { ...newCampaign };
                obj.templateId = e.target.value;
                setNewCampaign(obj);
              }}
            >
              {templates.map((x, i) => (
                <MenuItem value={x._id} key={i}>
                  {x.title}
                </MenuItem>
              ))}
            </Select>
            {newCampaign.templateId !== "" && (
              <Box
                sx={{
                  // height: { xs: "40vh", sm: "20vh" },
                  // overflowY: "auto",
                  // overflowX: "hidden",
                  p: 1,
                  my: 1,
                  border: ".1rem solid pink",
                  borderRadius: ".25rem",
                }}
              >
                <TemplateCard
                  x={getTemplateInfo(newCampaign.templateId)}
                  editable={false}
                />
              </Box>
            )}
            <Button
              variant="contained"
              size="small"
              fullWidth
              color="primary"
              sx={{ pointerEvents: "none" }}
            >
              {translate1("Recipient List")} ({newCampaign.emailArr.length})
            </Button>
            <Box
              sx={{
                maxHeight: "40vh",
                overflowY: "auto",
                overflowX: "hidden",
                border: ".1rem solid pink",
                borderRadius: ".25rem",
                p: 1,
                my: 1,
              }}
            >
              <Grid container spacing={0}>
                <Grid item xs={4}>
                  <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>
                    {translate1("Name")}
                  </Typography>
                </Grid>

                <Grid item xs={8}>
                  <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>
                    {translate1("Email")}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
              {/* {selectedSubs} */}
              {newCampaign.emailArr &&
                newCampaign.emailArr.length > 0 &&
                newCampaign.emailArr.map((y, j) => (
                  <Box key={j}>
                    <Grid container spacing={0}>
                      <Grid item xs={4}>
                        <Typography variant="body2">{y.name}</Typography>
                      </Grid>

                      <Grid item xs={8}>
                        <Typography variant="body2">{y.email}</Typography>
                      </Grid>
                    </Grid>
                    <Divider />
                  </Box>
                ))}
            </Box>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSendEmailToSubs} color="primary">
            {translate1("Close")}
          </Button>
          <LoadingButton
            loading={loadingEmailSend}
            onClick={handleSendEmailToSubs}
            color="success"
            disabled={newCampaign.templateId === ""}
          >
            {translate1("Send Email")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
