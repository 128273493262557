import { Breadcrumbs, Button, Divider, IconButton } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";
// Icons
import HomeIcon from "@mui/icons-material/Home";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

export default function BreadcrumbsTemplate({ b2, l2, b3 }) {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <>
      <Breadcrumbs
        separator={<KeyboardDoubleArrowRightIcon sx={{ color: "black" }} />}
        aria-label="breadcrumb"
        sx={{ mx: !smUp ? 0 : mdUp ? 20 : 5 }}
      >
        <Link underline="hover" color="inherit" to="/">
          <IconButton size="small">
            <HomeIcon color="primary" />
          </IconButton>
        </Link>
        {b2 && l2 && (
          <Link underline="hover" color="inherit" to={l2}>
            <Button variant="text" size="small">
              {b2}
            </Button>
          </Link>
        )}

        <Button
          variant="text"
          size="small"
          color="secondary"
          sx={{ pointerEvents: "none" }}
        >
          {/* {b2} */}
          {b3}
        </Button>
        {/* </Typography> */}
      </Breadcrumbs>
      <Divider />
    </>
  );
}
