import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Alert,
  Autocomplete,
  createFilterOptions,
} from "@mui/material";

import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import EuroOutlinedIcon from "@mui/icons-material/EuroOutlined";
import AuthDialogue from "./AuthDialogue";
import LogoutDialogue from "./LogoutDialogue";
import { useDispatch, useSelector } from "react-redux";
import SnackNotification from "../misc/SnackNotification";
import getSymbolFromCurrency from "currency-symbol-map";
import {
  checkIfBlockCustomer,
  closePromptLogin,
  logout,
  logOutUser,
  unblockRequest,
} from "../../reduxToolkit/features/auth";
import {
  getHeadTitle,
  getPaymentSettings,
  getSettings,
} from "../../reduxToolkit/features/adminPanel/webSettings";
import LoadingButton from "@mui/lab/LoadingButton";
import CaptchaTikTakToe from "../misc/CaptchaTikTakToe";
import {
  getCurrency,
  getLangs,
} from "../../reduxToolkit/features/adminPanel/misc";
import {
  getNotificationByCustomer,
  setActiveCurrencyFront,
  setActiveLangFront,
} from "../../reduxToolkit/features/miscFront";
import { getCompareList } from "../../reduxToolkit/features/frontend/compare";
import { getWishlist } from "../../reduxToolkit/features/frontend/wishlist";
import {
  getAskPriceListByCustomer,
  resetAskPriceListByCustomer,
} from "../../reduxToolkit/features/frontend/checkout";
import LoadingBox from "../misc/Loading";
import { getAssignedPriceByCustomer } from "../../reduxToolkit/features/adminPanel/assignPrice";
import { getCartInfoByCustomer } from "../../reduxToolkit/features/frontend/cart";
import { getBlogs } from "../../reduxToolkit/features/adminPanel/blog";
import {
  getCompanies,
  getDosages,
  getMedCategories,
  getMedicines,
  getMedicinesByLang,
  getViewCount,
} from "../../reduxToolkit/features/adminPanel/medicine";
import Seo from "../../utils/Seo";
import { PF } from "../../config";
import { getManagedContentSettings } from "../../reduxToolkit/features/adminPanel/managedContentSettings";
import LangImport from "../../screens/adminPanel/LangImport";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useNavigate } from "react-router-dom";

// Limit options to show in autocomplete
const OPTIONS_LIMIT = 8;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) => {
  return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
};

export default function TopBar() {
  // Responsive
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));

  const navigate = useNavigate();

  const [signUpOpen, setSignUpOpen] = useState(false);
  const [signInOpen, setSignInOpen] = useState(false);
  const [logoutD, setLogoutD] = useState(false);
  const [lang, setLang] = useState("en");
  const [langId, setLangId] = useState("");
  const [contentSettings, setContentSettings] = useState([]);
  const [headTitle, setHeadTitle] = useState("");
  const [loginStatus, setLoginStatus] = useState(false);

  // SnackBar
  const [openSnk, setOpenSnk] = useState(false);
  const [snkMessage, setSnkMessage] = useState("");
  const [snkSeverity, setSnkSeverity] = useState("");

  // Dlg
  const [unblockLoading, setUnBlockLoading] = useState(false);
  const [blockContactDlg, setBlockContactDlg] = useState(false);
  const [captchaDlg, setCaptchaDlg] = useState(false);
  const [blockContactBuffer, setBlockContactBuffer] = useState({});
  const [unBlockMsg, setUnBlockMsg] = useState("");

  // Currency
  // const [currencyLoading, setCurrencyLoading] = useState(false);
  // Local Store
  // const localData = JSON.parse(localStorage.getItem("loginInfo"));
  const localLangDataFront = JSON.parse(localStorage.getItem("langFront"));
  const localCurrencyDataFront = JSON.parse(
    localStorage.getItem("currencyFront")
  );
  const localCustomerData = () => {
    const c = JSON.parse(localStorage.getItem("loginInfo"));
    return c ? c : {};
  };

  const handleChangeLang = (e) => {
    setLang(e.target.value);
    const L = langs.find((l) => l.langCode === e.target.value);
    if (L) {
      localStorage.setItem("langFront", JSON.stringify(L));
      dispatch(setActiveLangFront(L));
    }
  };

  const handleLogin = () => {
    if (!loginStatus) {
      setSignInOpen(true);
    } else setLogoutD(true);
  };

  const handleUnBlockreq = () => {
    dispatch(
      unblockRequest({
        id: blockContactBuffer.id,
        message: unBlockMsg,
        langId: localLangDataFront._id,
      })
    );
    setUnBlockLoading(true);

    setTimeout(() => {
      setUnBlockLoading(false);
      setBlockContactDlg(false);
      setBlockContactBuffer({});
      setUnBlockMsg("");
    }, 2000);
  };

  // Store
  // const { viewCountArr } = useSelector((state) => state.medicine);
  const { snackNotiCustomer, checkBlockResponse, authData, loginPrompt } =
    useSelector((state) => state.auth);
  const { snackNotiMiscFront, activeLangFront } = useSelector(
    (state) => state.miscFront
  );
  const { snackNotiCustomerOnProduct } = useSelector(
    (state) => state.customerOnProduct
  );
  const { snackNotiCompare } = useSelector((state) => state.compare);
  const { snackNotiWishlist } = useSelector((state) => state.wishlist);
  const { snackNotiCheckout } = useSelector((state) => state.checkout);
  const { snackNotiCart } = useSelector((state) => state.cart);
  const { webSettings, headTitles } = useSelector((state) => state.webSettings);
  const { managedContentSettings } = useSelector(
    (state) => state.managedContentSettings
  );
  const { langs, currencyList } = useSelector((state) => state.misc);
  const { activeCurrencyFront } = useSelector((state) => state.miscFront);

  // Use Effect
  useEffect(() => {
    if (snackNotiCustomer && snackNotiCustomer.message) {
      setOpenSnk(true);
      setSnkMessage(snackNotiCustomer.message);
      setSnkSeverity(snackNotiCustomer.severity);
    }
    if (snackNotiMiscFront && snackNotiMiscFront.message) {
      setOpenSnk(true);
      setSnkMessage(snackNotiMiscFront.message);
      setSnkSeverity(snackNotiMiscFront.severity);
    }
    if (snackNotiCustomerOnProduct && snackNotiCustomerOnProduct.message) {
      setOpenSnk(true);
      setSnkMessage(snackNotiCustomerOnProduct.message);
      setSnkSeverity(snackNotiCustomerOnProduct.severity);
    }
    if (snackNotiCompare && snackNotiCompare.message) {
      setOpenSnk(true);
      setSnkMessage(snackNotiCompare.message);
      setSnkSeverity(snackNotiCompare.severity);
    }
    if (snackNotiWishlist && snackNotiWishlist.message) {
      setOpenSnk(true);
      setSnkMessage(snackNotiWishlist.message);
      setSnkSeverity(snackNotiWishlist.severity);
    }
    if (snackNotiCheckout && snackNotiCheckout.message) {
      setOpenSnk(true);
      setSnkMessage(snackNotiCheckout.message);
      setSnkSeverity(snackNotiCheckout.severity);
    }
    if (snackNotiCart && snackNotiCart.message) {
      setOpenSnk(true);
      setSnkMessage(snackNotiCart.message);
      setSnkSeverity(snackNotiCart.severity);
    }
  }, [
    snackNotiCustomer,
    snackNotiMiscFront,
    snackNotiCustomerOnProduct,
    snackNotiCompare,
    snackNotiWishlist,
    snackNotiCheckout,
    snackNotiCart,
  ]);

  const dispatch = useDispatch();

  // Check Block
  useEffect(() => {
    if (checkBlockResponse === true && localCustomerData()._id) {
      if (authData.customerInfo) {
        setBlockContactBuffer({
          id: authData.customerInfo._id,
          blockMsgCount: authData.customerInfo.unblockMessage.length,
        });
      }
      dispatch(logout());
      dispatch(logOutUser({ id: localCustomerData()._id }));
      setTimeout(() => {
        dispatch(getCompareList());
        dispatch(getWishlist());
        dispatch(resetAskPriceListByCustomer());
        setLoginStatus(false);
        setBlockContactDlg(true);
        setLoginStatus(false);
        localStorage.removeItem("loginInfo");
      }, 500);
    }
  }, [checkBlockResponse, authData]);

  // Handle Login / logout status
  useEffect(() => {
    dispatch(getCurrency());
    dispatch(getLangs());
    if (localCustomerData()._id) {
      dispatch(checkIfBlockCustomer(localCustomerData()._id));
      setTimeout(() => {
        if (localCustomerData().expire) {
          var now = new Date().getTime();
          var distance = localCustomerData().expire - now;
          if (distance < 0 || localCustomerData().block) {
            setLoginStatus(false);
            localStorage.removeItem("loginInfo");
            dispatch(logOutUser({ id: localCustomerData()._id }));
            dispatch(resetAskPriceListByCustomer());
            dispatch(getCompareList());
            dispatch(getWishlist());
          } else {
            setLoginStatus(true);
          }
        } else setLoginStatus(true);
      }, 1000);
    } else setLoginStatus(false);
    // Get backend web settings
    dispatch(getSettings());
    dispatch(getManagedContentSettings());
    dispatch(getPaymentSettings());
    dispatch(getHeadTitle());
    dispatch(getBlogs());
  }, []);

  useEffect(() => {
    if (activeLangFront) {
      setLang(activeLangFront.langCode);
      setLangId(activeLangFront._id);
    }
  }, [activeLangFront]);

  // Lang
  useEffect(() => {
    if (localLangDataFront) {
      setLang(localLangDataFront.langCode);
      setLangId(localLangDataFront._id);
      dispatch(setActiveLangFront(localLangDataFront));
    } else {
      if (langs.length > 0) {
        const L = langs.find((l) => l.langCode === "en");
        if (L) {
          setLangId(L._id);
          localStorage.setItem("langFront", JSON.stringify(L));
          dispatch(setActiveLangFront(L));
        }
      }
    }
  }, [langs]);

  useEffect(() => {
    if (headTitles && headTitles.length > 0 && langs && langs.length > 0) {
      const L = langs.find((l) => l.langCode === lang);
      if (L && L._id) {
        const H = headTitles.find((h) => h.langId === L._id);
        if (H) setHeadTitle(H.title);
        else {
          const LEn = langs.find((l) => l.langCode === "en");
          if (LEn && LEn._id) {
            const H = headTitles.find((h) => h.langId === LEn._id);
            if (H) setHeadTitle(H.title);
            else setHeadTitle("");
          }
        }
      }
    }
  }, [headTitles, lang, langs]);

  // Functions
  //   handle Currency Change
  const handleCurrencyChange = (e, newValue) => {
    const obj = {
      exchangeRate: newValue.exchangeRate,
      currencyName: newValue.currencyName,
    };
    localStorage.setItem("currencyFront", JSON.stringify(obj));
    dispatch(setActiveCurrencyFront(obj));
  };

  // Set Currency
  useEffect(() => {
    dispatch(getViewCount());
    if (
      localCurrencyDataFront !== null &&
      localCurrencyDataFront.currencyName
    ) {
      const obj = {
        exchangeRate: localCurrencyDataFront.exchangeRate,
        currencyName: localCurrencyDataFront.currencyName,
      };
      dispatch(setActiveCurrencyFront(obj));
    }
  }, []);

  useEffect(() => {
    if (loginPrompt) setSignInOpen(true);
    dispatch(closePromptLogin());
  }, [loginPrompt]);

  // Check login status
  useEffect(() => {
    dispatch(getAssignedPriceByCustomer(localCustomerData()._id));
    dispatch(getCartInfoByCustomer(localCustomerData()._id));
    dispatch(getNotificationByCustomer(localCustomerData()._id));
    // setTimeout(() => {}, 1000);
  }, [authData]);

  // Set Settings
  useEffect(() => {
    if (managedContentSettings && managedContentSettings.length > 0) {
      const M = managedContentSettings.find((m) => m.langId === langId);
      if (M) setContentSettings(M);
    }
  }, [langId, managedContentSettings]);

  useEffect(() => {
    if (langs && langs.length > 0) {
      const L = langs.find((l) => l.langCode === lang);
      if (L) {
        if (!L.active) {
          const LEn = langs.find((l) => l.langCode === "en");
          if (LEn) {
            setLangId(LEn._id);
            localStorage.setItem("langFront", JSON.stringify(LEn));
            dispatch(setActiveLangFront(LEn));
          }
        }
      }
    }
  }, [lang, langs]);

  // store
  const { medicines, categories, companies, dosages, viewCountArr } =
    useSelector((state) => state.medicine);

  useEffect(() => {
    // dispatch(getViewCount());
    if (lang !== "") {
      if (!(medicines && medicines.length > 0)) dispatch(getMedicines());
      // if (!(medicines && medicines.length > 0))
      //   dispatch(getMedicinesByLang({ lang }));
      if (!(categories && categories.length > 0)) dispatch(getMedCategories());
      if (!(companies && companies.length > 0)) dispatch(getCompanies());
      if (!(dosages && dosages.length > 0)) dispatch(getDosages());
      if (!(viewCountArr && viewCountArr.length > 0)) dispatch(getViewCount());
    }
  }, [lang]);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate

  return (
    <>
      <Seo
        title={
          (contentSettings.siteSeoSettings &&
            contentSettings.siteSeoSettings[0].siteName) ||
          "elifesaving Medicine"
        }
        description={
          (contentSettings.siteSeoSettings &&
            contentSettings.siteSeoSettings[0].siteDesc) ||
          "elifesaving Medicine is an online-based medicine-supplying service that provides safe and affordable lifesaving prescription medicines globally."
        }
        name={
          (contentSettings.siteSeoSettings &&
            contentSettings.siteSeoSettings[0].companyName) ||
          "elifesaving Medicine"
        }
        type="website"
        url={window.location.href || ""}
        imageUrl={
          webSettings.siteLogo !== ""
            ? PF + "/misc/" + webSettings.siteLogo
            : "https://mfw.com.bd/wp-content/uploads/2021/05/getImage.png"
        }
        langCode={lang}
      />
      <LangImport front={true} />

      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{
          background: webSettings.siteColorTheme || "lightblue",
          pb: 1,
          px: { xs: 1, md: 5 },
          maxWidth: "100vw",
          overflowX: "hidden",
        }}
      >
        <Grid item xs={12} sm={3} md={2}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={loginStatus ? true : false}
                  onChange={handleLogin}
                  aria-label="login switch"
                  color="error"
                />
              }
              label={
                loginStatus ? translateFront("Logout") : translateFront("Login")
              }
            />
            <AuthDialogue
              signUpOpen={signUpOpen}
              setSignUpOpen={setSignUpOpen}
              signInOpen={signInOpen}
              setSignInOpen={setSignInOpen}
              setLoginStatus={setLoginStatus}
            />
            <LogoutDialogue
              setLoginStatus={setLoginStatus}
              logoutD={logoutD}
              setLogoutD={setLogoutD}
            />
          </FormGroup>
        </Grid>
        {mdUp ? (
          <Grid item md={5}>
            <Typography
              variant="h6"
              sx={{
                borderBottom: ".3rem solid black",
                fontSize: xlUp ? null : "1rem",
              }}
              align="center"
            >
              {headTitle}
            </Typography>

            {/* <div className="elfsight-app-134bee78-cb26-454b-b72b-01580e9d50f8"></div> */}
          </Grid>
        ) : (
          <Grid item sm={3}>
            <Box
              sx={{
                display: mdUp ? "none" : "flex",
                justifyContent: "start",
                alignItems: "center",
                mx: 1,
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/");
                // dispatch(triggerPreloader());
              }}
            >
              <LazyLoadImage
                alt="Black"
                effect="blur"
                src={
                  webSettings.siteLogo !== ""
                    ? PF + "/misc/" + webSettings.siteLogo
                    : "https://mfw.com.bd/wp-content/uploads/2021/05/getImage.png"
                }
                style={{
                  objectFit: "cover",
                  // width: smUp && "100%",
                  maxHeight: !smUp ? "50px" : "50px",

                  // width: "100%",
                  margin: "0px 1px",
                  // borderRadius: "5rem",
                }}
              />
            </Box>
          </Grid>
        )}

        <Grid item xs={12} sm={6} md={4}>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="flex-end"
            spacing={2}
          >
            <Grid item xs={6}>
              <Box>
                <FormControl fullWidth>
                  <Select
                    value={lang}
                    label="language"
                    variant="standard"
                    onChange={handleChangeLang}
                    size="small"
                    sx={{ my: 1 }}
                  >
                    {langs.length > 0 &&
                      langs.map(
                        (x, i) =>
                          x.active && (
                            <MenuItem key={i} value={x.langCode} dense>
                              <img
                                alt={x.langCode}
                                src={`https://flagcdn.com/w20/${
                                  x.langCode === "en" ? "gb" : x.langCode
                                }.png`}
                              />
                              <Button color="inherit" size="small">
                                {x.langName}
                              </Button>
                            </MenuItem>
                          )
                      )}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            {/* <Grid item xs={1}>
            <Divider orientation="vertical" sx={{ mx: 1 }} />
          </Grid> */}
            <Grid item xs={6}>
              <Box>
                {currencyList &&
                  currencyList.length > 0 &&
                  activeCurrencyFront.currencyName && (
                    <Autocomplete
                      size="small"
                      onChange={(e, newValue) =>
                        handleCurrencyChange(e, newValue)
                      }
                      disablePortal
                      options={currencyList}
                      defaultValue={currencyList.find(
                        (c) =>
                          c.currencyName === activeCurrencyFront.currencyName
                      )}
                      filterOptions={filterOptions}
                      getOptionLabel={(option) =>
                        "(" +
                        getSymbolFromCurrency(option.currencyName) +
                        ") " +
                        option.currencyName
                      }
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{
                            "& > img": { mr: 2, flexShrink: 0 },
                          }}
                          {...props}
                        >
                          ({getSymbolFromCurrency(option.currencyName)}){" "}
                          {option.currencyName}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // label="Select Currency"
                          variant="standard"
                          size="small"
                          fullWidth
                          sx={{ my: 1 }}
                        />
                      )}
                    />
                  )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <SnackNotification
          message={snkMessage}
          setMessage={setSnkMessage}
          severity={snkSeverity}
          setSeverity={setSnkSeverity}
          openSnk={openSnk}
          setOpenSnk={setOpenSnk}
        />
        {/* Block Conctact */}
        {blockContactDlg && (
          <Dialog
            open={blockContactDlg}
            onClose={() => setBlockContactDlg(false)}
            fullWidth
            maxWidth={"lg"}
          >
            <DialogTitle>
              <Typography variant="h6" color="error" align="center">
                {" "}
                {translateFront("You Are Blocked For Unfair Usage")}
              </Typography>
              <Divider />
            </DialogTitle>
            <DialogContent>
              {blockContactBuffer.blockMsgCount >=
              (webSettings.maxUnblockReqCount || 2) ? (
                <Alert severity="error">
                  {translateFront("Maximum Limit Reached For Unblock Request")}
                </Alert>
              ) : (
                <>
                  <TextField
                    label="Message"
                    value={unBlockMsg}
                    onChange={(e) => setUnBlockMsg(e.target.value)}
                    fullWidth
                    multiline
                    minRows={3}
                    variant="standard"
                    size="small"
                    required
                  />
                  <Typography variant="body2" color="secondary">
                    {" "}
                    {translateFront(
                      "Please Check Email For Reviewing Reason For Blocking"
                    )}
                  </Typography>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <LoadingButton
                      variant="contained"
                      size="small"
                      sx={{ my: 1 }}
                      loading={unblockLoading}
                      disabled={unBlockMsg.length > 0 ? false : true}
                      onClick={() => setCaptchaDlg(true)}
                    >
                      {translateFront("Request Unblock")}
                    </LoadingButton>
                  </Box>
                </>
              )}
            </DialogContent>
          </Dialog>
        )}
        {/* Captcha */}
        <Dialog open={captchaDlg} onClose={() => setCaptchaDlg(false)}>
          <DialogContent>
            <CaptchaTikTakToe
              loginFunc={handleUnBlockreq}
              setCaptchaDlg={setCaptchaDlg}
            />
          </DialogContent>
        </Dialog>
      </Grid>
    </>
  );
}
