const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getMonthName = (date) => {
  const d = new Date(date);
  const mName = month[d.getMonth()];
  const year = d.getFullYear();
  return mName + " '" + year.toString().substr(-2);
};
