import { Grid, IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";

// Media Query
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function GoToTopButton() {
  const [visible, setVisible] = useState(false);

  // Responsive
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  window.addEventListener("scroll", toggleVisible);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate

  return (
    <Grid
      container
      spacing={0}
      justifyContent="flex-end"
      sx={{ display: smUp ? "block" : "none" }}
    >
      <Grid item xs={2}>
        <Tooltip arrow placement="top" title={translateFront("Go To Top")}>
          <IconButton
            size="small"
            sx={{
              position: "fixed",
              bottom: "10px",
              right: "0px",
              zIndex: 2,
              cursor: "pointer",
              display: visible ? "inline" : "none",
            }}
            onClick={scrollToTop}
          >
            <img
              src="https://img.icons8.com/color/48/000000/circled-up--v1.png"
              alt="go-to-top"
            />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
}
