import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {
  AppBar,
  Grid,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

// Icons
import CloseIcon from "@mui/icons-material/Close";
import CallMissedOutgoingIcon from "@mui/icons-material/CallMissedOutgoing";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import LoadingBox from "../../../../../components/misc/Loading";
import { format } from "date-fns";
import { axiosInstance, PF } from "../../../../../config";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompanies,
  getDosages,
  getMedCategories,
  getMedicines,
  leafletPdfDownload,
  resetPdfResponse,
} from "../../../../../reduxToolkit/features/adminPanel/medicine";
import { Route, Routes, useNavigate } from "react-router-dom";
import PreviewLeafletPage from "./PreviewLeafletPage";
import AdminHome from "../../../AdminHome";
import { setLeafletObj } from "../../../../../reduxToolkit/features/adminPanel/misc";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PreviewLeafletDlg({
  open,
  setOpen,
  selectedForPreview,
  settings,
  siteLang,
}) {
  //! Translate
  // Store
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);

  const [htmlToView, setHtmlToView] = useState(``);

  //   Store
  const {
    medicines,
    categories,
    companies,
    medVariants,
    dosages,
    leafletPdfDownloadResponse,
  } = useSelector((state) => state.medicine);

  useEffect(() => {
    if (!(medicines && medicines.length > 0)) dispatch(getMedicines());
    if (!(categories && categories.length > 0)) dispatch(getMedCategories());
    if (!(companies && companies.length > 0)) dispatch(getCompanies());
    if (!(dosages && dosages.length > 0)) dispatch(getDosages());
  }, []);

  const handleDownload = () => {
    setLoadingDownload(true);
    const fileName = new Date().getTime();
    dispatch(
      leafletPdfDownload({ htmlTemplate: htmlToView, fileName: fileName })
    );
  };

  const navigate = useNavigate();
  const handleOpenInNewPage = () => {
    setLoadingOpen(true);
    dispatch(setLeafletObj({ selectedForPreview, settings, siteLang }));
    setTimeout(() => {
      navigate("/medicines/leaflet");
      setLoadingOpen(false);
      // window.open("http://localhost:3000/medicines/leaflet", "_blank");
    }, 3000);
  };

  useEffect(() => {
    if (leafletPdfDownloadResponse && leafletPdfDownloadResponse.filename) {
      window.open(
        PF + "files/PDFs/" + leafletPdfDownloadResponse.filename,
        "_blank"
      );
      setTimeout(() => {
        setLoadingDownload(false);
        dispatch(resetPdfResponse());
      }, 1000);
    }
  }, [leafletPdfDownloadResponse]);

  const getImage = (imageArr) => {
    let imageUrl = ``;
    if (imageArr && imageArr.length > 0 && imageArr[0].src) {
      if (imageArr[0].src !== "")
        imageUrl = PF + "adminPanel/medicines/img/" + imageArr[0].src;
      else imageUrl = PF + "adminPanel/medicines/no-medicine-image.jpg";
    }
    return imageUrl;
  };

  // Get Category
  const [catList, setCatList] = useState([]);
  useEffect(() => {
    if (categories && categories.length > 0) {
      const C = categories.find((c) => c.lang === siteLang);
      if (C && C.data) {
        setCatList(C.data);
      }
    }
  }, [categories, siteLang]);

  const getCatString = (subCatArr) => {
    let cat = "";
    let catArr = [];
    subCatArr?.forEach((x) => {
      catList.forEach((y) => {
        let Sc = y.subCatList.find(
          (sc) =>
            (siteLang && siteLang !== "en"
              ? sc.subCatDetectorId
              : sc.fixedSubCatId) === x.subCatId
        );
        if (Sc) catArr.push(y.catName);
      });
    });
    let uniqueCatArr = [...new Set(catArr)];
    cat = uniqueCatArr.join(", ");
    return cat;
  };

  // Get Sub Category
  const getSubCatString = (subCatArr) => {
    let subCat = "";
    let scArr = [];
    subCatArr?.forEach((x) => {
      catList.forEach((y) => {
        let Sc = y.subCatList.find(
          (sc) =>
            (siteLang && siteLang !== "en"
              ? sc.subCatDetectorId
              : sc.fixedSubCatId) === x.subCatId
        );
        if (Sc) scArr.push(Sc.subCatName);
      });
    });
    let uniqueSubCatArr = [...new Set(scArr)];
    subCat = uniqueSubCatArr.join(", ");
    return subCat;
  };

  // Get Company
  const [companyList, setCompanyList] = useState([]);
  useEffect(() => {
    if (companies && companies.length > 0) {
      const C = companies.find((c) => c.lang === siteLang);
      if (C && C.data) {
        setCompanyList(C.data);
      }
    }
  }, [companies, siteLang]);

  const getCompanyString = (companyId) => {
    let companyString = "";
    let C = companyList.find(
      (c) =>
        (siteLang && siteLang === "en"
          ? c.fixedCompanyId
          : c.companyDetectorId) === companyId
    );
    if (C) {
      companyString = C.companyName;
    }
    return companyString;
  };

  // Get Variant Info
  const [dosageList, setDosageList] = useState([]);
  useEffect(() => {
    if (siteLang !== "" && dosages && dosages.length > 0) {
      const D = dosages.find((d) => d.lang === siteLang);
      if (D && D.data) setDosageList(D.data);
      else setDosageList([]);
    }
  }, [dosages, siteLang]);

  const getVariantInfo = (id) => {
    const MV = medVariants.find((mv) => mv.fixedVariantId === id);
    return MV;
  };

  const getDosageInfo = (dosageId) => {
    let dosageName = "";
    const D = dosageList.find(
      (d) =>
        (siteLang !== "en" ? d.dosageDetectorId : d.fixedDosageId) === dosageId
    );
    if (D && D.dosageName) dosageName = D.dosageName;

    return dosageName;
  };

  const getVariantStringObj = (variantArr) => {
    let obj = {
      dosageString: "",
      strengthString: "",
      packTypesString: "",
      priceString: "",
    };
    let dosageArr = [];
    let strengthArr = [];
    let packTypesArr = [];
    let priceArr = [];
    variantArr.forEach((z) => {
      dosageArr.push(getDosageInfo(getVariantInfo(z.variantId)?.dosageForm));
      strengthArr.push(getVariantInfo(z.variantId)?.strength);
      packTypesArr.push(getVariantInfo(z.variantId)?.packTypes);
      priceArr.push("$" + getVariantInfo(z.variantId)?.sellingPrice);
    });

    let uniqueDosageArr = [...new Set(dosageArr)];
    let uniqueStrengthArr = [...new Set(strengthArr)];
    let uniquePackTypesArr = [...new Set(packTypesArr)];
    let uniquePriceArr = [...new Set(priceArr)];
    obj.dosageString = uniqueDosageArr.join(", ");
    obj.strengthString = uniqueStrengthArr.join(", ");
    obj.packTypesString = uniquePackTypesArr.join(", ");
    if (uniquePriceArr.length > 1) {
      obj.priceString = `${uniquePriceArr[0]} - ${
        uniquePriceArr[uniquePriceArr.length - 1]
      }`;
    } else {
      obj.priceString = uniquePriceArr.join(", ");
    }
    return obj;
  };

  useEffect(() => {
    setLoading(true);
    let newHtml = html;
    newHtml = newHtml.replace("{__header_text}", settings.headerText);
    newHtml = newHtml.replace(
      "{__date_string}",
      `${translate0("Date")}: ${format(settings.date, "P")}`
    );
    newHtml = newHtml.replace("{__footer_text}", settings.footerText);
    let medListHtml = ``;
    if (selectedForPreview && selectedForPreview.length > 0) {
      selectedForPreview.forEach((x, i) => {
        medListHtml =
          medListHtml +
          `<section class="med-list">
          <div class="box">
            <div>
            ${
              settings.fieldsToView.image
                ? `<img            
                      src=${getImage(x.image)}
                      alt=${x.genericBrand}
                      class="med-image"
                  />`
                : ""
            } 
            
            </div>
            <div class="basic-info">
            ${
              settings.fieldsToView.generic
                ? `<p>${translate0("Generic")}: ${x.generic}</p>`
                : ""
            } 
              
              ${
                settings.fieldsToView.genericBrand
                  ? `<p>${translate0("Generic Brand")}: ${x.genericBrand}</p>`
                  : ""
              } 
              
              ${
                settings.fieldsToView.category
                  ? `<p>${translate0("Category")}: ${getCatString(
                      x.subCats
                    )}</p>`
                  : ""
              }  
              
              ${
                settings.fieldsToView.subCategory
                  ? `<p>${translate0("Sub Category")}: ${getSubCatString(
                      x.subCats
                    )}</p>`
                  : ""
              }  

              ${
                settings.fieldsToView.company
                  ? `<p>${translate0("Mfg Company")}: ${getCompanyString(
                      x.companyId
                    )}</p>`
                  : ""
              }  
              
              ${
                settings.fieldsToView.originator
                  ? `<p>${translate0("Originator")}: ${x.originator}</p>`
                  : ""
              }              
            </div>
            <div class="variant-info">              
              ${
                settings.fieldsToView.dosage
                  ? `<p>${translate0("Dosage Form")}: ${
                      getVariantStringObj(x.variants).dosageString
                    }</p>`
                  : ""
              }               
              ${
                settings.fieldsToView.strength
                  ? `<p>${translate0("Strength")}: ${
                      getVariantStringObj(x.variants).strengthString
                    }</p>`
                  : ""
              }              
              ${
                settings.fieldsToView.packSize
                  ? `<p>${translate0("Pack Types")}: ${
                      getVariantStringObj(x.variants).packTypesString
                    }</p>`
                  : ""
              }
              ${
                settings.fieldsToView.price
                  ? `<p>${translate0("Price")}: ${
                      getVariantStringObj(x.variants).priceString
                    }</p>`
                  : ""
              }              
              ${
                settings.fieldsToView.availability
                  ? `<p>${translate0("Availability")}: ${
                      x.visible
                        ? translate0("Available")
                        : translate0("Not Available")
                    }</p>`
                  : ""
              }
            </div>
          </div>
        </section>`;

        if ((i + 1) % 5 === 0) {
          if (i < 5)
            medListHtml = medListHtml + `<div style="margin-top: 70px"/>`;
          else medListHtml = medListHtml + `<div style="margin-top: 150px"/>`;
        }
      });
    }
    newHtml = newHtml.replace("{__insert_meds_here} ", medListHtml);
    setHtmlToView(newHtml);
    setTimeout(() => setLoading(false), 3000);
  }, [
    selectedForPreview,
    settings.date,
    settings.fieldsToView,
    settings.footerText,
    settings.headerText,
    siteLang,
  ]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby={"preview-leaflet-dialog"}
        fullWidth
        maxWidth={"md"}
      >
        <AppBar sx={{ position: "relative" }} color="info">
          <Toolbar>
            <Grid
              container
              spacing={0}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => setOpen(false)}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                {translate0("Preview")}
              </Grid>

              <Grid item xs={10} sm={6} xl={4} sx={{ alignSelf: "flex-end" }}>
                <Grid container justifyContent="flex-end" spacing={2}>
                  <Grid item>
                    <LoadingButton
                      loading={loadingOpen}
                      loadingPosition="end"
                      endIcon={<CallMissedOutgoingIcon />}
                      color="warning"
                      variant="contained"
                      onClick={handleOpenInNewPage}
                      fullWidth
                      size="small"
                    >
                      Open
                    </LoadingButton>
                  </Grid>
                  <Grid item>
                    <LoadingButton
                      loading={loadingDownload}
                      loadingPosition="end"
                      endIcon={<DownloadForOfflineIcon />}
                      color="error"
                      variant="contained"
                      onClick={handleDownload}
                      fullWidth
                      size="small"
                    >
                      {translate0("Download")}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <DialogContent>
          {loading ? (
            <LoadingBox w={"100%"} />
          ) : (
            <>
              <div dangerouslySetInnerHTML={{ __html: htmlToView }} />
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

const html = `
<style>
.topbar {
  // display: -webkit-box;
  // display: flex;
  // -webkit-box-pack: space-between;
  // justify-content: space-between;
  width: 90%;
  margin: auto;
}

.top-divider,
footer {
  width: 90%;
  margin: 10px auto;
}

.med-list {
  width: 90%;
  margin-left: auto;
  margin-right: auto;  
  height: 180px;
}

.box {
  display: -webkit-box;
  display: flex;
  background: #E6E6E3;
  border-radius: .5rem;

}

.med-image {
  padding:10px;
  height: 150px;
  width: 150px;
  object-fit: contain;
}

p {
  font-size: 9px;
}

.basic-info,
.variant-info {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  margin-top: 5px;
  flex: 1;
  width:280px;
  padding:0px 10px ;
}

</style>
<div class="topbar">
  <p>{__header_text}</p>
  <p>{__date_string}</p>
</div>
<hr class="top-divider" />
{__insert_meds_here}            
<footer>
  {__footer_text}                
</footer>
`;
