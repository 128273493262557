import { Divider, List, ListItem, ListItemText } from "@mui/material";
import React from "react";

export default function TestimonialRules() {
  return (
    <>
      <List>
        {[...Array(5)].map((x, i) => (
          <>
            <ListItem disablePadding key={i}>
              <ListItemText secondary="Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio iusto temporibus consequatur quam maxime numquam sint perferendis dolorem magnam, magni inventore repudiandae itaque voluptates. Aperiam, voluptas? At dignissimos vitae omnis iure ipsam officiis blanditiis!" />
            </ListItem>
            <Divider />
          </>
        ))}
      </List>
    </>
  );
}
