import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrency,
  getLangs,
  updateCurrency,
} from "../../../reduxToolkit/features/adminPanel/misc";
import { getMedicines } from "../../../reduxToolkit/features/adminPanel/medicine";
import LoadingBox from "../../../components/misc/Loading";
import {
  Autocomplete,
  Box,
  createFilterOptions,
  Divider,
  Grid,
} from "@mui/material";
import getSymbolFromCurrency from "currency-symbol-map";
import LoadingButton from "@mui/lab/LoadingButton";

// Icons
import UpdateIcon from "@mui/icons-material/Update";
import {
  assignPriceByCustomer,
  getAssignedPriceByCustomer,
  getAssignedPriceByVariant,
} from "../../../reduxToolkit/features/adminPanel/assignPrice";
import { getAskAllPriceList } from "../../../reduxToolkit/features/frontend/checkout";
import { getNotification } from "../../../reduxToolkit/features/adminPanel/notification";

// Limit options to show in autocomplete
const OPTIONS_LIMIT = 8;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) => {
  return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
};

export default function AssignPriceDlg({
  priceChangeDlg,
  setPriceChangeDlg,
  priceChangeBuffer,
  lang,
  customerId,
  modify,
  langId,
}) {
  const dispatch = useDispatch();

  // Currency
  const [currencySymbol, setCurrencySymbol] = useState("USA");
  const [selectedCurrency, setSelectedCurrency] = useState(1);

  const [loadingMain, setLoadingMain] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingAssign, setLoadingAssign] = useState(false);
  const [sellingPrice, setSellingPrice] = useState(0 * selectedCurrency);
  const [moq, setMoq] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [note, setNote] = useState("");

  // Local Store
  const localAdminData = JSON.parse(localStorage.getItem("adminLoginInfo"));

  // Store
  const { medVariants } = useSelector((state) => state.medicine);
  const { langs, currencyList } = useSelector((state) => state.misc);
  const { assignPriceResponse, assignedPriceByVariant } = useSelector(
    (state) => state.assignPrice
  );

  // ? Functions
  const getLangCode = (langId) => {
    const L = langs.find((l) => l._id === langId);
    if (L && L.langCode) return L.langCode;
    else return "en";
  };

  //   update Currency
  const handleUpdateCurrency = () => {
    setLoading(true);
    dispatch(updateCurrency());
    dispatch(getCurrency());
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  //   handle Currency Change
  const handleCurrencyChange = (e, newValue) => {
    setSelectedCurrency(newValue.exchangeRate);
    setCurrencySymbol(newValue.currencyName);
  };

  //   Handle assign Price
  const handleAssignPrice = () => {
    setLoadingAssign(true);
    const obj = {
      customerId,
      sellingPrice,
      moq,
      discount,
      note,
      variantId: priceChangeBuffer.varId,
      medId: priceChangeBuffer.medId,
      creatorId: localAdminData._id,
      modify,
      langId,
    };
    dispatch(assignPriceByCustomer(obj));
  };

  // Handle Reset to Default
  const handleResetToDefault = () => {
    if (priceChangeBuffer.varId) {
      if (medVariants && medVariants.length > 0) {
        const V = medVariants.find(
          (v) =>
            (getLangCode(lang) !== "en"
              ? v.variantDetectorId
              : v.fixedVariantId) === priceChangeBuffer.varId
        );
        if (V) {
          setSellingPrice(V.sellingPrice);
          setMoq(V.minimumOrderQty);
          setDiscount(0);
          setNote("");
        }
      }
    }
  };

  // ? Use Effect
  useEffect(() => {
    if (priceChangeBuffer.varId) {
      if (medVariants && medVariants.length > 0) {
        const V = medVariants.find(
          (v) =>
            (getLangCode(lang) !== "en"
              ? v.variantDetectorId
              : v.fixedVariantId) === priceChangeBuffer.varId
        );
        if (V) {
          if (modify && assignedPriceByVariant) {
            assignedPriceByVariant.sellingPrice &&
              setSellingPrice(assignedPriceByVariant.sellingPrice);
            assignedPriceByVariant.moq && setMoq(assignedPriceByVariant.moq);
            assignedPriceByVariant.note && setNote(assignedPriceByVariant.note);
            assignedPriceByVariant.discount &&
              setDiscount(assignedPriceByVariant.discount);
          } else {
            setSellingPrice(V.sellingPrice);
            setMoq(V.minimumOrderQty);
          }
        }
      }
    }
  }, [priceChangeBuffer.varId, lang, medVariants, assignedPriceByVariant]);

  // ? UseEffect
  useEffect(() => {
    setLoadingMain(true);
    if (!langs) dispatch(getLangs());
    dispatch(getMedicines());
    dispatch(getCurrency());
    setTimeout(() => {
      setLoadingMain(false);
    }, 500);
  }, []);

  //   Set USD as default currency
  useEffect(() => {
    if (currencyList.length > 0) {
      const C = currencyList.find((x) => x.currencyName === "USD");
      setSelectedCurrency(C.exchangeRate);
      setCurrencySymbol(C.currencyName);
    }
  }, [currencyList]);

  // Check assign Price response
  useEffect(() => {
    if (assignPriceResponse && assignPriceResponse.message) {
      if (!assignPriceResponse.failed) {
        dispatch(getAskAllPriceList());
        setTimeout(() => {
          dispatch(getNotification());
          setLoadingAssign(false);
          setPriceChangeDlg(false);
        }, 500);
      }
    }
  }, [assignPriceResponse]);

  useEffect(() => {
    if (modify && priceChangeBuffer && priceChangeBuffer.varId) {
      dispatch(getAssignedPriceByVariant(priceChangeBuffer.varId));
    }
  }, [modify, priceChangeBuffer.varId]);

  // ! translate
  // Store
  const [langSettings1, setLangSettings1] = useState([]);
  const [langSettings2, setLangSettings2] = useState([]);
  const title1 = "Price Request List";
  const title2 = "Misc Words";

  useEffect(() => {
    if (langs && langs.length > 0 && lang !== "") {
      const L = langs.find((l) => l.langCode === lang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      const A = arr.find((l) => l._title === title1);
      if (A) setLangSettings1(A.data);
      const B = arr.find((l) => l._title === title2);
      if (B) setLangSettings2(B.data);
    }
  }, [lang, langs]);

  const translate1 = (String) => translation(String, title1, 1);
  const translate2 = (String) => translation(String, title2, 2);

  const translation = (String, title, index) => {
    let translation = "";
    let settings = [];
    if (index === 1) {
      settings = langSettings1;
    } else if (index === 2) {
      settings = langSettings2;
    }
    const T = settings.find((ls) => ls.title === String);
    if (T && T.value) translation = T.value;
    else {
      let arr = [];
      if (langs && langs.length > 0 && lang !== "") {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.settings && LEn.settings.length > 0) arr = LEn.settings;
        const S = arr.find((l) => l._title === title);
        if (S) {
          const T = S.data.find((ls) => ls.title === String);
          if (T && T.value) translation = T.value;
        }
      }
    }
    return translation;
  };

  return (
    <>
      <Dialog
        open={priceChangeDlg}
        onClose={() => setPriceChangeDlg(false)}
        fullWidth
        maxWidth={"xs"}
      >
        <DialogTitle>
          {modify
            ? translate1("Modify Assigned Price")
            : translate1("Assign Price")}
        </DialogTitle>
        <DialogContent>
          {loadingMain ? (
            <LoadingBox w={"100%"} h={"100%"} />
          ) : (
            <>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} sm={12} md={6}>
                  {currencyList.length > 0 && (
                    <Autocomplete
                      size="small"
                      onChange={(e, newValue) =>
                        handleCurrencyChange(e, newValue)
                      }
                      disablePortal
                      options={currencyList}
                      defaultValue={currencyList.find(
                        (c) => c.currencyName === "USD"
                      )}
                      filterOptions={filterOptions}
                      getOptionLabel={(option) =>
                        "(" +
                        getSymbolFromCurrency(option.currencyName) +
                        ") " +
                        option.currencyName +
                        " [" +
                        option.exchangeRate +
                        "]"
                      }
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{
                            "& > img": { mr: 2, flexShrink: 0 },
                          }}
                          {...props}
                        >
                          ({getSymbolFromCurrency(option.currencyName)}){" "}
                          {option.currencyName} [{option.exchangeRate}]
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={translate1("View Currency")}
                          variant="standard"
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <LoadingButton
                    onClick={handleUpdateCurrency}
                    variant="contained"
                    size="small"
                    fullWidth
                    loading={loading}
                    endIcon={<UpdateIcon />}
                    color="success"
                  >
                    {translate1("Update Currency")}
                  </LoadingButton>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Button
                    variant="contained"
                    size="small"
                    fullWidth
                    sx={{ pointerEvents: "none" }}
                  >
                    {getSymbolFromCurrency(currencySymbol)}
                    {sellingPrice > 0
                      ? (Number(sellingPrice) * selectedCurrency).toFixed(2)
                      : 0}
                  </Button>
                </Grid>
              </Grid>
              <Divider sx={{ my: 1 }} />
              {modify && (
                <Button
                  variant="contained"
                  size="small"
                  fullWidth
                  color="warning"
                  onClick={handleResetToDefault}
                >
                  {translate1("Reset to default")}
                </Button>
              )}
              <TextField
                label={`${translate1("Selling Price")} (${translate1("USD")})`}
                required
                value={sellingPrice}
                onChange={(e) => {
                  const result = e.target.value.replace(/\D/g, "");
                  setSellingPrice(result);
                }}
                variant="standard"
                size="small"
                fullWidth
                sx={{ mt: 1 }}
              />
              <TextField
                label={translate1("Minimum Order Qty")}
                required
                value={moq}
                onChange={(e) => {
                  const result = e.target.value.replace(/\D/g, "");
                  setMoq(result);
                }}
                variant="standard"
                size="small"
                fullWidth
                sx={{ mt: 2 }}
              />
              <TextField
                label={translate1("Discount")}
                value={discount}
                onChange={(e) => {
                  const result = e.target.value.replace(/\D/g, "");
                  setDiscount(result);
                }}
                variant="standard"
                size="small"
                fullWidth
                sx={{ mt: 2 }}
                error={Number(discount) > Number(sellingPrice) ? true : false}
                helperText={
                  Number(discount) > Number(sellingPrice) && (
                    <Typography variant="body2" color="error">
                      Discount cannot be more than selling price
                    </Typography>
                  )
                }
              />
              <TextField
                label={translate1("Note")}
                value={note}
                onChange={(e) => setNote(e.target.value)}
                multiline
                minRows={3}
                variant="standard"
                size="small"
                fullWidth
                placeholder="Write down additional note to view in frontend"
                sx={{ mt: 2 }}
                error={note.length > 200 ? true : false}
                helperText={
                  <Typography
                    variant="body2"
                    color={note.length > 200 ? "error" : "primary"}
                    align="right"
                  >
                    {note.length}/200
                  </Typography>
                }
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPriceChangeDlg(false)} color="error">
            {translate1("Cancel")}
          </Button>
          <LoadingButton
            onClick={handleAssignPrice}
            color={modify ? "warning" : "primary"}
            disabled={
              note.length > 200 ||
              sellingPrice.length < 1 ||
              moq.length < 1 ||
              Number(sellingPrice) < Number(discount)
            }
            loading={loadingAssign}
          >
            {modify ? translate1("Update") : translate1("Confirm")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
