import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";

export default function LoadingBox({ w, h }) {
  return (
    <Box
      sx={{
        width: w ? w : "200px",
        height: h ? h : 80,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color="secondary" sx={{ my: 0 }} />
    </Box>
  );
}
