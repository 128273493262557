import {
  AppBar,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import SignUpForm from "./SignUpForm";
import SignInForm from "./SignInForm";
import { useSelector } from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AuthDialogue({
  signUpOpen,
  setSignUpOpen,
  signInOpen,
  setSignInOpen,
  setLoginStatus,
}) {
  // Functions
  const handleDialogueClose = () => {
    setSignUpOpen(false);
    setSignInOpen(false);
  };

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate
  return (
    <Dialog
      fullScreen
      open={signUpOpen || signInOpen}
      onClose={handleDialogueClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleDialogueClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {signUpOpen && translateFront("Sign up")}
            {signInOpen && translateFront("Sign In")}
          </Typography>
        </Toolbar>
      </AppBar>
      {signUpOpen && (
        <SignUpForm
          setLoginStatus={setLoginStatus}
          setSignUpOpen={setSignUpOpen}
          setSignInOpen={setSignInOpen}
        />
      )}
      {signInOpen && (
        <SignInForm
          setLoginStatus={setLoginStatus}
          setSignUpOpen={setSignUpOpen}
          setSignInOpen={setSignInOpen}
        />
      )}
    </Dialog>
  );
}
