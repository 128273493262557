import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../config";

// Get Settings
export const getSettings = createAsyncThunk(
  "webSettings/getSettings",
  async () => {
    const res = await axiosInstance.get("/api/web-settings/");
    return res.data;
  }
);

// Get Google Review Count
export const getGoogleReviewCount = createAsyncThunk(
  "webSettings/getGoogleReviewCount",
  async () => {
    const res = await axiosInstance.get(
      "/api/web-settings/google-review-count"
    );
    return res.data;
  }
);

// Set Google Review Count
export const setGoogleReviewCount = createAsyncThunk(
  "webSettings/setGoogleReviewCount",
  async (data) => {
    const res = await axiosInstance.post(
      "/api/web-settings/set-google-review-count",
      data
    );
    return res.data;
  }
);

// verification Interval
export const verificationInterval = createAsyncThunk(
  "webSettings/VerificationInterval",
  async (data) => {
    const res = await axiosInstance.put(
      "/api/web-settings/verification-interval",
      data
    );
    return res.data;
  }
);

// Un remember
export const unRemember = createAsyncThunk(
  "webSettings/unRemember",
  async (data) => {
    const res = await axiosInstance.put("/api/web-settings/un-remember", data);
    return res.data;
  }
);

// Unblock Request
export const maxUnblockRequest = createAsyncThunk(
  "webSettings/maxUnblockRequest",
  async (data) => {
    const res = await axiosInstance.put("/api/web-settings/un-block-req", data);
    return res.data;
  }
);

// Payment Receipt Instruction
export const savePayRcptInstruction = createAsyncThunk(
  "webSettings/savePayRcptInstruction",
  async (data) => {
    const res = await axiosInstance.post(
      "/api/web-settings/save-payment-receipt-instruction",
      data
    );
    return res.data;
  }
);

// Get Payment Settings
export const getPaymentSettings = createAsyncThunk(
  "webSettings/getPaymentSettings",
  async (data) => {
    const res = await axiosInstance.get(
      "/api/web-settings/get-payment-settings",
      data
    );
    return res.data;
  }
);

export const saveSiteSettings = createAsyncThunk(
  "webSettings/saveSiteSettings",
  async (data) => {
    const res = await axiosInstance.post(
      "/api/web-settings/save-site-settings",
      data
    );
    return res.data;
  }
);

export const saveHeadTitle = createAsyncThunk(
  "webSettings/saveHeadTitle",
  async (data) => {
    const res = await axiosInstance.post(
      "/api/web-settings/save-head-title",
      data
    );
    return res.data;
  }
);

export const getHeadTitle = createAsyncThunk(
  "webSettings/getHeadTitle",
  async () => {
    const res = await axiosInstance.get("/api/web-settings/get-head-title");
    return res.data;
  }
);

export const uploadLogo = createAsyncThunk(
  "webSettings/uploadLogo",
  async (data) => {
    const res = await axiosInstance.post("/api/multer/singleLogoImg/", data);
    return res.data;
  }
);

export const updateSubEmailTemplate = createAsyncThunk(
  "webSettings/updateSubEmailTemplate",
  async (data) => {
    const res = await axiosInstance.post(
      "/api/web-settings/update-sub-email-template/",
      data
    );
    return res.data;
  }
);

export const deleteSubEmailTemplate = createAsyncThunk(
  "webSettings/deleteSubEmailTemplate",
  async (data) => {
    const res = await axiosInstance.post(
      "/api/web-settings/delete-sub-email-template/",
      data
    );
    return res.data;
  }
);

export const addChatAgent = createAsyncThunk(
  "webSettings/addChatAgent",
  async (data) => {
    const res = await axiosInstance.post(
      "/api/web-settings/add-chat-agent/",
      data
    );
    return res.data;
  }
);

export const getChatAgents = createAsyncThunk(
  "webSettings/getChatAgents",
  async (data) => {
    const res = await axiosInstance.get(
      "/api/web-settings/get-chat-agents/",
      data
    );
    return res.data;
  }
);

export const editChatAgent = createAsyncThunk(
  "webSettings/editChatAgent",
  async (data) => {
    const res = await axiosInstance.post(
      "/api/web-settings/edit-chat-agent/",
      data
    );
    return res.data;
  }
);

export const deleteChatAgent = createAsyncThunk(
  "webSettings/deleteChatAgent",
  async (data) => {
    const res = await axiosInstance.post(
      "/api/web-settings/delete-chat-agent/",
      data
    );
    return res.data;
  }
);

export const updateShowPriceSettings = createAsyncThunk(
  "webSettings/updateShowPrice",
  async (data) => {
    const res = await axiosInstance.post(
      `/api/web-settings/update-show-price`,
      data
    );
    return res.data;
  }
);

export const updateShowOriginatorSettings = createAsyncThunk(
  "webSettings/updateShowOriginator",
  async (data) => {
    const res = await axiosInstance.post(
      `/api/web-settings/update-show-originator`,
      data
    );
    return res.data;
  }
);

// Initial State
const initialState = {
  pending: false,
  error: false,
  webSettings: {},
  headTitles: [],
  paymentSettings: [],
  chatAgents: [],
  snackNotiWebSettings: {},
  googleReviewCount: 0,
};

// Slice
export const webSettingsSlice = createSlice({
  name: "webSettings",
  initialState,
  reducers: {
    resetSnkWebSettings(state) {
      state.snackNotiWebSettings = {};
    },
  },
  extraReducers: {
    [getSettings.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getSettings.fulfilled]: (state, action) => {
      state.pending = false;
      state.webSettings = action.payload;
      if (!action.payload.failed) {
        state.snackNotiWebSettings = {
          message: action.payload.message,
          severity: action.payload.severity,
        };
      }
    },
    [getSettings.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [getGoogleReviewCount.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getGoogleReviewCount.fulfilled]: (state, action) => {
      state.pending = false;
      state.googleReviewCount = action.payload;
      if (!action.payload.failed) {
        state.snackNotiWebSettings = {
          message: action.payload.message,
          severity: action.payload.severity,
        };
      }
    },
    [getGoogleReviewCount.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [setGoogleReviewCount.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [setGoogleReviewCount.fulfilled]: (state, action) => {
      state.pending = false;
      // state.googleReviewCount = action.payload;
      if (!action.payload.failed) {
        state.snackNotiWebSettings = {
          message: action.payload.message,
          severity: action.payload.severity,
        };
      }
    },
    [setGoogleReviewCount.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [verificationInterval.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [verificationInterval.fulfilled]: (state, action) => {
      state.pending = false;
      if (!action.payload.failed) {
        state.snackNotiWebSettings = {
          message: action.payload.message,
          severity: action.payload.severity,
        };
      }
    },
    [verificationInterval.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [unRemember.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [unRemember.fulfilled]: (state, action) => {
      state.pending = false;
      if (!action.payload.failed) {
        state.snackNotiWebSettings = {
          message: action.payload.message,
          severity: action.payload.severity,
        };
      }
    },
    [unRemember.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [maxUnblockRequest.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [maxUnblockRequest.fulfilled]: (state, action) => {
      state.pending = false;
      if (!action.payload.failed) {
        state.snackNotiWebSettings = {
          message: action.payload.message,
          severity: action.payload.severity,
        };
      }
    },
    [maxUnblockRequest.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [savePayRcptInstruction.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [savePayRcptInstruction.fulfilled]: (state, action) => {
      state.pending = false;
      if (!action.payload.failed) {
        state.snackNotiWebSettings = {
          message: action.payload.message,
          severity: action.payload.severity,
        };
      }
    },
    [savePayRcptInstruction.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [getPaymentSettings.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getPaymentSettings.fulfilled]: (state, action) => {
      state.pending = false;
      state.paymentSettings = action.payload;
    },
    [getPaymentSettings.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [saveSiteSettings.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [saveSiteSettings.fulfilled]: (state, action) => {
      state.pending = false;
      if (!action.payload.failed) {
        state.snackNotiWebSettings = {
          message: action.payload.message,
          severity: action.payload.severity,
        };
      }
    },
    [saveSiteSettings.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [saveHeadTitle.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [saveHeadTitle.fulfilled]: (state, action) => {
      state.pending = false;
      if (!action.payload.failed) {
        state.snackNotiWebSettings = {
          message: action.payload.message,
          severity: action.payload.severity,
        };
      }
    },
    [saveHeadTitle.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [getHeadTitle.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getHeadTitle.fulfilled]: (state, action) => {
      state.pending = false;
      state.headTitles = action.payload;
    },
    [getHeadTitle.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [uploadLogo.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [uploadLogo.fulfilled]: (state, action) => {
      state.pending = false;
    },
    [uploadLogo.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [updateSubEmailTemplate.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [updateSubEmailTemplate.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiWebSettings = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [updateSubEmailTemplate.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [deleteSubEmailTemplate.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [deleteSubEmailTemplate.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiWebSettings = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [deleteSubEmailTemplate.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [addChatAgent.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [addChatAgent.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiWebSettings = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [addChatAgent.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [getChatAgents.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getChatAgents.fulfilled]: (state, action) => {
      state.pending = false;
      state.chatAgents = action.payload;
    },
    [getChatAgents.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [editChatAgent.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [editChatAgent.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiWebSettings = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [editChatAgent.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [deleteChatAgent.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [deleteChatAgent.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiWebSettings = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [deleteChatAgent.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [updateShowPriceSettings.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [updateShowPriceSettings.fulfilled]: (state, action) => {
      state.pending = false;
      if (action.payload.data) state.webSettings = action.payload.data;
      state.snackNotiWebSettings = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [updateShowPriceSettings.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [updateShowOriginatorSettings.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [updateShowOriginatorSettings.fulfilled]: (state, action) => {
      state.pending = false;
      if (action.payload.data) state.webSettings = action.payload.data;
      state.snackNotiWebSettings = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [updateShowOriginatorSettings.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
  },
});

export const { resetSnkWebSettings } = webSettingsSlice.actions;
export default webSettingsSlice.reducer;
