import { ExpandMore } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNotification } from "../../../reduxToolkit/features/adminPanel/notification";

import LangNTitleBar from "./LangNTitleBar";
import {
  getManagedContentSettings,
  saveManagedContentSettings,
} from "../../../reduxToolkit/features/adminPanel/managedContentSettings";
import MedicineVideoUpload from "../../adminPanel/medicines/components/MedicineVideoUpload";

// Media Query
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// Icons
import CloseIcon from "@mui/icons-material/Close";
import InfoTut from "../../../components/InfoTut";
import { uploadLogo } from "../../../reduxToolkit/features/adminPanel/webSettings";

export default function AboutUsSettings({
  expanded,
  handleChange,
  translate0,
}) {
  const dispatch = useDispatch();
  // Media Query
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  const [loading, setLoading] = useState(false);
  const [lang, setLang] = useState("");
  const [langId, setLangId] = useState("");

  const [aboutUs, setAboutUs] = useState([
    {
      tabTitle: "",
      tabDetails: "",
    },
  ]);

  const [videos, setVideos] = useState([]);

  // Dialogs
  const [uploadVideosDlg, setUploadVideosDlg] = useState(false);

  // LocalData
  const localData = JSON.parse(localStorage.getItem("adminLoginInfo"));

  //   Store
  const { managedContentSettings } = useSelector(
    (state) => state.managedContentSettings
  );

  const handleSaveSiteSEO = () => {
    setLoading(true);
    // handle Video
    let arr = [...videos];
    videos.forEach((x, i) => {
      if (typeof x.file !== "undefined") {
        if (x.externalLink) {
          videos[i].src = x.file;
        } else {
          let data = new FormData();
          const ext = x.file.name.split(".");
          const filename =
            Date.now() + "-" + i + "-about-us-vid." + ext.slice(-1);
          data.append("name", filename);
          data.append("logoImg", x.file);
          arr[i].src = filename;
          dispatch(uploadLogo(data));
        }
      }
    });
    dispatch(
      saveManagedContentSettings({
        langId,
        aboutUs,
        changedSection: "About Us",
        creatorId: localData._id,
        aboutUsVideos: [...arr],
      })
    );
    setTimeout(() => {
      setLoading(false);
      dispatch(getNotification());
      dispatch(getManagedContentSettings());
    }, 1000);
  };

  const handleAddTab = () => {
    let arr = [...aboutUs];
    arr.push({
      tabTitle: "",
      tabDetails: "",
    });
    setAboutUs([...arr]);
  };

  const handleRemoveTab = (i) => {
    let arr = [...aboutUs];
    arr = arr.filter((a, index) => i !== index);

    setAboutUs([...arr]);
  };

  const handleUploadVideosClose = () => {
    setUploadVideosDlg(false);
  };

  useEffect(() => {
    if (!(managedContentSettings && managedContentSettings.length > 0))
      dispatch(getManagedContentSettings());
  }, []);

  // Set Settings
  useEffect(() => {
    if (managedContentSettings && managedContentSettings.length > 0) {
      const M = managedContentSettings.find((m) => m.langId === langId);
      if (M && M.aboutUs && M.aboutUs.length > 0) {
        setAboutUs(M.aboutUs);
      } else {
        setAboutUs([
          {
            tabTitle: "",
            tabDetails: "",
          },
        ]);
      }
      if (M && M.aboutUsVideos && M.aboutUsVideos.length > 0) {
        setVideos(M.aboutUsVideos);
      } else {
        setVideos([]);
      }
    }
  }, [langId, managedContentSettings]);

  return (
    <>
      <Accordion
        expanded={expanded === "about-us-settings"}
        onChange={handleChange("about-us-settings")}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-label="Expand"
          aria-controls="Head-Title-content"
          id="about-us-settings"
        >
          <Typography>{translate0("About Us")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <LangNTitleBar
            lang={lang}
            setLang={setLang}
            langId={langId}
            setLangId={setLangId}
            title={translate0("About Us")}
            infoImg="tut-about-us.png"
          />
          {/* Upload Video Start */}
          {!smUp ? (
            <Grid
              container
              spacing={0}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item sx={{ flex: 1 }}>
                <Button
                  fullWidth
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={() => setUploadVideosDlg(true)}
                >
                  {translate0("Upload About Us Videos")}
                </Button>
              </Grid>
              <Grid item>
                <InfoTut img="tut-about-us-videos.png" />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={1} justifyContent="center">
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    border: "1px solid lightblue",
                    p: 1,
                    m: 1,
                    borderRadius: ".25rem",
                  }}
                >
                  <Grid
                    container
                    spacing={0}
                    sx={{ mt: 1 }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item>
                      <Button
                        fullWidth
                        variant="outlined"
                        size="small"
                        color="primary"
                        sx={{ pointerEvents: "none" }}
                      >
                        {translate0("Upload About Us Videos")}
                      </Button>
                    </Grid>
                    <Grid item>
                      <InfoTut img="tut-about-us-videos.png" />
                    </Grid>
                  </Grid>
                  <MedicineVideoUpload
                    videos={videos}
                    setVideos={setVideos}
                    path="misc/"
                  />
                </Box>
              </Grid>
            </Grid>
          )}
          {uploadVideosDlg && (
            <Dialog open={uploadVideosDlg} fullScreen>
              <AppBar sx={{ position: "relative" }}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleUploadVideosClose}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography
                    sx={{ ml: 2, flex: 1 }}
                    variant="h6"
                    component="div"
                  >
                    {translate0("Upload About Us Videos")}
                  </Typography>
                </Toolbar>
              </AppBar>
              <DialogContent>
                <MedicineVideoUpload
                  videos={videos}
                  setVideos={setVideos}
                  path="misc/"
                />
              </DialogContent>
            </Dialog>
          )}
          {/* Upload Video End */}

          {aboutUs.length > 0 &&
            aboutUs.map((x, i) => (
              <Box
                key={i}
                sx={{
                  border: "1px solid lightblue",
                  p: 1,
                  m: 1,
                  borderRadius: ".25rem",
                }}
              >
                {aboutUs.length > 1 && (
                  <Button
                    fullWidth
                    variant="contained"
                    size="small"
                    color="error"
                    sx={{ my: 1 }}
                    onClick={() => handleRemoveTab(i)}
                  >
                    {translate0("Remove Tab")}
                  </Button>
                )}
                <TextField
                  label={translate0("Tab Title")}
                  value={x.tabTitle}
                  onChange={(e) => {
                    let arr = [...aboutUs];
                    let obj = { ...arr[i] };
                    obj.tabTitle = e.target.value;
                    arr[i] = obj;
                    setAboutUs(arr);
                  }}
                  size="small"
                  fullWidth
                  variant="standard"
                />
                <TextField
                  label={translate0("Tab Details")}
                  value={x.tabDetails}
                  multiline
                  minRows={4}
                  onChange={(e) => {
                    let arr = [...aboutUs];
                    let obj = { ...arr[i] };
                    obj.tabDetails = e.target.value;
                    arr[i] = obj;
                    setAboutUs(arr);
                  }}
                  size="small"
                  fullWidth
                  variant="standard"
                  sx={{ my: 1 }}
                />
              </Box>
            ))}
          <Button
            fullWidth
            variant="contained"
            size="small"
            color="primary"
            sx={{ my: 1 }}
            onClick={handleAddTab}
          >
            {translate0("Add Tab")}
          </Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            fullWidth
            size="small"
            color="success"
            onClick={handleSaveSiteSEO}
          >
            {translate0("Save")}
          </LoadingButton>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
