import React, { Fragment, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
} from "@mui/material";
// Icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ManageAdmins from "./ManageAdmins";
import { useSelector } from "react-redux";
import AdvancedSettings from "./AdvancedSettings";
import LanguageSettings from "./LanguageSettings";

// Media Query
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function Administration() {
  // Media Query
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  const [expanded, setExpanded] = React.useState(false);
  const [lang, setLang] = useState("");
  const [langId, setLangId] = useState("");

  // Local Store
  const localLangData = JSON.parse(localStorage.getItem("lang"));

  // Store
  const { panel } = useSelector((state) => state.notification);
  const { langs, activeLang } = useSelector((state) => state.misc);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    setExpanded(panel);
  }, [panel]);

  // translate
  const [langSettings, setLangSettings] = useState([]);
  const [langSettings2, setLangSettings2] = useState([]);
  const [langSettings3, setLangSettings3] = useState([]);
  const [langSettings4, setLangSettings4] = useState([]);
  const [langSettings5, setLangSettings5] = useState([]);
  const [langSettings6, setLangSettings6] = useState([]);
  const title1 = "Manage Admin";
  const title2 = "Advanced Settings";
  const title3 = "Language Settings";
  const title4 = "Add Language Dialog";
  const title5 = "Add Admin Dialog";
  const title6 = "Misc Words";

  useEffect(() => {
    if (langs && langs.length > 0 && lang !== "") {
      const L = langs.find((l) => l.langCode === lang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      const manageAdmin = arr.find((l) => l._title === title1);
      if (manageAdmin) setLangSettings(manageAdmin.data);
      const advancedSettings = arr.find((l) => l._title === title2);
      if (advancedSettings) setLangSettings2(advancedSettings.data);
      const languageSettings = arr.find((l) => l._title === title3);
      if (languageSettings) setLangSettings3(languageSettings.data);
      const langDlg = arr.find((l) => l._title === title4);
      if (langDlg) setLangSettings4(langDlg.data);
      const addAdminDlg = arr.find((l) => l._title === title5);
      if (addAdminDlg) setLangSettings5(addAdminDlg.data);
      const miscWords = arr.find((l) => l._title === title5);
      if (miscWords) setLangSettings6(miscWords.data);
    }
  }, [lang, langs]);

  const translate1 = (String) => translation(String, title1, 1);
  const translate2 = (String) => translation(String, title2, 2);
  const translate3 = (String) => translation(String, title3, 3);
  const translate4 = (String) => translation(String, title4, 4);
  const translate5 = (String) => translation(String, title5, 5);
  const translate6 = (String) => translation(String, title6, 6);

  const translation = (String, title, index) => {
    let translation = "";
    let settings = [];
    if (index === 1) {
      settings = langSettings;
    } else if (index === 2) {
      settings = langSettings2;
    } else if (index === 3) {
      settings = langSettings3;
    } else if (index === 4) {
      settings = langSettings4;
    } else if (index === 5) {
      settings = langSettings5;
    } else if (index === 6) {
      settings = langSettings6;
    }
    const T = settings.find((ls) => ls.title === String);
    if (T && T.value) translation = T.value;
    else {
      let arr = [];
      if (langs && langs.length > 0 && lang !== "") {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.settings && LEn.settings.length > 0) arr = LEn.settings;
        const dashboard = arr.find((l) => l._title === title);
        if (dashboard) {
          const T = dashboard.data.find((ls) => ls.title === String);
          if (T && T.value) translation = T.value;
        }
      }
    }
    return translation;
  };

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setLang(activeLang.langCode);
      setLangId(activeLang._id);
    } else {
      if (localLangData) {
        setLang(localLangData.langCode);
        setLangId(localLangData._id);
      }
    }
  }, [activeLang]);

  return (
    <Box
      sx={{
        maxWidth: !smUp ? "75vw" : !mdUp ? "85vw" : undefined,
      }}
    >
      <Typography
        variant="h4"
        color="initial"
        align="center"
        sx={{ borderBottom: ".2rem solid black", mb: 2 }}
      >
        {translate1("Administration")}
      </Typography>
      {/* Manage Admins */}
      <Accordion
        expanded={expanded === "manage-admins"}
        onChange={handleChange("manage-admins")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Grid container spacing={0}>
            <Grid item xs={5}>
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                {translate1("Manage Admin")}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography sx={{ color: "text.secondary" }}>
                {translate1("Add/ Remove Admin, Select Role")}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        {expanded === "manage-admins" && (
          <AccordionDetails>
            {/* Admin List */}
            <ManageAdmins
              translate1={translate1}
              translate2={translate2}
              translate5={translate5}
              translate6={translate6}
            />
          </AccordionDetails>
        )}
      </Accordion>
      <Accordion
        expanded={expanded === "advanced-settings"}
        onChange={handleChange("advanced-settings")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ width: "47%", flexShrink: 0 }}>
            {translate2("Advanced Settings")}
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>
            {translate2("Drop/ Handle Entire Data")}
          </Typography>
        </AccordionSummary>
        {expanded === "advanced-settings" && (
          <AccordionDetails>
            <AdvancedSettings translate2={translate2} />
          </AccordionDetails>
        )}
      </Accordion>
      <Accordion
        expanded={expanded === "language-settings"}
        onChange={handleChange("language-settings")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ width: "47%", flexShrink: 0 }}>
            {translate3("Language Settings")}
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>
            {translate3("Manage Language")}
          </Typography>
        </AccordionSummary>
        {expanded === "language-settings" && (
          <AccordionDetails>
            <LanguageSettings translate3={translate3} translate4={translate4} />
          </AccordionDetails>
        )}
      </Accordion>
    </Box>
  );
}
