import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
// Icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ManageExpense from "./components/ManageExpense";
import OfficeExpenseSheet from "./components/OfficeExpenseSheet";

export default function ExpenseCalcScreen() {
  const [expanded, setExpanded] = React.useState(false);

  // Store
  const { panel } = useSelector((state) => state.notification);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // handle Panel Based on Notification
  useEffect(() => {
    setExpanded(panel);
  }, [panel]);

  // Store
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate

  return (
    <Fragment>
      <Typography
        variant="h4"
        color="initial"
        align="center"
        sx={{ borderBottom: ".2rem solid black", mb: 2 }}
      >
        Expenses
      </Typography>
      {expenseMenu.map((x, i) => (
        <Accordion
          key={i}
          expanded={expanded === expenseMenu[i].panelName}
          onChange={handleChange(expenseMenu[i].panelName)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={expenseMenu[i].panelName}
            id={expenseMenu[i].panelName}
          >
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <Typography>{expenseMenu[i].headPrimary}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={{ color: "text.secondary" }}>
                  {expenseMenu[i].headSecondary}
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          {expanded === expenseMenu[i].panelName && (
            <AccordionDetails>{expenseMenu[i].component}</AccordionDetails>
          )}
        </Accordion>
      ))}
    </Fragment>
  );
}

const expenseMenu = [
  {
    panelName: "manage-expense",
    headPrimary: "Manage Expense",
    headSecondary: "View/ Manage Expense",
    // component: <ManageExpense />,
    component: <OfficeExpenseSheet />,
  },
];
