import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { IconButton, Tooltip, Typography } from "@mui/material";
// Icon
import CheckIcon from "@mui/icons-material/Check";

import { useDispatch, useSelector } from "react-redux";
import {
  getSettings,
  maxUnblockRequest,
  unRemember,
  verificationInterval,
} from "../../../reduxToolkit/features/adminPanel/webSettings";
import { getNotification } from "../../../reduxToolkit/features/adminPanel/notification";

export default function AuthenticationSettings() {
  // useState
  const [verInt, setVerInt] = useState(1);
  const [unRem, setUnRem] = useState(720);
  const [maxUnblockReq, setMaxUnblockReq] = useState(2);
  const [clickDisabledAfterSubmit, setClickDisabledAfterSubmit] =
    useState(false);

  // Store
  const { webSettings } = useSelector((state) => state.webSettings);
  // LocalData
  const localData = JSON.parse(localStorage.getItem("adminLoginInfo"));

  // useEffect
  useEffect(() => {
    dispatch(getSettings());
  }, []);

  useEffect(() => {
    if (webSettings.verificationInterval) {
      setVerInt(webSettings.verificationInterval);
      setUnRem(webSettings.unRememberInterval);
      setMaxUnblockReq(webSettings.maxUnblockReqCount);
    }
  }, [webSettings]);

  // Functions
  const dispatch = useDispatch();
  const handleVerification = () => {
    if (localData && localData._id) {
      dispatch(verificationInterval({ verInt, creatorId: localData._id }));
      setClickDisabledAfterSubmit(true);
      setTimeout(() => {
        dispatch(getNotification());
      }, 1000);
    }
  };

  // Handle Un remember
  const handleUnRemember = () => {
    if (localData && localData._id) {
      dispatch(
        unRemember({
          unRem,
          creatorId: localData._id,
        })
      );
      setClickDisabledAfterSubmit(true);
      setTimeout(() => {
        dispatch(getNotification());
      }, 1000);
    }
  };

  // Handle max Unblockreq
  const handleMaxUnblockReq = () => {
    if (localData && localData._id) {
      dispatch(
        maxUnblockRequest({
          maxUnblockReq: Number(maxUnblockReq),
          creatorId: localData._id,
        })
      );
      setClickDisabledAfterSubmit(true);
      setTimeout(() => {
        dispatch(getNotification());
      }, 1000);
    }
  };

  // Store
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate

  return (
    <Grid container spacing={0} flexDirection="column">
      <Grid item>
        {/* Verification Interval */}
        <Grid container spacing={0}>
          <Grid item xs={10}>
            <TextField
              required
              label={translate0("Verification Interval (min)")}
              value={verInt}
              onChange={(e) => {
                const result = e.target.value.replace(/\D/g, "");
                setVerInt(result);
                setClickDisabledAfterSubmit(false);
              }}
              fullWidth
              size="small"
              margin="dense"
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip arrow placement="top" title="Confirm">
              <IconButton
                size="'small"
                disabled={
                  verInt.length > 0 && !clickDisabledAfterSubmit ? false : true
                }
                onClick={handleVerification}
              >
                <CheckIcon
                  color={
                    verInt.length > 0 && !clickDisabledAfterSubmit
                      ? "success"
                      : "inherit"
                  }
                  sx={{ mt: 0.8 }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        {/* Login time if not remembered */}
        <Grid container spacing={0}>
          <Grid item xs={10}>
            <TextField
              required
              margin="dense"
              label={translate0("Un remember Login Time") + " (min)"}
              value={unRem}
              onChange={(e) => {
                const result = e.target.value.replace(/\D/g, "");
                setUnRem(result);
                setClickDisabledAfterSubmit(false);
              }}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip arrow placement="top" title="Confirm">
              <IconButton
                size="'small"
                disabled={
                  unRem.length > 0 && !clickDisabledAfterSubmit ? false : true
                }
                onClick={handleUnRemember}
              >
                <CheckIcon
                  color={
                    unRem.length > 0 && !clickDisabledAfterSubmit
                      ? "success"
                      : "inherit"
                  }
                  sx={{ mt: 0.8 }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        {/* maximum Unblock Request */}
        <Grid container spacing={0}>
          <Grid item xs={10}>
            <TextField
              required
              margin="dense"
              label={translate0("Maximum Block Request ")}
              value={maxUnblockReq}
              onChange={(e) => {
                const result = e.target.value.replace(/\D/g, "");
                setMaxUnblockReq(result);
                setClickDisabledAfterSubmit(false);
              }}
              fullWidth
              error={maxUnblockReq === "0" ? true : false}
              size="small"
              helperText={
                maxUnblockReq === "0" && (
                  <Typography variant="body2" color="error">
                    Minimum value 1
                  </Typography>
                )
              }
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip arrow placement="top" title="Confirm">
              <IconButton
                size="'small"
                disabled={
                  maxUnblockReq.length > 0 &&
                  Number(maxUnblockReq) > 0 &&
                  !clickDisabledAfterSubmit
                    ? false
                    : true
                }
                onClick={handleMaxUnblockReq}
              >
                <CheckIcon
                  color={
                    maxUnblockReq.length > 0 &&
                    Number(maxUnblockReq) > 0 &&
                    !clickDisabledAfterSubmit
                      ? "success"
                      : "inherit"
                  }
                  sx={{ mt: 0.8 }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
