import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  deleteAdmin,
  getAdmins,
} from "../../../../reduxToolkit/features/adminPanel/admin";
import { getNotification } from "../../../../reduxToolkit/features/adminPanel/notification";
import { getRole } from "../../../../reduxToolkit/features/adminPanel/role";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";

export default function DeleteAdmin({
  deleteAdminDlg,
  setDeleteAdminDlg,
  deleteAdminBuffer,
  setDeleteAdminBuffer,
  transferAdminBuffer,
  setTransferAdminBuffer,
  adminList,
}) {
  // use State
  const [loading, setLoading] = useState(false);
  // localData
  const localData = JSON.parse(localStorage.getItem("adminLoginInfo"));
  // Functions
  const handleAdminDeleteCancel = () => {
    setTransferAdminBuffer("");
    setDeleteAdminBuffer("");
    setDeleteAdminDlg(false);
  };
  const dispatch = useDispatch();
  const handleAdminDeleteConfirm = () => {
    setLoading(true);
    const formBody = {
      _id: deleteAdminBuffer._id,
      transferAdminId: transferAdminBuffer,
      deletedBy: localData._id,
    };
    dispatch(deleteAdmin(formBody));
    setTimeout(() => {
      dispatch(getNotification());
      dispatch(getAdmins());
      dispatch(getRole());
      setTransferAdminBuffer("");
      setDeleteAdminBuffer("");
      setDeleteAdminDlg(false);
      setLoading(false);
    }, 1000);
  };
  return (
    <>
      {/* Delete Admin Dialog */}
      <Dialog
        open={deleteAdminDlg}
        onClose={() => setDeleteAdminDlg(false)}
        aria-labelledby="delete-role-dialog"
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle>
          <Typography variant="subtitle2" color="initial">
            Delete "{deleteAdminBuffer.adminName}"
          </Typography>
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth size="small" sx={{ my: 1 }}>
            <InputLabel id="demo-simple-select-label">
              Transfer Admin
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={transferAdminBuffer}
              label="Transfer Admin"
              onChange={(e) => setTransferAdminBuffer(e.target.value)}
            >
              {adminList.map(
                (x, i) =>
                  deleteAdminBuffer._id !== x._id && (
                    <MenuItem key={i} value={x._id}>
                      {x.adminName}
                    </MenuItem>
                  )
              )}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAdminDeleteCancel} color="primary">
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            loadingPosition="end"
            endIcon={<ThumbUpOffAltIcon />}
            onClick={handleAdminDeleteConfirm}
            disabled={transferAdminBuffer === "" ? true : false}
            color="error"
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
