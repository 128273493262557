import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../config";

// check Password
export const checkPass = createAsyncThunk(
  "miscFront/checkPass",
  async (data) => {
    const res = await axiosInstance.post("/api/customerAuth/check-pass", data);
    return res.data;
  }
);

// Update Password from edit page
export const updateCustomerPassFromEdit = createAsyncThunk(
  "miscFront/updateCustomerPass",
  async (data) => {
    const res = await axiosInstance.put(
      "/api/customerAuth/update-pass-from-edit",
      data
    );
    return res.data;
  }
);

export const emailVerifyFromEditPage = createAsyncThunk(
  "miscFront/emailVerifyFromEditPage",
  async (data) => {
    const res = await axiosInstance.post(
      "/api/customerAuth/email-verify",
      data
    );
    return res.data;
  }
);

export const updateProfileFromEdit = createAsyncThunk(
  "miscFront/updateProfile",
  async (data) => {
    const { id, expire, ...others } = data;
    const res = await axiosInstance.put(
      `/api/customerAuth/update-profile/${id}`,
      others
    );
    if (res.data && res.data.customerInfo) {
      if (expire) {
        res.data.customerInfo.expire = expire;
      }
      localStorage.setItem("loginInfo", JSON.stringify(res.data.customerInfo));
    }
    return res.data;
  }
);

// get notification by customer
export const getNotificationByCustomer = createAsyncThunk(
  "miscFront/notificationByCustomer",
  async (id) => {
    const res = await axiosInstance.get(`/api/customer-notification/${id}`);
    return res.data;
  }
);

// Read notification by customer
export const readNotificationByCustomer = createAsyncThunk(
  "miscFront/readNotificationByCustomer",
  async (id) => {
    const res = await axiosInstance.post(`/api/customer-notification/${id}`);
    return res.data;
  }
);

// Front End Contact Us
export const contactUsFront = createAsyncThunk(
  "miscFront/contactUsFront",
  async (data) => {
    const res = await axiosInstance.post(`/api/customerAuth/contact-us`, data);
    return res.data;
  }
);

// Initial State
const initialState = {
  pending: false,
  checkPassResponse: {},
  updateProfileResponse: {},
  error: false,
  customerNotifications: [],
  emailVerifyFromEditPageResponse: {},
  langArrFromClientSideFrontend: [],
  activeLangFront: {},
  activeCurrencyFront: { exchangeRate: 1, currencyName: "USD" },
  snackNotiMiscFront: {},
  orderHistoryDlg: false,
  openProfileDrawerDlg: false,
  idToScroll: "",
  // triggerPreloader: false,
};
// Slice
export const miscFrontSlice = createSlice({
  name: "miscFront",
  initialState,
  reducers: {
    resetSnkMiscFront(state) {
      state.snackNotiMiscFront = {};
      state.emailVerifyFromEditPageResponse = {};
    },
    resetCheckPassResponse(state) {
      state.checkPassResponse = {};
    },
    resetUpdateProfileResponse(state) {
      state.updateProfileResponse = {};
    },
    setActiveLangFront(state, action) {
      state.activeLangFront = action.payload;
    },
    setActiveCurrencyFront(state, action) {
      state.activeCurrencyFront = action.payload;
    },
    openOrderHistoryDialog(state, action) {
      state.orderHistoryDlg = action.payload;
    },
    openProfileDrawerDialog(state, action) {
      state.openProfileDrawerDlg = action.payload;
    },
    setIdToScroll(state, action) {
      state.idToScroll = action.payload;
    },
    // triggerPreloader(state) {
    //   state.triggerPreloader = true;
    // },
    // stopPreloader(state) {
    //   state.triggerPreloader = false;
    // },
    setFrontendLangFromClient(state, action) {
      state.langArrFromClientSideFrontend = action.payload;
    },
  },
  extraReducers: {
    [updateCustomerPassFromEdit.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [updateCustomerPassFromEdit.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiMiscFront = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [updateCustomerPassFromEdit.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [checkPass.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [checkPass.fulfilled]: (state, action) => {
      state.pending = false;
      state.checkPassResponse = action.payload;
      state.snackNotiMiscFront = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [checkPass.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [emailVerifyFromEditPage.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [emailVerifyFromEditPage.fulfilled]: (state, action) => {
      state.pending = false;
      state.emailVerifyFromEditPageResponse = action.payload;
      state.snackNotiMiscFront = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [emailVerifyFromEditPage.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [updateProfileFromEdit.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [updateProfileFromEdit.fulfilled]: (state, action) => {
      state.pending = false;
      state.updateProfileResponse = action.payload;
      state.snackNotiMiscFront = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [updateProfileFromEdit.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [getNotificationByCustomer.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getNotificationByCustomer.fulfilled]: (state, action) => {
      state.pending = false;
      state.customerNotifications = action.payload;
    },
    [getNotificationByCustomer.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [readNotificationByCustomer.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [readNotificationByCustomer.fulfilled]: (state) => {
      state.pending = false;
    },
    [readNotificationByCustomer.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [contactUsFront.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [contactUsFront.fulfilled]: (state, action) => {
      state.pending = false;
      // state.updateProfileResponse = action.payload;
      state.snackNotiMiscFront = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [contactUsFront.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
  },
});
//
export const {
  resetSnkMiscFront,
  resetCheckPassResponse,
  resetUpdateProfileResponse,
  setActiveLangFront,
  setActiveCurrencyFront,
  openOrderHistoryDialog,
  openProfileDrawerDialog,
  setIdToScroll,
  // triggerPreloader,
  // stopPreloader,
  setFrontendLangFromClient,
} = miscFrontSlice.actions;
export default miscFrontSlice.reducer;
