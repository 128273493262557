import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  createFilterOptions,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddLangDialog from "../../medicines/dialog/AddLangDialog";

// Icons
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AddBlog from "../dialog/AddBlog";

import { getBlogs } from "../../../../reduxToolkit/features/adminPanel/blog";

import { PF } from "../../../../config";
import { ExpandMore } from "@mui/icons-material";
import BlogCard from "./BlogCard";
import EditBlog from "../dialog/EditBlog";
import { getAdmins } from "../../../../reduxToolkit/features/adminPanel/admin";

// Limit options to show in autocomplete
const OPTIONS_LIMIT = 5;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) => {
  return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
};

export default function ManageBlogs() {
  const dispatch = useDispatch();

  const [loadingMain, setLoadingMain] = useState(false);
  const [lang, setLang] = useState("");
  const [langId, setLangId] = useState("");

  const [blogList, setBlogList] = useState([]);
  const [blogListEn, setBlogListEn] = useState([]);
  const [searchingBlogList, setSearchingBlogList] = useState([]);

  // Dialog
  const [addLangDlg, setAddLangDlg] = useState(false);
  const [addBlogDlg, setAddBlogDlg] = useState(false);
  const [editDlg, setEditDlg] = useState(false);
  const [editBuffer, setEditBuffer] = useState({});

  // LocalData
  const localLangData = JSON.parse(localStorage.getItem("lang"));

  // store
  const { langs, activeLang } = useSelector((state) => state.misc);
  const { blogs } = useSelector((state) => state.blog);

  // Functions
  const handleChangeLang = (e) => {
    setLang(e.target.value);
    const L = langs.find((l) => l.langCode === e.target.value);
    setLangId(L._id);
  };

  const handleAddBlog = () => {
    setAddBlogDlg(true);
  };

  const handleSearch = (e, newValue) => {
    setSearchingBlogList(newValue);
  };

  const getEnInfo = (detectorId) => {
    let obj = {
      title: "",
    };
    const B = blogListEn.find((b) => b._id === detectorId);
    if (B) obj = { ...B };
    return obj;
  };

  const getLangId = (langCode) => {
    let id = "";
    const L = langs.find((l) => l.langCode === langCode);
    if (L) id = L._id;
    return id;
  };
  // UseEffect
  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setLang(activeLang.langCode);
      setLangId(activeLang._id);
    } else {
      if (localLangData) {
        setLang(localLangData.langCode);
        setLangId(localLangData._id);
      }
    }
  }, [activeLang]);

  useEffect(() => {
    setLoadingMain(true);
    dispatch(getBlogs());
    dispatch(getAdmins());
    setTimeout(() => {
      setLoadingMain(false);
    }, 1000);
  }, []);

  // Set Blogs
  useEffect(() => {
    if (blogs && blogs.length > 0) {
      const B = blogs.filter((b) => b.langId === langId);
      if (B) setBlogList(B);
      const BEn = blogs.filter((b) => b.langId === getLangId("en"));
      if (BEn) setBlogListEn(BEn);
    }
  }, [blogs, langId]);

  //! Translate
  // Store
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate

  return (
    <>
      <Grid
        container
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
      >
        {/* Select Language */}
        <Grid item xs={12} md={4}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={2} sm={1} md={1.5}>
              <IconButton>
                <AddCircleOutlineOutlinedIcon
                  color="info"
                  size="large"
                  onClick={() => setAddLangDlg(true)}
                />
              </IconButton>
              <AddLangDialog
                addLangDlg={addLangDlg}
                setAddLangDlg={setAddLangDlg}
                setLang={setLang}
              />
            </Grid>
            <Grid item xs={10} sm={4} md={5}>
              <FormControl fullWidth>
                <Select
                  value={lang}
                  label="language"
                  variant="standard"
                  onChange={handleChangeLang}
                  size="small"
                >
                  {langs.map((x, i) => (
                    <MenuItem key={i} value={x.langCode} dense>
                      <img
                        alt={x.langCode}
                        src={`https://flagcdn.com/w20/${
                          x.langCode === "en" ? "gb" : x.langCode
                        }.png`}
                      />
                      <Button color="inherit" size="small">
                        {x.langName}
                      </Button>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* Add Delivery Method */}
            <Grid item xs={12} sm={7} md={4.5}>
              <Button
                variant="contained"
                size="small"
                color="error"
                fullWidth
                onClick={handleAddBlog}
              >
                {translate0("Add Blog")}
              </Button>
              {addBlogDlg && (
                <AddBlog
                  lang={lang}
                  langId={langId}
                  addBlogDlg={addBlogDlg}
                  setAddBlogDlg={setAddBlogDlg}
                  blogListEn={blogListEn}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        {/* Search */}
        <Grid item xs={12} md={8} sx={{ mt: { md: -2, xs: 0 } }}>
          {loadingMain ? (
            <></>
          ) : (
            <Autocomplete
              multiple
              onChange={(e, newValue) => handleSearch(e, newValue)}
              disablePortal
              options={blogList}
              // options={searchDeliveryList}
              filterOptions={filterOptions}
              getOptionLabel={(option) =>
                option.title
                  ? option.title +
                    (lang !== "en"
                      ? " (" + getEnInfo(option.blogDetectorId).title + ")"
                      : "")
                  : option.title
              }
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option.title}{" "}
                  {lang !== "en"
                    ? " (" + getEnInfo(option.blogDetectorId).title + ")"
                    : ""}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={translate0("Search") + "..."}
                  variant="standard"
                  size="small"
                  fullWidth
                />
              )}
            />
          )}
        </Grid>
      </Grid>
      <Button
        variant="contained"
        size="small"
        fullWidth
        sx={{ pointerEvents: "none", my: 1 }}
      >
        {translate0("Blog")} (
        {blogList && blogList.length > 0 ? blogList.length : 0})
      </Button>
      <Box
        sx={{
          maxHeight: { xs: "60vh", sm: "70vh" },
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Grid container spacing={1} sx={{ mt: 1 }}>
          {blogList.length < 1 ? (
            <Grid item xs={12}>
              <Alert
                severity="warning"
                fullWidth
                sx={{ justifyContent: "center" }}
              >
                {translate0("No Data Available")}
              </Alert>
            </Grid>
          ) : (
            (searchingBlogList.length > 0 ? searchingBlogList : blogList).map(
              (x, i) => (
                <Grid item key={i} xs={12} sm={6} md={4} xl={3}>
                  <BlogCard
                    x={x}
                    setEditDlg={setEditDlg}
                    setEditBuffer={setEditBuffer}
                    blogListEn={blogListEn}
                    lang={lang}
                  />
                </Grid>
              )
            )
          )}
        </Grid>
      </Box>
      {editDlg && (
        <EditBlog
          editBuffer={editBuffer}
          lang={lang}
          langId={langId}
          editDlg={editDlg}
          setEditDlg={setEditDlg}
          blogListEn={blogListEn}
        />
      )}
    </>
  );
}
