import "./deal.css";
import {
  Grid,
  Box,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
// import Swiper core and required modules
import { Navigation, Scrollbar, Keyboard } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";

import { useEffect, useRef, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/black-and-white.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// MUI LAB
import ProductSmall from "../product/ProductSmall";
import HowToOrder from "./HowToOrder";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import HowToVid from "../misc/HowToVid";

// Icons
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompanies,
  getMedCategories,
  getMedicines,
} from "../../reduxToolkit/features/adminPanel/medicine";
import LoadingBox from "../misc/Loading";
import { setIdToScroll } from "../../reduxToolkit/features/miscFront";

export default function Deal() {
  const dispatch = useDispatch();
  // Responsive
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  // Use States
  // const [countDown, setCountDown] = useState("");
  const [howToVid, setHowToVid] = useState(false);
  const [contentSettings, setContentSettings] = useState([]);
  const [langId, setLangId] = useState("");

  const { webSettings } = useSelector((state) => state.webSettings);
  const { managedContentSettings } = useSelector(
    (state) => state.managedContentSettings
  );

  // Countdown
  // var countDownDate = new Date("January 1, 3022 00:00:00").getTime();
  // var x = setInterval(function () {
  //   // Get today's date and time
  //   var now = new Date().getTime();

  //   // Find the distance between now and the count down date
  //   var distance = countDownDate - now;

  //   // Time calculations for days, hours, minutes and seconds
  //   var hours = Math.floor(
  //     (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  //   );
  //   var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  //   var seconds = Math.floor((distance % (1000 * 60)) / 1000);

  //   // Display the result in the element with id="demo"
  //   setCountDown(hours + "h " + minutes + "m " + seconds + "s ");

  //   // If the count down is finished, write some text
  //   if (distance < 0) {
  //     clearInterval(x);
  //     setCountDown("EXPIRED");
  //   }
  // }, 1000);

  // ! ============== Medicine Section
  const [lang, setLang] = useState("en");
  const [medLoading, setMedLoading] = useState(false);
  const [medList, setMedList] = useState([]);
  const [catList, setCatList] = useState([]);

  // store
  const { medicines, categories, viewCountArr } = useSelector(
    (state) => state.medicine
  );
  const { activeLangFront } = useSelector((state) => state.miscFront);

  // Use Effect
  useEffect(() => {
    // if (!(medicines && medicines.length > 0)) dispatch(getMedicines());
    // if (!(categories && categories.length > 0)) dispatch(getMedCategories());
  }, []);

  // Set Medicine
  useEffect(() => {
    setMedLoading(true);
    if (lang !== "" && medicines && medicines.length > 0) {
      let M = medicines.find((m) => m.lang === lang);
      if (M) {
        let arr = [];
        M.data.forEach((x) => {
          let obj = { ...x };
          const C = viewCountArr.find(
            (c) =>
              c.medicineIdEn ===
              (lang !== "en" ? x.medicineDetectorId : x.fixedMedicineId)
          );
          if (C) obj.viewCount = C.viewCount;
          else obj.viewCount = 0;
          arr.push(obj);
        });
        arr = arr.filter((m) => m.visible === true);
        arr = arr.sort((a, b) => (a.viewCount < b.viewCount ? 1 : -1));
        if (arr) arr = arr.slice(0, 10);
        setMedList(arr);
      } else setMedList([]);
    }
    if (medicines) {
      setTimeout(() => {
        setMedLoading(false);
      }, 1000);
    }
  }, [medicines, lang, viewCountArr]);

  // set Category
  useEffect(() => {
    if (lang !== "" && categories && categories.length > 0) {
      let C = categories.find((c) => c.lang === lang);
      if (C) setCatList(C.data);
      else setCatList([]);
    }
  }, [categories, lang]);

  // Set Language
  useEffect(() => {
    if (activeLangFront) {
      setLang(activeLangFront.langCode);
      setLangId(activeLangFront._id);
    }
  }, [activeLangFront]);
  // ! ============== Medicine Section

  // ! ============== Scroll functionality
  const howToOrderRef = useRef();

  const { idToScroll } = useSelector((state) => state.miscFront);

  useEffect(() => {
    if (idToScroll === "how-to-order") {
      setTimeout(() => {
        howToOrderRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          top: 20,
        });
        dispatch(setIdToScroll(""));
      }, 250);
    }
  }, [idToScroll]);
  // ! ============== Scroll functionality

  // Set Settings
  useEffect(() => {
    if (
      managedContentSettings &&
      managedContentSettings.length > 0 &&
      langId !== ""
    ) {
      const M = managedContentSettings.find((m) => m.langId === langId);
      if (M) setContentSettings(M);
    }
  }, [langId, managedContentSettings]);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate

  return (
    <>
      <Grid container justifyContent="center">
        {/* Title */}
        <Grid item xs={11} sm={11} md={8}>
          <Box
            sx={{
              border: `3px double ${webSettings.siteColorTheme || "lightblue"}`,
              borderRadius: ".5rem",
              mb: { xs: 2, sm: 0 },
            }}
          >
            <Grid
              container
              spacing={2}
              alignItems="center"
              sx={{ ml: { xs: 0, sm: 2 } }}
            >
              <Grid item>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 40,
                  }}
                >
                  <LazyLoadImage
                    alt="deal"
                    effect="blur"
                    src="https://img.icons8.com/external-kiranshastry-solid-kiranshastry/64/000000/external-view-cyber-security-kiranshastry-solid-kiranshastry-1.png"
                    style={{
                      // width: !smUp ? "67px" : "180px",
                      // height: !smUp ? "47px" : "80px",
                      height: 40,
                      objectFit: "cover",
                      effect: "black-adn-white",
                    }}
                  />
                </Box>
              </Grid>
              {/* {mdUp && ( */}
              <Grid item>
                <Typography variant="h4">
                  {translateFront("Most Viewed")}
                </Typography>
              </Grid>
              {/* )} */}
              {/* <Grid item>
                <Typography
                  variant="subtitle2"
                  sx={{ fontSize: { xs: "12px", sm: "18px" } }}
                >
                  Ends In: {countDown}
                </Typography>
              </Grid> */}
            </Grid>

            {/* Product Card */}
            {medLoading ? (
              <LoadingBox w={"100%"} h={"600px"} />
            ) : (
              <Swiper
                slidesPerView={!smUp ? 1 : !mdUp ? 2 : 3}
                grabCursor
                modules={[Navigation, Scrollbar, Keyboard]}
                // keyboard={{ enabled: true }}
                spaceBetween={4}
                navigation
                scrollbar
                className="deal"
              >
                {medList.map((x, i) => (
                  <SwiperSlide key={i}>
                    <ProductSmall
                      x={x}
                      i={i}
                      view={0}
                      catList={catList}
                      lang={lang}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </Box>
        </Grid>
        <Grid
          item
          xs={11}
          sm={11}
          md={3}
          sx={{
            background: webSettings.siteColorTheme || "lightblue",
            borderRadius: ".5rem",
            mx: 2,
            mt: { xs: 1, sm: 1, md: 0 },
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            position: "relative",
          }}
          // ref={howToOrderRef}
        >
          <Box
            ref={howToOrderRef}
            sx={{
              position: "absolute",
              // zIndex: -20,
              top: -300,
              // width: "100%",
              height: 300,
              // background: "black",
            }}
          />
          {/* <Typography
            variant="h4"
            align="center"
            sx={{
              textDecoration: "underline",
              fontSize: { xs: "1.5rem", sm: "2.5rem" },
            }}
          >
            How To Order?
          </Typography> */}
          <Typography
            variant="h4"
            align="center"
            sx={{
              // mx: { xs: 2, sm: 20 },
              // my: 2,
              background: webSettings.siteColorTheme || "lightblue",
              // borderRadius: "2.5rem",
              fontSize: { xs: "1.8rem", sm: "2rem" },
              textDecoration: "underline",
              textTransform: "uppercase",
            }}
          >
            {translateFront("How To Order")}
          </Typography>
          <HowToOrder />
          <Button
            variant="contained"
            startIcon={<OndemandVideoIcon />}
            onClick={() => setHowToVid(true)}
          >
            {translateFront("Watch Video")}
          </Button>

          {howToVid && (
            <Dialog
              open={howToVid}
              onClose={() => setHowToVid(false)(false)}
              aria-labelledby={"about-us-video"}
            >
              <DialogContent>
                <HowToVid contentSettings={contentSettings} />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setHowToVid(false)} color="error">
                  {translateFront("Close")}
                </Button>
              </DialogActions>
            </Dialog>
          )}

          {/* <Dialog
            fullScreen={!mdUp ? true : false}
            open={howToVid}
            onClose={() => setHowToVid(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            {!smUp && (
              <DialogTitle
                id="alert-dialog-title"
                sx={{ textAlign: "center", fontSize: ".8rem" }}
              >
                {"Landscape Preferred"}
              </DialogTitle>
            )}
            <DialogContent sx={{ display: "flex" }}>
              <HowToVid contentSettings={contentSettings} />
            </DialogContent>
            <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={() => setHowToVid(false)}
                endIcon={<CloseIcon />}
                color="error"
                variant="contained"
              >
                Close
              </Button>
            </DialogActions>
          </Dialog> */}
        </Grid>
      </Grid>
    </>
  );
}
