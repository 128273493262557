import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../config";

//   Ask price
export const askPrice = createAsyncThunk("checkout/askPrice", async (data) => {
  const res = await axiosInstance.post("/api/ask-price/price-req", data);
  return res.data;
});

// Get Ask Price List by customer
export const getAskPriceListByCustomer = createAsyncThunk(
  "checkout/askPriceListByCustomer",
  async (customerId) => {
    const res = await axiosInstance.get(`/api/ask-price/${customerId}`);
    return res.data;
  }
);

export const getAskAllPriceList = createAsyncThunk(
  "checkout/askAllPriceListByCustomer",
  async () => {
    const res = await axiosInstance.get("/api/ask-price/all/ask-price-list");
    return res.data;
  }
);

export const saveShippingDetails = createAsyncThunk(
  "checkout/saveShippingDetails",
  async (data) => {
    const res = await axiosInstance.post(
      "/api/customerAuth/save-shipping-details",
      data
    );
    return res.data;
  }
);

export const getShippingDetails = createAsyncThunk(
  "checkout/getShippingDetails",
  async (customerId) => {
    const res = await axiosInstance.get(
      `/api/customerAuth/get-shipping-details/${customerId}`
    );
    return res.data;
  }
);

export const uploadPrescription = createAsyncThunk(
  "checkout/uploadPrescription",
  async (data) => {
    const res = await axiosInstance.post(
      `/api/multer/singlePrescription/`,
      data
    );
    return res.data;
  }
);

export const createPrescription = createAsyncThunk(
  "checkout/createPrescription",
  async (data) => {
    const res = await axiosInstance.post(`/api/prescription/create/`, data);
    return res.data;
  }
);

export const getPrescriptionByCustomer = createAsyncThunk(
  "checkout/getPrescriptionByCustomer",
  async (data) => {
    const res = await axiosInstance.get(
      `/api/prescription/get-prescription-by-customer/${data.customerId}`
    );
    return res.data;
  }
);

export const removePrescription = createAsyncThunk(
  "checkout/removePrescription",
  async (data) => {
    const res = await axiosInstance.post(`/api/prescription/remove/`, data);
    return res.data;
  }
);

export const addPrescriptionImage = createAsyncThunk(
  "checkout/addPrescriptionImage",
  async (data) => {
    const res = await axiosInstance.post(
      `/api/prescription/add-prescription-image/`,
      data
    );
    return res.data;
  }
);

export const getAllPrescription = createAsyncThunk(
  "checkout/getAllPrescription",
  async () => {
    const res = await axiosInstance.get(
      `/api/prescription/get-all-prescription`
    );
    return res.data;
  }
);

export const updateOrder = createAsyncThunk(
  "checkout/updateOrder",
  async (data) => {
    const res = await axiosInstance.post(
      `/api/order/update-order/${data.customerId}`,
      data
    );
    return res.data;
  }
);

export const getLatestOrder = createAsyncThunk(
  "checkout/getLatestOrder",
  async (data) => {
    const res = await axiosInstance.get(
      `/api/order/get-latest-order/${data.customerId}`
    );
    return res.data;
  }
);

export const getAllOrders = createAsyncThunk(
  "checkout/getAllOrders",
  async () => {
    const res = await axiosInstance.get(`/api/order/get-all-orders/`);
    return res.data;
  }
);

// Initial State
const initialState = {
  pending: false,
  error: false,
  askPriceListByCustomer: [],
  askPriceResponse: {},
  prescriptions: [],
  allPrescriptions: [],
  snackNotiCheckout: {},
  askAllPriceList: [],
  latestOrder: {},
  orders: [],
  shippingDetails: {
    firstName: "",
    lastName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    postal: "",
    country: "",
  },
};

// Slice
export const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    resetSnkCheckout(state) {
      state.snackNotiCheckout = {};
      state.askPriceResponse = {};
    },
    resetAskPriceListByCustomer(state) {
      state.askPriceListByCustomer = [];
    },
  },
  extraReducers: {
    [askPrice.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [askPrice.fulfilled]: (state, action) => {
      state.pending = false;
      state.askPriceResponse = action.payload;
      state.snackNotiCheckout = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [askPrice.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [getAskPriceListByCustomer.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getAskPriceListByCustomer.fulfilled]: (state, action) => {
      state.pending = false;
      state.askPriceListByCustomer = action.payload.data;
    },
    [getAskPriceListByCustomer.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [getAskAllPriceList.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getAskAllPriceList.fulfilled]: (state, action) => {
      state.pending = false;
      state.askAllPriceList = action.payload.data;
    },
    [getAskAllPriceList.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [saveShippingDetails.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [saveShippingDetails.fulfilled]: (state, action) => {
      state.pending = false;
    },
    [saveShippingDetails.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [getShippingDetails.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getShippingDetails.fulfilled]: (state, action) => {
      state.pending = false;
      state.shippingDetails = action.payload;
    },
    [getShippingDetails.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [uploadPrescription.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [uploadPrescription.fulfilled]: (state, action) => {
      state.pending = false;
    },
    [uploadPrescription.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [createPrescription.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [createPrescription.fulfilled]: (state, action) => {
      state.pending = false;
    },
    [createPrescription.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [getPrescriptionByCustomer.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getPrescriptionByCustomer.fulfilled]: (state, action) => {
      state.pending = false;
      state.prescriptions = action.payload;
    },
    [getPrescriptionByCustomer.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [getAllPrescription.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getAllPrescription.fulfilled]: (state, action) => {
      state.pending = false;
      state.allPrescriptions = action.payload;
    },
    [getAllPrescription.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [removePrescription.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [removePrescription.fulfilled]: (state, action) => {
      state.pending = false;
    },
    [removePrescription.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [addPrescriptionImage.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [addPrescriptionImage.fulfilled]: (state) => {
      state.pending = false;
    },
    [addPrescriptionImage.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [updateOrder.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [updateOrder.fulfilled]: (state) => {
      state.pending = false;
    },
    [updateOrder.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [getLatestOrder.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getLatestOrder.fulfilled]: (state, action) => {
      state.pending = false;
      state.latestOrder = action.payload;
    },
    [getLatestOrder.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [getAllOrders.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getAllOrders.fulfilled]: (state, action) => {
      state.pending = false;
      state.orders = action.payload;
    },
    [getAllOrders.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
  },
});
//
export const { resetSnkCheckout, resetAskPriceListByCustomer } =
  checkoutSlice.actions;
export default checkoutSlice.reducer;
