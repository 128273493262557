export const getProductNameFromURL = () => {
  // Setting Menu
  var currentUrl = window.location.href;
  var pId = currentUrl.split("/");
  return pId[pId.length - 1] || "";
};

export const getProductNameFromSpecificURL = (url) => {
  // var currentUrl = window.location.href;
  var pId = url.split("/");
  return pId[pId.length - 1] || "";
};

export const getLangFromSpecificURL = (url) => {
  var u = url.split("/");
  let lang = u[u.length - 2];
  lang = lang.replaceAll("lang=", "");
  return lang;
};
