import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Container,
  createFilterOptions,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  DialogContentText,
  DialogActions,
  Drawer,
} from "@mui/material";
import React, { useEffect, useState } from "react";

// Lazy Load Import
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PF } from "../config";
import {
  getPaymentSettings,
  getSettings,
} from "../reduxToolkit/features/adminPanel/webSettings";

// Media Query

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import AuthDialogue from "./appBar/AuthDialogue";
import LogoutDialogue from "./appBar/LogoutDialogue";
import { resetAskPriceListByCustomer } from "../reduxToolkit/features/frontend/checkout";
import { getCompareList } from "../reduxToolkit/features/frontend/compare";
import { getWishlist } from "../reduxToolkit/features/frontend/wishlist";
import {
  checkIfBlockCustomer,
  closePromptLogin,
  logout,
  logOutUser,
  unblockRequest,
} from "../reduxToolkit/features/auth";
import {
  getCurrency,
  getLangs,
} from "../reduxToolkit/features/adminPanel/misc";
import CaptchaTikTakToe from "./misc/CaptchaTikTakToe";
import LoadingButton from "@mui/lab/LoadingButton";
import SnackNotification from "./misc/SnackNotification";
import {
  setActiveCurrencyFront,
  setActiveLangFront,
} from "../reduxToolkit/features/miscFront";
import { getAssignedPriceByCustomer } from "../reduxToolkit/features/adminPanel/assignPrice";
import { getCartInfoByCustomer } from "../reduxToolkit/features/frontend/cart";
import getSymbolFromCurrency from "currency-symbol-map";
import {
  getCompanies,
  getDosages,
  getMedCategories,
  getMedicines,
  getViewCount,
} from "../reduxToolkit/features/adminPanel/medicine";

import Seo from "../utils/Seo";
import { getManagedContentSettings } from "../reduxToolkit/features/adminPanel/managedContentSettings";
import LangImport from "../screens/adminPanel/LangImport";

// Limit options to show in autocomplete
const OPTIONS_LIMIT = 8;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) => {
  return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
};

export default function MobileTopBar() {
  // Responsive
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  //   Use States
  const [lang, setLang] = useState("en");
  const [langId, setLangId] = useState("");
  const [contentSettings, setContentSettings] = useState([]);
  const [loginStatus, setLoginStatus] = useState(false);
  const [signUpOpen, setSignUpOpen] = useState(false);
  const [signInOpen, setSignInOpen] = useState(false);
  const [logoutD, setLogoutD] = useState(false);

  // SnackBar
  const [openSnk, setOpenSnk] = useState(false);
  const [snkMessage, setSnkMessage] = useState("");
  const [snkSeverity, setSnkSeverity] = useState("");

  // Dlg
  const [unblockLoading, setUnBlockLoading] = useState(false);
  const [blockContactDlg, setBlockContactDlg] = useState(false);
  const [captchaDlg, setCaptchaDlg] = useState(false);
  const [blockContactBuffer, setBlockContactBuffer] = useState({});
  const [unBlockMsg, setUnBlockMsg] = useState("");

  //   const [openCurrencyChooseDlg, setOpenCurrencyChooseDlg] = useState(false);
  const [openLangChooseDlg, setOpenLangChooseDlg] = useState(false);

  // Local Store
  // const localData = JSON.parse(localStorage.getItem("loginInfo"));
  const localLangDataFront = JSON.parse(localStorage.getItem("langFront"));
  const localCurrencyDataFront = JSON.parse(
    localStorage.getItem("currencyFront")
  );
  const localCustomerData = () => {
    const c = JSON.parse(localStorage.getItem("loginInfo"));
    return c ? c : {};
  };

  //   Store
  const { webSettings } = useSelector((state) => state.webSettings);
  const { managedContentSettings } = useSelector(
    (state) => state.managedContentSettings
  );
  const { snackNotiMiscFront, activeLangFront } = useSelector(
    (state) => state.miscFront
  );
  const { snackNotiCustomerOnProduct } = useSelector(
    (state) => state.customerOnProduct
  );
  const { snackNotiCompare } = useSelector((state) => state.compare);
  const { snackNotiWishlist } = useSelector((state) => state.wishlist);
  const { snackNotiCheckout } = useSelector((state) => state.checkout);
  const { snackNotiCart } = useSelector((state) => state.cart);
  const { snackNotiCustomer, checkBlockResponse, authData, loginPrompt } =
    useSelector((state) => state.auth);
  const { langs, currencyList } = useSelector((state) => state.misc);
  const { activeCurrencyFront } = useSelector((state) => state.miscFront);

  //   Functions
  const handleLogin = () => {
    if (!loginStatus) {
      setSignInOpen(true);
    } else setLogoutD(true);
  };

  const handleChangeLang = (e) => {
    setLang(e.target.value);
    const L = langs.find((l) => l.langCode === e.target.value);
    if (L) {
      localStorage.setItem("langFront", JSON.stringify(L));
      dispatch(setActiveLangFront(L));
      setOpenLangChooseDlg(false);
    }
  };

  const handleUnBlockreq = () => {
    dispatch(
      unblockRequest({
        id: blockContactBuffer.id,
        message: unBlockMsg,
        langId: localLangDataFront._id,
      })
    );
    setUnBlockLoading(true);

    setTimeout(() => {
      setUnBlockLoading(false);
      setBlockContactDlg(false);
      setBlockContactBuffer({});
      setUnBlockMsg("");
    }, 2000);
  };

  // Check Block
  useEffect(() => {
    if (checkBlockResponse === true && localCustomerData()._id) {
      if (authData.customerInfo) {
        setBlockContactBuffer({
          id: authData.customerInfo._id,
          blockMsgCount: authData.customerInfo.unblockMessage.length,
        });
      }
      dispatch(logout());
      dispatch(logOutUser({ id: localCustomerData()._id }));
      setTimeout(() => {
        dispatch(getCompareList());
        dispatch(getWishlist());
        dispatch(resetAskPriceListByCustomer());
        setLoginStatus(false);
        setBlockContactDlg(true);
        setLoginStatus(false);
        localStorage.removeItem("loginInfo");
      }, 500);
    }
  }, [checkBlockResponse, authData]);

  // Use Effect
  useEffect(() => {
    if (snackNotiCustomer && snackNotiCustomer.message) {
      setOpenSnk(true);
      setSnkMessage(snackNotiCustomer.message);
      setSnkSeverity(snackNotiCustomer.severity);
    }
    if (snackNotiMiscFront && snackNotiMiscFront.message) {
      setOpenSnk(true);
      setSnkMessage(snackNotiMiscFront.message);
      setSnkSeverity(snackNotiMiscFront.severity);
    }
    if (snackNotiCustomerOnProduct && snackNotiCustomerOnProduct.message) {
      setOpenSnk(true);
      setSnkMessage(snackNotiCustomerOnProduct.message);
      setSnkSeverity(snackNotiCustomerOnProduct.severity);
    }
    if (snackNotiCompare && snackNotiCompare.message) {
      setOpenSnk(true);
      setSnkMessage(snackNotiCompare.message);
      setSnkSeverity(snackNotiCompare.severity);
    }
    if (snackNotiWishlist && snackNotiWishlist.message) {
      setOpenSnk(true);
      setSnkMessage(snackNotiWishlist.message);
      setSnkSeverity(snackNotiWishlist.severity);
    }
    if (snackNotiCheckout && snackNotiCheckout.message) {
      setOpenSnk(true);
      setSnkMessage(snackNotiCheckout.message);
      setSnkSeverity(snackNotiCheckout.severity);
    }
    if (snackNotiCart && snackNotiCart.message) {
      setOpenSnk(true);
      setSnkMessage(snackNotiCart.message);
      setSnkSeverity(snackNotiCart.severity);
    }
  }, [
    snackNotiCustomer,
    snackNotiMiscFront,
    snackNotiCustomerOnProduct,
    snackNotiCompare,
    snackNotiWishlist,
    snackNotiCheckout,
    snackNotiCart,
  ]);

  // Handle Login / logout status
  useEffect(() => {
    dispatch(getCurrency());
    dispatch(getLangs());
    if (localCustomerData()._id) {
      dispatch(checkIfBlockCustomer(localCustomerData()._id));
      setTimeout(() => {
        if (localCustomerData().expire) {
          var now = new Date().getTime();
          var distance = localCustomerData().expire - now;
          if (distance < 0 || localCustomerData().block) {
            setLoginStatus(false);
            localStorage.removeItem("loginInfo");
            dispatch(logOutUser({ id: localCustomerData()._id }));
            dispatch(resetAskPriceListByCustomer());
            dispatch(getCompareList());
            dispatch(getWishlist());
          } else {
            setLoginStatus(true);
          }
        } else setLoginStatus(true);
      }, 1000);
    } else setLoginStatus(false);
    // Get backend web settings
    dispatch(getSettings());
    dispatch(getManagedContentSettings());
    dispatch(getPaymentSettings());
    // dispatch(getHeadTitle());
  }, []);

  useEffect(() => {
    if (activeLangFront) {
      setLang(activeLangFront.langCode);
      setLangId(activeLangFront._id);
    }
  }, [activeLangFront]);

  // Lang
  useEffect(() => {
    if (localLangDataFront) {
      setLang(localLangDataFront.langCode);
      setLangId(localLangDataFront._id);
      dispatch(setActiveLangFront(localLangDataFront));
    } else {
      if (langs.length > 0) {
        const L = langs.find((l) => l.langCode === "en");
        if (L) {
          setLangId(L._id);
          localStorage.setItem("langFront", JSON.stringify(L));
          dispatch(setActiveLangFront(L));
        }
      }
    }
  }, [langs]);

  //   handle Currency Change
  const handleCurrencyChange = (e, newValue) => {
    const obj = {
      exchangeRate: newValue.exchangeRate,
      currencyName: newValue.currencyName,
    };
    localStorage.setItem("currencyFront", JSON.stringify(obj));
    dispatch(setActiveCurrencyFront(obj));
  };

  // Set Currency
  useEffect(() => {
    if (
      localCurrencyDataFront !== null &&
      localCurrencyDataFront.currencyName
    ) {
      const obj = {
        exchangeRate: localCurrencyDataFront.exchangeRate,
        currencyName: localCurrencyDataFront.currencyName,
      };
      dispatch(setActiveCurrencyFront(obj));
    }
  }, []);

  useEffect(() => {
    if (loginPrompt) setSignInOpen(true);
    dispatch(closePromptLogin());
  }, [loginPrompt]);

  // Check login status
  useEffect(() => {
    dispatch(getAssignedPriceByCustomer(localCustomerData()._id));
    dispatch(getCartInfoByCustomer(localCustomerData()._id));
    // setTimeout(() => {}, 1000);
  }, [authData]);

  // store
  const { medicines, categories, companies, dosages, viewCountArr } =
    useSelector((state) => state.medicine);

  useEffect(() => {
    // dispatch(getViewCount());
    if (!(medicines && medicines.length > 0)) dispatch(getMedicines());
    if (!(categories && categories.length > 0)) dispatch(getMedCategories());
    if (!(companies && companies.length > 0)) dispatch(getCompanies());
    if (!(dosages && dosages.length > 0)) dispatch(getDosages());
    if (!(viewCountArr && viewCountArr.length > 0)) dispatch(getViewCount());
  }, []);

  // // Set Site Title
  // useEffect(() => {
  //   if (webSettings && webSettings.siteTitle) {
  //     document.title = webSettings.siteTitle;
  //   }
  // }, [webSettings]);
  // // Set Site Title
  // useEffect(() => {
  //   if (webSettings && webSettings.siteTitle) {
  //     document.title = webSettings.siteTitle;
  //   }
  // }, [webSettings]);

  // Set Settings
  useEffect(() => {
    if (managedContentSettings && managedContentSettings.length > 0) {
      const M = managedContentSettings.find((m) => m.langId === langId);
      if (M) setContentSettings(M);
    }
  }, [langId, managedContentSettings]);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate

  return (
    <>
      <Seo
        title={
          (contentSettings.siteSeoSettings &&
            contentSettings.siteSeoSettings[0].siteName) ||
          "elifesaving Medicine"
        }
        description={
          (contentSettings.siteSeoSettings &&
            contentSettings.siteSeoSettings[0].siteDesc) ||
          "elifesaving Medicine is an online-based medicine-supplying service that provides safe and affordable lifesaving prescription medicines globally."
        }
        name={
          (contentSettings.siteSeoSettings &&
            contentSettings.siteSeoSettings[0].companyName) ||
          "elifesaving Medicine"
        }
        type="website"
        url={window.location.href || ""}
        imageUrl={
          webSettings.siteLogo !== ""
            ? PF + "/misc/" + webSettings.siteLogo
            : "https://mfw.com.bd/wp-content/uploads/2021/05/getImage.png"
        }
        langCode={lang}
      />
      <Box
        sx={{
          //   position: "sticky",
          //   top: 0,
          zIndex: 13,
          display: "flex",
          justifyContent: "center",
          background: webSettings.siteColorTheme || "lightblue",
          //   pt: 1,
          pr: 2,
        }}
      >
        <LangImport front={true} />
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={2}>
            <Container>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={loginStatus ? true : false}
                      onChange={handleLogin}
                      aria-label="login switch"
                      color="error"
                    />
                  }
                  //   label={loginStatus ? "Logout" : "Login"}
                />
                <AuthDialogue
                  signUpOpen={signUpOpen}
                  setSignUpOpen={setSignUpOpen}
                  signInOpen={signInOpen}
                  setSignInOpen={setSignInOpen}
                  setLoginStatus={setLoginStatus}
                />
                <LogoutDialogue
                  setLoginStatus={setLoginStatus}
                  logoutD={logoutD}
                  setLogoutD={setLogoutD}
                />
              </FormGroup>
            </Container>
          </Grid>

          <Grid item xs={4.5}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 1,
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/");
                // dispatch(triggerPreloader());
              }}
            >
              <LazyLoadImage
                alt="Logo"
                effect="blur"
                src={
                  webSettings.siteLogo !== ""
                    ? PF + "/misc/" + webSettings.siteLogo
                    : "https://mfw.com.bd/wp-content/uploads/2021/05/getImage.png"
                }
                style={{
                  objectFit: "cover",
                  // width: !smUp && "100%",
                  maxHeight: 60,
                  // width: "100%",
                  margin: "0px 1px",
                  // borderRadius: "5rem",
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={2}>
            <IconButton size="small" onClick={() => setOpenLangChooseDlg(true)}>
              <span
                style={{
                  color: "black",
                  border: "1px solid black",
                  padding: "1px 5px",
                }}
              >
                {lang ? lang.toUpperCase() : "EN"}
              </span>
            </IconButton>
            {openLangChooseDlg && (
              <Dialog
                open={openLangChooseDlg}
                onClose={() => setOpenLangChooseDlg(false)}
                aria-labelledby={"Choose-language-dialog"}
                sx={{
                  "& .MuiDialog-container": {
                    justifyContent: "flex-end",
                    alignItems: "flex-start",
                  },
                }}
              >
                <DialogContent>
                  <Box>
                    <FormControl fullWidth>
                      <Select
                        value={lang}
                        label="language"
                        variant="standard"
                        onChange={handleChangeLang}
                        size="small"
                        sx={{ my: 1 }}
                      >
                        {langs.length > 0 &&
                          langs.map((x, i) => (
                            <MenuItem key={i} value={x.langCode} dense>
                              <img
                                alt={x.langCode}
                                src={`https://flagcdn.com/w20/${
                                  x.langCode === "en" ? "gb" : x.langCode
                                }.png`}
                              />
                              <Button color="inherit" size="small">
                                {x.langName}
                              </Button>
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                </DialogContent>
              </Dialog>
            )}
          </Grid>

          <Grid item xs={3.5}>
            <Box>
              {currencyList &&
                currencyList.length > 0 &&
                activeCurrencyFront.currencyName && (
                  <Autocomplete
                    size="small"
                    onChange={(e, newValue) =>
                      handleCurrencyChange(e, newValue)
                    }
                    disablePortal
                    options={currencyList}
                    defaultValue={currencyList.find(
                      (c) => c.currencyName === activeCurrencyFront.currencyName
                    )}
                    filterOptions={filterOptions}
                    getOptionLabel={(option) =>
                      //   "(" +
                      //   getSymbolFromCurrency(option.currencyName) +
                      //   ") " +
                      option.currencyName
                    }
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{
                          "& > img": { mr: 2, flexShrink: 0 },
                        }}
                        {...props}
                      >
                        ({getSymbolFromCurrency(option.currencyName)}){" "}
                        {option.currencyName}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // label="Select Currency"
                        variant="standard"
                        size="small"
                        fullWidth
                        sx={{ my: 1 }}
                      />
                    )}
                  />
                )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <SnackNotification
        message={snkMessage}
        setMessage={setSnkMessage}
        severity={snkSeverity}
        setSeverity={setSnkSeverity}
        openSnk={openSnk}
        setOpenSnk={setOpenSnk}
      />
      {/* Block Conctact */}
      {blockContactDlg && (
        <Dialog
          open={blockContactDlg}
          onClose={() => setBlockContactDlg(false)}
          fullWidth
          maxWidth={"lg"}
        >
          <DialogTitle>
            <Typography variant="h6" color="error" align="center">
              {" "}
              {translateFront("You Are Blocked For Unfair Usage")}
            </Typography>
            <Divider />
          </DialogTitle>
          <DialogContent>
            {blockContactBuffer.blockMsgCount >=
            (webSettings.maxUnblockReqCount || 2) ? (
              <Alert severity="error">
                {translateFront("Maximum Limit Reached For Unblock Request")}
              </Alert>
            ) : (
              <>
                <TextField
                  label="Message"
                  value={unBlockMsg}
                  onChange={(e) => setUnBlockMsg(e.target.value)}
                  fullWidth
                  multiline
                  minRows={3}
                  variant="standard"
                  size="small"
                  required
                />
                <Typography variant="body2" color="secondary">
                  {" "}
                  {translateFront(
                    "Please Check Email For Reviewing Reason For Blocking"
                  )}
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <LoadingButton
                    variant="contained"
                    size="small"
                    sx={{ my: 1 }}
                    loading={unblockLoading}
                    disabled={unBlockMsg.length > 0 ? false : true}
                    onClick={() => setCaptchaDlg(true)}
                  >
                    {translateFront("Request Unblock")}
                  </LoadingButton>
                </Box>
              </>
            )}
          </DialogContent>
        </Dialog>
      )}
      {/* Captcha */}
      <Dialog open={captchaDlg} onClose={() => setCaptchaDlg(false)}>
        <DialogContent>
          <CaptchaTikTakToe
            loginFunc={handleUnBlockreq}
            setCaptchaDlg={setCaptchaDlg}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
