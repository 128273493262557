import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  createFilterOptions,
  Dialog,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingBox from "../../../../../components/misc/Loading";
import { PF } from "../../../../../config";
import {
  getCompanies,
  getDosages,
  getMedicines,
} from "../../../../../reduxToolkit/features/adminPanel/medicine";
import ViewProductDetails from "../ViewProductDetails";

import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

// Limit options to show in autocomplete
const OPTIONS_LIMIT = 10;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) => {
  return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
};

export default function ChooseItems({
  lang,
  localLangData,
  selectedVariantArr,
  setSelectedVariantArr,
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  //   Store
  const { medicines, companies, medVariants, dosages } = useSelector(
    (state) => state.medicine
  );

  useEffect(() => {
    if (!(medicines && medicines.length > 0)) dispatch(getMedicines());
    if (!(companies && companies.length > 0)) dispatch(getCompanies());
    if (!(dosages && dosages.length > 0)) dispatch(getDosages());
  }, []);

  const [medList, setMedList] = useState([]);
  const [medListEn, setMedListEn] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [selectedMed, setSelectedMed] = useState([]);
  const [viewMed, setViewMed] = useState(undefined);

  // get MEd Name En
  const getMedNameEn = (id) => {
    const M = medListEn.find((m) => m.fixedMedicineId === id);
    if (M) return M.genericBrand;
  };

  // Use Effect
  useEffect(() => {
    if (companies && companies.length > 0) {
      let C = companies.find((c) => c.lang === lang);
      if (C) setCompanyList(C.data);
    }
  }, [companies, lang]);

  const handleSearch = (e, newValue) => {
    setSelectedMed(newValue);
  };

  const getCompany = (companyId) => {
    if (companyList.length > 0) {
      let cName = "";
      let cImage = "";
      let C = companyList.find(
        (c) =>
          (lang && lang === "en" ? c.fixedCompanyId : c.companyDetectorId) ===
          companyId
      );
      if (C) {
        cName = C.companyName;
        cImage = C.companyLogo;
      }
      return { cName, cImage };
    }
    return "";
  };

  const getDosageInfo = (dosageId, langCode) => {
    let dosageName = "";
    if (dosages && dosages.length > 0) {
      const dosageList = dosages.find((d) => d.lang === langCode);
      if (dosageList && dosageList.data) {
        const D = dosageList.data.find((d) =>
          langCode !== "en" ? d.dosageDetectorId : d.fixedDosageId === dosageId
        );
        if (D && D.dosageName) dosageName = D.dosageName;
      }
    }
    return dosageName;
  };

  const getVariantInfo = (varId) => {
    let obj = {
      dosage: "",
      strength: "",
      packTypes: "",
    };
    if (medVariants && medVariants.length > 0) {
      const V = medVariants.find(
        (v) =>
          (localLangData.langCode !== "en"
            ? v.variantDetectorId
            : v.fixedVariantId) === varId
      );
      if (V) {
        obj.dosage = getDosageInfo(V.dosageForm, localLangData.langCode);
        obj.strength = V.strength;
        obj.packTypes = V.packTypes;
      }
    }
    return obj;
  };

  const handleVariantChange = (
    id,
    genericBrand,
    generic,
    company,
    dosage,
    strength,
    packType
  ) => {
    let arr = [...selectedVariantArr];
    const A = arr.find((a) => a.varId === id);
    if (A) {
      arr = arr.filter((a) => a.varId !== id);
    } else {
      arr.push({
        genericBrand,
        generic,
        company,
        dosage,
        strength,
        packType,
        varId: id,
        unitPrice: 0,
        qty: 0,
      });
    }
    setSelectedVariantArr(arr);
  };

  const checkVariantExist = (varId) => {
    let arr = [...selectedVariantArr];
    const A = arr.find((a) => a.varId === varId);
    if (A) return true;
    return false;
  };

  const getVariantPrice = (varId) => {
    let arr = [...selectedVariantArr];
    const A = arr.find((a) => a.varId === varId);
    if (A && A.unitPrice !== undefined) return A.unitPrice;
    else return 0;
  };

  const getVariantQty = (varId) => {
    let arr = [...selectedVariantArr];
    const A = arr.find((a) => a.varId === varId);
    if (A && A.qty !== undefined) return A.qty;
    else return 0;
  };

  const handleVariantPriceChange = (e, varId) => {
    let arr = [...selectedVariantArr];
    const AIndex = arr.findIndex((a) => a.varId === varId);
    if (AIndex !== undefined) {
      let obj = { ...arr[AIndex] };
      obj.unitPrice = e.target.value.replace(/\D/g, "");
      arr[AIndex] = obj;
    }
    setSelectedVariantArr(arr);
  };

  const handleRemoveQty = (varId) => {
    let arr = [...selectedVariantArr];
    const AIndex = arr.findIndex((a) => a.varId === varId);
    if (AIndex !== undefined) {
      let obj = { ...arr[AIndex] };
      obj.qty = obj.qty ? obj.qty - 1 : 0;
      arr[AIndex] = obj;
    }
    setSelectedVariantArr(arr);
  };

  const handleAddQty = (varId) => {
    let arr = [...selectedVariantArr];
    const AIndex = arr.findIndex((a) => a.varId === varId);
    if (AIndex !== undefined) {
      let obj = { ...arr[AIndex] };
      obj.qty = obj.qty ? obj.qty + 1 : 1;
      arr[AIndex] = obj;
    }
    setSelectedVariantArr(arr);
  };

  useEffect(() => {
    setLoading(true);
    if (medicines && medicines.length > 0) {
      const m = medicines.find((m) => {
        return m.lang === lang;
      });
      const mEn = medicines.find((m) => m.lang === "en");

      if (m && m.data.length > 0) {
        let arr = [...m.data].sort((a, b) =>
          a.genericBrand.toLowerCase().replace(/\s/g, "") >
          b.genericBrand.toLowerCase().replace(/\s/g, "")
            ? 1
            : -1
        );
        setMedList(arr);
      }
      if (mEn && mEn.data.length > 0) {
        let arrEn = [...mEn.data].sort((a, b) =>
          a.genericBrand.toLowerCase().replace(/\s/g, "") >
          b.genericBrand.toLowerCase().replace(/\s/g, "")
            ? 1
            : -1
        );
        setMedListEn(arrEn);
      }
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [medicines, lang]);

  //! Translate
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate
  return (
    <>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          size="small"
          sx={{ pointerEvents: "none", mb: 1 }}
          fullWidth
          color="info"
        >
          {translate0("Choose Items")}
        </Button>
      </Grid>

      <Grid item xs={12}>
        {loading ? (
          <LoadingBox w="100%" />
        ) : (
          <Autocomplete
            disablePortal
            multiple
            options={medList}
            onChange={(e, newValue) => handleSearch(e, newValue)}
            filterOptions={filterOptions}
            getOptionLabel={(option) => option.genericBrand}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={
                    option.image.length > 0
                      ? option.image.find((i) => i.default).src.includes("http")
                        ? option.image.find((i) => i.default).src
                        : PF +
                          "adminPanel/medicines/img/" +
                          option.image.find((i) => i.default).src
                      : PF + "adminPanel/medicines/no-medicine-image-mfw.png"
                  }
                  alt="admins"
                />
                {option.genericBrand}{" "}
                {lang !== "en" &&
                  " (" + getMedNameEn(option.medicineDetectorId) + ")"}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={translate0("Search") + "..."}
                variant="outlined"
                size="small"
                fullWidth
              />
            )}
          />
        )}
      </Grid>
      {selectedMed.map((med, index) => (
        <Grid item xs={12} sm={4} key={index}>
          <Card>
            <CardContent>
              <Typography
                variant="subtitle1"
                color="initial"
                sx={{ fontSize: "1.3rem" }}
              >
                {med.genericBrand}{" "}
                <IconButton onClick={() => setViewMed(med)}>
                  <img
                    loading="lazy"
                    // width="30"
                    src={
                      med.image.length > 0
                        ? med.image.find((i) => i.default).src.includes("http")
                          ? med.image.find((i) => i.default).src
                          : PF +
                            "adminPanel/medicines/img/" +
                            med.image.find((i) => i.default).src
                        : PF + "adminPanel/medicines/no-medicine-image-mfw.png"
                    }
                    alt="admins"
                    style={{ height: "40px", objectFit: "cover" }}
                  />
                </IconButton>
              </Typography>
              <Typography variant="body2" color="initial">
                {getCompany(med.companyId).cName}
              </Typography>
              <FormGroup>
                {med &&
                  med.variants.length &&
                  med.variants.map((variant, index) => (
                    <Box
                      sx={{
                        p: 1,
                        border: "1px solid lightblue",
                        borderRadius: ".25rem",
                        my: 1,
                      }}
                      key={index}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkVariantExist(variant.variantId)}
                            onChange={() =>
                              handleVariantChange(
                                variant.variantId,
                                med.genericBrand,
                                med.generic,
                                getCompany(med.companyId).cName,

                                getVariantInfo(variant.variantId).dosage,

                                getVariantInfo(variant.variantId).strength,
                                getVariantInfo(variant.variantId).packTypes
                              )
                            }
                          />
                        }
                        label={` (
                    ${getVariantInfo(variant.variantId).dosage}
                    -
                    ${getVariantInfo(variant.variantId).strength}
                    -
                    ${getVariantInfo(variant.variantId).packTypes}
                    ) `}
                      />
                      <Grid container spacing={1}>
                        <Grid item flex={1}>
                          <TextField
                            label="Unit Price (USD)"
                            value={getVariantPrice(variant.variantId)}
                            onChange={(e) =>
                              handleVariantPriceChange(e, variant.variantId)
                            }
                            size="small"
                            fullWidth
                            variant="standard"
                            disabled={!checkVariantExist(variant.variantId)}
                          />
                        </Grid>
                        <Grid item>
                          <Grid
                            container
                            spacing={1}
                            alignItems="center"
                            sx={{ mt: 1 }}
                          >
                            <Grid item>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  handleRemoveQty(variant.variantId)
                                }
                                disabled={
                                  Number(getVariantQty(variant.variantId)) <
                                    1 || !checkVariantExist(variant.variantId)
                                }
                              >
                                <RemoveCircleOutlineIcon
                                  color={
                                    Number(getVariantQty(variant.variantId)) <
                                      1 || !checkVariantExist(variant.variantId)
                                      ? "inherit"
                                      : "error"
                                  }
                                />
                              </IconButton>
                            </Grid>
                            <Grid item>
                              <Typography
                                variant="body2"
                                color={
                                  !checkVariantExist(variant.variantId)
                                    ? "gray"
                                    : "initial"
                                }
                                sx={{ fontSize: "1rem" }}
                              >
                                {getVariantQty(variant.variantId)}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <IconButton
                                size="small"
                                onClick={() => handleAddQty(variant.variantId)}
                                disabled={!checkVariantExist(variant.variantId)}
                              >
                                <AddCircleOutlineIcon
                                  color={
                                    !checkVariantExist(variant.variantId)
                                      ? "inherit"
                                      : "success"
                                  }
                                />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
              </FormGroup>
            </CardContent>
          </Card>
        </Grid>
      ))}

      {/* View Med DIalog */}
      {viewMed && (
        <Dialog
          open={viewMed}
          onClose={() => setViewMed(undefined)}
          fullWidth
          maxWidth={"md"}
        >
          <ViewProductDetails medId={viewMed.fixedMedicineId} lang={lang} />
        </Dialog>
      )}
    </>
  );
}
