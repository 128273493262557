import React, { Fragment, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  IconButton,
} from "@mui/material";
import AddOfficeExpenseDlg from "../dialogs/AddOfficeExpenseDlg";
import { useDispatch, useSelector } from "react-redux";
import { getOfficeExpense } from "../../../../reduxToolkit/features/adminPanel/expense";
import { currencyFormatter } from "../../../../hooks/currencyFormatter";

import LoadingBox from "../../../../components/misc/Loading";
import { getMonthName } from "../../../../hooks/getMonthName";
import OfficeExpenseCard from "../card/OfficeExpenseCard";

// Icon
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

// Media Query
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import PreviewOfficeExpenseSheet from "../dialogs/PreviewOfficeExpenseSheet";

const OfficeExpenseSheet = () => {
  const dispatch = useDispatch();

  // Media Query
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  // Store
  const { officeExpenses } = useSelector((state) => state.expense);

  useEffect(() => {
    dispatch(getOfficeExpense());
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const [loading, setLoading] = useState(false);

  //   Last selected Date
  const [lastSelectedDate, setLastSelectedDate] = useState(new Date());

  const calculatePlus = (arr) => {
    const plus = arr.filter((oe) => oe.isPlus);
    const plusTotal = plus.reduce(
      (accumulator, currentValue) => accumulator + currentValue.total,
      0
    );
    return plusTotal;
  };

  const calculateMinus = (arr) => {
    const minus = arr.filter((oe) => !oe.isPlus);
    const minusTotal = minus.reduce(
      (accumulator, currentValue) => accumulator + currentValue.total,
      0
    );
    return minusTotal;
  };

  //   Generate Per Month Data
  const [monthlyData, setMonthlyData] = useState([]);
  useEffect(() => {
    if (officeExpenses && officeExpenses.length) {
      let months = [];
      officeExpenses.forEach((oe) => {
        if (oe.date) {
          const monthObj = {
            monthName: getMonthName(oe.date),
          };
          months.push(monthObj);
        }
      });
      let uniqueMonths = [
        ...new Map(months.map((item) => [item["monthName"], item])).values(),
      ];
      let inHand = 0;
      uniqueMonths.forEach((m) => {
        m.inHandFromPrev = inHand;

        let entries = officeExpenses.filter(
          (oe) => getMonthName(oe.date) === m.monthName
        );
        let dates = [];
        entries.forEach((e) => {
          if (e.date) {
            let dateObj = {
              date: new Date(e.date).getDate(),
            };
            dates.push(dateObj);
          }
        });
        let uniqueDates = [
          ...new Map(dates.map((item) => [item["date"], item])).values(),
        ];

        let perDateEntry = [];

        uniqueDates.forEach((d) => {
          let dateEntries = entries.filter(
            (e) => new Date(e.date).getDate() === d.date
          );
          if (dateEntries.length) {
            d.dateEntries = dateEntries;
            d.inHand =
              inHand + calculatePlus(dateEntries) - calculateMinus(dateEntries);
            inHand = d.inHand;
          }
          perDateEntry.push(d);
        });

        m.entries = perDateEntry;
        m.added = calculatePlus(entries);
        m.spent = calculateMinus(entries);
        // m.transaction =
        //   inHand + calculatePlus(entries) - calculateMinus(entries);
        // inHand += m.transaction;
        m.inHand = inHand;
      });
      setMonthlyData(uniqueMonths);
    }
  }, [officeExpenses]);

  const [addExpenseDlg, setAddExpenseDlg] = useState(false);

  // Expense Preview
  const [expensePreviewBuffer, setExpensePreviewBuffer] = useState(null);

  return (
    <>
      {/* Topbar */}
      <Grid container spacing={1} sx={{ mb: 2 }}>
        <Grid item xs={12} lg={1}>
          <Button
            variant="contained"
            color="error"
            fullWidth
            size="small"
            onClick={() => setAddExpenseDlg(true)}
          >
            Add
          </Button>
        </Grid>
        <Grid item xs={12} lg={11}>
          <Button
            variant="outlined"
            color="info"
            fullWidth
            size="small"
            sx={{ pointerEvents: "none" }}
          >
            Office Expenditure Sheet
          </Button>
        </Grid>
      </Grid>

      {/* Table Body */}
      {loading ? (
        <LoadingBox w="100%" />
      ) : (
        <Box sx={{ maxHeight: "65vh", overflowY: "auto", overflowX: "hidden" }}>
          {monthlyData.map((m, index) => (
            <Fragment key={index}>
              <Box sx={{ position: "relative" }}>
                <Box
                  sx={{
                    position: "absolute",
                    left: "10px",
                    top: "15px",
                    bottom: 0,
                    zIndex: 10,
                  }}
                >
                  <IconButton onClick={() => setExpensePreviewBuffer(m)}>
                    <DownloadForOfflineIcon color="error" />
                  </IconButton>
                </Box>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls={`monthly-expense-sheet-${index}`}
                    id={`monthly-expense-sheet-${index}`}
                  >
                    <Grid
                      container
                      spacing={0}
                      justifyContent="start"
                      sx={{ mt: 1 }}
                    >
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle1"
                          color="text.primary"
                          sx={{ fontWeight: 800, mr: 1, ml: "35px" }}
                        >
                          {m.monthName}
                        </Typography>
                      </Grid>
                      {m.inHandFromPrev > 0 && (
                        <Grid item xs={12} lg={2} xl={1.5}>
                          <Typography
                            variant="subtitle2"
                            color="#2980b9"
                            size="small"
                            sx={{
                              pointerEvents: "none",
                            }}
                          >
                            Prev Month: ৳{" "}
                            {currencyFormatter("bdt", m.inHandFromPrev)}
                          </Typography>
                        </Grid>
                      )}
                      <Grid item xs={12} lg={2} xl={1.5}>
                        <Typography
                          variant="subtitle2"
                          color="#27ae60"
                          size="small"
                          sx={{
                            pointerEvents: "none",
                          }}
                        >
                          Added: ৳ {currencyFormatter("bdt", m.added)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} lg={2} xl={1.5}>
                        <Typography
                          variant="subtitle2"
                          color="#c0392b"
                          size="small"
                          sx={{ pointerEvents: "none" }}
                        >
                          Spent: ৳ {currencyFormatter("bdt", m.spent)}
                        </Typography>
                      </Grid>
                      {/* <Grid item xs={12} lg={2} xl={1.5}>
                      <Typography
                        variant="subtitle2"
                        color="#8e44ad"
                        size="small"
                        sx={{ pointerEvents: "none" }}
                      >
                        Transaction: ৳ {currencyFormatter("bdt", m.transaction)}
                      </Typography>
                    </Grid> */}
                      {m.inHand > 0 && (
                        <Grid item xs={12} lg={2} xl={1.5}>
                          <Typography
                            variant="subtitle2"
                            color="#3c6382"
                            size="small"
                            sx={{
                              pointerEvents: "none",
                            }}
                          >
                            In Hand: ৳ {currencyFormatter("bdt", m.inHand)}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    {mdUp && (
                      <>
                        {/* Table Heading */}
                        <Grid container spacing={0} alignItems="center">
                          <Grid item xs={1}>
                            <Typography
                              fullWidth
                              variant="subtitle1"
                              color="text.primary"
                            >
                              Date
                            </Typography>
                          </Grid>
                          <Grid item xs={4.5}>
                            <Typography
                              fullWidth
                              variant="subtitle1"
                              color="text.primary"
                            >
                              Description
                            </Typography>
                          </Grid>
                          <Grid item xs={3.5}>
                            <Typography
                              fullWidth
                              variant="subtitle1"
                              color="text.primary"
                            >
                              Calculation
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              fullWidth
                              variant="subtitle1"
                              color="text.primary"
                              align="right"
                            >
                              Total
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Typography
                              fullWidth
                              variant="subtitle1"
                              color="text.primary"
                              align="right"
                              sx={{ mr: 2 }}
                            >
                              Action
                            </Typography>
                          </Grid>
                        </Grid>

                        <Divider sx={{ my: 1 }} />
                      </>
                    )}
                    {m.entries.map((entry, index) => (
                      <Fragment key={index}>
                        {entry.dateEntries.map((dateEntry, index2) => (
                          <Fragment key={index2}>
                            <OfficeExpenseCard expense={dateEntry} />
                          </Fragment>
                        ))}

                        {/* Table Footer */}
                        {!loading && (
                          <>
                            <Grid
                              container
                              spacing={0}
                              alignItems="center"
                              sx={{ mt: 1 }}
                            >
                              <Grid item xs={8}></Grid>
                              <Grid item xs={6} lg={1}>
                                <Typography
                                  variant="body2"
                                  color="text.primary"
                                  align="right"
                                  sx={{ fontWeight: 900 }}
                                >
                                  In Hand:
                                </Typography>
                              </Grid>
                              <Grid item xs={6} lg={2}>
                                <Typography
                                  fullWidth
                                  variant="body2"
                                  align="right"
                                  color="text.primary"
                                  sx={{ fontWeight: 900 }}
                                >
                                  ৳ {currencyFormatter("bdt", entry.inHand)}
                                </Typography>
                              </Grid>
                              <Grid item xs={1}></Grid>
                            </Grid>

                            <Divider sx={{ my: 1 }} />
                          </>
                        )}
                      </Fragment>
                    ))}
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Fragment>
          ))}
        </Box>
      )}

      {/* Add Expense Dialog */}
      {addExpenseDlg && (
        <AddOfficeExpenseDlg
          open={addExpenseDlg}
          setOpen={setAddExpenseDlg}
          lastSelectedDate={lastSelectedDate}
          setLastSelectedDate={setLastSelectedDate}
        />
      )}

      {/* Expense Preview */}
      {expensePreviewBuffer && (
        <PreviewOfficeExpenseSheet
          buffer={expensePreviewBuffer}
          onClose={() => setExpensePreviewBuffer(null)}
        />
      )}
    </>
  );
};

export default OfficeExpenseSheet;
