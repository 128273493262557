import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Input,
  Tooltip,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
// Icon
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import InfoIcon from "@mui/icons-material/Info";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ClearIcon from "@mui/icons-material/Clear";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { useEffect } from "react";
import { checkIfValidImage } from "../../../../hooks/checkIfValid";
import { useDispatch } from "react-redux";
// Redux
import { createNoti } from "../../../../reduxToolkit/features/adminPanel/misc";
import { PF } from "../../../../config";

export default function MedicineImageUpload({
  images,
  setImages,
  translate1,
  genericBrand,
}) {
  const [changeImageBuffer, setChangeImageBuffer] = useState("");
  const [altTag, setAltTag] = useState("");
  const [infoHelper, setInfoHelper] = useState(false);
  //   Dialog
  const [editImageDlg, setEditImageDlg] = useState(false);

  const [editBuffer, setEditBuffer] = useState("");

  const dispatch = useDispatch();
  const handleImagesUpload = (e) => {
    setImages([...images]);
    let arr = [];
    let uploads = [...e.target.files];
    uploads.forEach((x, i) => {
      let check = checkIfValidImage(x.name);
      if (check) {
        const obj = {
          sl: i,
          file: x,
          alt: "",
          default: false,
        };
        if (i === 0) obj.default = true;
        arr.push(obj);
      } else {
        dispatch(
          createNoti({
            message: "Non-supported files are excluded",
            severity: "error",
          })
        );
      }
    });
    setImages(arr);
  };

  const handleRemoveSingle = (sl) => {
    let arr = [...images];
    const index = arr.indexOf(arr.find((i) => i.sl === sl));
    arr.splice(index, 1);
    setImages([]);
    arr.sort((a, b) => a.sl - b.sl);
    if (arr.length > 0) {
      let obj = { ...arr[0] };
      obj.default = true;
      arr[0] = obj;
    }
    setImages([...arr]);
  };

  const handleImageChange = (e) => {
    let arr = [...images];
    const index = arr.indexOf(arr.find((i) => i.sl === changeImageBuffer));
    arr[index].file = e.target.files[0];
    setImages([]);
    arr.sort((a, b) => a.sl - b.sl);
    setImages([...arr]);
  };

  const handleEditImageAlt = (sl) => {
    setEditImageDlg(true);
    setEditBuffer(sl);
    const imgInfo = images.find((i) => i.sl === sl);
    if (genericBrand && genericBrand === imgInfo.alt) setAltTag("");
    else setAltTag(imgInfo.alt);
  };

  const handleMakeDefault = (sl) => {
    let arr = [...images];
    // arr.forEach((x, i) => {
    //   x.default = false;
    // });
    let arr2 = [];
    arr.forEach((element) => {
      let obj = { ...element };
      obj.default = false;
      arr2.push(obj);
    });
    arr = arr2;
    const index = arr.indexOf(arr.find((i) => i.sl === sl));
    arr[index].default = true;
    setImages([]);
    arr.sort((a, b) => a.sl - b.sl);
    setImages([...arr]);
  };

  const handleCloseEdit = () => {
    setEditImageDlg(false);
    setEditBuffer("");
    setAltTag("");
  };

  const handleChangeAltTag = () => {
    let arr = [...images];
    const index = arr.indexOf(arr.find((i) => i.sl === editBuffer));
    let obj = {
      ...arr[index],
    };
    obj.alt = altTag;
    arr[index] = obj;
    setImages([]);
    arr.sort((a, b) => a.sl - b.sl);
    setImages([...arr]);
    setEditImageDlg(false);
    setEditBuffer("");
    setAltTag("");
  };

  const handleAdditionalImage = (e) => {
    let arr = [...images];
    let check = checkIfValidImage(e.target.files[0].name);
    if (check) {
      const obj = {
        sl: arr.length,
        file: e.target.files[0],
        alt: "",
        default: false,
      };
      arr.push(obj);
    } else {
      dispatch(
        createNoti({
          message: "Non-supported files are excluded",
          severity: "error",
        })
      );
    }
    setImages([]);
    arr.sort((a, b) => a.sl - b.sl);
    setImages([...arr]);
  };

  return (
    <Box>
      <Typography variant="h6" color="success" align="center">
        {translate1("Image")}{" "}
        <Tooltip
          title={
            translate1("supports") +
            " .jpg, .png, .svg, .jpeg, .gif, .bmp, .tiff, .tif, .webp"
          }
          placement="left"
          arrow
        >
          <IconButton size="small">
            <InfoIcon color="info" />
          </IconButton>
        </Tooltip>
      </Typography>
      <Divider />
      <Grid
        sx={{ mt: 1 }}
        container
        spacing={1}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Grid container spacing={1} justifyContent="center">
            {images.length > 0 &&
              [...images].map((x, i) => (
                <Grid item key={i}>
                  <Grid
                    container
                    spacing={0}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid
                      item
                      sx={{
                        border: x.default
                          ? ".3rem solid chocolate"
                          : ".2rem solid lightblue",
                        borderRadius: ".25rem",
                        p: 0.5,
                      }}
                    >
                      <LazyLoadImage
                        effect="blur"
                        src={
                          x.src
                            ? PF + "adminPanel/medicines/img/" + x.src
                            : x.file &&
                              x.file !== "" &&
                              URL.createObjectURL(x.file)
                        }
                        alt="Upload Product"
                        style={{
                          objectFit: "contain",
                          width: "200px",
                          height: "160px",
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        spacing={0}
                        justifyContent="center"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <Grid item>
                          <Tooltip
                            title={translate1("Make Default")}
                            arrow
                            placement="left"
                          >
                            <IconButton
                              size="small"
                              onClick={() => handleMakeDefault(x.sl)}
                              sx={{ pointerEvents: x.default && "none" }}
                            >
                              <CheckCircleIcon
                                color={x.default ? "success" : "inherit"}
                              />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Grid item>
                          <Tooltip
                            title={translate1("Edit Alt tag")}
                            arrow
                            placement="left"
                          >
                            <IconButton
                              size="small"
                              onClick={() => handleEditImageAlt(x.sl)}
                            >
                              <ModeEditIcon color="info" />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Grid item>
                          <label htmlFor="change-image-file">
                            <Input
                              accept="image/*"
                              id="change-image-file"
                              required
                              type="file"
                              sx={{ display: "none" }}
                              onChange={handleImageChange}
                            />
                            <Tooltip
                              title={translate1("Change")}
                              arrow
                              placement="left"
                            >
                              <IconButton
                                size="small"
                                component="span"
                                onClick={() => setChangeImageBuffer(x.sl)}
                              >
                                <CameraswitchIcon color="warning" />
                              </IconButton>
                            </Tooltip>
                          </label>
                        </Grid>
                        <Grid item>
                          <Tooltip
                            title={translate1("Delete")}
                            arrow
                            placement="left"
                          >
                            <IconButton
                              size="small"
                              onClick={() => handleRemoveSingle(x.sl)}
                            >
                              <ClearIcon color="error" />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {typeof x.file !== "undefined" && (
                    <Button
                      variant="text"
                      size="small"
                      color="info"
                      fullWidth
                      sx={{ pointerEvents: "none" }}
                    >
                      {x.file.size > 1000000
                        ? (x.file.size / 1000000).toFixed(2) + " mb"
                        : (x.file.size / 1000).toFixed(2) + " kb"}
                    </Button>
                  )}
                </Grid>
              ))}
          </Grid>
        </Grid>
        {images.length > 0 && (
          <Grid item>
            <Grid container spacing={0}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="error"
                  fullWidth
                  size="small"
                  onClick={() => setImages([])}
                >
                  {translate1("Remove All")}
                </Button>
              </Grid>
              <Grid item>
                <label htmlFor="additional-image-file">
                  <Input
                    accept="image/*"
                    id="additional-image-file"
                    required
                    type="file"
                    sx={{ display: "none" }}
                    onChange={handleAdditionalImage}
                  />
                  <Tooltip
                    title={translate1("Add Image")}
                    arrow
                    placement="bottom"
                  >
                    <IconButton size="small" component="span">
                      <AddCircleIcon color="success" />
                    </IconButton>
                  </Tooltip>
                </label>
              </Grid>
            </Grid>
          </Grid>
        )}

        {images.length < 1 && (
          <Box sx={{ position: "relative" }}>
            <Grid item>
              <LazyLoadImage
                effect="blur"
                src="https://www.daveraine.com/img/products/no-image.jpg"
                alt="Upload Product"
                style={{
                  objectFit: "contain",
                  width: "200px",
                  height: "160px",
                }}
              />
            </Grid>
            <Grid item sx={{ position: "absolute", top: "40%", left: "40%" }}>
              <label htmlFor="icon-button-file">
                <Input
                  accept="image/*"
                  id="icon-button-file"
                  required
                  type="file"
                  inputProps={{ multiple: true }}
                  sx={{ display: "none" }}
                  onChange={handleImagesUpload}
                />
                <IconButton
                  color="primary"
                  aria-label="upload-picture"
                  component="span"
                >
                  <PhotoCamera />
                </IconButton>
              </label>
            </Grid>
          </Box>
        )}
      </Grid>
      {/* Edit Alt Tag */}
      <Dialog
        open={editImageDlg}
        onClose={handleCloseEdit}
        aria-labelledby={"edit-alt-tag"}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle>Edit Alt Tag</DialogTitle>
        <DialogContent>
          <Grid container spacing={0} alignItems="center">
            <Grid item xs={11}>
              <TextField
                label="Alt Tag"
                value={altTag}
                onChange={(e) => setAltTag(e.target.value)}
                size="small"
                variant="standard"
                fullWidth
                helperText={
                  <>
                    {infoHelper && (
                      <Typography component="span" variant="body2" color="info">
                        Keeping it empty will set default alt tag to image
                        dynamically
                      </Typography>
                    )}
                  </>
                }
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton
                size="small"
                onClick={() => setInfoHelper(!infoHelper)}
                sx={{ mt: 1.5 }}
              >
                <InfoIcon color="info" />
              </IconButton>
            </Grid>
          </Grid>
          <Button
            variant="contained"
            size="small"
            color="secondary"
            fullWidth
            onClick={handleChangeAltTag}
            // disabled={altTag === "" ? true : false}
          >
            Save
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEdit} color="error">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
