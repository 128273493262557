import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Grid,
  Tooltip,
  IconButton,
} from "@mui/material";
import LoadingBox from "../../../components/misc/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import { editOrder } from "../../../reduxToolkit/features/adminPanel/order";

// Icons
import RefreshIcon from "@mui/icons-material/Refresh";
import { getAllOrders } from "../../../reduxToolkit/features/frontend/checkout";
import { getNotification } from "../../../reduxToolkit/features/adminPanel/notification";

export default function EditOrder({
  editDlgOpen,
  setEditDlgOpen,
  x,
  getDeliverMethod,
  getPaymentMethod,
  lang,
}) {
  const dispatch = useDispatch();
  //   Local Data
  const localAdminData = () => {
    let obj = {};
    const admin = JSON.parse(localStorage.getItem("adminLoginInfo"));
    if (admin) obj = admin;
    return obj;
  };
  //   Store
  const { deliveryMethods } = useSelector((state) => state.delivery);
  const { paymentMethods } = useSelector((state) => state.payment);
  const { langs } = useSelector((state) => state.misc);

  const [loading, setLoading] = useState(false);
  const [loadingDelivery, setLoadingDelivery] = useState(false);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [editBuffer, setEditBuffer] = useState({});
  const [note, setNote] = useState("");

  const [deliveryMethodByLang, setDeliveryMethodByLang] = useState([]);
  const [paymentMethodByLang, setPaymentMethodByLang] = useState([]);

  const [updateValid, setUpdateValid] = useState(false);

  const [changesMadeObj, setChangesMadeObj] = useState({
    deliveryMethod: false,
    paymentMethod: false,
    shippingCost: false,
  });

  // Functions
  const handleShippingCostEdit = (e) => {
    let obj = { ...editBuffer };
    obj.shippingCost = e.target.value.replace(/\D/g, "");
    setEditBuffer(obj);

    // Update Changes
    let changesObj = { ...changesMadeObj };
    changesObj.shippingCost = true;
    setChangesMadeObj(changesObj);
  };

  const handleDeliveryMethodChange = (e) => {
    setLoadingDelivery(true);
    let obj = { ...editBuffer };
    obj.deliveryMethodId = e.target.value;
    setEditBuffer(obj);

    // Update Changes
    let changesObj = { ...changesMadeObj };
    changesObj.deliveryMethod = true;
    setChangesMadeObj(changesObj);
    setTimeout(() => {
      setLoadingDelivery(false);
    }, 1000);
  };

  const handlePaymentMethodChange = (e) => {
    setLoadingPayment(true);
    let obj = { ...editBuffer };
    obj.paymentMethodId = e.target.value;
    setEditBuffer(obj);

    // Update Changes
    let changesObj = { ...changesMadeObj };
    changesObj.paymentMethod = true;
    setChangesMadeObj(changesObj);

    setTimeout(() => {
      setLoadingPayment(false);
    }, 1000);
  };

  const getLangId = (langCode) => {
    let langId = "62a0848ca2ad3bd3035130f2";
    if (langs) {
      const L = langs.find((l) => l.langCode === langCode);
      if (L) langId = L._id;
    }
    return langId;
  };

  const handleUpdateOrder = () => {
    setLoadingUpdate(true);
    let obj = {
      editBuffer,
      changesMadeObj,
      note,
    };
    obj.creatorId = localAdminData()._id;
    dispatch(editOrder(obj));
    setTimeout(() => {
      setLoadingUpdate(false);
      setEditDlgOpen(false);
      dispatch(getAllOrders());
      dispatch(getNotification());
    }, 1000);
  };

  const handleResetShipping = () => {
    let obj = { ...editBuffer };
    obj.shippingCost = x.shippingCost;
    setEditBuffer(obj);
    let changesObj = { ...changesMadeObj };
    changesObj.shippingCost = false;
    setChangesMadeObj(changesObj);
  };

  const handleResetPayment = () => {
    let obj = { ...editBuffer };
    obj.paymentMethodId = x.paymentMethodId;
    setEditBuffer(obj);
    let changesObj = { ...changesMadeObj };
    changesObj.paymentMethod = false;
    setChangesMadeObj(changesObj);
  };
  const handleResetDelivery = () => {
    let obj = { ...editBuffer };
    obj.deliveryMethodId = x.deliveryMethodId;
    setEditBuffer(obj);
    let changesObj = { ...changesMadeObj };
    changesObj.deliveryMethod = false;
    setChangesMadeObj(changesObj);
  };

  //   Use Effects
  useEffect(() => {
    setLoading(true);
    if (x && x._id) {
      setEditBuffer(x);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [x]);

  useEffect(() => {
    if (deliveryMethods && deliveryMethods.length > 0) {
      const D = deliveryMethods.filter((d) => d.langId === getLangId(lang));
      if (D) setDeliveryMethodByLang(D);
      else setDeliveryMethodByLang([]);
    }
  }, [deliveryMethods, lang]);

  useEffect(() => {
    if (paymentMethods && paymentMethods.length > 0) {
      const P = paymentMethods.filter((p) => p.langId === getLangId(lang));
      if (P) setPaymentMethodByLang(P);
      else setPaymentMethodByLang([]);
    }
  }, [paymentMethods, lang]);

  //   Update Valid
  useEffect(() => {
    let valid = false;
    if (changesMadeObj.deliveryMethod) valid = true;
    if (changesMadeObj.paymentMethod) valid = true;
    if (changesMadeObj.shippingCost) valid = true;
    setUpdateValid(valid);
  }, [
    changesMadeObj.deliveryMethod,
    changesMadeObj.paymentMethod,
    changesMadeObj.shippingCost,
  ]);

  //! Translate
  // Store
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate
  return (
    <Dialog open={editDlgOpen} fullWidth maxWidth={"sm"}>
      <DialogTitle>{translate0("Edit Order")}</DialogTitle>
      <DialogContent>
        {loading ? (
          <LoadingBox w={"100%"} />
        ) : (
          <>
            {loadingDelivery ? (
              <LoadingBox w={"100%"} />
            ) : (
              <Grid container spacing={0} alignItems="center" sx={{ mt: 1 }}>
                <Grid item flex={1}>
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel id="delivery-method-label">
                      {translate0("Delivery Method")}
                    </InputLabel>
                    <Select
                      labelId="delivery-method-label"
                      id="delivery-method-select"
                      value={
                        changesMadeObj.deliveryMethod
                          ? editBuffer.deliveryMethodId
                          : getDeliverMethod(editBuffer.deliveryMethodId)._id
                      }
                      label={translate0("Delivery Method")}
                      onChange={handleDeliveryMethodChange}
                    >
                      {deliveryMethodByLang &&
                        deliveryMethodByLang.map((x, i) => (
                          <MenuItem value={x._id} key={i}>
                            {x.methodName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  {changesMadeObj.deliveryMethod && (
                    <Tooltip
                      arrow
                      placement="left"
                      title={translate0("Reset To Default")}
                    >
                      <IconButton
                        size="small"
                        onClick={handleResetDelivery}
                        sx={{ mt: 2 }}
                      >
                        <RefreshIcon color="warning" />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            )}

            {loadingPayment ? (
              <LoadingBox w={"100%"} />
            ) : (
              <Grid container spacing={0} alignItems="center" sx={{ mt: 1 }}>
                <Grid item flex={1}>
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel id="payment-method-label">
                      {translate0("Payment Method")}
                    </InputLabel>
                    <Select
                      labelId="payment-method-label"
                      id="payment-method-select"
                      value={
                        changesMadeObj.paymentMethod
                          ? editBuffer.paymentMethodId
                          : getPaymentMethod(editBuffer.paymentMethodId)._id
                      }
                      label={translate0("Payment Method")}
                      onChange={(e) => handlePaymentMethodChange(e)}
                    >
                      {paymentMethodByLang &&
                        paymentMethodByLang.map((x, i) => (
                          <MenuItem value={x._id} key={i}>
                            {x.methodName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  {changesMadeObj.paymentMethod && (
                    <Tooltip
                      arrow
                      placement="left"
                      title={translate0("Reset To Default")}
                    >
                      <IconButton
                        size="small"
                        onClick={handleResetPayment}
                        sx={{ mt: 2 }}
                      >
                        <RefreshIcon color="warning" />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            )}
            <Grid container spacing={0} alignItems="center" sx={{ mt: 1 }}>
              <Grid item flex={1}>
                <TextField
                  label={translate0("Shipping Cost")}
                  required
                  fullWidth
                  variant="standard"
                  size="small"
                  value={editBuffer.shippingCost}
                  onChange={(e) => handleShippingCostEdit(e)}
                />
              </Grid>
              <Grid item>
                {changesMadeObj.shippingCost && (
                  <Tooltip
                    arrow
                    placement="left"
                    title={translate0("Reset To Default")}
                  >
                    <IconButton size="small" onClick={handleResetShipping}>
                      <RefreshIcon color="warning" />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
            <TextField
              disabled={!updateValid}
              sx={{ mt: 1 }}
              label={translate0("Note")}
              fullWidth
              multiline
              minRows={3}
              variant="standard"
              size="small"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setEditDlgOpen(false)} color="primary">
          {translate0("Cancel")}
        </Button>
        <LoadingButton
          loading={loadingUpdate}
          onClick={handleUpdateOrder}
          color="warning"
          disabled={!updateValid}
        >
          {translate0("Update")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
