import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  DialogTitle,
  DialogContentText,
} from "@mui/material";
import { ChromePicker } from "react-color";

// Icons
import InvertColorsIcon from "@mui/icons-material/InvertColors";
import InfoTut from "../../../components/InfoTut";
import { useDispatch, useSelector } from "react-redux";
import {
  getSettings,
  saveSiteSettings,
} from "../../../reduxToolkit/features/adminPanel/webSettings";
import { getNotification } from "../../../reduxToolkit/features/adminPanel/notification";

export default function SiteDesignSettings() {
  const dispatch = useDispatch();
  // Color Picker
  const [color, setColor] = useState("lightblue");
  const [showColorPicker, setShowColorPicker] = useState(false);

  // LocalData
  const localData = JSON.parse(localStorage.getItem("adminLoginInfo"));
  // Store
  const { webSettings } = useSelector((state) => state.webSettings);

  const handleSaveSiteSettings = () => {
    dispatch(
      saveSiteSettings({
        siteColorTheme: color.hex || color,
        creatorId: localData._id,
      })
    );
    setTimeout(() => {
      dispatch(getNotification());
      dispatch(getSettings());
    }, 1000);
  };

  useEffect(() => {
    setColor(webSettings.siteColorTheme || "lightblue");
  }, [webSettings]);

  // Store
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate

  return (
    <>
      {/* Site Theme */}

      <Typography
        variant="body2"
        color="initial"
        sx={{ fontSize: "1rem", display: "flex", alignItems: "center" }}
      >
        <InfoTut img="tut-theme-color.png" />
        {translate0("Set Color Theme")}:{" "}
        <IconButton size="small" onClick={() => setShowColorPicker(true)}>
          <InvertColorsIcon sx={{ color: color.hex || color }} />
        </IconButton>
      </Typography>
      <Button
        variant="contained"
        fullWidth
        size="small"
        color="success"
        onClick={handleSaveSiteSettings}
      >
        {translate0("Save")}
      </Button>
      <Dialog open={showColorPicker} onClose={() => setShowColorPicker(false)}>
        <DialogContent>
          <ChromePicker
            color={color}
            disableAlpha
            onChange={(updatedColor) => setColor(updatedColor)}
          />
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button onClick={() => setShowColorPicker(false)} autoFocus>
            {translate0("Close")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
