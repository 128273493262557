import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {
  AppBar,
  Grid,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

// Icons
import CloseIcon from "@mui/icons-material/Close";
import CallMissedOutgoingIcon from "@mui/icons-material/CallMissedOutgoing";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import LoadingBox from "../../../../../components/misc/Loading";
import { format } from "date-fns";
import { axiosInstance, PF } from "../../../../../config";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompanies,
  getDosages,
  getMedCategories,
  getMedicines,
  leafletPdfDownload,
  resetPdfResponse,
} from "../../../../../reduxToolkit/features/adminPanel/medicine";
import { Route, Routes, useNavigate } from "react-router-dom";
import { setLeafletObj } from "../../../../../reduxToolkit/features/adminPanel/misc";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PreviewBrochureDlg({
  open,
  setOpen,
  selectedForPreview,
  settings,
  siteLang,
}) {
  //! Translate
  // Store
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);

  const [htmlToView, setHtmlToView] = useState(``);

  //   Store
  const {
    medicines,
    categories,
    companies,
    medVariants,
    dosages,
    leafletPdfDownloadResponse,
  } = useSelector((state) => state.medicine);

  useEffect(() => {
    if (!(medicines && medicines.length > 0)) dispatch(getMedicines());
    if (!(categories && categories.length > 0)) dispatch(getMedCategories());
    if (!(companies && companies.length > 0)) dispatch(getCompanies());
    if (!(dosages && dosages.length > 0)) dispatch(getDosages());
  }, []);

  const handleDownload = () => {
    setLoadingDownload(true);
    const fileName = new Date().getTime();
    dispatch(
      leafletPdfDownload({ htmlTemplate: htmlToView, fileName: fileName })
    );
  };

  const navigate = useNavigate();
  const handleOpenInNewPage = () => {
    setLoadingOpen(true);
    dispatch(setLeafletObj({ selectedForPreview, settings, siteLang }));
    setTimeout(() => {
      navigate("/medicines/brochure");
      setLoadingOpen(false);
      // window.open("http://localhost:3000/medicines/leaflet", "_blank");
    }, 6000);
  };

  useEffect(() => {
    if (leafletPdfDownloadResponse && leafletPdfDownloadResponse.filename) {
      window.open(
        PF + "files/PDFs/" + leafletPdfDownloadResponse.filename,
        "_blank"
      );
      setTimeout(() => {
        setLoadingDownload(false);
        dispatch(resetPdfResponse());
      }, 1000);
    }
  }, [leafletPdfDownloadResponse]);

  const getImage = (imageArr) => {
    let imageUrl = ``;
    if (imageArr && imageArr.length > 0 && imageArr[0].src) {
      if (imageArr[0].src !== "")
        imageUrl = PF + "adminPanel/medicines/img/" + imageArr[0].src;
      else imageUrl = PF + "adminPanel/medicines/no-medicine-image-mfw.png";
    } else imageUrl = PF + "adminPanel/medicines/no-medicine-image-mfw.png";
    return imageUrl;
  };

  // Get Category
  const [catList, setCatList] = useState([]);
  useEffect(() => {
    if (categories && categories.length > 0) {
      const C = categories.find((c) => c.lang === siteLang);
      if (C && C.data) {
        setCatList(C.data);
      }
    }
  }, [categories, siteLang]);

  const getCatString = (subCatArr) => {
    let cat = "";
    let catArr = [];
    subCatArr?.forEach((x) => {
      catList.forEach((y) => {
        let Sc = y.subCatList.find(
          (sc) =>
            (siteLang && siteLang !== "en"
              ? sc.subCatDetectorId
              : sc.fixedSubCatId) === x.subCatId
        );
        if (Sc) catArr.push(y.catName);
      });
    });
    let uniqueCatArr = [...new Set(catArr)];
    cat = uniqueCatArr.join(", ");
    return cat;
  };

  // Get Sub Category
  const getSubCatString = (subCatArr) => {
    let subCat = "";
    let scArr = [];
    subCatArr?.forEach((x) => {
      catList.forEach((y) => {
        let Sc = y.subCatList.find(
          (sc) =>
            (siteLang && siteLang !== "en"
              ? sc.subCatDetectorId
              : sc.fixedSubCatId) === x.subCatId
        );
        if (Sc) scArr.push(Sc.subCatName);
      });
    });
    let uniqueSubCatArr = [...new Set(scArr)];
    subCat = uniqueSubCatArr.join(", ");
    return subCat;
  };

  // Get Company
  const [companyList, setCompanyList] = useState([]);
  useEffect(() => {
    if (companies && companies.length > 0) {
      const C = companies.find((c) => c.lang === siteLang);
      if (C && C.data) {
        setCompanyList(C.data);
      }
    }
  }, [companies, siteLang]);

  const getCompanyString = (companyId) => {
    let companyString = "";
    let C = companyList.find(
      (c) =>
        (siteLang && siteLang === "en"
          ? c.fixedCompanyId
          : c.companyDetectorId) === companyId
    );
    if (C) {
      companyString = C.companyName;
    }
    return companyString;
  };

  // Get Variant Info
  const [dosageList, setDosageList] = useState([]);
  useEffect(() => {
    if (siteLang !== "" && dosages && dosages.length > 0) {
      const D = dosages.find((d) => d.lang === siteLang);
      if (D && D.data) setDosageList(D.data);
      else setDosageList([]);
    }
  }, [dosages, siteLang]);

  const getVariantInfo = (id) => {
    const MV = medVariants.find((mv) => mv.fixedVariantId === id);
    return MV;
  };

  const getDosageInfo = (dosageId) => {
    let dosageName = "";
    const D = dosageList.find(
      (d) =>
        (siteLang !== "en" ? d.dosageDetectorId : d.fixedDosageId) === dosageId
    );
    if (D && D.dosageName) dosageName = D.dosageName;

    return dosageName;
  };

  const getVariantStringObj = (variantArr) => {
    let obj = {
      dosageString: "",
      strengthString: "",
      packTypesString: "",
      priceString: "",
    };
    let dosageArr = [];
    let strengthArr = [];
    let packTypesArr = [];
    let priceArr = [];
    variantArr.forEach((z) => {
      dosageArr.push(getDosageInfo(getVariantInfo(z.variantId)?.dosageForm));
      strengthArr.push(getVariantInfo(z.variantId)?.strength);
      packTypesArr.push(getVariantInfo(z.variantId)?.packTypes);
      priceArr.push("$" + getVariantInfo(z.variantId)?.sellingPrice);
    });

    let uniqueDosageArr = [...new Set(dosageArr)];
    let uniqueStrengthArr = [...new Set(strengthArr)];
    let uniquePackTypesArr = [...new Set(packTypesArr)];
    let uniquePriceArr = [...new Set(priceArr)];
    obj.dosageString = uniqueDosageArr.join(", ");
    obj.strengthString = uniqueStrengthArr.join(", ");
    obj.packTypesString = uniquePackTypesArr.join(", ");
    if (uniquePriceArr.length > 1) {
      obj.priceString = `${uniquePriceArr[0]} - ${
        uniquePriceArr[uniquePriceArr.length - 1]
      }`;
    } else {
      obj.priceString = uniquePriceArr.join(", ");
    }
    return obj;
  };

  useEffect(() => {
    setLoading(true);
    let newHtml = html;
    newHtml = newHtml.replace("{__header_text}", settings.headerText);
    newHtml = newHtml.replace(
      "{__date_string}",
      `${translate0("Date")}: ${format(settings.date, "P")}`
    );
    newHtml = newHtml.replace("{__footer_text}", settings.footerText);
    let medListHtml = ``;
    medListHtml =
      medListHtml +
      `
    <thead>
      <tr>
        <th>Sl.</th>
        ${settings.fieldsToView.image ? `<th>Image</th>` : ``}
        ${
          settings.fieldsToView.generic
            ? `<th>${translate0("Generic")}</th>`
            : ``
        }
        ${
          settings.fieldsToView.genericBrand
            ? `<th>${translate0("Generic Brand")}</th>`
            : ``
        }
        ${
          settings.fieldsToView.company
            ? `<th>${translate0("Mfg Company")}</th>`
            : ``
        }
        ${
          settings.fieldsToView.originator
            ? `<th>${translate0("Originator")}</th>`
            : ``
        }
        ${
          settings.fieldsToView.category
            ? `<th>${translate0("Category")}</th>`
            : ``
        }
        ${
          settings.fieldsToView.subCategory
            ? `<th>${translate0("Sub Category")}</th>`
            : ``
        }
        ${
          settings.fieldsToView.dosage
            ? `<th>${translate0("Dosage Form")}</th>`
            : ``
        }
        ${
          settings.fieldsToView.strength
            ? `<th>${translate0("Strength")}</th>`
            : ``
        }
        ${
          settings.fieldsToView.packSize
            ? `<th>${translate0("Pack Types")}</th>`
            : ``
        }
        ${settings.fieldsToView.price ? `<th>${translate0("Price")}</th>` : ``}
        ${
          settings.fieldsToView.availability
            ? `<th>${translate0("Availability")}</th>`
            : ``
        }
      </tr>
    </thead>
    <tbody>
      `;
    if (selectedForPreview && selectedForPreview.length > 0) {
      selectedForPreview.forEach((x, i) => {
        medListHtml =
          medListHtml +
          `
          <tr>
              <td>${i + 1}</td>
              ${
                settings.fieldsToView.image
                  ? `<td>
                      <img            
                          src=${getImage(x.image)}
                          alt=${x.genericBrand}
                          class="med-image"
                      />
                    </td>`
                  : ""
              }
              ${settings.fieldsToView.generic ? `<td> ${x.generic}</td>` : ""}
              ${
                settings.fieldsToView.genericBrand
                  ? `<td> ${x.genericBrand}</td>`
                  : ""
              }
              ${
                settings.fieldsToView.company
                  ? `<td> ${getCompanyString(x.companyId)}</td>`
                  : ""
              }
              ${
                settings.fieldsToView.originator
                  ? `<td> ${x.originator}</td>`
                  : ""
              } 
              ${
                settings.fieldsToView.category
                  ? `<td> ${getCatString(x.subCats)}</td>`
                  : ""
              }
              ${
                settings.fieldsToView.subCategory
                  ? `<td> ${getSubCatString(x.subCats)}</td>`
                  : ""
              }
              ${
                settings.fieldsToView.dosage
                  ? `<td> ${getVariantStringObj(x.variants).dosageString}</td>`
                  : ""
              }
              ${
                settings.fieldsToView.strength
                  ? `<td> ${
                      getVariantStringObj(x.variants).strengthString
                    }</td>`
                  : ""
              }
              ${
                settings.fieldsToView.packSize
                  ? `<td> ${
                      getVariantStringObj(x.variants).packTypesString
                    }</td>`
                  : ""
              }
              ${
                settings.fieldsToView.price
                  ? `<td> ${getVariantStringObj(x.variants).priceString}</td>`
                  : ""
              }
              ${
                settings.fieldsToView.availability
                  ? `<td> ${
                      x.availability === 0
                        ? translate0("Available")
                        : x.availability === 1
                        ? translate0("Available Upon Inquiry")
                        : translate0("Not Available")
                    }</td>`
                  : ""
              }  
        </tr>
       
        `;

        medListHtml = medListHtml + `</tbody>`;

        // if ((i + 1) % 5 === 0) {
        //   if (i < 5)
        //     medListHtml = medListHtml + `<div style="margin-top: 70px"/>`;
        //   else medListHtml = medListHtml + `<div style="margin-top: 150px"/>`;
        // }
      });
    }
    newHtml = newHtml.replace("{__insert_meds_here} ", medListHtml);
    setHtmlToView(newHtml);
    setTimeout(() => setLoading(false), 3000);
  }, [
    selectedForPreview,
    settings.date,
    settings.fieldsToView,
    settings.footerText,
    settings.headerText,
    siteLang,
  ]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby={"preview-leaflet-dialog"}
        fullWidth
        maxWidth={"lg"}
      >
        <AppBar sx={{ position: "relative" }} color="info">
          <Toolbar>
            <Grid
              container
              spacing={0}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => setOpen(false)}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                {translate0("Preview")}
              </Grid>

              <Grid item xs={10} sm={6} xl={4} sx={{ alignSelf: "flex-end" }}>
                <Grid container justifyContent="flex-end" spacing={2}>
                  <Grid item>
                    <LoadingButton
                      loading={loadingOpen}
                      loadingPosition="end"
                      endIcon={<CallMissedOutgoingIcon />}
                      color="warning"
                      variant="contained"
                      onClick={handleOpenInNewPage}
                      fullWidth
                      size="small"
                    >
                      Open
                    </LoadingButton>
                  </Grid>
                  <Grid item>
                    <LoadingButton
                      loading={loadingDownload}
                      loadingPosition="end"
                      endIcon={<DownloadForOfflineIcon />}
                      color="error"
                      variant="contained"
                      onClick={handleDownload}
                      fullWidth
                      size="small"
                    >
                      {translate0("Download")}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <DialogContent>
          {loading ? (
            <LoadingBox w={"100%"} />
          ) : (
            <>
              <div dangerouslySetInnerHTML={{ __html: htmlToView }} />
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

const html = `
<style>
.topbar {
  // display: -webkit-box;
  // display: flex;
  // -webkit-box-pack: space-between;
  // justify-content: space-between;
  width: 100%;
  margin: auto;
}

.top-divider,
footer {
  width: 100%;
  margin: 10px auto;
}

.med-image {
  padding: 2px;
  height: 40px;
  width: 40px;
  object-fit: contain;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  border: 1px solid black;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-size: 9px;
}


tbody>tr {
  height: 80px;
}
</style>

<div class="topbar">
  <p>{__header_text}</p>
  <p>{__date_string}</p>
</div>
<hr class="top-divider" />
<table>
{__insert_meds_here}            
</table>
<footer>
  {__footer_text}                
</footer>
`;
