import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

import Typography from "@mui/material/Typography";
import {
  FormControl,
  InputLabel,
  NativeSelect,
  Grid,
  Divider,
  Autocomplete,
  Box,
  TextField,
  createFilterOptions,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

// Currency Symbol
import getSymbolFromCurrency from "currency-symbol-map";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrency,
  updateCurrency,
} from "../../../../reduxToolkit/features/adminPanel/misc";

// Icon
import UpdateIcon from "@mui/icons-material/Update";
import LoadingButton from "@mui/lab/LoadingButton";
import { getDosages } from "../../../../reduxToolkit/features/adminPanel/medicine";

// Limit options to show in autocomplete
const OPTIONS_LIMIT = 8;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) => {
  return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
};

export default function PriceInfoDialog({
  priceInfoDlg,
  setPriceInfoDlg,
  data,
  lang,
}) {
  const [loading, setLoading] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState();
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [activePriceInfoTab, setActivePriceInfoTab] = useState();

  // Store
  const { currencyList } = useSelector((state) => state.misc);
  const { dosages } = useSelector((state) => state.medicine);

  useEffect(() => {
    dispatch(getCurrency());
  }, []);

  useEffect(() => {
    setActivePriceInfoTab(data[0]);
  }, [data]);

  useEffect(() => {
    if (currencyList.length > 0) {
      const C = currencyList.find((x) => x.currencyName === "USD");
      setSelectedCurrency(C.exchangeRate);
      setCurrencySymbol(C.currencyName);
    }
  }, [currencyList]);

  const dispatch = useDispatch();
  const handleUpdateCurrency = () => {
    setLoading(true);
    dispatch(updateCurrency());
    dispatch(getCurrency());
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const handleCurrencyChange = (e, newValue) => {
    setSelectedCurrency(newValue.exchangeRate);
    setCurrencySymbol(newValue.currencyName);
  };

  const handleActivePriceInfo = (data) => {
    setActivePriceInfoTab(data);
  };

  const getDosageInfo = (dosageId, langCode) => {
    let dosageName = "";
    if (dosages && dosages.length > 0) {
      const dosageList = dosages.find((d) => d.lang === langCode);
      if (dosageList && dosageList.data) {
        const D = dosageList.data.find(
          (d) =>
            (langCode !== "en" ? d.dosageDetectorId : d.fixedDosageId) ===
            dosageId
        );
        if (D && D.dosageName) dosageName = D.dosageName;
      }
    }
    return dosageName;
  };

  // ? useEffect
  useEffect(() => {
    if (!dosages || dosages.length < 1) dispatch(getDosages());
  }, []);

  // ! translate
  // Store
  const { langs } = useSelector((state) => state.misc);
  const [langSettings1, setLangSettings1] = useState([]);
  const [langSettings2, setLangSettings2] = useState([]);
  const title1 = "Price Info";
  const title2 = "Misc Words";

  useEffect(() => {
    if (langs && langs.length > 0 && lang !== "") {
      const L = langs.find((l) => l.langCode === lang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      const A = arr.find((l) => l._title === title1);
      if (A) setLangSettings1(A.data);
      const B = arr.find((l) => l._title === title2);
      if (B) setLangSettings2(B.data);
    }
  }, [lang, langs]);

  const translate1 = (String) => translation(String, title1, 1);
  const translate2 = (String) => translation(String, title2, 2);

  const translation = (String, title, index) => {
    let translation = "";
    let settings = [];
    if (index === 1) {
      settings = langSettings1;
    } else if (index === 2) {
      settings = langSettings2;
    }
    const T = settings.find((ls) => ls.title === String);
    if (T && T.value) translation = T.value;
    else {
      let arr = [];
      if (langs && langs.length > 0 && lang !== "") {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.settings && LEn.settings.length > 0) arr = LEn.settings;
        const S = arr.find((l) => l._title === title);
        if (S) {
          const T = S.data.find((ls) => ls.title === String);
          if (T && T.value) translation = T.value;
        }
      }
    }
    return translation;
  };
  // ! translate

  //! Translate
  // Store
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate

  return (
    <Dialog
      open={priceInfoDlg}
      onClose={() => setPriceInfoDlg}
      aria-labelledby={"price-info-dlg"}
    >
      <DialogTitle>{translate1("Price Info")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} sm={6}>
              {currencyList.length > 0 && (
                <Autocomplete
                  size="small"
                  onChange={(e, newValue) => handleCurrencyChange(e, newValue)}
                  disablePortal
                  options={currencyList}
                  defaultValue={currencyList.find(
                    (c) => c.currencyName === "USD"
                  )}
                  filterOptions={filterOptions}
                  getOptionLabel={(option) =>
                    "(" +
                    getSymbolFromCurrency(option.currencyName) +
                    ") " +
                    option.currencyName +
                    " [" +
                    option.exchangeRate +
                    "]"
                  }
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{
                        "& > img": { mr: 2, flexShrink: 0 },
                      }}
                      {...props}
                    >
                      ({getSymbolFromCurrency(option.currencyName)}){" "}
                      {option.currencyName} [{option.exchangeRate}]
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={translate1("Select Currency")}
                      variant="standard"
                      size="small"
                      fullWidth
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <LoadingButton
                onClick={handleUpdateCurrency}
                variant="contained"
                size="small"
                fullWidth
                loading={loading}
                endIcon={<UpdateIcon />}
              >
                {translate1("Update Currency")}
              </LoadingButton>
            </Grid>
          </Grid>
          <Divider sx={{ my: 1 }} />
          <Grid container spacing={1} justifyContent="center">
            {data &&
              activePriceInfoTab &&
              data.map((x, i) => (
                <Grid item key={i} xs={12} sm={6}>
                  <Button
                    size="small"
                    variant={
                      x._id === activePriceInfoTab._id
                        ? "contained"
                        : "outlined"
                    }
                    fullWidth
                    color="error"
                    onClick={() => handleActivePriceInfo(x)}
                  >
                    {`${getDosageInfo(x.dosageForm, lang)}  (${x.strength}) ${
                      x.packTypes
                    }`}
                  </Button>
                </Grid>
              ))}

            {activePriceInfoTab && (
              <>
                <Grid container spacing={0} flexDirection="column">
                  <Grid item>
                    <FormControlLabel
                      // disabled
                      control={
                        <Checkbox
                          checked={activePriceInfoTab.showInFront}
                          color="success"
                        />
                      }
                      label="Show In Front-end"
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="body1" color="initial">
                      {translate1("Strength")}:{" "}
                      <Typography
                        variant="body2"
                        color="initial"
                        component={"span"}
                        sx={{ fontWeight: "900" }}
                      >
                        {activePriceInfoTab.strength}
                      </Typography>
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item>
                    <Typography variant="body1" color="initial">
                      {translate1("Pack Types")}:{" "}
                      <Typography
                        variant="body2"
                        color="initial"
                        component={"span"}
                        sx={{ fontWeight: "900" }}
                      >
                        {activePriceInfoTab.packTypes}
                      </Typography>
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item>
                    <Typography variant="body1" color="initial">
                      {translate1("Average Price of Originator")}:{" "}
                      <Typography
                        variant="body2"
                        color="initial"
                        component={"span"}
                        sx={{ fontWeight: "900" }}
                      >
                        {activePriceInfoTab.avgPriceOfOriginator}
                      </Typography>
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item>
                    <Typography variant="body1" color="initial">
                      {translate1("Selling Price")}:{" "}
                      <Typography
                        variant="body2"
                        color="initial"
                        component={"span"}
                        sx={{ fontWeight: "900" }}
                      >
                        {getSymbolFromCurrency(currencySymbol)}
                        {(
                          activePriceInfoTab.sellingPrice * selectedCurrency
                        ).toFixed(2)}
                      </Typography>
                    </Typography>
                    <Divider />
                  </Grid>

                  <Grid item>
                    <Typography variant="body1" color="initial">
                      {translate0("Discount")}:{" "}
                      <Typography
                        variant="body2"
                        color="initial"
                        component={"span"}
                        sx={{ fontWeight: "900" }}
                      >
                        {getSymbolFromCurrency(currencySymbol)}
                        {(
                          activePriceInfoTab.discount * selectedCurrency
                        ).toFixed(2)}
                      </Typography>
                    </Typography>
                    <Divider />
                  </Grid>

                  <Grid item>
                    <Typography variant="body1" color="initial">
                      {translate1("VIP Selling  Price")}:{" "}
                      <Typography
                        variant="body2"
                        color="initial"
                        component={"span"}
                        sx={{ fontWeight: "900" }}
                      >
                        {getSymbolFromCurrency(currencySymbol)}
                        {(
                          activePriceInfoTab.vipSellingPrice * selectedCurrency
                        ).toFixed(2)}
                      </Typography>
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item>
                    <Typography variant="body1" color="initial">
                      {translate1("Company Patient Selling Price Updated")}:
                      <Typography
                        variant="body2"
                        color="initial"
                        component={"span"}
                        sx={{ fontWeight: "900" }}
                      >
                        {getSymbolFromCurrency(currencySymbol)}
                        {(
                          activePriceInfoTab.companyPatientSellingPriceUpdated *
                          selectedCurrency
                        ).toFixed(2)}
                      </Typography>
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item>
                    <Typography variant="body1" color="initial">
                      {translate1("Company Patient Selling Price Old")}:{" "}
                      <Typography
                        variant="body2"
                        color="initial"
                        component={"span"}
                        sx={{ fontWeight: "900" }}
                      >
                        {getSymbolFromCurrency(currencySymbol)}
                        {(
                          activePriceInfoTab.companyPatientSellingPriceOld *
                          selectedCurrency
                        ).toFixed(2)}
                      </Typography>
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item>
                    <Typography variant="body1" color="initial">
                      {translate1("Company MRP")}:{" "}
                      <Typography
                        variant="body2"
                        color="initial"
                        component={"span"}
                        sx={{ fontWeight: "900" }}
                      >
                        {getSymbolFromCurrency(currencySymbol)}
                        {(
                          activePriceInfoTab.companyMRP * selectedCurrency
                        ).toFixed(2)}
                      </Typography>
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item>
                    <Typography variant="body1" color="initial">
                      {translate1("Minimum Order Qty ")}:{" "}
                      <Typography
                        variant="body2"
                        color="initial"
                        component={"span"}
                        sx={{ fontWeight: "900" }}
                      >
                        {activePriceInfoTab.minimumOrderQty}
                      </Typography>
                    </Typography>
                    <Divider sx={{ mb: 1 }} />
                  </Grid>
                  <Grid item>
                    <Typography variant="body1" color="initial">
                      {translate1("Weight Per Unit")}:{" "}
                      <Typography
                        variant="body2"
                        color="initial"
                        component={"span"}
                        sx={{ fontWeight: "900" }}
                      >
                        {activePriceInfoTab.weight}
                        {translate1("gm")}
                      </Typography>
                    </Typography>
                    <Divider sx={{ mb: 1 }} />
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setPriceInfoDlg(false)} color="error">
          {translate2("Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
