import LoadingButton from "@mui/lab/LoadingButton";
import {
  Autocomplete,
  Button,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createSeoTags,
  getSeoTags,
} from "../../../../reduxToolkit/features/adminPanel/medicine";
import { getNotification } from "../../../../reduxToolkit/features/adminPanel/notification";
// Icon
import CircleIcon from "@mui/icons-material/Circle";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import { Box } from "@mui/system";

// Limit options to show in autocomplete
const OPTIONS_LIMIT = 3;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) => {
  return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
};

export default function AddSeoTags({
  lang,
  setAddTagDlg,
  addTagDlg,
  tagListEn,
}) {
  // Use State
  const [tagName, setTagName] = useState("");
  const [tagDetectorId, setTagDetectorId] = useState("");
  const [tagNameExist, setTagNameExist] = useState(false);
  const [tagDetails, setTagDetails] = useState("");
  const tagDetailsLimit = 400;
  const [tagDetailsCursor, setTagDetailsCursor] = useState();
  const [tagSeverity, setTagSeverity] = useState("success");
  //   dlg
  const [loadingDlg, setLoadingDlg] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);

  // LocalData
  const localAdminData = JSON.parse(localStorage.getItem("adminLoginInfo"));
  //   Store
  const { createSeoTagResponse } = useSelector((state) => state.medicine);

  //   Use effect
  // add tag dlg submit disable
  useEffect(() => {
    if (tagName === "") setDisableSubmit(true);
    else setDisableSubmit(false);
  }, [tagDetails, tagName]);

  // Check add tag success
  useEffect(() => {
    if (createSeoTagResponse && createSeoTagResponse.failed) {
      setLoadingDlg(false);
      setTagNameExist(true);
    } else {
      setLoadingDlg(false);
      setTagNameExist(false);
      setTagName("");
      setTagDetails("");
      setTagSeverity("success");
      setTagDetectorId("");
      setAddTagDlg(false);
    }
  }, [createSeoTagResponse]);

  //   Functions
  const dispatch = useDispatch();
  const getCursorPosition = (e) => {
    setTagDetailsCursor(e.target.selectionStart);
  };

  const handleInsertMedicineName = () => {
    setTagDetails(
      tagDetails.substring(0, tagDetailsCursor) +
        "{_medicine_name}" +
        tagDetails.substring(tagDetailsCursor, tagDetails.length)
    );
  };

  const handleInsertCatName = () => {
    setTagDetails(
      tagDetails.substring(0, tagDetailsCursor) +
        "{_category_name}" +
        tagDetails.substring(tagDetailsCursor, tagDetails.length)
    );
  };

  const handleInsertSubCatName = () => {
    setTagDetails(
      tagDetails.substring(0, tagDetailsCursor) +
        "{_sub_category_name}" +
        tagDetails.substring(tagDetailsCursor, tagDetails.length)
    );
  };

  const handleAddTag = () => {
    setLoadingDlg(true);
    const f = {
      lang,
      data: [
        {
          tagName,
          tagDetectorId,
          tagDetails,
          severity: tagSeverity,
          creatorId: localAdminData._id,
        },
      ],
    };
    dispatch(createSeoTags(f));
    setTimeout(() => {
      dispatch(getNotification());
      dispatch(getSeoTags());
    }, 1000);
  };

  const handleTagName = (e) => {
    setTagName(e.target.value);
    setTagNameExist(false);
  };

  const handleDlgClose = () => {
    setTagName("");
    setTagDetails("");
    setTagSeverity("success");
    setAddTagDlg(false);
    setTagNameExist(false);
  };

  const handleSeoEnglishVariant = (e, newValue) => {
    setTagDetectorId(newValue.fixedId);
  };

  // ! translate
  const [siteLang, setSiteLang] = useState("");
  // Local Store
  const localLangData = JSON.parse(localStorage.getItem("lang"));
  // Store
  const { langs, activeLang } = useSelector((state) => state.misc);
  const [langSettings1, setLangSettings1] = useState([]);
  const [langSettings2, setLangSettings2] = useState([]);
  const title1 = "Add SEO Tag Dialog";
  const title2 = "Misc Words";

  useEffect(() => {
    if (langs && langs.length > 0 && siteLang !== "") {
      const L = langs.find((l) => l.langCode === siteLang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      const A = arr.find((l) => l._title === title1);
      if (A) setLangSettings1(A.data);
      const B = arr.find((l) => l._title === title2);
      if (B) setLangSettings2(B.data);
    }
  }, [siteLang, langs]);

  const translate1 = (String) => translation(String, title1, 1);
  const translate2 = (String) => translation(String, title2, 2);

  const translation = (String, title, index) => {
    let translation = "";
    let settings = [];
    if (index === 1) {
      settings = langSettings1;
    } else if (index === 2) {
      settings = langSettings2;
    }
    const T = settings.find((ls) => ls.title === String);
    if (T && T.value) translation = T.value;
    else {
      let arr = [];
      if (langs && langs.length > 0 && siteLang !== "") {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.settings && LEn.settings.length > 0) arr = LEn.settings;
        const S = arr.find((l) => l._title === title);
        if (S) {
          const T = S.data.find((ls) => ls.title === String);
          if (T && T.value) translation = T.value;
        }
      }
    }
    return translation;
  };

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setSiteLang(activeLang.langCode);
    } else {
      if (localLangData) {
        setSiteLang(localLangData.langCode);
      }
    }
  }, [activeLang]);
  // ! translate

  return (
    <Dialog
      open={addTagDlg}
      onClose={() => setAddTagDlg(false)}
      aria-labelledby={"add-tag-dialog"}
      fullWidth
      maxWidth={"sm"}
    >
      <DialogTitle>{translate1("Add Tag")}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1} flexDirection="column">
          <Grid item>
            <TextField
              variant="standard"
              size="small"
              fullWidth
              label={translate1("Tag Name")}
              value={tagName}
              error={tagNameExist ? true : false}
              onChange={handleTagName}
              helperText={tagNameExist && createSeoTagResponse.message}
            />
          </Grid>
          <Grid item>
            <Grid container spacing={1} justifyContent="center">
              <Grid item>
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleInsertMedicineName}
                  color="info"
                >
                  Medicine
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleInsertCatName}
                  color="info"
                >
                  Category
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleInsertSubCatName}
                  color="info"
                >
                  SubCategory
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <TextField
              variant="standard"
              size="small"
              fullWidth
              multiline
              minRows={2}
              label={translate1("Tag Details")}
              value={tagDetails}
              onChange={(e) => setTagDetails(e.target.value)}
              onBlur={getCursorPosition}
              error={tagDetails.length > tagDetailsLimit ? true : false}
              helperText={
                <Typography
                  align="right"
                  variant="body2"
                  color={
                    tagDetails.length > tagDetailsLimit ? "error" : "primary"
                  }
                >
                  {tagDetails.length}/{tagDetailsLimit}
                </Typography>
              }
            />
          </Grid>
          <Grid item>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small" fullWidth>
              <InputLabel id="demo-select-small">
                {translate1("Color")}
              </InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={tagSeverity}
                label="Color"
                variant="standard"
                onChange={(e) => setTagSeverity(e.target.value)}
              >
                <MenuItem value="primary">
                  <IconButton>
                    <CircleIcon color="primary" />
                  </IconButton>
                  Primary
                </MenuItem>
                <MenuItem value="secondary">
                  {" "}
                  <IconButton>
                    <CircleIcon color="secondary" />
                  </IconButton>
                  Secondary
                </MenuItem>
                <MenuItem value="success">
                  {" "}
                  <IconButton>
                    <CircleIcon color="success" />
                  </IconButton>
                  Success
                </MenuItem>
                <MenuItem value="info">
                  {" "}
                  <IconButton>
                    <CircleIcon color="info" />
                  </IconButton>
                  Info
                </MenuItem>
                <MenuItem value="error">
                  {" "}
                  <IconButton>
                    <CircleIcon color="error" />
                  </IconButton>
                  Error
                </MenuItem>
                <MenuItem value="warning">
                  {" "}
                  <IconButton>
                    <CircleIcon color="warning" />
                  </IconButton>
                  Warning
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {lang !== "en" && (
            <Grid item>
              <Autocomplete
                size="small"
                onChange={(e, newValue) => handleSeoEnglishVariant(e, newValue)}
                disablePortal
                options={tagListEn}
                filterOptions={filterOptions}
                filterSelectedOptions
                getOptionLabel={(option) => option.tagName}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{
                      "& > img": { mr: 2, flexShrink: 0 },
                    }}
                    {...props}
                  >
                    {option.tagName}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={translate1("English Variant")}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                )}
              />
            </Grid>
          )}
          <Grid item>
            <LoadingButton
              onClick={handleAddTag}
              loadingPosition="end"
              endIcon={<AddCircleOutlinedIcon />}
              loading={loadingDlg}
              disabled={disableSubmit}
              variant="contained"
              size="small"
              fullWidth
              color="success"
            >
              {translate1("Add")}
            </LoadingButton>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDlgClose} color="error">
          {translate1("Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
