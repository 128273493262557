import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Alert,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Slide,
  Grid,
  Tooltip,
  IconButton,
  Divider,
  Badge,
  Box,
} from "@mui/material";

// ICons
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import CancelIcon from "@mui/icons-material/Cancel";
import InfoIcon from "@mui/icons-material/Info";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";

// Media Query
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useState } from "react";
import ViewProductDetails from "../../adminPanel/medicines/components/ViewProductDetails";
import { useDispatch, useSelector } from "react-redux";
import { getLangs } from "../../../reduxToolkit/features/adminPanel/misc";
import {
  getDosages,
  getMedicines,
} from "../../../reduxToolkit/features/adminPanel/medicine";
import { getCreated, getExactTime } from "../../../hooks/getCreatedData";
import { getSuppliers } from "../../../reduxToolkit/features/adminPanel/supplier";
import LoadingBox from "../../../components/misc/Loading";
import AssignPriceDlg from "./AssignPriceDlg";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  cancelAskPrice,
  unassignPriceByCustomer,
} from "../../../reduxToolkit/features/adminPanel/assignPrice";
import { getAskAllPriceList } from "../../../reduxToolkit/features/frontend/checkout";
import { getNotification } from "../../../reduxToolkit/features/adminPanel/notification";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AskPriceListByCustomerDialog({
  askPriceList,
  // customerId,
}) {
  // Media Query
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  // Local Store
  const localAdminData = JSON.parse(localStorage.getItem("adminLoginInfo"));

  const dispatch = useDispatch();
  // ? use State
  const [loading, setLoading] = useState(false);
  const [viewMedBuffer, setViewMedBuffer] = useState([]);
  const [lang, setLang] = useState("en");
  const [langId, setLangId] = useState("");

  // Dialogs
  const [priceChangeDlg, setPriceChangeDlg] = useState(false);
  const [priceChangeBuffer, setPriceChangeBuffer] = useState({});

  const [priceUnassignLoading, setPriceUnassignLoading] = useState(false);
  const [priceUnassignDlg, setPriceUnassignDlg] = useState(false);
  const [priceUnassignBuffer, setPriceUnassignBuffer] = useState({});

  const [priceCancelLoading, setPriceCancelLoading] = useState(false);
  const [priceCancelDlg, setPriceCancelDlg] = useState(false);
  const [priceCancelBuffer, setPriceCancelBuffer] = useState({});

  const [priceModifyDlg, setPriceModifyDlg] = useState(false);
  const [priceModifyBuffer, setPriceModifyBuffer] = useState({});

  //   ? Local Store
  const localLangData = JSON.parse(localStorage.getItem("lang"));
  //   ? Store
  const { langs, activeLang } = useSelector((state) => state.misc);
  const { medicines, medVariants, dosages } = useSelector(
    (state) => state.medicine
  );
  const { unassignPriceResponse, cancelAskPriceResponse } = useSelector(
    (state) => state.assignPrice
  );

  const handlePriceUnassignClose = () => {
    setPriceUnassignDlg(false);
    setPriceUnassignBuffer({});
  };

  const handlePriceUnassign = () => {
    setPriceUnassignLoading(true);
    let obj = { ...priceUnassignBuffer };
    obj.creatorId = localAdminData._id;
    dispatch(unassignPriceByCustomer(obj));
    setTimeout(() => {}, 1000);
  };

  const handlePriceCancelClose = () => {
    setPriceCancelDlg(false);
    setPriceCancelBuffer({});
  };

  const handlePriceCancel = () => {
    setPriceCancelLoading(true);
    let obj = { ...priceCancelBuffer };
    obj.creatorId = localAdminData._id;
    dispatch(cancelAskPrice(obj));
  };

  const handleMedPreview = (id) => {
    let arr = [...viewMedBuffer];
    if (arr.includes(id)) arr = arr.filter((a) => a !== id);
    else arr = [id];
    setViewMedBuffer(arr);
  };

  const getLangInfo = (langId) => {
    const L = langs.find((l) => l._id === langId);
    if (L && L.langName) return L.langName;
    else return "English";
  };

  const getLangCode = (langId) => {
    const L = langs.find((l) => l._id === langId);
    if (L && L.langCode) return L.langCode;
    else return "en";
  };

  const getDosageInfo = (dosageId, langCode) => {
    let dosageName = "";
    if (dosages && dosages.length > 0) {
      const dosageList = dosages.find((d) => d.lang === langCode);
      if (dosageList && dosageList.data) {
        const D = dosageList.data.find((d) =>
          langCode !== "en" ? d.dosageDetectorId : d.fixedDosageId === dosageId
        );
        if (D && D.dosageName) dosageName = D.dosageName;
      }
    }
    return dosageName;
  };

  const getMedShortInfo = (medId, variantId, langId) => {
    let genericBrand_Variant = "";
    if (medicines && medicines.length > 0) {
      const M = medicines.find((m) => m.lang === lang);
      if (M && M.data) {
        let str = "";
        const Med = M.data.find(
          (med) =>
            (lang === "en" ? med.fixedMedicineId : med.medicineDetectorId) ===
            medId
        );
        if (Med) {
          str = str + Med.genericBrand;
        }
        if (medVariants && medVariants.length > 0) {
          const V = medVariants.find(
            (v) =>
              (getLangCode(langId) !== "en"
                ? v.variantDetectorId
                : v.fixedVariantId) === variantId
          );

          if (V) {
            const VV = medVariants.find(
              (vv) =>
                (lang !== "en" ? vv.variantDetectorId : vv.fixedVariantId) ===
                (getLangCode(langId) !== "en"
                  ? V.variantDetectorId
                  : V.fixedVariantId)
            );
            if (VV) {
              str =
                str +
                " ( " +
                getDosageInfo(VV.dosageForm, lang) +
                " - " +
                VV.strength +
                " - " +
                VV.packTypes +
                " ) ";
            }
          }
        }
        genericBrand_Variant = str;
      }
    }

    return genericBrand_Variant;
  };

  // ? UseEffect
  useEffect(() => {
    setLoading(true);
    if (!langs) dispatch(getLangs);
    if (!(medicines && medicines.length > 0)) dispatch(getMedicines());
    dispatch(getDosages());
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setLang(activeLang.langCode);
      setLangId(activeLang._id);
    } else {
      if (localLangData) {
        setLang(localLangData.langCode);
        setLangId(localLangData._id);
      }
    }
  }, [activeLang]);

  // Fixed date
  const [showDate, setShowDate] = useState([]);

  const handleShowDate = (date) => {
    let arr = [...showDate];
    if (arr.includes(date)) {
      arr = arr.filter((a) => a !== date);
    } else {
      arr.push(date);
    }
    setShowDate(arr);
  };

  // Check unassign Price response
  useEffect(() => {
    if (unassignPriceResponse && unassignPriceResponse.message) {
      if (!unassignPriceResponse.failed) {
        dispatch(getAskAllPriceList());
        setTimeout(() => {
          dispatch(getNotification());
          setPriceUnassignLoading(false);
          handlePriceUnassignClose();
        }, 500);
      }
    }
  }, [unassignPriceResponse]);

  useEffect(() => {
    if (cancelAskPriceResponse && cancelAskPriceResponse.message) {
      if (!cancelAskPriceResponse.failed) {
        dispatch(getAskAllPriceList());
        setTimeout(() => {
          dispatch(getNotification());
          setPriceCancelLoading(false);
          handlePriceCancelClose();
        }, 500);
      }
    }
  }, [cancelAskPriceResponse]);

  // ! translate
  // Store
  const [langSettings1, setLangSettings1] = useState([]);
  const [langSettings2, setLangSettings2] = useState([]);
  const title1 = "Price Request List";
  const title2 = "Misc Words";

  useEffect(() => {
    if (langs && langs.length > 0 && lang !== "") {
      const L = langs.find((l) => l.langCode === lang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      const A = arr.find((l) => l._title === title1);
      if (A) setLangSettings1(A.data);
      const B = arr.find((l) => l._title === title2);
      if (B) setLangSettings2(B.data);
    }
  }, [lang, langs]);

  const translate1 = (String) => translation(String, title1, 1);
  const translate2 = (String) => translation(String, title2, 2);

  const translation = (String, title, index) => {
    let translation = "";
    let settings = [];
    if (index === 1) {
      settings = langSettings1;
    } else if (index === 2) {
      settings = langSettings2;
    }
    const T = settings.find((ls) => ls.title === String);
    if (T && T.value) translation = T.value;
    else {
      let arr = [];
      if (langs && langs.length > 0 && lang !== "") {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.settings && LEn.settings.length > 0) arr = LEn.settings;
        const S = arr.find((l) => l._title === title);
        if (S) {
          const T = S.data.find((ls) => ls.title === String);
          if (T && T.value) translation = T.value;
        }
      }
    }
    return translation;
  };

  // ! translate

  return (
    <>
      {loading ? (
        <LoadingBox w={"100%"} />
      ) : (
        askPriceList.map((x, i) => (
          <Grid container spacing={0.5} flexDirection="column">
            <Grid item sx={{ mb: 2 }}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} sm={x.priceAdded ? 10.5 : 10.5}>
                  <Button
                    size="small"
                    color={x.priceAdded ? "success" : "info"}
                    variant={x.priceAdded ? "contained" : "outlined"}
                    fullWidth
                    sx={{
                      my: 0.2,
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      fontSize: !smUp ? ".6rem" : "1rem",
                    }}
                    endIcon={
                      viewMedBuffer.includes(x._id) ? (
                        <ArrowDropUpIcon />
                      ) : (
                        <ArrowDropDownCircleIcon />
                      )
                    }
                    onClick={() => handleMedPreview(x._id)}
                  >
                    {getMedShortInfo(x.medId, x.variantId, x.langId)}
                  </Button>
                </Grid>
                {x.priceAdded && (
                  <Grid item xs={6} sm={0.75}>
                    {!smUp ? (
                      <Button
                        variant="outlined"
                        size="small"
                        color="warning"
                        fullWidth
                        sx={{ fontSize: !smUp ? ".6rem" : "1rem" }}
                        onClick={() => {
                          setPriceModifyDlg(true);
                          setPriceModifyBuffer({
                            customerId: x.customerId,
                            medId: x.medId,
                            varId: x.variantId,
                          });
                        }}
                      >
                        {translate1("Modify Assigned Price")}
                      </Button>
                    ) : (
                      <Tooltip
                        arrow
                        placement={x.priceAdded ? "left" : "right"}
                        title={translate1("Modify Assigned Price")}
                      >
                        <IconButton
                          size="small"
                          onClick={() => {
                            setPriceModifyDlg(true);
                            setPriceModifyBuffer({
                              customerId: x.customerId,
                              medId: x.medId,
                              varId: x.variantId,
                            });
                          }}
                        >
                          <BorderColorOutlinedIcon color="warning" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                )}
                <Grid item xs={6} sm={0.75}>
                  {!smUp ? (
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{ fontSize: !smUp ? ".6rem" : "1rem" }}
                      color={x.priceAdded ? "error" : "primary"}
                      fullWidth
                      onClick={() => {
                        if (x.priceAdded) {
                          setPriceUnassignDlg(true);
                          setPriceUnassignBuffer(x);
                        } else {
                          setPriceChangeDlg(true);
                          setPriceChangeBuffer({
                            customerId: x.customerId,
                            medId: x.medId,
                            varId: x.variantId,
                          });
                        }
                      }}
                    >
                      {x.priceAdded
                        ? translate1("Unassign Price")
                        : translate1("Assign Price")}
                    </Button>
                  ) : (
                    <Tooltip
                      arrow
                      placement={x.priceAdded ? "right" : "left"}
                      title={
                        x.priceAdded
                          ? translate1("Unassign Price")
                          : translate1("Assign Price")
                      }
                    >
                      <IconButton
                        size="small"
                        onClick={() => {
                          if (x.priceAdded) {
                            setPriceUnassignDlg(true);
                            setPriceUnassignBuffer(x);
                          } else {
                            setPriceChangeDlg(true);
                            setPriceChangeBuffer({
                              customerId: x.customerId,
                              medId: x.medId,
                              varId: x.variantId,
                            });
                          }
                        }}
                      >
                        <PriceChangeIcon
                          color={x.priceAdded ? "error" : "primary"}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
                {!x.priceAdded && (
                  <Grid item xs={6} sm={0.75}>
                    {!smUp ? (
                      <Button
                        variant="outlined"
                        size="small"
                        color="error"
                        fullWidth
                        sx={{ fontSize: !smUp ? ".6rem" : "1rem" }}
                        onClick={() => {
                          setPriceCancelDlg(true);
                          setPriceCancelBuffer(x);
                        }}
                      >
                        {translate1("Cancel")}
                      </Button>
                    ) : (
                      <Tooltip
                        arrow
                        placement="right"
                        title={translate1("Cancel Request")}
                      >
                        <IconButton
                          size="small"
                          onClick={() => {
                            setPriceCancelDlg(true);
                            setPriceCancelBuffer(x);
                          }}
                        >
                          <CancelIcon color="error" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
            {viewMedBuffer.includes(x._id) && (
              <>
                <Grid item>
                  <Slide
                    direction="right"
                    in={viewMedBuffer.includes(x._id)}
                    mountOnEnter
                    unmountOnExit
                  >
                    <Card sx={{ background: "lightblue" }}>
                      <CardContent>
                        <Button
                          variant="text"
                          size="small"
                          color="inherit"
                          sx={{
                            fontSize: !smUp ? ".6rem" : "1rem",
                            pointerEvents: "none",
                          }}
                        >
                          {translate1("Submitted From ")}
                          <span style={{ color: "red", margin: "0 5px" }}>
                            {getLangInfo(x.langId)}
                          </span>
                          {translate1("Page")}
                        </Button>
                        <Typography
                          variant="subtitle2"
                          color="secondary"
                          sx={{ fontSize: ".8rem", ml: 0.8 }}
                        >
                          {translate1("Submitted")}: {getCreated(x.createdAt)}
                          <Tooltip
                            arrow
                            placement="top"
                            title={`Submitted: ${getExactTime(x.createdAt)}`}
                          >
                            <IconButton
                              size="small"
                              onClick={() => handleShowDate(x.createdAt)}
                            >
                              <InfoIcon color="info" />
                            </IconButton>
                          </Tooltip>
                          <br />
                          {showDate.includes(x.createdAt) &&
                            getExactTime(x.createdAt)}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Slide>
                </Grid>
                <Grid item></Grid>

                <Grid item>
                  <ViewProductDetails
                    medId={x.medId}
                    varId={x.variantId}
                    lang={lang}
                  />
                </Grid>
              </>
            )}
          </Grid>
        ))
      )}
      {/* Price Change Dialog */}
      {priceChangeDlg && (
        <AssignPriceDlg
          priceChangeDlg={priceChangeDlg}
          setPriceChangeDlg={setPriceChangeDlg}
          priceChangeBuffer={priceChangeBuffer}
          lang={lang}
          customerId={priceChangeBuffer.customerId}
          modify={false}
          langId={langId}
        />
      )}
      {/* Price Modify Dialog */}
      {priceModifyDlg && (
        <AssignPriceDlg
          priceChangeDlg={priceModifyDlg}
          setPriceChangeDlg={setPriceModifyDlg}
          priceChangeBuffer={priceModifyBuffer}
          lang={lang}
          customerId={priceModifyBuffer.customerId}
          modify={true}
          langId={langId}
        />
      )}
      {/* Price un assign Dialog */}
      {priceUnassignDlg && (
        <Dialog
          open={priceUnassignDlg}
          onClose={handlePriceUnassignClose}
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle sx={{ fontSize: ".9rem" }}>
            Do you want to unassign{" "}
            <span style={{ color: "blue" }}>
              {getMedShortInfo(
                priceUnassignBuffer.medId,
                priceUnassignBuffer.variantId,
                priceUnassignBuffer.langId
              )}
            </span>
            ?
          </DialogTitle>
          <DialogActions>
            <Button onClick={handlePriceUnassignClose} color="primary">
              {translate1("Cancel")}
            </Button>
            <LoadingButton
              loading={priceUnassignLoading}
              onClick={handlePriceUnassign}
              color="error"
            >
              {translate1("Unassign Price")}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
      {/* Price Cancel Dialog */}
      {priceCancelDlg && (
        <Dialog open={priceCancelDlg} onClose={handlePriceCancelClose}>
          <DialogTitle sx={{ fontSize: ".9rem" }}>
            {translate1("Cancel Request")} for{" "}
            <span style={{ color: "blue" }}>
              {getMedShortInfo(
                priceCancelBuffer.medId,
                priceCancelBuffer.variantId,
                priceCancelBuffer.langId
              )}
            </span>
            ?
          </DialogTitle>

          <DialogActions>
            <Button onClick={handlePriceCancelClose} color="primary">
              {translate1("Cancel")}
            </Button>
            <LoadingButton
              loading={priceCancelLoading}
              onClick={handlePriceCancel}
              color="error"
            >
              {translate1("Cancel Request")}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
