import {
  Alert,
  Autocomplete,
  Box,
  Button,
  createFilterOptions,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddLangDialog from "../dialog/AddLangDialog";
// Icon
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ArrowRightAltTwoToneIcon from "@mui/icons-material/ArrowRightAltTwoTone";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import InfoIcon from "@mui/icons-material/Info";

import AddCategoryDialog from "../dialog/AddCategoryDialog";
import {
  deleteCat,
  getMedCategories,
} from "../../../../reduxToolkit/features/adminPanel/medicine";
import { getAdmins } from "../../../../reduxToolkit/features/adminPanel/admin";
import { formatDistance } from "date-fns";
import { getNotification } from "../../../../reduxToolkit/features/adminPanel/notification";
import EditCategoryDialog from "../dialog/EditCategoryDialog";
import LoadingBox from "../../../../components/misc/Loading";
import { getExactTime } from "../../../../hooks/getCreatedData";

// Limit options to show in autocomplete
const OPTIONS_LIMIT = 6;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) => {
  return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
};

export default function ManageMedicineCategory({ langFromPrev }) {
  // use States
  const [loadingMain, setLoadingMain] = useState(false);
  const [lang, setLang] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [categoryListEn, setCategoryListEn] = useState([]);
  const [searchCategoryList, setSearchCategoryList] = useState([]);
  const [searchingList, setSearchingList] = useState([]);

  // Dialog
  const [addLangDlg, setAddLangDlg] = useState(false);
  const [addCategoryDlg, setAddCategoryDlg] = useState(false);
  const [editCategoryDlg, setEditCategoryDlg] = useState(false);
  const [editCatBuffer, setEditCatBuffer] = useState({});
  const [viewCatDlg, setViewCatDlg] = useState(false);
  const [viewCatBuffer, setViewCatBuffer] = useState({});

  // LocalData
  const localLangData = JSON.parse(localStorage.getItem("lang"));
  const localAdminData = JSON.parse(localStorage.getItem("adminLoginInfo"));
  // store
  const { langs, activeLang } = useSelector((state) => state.misc);
  const { categories } = useSelector((state) => state.medicine);
  const { admins } = useSelector((state) => state.admin);

  // UseEffect
  // get Categories
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMedCategories());
    dispatch(getAdmins());
  }, []);

  // Set Language
  useEffect(() => {
    if (langFromPrev) {
      setLang(langFromPrev);
    } else {
      if (activeLang && activeLang.langCode) {
        setLang(activeLang.langCode);
      } else localLangData && setLang(localLangData.langCode);
    }
  }, [activeLang, langFromPrev]);

  // Set Category Data
  useEffect(() => {
    setLoadingMain(true);
    if (
      lang !== "" &&
      categories &&
      categories.length > 0 &&
      admins.length > 0
    ) {
      const M = categories.find((m) => m.lang === lang);
      const MEn = categories.find((m) => m.lang === "en");
      let searchArr = [];
      let A = [];
      if (M && M.data) {
        A = M.data.map((x, i) => {
          let newX = { ...x };
          let { adminName } = admins.find((a) => a._id === x.creatorId);
          if (MEn && MEn.data && lang !== "en") {
            let C = MEn.data.find((m) => m.fixedCatId === x.categoryDetectorId);
            if (C) newX["catNameEn"] = C.catName;
          }
          if (adminName) newX["adminName"] = adminName;
          return newX;
        });
        A.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setCategoryList(A);
        let arr = [];
        M.data.map((x, i) => {
          arr.push({
            searchName: x.catName,
            searchId: x.categoryDetectorId,
            ...x,
          });
          x.subCatList &&
            x.subCatList.length > 0 &&
            x.subCatList.map((y, j) => {
              arr.push({
                searchName: y.subCatName,
                searchId: y.subCatDetectorId,
                ...x,
              });
            });
          return 0;
        });
        searchArr = [...arr];
      } else setCategoryList([]);
      if (lang !== "en") {
        if (MEn && MEn.data) {
          setCategoryListEn([...MEn.data]);
          let arr2 = [];
          MEn.data.map((x, i) => {
            const C = A.find((c) => c.categoryDetectorId === x.fixedCatId);
            C &&
              arr2.push({
                searchName: x.catName,
                searchId: x.fixedCatId,
                ...x,
              });
            x.subCatList &&
              x.subCatList.length > 0 &&
              x.subCatList.map((y, j) => {
                A.map((z, k) => {
                  const Sc = z.subCatList.find(
                    (sc) => sc.subCatDetectorId === y.fixedSubCatId
                  );
                  Sc &&
                    arr2.push({
                      searchName: y.subCatName,
                      searchId: y.fixedSubCatId,
                      ...x,
                    });
                });
              });
            return 0;
          });
          searchArr = [...searchArr, ...arr2];
        } else {
          setCategoryListEn([]);
        }
      }
      setSearchCategoryList(searchArr);
    }
    setTimeout(() => {
      setLoadingMain(false);
    }, 1000);
  }, [admins, categories, lang]);

  // Functions
  const handleChangeLang = (e) => {
    setLang(e.target.value);
    setSearchingList([]);
  };

  const handleAddCategory = () => {
    setAddCategoryDlg(true);
  };

  const handleCatDetails = (id) => {
    setViewCatDlg(true);
    const C = categoryList.find((c) => c._id === id);
    setViewCatBuffer(C);
  };

  const handleCloseViewCatDlg = () => {
    setViewCatDlg(false);
    setViewCatBuffer({});
  };

  const handleDeleteCat = (id) => {
    dispatch(deleteCat({ id, creatorId: localAdminData._id, lang }));
    setTimeout(() => {
      dispatch(getMedCategories());
      dispatch(getNotification());
    }, 1000);
  };

  const handleEditCat = (data) => {
    setEditCategoryDlg(true);
    setEditCatBuffer(data);
  };

  // Search
  const handleSearch = (e, newValue) => {
    let arr = [];
    if (newValue.length < 1) setSearchingList([]);
    if (lang !== "en") {
      let arr2 = [];
      newValue.map((x, i) => {
        if (!x.categoryDetectorId) {
          const C = categoryList.find(
            (c) => c.categoryDetectorId === x.searchId
          );
          if (C) {
            arr2.push(C);
          } else {
            if (!x.subCatDetectorId) {
              categoryList.map((y, j) => {
                const Sc = y.subCatList.find(
                  (sc) => sc.subCatDetectorId === x.searchId
                );
                if (Sc) arr2.push(y);
              });
            }
          }
        } else {
          const AC = categoryList.find(
            (c) => c.categoryDetectorId === x.searchId
          );
          if (AC) {
            AC && arr2.push(AC);
          } else {
            categoryList.map((y, j) => {
              const Sc = y.subCatList.find(
                (sc) => sc.subCatDetectorId === x.searchId
              );
              if (Sc) arr2.push(y);
            });
          }
        }
        return 0;
      });
      arr = [...arr2];
      if (arr.length > 0) {
        const unique = [
          ...new Map(arr.map((item) => [item["_id"], item])).values(),
        ];
        setSearchingList([...unique]);
      }
    } else {
      arr = [...newValue];
      const unique = [
        ...new Map(arr.map((item) => [item["_id"], item])).values(),
      ];
      setSearchingList([...unique]);
    }
  };

  const getSubCatNameEn = (id) => {
    let result;
    result = categoryListEn.map((x, i) => {
      const S = x.subCatList.find((s) => s.fixedSubCatId === id);
      if (S && S.subCatName) return S.subCatName;
    });
    return result;
  };

  // Fixed date
  const [showDate, setShowDate] = useState([]);

  const handleShowDate = (date) => {
    let arr = [...showDate];
    if (arr.includes(date)) {
      arr = arr.filter((a) => a !== date);
    } else {
      arr.push(date);
    }
    setShowDate(arr);
  };

  // ! translate
  const [siteLang, setSiteLang] = useState("");
  // Store
  const [langSettings1, setLangSettings1] = useState([]);
  const [langSettings2, setLangSettings2] = useState([]);
  const [langSettings3, setLangSettings3] = useState([]);
  const title1 = "Add Sub Category Dialog";
  const title2 = "Misc Words";
  const title3 = "Manage Category";

  useEffect(() => {
    if (langs && langs.length > 0 && siteLang !== "") {
      const L = langs.find((l) => l.langCode === siteLang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      const A = arr.find((l) => l._title === title1);
      if (A) setLangSettings1(A.data);
      const B = arr.find((l) => l._title === title2);
      if (B) setLangSettings2(B.data);
      const C = arr.find((l) => l._title === title3);
      if (C) setLangSettings3(C.data);
    }
  }, [siteLang, langs]);

  const translate1 = (String) => translation(String, title1, 1);
  const translate2 = (String) => translation(String, title2, 2);
  const translate3 = (String) => translation(String, title3, 3);

  const translation = (String, title, index) => {
    let translation = "";
    let settings = [];
    if (index === 1) {
      settings = langSettings1;
    } else if (index === 2) {
      settings = langSettings2;
    } else if (index === 3) {
      settings = langSettings3;
    }
    const T = settings.find((ls) => ls.title === String);
    if (T && T.value) translation = T.value;
    else {
      let arr = [];
      if (langs && langs.length > 0 && siteLang !== "") {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.settings && LEn.settings.length > 0) arr = LEn.settings;
        const S = arr.find((l) => l._title === title);
        if (S) {
          const T = S.data.find((ls) => ls.title === String);
          if (T && T.value) translation = T.value;
        }
      }
    }
    return translation;
  };

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setSiteLang(activeLang.langCode);
    } else {
      if (localLangData) {
        setSiteLang(localLangData.langCode);
      }
    }
  }, [activeLang]);
  // ! translate

  return (
    <>
      {langs.length > 0 && (
        <>
          <Grid>
            <Grid
              container
              spacing={1}
              justifyContent="space-between"
              alignItems="center"
            >
              {/* Select Language */}
              <Grid item xs={12} md={4}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={2} sm={1} md={1.5}>
                    <IconButton>
                      <AddCircleOutlineOutlinedIcon
                        color="info"
                        size="large"
                        onClick={() => setAddLangDlg(true)}
                      />
                    </IconButton>
                    <AddLangDialog
                      addLangDlg={addLangDlg}
                      setAddLangDlg={setAddLangDlg}
                      setLang={setLang}
                    />
                  </Grid>
                  <Grid item xs={10} sm={4} md={5}>
                    <FormControl fullWidth>
                      <Select
                        value={lang}
                        label="language"
                        variant="standard"
                        onChange={handleChangeLang}
                        size="small"
                      >
                        {langs.map((x, i) => (
                          <MenuItem key={i} value={x.langCode} dense>
                            <img
                              alt={x.langCode}
                              src={`https://flagcdn.com/w20/${
                                x.langCode === "en" ? "gb" : x.langCode
                              }.png`}
                            />
                            <Button color="inherit" size="small">
                              {x.langName}
                            </Button>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* Add category */}
                  <Grid item xs={12} sm={7} md={4.5}>
                    <Button
                      variant="contained"
                      size="small"
                      color="error"
                      fullWidth
                      onClick={() => handleAddCategory()}
                    >
                      {translate1("Add Category")}
                    </Button>
                    {/* Add Category Dialog */}
                    <AddCategoryDialog
                      addCategoryDlg={addCategoryDlg}
                      setAddCategoryDlg={setAddCategoryDlg}
                      lang={lang}
                      categoryListEn={categoryListEn}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* Search */}
              <Grid item xs={12} md={8} sx={{ mt: { xs: 0, sm: -2 } }}>
                <Autocomplete
                  multiple
                  options={searchCategoryList}
                  onChange={(e, newValue) => handleSearch(e, newValue)}
                  filterOptions={filterOptions}
                  getOptionLabel={(option) => option.searchName}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.searchName}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={translate1("Search") + "..."}
                      variant="standard"
                      size="small"
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </Grid>
            {/* Medicine List */}
            <Button
              size="small"
              variant="contained"
              fullWidth
              sx={{ my: 1, pointerEvents: "none" }}
            >
              {translate1("Categories")} ({categoryList.length})
            </Button>
          </Grid>
          {loadingMain ? (
            <LoadingBox w={"100%"} />
          ) : categoryList.length < 1 || searchingList === -1 ? (
            <Alert
              severity="warning"
              sx={{ display: "flex", justifyContent: "center" }}
            >
              {translate1("No Data Available")}
            </Alert>
          ) : (
            <>
              <Box
                sx={{
                  maxHeight: { xs: "60vh", sm: "57vh" },
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                <Grid
                  container
                  spacing={0}
                  justifyContent="center"
                  flexDirection="column"
                >
                  {(searchingList.length > 0
                    ? searchingList
                    : categoryList
                  ).map((x, i) => (
                    <Grid
                      item
                      key={i}
                      sx={{ border: "1px solid lightblue", p: 1 }}
                      xs={12}
                    >
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12} sm={1.5} md={1} xl={0.75}>
                          <Grid container spacing={0} justifyContent="center">
                            {/* Edit */}
                            <Grid item>
                              <Tooltip
                                arrow
                                title={translate2("Edit")}
                                placement="top"
                              >
                                <IconButton
                                  size="small"
                                  onClick={() => handleEditCat(x)}
                                >
                                  <EditRoundedIcon color="info" />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                            {/* Delete */}
                            <Grid item>
                              <Tooltip
                                title={translate2("Delete")}
                                placement="top"
                                arrow
                              >
                                <IconButton
                                  size="small"
                                  onClick={() => handleDeleteCat(x._id)}
                                >
                                  <DeleteRoundedIcon color="error" />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </Grid>
                        {/* Category */}
                        <Grid item xs={12} sm={10.5} md={10.5} xl={11.25}>
                          <Button
                            variant="contained"
                            size="small"
                            color="secondary"
                            sx={{
                              my: 0.5,
                              width: { xs: "100%", md: "30%", xl: "18%" },
                            }}
                            onClick={() => handleCatDetails(x._id)}
                            // fullWidth
                          >
                            {x.catName} {x.catNameEn && "(" + x.catNameEn + ")"}
                          </Button>
                          <Divider />
                        </Grid>

                        {/* Sub Category */}
                        {x.subCatList &&
                          x.subCatList.length > 0 &&
                          x.subCatList.map((y, j) => (
                            <Grid item xs={11} sm={6} md={4} xl={3} key={j}>
                              <Button
                                variant="outlined"
                                size="small"
                                color="warning"
                                sx={{
                                  pointerEvents: "none",
                                  m: 1,
                                  display: "flex",
                                  justifyContent: "flex-start",
                                }}
                                startIcon={<ArrowRightAltTwoToneIcon />}
                                fullWidth
                              >
                                {y.subCatName}
                                {lang !== "en" && " - "}
                                {lang !== "en" &&
                                  getSubCatNameEn(y.subCatDetectorId)}
                              </Button>
                            </Grid>
                          ))}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </>
          )}
          {/* Edit Category Dialog */}
          <EditCategoryDialog
            lang={lang}
            editCategoryDlg={editCategoryDlg}
            setEditCategoryDlg={setEditCategoryDlg}
            editCatBuffer={editCatBuffer}
            setEditCatBuffer={setEditCatBuffer}
            categoryListEn={categoryListEn}
          />
          {/* view Category Dialog */}
          <Dialog
            open={viewCatDlg}
            onClose={() => setViewCatDlg(false)}
            aria-labelledby={"view-cat-dlg"}
          >
            <DialogContent>
              {viewCatBuffer && viewCatBuffer.catName && (
                <>
                  <Grid
                    container
                    spacing={0}
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography
                        variant="subtitle1"
                        color="initial"
                        align="center"
                        sx={{ textDecoration: "underline" }}
                      >
                        {viewCatBuffer.catName}
                        {viewCatBuffer.catNameEn &&
                          " (" + viewCatBuffer.catNameEn + ")"}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="subtitle1"
                        color="success"
                        align="center"
                      >
                        {translate2("Created BY")}: {viewCatBuffer.adminName}
                      </Typography>
                    </Grid>
                    <Grid item>
                      {viewCatBuffer.createdAt && (
                        <Typography
                          variant="body2"
                          color="error"
                          align="center"
                        >
                          {translate2("Created")}:{" "}
                          {formatDistance(
                            new Date(viewCatBuffer.createdAt),
                            new Date(),
                            {
                              addSuffix: true,
                            }
                          )}
                          <Tooltip
                            arrow
                            placement="top"
                            title={getExactTime(viewCatBuffer.createdAt)}
                          >
                            <IconButton
                              size="small"
                              onClick={() =>
                                handleShowDate(viewCatBuffer.createdAt)
                              }
                            >
                              <InfoIcon color="info" />
                            </IconButton>
                          </Tooltip>
                          <br />
                          {showDate.includes(viewCatBuffer.createdAt) &&
                            getExactTime(viewCatBuffer.createdAt)}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item>
                      {viewCatBuffer.updatedAt && (
                        <Typography
                          variant="body2"
                          sx={{ color: "chocolate" }}
                          align="center"
                        >
                          {translate2("Updated")}:{" "}
                          {formatDistance(
                            new Date(viewCatBuffer.updatedAt),
                            new Date(),
                            {
                              addSuffix: true,
                            }
                          )}
                          <Tooltip
                            arrow
                            placement="top"
                            title={getExactTime(viewCatBuffer.updatedAt)}
                          >
                            <IconButton
                              size="small"
                              onClick={() =>
                                handleShowDate(viewCatBuffer.updatedAt)
                              }
                            >
                              <InfoIcon color="info" />
                            </IconButton>
                          </Tooltip>
                          <br />
                          {showDate.includes(viewCatBuffer.updatedAt) &&
                            getExactTime(viewCatBuffer.updatedAt)}
                        </Typography>
                      )}
                    </Grid>
                    {viewCatBuffer.subCatList.map((x, i) => (
                      <Fragment key={i}>
                        <Grid item xs={12}>
                          <IconButton
                            size="small"
                            sx={{ pointerEvents: "none" }}
                          >
                            <ArrowDownwardIcon />
                          </IconButton>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body2" color="initial">
                            {x.subCatName}
                            {lang !== "en" && " - "}
                            {lang !== "en" &&
                              getSubCatNameEn(x.subCatDetectorId)}
                          </Typography>
                        </Grid>
                      </Fragment>
                    ))}
                  </Grid>
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseViewCatDlg} color="error">
                {translate2("Close")}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}
