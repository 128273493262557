import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector } from "react-redux";

import { ExpandMore } from "@mui/icons-material";
import AboutUsVideo from "../misc/AboutUsVideo";

export default function Focus() {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  const [viewMoreAbout, setViewMoreAbout] = useState(false);
  const [viewAboutVid, setViewAboutVid] = useState(false);
  const [contentSettings, setContentSettings] = useState([]);
  const [langId, setLangId] = useState("");

  // Store
  const { webSettings } = useSelector((state) => state.webSettings);
  const { managedContentSettings } = useSelector(
    (state) => state.managedContentSettings
  );
  const { activeLangFront } = useSelector((state) => state.miscFront);

  // Set Language
  useEffect(() => {
    if (activeLangFront) setLangId(activeLangFront._id);
  }, [activeLangFront]);

  // Set Settings
  useEffect(() => {
    if (
      managedContentSettings &&
      managedContentSettings.length > 0 &&
      langId !== ""
    ) {
      const M = managedContentSettings.find((m) => m.langId === langId);
      if (M) setContentSettings(M);
    }
  }, [langId, managedContentSettings]);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate
  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12}>
          <Card sx={{ m: 1 }}>
            <CardContent>
              <Typography
                variant="h4"
                align="center"
                sx={{
                  // mx: { xs: 2, sm: 20 },
                  // my: 2,
                  background: webSettings.siteColorTheme || "lightblue",
                  // borderRadius: "2.5rem",
                  fontSize: { xs: "1.8rem", sm: "2rem" },
                  mb: 1,
                }}
              >
                {translateFront("About Us")}
              </Typography>

              <Typography
                variant="body2"
                color="text-secondary"
                sx={{ fontSize: { xs: ".8rem", sm: "1.6rem" } }}
                align="center"
              >
                {contentSettings.aboutUs &&
                  contentSettings.aboutUs.length > 0 &&
                  contentSettings.aboutUs[0].tabDetails}
              </Typography>

              <Grid container spacing={1} justifyContent="center">
                <Grid item>
                  <Button
                    variant="contained"
                    size="small"
                    color="info"
                    fullWidth={!smUp ? true : false}
                    sx={{
                      mt: 1,
                    }}
                    onClick={() => setViewMoreAbout(true)}
                  >
                    {translateFront("Learn More")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    size="small"
                    color="error"
                    fullWidth={!smUp ? true : false}
                    sx={{
                      mt: 1,
                      // background: webSettings.siteColorTheme || "lightblue",
                      // color: "black",
                    }}
                    onClick={() => setViewAboutVid(true)}
                  >
                    {translateFront("Watch Overview")}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {viewMoreAbout && (
        <Dialog
          open={viewMoreAbout}
          onClose={() => setViewMoreAbout(false)}
          aria-labelledby={"view-more-about"}
        >
          <DialogTitle>{translateFront("About Us")}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {contentSettings &&
                contentSettings.aboutUs &&
                contentSettings.aboutUs.length > 0 &&
                contentSettings.aboutUs.map((x, i) => (
                  <Accordion key={i}>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-label={`${i}Expand`}
                      aria-controls={`${i}-content`}
                      id={`${i}-header`}
                    >
                      <Typography>{x.tabTitle}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>{x.tabDetails}</AccordionDetails>
                  </Accordion>
                ))}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setViewMoreAbout(false)} color="error">
              {translateFront("Close")}
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {viewAboutVid && (
        <Dialog
          open={viewAboutVid}
          onClose={() => setViewAboutVid(false)}
          aria-labelledby={"about-us-video"}
        >
          <DialogContent>
            <AboutUsVideo contentSettings={contentSettings} />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setViewAboutVid(false)} color="error">
              {translateFront("Close")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
