import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Container,
  Divider,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { getCreated } from "../../hooks/getCreatedData";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import LoadingBox from "../misc/Loading";
import { useSelector } from "react-redux";

export default function MaintenanceMode({ maintenanceExpireTime }) {
  // Responsive
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  const [loading, setLoading] = useState(false);
  const [timeString, setTimeString] = useState("");
  const [countDownTime, setCountDownTime] = useState(
    new Date().getTime() + 14500 * 60000
  );

  useEffect(() => {
    setLoading(true);
    if (Number(maintenanceExpireTime) > 0)
      setCountDownTime(Number(maintenanceExpireTime));
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  const interval = setInterval(() => {
    const currentTime = new Date().getTime();

    let distance = countDownTime - currentTime;

    // // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // Display the result in the element with id="demo"
    setTimeString(
      `${days !== 0 ? days + "d " : ""} ${hours !== 0 ? hours + "h " : ""}  ${
        minutes + "m "
      } ${seconds + "s "}`
    );
    if (distance < 0) {
      clearInterval(interval);
    }
  }, 1000);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
        // backgroundImage:
        //   "url('https://media.capc.org/images/AdobeStock_274131656.original.original.jpg')",
        // backgroundRepeat: "no-repeat",
        p: 1,
      }}
    >
      <Card>
        <CardContent>
          <Typography
            variant="h1"
            color="inherit"
            align="center"
            sx={{
              fontSize: !smUp ? "1.2rem" : !mdUp ? "1.8rem" : "5rem",
              fontWeight: 900,
            }}
          >
            Site Is Down For Maintenance
          </Typography>
          <Divider sx={{ my: 1 }} />
          {Number(maintenanceExpireTime) > 0 ? (
            <>
              <Typography
                variant="h1"
                color="inherit"
                align="center"
                sx={{
                  fontSize: !smUp ? "1rem" : !mdUp ? "1.2rem" : "3rem",
                  fontWeight: 600,
                }}
              >
                Please Come Back After
              </Typography>
              {loading ? (
                <LoadingBox w={"100%"} h={"100px"} />
              ) : (
                <Typography
                  variant="body2"
                  color="error"
                  align="center"
                  sx={{ fontSize: !smUp ? "2rem" : !mdUp ? "2.5rem" : "5rem" }}
                >
                  {timeString}
                </Typography>
              )}
              <Typography
                variant="body2"
                color="text.secondary"
                align="center"
                sx={{ fontSize: !smUp ? ".8rem" : !mdUp ? "1rem" : "2rem" }}
              >
                {getCreated(countDownTime)}
              </Typography>
            </>
          ) : (
            <>
              <Typography
                variant="h1"
                color="error"
                align="center"
                sx={{
                  fontSize: !smUp ? "1rem" : !mdUp ? "1.2rem" : "3rem",
                  fontWeight: 600,
                }}
              >
                We Will Be Back Soon!
              </Typography>
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  );
}
