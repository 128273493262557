import {
  FormControlLabel,
  Switch,
  Grid,
  CircularProgress,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Tooltip,
  IconButton,
  Box,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSettings,
  saveSiteSettings,
} from "../../../reduxToolkit/features/adminPanel/webSettings";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker, MobileDatePicker } from "@mui/x-date-pickers";

// Icons
import ScheduleIcon from "@mui/icons-material/Schedule";

export default function MaintenanceMode() {
  const dispatch = useDispatch();
  // Store
  const { webSettings } = useSelector((state) => state.webSettings);
  // LocalData
  const localData = JSON.parse(localStorage.getItem("adminLoginInfo"));

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [checked, setChecked] = React.useState(false);
  const [maintenanceMinute, setMaintenanceMinute] = useState("0");

  const [scheduleDlg, setScheduleDlg] = useState(false);
  const [expiration, setExpiration] = useState(null);

  const handleChange = (event) => {
    setLoading(true);
    setChecked(event.target.checked);
    let obj = {
      maintenanceMode: event.target.checked,
      maintenanceMinute,
    };
    if (event.target.checked === false) {
      obj.maintenanceMinute = 0;
      setMaintenanceMinute("0");
    }
    if (localData._id) obj.creatorId = localData._id;
    let timer = 0;
    const Int = setInterval(() => {
      timer += 10;
      if (timer <= 100) {
      } else {
        clearInterval(Int);
        dispatch(saveSiteSettings(obj));
        timer = 0;
        setTimeout(() => {
          dispatch(getSettings());
          setLoading(false);
          setExpiration(null);
        }, 1000);
      }
      setProgress(timer);
    }, 800);
  };

  useEffect(() => {
    if (webSettings) {
      if (webSettings.maintenanceMode) setChecked(true);
      else setChecked(false);
      if (webSettings.maintenanceMinute)
        setMaintenanceMinute(webSettings.maintenanceMinute);
      else setMaintenanceMinute(0);
    }
  }, [webSettings]);

  useEffect(() => {
    if (expiration !== null) {
      let expirationTime = expiration.getTime() - new Date().getTime();
      setMaintenanceMinute(Math.round(expirationTime / 60000));
    }
  }, [expiration]);

  //! Translate
  // Store
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate
  return (
    <>
      <Grid container justifyContent="center">
        <Grid
          item
          sx={{
            border: "1px solid lightblue",
            borderRadius: ".25rem",
            p: 1,
            my: 1,
          }}
          xs={12}
          md={6}
        >
          <Grid container spacing={0} alignItems="center">
            <Grid item sx={{ flex: 1 }}>
              <TextField
                label={translate0("Maintenance Time (Minute)")}
                value={maintenanceMinute}
                onChange={(e) =>
                  setMaintenanceMinute(e.target.value.replace(/\D/g, ""))
                }
                variant="standard"
                helperText={translate0("keep at 0 to view no time")}
                fullWidth
                disabled={checked || loading}
              />
            </Grid>
            <Grid item>
              <Tooltip arrow placeholder="right" title={translate0("Schedule")}>
                <IconButton
                  size="small"
                  onClick={() => setScheduleDlg(true)}
                  disabled={checked || loading}
                >
                  <ScheduleIcon
                    color={checked || loading ? "inherit" : "secondary"}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                    disabled={loading}
                  />
                }
                label={progress === 0 ? "" : progress + "%"}
              />
            </Grid>
            <Grid item>
              {loading && (
                <CircularProgress variant="determinate" value={progress} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {scheduleDlg && (
        <Dialog open={scheduleDlg} onClose={() => setScheduleDlg(false)}>
          <DialogTitle>{translate0("Set Expire Date & Time")}</DialogTitle>
          <DialogContent>
            <Box sx={{ mt: 1 }} />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                renderInput={(props) => <TextField {...props} />}
                label="Date Time Picker"
                value={expiration}
                onChange={(newValue) => {
                  setExpiration(newValue);
                }}
              />
            </LocalizationProvider>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
