import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMore from "@mui/icons-material/ExpandMore";
import TextField from "@mui/material/TextField";
import InfoTut from "../../../components/InfoTut";
import { Box, Button, Grid, IconButton, Input } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getSettings,
  saveSiteSettings,
  uploadLogo,
} from "../../../reduxToolkit/features/adminPanel/webSettings";
import { getNotification } from "../../../reduxToolkit/features/adminPanel/notification";

// Icons
import PhotoCamera from "@mui/icons-material/PhotoCamera";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { PF } from "../../../config";
import SiteSEO from "./SiteSEO";

export default function SiteIdentity({ expanded, handleChange, translate0 }) {
  const dispatch = useDispatch();
  const [siteName, setSiteName] = useState("");
  const [siteLogo, setSiteLogo] = useState("");
  // const [siteDesc, setSiteDesc] = useState("");
  // const [siteCompanyName, setSiteCompanyName] = useState("");
  const [prevSiteLogo, setPrevSiteLogo] = useState("");

  // LocalData
  const localData = JSON.parse(localStorage.getItem("adminLoginInfo"));

  // Store
  const { webSettings } = useSelector((state) => state.webSettings);

  const handleSaveSiteSettings = () => {
    let obj = {
      siteName,
      creatorId: localData._id,
      // siteDesc,
      // siteCompanyName,
    };

    if (siteLogo && siteLogo.name) {
      const data = new FormData();
      const ext = siteLogo.name.split(".");
      const filename =
        Date.now() +
        "-" +
        "MedicineForWorld".replace(/\s+/g, "") +
        "-site-logo." +
        ext.slice(-1);
      data.append("name", filename);
      data.append("logoImg", siteLogo);
      obj.siteLogo = filename;
      if (prevSiteLogo) obj.prevSiteLogo = prevSiteLogo;
      dispatch(uploadLogo(data));
    } else {
      if (siteLogo) {
        obj.siteLogo = siteLogo;
      }
    }
    dispatch(saveSiteSettings(obj));
    setTimeout(() => {
      setSiteName("");
      setPrevSiteLogo("");
      // setSiteDesc("");
      // setSiteCompanyName("");
      dispatch(getNotification());
      dispatch(getSettings());
    }, 1000);
  };

  useEffect(() => {
    setSiteName(webSettings.siteTitle || "");
    setPrevSiteLogo(webSettings.siteLogo || "");
    // setSiteDesc(webSettings.siteDesc || "");
    // setSiteCompanyName(webSettings.companyName || "");
  }, [webSettings]);

  return (
    <>
      <Accordion
        expanded={expanded === "site-identity-header"}
        onChange={handleChange("site-identity-header")}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-label="Expand"
          aria-controls="site-identity-content"
          id="site-identity-header"
        >
          <Typography>{translate0("Site identity")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Button variant="outlined" fullWidth size="small" color="primary">
            {translate0("Logo")}
          </Button>
          <Grid
            container
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{ mt: 1 }}
          >
            <Grid item>
              <LazyLoadImage
                effect="blur"
                src={
                  siteLogo && siteLogo.name
                    ? URL.createObjectURL(siteLogo)
                    : siteLogo && siteLogo !== ""
                    ? PF + "/misc/" + siteLogo
                    : prevSiteLogo && prevSiteLogo !== ""
                    ? PF + "/misc/" + prevSiteLogo
                    : PF + "/adminPanel/medicines/no-medicine-image.jpg"
                }
                alt={"MFW"}
                style={{
                  objectFit: "cover",
                  width: "200px",
                }}
              />

              <Typography variant="body2" align="center" color="secondary">
                {translate0("Recommended")}: 🖼️(970x250)
              </Typography>
            </Grid>
            <Grid item position="relative">
              <Box>
                <label htmlFor={`icon-button-file-1`}>
                  <Input
                    accept="image/*"
                    id={`icon-button-file-1`}
                    required
                    type="file"
                    sx={{ display: "none" }}
                    onChange={(e) => {
                      setSiteLogo(e.target.files[0]);
                    }}
                  />
                  <IconButton
                    color="primary"
                    aria-label={`upload-picture-1`}
                    component="span"
                  >
                    <PhotoCamera color="primary" />
                  </IconButton>
                </label>
              </Box>
            </Grid>
            {/* <Grid item>
              {(siteLogo || prevSiteLogo) && (
                <Button
                  variant="contained"
                  size="small"
                  color="error"
                  onClick={() => {
                    setSiteLogo("");
                    setPrevSiteLogo("");
                  }}
                >
                  Remove Image
                </Button>
              )}
            </Grid> */}
          </Grid>
          {/* <SiteSEO /> */}
          <TextField
            label={translate0("Site Name")}
            value={siteName}
            onChange={(e) => setSiteName(e.target.value)}
            size="small"
            fullWidth
            variant="outlined"
            // helperText={<InfoTut img="tut-site-title.png" />}
          />
          {/* 
          <TextField
            label="Meta Desc (Home Page SEO)"
            multiline
            minRows={2}
            value={siteDesc}
            onChange={(e) => setSiteDesc(e.target.value)}
            size="small"
            fullWidth
            variant="standard"
            helperText={<InfoTut img="tut-meta-desc-home.png" />}
          />
          <TextField
            label="Company Name (Blog Author for twitter SEO)"
            value={siteCompanyName}
            onChange={(e) => setSiteCompanyName(e.target.value)}
            size="small"
            fullWidth
            variant="standard"
            helperText={<InfoTut img="tut-twitter-creator.png" />}
          /> */}
          <Button
            sx={{ mt: 1 }}
            variant="contained"
            fullWidth
            size="small"
            color="success"
            onClick={handleSaveSiteSettings}
          >
            {translate0("Save")}
          </Button>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
