import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Icon
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  checkPass,
  getNotificationByCustomer,
  updateCustomerPassFromEdit,
} from "../reduxToolkit/features/miscFront";
import { useEffect } from "react";
import CaptchaTikTakToe from "./misc/CaptchaTikTakToe";

export default function ChangePassPortion() {
  // Ref
  const passRef = useRef();

  //   Use State
  const [captchaDlgCheckPass, setCaptchaDlgCheckPass] = React.useState(false);
  const [captchaDlgUpdatePass, setCaptchaDlgUpdatePass] = React.useState(false);
  const [prevPassword, setPrevPassword] = React.useState("");
  const [showPrevPass, setShowPrevPass] = React.useState(false);
  const [prevPassError, setPrevPassError] = useState(false);
  const [password, setPassword] = React.useState("");
  const [showPass, setShowPass] = React.useState(false);
  const [confirmPass, setConfirmPass] = React.useState("");
  const [showConfirmPass, setShowConfirmPass] = React.useState(false);
  const [passMatch, setPassMatch] = React.useState(false);
  const [changePassPortion, setChangePassPortion] = useState(false);
  const [checkPrevPass, setCheckPrevPass] = useState(false);
  const [checkPrevPassLoading, setCheckPrevPassLoading] = useState(false);

  //   Local Data
  const localData = JSON.parse(localStorage.getItem("loginInfo"));

  //   Store
  const { checkPassResponse } = useSelector((state) => state.miscFront);

  // Use effect
  useEffect(() => {
    if (checkPassResponse.message) {
      if (checkPassResponse.failed) setPrevPassError(true);
      else {
        setCheckPrevPass(true);
        setPrevPassError(false);
        setPrevPassword("");
      }
    } else setCheckPrevPass(false);
  }, [checkPassResponse]);

  // Password Match
  useEffect(() => {
    let errorCounter = 0;
    [...Array(confirmPass.length)].map((x, i) => {
      confirmPass[i] !== password[i] && errorCounter++;
      return 0;
    });
    if (confirmPass !== "" && errorCounter > 0) {
      setPassMatch(false);
    } else setPassMatch(true);
  }, [confirmPass, password]);

  //   Functions
  const dispatch = useDispatch();
  const sendReqToCheckPass = () => {
    setCheckPrevPassLoading(true);
    dispatch(checkPass({ prevPassword, customerId: localData._id }));
    setTimeout(() => {
      setCheckPrevPassLoading(false);
    }, 1000);
  };

  const handleUpdatePass = () => {
    setCheckPrevPass(false);
    dispatch(
      updateCustomerPassFromEdit({
        password,
        email: localData.email,
      })
    );
    setTimeout(() => {
      dispatch(getNotificationByCustomer(localData._id));
      setChangePassPortion(false);
    }, 1000);
  };

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate

  return (
    <>
      <Grid item xs={12}>
        <Button
          variant={changePassPortion ? "outlined" : "contained"}
          fullWidth
          size="small"
          color="secondary"
          onClick={() => setChangePassPortion(!changePassPortion)}
          ref={passRef}
        >
          {translateFront("Change Password")}
        </Button>
      </Grid>
      {changePassPortion && (
        <>
          {!checkPrevPass ? (
            // Check previous password
            <>
              <Slide
                direction="down"
                in={!checkPrevPass}
                mountOnEnter
                unmountOnExit
                container={passRef.current}
              >
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label={translateFront("Old Password")}
                    type={showPrevPass ? "text" : "password"}
                    autoComplete="old-password"
                    value={prevPassword}
                    onChange={(e) => {
                      setPrevPassword(e.target.value);
                      setPrevPassError(false);
                    }}
                    error={
                      prevPassword.length > 0 && prevPassError ? true : false
                    }
                    helperText={
                      prevPassword.length > 0 &&
                      prevPassError && (
                        <Typography variant="body2" color="error">
                          {checkPassResponse.message}
                        </Typography>
                      )
                    }
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            size="small"
                            onClick={() => setShowPrevPass(!showPrevPass)}
                          >
                            {showPrevPass ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Slide>

              <Slide
                direction="down"
                in={!checkPrevPass}
                mountOnEnter
                unmountOnExit
                container={passRef.current}
              >
                <Grid item xs={12}>
                  <LoadingButton
                    variant="contained"
                    color="secondary"
                    size="small"
                    fullWidth
                    onClick={() => setCaptchaDlgCheckPass(true)}
                    disabled={prevPassword.length > 0 ? false : true}
                    loading={checkPrevPassLoading}
                  >
                    {translateFront("Change")}
                  </LoadingButton>
                </Grid>
              </Slide>
            </>
          ) : (
            <>
              <Slide
                direction="down"
                in={checkPrevPass}
                mountOnEnter
                unmountOnExit
                container={passRef.current}
              >
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label={translateFront("New Password")}
                    type={showPass ? "text" : "password"}
                    autoComplete="new-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            size="small"
                            onClick={() => setShowPass(!showPass)}
                          >
                            {showPass ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Slide>

              <Slide
                direction="down"
                in={checkPrevPass}
                mountOnEnter
                unmountOnExit
                container={passRef.current}
              >
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="confirm-password"
                    label={translateFront("Confirm Password")}
                    type={showConfirmPass ? "text" : "password"}
                    autoComplete="confirm-password"
                    size="small"
                    value={confirmPass}
                    onChange={(e) => setConfirmPass(e.target.value)}
                    onBlur={
                      password !== "" &&
                      (password === confirmPass
                        ? () => setPassMatch(true)
                        : () => setPassMatch(false))
                    }
                    error={password !== "" && (passMatch ? false : true)}
                    helperText={
                      password !== "" &&
                      !passMatch &&
                      translateFront("Password Not Matched")
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            size="small"
                            onClick={() => setShowConfirmPass(!showConfirmPass)}
                          >
                            {showConfirmPass ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Slide>
              <Slide
                direction="down"
                in={checkPrevPass}
                mountOnEnter
                unmountOnExit
                container={passRef.current}
              >
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="warning"
                    size="small"
                    fullWidth
                    onClick={() => setCaptchaDlgUpdatePass(true)}
                    disabled={
                      password.length > 0 && password === confirmPass
                        ? false
                        : true
                    }
                  >
                    {translateFront("Update Password")}
                  </Button>
                </Grid>
              </Slide>
            </>
          )}
        </>
      )}
      <Dialog
        open={captchaDlgCheckPass}
        onClose={() => setCaptchaDlgCheckPass(false)}
        aria-labelledby={"Captcha"}
      >
        <DialogContent>
          <CaptchaTikTakToe
            loginFunc={sendReqToCheckPass}
            setCaptchaDlg={setCaptchaDlgCheckPass}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={captchaDlgUpdatePass}
        onClose={() => setCaptchaDlgUpdatePass(false)}
        aria-labelledby={"Captcha"}
      >
        <DialogContent>
          <CaptchaTikTakToe
            loginFunc={handleUpdatePass}
            setCaptchaDlg={setCaptchaDlgUpdatePass}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
