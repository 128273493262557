import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import LoadingBox from "../../../../components/misc/Loading";
import {
  getMedCategories,
  getMedicines,
  getViewCount,
} from "../../../../reduxToolkit/features/adminPanel/medicine";
import Grid from "@mui/material/Grid";
import ProductSmallBackend from "../../../../components/product/ProductSmallBackend";
import { Box } from "@mui/material";

export default function MedicinePopularityReport() {
  const dispatch = useDispatch();

  const [medLoading, setMedLoading] = useState(false);
  const [medList, setMedList] = useState([]);
  const [catList, setCatList] = useState([]);
  const [lang, setLang] = useState("en");

  // Responsive
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  // LocalData
  const localLangData = JSON.parse(localStorage.getItem("lang"));

  // Store
  const { activeLang } = useSelector((state) => state.misc);
  const { medicines, categories, viewCountArr } = useSelector(
    (state) => state.medicine
  );

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setLang(activeLang.langCode);
    } else {
      if (localLangData) setLang(localLangData.langCode);
    }
  }, [activeLang]);

  // Set Medicine
  useEffect(() => {
    setMedLoading(true);
    if (lang !== "" && medicines && medicines.length > 0) {
      let M = medicines.find((m) => m.lang === lang);
      if (M) {
        let arr = [];
        M.data.forEach((x) => {
          let obj = { ...x };
          const C = viewCountArr.find(
            (c) =>
              c.medicineIdEn ===
              (lang !== "en" ? x.medicineDetectorId : x.fixedMedicineId)
          );
          if (C) obj.viewCount = C.viewCount;
          else obj.viewCount = 0;
          arr.push(obj);
        });
        arr = arr.sort((a, b) => (a.viewCount < b.viewCount ? 1 : -1));
        setMedList(arr);
      } else setMedList([]);
    }
    setTimeout(() => {
      setMedLoading(false);
    }, 1000);
  }, [medicines, lang, viewCountArr]);

  // set Category
  useEffect(() => {
    if (lang !== "" && categories && categories.length > 0) {
      let C = categories.find((c) => c.lang === lang);
      if (C) setCatList(C.data);
      else setCatList([]);
    }
  }, [categories, lang]);

  useEffect(() => {
    if (!(medicines && medicines.length > 0)) dispatch(getMedicines());
    if (!(categories && categories.length > 0)) dispatch(getMedCategories());
    dispatch(getViewCount());
  }, []);

  return (
    <>
      {medLoading ? (
        <LoadingBox w={"100%"} h={"600px"} />
      ) : (
        <Box sx={{ height: { xs: "60vh", sm: "60vh" }, overflowY: "scroll" }}>
          <Grid container spacing={1}>
            {medList.map((x, i) => (
              <Grid item key={i} xs={12} sm={6} md={4} xl={3}>
                <ProductSmallBackend
                  x={x}
                  i={i}
                  view={0}
                  catList={catList}
                  lang={lang}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </>
  );
}
