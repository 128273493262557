import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../config";

// Get Geo Location
export const getGeoInfo = createAsyncThunk("misc/getGeo", async (data) => {
  const res = await axiosInstance.get(
    "https://geolocation-db.com/json/4bdf2390-d062-11ec-81c2-0baa51ec38e1"
  );
  await axiosInstance.post(
    `/api/notification/misc/geo/${data.adminTryingId}`,
    res.data
  );
  return res.data;
});

// create Language
export const createLang = createAsyncThunk("misc/createLangs", async (data) => {
  const res = await axiosInstance.post("/api/lang", data);
  return res.data;
});

// Get Language
export const getLangs = createAsyncThunk("misc/getLangs", async () => {
  const res = await axiosInstance.get("/api/lang");
  return res.data;
});

// Get Language Settings
export const getLangSettings = createAsyncThunk(
  "misc/getLangSettings",
  async (data) => {
    const res = await axiosInstance.get(
      `/api/lang/lang-settings/${data.langCode}`
    );
    return res.data;
  }
);

// Delete Language
export const deleteLang = createAsyncThunk("misc/deleteLangs", async (data) => {
  const { _id, ...others } = data;
  const res = await axiosInstance.put(`/api/lang/delete/${_id}`, others);
  return res.data;
});

// manage Lang
export const manageLang = createAsyncThunk("misc/manageLangs", async (data) => {
  const res = await axiosInstance.post("/api/lang/manage-lang", data);
  return res.data;
});

// manage Lang
export const manageLangActive = createAsyncThunk(
  "misc/manageLangActive",
  async (data) => {
    const res = await axiosInstance.post("/api/lang/manage-lang-active", data);
    return res.data;
  }
);

// Update Currency
export const updateCurrency = createAsyncThunk(
  "misc/updateCurrency",
  async () => {
    const res1 = await axiosInstance.get(
      "https://v6.exchangerate-api.com/v6/7841547b1f54395427e41daa/latest/USD"
    );
    const res2 = await axiosInstance.post("/api/currency/update", res1.data);
    return res2.data;
  }
);

export const getCurrency = createAsyncThunk("misc/getCurrency", async () => {
  const res = await axiosInstance.get("/api/currency/");
  if (res.data) return res.data;
});

export const editManualCurrencyRatio = createAsyncThunk(
  "misc/editManualCurrencyRatio",
  async (data) => {
    const res = await axiosInstance.post("/api/currency/edit-ratio", data);
    if (res.data) return res.data;
  }
);

// upload temp Image
export const uploadTempPic = createAsyncThunk(
  "misc/uploadTempPic",
  async (data) => {
    const res = await axiosInstance.post("/api/multer/temp", data);
    return res.data;
  }
);

// remove temp Image
export const removeTempPic = createAsyncThunk(
  "misc/removeTempPic",
  async () => {
    const res = await axiosInstance.post("/api/medicine/removeTemp");
    return res.data;
  }
);

// Upload Generic File
export const uploadGenericFile = createAsyncThunk(
  "misc/uploadGenericFiles",
  async (data) => {
    const res = await axiosInstance.post("/api/multer/files", data);
    return res.data;
  }
);

// Block Customer
export const blockCustomer = createAsyncThunk(
  "auth/blockCustomer",
  async (data) => {
    const { id, ...others } = data;
    const res = await axiosInstance.put(
      `/api/customerAuth/block/${id}`,
      others
    );
    return res.data;
  }
);

// ? Manage Scopes
// Create Complain Scopes
export const createComplainScopes = createAsyncThunk(
  "customer-on-product/createComplainScopes",
  async (data) => {
    const res = await axiosInstance.post(
      "/api/review-settings/create-scope",
      data
    );
    return res.data;
  }
);

// Remove Complain Scopes
export const removeComplainScopes = createAsyncThunk(
  "customer-on-product/removeComplainScopes",
  async (data) => {
    const { id, ...others } = data;
    const res = await axiosInstance.post(
      `/api/review-settings/remove-scope/${id}`,
      others
    );
    return res.data;
  }
);

// Update Complain Scopes
export const updateComplainScopes = createAsyncThunk(
  "customer-on-product/updateComplainScopes",
  async (data) => {
    const { id, ...others } = data;
    const res = await axiosInstance.post(
      `/api/review-settings/update-scope/${id}`,
      others
    );
    return res.data;
  }
);

// Manage Review
export const approveReview = createAsyncThunk(
  "customer-on-product/approveReview",
  async (data) => {
    const res = await axiosInstance.post(
      "/api/customer-on-product/approve-review/",
      data
    );
    return res.data;
  }
);

export const disApproveReview = createAsyncThunk(
  "customer-on-product/disApproveReview",
  async (data) => {
    const res = await axiosInstance.post(
      "/api/customer-on-product/disapprove-review/",
      data
    );
    return res.data;
  }
);

export const deleteReview = createAsyncThunk(
  "customer-on-product/deleteReview",
  async (data) => {
    const res = await axiosInstance.post(
      `/api/customer-on-product/delete-review/`,
      data
    );
    return res.data;
  }
);

export const updateReview = createAsyncThunk(
  "customer-on-product/updateReview",
  async (data) => {
    const res = await axiosInstance.post(
      `/api/customer-on-product/update-review/`,
      data
    );
    return res.data;
  }
);

// remove Customer
export const removeCustomer = createAsyncThunk(
  "misc/removeCustomer",
  async (data) => {
    const res = await axiosInstance.post(
      "/api/customerAuth/remove-customer",
      data
    );
    return res.data;
  }
);

// backup uploads
export const backupUploads = createAsyncThunk(
  "misc/backupUploads",
  async () => {
    const min = 10000;
    const max = 99999;
    const random = Math.floor(Math.random() * (max - min)) + min;
    const res = await axiosInstance.post(`/api/misc/backup-uploads/${random}`);
    return res.data;
  }
);

// get backup uploads
export const getBackupUploads = createAsyncThunk(
  "misc/getBackupUploads",
  async () => {
    const res = await axiosInstance.get(`/api/misc/get-backup-uploads`);
    return res.data;
  }
);

// delete backup uploads
export const deleteBackupUploads = createAsyncThunk(
  "misc/deleteBackupUploads",
  async (data) => {
    const res = await axiosInstance.post(
      `/api/misc/delete-backup-uploads`,
      data
    );
    return res.data;
  }
);

// initial State
const initialState = {
  pending: false,
  geoResponse: {},
  createLangResponse: {},
  langs: {},
  langSettings: [],
  langArrFromClientSide: [],
  activeLang: {},
  snackNotiMisc: {},
  complainScopes: [],
  currencyList: [],
  // genericFileResponse: {},
  backupResponse: false,
  backupUploadsList: [],
  error: false,
  leafletObj: {},
};

// Slice
export const miscSlice = createSlice({
  name: "misc",
  initialState,
  reducers: {
    changeLang(state, action) {
      state.activeLang = action.payload;
    },
    resetSnkMisc(state) {
      state.snackNotiMisc = {};
    },
    createNoti(state, action) {
      state.snackNotiMisc = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    resetGenericFileResponse(state) {
      state.genericFileResponse = {};
    },
    setAdminLangFromClient(state, action) {
      state.langArrFromClientSide = action.payload;
    },
    setLeafletObj(state, action) {
      state.leafletObj = action.payload;
    },
  },
  extraReducers: {
    [getGeoInfo.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getGeoInfo.fulfilled]: (state, action) => {
      state.pending = false;
      state.geoResponse = action.payload;
    },
    [getGeoInfo.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [manageLang.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [manageLang.fulfilled]: (state, action) => {
      state.pending = false;
      // state.createLangResponse = action.payload;
      state.snackNotiMisc = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [manageLang.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [manageLangActive.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [manageLangActive.fulfilled]: (state, action) => {
      state.pending = false;
      // state.createLangResponse = action.payload;
      state.snackNotiMisc = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [manageLangActive.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [createLang.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [createLang.fulfilled]: (state, action) => {
      state.pending = false;
      state.createLangResponse = action.payload;
      state.snackNotiMisc = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [createLang.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [deleteLang.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [deleteLang.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiMisc = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [deleteLang.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [getLangs.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getLangs.fulfilled]: (state, action) => {
      state.pending = false;
      state.langs = action.payload;
    },
    [getLangs.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [getLangSettings.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getLangSettings.fulfilled]: (state, action) => {
      state.pending = false;
      state.langSettings = action.payload;
    },
    [getLangSettings.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [updateCurrency.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [updateCurrency.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiMisc = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [updateCurrency.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [editManualCurrencyRatio.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [editManualCurrencyRatio.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiMisc = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [editManualCurrencyRatio.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [getCurrency.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getCurrency.fulfilled]: (state, action) => {
      state.pending = false;
      state.currencyList = action.payload;
    },
    [getCurrency.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [uploadTempPic.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [uploadTempPic.fulfilled]: (state, action) => {
      state.pending = false;
    },
    [uploadTempPic.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [removeTempPic.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [removeTempPic.fulfilled]: (state, action) => {
      state.pending = false;
    },
    [removeTempPic.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [uploadGenericFile.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [uploadGenericFile.fulfilled]: (state, action) => {
      state.pending = false;
      // state.genericFileResponse = action.payload;
    },
    [uploadGenericFile.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [blockCustomer.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [blockCustomer.fulfilled]: (state, action) => {
      state.pending = false;
      if (action.payload.message) {
        state.snackNotiMisc.message = action.payload.message;
        state.snackNotiMisc.severity = action.payload.severity;
      }
    },
    [blockCustomer.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [createComplainScopes.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [createComplainScopes.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiMisc = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [createComplainScopes.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [removeComplainScopes.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [removeComplainScopes.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiMisc = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [removeComplainScopes.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [updateComplainScopes.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [updateComplainScopes.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiMisc = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [updateComplainScopes.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    // Review
    [approveReview.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [approveReview.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiMisc = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [approveReview.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [disApproveReview.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [disApproveReview.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiMisc = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [disApproveReview.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [deleteReview.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [deleteReview.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiMisc = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [deleteReview.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [updateReview.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [updateReview.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiMisc = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [updateReview.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [removeCustomer.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [removeCustomer.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiMisc = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [removeCustomer.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [backupUploads.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [backupUploads.fulfilled]: (state, action) => {
      state.pending = false;
      state.backupResponse = action.payload.backupResponse;
      state.snackNotiMisc = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [backupUploads.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [getBackupUploads.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getBackupUploads.fulfilled]: (state, action) => {
      state.pending = false;
      state.backupUploadsList = action.payload;
    },
    [getBackupUploads.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [deleteBackupUploads.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [deleteBackupUploads.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiMisc = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [deleteBackupUploads.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
  },
});

export const {
  changeLang,
  resetSnkMisc,
  createNoti,
  resetGenericFileResponse,
  setAdminLangFromClient,
  setLeafletObj,
} = miscSlice.actions;
export default miscSlice.reducer;
