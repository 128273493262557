export const additionalPages = [
  {
    sectionName: "Indication & Dosage",
    content: "",
  },
  {
    sectionName: "Side Effects",
    content: "",
  },
  {
    sectionName: "Prescribing Information",
    content: "",
  },
  {
    sectionName: "Additional Information",
    content: "",
  },
  {
    sectionName: "FAQ",
    content: "",
  },
  {
    sectionName: "Suggestions",
    content: "",
  },
];
