import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { CardMedia } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector } from "react-redux";
import { PF } from "../../config";

export default function ShortVideo() {
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  // Store
  const { activeLangFront } = useSelector((state) => state.miscFront);
  const { managedContentSettings } = useSelector(
    (state) => state.managedContentSettings
  );

  const [langId, setLangId] = useState("");
  const [video, setVideo] = useState("");
  const [contentSettings, setContentSettings] = useState([]);

  // Set Language
  useEffect(() => {
    if (activeLangFront) {
      setLangId(activeLangFront._id);
    }
  }, [activeLangFront]);

  // Set Settings
  useEffect(() => {
    if (
      managedContentSettings &&
      managedContentSettings.length > 0 &&
      langId !== ""
    ) {
      const M = managedContentSettings.find((m) => m.langId === langId);
      if (M) setContentSettings(M);
    }
  }, [langId, managedContentSettings]);

  useEffect(() => {
    if (
      contentSettings &&
      contentSettings.howToOrderVideos &&
      contentSettings.howToOrderVideos.length > 0
    ) {
      const V = contentSettings.howToOrderVideos.find((v) => v.default);
      if (V) {
        setVideo(V);
      }
      setVideo(contentSettings.howToOrderVideos[0]);
    }
  }, [contentSettings]);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate
  return (
    <>
      <Typography
        variant="h6"
        align="center"
        sx={{ borderBottom: "2px solid black", mt: 1 }}
      >
        {translateFront("How To Order")}
      </Typography>
      <CardMedia
        component="video"
        // height={mdUp && 240}
        image={PF + "misc/" + video.src}
        sx={{ pt: 1 }}
        // autoPlay
        muted
        controls
        loop
      />
    </>
  );
}
