import {
  Alert,
  Autocomplete,
  Box,
  Button,
  createFilterOptions,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddLangDialog from "../dialog/AddLangDialog";
// Icon
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import InfoIcon from "@mui/icons-material/Info";

import {
  deleteSeoTags,
  getSeoTags,
} from "../../../../reduxToolkit/features/adminPanel/medicine";
import { getNotification } from "../../../../reduxToolkit/features/adminPanel/notification";
import AddSeoTags from "../dialog/AddSeoTags";
import EditSeoTags from "../dialog/EditSeoTags";
import { formatDistance } from "date-fns";
import { getAdmins } from "../../../../reduxToolkit/features/adminPanel/admin";
import LoadingBox from "../../../../components/misc/Loading";
import { getExactTime } from "../../../../hooks/getCreatedData";

// Limit options to show in autocomplete
const OPTIONS_LIMIT = 3;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) => {
  return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
};

export default function ManageMedicineVariations() {
  // use States
  const [loadingMain, setLoadingMain] = useState(false);
  const [lang, setLang] = useState("");
  const [tagList, setTagList] = useState([]);
  const [tagListEn, setTagListEn] = useState([]);
  const [searchingTagList, setSearchingTagList] = useState([]);
  const [searchTagList, setSearchTagList] = useState([]);
  const [editBuffer, SetEditBuffer] = useState({});

  // Dialog
  const [addTagDlg, setAddTagDlg] = useState(false);
  const [editTagDlg, setEditTagDlg] = useState(false);
  const [addLangDlg, setAddLangDlg] = useState(false);

  // LocalData
  const localAdminData = JSON.parse(localStorage.getItem("adminLoginInfo"));
  const localLangData = JSON.parse(localStorage.getItem("lang"));
  // store
  const { langs, activeLang } = useSelector((state) => state.misc);
  const { seoTags } = useSelector((state) => state.medicine);
  const { admins } = useSelector((state) => state.admin);

  // UseEffect
  // get Seo tags
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSeoTags());
    dispatch(getAdmins());
  }, []);

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setLang(activeLang.langCode);
    } else localLangData && setLang(localLangData.langCode);
  }, [activeLang]);

  // set SeoTagData
  useEffect(() => {
    setLoadingMain(true);
    if (lang !== "" && seoTags && seoTags.length > 0 && admins.length > 0) {
      let M = seoTags.find((m) => m.lang === lang);
      let MEn = seoTags.find((m) => m.lang === "en");
      let searchArr = [];
      if (M) {
        const A = M.data.map((x, i) => {
          let { adminName } = admins.find((a) => a._id === x.creatorId);
          let newX = { ...x };
          if (adminName) newX["adminName"] = adminName;
          newX["searchName"] = x.tagName;
          return newX;
        });
        A.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setTagList(A);
        searchArr = [...searchArr, ...A];
        let searchEngVariants = [];
        if (MEn && MEn.data && lang !== "en") {
          MEn.data.map((x, i) => {
            const T = A.find((t) => t.tagDetectorId === x.fixedId);
            if (T) {
              const { searchName, ...others } = T;
              searchEngVariants.push({ searchName: x.tagName, ...others });
            }
            return 0;
          });
        }
        searchArr = [...searchArr, ...searchEngVariants];
        setSearchTagList(searchArr);
      } else {
        setTagList([]);
        setSearchTagList([]);
      }
      if (MEn && MEn.data) {
        setTagListEn(MEn.data);
      } else {
        setTagListEn([]);
      }
    }
    setTimeout(() => {
      setLoadingMain(false);
    }, 1000);
  }, [admins, lang, seoTags]);

  // Functions
  const handleChangeLang = (e) => {
    setLang(e.target.value);
    setSearchingTagList([]);
  };

  // handle Tag Delete
  const handleTagDelete = (id) => {
    dispatch(deleteSeoTags({ id, creatorId: localAdminData._id, lang }));
    setTimeout(() => {
      dispatch(getSeoTags());
      dispatch(getNotification());
    }, 1000);
  };

  // handle Tag edit
  const handleTagEdit = (data) => {
    setEditTagDlg(true);
    SetEditBuffer(data);
  };

  // handle Search
  const handleSearch = (e, newValue) => {
    let arr = [...newValue];
    const unique = [
      ...new Map(arr.map((item) => [item["_id"], item])).values(),
    ];
    setSearchingTagList([...unique]);
  };

  // getTagName En
  const getTagNameEn = (id) => {
    const T = tagListEn.find((t) => t.fixedId === id);
    if (T) return T.tagName;
  };

  // Fixed date
  const [showDate, setShowDate] = useState([]);

  const handleShowDate = (date) => {
    let arr = [...showDate];
    if (arr.includes(date)) {
      arr = arr.filter((a) => a !== date);
    } else {
      arr.push(date);
    }
    setShowDate(arr);
  };

  // ! translate
  const [siteLang, setSiteLang] = useState("");
  // Store
  const [langSettings1, setLangSettings1] = useState([]);
  const [langSettings2, setLangSettings2] = useState([]);
  const title1 = "Manage SEO Tags";
  const title2 = "Misc Words";

  useEffect(() => {
    if (langs && langs.length > 0 && siteLang !== "") {
      const L = langs.find((l) => l.langCode === siteLang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      const A = arr.find((l) => l._title === title1);
      if (A) setLangSettings1(A.data);
      const B = arr.find((l) => l._title === title2);
      if (B) setLangSettings2(B.data);
    }
  }, [siteLang, langs]);

  const translate1 = (String) => translation(String, title1, 1);
  const translate2 = (String) => translation(String, title2, 2);

  const translation = (String, title, index) => {
    let translation = "";
    let settings = [];
    if (index === 1) {
      settings = langSettings1;
    } else if (index === 2) {
      settings = langSettings2;
    }
    const T = settings.find((ls) => ls.title === String);
    if (T && T.value) translation = T.value;
    else {
      let arr = [];
      if (langs && langs.length > 0 && siteLang !== "") {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.settings && LEn.settings.length > 0) arr = LEn.settings;
        const S = arr.find((l) => l._title === title);
        if (S) {
          const T = S.data.find((ls) => ls.title === String);
          if (T && T.value) translation = T.value;
        }
      }
    }
    return translation;
  };

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setSiteLang(activeLang.langCode);
    } else {
      if (localLangData) {
        setSiteLang(localLangData.langCode);
      }
    }
  }, [activeLang]);
  // ! translate

  return (
    <>
      {langs.length > 0 && (
        <>
          <Grid>
            <Grid
              container
              spacing={1}
              justifyContent="space-between"
              alignItems="center"
            >
              {/* Select Language */}
              <Grid item xs={12} md={4}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={2} sm={1} md={1.5}>
                    <IconButton>
                      <AddCircleOutlineOutlinedIcon
                        color="info"
                        size="large"
                        onClick={() => setAddLangDlg(true)}
                      />
                    </IconButton>
                    <AddLangDialog
                      addLangDlg={addLangDlg}
                      setAddLangDlg={setAddLangDlg}
                      setLang={setLang}
                    />
                  </Grid>
                  <Grid item xs={10} sm={4} md={5}>
                    <FormControl fullWidth>
                      <Select
                        value={lang}
                        label="language"
                        variant="standard"
                        onChange={handleChangeLang}
                        size="small"
                      >
                        {langs.map((x, i) => (
                          <MenuItem key={i} value={x.langCode} dense>
                            <img
                              alt={x.langCode}
                              src={`https://flagcdn.com/w20/${
                                x.langCode === "en" ? "gb" : x.langCode
                              }.png`}
                            />
                            <Button color="inherit" size="small">
                              {x.langName}
                            </Button>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* Add Tags */}
                  <Grid item xs={12} sm={7} md={4.5}>
                    <Button
                      variant="contained"
                      size="small"
                      color="error"
                      fullWidth
                      onClick={() => setAddTagDlg(true)}
                    >
                      {translate1("Add Tags")}
                    </Button>
                  </Grid>
                  {/* Add Tag Dialog */}
                  <AddSeoTags
                    lang={lang}
                    addTagDlg={addTagDlg}
                    setAddTagDlg={setAddTagDlg}
                    tagListEn={tagListEn}
                  />
                  <EditSeoTags
                    lang={lang}
                    editTagDlg={editTagDlg}
                    setEditTagDlg={setEditTagDlg}
                    editBuffer={editBuffer}
                    tagListEn={tagListEn}
                  />
                </Grid>
              </Grid>
              {/* Search */}
              <Grid item xs={12} md={8} sx={{ mt: -2 }}>
                <Autocomplete
                  multiple
                  onChange={(e, newValue) => handleSearch(e, newValue)}
                  options={searchTagList}
                  filterOptions={filterOptions}
                  getOptionLabel={(option) => option.searchName}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.searchName}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={translate1("Search") + "..."}
                      variant="standard"
                      size="small"
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </Grid>
            {/* Medicine List */}
            <Button
              size="small"
              variant="contained"
              fullWidth
              sx={{ my: 1, pointerEvents: "none" }}
            >
              {translate1("Tags")} ({tagList.length})
            </Button>
          </Grid>
          {loadingMain ? (
            <LoadingBox w={"100%"} />
          ) : tagList.length < 1 ? (
            <Alert
              severity="warning"
              sx={{ display: "flex", justifyContent: "center" }}
            >
              {translate2("No Data Available")}
            </Alert>
          ) : (
            <>
              <Box
                sx={{
                  maxHeight: { xs: "60vh", sm: "57vh" },
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                <Grid container spacing={1} justifyContent="center">
                  {(searchingTagList.length > 0
                    ? searchingTagList
                    : tagList
                  ).map((x, i) => (
                    <Grid item key={i} sm={12} lg={3} sx={{ width: "100%" }}>
                      <Box
                        sx={{
                          border: "1px solid lightblue",
                          borderRadius: ".25rem",
                          p: 1,
                        }}
                      >
                        <Button
                          variant="outlined"
                          color={x.severity}
                          size="small"
                          sx={{ my: 1, pointerEvents: "none" }}
                          fullWidth
                        >
                          {x.tagName}
                          {lang !== "en" && " - "}
                          {lang !== "en" && getTagNameEn(x.tagDetectorId)}
                        </Button>
                        <Typography variant="body2">
                          {translate2("Created By")}: {x.adminName}
                        </Typography>
                        {x.createdAt && (
                          <Typography variant="body2">
                            {translate2("Created")}:{" "}
                            {x.createdAt &&
                              formatDistance(
                                new Date(x.createdAt),
                                new Date(),
                                {
                                  addSuffix: true,
                                }
                              )}
                            <Tooltip
                              arrow
                              placement="top"
                              title={getExactTime(x.createdAt)}
                            >
                              <IconButton
                                size="small"
                                onClick={() => handleShowDate(x.createdAt)}
                              >
                                <InfoIcon color="info" />
                              </IconButton>
                            </Tooltip>
                            <br />
                            {showDate.includes(x.createdAt) &&
                              getExactTime(x.createdAt)}
                          </Typography>
                        )}
                        {x.updatedAt && (
                          <Typography variant="body2">
                            {translate2("Updated")}:{" "}
                            {formatDistance(new Date(x.updatedAt), new Date(), {
                              addSuffix: true,
                            })}
                            <Tooltip
                              arrow
                              placement="top"
                              title={getExactTime(x.updatedAt)}
                            >
                              <IconButton
                                size="small"
                                onClick={() => handleShowDate(x.updatedAt)}
                              >
                                <InfoIcon color="info" />
                              </IconButton>
                            </Tooltip>
                            <br />
                            {showDate.includes(x.updatedAt) &&
                              getExactTime(x.updatedAt)}
                          </Typography>
                        )}
                        <Divider sx={{ my: 1 }} />
                        <Typography variant="body2" sx={{ color: "gray" }}>
                          {x.tagDetails}
                        </Typography>

                        <Grid
                          container
                          spacing={0}
                          sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                          <Grid item>
                            <Tooltip
                              title={translate1("Edit")}
                              placement="top"
                              arrow
                            >
                              <IconButton
                                size="small"
                                onClick={() => handleTagEdit(x)}
                              >
                                <EditRoundedIcon color="info" />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item>
                            <Tooltip
                              title={translate1("Delete")}
                              placement="top"
                              arrow
                            >
                              <IconButton
                                size="small"
                                onClick={() => handleTagDelete(x._id)}
                              >
                                <DeleteRoundedIcon color="error" />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
}
