import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Slide,
  Grid,
  Divider,
  Autocomplete,
  TextField,
  createFilterOptions,
  Box,
  Tooltip,
  IconButton,
  AppBar,
  Toolbar,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getDosages,
  getMedicines,
} from "../../../reduxToolkit/features/adminPanel/medicine";

// Media Query
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// Icons
import UpdateIcon from "@mui/icons-material/Update";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";

// Lazy load image
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { PF } from "../../../config";
import LoadingButton from "@mui/lab/LoadingButton";
import getSymbolFromCurrency from "currency-symbol-map";
import {
  getCurrency,
  updateCurrency,
} from "../../../reduxToolkit/features/adminPanel/misc";
import LoadingBox from "../../../components/misc/Loading";
import { currencyFormatter } from "../../../hooks/currencyFormatter";
import { getCreated, getExactTime } from "../../../hooks/getCreatedData";
import ViewProductDetails from "../../adminPanel/medicines/components/ViewProductDetails";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

// Limit options to show in autocomplete
const OPTIONS_LIMIT = 8;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) => {
  return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
};

export default function ViewCartByCustomer({
  showCartDlg,
  setShowCartDlg,
  showCartBuffer,
  setShowCartBuffer,
}) {
  const dispatch = useDispatch();

  // Media Query
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  // Use state
  // Currency
  const [currencySymbol, setCurrencySymbol] = useState("USA");
  const [selectedCurrency, setSelectedCurrency] = useState(1);

  const [loading, setLoading] = useState();

  //   Dialog
  const [viewMedDlg, setViewMedDlg] = useState(false);
  const [viewMedBuffer, setViewMedBuffer] = useState({});

  //   Local Data
  const localLangData = JSON.parse(localStorage.getItem("lang"));

  //   Store
  const { medicines, medVariants, dosages } = useSelector(
    (state) => state.medicine
  );
  const { currencyList } = useSelector((state) => state.misc);

  const getDosageInfo = (dosageId, langCode) => {
    let dosageName = "";
    if (dosages && dosages.length > 0) {
      const dosageList = dosages.find((d) => d.lang === langCode);
      if (dosageList && dosageList.data) {
        const D = dosageList.data.find((d) =>
          langCode !== "en" ? d.dosageDetectorId : d.fixedDosageId === dosageId
        );
        if (D && D.dosageName) dosageName = D.dosageName;
      }
    }
    return dosageName;
  };

  const getMedicineInfo = (medId) => {
    let obj = {};
    if (
      medicines &&
      medicines.length > 0 &&
      localLangData &&
      localLangData.langCode
    ) {
      const M = medicines.find((m) => m.lang === localLangData.langCode);
      if (M && M.data) {
        const med = M.data.find(
          (m) =>
            (localLangData.langCode !== "en"
              ? m.medicineDetectorId
              : m.fixedMedicineId) === medId
        );
        if (med) {
          obj = { ...med };
        }
      }
    }
    return obj;
  };

  const getVariantInfo = (varId) => {
    let str = "";
    if (
      medVariants &&
      medVariants.length > 0 &&
      localLangData &&
      localLangData.langCode
    ) {
      const V = medVariants.find(
        (v) =>
          (localLangData.langCode !== "en"
            ? v.variantDetectorId
            : v.fixedVariantId) === varId
      );
      if (V) {
        str =
          str +
          " ( " +
          getDosageInfo(V.dosageForm, localLangData.langCode) +
          " - " +
          V.strength +
          " - " +
          V.packTypes +
          " ) ";
      }
    }
    return str;
  };

  // Fixed date
  const [showDate, setShowDate] = useState([]);

  const handleShowDate = (date) => {
    let arr = [...showDate];
    if (arr.includes(date)) {
      arr = arr.filter((a) => a !== date);
    } else {
      arr.push(date);
    }
    setShowDate(arr);
  };

  const handleCloseViewCartDlg = () => {
    setShowCartDlg(false);
    setShowCartBuffer([]);
  };

  //   handle View med
  const handleViewMed = (medId, varId, lang) => {
    const obj = {
      medId,
      varId,
      lang,
    };
    setViewMedBuffer(obj);
    setViewMedDlg(true);
  };

  //   close view med
  const handleCloseViewMed = () => {
    setViewMedBuffer({});
    setViewMedDlg(false);
  };

  //   handle Currency Change
  const handleCurrencyChange = (e, newValue) => {
    setSelectedCurrency(newValue.exchangeRate);
    setCurrencySymbol(newValue.currencyName);
  };

  //   update Currency
  const handleUpdateCurrency = () => {
    setLoading(true);
    dispatch(updateCurrency());
    dispatch(getCurrency());
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  // ? UseEffect
  useEffect(() => {
    setLoading(true);
    // if (!langs) dispatch(getLangs);
    if (!(medicines && medicines.length > 0)) dispatch(getMedicines());
    dispatch(getDosages());
    dispatch(getCurrency());
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  //   Set USD as default currency
  useEffect(() => {
    if (currencyList.length > 0) {
      const C = currencyList.find((x) => x.currencyName === "USD");
      setSelectedCurrency(C.exchangeRate);
      setCurrencySymbol(C.currencyName);
    }
  }, [currencyList]);

  // ! translate
  const [lang, setLang] = useState("");
  const [langId, setLangId] = useState("");
  // Store
  const { langs, activeLang } = useSelector((state) => state.misc);
  const [langSettings1, setLangSettings1] = useState([]);
  const [langSettings2, setLangSettings2] = useState([]);
  const [langSettings3, setLangSettings3] = useState([]);
  const title1 = "Cart";
  const title2 = "Misc Words";
  const title3 = "Product Details Card";

  useEffect(() => {
    if (langs && langs.length > 0 && lang !== "") {
      const L = langs.find((l) => l.langCode === lang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      const A = arr.find((l) => l._title === title1);
      if (A) setLangSettings1(A.data);
      const B = arr.find((l) => l._title === title2);
      if (B) setLangSettings2(B.data);
      const C = arr.find((l) => l._title === title3);
      if (C) setLangSettings3(C.data);
    }
  }, [lang, langs]);

  const translate1 = (String) => translation(String, title1, 1);
  const translate2 = (String) => translation(String, title2, 2);
  const translate3 = (String) => translation(String, title3, 3);

  const translation = (String, title, index) => {
    let translation = "";
    let settings = [];
    if (index === 1) {
      settings = langSettings1;
    } else if (index === 2) {
      settings = langSettings2;
    } else if (index === 3) {
      settings = langSettings3;
    }
    const T = settings.find((ls) => ls.title === String);
    if (T && T.value) translation = T.value;
    else {
      let arr = [];
      if (langs && langs.length > 0 && lang !== "") {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.settings && LEn.settings.length > 0) arr = LEn.settings;
        const S = arr.find((l) => l._title === title);
        if (S) {
          const T = S.data.find((ls) => ls.title === String);
          if (T && T.value) translation = T.value;
        }
      }
    }
    return translation;
  };

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setLang(activeLang.langCode);
      setLangId(activeLang._id);
    } else {
      if (localLangData) {
        setLang(localLangData.langCode);
        setLangId(localLangData._id);
      }
    }
  }, [activeLang]);
  // ! translate

  return (
    <>
      <Dialog
        open={showCartDlg}
        onClose={handleCloseViewCartDlg}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        //   maxWidth={"md"}
      >
        <DialogTitle>{translate1("Cart")}</DialogTitle>
        <DialogContent>
          {loading ? (
            <LoadingBox w={"100%"} />
          ) : (
            <>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} sm={12} md={6}>
                  {currencyList.length > 0 && (
                    <Autocomplete
                      size="small"
                      onChange={(e, newValue) =>
                        handleCurrencyChange(e, newValue)
                      }
                      disablePortal
                      options={currencyList}
                      defaultValue={currencyList.find(
                        (c) => c.currencyName === "USD"
                      )}
                      filterOptions={filterOptions}
                      getOptionLabel={(option) =>
                        "(" +
                        getSymbolFromCurrency(option.currencyName) +
                        ") " +
                        option.currencyName +
                        " [" +
                        option.exchangeRate +
                        "]"
                      }
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{
                            "& > img": { mr: 2, flexShrink: 0 },
                          }}
                          {...props}
                        >
                          ({getSymbolFromCurrency(option.currencyName)}){" "}
                          {option.currencyName} [{option.exchangeRate}]
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={translate1("Select Currency")}
                          variant="standard"
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <LoadingButton
                    onClick={handleUpdateCurrency}
                    variant="contained"
                    size="small"
                    fullWidth
                    loading={loading}
                    endIcon={<UpdateIcon />}
                    color="success"
                  >
                    {translate1("Update Currency")}
                  </LoadingButton>
                </Grid>
              </Grid>
              <Divider sx={{ my: 1 }} />
              {showCartBuffer.length > 0 &&
                showCartBuffer.map((x, i) => (
                  <>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={3}>
                        <Box
                          sx={{
                            border: "1px solid navy",
                            borderRadius: ".25rem",
                            display: "flex",
                          }}
                          onClick={() =>
                            handleViewMed(
                              x.medId,
                              x.variantId,
                              localLangData && localLangData.langCode
                                ? localLangData.langCode
                                : "en"
                            )
                          }
                        >
                          <LazyLoadImage
                            src={
                              getMedicineInfo(x.medId).image &&
                              getMedicineInfo(x.medId).image.length > 0
                                ? PF +
                                  "adminPanel/medicines/img/" +
                                  getMedicineInfo(x.medId).image.find(
                                    (i) => i.default
                                  ).src
                                : PF +
                                  "adminPanel/medicines/no-medicine-image.jpg"
                            }
                            alt="main"
                            effect="blur"
                            style={{
                              objectFit: "cover",
                              width: !smUp ? "100%" : "100%",
                              cursor: "pointer",
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={9}>
                        <Grid container spacing={0} flexDirection="column">
                          <Grid item>
                            <Typography
                              variant="subtitle1"
                              color="initial"
                              sx={{ fontSize: "1.2rem", fontWeight: 100 }}
                            >
                              {getMedicineInfo(
                                x.medId
                              ).genericBrand.toUpperCase()}
                              <span
                                style={{ fontSize: "1.2rem", fontWeight: 100 }}
                              >
                                {smUp && getVariantInfo(x.variantId)}
                              </span>
                            </Typography>
                          </Grid>
                          {!smUp && (
                            <Grid item>
                              <Typography
                                variant="subtitle1"
                                color="initial"
                                sx={{ fontSize: ".9rem", fontWeight: 100 }}
                              >
                                {getVariantInfo(x.variantId)}
                              </Typography>
                            </Grid>
                          )}
                          <Grid item>
                            <Typography
                              variant="subtitle1"
                              color="initial"
                              sx={{ fontSize: ".8rem", fontWeight: 100 }}
                            >
                              {getCreated(x.createdAt)}
                              <Tooltip
                                arrow
                                placement="top"
                                title={`Created: ${getExactTime(x.createdAt)}`}
                              >
                                <IconButton
                                  size="small"
                                  onClick={() => handleShowDate(x.createdAt)}
                                >
                                  <InfoIcon color="info" />
                                </IconButton>
                              </Tooltip>
                              <br />
                              {showDate.includes(x.createdAt) &&
                                getExactTime(x.createdAt)}
                            </Typography>
                          </Grid>

                          <Grid item>
                            <Typography
                              variant="body2"
                              color="initial"
                              sx={{ fontWeight: 300 }}
                            >
                              ({getSymbolFromCurrency(currencySymbol)}
                              {Number(x.price) > 0
                                ? currencyFormatter(
                                    "USA",
                                    (
                                      Number(x.price) * selectedCurrency
                                    ).toFixed(2)
                                  )
                                : 0}{" "}
                              <span style={{ color: "red" }}>X {x.moq}</span>)
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="body2"
                              color="initial"
                              sx={{ fontWeight: 300 }}
                            >
                              = {getSymbolFromCurrency(currencySymbol)}
                              {Number(x.price) > 0
                                ? currencyFormatter(
                                    "USA",
                                    (
                                      Number(x.price) *
                                      Number(x.moq) *
                                      selectedCurrency
                                    ).toFixed(2)
                                  )
                                : 0}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Divider />
                  </>
                ))}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseViewCartDlg} color="primary">
            {translate2("Close")}
          </Button>
        </DialogActions>
      </Dialog>
      {/* view med */}
      {viewMedDlg && (
        <Dialog
          open={viewMedDlg}
          //   onClose={handleCloseViewMed}
          TransitionComponent={Transition}
          keepMounted
          fullWidth
          maxWidth={"md"}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseViewMed}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {translate3("Product Details")}
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <ViewProductDetails
              medId={viewMedBuffer.medId}
              varId={viewMedBuffer.varId}
              lang={viewMedBuffer.lang}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
