import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { logout, logOutUser } from "../../reduxToolkit/features/auth";
import { resetAskPriceListByCustomer } from "../../reduxToolkit/features/frontend/checkout";
import { getCompareList } from "../../reduxToolkit/features/frontend/compare";
import { getWishlist } from "../../reduxToolkit/features/frontend/wishlist";

export default function LogoutDialogue({
  setLoginStatus,
  logoutD,
  setLogoutD,
}) {
  // Local Store
  const localData = JSON.parse(localStorage.getItem("loginInfo"));
  // Function
  const dispatch = useDispatch();
  const handleLogout = () => {
    setLoginStatus(false);
    setLogoutD(false);
    localData && localData._id && dispatch(logOutUser({ id: localData._id }));
    localStorage.removeItem("loginInfo");
    dispatch(logout());
    dispatch(resetAskPriceListByCustomer());
    dispatch(getCompareList());
    dispatch(getWishlist());
  };
  return (
    <Dialog
      open={logoutD}
      onClose={() => setLogoutD}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Do you really wish to logout?"}
      </DialogTitle>
      <DialogActions>
        <Button onClick={() => setLogoutD(false)}>Close</Button>
        <Button onClick={handleLogout} color="error">
          Logout
        </Button>
      </DialogActions>
    </Dialog>
  );
}
