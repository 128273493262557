export const currencyFormatter = (currencyName, amount) => {
  //   var formatter = new Intl.NumberFormat("en-US", {
  //     style: "currency",
  //     currency: currencyName,

  //     // These options are needed to round to whole numbers if that's what you want.
  //     //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //     //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  //   });
  //   return formatter.format(amount);

  if (amount) {
    const a = Number(amount)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return a;
  }
  return amount;
};

export const getDecimal = (amount) => {
  let result = 0;
  amount = Math.abs(amount); // Change to positive
  var decimal = amount - Math.floor(amount);
  const decimalArr = decimal.toFixed(2).toString().split(".");
  if (decimalArr.length > 0) result = decimalArr[1];
  return result;
};
