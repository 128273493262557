import React from "react";
import TemplateCard from "../component/TemplateCard";

export default function TemplateView({ viewTemplateBuffer }) {
  return (
    <>
      <TemplateCard x={viewTemplateBuffer} editable={false} />
    </>
  );
}
