import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
// Icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ManageMedicine from "./components/ManageMedicine";
import ManageGenericCompany from "./components/ManageGenericCompany";
import ManageMedicineCategory from "./components/ManageMedicineCategory";
import SeoTags from "./components/SeoTags";
import ManageDosageForm from "./components/ManageDosageForm";
import AdvancedMedicineSettings from "./components/AdvancedMedicineSettings";
import ManageBrochure from "./components/ManageBrochure";

// Media Query
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ManualInvoice from "./components/manual-invoice/ManualInvoice";
import ManageCollectiveMedicineAdditionalSection from "./components/ManageCollectiveMedicineAdditionalSection";
import InvoiceVariantSelection from "./components/manual-invoice/InvoiceVariantSelection";

export default function MedicinesScreen() {
  const [expanded, setExpanded] = React.useState(false);

  // Media Query
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  // Store
  const { panel } = useSelector((state) => state.notification);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // handle Panel Based on Notification
  useEffect(() => {
    setExpanded(panel);
  }, [panel]);

  //! Translate
  // Store
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate

  return (
    <Box
      sx={{
        maxWidth: !smUp ? "75vw" : !mdUp ? "85vw" : undefined,
      }}
    >
      <Typography
        variant="h4"
        color="initial"
        align="center"
        sx={{ borderBottom: ".2rem solid black", mb: 2 }}
      >
        {translate0("Medicines")}
      </Typography>
      {medicineAcMenu.map((x, i) => (
        <Accordion
          key={i}
          expanded={expanded === medicineAcMenu[i].panelName}
          onChange={handleChange(medicineAcMenu[i].panelName)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={medicineAcMenu[i].panelName}
            id={medicineAcMenu[i].panelName}
          >
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <Typography>
                  {translate0(medicineAcMenu[i].headPrimary)}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={{ color: "text.secondary" }}>
                  {translate0(medicineAcMenu[i].headSecondary)}
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          {expanded === medicineAcMenu[i].panelName && (
            <AccordionDetails>{medicineAcMenu[i].component}</AccordionDetails>
          )}
        </Accordion>
      ))}
    </Box>
  );
}

const medicineAcMenu = [
  {
    panelName: "medicines",
    headPrimary: "Medicines",
    headSecondary: "Add/Manage Generics/ Generic Brands",
    component: <ManageMedicine />,
  },
  {
    panelName: "manage-medicine-additional-section",
    headPrimary: "Manage Medicine Additional Section",
    headSecondary: "",
    component: <ManageCollectiveMedicineAdditionalSection />,
  },
  {
    panelName: "manage-generic-company",
    headPrimary: "Manage Generic Company",
    headSecondary: "Add/Manage Generic Companies",
    component: <ManageGenericCompany />,
  },
  {
    panelName: "manage-category",
    headPrimary: "Manage Category",
    headSecondary: "Add/Manage Categories/ SubCategories",
    component: <ManageMedicineCategory />,
  },
  {
    panelName: "manage-dosage",
    headPrimary: "Manage Dosage Form",
    headSecondary: "Add/Manage Dosage Forms",
    component: <ManageDosageForm />,
  },

  {
    panelName: "manage-seo-tags",
    headPrimary: "Manage SEO Tags",
    headSecondary: "Add/Manage SEO tags",
    component: <SeoTags />,
  },
  {
    panelName: "advanced-medicine-settings",
    headPrimary: "Advanced Med Settings",
    headSecondary: "Manage Medicines",
    component: <AdvancedMedicineSettings />,
  },
  {
    panelName: "manage-brochure",
    headPrimary: "Manage Brochure",
    headSecondary: "Handle Brochure/PDF",
    component: <ManageBrochure />,
  },
  {
    panelName: "manual-invoice",
    headPrimary: "Manual Invoice",
    headSecondary: "",
    component: <InvoiceVariantSelection />,
  },
];
