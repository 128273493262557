import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// Icon
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { currencyFormatter } from "../../../../hooks/currencyFormatter";
import { format } from "date-fns";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useDispatch, useSelector } from "react-redux";
import { getCurrency } from "../../../../reduxToolkit/features/adminPanel/misc";
import getSymbolFromCurrency from "currency-symbol-map";

const ProductListEntry = ({
  productListEntries,
  setProductListEntries,
  editable,
}) => {
  // Media Query
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  const dispatch = useDispatch();
  const { currencyList } = useSelector((state) => state.misc);

  useEffect(() => {
    if (!(currencyList && currencyList.length > 0)) dispatch(getCurrency());
  }, []);

  const [editMode, setEditMode] = useState(null);
  const initialEditBuffer = {
    productName: "",
    productQty: "",
    perUnitPrice: "",
    isPaid: false,
    date: new Date().toString(),
    currencyTypeId: "",
  };
  const [editBuffer, setEditBuffer] = useState(initialEditBuffer);

  const getCurrencySymbol = (index) => {
    let symbol = "";

    if (productListEntries[index].currencyTypeId) {
      const currency = currencyList.find(
        (c) => c._id === productListEntries[index].currencyTypeId
      );
      if (currency) {
        symbol = getSymbolFromCurrency(currency.currencyName);
      }
    }
    return symbol;
  };

  const handleEditEntry = (index) => {
    setEditMode(index);
    setEditBuffer({
      productName: productListEntries[index].productName,
      productQty: productListEntries[index].productQty,
      perUnitPrice: productListEntries[index].perUnitPrice,
      isPaid: productListEntries[index].isPaid,
      date: productListEntries[index].date,
      currencyTypeId: productListEntries[index].currencyTypeId,
    });
  };

  const handleEntryChange = (e, key, isNumeric) => {
    let obj = { ...editBuffer };
    if (isNumeric) obj[key] = e.target.value.replace(/\D/g, "");
    else obj[key] = e.target.value;
    setEditBuffer(obj);
  };

  const handleUpdateEntry = (index) => {
    let arr = [...productListEntries];
    arr[index] = { ...editBuffer };
    arr = arr.sort((a, b) => (new Date(a.date) > new Date(b.date) ? 1 : -1));
    setProductListEntries(arr);
    setEditMode(null);
    setEditBuffer(initialEditBuffer);
  };

  const handleRemoveEntry = (index) => {
    let arr = [...productListEntries];
    arr = arr.filter((a, i) => i !== index);
    setProductListEntries(arr);
  };

  return (
    <>
      {mdUp && (
        <>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ px: 2, py: 1 }}
          >
            <Grid item xs={2}>
              <Typography
                sx={{
                  textAlign: "left",
                }}
                color="text.primary"
                variant="body2"
              >
                Date
              </Typography>
            </Grid>
            <Grid item xs={editable ? 2 : 4}>
              <Typography
                sx={{ textTransform: "capitalize", textAlign: "left" }}
                color="text.primary"
                variant="body2"
              >
                Product
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography
                sx={{ textAlign: "center" }}
                color="text.primary"
                variant="body2"
              >
                Qty
              </Typography>
            </Grid>
            {/* <Grid item xs={1.5}>
              <Typography
                sx={{ textAlign: "center" }}
                color="text.primary"
                variant="body2"
              >
                Unit Price
              </Typography>
            </Grid> */}
            <Grid item xs={1.5}>
              <Typography
                sx={{ textAlign: "center" }}
                color="text.primary"
                variant="body2"
              >
                Amount
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography
                sx={{ textAlign: "center" }}
                color="text.primary"
                variant="body2"
              >
                Status
              </Typography>
            </Grid>
            {editable && (
              <Grid item xs={2}>
                <Typography
                  sx={{
                    textAlign: "right",
                    mr: 2,
                  }}
                  color="text.primary"
                  variant="body2"
                >
                  Action
                </Typography>
              </Grid>
            )}
          </Grid>
          <Divider />
        </>
      )}

      <Box
        sx={{
          height: !editable ? "85%" : !mdUp ? "500px" : "300px",
          overflowY: "auto",
        }}
      >
        {productListEntries.map((entry, index) => (
          <Fragment key={index}>
            <Grid
              container
              justifyContent={!mdUp ? "center" : "space-between"}
              alignItems="center"
              sx={{ px: 2, py: 1 }}
            >
              <Grid item xs={!mdUp ? 12 : 2}>
                {editMode === index ? (
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        // label="Date"
                        value={editBuffer.date}
                        onChange={(newValue) => {
                          let obj = { ...editBuffer };
                          obj.date = newValue;
                          setEditBuffer(obj);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            fullWidth
                            sx={{ ml: !mdUp ? 0 : 0, py: 1 }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                ) : (
                  <Typography
                    sx={{
                      textAlign: !mdUp ? "left" : "left",
                    }}
                    color="text.secondary"
                    variant="body2"
                  >
                    {!mdUp && "Date: "}
                    {format(new Date(entry.date), "dd-MM-y")}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={!mdUp ? 12 : editable ? 2 : 4}>
                {editMode === index ? (
                  <TextField
                    // label="Product Name"
                    value={editBuffer.productName}
                    onChange={(e) => handleEntryChange(e, "productName")}
                    size="small"
                    variant="outlined"
                    fullWidth
                    sx={{ mr: mdUp ? 0 : 1, my: 1 }}
                  />
                ) : (
                  <Typography
                    sx={{
                      textTransform: "capitalize",
                    }}
                    color="text.secondary"
                    variant="body2"
                  >
                    {!mdUp && "Product: "}
                    {entry.productName}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={!mdUp ? 12 : 1}>
                {editMode === index ? (
                  <TextField
                    // label="Qty"
                    value={editBuffer.productQty}
                    onChange={(e) => handleEntryChange(e, "productQty", true)}
                    size="small"
                    variant="outlined"
                    fullWidth
                    sx={{ mx: !mdUp ? 0 : 1, my: 1 }}
                  />
                ) : (
                  <Typography
                    sx={{ textAlign: !mdUp ? "left" : "center" }}
                    color="text.secondary"
                    variant="body2"
                  >
                    {!mdUp && "Qty: "}
                    {entry.productQty}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={!mdUp ? 12 : 1.5}>
                {editMode === index ? (
                  <TextField
                    // label="Amount"
                    value={editBuffer.perUnitPrice}
                    onChange={(e) => handleEntryChange(e, "perUnitPrice", true)}
                    size="small"
                    variant="outlined"
                    fullWidth
                    sx={{ mx: !mdUp ? 0 : 1, my: 1, pl: !mdUp ? 0 : 1 }}
                  />
                ) : (
                  <Typography
                    sx={{ textAlign: !mdUp ? "left" : "center" }}
                    color="text.secondary"
                    variant="body2"
                  >
                    {!mdUp && "Amount: "}
                    {getCurrencySymbol(index)}{" "}
                    {currencyFormatter("usd", entry.perUnitPrice)}
                  </Typography>
                )}
              </Grid>

              {/* <Grid item xs={!mdUp ? 12 : 1.5}>
                {editMode === index ? (
                  <Typography
                    variant="body1"
                    color="initial"
                    sx={{ textAlign: "center" }}
                  >
                    {mdUp && "--"}
                  </Typography>
                ) : (
                  <Typography
                    sx={{ textAlign: !mdUp ? "left" : "center" }}
                    color="text.secondary"
                    variant="body2"
                  >
                    {!mdUp && "Total Price: "} {getCurrencySymbol(index)}{" "}
                    {currencyFormatter(
                      "usd",
                      entry.perUnitPrice * entry.productQty
                    )}
                  </Typography>
                )}
              </Grid> */}

              <Grid
                item
                xs={!mdUp ? 12 : 2}
                sx={{
                  display: "flex",
                  justifyContent: !mdUp ? "start" : "center",
                  alignItems: "center",
                }}
              >
                {editMode === index ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={editBuffer.isPaid}
                        onChange={(e) => {
                          let obj = { ...editBuffer };
                          obj.isPaid = e.target.checked;
                          setEditBuffer(obj);
                        }}
                      />
                    }
                    label="Paid"
                  />
                ) : (
                  <Typography
                    sx={{ textAlign: !mdUp ? "left" : "center" }}
                    color={entry.isPaid ? "#27ae60" : "#e74c3c"}
                    variant="body2"
                  >
                    {entry.isPaid ? "Paid" : "Not Paid"}
                  </Typography>
                )}
              </Grid>

              {!mdUp && (
                <Grid item xs={12}>
                  <Divider sx={{ mt: 1 }} />
                </Grid>
              )}

              {editable && (
                <Grid item xs={!mdUp ? 12 : 2}>
                  <Grid
                    container
                    justifyContent={!mdUp ? "end" : "end"}
                    alignItems={!mdUp ? "end" : "end"}
                    spacing={1}
                    sx={{ mt: !mdUp ? 0 : 0 }}
                  >
                    <Grid item>
                      {editMode === index ? (
                        <IconButton
                          color="error"
                          size="small"
                          onClick={() => setEditMode(null)}
                        >
                          <CancelIcon sx={{ fontSize: "1.2rem" }} />
                        </IconButton>
                      ) : (
                        <IconButton
                          color="error"
                          size="small"
                          onClick={() => handleRemoveEntry(index)}
                        >
                          <DeleteRoundedIcon sx={{ fontSize: "1.2rem" }} />
                        </IconButton>
                      )}
                    </Grid>
                    <Grid item>
                      {editMode === index ? (
                        <IconButton
                          color="success"
                          size="small"
                          onClick={() => handleUpdateEntry(index)}
                        >
                          <CheckCircleIcon sx={{ fontSize: "1.2rem" }} />
                        </IconButton>
                      ) : (
                        <IconButton
                          color="info"
                          size="small"
                          onClick={() => handleEditEntry(index)}
                        >
                          <EditRoundedIcon sx={{ fontSize: "1.2rem" }} />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Divider />
          </Fragment>
        ))}
      </Box>
    </>
  );
};

export default ProductListEntry;
