import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDosages,
  getMedicines,
} from "../../reduxToolkit/features/adminPanel/medicine";
import { getCartInfoByCustomer } from "../../reduxToolkit/features/frontend/cart";
import LoadingBox from "../misc/Loading";
import Grid from "@mui/material/Grid";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Divider,
  IconButton,
  Input,
  Tooltip,
  Typography,
} from "@mui/material";

// Lazy Load Image
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { PF } from "../../config";

// icons
import UploadIcon from "@mui/icons-material/Upload";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  addPrescriptionImage,
  createPrescription,
  getPrescriptionByCustomer,
  removePrescription,
  uploadPrescription,
} from "../../reduxToolkit/features/frontend/checkout";

export default function Prescriptions({ prescribeData, setPrescribeData }) {
  const dispatch = useDispatch();
  // useState
  const [cartList, setCartList] = useState([]);
  const [prescribeArr, setPrescribeArr] = useState([]);
  const [loadingMain, setLoadingMain] = useState(false);
  const [presImgLoadingBuffer, setPresImgLoadingBuffer] = useState("");
  const [addPresImgLoading, setAddPresImgLoading] = useState(false);
  const [editPrescribeBuffer, setEditPrescribeBuffer] = useState("");

  // Local Store
  const localCustomerData = () => {
    const c = JSON.parse(localStorage.getItem("loginInfo"));
    return c ? c : {};
  };
  const localLangData = () => {
    const l = JSON.parse(localStorage.getItem("langFront"));
    return l ? l : {};
  };

  // Store
  const { medicines, medVariants, dosages } = useSelector(
    (state) => state.medicine
  );
  const { carts } = useSelector((state) => state.cart);
  const { prescriptions } = useSelector((state) => state.checkout);

  //   Functions
  const getDosageInfo = (dosageId, langCode) => {
    let dosageName = "";
    if (dosages && dosages.length > 0) {
      const dosageList = dosages.find((d) => d.lang === langCode);
      if (dosageList && dosageList.data) {
        const D = dosageList.data.find((d) =>
          langCode !== "en" ? d.dosageDetectorId : d.fixedDosageId === dosageId
        );
        if (D && D.dosageName) dosageName = D.dosageName;
      }
    }
    return dosageName;
  };

  const getVariantInfo = (varId) => {
    let obj = {
      dosage: "",
      strength: "",
      packTypes: "",
    };
    if (medVariants && medVariants.length > 0) {
      const V = medVariants.find(
        (v) =>
          (localLangData().langCode !== "en"
            ? v.variantDetectorId
            : v.fixedVariantId) === varId
      );
      if (V) {
        obj.dosage = getDosageInfo(V.dosageForm, localLangData().langCode);
        obj.strength = V.strength;
        obj.packTypes = V.packTypes;
      }
    }
    return obj;
  };

  const getMedInfo = (medId) => {
    let obj = {
      medImg: "",
      genericBrand: "",
    };
    if (medicines && medicines.length > 0) {
      const M = medicines.find(
        (m) => m.lang === (localLangData().langCode || "en")
      );
      if (M && M.data) {
        const Med = M.data.find(
          (med) =>
            (localLangData().langCode !== "en"
              ? med.medicineDetectorId
              : med.fixedMedicineId) === medId
        );
        if (Med) {
          obj.genericBrand = Med.genericBrand;
          if (Med.fixedMedicineId) obj.fixedMedicineId = Med.fixedMedicineId;
          if (Med.medicineDetectorId)
            obj.medicineDetectorId = Med.medicineDetectorId;
          const medImg = Med.image.find((i) => i.default);
          if (medImg) obj.medImg = medImg;
        }
      }
    }
    return obj;
  };

  const handleUploadPrescribeImage = (e, index) => {
    let arr = [...prescribeArr];
    arr[index] = [...e.target.files];
    setPrescribeArr(arr);
    // Set Checkout Data
    let fromCheckoutArr = [...prescribeData];
    fromCheckoutArr[index].imageArr = [...e.target.files];
    setPrescribeData(fromCheckoutArr);
  };

  const handleSetCheckOutPageData = (cartsArr) => {
    let arr = [];
    cartsArr.forEach((c) => {
      let obj = {
        imageArr: [],
        medicineId: c.medId,
        variantId: c.variantId,
        customerId: localCustomerData()._id,
      };
      arr.push(obj);
    });
    setPrescribeData(arr);
  };

  const handleChangePrescription = (e, x, i, y, j) => {
    setPresImgLoadingBuffer(j);
    if (y.src) {
      const data = new FormData();
      const ext = e.target.files[0].name.split(".");
      const filename =
        Date.now() +
        "-" +
        x.medId.replace(/\s+/g, "") +
        "-prescription." +
        ext.slice(-1);
      data.append("name", filename);
      data.append("prescription", e.target.files[0]);
      dispatch(uploadPrescription(data));

      dispatch(
        removePrescription({
          medicineId: x.medId,
          customerId: localCustomerData()._id,
          variantId: x.variantId,
          prevImg: y.src,
          newImg: filename,
        })
      );
      setTimeout(() => {
        setPresImgLoadingBuffer("");
        dispatch(
          getPrescriptionByCustomer({ customerId: localCustomerData()._id })
        );
      }, 1000);
    } else {
      let arr = [...prescribeArr];
      const arr2 = [...arr[i]];
      arr2[j] = e.target.files[0];
      arr[i] = arr2;
      setPrescribeArr(arr);
      setTimeout(() => {
        setPresImgLoadingBuffer("");
      }, 1000);
    }
  };

  const handleRemovePrescriptionImg = (x, i, y, j) => {
    setPresImgLoadingBuffer(j);
    if (y.src) {
      dispatch(
        removePrescription({
          medicineId: x.medId,
          customerId: localCustomerData()._id,
          variantId: x.variantId,
          prevImg: y.src,
        })
      );
      setTimeout(() => {
        setPresImgLoadingBuffer("");
        prescribeArr[i].length === 1 && setEditPrescribeBuffer("");
        dispatch(
          getPrescriptionByCustomer({ customerId: localCustomerData()._id })
        );
      }, 1000);
    } else {
      let arr = [...prescribeArr];
      const arr2 = arr[i].filter((a) => a.name !== y.name);
      arr[i] = arr2;
      arr[i].length < 1 && setEditPrescribeBuffer("");
      setPrescribeArr(arr);
      setTimeout(() => {
        setPresImgLoadingBuffer("");
      }, 1000);
    }
  };

  const checkIfFileTypeExist = (arr) => {
    let counter = 0;
    arr.forEach((x) => {
      if (x.name) {
        counter++;
      }
    });
    if (counter > 0) return false;
    return true;
  };

  const handleAddPrescription = (e, x, i) => {
    setAddPresImgLoading(true);

    if (prescribeArr[i].length > 0) {
      const data = new FormData();
      const ext = e.target.files[0].name.split(".");
      const filename =
        Date.now() +
        "-" +
        x.medId.replace(/\s+/g, "") +
        "-prescription." +
        ext.slice(-1);
      data.append("name", filename);
      data.append("prescription", e.target.files[0]);
      dispatch(uploadPrescription(data));
      dispatch(
        addPrescriptionImage({
          medicineId: x.medId,
          customerId: localCustomerData()._id,
          variantId: x.variantId,
          newImg: filename,
        })
      );
      setTimeout(() => {
        dispatch(
          getPrescriptionByCustomer({ customerId: localCustomerData()._id })
        );
        setAddPresImgLoading(false);
      }, 1000);
    } else {
      let arr = [...prescribeArr];
      arr[i].push(e.target.files[0]);
      setPrescribeArr(arr);
      setTimeout(() => {
        setAddPresImgLoading(false);
      }, 1000);
    }
  };

  //   Use Effect
  useEffect(() => {
    setLoadingMain(true);
    // if (!(medicines && medicines.length > 0)) dispatch(getMedicines());
    if (localCustomerData()._id) {
      dispatch(getCartInfoByCustomer(localCustomerData()._id));
    }
    if (!(dosages && dosages.length > 0)) dispatch(getDosages());
    dispatch(
      getPrescriptionByCustomer({ customerId: localCustomerData()._id })
    );
    setTimeout(() => {
      setLoadingMain(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (carts && carts.length > 0) {
      setCartList(carts);
      // set checkout page data
      handleSetCheckOutPageData(carts);
      let arr = [];
      carts.forEach((c) => arr.push([]));

      carts.forEach((c, i) => {
        const P = prescriptions.find((p) => p.variantId === c.variantId);

        if (P && P.imageArr) arr[i] = P.imageArr;
        else arr[i] = [];
      });
      setPrescribeArr(arr);
    } else {
      setCartList([]);
      setPrescribeArr([]);
    }
  }, [carts, prescriptions]);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {translateFront("Upload Prescription")}
      </Typography>
      {loadingMain ? (
        <LoadingBox w={"100%"} />
      ) : (
        <Grid container spacing={1}>
          {cartList.map((x, i) => (
            <Grid item key={i} xs={12}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} sm={7}>
                  <Button
                    variant="text"
                    size="small"
                    fullWidth
                    sx={{
                      justifyContent: "flex-start",
                      alignItems: "center",
                      pointerEvents: "none",
                      fontSize: ".7rem",
                    }}
                    startIcon={
                      <LazyLoadImage
                        src={
                          getMedInfo(x.medId).medImg.src !== ""
                            ? PF +
                              "adminPanel/medicines/img/" +
                              getMedInfo(x.medId).medImg.src
                            : PF + "adminPanel/medicines/no-medicine-image.jpg"
                        }
                        alt={getMedInfo(x.medId).medImg.alt}
                        effect="blur"
                        placeholderSrc="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png"
                        style={{
                          objectFit: "stretch",
                          height: 20,
                        }}
                      />
                    }
                  >
                    {getMedInfo(x.medId).genericBrand.toUpperCase()}
                    {" ("}
                    {getVariantInfo(x.variantId).dosage}
                    {" - "}
                    {getVariantInfo(x.variantId).strength}
                    {" - "}
                    {getVariantInfo(x.variantId).packTypes}
                    {") "}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    {prescribeArr[i].length < 1 ? (
                      <>
                        <label htmlFor={`add-prescription-${i}`}>
                          <Input
                            accept="image/*"
                            id={`add-prescription-${i}`}
                            inputProps={{ multiple: true }}
                            type="file"
                            sx={{ display: "none" }}
                            onChange={(e) => handleUploadPrescribeImage(e, i)}
                          />
                          <Tooltip
                            arrow
                            placement="left"
                            title={translateFront("Upload Prescription")}
                          >
                            <IconButton
                              size="medium"
                              aria-label={`add-prescription-${i}`}
                              component="span"
                              disabled={editPrescribeBuffer !== ""}
                            >
                              <UploadIcon
                                color={
                                  editPrescribeBuffer !== ""
                                    ? "inherit"
                                    : "warning"
                                }
                              />
                            </IconButton>
                          </Tooltip>
                        </label>
                      </>
                    ) : (
                      <Grid
                        container
                        spacing={0}
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Grid item>
                          <AvatarGroup max={4}>
                            {prescribeArr[i].length > 0 &&
                              prescribeArr[i].map((y, j) => (
                                <Avatar
                                  alt={`prescription-image-${i}-[${j}]`}
                                  src={
                                    y.src
                                      ? PF + "adminPanel/prescription/" + y.src
                                      : URL.createObjectURL(y)
                                  }
                                  sx={{ width: 36, height: 36 }}
                                  key={j}
                                />
                              ))}
                          </AvatarGroup>
                        </Grid>
                        <Grid item>
                          {editPrescribeBuffer === i ? (
                            // <Tooltip arrow placement="left" title="uploaded">
                            <IconButton
                              size="medium"
                              onClick={() => setEditPrescribeBuffer("")}
                            >
                              <CheckCircleIcon color="success" />
                            </IconButton>
                          ) : (
                            // </Tooltip>
                            // <Tooltip arrow placement="left" title="Edit">
                            <IconButton
                              size="medium"
                              onClick={() => setEditPrescribeBuffer(i)}
                              disabled={editPrescribeBuffer !== ""}
                            >
                              <EditIcon
                                color={
                                  editPrescribeBuffer !== ""
                                    ? "inherit"
                                    : "primary"
                                }
                              />
                            </IconButton>
                            // </Tooltip>
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </Box>
                </Grid>
              </Grid>
              <Divider />
              {editPrescribeBuffer === i && (
                <Grid container spacing={1}>
                  {prescribeArr[i].length > 0 &&
                    prescribeArr[i].map((y, j) => (
                      <Grid item xs={12} sm={6} key={j}>
                        <Box>
                          <Grid container spacing={0} alignItems="center">
                            <Grid item xs={8}>
                              <Box
                                sx={{
                                  display: "flex",
                                  //   justifyContent: "center",
                                  alignItems: "center",
                                  m: 1,
                                }}
                              >
                                {presImgLoadingBuffer === j ? (
                                  <LoadingBox w={"100%"} />
                                ) : (
                                  <LazyLoadImage
                                    src={
                                      y.src && y.src !== ""
                                        ? PF +
                                          "adminPanel/prescription/" +
                                          y.src
                                        : URL.createObjectURL(y)
                                    }
                                    alt={`prescription-image-${i}-[${j}]`}
                                    effect="blur"
                                    placeholderSrc="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "contain",
                                    }}
                                  />
                                )}
                              </Box>
                            </Grid>
                            <Grid item xs={4}>
                              <Grid
                                container
                                spacing={0}
                                flexDirection="column"
                              >
                                <Grid item>
                                  <label htmlFor={`change-prescription-${j}`}>
                                    <Input
                                      accept="image/*"
                                      id={`change-prescription-${j}`}
                                      inputProps={{ multiple: false }}
                                      type="file"
                                      sx={{ display: "none" }}
                                      onChange={(e) =>
                                        handleChangePrescription(e, x, i, y, j)
                                      }
                                    />
                                    <Tooltip
                                      arrow
                                      placement="left"
                                      title={translateFront("Change")}
                                    >
                                      <IconButton
                                        size="medium"
                                        aria-label={`change-prescription-${i}`}
                                        component="span"
                                      >
                                        <ChangeCircleIcon color="warning" />
                                      </IconButton>
                                    </Tooltip>
                                  </label>
                                </Grid>
                                <Grid item>
                                  <Tooltip
                                    arrow
                                    placement="left"
                                    title={translateFront("Remove")}
                                  >
                                    <IconButton
                                      size="medium"
                                      onClick={() =>
                                        handleRemovePrescriptionImg(x, i, y, j)
                                      }
                                    >
                                      <DeleteIcon color="error" />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    ))}
                  {checkIfFileTypeExist(prescribeArr[i]) && (
                    <Grid item xs={12} sm={6}>
                      {addPresImgLoading ? (
                        <LoadingBox w={"100%"} />
                      ) : (
                        // <Box
                        //   sx={{
                        //     display: "flex",
                        //     // justifyContent: "center",
                        //     alignItems: "center",
                        //   }}
                        // >
                        <label htmlFor={`add-new-prescription`}>
                          <Input
                            accept="image/*"
                            id={`add-new-prescription`}
                            inputProps={{ multiple: false }}
                            type="file"
                            sx={{ display: "none" }}
                            onChange={(e) => handleAddPrescription(e, x, i)}
                          />
                          <Button
                            variant="outlined"
                            size="large"
                            color="success"
                            fullWidth
                            sx={{
                              height: 150,
                              width: { xs: 190, sm: 150 },
                              my: 1,
                              ml: 1,
                              // borderRadius: "50%",
                            }}
                            startIcon={<AddCircleIcon />}
                            aria-label={`add-new-prescription`}
                            component="span"
                          >
                            {translateFront("Add")}
                          </Button>
                        </label>
                        // </Box>
                      )}
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
}
