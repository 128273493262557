import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { red } from "@mui/material/colors";
import { axiosInstance, PF } from "../../../../config";
import { useNavigate } from "react-router-dom";
import { getExactTime } from "../../../../hooks/getCreatedData";

export default function CustomerReport({ startDate, endDate }) {
  const navigate = useNavigate();
  // useState
  const [customerList, setCustomerList] = useState([]);
  const [adminUrl, setAdminUrl] = useState("");

  // Store
  const { customers } = useSelector((state) => state.auth);

  // Functions
  const getAdminUrl = async () => {
    const res = await axiosInstance.get("/api/adminUrl");
    setAdminUrl(res.data.url);
  };

  useEffect(() => {
    getAdminUrl();
    if (customers && customers.length && startDate && endDate) {
      var resultProductData = customers.filter((a) => {
        var date = new Date(a.createdAt);
        return date >= startDate && date <= endDate;
      });
      setCustomerList(resultProductData);
    }
  }, []);
  return (
    <>
      <Box
        sx={{
          maxHeight: { xs: "40vh", sm: "40vh" },
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Grid container spacing={1}>
          {customerList.map((x, i) => (
            <Grid item key={i} xs={12} sm={6}>
              <Card>
                <CardActionArea
                  onClick={() =>
                    window.open(
                      `/${adminUrl}/customer${"?search=" + x._id}`,
                      "_blank"
                    )
                  }
                >
                  <CardContent>
                    <Tooltip
                      arrow
                      title={x.loginStatus ? "online" : "offline"}
                      placement="top"
                    >
                      <Avatar
                        sx={{
                          bgcolor: red[500],
                          filter: x.loginStatus
                            ? "grayscale(0%)"
                            : "grayscale(100%)",
                        }}
                        aria-label="customers"
                        src={
                          x.customerPic
                            ? x.customerPic
                            : x.gender
                            ? x.gender === "male"
                              ? PF +
                                "frontEnd/default/man-default-customer-image.jpg"
                              : x.gender === "female" &&
                                PF +
                                  "frontEnd/default/women-default-customer-image.jpg"
                            : "https://picsum.photos/200/300"
                        }
                      />
                    </Tooltip>
                    <Typography variant="body2" color="initial" sx={{ mt: 1 }}>
                      {x.name.toUpperCase()}
                    </Typography>
                    <Typography variant="body2" color="initial">
                      {x.email}
                    </Typography>
                    <Typography variant="body2" color="initial">
                      {getExactTime(x.createdAt)}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
}
