import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../config";

// export const getAdminUrl = createAsyncThunk("get/admin-url", async () => {
//   const res = await axiosInstance.get("/api/adminUrl");
//   return res.data;
// });

export const updateAdminUrl = createAsyncThunk(
  "change/admin-url",
  async (data) => {
    const { id, ...others } = data;
    const res = await axiosInstance.put(`/api/adminUrl/${id}`, others);
    return res.data;
  }
);

// initial State
const initialState = {
  pending: false,
  //   adminUrlInfo: {},
  error: false,
};

// Slice
export const adminUrlSlice = createSlice({
  name: "adminUrl",
  initialState,
  reducers: {},
  extraReducers: {
    // [getAdminUrl.pending]: (state) => {
    //   state.pending = true;
    //   state.error = false;
    // },
    // [getAdminUrl.fulfilled]: (state, action) => {
    //   state.pending = false;
    //   state.adminUrlInfo = action.payload;
    // },
    // [getAdminUrl.rejected]: (state) => {
    //   state.pending = true;
    //   state.error = false;
    // },
    [updateAdminUrl.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [updateAdminUrl.fulfilled]: (state, action) => {
      state.pending = false;
      //   state.adminUrlInfo = action.payload;
    },
    [updateAdminUrl.rejected]: (state) => {
      state.pending = true;
      state.error = false;
    },
  },
});

export const {} = adminUrlSlice.actions;
export default adminUrlSlice.reducer;
