export const checkIfValidImage = (data) => {
  let result = false;
  const keys = [
    ".jpg",
    ".png",
    ".svg",
    ".jpeg",
    ".gif",
    ".bmp",
    ".tiff",
    ".tif",
    ".webp",
  ];
  keys.map((x, i) => {
    if (data && data.includes(x)) result = true;
  });
  return result;
};

export const checkIfValidVideo = (data) => {
  let result = false;
  const keys = [
    ".mp4",
    ".mov",
    ".avi",
    ".flv",
    ".mkv",
    ".wmv",
    ".webm",
    ".avchd",
    ".m4v",
    ".h264",
  ];
  keys.map((x, i) => {
    if (data && data.includes(x)) result = true;
  });
  return result;
};
