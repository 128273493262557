import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import LoadingBox from "../../../../../components/misc/Loading";
import { PF } from "../../../../../config";
import { format } from "date-fns";
// import { currencyFormatter } from "../../../../../hooks/currencyFormatter";
import { useDispatch, useSelector } from "react-redux";
import { Button, Divider, Grid } from "@mui/material";
import { invoicePdfDownload } from "../../../../../reduxToolkit/features/adminPanel/medicine";
import LoadingButton from "@mui/lab/LoadingButton";

export default function PreviewInvoice({
  open,
  setOpen,
  invoiceDate,
  invoiceNo,
  rows,
  total,
  totalInWord,
  clientInfo,
  companyInfo,
}) {
  const [loading, setLoading] = useState(false);
  const [htmlToPreview, setHtmlToPreview] = useState(``);
  const [loadingPdfGenerate, setLoadingPdfGenerate] = useState(false);

  const dispatch = useDispatch();

  const { invoicePdfDownloadResponse } = useSelector((state) => state.medicine);

  // Download pdf
  const handleDownloadPDF = () => {
    setLoadingPdfGenerate(true);
    dispatch(invoicePdfDownload({ html: htmlToPreview }));
    setTimeout(() => {
      setLoadingPdfGenerate(false);
    }, 2000);
  };

  useEffect(() => {
    if (invoicePdfDownloadResponse && invoicePdfDownloadResponse.filename) {
      setTimeout(() => {
        window.open(
          PF + "files/PDFs/" + invoicePdfDownloadResponse.filename,
          "_blank"
        );
      }, 2000);
    }
  }, [invoicePdfDownloadResponse]);

  useEffect(() => {
    let html =
      templateStyle +
      `
      .container::before {
        opacity: .1;
        content: "";
        position: absolute;
        top: 150px;
        right: 0px;
        bottom: 0px;
        background-image: url(${
          companyInfo.companyWatermark
            ? companyInfo.companyWatermark
            : PF + "/misc/mfw-watermark.png"
        });
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        left: 0px;
      }
      </style>
      <div class="container">
	<div class="topbar">
		<div>
			<img src="https://medicineforworld.com.bd/image/getImage?url=/uploads/frontend/logo-logo.png" alt="company-logo" class="topbar__company-logo" />
        </div>
			<div class="topbar__vertical-divider" />
			<div class="topbar__company-info">
				<p>H# DCC 1, Momin Shoroni</p>
				<p>Road, North Ibrahimpur, Mirpur-14, Dhaka-1206,</p>
				<p>Dhaka, Bangladesh</p>
				<p>Phone: +8801916942634, +8801764000174</p>
				<p>Emails: info@medicineforworld.com.bd</p>
				<p>medicineforworld@gmail.com</p>
				<p>Website: www.medicineforworld.com.bd</p>
			</div>
		</div>

		<table style="border: none; margin-top: 3rem;">
			<tbody style="border: none">
				<tr>
					<td class="table__consignee-block table__extra-padding" style="border: none">

					</td>
					<td class="table__consignee-block table__extra-padding" style="border: none">
						<div>
							<p style="font-size: 1.4rem; font-weight: 900; text-align: right; margin-bottom: 1.4rem ">
								INVOICE</p>
							<p style="font-size: .7rem; text-align: right;">INVOICE ${invoiceNo}</p>
							<p style="font-size: .7rem; text-align: right;">DATE ${invoiceDate}</p>
						</div>
					</td>
				</tr>
				<tr style="border: none">
					<td class="table__consignee-block table__extra-padding" style="border: none">
						<div>
							${clientInfo}
						</div>
					</td>
					<td class="table__consignee-block table__extra-padding" style="border: none">

					</td>
				</tr>
			</tbody>
		</table>
		<table>
			<thead>
				<tr>
					<th>Description</th>
					<th class="text-center">Quantity</th>
					<th class="text-center">Unit Price</th>
					<th style="text-align: right">Total</th>
				</tr>
			</thead>
			<tbody>
				{items}
			</tbody>
		</table>

		<table style="border: none;">
			<tbody>
				<tr>
					<td class="table__consignee-block table__extra-padding" style="border: none">
						<div>
							<p style="visibility: hidden">1</p>
						</div>
					</td>
					<td class="table__consignee-block table__extra-padding" style="border: none">
						<div>
							<p style="text-align: right">SUBTOTAL IN WORD</p>
						</div>
					</td>
					<td class="table__consignee-block table__extra-padding" style="border: none">
						<div>
							<p style="font-weight: 900; text-align: right;">${total}</p>
              <br/>
							<p style="text-align: right;">${totalInWord}</p>
						</div>
					</td>
				</tr>
			</tbody>
		</table>

		<table style="border: none;">
			<tbody>
				<tr>
					<td style="border: none;">
						<div>
							<p style="font-size: 1.3rem; text-align: center; font-weight: 900">Thank You!</p>
						</div>
					</td>
				</tr>
			</tbody>
		</table>


		<table style="border: none;">
			<tbody>
				<tr>
					<td class="table__consignee-block table__extra-padding" style="border: none">
						<div style="width: 300px">
						</div>
					</td>
					<td class="table__consignee-block table__extra-padding" style="border: none">
						<div>
							<img src=${
                companyInfo.companySil
                  ? companyInfo.companySil
                  : PF + "misc/mfw-sil.png"
              } alt="company-logo" />
						</div>
					</td>
				</tr>
			</tbody>
		</table>

	</div>   
        `;

    // Items
    let items = "";
    rows.forEach(
      (item) =>
        (items =
          items +
          `	
          <tr>
					   <td>${item.description}</td>
					   <td class="text-center">${item.qty}</td>
					   <td class="text-center">${item.unitPrice}</td>
					   <td style="text-align: right">${item.totalPrice}</td>
				  </tr>
        `)
    );
    html = html.replace("{items}", items);

    setHtmlToPreview(html);
  }, []);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullScreen>
        {loading ? (
          <LoadingBox w={"100%"} h="100vh" />
        ) : (
          <DialogContent>
            <Grid
              container
              sx={{ p: 0 }}
              style={{ margin: "auto", width: "50%" }}
              justifyContent="center"
              alignItems="center"
              spacing={4}
            >
              <Grid item xs={6}>
                <Button
                  onClick={() => setOpen(false)}
                  // sx={{ m: 0, p: 0 }}
                  color="error"
                  variant="outlined"
                  fullWidth
                  size="small"
                >
                  Back
                </Button>
              </Grid>
              <Grid item xs={6}>
                <LoadingButton
                  loading={loadingPdfGenerate}
                  onClick={handleDownloadPDF}
                  // sx={{ m: 0, p: 0 }}
                  color="warning"
                  variant="contained"
                  fullWidth
                  size="small"
                >
                  Download PDF
                </LoadingButton>
              </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />

            {loading ? (
              <LoadingBox w={"100%"} />
            ) : (
              <div style={{ margin: "auto", width: "50%" }}>
                <div dangerouslySetInnerHTML={{ __html: htmlToPreview }} />
              </div>
            )}
          </DialogContent>
        )}
      </Dialog>
    </>
  );
}

const templateStyle = `
<style>
	.container {
		position: relative;
		z-index: 10;
		margin-top: 40px;
		margin-bottom: 50px;
		margin-left: 5px;
		margin-right: 5px;
	}

	.topbar {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
	}

	.topbar__company-info {
		line-height: 1.5px;
		font-size: 11px;
		flex: 1;
		padding: 0 10px;
		border-left: 3px solid green;
	}

	.topbar__company-logo {
		height: 100px;
		width: 450px;
		object-fit: contain;
		padding-right: 10px;
		object-position: left;
		flex: 1;
	}

	table {
		border-collapse: collapse;
		width: 100%;
		border: 1px solid black;
	}

	td,
	th {
		border: 1px solid black;
		text-align: left;
		font-size: 11px;
		table-layout: auto;
		padding: .4rem;
	}

	.table__extra-padding {
		padding: 8px;
	}

	.table__heading {
		text-align: center;
		font-weight: 500;
		font-size: 16px;
	}

	.table__consignee-block {
		line-height: 1.5px;
		vertical-align: top;
	}

	.table__large-text {
		font-size: 13px;
		font-weight: 800;
	}

	.bold-text {
		font-weight: 800;
	}

	.w-fixed {
		display: inline-block;
		width: 70px;
	}

	.table__total-title {
		text-align: right;
	}

	.flex {
		display: flex;
		gap: 10px;
	}


	.text-right {
		float: right
	}

	.text-center {
		text-align: center;
	}

	.text-align-left {
		text-align: left;
	}

	.company-sil {
		height: 80px;
		width: 80px;
		object-fit: contain;
		margin-left: 30px;
	}

	.normal-line-height {
		line-height: normal !important;
	}
`;
