import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrders } from "../../../reduxToolkit/features/frontend/checkout";
import { Alert } from "@mui/material";
import { useState } from "react";
import LoadingBox from "../../../components/misc/Loading";
import { getAllDeliveryMethods } from "../../../reduxToolkit/features/adminPanel/delivery";
import { getAllPaymentMethods } from "../../../reduxToolkit/features/adminPanel/payment";
import { getAllCustomers } from "../../../reduxToolkit/features/auth";
import OrderCard from "./OrderCard";

export default function ManageOrders() {
  const dispatch = useDispatch();

  const [loadingMain, setLoadingMain] = useState(false);
  const [lang, setLang] = useState("");
  const [langId, setLangId] = useState("");
  const [orderList, setOrderList] = useState([]);

  // LocalData
  const localLangData = JSON.parse(localStorage.getItem("lang"));

  // Store
  const { orders } = useSelector((state) => state.checkout);
  const { langs, activeLang } = useSelector((state) => state.misc);

  // Use Effect
  useEffect(() => {
    dispatch(getAllOrders());
    dispatch(getAllCustomers());
  }, []);

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setLang(activeLang.langCode);
      setLangId(activeLang._id);
    } else {
      if (localLangData) {
        setLang(localLangData.langCode);
        setLangId(localLangData._id);
      }
    }
  }, [activeLang]);

  useEffect(() => {
    if (orders && orders.length > 0) {
      const O = orders.filter((o) => o.orderNumber);
      if (O) setOrderList(O);
    }
  }, [orders]);

  useEffect(() => {
    setLoadingMain(true);
    setTimeout(() => {
      setLoadingMain(false);
    }, 2000);
  }, []);

  return (
    <>
      <Grid container spacing={1}>
        {loadingMain ? (
          <LoadingBox w={"100%"} />
        ) : orderList.length < 1 ? (
          <Grid item xs={12}>
            <Alert severity="warning" sx={{ justifyContent: "center" }}>
              No Orders...
            </Alert>
          </Grid>
        ) : (
          orderList.map((x, i) => (
            <Grid item key={i} xs={12} sm={6} md={4} xl={2}>
              <OrderCard x={x} lang={lang} />
            </Grid>
          ))
        )}
      </Grid>
    </>
  );
}
