import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import React, { useState } from "react";
import { currencyFormatter } from "../../../../hooks/currencyFormatter";
import { useDispatch } from "react-redux";
import {
  deleteOfficeExpense,
  getOfficeExpense,
  updateOfficeExpense,
} from "../../../../reduxToolkit/features/adminPanel/expense";
import LoadingButton from "@mui/lab/LoadingButton";

// Icon
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

// Media Query
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const OfficeExpenseCard = ({ expense }) => {
  const dispatch = useDispatch();

  // Media Query
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  const [loading, setLoading] = useState(false);

  const [deleteExpenseDlg, setDeleteExpenseDlg] = useState(false);

  const [editMode, setEditMode] = useState(false);

  const initialState = {
    date: expense.date,
    desc: expense.desc,
    calculation: expense.calculation,
    total: expense.total,
    isPlus: expense.isPlus,
  };

  const [editEntry, setEditEntry] = useState(initialState);

  const handleExpenseChange = (e, key, numeric) => {
    let obj = { ...editEntry };
    if (numeric) {
      obj[key] = e.target.value.replace(/\D/g, "");
    } else {
      obj[key] = e.target.value;
    }
    setEditEntry(obj);
  };

  const handleUpdateEntry = () => {
    setLoading(true);
    let obj = { ...editEntry };
    dispatch(updateOfficeExpense({ id: expense._id, body: obj }));
    setTimeout(() => {
      setLoading(false);
      setEditEntry(initialState);
      dispatch(getOfficeExpense());
      setEditMode(false);
    }, 3000);
  };

  const handleDeleteEntry = () => {
    setDeleteExpenseDlg(false);
    dispatch(deleteOfficeExpense(expense._id));
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      dispatch(getOfficeExpense());
    }, 3000);
  };
  return (
    <>
      <Grid container spacing={0} alignItems="center">
        <Grid item xs={12} lg={1}>
          {editMode ? (
            <Box sx={{ pr: !mdUp ? 0 : 2 }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label={!mdUp ? "Date" : ""}
                  value={editEntry.date}
                  onChange={(newValue) => {
                    let obj = { ...editEntry };
                    obj.date = newValue;
                    setEditEntry(obj);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      fullWidth
                      disabled={loading}
                      sx={{ my: 1 }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
          ) : (
            <Typography fullWidth variant="body2" color="text.secondary">
              <span style={{ color: "black" }}>{!mdUp && "Date: "}</span>
              {format(new Date(expense.date), "dd-MM-y")}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} lg={4.5}>
          {editMode ? (
            <TextField
              label={!mdUp ? "Description" : ""}
              size="small"
              variant="outlined"
              fullWidth
              disabled={loading}
              value={editEntry.desc}
              sx={{ pr: !mdUp ? 0 : 2, my: !mdUp ? 2 : 0 }}
              onChange={(e) => handleExpenseChange(e, "desc")}
            />
          ) : (
            <Typography
              fullWidth
              variant="body2"
              color="text.secondary"
              sx={!mdUp && { maxWidth: 200, wordWrap: "break-word" }}
            >
              <span style={{ color: "black", display: "block" }}>
                {!mdUp && "Description: "}
              </span>
              {expense.desc}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} lg={3.5}>
          {editMode ? (
            <TextField
              label={!mdUp ? "Calculation" : ""}
              size="small"
              variant="outlined"
              fullWidth
              disabled={loading}
              value={editEntry.calculation}
              sx={{ pr: !mdUp ? 0 : 2, my: !mdUp ? 2 : 0 }}
              onChange={(e) => handleExpenseChange(e, "calculation")}
            />
          ) : (
            <Typography
              fullWidth
              variant="body2"
              color="text.secondary"
              sx={!mdUp && { maxWidth: 200, wordWrap: "break-word" }}
            >
              <span style={{ color: "black", display: "block" }}>
                {!mdUp && "Calculation: "}
              </span>
              {expense.calculation}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} lg={2}>
          {editMode ? (
            <Grid
              container
              spacing={0}
              justifyContent={!mdUp ? "start" : "end"}
            >
              <Grid item xs={12} lg={6}>
                <TextField
                  label={!mdUp ? "Total" : ""}
                  size="small"
                  variant="outlined"
                  sx={{ pl: !mdUp ? 0 : "auto", my: !mdUp ? 2 : 0 }}
                  fullWidth={!mdUp ? true : false}
                  disabled={loading}
                  value={editEntry.total}
                  onChange={(e) => handleExpenseChange(e, "total", true)}
                />
              </Grid>
            </Grid>
          ) : (
            <Typography
              fullWidth
              variant="body2"
              align={!mdUp ? "left" : "right"}
              color={expense.isPlus ? "#27ae60" : "#e74c3c"}
            >
              <span style={{ color: "black" }}>{!mdUp && "Total: "}</span>৳{" "}
              {expense.isPlus ? "" : "- "}
              {currencyFormatter("bdt", expense.total)}
            </Typography>
          )}
        </Grid>
        {!mdUp && (
          <Grid item xs={12}>
            <Divider sx={{ mt: 2, mb: 1 }} />
          </Grid>
        )}
        <Grid item xs={12} lg={1}>
          <Grid
            container
            justifyContent={!mdUp ? "end" : "end"}
            alignItems={!mdUp ? "end" : "end"}
            spacing={1}
            sx={{ mt: !mdUp ? 0 : 0 }}
          >
            <Grid item>
              {editMode ? (
                <IconButton
                  color="error"
                  size="small"
                  onClick={() => {
                    setEditMode(false);
                    setEditEntry(initialState);
                  }}
                >
                  <CancelIcon sx={{ fontSize: "1.2rem" }} />
                </IconButton>
              ) : (
                <IconButton
                  color="error"
                  size="small"
                  onClick={() => {
                    setDeleteExpenseDlg(true);
                  }}
                >
                  <DeleteRoundedIcon sx={{ fontSize: "1.2rem" }} />
                </IconButton>
              )}
            </Grid>
            <Grid item>
              {editMode ? (
                <IconButton
                  color="success"
                  size="small"
                  onClick={handleUpdateEntry}
                >
                  <CheckCircleIcon sx={{ fontSize: "1.2rem" }} />
                </IconButton>
              ) : (
                <IconButton
                  color="info"
                  size="small"
                  onClick={() => {
                    setEditMode(true);
                    setEditEntry(initialState);
                  }}
                >
                  <EditRoundedIcon sx={{ fontSize: "1.2rem" }} />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider sx={{ my: !mdUp ? 2 : 0 }} />
      {/* Delete Database */}
      {deleteExpenseDlg && (
        <Dialog open={deleteExpenseDlg}>
          <DialogTitle>Do you really want to remove this entry?</DialogTitle>
          <DialogActions>
            <Button
              onClick={() => {
                setDeleteExpenseDlg(false);
              }}
              color="primary"
            >
              Close
            </Button>
            <LoadingButton
              loading={loading}
              onClick={handleDeleteEntry}
              color="error"
            >
              Confirm
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default OfficeExpenseCard;
