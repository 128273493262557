import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Input,
  Tooltip,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  CardMedia,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
// Icon
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import InfoIcon from "@mui/icons-material/Info";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ClearIcon from "@mui/icons-material/Clear";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { useEffect } from "react";
import { checkIfValidVideo } from "../../../../hooks/checkIfValid";
import { createNoti } from "../../../../reduxToolkit/features/adminPanel/misc";
import { useDispatch, useSelector } from "react-redux";
import ReactPlayer from "react-player";
import { PF } from "../../../../config";

export default function MedicineVideoUpload({ videos, setVideos, path }) {
  const [changeVideoBuffer, setChangeVideoBuffer] = useState("");
  const [additionalUrl, setAdditionalUrl] = useState("");
  const [altTag, setAltTag] = useState("");
  const [infoHelper, setInfoHelper] = useState(false);
  //   Dialog
  const [editVideoDlg, setEditVideoDlg] = useState(false);
  const [addUrl, setAddUrl] = useState(false);

  const [editBuffer, setEditBuffer] = useState("");

  const dispatch = useDispatch();
  const handleVideosUpload = (e) => {
    setVideos([...videos]);
    let arr = [];
    let uploads = [...e.target.files];
    uploads.map((x, i) => {
      let check = checkIfValidVideo(e.target.files[0].name);
      if (check) {
        let obj = {
          sl: i,
          file: x,
          alt: "",
          externalLink: false,
          default: false,
        };
        if (i === 0) obj.default = true;
        arr.push(obj);
      } else {
        dispatch(
          createNoti({
            message: "Non-supported files are excluded",
            severity: "error",
          })
        );
      }
    });
    setVideos(arr);
  };

  const handleRemoveSingle = (sl) => {
    let arr = [...videos];
    const index = arr.indexOf(arr.find((i) => i.sl === sl));
    arr.splice(index, 1);
    setVideos([]);
    arr.sort((a, b) => a.sl - b.sl);
    if (arr.length > 0) {
      let obj = { ...arr[0] };
      obj.default = true;
      arr[0] = obj;
    }
    setVideos([...arr]);
  };

  const handleVideoChange = (e) => {
    let arr = [...videos];
    const index = arr.indexOf(arr.find((i) => i.sl === changeVideoBuffer));
    arr[index].file = e.target.files[0];
    setVideos([]);
    arr.sort((a, b) => a.sl - b.sl);
    setVideos([...arr]);
  };

  const handleEditVideoAlt = (sl) => {
    setEditVideoDlg(true);
    setEditBuffer(sl);
  };

  const handleMakeDefault = (sl) => {
    let arr = [...videos];
    let arr2 = [];
    arr.forEach((x) => {
      let obj = { ...x };
      obj.default = false;
      arr2.push(obj);
    });
    arr = arr2;
    const index = arr.indexOf(arr.find((i) => i.sl === sl));
    arr[index].default = true;
    setVideos([]);
    arr.sort((a, b) => a.sl - b.sl);
    setVideos([...arr]);
  };

  const handleCloseEdit = () => {
    setEditVideoDlg(false);
    setEditBuffer("");
    setAltTag("");
  };

  const handleChangeAltTag = () => {
    let arr = [...videos];
    const index = arr.indexOf(arr.find((i) => i.sl === editBuffer));
    arr[index].alt = altTag;
    setVideos([]);
    arr.sort((a, b) => a.sl - b.sl);
    setVideos([...arr]);
    setEditVideoDlg(false);
    setEditBuffer("");
    setAltTag("");
  };

  const handleAdditionalUrl = () => {
    let arr = [...videos];
    let check = additionalUrl.includes("http");
    if (check) {
      const obj = {
        sl: arr.length,
        file: additionalUrl,
        alt: "",
        default: false,
        externalLink: true,
      };
      arr.push(obj);
      setAdditionalUrl("");
      setAddUrl(false);
    } else {
      dispatch(
        createNoti({
          message: "Invalid URL",
          severity: "error",
        })
      );
    }
    setVideos([]);
    arr.sort((a, b) => a.sl - b.sl);
    setVideos([...arr]);
  };

  const handleAdditionalVideo = (e) => {
    let arr = [...videos];
    let check = checkIfValidVideo(e.target.files[0].name);
    if (check) {
      const obj = {
        sl: arr.length,
        file: e.target.files[0],
        alt: "",
        default: false,
        externalLink: false,
      };
      arr.push(obj);
    } else {
      dispatch(
        createNoti({
          message: "Non-supported files are excluded",
          severity: "error",
        })
      );
    }
    setVideos([]);
    arr.sort((a, b) => a.sl - b.sl);
    setVideos([...arr]);
  };

  // ! translate
  const [siteLang, setSiteLang] = useState("");
  // Local Store
  const localLangData = JSON.parse(localStorage.getItem("lang"));
  // Store
  const { langs, activeLang } = useSelector((state) => state.misc);
  const [langSettings1, setLangSettings1] = useState([]);
  const [langSettings2, setLangSettings2] = useState([]);
  const title1 = "Add Medicine Dialog";
  const title2 = "Misc Words";

  useEffect(() => {
    if (langs && langs.length > 0 && siteLang !== "") {
      const L = langs.find((l) => l.langCode === siteLang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      const A = arr.find((l) => l._title === title1);
      if (A) setLangSettings1(A.data);
      const B = arr.find((l) => l._title === title2);
      if (B) setLangSettings2(B.data);
    }
  }, [siteLang, langs]);

  const translate1 = (String) => translation(String, title1, 1);
  const translate2 = (String) => translation(String, title2, 2);

  const translation = (String, title, index) => {
    let translation = "";
    let settings = [];
    if (index === 1) {
      settings = langSettings1;
    } else if (index === 2) {
      settings = langSettings2;
    }
    const T = settings.find((ls) => ls.title === String);
    if (T && T.value) translation = T.value;
    else {
      let arr = [];
      if (langs && langs.length > 0 && siteLang !== "") {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.settings && LEn.settings.length > 0) arr = LEn.settings;
        const S = arr.find((l) => l._title === title);
        if (S) {
          const T = S.data.find((ls) => ls.title === String);
          if (T && T.value) translation = T.value;
        }
      }
    }
    return translation;
  };

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setSiteLang(activeLang.langCode);
    } else {
      if (localLangData) {
        setSiteLang(localLangData.langCode);
      }
    }
  }, [activeLang]);
  // ! translate
  return (
    <Box>
      <Typography variant="h6" color="success" align="center">
        {translate1("Video")}
        <Tooltip
          title={
            translate1("supports") +
            " supports .mp4, .mov, .avi, .flv, .wmv, .webm, .m4v"
          }
          placement="top"
          arrow
        >
          <IconButton size="small">
            <InfoIcon color="info" />
          </IconButton>
        </Tooltip>
      </Typography>
      <Divider />
      <Grid container spacing={0} justifyContent="center">
        <Grid item xs={10} md={10}>
          <Grid
            sx={{ mt: 1 }}
            container
            spacing={1}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Grid container spacing={1} justifyContent="center">
                {videos.length > 0 &&
                  [...videos].map((x, i) => (
                    <Grid item xs={12} key={i}>
                      <Grid
                        container
                        spacing={0}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid
                          item
                          xs={10}
                          md={6}
                          sx={{
                            border: x.default
                              ? ".3rem solid chocolate"
                              : ".2rem solid lightblue",
                            borderRadius: ".25rem",
                            p: 0.5,
                          }}
                        >
                          {x.externalLink ? (
                            <ReactPlayer
                              url={
                                x.src
                                  ? x.src
                                  : x.file && x.file !== "" && x.file
                              }
                              controls
                              height={"150px"}
                              width={"100%"}
                            />
                          ) : (
                            <CardMedia
                              component="video"
                              height={150}
                              image={
                                x.src
                                  ? PF +
                                    (path
                                      ? path
                                      : "adminPanel/medicines/vid/") +
                                    x.src
                                  : x.file &&
                                    x.file !== "" &&
                                    URL.createObjectURL(x.file)
                              }
                              controls
                            />
                          )}
                        </Grid>
                        <Grid item xs={2}>
                          <Grid
                            container
                            spacing={0}
                            justifyContent="center"
                            flexDirection="column"
                            alignItems="center"
                          >
                            <Grid item>
                              <Tooltip
                                title={translate1("Make Default")}
                                arrow
                                placement="bottom"
                              >
                                <IconButton
                                  size="small"
                                  onClick={() => handleMakeDefault(x.sl)}
                                  sx={{ pointerEvents: x.default && "none" }}
                                >
                                  <CheckCircleIcon
                                    color={x.default ? "success" : "inherit"}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                            <Grid item>
                              <Tooltip
                                title={translate1("Edit Alt tag")}
                                arrow
                                placement="bottom"
                              >
                                <IconButton
                                  size="small"
                                  onClick={() => handleEditVideoAlt(x.sl)}
                                >
                                  <ModeEditIcon color="info" />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                            <Grid item>
                              <label htmlFor="change-video-file">
                                <Input
                                  id="change-video-file"
                                  required
                                  type="file"
                                  sx={{ display: "none" }}
                                  onChange={handleVideoChange}
                                />
                                <Tooltip
                                  title={translate1("Change")}
                                  arrow
                                  placement="bottom"
                                >
                                  <IconButton
                                    size="small"
                                    component="span"
                                    onClick={() => setChangeVideoBuffer(x.sl)}
                                  >
                                    <CameraswitchIcon color="warning" />
                                  </IconButton>
                                </Tooltip>
                              </label>
                            </Grid>
                            <Grid item>
                              <Tooltip
                                title={translate1("Delete")}
                                arrow
                                placement="bottom"
                              >
                                <IconButton
                                  size="small"
                                  onClick={() => handleRemoveSingle(x.sl)}
                                >
                                  <ClearIcon color="error" />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      {!x.externalLink && typeof x.file !== "undefined" && (
                        <Button
                          variant="text"
                          size="small"
                          color="info"
                          fullWidth
                          sx={{ pointerEvents: "none" }}
                        >
                          {x.file.size > 1000000
                            ? (x.file.size / 1000000).toFixed(2) + " mb"
                            : (x.file.size / 1000).toFixed(2) + " kb"}
                        </Button>
                      )}
                    </Grid>
                  ))}
              </Grid>
            </Grid>
            {videos.length > 0 && (
              <Grid item>
                <Grid container spacing={0}>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="error"
                      fullWidth
                      size="small"
                      onClick={() => setVideos([])}
                    >
                      {translate1("Remove All")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <label htmlFor="additional-video-file">
                      <Input
                        accept="video/mp4,video/x-m4v,video/*"
                        id="additional-video-file"
                        required
                        type="file"
                        sx={{ display: "none" }}
                        onChange={handleAdditionalVideo}
                      />
                      <Tooltip title="Add Image" arrow placement="bottom">
                        <IconButton size="small" component="span">
                          <AddCircleIcon color="success" />
                        </IconButton>
                      </Tooltip>
                    </label>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {videos.length < 1 && (
              <Box sx={{ position: "relative" }}>
                <Grid item>
                  <LazyLoadImage
                    title="Upload Video"
                    effect="blur"
                    src="https://www.daveraine.com/img/products/no-image.jpg"
                    alt="Upload Product"
                    style={{
                      objectFit: "contain",
                      width: "200px",
                      height: "160px",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  sx={{ position: "absolute", top: "40%", left: "40%" }}
                >
                  <label htmlFor="icon-video-file">
                    <Input
                      accept="video/mp4,video/x-m4v,video/*"
                      id="icon-video-file"
                      required
                      type="file"
                      inputProps={{ multiple: true }}
                      sx={{ display: "none" }}
                      onChange={handleVideosUpload}
                    />
                    <IconButton
                      color="primary"
                      aria-label="upload-picture"
                      component="span"
                    >
                      <PhotoCamera color="error" />
                    </IconButton>
                  </label>
                </Grid>
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            size="small"
            fullWidth
            startIcon={<AddCircleIcon />}
            sx={{ mt: 2, height: "160px" }}
            onClick={() => setAddUrl(true)}
          >
            {translate1("Url")}
          </Button>
        </Grid>
      </Grid>
      {/* Add URL dialog */}
      <Dialog
        open={addUrl}
        onClose={() => setAddUrl(false)}
        aria-labelledby={"additional-url-add"}
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle>{translate1("Add Url")}</DialogTitle>
        <DialogContent>
          <TextField
            label={translate1("Url")}
            value={additionalUrl}
            onChange={(e) => setAdditionalUrl(e.target.value)}
            fullWidth
            variant="standard"
            size="small"
          />
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            startIcon={<AddCircleIcon />}
            sx={{ mt: 1 }}
            disabled={additionalUrl === "" ? true : false}
            onClick={handleAdditionalUrl}
          >
            {translate1("Add")}
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddUrl(false)} color="error">
            {translate1("Close")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Alt Tag */}
      <Dialog
        open={editVideoDlg}
        onClose={handleCloseEdit}
        aria-labelledby={"edit-alt-tag"}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle>Edit Alt Tag</DialogTitle>
        <DialogContent>
          <Grid container spacing={0} alignItems="center">
            <Grid item xs={11}>
              <TextField
                label="Alt Tag"
                value={altTag}
                onChange={(e) => setAltTag(e.target.value)}
                size="small"
                variant="standard"
                fullWidth
                helperText={
                  <>
                    {infoHelper && (
                      <Typography component="span" variant="body2" color="info">
                        Keeping it empty will set default alt tag to image
                        dynamically
                      </Typography>
                    )}
                  </>
                }
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton
                size="small"
                onClick={() => setInfoHelper(!infoHelper)}
                sx={{ mt: 1.5 }}
              >
                <InfoIcon color="info" />
              </IconButton>
            </Grid>
          </Grid>
          <Button
            variant="contained"
            size="small"
            color="secondary"
            fullWidth
            onClick={handleChangeAltTag}
            disabled={altTag === "" ? true : false}
          >
            Save
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEdit} color="error">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
