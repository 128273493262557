import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useEffect, useState } from "react";
import { monthNameArr } from "../../data/monthNameArr";

// Ck Editor
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { useDispatch } from "react-redux";
import {
  createNoti,
  uploadTempPic,
} from "../../../../../reduxToolkit/features/adminPanel/misc";
import { PF } from "../../../../../config";
import PreviewInvoiceNew from "./PreviewInvoiceNew";

function ManualInvoiceForBismibEnterprise() {
  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const [invoiceNo, setInvoiceNo] = useState("");

  useEffect(() => {
    const d = new Date(invoiceDate);
    let monthName = monthNameArr[d.getMonth()].shortName;
    let monthIndex = Number(d.getMonth()) + 1;
    if (Number(monthIndex) < 10) monthIndex = "0" + monthIndex;
    let date = d.getDate();
    if (Number(date) < 10) date = "0" + date;
    let fullYear = d.getFullYear();
    let year = fullYear.toString().slice(2, 4);
    const updatedInvoiceNo = `${monthName}/${date}/${monthIndex}/${year}/BIS`;
    setInvoiceNo(updatedInvoiceNo);
  }, [invoiceDate]);

  const [paymentMedia, setPaymentMedia] = useState("Bank");

  const [companyPhone, setCompanyPhone] = useState("+971 50 587 9101");

  // ! CK Editor Image Upload

  const dispatch = useDispatch();
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          let tempName = Math.floor(Math.random() * (10000 - 0) + 0).toString();
          loader.file
            .then((file) => {
              const data = new FormData();
              if (file) {
                tempName = tempName + "." + file.name.split(".").pop();
                data.append("name", tempName);
                data.append("tempImg", file);
                dispatch(uploadTempPic(data));
                dispatch(
                  createNoti({
                    message: "Pls click on the image Confirm Image Upload",
                    severity: "warning",
                  })
                );
              }
            })
            .then(() => {
              resolve({
                default: `${PF}temp/${tempName}`,
              });
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }
  // ! CK Editor Image Upload

  const [clientInfo, setClientInfo] = useState("");

  const handleClientInfo = (e, editor) => {
    const data = editor.getData();

    setClientInfo(data);
  };

  const [paymentAmount, setPaymentAmount] = useState("$");

  const [prevDlg, setPrevDlg] = useState(false);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={1.5}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MobileDatePicker
            label="Date"
            value={invoiceDate}
            onChange={(newValue) => setInvoiceDate(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Grid>

      <Grid item xs={12} sm={10.5}>
        <TextField
          label="Invoice No"
          value={invoiceNo}
          onChange={(e) => setInvoiceNo(e.target.value)}
          size="large"
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Payment Media"
          value={paymentMedia}
          onChange={(e) => setPaymentMedia(e.target.value)}
          size="large"
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Company Phone Number"
          value={companyPhone}
          onChange={(e) => setCompanyPhone(e.target.value)}
          size="large"
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" fullWidth sx={{ mb: 1 }}>
          Client Info
        </Typography>
        <CKEditor
          editor={Editor}
          data={clientInfo}
          config={{
            extraPlugins: [uploadPlugin],
          }}
          onBlur={(e, editor) => handleClientInfo(e, editor)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Payment Amount"
          value={paymentAmount}
          onChange={(e) => setPaymentAmount(e.target.value)}
          size="large"
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          size="small"
          sx={{ mb: 1 }}
          fullWidth
          color="error"
          onClick={() => setPrevDlg(true)}
        >
          Preview
        </Button>
      </Grid>

      {prevDlg && (
        <PreviewInvoiceNew
          open={prevDlg}
          setOpen={setPrevDlg}
          invoiceDate={invoiceDate}
          invoiceNo={invoiceNo}
          paymentMedia={paymentMedia}
          companyPhone={companyPhone}
          clientInfo={clientInfo}
          paymentAmount={paymentAmount}
        />
      )}
    </Grid>
  );
}

export default ManualInvoiceForBismibEnterprise;
