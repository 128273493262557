import React, { useEffect, useState } from "react";
import "../../components/home/deal.css";
import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation, Scrollbar, Keyboard, Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import ProductSmall from "../product/ProductSmall";
import { Alert, Typography } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import {
  getMedCategories,
  getMedicines,
} from "../../reduxToolkit/features/adminPanel/medicine";
import LoadingBox from "../misc/Loading";

export default function RelatedProducts({
  lang,
  subCatInfo,
  genericInfo,
  fixedMedicineId,
}) {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  // Use State
  const [catList, setCatList] = useState([]);
  const [medList, setMedList] = useState([]);
  const [medLoading, setMedLoading] = useState(false);

  // store
  const { medicines, categories } = useSelector((state) => state.medicine);

  // Use Effect
  // const dispatch = useDispatch();
  // useEffect(() => {
  // dispatch(getMedCategories());
  // dispatch(getMedicines());
  // }, []);

  // set Category
  useEffect(() => {
    if (lang !== "" && categories && categories.length > 0) {
      let C = categories.find((c) => c.lang === lang);
      if (C) setCatList(C.data);
      else setCatList([]);
    }
  }, [categories, lang]);

  useEffect(() => {
    setMedLoading(true);
    if (lang !== "" && medicines && medicines.length > 0) {
      let M = medicines.find((m) => m.lang === lang);
      if (M) {
        let arr = [];
        M.data.forEach((x) => {
          // ! Code For showing related products based on sub category
          if (
            x.generic &&
            x.generic.toLocaleLowerCase().replace(/\W+/g, "") ===
              genericInfo.toLocaleLowerCase().replace(/\W+/g, "")
          )
            arr.push(x);
          // x.subCats.forEach((y) => {
          //   subCatInfo.forEach((z) => z.subCatId === y.subCatId && arr.push(x));
          // });
        });
        let tempArr = [...arr];
        let unique = [
          ...new Map(
            tempArr.map((item) => [item["fixedMedicineId"], item])
          ).values(),
        ];
        unique = unique.filter((u) => u.fixedMedicineId !== fixedMedicineId);
        setMedList(
          [...unique].sort((a, b) =>
            a.genericBrand.toLowerCase() > b.genericBrand.toLowerCase() ? 1 : -1
          )
        );
      } else {
        setMedList([]);
      }
    }
    setTimeout(() => {
      setMedLoading(false);
    }, 1000);
  }, [medicines, lang, subCatInfo, fixedMedicineId]);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate

  return (
    <>
      {medList.length > 0 && (
        <>
          <Typography
            variant="h4"
            align="center"
            sx={{ textDecoration: "underline", mb: 1 }}
          >
            {translateFront("Related Products")}
          </Typography>
          <Swiper
            slidesPerView={!smUp ? 1 : !mdUp ? 2 : 3}
            grabCursor
            modules={[Navigation, Scrollbar, Keyboard, Autoplay]}
            // loop={{ loop: true }}
            autoplay={{ delay: 5000 }}
            keyboard={{ enabled: true }}
            spaceBetween={4}
            navigation
            scrollbar={{ draggable: true }}
            className="deal"
            style={{ margin: mdUp && "0 15rem" }}
          >
            {medLoading ? (
              <LoadingBox w={"100%"} />
            ) : medList.length < 1 ? (
              <Alert
                severity="warning"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 300,
                }}
              >
                {translateFront("No Related Products...")}
              </Alert>
            ) : (
              medList.map((x, i) => (
                <SwiperSlide key={i}>
                  <ProductSmall
                    x={x}
                    i={i}
                    view={0}
                    catList={catList}
                    lang={lang}
                  />
                </SwiperSlide>
              ))
            )}
          </Swiper>
        </>
      )}
    </>
  );
}
