import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../config";

// Create Payment Method
export const createPaymentMethod = createAsyncThunk(
  "payment/createPaymentMethod",
  async (data) => {
    const res = await axiosInstance.post("/api/payment/create", data);
    return res.data;
  }
);

// get all Payment Methods
export const getAllPaymentMethods = createAsyncThunk(
  "payment/getAllPaymentMethods",
  async () => {
    const res = await axiosInstance.get("/api/payment/get-all");
    return res.data;
  }
);

// remove Payment method
export const removePaymentMethods = createAsyncThunk(
  "payment/removePaymentMethods",
  async (data) => {
    const res = await axiosInstance.post("/api/payment/remove", data);
    return res.data;
  }
);

// Update Payment Method
export const updatePaymentMethod = createAsyncThunk(
  "payment/updatePaymentMethod",
  async (data) => {
    const res = await axiosInstance.post("/api/payment/update", data);
    return res.data;
  }
);

// upload Bar Code Image
export const uploadBarcodeImg = createAsyncThunk(
  "payment/uploadBarcodeImg",
  async (data) => {
    const res = await axiosInstance.post("/api/multer/singleBarCode/", data);
    return res.data;
  }
);

// Initial State
const initialState = {
  pending: false,
  error: false,
  paymentMethods: [],
  snackNotiPayment: {},
};

// Slice
export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    resetSnkPayment(state) {
      state.snackNotiPayment = {};
    },
  },
  extraReducers: {
    [createPaymentMethod.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [createPaymentMethod.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiPayment = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [createPaymentMethod.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [getAllPaymentMethods.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getAllPaymentMethods.fulfilled]: (state, action) => {
      state.pending = false;
      state.paymentMethods = action.payload;
    },
    [getAllPaymentMethods.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [removePaymentMethods.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [removePaymentMethods.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiPayment = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [removePaymentMethods.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [updatePaymentMethod.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [updatePaymentMethod.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiPayment = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [updatePaymentMethod.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [uploadBarcodeImg.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [uploadBarcodeImg.fulfilled]: (state, action) => {
      state.pending = false;
    },
    [uploadBarcodeImg.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
  },
});

export const { resetSnkPayment } = paymentSlice.actions;
export default paymentSlice.reducer;
