import { Alert, Divider, List, ListItem } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getExactTime } from "../../hooks/getCreatedData";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function LatestChangesMade() {
  // Responsive
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [changes, setChanges] = useState([]);
  const [counter, setCounter] = useState(0);
  const [pending, setPending] = useState(true);

  // Store
  const { notifications } = useSelector((state) => state.notification);

  useEffect(() => {
    // dispatch(getChangesMade(changesMadeBuffer));
    if (counter < 2) {
      setPending(true);
      let arr = [...notifications];
      arr.sort((a, b) => b.createdAt - a.createdAt);
      setChanges(arr.slice(0, mdUp ? 8 : 6));
      setPending(false);
      setCounter(counter + 1);
    }
  }, [changes, counter, notifications]);

  return (
    <>
      {changes.length > 0 ? (
        <>
          <List>
            {changes.map((x, i) => (
              <Fragment key={i}>
                <ListItem disablePadding>
                  <Alert
                    severity={x.severity || "info"}
                    sx={{
                      width: "100%",
                    }}
                  >
                    {`${x.title}`} <br />
                    {`${getExactTime(x.createdAt)}`}
                  </Alert>
                </ListItem>
                <Divider />
              </Fragment>
            ))}
          </List>
        </>
      ) : (
        <Alert severity="warning">No Changes</Alert>
      )}
    </>
  );
}
