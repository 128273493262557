import { CssBaseline } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import Missing from "./components/404/Missing";
import LoadingBox from "./components/misc/Loading";
import SiteMap from "./components/misc/SiteMap";
import { axiosInstance } from "./config";
import { stopPreloader } from "./reduxToolkit/features/miscFront";
import DashBoard from "./screens/adminPanel/DashBoard";
import Blog from "./screens/Blog";
import Category from "./screens/Category";
import ContactUsScreen from "./screens/ContactUsScreen";
import FrontEndLayout from "./screens/FrontEndLayout";
import Home from "./screens/Home";
import HowToOrderScreen from "./screens/HowToOrderScreen";
import OurMedicines from "./screens/OurMedicines";
// import SingleBlog from "./screens/SingleBlog";
import SingleMedicine from "./screens/SingleMedicine";
import SingleMedicineVariation2 from "./screens/SingleMedicineVariation2";
import TestimonialScreen from "./screens/TestimonialScreen";
import Unblock from "./screens/Unblock";

import { HelmetProvider } from "react-helmet-async";
import SingleBlog from "./screens/SingleBlog";
import MaintenanceMode from "./components/MaintenanceMode/MaintenanceMode";
import { getSettings } from "./reduxToolkit/features/adminPanel/webSettings";
import PreviewLeafletPage from "./screens/adminPanel/medicines/components/leaflet/PreviewLeafletPage";
import PreviewBrochurePage from "./screens/adminPanel/medicines/components/brochure/PreviewBrochurePage";
function App() {
  const dispatch = useDispatch();
  const [preLoader, setPreLoader] = useState(false);
  const [policyOpen, setPolicyOpen] = useState(false);
  const [policyName, setPolicyName] = useState("");
  const [adminUrl, setAdminUrl] = useState("");
  const [medicinePageVariation, setMedicinePageVariation] = useState(0);

  const { webSettings } = useSelector((state) => state.webSettings);
  // Use Effect
  useEffect(() => {
    getAdminUrl();
  }, []);

  useEffect(() => {
    setPreLoader(true);
    setTimeout(() => {
      setPreLoader(false);
      if (!webSettings) dispatch(getSettings());
    }, 1000);
  }, []);

  const getAdminUrl = async () => {
    const res = await axiosInstance.get("/api/adminUrl");
    setAdminUrl(res.data.url);
  };

  // Handle Maintenance Mode
  const [maintenanceMode, setMaintenanceMode] = useState(undefined);
  // const [maintenanceTime, setMaintenanceTime] = useState(0);
  const [maintenanceExpireTime, setMaintenanceExpireTime] = useState(0);

  useEffect(() => {
    if (webSettings) {
      if (webSettings.maintenanceMode) setMaintenanceMode(true);
      else setMaintenanceMode(false);
      // if (webSettings.maintenanceMinute)
      //   setMaintenanceTime(webSettings.maintenanceMinute);
      // else setMaintenanceTime(0);
      if (webSettings.maintenanceExpireTime)
        setMaintenanceExpireTime(webSettings.maintenanceExpireTime);
      else setMaintenanceExpireTime(0);
    }
  }, [webSettings]);

  return (
    <>
      <CssBaseline />
      <HelmetProvider>
        {preLoader || maintenanceMode === undefined ? (
          <LoadingBox w={"100%"} h={"100vh"} />
        ) : !window.location.href.includes(adminUrl) && maintenanceMode ? (
          <MaintenanceMode maintenanceExpireTime={maintenanceExpireTime} />
        ) : (
          adminUrl !== "" && (
            <Routes>
              <Route
                path="/medicines/leaflet"
                element={<PreviewLeafletPage />}
              />
              <Route
                path="/medicines/brochure"
                element={<PreviewBrochurePage />}
              />
              <Route
                path="/"
                element={
                  <FrontEndLayout
                    policyOpen={policyOpen}
                    setPolicyOpen={setPolicyOpen}
                    policyName={policyName}
                    setPolicyName={setPolicyName}
                  />
                }
              >
                <Route index element={<Home />} />
                <Route path="/our-medicines">
                  <Route index element={<OurMedicines />} />
                  {medicinePageVariation === 0 ? (
                    <Route path=":id" element={<SingleMedicine />} />
                  ) : (
                    <Route path=":id" element={<SingleMedicineVariation2 />} />
                  )}
                </Route>
                <Route path="/category/:id" element={<Category />} />
                <Route path="/blog">
                  <Route index element={<Blog />} />
                  <Route path=":id" element={<SingleBlog />} />
                </Route>
                <Route path="/testimonials" element={<TestimonialScreen />} />
                <Route path="/contact-us" element={<ContactUsScreen />} />
                <Route path="/unblock-request" element={<Unblock />} />
                <Route
                  path="/how-to-order"
                  element={
                    <HowToOrderScreen
                      setPolicyOpen={setPolicyOpen}
                      setPolicyName={setPolicyName}
                    />
                  }
                />
                <Route path="/sitemap" element={<SiteMap />} />
                <Route path="*" element={<Missing />} />
              </Route>

              <Route
                path={`/${adminUrl}/*`}
                element={<DashBoard adminUrl={adminUrl} />}
              />
            </Routes>
          )
        )}
      </HelmetProvider>
    </>
  );
}

export default App;
