import React from "react";
import Testimonials from "../components/testimonial/Testimonials";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import TestimonialRules from "../components/testimonial/TestimonialRules";
import InqTestimonialForm from "../components/testimonial/InqTestimonialForm";
import BreadcrumbsTemplate from "../components/misc/BreadcrumbsTemplate";

export default function TestimonialScreen() {
  return (
    <>
      <BreadcrumbsTemplate b3="Testimonial" />
      <Grid container spacing={0} justifyContent="space-around">
        <Grid item xs={12} sm={6}>
          <Typography
            variant="h1"
            align="center"
            sx={{
              fontSize: { xs: "1.5rem", sm: "2.5rem" },
              textDecoration: "underline",
            }}
          >
            Our Valued Customers
          </Typography>
          <Testimonials />
        </Grid>
        <Grid item xs={11.5} sm={5.5} sx={{ mr: 1 }}>
          <Grid container spacing={0} flexDirection="column">
            <Grid item>
              <InqTestimonialForm />
            </Grid>
            <Grid item>
              <TestimonialRules />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
