import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../config";
// TODO SEO SECTION ---------------------------------------------------------------
// Create Seo Tags
export const createSeoTags = createAsyncThunk(
  "medicine/createSeoTags",
  async (data) => {
    const res = await axiosInstance.post("/api/seoTags/", data);
    return res.data;
  }
);

// Get Seo Tags
export const getSeoTags = createAsyncThunk("medicine/getSeoTags", async () => {
  const res = await axiosInstance.get("/api/seoTags/");
  return res.data;
});

// Delete Seo Tags
export const deleteSeoTags = createAsyncThunk(
  "medicine/deleteSeoTags",
  async (data) => {
    const { id, ...others } = data;
    const res = await axiosInstance.put(`/api/seoTags/delete/${id}`, others);
    return res.data;
  }
);

// Edit Seo Tags
export const editSeoTags = createAsyncThunk(
  "medicine/editSeoTags",
  async (data) => {
    const { id, ...others } = data;
    const res = await axiosInstance.put(`/api/seoTags/edit/${id}`, others);
    return res.data;
  }
);
// TODO Category Section SECTION ---------------------------------------------------------------
// create category
export const createMedCategory = createAsyncThunk(
  "medicine/createMedCategory",
  async (data) => {
    const res = await axiosInstance.post("/api/medicineCategory/", data);
    return res.data;
  }
);

// get Categories
export const getMedCategories = createAsyncThunk(
  "medicine/getMedCategories",
  async () => {
    const res = await axiosInstance.get("/api/medicineCategory/");
    return res.data;
  }
);

// Delete Categories
export const deleteCat = createAsyncThunk(
  "medicine/deleteSeoTags",
  async (data) => {
    const { id, ...others } = data;
    const res = await axiosInstance.put(
      `/api/medicineCategory/delete/${id}`,
      others
    );
    return res.data;
  }
);

// Edit Cat
export const editCat = createAsyncThunk(
  "medicine/editSeoTags",
  async (data) => {
    const { id, ...others } = data;
    const res = await axiosInstance.put(
      `/api/medicineCategory/edit/${id}`,
      others
    );
    return res.data;
  }
);

// TODO Generic Company Section SECTION ---------------------------------------------------------------
// create Company
export const createGenCompany = createAsyncThunk(
  "medicine/createGenCompany",
  async (data) => {
    const res = await axiosInstance.post("/api/genCompany/", data);
    return res.data;
  }
);

// upload Company Logo
export const uploadCompanyLogo = createAsyncThunk(
  "medicine/uploadCompanyLogo",
  async (data) => {
    const res = await axiosInstance.post(
      "/api/multer/singleGenCompanyLogo/",
      data
    );
    return res.data;
  }
);

export const getCompanies = createAsyncThunk(
  "medicine/getCompanies",
  async () => {
    const res = await axiosInstance.get("/api/genCompany/");
    return res.data;
  }
);

export const deleteCompany = createAsyncThunk(
  "medicine/deleteCompany",
  async (data) => {
    const { id, ...others } = data;
    const res = await axiosInstance.put(`/api/genCompany/delete/${id}`, others);
    return res.data;
  }
);

export const editGenCompany = createAsyncThunk(
  "medicine/editCompany",
  async (data) => {
    const { id, ...others } = data;
    const res = await axiosInstance.put(`/api/genCompany/${id}`, others);
    return res.data;
  }
);

// TODO Medicine Section SECTION ---------------------------------------------------------------
export const createMedicine = createAsyncThunk(
  "medicine/createMedicine",
  async (data) => {
    const res = await axiosInstance.post("/api/medicine/", data);
    return res.data;
  }
);

export const uploadMedicineImages = createAsyncThunk(
  "medicine/uploadMedicineImages",
  async (data) => {
    const res = await axiosInstance.post("/api/multer/medicineImg", data);
    return res.data;
  }
);

export const uploadMedicineVideos = createAsyncThunk(
  "medicine/uploadMedicineVideos",
  async (data) => {
    const res = await axiosInstance.post("/api/multer/medicineVid", data);
    return res.data;
  }
);

export const getMedicines = createAsyncThunk(
  "medicine/getMedicines",
  async () => {
    const res = await axiosInstance.get("/api/medicine/");
    return res.data;
  }
);

export const getMedicinesByLang = createAsyncThunk(
  "medicine/getMedicinesByLang",
  async (data) => {
    const res = await axiosInstance.get(`/api/medicine/by-lang/${data.lang}`);
    return res.data;
  }
);

export const removeSingleMedicine = createAsyncThunk(
  "medicine/removeSingleMedicine",
  async (data) => {
    const { id, ...others } = data;
    const res = await axiosInstance.put(`/api/medicine/remove/${id}`, others);
    return res.data;
  }
);

export const updateMedicine = createAsyncThunk(
  "medicine/updateMedicine",
  async (data) => {
    const { id, ...others } = data;
    const res = await axiosInstance.post(`/api/medicine/update/${id}`, others);
    return res.data;
  }
);

export const createDosage = createAsyncThunk(
  "dosage/createDosage",
  async (data) => {
    const res = await axiosInstance.post("/api/dosage/", data);
    return res.data;
  }
);

export const getDosages = createAsyncThunk("dosage/getDosages", async () => {
  const res = await axiosInstance.get("/api/dosage/");
  return res.data;
});

export const removeDosage = createAsyncThunk(
  "dosage/removeDosage",
  async (id) => {
    const res = await axiosInstance.put("/api/dosage/remove/", id);
    return res.data;
  }
);

export const editDosage = createAsyncThunk(
  "dosage/editDosage",
  async (data) => {
    const res = await axiosInstance.post("/api/dosage/edit/", data);
    return res.data;
  }
);

// TODO Advanced Medicine Settings Section
export const removeAllMedInfo = createAsyncThunk(
  "medicine/removeAllMedInfo",
  async () => {
    const res = await axiosInstance.post("/api/medicine/remove-all-info");
    return res.data;
  }
);

export const getUnusedImg = createAsyncThunk(
  "medicine/getUnusedImg",
  async () => {
    const res = await axiosInstance.get("/api/medicine/get-unused-img");
    return res.data;
  }
);

export const getUnusedVid = createAsyncThunk(
  "medicine/getUnusedVid",
  async () => {
    const res = await axiosInstance.get("/api/medicine/get-unused-vid");
    return res.data;
  }
);

export const getUnusedFiles = createAsyncThunk(
  "medicine/getUnusedFiles",
  async () => {
    const res = await axiosInstance.get("/api/medicine/get-unused-files");
    return res.data;
  }
);

export const removeJunkFiles = createAsyncThunk(
  "medicine/removeJunkFiles",
  async (data) => {
    const res = await axiosInstance.post(
      "/api/medicine/remove-junk-files",
      data
    );
    return res.data;
  }
);

export const removeAllMedicine = createAsyncThunk(
  "medicine/remove-all-med",
  async () => {
    const res = await axiosInstance.post("/api/medicine/remove-all-med");
    return res.data;
  }
);

export const removeAllCompanies = createAsyncThunk(
  "medicine/remove-all-companies",
  async () => {
    const res = await axiosInstance.post("/api/medicine/remove-all-companies");
    return res.data;
  }
);

export const removeAllCategories = createAsyncThunk(
  "medicine/remove-all-cats",
  async () => {
    const res = await axiosInstance.post("/api/medicine/remove-all-cats");
    return res.data;
  }
);

export const removeAllDosages = createAsyncThunk(
  "medicine/remove-all-dosages",
  async () => {
    const res = await axiosInstance.post("/api/medicine/remove-all-dosages");
    return res.data;
  }
);

export const removeAllSeoTags = createAsyncThunk(
  "medicine/remove-all-tags",
  async () => {
    const res = await axiosInstance.post("/api/medicine/remove-all-tags");
    return res.data;
  }
);

export const removeAllSuppliers = createAsyncThunk(
  "medicine/remove-all-suppliers",
  async () => {
    const res = await axiosInstance.post("/api/medicine/remove-all-suppliers");
    return res.data;
  }
);

export const removeAllUnusedImg = createAsyncThunk(
  "medicine/remove-all-unused-img",
  async () => {
    const res = await axiosInstance.post("/api/medicine/remove-all-unused-img");
    return res.data;
  }
);

export const removeAllUnusedVid = createAsyncThunk(
  "medicine/remove-all-unused-vid",
  async () => {
    const res = await axiosInstance.post("/api/medicine/remove-all-unused-vid");
    return res.data;
  }
);

export const removeAllUploads = createAsyncThunk(
  "medicine/remove-all-uploads",
  async () => {
    const res = await axiosInstance.post("/api/medicine/remove-all-uploads");
    return res.data;
  }
);

export const postViewCount = createAsyncThunk(
  "medicine/post-view-count",
  async (data) => {
    const res = await axiosInstance.post("/api/view-count/", data);
    return res.data;
  }
);

export const getViewCount = createAsyncThunk(
  "medicine/get-view-count",
  async () => {
    const res = await axiosInstance.get(`/api/view-count/`);
    return res.data;
  }
);

// TODO Brochure / Leaflet
export const leafletPdfDownload = createAsyncThunk(
  "medicine/leaflet-pdf-download",
  async (data) => {
    const res = await axiosInstance.post(
      `/api/medicine/leaflet-pdf-download`,
      data
    );
    return res.data;
  }
);

// TODO Brochure / Leaflet
export const invoicePdfDownload = createAsyncThunk(
  "medicine/invoice-pdf-download",
  async (data) => {
    const res = await axiosInstance.post(
      `/api/medicine/invoice-pdf-download`,
      data
    );
    return res.data;
  }
);

// TODO Brochure / Leaflet
export const expensePdfDownload = createAsyncThunk(
  "medicine/expense-pdf-download",
  async (data) => {
    const res = await axiosInstance.post(
      `/api/medicine/expense-pdf-download`,
      data
    );
    return res.data;
  }
);

// TODO Replace Text In Medicine Additional Section
export const replaceTextInMedicineAdditionalSection = createAsyncThunk(
  "medicine/replace-text-in-medicine-additional-section",
  async (data) => {
    const res = await axiosInstance.post(
      `/api/medicine/replace-text-in-medicine-additional-section`,
      data
    );
    return res.data;
  }
);

// initial State
const initialState = {
  pending: false,
  createSeoTagResponse: {},
  editTagResponse: {},
  seoTags: [],
  createCatResponse: {},
  editCatResponse: {},
  categories: {},
  dosages: {},
  createDosagesResponse: {},
  removeDosageResponse: {},
  editDosagesResponse: {},
  createGenCompanyResponse: {},
  editGenCompanyResponse: {},
  companies: {},
  createMedicineResponse: {},
  updateMedicineResponse: {},
  removeAllMedInfoResponse: {},
  unusedImages: {},
  unusedVids: {},
  unusedFiles: {},
  removeJunkFilesResponse: {},

  removeAllMedResponse: {},
  removeAllCompaniesResponse: {},
  removeAllCatResponse: {},
  removeAllDosageResponse: {},
  removeAllTagsResponse: {},
  removeAllSuppliersResponse: {},

  viewCountArr: [],

  leafletPdfDownloadResponse: {},
  invoicePdfDownloadResponse: {},
  expensePdfDownloadResponse: {},
  replaceTextInMedicineAdditionalSectionResponse: {},

  medicines: {},
  medVariants: {},
  error: false,
  snackNotiMedicine: {},
};

// Slice
export const medicineSlice = createSlice({
  name: "medicine",
  initialState,
  reducers: {
    resetSnkMedicine(state) {
      state.snackNotiMedicine = {};
      state.createCatResponse = {};
      state.createDosagesResponse = {};
      state.updateMedicineResponse = {};
      state.removeDosageResponse = {};
      state.editDosagesResponse = {};
      state.removeAllMedInfoResponse = {};
      state.removeJunkFilesResponse = {};
      state.createMedicineResponse = {};

      state.removeAllMedResponse = {};
      state.removeAllCompaniesResponse = {};
      state.removeAllCatResponse = {};
      state.removeAllDosageResponse = {};
      state.removeAllTagsResponse = {};
      state.removeAllSuppliersResponse = {};
      state.invoicePdfDownloadResponse = {};
      state.expensePdfDownloadResponse = {};
    },
    resetPdfResponse(state) {
      state.leafletPdfDownloadResponse = {};
    },

    resetReplaceAdditinalSectionResponse(state) {
      state.replaceTextInMedicineAdditionalSectionResponse = {};
    },
  },
  extraReducers: {
    // TODO SEO Section SECTION ---------------------------------------------------------------
    [createSeoTags.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [createSeoTags.fulfilled]: (state, action) => {
      state.pending = false;
      state.createSeoTagResponse = action.payload;
      state.snackNotiMedicine = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [createSeoTags.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [deleteSeoTags.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [deleteSeoTags.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiMedicine = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [deleteSeoTags.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [editSeoTags.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [editSeoTags.fulfilled]: (state, action) => {
      state.pending = false;
      state.editTagResponse = action.payload;
      state.snackNotiMedicine = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [editSeoTags.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [getSeoTags.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getSeoTags.fulfilled]: (state, action) => {
      state.pending = false;
      state.seoTags = action.payload;
    },
    [getSeoTags.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    // TODO Category Section SECTION ---------------------------------------------------------------
    [createMedCategory.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [createMedCategory.fulfilled]: (state, action) => {
      state.pending = false;
      state.createCatResponse = action.payload;
      state.snackNotiMedicine = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [createMedCategory.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [getMedCategories.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getMedCategories.fulfilled]: (state, action) => {
      state.pending = false;
      state.categories = action.payload;
    },
    [getMedCategories.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [deleteCat.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [deleteCat.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiMedicine = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [deleteCat.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [editCat.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [editCat.fulfilled]: (state, action) => {
      state.pending = false;
      state.editCatResponse = action.payload;
      state.snackNotiMedicine = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [editCat.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    // TODO  Generic Company SECTION ---------------------------------------------------------------
    [createGenCompany.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [createGenCompany.fulfilled]: (state, action) => {
      state.pending = false;
      state.createGenCompanyResponse = action.payload;
      state.snackNotiMedicine = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [createGenCompany.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [uploadCompanyLogo.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [uploadCompanyLogo.fulfilled]: (state) => {
      state.pending = false;
    },
    [uploadCompanyLogo.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [getCompanies.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getCompanies.fulfilled]: (state, action) => {
      state.pending = false;
      state.companies = action.payload;
    },
    [getCompanies.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [deleteCompany.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [deleteCompany.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiMedicine = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [deleteCompany.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [editGenCompany.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [editGenCompany.fulfilled]: (state, action) => {
      state.pending = false;
      state.editGenCompanyResponse = action.payload;
      state.snackNotiMedicine = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [editGenCompany.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    // TODO  Medicine SECTION ---------------------------------------------------------------
    [createMedicine.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [createMedicine.fulfilled]: (state, action) => {
      state.pending = false;
      state.createMedicineResponse = action.payload;
      state.snackNotiMedicine = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [createMedicine.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [uploadMedicineImages.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [uploadMedicineImages.fulfilled]: (state, action) => {
      state.pending = false;
    },
    [uploadMedicineImages.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [uploadMedicineVideos.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [uploadMedicineVideos.fulfilled]: (state, action) => {
      state.pending = false;
    },
    [uploadMedicineVideos.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [getMedicines.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getMedicines.fulfilled]: (state, action) => {
      state.pending = false;
      state.medicines = action.payload.medicines;
      state.medVariants = action.payload.variants;
    },
    [getMedicines.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [getMedicinesByLang.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getMedicinesByLang.fulfilled]: (state, action) => {
      state.pending = false;
      state.medicines = action.payload.medicines;
      state.medVariants = action.payload.variants;
    },
    [getMedicinesByLang.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [removeSingleMedicine.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [removeSingleMedicine.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiMedicine = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [removeSingleMedicine.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [updateMedicine.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [updateMedicine.fulfilled]: (state, action) => {
      state.pending = false;
      state.updateMedicineResponse = action.payload;
      state.snackNotiMedicine = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [updateMedicine.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    // TODO  Dosage SECTION ---------------------------------------------------------------
    [createDosage.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [createDosage.fulfilled]: (state, action) => {
      state.pending = false;
      state.createDosagesResponse = action.payload;
      state.snackNotiMedicine = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [createDosage.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [getDosages.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getDosages.fulfilled]: (state, action) => {
      state.pending = false;
      state.dosages = action.payload;
    },
    [getDosages.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [removeDosage.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [removeDosage.fulfilled]: (state, action) => {
      state.pending = false;
      state.removeDosageResponse = action.payload;
      state.snackNotiMedicine = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [removeDosage.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [editDosage.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [editDosage.fulfilled]: (state, action) => {
      state.pending = false;
      state.editDosagesResponse = action.payload;
      state.snackNotiMedicine = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [editDosage.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    // TODO  Advanced Medicine Settings SECTION ---------------------------------------------------------------
    [removeAllMedInfo.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [removeAllMedInfo.fulfilled]: (state, action) => {
      state.pending = false;
      state.removeAllMedInfoResponse = action.payload;
      state.snackNotiMedicine = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [removeAllMedInfo.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [getUnusedImg.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getUnusedImg.fulfilled]: (state, action) => {
      state.pending = false;
      state.unusedImages = action.payload;
    },
    [getUnusedImg.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [getUnusedVid.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getUnusedVid.fulfilled]: (state, action) => {
      state.pending = false;
      state.unusedVids = action.payload;
    },
    [getUnusedVid.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [getUnusedFiles.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getUnusedFiles.fulfilled]: (state, action) => {
      state.pending = false;
      state.unusedFiles = action.payload;
    },
    [getUnusedFiles.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [removeJunkFiles.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [removeJunkFiles.fulfilled]: (state, action) => {
      state.pending = false;
      state.removeJunkFilesResponse = action.payload;
      state.snackNotiMedicine = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [removeJunkFiles.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [removeAllMedicine.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [removeAllMedicine.fulfilled]: (state, action) => {
      state.pending = false;
      state.removeAllMedResponse = action.payload;
      state.snackNotiMedicine = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [removeAllMedicine.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [removeAllCompanies.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [removeAllCompanies.fulfilled]: (state, action) => {
      state.pending = false;
      state.removeAllCompaniesResponse = action.payload;
      state.snackNotiMedicine = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [removeAllCompanies.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [removeAllCategories.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [removeAllCategories.fulfilled]: (state, action) => {
      state.pending = false;
      state.removeAllCatResponse = action.payload;
      state.snackNotiMedicine = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [removeAllCategories.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [removeAllDosages.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [removeAllDosages.fulfilled]: (state, action) => {
      state.pending = false;
      state.removeAllDosageResponse = action.payload;
      state.snackNotiMedicine = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [removeAllDosages.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [removeAllSeoTags.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [removeAllSeoTags.fulfilled]: (state, action) => {
      state.pending = false;
      state.removeAllTagsResponse = action.payload;
      state.snackNotiMedicine = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [removeAllSeoTags.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [removeAllSuppliers.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [removeAllSuppliers.fulfilled]: (state, action) => {
      state.pending = false;
      state.removeAllSuppliersResponse = action.payload;
      state.snackNotiMedicine = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [removeAllSuppliers.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [removeAllUnusedImg.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [removeAllUnusedImg.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiMedicine = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [removeAllUnusedImg.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [removeAllUnusedVid.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [removeAllUnusedVid.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiMedicine = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [removeAllUnusedVid.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [removeAllUploads.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [removeAllUploads.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiMedicine = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [removeAllUploads.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    // View Count
    [postViewCount.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [postViewCount.fulfilled]: (state) => {
      state.pending = false;
    },
    [postViewCount.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    // Get View Count
    [getViewCount.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getViewCount.fulfilled]: (state, action) => {
      state.pending = false;
      state.viewCountArr = action.payload;
    },
    [getViewCount.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    // TODO Leaflet Brochure ---------------------------------------------------------------
    [leafletPdfDownload.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [leafletPdfDownload.fulfilled]: (state, action) => {
      state.pending = false;
      state.leafletPdfDownloadResponse = action.payload;
    },
    [leafletPdfDownload.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    // TODO Replace Text In Medicine Additional Section ---------------------------------------------------------------
    [replaceTextInMedicineAdditionalSection.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [replaceTextInMedicineAdditionalSection.fulfilled]: (state, action) => {
      state.pending = false;
      state.replaceTextInMedicineAdditionalSectionResponse = action.payload;
      state.snackNotiMedicine = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [replaceTextInMedicineAdditionalSection.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    // TODO Invoice PDF DOWNLOAD
    [invoicePdfDownload.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [invoicePdfDownload.fulfilled]: (state, action) => {
      state.pending = false;
      state.invoicePdfDownloadResponse = action.payload;
      state.snackNotiMedicine = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [invoicePdfDownload.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    // TODO Expesen PDF DOWNLOAD
    [expensePdfDownload.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [expensePdfDownload.fulfilled]: (state, action) => {
      state.pending = false;
      state.expensePdfDownloadResponse = action.payload;
      state.snackNotiMedicine = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [expensePdfDownload.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
  },
});

export const { resetSnkMedicine, resetPdfResponse } = medicineSlice.actions;
export default medicineSlice.reducer;
