import {
  Box,
  Button,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import React, { useEffect, useState } from "react";

// Icons
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

export default function SelectionByCategory({
  medList,
  categories,
  siteLang,
  setSelectedForPreview,
}) {
  const [catSelection, setCatSelection] = useState([]);
  const [selectedCatSelection, setSelectedCatSelection] = useState([]);
  const [viewCatDetailsBuffer, setViewCatDetailsBuffer] = useState(undefined);

  useEffect(() => {
    if (categories && categories.length > 0 && siteLang) {
      const C = categories.find((c) => c.lang === siteLang);
      let cArr = [];
      if (C && C.data) {
        C.data.forEach((x) => {
          let mArr = [];
          x.subCatList.forEach((y) => {
            medList.forEach((z) => {
              const subCatExist = z.subCats.find(
                (z) =>
                  z.subCatId ===
                  (siteLang === "en" ? y.fixedSubCatId : y.subCatDetectorId)
              );
              if (subCatExist) mArr.push({ ...z, catName: x.catName });
            });
          });
          const unique = [
            ...new Map(mArr.map((item) => [item["_id"], item])).values(),
          ];
          cArr.push({ data: unique, catName: x.catName });
        });
      }
      setCatSelection(cArr);
    }
  }, [categories, medList, siteLang]);

  useEffect(() => {
    if (siteLang) {
      let arr = [];
      selectedCatSelection.forEach((x) => {
        const Cs = catSelection.find((cs) => cs.catName === x);
        arr.push(...Cs.data);
      });
      const unique = [
        ...new Map(arr.map((item) => [item["_id"], item])).values(),
      ];
      setSelectedForPreview(unique);
    }
  }, [catSelection, selectedCatSelection, siteLang]);
  return (
    <>
      <Grid container flexDirection={"column"}>
        <Grid item xs={12}>
          <Box
            sx={{
              minHeight: "20vh",
              overflow: "auto",
              border: "1px solid lightblue",
              borderRadius: ".25rem",
              my: 1,
              p: 1,
            }}
          >
            <Grid container spacing={1}>
              {catSelection.map((x, i) => (
                <Grid item key={i} xs={12}>
                  <Grid container sx={{ width: "100%" }}>
                    <Grid item flex={1}>
                      <Button
                        variant={
                          selectedCatSelection.includes(x.catName)
                            ? "contained"
                            : "outlined"
                        }
                        size="small"
                        fullWidth
                        sx={{
                          justifyContent: "flex-start",
                        }}
                        onClick={() => setViewCatDetailsBuffer(x)}
                      >
                        {x.catName} ({x.data.length})
                      </Button>
                    </Grid>
                    <Grid item>
                      {selectedCatSelection.includes(x.catName) ? (
                        <IconButton
                          size="small"
                          onClick={() => {
                            let arr = [...selectedCatSelection];
                            arr = arr.filter((a) => a !== x.catName);
                            setSelectedCatSelection(arr);
                          }}
                        >
                          <RemoveCircleIcon color="error" />
                        </IconButton>
                      ) : (
                        <IconButton
                          size="small"
                          onClick={() => {
                            let arr = [...selectedCatSelection];
                            arr.push(x.catName);
                            setSelectedCatSelection(arr);
                          }}
                        >
                          <AddCircleIcon color="success" />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {viewCatDetailsBuffer && (
        <Dialog
          open={viewCatDetailsBuffer !== undefined ? true : false}
          onClose={() => setViewCatDetailsBuffer(undefined)}
        >
          <DialogTitle>{viewCatDetailsBuffer.catName}</DialogTitle>
          <DialogContent>
            <Grid container spacing={1}>
              {viewCatDetailsBuffer?.data?.map((x, i) => (
                <Grid item key={i} xs={12}>
                  <Button
                    variant="outlined"
                    fullWidth
                    size="small"
                    sx={{ justifyContent: "flex-start", pointerEvents: "none" }}
                  >
                    {x.genericBrand}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
