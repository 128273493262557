import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Checkbox,
  FormControlLabel,
  Grid,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  Box,
  Card,
  CardContent,
  Typography,
  Divider,
  IconButton,
  CardActions,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingBox from "../../../components/misc/Loading";
import { getNotification } from "../../../reduxToolkit/features/adminPanel/notification";
import {
  addChatAgent,
  deleteChatAgent,
  editChatAgent,
  getChatAgents,
} from "../../../reduxToolkit/features/adminPanel/webSettings";

// Icons
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

export default function ManageChatBot() {
  const dispatch = useDispatch();
  const [loadingMain, setLoadingMain] = useState(false);
  const [loadingAddAgent, setLoadingAddAgent] = useState(false);
  const [addAgentDlg, setAddAgentDlg] = useState(false);
  const newAgentInit = {
    name: "",
    langCode: "",
    contactInfo: [
      {
        iconUrl: "",
        contactLink: "",
      },
    ],
    daysActive: [
      { title: "Sat", active: false },
      { title: "Sun", active: false },
      { title: "Mon", active: false },
      { title: "Tue", active: false },
      { title: "Wed", active: false },
      { title: "Thu", active: false },
      { title: "Fri", active: false },
    ],
  };
  const [newAgent, setNewAgent] = useState(newAgentInit);

  const [agents, setAgents] = useState([]);

  const [editDlg, setEditDlg] = useState(false);

  const [dltDlg, setDltDlg] = useState(false);
  const [dltBuffer, setDltBuffer] = useState({});

  // Local Store
  const localAdminData = JSON.parse(localStorage.getItem("adminLoginInfo"));
  // store
  const { chatAgents } = useSelector((state) => state.webSettings);

  const handleDateCheckBox = (index) => {
    let obj = { ...newAgent };
    let arr = obj.daysActive;
    arr[index].active = !arr[index].active;
    obj.daysActive = arr;
    setNewAgent(obj);
  };

  const handleAddAgentClose = () => {
    setAddAgentDlg(false);
    setEditDlg(false);
    setNewAgent(newAgentInit);
  };

  const handleAddAgent = () => {
    setLoadingAddAgent(true);
    let obj = { ...newAgent };
    if (localAdminData._id) obj.creatorId = localAdminData._id;
    dispatch(addChatAgent(obj));
    setTimeout(() => {
      dispatch(getNotification());
      dispatch(getChatAgents());
      setLoadingAddAgent(false);
      handleAddAgentClose();
    }, 1000);
  };

  const handleEditAgent = () => {
    setLoadingAddAgent(true);
    let obj = { ...newAgent };
    if (localAdminData._id) obj.creatorId = localAdminData._id;
    dispatch(editChatAgent(obj));
    setTimeout(() => {
      dispatch(getNotification());
      dispatch(getChatAgents());
      setLoadingAddAgent(false);
      handleAddAgentClose();
    }, 1000);
  };

  const handleAddContactPlatform = () => {
    const obj = { ...newAgent };
    let arr = [...obj.contactInfo];
    arr.push({
      iconUrl: "",
      contactLink: "",
    });
    obj.contactInfo = arr;
    setNewAgent(obj);
  };

  const handleRemoveContactPlatform = (index) => {
    const obj = { ...newAgent };
    let arr = [...obj.contactInfo];
    arr = arr.filter((a, i) => i !== index);
    obj.contactInfo = arr;
    setNewAgent(obj);
  };

  const handleEditClick = (x) => {
    setEditDlg(true);
    let obj = {
      _id: x._id,
      createdAt: x.createdAt,
      creatorId: x.creatorId,
      langCode: x.langCode,
      name: x.name,
      updatedAt: x.updatedAt,
    };
    let contactInfoArr = [];
    if (x.contactInfo && x.contactInfo.length > 0) {
      x.contactInfo.forEach((y) => {
        let contactInfoObj = {
          iconUrl: y.iconUrl,
          contactLink: y.contactLink,
        };
        contactInfoArr.push(contactInfoObj);
      });
    }
    let daysActiveArr = [];
    if (x.daysActive && x.daysActive.length > 0) {
      x.daysActive.forEach((y) => {
        let daysActiveObj = {
          title: y.title,
          active: y.active,
        };
        daysActiveArr.push(daysActiveObj);
      });
    }
    obj.contactInfo = contactInfoArr;
    obj.daysActive = daysActiveArr;
    setNewAgent(obj);
  };

  const handleDltClick = (x) => {
    setDltDlg(true);
    setDltBuffer(x);
  };

  const handleDltClose = () => {
    setDltDlg(false);
    setDltBuffer({});
  };

  const handleDlt = () => {
    setLoadingAddAgent(true);
    let obj = {
      id: dltBuffer._id,
    };
    if (localAdminData._id) obj.creatorId = localAdminData._id;
    dispatch(deleteChatAgent(obj));
    setTimeout(() => {
      dispatch(getNotification());
      dispatch(getChatAgents());
      setLoadingAddAgent(false);
      handleDltClose();
    }, 1000);
  };

  useEffect(() => {
    setLoadingMain(true);
    setTimeout(() => {
      if (!(chatAgents && chatAgents.length > 0)) dispatch(getChatAgents());
      setLoadingMain(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (chatAgents && chatAgents.length > 0) {
      setAgents(chatAgents);
    }
  }, [chatAgents]);

  //! Translate
  // Store
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate

  return (
    <>
      <Button
        variant="contained"
        color="success"
        size="small"
        fullWidth
        onClick={() => setAddAgentDlg(true)}
      >
        {translate0("Add Agent")}
      </Button>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        fullWidth
        sx={{ pointerEvents: "none", my: 1 }}
      >
        {translate0("Agents")} ({agents.length})
      </Button>
      {loadingMain ? (
        <LoadingBox w={"100%"} />
      ) : (
        <Grid container spacing={1}>
          {agents.map((x, i) => (
            <Grid item key={i} xs={12} sm={6} md={4} xl={3}>
              <Card>
                <CardContent>
                  <Button
                    variant="text"
                    size="large"
                    startIcon={<AccountBoxIcon sx={{ color: "black" }} />}
                    endIcon={
                      <img
                        src={`https://flagcdn.com/w20/${
                          x.langCode === "en" ? "gb" : x.langCode
                        }.png`}
                        alt="Flag"
                      />
                    }
                    sx={{ pointerEvents: "none", textTransform: "none" }}
                    color="inherit"
                  >
                    {x.name ? x.name : ""}
                  </Button>
                  <Divider />
                  {x.contactInfo &&
                    x.contactInfo.map((y, j) => (
                      <Button
                        key={j}
                        variant="text"
                        size="small"
                        fullWidth
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          textTransform: "none",
                          fontSize: { xs: ".6rem", sm: ".8rem", md: "1rem" },
                        }}
                        color="inherit"
                        startIcon={
                          <img
                            alt={x.name}
                            src={y.iconUrl}
                            style={{ height: "1.5rem" }}
                          />
                        }
                        onClick={() => window.open(y.contactLink, "_blank")}
                      >
                        {y.contactLink}
                      </Button>
                    ))}
                  <Divider />
                  <Grid container spacing={0}>
                    {x.daysActive &&
                      x.daysActive.map((y, j) => (
                        <Grid item xs={4} key={j}>
                          <Button
                            variant="text"
                            size="small"
                            fullWidth
                            sx={{
                              pointerEvents: "none",
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                            startIcon={
                              y.active ? (
                                <RadioButtonCheckedIcon color="success" />
                              ) : (
                                <RadioButtonUncheckedIcon color="error" />
                              )
                            }
                            color={y.active ? "success" : "error"}
                          >
                            {y.title}
                          </Button>
                        </Grid>
                      ))}
                  </Grid>
                  <Divider />
                </CardContent>
                <CardActions
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => handleEditClick(x)}
                  >
                    {translate0("Edit")}
                  </Button>
                  <Button
                    variant="text"
                    color="error"
                    onClick={() => handleDltClick(x)}
                  >
                    {translate0("Delete")}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
      {(addAgentDlg || editDlg) && (
        <Dialog open={addAgentDlg || editDlg} fullWidth maxWidth={"md"}>
          <DialogTitle>
            {editDlg ? translate0("Edit") : translate0("Add")}{" "}
            {translate0("Agents")}
          </DialogTitle>
          <DialogContent>
            <TextField
              label={translate0("Agent Name")}
              value={newAgent.name}
              onChange={(e) => {
                let obj = { ...newAgent };
                obj.name = e.target.value;
                setNewAgent(obj);
              }}
              variant="standard"
              size="small"
              fullWidth
              sx={{ mb: 1 }}
            />
            <FormControl variant="standard" fullWidth required>
              <InputLabel htmlFor="input-with-icon-adornment">
                {translate0("Language Code")}
              </InputLabel>
              <Input
                id="input-with-icon-adornment"
                value={newAgent.langCode}
                onChange={(e) => {
                  let obj = { ...newAgent };
                  obj.langCode = e.target.value;
                  setNewAgent(obj);
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <img
                      src={`https://flagcdn.com/w20/${
                        newAgent.langCode === "en" ? "gb" : newAgent.langCode
                      }.png`}
                      alt="Flag"
                    />
                  </InputAdornment>
                }
              />
            </FormControl>
            <Button variant="outlined" size="small" fullWidth sx={{ mt: 1 }}>
              Contact Platforms
            </Button>
            {newAgent.contactInfo.map((x, i) => (
              <>
                <Box
                  sx={{
                    my: 1,
                    p: 1,
                    border: ".1rem solid pink",
                    borderRadius: ".25rem",
                  }}
                >
                  {newAgent.contactInfo.length > 1 && (
                    <Button
                      variant="contained"
                      size="small"
                      fullWidth
                      sx={{ my: 1 }}
                      color="error"
                      onClick={() => handleRemoveContactPlatform(i)}
                    >
                      {translate0("Delete")}
                    </Button>
                  )}
                  <TextField
                    label={translate0("Icon Url")}
                    value={x.iconUrl}
                    onChange={(e) => {
                      let obj = { ...newAgent };
                      let arr = [...obj.contactInfo];
                      arr[i].iconUrl = e.target.value;
                      obj.contactInfo = [...arr];
                      setNewAgent(obj);
                    }}
                    variant="standard"
                    size="small"
                    fullWidth
                    sx={{ mb: 1 }}
                  />

                  <TextField
                    label={translate0("Contact Link")}
                    value={x.contactLink}
                    onChange={(e) => {
                      let obj = { ...newAgent };
                      let arr = [...obj.contactInfo];
                      arr[i].contactLink = e.target.value;
                      obj.contactInfo = [...arr];
                      setNewAgent(obj);
                    }}
                    variant="standard"
                    size="small"
                    fullWidth
                    sx={{ mb: 1 }}
                  />
                </Box>
              </>
            ))}
            <Button
              variant="contained"
              size="small"
              fullWidth
              sx={{ mb: 1 }}
              color="success"
              onClick={handleAddContactPlatform}
            >
              {translate0("Add Contact Platform")}
            </Button>
            <Grid container spacing={0}>
              {newAgent.daysActive.map((x, i) => (
                <Grid item key={i} xs={4} sm={1.5}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={x.active}
                        onChange={() => handleDateCheckBox(i)}
                      />
                    }
                    label={x.title}
                  />
                </Grid>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAddAgentClose} color="primary">
              {translate0("Cancel")}
            </Button>
            <LoadingButton
              loading={loadingAddAgent}
              onClick={editDlg ? handleEditAgent : handleAddAgent}
              color={editDlg ? "warning" : "success"}
              disabled={newAgent.name === "" || newAgent.langCode === ""}
            >
              {editDlg ? translate0("Update") : translate0("Add")}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}

      {dltDlg && (
        <Dialog open={dltDlg}>
          <DialogTitle>
            {translate0("Do you really want to delete")} "
            <span style={{ color: "red" }}>{dltBuffer.name}</span>"?
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleDltClose} color="primary">
              {translate0("Close")}
            </Button>
            <LoadingButton
              loading={loadingAddAgent}
              onClick={handleDlt}
              color="error"
            >
              {translate0("Delete")}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
