import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import OrderCardCustomerView from "./OrderCardCustomerView";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrders } from "../reduxToolkit/features/frontend/checkout";
import { getAllDeliveryMethods } from "../reduxToolkit/features/adminPanel/delivery";
import { getAllPaymentMethods } from "../reduxToolkit/features/adminPanel/payment";
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";

// Icons
import CloseIcon from "@mui/icons-material/Close";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import LoadingBox from "./misc/Loading";
import { getOrderBackEndByCustomer } from "../reduxToolkit/features/adminPanel/order";
import { ExpandMore } from "@mui/icons-material";
import { getCreated } from "../hooks/getCreatedData";
import { openOrderHistoryDialog } from "../reduxToolkit/features/miscFront";

export default function OrderHistoryDialog() {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  const dispatch = useDispatch();

  const [loadingMain, setLoadingMain] = useState(false);
  const [orderList, setOrderList] = useState([]);

  // local Store
  const localLangData = () => {
    let langObj = {};
    const L = JSON.parse(localStorage.getItem("langFront"));
    if (L && L._id) langObj = L;
    return langObj;
  };

  const localCustomerData = () => {
    const c = JSON.parse(localStorage.getItem("loginInfo"));
    return c ? c : {};
  };

  // Store
  const { orders } = useSelector((state) => state.checkout);
  // Store
  const { orderHistoryDlg } = useSelector((state) => state.miscFront);

  const orderHistoryClose = () => {
    dispatch(openOrderHistoryDialog(false));
  };

  // Use Effect
  useEffect(() => {
    setLoadingMain(true);
    dispatch(getAllOrders());
    dispatch(getAllDeliveryMethods());
    dispatch(getAllPaymentMethods());
    if (localCustomerData()._id)
      dispatch(
        getOrderBackEndByCustomer({ customerId: localCustomerData()._id })
      );
    setTimeout(() => {
      setLoadingMain(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (orders.length) {
      console.log(orders);
      const O = orders.filter(
        (o) => o.orderNumber && o.customerId === localCustomerData()._id
      );
      if (O) setOrderList(O);
    }
  }, [orders]);

  // useEffect(() => {}, []);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate

  return (
    <Dialog
      open={orderHistoryDlg}
      onClose={orderHistoryClose}
      aria-labelledby={"order-history-dialog"}
      fullScreen={!smUp}
      fullWidth={smUp}
      maxWidth={"sm"}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={orderHistoryClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {translateFront("Order History")}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        {loadingMain ? (
          <LoadingBox w={"100%"} h="80vh" />
        ) : (
          <Grid container spacing={1}>
            {orderList.map((x, i) => (
              <Grid item key={i} xs={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-label={`${i}-Expand`}
                    aria-controls={`${i}-content`}
                    id={`${i}-header`}
                  >
                    <Typography>
                      #{x.orderNumber}{" "}
                      <span style={{ color: "grey", fontSize: ".6rem" }}>
                        {" "}
                        ({getCreated(x.updatedAt)})
                      </span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <OrderCardCustomerView
                      lang={localLangData().langCode}
                      x={x}
                      langId={localLangData()._id}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
            ))}
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
}
