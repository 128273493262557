import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Badge,
} from "@mui/material";
import React, { useEffect, useState } from "react";
// Icons
import FaceIcon from "@mui/icons-material/Face";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useNavigate } from "react-router-dom";
import ChangesMade from "./ChangesMade";
import EditAdmin from "./EditAdmin";
import ViewAdmin from "./ViewAdmin";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleAdmin,
  logout,
} from "../../../../reduxToolkit/features/adminPanel/admin";
import { resetAskPriceListByCustomer } from "../../../../reduxToolkit/features/frontend/checkout";

export default function Profile({ setLoginStatus, adminUrl, setProfileDlg }) {
  const [lang, setLang] = useState("");
  const [langId, setLangId] = useState("");
  //   Dialog
  // View Profile
  const [viewProfileDlg, setViewProfileDlg] = useState(false);
  //   Edit Profile
  const [editAdminDlg, setEditAdminDlg] = useState(false);
  //   Changes
  const [changesMadeDlg, setChangesMadeDlg] = useState(false);
  // Logout
  const [logoutDlg, setLogoutDlg] = useState(false);

  // LocalData
  const localData = JSON.parse(localStorage.getItem("adminLoginInfo"));
  const localLangData = JSON.parse(localStorage.getItem("lang"));

  // store
  const { pending, singleAdmin } = useSelector((state) => state.admin);
  const { langs, activeLang } = useSelector((state) => state.misc);

  const dispatch = useDispatch();
  useEffect(() => {
    localData._id && dispatch(getSingleAdmin(localData._id));
  }, [localData._id]);

  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(logout());
    dispatch(resetAskPriceListByCustomer());
    localStorage.removeItem("adminLoginInfo");
    setLoginStatus(false);
    setLogoutDlg(false);
    setProfileDlg(false);
    navigate(`/${adminUrl}`);
  };

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setLang(activeLang.langCode);
      setLangId(activeLang._id);
    } else {
      if (localLangData) {
        setLang(localLangData.langCode);
        setLangId(localLangData._id);
      }
    }
  }, [activeLang]);

  // translate

  const [langSettings, setLangSettings] = useState([]);
  const titleDashboard = "Dashboard Top Bar";

  useEffect(() => {
    if (langs && langs.length > 0 && lang !== "") {
      const L = langs.find((l) => l.langCode === lang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      const dashboard = arr.find((l) => l._title === titleDashboard);
      if (dashboard) setLangSettings(dashboard.data);
    }
  }, [lang, langs]);

  const translate = (String) => {
    let translation = "";
    const T = langSettings.find((ls) => ls.title === String);
    if (T && T.value) translation = T.value;
    else {
      let arr = [];
      if (langs && langs.length > 0 && lang !== "") {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
        const dashboard = arr.find((l) => l._title === titleDashboard);
        if (dashboard) {
          const T = dashboard.data.find((ls) => ls.title === String);
          if (T && T.value) translation = T.value;
        }
      }
    }
    return translation;
  };
  return (
    <List dense disablePadding>
      {/* View Profile */}
      <ListItem disablePadding>
        <ListItemButton onClick={() => setViewProfileDlg(true)}>
          <FaceIcon color="primary" sx={{ mr: 1 }} />
          <ListItemText primary={translate("View profile")} />
        </ListItemButton>
      </ListItem>
      {/* View Profile Dialog */}
      <ViewAdmin
        viewProfileDlg={viewProfileDlg}
        setViewProfileDlg={setViewProfileDlg}
        singleAdmin={singleAdmin._id && singleAdmin}
      />
      {/* Edit Profile */}
      <ListItem disablePadding>
        <ListItemButton onClick={() => setEditAdminDlg(true)}>
          <ManageAccountsIcon color="primary" sx={{ mr: 1 }} />
          <ListItemText primary={translate("Edit profile")} />
        </ListItemButton>
      </ListItem>
      {/* Edit Profile Dialog */}
      <EditAdmin
        editAdminDlg={editAdminDlg}
        setEditAdminDlg={setEditAdminDlg}
        singleAdmin={singleAdmin._id && singleAdmin}
        loading={pending}
      />
      {/* Changes */}
      <ListItem disablePadding sx={{ mr: 2 }}>
        <ListItemButton onClick={() => setChangesMadeDlg(true)}>
          <Badge
            badgeContent={singleAdmin && singleAdmin.changesMade}
            color="error"
            max={9999}
          >
            <ChangeCircleIcon color="primary" sx={{ mr: 1 }} />
            <ListItemText primary={translate("Changes Made")} />
          </Badge>
        </ListItemButton>
      </ListItem>
      {/* Changes Dialog */}
      <Dialog
        open={changesMadeDlg}
        onClose={() => setChangesMadeDlg(false)}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle>Changes Made</DialogTitle>
        <DialogContent>
          <ChangesMade changesMadeBuffer={singleAdmin._id} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setChangesMadeDlg(false)} color="error">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* Logout */}
      <ListItem disablePadding>
        <ListItemButton onClick={() => setLogoutDlg(true)}>
          <ExitToAppIcon color="error" sx={{ mr: 1 }} />
          <ListItemText primary={translate("Logout")} />
        </ListItemButton>
      </ListItem>
      {/* Logout Dialog */}
      <Dialog open={logoutDlg} onClose={() => setLogoutDlg(false)}>
        <DialogContent>
          <Typography variant="body2" color="initial">
            Do you really want to log out?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLogoutDlg(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLogout} color="error">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </List>
  );
}
