import React, { useEffect, useState } from "react";

// Icons
import LoadingBox from "../../../../../components/misc/Loading";
import { format } from "date-fns";
import { PF } from "../../../../../config";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompanies,
  getDosages,
  getMedCategories,
  getMedicines,
} from "../../../../../reduxToolkit/features/adminPanel/medicine";

export default function PreviewBrochurePage() {
  const [loadingMain, setLoadingMain] = useState(true);

  const { leafletObj } = useSelector((state) => state.misc);

  useEffect(() => {
    setLoadingMain(true);
    if (
      leafletObj &&
      leafletObj.selectedForPreview &&
      leafletObj.leafletObj?.settings &&
      leafletObj.siteLang
    ) {
    }
    setTimeout(() => {
      setLoadingMain(false);
    }, 5000);
  }, [leafletObj]);

  //! Translate
  // Store
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [htmlToView, setHtmlToView] = useState(``);

  //   Store
  const { medicines, categories, companies, medVariants, dosages } =
    useSelector((state) => state.medicine);

  useEffect(() => {
    if (!(medicines && medicines.length > 0)) dispatch(getMedicines());
    if (!(categories && categories.length > 0)) dispatch(getMedCategories());
    if (!(companies && companies.length > 0)) dispatch(getCompanies());
    if (!(dosages && dosages.length > 0)) dispatch(getDosages());
  }, []);

  const getImage = (imageArr) => {
    let imageUrl = ``;
    if (imageArr && imageArr.length > 0 && imageArr[0].src) {
      if (imageArr[0].src !== "")
        imageUrl = PF + "adminPanel/medicines/img/" + imageArr[0].src;
      else imageUrl = PF + "adminPanel/medicines/no-medicine-image-mfw.png";
    } else imageUrl = PF + "adminPanel/medicines/no-medicine-image-mfw.png";
    return imageUrl;
  };

  // Get Category
  const [catList, setCatList] = useState([]);
  useEffect(() => {
    if (categories && categories.length > 0) {
      const C = categories.find((c) => c.lang === leafletObj?.siteLang);
      if (C && C.data) {
        setCatList(C.data);
      }
    }
  }, [categories, leafletObj.siteLang]);

  const getCatString = (subCatArr) => {
    let cat = "";
    let catArr = [];
    subCatArr?.forEach((x) => {
      catList.forEach((y) => {
        let Sc = y.subCatList.find(
          (sc) =>
            (leafletObj?.siteLang && leafletObj?.siteLang !== "en"
              ? sc.subCatDetectorId
              : sc.fixedSubCatId) === x.subCatId
        );
        if (Sc) catArr.push(y.catName);
      });
    });
    let uniqueCatArr = [...new Set(catArr)];
    cat = uniqueCatArr.join(", ");
    return cat;
  };

  // Get Sub Category
  const getSubCatString = (subCatArr) => {
    let subCat = "";
    let scArr = [];
    subCatArr?.forEach((x) => {
      catList.forEach((y) => {
        let Sc = y.subCatList.find(
          (sc) =>
            (leafletObj?.siteLang && leafletObj?.siteLang !== "en"
              ? sc.subCatDetectorId
              : sc.fixedSubCatId) === x.subCatId
        );
        if (Sc) scArr.push(Sc.subCatName);
      });
    });
    let uniqueSubCatArr = [...new Set(scArr)];
    subCat = uniqueSubCatArr.join(", ");
    return subCat;
  };

  // Get Company
  const [companyList, setCompanyList] = useState([]);
  useEffect(() => {
    if (companies && companies.length > 0) {
      const C = companies.find((c) => c.lang === leafletObj?.siteLang);
      if (C && C.data) {
        setCompanyList(C.data);
      }
    }
  }, [companies, leafletObj?.siteLang]);

  const getCompanyString = (companyId) => {
    let companyString = "";
    let C = companyList.find(
      (c) =>
        (leafletObj?.siteLang && leafletObj?.siteLang === "en"
          ? c.fixedCompanyId
          : c.companyDetectorId) === companyId
    );
    if (C) {
      companyString = C.companyName;
    }
    return companyString;
  };

  // Get Variant Info
  const [dosageList, setDosageList] = useState([]);
  useEffect(() => {
    if (leafletObj?.siteLang !== "" && dosages && dosages.length > 0) {
      const D = dosages.find((d) => d.lang === leafletObj?.siteLang);
      if (D && D.data) setDosageList(D.data);
      else setDosageList([]);
    }
  }, [dosages, leafletObj?.siteLang]);

  const getVariantInfo = (id) => {
    const MV = medVariants.find((mv) => mv.fixedVariantId === id);
    return MV;
  };

  const getDosageInfo = (dosageId) => {
    let dosageName = "";
    const D = dosageList.find(
      (d) =>
        (leafletObj?.siteLang !== "en"
          ? d.dosageDetectorId
          : d.fixedDosageId) === dosageId
    );
    if (D && D.dosageName) dosageName = D.dosageName;

    return dosageName;
  };

  const getVariantStringObj = (variantArr) => {
    let obj = {
      dosageString: "",
      strengthString: "",
      packTypesString: "",
      priceString: "",
    };
    let dosageArr = [];
    let strengthArr = [];
    let packTypesArr = [];
    let priceArr = [];
    variantArr.forEach((z) => {
      dosageArr.push(getDosageInfo(getVariantInfo(z.variantId)?.dosageForm));
      strengthArr.push(getVariantInfo(z.variantId)?.strength);
      packTypesArr.push(getVariantInfo(z.variantId)?.packTypes);
      priceArr.push("$" + getVariantInfo(z.variantId)?.sellingPrice);
    });

    let uniqueDosageArr = [...new Set(dosageArr)];
    let uniqueStrengthArr = [...new Set(strengthArr)];
    let uniquePackTypesArr = [...new Set(packTypesArr)];
    let uniquePriceArr = [...new Set(priceArr)];
    obj.dosageString = uniqueDosageArr.join(", ");
    obj.strengthString = uniqueStrengthArr.join(", ");
    obj.packTypesString = uniquePackTypesArr.join(", ");
    if (uniquePriceArr.length > 1) {
      obj.priceString = `${uniquePriceArr[0]} - ${
        uniquePriceArr[uniquePriceArr.length - 1]
      }`;
    } else {
      obj.priceString = uniquePriceArr.join(", ");
    }
    return obj;
  };

  useEffect(() => {
    if (!loadingMain) {
      setLoading(true);
      let newHtml = html;
      newHtml = newHtml.replace(
        "{__header_text}",
        leafletObj?.settings.headerText
      );
      newHtml = newHtml.replace(
        "{__date_string}",
        `${translate0("Date")}: ${format(leafletObj?.settings.date, "P")}`
      );
      newHtml = newHtml.replace(
        "{__footer_text}",
        leafletObj?.settings.footerText
      );
      let medListHtml = ``;
      const tableHead = `
      <thead>
        <tr>
          <th>Sl.</th>
          ${leafletObj?.settings.fieldsToView.image ? `<th>Image</th>` : ``}
          ${
            leafletObj?.settings.fieldsToView.generic
              ? `<th>${translate0("Generic")}</th>`
              : ``
          }
          ${
            leafletObj?.settings.fieldsToView.genericBrand
              ? `<th>${translate0("Generic Brand")}</th>`
              : ``
          }
          ${
            leafletObj?.settings.fieldsToView.company
              ? `<th>${translate0("Mfg Company")}</th>`
              : ``
          }
          ${
            leafletObj?.settings.fieldsToView.originator
              ? `<th>${translate0("Originator")}</th>`
              : ``
          }
          ${
            leafletObj?.settings.fieldsToView.category
              ? `<th>${translate0("Category")}</th>`
              : ``
          }
          ${
            leafletObj?.settings.fieldsToView.subCategory
              ? `<th>${translate0("Sub Category")}</th>`
              : ``
          }
          ${
            leafletObj?.settings.fieldsToView.dosage
              ? `<th>${translate0("Dosage Form")}</th>`
              : ``
          }
          ${
            leafletObj?.settings.fieldsToView.strength
              ? `<th>${translate0("Strength")}</th>`
              : ``
          }
          ${
            leafletObj?.settings.fieldsToView.packSize
              ? `<th>${translate0("Pack Types")}</th>`
              : ``
          }
          ${
            leafletObj?.settings.fieldsToView.price
              ? `<th>${translate0("Price")}</th>`
              : ``
          }
          ${
            leafletObj?.settings.fieldsToView.availability
              ? `<th>${translate0("Availability")}</th>`
              : ``
          }
        </tr>
      </thead>
      <tbody>
      `;
      medListHtml = medListHtml + tableHead;

      if (
        leafletObj?.selectedForPreview &&
        leafletObj?.selectedForPreview.length > 0
      ) {
        leafletObj?.selectedForPreview.forEach((x, i) => {
          medListHtml =
            medListHtml +
            `
          <tr>
            <td>${i + 1}</td>
              ${
                leafletObj?.settings.fieldsToView.image
                  ? `<td>
                      <img            
                          src=${getImage(x.image)}
                          alt=${x.genericBrand}
                          class="med-image"
                      />
                    </td>`
                  : ""
              }
              ${
                leafletObj?.settings.fieldsToView.generic
                  ? `<td> ${x.generic}</td>`
                  : ""
              }
              ${
                leafletObj?.settings.fieldsToView.genericBrand
                  ? `<td> ${x.genericBrand}</td>`
                  : ""
              }
              ${
                leafletObj?.settings.fieldsToView.company
                  ? `<td> ${getCompanyString(x.companyId)}</td>`
                  : ""
              }
              ${
                leafletObj?.settings.fieldsToView.originator
                  ? `<td> ${x.originator}</td>`
                  : ""
              } 
              ${
                leafletObj?.settings.fieldsToView.category
                  ? `<td> ${getCatString(x.subCats)}</td>`
                  : ""
              }
              ${
                leafletObj?.settings.fieldsToView.subCategory
                  ? `<td> ${getSubCatString(x.subCats)}</td>`
                  : ""
              }
              ${
                leafletObj?.settings.fieldsToView.dosage
                  ? `<td> ${getVariantStringObj(x.variants).dosageString}</td>`
                  : ""
              }
              ${
                leafletObj?.settings.fieldsToView.strength
                  ? `<td> ${
                      getVariantStringObj(x.variants).strengthString
                    }</td>`
                  : ""
              }
              ${
                leafletObj?.settings.fieldsToView.packSize
                  ? `<td> ${
                      getVariantStringObj(x.variants).packTypesString
                    }</td>`
                  : ""
              }
              ${
                leafletObj?.settings.fieldsToView.price
                  ? `<td> ${getVariantStringObj(x.variants).priceString}</td>`
                  : ""
              }
              ${
                leafletObj?.settings.fieldsToView.availability
                  ? `<td> ${
                      x.availability === 0
                        ? translate0("Available")
                        : x.availability === 1
                        ? translate0("Available Upon Inquiry")
                        : translate0("Not Available")
                    }</td>`
                  : ""
              }  
        </tr>
       
        `;

          if (i + 1 < 30) {
            if ((i + 1) % 28 === 0) {
              medListHtml =
                medListHtml +
                `
                  </tbody>
                </table>
                <div style="margin-top: ${leafletObj?.settings.pageGap}px"/>
                <table >
                ${tableHead}`;
            }
          } else {
            if ((i + 1) % 28 === 0) {
              medListHtml =
                medListHtml +
                `</tbody>
            </table>   
          <div style="margin-top: ${leafletObj?.settings.pageGap}px"/>
          <table >
          ${tableHead}`;
            }
          }

          medListHtml = medListHtml + `</tbody>`;
        });
      }
      newHtml = newHtml.replace("{__insert_meds_here} ", medListHtml);
      setHtmlToView(newHtml);
      setTimeout(() => setLoading(false), 2000);
    }
  }, [
    loadingMain,
    medicines,
    categories,
    companies,
    medVariants,
    dosages,
    leafletObj?.selectedForPreview,
    leafletObj?.settings.date,
    leafletObj?.settings.fieldsToView,
    leafletObj?.settings.footerText,
    leafletObj?.settings.headerText,
    leafletObj.siteLang,
  ]);

  return (
    <>
      {loadingMain || loading ? (
        <LoadingBox w={"100%"} />
      ) : (
        <>
          <div dangerouslySetInnerHTML={{ __html: htmlToView }} />
        </>
      )}
    </>
  );
}

const html = `
<style>
.topbar {
  // display: -webkit-box;
  // display: flex;
  // -webkit-box-pack: space-between;
  // justify-content: space-between;
  width: 90%;
  margin: auto;
}

.top-divider,
footer {
  width: 90%;
  margin: 10px auto;
}

.med-image {
  padding: 2px;
  height: 40px;
  width: 40px;
  object-fit: contain;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 90%;
  margin: auto;
  border: 1px solid black;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-size: 12px;
}


tbody>tr {
  height: 80px;
}
</style>

<div class="topbar">
  <p>{__header_text}</p>
  <p>{__date_string}</p>
</div>
<hr class="top-divider" />
<table>
{__insert_meds_here}            
</table>
<footer>
  {__footer_text}                
</footer>
`;
