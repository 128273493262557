import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Avatar,
  TextField,
  Button,
  Card,
  CardContent,
  Divider,
  Badge,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// Icons
import { ExpandMore } from "@mui/icons-material";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

import { chatBotMenus } from "../../utils/chatBot/ChatBotMenuList";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getChatAgents } from "../../reduxToolkit/features/adminPanel/webSettings";
import LoadingBox from "./Loading";
import { styled } from "@mui/material/styles";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

export default function EmailBot() {
  // Responsive
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  const dispatch = useDispatch();

  //   Use State
  const [chatBotDLg, setChatBotDlg] = useState(false);
  const [loadingMain, setLoadingMain] = useState(false);
  const [agents, setAgents] = useState([]);
  // Store
  const { chatAgents } = useSelector((state) => state.webSettings);

  //   Functions
  // const handleChat = (e, chatName) => {
  //   e.preventDefault();
  //   setChatBotDlg(false);
  // };

  const calcTime = (offset) => {
    // create Date object for current location
    var d = new Date();

    // convert to msec
    // subtract local time zone offset
    // get UTC time in msec
    var utc = d.getTime() + d.getTimezoneOffset() * 60000;

    // create new Date object for different city
    // using supplied offset
    var nd = new Date(utc + 3600000 * offset);

    // return time as a string
    return nd;
  };
  let today = calcTime("+6");

  const getIfAgentActiveToday = (daysActiveArray) => {
    if (daysActiveArray && daysActiveArray.length > 0) {
      let day = today.getDay();
      if (day > 5) day = -1;
      if (daysActiveArray[day + 1] && daysActiveArray[day + 1].active) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    setLoadingMain(true);
    setTimeout(() => {
      if (!(chatAgents && chatAgents.length > 0)) dispatch(getChatAgents());
      setLoadingMain(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (chatAgents && chatAgents.length > 0) {
      setAgents(chatAgents);
    }
  }, [chatAgents]);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate

  return (
    <>
      <Grid container spacing={0} justifyContent="flex-end">
        <Grid item xs={2}>
          <Tooltip arrow placement="top" title={translateFront("Chat Bot")}>
            <IconButton
              sx={
                smUp
                  ? {
                      position: "fixed",
                      bottom: "7px",
                      right: "45px",
                      zIndex: 2,
                      cursor: "pointer",
                      display: "inline",
                    }
                  : {
                      position: "fixed",
                      bottom: "7px",
                      right: "7px",
                      zIndex: 2,
                      cursor: "pointer",
                      display: "inline",
                    }
              }
              onClick={() => setChatBotDlg(true)}
              size="small"
            >
              <img
                src="https://img.icons8.com/color/48/null/filled-chat.png"
                alt={translateFront("Chat Bot")}
              />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Dialog
        open={chatBotDLg}
        onClose={() => setChatBotDlg(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            justifyContent: "flex-end",
            alignItems: "flex-end",
          },
          bottom: !smUp ? "4.5%" : !mdUp ? "4%" : "5%",
          //   maxWidth: "200px",
        }}
      >
        <DialogContent>
          {loadingMain ? (
            <LoadingBox w={"200px"} />
          ) : (
            <>
              <Grid container spacing={1}>
                {agents.map((x, i) => (
                  <Grid item key={i} xs={12}>
                    <Card
                      sx={{
                        filter: getIfAgentActiveToday(x.daysActive)
                          ? "grayscale(0%)"
                          : "grayscale(100%)",
                      }}
                    >
                      <CardContent>
                        <StyledBadge
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          variant={
                            getIfAgentActiveToday(x.daysActive)
                              ? "dot"
                              : "standard"
                          }
                        >
                          <Avatar
                            alt={x.name}
                            src={`https://img.icons8.com/?size=100&id=7820&format=png&color=000000`}
                            sx={{
                              width: 22,
                              height: 22,
                              objectFit: "cover",
                              filter: getIfAgentActiveToday(x.daysActive)
                                ? "grayscale(0%)"
                                : "grayscale(100%)",
                            }}
                          />
                        </StyledBadge>
                        <Button
                          variant="text"
                          size="large"
                          sx={{ pointerEvents: "none", textTransform: "none" }}
                          color="inherit"
                        >
                          {x.name ? x.name : ""}
                        </Button>
                        <Divider />
                        <Grid container spacing={0}>
                          {x.contactInfo &&
                            x.contactInfo.map((y, j) => (
                              <Grid item key={j}>
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    window.open(y.contactLink, "_blank")
                                  }
                                  disabled={
                                    !getIfAgentActiveToday(x.daysActive)
                                  }
                                >
                                  <img
                                    alt={x.name}
                                    src={y.iconUrl}
                                    style={{ height: "1.5rem" }}
                                  />
                                </IconButton>
                              </Grid>
                            ))}
                        </Grid>

                        <Divider />
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
