import axios from "axios";
export const axiosInstance = axios.create({
  //baseURL: "http://localhost:5600/",
    baseURL: "https://api.elifesavingmedicine.com/",
  // baseURL: "https://gen-pharm.herokuapp.com/",
});

//export const PF = "http://localhost:5600/uploads/";
//export const PF_backup = "http://localhost:5600/backup/";
  export const PF = "https://api.elifesavingmedicine.com/api/uploads/";
  export const PF_backup = "https://api.elifesavingmedicine.com/api/backup/";
  //baseURL: "http://localhost:5600/",
  //  baseURL: "https://api.damaldesh.org/",
  // baseURL: "https://gen-pharm.herokuapp.com/",
//});



const root = document.documentElement;
export const getSiteColorTheme = async () => {
  let color = "lightblue";
  const res = await axiosInstance.get("/api/web-settings/");
  if (res.data && res.data.siteColorTheme)
    color = res.data.siteColorTheme.toString();
  root.style.setProperty("--get-site-color-theme", color);
  return color;
};

getSiteColorTheme();
