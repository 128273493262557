import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  createFilterOptions,
  Grid,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ProductListEntry from "./ProductListEntry";
import { PF } from "../../../../config";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useDispatch, useSelector } from "react-redux";
import {
  getDosages,
  getMedicines,
} from "../../../../reduxToolkit/features/adminPanel/medicine";
import getSymbolFromCurrency from "currency-symbol-map";
import { getCurrency } from "../../../../reduxToolkit/features/adminPanel/misc";
import { useTheme } from "@emotion/react";

// Limit options to show in autocomplete
const OPTIONS_LIMIT = 10;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) => {
  return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
};

const EditProductList = ({ productListEntries, setProductListEntries }) => {
  const dispatch = useDispatch();
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);

  //   Store
  const { medicines, medVariants, dosages } = useSelector(
    (state) => state.medicine
  );
  const { currencyList } = useSelector((state) => state.misc);

  useEffect(() => {
    if (!(medicines && medicines.length > 0)) dispatch(getMedicines());
    if (!(dosages && dosages.length > 0)) dispatch(getDosages());
    if (!(currencyList && currencyList.length > 0)) dispatch(getCurrency());
  }, []);

  const [medList, setMedList] = useState([]);
  const [selectedMed, setSelectedMed] = useState(undefined);

  useEffect(() => {
    setLoading2(true);
    if (medicines && medicines.length > 0) {
      const m = medicines.find((m) => {
        return m.lang === "en";
      });

      if (m && m.data.length > 0) {
        let arr = [...m.data].sort((a, b) =>
          a.genericBrand.toLowerCase().replace(/\s/g, "") >
          b.genericBrand.toLowerCase().replace(/\s/g, "")
            ? 1
            : -1
        );
        setMedList(arr);
      }
    }
    setTimeout(() => {
      setLoading2(false);
    }, 1000);
  }, [medicines]);

  const shortLoading = () => {
    setLoading2(true);
    setTimeout(() => {
      setLoading2(false);
    }, 100);
  };

  const handleSearch = (e, newValue) => {
    setSelectedMed(newValue);
    shortLoading();
  };

  const handleVariantSelect = (e, newValue) => {
    let obj = { ...newEntry };
    let productNameStr = "";
    if (selectedMed) {
      productNameStr = selectedMed.genericBrand;
      if (newValue) {
        productNameStr += ` - ${getVariantInfo(newValue.variantId).strength}`;
      }
    }
    obj.productName = productNameStr;
    setNewEntry(obj);
  };

  const getDosageInfo = (dosageId) => {
    let dosageName = "";
    if (dosages && dosages.length > 0) {
      const dosageList = dosages.find((d) => d.lang === "en");
      if (dosageList && dosageList.data) {
        const D = dosageList.data.find((d) => d.fixedDosageId === dosageId);
        if (D && D.dosageName) dosageName = D.dosageName;
      }
    }
    return dosageName;
  };

  const getVariantInfo = (varId) => {
    let obj = {
      dosage: "",
      strength: "",
      packTypes: "",
    };
    if (medVariants && medVariants.length > 0) {
      const V = medVariants.find((v) => v.fixedVariantId === varId);
      if (V) {
        obj.dosage = getDosageInfo(V.dosageForm);
        obj.strength = V.strength;
        obj.packTypes = V.packTypes;
      }
    }
    return obj;
  };

  const handleCurrencyChange = (e, newValue) => {
    let obj = { ...newEntry };
    if (newValue && newValue._id) obj.currencyTypeId = newValue._id;
    setNewEntry(obj);
  };

  const initialNewEntry = {
    productName: "",
    productQty: "",
    perUnitPrice: "",
    isPaid: true,
    date: new Date(),
    currencyTypeId: "",
  };
  const [newEntry, setNewEntry] = useState(initialNewEntry);

  const handleNewEntryChange = (e, key, isNumeric) => {
    let obj = { ...newEntry };
    if (isNumeric) obj[key] = e.target.value.replace(/\D/g, "");
    else obj[key] = e.target.value;
    setNewEntry(obj);
  };

  const addNewEntry = () => {
    let entryArr = [...productListEntries];
    let obj = { ...newEntry };
    if (!obj.currencyTypeId) {
      const c = currencyList.find((c) => c.currencyName === "BDT");
      if (c) {
        obj.currencyTypeId = c._id;
      }
    }
    entryArr.push(obj);
    entryArr = entryArr.sort((a, b) =>
      new Date(a.date) > new Date(b.date) ? 1 : -1
    );
    setProductListEntries(entryArr);
    setNewEntry(initialNewEntry);
    setSelectedMed(undefined);
    shortLoading();
    setLoading1(true);
    setTimeout(() => {
      setLoading1(false);
    }, 100);
  };

  return (
    <>
      <Box sx={{ mb: 2, border: "1px solid gray", borderRadius: ".25rem" }}>
        <ProductListEntry
          productListEntries={productListEntries}
          setProductListEntries={setProductListEntries}
          editable={true}
        />
      </Box>
      {/* 
      {!loading1 && (
        <Autocomplete
          disablePortal
          disabled={loading1 || loading2}
          options={medList}
          onChange={(e, newValue) => handleSearch(e, newValue)}
          filterOptions={filterOptions}
          getOptionLabel={(option) => option.genericBrand}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <img
                loading2="lazy"
                width="20"
                src={
                  option.image.length > 0
                    ? option.image.find((i) => i.default).src.includes("http")
                      ? option.image.find((i) => i.default).src
                      : PF +
                        "adminPanel/medicines/img/" +
                        option.image.find((i) => i.default).src
                    : PF + "adminPanel/medicines/no-medicine-image-mfw.png"
                }
                alt="admins"
              />
              {option.genericBrand}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search medicine..."
              variant="outlined"
              size="small"
              fullWidth
            />
          )}
        />
      )} */}

      {/* {selectedMed && !loading2 ? (
        <Autocomplete
          disablePortal
          disabled={loading2}
          options={
            selectedMed && selectedMed.variants.length > 0
              ? selectedMed.variants
              : []
          }
          onChange={(e, newValue) => handleVariantSelect(e, newValue)}
          filterOptions={filterOptions}
          getOptionLabel={(option) =>
            `${getVariantInfo(option.variantId).strength}`
          }
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              {`${getVariantInfo(option.variantId).strength}`}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Variant..."
              variant="outlined"
              size="small"
              fullWidth
              sx={{ my: 2 }}
            />
          )}
        />
      ) : (
        <TextField
          label="Search Variant..."
          variant="outlined"
          size="small"
          fullWidth
          disabled
          sx={{ my: 2 }}
        />
      )} */}

      <Box>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MobileDatePicker
            label="Date"
            value={newEntry.date}
            onChange={(newValue) => {
              let obj = { ...newEntry };
              obj.date = newValue;
              setNewEntry(obj);
            }}
            renderInput={(params) => (
              <TextField {...params} size="small" fullWidth sx={{ my: 1 }} />
            )}
          />
        </LocalizationProvider>
      </Box>

      <TextField
        label="Product Name"
        value={newEntry.productName}
        onChange={(e) => handleNewEntryChange(e, "productName")}
        size="small"
        variant="outlined"
        fullWidth
        sx={{ my: 1 }}
      />

      <TextField
        label="Qty"
        value={newEntry.productQty}
        onChange={(e) => handleNewEntryChange(e, "productQty", true)}
        size="small"
        variant="outlined"
        fullWidth
        sx={{ my: 1 }}
      />
      <Grid container spacing={1}>
        <Grid item xs={12} lg={6}>
          <TextField
            label="Amount"
            value={newEntry.perUnitPrice}
            onChange={(e) => handleNewEntryChange(e, "perUnitPrice", true)}
            size="small"
            variant="outlined"
            fullWidth
            sx={{ my: 1 }}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box>
            {currencyList && currencyList.length > 0 && (
              <Autocomplete
                size="small"
                onChange={(e, newValue) => handleCurrencyChange(e, newValue)}
                disablePortal
                options={currencyList}
                defaultValue={currencyList.find(
                  (c) => c.currencyName === "BDT"
                )}
                filterOptions={filterOptions}
                getOptionLabel={(option) =>
                  "(" +
                  getSymbolFromCurrency(option.currencyName) +
                  ") " +
                  option.currencyName
                }
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{
                      "& > img": { mr: 2, flexShrink: 0 },
                    }}
                    {...props}
                  >
                    ({getSymbolFromCurrency(option.currencyName)}){" "}
                    {option.currencyName}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Currency"
                    variant="outlined"
                    size="small"
                    fullWidth
                    sx={{ my: 1 }}
                  />
                )}
              />
            )}
          </Box>
        </Grid>
      </Grid>

      <FormControlLabel
        control={
          <Checkbox
            checked={newEntry.isPaid}
            onChange={(e) => {
              let obj = { ...newEntry };
              obj.isPaid = e.target.checked;
              setNewEntry(obj);
            }}
          />
        }
        label="Paid"
      />

      <Button
        color="success"
        variant="contained"
        size="small"
        fullWidth
        onClick={addNewEntry}
      >
        Add Entry
      </Button>
    </>
  );
};

export default EditProductList;
