import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import {
  AppBar,
  Grid,
  IconButton,
  Toolbar,
  TextField,
  Typography,
  Autocomplete,
  Box,
  createFilterOptions,
  Input,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

// Icons
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import PhotoCamera from "@mui/icons-material/PhotoCamera";

// Ck Editor
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

import {
  createNoti,
  uploadTempPic,
} from "../../../../reduxToolkit/features/adminPanel/misc";
import { useDispatch, useSelector } from "react-redux";
import { PF } from "../../../../config";
import {
  createBlog,
  getBlogs,
  updateBlogImg,
  uploadBlogImg,
} from "../../../../reduxToolkit/features/adminPanel/blog";
import { getNotification } from "../../../../reduxToolkit/features/adminPanel/notification";
import LoadingBox from "../../../../components/misc/Loading";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

// Limit options to show in autocomplete
const OPTIONS_LIMIT = 5;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) => {
  return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
};

export default function EditBlog({
  editBuffer,
  lang,
  langId,
  editDlg,
  setEditDlg,
  blogListEn,
}) {
  // Use State
  const [loading, setLoading] = useState(false);
  const [loadingVariantChange, setLoadingVariantChange] = useState(false);
  const [disableForm, setDisableForm] = useState(false);
  const [blog, setBlog] = useState({
    title: editBuffer.title,
    metaKeyWord: editBuffer.metaKeyWord,
    metaTitle: editBuffer.metaTitle,
    metaDesc: editBuffer.metaDesc,
    content: editBuffer.content,
  });
  const [blogImg, setBlogImg] = useState(editBuffer.blogImg);
  const [enVariant, setEnVariant] = useState(editBuffer.blogDetectorId);
  const [defaultEnVariant, setDefaultEnVariant] = useState({});
  const [variantLoading, setVariantLoading] = useState(false);

  // Local Store
  const localAdminData = () => {
    let adminData = JSON.parse(localStorage.getItem("adminLoginInfo"));
    return adminData;
  };

  const handleBlogClose = () => {
    setEditDlg(false);
  };

  const getEnInfo = (detectorId) => {
    let obj = {
      title: "",
    };
    const B = blogListEn.find((b) => b._id === detectorId);
    if (B) obj = { ...B };
    return obj;
  };

  const handleAddBlog = () => {
    setLoading(true);
    let obj = { ...blog, langId, _id: editBuffer._id };
    if (localAdminData()._id) obj.creatorId = localAdminData()._id;
    if (enVariant !== "") obj.blogDetectorId = enVariant;
    if (blogImg && blogImg.name) {
      const data = new FormData();
      const ext = blogImg.name.split(".");
      const filename =
        Date.now() +
        "-" +
        blog.metaKeyWord.replace(/\s+/g, "") +
        "-blog-img." +
        ext.slice(-1);
      data.append("name", filename);
      data.append("blogImg", blogImg);
      obj.blogImg = filename;
      dispatch(uploadBlogImg(data));
    } else {
      if (blogImg) obj.blogImg = blogImg;
    }
    dispatch(updateBlogImg(obj));
    setTimeout(() => {
      dispatch(getNotification());
      dispatch(getBlogs());
      setLoading(false);
      setEditDlg(false);
    }, 1000);
  };

  const handleContent = (e, editor) => {
    let obj = { ...blog };
    const data = editor.getData();
    obj.content = data;
    setBlog(obj);
  };

  const hasWhiteSpace = (s) => {
    return s.indexOf(" ") >= 0;
  };

  // Functions
  const handleEnVariant = (e, newValue) => {
    setLoadingVariantChange(true);
    setEnVariant(newValue._id);
    if (newValue.title) {
      let obj = {
        title: newValue.title,
        metaKeyWord: newValue.metaKeyWord,
        metaTitle: newValue.metaTitle,
        metaDesc: newValue.metaDesc,
        content: newValue.content,
      };
      setBlogImg(newValue.blogImg);
      setBlog(obj);
    }
    setTimeout(() => {
      setLoadingVariantChange(false);
    }, 1000);
  };

  // ! CK Editor Image Upload

  const dispatch = useDispatch();
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          let tempName = Math.floor(Math.random() * (10000 - 0) + 0).toString();
          loader.file
            .then((file) => {
              const data = new FormData();
              if (file) {
                tempName = tempName + "." + file.name.split(".").pop();
                data.append("name", tempName);
                data.append("tempImg", file);
                dispatch(uploadTempPic(data));
                dispatch(
                  createNoti({
                    message: "Pls click on the image Confirm Image Upload",
                    severity: "warning",
                  })
                );
              }
            })
            .then(() => {
              resolve({
                default: `${PF}temp/${tempName}`,
              });
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }
  // ! CK Editor Image Upload

  useEffect(() => {
    let errCount = 0;

    if (blog.title === "") errCount++;
    if (blog.metaKeyWord === "") errCount++;
    if (hasWhiteSpace(blog.metaKeyWord)) errCount++;
    if (blog.metaTitle === "") errCount++;
    if (blog.metaDesc === "") errCount++;
    if (errCount > 0) setDisableForm(true);
    else setDisableForm(false);
  }, [
    blog.title,
    blog.metaTitle,
    blog.metaKeyWord,
    blog.metaDesc,
    blog.content,
  ]);

  useEffect(() => {
    setVariantLoading(true);
    setDefaultEnVariant(getEnInfo(editBuffer.blogDetectorId));
    setTimeout(() => {
      setVariantLoading(false);
    }, 1000);
  }, []);

  //! Translate
  // Store
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate

  return (
    <Dialog
      open={editDlg}
      // onClose={handleBlogClose}
      fullWidth
      maxWidth={"md"}
    >
      <AppBar sx={{ position: "relative" }} color="info">
        <Toolbar>
          <Grid
            container
            spacing={0}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleBlogClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Grid>

            <Grid item xs={10} sm={4} xl={2} sx={{ alignSelf: "flex-end" }}>
              <LoadingButton
                loading={loading}
                loadingPosition="end"
                endIcon={<AddIcon />}
                color="warning"
                variant="contained"
                onClick={handleAddBlog}
                disabled={disableForm}
                fullWidth
                size="small"
              >
                {translate0("Update")}
              </LoadingButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <DialogContent>
        {lang !== "en" && !variantLoading && (
          <Autocomplete
            onChange={(e, newValue) => handleEnVariant(e, newValue)}
            disablePortal
            options={blogListEn}
            filterOptions={filterOptions}
            defaultValue={defaultEnVariant}
            getOptionLabel={(option) => option.title}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option.title}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={translate0("English Variant")}
                variant="standard"
                size="small"
                fullWidth
              />
            )}
          />
        )}
        <Grid
          container
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{ mt: 1 }}
        >
          <Grid item>
            {loadingVariantChange ? (
              <LoadingBox w={"100%"} />
            ) : (
              <LazyLoadImage
                effect="blur"
                src={
                  blogImg && blogImg.name
                    ? URL.createObjectURL(blogImg)
                    : blogImg && blogImg !== ""
                    ? PF + "/adminPanel/blog/" + blogImg
                    : PF + "/adminPanel/medicines/no-medicine-image.jpg"
                }
                alt={blog.title}
                style={{
                  objectFit: "cover",
                  width: "200px",
                }}
              />
            )}
            <Typography variant="body2" align="center" color="secondary">
              {translate0("Recommended")}: 🖼️(800x600)
            </Typography>
          </Grid>
          <Grid item position="relative">
            <Box
              sx={{
                pointerEvents: lang !== "en" && enVariant === "" && "none",
              }}
            >
              <label htmlFor={`icon-button-file-1`}>
                <Input
                  accept="image/*"
                  id={`icon-button-file-1`}
                  required
                  type="file"
                  sx={{ display: "none" }}
                  onChange={(e) => {
                    setBlogImg(e.target.files[0]);
                  }}
                />
                <IconButton
                  color="primary"
                  aria-label={`upload-picture-1`}
                  component="span"
                  disabled={lang !== "en" && enVariant === ""}
                >
                  <PhotoCamera
                    color={
                      lang !== "en" && enVariant === "" ? "inherit" : "primary"
                    }
                  />
                </IconButton>
              </label>
            </Box>
          </Grid>
          <Grid item>
            {blogImg && (
              <Button
                variant="contained"
                size="small"
                color="error"
                onClick={() => {
                  setBlogImg("");
                }}
              >
                {translate0("Remove Image")}
              </Button>
            )}
          </Grid>
        </Grid>
        <TextField
          label={translate0("Title")}
          value={blog.title}
          onChange={(e) => {
            let obj = { ...blog };
            obj.title = e.target.value;
            setBlog(obj);
          }}
          variant="standard"
          fullWidth
          size="small"
          required
          disabled={lang !== "en" && enVariant === ""}
        />
        <TextField
          label={translate0("Meta Keyword")}
          value={blog.metaKeyWord}
          onChange={(e) => {
            let obj = { ...blog };
            obj.metaKeyWord = e.target.value;
            setBlog(obj);
          }}
          variant="standard"
          fullWidth
          size="small"
          helperText={
            hasWhiteSpace(blog.metaKeyWord) && (
              <Typography variant="body2" color="error">
                Only One Word Allowed No Space
              </Typography>
            )
          }
          required
          disabled={lang !== "en" && enVariant === ""}
        />
        <TextField
          label={translate0("Meta Title")}
          value={blog.metaTitle}
          onChange={(e) => {
            let obj = { ...blog };
            obj.metaTitle = e.target.value;
            setBlog(obj);
          }}
          variant="standard"
          fullWidth
          size="small"
          required
          disabled={lang !== "en" && enVariant === ""}
          helperText={
            <Typography
              variant="body2"
              color={blog.metaTitle.length > 60 ? "error" : "primary"}
              align="right"
            >
              {blog.metaTitle.length}/60
            </Typography>
          }
        />
        <TextField
          label={translate0("Meta Description")}
          value={blog.metaDesc}
          onChange={(e) => {
            let obj = { ...blog };
            obj.metaDesc = e.target.value;
            setBlog(obj);
          }}
          multiline
          minRows={3}
          variant="standard"
          fullWidth
          size="small"
          required
          disabled={lang !== "en" && enVariant === ""}
          helperText={
            <Typography
              variant="body2"
              color={blog.metaDesc.length > 150 ? "error" : "primary"}
              align="right"
            >
              {blog.metaDesc.length}/150
            </Typography>
          }
        />
        {lang !== "en" && enVariant === "" ? (
          <></>
        ) : (
          <>
            <Typography variant="h6">{translate0("Content")}</Typography>
            <CKEditor
              editor={Editor}
              data={blog.content}
              config={{
                extraPlugins: [uploadPlugin],
              }}
              onBlur={(e, editor) => handleContent(e, editor)}
            />
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
