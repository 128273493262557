import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../config";

// TODO Supplier Section SECTION ---------------------------------------------------------------
// Create supplier
export const createSupplier = createAsyncThunk(
  "/supplier/create",
  async (data) => {
    const res = await axiosInstance.post("/api/supplier", data);
    return res.data;
  }
);

// get Supplier
export const getSuppliers = createAsyncThunk("/supplier/get", async () => {
  const res = await axiosInstance.get("/api/supplier");
  return res.data;
});

// Update Supplier
export const updateSuppliers = createAsyncThunk(
  "/supplier/update",
  async (data) => {
    const res = await axiosInstance.post("/api/supplier/update", data);
    return res.data;
  }
);

// Remove Supplier
export const removeSuppliers = createAsyncThunk(
  "/supplier/remove",
  async (data) => {
    const res = await axiosInstance.post("/api/supplier/remove", data);
    return res.data;
  }
);

// initial State
const initialState = {
  pending: false,
  createSupplierResponse: {},
  suppliers: {},
  error: false,
  snackNotiSupplier: {},
};

// Slice
export const supplierSlice = createSlice({
  name: "supplier",
  initialState,
  reducers: {
    resetSnkSupplier(state) {
      state.snackNotiSupplier = {};
    },
  },
  extraReducers: {
    [createSupplier.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [createSupplier.fulfilled]: (state, action) => {
      state.pending = true;
      state.createSupplierResponse = action.payload;
      state.snackNotiSupplier = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [createSupplier.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [getSuppliers.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getSuppliers.fulfilled]: (state, action) => {
      state.pending = true;
      state.suppliers = action.payload;
    },
    [getSuppliers.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [updateSuppliers.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [updateSuppliers.fulfilled]: (state, action) => {
      state.pending = true;
      state.suppliers = action.payload;
      state.snackNotiSupplier = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [updateSuppliers.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [removeSuppliers.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [removeSuppliers.fulfilled]: (state, action) => {
      state.pending = true;
      state.suppliers = action.payload;
      state.snackNotiSupplier = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [removeSuppliers.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
  },
});

export const { resetSnkSupplier } = supplierSlice.actions;
export default supplierSlice.reducer;
