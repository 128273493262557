import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { fakeTypingCaptchaText } from "../data/fakeTypingCaptcha";
import Typography from "@mui/material/Typography";
// Icon
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

import {
  Alert,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Tooltip,
  IconButton,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  backupUploads,
  deleteBackupUploads,
  getBackupUploads,
  getGeoInfo,
} from "../../../reduxToolkit/features/adminPanel/misc";
import {
  deleteAllNotification,
  getNotification,
} from "../../../reduxToolkit/features/adminPanel/notification";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  deleteAllRole,
  getRole,
} from "../../../reduxToolkit/features/adminPanel/role";
import {
  deleteAllAdmin,
  getAdmins,
} from "../../../reduxToolkit/features/adminPanel/admin";
import { axiosInstance, PF, PF_backup } from "../../../config";
import CaptchaTikTakToe from "../../../components/misc/CaptchaTikTakToe";
import { updateAdminUrl } from "../../../reduxToolkit/features/adminPanel/adminUrl";
import LoadingBox from "../../../components/misc/Loading";
import { formatByteSize } from "../../../hooks/formatByteSize";
import { getExactTime } from "../../../hooks/getCreatedData";

export default function AdvancedSettings({ translate2 }) {
  const [verified, setVerified] = useState(false);
  const [verifiedLoading, setVerifiedLoading] = useState(false);
  const [random, setRandom] = useState(0);
  const [code, setCode] = useState("");
  const [noMatchAlert, setNoMatchAlert] = useState(false);
  // AdminURl
  const [adminUrlLoading, setAdminUrlLoading] = useState(false);
  const [prevAdminUrlInfo, setPrevAdminUrlInfo] = useState("");
  const [adminUrl, setAdminUrl] = useState("");
  const [adminHint, setAdminHint] = useState("");
  const [adminUrlId, setAdminUrlId] = useState("");
  const [adminUrlChangesMade, setAdminUrlChangesMade] = useState(false);
  const [captchaDlg, setCaptchaDlg] = useState(false);

  // Back up
  const [viewBackupOptions, setViewBackupOptions] = useState(false);
  const [backupLoading, setBackupLoading] = useState(false);
  const [backupUploadsLoading, setBackupUploadsLoading] = useState(false);

  const [backupList, setBackupList] = useState(false);
  const [manageUploadBackUpDlg, setManageUploadBackUpDlg] = useState(false);
  const [manageUploadBackUpLoading, setManageBackUpLoading] = useState(false);
  const [deleteUploadsDatabaseDlg, setDeleteUploadsDatabaseDlg] =
    useState(false);
  const [deleteUploadsDatabaseBuffer, setDeleteUploadsDatabaseBuffer] =
    useState({});
  // notification
  const [dltNotiDlg, setDltNotiDlg] = useState(false);
  const [dltNotiLoading, setDltNotiLoading] = useState(false);
  // Admin
  const [dltAdminDlg, setDltAdminDlg] = useState(false);
  const [dltAdminLoading, setDltAdminLoading] = useState(false);
  // role
  const [dltRoleDlg, setDltRoleDlg] = useState(false);
  const [dltRoleLoading, setDltRoleLoading] = useState(false);
  // useEffect
  useEffect(() => {
    setRandom(Math.floor(Math.random() * 27) + 1);
  }, []);

  // Use Effect
  useEffect(() => {
    verified && getAdminUrl();
  }, [verified]);

  const getAdminUrl = async () => {
    const res = await axiosInstance.get("/api/adminUrl");
    setPrevAdminUrlInfo(res.data);
    setAdminUrl(res.data.url);
    setAdminHint(res.data.hint);
    setAdminUrlId(res.data._id);
  };

  // Check If admin url changed
  useEffect(() => {
    if (
      adminUrl !== prevAdminUrlInfo.url ||
      adminHint !== prevAdminUrlInfo.hint
    )
      setAdminUrlChangesMade(true);
    else setAdminUrlChangesMade(false);
  }, [adminHint, adminUrl, prevAdminUrlInfo.hint, prevAdminUrlInfo.url]);

  //   localdata
  const localData = JSON.parse(localStorage.getItem("adminLoginInfo"));
  // Store
  const { backupResponse, backupUploadsList, pending } = useSelector(
    (state) => state.misc
  );

  //   Functions
  const dispatch = useDispatch();
  const handleCodeMatch = () => {
    setVerifiedLoading(true);
    if (code === fakeTypingCaptchaText[random].verificationCode) {
      setVerified(true);
      setNoMatchAlert(false);
    } else {
      setRandom(Math.floor(Math.random() * 27) + 1);
      setNoMatchAlert(true);
      dispatch(getGeoInfo({ adminTryingId: localData._id }));
      setTimeout(() => {
        dispatch(getNotification());
      }, 2000);
    }
    setTimeout(() => {
      setVerifiedLoading(false);
    }, 2000);
  };

  // Admin Url Change
  const handleAdminUrlChange = () => {
    setCaptchaDlg(true);
  };

  const changeAdminUrl = () => {
    setAdminUrlLoading(true);
    dispatch(
      updateAdminUrl({
        url: adminUrl,
        hint: adminHint,
        id: adminUrlId,
        creatorId: localData._id,
      })
    );
    setTimeout(() => {
      setAdminUrlLoading(false);
      window.location.replace(`/${adminUrl}`);
    }, 2000);
  };

  // Delete All Notification
  const handleDeleteAllNotification = () => {
    setDltNotiLoading(true);
    dispatch(deleteAllNotification());
    setTimeout(() => {
      dispatch(getNotification());
      setDltNotiDlg(false);
      setDltNotiLoading(false);
    }, 2000);
  };

  // Delete All Admins
  const handleDeleteAllAdmins = () => {
    setDltAdminLoading(true);
    dispatch(deleteAllAdmin({ adminTryingId: localData._id }));
    setTimeout(() => {
      dispatch(getNotification());
      dispatch(getRole());
      dispatch(getAdmins());
      setDltAdminDlg(false);
      setDltAdminLoading(false);
    }, 2000);
  };

  // Delete All Roles
  const handleDeleteAllRoles = () => {
    setDltRoleLoading(true);
    dispatch(deleteAllRole({ adminTryingId: localData._id }));
    setTimeout(() => {
      dispatch(getNotification());
      dispatch(getRole());
      dispatch(getAdmins());
      setDltRoleDlg(false);
      setDltRoleLoading(false);
    }, 2000);
  };

  const handleBackupUploads = () => {
    // setBackupUploadsLoading(true);
    dispatch(backupUploads());
    // setTimeout(() => {
    // setBackupUploadsLoading(false);
    // }, 1000);
  };

  useEffect(() => {
    if (pending) {
      setBackupUploadsLoading(true);
    } else {
      setBackupUploadsLoading(false);
    }
  }, [pending]);

  const handleManageUploadBackUpOpen = () => {
    setManageBackUpLoading(true);
    setManageUploadBackUpDlg(true);
    dispatch(getBackupUploads());
    setTimeout(() => {
      setManageBackUpLoading(false);
    }, 1000);
  };

  const handleDeleteUploadsDatabase = () => {
    setManageBackUpLoading(true);
    dispatch(
      deleteBackupUploads({ fileName: deleteUploadsDatabaseBuffer.fileName })
    );
    setTimeout(() => {
      dispatch(getBackupUploads());
      setDeleteUploadsDatabaseDlg(false);
      setDeleteUploadsDatabaseBuffer({});
      setManageBackUpLoading(false);
    }, 1000);
  };

  useEffect(() => {
    if (backupUploadsList && backupUploadsList.length > 0) {
      setBackupList(backupUploadsList);
    } else {
      setBackupList([]);
    }
  }, [backupUploadsList]);

  //! Translate
  // Store
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate
  return (
    <>
      {verifiedLoading ? (
        <LoadingBox w={"100%"} />
      ) : !verified ? (
        <>
          <Grid
            container
            spacing={0}
            flexDirection="column"
            alignItems="center"
          >
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                border: ".1rem solid indigo",
                borderRadius: ".25rem",
                p: 1,
              }}
            >
              <Typography variant="body2" color="inherit" align="center">
                {translate2("Type the sentence")} -
              </Typography>
              <Typography variant="body2" color="secondary" align="center">
                "
                {fakeTypingCaptchaText[random] &&
                  fakeTypingCaptchaText[random].text}
                "
              </Typography>
              <TextField
                label={translate2("Type Here") + "..."}
                value={code}
                onChange={(e) => setCode(e.target.value)}
                variant="standard"
                fullWidth
                sx={{ mb: 1 }}
              />
              {noMatchAlert && <Alert severity="warning">Not Matched!</Alert>}
              <Button
                variant="contained"
                size="small"
                fullWidth
                onClick={handleCodeMatch}
              >
                {translate2("Submit")}
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid container spacing={1} justifyContent="space-around">
            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                border: ".2rem Solid lightblue",
                p: 1,
                borderRadius: ".25rem",
              }}
            >
              <Typography
                variant="subtitle2"
                color="primary"
                align="center"
                sx={{ borderBottom: ".2rem solid lightblue" }}
              >
                {translate2("Change Admin Url")}
              </Typography>
              <TextField
                label={translate2("Admin Url")}
                required
                value={adminUrl}
                onChange={(e) => setAdminUrl(e.target.value)}
                variant="standard"
                fullWidth
              />
              <TextField
                label={translate2("Hint")}
                value={adminHint}
                onChange={(e) => setAdminHint(e.target.value)}
                variant="standard"
                fullWidth
                sx={{ mb: 1 }}
              />
              <LoadingButton
                loading={adminUrlLoading}
                loadingPosition="end"
                endIcon={<ChangeCircleIcon />}
                variant="contained"
                size="small"
                fullWidth
                onClick={handleAdminUrlChange}
                disabled={!adminUrlChangesMade}
              >
                {translate2("Change")}
              </LoadingButton>
              <Dialog
                open={captchaDlg}
                onClose={() => setCaptchaDlg(false)}
                aria-labelledby={"change-admin-url"}
              >
                <DialogContent>
                  <CaptchaTikTakToe
                    setCaptchaDlg={setCaptchaDlg}
                    loginFunc={changeAdminUrl}
                  />
                </DialogContent>
              </Dialog>
            </Grid>
            {/* Backup */}
            <Grid item xs={12} sm={3}>
              {viewBackupOptions ? (
                <>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        size="small"
                        fullWidth
                        sx={{ height: "60px" }}
                        color="secondary"
                      >
                        {translate2("Backup Database")}
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={0} alignItems="center">
                        <Grid item sx={{ flex: 1 }}>
                          <LoadingButton
                            variant="contained"
                            size="small"
                            fullWidth
                            sx={{ height: "60px" }}
                            color="info"
                            onClick={handleBackupUploads}
                            loading={backupUploadsLoading}
                          >
                            {translate2("Backup /Uploads Folder")}
                          </LoadingButton>
                        </Grid>
                        <Grid item>
                          <Tooltip
                            arrow
                            placement="top"
                            title={translate0("Manage")}
                          >
                            <IconButton onClick={handleManageUploadBackUpOpen}>
                              <SettingsApplicationsIcon color="info" />
                            </IconButton>
                          </Tooltip>
                          {manageUploadBackUpDlg && (
                            <Dialog
                              open={manageUploadBackUpDlg}
                              onClose={() => setManageUploadBackUpDlg(false)}
                              fullWidth
                              maxWidth={"md"}
                            >
                              <DialogTitle>Manage Database</DialogTitle>
                              <DialogContent>
                                {manageUploadBackUpLoading ? (
                                  <LoadingBox w={"100%"} />
                                ) : (
                                  <Grid container spacing={1}>
                                    {backupList &&
                                      backupList.map((x, i) => (
                                        <Grid item key={i} xs={12} sm={6}>
                                          <Card>
                                            <CardContent>
                                              <Typography
                                                variant="subtitle1"
                                                color="initial"
                                                component="div"
                                              >
                                                {x.fileName}
                                              </Typography>
                                              <Typography
                                                variant="body2"
                                                color="text.secondary"
                                                // align="right"
                                              >
                                                ({formatByteSize(x.stat.size)})
                                              </Typography>
                                              <Typography
                                                variant="body2"
                                                color="text.secondary"
                                                // align="right"
                                              >
                                                {getExactTime(x.stat.birthtime)}
                                              </Typography>
                                            </CardContent>
                                            <CardActions>
                                              <IconButton
                                                size="small"
                                                onClick={() =>
                                                  window.open(
                                                    `${PF_backup}${x.fileName}`,
                                                    "_blank"
                                                  )
                                                }
                                              >
                                                <DownloadForOfflineIcon color="secondary" />
                                              </IconButton>
                                              <IconButton
                                                size="small"
                                                onClick={() => {
                                                  setDeleteUploadsDatabaseDlg(
                                                    true
                                                  );
                                                  setDeleteUploadsDatabaseBuffer(
                                                    x
                                                  );
                                                }}
                                              >
                                                <DeleteOutlineIcon color="error" />
                                              </IconButton>
                                            </CardActions>
                                          </Card>
                                        </Grid>
                                      ))}
                                  </Grid>
                                )}
                              </DialogContent>
                            </Dialog>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <LoadingButton
                        variant="contained"
                        size="small"
                        fullWidth
                        sx={{ height: "30px" }}
                        color="error"
                        onClick={() => {
                          setBackupLoading(true);
                          setTimeout(() => {
                            setBackupLoading(false);
                            setViewBackupOptions(false);
                          }, 1000);
                        }}
                        loading={backupLoading}
                      >
                        {translate2("Cancel")}
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <LoadingButton
                  variant="contained"
                  size="small"
                  color="warning"
                  fullWidth
                  sx={{ height: "150px" }}
                  onClick={() => {
                    setBackupLoading(true);
                    setTimeout(() => {
                      setBackupLoading(false);
                      setViewBackupOptions(true);
                    }, 1000);
                  }}
                  loading={backupLoading}
                >
                  {translate2("Backup")}
                </LoadingButton>
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                variant="contained"
                size="small"
                color="error"
                fullWidth
                onClick={() => setDltNotiDlg(true)}
                sx={{ mb: 1 }}
              >
                {translate2("Delete All Notification")}
              </Button>
              {/* delete notification Dialog */}
              <Dialog
                open={dltNotiDlg}
                onClose={() => setDltNotiDlg(false)}
                aria-labelledby={"delete-all-notification"}
              >
                <DialogTitle>
                  {translate2("Do You Want To Delete All Notification?")}
                </DialogTitle>
                <DialogActions>
                  <Button onClick={() => setDltNotiDlg(false)} color="primary">
                    {translate2("Cancel")}
                  </Button>
                  <LoadingButton
                    endIcon={<DeleteOutlineIcon sx={{ mb: 0.5 }} />}
                    loading={dltNotiLoading}
                    loadingPosition="end"
                    onClick={handleDeleteAllNotification}
                    color="error"
                  >
                    {translate2("Confirm")}
                  </LoadingButton>
                </DialogActions>
              </Dialog>

              <Button
                variant="contained"
                size="small"
                color="error"
                fullWidth
                onClick={() => setDltAdminDlg(true)}
                sx={{ mb: 1 }}
              >
                {translate2("Delete All Admins")}
              </Button>
              {/* Delete All Admins Dialog */}
              <Dialog
                open={dltAdminDlg}
                onClose={() => setDltAdminDlg(false)}
                aria-labelledby={"delete-all-notification"}
              >
                <DialogTitle>
                  {translate2("Do You Want To Delete All Admins?")}
                </DialogTitle>
                <DialogActions>
                  <Button onClick={() => setDltAdminDlg(false)} color="primary">
                    {translate2("Cancel")}
                  </Button>
                  <LoadingButton
                    endIcon={<DeleteOutlineIcon sx={{ mb: 0.5 }} />}
                    loading={dltAdminLoading}
                    loadingPosition="end"
                    onClick={handleDeleteAllAdmins}
                    color="error"
                  >
                    {translate2("Confirm")}
                  </LoadingButton>
                </DialogActions>
              </Dialog>

              <Button
                variant="contained"
                size="small"
                color="error"
                fullWidth
                onClick={() => setDltRoleDlg(true)}
                sx={{ mb: 1 }}
              >
                {translate2("Delete All Roles")}
              </Button>
              {/* Delete All Roles Dialog */}
              <Dialog
                open={dltRoleDlg}
                onClose={() => setDltRoleDlg(false)}
                aria-labelledby={"delete-all-notification"}
              >
                <DialogTitle>
                  {translate2("Do You Want To Delete All Roles?")}
                </DialogTitle>
                <DialogActions>
                  <Button onClick={() => setDltRoleDlg(false)} color="primary">
                    {translate2("Cancel")}
                  </Button>
                  <LoadingButton
                    endIcon={<DeleteOutlineIcon sx={{ mb: 0.5 }} />}
                    loading={dltRoleLoading}
                    loadingPosition="end"
                    onClick={handleDeleteAllRoles}
                    color="error"
                  >
                    {translate2("Confirm")}
                  </LoadingButton>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>

          {/* Delete Database */}
          {deleteUploadsDatabaseDlg && (
            <Dialog open={deleteUploadsDatabaseDlg}>
              <DialogTitle>
                {translate0("Do you really want to delete") +
                  deleteUploadsDatabaseBuffer.fileName}
              </DialogTitle>

              <DialogActions>
                <Button
                  onClick={() => {
                    setDeleteUploadsDatabaseDlg(false);
                    setDeleteUploadsDatabaseBuffer({});
                  }}
                  color="primary"
                >
                  {translate0("Close")}
                </Button>
                <LoadingButton
                  loading={manageUploadBackUpLoading}
                  onClick={handleDeleteUploadsDatabase}
                  color="primary"
                >
                  {translate0("Confirm")}
                </LoadingButton>
              </DialogActions>
            </Dialog>
          )}
        </>
      )}
    </>
  );
}
