import { Button, Grid, Typography } from "@mui/material";

import React from "react";
import HowToOrder from "../components/home/HowToOrder";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// import HowToVid from "../components/misc/HowToVid";
import BreadcrumbsTemplate from "../components/misc/BreadcrumbsTemplate";
import { Link } from "react-router-dom";

export default function HowToOrderScreen({ setPolicyOpen, setPolicyName }) {
  // Media Query
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  // Functions
  const handlePolicy = (policy) => {
    setPolicyOpen(true);
    setPolicyName(policy);
  };
  return (
    <>
      <BreadcrumbsTemplate b3="How To Order" />
      <Typography
        variant="h6"
        align="center"
        sx={{ borderBottom: "2px solid black", mt: 1 }}
      >
        How to Order
      </Typography>
      <Grid
        container
        spacing={0}
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid item xs={12} sm={7} md={6}>
          {/* <HowToVid /> */}
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <HowToOrder />
        </Grid>
        <Grid
          item
          xs={10}
          sm={10}
          md={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {mdUp && (
            <img
              src="https://jungleworks.com/wp-content/uploads/2021/03/shutterstock_1481692793-1-1024x653.png"
              alt="hanger"
              style={{ padding: "1rem" }}
            />
          )}
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item xs={12} md={6}>
              <Link to="/our-medicines" style={{ textDecoration: "none" }}>
                <Button variant="contained" fullWidth>
                  Order Now
                </Button>
              </Link>
            </Grid>
            {mdUp && (
              <Grid item md={6}>
                <Link to="/contact-us" style={{ textDecoration: "none" }}>
                  <Button variant="contained" color="secondary" fullWidth>
                    Contact Us
                  </Button>
                </Link>
              </Grid>
            )}
          </Grid>
          {mdUp && (
            <>
              <br />
              <Button
                variant="contained"
                fullWidth
                onClick={() => handlePolicy("terms-and-conditions")}
              >
                Terms & Conditions
              </Button>
              <br />
              <Button
                variant="contained"
                fullWidth
                onClick={() => handlePolicy("shipping-policy")}
              >
                Shipping Policy
              </Button>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
}
