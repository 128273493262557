var censorWord = function (str, offSet) {
  let text = "";
  if (str.length > offSet) {
    for (let i = 0; i < offSet; i++) text = text + str[i];
    text = text + "*".repeat(str.length - offSet + 1) + str.slice(-1);
  }
  return text;
};

export const censorEmail = (email) => {
  let arr = [];
  arr = email.split("@");
  return censorWord(arr[0], 3) + "@" + censorWord(arr[1], 0) || "N/A";
};

export const censorPhone = (phoneNumber) => {
  return censorWord(phoneNumber, 5) || "N/A";
};
