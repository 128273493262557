import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import { getExactTimePreDateFormatted } from "../../hooks/getCreatedData";
import { changeLog } from "./data/changeLogList";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function ChangeLog() {
  // Responsive
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));
  return (
    <>
      <Box
        sx={{
          maxHeight: { xs: "30vh", sm: "20vh" },
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ fontFamily: "monospace" }}
          component="pre"
        >
          {`
          > 01-11-22
          - [x] com/ org review does not submit - done
          - [x] review text limit increase - done
          - [x] admin changes made timing add - done
          - [x] admin image load fast - done
          - [x] mg replace with 毫克 , мг -done
          - [x] originator from med edit -done
          > 02-11-22
          - [x] payment details add all country - done
          - [x] payment details transaction fee - done
          - [x] delivery details add all country - done
          - [x] manual review add - done
          > 03-11-22
          - [x] medicine view count all 3 language
          - [x] testimonial height fix mobile
          - [x] additional page section in create/ edit medicine
          > 05-11-22
          - [x] mobile dosage not open
          - [x] fixed testimonial, how to order, contact us scroll to id 
          - [x] custom scroll bar added  
          - [x] fixed scroll issues  
          - [x] dosage in cn and ru fix
          > 07-11-22
          - [x] add medicine view count in report - delayed
          - [x] front end notification count does not show properly
          - [x] customer registration double verification code fix
          - [x] customer new registration notification does not update fix 
          - [x] Review Section Custom Image for medicine did not show fix  
          - [x] Changes Made section fixed
          - [x] Admin Home/ DashBoard page added
          > 11-11-22
          - [x] add/ Mange SEO tags
          - [x] manage payment page english variant show 
          - [x] Blog Single page Create
          - [x] Payment settings active account option
          - [x] QOL improvement in add & edit medicine 
          - [x] managed content (About Us) section in webSettings
          - [x] managed content (How To Order) section in webSettings
          > 12-11-22 
          - [x] About us Video
          - [x] fixed home page title
          - [x] How To Order Video
          > 13-11-22
          - [x] managed content (Disclaimer) section in webSettings
          - [x] managed content (terms & condition) section in webSettings
          - [x] managed content (shipping policy) section in webSettings
          - [x] managed content (review Policy) section in webSettings
          - [x] managed content (privacy Policy) section in webSettings
          - [x] advanced med settings fixed
          - [x] med cn width issue mobile fix
          - [x] Social Media Pages
          - [x] Social Media contacts
          > 14-11-22
          - [x] Med search both eng and current
          - [x] Whats app phone number 
          - [x] Social media link create from admin panel
          - [x] Time another format - Done
          - [x] Play button in video - Done
          - [x] Pack size select
          - [x] Discount
          - [x] Tags add med info
          - [x] Generic brand or originator for meta keyword
          - [x] Add med eng loading
          - [x] Strength mg 
          - [x] Assign price specific for a client
          > 17-11-22
          - [x] Contact Info
          - [x] Footer Text
          - [x] Additional information and faq choose from previous -  not required
          - [x] Url translate - url can only be english
          - [x] Select from multiple variant - Not possible
          - [x] browser info using javascript
          - [x] get ip while login -  too many request for free tier
          > 19-11-22
          - [x] Email Template Section (Email Verification)  
          > 20-11-22
          - [x] Email Template Section (registration success)  
          - [x] Email Template Section (Ask Price)  
          - [x] Email Template Section (Assign Price)  
          - [x] Email Template Section (Update Price)  
          > 22-11-22
          - [x] Email Template Section (Order Created)  
          > 23-11-22
          - [x] Email Template Section (Payment Account Assigned)  
          - [x] Email Template Section (Payment Receipt Uploaded)  
          - [x] Email Template Section (Payment Confirmed & Shipping)  
          - [x] Email Template Section (Shipping Complete)  
          - [x] Email Template Section (Shipping Confirmed)  
          - [x] Email Template Section (Review Submitted)  
          > 26-11-22
          - [x] Email Template Section (New Subscription)
          - [x] Email Template Section (Contact Us Form Submitted)
          - [x] Email Template Section (Customer UnBlock Request)
          - [x] Subscriber Email Send Section
          - [x] Contact Us Section
          > 27-11-22
          - [x] bulk email sending WOP
          > 28-11-22
          - [x] bulk email send successful
          - [x] email templates creation
          - [x] campaign management
          - [x] rerun campaign
          - [x] campaign Visual overview before sending mail 
          > 01-12-22
          - [x] chat bot
          > 07-12-22
          - [x] Language (Back End)
          > 08-12-22
          - [x] SearchQuery addition in email templates
          > 11-12-22
          - [x] Language (Front End) - Search Bar
          - [x] Language (Front End) - view profile
          - [x] Language (Front End) - edit profile
          - [x] Language (Front End) - order card
          - [x] Language (Front End) - notification
          > 12-12-22
          - [x] Language (Front End) - cart
          - [x] Language (Front End) - wishlist
          - [x] Language (Front End) - compare
          - [x] Language (Front End) - product large
          - [x] Language (Front End) - product small
          - [x] Language (Front End) - checkout
          - [x] Language (Front End) - shipping
          - [x] Language (Front End) - prescription
          - [x] Language (Front End) - payment
          - [x] Language (Front End) - order review
          - [x] Language Settings - Search Language function added
          - [x] Maintenance Mode
          > 13-12-22
          - [x] Maintenance Mode (Scheduler Added)
          - [x] Enable-disable Language 
          - [x] Language Settings - Go To Top
          > 15-12-22
          - [x] Language Settings - Footer - Social Media
          - [x] Language Settings - Footer - Information
          - [x] Language Settings - Home Page
          - [x] Language Settings - Our Medicine Page
          - [x] Language Settings - Blog Page
          > 17-12-22
          - [x] Blog Page Bug Fixed
          > 08-01-22
          - [x] Image not required in add admin
          - [x] Assign price korle product page e nibe
          - [x] email verification fix
          - [x] shipping cost to shipping cost discount fix
          - [x] Transaction fee bug fix
          - [x] frontend subscription from non register customers
          - [x] product small ask for price scroll x
          - [x] blog seo crash fix
          - [x] shipping er document attach er option
          - [x] ems er jonno 100 gram 
          - [x] dhl er jonno 1kg gram
          - [ ] Language Settings - Snack Notification
          - [ ] Language Settings - Notification Admin
          - [ ] Language Settings - Notification Customer
          - [ ] import csv, excel
          - [ ] Password Strength Section
          - [ ] Role Basic Access
          - [ ] Custom tabs
          - [ ] Sub menu admin scope
          - [ ] Originator image
          - [ ] Changes made sub section
          - [ ] Block notification
          - [ ] Push notification
          - [ ] Supplier unique dealer. View med
          - [ ] Convert number with lang change
      `}
        </Typography>
      </Box>
      {/* <List>
        {changeLog.map((x, i) => (
          <Fragment key={i}>
            <ListItem disablePadding>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={8} xl={10}>
                  <Alert
                    severity="info"
                    sx={{
                      width: "100%",
                    }}
                  >
                    {`${x.changeTitle} `}
                  </Alert>
                </Grid>
                <Grid item xs={12} sm={4} xl={2}>
                  <Button
                    size="small"
                    variant={!smUp ? "outlined" : "text"}
                    color="info"
                    fullWidth
                  >
                    {getExactTimePreDateFormatted(x.changeDate)}
                  </Button>
                </Grid>
              </Grid>
            </ListItem>
            <Divider sx={{ my: 1 }} />
          </Fragment>
        ))}
      </List> */}
    </>
  );
}
