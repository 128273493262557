import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Grid,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
// import { roleList } from "./data/roleList";
// Icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useSelector } from "react-redux";

export default function RolePrioritySelector({
  color,
  roleName,
  setDisableEdit,
  updatedRoleListMain,
  setUpdatedRoleListMain,
  translate6,
}) {
  const [showArrow, setShowArrow] = useState(false);
  const [roleList, setRoleList] = useState([]);

  // Store
  const { roles } = useSelector((state) => state.role);

  // UseEffect
  useEffect(() => {
    roles.length > 0 && setRoleList([...roles]);
  }, [roles]);

  useEffect(() => {
    roleList.sort((a, b) => a.priority - b.priority);
  }, [roleList]);

  // USe Effects
  useEffect(() => {
    let updatedRoleList = [...roleList];
    setUpdatedRoleListMain(updatedRoleList);
  }, []);

  useEffect(() => {
    if (roleList.length > 0) {
      let picked = [];
      roleList.map((x, i) => {
        const p = (({ roleName, color, priority }) => ({
          roleName,
          color,
          priority,
        }))(roleList[i]);
        picked.push(p);
      });
      let updatedRoleList = [...picked];
      updatedRoleList.sort((a, b) => a.priority - b.priority);
      let obj = {};
      obj["roleName"] = roleName;
      obj["priority"] = roleList[roleList.length - 1].priority + 1;
      obj["color"] = color;
      updatedRoleList.push(obj);
      setUpdatedRoleListMain(updatedRoleList);
    }
  }, [color, roleList, roleName]);

  useEffect(() => {
    roleName !== "" ? setShowArrow(true) : setShowArrow(false);
  }, [roleName]);

  // Functions
  // Promotion
  const handleRolePromotion = (index, priority) => {
    let updatedRoleList = [...updatedRoleListMain];
    updatedRoleList[index].priority = priority - 1;
    updatedRoleList[index - 1].priority = priority;
    updatedRoleList.sort((a, b) => a.priority - b.priority);
    setDisableEdit && setDisableEdit(true);
    setUpdatedRoleListMain(updatedRoleList);
  };

  // Demotion
  const handleRoleDemotion = (index, priority) => {
    let updatedRoleList = [...updatedRoleListMain];

    updatedRoleList[index].priority = priority + 1;
    updatedRoleList[index + 1].priority = priority;
    updatedRoleList.sort((a, b) => a.priority - b.priority);

    setDisableEdit && setDisableEdit(true);
    setUpdatedRoleListMain(updatedRoleList);
  };
  return (
    <>
      <List>
        {updatedRoleListMain &&
          updatedRoleListMain.map((x, i) => (
            <Grid
              key={i}
              container
              spacing={0}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={2} sm={0.5}>
                <IconButton
                  size="small"
                  onClick={() => handleRolePromotion(i, x.priority)}
                >
                  {i !== 0 && showArrow && (
                    <KeyboardArrowUpIcon color="primary" />
                  )}
                </IconButton>
              </Grid>
              <Grid
                item
                xs={8}
                sm={11}
                sx={{
                  borderLeft:
                    i !== 0 && x.roleName === roleName && ".8rem solid black ",
                  borderRight:
                    i !== 0 && x.roleName === roleName && ".8rem solid black ",
                }}
              >
                <ListItem disablePadding sx={{ my: 0.5 }} dense>
                  <ListItemButton
                    sx={{
                      background: x.color,
                      "&:hover": {
                        background: x.color,
                        pointerEvents: "none",
                      },
                    }}
                  >
                    <ListItemText
                      primary={`${x.roleName} ${
                        (i !== updatedRoleListMain.length - 1 || roleName) &&
                        ` | ${translate6("Priority")}: ` + x.priority
                      }`}
                    />
                  </ListItemButton>
                </ListItem>
              </Grid>
              <Grid item xs={2} sm={0.5}>
                <IconButton
                  size="small"
                  onClick={() => handleRoleDemotion(i, x.priority)}
                >
                  {i !== updatedRoleListMain.length - 1 && showArrow && (
                    <KeyboardArrowDownIcon color="error" />
                  )}
                </IconButton>
              </Grid>
            </Grid>
          ))}
      </List>
    </>
  );
}
