import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../config";

// Create expense
export const createExpense = createAsyncThunk(
  "/expense/createExpense",
  async (data) => {
    const res = await axiosInstance.post("/api/expense/create", data);
    return res.data;
  }
);

// Get expense
export const getExpense = createAsyncThunk("/expense/getExpense", async () => {
  const res = await axiosInstance.get("/api/expense/");
  return res.data;
});

// Delete expense
export const deleteExpense = createAsyncThunk(
  "/expense/deleteExpense",
  async (data) => {
    const res = await axiosInstance.post("/api/expense/delete", data);
    return res.data;
  }
);

// Update expense
export const updateExpense = createAsyncThunk(
  "/expense/updateExpense",
  async (data) => {
    const res = await axiosInstance.post("/api/expense/update", data);
    return res.data;
  }
);

// Create expense Cats
export const createExpenseCat = createAsyncThunk(
  "/expense/createExpenseCat",
  async (data) => {
    const res = await axiosInstance.post("/api/expense/create-cat", data);
    return res.data;
  }
);

// Update expense Cats
export const updateExpenseCat = createAsyncThunk(
  "/expense/updateExpenseCat",
  async (data) => {
    const res = await axiosInstance.post("/api/expense/update-cat", data);
    return res.data;
  }
);

// Get expense Cats
export const getExpenseCat = createAsyncThunk(
  "/expense/getExpenseCat",
  async () => {
    const res = await axiosInstance.get("/api/expense/cat");
    return res.data;
  }
);

// Delete expense Cats
export const deleteExpenseCat = createAsyncThunk(
  "/expense/deleteExpenseCat",
  async (data) => {
    const res = await axiosInstance.post("/api/expense/delete-cat", data);
    return res.data;
  }
);

// Upload Expense Images
export const uploadExpenseImages = createAsyncThunk(
  "expense/uploadExpenseImages",
  async (data) => {
    const res = await axiosInstance.post("/api/multer/expenseImages", data);
    return res.data;
  }
);

// Selected PDF Download
export const selectedPDFDownload = createAsyncThunk(
  "expense/selectedPDFDownload",
  async (data) => {
    const res = await axiosInstance.post(
      "/api/expense/selected-pdf-download",
      data
    );
    return res.data;
  }
);

// Store Office Expense
export const storeOfficeExpense = createAsyncThunk(
  "expense/storeOfficeExpense",
  async (data) => {
    const res = await axiosInstance.post(
      "/api/expense//office-expense/store",
      data
    );
    return res.data;
  }
);

// Get Office Expense
export const getOfficeExpense = createAsyncThunk(
  "expense/getOfficeExpense",
  async (data) => {
    const res = await axiosInstance.get("/api/expense/office-expense/", data);
    return res.data;
  }
);

// Delete Office Expense
export const deleteOfficeExpense = createAsyncThunk(
  "expense/deleteOfficeExpense",
  async (id) => {
    const res = await axiosInstance.delete(`/api/expense/office-expense/${id}`);
    return res.data;
  }
);

// Update Office Expense
export const updateOfficeExpense = createAsyncThunk(
  "expense/updateOfficeExpense",
  async (data) => {
    const res = await axiosInstance.put(
      `/api/expense/office-expense/${data.id}`,
      data.body
    );
    return res.data;
  }
);

// initial State
const initialState = {
  pending: false,
  createExpenseResponse: {},
  expenses: [],
  expenseCats: [],
  officeExpenses: [],
  error: false,
  snackExpense: {},
  pdfResponse: {},
};

// Slice
export const expenseSlice = createSlice({
  name: "expense",
  initialState,
  reducers: {
    resetSnkExpense(state) {
      state.snackExpense = {};
      state.pdfResponse = {};
    },
  },
  extraReducers: {
    [createExpense.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [createExpense.fulfilled]: (state, action) => {
      state.pending = true;
      state.expenses = action.payload.data;
      state.snackExpense = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [createExpense.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [createExpenseCat.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [createExpenseCat.fulfilled]: (state, action) => {
      state.pending = true;
      state.expenseCats = action.payload.data;
      state.snackExpense = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [createExpenseCat.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [getExpenseCat.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getExpenseCat.fulfilled]: (state, action) => {
      state.pending = true;
      state.expenseCats = action.payload;
    },
    [getExpenseCat.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [updateExpenseCat.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [updateExpenseCat.fulfilled]: (state, action) => {
      state.pending = true;
      state.expenseCats = action.payload.data;
      state.snackExpense = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [updateExpenseCat.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [deleteExpenseCat.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [deleteExpenseCat.fulfilled]: (state, action) => {
      state.pending = true;
      state.expenseCats = action.payload.data;
      state.snackExpense = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [deleteExpenseCat.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [getExpense.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getExpense.fulfilled]: (state, action) => {
      state.pending = true;
      state.expenses = action.payload;
    },
    [getExpense.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [deleteExpense.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [deleteExpense.fulfilled]: (state, action) => {
      state.pending = true;
      state.expenses = action.payload.data;
      state.snackExpense = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [deleteExpense.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [updateExpense.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [updateExpense.fulfilled]: (state, action) => {
      state.pending = true;
      state.expenses = action.payload.data;
      state.snackExpense = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [updateExpense.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [uploadExpenseImages.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [uploadExpenseImages.fulfilled]: (state) => {
      state.pending = true;
    },
    [uploadExpenseImages.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [selectedPDFDownload.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [selectedPDFDownload.fulfilled]: (state, action) => {
      state.pending = true;
      state.pdfResponse = action.payload;
      state.snackExpense = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [selectedPDFDownload.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [storeOfficeExpense.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [storeOfficeExpense.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackExpense = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [storeOfficeExpense.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [getOfficeExpense.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getOfficeExpense.fulfilled]: (state, action) => {
      state.pending = false;
      state.officeExpenses = action.payload.officeExpenses;
    },
    [getOfficeExpense.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [deleteOfficeExpense.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [deleteOfficeExpense.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackExpense = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [deleteOfficeExpense.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [updateOfficeExpense.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [updateOfficeExpense.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackExpense = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [updateOfficeExpense.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
  },
});

export const { resetSnkExpense } = expenseSlice.actions;
export default expenseSlice.reducer;
