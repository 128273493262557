import Grid from "@mui/material/Grid";
import BreadcrumbsTemplate from "../components/misc/BreadcrumbsTemplate";
import BlogCard from "./adminPanel/blog/components/BlogCard";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBlogs } from "../reduxToolkit/features/adminPanel/blog";
import LoadingBox from "../components/misc/Loading";
import {
  Alert,
  Autocomplete,
  Box,
  Container,
  createFilterOptions,
  TextField,
} from "@mui/material";
import Seo from "../utils/Seo";
import { PF } from "../config";

// Limit options to show in autocomplete
const OPTIONS_LIMIT = 5;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) => {
  return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
};

export default function Blog() {
  const dispatch = useDispatch();
  const [loadingMain, setLoadingMain] = useState(false);
  const [blogList, setBlogList] = useState([]);
  const [blogListEn, setBlogListEn] = useState([]);
  const [searchingBlogList, setSearchingBlogList] = useState([]);

  const [lang, setLang] = useState("en");
  const [langId, setLangId] = useState("");
  const [contentSettings, setContentSettings] = useState([]);

  const [viewMode, setViewMode] = useState([]);

  // store
  const { langs } = useSelector((state) => state.misc);
  const { blogs } = useSelector((state) => state.blog);
  const { activeLangFront } = useSelector((state) => state.miscFront);
  const { webSettings } = useSelector((state) => state.webSettings);
  const { managedContentSettings } = useSelector(
    (state) => state.managedContentSettings
  );

  // Functions
  const getLangId = (langCode) => {
    let id = "";
    const L = langs.find((l) => l.langCode === langCode);
    if (L) id = L._id;
    return id;
  };

  const getEnInfo = (detectorId) => {
    let obj = {
      title: "",
    };
    const B = blogListEn.find((b) => b._id === detectorId);
    if (B) obj = { ...B };
    return obj;
  };

  const handleSearch = (e, newValue) => {
    setSearchingBlogList(newValue);
  };

  useEffect(() => {
    setLoadingMain(true);
    dispatch(getBlogs());
    // dispatch(getAdmins());
    setTimeout(() => {
      setLoadingMain(false);
    }, 1000);
  }, []);

  // Set Blogs
  useEffect(() => {
    if (blogs && blogs.length > 0) {
      const B = blogs.filter((b) => b.langId === langId);
      if (B) setBlogList(B);
      const BEn = blogs.filter((b) => b.langId === getLangId("en"));
      if (BEn) setBlogListEn(BEn);
    }
  }, [blogs, langId]);

  // Set Language
  useEffect(() => {
    if (activeLangFront) {
      setLang(activeLangFront.langCode);
      setLangId(activeLangFront._id);
    }
  }, [activeLangFront]);

  const scroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Set Blogs
  useEffect(() => {
    // dispatch(getBlogs());
    setTimeout(() => {
      scroll();
    }, 250);
  }, []);

  // Set Language
  useEffect(() => {
    if (activeLangFront) setLang(activeLangFront.langCode);
  }, [activeLangFront]);

  // Set Settings
  useEffect(() => {
    if (managedContentSettings && managedContentSettings.length > 0) {
      const M = managedContentSettings.find((m) => m.langId === langId);
      if (M) setContentSettings(M);
    }
  }, [langId, managedContentSettings]);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate

  return (
    <>
      <Seo
        title={
          (contentSettings.siteSeoSettings &&
            contentSettings.siteSeoSettings[1] &&
            contentSettings.siteSeoSettings[1].siteName) ||
          "Blog | Elifesaving Medicine"
        }
        description={
          (contentSettings.siteSeoSettings &&
            contentSettings.siteSeoSettings[1] &&
            contentSettings.siteSeoSettings[1].siteDesc) ||
          "Blog - Elifesaving Medicine ... H# DCC1, Momin Shoroni Road, North Ibrahimpur, Mirpur-14, Dhaka- 1206, Bangladesh;"
        }
        name={
          (contentSettings.siteSeoSettings &&
            contentSettings.siteSeoSettings[1] &&
            contentSettings.siteSeoSettings[1].companyName) ||
          "Elifesaving Medicine"
        }
        type="website"
        url={window.location.href || ""}
        imageUrl={
          webSettings.siteLogo !== ""
            ? PF + "/misc/" + webSettings.siteLogo
            : "https://mfw.com.bd/wp-content/uploads/2021/05/getImage.png"
        }
        langCode={lang}
      />
      <BreadcrumbsTemplate b3={translateFront("Blog")} />
      {loadingMain ? (
        <LoadingBox w={"100%"} />
      ) : (
        <Container>
          {loadingMain ? (
            <></>
          ) : (
            <Autocomplete
              multiple
              onChange={(e, newValue) => handleSearch(e, newValue)}
              disablePortal
              options={blogList}
              // options={searchDeliveryList}
              filterOptions={filterOptions}
              getOptionLabel={(option) =>
                option.title
                  ? option.title +
                    (lang !== "en"
                      ? " (" + getEnInfo(option.blogDetectorId).title + ")"
                      : "")
                  : option.title
              }
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option.title}{" "}
                  {lang !== "en"
                    ? " (" + getEnInfo(option.blogDetectorId).title + ")"
                    : ""}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={translateFront("Search Blogs...")}
                  // variant="standard"
                  size="small"
                  fullWidth
                />
              )}
              sx={{ mt: 1 }}
            />
          )}

          <Grid container spacing={1} sx={{ mt: 1 }}>
            {blogList.length < 1 ? (
              <Grid item xs={12}>
                <Alert
                  severity="warning"
                  fullWidth
                  sx={{ justifyContent: "center" }}
                >
                  {translateFront("No Blogs...")}
                </Alert>
              </Grid>
            ) : (
              (searchingBlogList.length > 0 ? searchingBlogList : blogList).map(
                (x, i) => (
                  <Grid
                    item
                    key={i}
                    xs={12}
                    md={viewMode.includes(x._id) ? 12 : 6}
                  >
                    <BlogCard
                      x={x}
                      blogListEn={blogListEn}
                      lang={lang}
                      frontEnd={true}
                      setViewMode={setViewMode}
                      viewMode={viewMode}
                    />
                  </Grid>
                )
              )
            )}
            {/* </Grid> */}
          </Grid>
        </Container>
      )}
    </>
  );
}
