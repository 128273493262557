import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getReq,
  removeReq,
} from "../../../../reduxToolkit/features/adminPanel/review";
import Typography from "@mui/material/Typography";
import LoadingBox from "../../../../components/misc/Loading";
import { getReviews } from "../../../../reduxToolkit/features/customerOnProduct";
import {
  Divider,
  Rating,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
} from "@mui/material";
import { getMedicines } from "../../../../reduxToolkit/features/adminPanel/medicine";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { PF } from "../../../../config";

// Icons
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import LoadingButton from "@mui/lab/LoadingButton";

export default function ViewReviewerRequests() {
  const dispatch = useDispatch();
  // Use State
  const [loadingMain, setLoadingMain] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [reqList, setReqList] = useState([]);
  const [removeBuffer, setRemoveBuffer] = useState("");

  // ? Local Data
  const localAdminData = JSON.parse(localStorage.getItem("adminLoginInfo"));

  // ? Store
  const { requests } = useSelector((state) => state.review);
  const { reviews } = useSelector((state) => state.customerOnProduct);
  const { medicines } = useSelector((state) => state.medicine);

  // ? Functions
  const getReviewInfo = (reviewId) => {
    let reviewInfo = {};
    if (reviews && reviews.length > 0) {
      const R = reviews.find((r) => r._id === reviewId);
      if (R) reviewInfo = R;
    }
    return reviewInfo;
  };

  const getMedInfo = (medId) => {
    if (medicines && medicines.length > 0) {
      const M = medicines.find((m) => m.lang === "en");
      if (M && M.data) {
        const medInfo = M.data.find((m) => m.fixedMedicineId === medId);
        return medInfo;
      }
    }
    return {};
  };

  const getGenericBrand = (id) => {
    let gb = "";
    const GB = getMedInfo(getReviewInfo(id).medicineId).genericBrand;
    if (GB) gb = GB;
    return gb;
  };

  const getMedImg = (id) => {
    let img = "";
    const ImgArr = getMedInfo(getReviewInfo(id).medicineId).image;
    if (ImgArr && ImgArr.length > 0) {
      const Img = ImgArr.find((i) => i.default);
      if (Img) img = Img;
    }
    return img;
  };

  const handleRemoveReq = (id) => {
    setLoadingRemove(true);
    dispatch(removeReq({ id, creatorId: localAdminData._id }));
    setTimeout(() => {
      dispatch(getReq());
      setRemoveBuffer("");
      setLoadingRemove(false);
    }, 1000);
  };

  // ? Use Effect
  useEffect(() => {
    setLoadingMain(true);
    dispatch(getReq());
    dispatch(getReviews());
    if (!(medicines && medicines.length > 0)) dispatch(getMedicines());
    setTimeout(() => {
      setLoadingMain(false);
    }, 1000);
  }, []);

  useEffect(() => {
    setLoadingMain(true);
    if (requests && requests.length > 0) {
      setTimeout(() => {
        setReqList([...requests]);
        setLoadingMain(false);
      }, 1000);
    }
  }, [requests]);

  // ! translate
  const [siteLang, setSiteLang] = useState("");
  // Local Store
  const localLangData = JSON.parse(localStorage.getItem("lang"));
  // Store
  const { langs, activeLang } = useSelector((state) => state.misc);
  const [langSettings1, setLangSettings1] = useState([]);
  const [langSettings2, setLangSettings2] = useState([]);
  const title1 = "Reviewer Info Request";
  const title2 = "Misc Words";

  useEffect(() => {
    if (langs && langs.length > 0 && siteLang !== "") {
      const L = langs.find((l) => l.langCode === siteLang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      const A = arr.find((l) => l._title === title1);
      if (A) setLangSettings1(A.data);
      const B = arr.find((l) => l._title === title2);
      if (B) setLangSettings2(B.data);
    }
  }, [siteLang, langs]);

  const translate1 = (String) => translation(String, title1, 1);
  const translate2 = (String) => translation(String, title2, 2);

  const translation = (String, title, index) => {
    let translation = "";
    let settings = [];
    if (index === 1) {
      settings = langSettings1;
    } else if (index === 2) {
      settings = langSettings2;
    }
    const T = settings.find((ls) => ls.title === String);
    if (T && T.value) translation = T.value;
    else {
      let arr = [];
      if (langs && langs.length > 0 && siteLang !== "") {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.settings && LEn.settings.length > 0) arr = LEn.settings;
        const S = arr.find((l) => l._title === title);
        if (S) {
          const T = S.data.find((ls) => ls.title === String);
          if (T && T.value) translation = T.value;
        }
      }
    }
    return translation;
  };

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setSiteLang(activeLang.langCode);
    } else {
      if (localLangData) {
        setSiteLang(localLangData.langCode);
      }
    }
  }, [activeLang]);
  // ! translate

  return (
    <Grid container spacing={1}>
      {loadingMain ? (
        <LoadingBox w={"100%"} h={"100%"} />
      ) : (
        reqList.map((x, i) => (
          <Grid item key={i} xs={12} sm={6} md={4} xl={3}>
            <Card>
              <CardContent>
                <Grid container spacing={0} alignItems="center">
                  <Grid item>
                    <LazyLoadImage
                      effect="blur"
                      src={
                        getMedImg(x.reviewId) !== ""
                          ? PF +
                            "adminPanel/medicines/img/" +
                            getMedImg(x.reviewId).src
                          : PF + "adminPanel/medicines/no-medicine-image.jpg"
                      }
                      alt={getMedImg(x.reviewId).alt}
                      style={{
                        height: 30,
                        objectFit: "cover",
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="body1" color="initial">
                      {getGenericBrand(x.reviewId) || ""}
                    </Typography>
                  </Grid>
                </Grid>
                <Typography variant="body1" color="text.secondary">
                  {x.requesterEmail}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {x.requesterPhone}
                </Typography>

                <Typography variant="subtitle1" color="initial">
                  {getReviewInfo(x.reviewId).review.toUpperCase() || ""}
                </Typography>
                <Rating
                  value={getReviewInfo(x.reviewId).rating || 0}
                  precision={0.5}
                  readOnly
                />
              </CardContent>
              <CardActions>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <LoadingButton
                      size="small"
                      variant="outlined"
                      fullWidth
                      color="error"
                      onClick={() => {
                        handleRemoveReq(x._id);
                        setRemoveBuffer(x._id);
                      }}
                      loading={
                        removeBuffer === x._id && loadingRemove ? true : false
                      }
                    >
                      {translate1("Remove")}
                    </LoadingButton>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      size="small"
                      variant="contained"
                      startIcon={<ForwardToInboxIcon />}
                      fullWidth
                      color="secondary"
                      onClick={() => window.open(`mailto: ${x.requesterEmail}`)}
                    >
                      {translate1("Send Email")}
                    </Button>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
        ))
      )}
    </Grid>
  );
}
