import LoadingButton from "@mui/lab/LoadingButton";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  createFilterOptions,
  Grid,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import getSymbolFromCurrency from "currency-symbol-map";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { currencyFormatter } from "../../../../../hooks/currencyFormatter";
import {
  editManualCurrencyRatio,
  getCurrency,
  updateCurrency,
} from "../../../../../reduxToolkit/features/adminPanel/misc";

import UpdateIcon from "@mui/icons-material/Update";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
const floatRegExp = new RegExp("^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$");

// Limit options to show in autocomplete
const OPTIONS_LIMIT = 8;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) => {
  return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
};

export default function TotalCost({ total, setTotal }) {
  const dispatch = useDispatch();

  // store
  const { currencyList } = useSelector((state) => state.misc);

  // use States
  const [loading, setLoading] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState("USD");
  const [selectedCurrency, setSelectedCurrency] = useState(1);

  const [editCurrencyDlg, setEditCurrencyDlg] = useState(false);
  const [newCurrencyRatio, setNewCurrencyRatio] = useState(0);

  //   update Currency
  const handleUpdateCurrency = () => {
    setLoading(true);
    dispatch(updateCurrency());
    dispatch(getCurrency());
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  // Edit Ratio
  const editCurrencyRatio = (currency) => {
    setLoading(true);
    dispatch(editManualCurrencyRatio({ currency, newCurrencyRatio }));
    setEditCurrencyDlg(false);
    setNewCurrencyRatio(0);
    setTimeout(() => {
      dispatch(getCurrency());
      setLoading(false);
    }, 2000);
  };

  //   handle Currency Change
  const handleCurrencyChange = (e, newValue) => {
    setSelectedCurrency(newValue.exchangeRate);
    setCurrencySymbol(newValue.currencyName);
  };

  // get suppliers
  useEffect(() => {
    dispatch(getCurrency());
  }, []);

  useEffect(() => {
    let obj = { ...total };
    obj.variantTotal = Math.ceil(total.usdTotal * selectedCurrency);
    obj.variantTotalWithSymbol =
      currencySymbol + " " + currencyFormatter("en", obj.variantTotal);
    setTotal(obj);
  }, [selectedCurrency, total]);

  //! Translate
  // Store
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate
  return (
    <>
      <Grid item xs={12}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12} sm={12} md={2}>
            {currencyList.length > 0 && !loading && (
              <Autocomplete
                size="small"
                onChange={(e, newValue) => handleCurrencyChange(e, newValue)}
                disablePortal
                options={currencyList}
                defaultValue={currencyList.find(
                  (c) => c.currencyName === "USD"
                )}
                filterOptions={filterOptions}
                getOptionLabel={(option) =>
                  "(" +
                  getSymbolFromCurrency(option.currencyName) +
                  ") " +
                  option.currencyName +
                  " [" +
                  option.exchangeRate +
                  "]"
                }
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{
                      "& > img": { mr: 2, flexShrink: 0 },
                    }}
                    {...props}
                  >
                    ({getSymbolFromCurrency(option.currencyName)}){" "}
                    {option.currencyName} [{option.exchangeRate}]
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={translate0("Select Secondary Currency")}
                    variant="standard"
                    size="small"
                    fullWidth
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <LoadingButton
              onClick={() => {
                setEditCurrencyDlg(true);
                setNewCurrencyRatio(selectedCurrency);
              }}
              variant="contained"
              size="small"
              fullWidth
              loading={loading}
              endIcon={<CurrencyExchangeIcon />}
              color="info"
            >
              {translate0("Edit Ratio")}
            </LoadingButton>
            <Dialog open={editCurrencyDlg}>
              <DialogTitle>
                {translate0("Edit Currency")} "{currencySymbol}"
              </DialogTitle>
              <DialogContent>
                <TextField
                  label={translate0("Ratio to USD")}
                  value={newCurrencyRatio}
                  onChange={(e) => {
                    if (floatRegExp.test(e.target.value))
                      setNewCurrencyRatio(e.target.value);
                  }}
                  size="small"
                  fullWidth
                  variant="standard"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setEditCurrencyDlg(false)} color="error">
                  Close
                </Button>
                <Button
                  onClick={() => editCurrencyRatio(currencySymbol)}
                  color="primary"
                  disabled={newCurrencyRatio === ""}
                >
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <LoadingButton
              onClick={handleUpdateCurrency}
              variant="contained"
              size="small"
              fullWidth
              loading={loading}
              endIcon={<UpdateIcon />}
              color="warning"
            >
              {translate0("Update Currency")}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          size="small"
          sx={{ pointerEvents: "none", mb: 1 }}
          fullWidth
          color="error"
        >
          {translate0("Total")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid
              container
              spacing={1}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="h5">
                  {translate0("Total In USD")}:{" "}
                  <span style={{ color: "blue" }}>
                    $ {currencyFormatter("en", total.usdTotal)}
                  </span>
                </Typography>
              </Grid>

              <Grid item flex={1}>
                <TextField
                  label={translate0("in Text")}
                  value={total.usdTotalInText}
                  onChange={(e) => {
                    let obj = { ...total };
                    obj.usdTotalInText = e.target.value;
                    setTotal(obj);
                  }}
                  size="small"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
            </Grid>
            {currencySymbol !== "USD" && (
              <Grid
                container
                spacing={1}
                justifyContent="center"
                alignItems="center"
                sx={{ mt: 1 }}
              >
                <Grid item>
                  <Typography variant="h5">
                    {translate0("Secondary Total")}:{" "}
                    <span style={{ color: "blue" }}>
                      {getSymbolFromCurrency(currencySymbol)}{" "}
                      {currencyFormatter("en", total.variantTotal)}
                    </span>
                  </Typography>
                </Grid>
                <Grid item flex={1}>
                  <TextField
                    label={translate0("Total In USD")}
                    value={total.variantTotalInText}
                    onChange={(e) => {
                      let obj = { ...total };
                      obj.variantTotalInText = e.target.value;
                      setTotal(obj);
                    }}
                    size="small"
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            )}
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
