import {
  Autocomplete,
  Box,
  Button,
  // createFilterOptions,
  Grid,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PF } from "../../../../../config";

// Limit options to show in autocomplete
// const OPTIONS_LIMIT = 30;
// const defaultFilterOptions = createFilterOptions();

// const filterOptions = (options, state) => {
//   return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
// };

export default function SelectionManual({
  medList,
  medListEn,
  siteLang,
  setSelectedForPreview,
}) {
  const [loading, setLoading] = useState(false);
  const [selectedManualMeds, setSelectedManualMeds] = useState([]);

  // get MEd Name En
  const getMedNameEn = (id) => {
    const M = medListEn.find((m) => m.fixedMedicineId === id);
    if (M) return M.genericBrand;
  };

  const handleSearch = (e, newValue) => {
    setSelectedForPreview(newValue);
    setSelectedManualMeds(newValue);
  };

  //! Translate
  // Store
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      <Grid container flexDirection={"column"}>
        <Grid item xs={12}>
          {!loading && (
            <Autocomplete
              disablePortal
              multiple
              options={medList}
              onChange={(e, newValue) => handleSearch(e, newValue)}
              // filterOptions={filterOptions}
              getOptionLabel={(option) => option.genericBrand}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img
                    loading="lazy"
                    width="20"
                    src={
                      option.image.length > 0
                        ? option.image
                            .find((i) => i.default)
                            .src.includes("http")
                          ? option.image.find((i) => i.default).src
                          : PF +
                            "adminPanel/medicines/img/" +
                            option.image.find((i) => i.default).src
                        : PF + "adminPanel/medicines/no-medicine-image-mfw.png"
                    }
                    alt="admins"
                  />
                  {option.genericBrand}{" "}
                  {siteLang !== "en" &&
                    " (" + getMedNameEn(option.medicineDetectorId) + ")"}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={translate0("Search") + "..."}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              minHeight: "20vh",
              overflow: "auto",
              border: "1px solid lightblue",
              borderRadius: ".25rem",
              my: 1,
              p: 1,
            }}
          >
            <Grid container spacing={1}>
              {selectedManualMeds?.map((x, i) => (
                <Grid item key={i} xs={12}>
                  <Button
                    variant="outlined"
                    fullWidth
                    size="small"
                    sx={{ justifyContent: "flex-start", pointerEvents: "none" }}
                  >
                    {x.genericBrand}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
