import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  DialogTitle,
  DialogContentText,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Input,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import LoadingBox from "../../../components/misc/Loading";

// Icons
import HandymanIcon from "@mui/icons-material/Handyman";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import PhotoCamera from "@mui/icons-material/PhotoCamera";

// Media Query
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { getAllPaymentMethods } from "../../../reduxToolkit/features/adminPanel/payment";
import ManagePayment from "../../adminPanel/payment/components/ManagePayment";
import {
  confirmPayment,
  getAllOrderBackEnd,
  rejectPayment,
  setPaymentAccount,
  shippedOrder,
  uploadDeliveryReceivedImg,
} from "../../../reduxToolkit/features/adminPanel/order";
import { getAllOrders } from "../../../reduxToolkit/features/frontend/checkout";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { PF } from "../../../config";
import { getAllCustomers } from "../../../reduxToolkit/features/auth";
import { ExpandMore } from "@mui/icons-material";
import { getNotification } from "../../../reduxToolkit/features/adminPanel/notification";

export default function OrderHistoryFromCustomer({
  x,
  lang,
  customerId,
  // orderBackends,
  setOrderComplete,
  translate1,
  translate2,
  setEditDisable,
}) {
  const dispatch = useDispatch();
  // Media Query
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  const [showPaymentSelection, setShowPaymentSelection] = useState("");
  const [paymentAccountId, setPaymentAccountId] = useState("");
  // const [orderBackend, setOrderBackend] = useState({
  //   paymentAccountId:"",
  //   paymentReceiptImg:""

  // })

  const [stepBackend, setStepBackend] = useState(0);

  const [prevPaymentAccountId, setPrevPaymentAccountId] = useState("");
  const [paymentReceiptImg, setPaymentReceiptImg] = useState("");

  const [shippingDocumentImg, setShippingDocumentImg] = useState("");

  const [uploadShippingDocumentImg, setUploadShippingDocumentImg] =
    useState("");
  const [trackId, setTrackId] = useState("");
  const [trackingDB, setTrackingDB] = useState("");

  const [deliveryReceivedImg, setDeliveryReceivedImg] = useState("");

  const [paymentLoading, setPaymentLoading] = useState(false);
  // Dialog
  const [managePaymentDlg, setManagePaymentDlg] = useState(false);
  const [paymentImgViewDlg, setPaymentImgViewDlg] = useState(false);
  const [deliveryImgViewDlg, setDeliveryImgViewDlg] = useState(false);
  const [shippingDocumentImgViewDlg, setShippingDocumentImgViewDlg] =
    useState(false);

  // LocalData
  const localAdminData = () => {
    let adminData = JSON.parse(localStorage.getItem("adminLoginInfo"));
    return adminData;
  };

  // Store
  const { paymentMethods } = useSelector((state) => state.payment);
  const { orderBackends } = useSelector((state) => state.order);
  const { orders } = useSelector((state) => state.checkout);

  // Functions
  const getPaymentMethod = (id) => {
    const P = paymentMethods.find(
      (p) => (lang === "en" ? p._id : p.paymentDetectorId) === id
    );
    if (P) return P;
    return {};
  };

  const handleConfirmOrder = () => {
    setPaymentLoading(true);
    dispatch(
      setPaymentAccount({
        orderId: x._id,
        paymentAccountId,
        customerId,
        step: 0,
        creatorId: localAdminData()._id,
      })
    );
    setTimeout(() => {
      dispatch(getNotification());
      dispatch(getAllOrderBackEnd());
      setPaymentLoading(false);
    }, 1000);
  };
  const handleShowPaymentSelection = () => {
    if (showPaymentSelection === "") setShowPaymentSelection(x._id);
    else setShowPaymentSelection("");
  };

  const handleRejectPayment = () => {
    setPaymentLoading(true);
    dispatch(
      rejectPayment({
        orderId: x._id,
        customerId,
        paymentReceiptImg,
        step: 0,
        creatorId: localAdminData()._id,
      })
    );
    setTimeout(() => {
      dispatch(getAllOrderBackEnd());
      dispatch(getNotification());
      setPaymentLoading(false);
    }, 1000);
  };

  const handleConfirmPayment = () => {
    setPaymentLoading(true);
    let obj = {
      orderId: x._id,
      customerId,
      shippingDocumentImg: "",
      trackId,
      step: 1,
      creatorId: localAdminData()._id,
    };

    if (uploadShippingDocumentImg !== "") {
      const data = new FormData();
      const ext = uploadShippingDocumentImg.name.split(".");
      const filename =
        Date.now() +
        "-" +
        x.orderNumber.replace(/\s+/g, "") +
        "-shipping-document-img." +
        ext.slice(-1);
      data.append("name", filename);
      data.append("deliveryReceivedImg", uploadShippingDocumentImg);
      obj.shippingDocumentImg = filename;
      dispatch(uploadDeliveryReceivedImg(data));
    }
    dispatch(confirmPayment(obj));
    setTimeout(() => {
      dispatch(getAllOrderBackEnd());
      dispatch(getNotification());
      setPaymentLoading(false);
    }, 1000);
  };

  const handleShipped = () => {
    setPaymentLoading(true);
    dispatch(
      shippedOrder({
        orderId: x._id,
        customerId,
        step: 2,
        creatorId: localAdminData()._id,
      })
    );
    setTimeout(() => {
      dispatch(getAllOrderBackEnd());
      dispatch(getNotification());
      setPaymentLoading(false);
    }, 1000);
  };

  useEffect(() => {
    if (!(paymentMethods && paymentMethods.length > 0))
      dispatch(getAllPaymentMethods());
    if (!(orders && orders.length > 0)) dispatch(getAllOrders());
    if (!(orderBackends && orderBackends.length > 0))
      dispatch(getAllOrderBackEnd());
  }, []);

  useEffect(() => {
    setPaymentLoading(true);
    const Ob = orderBackends.find((ob) => ob.orderId === x._id);
    if (Ob) {
      if (Ob.paymentAccountId) setPrevPaymentAccountId(Ob.paymentAccountId);
      setPaymentReceiptImg(Ob.paymentReceiptImg || "");
      setShippingDocumentImg(Ob.shippingDocumentImg || "");
      setTrackingDB(Ob.trackId || "");
      setStepBackend(Ob.step || 0);
      setDeliveryReceivedImg(Ob.deliveryReceivedImg || "");
      if (Ob.step === 3) {
        setOrderComplete(true);
      }
    }
    setTimeout(() => {
      setPaymentLoading(false);
    }, 1000);
  }, [orderBackends, x._id]);

  useEffect(() => {
    if (paymentReceiptImg !== "") {
      setEditDisable(true);
    } else setEditDisable(false);
  }, [paymentReceiptImg]);

  // Store
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate

  return (
    <>
      {paymentLoading ? (
        <LoadingBox w={"100%"} />
      ) : prevPaymentAccountId !== "" ? (
        <>
          {paymentReceiptImg !== "" ? (
            <>
              {trackingDB !== "" ? (
                <>
                  <Divider />
                  <Typography
                    variant="body2"
                    color="gray"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    {translate1("Payment Receipt")}:{" "}
                    {paymentReceiptImg !== "" && (
                      <LazyLoadImage
                        effect="blur"
                        src={
                          paymentReceiptImg !== ""
                            ? PF +
                              "/adminPanel/orders/paymentReceipt/" +
                              paymentReceiptImg
                            : PF + "/adminPanel/medicines/no-medicine-image.jpg"
                        }
                        alt="payment-img"
                        style={{
                          objectFit: "cover",
                          width: "40px",
                          cursor: "pointer",
                          marginLeft: 2,
                          // border: "1px solid navy",
                          borderRadius: ".25rem",
                          padding: 1,
                        }}
                        onClick={() => setPaymentImgViewDlg(true)}
                      />
                    )}
                  </Typography>
                  <Divider />
                  <Typography
                    variant="body2"
                    color="gray"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    {translate0("Shipping Document")}
                    <LazyLoadImage
                      effect="blur"
                      src={
                        shippingDocumentImg !== ""
                          ? PF +
                            "/adminPanel/orders/deliveryReceived/" +
                            shippingDocumentImg
                          : PF + "/adminPanel/medicines/no-medicine-image.jpg"
                      }
                      alt="shippingDocumentImg"
                      style={{
                        objectFit: "cover",
                        width: "40px",
                        cursor: "pointer",
                        marginLeft: 2,
                        // border: "1px solid navy",
                        borderRadius: ".25rem",
                        padding: 1,
                      }}
                      onClick={() => setShippingDocumentImgViewDlg(true)}
                    />
                  </Typography>

                  <Typography variant="body2" color="gray">
                    {translate1("Tracking ID")}:{" "}
                    <span style={{ color: "black" }}> #{trackingDB}</span>
                  </Typography>
                  {stepBackend === 2 ? (
                    <>
                      <Button
                        variant="outlined"
                        size="small"
                        fullWidth
                        color="warning"
                        sx={{ pointerEvents: "none" }}
                      >
                        {translate2("Awaiting Delivery Confirmation")}
                      </Button>
                    </>
                  ) : stepBackend === 3 ? (
                    <>
                      <Divider />
                      <Typography variant="body2" color="gray">
                        {translate1("Customer Confirmed")} ✔️{" "}
                      </Typography>
                      <Divider />
                      <Typography
                        variant="body2"
                        color="gray"
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {translate1("Delivery Image")}:{" "}
                        <LazyLoadImage
                          effect="blur"
                          src={
                            deliveryReceivedImg !== ""
                              ? PF +
                                "/adminPanel/orders/deliveryReceived/" +
                                deliveryReceivedImg
                              : PF +
                                "/adminPanel/medicines/no-medicine-image.jpg"
                          }
                          alt="deliveryReceivedImg"
                          style={{
                            objectFit: "cover",
                            width: "40px",
                            cursor: "pointer",
                            marginLeft: 2,
                            // border: "1px solid navy",
                            borderRadius: ".25rem",
                            padding: 1,
                          }}
                          onClick={() => setDeliveryImgViewDlg(true)}
                        />
                      </Typography>
                    </>
                  ) : (
                    <Button
                      variant="contained"
                      size="small"
                      fullWidth
                      color="success"
                      onClick={handleShipped}
                    >
                      {translate2("Shipping Complete")}
                    </Button>
                  )}
                </>
              ) : (
                <>
                  <Button
                    variant="contained"
                    size="small"
                    fullWidth
                    sx={{ mb: 1 }}
                  >
                    {translate0("Customer Payment Receipt")}
                  </Button>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => setPaymentImgViewDlg(true)}
                  >
                    <LazyLoadImage
                      effect="blur"
                      src={
                        paymentReceiptImg !== ""
                          ? PF +
                            "/adminPanel/orders/paymentReceipt/" +
                            paymentReceiptImg
                          : PF + "/adminPanel/medicines/no-medicine-image.jpg"
                      }
                      alt="payment-img"
                      style={{
                        objectFit: "cover",
                        width: "100px",
                      }}
                    />
                  </Box>

                  <TextField
                    label="Tracking Id"
                    value={trackId}
                    onChange={(e) => setTrackId(e.target.value)}
                    variant="standard"
                    fullWidth
                    sx={{ mb: 1 }}
                  />
                  <Grid
                    container
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item>
                      <LazyLoadImage
                        effect="blur"
                        src={
                          uploadShippingDocumentImg !== ""
                            ? URL.createObjectURL(uploadShippingDocumentImg)
                            : PF + "/adminPanel/medicines/no-medicine-image.jpg"
                        }
                        alt="delivery-img"
                        style={{
                          objectFit: "cover",
                          width: "200px",
                        }}
                      />
                    </Grid>
                    <Typography variant="body2" color="inherit" align="center">
                      {translate0("Upload Shipping Document (Optional)")}
                    </Typography>
                    <Grid item position="relative">
                      <Box>
                        <label htmlFor={`shipping-document-img-input-${x._id}`}>
                          <Input
                            accept="image/*"
                            id={`shipping-document-img-input-${x._id}`}
                            required
                            type="file"
                            sx={{ display: "none" }}
                            onChange={(e) => {
                              setUploadShippingDocumentImg(e.target.files[0]);
                            }}
                          />

                          <IconButton
                            color="primary"
                            aria-label={`upload-picture-as-${x._id}`}
                            component="span"
                          >
                            <PhotoCamera />
                          </IconButton>
                        </label>
                      </Box>
                    </Grid>
                    <Grid item>
                      {uploadShippingDocumentImg !== "" && (
                        <Button
                          variant="contained"
                          size="small"
                          color="error"
                          onClick={() => {
                            setUploadShippingDocumentImg("");
                          }}
                        >
                          {translate0("Remove Image")}
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                  <Button
                    variant="contained"
                    size="small"
                    fullWidth
                    color="error"
                    sx={{ mt: 1 }}
                    onClick={handleRejectPayment}
                  >
                    {translate2("Reject & re-request")}
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    disabled={trackId === ""}
                    fullWidth
                    color="success"
                    sx={{ mt: 1 }}
                    onClick={handleConfirmPayment}
                  >
                    {translate2("Confirm Payment & Ship")}
                  </Button>
                </>
              )}
            </>
          ) : (
            <Button
              variant="outlined"
              size="small"
              fullWidth
              color="warning"
              sx={{ pointerEvents: "none" }}
            >
              {translate2("Awaiting Payment Receipt")}
            </Button>
          )}
        </>
      ) : (
        <>
          <Button
            variant={showPaymentSelection ? "outlined" : "contained"}
            size="small"
            fullWidth
            color="secondary"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              // pointerEvents: "none",
              mb: 1,
            }}
            endIcon={
              showPaymentSelection ? (
                <ArrowDropUpIcon />
              ) : (
                <ArrowDropDownCircleIcon />
              )
            }
            onClick={handleShowPaymentSelection}
          >
            {translate2("Set Payment Account")}
          </Button>
          {showPaymentSelection === x._id && (
            <>
              {
                <>
                  <Grid container spacing={0} alignItems="center">
                    <Grid item xs={11}>
                      {getPaymentMethod(x.paymentMethodId).accountArr &&
                      getPaymentMethod(x.paymentMethodId).accountArr.length >
                        0 ? (
                        <FormControl fullWidth size="small">
                          <InputLabel id="demo-simple-select-label">
                            {translate2("Account")}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={paymentAccountId}
                            label="Account"
                            onChange={(e) =>
                              setPaymentAccountId(e.target.value)
                            }
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {getPaymentMethod(x.paymentMethodId).accountArr.map(
                              (y, j) =>
                                y.active && (
                                  <MenuItem value={y._id} key={j}>
                                    <Typography variant="body2" color="initial">
                                      {y.accountTitle}
                                      {smUp && (
                                        <>
                                          <Divider />
                                          {y.accountDetails}
                                        </>
                                      )}
                                    </Typography>
                                  </MenuItem>
                                )
                            )}
                          </Select>
                        </FormControl>
                      ) : (
                        <Alert severity="warning">
                          {translate2("No Account Available...")}
                        </Alert>
                      )}
                    </Grid>
                    <Grid item xs={1}>
                      <Tooltip
                        arrow
                        placement="top"
                        title="Manage Payment Methods"
                      >
                        <IconButton
                          size="small"
                          onClick={() => setManagePaymentDlg(true)}
                        >
                          <HandymanIcon color="secondary" />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Button
                    variant="contained"
                    size="small"
                    disabled={paymentAccountId === ""}
                    fullWidth
                    color="success"
                    sx={{ mt: 1 }}
                    onClick={handleConfirmOrder}
                  >
                    {translate2("Confirm Order")}
                  </Button>
                </>
              }
            </>
          )}
        </>
      )}
      {managePaymentDlg && (
        <Dialog open={managePaymentDlg} fullWidth maxWidth={"lg"}>
          <DialogContent>
            <ManagePayment />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setManagePaymentDlg(false)} color="error">
              {translate2("Close")}
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {paymentImgViewDlg && (
        <Dialog
          open={paymentImgViewDlg}
          onClose={() => setPaymentImgViewDlg(false)}
        >
          <DialogContent>
            <LazyLoadImage
              effect="blur"
              src={
                paymentReceiptImg !== ""
                  ? PF +
                    "/adminPanel/orders/paymentReceipt/" +
                    paymentReceiptImg
                  : PF + "/adminPanel/medicines/no-medicine-image.jpg"
              }
              alt="payment-img"
              style={{
                objectFit: "cover",
                width: "260px",
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() =>
                window.open(
                  `${PF}/adminPanel/orders/paymentReceipt/${paymentReceiptImg}`,
                  "_blank"
                )
              }
              color="primary"
            >
              {translate2("Download")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {deliveryImgViewDlg && (
        <Dialog
          open={deliveryImgViewDlg}
          onClose={() => setDeliveryImgViewDlg(false)}
        >
          <DialogContent>
            <LazyLoadImage
              effect="blur"
              src={
                deliveryReceivedImg !== ""
                  ? PF +
                    "/adminPanel/orders/deliveryReceived/" +
                    deliveryReceivedImg
                  : PF + "/adminPanel/medicines/no-medicine-image.jpg"
              }
              alt="deliveryReceivedImg"
              style={{
                objectFit: "cover",
                width: "260px",
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() =>
                window.open(
                  `${PF}/adminPanel/orders/deliveryReceived/${deliveryReceivedImg}`,
                  "_blank"
                )
              }
              color="primary"
              disabled={deliveryReceivedImg === ""}
            >
              {translate2("Download")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {shippingDocumentImgViewDlg && (
        <Dialog
          open={shippingDocumentImgViewDlg}
          onClose={() => setShippingDocumentImgViewDlg(false)}
        >
          <DialogContent>
            <LazyLoadImage
              effect="blur"
              src={
                shippingDocumentImg !== ""
                  ? PF +
                    "/adminPanel/orders/deliveryReceived/" +
                    shippingDocumentImg
                  : PF + "/adminPanel/medicines/no-medicine-image.jpg"
              }
              alt="shippingDocumentImg"
              style={{
                objectFit: "cover",
                width: "260px",
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() =>
                window.open(
                  `${PF}/adminPanel/orders/deliveryReceived/${shippingDocumentImg}`,
                  "_blank"
                )
              }
              color="primary"
              disabled={shippingDocumentImg === ""}
            >
              {translate2("Download")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
