import {
  Alert,
  AppBar,
  CardMedia,
  Dialog,
  Grid,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { PF } from "../../../../config";
// Icon
import CloseIcon from "@mui/icons-material/Close";
// Media Query
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function MedicineVideoDialog({
  x,
  mobileVideoDlg,
  setMobileVideoDlg,
}) {
  // Media Query
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  // useState
  const [activeVid, setActiveVid] = useState("");
  const [loading, setLoading] = useState(false);

  // useEffect
  useEffect(() => {
    setLoading(true);
    x.video && setActiveVid(x.video[0]);
    setLoading(false);
  }, [x]);
  return (
    <>
      {x.video && (
        <Dialog
          fullScreen
          open={mobileVideoDlg}
          onClose={() => setMobileVideoDlg(false)}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }} color="secondary">
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setMobileVideoDlg(false)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Video
              </Typography>
            </Toolbar>
          </AppBar>
          <Grid
            container
            spacing={0}
            flexDirection="column"
            alignItems="center"
          >
            {activeVid && (
              <Grid item sx={{ my: 1 }}>
                {activeVid !== "" && activeVid.externalLink ? (
                  <ReactPlayer
                    url={activeVid.src}
                    controls
                    height={!smUp ? "150px" : "190px"}
                    width="100%"
                  />
                ) : (
                  <CardMedia
                    component="video"
                    height={!smUp ? "100%" : 150}
                    image={PF + "adminPanel/medicines/vid/" + activeVid.src}
                    controls
                  />
                )}
              </Grid>
            )}
            <Grid item>
              <Grid container spacing={1} justifyContent="center">
                {x.video.map((y, j) => (
                  <Grid item>
                    <>
                      {j < 4 && (
                        <>
                          {y.externalLink ? (
                            <ReactPlayer
                              url={y.src}
                              controls
                              muted
                              height="80px"
                              width={!smUp ? "90px" : "50px"}
                              onPlay={() => setActiveVid(y)}
                            />
                          ) : (
                            <CardMedia
                              component="video"
                              sx={{
                                height: "80px",
                                width: !smUp ? "90px" : "80px",
                              }}
                              image={PF + "adminPanel/medicines/vid/" + y.src}
                              // controls
                              muted
                              onClick={() => setActiveVid(y)}
                            />
                          )}
                        </>
                      )}
                    </>
                  </Grid>
                ))}
                {loading && <Alert severity="warning">Loading....</Alert>}
              </Grid>
            </Grid>
          </Grid>
        </Dialog>
      )}
    </>
  );
}
