import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {
  Slide,
  TextField,
  Grid,
  Tooltip,
  IconButton,
  Typography,
  Box,
  Autocomplete,
  createFilterOptions,
} from "@mui/material";
import { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import LoadingBox from "../../../../components/misc/Loading";

// Icons
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect } from "react";
import { countries } from "../../../../utils/countryList";
import { useDispatch, useSelector } from "react-redux";
import {
  createDeliveryMethod,
  getAllDeliveryMethods,
  updateDeliveryMethod,
} from "../../../../reduxToolkit/features/adminPanel/delivery";
import { getNotification } from "../../../../reduxToolkit/features/adminPanel/notification";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

// Limit options to show in autocomplete
const OPTIONS_LIMIT = 5;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) => {
  return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
};

export default function EditDeliveryMethods({
  lang,
  langId,
  editDeliveryDlg,
  setEditDeliveryDlg,
  deliveryListEn,
  editBuffer,
}) {
  const dispatch = useDispatch();

  const [loadingEdit, setLoadingEdit] = useState(false);
  const [enVariant, setEnVariant] = useState("");
  const [defaultEnVariant, setDefaultEnVariant] = useState({});
  const [enVariantLoading, setEnVariantLoading] = useState(false);
  const [enVariantSelected, setEnVariantSelected] = useState(false);
  const [methodName, setMethodName] = useState("");
  const [deliverySummery, setDeliverySummery] = useState([""]);
  const [deliverySummeryErrorBuffer, setDeliverySummeryErrorBuffer] = useState(
    []
  );
  const [countryArr, setCountryArr] = useState([]);
  const [countryWithPrice, setCountryWithPrice] = useState([]);
  const [countryLoading, setCountryLoading] = useState(false);

  // Dialog
  const [initialValueDlg, setInitialValueDlg] = useState(false);
  const [tempInitKGValue, setTempInitKGValue] = useState(undefined);
  const [tempIncrementKGValue, setTempIncrementKGValue] = useState(undefined);
  const [tempInitValue, setTempInitValue] = useState(undefined);
  const [tempIncrementValue, setTempIncrementValue] = useState(undefined);

  //   local Store
  const localAdminData = () => {
    let adminData = JSON.parse(localStorage.getItem("adminLoginInfo"));
    return adminData;
  };

  // Functions
  const handleEnVariant = (e, newValue) => {
    setEnVariant(newValue._id);
    setEnVariantSelected(true);
    setCountryLoading(true);
    // setMethodName(newValue.methodName);
    setDeliverySummery([...newValue.deliverySummery]);
    let arr = [...deliverySummeryErrorBuffer];
    newValue.deliverySummery.forEach((d, i) => {
      arr.push(i);
    });
    setDeliverySummeryErrorBuffer(arr);
    setTimeout(() => {
      setCountryArr([...newValue.countryArr]);
      setCountryWithPrice([...newValue.countryArr]);
      setCountryLoading(false);
    }, 100);
  };

  const handleEditDeliveryClose = () => {
    setCountryWithPrice([]);
    setCountryArr([]);
    setEditDeliveryDlg(false);
  };

  const handleDeliverySummery = (e, index) => {
    setEnVariantSelected(false);
    let arr0 = [...deliverySummeryErrorBuffer];
    arr0 = arr0.filter((a, i) => a !== index);
    setDeliverySummeryErrorBuffer(arr0);
    let arr = [...deliverySummery];
    arr[index] = e.target.value;
    setDeliverySummery(arr);
  };

  const handleAddDeliverySummery = () => {
    let arr = [...deliverySummery];
    arr.push("");
    setDeliverySummery(arr);
  };

  const handleRemoveDeliverySummery = (index) => {
    let arr = [...deliverySummery];
    arr = arr.filter((a, i) => i !== index);
    setDeliverySummery(arr);
  };

  const handleAllCountrySelect = () => {
    setCountryLoading(true);
    setCountryArr(countries);
    let arr = [...countries];
    countries.forEach((x, i) => {
      arr[i].initialPriceKG = tempInitKGValue;
      arr[i].incrementPriceKG = tempIncrementKGValue;
      arr[i].initialPrice = tempInitValue;
      arr[i].incrementPrice = tempIncrementValue;
    });
    setCountryWithPrice(arr);

    setTimeout(() => {
      setTempInitKGValue(undefined);
      setTempIncrementKGValue(undefined);
      setTempInitValue(undefined);
      setTempIncrementValue(undefined);
      setCountryLoading(false);
    }, 100);
  };

  const handleEditDelivery = () => {
    setLoadingEdit(true);
    let obj = {
      prevId: editBuffer._id,
      langId,
      methodName,
      deliverySummery,
      countryArr: countryWithPrice,
    };
    // Admin Data
    obj.creatorId = localAdminData()._id;
    if (enVariant !== "") obj.deliveryDetectorId = enVariant;
    if (localAdminData()._id) dispatch(updateDeliveryMethod(obj));
    setTimeout(() => {
      setLoadingEdit(false);
      dispatch(getAllDeliveryMethods());
      dispatch(getNotification());
      handleEditDeliveryClose();
    }, 1000);
  };

  // useEffect
  useEffect(() => {
    setCountryLoading(true);
    setTimeout(() => {
      setCountryLoading(false);
    }, 100);
  }, []);

  // Set data from edit buffer
  useEffect(() => {
    if (editBuffer.methodName) {
      setEnVariantLoading(true);
      setMethodName(editBuffer.methodName);
      setDeliverySummery(editBuffer.deliverySummery);
      setCountryArr(editBuffer.countryArr);
      if (editBuffer.countryArr)
        setCountryWithPrice([...editBuffer.countryArr]);
      if (editBuffer.deliveryDetectorId) {
        const D = deliveryListEn.find(
          (d) => d._id === editBuffer.deliveryDetectorId
        );
        if (D) {
          setDefaultEnVariant(D);
          setEnVariant(editBuffer.deliveryDetectorId);
        }
      }
      setTimeout(() => {
        setEnVariantLoading(false);
      }, 100);
    }
  }, [editBuffer]);

  // ! translate
  const [siteLang, setSiteLang] = useState("");
  // Local Store
  const localLangData = JSON.parse(localStorage.getItem("lang"));
  // Store
  const { langs, activeLang } = useSelector((state) => state.misc);
  const [langSettings1, setLangSettings1] = useState([]);
  const [langSettings2, setLangSettings2] = useState([]);
  const [langSettings3, setLangSettings3] = useState([]);
  const [langSettings4, setLangSettings4] = useState([]);
  const title1 = "Add Delivery Method Dialog";
  const title2 = "Misc Words";
  const title3 = "Manage Delivery Method";
  const title4 = "Edit Delivery Method";

  useEffect(() => {
    if (langs && langs.length > 0 && siteLang !== "") {
      const L = langs.find((l) => l.langCode === siteLang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      const A = arr.find((l) => l._title === title1);
      if (A) setLangSettings1(A.data);
      const B = arr.find((l) => l._title === title2);
      if (B) setLangSettings2(B.data);
      const C = arr.find((l) => l._title === title3);
      if (C) setLangSettings3(C.data);
      const D = arr.find((l) => l._title === title4);
      if (D) setLangSettings4(D.data);
    }
  }, [siteLang, langs]);

  const translate1 = (String) => translation(String, title1, 1);
  const translate2 = (String) => translation(String, title2, 2);
  const translate3 = (String) => translation(String, title3, 3);
  const translate4 = (String) => translation(String, title4, 4);

  const translation = (String, title, index) => {
    let translation = "";
    let settings = [];
    if (index === 1) {
      settings = langSettings1;
    } else if (index === 2) {
      settings = langSettings2;
    } else if (index === 3) {
      settings = langSettings3;
    } else if (index === 4) {
      settings = langSettings4;
    }
    const T = settings.find((ls) => ls.title === String);
    if (T && T.value) translation = T.value;
    else {
      let arr = [];
      if (langs && langs.length > 0 && siteLang !== "") {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.settings && LEn.settings.length > 0) arr = LEn.settings;
        const S = arr.find((l) => l._title === title);
        if (S) {
          const T = S.data.find((ls) => ls.title === String);
          if (T && T.value) translation = T.value;
        }
      }
    }
    return translation;
  };

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setSiteLang(activeLang.langCode);
    } else {
      if (localLangData) {
        setSiteLang(localLangData.langCode);
      }
    }
  }, [activeLang]);
  // ! translate

  return (
    <>
      <Dialog
        open={editDeliveryDlg}
        TransitionComponent={Transition}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle>{translate4("Edit Delivery Method")}</DialogTitle>
        <DialogContent>
          {!enVariantLoading && lang !== "en" && (
            <Autocomplete
              onChange={(e, newValue) => handleEnVariant(e, newValue)}
              disablePortal
              options={deliveryListEn}
              filterOptions={filterOptions}
              defaultValue={defaultEnVariant}
              getOptionLabel={(option) => option.methodName}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option.methodName}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="English Variant"
                  variant="standard"
                  size="small"
                  fullWidth
                />
              )}
            />
          )}
          <TextField
            required
            label={translate1("Delivery Method")}
            value={methodName}
            onChange={(e) => {
              setMethodName(e.target.value);
              setEnVariantSelected(false);
            }}
            variant="standard"
            fullWidth
            disabled={lang !== "en" && enVariant === ""}
            error={enVariantSelected}
          />

          <Button
            variant="outlined"
            size="small"
            fullWidth
            sx={{ mt: 1, pointerEvents: "none" }}
          >
            {translate1("Delivery Summery")}
          </Button>
          {deliverySummery.map((x, i) => (
            <Grid container spacing={0} alignItems="center" key={i}>
              <Grid item sx={{ flex: 1 }}>
                <TextField
                  label={`${i + 1}.${translate1("Delivery Summery")}`}
                  value={deliverySummery[i]}
                  onChange={(e) => handleDeliverySummery(e, i)}
                  variant="standard"
                  fullWidth
                  disabled={lang !== "en" && enVariant === ""}
                  error={deliverySummeryErrorBuffer.includes(i) ? true : false}
                />
              </Grid>

              <Grid item>
                <Grid container spacing={0}>
                  {deliverySummery.length > 1 && (
                    <Grid item>
                      <Tooltip
                        arrow
                        placement="left"
                        title={translate2("Delete")}
                        sx={{ mt: 2 }}
                      >
                        <IconButton
                          size="small"
                          onClick={() => handleRemoveDeliverySummery(i)}
                          disabled={lang !== "en" && enVariant === ""}
                        >
                          <DeleteIcon
                            color={
                              lang !== "en" && enVariant === ""
                                ? "inherit"
                                : "error"
                            }
                          />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
                  {deliverySummery.length === i + 1 && (
                    <Grid item>
                      <Tooltip
                        arrow
                        placement="right"
                        title={translate2("Add")}
                        sx={{ mt: 2 }}
                      >
                        <IconButton
                          size="small"
                          onClick={handleAddDeliverySummery}
                          disabled={lang !== "en" && enVariant === ""}
                        >
                          <AddCircleIcon
                            color={
                              lang !== "en" && enVariant === ""
                                ? "inherit"
                                : "success"
                            }
                          />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ))}
          <Button
            variant="outlined"
            size="small"
            fullWidth
            sx={{ mt: 1, pointerEvents: "none" }}
          >
            {translate1("Choose Countries")}
          </Button>
          <Grid
            container
            spacing={0}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item sx={{ flex: 1 }}>
              {countryLoading ? (
                <>
                  <LoadingBox w={"100%"} />
                </>
              ) : (
                <Autocomplete
                  id="country-select-demo"
                  multiple
                  options={countries}
                  onChange={(e, newValue) => {
                    setCountryArr(newValue);
                    // let arr = [...newValue];
                    // newValue.forEach((x, i) => {
                    //   if (!x.initialPrice) arr[i].initialPrice = 90;
                    //   if (!x.incrementPrice) arr[i].incrementPrice = 20;
                    // });
                    setCountryWithPrice(newValue);
                  }}
                  defaultValue={countryArr}
                  autoHighlight
                  getOptionLabel={(option) => option.label}
                  disabled={lang !== "en" && enVariant === ""}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      {option.label} ({option.code})
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label={translate1("Choose a country")}
                      variant="standard"
                      //   inputProps={{
                      //     ...params.inputProps,
                      //     autoComplete: "new-password", // disable autocomplete and autofill
                      //   }}
                    />
                  )}
                />
              )}
            </Grid>{" "}
            <Grid item>
              <Button
                variant="contained"
                size="small"
                onClick={() => setInitialValueDlg(true)}
                sx={{ mt: 1 }}
                disabled={countryWithPrice.length > 0}
              >
                {translate1("All")}
              </Button>
            </Grid>
          </Grid>
          {countryWithPrice.length > 0 && (
            <Box
              sx={{ height: { xs: "60vh", sm: "40vh" }, overflowY: "scroll" }}
            >
              {!countryLoading &&
                countryArr.map((x, i) => (
                  <Grid container spacing={1} key={i} alignItems="center">
                    <Grid item xs={12} sm={12} xl={12}>
                      <Button
                        variant="outlined"
                        color="secondary"
                        size="small"
                        fullWidth
                        sx={{ mt: 2, pointerEvents: "none" }}
                      >
                        {x.label}
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={6}>
                      <TextField
                        label={translate3("Price upto 100gm")}
                        value={
                          countryWithPrice[i] &&
                          countryWithPrice[i].initialPrice
                        }
                        onChange={(e) => {
                          let arr = [...countryWithPrice];
                          let obj = { ...arr[i] };
                          obj.initialPrice = e.target.value.replace(/\D/g, "");
                          arr[i] = obj;
                          setCountryWithPrice(arr);
                        }}
                        variant="standard"
                        fullWidth
                        size="small"
                        disabled={
                          countryWithPrice[i] &&
                          (countryWithPrice[i].initialPriceKG ||
                            countryWithPrice[i].incrementPriceKG)
                            ? true
                            : false
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} xl={6}>
                      {" "}
                      <TextField
                        label={translate3("Price increment /100gm")}
                        value={
                          countryWithPrice[i] &&
                          countryWithPrice[i].incrementPrice
                        }
                        onChange={(e) => {
                          let arr = [...countryWithPrice];
                          let obj = { ...arr[i] };
                          obj.incrementPrice = e.target.value.replace(
                            /\D/g,
                            ""
                          );
                          arr[i] = obj;
                          setCountryWithPrice(arr);
                        }}
                        variant="standard"
                        fullWidth
                        size="small"
                        disabled={
                          countryWithPrice[i] &&
                          (countryWithPrice[i].initialPriceKG ||
                            countryWithPrice[i].incrementPriceKG)
                            ? true
                            : false
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} xl={6}>
                      <TextField
                        label={"Price upto 1kg"}
                        value={
                          countryWithPrice[i] &&
                          countryWithPrice[i].initialPriceKG
                        }
                        onChange={(e) => {
                          let arr = [...countryWithPrice];
                          let obj = { ...arr[i] };
                          obj.initialPriceKG = e.target.value.replace(
                            /\D/g,
                            ""
                          );
                          arr[i] = obj;
                          setCountryWithPrice(arr);
                        }}
                        variant="standard"
                        fullWidth
                        size="small"
                        disabled={
                          countryWithPrice[i] &&
                          (countryWithPrice[i].initialPrice ||
                            countryWithPrice[i].incrementPrice)
                            ? true
                            : false
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} xl={6}>
                      {" "}
                      <TextField
                        label="Price increment /1kg"
                        value={
                          countryWithPrice[i] &&
                          countryWithPrice[i].incrementPriceKG
                        }
                        onChange={(e) => {
                          let arr = [...countryWithPrice];
                          let obj = { ...arr[i] };
                          obj.incrementPriceKG = e.target.value.replace(
                            /\D/g,
                            ""
                          );
                          arr[i] = obj;
                          setCountryWithPrice(arr);
                        }}
                        variant="standard"
                        fullWidth
                        size="small"
                        disabled={
                          countryWithPrice[i] &&
                          (countryWithPrice[i].initialPrice ||
                            countryWithPrice[i].incrementPrice)
                            ? true
                            : false
                        }
                      />
                    </Grid>
                  </Grid>
                ))}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditDeliveryClose} color="primary">
            {translate2("Close")}
          </Button>
          <LoadingButton
            onClick={handleEditDelivery}
            color="warning"
            loading={loadingEdit}
            disabled={(lang !== "en" && enVariant === "") || methodName === ""}
          >
            {translate2("Update")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {initialValueDlg && (
        <Dialog open={initialValueDlg}>
          <DialogTitle>Set initial Value for all countries</DialogTitle>
          <DialogContent>
            <TextField
              label="Price Upto 100gm"
              value={tempInitValue}
              onChange={(e) =>
                setTempInitValue(e.target.value.replace(/\D/g, ""))
              }
              variant="standard"
              fullWidth
              size="small"
              sx={{ mb: 1 }}
              disabled={tempInitKGValue || tempIncrementKGValue}
            />

            <TextField
              label="Per Increment/100gm"
              value={tempIncrementValue}
              onChange={(e) =>
                setTempIncrementValue(e.target.value.replace(/\D/g, ""))
              }
              variant="standard"
              fullWidth
              size="small"
              sx={{ mb: 1 }}
              disabled={tempInitKGValue || tempIncrementKGValue}
            />
            <TextField
              label="Price Upto 1kg"
              value={tempInitKGValue}
              onChange={(e) =>
                setTempInitKGValue(e.target.value.replace(/\D/g, ""))
              }
              variant="standard"
              fullWidth
              size="small"
              sx={{ mb: 1 }}
              disabled={tempInitValue || tempIncrementValue}
            />
            <TextField
              label="Per Increment/1kg"
              value={tempIncrementKGValue}
              onChange={(e) =>
                setTempIncrementKGValue(e.target.value.replace(/\D/g, ""))
              }
              variant="standard"
              fullWidth
              size="small"
              disabled={tempInitValue || tempIncrementValue}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setInitialValueDlg(false);
                setTempInitKGValue(undefined);
                setTempIncrementKGValue(undefined);
                setTempInitValue(undefined);
                setTempIncrementValue(undefined);
              }}
              color="error"
            >
              Close
            </Button>
            <Button
              onClick={() => {
                setInitialValueDlg(false);
                handleAllCountrySelect();
              }}
              color="primary"
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
