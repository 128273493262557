import { Box, Button, CardMedia, Grid } from "@mui/material";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { PF } from "../../config";
import ReactPlayer from "react-player";
import { useState } from "react";
import { useEffect } from "react";
import LoadingBox from "./Loading";

export default function AboutUsVideo({ contentSettings }) {
  const [loadingMain, setLoadingMain] = useState(false);
  const [focusVid, setFocusVid] = useState({});
  const [videos, setVideos] = useState([]);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));

  useEffect(() => {
    if (
      contentSettings &&
      contentSettings.aboutUsVideos &&
      contentSettings.aboutUsVideos.length > 0
    ) {
      const V = contentSettings.aboutUsVideos.find((v) => v.default);
      if (V) {
        setFocusVid(V);
      }
      setVideos(contentSettings.aboutUsVideos);
    }
  }, [contentSettings]);

  useEffect(() => {
    setLoadingMain(true);
    setTimeout(() => {
      setLoadingMain(false);
    }, 1000);
  }, []);
  return (
    <>
      {loadingMain ? (
        <Box sx={{ overflow: "hidden" }}>
          <LoadingBox
            w={!smUp ? "60vw" : smUp && !mdUp ? 620 : mdUp && !xlUp ? 500 : 620}
            h={!smUp ? "350" : 500}
          />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              height: mdUp && 500,
              display: "flex",
              alignItems: "center",
              mb: 2,
              maxWidth: "98vw",
            }}
          >
            {focusVid.src && focusVid.src !== "" && focusVid.externalLink ? (
              <ReactPlayer
                url={focusVid.src}
                controls
                width={
                  !smUp
                    ? "90vw"
                    : smUp && !mdUp
                    ? 620
                    : mdUp && !xlUp
                    ? 500
                    : 620
                }
              />
            ) : (
              <CardMedia
                component="video"
                height={!smUp ? "100%" : 350}
                image={PF + "misc/" + focusVid.src}
                sx={{ mx: 2 }}
                controls
              />
            )}
          </Box>
          <Grid container spacing={1} justifyContent="center">
            {videos.length > 1 &&
              videos.map((x, i) => (
                <Grid item>
                  <Button
                    size="small"
                    variant={
                      x.src && x.src === focusVid.src ? "contained" : "outlined"
                    }
                    onClick={() => setFocusVid(x)}
                  >
                    {i + 1}
                  </Button>
                </Grid>
              ))}
          </Grid>
        </>
      )}
    </>
  );
}
