import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

export default function AddSocialDialog({
  additionalSocialMedia,
  setAdditionalSocialMedia,
  addSocialDlg,
  setAddSocialDlg,
  additionalSocialMediaChanged,
  setAdditionalSocialMediaChanged,
}) {
  // Use State
  const [newSocialName, setNewSocialName] = React.useState("");
  const [newSocialLink, setNewSocialLink] = React.useState("");

  // Functions
  const addSocialConfirm = () => {
    setAdditionalSocialMediaChanged &&
      setAdditionalSocialMediaChanged(additionalSocialMediaChanged + 1);
    let arr = [...additionalSocialMedia];
    const obj = {
      socialName: newSocialName,
      socialLink: newSocialLink,
    };
    arr.push(obj);
    setAdditionalSocialMedia(arr);
    closeAddSocial();
  };

  const closeAddSocial = () => {
    setAddSocialDlg(false);
    setNewSocialName("");
    setNewSocialLink("");
    setAddSocialDlg(false);
  };
  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate
  return (
    <Dialog
      open={addSocialDlg}
      onClose={closeAddSocial}
      aria-labelledby={"social-media-add-dialog"}
    >
      <DialogTitle>{translateFront("Add Social Media")}</DialogTitle>
      <DialogContent>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <TextField
              required
              label={translateFront("Platform Name")}
              value={newSocialName}
              onChange={(e) => setNewSocialName(e.target.value)}
              variant="standard"
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              label={translateFront("Link")}
              value={newSocialLink}
              onChange={(e) => setNewSocialLink(e.target.value)}
              variant="standard"
              fullWidth
              size="small"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeAddSocial} color="error">
          {translateFront("Cancel")}
        </Button>
        <Button
          color="success"
          onClick={addSocialConfirm}
          disabled={newSocialLink === "" || newSocialName === "" ? true : false}
        >
          {translateFront("Confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
