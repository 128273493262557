import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Tooltip,
  Card,
  CardContent,
  FormControlLabel,
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingBox from "../../../components/misc/Loading";
import {
  addSubscriber,
  deleteSubscriber,
  editSubscriber,
  getAllCustomers,
  getCampaigns,
  getSubscriber,
  subscriberBulkEmail,
} from "../../../reduxToolkit/features/auth";

// Icons
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import InfoIcon from "@mui/icons-material/Info";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import UploadIcon from "@mui/icons-material/Upload";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import ExpandMore from "@mui/icons-material/ExpandMore";

import LoadingButton from "@mui/lab/LoadingButton";
import { getCreated, getExactTime } from "../../../hooks/getCreatedData";

// Media Query
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getNotification } from "../../../reduxToolkit/features/adminPanel/notification";
import SubscriptionEmail from "./SubscriptionEmail";
import CampaignManagement from "./CampaignManagement";
import TemplateCard from "./TemplateCard";
import SendBulkEmailDialog from "../dialogs/SendBulkEmailDialog";

export default function ManageSubscriptions() {
  const dispatch = useDispatch();

  // Media Query
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  const [loading, setLoading] = useState(false);
  const [subs, setSubs] = useState([]);
  const [selectedSubs, setSelectedSubs] = useState([]);
  const [templates, setTemplates] = useState([]);

  // Dialog
  const [loadingDlg, setLoadingDlg] = useState(false);

  const [addSubDlg, setAddSubDlg] = useState(false);
  const [newSub, setNewSub] = useState({
    name: "",
    email: "",
    subscriber: true,
  });

  const [editSubDlg, setEditSubDlg] = useState(false);
  const [editSub, setEditSub] = useState({
    name: "",
    email: "",
    subscriber: true,
    isCustomer: "",
    id: "",
  });

  const [dltSubDlg, setDltSubDlg] = useState(false);
  const [dltSubBuffer, setDltSubBuffer] = useState({});

  const [importSubDlg, setImportSubDlg] = useState(false);
  const [campaignStartDlg, setCampaignStartDlg] = useState(false);

  // Local Data
  const localAdminInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));

  //   Store
  const { customers, subscribers, campaigns } = useSelector(
    (state) => state.auth
  );

  //   Functions

  const handleAddSub = () => {
    setLoadingDlg(true);
    let obj = { ...newSub };
    if (localAdminInfo && localAdminInfo._id)
      obj.creatorId = localAdminInfo._id;
    dispatch(addSubscriber(obj));
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      dispatch(getAllCustomers());
      dispatch(getSubscriber());
      dispatch(getNotification());
      handleCloseAddSub();
    }, 1000);
  };

  const handleCloseAddSub = () => {
    setAddSubDlg(false);
    setLoadingDlg(false);
    setNewSub({
      name: "",
      email: "",
      subscriber: true,
    });
  };

  const handleEditClick = (x) => {
    setEditSubDlg(true);
    setEditSub({
      name: x.name && x.name !== "" ? x.name : "",
      email: x.email,
      subscriber: true,
      isCustomer: x.isCustomer,
      id: x.id,
    });
  };

  const handleEditSub = () => {
    setLoadingDlg(true);
    let obj = { ...editSub };
    if (localAdminInfo && localAdminInfo._id)
      obj.creatorId = localAdminInfo._id;
    dispatch(editSubscriber(obj));
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      dispatch(getAllCustomers());
      dispatch(getSubscriber());
      dispatch(getNotification());
      handleCloseEditSub();
    }, 1000);
  };

  const handleCloseEditSub = () => {
    setEditSubDlg(false);
    setLoadingDlg(false);
    setEditSub({
      name: "",
      email: "",
      subscriber: true,
      isCustomer: "",
      id: "",
    });
  };

  const handleDltSub = () => {
    setLoadingDlg(true);
    let obj = { ...dltSubBuffer };
    if (localAdminInfo && localAdminInfo._id)
      obj.creatorId = localAdminInfo._id;
    dispatch(deleteSubscriber(obj));
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setLoadingDlg(false);
      dispatch(getAllCustomers());
      dispatch(getSubscriber());
      dispatch(getNotification());
      setDltSubDlg(false);
      setDltSubBuffer({});
    }, 1000);
  };

  const handleAllSubSelect = () => {
    let arr = [];
    if (selectedSubs.length !== subs.length)
      subs.forEach((s) => arr.push(s.id));
    else arr = [];
    setSelectedSubs(arr);
  };

  const handleSelectedSub = (id) => {
    let arr = [...selectedSubs];
    if (arr.includes(id)) arr = arr.filter((a) => a !== id);
    else arr.push(id);
    setSelectedSubs(arr);
  };

  useEffect(() => {
    setLoading(true);
    if (!(customers && customers.length > 0)) dispatch(getAllCustomers());
    if (!(subscribers && subscribers.length > 0)) dispatch(getSubscriber());
    if (!(campaigns && campaigns.length > 0)) dispatch(getCampaigns());
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (
      customers &&
      customers.length > 0 &&
      subscribers &&
      subscribers.length > 0
    ) {
      let arr = [];
      customers.forEach((c) => {
        if (c.subscribed) {
          arr.push({
            email: c.email,
            name: c.name,
            created: c.createdAt,
            id: c._id,
            isCustomer: true,
          });
        }
      });
      subscribers.forEach((s) =>
        arr.push({
          email: s.email,
          name: s.name ? s.name : "",
          created: s.createdAt,
          id: s._id,
          isCustomer: false,
        })
      );
      setSubs(arr);
    }
  }, [customers, subscribers]);

  // Fixed date
  const [showDate, setShowDate] = useState([]);

  const handleShowDate = (date) => {
    let arr = [...showDate];
    if (arr.includes(date)) {
      arr = arr.filter((a) => a !== date);
    } else {
      arr.push(date);
    }
    setShowDate(arr);
  };

  // ! translate
  const [lang, setLang] = useState("");
  const [langId, setLangId] = useState("");
  // Local Store
  const localLangData = JSON.parse(localStorage.getItem("lang"));
  // Store
  const { langs, activeLang } = useSelector((state) => state.misc);
  const [langSettings1, setLangSettings1] = useState([]);
  const [langSettings2, setLangSettings2] = useState([]);
  const title1 = "Manage Subscription";
  const title2 = "Misc Words";

  useEffect(() => {
    if (langs && langs.length > 0 && lang !== "") {
      const L = langs.find((l) => l.langCode === lang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      const A = arr.find((l) => l._title === title1);
      if (A) setLangSettings1(A.data);
      const B = arr.find((l) => l._title === title2);
      if (B) setLangSettings2(B.data);
    }
  }, [lang, langs]);

  const translate1 = (String) => translation(String, title1, 1);
  const translate2 = (String) => translation(String, title2, 2);

  const translation = (String, title, index) => {
    let translation = "";
    let settings = [];
    if (index === 1) {
      settings = langSettings1;
    } else if (index === 2) {
      settings = langSettings2;
    }
    const T = settings.find((ls) => ls.title === String);
    if (T && T.value) translation = T.value;
    else {
      let arr = [];
      if (langs && langs.length > 0 && lang !== "") {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.settings && LEn.settings.length > 0) arr = LEn.settings;
        const S = arr.find((l) => l._title === title);
        if (S) {
          const T = S.data.find((ls) => ls.title === String);
          if (T && T.value) translation = T.value;
        }
      }
    }
    return translation;
  };

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setLang(activeLang.langCode);
      setLangId(activeLang._id);
    } else {
      if (localLangData) {
        setLang(localLangData.langCode);
        setLangId(localLangData._id);
      }
    }
  }, [activeLang]);
  // ! translate

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-label="Email-Templates-Expand"
          aria-controls="email-template-content"
          id="email-template-header"
        >
          <Typography>{translate1("Email Templates")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SubscriptionEmail
            templates={templates}
            setTemplates={setTemplates}
            translate1={translate1}
            translate2={translate2}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-label="CampaignExpand"
          aria-controls="campaign-content"
          id="campaign-header"
        >
          <Typography>{translate1("Run Campaign")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Button
            size="small"
            variant="outlined"
            fullWidth
            sx={{ my: 1, pointerEvents: "none" }}
          >
            {translate1("Subscribers")} ({subs.length})
          </Button>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={3}>
              <Button
                variant="contained"
                size="small"
                fullWidth
                color="error"
                // sx={{ mb: 1 }}
                onClick={() => setAddSubDlg(true)}
                startIcon={<AddCircleOutlineIcon />}
              >
                {translate1("Add Subscriber")}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Button
                variant="contained"
                size="small"
                fullWidth
                color="warning"
                // sx={{ mb: 1 }}
                startIcon={<UploadIcon />}
                onClick={() => setImportSubDlg(true)}
              >
                {translate1("Import ")}
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <LoadingButton
                loading={loading}
                size="small"
                variant="contained"
                fullWidth
                sx={{ mb: 1 }}
                startIcon={<MarkEmailReadIcon />}
                disabled={selectedSubs.length < 1 ? true : false}
                onClick={() => setCampaignStartDlg(true)}
              >
                {translate1("Send Email")}
              </LoadingButton>
            </Grid>
          </Grid>
          {mdUp ? (
            <>
              <Grid container spacing={0} alignItems="center">
                <Grid item xs={1}>
                  <Checkbox
                    checked={selectedSubs.length === subs.length ? true : false}
                    onChange={handleAllSubSelect}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="subtitle1">S.L.</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="subtitle1">
                    {translate2("Customer")}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="subtitle1">
                    {translate1("Name")}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">
                    {translate1("Email")}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="subtitle1">
                    {translate2("Created")}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="subtitle1">
                    {translate2("Action")}
                  </Typography>
                </Grid>
              </Grid>
              <Divider sx={{ my: 1, border: ".1rem solid navy" }} />
              <Box
                sx={{
                  height: "40vh",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                {loading ? (
                  <LoadingBox w={"100%"} />
                ) : (
                  subs.length > 0 &&
                  subs.map((x, i) => (
                    <Box sx={{}}>
                      <Grid container spacing={0} key={i} alignItems="center">
                        <Grid item xs={2} md={1}>
                          <Checkbox
                            checked={selectedSubs.includes(x.id) ? true : false}
                            onChange={() => handleSelectedSub(x.id)}
                          />
                        </Grid>
                        <Grid item xs={2} md={1}>
                          <Typography variant="text.secondary">
                            {i + 1}.
                          </Typography>
                        </Grid>
                        <Grid item xs={2} md={1}>
                          <Typography variant="text.secondary">
                            <IconButton
                              size="small"
                              sx={{ pointerEvents: "none" }}
                            >
                              {x.isCustomer ? (
                                <CheckCircleIcon color="success" />
                              ) : (
                                <CancelIcon color="error" />
                              )}
                            </IconButton>
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                          <Typography variant="text.secondary">
                            {x.name && x.name !== "" ? x.name : "N/A"}
                          </Typography>
                        </Grid>
                        <Grid item md={3}>
                          <Typography variant="text.secondary">
                            {x.email}
                          </Typography>
                        </Grid>
                        <Grid item md={2}>
                          <Typography variant="text.secondary">
                            {getCreated(x.created)}
                            <Tooltip
                              arrow
                              placement="top"
                              title={`Created: ${getExactTime(x.created)}`}
                            >
                              <IconButton
                                size="small"
                                onClick={() => handleShowDate(x.created)}
                              >
                                <InfoIcon color="info" />
                              </IconButton>
                            </Tooltip>
                            <br />
                            {showDate.includes(x.created) &&
                              getExactTime(x.created)}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Grid container spacing={0}>
                            <Grid item>
                              <Tooltip
                                arrow
                                placement="top"
                                title={translate1("Edit")}
                              >
                                <IconButton
                                  size="small"
                                  disabled={x.isCustomer}
                                  onClick={() => handleEditClick(x)}
                                >
                                  <EditIcon
                                    color={x.isCustomer ? "inherit" : "primary"}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                            <Grid item>
                              <Tooltip
                                arrow
                                placement="top"
                                title={translate1("Delete")}
                              >
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    setDltSubDlg(true);
                                    setDltSubBuffer(x);
                                  }}
                                >
                                  <DeleteIcon color="error" />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Divider sx={{ my: 1 }} />
                    </Box>
                  ))
                )}
              </Box>
            </>
          ) : (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedSubs.length === subs.length ? true : false}
                    onChange={handleAllSubSelect}
                  />
                }
                label={translate2("Select All")}
              />
              <Box
                sx={{
                  height: "60vh",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                {loading ? (
                  <LoadingBox w={"100%"} />
                ) : (
                  subs.length > 0 &&
                  subs.map((x, i) => (
                    <Card sx={{ my: 1 }}>
                      <CardContent>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                selectedSubs.includes(x.id) ? true : false
                              }
                              onChange={() => handleSelectedSub(x.id)}
                            />
                          }
                          label={translate2("Selected")}
                        />

                        <Typography variant="text.secondary" component="div">
                          {translate2("Serial")}. {i + 1}
                        </Typography>
                        <Typography variant="text.secondary" component="div">
                          {translate2("Customer")}:{" "}
                          <IconButton
                            size="small"
                            sx={{ pointerEvents: "none" }}
                          >
                            {x.isCustomer ? (
                              <CheckCircleIcon color="success" />
                            ) : (
                              <CancelIcon color="error" />
                            )}
                          </IconButton>
                        </Typography>
                        <Typography variant="text.secondary" component="div">
                          {translate1("Name")}:{" "}
                          {x.name && x.name !== "" ? x.name : "N/A"}
                        </Typography>
                        <Typography
                          variant="text.secondary"
                          component="div"
                          color="red"
                        >
                          {x.email}
                        </Typography>
                        <Typography variant="text.secondary" component="div">
                          {getCreated(x.created)}
                          <Tooltip
                            arrow
                            placement="top"
                            title={`Created: ${getExactTime(x.created)}`}
                          >
                            <IconButton
                              size="small"
                              onClick={() => handleShowDate(x.created)}
                            >
                              <InfoIcon color="info" />
                            </IconButton>
                          </Tooltip>
                          <br />
                          {showDate.includes(x.created) &&
                            getExactTime(x.created)}
                        </Typography>
                        <Divider />
                        <Grid container spacing={0} justifyContent="flex-end">
                          <Grid item>
                            <Tooltip
                              arrow
                              placement="top"
                              title={translate1("Edit")}
                            >
                              <IconButton
                                size="small"
                                disabled={x.isCustomer}
                                onClick={() => handleEditClick(x)}
                              >
                                <EditIcon
                                  color={x.isCustomer ? "inherit" : "primary"}
                                />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item>
                            <Tooltip
                              arrow
                              placement="top"
                              title={translate1("Delete")}
                            >
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setDltSubDlg(true);
                                  setDltSubBuffer(x);
                                }}
                              >
                                <DeleteIcon color="error" />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  ))
                )}
              </Box>
            </>
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-label="Campaign-Management-Expand"
          aria-controls="campaign-management-content"
          id="campaign-management-header"
        >
          <Typography>{translate1("Campaign Management")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CampaignManagement
            campaigns={campaigns}
            templates={templates}
            translate1={translate1}
            translate2={translate2}
          />
        </AccordionDetails>
      </Accordion>

      {/* Add Subscriber Dialog */}
      {addSubDlg && (
        <Dialog open={addSubDlg} fullWidth maxWidth={"sm"}>
          <DialogTitle>{translate1("Add Subscriber")}</DialogTitle>
          <DialogContent>
            <TextField
              label={translate1("Name")}
              value={newSub.name}
              onChange={(e) => {
                let obj = { ...newSub };
                obj.name = e.target.value;
                setNewSub(obj);
              }}
              size="small"
              variant="standard"
              fullWidth
            />
            <TextField
              label={translate1("Email")}
              value={newSub.email}
              onChange={(e) => {
                let obj = { ...newSub };
                obj.email = e.target.value;
                setNewSub(obj);
              }}
              size="small"
              variant="standard"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAddSub} color="error">
              {translate1("Close")}
            </Button>
            <LoadingButton
              loading={loadingDlg}
              onClick={handleAddSub}
              color="success"
              disabled={newSub.email === "" ? true : false}
            >
              {translate2("Add")}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}

      {/* Edit Subscriber Dialog */}
      {editSubDlg && (
        <Dialog open={editSubDlg} fullWidth maxWidth={"sm"}>
          <DialogTitle>
            {translate1("Edit")}
            {translate1("Subscribers")}
          </DialogTitle>
          <DialogContent>
            <TextField
              label={translate1("Name")}
              value={editSub.name}
              onChange={(e) => {
                let obj = { ...editSub };
                obj.name = e.target.value;
                setEditSub(obj);
              }}
              sx={{ mb: 1 }}
              size="small"
              variant="standard"
              fullWidth
            />
            <TextField
              label={translate1("Email")}
              value={editSub.email}
              onChange={(e) => {
                let obj = { ...editSub };
                obj.email = e.target.value;
                setEditSub(obj);
              }}
              size="small"
              variant="standard"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEditSub} color="error">
              {translate1("Close")}
            </Button>
            <LoadingButton
              loading={loadingDlg}
              onClick={handleEditSub}
              color="warning"
              disabled={editSub.email === "" ? true : false}
            >
              {translate1("Update")}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}

      {/* Delete Dialog */}
      {dltSubDlg && (
        <Dialog open={dltSubDlg}>
          <DialogContent>
            <DialogContentText>
              {translate1("Do you really want to delete")}{" "}
              <span style={{ color: "red" }}> {dltSubBuffer.email}</span>?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setDltSubDlg(false);
                setDltSubBuffer({});
              }}
              color="primary"
            >
              {translate1("Cancel")}
            </Button>
            <LoadingButton
              loading={loadingDlg}
              onClick={handleDltSub}
              color="error"
            >
              {translate1("Confirm")}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}

      {/* Import Sub Dialog */}
      {importSubDlg && (
        <Dialog open={importSubDlg} onClose={() => setImportSubDlg(false)}>
          <DialogContent>
            <Button
              onClick={() => {
                alert("Work In Progress");
              }}
              color="warning"
              variant="outlined"
              fullWidth
              sx={{ mb: 1 }}
            >
              {translate1("Import ")} .xlsx ({translate1("Excel File")})
            </Button>
            <Button
              onClick={() => {
                alert("Work In Progress");
              }}
              color="warning"
              variant="outlined"
              fullWidth
            >
              {translate1("Import ")} .csv ({translate1("CSV File")})
            </Button>
          </DialogContent>
        </Dialog>
      )}

      {/* Campaign Start Dialog */}
      {campaignStartDlg && (
        <SendBulkEmailDialog
          openDlg={campaignStartDlg}
          setOpenDlg={setCampaignStartDlg}
          templates={templates}
          subs={subs}
          selectedSubs={selectedSubs}
          rerunCampaign={false}
          translate1={translate1}
          translate2={translate2}
        />
      )}
    </>
  );
}
