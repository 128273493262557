import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
// Icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ManageOrders from "./ManageOrders";

// Media Query
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function OrderScreen() {
  // Media Query
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [expanded, setExpanded] = React.useState(false);

  // Store
  const { panel } = useSelector((state) => state.notification);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // handle Panel Based on Notification
  useEffect(() => {
    setExpanded(panel);
  }, [panel]);

  // ! translate
  const [siteLang, setSiteLang] = useState("");
  // Local Store
  const localLangData = JSON.parse(localStorage.getItem("lang"));
  // Store
  const { langs, activeLang } = useSelector((state) => state.misc);
  const [langSettings1, setLangSettings1] = useState([]);
  const [langSettings2, setLangSettings2] = useState([]);
  const title1 = "Manage Orders";
  const title2 = "Misc Words";

  useEffect(() => {
    if (langs && langs.length > 0 && siteLang !== "") {
      const L = langs.find((l) => l.langCode === siteLang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      const A = arr.find((l) => l._title === title1);
      if (A) setLangSettings1(A.data);
      const B = arr.find((l) => l._title === title2);
      if (B) setLangSettings2(B.data);
    }
  }, [siteLang, langs]);

  const translate1 = (String) => translation(String, title1, 1);
  const translate2 = (String) => translation(String, title2, 2);

  const translation = (String, title, index) => {
    let translation = "";
    let settings = [];
    if (index === 1) {
      settings = langSettings1;
    } else if (index === 2) {
      settings = langSettings2;
    }
    const T = settings.find((ls) => ls.title === String);
    if (T && T.value) translation = T.value;
    else {
      let arr = [];
      if (langs && langs.length > 0 && siteLang !== "") {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.settings && LEn.settings.length > 0) arr = LEn.settings;
        const S = arr.find((l) => l._title === title);
        if (S) {
          const T = S.data.find((ls) => ls.title === String);
          if (T && T.value) translation = T.value;
        }
      }
    }
    return translation;
  };

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setSiteLang(activeLang.langCode);
    } else {
      if (localLangData) {
        setSiteLang(localLangData.langCode);
      }
    }
  }, [activeLang]);
  // ! translate

  return (
    <Box
      sx={{
        maxWidth: !smUp ? "75vw" : !mdUp ? "85vw" : undefined,
      }}
    >
      <Typography
        variant="h4"
        color="initial"
        align="center"
        sx={{ borderBottom: ".2rem solid black", mb: 2 }}
      >
        {translate1("Manage Orders")}
      </Typography>
      {orderMenu.map((x, i) => (
        <Accordion
          key={i}
          expanded={expanded === orderMenu[i].panelName}
          onChange={handleChange(orderMenu[i].panelName)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={orderMenu[i].panelName}
            id={orderMenu[i].panelName}
          >
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <Typography>{translate1(orderMenu[i].headPrimary)}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={{ color: "text.secondary" }}>
                  {orderMenu[i].headSecondary}
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          {expanded === orderMenu[i].panelName && (
            <AccordionDetails>{orderMenu[i].component}</AccordionDetails>
          )}
        </Accordion>
      ))}
    </Box>
  );
}

const orderMenu = [
  {
    panelName: "manage-orders",
    headPrimary: "Manage Orders",
    headSecondary: "",
    component: <ManageOrders />,
  },
];
