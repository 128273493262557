import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../config";

// get Role
export const getRole = createAsyncThunk("role/get", async () => {
  const res = await axiosInstance.get("/api/role/");
  return res.data;
});

// Create Role
export const createRole = createAsyncThunk("role/create", async (data) => {
  const { lang, ...others } = data;
  const res = await axiosInstance.post(`/api/role/${lang}/create`, others);
  return res.data;
});

// Update Role
export const updateRole = createAsyncThunk("/role/update", async (data) => {
  const { lang, roleId, ...others } = data;
  const res = await axiosInstance.put(
    `/api/role/update/${lang}/${roleId}`,
    others
  );
  return res.data;
});

// Delete Role
export const deleteRole = createAsyncThunk("/role/delete", async (data) => {
  const { _id, ...others } = data;
  const res = await axiosInstance.put(`/api/role/delete/${_id}`, others);
  return res.data;
});

// Delete All Role
export const deleteAllRole = createAsyncThunk(
  "/role/deleteAll",
  async (data) => {
    const res = await axiosInstance.put(`/api/role/delete-all/`, data);
    return res.data;
  }
);

// get single Role
export const getSingleRole = createAsyncThunk("/role/single", async (id) => {
  const res = await axiosInstance.get(`/api/role/single-role/${id}`);
  return res.data;
});

// initial State
const initialState = {
  pending: false,
  roles: [],
  createRoleResponse: {},
  updateRoleResponse: {},
  singleRoleResponse: {},
  deleteRoleResponse: {},
  deleteAllRoleResponse: {},
  error: false,
  snackNotiRole: {},
};

// Slice
export const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    resetSnkRole(state) {
      state.snackNotiRole = {};
    },
  },
  extraReducers: {
    [getRole.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getRole.fulfilled]: (state, action) => {
      state.pending = false;
      state.roles = action.payload;
    },
    [getRole.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [getSingleRole.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getSingleRole.fulfilled]: (state, action) => {
      state.pending = false;
      state.singleRoleResponse = action.payload;
    },
    [getSingleRole.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [createRole.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [createRole.fulfilled]: (state, action) => {
      state.pending = false;
      state.createRoleResponse = action.payload;
      state.snackNotiRole = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [createRole.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [updateRole.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [updateRole.fulfilled]: (state, action) => {
      state.pending = false;
      state.updateRoleResponse = action.payload;
      state.snackNotiRole = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [updateRole.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [deleteRole.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [deleteRole.fulfilled]: (state, action) => {
      state.pending = false;
      state.deleteRoleResponse = action.payload;
      state.snackNotiRole = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [deleteRole.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [deleteAllRole.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [deleteAllRole.fulfilled]: (state, action) => {
      state.pending = false;
      state.deleteAllRoleResponse = action.payload;
      state.snackNotiRole = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [deleteAllRole.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
  },
});

export const { resetSnkRole } = roleSlice.actions;
export default roleSlice.reducer;
