import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// Icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AuthenticationSettings from "./component/AuthenticationSettings";
import SiteDesignSettings from "./component/SiteDesignSettings";
import ManageSiteContent from "./component/ManageSiteContent";
import { getSettings } from "../../reduxToolkit/features/adminPanel/webSettings";
import EmailSettings from "./component/EmailSettings";
import ManageSocialMedia from "./component/ManageSocialMedia";
import FooterContactInfo from "./component/FooterContactInfo";
import ManageChatBot from "./component/ManageChatBot";
import MaintenanceMode from "./component/MaintenanceMode";
import CurrencySettings from "./component/CurrencySettings";

export default function WebSettingsScreen() {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState(false);

  // Store
  const { panel } = useSelector((state) => state.notification);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // handle Panel Based on Notification
  useEffect(() => {
    setExpanded(panel);
  }, [panel]);
  useEffect(() => {
    dispatch(getSettings());
  }, []);

  //! Translate
  // Store
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate

  return (
    <Fragment>
      <Typography
        variant="h4"
        color="initial"
        align="center"
        sx={{ borderBottom: ".2rem solid black", mb: 2 }}
      >
        {translate0("Web Settings")}
      </Typography>
      {supplierAcMenu.map((x, i) => (
        <Accordion
          key={i}
          expanded={expanded === supplierAcMenu[i].panelName}
          onChange={handleChange(supplierAcMenu[i].panelName)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={supplierAcMenu[i].panelName}
            id={supplierAcMenu[i].panelName}
          >
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <Typography>
                  {translate0(supplierAcMenu[i].headPrimary)}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={{ color: "text.secondary" }}>
                  {translate0(supplierAcMenu[i].headSecondary)}
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          {expanded === supplierAcMenu[i].panelName && (
            <AccordionDetails>{supplierAcMenu[i].component}</AccordionDetails>
          )}
        </Accordion>
      ))}
    </Fragment>
  );
}

const supplierAcMenu = [
  {
    panelName: "auth-settings",
    headPrimary: "Auth Settings",
    headSecondary: "Manage Auth Process",
    component: <AuthenticationSettings />,
  },
  {
    panelName: "currency-settings",
    headPrimary: "Currency Settings",
    headSecondary: "Manage Currency",
    component: <CurrencySettings />,
  },
  {
    panelName: "email-settings",
    headPrimary: "Email Settings",
    headSecondary: "Manage Email Templates & More",
    component: <EmailSettings />,
  },
  {
    panelName: "site-design",
    headPrimary: "Site Design",
    headSecondary: "Change Site Looks",
    component: <SiteDesignSettings />,
  },
  {
    panelName: "manage-content",
    headPrimary: "Manage Content",
    headSecondary: "Modify Content",
    component: <ManageSiteContent />,
  },
  {
    panelName: "social-media",
    headPrimary: "Social Media",
    headSecondary: "Modify Social Media",
    component: <ManageSocialMedia />,
  },
  {
    panelName: "footer",
    headPrimary: "Footer",
    headSecondary: "Manage Footer",
    component: <FooterContactInfo />,
  },
  {
    panelName: "chat-bot",
    headPrimary: "Chat Bot",
    headSecondary: "Manage Chat Agents",
    component: <ManageChatBot />,
  },
  {
    panelName: "maintenance-mode",
    headPrimary: "Maintenance Mode",
    headSecondary: "",
    component: <MaintenanceMode />,
  },
];
