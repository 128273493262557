import {
  Autocomplete,
  Avatar,
  Badge,
  Box,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  Tooltip,
  Grid,
  Backdrop,
  CircularProgress,
  createFilterOptions,
} from "@mui/material";
import React, { useEffect, useState } from "react";

// Icons
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CompareIcon from "@mui/icons-material/Compare";

import { PF } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CompareDlg from "../customerInteraction/CompareDlg";
import CheckoutMain from "../checkout/CheckoutMain";
import OrderHistoryDialog from "../OrderHistoryDialog";
import { getCompareList } from "../../reduxToolkit/features/frontend/compare";
import { getWishlist } from "../../reduxToolkit/features/frontend/wishlist";
import {
  getNotificationByCustomer,
  setIdToScroll,
} from "../../reduxToolkit/features/miscFront";
import { getAskPriceListByCustomer } from "../../reduxToolkit/features/frontend/checkout";
import WishListDrawer from "./appBarDrawers/WishListDrawer";
import CartDrawer from "./appBarDrawers/CartDrawer";
import ProfileDrawer from "./appBarDrawers/ProfileDrawer";
import { getMedicines } from "../../reduxToolkit/features/adminPanel/medicine";
import { makeUrlFriendly } from "../../hooks/makeUrlFriendly";

export default function MenuSection() {
  const [visible, setVisible] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [profileOpen, setProfileOpen] = useState(false);
  const [wishOpen, setWishOpen] = useState(false);
  const [compareOpen, setCompareOpen] = useState(false);
  const [cartOpen, setCartOpen] = useState(false);
  const [checkoutDlg, setCheckoutDlg] = useState(false);

  const [readNotiCount, setReadNotiCount] = useState(0);

  const [medLoading, setMedLoading] = useState(false);
  const [medList, setMedList] = useState([]);
  const [medListEn, setMedListEn] = useState([]);

  const [lang, setLang] = useState("en");

  const [isHome, setIsHome] = useState(false);

  // Dialogs
  const [menuDlg, setMenuDlg] = useState(false);
  const [activeMenu, setActiveMenu] = useState("home");

  // Local Store
  const localData = () => JSON.parse(localStorage.getItem("loginInfo"));
  const localLangData = JSON.parse(localStorage.getItem("langFront"));

  // Store

  const { customerNotifications, orderHistoryDlg } = useSelector(
    (state) => state.miscFront
  );
  const { compareList } = useSelector((state) => state.compare);
  const { wishlist } = useSelector((state) => state.wishlist);
  const { carts } = useSelector((state) => state.cart);
  const { webSettings } = useSelector((state) => state.webSettings);
  const { medicines } = useSelector((state) => state.medicine);
  const { activeLangFront } = useSelector((state) => state.miscFront);

  // Limit options to show in autocomplete
  const OPTIONS_LIMIT = 12;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };

  //   Function
  const handleMenu = async (menuProp) => {
    await navigate(menuProp === "home" ? "/" : `/${menuProp}`);
    setActiveMenu(menuProp);
    setMenuDlg(false);
    setTimeout(() => {
      scroll();
    }, 250);
  };

  const getGenericBrand = (id) => {
    let genericBrand = "";
    const M = medicines.find((m) => m.lang === "en");
    if (M) {
      const med = M.data.find((x) => x.fixedMedicineId === id);
      if (med) genericBrand = med.genericBrand;
    }
    return genericBrand;
  };

  const handleSearchClick = (e, newValue) => {
    setMedLoading(true);
    navigate(
      `/our-medicines/${makeUrlFriendly(
        getGenericBrand(
          lang !== "en" ? newValue.medicineDetectorId : newValue.fixedMedicineId
        )
      )}`
    );
    setTimeout(() => {
      setMedLoading(false);
    }, 100);
  };

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 100) {
      setVisible(true);
    } else if (scrolled <= 100) {
      setVisible(false);
    }
  };

  const scroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // BackDrop
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  // const handleToggle = () => {
  //   setOpen(true);
  //   setTimeout(() => {
  //     setOpen(false);
  //   }, 1000);
  // };

  // get MEd Name En
  const getMedNameEn = (id) => {
    const M = medListEn.find((m) => m.fixedMedicineId === id);
    if (M) return M.genericBrand;
  };

  // get MEd Generic Name En
  const getMedGenericNameEn = (id) => {
    const M = medListEn.find((m) => m.fixedMedicineId === id);
    if (M) return M.generic;
  };

  // useEffect
  useEffect(() => {
    dispatch(getCompareList());
    dispatch(getWishlist());
    if (localData() && localData()._id) {
      dispatch(getNotificationByCustomer(localData()._id));
      dispatch(getAskPriceListByCustomer(localData()._id));
    }
    // if (!(medicines && medicines.length > 0)) dispatch(getMedicines());
  }, []);

  // Read Not Count use Effect
  useEffect(() => {
    if (
      customerNotifications &&
      customerNotifications.length > 0 &&
      localData() &&
      localData()._id
    ) {
      const readArr = customerNotifications.filter((o) => !o.read);
      setReadNotiCount(readArr.length);
    }
  }, [customerNotifications]);

  window.addEventListener("scroll", toggleVisible);

  // Set Language
  useEffect(() => {
    if (activeLangFront) setLang(activeLangFront.langCode);
  }, [activeLangFront]);

  // set medList
  useEffect(() => {
    setMedLoading(true);
    if (medicines && medicines.length > 1) {
      const M = medicines.find((m) => m.lang === lang);
      if (M && M.data) {
        setMedList(M.data);
      }
      const MEn = medicines.find((m) => m.lang === "en");
      if (MEn && MEn.data) {
        setMedListEn(MEn.data);
      }
    }
    setTimeout(() => {
      setMedLoading(false);
    }, 1000);
  }, [lang, medicines]);

  // Check current url
  var currentUrl = window.location.href;

  useEffect(() => {
    if (currentUrl.includes("our-medicine")) {
      setActiveMenu("our-medicines");
      setIsHome(false);
    } else if (currentUrl.includes("blog")) {
      setActiveMenu("blog");
      setIsHome(false);
    } else {
      setActiveMenu("home");
      setIsHome(true);
    }
  }, [currentUrl]);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate

  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 11,
        background: webSettings.siteColorTheme || "lightblue",
        py: 1,
        pr: 2,
      }}
    >
      <Grid container justifyContent="flex-end">
        <Grid item sx={{ flex: 1 }}>
          {!medLoading && (
            <Autocomplete
              sx={{ mx: 1 }}
              filterOptions={filterOptions}
              disablePortal
              options={medList}
              getOptionLabel={(option) =>
                `${option.genericBrand} (${option.generic}) ${
                  lang !== "en"
                    ? " - " +
                      getMedNameEn(option.medicineDetectorId) +
                      " (" +
                      getMedGenericNameEn(option.medicineDetectorId) +
                      ")"
                    : ""
                }`
              }
              onChange={(e, newValue) => handleSearchClick(e, newValue)}
              onKeyPress={(e, newValue) =>
                e.key === "Enter" && handleSearchClick(e, newValue)
              }
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {/* <img
                    loading="lazy"
                    width="20"
                    src={
                      option.image.length > 0
                        ? option.image
                            .find((i) => i.default)
                            .src.includes("http")
                          ? option.image.find((i) => i.default).src
                          : PF +
                            "adminPanel/medicines/img/" +
                            option.image.find((i) => i.default).src
                        : "https://www.daveraine.com/img/products/no-image.jpg"
                    }
                    alt="admins"
                  /> */}
                  <img
                    loading="lazy"
                    width="20"
                    src={
                      option.image.length > 0
                        ? option.image
                            .find((i) => i.default)
                            .src.includes("http")
                          ? option.image.find((i) => i.default).src
                          : PF +
                            "adminPanel/medicines/img/" +
                            option.image.find((i) => i.default).src
                        : PF + "adminPanel/medicines/no-medicine-image.jpg"
                    }
                    alt={option.genericBrand}
                  />
                  <div style={{ fontSize: ".85rem" }}>
                    {option.genericBrand}
                    <br />
                    {" (" + option.generic + ") "}
                  </div>
                  {/* <br />
                  {lang !== "en" &&
                    " - " + getMedNameEn(option.medicineDetectorId)}
                  {lang !== "en" &&
                    " (" + getMedGenericNameEn(option.medicineDetectorId) + ")"} */}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  color="secondary"
                  label={translateFront("Search Products...")}
                  variant="outlined"
                  size="small"
                />
              )}
            />
          )}
        </Grid>
        <Grid item>
          {localData() && localData()._id ? (
            <Tooltip arrow placement="top" title={translateFront("Profile")}>
              <IconButton onClick={() => setProfileOpen(true)}>
                <Badge badgeContent={readNotiCount || 0} color="error">
                  <Avatar
                    alt={localData().name}
                    src={
                      localData().customerPic
                        ? localData().customerPic
                        : localData().gender
                        ? localData().gender === "male"
                          ? PF +
                            "frontEnd/default/man-default-customer-image.jpg"
                          : localData().gender === "female" &&
                            PF +
                              "frontEnd/default/women-default-customer-image.jpg"
                        : "https://picsum.photos/200/300"
                    }
                    sx={{ width: 24, height: 24 }}
                  />
                </Badge>
              </IconButton>
            </Tooltip>
          ) : (
            <IconButton disabled>
              <PersonIcon />
            </IconButton>
          )}
          <Tooltip arrow placement="top" title={translateFront("Wishlist")}>
            <IconButton
              onClick={() => setWishOpen(true)}
              disabled={wishlist && wishlist.length < 1}
            >
              <Badge badgeContent={wishlist && wishlist.length} color="primary">
                <FavoriteIcon
                  sx={{
                    color: wishlist && wishlist.length > 0 ? "black" : "grey",
                  }}
                />
              </Badge>
            </IconButton>
          </Tooltip>
          <Tooltip arrow placement="top" title={translateFront("Comparison")}>
            <IconButton
              onClick={() => setCompareOpen(true)}
              disabled={compareList && compareList.length < 1}
            >
              <Badge
                badgeContent={compareList && compareList.length}
                color="error"
              >
                <CompareIcon
                  sx={{
                    color:
                      compareList && compareList.length > 0 ? "black" : "grey",
                  }}
                />
              </Badge>
            </IconButton>
          </Tooltip>

          {localData() && localData()._id && (
            <Tooltip arrow placement="top" title={translateFront("Cart")}>
              <IconButton
                onClick={() => setCartOpen(true)}
                disabled={carts && carts.length < 1}
              >
                <Badge badgeContent={carts ? carts.length : 0} color="error">
                  <ShoppingCartIcon
                    sx={{
                      color: carts && carts.length > 0 ? "black" : "grey",
                    }}
                  />
                </Badge>
              </IconButton>
            </Tooltip>
          )}
          <IconButton onClick={() => setMenuDlg(true)} size="small">
            <MenuIcon sx={{ color: "black" }} />
          </IconButton>
          <Drawer
            anchor={"right"}
            open={menuDlg}
            onClose={() => setMenuDlg(false)}
          >
            <List>
              <ListItem disablePadding>
                <ListItemButton
                  selected={activeMenu === "home"}
                  onClick={() => handleMenu("home")}
                >
                  <ListItemText primary={translateFront("Home")} />
                </ListItemButton>
              </ListItem>
              <Link
                to="/our-medicines"
                style={{
                  color: "inherit",
                  textDecoration: "none",
                  width: "100%",
                }}
              >
                <ListItem disablePadding>
                  <ListItemButton
                    selected={activeMenu === "our-medicines"}
                    onClick={() => handleMenu("our-medicines")}
                  >
                    <ListItemText primary={translateFront("Our Medicine")} />
                  </ListItemButton>
                </ListItem>
              </Link>
              <ListItem disablePadding>
                <Link
                  to="/blog"
                  style={{
                    color: "inherit",
                    textDecoration: "none",
                    width: "100%",
                  }}
                >
                  <ListItem disablePadding>
                    <ListItemButton
                      selected={activeMenu === "blog"}
                      onClick={() => handleMenu("blog")}
                    >
                      <ListItemText primary={translateFront("Blog")} />
                    </ListItemButton>
                  </ListItem>
                </Link>
              </ListItem>
              <ListItem disablePadding>
                {/* <Link
                to="/testimonials"
                style={{
                  color: "inherit",
                  textDecoration: "none",
                  width: "100%",
                }}
              > */}
                <ListItem disablePadding>
                  <ListItemButton
                    selected={activeMenu === "testimonials"}
                    onClick={() => {
                      setOpen(true);
                      setActiveMenu("testimonials");
                      navigate("/");
                      // handleToggle();
                      setMenuDlg(false);
                      setTimeout(
                        () => {
                          dispatch(setIdToScroll("testimonial"));
                          setOpen(false);
                        },
                        isHome ? 100 : 3000
                      );
                    }}
                  >
                    <ListItemText primary={translateFront("Testimonial")} />
                  </ListItemButton>
                </ListItem>
                {/* </Link> */}
              </ListItem>
              <ListItem disablePadding>
                {/* <Link
                to="/contact-us"
                style={{
                  color: "inherit",
                  textDecoration: "none",
                  width: "100%",
                }}
              > */}
                <ListItem disablePadding>
                  <ListItemButton
                    selected={activeMenu === "contact-us"}
                    // onClick={() => handleMenu("contact-us")}
                    onClick={() => {
                      setOpen(true);
                      setActiveMenu("contact-us");
                      navigate("/");
                      // handleToggle();
                      setMenuDlg(false);
                      setTimeout(
                        () => {
                          dispatch(setIdToScroll("contact-us"));
                          setOpen(false);
                        },
                        isHome ? 100 : 3000
                      );
                    }}
                  >
                    <ListItemText primary={translateFront("Contact Us")} />
                  </ListItemButton>
                </ListItem>
                {/* </Link> */}
              </ListItem>
              <ListItem disablePadding>
                {/* <Link
                to="/how-to-order"
                style={{
                  color: "inherit",
                  textDecoration: "none",
                  width: "100%",
                }}
              > */}
                <ListItem disablePadding>
                  <ListItemButton
                    selected={activeMenu === "how-to-order"}
                    // onClick={() => handleMenu("how-to-order")}
                    onClick={() => {
                      setOpen(true);
                      setActiveMenu("how-to-order");
                      navigate("/");
                      // handleToggle();
                      setMenuDlg(false);
                      setTimeout(
                        () => {
                          dispatch(setIdToScroll("how-to-order"));
                          setOpen(false);
                        },
                        isHome ? 100 : 3000
                      );
                    }}
                  >
                    <ListItemText primary={translateFront("How To Order")} />
                  </ListItemButton>
                </ListItem>
                {/* </Link> */}
              </ListItem>
            </List>
          </Drawer>
        </Grid>
      </Grid>

      {wishOpen && (
        <Drawer
          anchor="left"
          open={wishOpen}
          onClose={() => setWishOpen(false)}
        >
          <WishListDrawer wishOpen={wishOpen} setWishOpen={setWishOpen} />
        </Drawer>
      )}
      {/* Cart Drawer */}
      {cartOpen && (
        <Drawer
          anchor="right"
          open={cartOpen}
          // onClose={() => setCartOpen(false)}
        >
          <CartDrawer
            setCartOpen={setCartOpen}
            setCheckoutDlg={setCheckoutDlg}
          />
        </Drawer>
      )}
      {/* profile dialog menu */}
      <Drawer
        anchor="top"
        open={profileOpen}
        onClose={() => setProfileOpen(false)}
      >
        <ProfileDrawer
          readNotiCount={readNotiCount}
          setProfileOpen={setProfileOpen}
        />
      </Drawer>
      {/* Compare Dialog */}
      {compareOpen && (
        <CompareDlg
          compareOpen={compareOpen}
          setCompareOpen={setCompareOpen}
          lang={localLangData ? localLangData.langCode : "en"}
        />
      )}

      {/* CheckOut Dlg */}
      {checkoutDlg && (
        <CheckoutMain
          checkoutDlg={checkoutDlg}
          setCheckoutDlg={setCheckoutDlg}
        />
      )}

      {orderHistoryDlg && <OrderHistoryDialog />}

      {/* Back Drop */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}
