import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  Box,
  Dialog,
  DialogContent,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import CaptchaTikTakToe from "../components/misc/CaptchaTikTakToe";
import {
  checkIfBlockCustomer,
  resetUnblockReqCount,
  unblockReqCount,
  unblockRequest,
} from "../reduxToolkit/features/auth";

// Media Query
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function Unblock() {
  // Media Query
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Dlg
  const [unblockLoading, setUnBlockLoading] = useState(false);
  const [captchaDlg, setCaptchaDlg] = useState(false);
  const [blockContactBuffer, setBlockContactBuffer] = useState({});
  const [unBlockMsg, setUnBlockMsg] = useState("");

  const localLangDataFront = JSON.parse(localStorage.getItem("langFront"));

  //   Store
  const { webSettings } = useSelector((state) => state.webSettings);
  const { unblockReqCountResponse } = useSelector((state) => state.auth);

  const [searchParams, setSearchParams] = useSearchParams();

  //   Functions
  const handleUnBlockreq = () => {
    dispatch(
      unblockRequest({
        id: blockContactBuffer.id,
        message: unBlockMsg,
        langId: localLangDataFront._id,
      })
    );
    setUnBlockLoading(true);

    setTimeout(() => {
      setUnBlockLoading(false);
      setBlockContactBuffer({});
      dispatch(resetUnblockReqCount());
      setUnBlockMsg("");
      navigate("/");
      //   window.open("/", "_self");
    }, 2000);
  };

  //   useEffect
  useEffect(() => {
    dispatch(unblockReqCount(searchParams.get("__customer_id_encrypted")));
    setBlockContactBuffer({});
  }, []);

  useEffect(() => {
    setBlockContactBuffer({
      id: searchParams.get("__customer_id_encrypted"),
      blockMsgLength: unblockReqCountResponse,
    });
  }, [unblockReqCountResponse]);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate

  return (
    <>
      <Grid container spacing={0} justifyContent="center" alignItems="center">
        <Grid
          item
          sx={{
            border: smUp && "1rem double red",
            borderRadius: smUp && ".25rem",
            p: !smUp ? 1 : !mdUp ? 5 : 15,
            m: smUp && 5,
          }}
        >
          <Typography
            variant={!smUp ? "subtitle2" : "h6"}
            color="error"
            align="center"
            sx={{ textDecoration: "underline" }}
          >
            {" "}
            {translateFront("You Are Blocked For Unfair Usage")}
          </Typography>
          {blockContactBuffer.blockMsgLength >
          (webSettings.maxUnblockReqCount || 2) ? (
            <Alert severity="error">
              {translateFront("Maximum Limit reached for UnBlock Request")}
            </Alert>
          ) : (
            <>
              <TextField
                label="Message"
                value={unBlockMsg}
                onChange={(e) => setUnBlockMsg(e.target.value)}
                fullWidth
                multiline
                minRows={3}
                variant="outlined"
                size="small"
                required
                autoFocus
                sx={{ my: 1 }}
              />
              <Typography variant="body2" color="secondary">
                {" "}
                {translateFront(
                  "Please Check Email For Reviewing Reason For Blocking"
                )}
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <LoadingButton
                  variant="contained"
                  size="small"
                  sx={{ my: 1 }}
                  loading={unblockLoading}
                  disabled={unBlockMsg.length > 0 ? false : true}
                  onClick={() => setCaptchaDlg(true)}
                >
                  {translateFront("Request Unblock")}
                </LoadingButton>
              </Box>
            </>
          )}
        </Grid>
      </Grid>
      {/* Captcha */}
      <Dialog open={captchaDlg} onClose={() => setCaptchaDlg(false)}>
        <DialogContent>
          <CaptchaTikTakToe
            loginFunc={handleUnBlockreq}
            setCaptchaDlg={setCaptchaDlg}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
