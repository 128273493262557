import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Grid from "@mui/material/Grid";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CardActions,
} from "@mui/material";

// Icons
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import TemplateView from "../dialogs/TemplateView";
import { getExactTime } from "../../../hooks/getCreatedData";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  getCampaigns,
  removeCampaign,
} from "../../../reduxToolkit/features/auth";
import { useDispatch } from "react-redux";
import SendBulkEmailDialog from "../dialogs/SendBulkEmailDialog";

export default function CampaignManagement({
  campaigns,
  templates,
  translate1,
  translate2,
}) {
  const dispatch = useDispatch();
  const [campaignList, setCampaignList] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [showEmailList, setShowEmailList] = useState([]);
  const [viewTemplate, setViewTemplate] = useState(false);
  const [viewTemplateBuffer, setViewTemplateBuffer] = useState(undefined);

  //   Dialogs
  const [deleteDlg, setDeleteDlg] = useState(false);
  const [deleteBuffer, setDeleteBuffer] = useState({});
  const [loadingDlt, setLoadingDlt] = useState(false);

  const [rerunDlg, setRerunDlg] = useState(false);
  const [rerunBuffer, setRerunBuffer] = useState({});

  // Local Data
  const localAdminInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));

  const getTemplateInfo = (id) => {
    let obj = {};
    const T = templateList.find((t) => t._id === id);
    if (T) obj = T;
    return obj;
  };

  const handleShowEmailList = (index) => {
    let arr = [...showEmailList];

    if (arr.includes(index)) {
      arr = arr.filter((a) => a !== index);
    } else {
      arr.push(index);
    }
    setShowEmailList(arr);
  };

  const handleDeleteClick = (obj) => {
    setDeleteDlg(true);
    setDeleteBuffer(obj);
  };

  const handleDeleteDlgClose = () => {
    setDeleteDlg(false);
    setDeleteBuffer({});
  };

  const handleDelete = () => {
    setLoadingDlt(true);
    let obj = {
      id: deleteBuffer._id,
    };
    if (localAdminInfo._id) obj.creatorId = localAdminInfo._id;
    dispatch(removeCampaign(obj));
    setTimeout(() => {
      setLoadingDlt(false);
      handleDeleteDlgClose();
      dispatch(getCampaigns());
    }, 1000);
  };

  const handleRerunClick = (obj) => {
    setRerunDlg(true);
    setRerunBuffer(obj);
  };

  useEffect(() => {
    if (campaigns && campaigns.length > 0) setCampaignList(campaigns);
  }, [campaigns]);

  useEffect(() => {
    if (templates && templates.length > 0) setTemplateList(templates);
  }, [templates]);
  return (
    <>
      <Button
        variant="outlined"
        size="small"
        fullWidth
        sx={{ mb: 1, pointerEvents: "none" }}
      >
        {translate1("Campaigns")} ({campaignList.length})
      </Button>
      <Grid container spacing={1}>
        {campaignList.map((x, i) => (
          <Grid item key={i} xs={12} sm={6} md={4} xl={3}>
            <Card>
              <CardContent>
                <Typography
                  variant="body2"
                  color="initial"
                  align="center"
                  sx={{ fontSize: "1rem" }}
                >
                  {x.campaignName}
                </Typography>
                <Typography variant="body2" color="green" align="center">
                  {getExactTime(x.createdAt)}
                </Typography>

                <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  fullWidth
                  sx={{ my: 1 }}
                  onClick={() => {
                    setViewTemplate(true);
                    setViewTemplateBuffer(getTemplateInfo(x.templateId));
                  }}
                >
                  {getTemplateInfo(x.templateId).title}
                </Button>
                <Button
                  variant={showEmailList.includes(i) ? "outlined" : "contained"}
                  size="small"
                  color="secondary"
                  fullWidth
                  endIcon={
                    showEmailList.includes(i) ? (
                      <ArrowDropUpIcon />
                    ) : (
                      <ArrowDropDownCircleIcon />
                    )
                  }
                  onClick={() => handleShowEmailList(i)}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  {translate1("Email List")} (
                  {x.emailArr && x.emailArr.length > 0 && x.emailArr.length})
                </Button>
                {showEmailList.includes(i) && (
                  <Box
                    sx={{
                      maxHeight: "40vh",
                      overflowY: "auto",
                      overflowX: "hidden",
                      border: ".1rem solid pink",
                      borderRadius: ".25rem",
                      p: 1,
                      my: 1,
                    }}
                  >
                    <Grid container spacing={0}>
                      <Grid item xs={4}>
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: "600" }}
                        >
                          {translate1("Name")}
                        </Typography>
                      </Grid>

                      <Grid item xs={8}>
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: "600" }}
                        >
                          {translate1("Email")}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider />
                    {x.emailArr &&
                      x.emailArr.length > 0 &&
                      x.emailArr.map((y, j) => (
                        <Box key={j}>
                          <Grid container spacing={0}>
                            <Grid item xs={4}>
                              <Typography variant="body2">{y.name}</Typography>
                            </Grid>

                            <Grid item xs={8}>
                              <Typography variant="body2">{y.email}</Typography>
                            </Grid>
                          </Grid>
                          <Divider />
                        </Box>
                      ))}
                  </Box>
                )}
              </CardContent>
              <CardActions>
                <Grid container spacing={1}>
                  <Grid item sm={12} md={6}>
                    <Button
                      size="small"
                      variant="outlined"
                      color="warning"
                      fullWidth
                      onClick={() => handleRerunClick(x)}
                    >
                      {translate1("Rerun Campaign")}
                    </Button>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <Button
                      size="small"
                      color="error"
                      fullWidth
                      variant="outlined"
                      onClick={() => handleDeleteClick(x)}
                    >
                      {translate1("Delete")}
                    </Button>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      {viewTemplate && (
        <Dialog
          open={viewTemplate}
          onClose={() => {
            setViewTemplate(false);
            setViewTemplateBuffer(undefined);
          }}
          fullWidth
          maxWidth={"md"}
        >
          <DialogContent>
            <DialogContentText>
              <TemplateView viewTemplateBuffer={viewTemplateBuffer} />
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}

      {deleteDlg && (
        <Dialog open={deleteDlg}>
          <DialogContent>
            Do you really want to delete "
            <span style={{ color: "red" }}>{deleteBuffer.campaignName} </span>"
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteDlgClose} color="primary">
              Close
            </Button>
            <LoadingButton
              loading={loadingDlt}
              onClick={handleDelete}
              color="error"
            >
              Delete
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}

      {rerunDlg && (
        <SendBulkEmailDialog
          openDlg={rerunDlg}
          setOpenDlg={setRerunDlg}
          templates={templates}
          campaignBuffer={rerunBuffer}
          rerunCampaign={true}
        />
      )}
    </>
  );
}
