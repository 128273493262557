import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
  TextField,
  Alert,
  DialogContent,
  Tooltip,
  IconButton,
  CardMedia,
  Divider,
  Box,
  Link,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { fakeTypingCaptchaText } from "../../data/fakeTypingCaptcha";
import { useDispatch, useSelector } from "react-redux";
import { getGeoInfo } from "../../../../reduxToolkit/features/adminPanel/misc";
import { getNotification } from "../../../../reduxToolkit/features/adminPanel/notification";
import LoadingBox from "../../../../components/misc/Loading";
import {
  getUnusedFiles,
  getUnusedImg,
  getUnusedVid,
  removeAllCategories,
  removeAllCompanies,
  removeAllDosages,
  removeAllMedicine,
  removeAllMedInfo,
  removeAllSeoTags,
  removeAllSuppliers,
  removeAllUnusedImg,
  removeAllUnusedVid,
  removeAllUploads,
  removeJunkFiles,
} from "../../../../reduxToolkit/features/adminPanel/medicine";
import LoadingButton from "@mui/lab/LoadingButton";

// Icon
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import CancelIcon from "@mui/icons-material/Cancel";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { PF } from "../../../../config";

// Media Query
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { formatByteSize } from "../../../../hooks/formatByteSize";
import {
  getSettings,
  updateShowOriginatorSettings,
  updateShowPriceSettings,
} from "../../../../reduxToolkit/features/adminPanel/webSettings";
import ManageCollectiveMedicineAdditionalSection from "./ManageCollectiveMedicineAdditionalSection";

export default function AdvancedMedicineSettings() {
  // Media Query
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));

  const endRef = useRef();
  const imgSecRef = useRef();
  const vidSecRef = useRef();
  const filesSecRef = useRef();

  const dispatch = useDispatch();
  // ! Use State
  const [verified, setVerified] = useState(false);
  const [verifiedLoading, setVerifiedLoading] = useState(false);
  const [random, setRandom] = useState(0);
  const [code, setCode] = useState("");
  const [noMatchAlert, setNoMatchAlert] = useState(false);

  // files view section
  const [fileViewSecLoading, setFileViewSecLoading] = useState(false);
  const [expandUnusedImg, setExpandUnusedImg] = useState(false);
  const [unUsedImgArr, setUnUsedImgArr] = useState([]);
  const [unUsedImgTotalSize, setUnUsedImgTotalSize] = useState(0);
  const [expandUnusedVid, setExpandUnusedVid] = useState(false);
  const [unUsedVidArr, setUnUsedVidArr] = useState([]);
  const [unUsedVidTotalSize, setUnUsedVidTotalSize] = useState(0);
  const [vidLoading, setVidLoading] = useState(false);
  const [expandUnusedFiles, setExpandUnusedFiles] = useState(false);
  const [unUsedFilesArr, setUnUsedFilesArr] = useState([]);
  const [unUsedFilesTotalSize, setUnUsedFilesTotalSize] = useState(0);

  const [collective, setCollective] = useState(false);
  const [specific, setSpecific] = useState(false);

  const [switchLoading, setSwitchLoading] = useState(false);
  const [showPriceChecked, setShowPriceChecked] = useState(false);
  const [showOriginatorChecked, setShowOriginatorChecked] = useState(false);

  // ! Dialogs
  const [dltDlg, setDltDlg] = useState(false);
  const [dltTextBox, setDltTextBox] = useState("");
  const [dlgBuffer, setDlgBuffer] = useState({});
  const [dlgLoading, setDlgLoading] = useState(false);
  const [dlgBtnEnable, setDlgBtnEnable] = useState(false);

  // !  local Data
  const localData = JSON.parse(localStorage.getItem("adminLoginInfo"));

  //   ! Store
  const {
    removeAllMedInfoResponse,
    unusedImages,
    unusedVids,
    unusedFiles,
    removeJunkFilesResponse,
  } = useSelector((state) => state.medicine);
  // Store
  const { webSettings } = useSelector((state) => state.webSettings);

  //  ! Functions
  const handleCodeMatch = () => {
    setVerifiedLoading(true);
    if (
      fakeTypingCaptchaText &&
      code === fakeTypingCaptchaText[random].verificationCode
    ) {
      setVerified(true);
      setNoMatchAlert(false);
    } else {
      setRandom(Math.floor(Math.random() * 27) + 1);
      setNoMatchAlert(true);
      dispatch(getGeoInfo({ adminTryingId: localData._id }));
      setTimeout(() => dispatch(getNotification()), 2000);
    }
    setTimeout(() => setVerifiedLoading(false), 2000);
  };

  const resetDltDlg = () => {
    setDlgBuffer({});
    setDltTextBox("");
  };

  const handleRemoveAllMedicineInfo = () => {
    setDlgLoading(true);
    setFileViewSecLoading(true);
    resetDltDlg();
    dispatch(removeAllMedInfo());
  };

  const handleRemoveAllJunk = () => {
    setDlgLoading(true);
    setFileViewSecLoading(true);
    resetDltDlg();
    let arr = [];
    unUsedImgArr.length > 0 &&
      unUsedImgArr.forEach((element) => arr.push(element.filePath));
    unUsedVidArr.length > 0 &&
      unUsedVidArr.forEach((element) =>
        arr.push("./uploads/adminPanel/medicines/vid/" + element.fileName)
      );
    // unUsedFilesArr.length > 0 &&
    //   unUsedFilesArr.forEach((element) =>
    //     arr.push("./uploads/files/" + element.fileName)
    //   );
    dispatch(removeJunkFiles(arr));
    setTimeout(() => {
      dispatch(getUnusedImg());
      dispatch(getUnusedVid());
      dispatch(getUnusedFiles());
    }, 1000);
  };

  const closure = () => {
    setDlgLoading(true);
    setFileViewSecLoading(true);
    resetDltDlg();
    setTimeout(() => {
      dispatch(getUnusedImg());
      dispatch(getUnusedVid());
      dispatch(getUnusedFiles());
      setDltDlg(false);
      setDlgLoading(false);
      setFileViewSecLoading(false);
    }, 2000);
  };

  const handleRemoveAllMedicine = () => {
    dispatch(removeAllMedicine());
    closure();
  };

  const handleRemoveAllCompanies = () => {
    dispatch(removeAllCompanies());
    closure();
  };

  const handleRemoveAllCategories = () => {
    dispatch(removeAllCategories());
    closure();
  };

  const handleRemoveAllDosages = () => {
    dispatch(removeAllDosages());
    closure();
  };

  const handleRemoveAllSeoTags = () => {
    dispatch(removeAllSeoTags());
    closure();
  };

  const handleRemoveAllSuppliers = () => {
    dispatch(removeAllSuppliers());
    closure();
  };

  const handleRemoveAllImg = () => {
    dispatch(removeAllUnusedImg());
    closure();
  };

  const handleRemoveAllVid = () => {
    dispatch(removeAllUnusedVid());
    closure();
  };

  const handleRemoveAllUploaded = () => {
    dispatch(removeAllUploads());
    closure();
  };

  const scrollBot = () =>
    setTimeout(
      () => endRef.current.scrollIntoView({ behavior: "smooth" }),
      250
    );

  const handleShowPrice = (e) => {
    setSwitchLoading(true);
    dispatch(
      updateShowPriceSettings({
        showPrice: e.target.checked,
        creatorId: localData._id,
      })
    );
    setTimeout(() => {
      dispatch(getNotification());
      setSwitchLoading(false);
    }, 2000);
  };

  const handleShowOriginator = (e) => {
    setSwitchLoading(true);
    dispatch(
      updateShowOriginatorSettings({
        showOriginator: e.target.checked,
        creatorId: localData._id,
      })
    );
    setTimeout(() => {
      dispatch(getNotification());
      setSwitchLoading(false);
    }, 2000);
  };

  //   ! useEffect
  useEffect(() => {
    if (webSettings) {
      // if (webSettings.showPrice !== undefined)
      setShowPriceChecked(webSettings.showPrice);
      // if (webSettings.showOriginator !== undefined)
      setShowOriginatorChecked(webSettings.showOriginator);
    }
  }, [webSettings]);

  useEffect(() => setRandom(Math.floor(Math.random() * 27) + 1), []);

  // get Un Used Files
  useEffect(() => {
    dispatch(getUnusedImg());
    dispatch(getUnusedVid());
    dispatch(getUnusedFiles());
    dispatch(getSettings());
  }, []);

  useEffect(() => {
    if (
      unusedImages &&
      unusedImages.message &&
      unusedImages.data &&
      unusedImages.data.length > 0
    ) {
      setUnUsedImgArr([...unusedImages.data]);
      let counter = 0;
      unusedImages.data.map((x) => {
        if (x.stat.size) counter = counter + x.stat.size;
      });
      setUnUsedImgTotalSize(counter);
    }
  }, [unusedImages]);

  useEffect(() => {
    if (
      unusedVids &&
      unusedVids.message &&
      unusedVids.data &&
      unusedVids.data.length > 0
    ) {
      setUnUsedVidArr([...unusedVids.data]);
      let counter = 0;
      unusedVids.data.map((x) => {
        if (x.stat.size) counter = counter + x.stat.size;
      });
      setUnUsedVidTotalSize(counter);
    }
  }, [unusedVids]);

  useEffect(() => {
    if (
      unusedFiles &&
      unusedFiles.message &&
      unusedFiles.data &&
      unusedFiles.data.length > 0
    ) {
      setUnUsedFilesArr([...unusedFiles.data]);
      let counter = 0;
      unusedFiles.data.map((x) => {
        if (x.stat.size) counter = counter + x.stat.size;
      });
      setUnUsedFilesTotalSize(counter);
    }
  }, [unusedFiles]);

  useEffect(() => {
    if (removeAllMedInfoResponse.message || removeJunkFilesResponse.message) {
      if (!removeAllMedInfoResponse.failed) setDltDlg(false);
      if (!removeJunkFilesResponse.failed) {
        setDltDlg(false);
        dispatch(getUnusedImg());
        dispatch(getUnusedVid());
        dispatch(getUnusedFiles());
        setTimeout(() => {
          setFileViewSecLoading(false);
        }, 2000);
      }
      setTimeout(() => setDlgLoading(false), 1000);
    }
  }, [
    removeAllMedInfoResponse.failed,
    removeAllMedInfoResponse.message,
    removeJunkFilesResponse.failed,
    removeJunkFilesResponse.message,
  ]);

  //   Check All Med Delete text
  useEffect(() => {
    dltTextBox === "confirm-delete"
      ? setDlgBtnEnable(true)
      : setDlgBtnEnable(false);
  }, [dltTextBox]);

  // ! translate
  const [siteLang, setSiteLang] = useState("");
  // Local Store
  const localLangData = JSON.parse(localStorage.getItem("lang"));
  // Store
  const { langs, activeLang } = useSelector((state) => state.misc);
  const [langSettings1, setLangSettings1] = useState([]);
  const [langSettings2, setLangSettings2] = useState([]);
  const title1 = "Advanced Med Settings";
  const title2 = "Misc Words";

  useEffect(() => {
    if (langs && langs.length > 0 && siteLang !== "") {
      const L = langs.find((l) => l.langCode === siteLang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      const A = arr.find((l) => l._title === title1);
      if (A) setLangSettings1(A.data);
      const B = arr.find((l) => l._title === title2);
      if (B) setLangSettings2(B.data);
    }
  }, [siteLang, langs]);

  const translate1 = (String) => translation(String, title1, 1);
  const translate2 = (String) => translation(String, title2, 2);

  const translation = (String, title, index) => {
    let translation = "";
    let settings = [];
    if (index === 1) {
      settings = langSettings1;
    } else if (index === 2) {
      settings = langSettings2;
    }
    const T = settings.find((ls) => ls.title === String);
    if (T && T.value) translation = T.value;
    else {
      let arr = [];
      if (langs && langs.length > 0 && siteLang !== "") {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.settings && LEn.settings.length > 0) arr = LEn.settings;
        const S = arr.find((l) => l._title === title);
        if (S) {
          const T = S.data.find((ls) => ls.title === String);
          if (T && T.value) translation = T.value;
        }
      }
    }
    return translation;
  };

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setSiteLang(activeLang.langCode);
    } else {
      if (localLangData) {
        setSiteLang(localLangData.langCode);
      }
    }
  }, [activeLang]);
  // ! translate

  return (
    <>
      {verifiedLoading ? (
        <LoadingBox w={"100%"} />
      ) : !verified ? (
        <Grid container spacing={0} justifyContent="center">
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              border: ".1rem solid indigo",
              borderRadius: ".25rem",
              p: 1,
            }}
          >
            <Typography variant="body2" color="inherit" align="center">
              {translate1("Type the sentence")} -
            </Typography>
            <Typography variant="body2" color="secondary" align="center">
              "{fakeTypingCaptchaText && fakeTypingCaptchaText[random].text}"
            </Typography>
            <TextField
              label={translate1("Type Here") + "..."}
              value={code}
              onChange={(e) => setCode(e.target.value)}
              variant="standard"
              fullWidth
              sx={{ mb: 1 }}
              onKeyPress={(e) => e.key === "Enter" && handleCodeMatch()}
            />
            {noMatchAlert && <Alert severity="warning">Not Matched!</Alert>}
            <Button
              variant="contained"
              size="small"
              fullWidth
              onClick={handleCodeMatch}
            >
              {translate1("Submit")}
            </Button>
          </Grid>
        </Grid>
      ) : (
        <>
          <Divider sx={{ my: 2 }} />
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  disabled={switchLoading}
                  checked={showPriceChecked}
                  onChange={handleShowPrice}
                />
              }
              label="Show Price"
            />
            <FormControlLabel
              control={
                <Switch
                  disabled={switchLoading}
                  checked={showOriginatorChecked}
                  onChange={handleShowOriginator}
                />
              }
              label="Show Originator"
            />
          </FormGroup>
          <Grid container spacing={1} justifyContent="center">
            {fileViewSecLoading ? (
              <Grid item xs={12} sm={8}>
                <LoadingBox w={"100%"} h={"100%"} />
              </Grid>
            ) : (
              <Grid item xs={12} sm={8}>
                <Grid container spacing={1} flexDirection="column">
                  <Grid item>
                    <Button
                      disabled={unUsedImgArr.length < 1}
                      variant={expandUnusedImg ? "outlined" : "contained"}
                      size="small"
                      fullWidth
                      color="primary"
                      ref={imgSecRef}
                      onClick={() => {
                        setExpandUnusedImg(!expandUnusedImg);
                        setTimeout(
                          () =>
                            imgSecRef.current.scrollIntoView({
                              behavior: "smooth",
                            }),
                          250
                        );
                      }}
                      endIcon={
                        expandUnusedImg ? (
                          <ArrowDropUpIcon />
                        ) : (
                          <ArrowDropDownCircleIcon />
                        )
                      }
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      {translate1("Unused Images")} (
                      {formatByteSize(unUsedImgTotalSize)})
                    </Button>
                  </Grid>

                  {expandUnusedImg && (
                    <>
                      <Grid item>
                        <Button
                          fullWidth
                          variant="contained"
                          color="warning"
                          size="small"
                          onClick={() => {
                            setDlgBuffer({
                              title: "remove all unused images",
                              func: handleRemoveAllImg,
                            });
                            setDltDlg(true);
                          }}
                        >
                          {translate1("Remove all unused images")}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Box
                          sx={{
                            maxHeight: xlUp ? "40vh" : smUp ? "25vh" : "65vh",
                            overflowY: "auto",
                            overflowX: "hidden",
                          }}
                        >
                          <Grid container spacing={1} justifyContent="center">
                            {unUsedImgArr.map((x, i) => (
                              <Grid
                                item
                                key={i}
                                sx={{
                                  border: ".1rem solid blue",
                                  m: 1,
                                  borderRadius: ".25rem",
                                }}
                              >
                                <Grid container spacing={0}>
                                  <Grid item>
                                    <LazyLoadImage
                                      alt="deal"
                                      effect="blur"
                                      src={PF + x.filePath}
                                      style={{
                                        width: xlUp
                                          ? "220px"
                                          : smUp && !mdUp
                                          ? "120px"
                                          : "180px",
                                        height: xlUp ? "120px" : "80px",
                                        objectFit: "cover",
                                        effect: "black-adn-white",
                                      }}
                                    />
                                  </Grid>
                                  <Grid item>
                                    <Grid
                                      container
                                      spacing={0}
                                      flexDirection="column"
                                    >
                                      <Grid item>
                                        <Tooltip
                                          arrow
                                          placement="top"
                                          title="info"
                                        >
                                          <IconButton size="small">
                                            <InfoIcon color="info" />
                                          </IconButton>
                                        </Tooltip>
                                      </Grid>
                                      <Grid item>
                                        <Tooltip
                                          arrow
                                          placement="top"
                                          title="Remove"
                                        >
                                          <IconButton size="small">
                                            <CloseIcon color="error" />
                                          </IconButton>
                                        </Tooltip>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Typography
                                  variant="body2"
                                  color="error"
                                  align="center"
                                >
                                  {formatByteSize(x.stat.size)}
                                </Typography>
                              </Grid>
                            ))}
                          </Grid>
                        </Box>
                      </Grid>
                    </>
                  )}
                  <Grid item>
                    <Button
                      variant={expandUnusedVid ? "outlined" : "contained"}
                      size="small"
                      fullWidth
                      color="primary"
                      disabled={unUsedVidArr.length < 1}
                      ref={vidSecRef}
                      onClick={() => {
                        setExpandUnusedVid(!expandUnusedVid);
                        setTimeout(
                          () =>
                            vidSecRef.current.scrollIntoView({
                              behavior: "smooth",
                            }),
                          250
                        );
                        if (expandUnusedVid) {
                          setVidLoading(true);
                          setTimeout(() => setVidLoading(false), 2000);
                        }
                      }}
                      endIcon={
                        expandUnusedVid ? (
                          <ArrowDropUpIcon />
                        ) : (
                          <ArrowDropDownCircleIcon />
                        )
                      }
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      {translate1("Unused Videos")} (
                      {formatByteSize(unUsedVidTotalSize)})
                    </Button>
                  </Grid>
                  {expandUnusedVid && (
                    <>
                      <Grid item>
                        <Button
                          fullWidth
                          variant="contained"
                          color="warning"
                          size="small"
                          onClick={() => {
                            setDlgBuffer({
                              title: "remove all unused videos",
                              func: handleRemoveAllVid,
                            });
                            setDltDlg(true);
                          }}
                        >
                          {translate1("Remove all unused videos")}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Box
                          sx={{
                            maxHeight: xlUp ? "40vh" : smUp ? "37vh" : "65vh",
                            overflowY: "auto",
                            overflowX: "hidden",
                          }}
                        >
                          <Grid container spacing={0} justifyContent="center">
                            {unUsedVidArr.map((x, i) => (
                              <Grid
                                item
                                key={i}
                                sx={{
                                  border: ".1rem solid blue",
                                  m: 1,
                                  borderRadius: ".25rem",
                                  minHeight: 150,
                                  minWidth: 150,
                                }}
                              >
                                {vidLoading ? (
                                  <LoadingBox w={"100%"} h={150} />
                                ) : (
                                  <>
                                    <CardMedia
                                      component="video"
                                      height={150}
                                      image={
                                        PF +
                                        "adminPanel/medicines/vid/" +
                                        x.fileName
                                      }
                                      controls
                                    />
                                    <Typography
                                      variant="body2"
                                      color="error"
                                      align="center"
                                    >
                                      {formatByteSize(x.stat.size)}
                                    </Typography>
                                  </>
                                )}
                              </Grid>
                            ))}
                          </Grid>
                        </Box>
                      </Grid>
                    </>
                  )}

                  <Grid item>
                    <Button
                      disabled={unUsedFilesArr.length < 1}
                      variant={expandUnusedFiles ? "outlined" : "contained"}
                      size="small"
                      fullWidth
                      color="success"
                      ref={filesSecRef}
                      onClick={() => {
                        setExpandUnusedFiles(!expandUnusedFiles);
                        setTimeout(
                          () =>
                            filesSecRef.current.scrollIntoView({
                              behavior: "smooth",
                            }),
                          250
                        );
                      }}
                      endIcon={
                        expandUnusedFiles ? (
                          <ArrowDropUpIcon />
                        ) : (
                          <ArrowDropDownCircleIcon />
                        )
                      }
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      {translate1("uploaded Files")} (
                      {formatByteSize(unUsedFilesTotalSize)})
                    </Button>
                  </Grid>

                  {expandUnusedFiles && (
                    <>
                      <Grid item>
                        <Button
                          fullWidth
                          variant="contained"
                          color="warning"
                          size="small"
                          onClick={() => {
                            setDlgBuffer({
                              title: "remove all uploaded files",
                              func: handleRemoveAllUploaded,
                            });
                            setDltDlg(true);
                          }}
                        >
                          {translate1("Remove all uploaded files")}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Box
                          sx={{
                            maxHeight: xlUp ? "40vh" : smUp ? "37vh" : "65vh",
                            overflowY: "auto",
                            overflowX: "hidden",
                          }}
                        >
                          <Grid container spacing={1}>
                            {unUsedFilesArr.map((x, i) => (
                              <Grid
                                item
                                key={i}
                                sx={{ width: "100%" }}
                                xs={12}
                                lg={6}
                              >
                                <Grid
                                  container
                                  spacing={0}
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  <Grid item xs={10} sm={11}>
                                    <Button
                                      variant="outlined"
                                      size="small"
                                      fullWidth
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        textTransform: "lowercase",
                                        borderRadius: !smUp ? 0 : ".25rem",
                                      }}
                                      onClick={() =>
                                        window.open(
                                          PF + "files/" + x.fileName,
                                          "_blank"
                                        )
                                      }
                                    >
                                      {x.fileName}{" "}
                                      {smUp &&
                                        "( " +
                                          formatByteSize(x.stat.size) +
                                          " )"}
                                    </Button>
                                    {!smUp && (
                                      <Button
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        sx={{
                                          textTransform: "lowercase",
                                          pointerEvents: "none",
                                          borderRadius: 0,
                                        }}
                                      >
                                        {formatByteSize(x.stat.size)}{" "}
                                      </Button>
                                    )}
                                  </Grid>
                                  <Grid item xs={2} sm={1}>
                                    <Tooltip
                                      arrow
                                      placement="right"
                                      title="delete"
                                    >
                                      <IconButton size="small">
                                        <CancelIcon color="error" />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                          </Grid>
                        </Box>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} sm={4}>
              <Grid container spacing={1} flexDirection="column">
                {!smUp && (
                  <Grid item>
                    <Divider />
                  </Grid>
                )}
                <Grid item>
                  <Button
                    variant={collective ? "outlined" : "contained"}
                    size="small"
                    fullWidth
                    color="error"
                    onClick={() => {
                      setCollective(!collective);
                      !smUp && scrollBot();
                    }}
                    endIcon={
                      collective ? (
                        <ArrowDropUpIcon />
                      ) : (
                        <ArrowDropDownCircleIcon />
                      )
                    }
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {translate1("Collective remove")}
                  </Button>
                </Grid>
                {collective && (
                  <>
                    <Grid item>
                      <Button
                        variant="contained"
                        size="small"
                        fullWidth
                        color="error"
                        onClick={() => {
                          setDlgBuffer({
                            title: "delete all medicine info",
                            func: handleRemoveAllMedicineInfo,
                          });
                          setDltDlg(true);
                        }}
                      >
                        {translate1("Delete all medicine info")}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        size="small"
                        fullWidth
                        color="error"
                        disabled={
                          unUsedImgArr.length + unUsedVidArr.length > 0
                            ? false
                            : true
                        }
                        onClick={() => {
                          setDlgBuffer({
                            title: "remove all junk files",
                            func: handleRemoveAllJunk,
                          });
                          setDltDlg(true);
                        }}
                      >
                        {translate1("Remove junk")} (
                        {formatByteSize(
                          unUsedImgTotalSize + unUsedVidTotalSize
                        )}
                        )
                      </Button>
                    </Grid>
                  </>
                )}

                <Grid item>
                  <Button
                    variant={specific ? "outlined" : "contained"}
                    size="small"
                    fullWidth
                    color="secondary"
                    onClick={() => {
                      setSpecific(!specific);
                      !smUp && scrollBot();
                    }}
                    endIcon={
                      specific ? (
                        <ArrowDropUpIcon />
                      ) : (
                        <ArrowDropDownCircleIcon />
                      )
                    }
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {translate2("Specific Remove")}
                  </Button>
                </Grid>
                {specific && (
                  <>
                    <Grid item>
                      <Button
                        variant="contained"
                        size="small"
                        fullWidth
                        color="secondary"
                        onClick={() => {
                          setDlgBuffer({
                            title: "delete all medicines",
                            func: handleRemoveAllMedicine,
                          });
                          setDltDlg(true);
                        }}
                      >
                        {translate1("Remove All Medicines")}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        size="small"
                        fullWidth
                        color="secondary"
                        onClick={() => {
                          setDlgBuffer({
                            title: "delete all companies",
                            func: handleRemoveAllCompanies,
                          });
                          setDltDlg(true);
                        }}
                      >
                        {translate1("Remove All Companies")}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        size="small"
                        fullWidth
                        color="secondary"
                        onClick={() => {
                          setDlgBuffer({
                            title: "delete all category",
                            func: handleRemoveAllCategories,
                          });
                          setDltDlg(true);
                        }}
                      >
                        {translate1("Remove All Categories")}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        size="small"
                        fullWidth
                        color="secondary"
                        onClick={() => {
                          setDlgBuffer({
                            title: "delete all dosages",
                            func: handleRemoveAllDosages,
                          });
                          setDltDlg(true);
                        }}
                      >
                        {translate1("Remove All Dosages")}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        size="small"
                        fullWidth
                        color="secondary"
                        onClick={() => {
                          setDlgBuffer({
                            title: "delete all seo tags",
                            func: handleRemoveAllSeoTags,
                          });
                          setDltDlg(true);
                        }}
                      >
                        {translate1("Remove All Seo Tags")}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        size="small"
                        fullWidth
                        color="secondary"
                        onClick={() => {
                          setDlgBuffer({
                            title: "delete all suppliers",
                            func: handleRemoveAllSuppliers,
                          });
                          setDltDlg(true);
                        }}
                      >
                        {translate1("Remove All Suppliers")}
                      </Button>
                    </Grid>
                  </>
                )}
                <Box ref={endRef}></Box>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {dltDlg && (
        <Dialog
          open={dltDlg}
          onClose={() => {
            resetDltDlg();
            setDltDlg(false);
          }}
        >
          <DialogTitle>
            {translate1("Do you really want to delete")} {dlgBuffer.title}?
          </DialogTitle>
          <DialogContent>
            <Typography variant="body2" color="inherit">
              Type <span style={{ color: "red" }}>"confirm-delete"</span> Below
            </Typography>
            <TextField
              fullWidth
              variant="standard"
              label=""
              value={dltTextBox}
              onChange={(e) => setDltTextBox(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                resetDltDlg();
                setDltDlg(false);
              }}
              color="primary"
            >
              {translate2("Close")}
            </Button>
            <LoadingButton
              loading={dlgLoading}
              onClick={dlgBuffer.func}
              color="error"
              disabled={!dlgBtnEnable}
            >
              {translate2("Confirm")}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
