import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function NoticeBoard({ dOpen, dSetOpen, dName }) {
  const [contentSettings, setContentSettings] = useState([]);
  const [langId, setLangId] = useState("");

  // Store
  const { managedContentSettings } = useSelector(
    (state) => state.managedContentSettings
  );
  const { activeLangFront } = useSelector((state) => state.miscFront);

  // Set Language
  useEffect(() => {
    if (activeLangFront) setLangId(activeLangFront._id);
  }, [activeLangFront]);

  // Set Settings
  useEffect(() => {
    if (
      managedContentSettings &&
      managedContentSettings.length > 0 &&
      langId !== ""
    ) {
      const M = managedContentSettings.find((m) => m.langId === langId);
      if (M) setContentSettings(M);
    }
  }, [langId, managedContentSettings]);

  // useEffect(() => {
  // }, [dName]);
  return (
    <Dialog
      open={dOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => dSetOpen(false)}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle sx={{ textAlign: "center" }}>{`${dName}`}</DialogTitle>
      <DialogContent>
        {dName === "about-us" ? (
          <DialogContentText id="alert-dialog-slide-description">
            Let Google help apps determine location. This means sending
            anonymous location data to Google, even when no apps are running.The
            content of this site isn't intended to be a substitute for
            professional medical advice, diagnosis, or treatment. Information
            provided on this website is for reference only. The content on this
            website has been supplied to Elifesaving Medicine by independent
            third parties contracted to provide information for our website.
            Elifesaving Medicine relies on these third parties to create and
            maintain these information and cannot guarantee the medical
            efficacy, accuracy or reliability of the information that has been
            provided to us. If you need any advice or information about the
            drugs on this website, a medical condition or treatment advice, you
            ought to always speak to a health care provider. Only healthcare
            professional can provide you advice on what is safe and most
            effective for you. Please note that Elifesaving Medicine is an
            online based medicine supplying service focused on providing safe
            and affordable lifesaving prescription drugs to thousands of
            customers globally. MFW will assist you in getting required medicine
            against valid prescription and other documents as required by the
            laws of the jurisdiction in which you are present. We consult and
            facilitate patients, hospitals, clinicians and researchers in
            accessing products through reliable sources, which aren't available
            or approved in their home country. All trademarks and registered
            trademarks appearing on this website are the property of their
            respective owners and Elifesaving Medicine is not affiliated with
            them in any way. Any advice on the use of drugs on this website is
            for reference only and is not a substitute for medical advice.
            Please consult with healthcare professional for medical advice.
          </DialogContentText>
        ) : (
          <DialogContentText id="alert-dialog-slide-description">
            Elifesaving Medicine is an online based medicine supplying service
            focused on providing safe and affordable lifesaving prescription
            medicines globally. We’re licensed online pharmacy service provider
            in Bangladesh. We supply most kind of generic prescription medicine
            manufactured by Bangladeshi pharma companies. We give top priority
            to supply ‘WHO Model List of Essential Medicines (EML)'. Specially
            now we’re supplying Oncology (Anti cancer), Anti viral, Bio-tech,
            Liver diseases (Hepatitis B, C) and other lifesaving prescription
            drugs. We follow International drugs rules and regulations to supply
            prescription medicines globally. According to WTO rules and
            regulations, we can supply our prescription medicine in most of the
            country for patient’s personal use. We assist patient in getting
            required medicine against valid prescription and other documents as
            required by the laws of jurisdiction in which they’re present. We
            consult and facilitate patients, hospitals, clinicians and
            researchers in accessing products through reliable sources, which
            are not available or approved in their home country. Our online
            medicine supplying service is helping lots of patient around the
            world to get safe and affordable lifesaving prescription medicines
            from Bangladesh. Patients are being cured by taking our pharma
            companies standard quality medicines. We’re providing this service
            since 2015. We’ve good reputation as an online prescription medicine
            supplying service from Bangladesh. Registered doctors and patients
            recommend our service to help other patients around the world. BBC
            World News reported about our online prescription medicine supplying
            service on their program. These are our great achievements.
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          onClick={() => dSetOpen(false)}
          endIcon={<CloseIcon />}
          color="error"
          variant="contained"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
