export const chatBotMenus = [
  {
    name: "Email",
    image: "https://img.icons8.com/fluency/48/000000/email-open.png",
    imgAlt: "Email",
  },
  {
    name: "Messenger",
    image:
      "https://img.icons8.com/fluency/48/000000/facebook-messenger--v2.png",
    imgAlt: "Messenger",
  },
  {
    name: "WhatsApp",
    image: "https://img.icons8.com/fluency/48/000000/whatsapp.png",
    imgAlt: "Whats-app",
  },
  {
    name: "Call",
    image: "https://img.icons8.com/office/16/000000/callback.png",
    imgAlt: "phone",
  },
  {
    name: "Skype",
    image: "https://img.icons8.com/color/48/000000/skype--v1.png",
    imgAlt: "skype",
  },
];
