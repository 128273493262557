import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  Alert,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// localLangData;
import { getAllPaymentMethods } from "../../reduxToolkit/features/adminPanel/payment";
import { useEffect } from "react";
import getSymbolFromCurrency from "currency-symbol-map";
import { currencyFormatter } from "../../hooks/currencyFormatter";

export default function PaymentForm({
  setCompletedStep,
  addressFormObj,
  paymentData,
  setPaymentData,
}) {
  const dispatch = useDispatch();
  // use State
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [paymentNote, setPaymentNote] = useState("");
  const [availablePaymentMethod, setAvailablePaymentMethod] = useState([]);

  // Currency
  const [exchangeRate, setExchangeRate] = useState(1);
  const [currencyName, setCurrencyName] = useState("USA");

  // Local Store
  const localLangData = () => {
    let langObj = {};
    const L = JSON.parse(localStorage.getItem("langFront"));
    if (L && L._id) langObj = L;
    return langObj;
  };

  // Store
  const { paymentMethods } = useSelector((state) => state.payment);
  const { activeCurrencyFront } = useSelector((state) => state.miscFront);

  // Functions
  const getPaymentInfo = (methodId) => {
    let obj = {};
    // let arr = [];
    if (availablePaymentMethod.length > 0) {
      const D = availablePaymentMethod.find(
        (d) =>
          (localLangData().langCode !== "en" ? d.paymentDetectorId : d._id) ===
          methodId
      );
      if (D) {
        // arr = [...D.paymentSummery];
        obj = { ...D };
      }
    }
    return obj;
  };

  // UseEffect
  useEffect(() => {
    dispatch(getAllPaymentMethods());
  }, []);

  // set currency
  useEffect(() => {
    setExchangeRate(activeCurrencyFront.exchangeRate);
    setCurrencyName(activeCurrencyFront.currencyName);
  }, [activeCurrencyFront]);

  useEffect(() => {
    if (selectedPaymentMethod !== "") {
      setCompletedStep(4);
    } else {
      setCompletedStep(2);
    }
  }, [selectedPaymentMethod]);

  useEffect(() => {
    if (paymentMethods && paymentMethods.length > 0 && addressFormObj.country) {
      const dArr = paymentMethods.filter(
        (d) => d.langId === localLangData()._id
      );
      let finalArr = [];
      dArr.forEach((d) => {
        d.countryArr.forEach((c) => {
          if (c.label === addressFormObj.country) finalArr.push(d);
        });
      });
      if (selectedPaymentMethod === "" && finalArr.length > 0)
        setSelectedPaymentMethod(
          localLangData().langCode !== "en"
            ? finalArr[0].paymentDetectorId
            : finalArr[0]._id
        );
      else setSelectedPaymentMethod("");
      setAvailablePaymentMethod(finalArr);
    }
  }, [paymentMethods, addressFormObj.country]);

  useEffect(() => {
    let obj = {
      selectedPaymentMethod,
      paymentNote,
    };
    setPaymentData(obj);
  }, [selectedPaymentMethod, paymentNote, setPaymentData]);

  // set data from address Form
  useEffect(() => {
    paymentData.selectedPaymentMethod &&
      setSelectedPaymentMethod(paymentData.selectedPaymentMethod);
    paymentData.paymentNote && setPaymentNote(paymentData.paymentNote);
  }, [paymentData.paymentNote, paymentData.selectedPaymentMethod]);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {translateFront("Payment Method")}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {availablePaymentMethod.length < 1 ? (
            <>
              <Alert
                severity="warning"
                sx={{ display: "flex", justifyContent: "center" }}
              >
                {translateFront("No Payment Method Available For")}{" "}
                {addressFormObj.country}
              </Alert>
            </>
          ) : (
            <>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">
                  {translateFront("Select Payment Method")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedPaymentMethod}
                  label={translateFront("Select Payment Method")}
                  onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                >
                  {availablePaymentMethod.map((x, i) => (
                    <MenuItem
                      value={
                        localLangData().langCode !== "en"
                          ? x.paymentDetectorId
                          : x._id
                      }
                      key={i}
                    >
                      {x.methodName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <List>
                {getPaymentInfo(selectedPaymentMethod).paymentSummery &&
                  getPaymentInfo(selectedPaymentMethod).paymentSummery.map(
                    (x, i) =>
                      x !== "" && (
                        <ListItem disablePadding>
                          <ListItemText primary={`${i + 1}.${x}`} />
                        </ListItem>
                      )
                  )}
              </List>
              <Typography variant="h6" color="primary" align="right">
                {translateFront("Transaction Fee")} :{" "}
                {getPaymentInfo(selectedPaymentMethod).transactionUnit === 0
                  ? getPaymentInfo(selectedPaymentMethod).transactionFee + "%"
                  : getSymbolFromCurrency(currencyName) +
                    currencyFormatter(
                      currencyName,
                      (
                        Number(
                          getPaymentInfo(selectedPaymentMethod).transactionFee
                        ) * exchangeRate
                      ).toFixed(0)
                    ) +
                    "(flat)"}
              </Typography>
              <TextField
                label={translateFront("Note")}
                value={paymentNote}
                variant="standard"
                size="small"
                placeholder={translateFront("Write Any Note You Want To Add")}
                fullWidth
                multiline
                minRows={3}
                onChange={(e) => setPaymentNote(e.target.value)}
              />
            </>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
