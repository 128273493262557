import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {
  Slide,
  TextField,
  Grid,
  Tooltip,
  IconButton,
  Typography,
  Box,
  Autocomplete,
  createFilterOptions,
  Input,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import LoadingBox from "../../../../components/misc/Loading";

import { getNotification } from "../../../../reduxToolkit/features/adminPanel/notification";

// Icons
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import PercentIcon from "@mui/icons-material/Percent";
import LooksOneIcon from "@mui/icons-material/LooksOne";

import { useEffect } from "react";
import { countries } from "../../../../utils/countryList";
import { useDispatch, useSelector } from "react-redux";
import {
  createPaymentMethod,
  getAllPaymentMethods,
  uploadBarcodeImg,
} from "../../../../reduxToolkit/features/adminPanel/payment";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { PF } from "../../../../config";
import { useRef } from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

// Limit options to show in autocomplete
const OPTIONS_LIMIT = 5;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) => {
  return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
};

export default function AddPaymentMethods({
  lang,
  langId,
  addPaymentDlg,
  setAddPaymentDlg,
  paymentListEn,
}) {
  const dispatch = useDispatch();
  const addAccountRef = useRef();

  const [loadingAdd, setLoadingAdd] = useState(false);
  const [enVariant, setEnVariant] = useState("");
  const [enVariantSelected, setEnVariantSelected] = useState(false);
  const [methodName, setMethodName] = useState("");
  // const [prevBarcodeImg, setPrevBarcodeImg] = useState("");
  // const [barcodeImg, setBarcodeImg] = useState("");
  const [paymentSummery, setPaymentSummery] = useState([""]);
  const [paymentSummeryErrorBuffer, setPaymentSummeryErrorBuffer] = useState(
    []
  );

  const [accountArr, setAccountArr] = useState([]);

  const [countryArr, setCountryArr] = useState([]);
  const [countryLoading, setCountryLoading] = useState(false);

  const [transactionFee, setTransactionFee] = useState(0);
  const [transactionUnit, setTransactionUnit] = useState(0);

  //   local Store
  const localAdminData = () => {
    let adminData = JSON.parse(localStorage.getItem("adminLoginInfo"));
    return adminData;
  };

  // Functions
  const handleEnVariant = (e, newValue) => {
    setEnVariant(newValue._id);
    setEnVariantSelected(true);
    setCountryLoading(true);
    // setMethodName(newValue.methodName);
    setPaymentSummery([...newValue.paymentSummery]);
    // if (newValue.barcodeImg && newValue.barcodeImg !== "")
    setAccountArr([...newValue.accountArr]);
    setTransactionFee(newValue.transactionFee);
    setTransactionUnit(newValue.transactionUnit);
    let arr = [...paymentSummeryErrorBuffer];
    newValue.paymentSummery.forEach((d, i) => {
      arr.push(i);
    });
    setPaymentSummeryErrorBuffer(arr);
    setTimeout(() => {
      setCountryArr([...newValue.countryArr]);
      setCountryLoading(false);
    }, 100);
  };

  const handleAddPaymentClose = () => {
    setAddPaymentDlg(false);
  };

  const handlePaymentSummery = (e, index) => {
    setEnVariantSelected(false);
    let arr0 = [...paymentSummeryErrorBuffer];
    arr0 = arr0.filter((a, i) => a !== index);
    setPaymentSummeryErrorBuffer(arr0);
    let arr = [...paymentSummery];
    arr[index] = e.target.value;
    setPaymentSummery(arr);
  };

  const handleAddPaymentSummery = () => {
    let arr = [...paymentSummery];
    arr.push("");
    setPaymentSummery(arr);
  };

  const handleRemovePaymentSummery = (index) => {
    let arr = [...paymentSummery];
    arr = arr.filter((a, i) => i !== index);
    setPaymentSummery(arr);
  };

  //  ! Account
  const handleAddAccount = () => {
    let arr = [...accountArr];
    arr.push({
      barcodeImg: "",
      accountTitle: "",
      accountDetails: "",
      active: false,
    });
    setAccountArr(arr);
    setTimeout(() => {
      addAccountRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }, 250);
  };

  const handleRemoveAccount = (index) => {
    let arr = [...accountArr];
    arr = arr.filter((a, i) => i !== index);
    setAccountArr(arr);
  };

  const handleAllCountrySelect = () => {
    setCountryLoading(true);
    setCountryArr(countries);
    setTimeout(() => {
      setCountryLoading(false);
    }, 100);
  };

  const handleAddPayment = () => {
    setLoadingAdd(true);
    let obj = {
      langId,
      methodName,
      paymentSummery,
      countryArr,
    };
    // Admin Data
    obj.creatorId = localAdminData()._id;
    if (enVariant !== "") obj.paymentDetectorId = enVariant;

    // if (prevBarcodeImg !== "") {
    //   obj.barcodeImg = prevBarcodeImg;
    // } else if (barcodeImg !== "") {
    //   const data = new FormData();
    //   const ext = barcodeImg.name.split(".");
    //   const filename =
    //     Date.now() +
    //     "-" +
    //     methodName.replace(/\s+/g, "") +
    //     "-admin-pic." +
    //     ext.slice(-1);
    //   data.append("name", filename);
    //   data.append("barcodeImg", barcodeImg);
    //   obj.barcodeImg = filename;
    //   dispatch(uploadBarcodeImg(data));
    // }

    let arr = [...accountArr];
    arr.forEach((a, i) => {
      if (a.barcodeImg && a.barcodeImg.name) {
        const data = new FormData();
        const ext = a.barcodeImg.name.split(".");
        const filename =
          Date.now() +
          "-" +
          methodName.replace(/\s+/g, "") +
          "-admin-pic." +
          ext.slice(-1);
        data.append("name", filename);
        data.append("barcodeImg", a.barcodeImg);
        arr[i].barcodeImg = filename;
        dispatch(uploadBarcodeImg(data));
      }
    });
    obj.accountArr = arr;
    obj.transactionFee = transactionFee;
    obj.transactionUnit = transactionUnit;
    if (localAdminData()._id) dispatch(createPaymentMethod(obj));
    setTimeout(() => {
      setLoadingAdd(false);
      dispatch(getAllPaymentMethods());
      dispatch(getNotification());
      handleAddPaymentClose();
    }, 1000);
  };

  // useEffect
  useEffect(() => {
    setCountryLoading(true);
    setTimeout(() => {
      setCountryLoading(false);
    }, 100);
  }, []);

  // ! Translate
  // Store
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate

  return (
    <>
      <Dialog
        open={addPaymentDlg}
        TransitionComponent={Transition}
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle>{translate0("Add Payment Method")}</DialogTitle>
        <DialogContent>
          {lang !== "en" && (
            <Autocomplete
              onChange={(e, newValue) => handleEnVariant(e, newValue)}
              disablePortal
              options={paymentListEn}
              filterOptions={filterOptions}
              getOptionLabel={(option) => option.methodName}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option.methodName}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={translate0("English Variant")}
                  variant="standard"
                  size="small"
                  fullWidth
                />
              )}
            />
          )}
          <TextField
            required
            label={translate0("Payment Method")}
            value={methodName}
            onChange={(e) => {
              setMethodName(e.target.value);
              setEnVariantSelected(false);
            }}
            variant="standard"
            fullWidth
            disabled={lang !== "en" && enVariant === ""}
            error={enVariantSelected}
          />

          <Button
            variant="outlined"
            size="small"
            fullWidth
            sx={{ mt: 1, pointerEvents: "none" }}
          >
            {translate0("Payment Instruction")}
          </Button>
          {paymentSummery.map((x, i) => (
            <Grid container spacing={0} alignItems="center" key={i}>
              <Grid item sx={{ flex: 1 }}>
                <TextField
                  label={`${i + 1}.${translate0("Payment Instruction")}`}
                  value={paymentSummery[i]}
                  onChange={(e) => handlePaymentSummery(e, i)}
                  variant="standard"
                  fullWidth
                  disabled={lang !== "en" && enVariant === ""}
                  error={paymentSummeryErrorBuffer.includes(i) ? true : false}
                />
              </Grid>

              <Grid item>
                <Grid container spacing={0}>
                  {paymentSummery.length > 1 && (
                    <Grid item>
                      <Tooltip
                        arrow
                        placement="left"
                        title={translate0("Remove")}
                        sx={{ mt: 2 }}
                      >
                        <IconButton
                          size="small"
                          onClick={() => handleRemovePaymentSummery(i)}
                          disabled={lang !== "en" && enVariant === ""}
                        >
                          <DeleteIcon
                            color={
                              lang !== "en" && enVariant === ""
                                ? "inherit"
                                : "error"
                            }
                          />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
                  {paymentSummery.length === i + 1 && (
                    <Grid item>
                      <Tooltip
                        arrow
                        placement="right"
                        title={translate0("Add")}
                        sx={{ mt: 2 }}
                      >
                        <IconButton
                          size="small"
                          onClick={handleAddPaymentSummery}
                          disabled={lang !== "en" && enVariant === ""}
                        >
                          <AddCircleIcon
                            color={
                              lang !== "en" && enVariant === ""
                                ? "inherit"
                                : "success"
                            }
                          />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ))}
          <Button
            variant="outlined"
            size="small"
            fullWidth
            sx={{ mt: 1, pointerEvents: "none" }}
          >
            {translate0("Choose Countries")}
          </Button>
          <Grid
            container
            spacing={0}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item sx={{ flex: 1 }}>
              {countryLoading ? (
                <></>
              ) : (
                <Autocomplete
                  id="country-select-demo"
                  multiple
                  options={countries}
                  onChange={(e, newValue) => setCountryArr(newValue)}
                  defaultValue={countryArr}
                  autoHighlight
                  getOptionLabel={(option) => option.label}
                  disabled={lang !== "en" && enVariant === ""}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      {option.label} ({option.code})
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label={translate0("Choose a country")}
                      variant="standard"
                      //   inputProps={{
                      //     ...params.inputProps,
                      //     autoComplete: "new-password", // disable autocomplete and autofill
                      //   }}
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                size="small"
                onClick={handleAllCountrySelect}
                sx={{ mt: 1 }}
              >
                {translate0("All")}
              </Button>
            </Grid>
          </Grid>
          {/* Transaction  */}
          <Grid
            container
            spacing={0}
            sx={{ mt: 1 }}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item sx={{ flex: 1 }}>
              <TextField
                label={translate0("Transaction Fee")}
                value={transactionFee}
                size="small"
                variant="standard"
                fullWidth
                onChange={(e) => {
                  // const result = e.target.value.replace(/\D/g, "");
                  const result = e.target.value;
                  setTransactionFee(result);
                }}
              />
            </Grid>
            <Grid item>
              <Tooltip arrow placement="top" title={translate0("Percent")}>
                <IconButton size="small" onClick={() => setTransactionUnit(0)}>
                  <PercentIcon
                    color={transactionUnit === 0 ? "primary" : "inherit"}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip arrow placement="top" title={translate0("Flat")}>
                <IconButton size="small" onClick={() => setTransactionUnit(1)}>
                  <LooksOneIcon
                    color={transactionUnit === 1 ? "primary" : "inherit"}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Button
            variant="outlined"
            size="small"
            fullWidth
            sx={{ mt: 1, pointerEvents: "none" }}
          >
            {translate0("Account Details")}
          </Button>

          {accountArr.map((x, i) => (
            <Box
              sx={{
                border: ".1rem solid navy",
                borderRadius: ".25rem",
                m: 0.5,
                p: 1,
              }}
            >
              <Button
                variant="contained"
                size="small"
                color="error"
                fullWidth
                sx={{ my: 1 }}
                onClick={() => handleRemoveAccount(i)}
              >
                {translate0("Remove Account")}
              </Button>
              <Grid
                container
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  {loadingAdd ? (
                    <LoadingBox w={"100%"} />
                  ) : (
                    // <></>
                    <LazyLoadImage
                      effect="blur"
                      src={
                        x.barcodeImg && x.barcodeImg.name
                          ? URL.createObjectURL(x.barcodeImg)
                          : x.barcodeImg && x.barcodeImg !== ""
                          ? PF + "/adminPanel/barcode/" + x.barcodeImg
                          : PF + "/adminPanel/medicines/no-medicine-image.jpg"
                      }
                      alt={methodName}
                      style={{
                        objectFit: "cover",
                        width: "200px",
                      }}
                    />
                  )}
                  <Typography variant="body2" align="center" color="secondary">
                    {translate0("Recommended")}: 🖼️(800x600)
                  </Typography>
                </Grid>
                <Grid item position="relative">
                  <Box
                    sx={{
                      pointerEvents:
                        lang !== "en" && enVariant === "" && "none",
                    }}
                  >
                    <label htmlFor={`icon-button-file-${i}`}>
                      <Input
                        accept="image/*"
                        id={`icon-button-file-${i}`}
                        required
                        type="file"
                        sx={{ display: "none" }}
                        onChange={(e) => {
                          // setPrevBarcodeImg("");
                          // setBarcodeImg(e.target.files[0]);
                          let arr = [...accountArr];
                          // arr[i].prevBarcodeImg = "";
                          // arr[i].barcodeImg = e.target.files[0];
                          let obj = { ...arr[i] };
                          obj.barcodeImg = e.target.files[0];
                          arr[i] = obj;
                          setAccountArr(arr);
                        }}
                      />
                      <IconButton
                        color="primary"
                        aria-label={`upload-picture-${i}`}
                        component="span"
                        disabled={lang !== "en" && enVariant === ""}
                      >
                        <PhotoCamera
                          color={
                            lang !== "en" && enVariant === ""
                              ? "inherit"
                              : "primary"
                          }
                        />
                      </IconButton>
                    </label>
                  </Box>
                </Grid>
                <Grid item>
                  {x.barcodeImg && (
                    <Button
                      variant="contained"
                      size="small"
                      color="error"
                      onClick={() => {
                        let arr = [...accountArr];
                        // arr[i].prevBarcodeImg = "";
                        let obj = { ...arr[i] };
                        obj.barcodeImg = "";
                        arr[i] = obj;
                        setAccountArr(arr);
                      }}
                    >
                      {translate0("Remove Image")}
                    </Button>
                  )}
                </Grid>
                {/* {noAdminPic && <Alert severity="error">Upload Image</Alert>} */}
              </Grid>
              <TextField
                label={translate0("Account Title")}
                value={accountArr[i].accountTitle}
                onChange={(e) => {
                  let arr = [...accountArr];
                  let obj = { ...arr[i] };
                  obj.accountTitle = e.target.value;
                  arr[i] = obj;
                  setAccountArr(arr);
                }}
                variant="standard"
                fullWidth
                size="small"
              />
              <TextField
                label={translate0("Account Details")}
                multiline
                minRows={3}
                value={accountArr[i].accountDetails}
                onChange={(e) => {
                  let arr = [...accountArr];
                  // arr[i].accountDetails = e.target.value;
                  let obj = { ...arr[i] };
                  obj.accountDetails = e.target.value;
                  arr[i] = obj;
                  setAccountArr(arr);
                }}
                variant="standard"
                fullWidth
                size="small"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={accountArr[i].active}
                    label="Active"
                    onChange={(e) => {
                      let arr = [...accountArr];
                      let obj = { ...arr[i] };
                      obj.active = e.target.checked;
                      arr[i] = obj;
                      setAccountArr(arr);
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label={translate0("Active")}
              />
            </Box>
          ))}
          <Button
            variant="contained"
            size="small"
            fullWidth
            color="success"
            sx={{ mt: 1 }}
            onClick={() => handleAddAccount()}
          >
            {translate0("Add Account")}
          </Button>
          <Box ref={addAccountRef} sx={{ mt: "20px" }}></Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddPaymentClose} color="primary">
            {translate0("Cancel")}
          </Button>
          <LoadingButton
            onClick={handleAddPayment}
            color="success"
            loading={loadingAdd}
            disabled={(lang !== "en" && enVariant === "") || methodName === ""}
          >
            {translate0("Add")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
