import {
  Alert,
  Autocomplete,
  Box,
  Button,
  createFilterOptions,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddLangDialog from "../dialog/AddLangDialog";
// Icon
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

import { PF } from "../../../../config";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

// Media Query
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import AddCompanyDialog from "../dialog/AddCompanyDialog";
import {
  deleteCompany,
  editGenCompany,
  getCompanies,
} from "../../../../reduxToolkit/features/adminPanel/medicine";
import { copyToClipBoard } from "../../../../hooks/copyToClipBoard";
import { getNotification } from "../../../../reduxToolkit/features/adminPanel/notification";
import EditCompanyDialog from "../dialog/EditCompanyDialog";
import LoadingBox from "../../../../components/misc/Loading";

// Limit options to show in autocomplete
const OPTIONS_LIMIT = 5;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) => {
  return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
};

export default function ManageGenericCompany() {
  // Media Query
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  // use States
  const [loadingMain, setLoadingMain] = useState(false);
  const [lang, setLang] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [companyListEn, setCompanyListEn] = useState([]);
  const [searchCompanyList, setSearchCompanyList] = useState([]);
  const [searchingCompanyList, setSearchingCompanyList] = useState([]);

  // Dialog
  const [addLangDlg, setAddLangDlg] = useState(false);
  const [addCompanyDlg, setAddCompanyDlg] = useState(false);
  const [editCompanyDlg, setEditCompanyDlg] = useState(false);
  const [editCompanyBuffer, setEditCompanyBuffer] = useState(false);

  // LocalData
  const localLangData = JSON.parse(localStorage.getItem("lang"));
  const localAdminData = JSON.parse(localStorage.getItem("adminLoginInfo"));
  // store
  const { langs, activeLang } = useSelector((state) => state.misc);
  const { companies } = useSelector((state) => state.medicine);

  // UseEffect
  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setLang(activeLang.langCode);
    } else localLangData && setLang(localLangData.langCode);
  }, [activeLang]);

  const dispatch = useDispatch();
  // import company data
  useEffect(() => {
    dispatch(getCompanies());
  }, []);

  // set companyData
  useEffect(() => {
    setLoadingMain(true);
    if (lang !== "" && companies && companies.length > 0) {
      const M = companies.find((m) => m.lang === lang);
      const MEn = companies.find((m) => m.lang === "en");
      let searchArr = [];
      if (M) {
        let arr = [...M.data];
        if (lang !== "en") {
          arr.map((x, i) => {
            if (MEn) {
              const C = MEn.data.find(
                (c) => c.fixedCompanyId === x.companyDetectorId
              );
              if (C) {
                let obj = { ...arr[i] };
                obj["companyDetector"] = C.companyName;
                arr[i] = obj;
              }
            }
            return 0;
          });
        }
        setCompanyList(arr);
        searchArr = [...searchArr, ...arr];
      } else {
        setCompanyList([]);
      }
      if (MEn) {
        if (lang !== "en") {
          let arr2 = [];
          M &&
            M.data.map((x, i) => {
              const C = MEn.data.find(
                (c) => c.fixedCompanyId === x.companyDetectorId
              );
              if (C) {
                arr2.push(C);
              }
              return 0;
            });
          setCompanyListEn([...MEn.data]);
          searchArr = [...searchArr, ...arr2];
        }
      } else {
        setCompanyListEn([]);
      }

      var arr = [...searchArr];
      if (arr.length > 0) {
        const unique = [
          ...new Map(arr.map((item) => [item["_id"], item])).values(),
        ];
        setSearchCompanyList(unique);
      } else setSearchCompanyList([]);
    }
    setTimeout(() => {
      setLoadingMain(false);
    }, 1000);
  }, [companies, lang]);

  // Functions
  const handleChangeLang = (e) => {
    setLang(e.target.value);
  };

  // handle Search
  const handleSearch = (e, newValue) => {
    if (lang !== "en") {
      const arr = [];
      newValue.map((x, i) => {
        if (!x.companyDetectorId) {
          const C = companyList.find(
            (c) => c.companyDetectorId === x.fixedCompanyId
          );
          arr.push(C);
        } else arr.push(x);
        return 0;
      });
      var searchArr = [...arr];
      const unique = [
        ...new Map(searchArr.map((item) => [item["_id"], item])).values(),
      ];
      setSearchingCompanyList(unique);
    } else {
      setSearchingCompanyList(newValue);
    }
  };

  // Handle Add Company Dialog
  const handleAddCompany = () => {
    setAddCompanyDlg(true);
  };

  // Handle Company Delete
  const handleCompanyDelete = (x) => {
    dispatch(
      deleteCompany({
        id: x._id,
        creatorId: localAdminData._id,
        lang,
        companyName: x.companyName,
        companyLogo: x.companyLogo,
      })
    );
    setTimeout(() => {
      setSearchCompanyList([]);
      dispatch(getCompanies());
      dispatch(getNotification());
    }, 1000);
  };

  // Handle Company Edit
  const handleCompanyEdit = (x) => {
    setEditCompanyBuffer(x);
    setEditCompanyDlg(true);
  };

  // ! translate
  const [siteLang, setSiteLang] = useState("");
  // Local Store
  // Store
  const [langSettings1, setLangSettings1] = useState([]);
  const [langSettings2, setLangSettings2] = useState([]);
  const title1 = "Manage Generic Company";
  const title2 = "Misc Words";

  useEffect(() => {
    if (langs && langs.length > 0 && siteLang !== "") {
      const L = langs.find((l) => l.langCode === siteLang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      const A = arr.find((l) => l._title === title1);
      if (A) setLangSettings1(A.data);
      const B = arr.find((l) => l._title === title2);
      if (B) setLangSettings2(B.data);
    }
  }, [siteLang, langs]);

  const translate1 = (String) => translation(String, title1, 1);
  const translate2 = (String) => translation(String, title2, 2);

  const translation = (String, title, index) => {
    let translation = "";
    let settings = [];
    if (index === 1) {
      settings = langSettings1;
    } else if (index === 2) {
      settings = langSettings2;
    }
    const T = settings.find((ls) => ls.title === String);
    if (T && T.value) translation = T.value;
    else {
      let arr = [];
      if (langs && langs.length > 0 && siteLang !== "") {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.settings && LEn.settings.length > 0) arr = LEn.settings;
        const S = arr.find((l) => l._title === title);
        if (S) {
          const T = S.data.find((ls) => ls.title === String);
          if (T && T.value) translation = T.value;
        }
      }
    }
    return translation;
  };

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setSiteLang(activeLang.langCode);
    } else {
      if (localLangData) {
        setSiteLang(localLangData.langCode);
      }
    }
  }, [activeLang]);
  // ! translate

  return (
    <>
      {langs.length > 0 && (
        <>
          <Box>
            <Grid
              container
              spacing={1}
              justifyContent="space-between"
              alignItems="center"
            >
              {/* Select Language */}
              <Grid item xs={12} md={4}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={2} sm={1} md={1.5}>
                    <IconButton>
                      <AddCircleOutlineOutlinedIcon
                        color="info"
                        size="large"
                        onClick={() => setAddLangDlg(true)}
                      />
                    </IconButton>
                    <AddLangDialog
                      addLangDlg={addLangDlg}
                      setAddLangDlg={setAddLangDlg}
                      setLang={setLang}
                    />
                  </Grid>
                  <Grid item xs={10} sm={4} md={5}>
                    <FormControl fullWidth>
                      <Select
                        value={lang}
                        label="language"
                        variant="standard"
                        onChange={handleChangeLang}
                        size="small"
                      >
                        {langs.map((x, i) => (
                          <MenuItem key={i} value={x.langCode} dense>
                            <img
                              alt={x.langCode}
                              src={`https://flagcdn.com/w20/${
                                x.langCode === "en" ? "gb" : x.langCode
                              }.png`}
                            />
                            <Button color="inherit" size="small">
                              {x.langName}
                            </Button>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* Add Comapny */}
                  <Grid item xs={12} sm={7} md={4.5}>
                    <Button
                      variant="contained"
                      size="small"
                      color="error"
                      fullWidth
                      onClick={handleAddCompany}
                    >
                      {translate1("Add Company")}
                    </Button>
                    <AddCompanyDialog
                      lang={lang}
                      addCompanyDlg={addCompanyDlg}
                      setAddCompanyDlg={setAddCompanyDlg}
                      companyListEn={companyListEn}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* Search */}
              <Grid item xs={12} md={8} sx={{ mt: { md: -2, xs: 0 } }}>
                <Autocomplete
                  multiple
                  onChange={(e, newValue) => handleSearch(e, newValue)}
                  disablePortal
                  options={searchCompanyList}
                  filterOptions={filterOptions}
                  getOptionLabel={(option) => option.companyName}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        src={
                          option.companyLogo.includes("http")
                            ? option.companyLogo
                            : PF + "adminPanel/companies/" + option.companyLogo
                        }
                        alt="admins"
                        style={{
                          objectFit: "contain",
                          width: "40px",
                          height: "30px",
                        }}
                      />
                      {option.companyName}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={translate1("Search") + "..."}
                      variant="standard"
                      size="small"
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </Grid>
            {/* Company List */}
            <Button
              size="small"
              variant="contained"
              fullWidth
              sx={{ my: 1, pointerEvents: "none" }}
            >
              {translate1("Companies")} ({companyList.length})
            </Button>
          </Box>
          {loadingMain ? (
            <LoadingBox w={"100%"} />
          ) : companyList.length < 1 ? (
            <Alert
              severity="warning"
              sx={{ display: "flex", justifyContent: "center" }}
            >
              {translate1("No Data Available")}
            </Alert>
          ) : (
            <>
              <Box
                sx={{
                  maxHeight: { xs: "60vh", sm: "57vh" },
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                <Grid container spacing={1}>
                  {(searchingCompanyList.length > 0
                    ? searchingCompanyList
                    : companyList
                  ).map((x, i) => (
                    <Grid
                      item
                      key={i}
                      sx={{ borderBottom: "1px solid lightblue" }}
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Grid
                        container
                        spacing={1}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        {/* Serial */}
                        <Grid item xs={12} sm={12} order={1}>
                          <Button
                            variant="outlined"
                            size="small"
                            fullWidth
                            color="warning"
                          >
                            {" "}
                            {i + 1}
                          </Button>
                        </Grid>
                        {/* Actions */}
                        <Grid item xs={12} sm={3} order={4}>
                          <Grid container spacing={0} justifyContent="center">
                            <Grid item>
                              <Tooltip title="Edit" placement="top" arrow>
                                <IconButton
                                  size="small"
                                  onClick={() => handleCompanyEdit(x)}
                                >
                                  <EditRoundedIcon color="info" />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                            <Grid item>
                              <Tooltip title="Delete" placement="top" arrow>
                                <IconButton
                                  size="small"
                                  onClick={() => handleCompanyDelete(x)}
                                >
                                  <DeleteRoundedIcon color="error" />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </Grid>
                        {/* Image */}
                        <Grid item xs={12} sm={3} order={2}>
                          <Box
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <LazyLoadImage
                              effect="blur"
                              src={
                                x.companyLogo.includes("http")
                                  ? x.companyLogo
                                  : PF + "adminPanel/companies/" + x.companyLogo
                              }
                              alt={x.companyName}
                              title={x.companyName}
                              style={{
                                // height: !smUp ? "100%" : "50px",
                                width: !smUp ? "160px" : "100px",
                                objectFit: "contain",
                                borderRadius: ".25rem",
                              }}
                            />
                          </Box>
                        </Grid>
                        {/* Name */}
                        <Grid item xs={12} sm={4} order={3}>
                          <Grid container spacing={0} flexDirection="column">
                            <Grid item>
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                                align="center"
                              >
                                {x.companyName}{" "}
                              </Typography>
                            </Grid>
                            <Grid item>
                              {x.companyDetector && (
                                <Button
                                  variant="outlined"
                                  size="small"
                                  color="warning"
                                  fullWidth
                                  sx={{ mb: 1 }}
                                  onClick={() =>
                                    copyToClipBoard(dispatch, x.companyDetector)
                                  }
                                >
                                  {x.companyDetector}
                                </Button>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </>
          )}
          <EditCompanyDialog
            editCompanyBuffer={editCompanyBuffer}
            editCompanyDlg={editCompanyDlg}
            setEditCompanyDlg={setEditCompanyDlg}
            lang={lang}
            companyListEn={companyListEn}
          />
        </>
      )}
    </>
  );
}
