import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import React, { useRef, useState } from "react";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { PF } from "../../../../config";

// Icons
import InfoIcon from "@mui/icons-material/Info";
import LaunchIcon from "@mui/icons-material/Launch";

import { useDispatch, useSelector } from "react-redux";
import {
  getBlogs,
  removeBlog,
} from "../../../../reduxToolkit/features/adminPanel/blog";
import { getNotification } from "../../../../reduxToolkit/features/adminPanel/notification";
import LoadingButton from "@mui/lab/LoadingButton";
import { getCreated, getExactTime } from "../../../../hooks/getCreatedData";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import { makeUrlFriendly } from "../../../../hooks/makeUrlFriendly";

export default function BlogCard({
  x,
  frontEnd,
  viewMode,
  setViewMode,
  setEditDlg,
  setEditBuffer,
  blogListEn,
  lang,
  fromHome,
}) {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  // Responsive
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  const blogRef = useRef();
  const [loading, setLoading] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [openBlogDlg, setOpenBlogDlg] = useState(false);

  // Local Store
  const localAdminData = () => {
    let adminData = JSON.parse(localStorage.getItem("adminLoginInfo"));
    return adminData;
  };

  //   Store
  const { admins } = useSelector((state) => state.admin);

  const getEnInfo = (detectorId) => {
    let obj = {
      title: "",
    };
    const B = blogListEn.find((b) => b._id === detectorId);
    if (B) obj = { ...B };
    return obj;
  };

  const getCreatorName = (id) => {
    let name = "";
    const A = admins.find((a) => a._id === id);
    if (A) name = A.adminName;
    return name;
  };

  const handleRemoveBlog = () => {
    setLoading(true);
    if (localAdminData()._id)
      dispatch(
        removeBlog({
          _id: x._id,
          title: x.title,
          creatorId: localAdminData()._id,
        })
      );
    setTimeout(() => {
      dispatch(getNotification());
      dispatch(getBlogs());
      setLoading(false);
    }, 1000);
  };

  const handleBlogOpen = () => {
    if (fromHome) {
      setOpenBlogDlg(true);
    }
  };

  //! Translate
  // Store
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate
  return (
    <>
      {x && (
        <Card sx={{ height: fromHome && 400 }}>
          <Box ref={blogRef}></Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              height: 200,
              cursor: fromHome && "pointer",
            }}
            onClick={() => handleBlogOpen()}
          >
            <LazyLoadImage
              effect="blur"
              src={
                x.blogImg && x.blogImg !== ""
                  ? PF + "/adminPanel/blog/" + x.blogImg
                  : PF + "/adminPanel/medicines/no-medicine-image.jpg"
              }
              alt={x.title}
              style={{
                objectFit: "cover",
                width: "100%",
              }}
            />
          </Box>
          <CardContent>
            <Tooltip
              arrow
              placement="top-start"
              title={getEnInfo(x.blogDetectorId).title}
            >
              <Typography gutterBottom variant="h5" component="div">
                {x.title}
                {/* {lang !== "en" && mdUp && ( */}
                <IconButton
                  size="small"
                  onClick={() =>
                    window.open(
                      `/blog/${makeUrlFriendly(
                        lang !== "en"
                          ? getEnInfo(x.blogDetectorId).title
                          : x.title
                      )}`,
                      "_blank"
                    )
                  }
                >
                  <LaunchIcon color="info" />
                </IconButton>
                {/* )} */}
              </Typography>
            </Tooltip>
            {!frontEnd && (
              <>
                <Typography variant="subtitle1" color="primary">
                  {translate0("Author")}:{" "}
                  {getCreatorName(x.creatorId).toUpperCase()}
                </Typography>
                <Typography variant="body2" color="error">
                  {translate0("Published")}: {getCreated(x.createdAt)}
                </Typography>
                <Typography variant="body2" color="warning">
                  {translate0("Updated")}: {getCreated(x.updatedAt)}
                </Typography>
              </>
            )}
            <Typography variant="body2" color="text.secondary">
              {frontEnd ? (
                <>
                  {showMore ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${x.content}`,
                      }}
                    ></div>
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${
                          x.content.length > 150
                            ? x.content.substring(0, 150) + "..."
                            : x.content
                        }`,
                      }}
                    ></div>
                  )}
                  {x.content.length > 150 && (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      {!fromHome && (
                        <Button
                          variant="contained"
                          size="small"
                          color="primary"
                          onClick={() => {
                            setShowMore(!showMore);
                            let arr = [...viewMode];
                            if (!showMore) arr.push(x._id);
                            else arr = arr.filter((a) => a !== x._id);
                            setViewMode(arr);
                            setTimeout(
                              () =>
                                blogRef.current.scrollIntoView({
                                  behavior: "smooth",
                                }),
                              250
                            );
                          }}
                        >
                          {showMore
                            ? frontEnd
                              ? translateFront("Show Less")
                              : translate0("Show Less")
                            : frontEnd
                            ? translateFront("More")
                            : translate0("More")}
                        </Button>
                      )}
                    </Box>
                  )}
                </>
              ) : (
                <>
                  <Box
                    sx={{
                      maxHeight: { xs: "40vh", sm: "20vh" },
                      overflowY: "auto",
                      overflowX: "hidden",
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${x.content}`,
                      }}
                    ></div>
                  </Box>
                </>
              )}
            </Typography>
          </CardContent>
          {!frontEnd && (
            <CardActions sx={{ justifyContent: "flex-end" }}>
              <Button
                size="small"
                color="primary"
                onClick={() => {
                  setEditDlg(true);
                  setEditBuffer(x);
                }}
              >
                {translate0("Edit")}
              </Button>
              <LoadingButton
                loading={loading}
                size="small"
                color="error"
                onClick={handleRemoveBlog}
              >
                {translate0("Delete")}
              </LoadingButton>
            </CardActions>
          )}
        </Card>
      )}
      {openBlogDlg && (
        <Dialog open={openBlogDlg} aria-labelledby={"blog-dialog"}>
          <DialogTitle>
            <Typography gutterBottom variant="h5" component="div">
              {x.title}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <div
              dangerouslySetInnerHTML={{
                __html: `${x.content}`,
              }}
            ></div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenBlogDlg(false)} color="error">
              {translate0("Close")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
