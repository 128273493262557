import {
  Box,
  Card,
  CardContent,
  Container,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useDispatch, useSelector } from "react-redux";
import BreadcrumbsTemplate from "../components/misc/BreadcrumbsTemplate";
import LoadingBox from "../components/misc/Loading";
import { PF } from "../config";
import { getProductNameFromURL } from "../hooks/getProductNameFromURL";
import { makeUrlFriendly } from "../hooks/makeUrlFriendly";
import { getBlogs } from "../reduxToolkit/features/adminPanel/blog";
import Seo from "../utils/Seo";

export default function SingleBlog() {
  const dispatch = useDispatch();

  const [loadingMain, setLoadingMain] = useState(true);
  const [lang, setLang] = useState("en");
  const [langId, setLangId] = useState("");
  const [contentSettings, setContentSettings] = useState([]);
  const [blogListEn, setBlogListEn] = useState([]);

  const [singleBlogInfo, setSingleBlogInfo] = useState({});

  // Store
  const { langs } = useSelector((state) => state.misc);
  const { blogs } = useSelector((state) => state.blog);
  const { activeLangFront } = useSelector((state) => state.miscFront);
  const { webSettings } = useSelector((state) => state.webSettings);
  const { managedContentSettings } = useSelector(
    (state) => state.managedContentSettings
  );

  const getEnInfo = (detectorId) => {
    let obj = {
      title: "",
    };
    const B = blogListEn.find((b) => b._id === detectorId);
    if (B) obj = { ...B };
    return obj;
  };

  // Functions
  const getLangId = (langCode) => {
    let id = "";
    const L = langs.find((l) => l.langCode === langCode);
    if (L) id = L._id;
    return id;
  };

  useEffect(() => {
    scroll();
  }, []);
  const scroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    setLoadingMain(true);
    if (!(blogs && blogs.length > 0)) dispatch(getBlogs());
    // dispatch(getAdmins());
    setTimeout(() => {
      setLoadingMain(false);
    }, 1000);
  }, []);

  // Set Language
  useEffect(() => {
    if (activeLangFront) {
      setLang(activeLangFront.langCode);
      setLangId(activeLangFront._id);
    }
  }, [activeLangFront]);

  // Get single Blog
  useEffect(() => {
    if (
      langId !== "" &&
      blogs &&
      blogs.length > 0 &&
      langs &&
      langs.length > 0
    ) {
      const BEn = blogs.filter((b) => b.langId === getLangId("en"));
      // const nonEnBlogs = blogs.filter((b) => b.langId === getLangId("en"));
      if (BEn) {
        setBlogListEn(BEn);
        const B = BEn.find(
          (b) => makeUrlFriendly(b.title) === getProductNameFromURL()
        );
        let singleBlog = {};
        if (B && B._id) {
          if (lang !== "en") {
            const nonEnB = blogs.find(
              (b) => b.blogDetectorId === B._id && b.langId === langId
            );
            if (nonEnB) singleBlog = { ...nonEnB };
          } else singleBlog = { ...B };
        }
        setSingleBlogInfo(singleBlog);
      }
    }
  }, [lang, langId, blogs, langs]);

  // Set Settings
  useEffect(() => {
    if (managedContentSettings && managedContentSettings.length > 0) {
      const M = managedContentSettings.find((m) => m.langId === langId);
      if (M) setContentSettings(M);
    }
  }, [langId, managedContentSettings]);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate

  return (
    <>
      <BreadcrumbsTemplate
        b2={translateFront("Blog")}
        l2="/blog"
        b3={singleBlogInfo.title}
      />
      {loadingMain ? (
        <LoadingBox w={"100%"} h={"50vh"} />
      ) : (
        <Container>
          <Seo
            title={`${singleBlogInfo.metaTitle} | MFW`}
            description={singleBlogInfo.metaDesc}
            name={
              (contentSettings.siteSeoSettings &&
                contentSettings.siteSeoSettings[0].companyName) ||
              "Elifesaving Medicine"
            }
            type="article"
            url={window.location.href || ""}
            imageUrl={
              singleBlogInfo.blogImg && singleBlogInfo.blogImg !== ""
                ? PF + "/adminPanel/blog/" + singleBlogInfo.blogImg
                : "https://mfw.com.bd/wp-content/uploads/2021/05/getImage.png"
            }
            langCode={lang}
          />
          <Card sx={{ mt: 1 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
                // maxHeight: 400,
                cursor: "pointer",
              }}
            >
              <LazyLoadImage
                effect="blur"
                src={
                  singleBlogInfo.blogImg && singleBlogInfo.blogImg !== ""
                    ? PF + "/adminPanel/blog/" + singleBlogInfo.blogImg
                    : PF + "/adminPanel/medicines/no-medicine-image.jpg"
                }
                alt={singleBlogInfo.title}
                style={{
                  objectFit: "cover",
                  // width: "100%",
                  height: 300,
                }}
              />
            </Box>
            <CardContent>
              <Tooltip
                arrow
                placement="top-start"
                title={getEnInfo(singleBlogInfo.blogDetectorId).title}
              >
                <Typography gutterBottom variant="h5" component="div">
                  {singleBlogInfo.title}
                </Typography>
              </Tooltip>

              <Typography variant="body2" color="text.secondary">
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${singleBlogInfo.content}`,
                  }}
                ></div>
              </Typography>
            </CardContent>
          </Card>
        </Container>
      )}
    </>
  );
}
