import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ManageReview from "../../review/components/ManageReview";

export default function ReviewReport({ startDate, endDate }) {
  const [reviewList, setReviewList] = useState([]);
  // ? Store
  const { reviews } = useSelector((state) => state.customerOnProduct);

  useEffect(() => {
    if (reviews && reviews.length && startDate && endDate) {
      var resultProductData = reviews.filter((a) => {
        var date = new Date(a.createdAt);
        return date >= startDate && date <= endDate;
      });
      setReviewList(resultProductData);
    }
  }, []);
  return (
    <>
      {startDate && endDate && (
        <>
          <ManageReview reviewArr={reviewList} />
        </>
      )}
    </>
  );
}
