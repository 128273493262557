import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { emailVerify, registerUser } from "../../reduxToolkit/features/auth";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CaptchaTikTakToe from "../misc/CaptchaTikTakToe";
import PhoneInput from "react-phone-number-input";
import { IconButton, InputAdornment, Stack, Tooltip } from "@mui/material";
// Icons
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import AddIcon from "@mui/icons-material/Add";
import LoadingButton from "@mui/lab/LoadingButton";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";

import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";
import { useEffect } from "react";
import Policy from "../Policy/Policy";
import { useRef } from "react";
import { useState } from "react";
import { alpha, styled } from "@mui/material/styles";
import { pink } from "@mui/material/colors";
import Switch from "@mui/material/Switch";
import AddSocialDialog from "../AddSocialDialog";
import EmailVerificationDialog from "../EmailVerificationDialog";
const theme = createTheme();

const GreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: pink[600],
    "&:hover": {
      backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: pink[600],
  },
}));

export default function SignUp({
  setLoginStatus,
  setSignInOpen,
  setSignUpOpen,
}) {
  // Refs
  const nameRef = useRef();
  const emailRef = useRef();
  // Use State
  const [formEnable, setFormEnable] = React.useState(false);
  const [formLoading, setFormLoading] = React.useState(false);
  const [captchaDlg, setCaptchaDlg] = React.useState(false);
  const [name, setName] = React.useState("");
  const [nameFailed, setNameFailed] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [invalidEmail, setInvalidEmail] = React.useState(false);
  const [emailFailed, setEmailFailed] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [showPass, setShowPass] = React.useState(false);
  const [confirmPass, setConfirmPass] = React.useState("");
  const [passMatch, setPassMatch] = React.useState(false);
  const [showConfirmPass, setShowConfirmPass] = React.useState(false);
  const [gender, setGender] = React.useState(false);
  const [address, setAddress] = React.useState("");

  const [viewSocial, setViewSocial] = useState(false);
  const [whatsApp, setWhatsApp] = React.useState("");
  const [weChat, setWeChat] = React.useState("");
  const [skype, setSkype] = React.useState("");
  const [fb, setFb] = React.useState("");
  const [additionalSocialMedia, setAdditionalSocialMedia] = React.useState([]);

  const [phone, setPhone] = React.useState("");
  const [subscribe, setSubscribe] = React.useState(true);
  const [agree, setAgree] = React.useState(true);
  const [policyOpen, setPolicyOpen] = React.useState(false);
  const [clickedPolicy, setClickedPolicy] = React.useState("");
  const [vCode, setVCode] = useState("");

  // Dialogs
  const [addSocialLoading, setAddSocialLoading] = React.useState(false);
  const [addSocialDlg, setAddSocialDlg] = React.useState(false);

  const [verifyDlg, setVerifyDlg] = useState(false);
  const [verifyCodeInput, setVerifyCodeInput] = useState("");

  // Count down
  const [countDownStart, setCountDownStart] = React.useState("");

  // Store
  const { authData, emailResponse } = useSelector((state) => state.auth);
  const { webSettings } = useSelector((state) => state.webSettings);

  // ! Lang Id Assign
  const [langId, setLangId] = useState("");
  const { activeLangFront } = useSelector((state) => state.miscFront);
  // Set Language
  useEffect(() => {
    if (activeLangFront) setLangId(activeLangFront._id);
  }, [activeLangFront]);
  // ! Lang Id Assign

  // Use Effects

  // Password Match
  useEffect(() => {
    let errorCounter = 0;
    [...Array(confirmPass.length)].map((x, i) => {
      confirmPass[i] !== password[i] && errorCounter++;
      return 0;
    });
    if (confirmPass !== "" && errorCounter > 0) {
      setPassMatch(false);
    } else setPassMatch(true);
  }, [confirmPass, password]);

  // check Email Response
  useEffect(() => {
    if (emailResponse.message) {
      if (!emailResponse.failed) setVerifyDlg(true);
      setFormLoading(false);
    }
  }, [emailResponse]);
  // Check Response
  useEffect(() => {
    if (authData.failed) {
      setFormLoading(false);
      if (authData.usernameFailed) {
        setNameFailed(true);
        nameRef.current.scrollIntoView({ behavior: "smooth" });
      }
      if (authData.emailFailed) {
        setEmailFailed(true);

        emailRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (authData.customerInfo) {
      setFormLoading(false);
      setLoginStatus(true);
      setSignUpOpen(false);
    }
  }, [authData]);

  // Form Enable
  useEffect(() => {
    if (
      name !== "" &&
      email !== "" &&
      !invalidEmail &&
      password !== "" &&
      passMatch &&
      address !== "" &&
      phone !== "" &&
      agree
    )
      setFormEnable(true);
    else setFormEnable(false);
  }, [address, agree, email, invalidEmail, name, passMatch, password, phone]);

  // Check verification Code
  useEffect(() => {
    if (vCode === Number(verifyCodeInput)) {
      setVerifyDlg(false);
      setFormLoading(true);
      setVerifyCodeInput("");
      const obj = {
        name,
        email,
        password,
        address,
        phone,
        subscribed: subscribe,
      };
      gender ? (obj.gender = "female") : (obj.gender = "male");
      let socialArr = [...additionalSocialMedia];
      let socialObj = {};
      if (whatsApp !== "") {
        socialObj.socialName = "whatsApp";
        socialObj.socialLink = whatsApp;
        socialArr.push(socialObj);
      }
      socialObj = {};
      if (weChat !== "") {
        socialObj.socialName = "weChat";
        socialObj.socialLink = weChat;
        socialArr.push(socialObj);
      }
      socialObj = {};
      if (skype !== "") {
        socialObj.socialName = "skype";
        socialObj.socialLink = skype;
        socialArr.push(socialObj);
      }
      socialObj = {};
      if (fb !== "") {
        socialObj.socialName = "facebook";
        socialObj.socialLink = fb;
        socialArr.push(socialObj);
      }
      obj.socialArr = socialArr;
      obj.langId = langId;
      dispatch(registerUser(obj));
    }
  }, [vCode, verifyCodeInput]);

  // Functions
  const dispatch = useDispatch();
  const handleSubmit = (event) => {
    event.preventDefault();
    setCaptchaDlg(true);
  };

  const registerUserFunc = () => {
    setCountDownStart(
      new Date().getTime() + (webSettings.verificationInterval || 1) * 60000
    );
    let v = Math.floor(Math.random() * (999999 - 100000 + 1) + 100000);
    setVCode(v);
    v = v * 3875412698;
    dispatch(emailVerify({ v, email, langId }));
    setFormLoading(true);
  };

  const goToSignIn = () => {
    setSignUpOpen(false);
    setSignInOpen(true);
  };

  const handleAddSocial = () => {
    setAddSocialLoading(true);
    setTimeout(() => {
      setAddSocialDlg(true);
      setAddSocialLoading(false);
    }, 1000);
  };

  const removeSocial = (index) => {
    let arr = [...additionalSocialMedia];
    arr.splice(index, 1);
    setAdditionalSocialMedia(arr);
  };

  const handlePolicy = (policyName) => {
    setClickedPolicy(policyName);
    setPolicyOpen(true);
  };

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {translateFront("Sign up")}
          </Typography>
          <Box component="form" sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} ref={nameRef}>
                <TextField
                  autoComplete="name"
                  required
                  fullWidth
                  label={translateFront("Name")}
                  autoFocus
                  size="small"
                  error={nameFailed ? true : false}
                  value={name}
                  helperText={
                    nameFailed && (
                      <Typography variant="body2" color="error">
                        {translateFront("Name Already Exist ")}
                      </Typography>
                    )
                  }
                  onChange={(e) => {
                    setName(e.target.value);
                    setNameFailed(false);
                  }}
                />
              </Grid>

              <Grid item xs={12} ref={emailRef}>
                <TextField
                  required
                  fullWidth
                  label={translateFront("Email Address")}
                  error={
                    email !== "" && (invalidEmail || emailFailed ? true : false)
                  }
                  autoComplete="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setInvalidEmail(false);
                    setEmailFailed(false);
                  }}
                  size="small"
                  onBlur={() => {
                    email !== "" &&
                      (email.includes("@")
                        ? setInvalidEmail(false)
                        : setInvalidEmail(true));
                  }}
                  helperText={
                    email !== "" && invalidEmail
                      ? translateFront("Invalid Email")
                      : emailFailed && (
                          <Typography variant="body2" color="error">
                            {translateFront("Email Already Exist")}
                          </Typography>
                        )
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label={translateFront("Password")}
                  type={showPass ? "text" : "password"}
                  autoComplete="new-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          size="small"
                          onClick={() => setShowPass(!showPass)}
                        >
                          {showPass ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="confirm-password"
                  label={translateFront("Confirm Password")}
                  type={showConfirmPass ? "text" : "password"}
                  autoComplete="confirm-password"
                  size="small"
                  value={confirmPass}
                  onChange={(e) => setConfirmPass(e.target.value)}
                  onBlur={
                    password !== "" &&
                    (password === confirmPass
                      ? () => setPassMatch(true)
                      : () => setPassMatch(false))
                  }
                  error={password !== "" && (passMatch ? false : true)}
                  helperText={
                    password !== "" &&
                    !passMatch &&
                    translateFront("Password Not Matched")
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          size="small"
                          onClick={() => setShowConfirmPass(!showConfirmPass)}
                        >
                          {showConfirmPass ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={0}></Grid>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  sx={{ mb: { xs: 1 } }}
                >
                  <Button
                    variant="text"
                    size="small"
                    color="success"
                    startIcon={<MaleIcon />}
                    sx={{ pointerEvents: "none" }}
                  >
                    {" "}
                    {translateFront("Male")}{" "}
                  </Button>
                  <Tooltip
                    arrow
                    title={translateFront("Choose Your Gender")}
                    placement="top"
                  >
                    <GreenSwitch
                      checked={gender}
                      onChange={(e) => setGender(e.target.checked)}
                    />
                  </Tooltip>
                  <Button
                    variant="text"
                    size="small"
                    color="secondary"
                    startIcon={<FemaleIcon />}
                    sx={{ pointerEvents: "none" }}
                  >
                    {translateFront("Female")}{" "}
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  multiline
                  minRows={3}
                  fullWidth
                  label={translateFront("Address")}
                  type="text"
                  autoComplete="address"
                  size="small"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  size="small"
                  endIcon={<ArrowDropDownCircleIcon />}
                  variant={viewSocial ? "outlined" : "contained"}
                  fullWidth
                  sx={{ display: "flex", justifyContent: "space-between" }}
                  onClick={() => setViewSocial(!viewSocial)}
                >
                  {translateFront("Social Media")}
                </Button>
              </Grid>
              {viewSocial && (
                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <Grid item xs={12}>
                      <TextField
                        label={translateFront("WhatsApp")}
                        sx={{ mb: 1 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <WhatsAppIcon sx={{ color: "#4ccf7a" }} />
                            </InputAdornment>
                          ),
                        }}
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={whatsApp}
                        onChange={(e) => setWhatsApp(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label={translateFront("WeChat")}
                        sx={{ mb: 1 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img
                                src="https://img.icons8.com/doodle/48/000000/weixing.png"
                                alt="WeChat"
                                style={{ height: "1.5rem" }}
                              />
                            </InputAdornment>
                          ),
                        }}
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={weChat}
                        onChange={(e) => setWeChat(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label={translateFront("Skype")}
                        sx={{ mb: 1 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img
                                src="https://img.icons8.com/color/48/000000/skype--v1.png"
                                alt="Skype"
                                style={{ height: "1.5rem" }}
                              />
                            </InputAdornment>
                          ),
                        }}
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={skype}
                        onChange={(e) => setSkype(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label={translateFront("Facebook")}
                        sx={{ mb: 1 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FacebookIcon sx={{ color: "navy" }} />
                            </InputAdornment>
                          ),
                        }}
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={fb}
                        onChange={(e) => setFb(e.target.value)}
                      />
                    </Grid>
                    {additionalSocialMedia.length > 0 &&
                      additionalSocialMedia.map((x, i) => (
                        <Grid item xs={12} key={i}>
                          <Grid container spacing={0}>
                            <Grid item sx={{ flexGrow: 2 }}>
                              <TextField
                                label={x.socialName}
                                value={x.socialLink}
                                disabled
                                sx={{ mb: 1 }}
                                size="small"
                                fullWidth
                              />
                            </Grid>
                            <Grid item>
                              <Tooltip
                                arrow
                                title={translateFront("Delete")}
                                placement="top"
                              >
                                <IconButton
                                  size="small"
                                  onClick={() => removeSocial(i)}
                                >
                                  <NotInterestedIcon color="error" />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    <Grid item xs={12}>
                      <LoadingButton
                        fullWidth
                        variant="contained"
                        color="success"
                        size="small"
                        endIcon={<AddIcon />}
                        onClick={handleAddSocial}
                        loading={addSocialLoading}
                        loadingPosition="end"
                      >
                        {translateFront("Add Social Media")}
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography variant="body2" color="inherit">
                  {translateFront("Phone")} *
                </Typography>
                <PhoneInput
                  placeholder={translateFront("Enter Phone Number")}
                  international
                  value={phone}
                  defaultCountry="US"
                  onChange={setPhone}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={subscribe}
                      defaultChecked
                      color="warning"
                      onChange={(e) => setSubscribe(e.target.checked)}
                    />
                  }
                  label={
                    <Typography variant="body2">
                      {translateFront(
                        "I want to receive inspiration, marketing promotions and updates via email."
                      )}
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={agree}
                      defaultChecked
                      color="primary"
                      onChange={(e) => setAgree(e.target.checked)}
                    />
                  }
                  label={
                    <Typography variant="body2">
                      {translateFront("I agree with the ")}
                      <Button
                        variant="text"
                        size="small"
                        sx={{ mt: -0.5 }}
                        onClick={() => handlePolicy("Terms & Condition")}
                      >
                        {translateFront("Terms & Conditions")}
                      </Button>
                      {translateFront("&")}
                      <Button
                        variant="text"
                        size="small"
                        sx={{ mt: -0.5 }}
                        onClick={() => handlePolicy("Privacy Policy")}
                      >
                        {translateFront("Privacy Policy")}
                      </Button>{" "}
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
            <LoadingButton
              onClick={handleSubmit}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={!formEnable ? true : false}
              loading={formLoading ? true : false}
            >
              {translateFront("Sign up")}
            </LoadingButton>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Typography
                  variant="body2"
                  sx={{ textDecoration: "underline", cursor: "pointer" }}
                  color="primary"
                  onClick={goToSignIn}
                >
                  {translateFront("Already have an account? Sign in")}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <Dialog
        open={captchaDlg}
        onClose={() => setCaptchaDlg(false)}
        aria-labelledby={"register-user"}
      >
        <DialogContent>
          <CaptchaTikTakToe
            loginFunc={registerUserFunc}
            setCaptchaDlg={setCaptchaDlg}
          />
        </DialogContent>
      </Dialog>
      {/* Add Social Dialog */}
      <AddSocialDialog
        additionalSocialMedia={additionalSocialMedia}
        setAdditionalSocialMedia={setAdditionalSocialMedia}
        addSocialDlg={addSocialDlg}
        setAddSocialDlg={setAddSocialDlg}
      />
      {/* Policy */}
      <Policy
        policyOpen={policyOpen}
        setPolicyOpen={setPolicyOpen}
        policyName={clickedPolicy}
      />
      {/* Verification dialog */}
      {verifyDlg && (
        <EmailVerificationDialog
          verifyDlg={verifyDlg}
          setVerifyDlg={setVerifyDlg}
          verifyCodeInput={verifyCodeInput}
          setVerifyCodeInput={setVerifyCodeInput}
          countDownStart={countDownStart}
          setCountDownStart={setCountDownStart}
          logic={registerUserFunc}
        />
      )}
    </ThemeProvider>
  );
}
