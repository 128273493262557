import { configureStore } from "@reduxjs/toolkit";
import auth from "./features/auth";
import miscFront from "./features/miscFront";
import compare from "./features/frontend/compare";
import wishlist from "./features/frontend/wishlist";
import checkout from "./features/frontend/checkout";
import cart from "./features/frontend/cart";
import customerOnProduct from "./features/customerOnProduct";
import notification from "./features/adminPanel/notification";
import admin from "./features/adminPanel/admin";
import role from "./features/adminPanel/role";
import misc from "./features/adminPanel/misc";
import assignPrice from "./features/adminPanel/assignPrice";
import adminUrl from "./features/adminPanel/adminUrl";
import medicine from "./features/adminPanel/medicine";
import supplier from "./features/adminPanel/supplier";
import review from "./features/adminPanel/review";
import order from "./features/adminPanel/order";
import delivery from "./features/adminPanel/delivery";
import payment from "./features/adminPanel/payment";
import blog from "./features/adminPanel/blog";
import webSettings from "./features/adminPanel/webSettings";
import expense from "./features/adminPanel/expense";
import managedContentSettings from "./features/adminPanel/managedContentSettings";
const store = configureStore({
  reducer: {
    // frontend
    auth,
    miscFront,
    customerOnProduct,
    compare,
    wishlist,
    checkout,
    cart,
    // Backend
    notification,
    admin,
    role,
    misc,
    adminUrl,
    medicine,
    supplier,
    review,
    blog,
    webSettings,
    managedContentSettings,
    assignPrice,
    delivery,
    payment,
    order,
    expense,
  },
});

export default store;
