import {
  Autocomplete,
  Box,
  Button,
  createFilterOptions,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Tooltip,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import React, { useEffect } from "react";
import AddLangDialog from "../../medicines/dialog/AddLangDialog";

// Icons
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ExpandMore } from "@mui/icons-material";
import { getComplainScopes } from "../../../../reduxToolkit/features/customerOnProduct";
import LoadingBox from "../../../../components/misc/Loading";
import {
  createComplainScopes,
  removeComplainScopes,
  updateComplainScopes,
} from "../../../../reduxToolkit/features/adminPanel/misc";
import { getNotification } from "../../../../reduxToolkit/features/adminPanel/notification";
import LoadingButton from "@mui/lab/LoadingButton";

export default function ReviewSettings() {
  const dispatch = useDispatch();

  const [scopeLoading, setScopeLoading] = useState(false);
  const [complainScopeList, setComplainScopeList] = useState([]);
  const [scopeName, setScopeName] = useState("");

  const [modifyComplainScope, setModifyComplainScope] = useState([]);

  const [addLangDlg, setAddLangDlg] = useState(false);
  const [lang, setLang] = useState("");
  const [langId, setLangId] = useState("");
  const [addScopeSec, setAddScopeSec] = useState(false);

  const [editScopeLoading, setEditScopeLoading] = useState(false);
  const [editScopeList, setEditScopeList] = useState("");
  const [editScopeBuffer, setEditScopeBuffer] = useState({});
  const [editScopeName, setEditScopeName] = useState("");

  // Dialogs
  const [removeScopeLoading, setRemoveScopeLoading] = useState(false);
  const [removeScopeDlg, setRemoveScopeDlg] = useState(false);
  const [removeScopeBuffer, setRemoveScopeBuffer] = useState({});

  // LocalData
  const localLangData = JSON.parse(localStorage.getItem("lang"));
  const localAdminData = JSON.parse(localStorage.getItem("adminLoginInfo"));

  // store
  const { langs, activeLang } = useSelector((state) => state.misc);
  const { complainScopes } = useSelector((state) => state.customerOnProduct);

  // Functions
  const handleChangeLang = (e) => {
    setLang(e.target.value);
    const L = langs.find((l) => l.langCode === e.target.value);
    if (L) setLangId(L._id);
  };

  const handleCreateScope = () => {
    setScopeLoading(true);
    const L = langs.find((l) => l.langCode === lang);
    let obj = {
      langId: L._id,
      scopeName,
      createdBy: localAdminData._id,
    };
    dispatch(createComplainScopes(obj));
    setTimeout(() => {
      setScopeLoading(false);
      setAddScopeSec(false);
      setScopeName("");
      dispatch(getComplainScopes());
      dispatch(getNotification());
    }, 1000);
  };

  const handleModifyScopeAdd = (id) => {
    // let arr = [...modifyComplainScope];
    let arr = [];
    arr.push(id);
    handleEditSecClose();
    setModifyComplainScope(arr);
  };

  const handleModifyScopeRemove = (id) => {
    let arr = [...modifyComplainScope];
    arr = arr.filter((a) => a !== id);
    setModifyComplainScope(arr);
  };

  const handleEditSecClose = () => {
    setEditScopeList("");
    setEditScopeBuffer({});
    setEditScopeName("");
  };

  const handleEditScope = () => {
    setEditScopeLoading(true);
    dispatch(
      updateComplainScopes({
        id: editScopeBuffer._id,
        creatorId: localAdminData._id,
        prevScopeName: editScopeBuffer.scopeName,
        newScopeName: editScopeName,
      })
    );
    setTimeout(() => {
      setEditScopeLoading(false);
      handleModifyScopeRemove(editScopeBuffer._id);
      handleEditSecClose();
      dispatch(getComplainScopes());
      dispatch(getNotification());
    }, 1000);
  };

  const handleRemoveScope = (id) => {
    setRemoveScopeLoading(true);
    dispatch(
      removeComplainScopes({
        id,
        creatorId: localAdminData._id,
        scopeName: removeScopeBuffer.scopeName,
      })
    );
    setTimeout(() => {
      setRemoveScopeLoading(false);
      setRemoveScopeBuffer({});
      setRemoveScopeDlg(false);
      setAddScopeSec(false);
      dispatch(getComplainScopes());
      dispatch(getNotification());
    }, 1000);
  };

  // UseEffect
  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setLang(activeLang.langCode);
      setLangId(activeLang._id);
    } else if (localLangData) {
      setLang(localLangData.langCode);
      setLangId(localLangData._id);
    }
  }, [activeLang]);

  //   Get complain Scopes
  useEffect(() => {
    dispatch(getComplainScopes());
  }, []);

  //   set Complain Scopes
  useEffect(() => {
    if (complainScopes && complainScopes.length > 0) {
      let arr = complainScopes.filter((a) => a.langId === langId);
      setComplainScopeList(arr);
    }
  }, [complainScopes, langId]);

  // Set Edit Scope Name
  useEffect(() => {
    if (editScopeBuffer.scopeName) setEditScopeName(editScopeBuffer.scopeName);
  }, [editScopeBuffer]);

  // ! translate
  const [siteLang, setSiteLang] = useState("");
  const [langSettings1, setLangSettings1] = useState([]);
  const [langSettings2, setLangSettings2] = useState([]);
  const title1 = "Review Settings";
  const title2 = "Misc Words";

  useEffect(() => {
    if (langs && langs.length > 0 && siteLang !== "") {
      const L = langs.find((l) => l.langCode === siteLang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      const A = arr.find((l) => l._title === title1);
      if (A) setLangSettings1(A.data);
      const B = arr.find((l) => l._title === title2);
      if (B) setLangSettings2(B.data);
    }
  }, [siteLang, langs]);

  const translate1 = (String) => translation(String, title1, 1);
  const translate2 = (String) => translation(String, title2, 2);

  const translation = (String, title, index) => {
    let translation = "";
    let settings = [];
    if (index === 1) {
      settings = langSettings1;
    } else if (index === 2) {
      settings = langSettings2;
    }
    const T = settings.find((ls) => ls.title === String);
    if (T && T.value) translation = T.value;
    else {
      let arr = [];
      if (langs && langs.length > 0 && siteLang !== "") {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.settings && LEn.settings.length > 0) arr = LEn.settings;
        const S = arr.find((l) => l._title === title);
        if (S) {
          const T = S.data.find((ls) => ls.title === String);
          if (T && T.value) translation = T.value;
        }
      }
    }
    return translation;
  };

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setSiteLang(activeLang.langCode);
    } else {
      if (localLangData) {
        setSiteLang(localLangData.langCode);
      }
    }
  }, [activeLang]);
  // ! translate

  return (
    <>
      <Box>
        <Grid
          container
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
        >
          {/* Select Language */}
          <Grid item xs={12} md={2}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={2} sm={1} md={1.5}>
                <IconButton size="small" onClick={() => setAddLangDlg(true)}>
                  <AddCircleOutlineOutlinedIcon color="info" />
                </IconButton>
                <AddLangDialog
                  addLangDlg={addLangDlg}
                  setAddLangDlg={setAddLangDlg}
                  setLang={setLang}
                />
              </Grid>
              <Grid item xs={10} sm={4} md={10.5}>
                <FormControl fullWidth>
                  <Select
                    value={lang}
                    label="language"
                    variant="standard"
                    onChange={handleChangeLang}
                    size="small"
                  >
                    {langs.map((x, i) => (
                      <MenuItem key={i} value={x.langCode} dense>
                        <img
                          alt={x.langCode}
                          src={`https://flagcdn.com/w20/${
                            x.langCode === "en" ? "gb" : x.langCode
                          }.png`}
                        />
                        <Button color="inherit" size="small">
                          {x.langName}
                        </Button>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={10}>
            {/* Review Settings */}
            <Button
              size="small"
              variant="outlined"
              fullWidth
              sx={{ my: 1, pointerEvents: "none" }}
            >
              {translate1("Review Settings")}
            </Button>
          </Grid>
        </Grid>
      </Box>

      {scopeLoading ? (
        <LoadingBox w={"100%"} />
      ) : !addScopeSec ? (
        <Grid container spacing={1}>
          <Grid item xs={12} sm={4} md={6}>
            <Typography variant="h6">{translate1("Complain Scope")}</Typography>
          </Grid>
          <Grid item xs={12} sm={8} md={6}>
            <Button
              variant="contained"
              size="small"
              fullWidth
              color="error"
              onClick={() => setAddScopeSec(true)}
            >
              {translate1("Add Scope")}
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={0} justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={10.5} md={11} xl={11.5}>
            <TextField
              label={translate1("Scope Name")}
              value={scopeName}
              onChange={(e) => setScopeName(e.target.value)}
              size="small"
              variant="standard"
              fullWidth
              autoFocus
              onKeyPress={(e) => e.key === "Enter" && handleCreateScope()}
            />
          </Grid>
          <Grid item xs={2} sm={0.75} md={0.5} xl={0.25}>
            <Tooltip arrow placement="top" title={translate2("Add")}>
              <IconButton
                size="small"
                disabled={scopeName.length > 0 ? false : true}
                onClick={handleCreateScope}
              >
                <AddCircleOutlineOutlinedIcon
                  color={scopeName.length > 0 ? "success" : "inherit"}
                />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={2} sm={0.75} md={0.5} xl={0.25}>
            <Tooltip arrow placement="top" title={translate2("Close")}>
              <IconButton size="small" onClick={() => setAddScopeSec(false)}>
                <CloseIcon color="error" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={1} sx={{ mt: 1 }}>
        {complainScopeList.length < 1 ? (
          <Grid item xs={12}>
            <Alert severity="warning" fullWidth>
              {translate2("No Data Available")}
            </Alert>
          </Grid>
        ) : editScopeLoading ? (
          <LoadingBox w={"100%"} />
        ) : (
          complainScopeList.map(
            (x, i) =>
              x.langId !== "" && (
                <Grid item key={i} xs={12} sm={6} md={4} xl={2}>
                  {!modifyComplainScope.includes(x._id) ? (
                    <Button
                      variant="outlined"
                      size="medium"
                      color={i % 2 === 0 ? "success" : "primary"}
                      onClick={() => handleModifyScopeAdd(x._id)}
                      fullWidth
                    >
                      {x.scopeName}
                    </Button>
                  ) : editScopeList.includes(x._id) ? (
                    <Grid container>
                      <Grid item xs={9} sm={10.5} md={11} xl={11.5}>
                        <TextField
                          label={translate1("Scope Name")}
                          value={editScopeName}
                          onChange={(e) => setEditScopeName(e.target.value)}
                          onKeyPress={(e) =>
                            e.key === "Enter" && handleEditScope()
                          }
                          fullWidth
                          variant="outlined"
                          size="small"
                          autoFocus
                          error={editScopeName.length < 1 ? true : false}
                        />
                      </Grid>
                      <Grid item xs={1.5} sm={0.75} md={0.5} xl={0.25}>
                        <Tooltip arrow placement="top" title="Confirm">
                          <IconButton
                            size="small"
                            disabled={editScopeName.length < 1}
                            onClick={() => handleEditScope()}
                          >
                            <CheckCircleIcon
                              color={
                                editScopeName.length < 1 ? "inherit" : "success"
                              }
                            />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={1.5} sm={0.75} md={0.5} xl={0.25}>
                        <Tooltip
                          arrow
                          placement="top"
                          title={translate2("Close")}
                        >
                          <IconButton size="small" onClick={handleEditSecClose}>
                            <HighlightOffIcon color="error" />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  ) : (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Tooltip arrow placement="top" title={translate2("Edit")}>
                        <IconButton
                          size="small"
                          onClick={() => {
                            setEditScopeBuffer(x);
                            setEditScopeList(x._id);
                          }}
                        >
                          <EditIcon color="info" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        arrow
                        placement="top"
                        title={translate1("Remove")}
                      >
                        <IconButton
                          size="small"
                          onClick={() => {
                            setRemoveScopeDlg(true);
                            setRemoveScopeBuffer(x);
                          }}
                        >
                          <DeleteIcon color="error" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        arrow
                        placement="top"
                        title={translate2("Close")}
                      >
                        <IconButton
                          size="small"
                          onClick={() => handleModifyScopeRemove(x._id)}
                        >
                          <CloseIcon color="error" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                </Grid>
              )
          )
        )}
      </Grid>
      <Dialog
        open={removeScopeDlg}
        onClose={() => {
          setRemoveScopeDlg(false);
          setRemoveScopeBuffer({});
        }}
      >
        <DialogTitle>
          {translate1("Do you really want to delete")} "
          <span style={{ color: "red" }}>{removeScopeBuffer.scopeName}</span>"
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setRemoveScopeDlg(false);
              setRemoveScopeBuffer({});
            }}
            color="primary"
          >
            {translate2("Close")}
          </Button>
          <LoadingButton
            onClick={() => handleRemoveScope(removeScopeBuffer._id)}
            color="error"
            loading={removeScopeLoading}
          >
            {translate2("Confirm")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
