import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCustomers } from "../../../reduxToolkit/features/auth";
import Typography from "@mui/material/Typography";
import LoadingBox from "../../../components/misc/Loading";
import {
  Autocomplete,
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Collapse,
  createFilterOptions,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Alert,
  Slide,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Toolbar,
  AppBar,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PF } from "../../../config";
import { getCreated, getExactTime } from "../../../hooks/getCreatedData";
// Icons
import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import BlockIcon from "@mui/icons-material/Block";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import MessageIcon from "@mui/icons-material/Message";
import CheckIcon from "@mui/icons-material/Check";
import InfoIcon from "@mui/icons-material/Info";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import MedicationIcon from "@mui/icons-material/Medication";

// Media Query
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { copyToClipBoard } from "../../../hooks/copyToClipBoard";
import {
  blockCustomer,
  removeCustomer,
} from "../../../reduxToolkit/features/adminPanel/misc";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  getAllOrders,
  getAllPrescription,
  getAskAllPriceList,
} from "../../../reduxToolkit/features/frontend/checkout";
import AskPriceListByCustomerDialog from "../dialogs/AskPriceListByCustomerDialog";
import { getAllCartInfo } from "../../../reduxToolkit/features/frontend/cart";
import ViewCartByCustomer from "../dialogs/ViewCartByCustomer";
import OrderCard from "../../adminPanel/order/OrderCard";
import OrderHistoryFromCustomer from "../dialogs/OrderHistoryFromCustomer";
import { getAllOrderBackEnd } from "../../../reduxToolkit/features/adminPanel/order";
import ViewCustomerPrescription from "../dialogs/ViewCustomerPrescription";

// Limit options to show in autocomplete
const OPTIONS_LIMIT = 5;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) => {
  return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

// Styled Avatar
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

export default function CustomerList() {
  // Media Query
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  // useStates
  const [loadingMain, setLoadingMain] = useState(false);
  const [lang, setLang] = useState("");
  const [langId, setLangId] = useState("");

  const [customerList, setCustomerList] = useState([]);
  const [searchingCustomerList, setSearchingCustomerList] = useState([]);
  const [filteredCustomerList, setFilteredCustomerList] = useState([]);

  const [filter, setFilter] = useState(0);

  const [expanded, setExpanded] = React.useState([]);
  // const [subBlockMenu, setSubBlockMenu] = useState(false);
  const [subBlockMenuBuffer, setSubBlockMenuBuffer] = useState([]);

  // Expanded Section
  const [additionalInfoShowArr, setAdditionalInfoShowArr] = useState([]);
  const [clientInfoArr, setClientInfoArr] = useState([]);
  const [socialMediaArr, setSocialMediaArr] = useState([]);
  const [loginInfoArr, setLoginInfoArr] = useState([]);
  const [removeCustomerLoading, setRemoveCustomerLoading] = useState(false);

  // Searchbox
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchFromNoti, setSearchFromNoti] = useState([]);

  // Dialog
  const [showOrderHistoryDlg, setShowOrderHistoryDlg] = useState(false);
  const [showOrderHistoryBuffer, setShowOrderHistoryBuffer] = useState([]);

  const [showCartDlg, setShowCartDlg] = useState(false);
  const [showCartBuffer, setShowCartBuffer] = useState([]);

  const [removeCustomerDlg, setRemoveCustomerDlg] = useState(false);
  const [removeCustomerBuffer, setRemoveCustomerBuffer] = useState("");

  const [blockLoading, setBlockLoading] = useState(false);
  const [blockReasonBuffer, setBlockReasonBuffer] = useState({});
  const [blockReason, setBlockReason] = useState("");
  const [blockReasonDlg, setBlockReasonDlg] = useState(false);

  const [unblockMessageDlg, setUnblockMessageDlg] = useState(false);
  const [unblockMessageBuffer, setUnblockMessageBuffer] = useState([]);

  const [askPriceDlg, setAskPriceDlg] = useState(false);
  const [askPriceList, setAskPriceList] = useState([]);
  const [askPriceBuffer, setAskPriceBuffer] = useState("");
  const [askPriceCustomerId, setAskPriceCustomerId] = useState("");

  const [prescriptionDlg, setPrescriptionDlg] = useState(false);
  const [prescriptionBuffer, setPrescriptionBuffer] = useState({});

  // LocalData
  const localLangData = JSON.parse(localStorage.getItem("lang"));

  // store
  const { customers } = useSelector((state) => state.auth);
  const { askAllPriceList } = useSelector((state) => state.checkout);
  const { assignPriceResponse } = useSelector((state) => state.assignPrice);
  const { unassignPriceResponse, cancelAskPriceResponse } = useSelector(
    (state) => state.assignPrice
  );
  const { allCarts } = useSelector((state) => state.cart);
  const { orders } = useSelector((state) => state.checkout);

  const { langs, activeLang } = useSelector((state) => state.misc);

  // Use Effect
  // get Customers
  const dispatch = useDispatch();

  // Functions
  const goToLocation = (index) => {
    if (customerList[index].geoInfo) {
      let lat = customerList[index].geoInfo.latitude.toString();
      let latAfterDeci = lat.split(".")[1];
      if (latAfterDeci.length < 6) {
        [...Array(6 - latAfterDeci.length)].map(() => {
          lat = lat + "9";
        });
      }
      let long = customerList[index].geoInfo.longitude.toString();
      let longAfterDeci = long.split(".")[1];
      if (longAfterDeci.length < 6) {
        [...Array(6 - longAfterDeci.length)].map(() => {
          long = long + "9";
        });
      }
      window.open(
        `https://www.google.com/maps/search/?api=1&query=
      ${lat}%2c${long}`,
        "_blank"
      );
    }
  };

  const getSocialLink = (platform, x) => {
    let link = "";
    if (x) {
      const sl = x.socialArr.find((o) => o.socialName === platform);
      if (sl) link = sl.socialLink;
    }

    return link;
  };

  const getAdditionalSocialLink = (x) => {
    let arr = [];
    if (x && x.socialArr) {
      arr = x.socialArr.filter(
        (o) =>
          o.socialName !== "whatsApp" &&
          o.socialName !== "weChat" &&
          o.socialName !== "skype" &&
          o.socialName !== "facebook"
      );
    }
    return arr;
  };

  const handleExpandClick = (index) => {
    let arr = [...expanded];
    arr[index] = !arr[index];
    setExpanded(arr);
  };

  const handleSearch = (e, newValue) => {
    setSearchingCustomerList(newValue);
  };

  const handleCustomerRemove = (customerId) => {
    if (customerList && customerList.length > 0) {
      const C = customerList.find((c) => c._id === customerId);
      if (C) {
        setRemoveCustomerBuffer(C);
        setRemoveCustomerDlg(true);
      }
    }
  };

  const getTotalLoginTime = (d, loginStatus, logoutTime, loginTime) => {
    if (loginStatus) {
      if (logoutTime)
        d = d + new Date().getTime() - new Date(logoutTime).getTime();
      else d = d + new Date().getTime() - new Date(loginTime).getTime();
    }
    // Time calculations for hours, minutes and seconds
    var days = Math.floor(d / (1000 * 3600 * 24));
    var hours = Math.floor((d % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((d % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((d % (1000 * 60)) / 1000);
    return days + "d : " + hours + "h : " + minutes + "m : " + seconds + "s ";
  };

  const showAdditionalInfo = (index) => {
    let arr = [...additionalInfoShowArr];
    if (arr.includes(index)) arr = arr.filter((i) => i !== index);
    else arr.push(index);
    setAdditionalInfoShowArr(arr);
  };

  const showClientInfo = (index) => {
    let arr = [...clientInfoArr];
    if (arr.includes(index)) arr = arr.filter((i) => i !== index);
    else arr.push(index);
    setClientInfoArr(arr);
  };

  const showSocialMedia = (index) => {
    let arr = [...socialMediaArr];
    if (arr.includes(index)) arr = arr.filter((i) => i !== index);
    else arr.push(index);
    setSocialMediaArr(arr);
  };

  const showLoginInfo = (index) => {
    let arr = [...loginInfoArr];
    if (arr.includes(index)) arr = arr.filter((i) => i !== index);
    else arr.push(index);
    setLoginInfoArr(arr);
  };

  const handleBlockConfirm = (id, block) => {
    if (block) {
      dispatch(blockCustomer({ id }));
      setTimeout(() => {
        dispatch(getAllCustomers());
        // setSubBlockMenu(false);
        // setSubBlockMenuBuffer(
        //   [...subBlockMenuBuffer].filter((fi) => fi !== id)
        // );
        setSubBlockMenuBuffer([]);
      }, 1000);
    } else {
      setBlockReasonDlg(true);
      setBlockReasonBuffer({ id });
    }
  };

  const handleBlockConfirmWithReason = (msg) => {
    setBlockLoading(true);
    if (blockReasonBuffer.id) {
      dispatch(
        blockCustomer({
          id: blockReasonBuffer.id,
          message: msg ? msg : blockReason,
        })
      );
    }
    setTimeout(() => {
      setBlockLoading(false);
      setBlockReasonDlg(false);
      setBlockReason("");
      dispatch(getAllCustomers());
      // setSubBlockMenu(false);
      // setSubBlockMenuBuffer(
      //   [...subBlockMenuBuffer].filter((i) => i !== blockReasonBuffer.id)
      // );
      setSubBlockMenuBuffer([]);
    }, 1000);
  };

  const getAskPriceListByCustomer = (customerId) => {
    let arr = [];
    if (askAllPriceList) {
      const A = askAllPriceList.filter((a) => a.customerId === customerId);
      if (A) arr = [...A];
    }
    return arr;
  };

  const getAskPriceByCustomerBadge = (customerId) => {
    let str = 0;
    let arr = [...getAskPriceListByCustomer(customerId)];
    if (arr.length > 0) {
      let priceAddedCounter = 0;
      arr.forEach((x) => x.priceAdded && priceAddedCounter++);
      str = `${priceAddedCounter}/${arr.length}`;
    }
    return str;
  };

  const getOrderHistory = (customerId) => {
    const O = orders.filter(
      (o) => o.customerId === customerId && o.orderNumber
    );
    return [...O];
  };

  const handleSearchQuery = (value) => {
    setSearchLoading(true);
    if (customerList && customerList.length > 0) {
      const C = customerList.find((c) => c._id === value);
      if (C) {
        setSearchFromNoti([C]);
        setSearchingCustomerList([C]);
      }
    }
    setTimeout(() => {
      setSearchLoading(false);
    }, 1000);
  };

  const handleOpenAskPriceDlg = (customerId) => {
    setAskPriceDlg(true);
    setAskPriceList(getAskPriceListByCustomer(customerId));
    setAskPriceBuffer(customerId);
    setAskPriceCustomerId(customerId);
  };

  const handleRemoveCustomer = () => {
    setRemoveCustomerLoading(true);
    dispatch(removeCustomer({ customerId: removeCustomerBuffer._id }));
    setTimeout(() => {
      setRemoveCustomerDlg(false);
      setRemoveCustomerBuffer("");
      setRemoveCustomerLoading(false);
      dispatch(getAllCustomers());
    }, 1000);
  };

  // ! Cart
  // get cart info
  const getCartInfo = (customerId) => {
    let obj = {
      cartList: [],
      count: 0,
    };
    if (allCarts && allCarts.length > 0) {
      const C = allCarts.filter((c) => c.customerId === customerId);
      if (C.length > 0) {
        obj.cartList = C;
        obj.count = C.length;
      }
    }
    return obj;
  };

  // UseEffect
  useEffect(() => {
    dispatch(getAllCustomers());
    dispatch(getAskAllPriceList());
    dispatch(getAllCartInfo());
    dispatch(getAllOrders());
    dispatch(getAllPrescription());
    // dispatch(getAllOrderBackEnd());
  }, []);

  useEffect(() => {
    // Check Query Params
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
    let value = params.search; // "some_value"
    if (value) handleSearchQuery(value);
    else setSearchFromNoti([]);
  }, [customerList]);

  //
  useEffect(() => {
    setLoadingMain(true);
    customers && customers.length > 0 && setCustomerList([...customers]);
    setTimeout(() => {
      setLoadingMain(false);
      setFilter(0);
    }, 1000);
  }, [customers]);

  useEffect(() => {
    customerList.map(() => setExpanded(() => [...expanded, false]));
  }, [customerList]);

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setLang(activeLang.langCode);
      setLangId(activeLang._id);
    } else {
      if (localLangData) {
        setLang(localLangData.langCode);
        setLangId(localLangData._id);
      }
    }
  }, [activeLang]);

  // Filter Block
  useEffect(() => {
    if (customerList.length > 0) {
      let arr = [];
      if (filter === 1) arr = customerList.filter((x) => x.block);
      else if (filter === 2) arr = customerList.filter((x) => !x.block);
      else arr = customerList;
      setFilteredCustomerList(arr);
    }
  }, [filter, customerList]);

  // Fixed date
  const [showDate, setShowDate] = useState([]);

  const handleShowDate = (date) => {
    let arr = [...showDate];
    if (arr.includes(date)) {
      arr = arr.filter((a) => a !== date);
    } else {
      arr.push(date);
    }
    setShowDate(arr);
  };

  // askAllPriceList
  // useEffect(() => {
  // }, [askAllPriceList]);

  // Check responses
  const refreshAskPriceCustomerDlg = () => {
    setTimeout(() => {
      setAskPriceDlg(false);
      handleOpenAskPriceDlg(askPriceCustomerId);
    }, 1500);
  };
  useEffect(() => {
    // Check assign Price response
    if (assignPriceResponse && assignPriceResponse.message) {
      if (!assignPriceResponse.failed) refreshAskPriceCustomerDlg();
    }

    // Check unassign Price response
    if (unassignPriceResponse && unassignPriceResponse.message) {
      if (!unassignPriceResponse.failed) refreshAskPriceCustomerDlg();
    }

    // Check Cancel Price response
    if (cancelAskPriceResponse && cancelAskPriceResponse.message) {
      if (!cancelAskPriceResponse.failed) refreshAskPriceCustomerDlg();
    }
  }, [
    assignPriceResponse,
    unassignPriceResponse,
    cancelAskPriceResponse,
    askAllPriceList,
  ]);

  // ! translate
  // Store
  const [langSettings1, setLangSettings1] = useState([]);
  const [langSettings2, setLangSettings2] = useState([]);
  const [langSettings3, setLangSettings3] = useState([]);
  const title1 = "Customer List";
  const title2 = "Misc Words";
  const title3 = "Price Request List";

  useEffect(() => {
    if (langs && langs.length > 0 && lang !== "") {
      const L = langs.find((l) => l.langCode === lang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      const A = arr.find((l) => l._title === title1);
      if (A) setLangSettings1(A.data);
      const B = arr.find((l) => l._title === title2);
      if (B) setLangSettings2(B.data);
      const C = arr.find((l) => l._title === title3);
      if (C) setLangSettings3(C.data);
    }
  }, [lang, langs]);

  const translate1 = (String) => translation(String, title1, 1);
  const translate2 = (String) => translation(String, title2, 2);
  const translate3 = (String) => translation(String, title3, 3);

  const translation = (String, title, index) => {
    let translation = "";
    let settings = [];
    if (index === 1) {
      settings = langSettings1;
    } else if (index === 2) {
      settings = langSettings2;
    } else if (index === 3) {
      settings = langSettings3;
    }
    const T = settings.find((ls) => ls.title === String);
    if (T && T.value) translation = T.value;
    else {
      let arr = [];
      if (langs && langs.length > 0 && lang !== "") {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.settings && LEn.settings.length > 0) arr = LEn.settings;
        const S = arr.find((l) => l._title === title);
        if (S) {
          const T = S.data.find((ls) => ls.title === String);
          if (T && T.value) translation = T.value;
        }
      }
    }
    return translation;
  };

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setLang(activeLang.langCode);
      setLangId(activeLang._id);
    } else {
      if (localLangData) {
        setLang(localLangData.langCode);
        setLangId(localLangData._id);
      }
    }
  }, [activeLang]);
  // ! translate

  return (
    <>
      {loadingMain ? (
        <LoadingBox w={"100%"} />
      ) : (
        <>
          <Grid container spacing={1} flexDirection="column">
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={3} lg={1}>
                  <FormControl fullWidth size="small" variant="standard">
                    <InputLabel>{translate1("Filter")}</InputLabel>
                    <Select
                      value={filter}
                      label="Age"
                      onChange={(e) => {
                        setFilter(e.target.value);
                        setSearchLoading(true);
                        setSearchingCustomerList([]);
                        setTimeout(() => {
                          setSearchLoading(false);
                        }, 100);
                      }}
                    >
                      <MenuItem value={0}>{translate1("All")}</MenuItem>
                      <MenuItem value={1}>{translate1("Blocked")}</MenuItem>
                      <MenuItem value={2}>{translate1("Unblocked")}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={9} lg={11}>
                  {searchLoading ? (
                    <LoadingBox w={"100%"} />
                  ) : (
                    <Autocomplete
                      multiple
                      onChange={(e, newValue) => handleSearch(e, newValue)}
                      options={customerList}
                      defaultValue={searchFromNoti}
                      filterOptions={filterOptions}
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={translate1("Search") + "..."}
                          variant="standard"
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1} justifyContent="center">
                {(searchingCustomerList.length > 0
                  ? searchingCustomerList
                  : filteredCustomerList
                  ? filteredCustomerList
                  : customerList.length > 0
                  ? customerList
                  : [...Array(0)]
                ).map((x, i) => (
                  <Fragment key={i}>
                    <Grid item xs={12} sm={6} md={4} xl={2}>
                      <Card
                        sx={{
                          maxWidth: 345,
                          background:
                            x.gender && x.gender === "male"
                              ? "lightgreen"
                              : "#f7c6eb",
                        }}
                      >
                        <CardHeader
                          avatar={
                            <StyledBadge
                              overlap="circular"
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              variant={x.loginStatus && "dot"}
                            >
                              <Tooltip
                                arrow
                                title={
                                  x.loginStatus
                                    ? translate1("Online")
                                    : translate1("Offline")
                                }
                                placement="top"
                              >
                                <Avatar
                                  sx={{
                                    bgcolor: red[500],
                                    filter: x.loginStatus
                                      ? "grayscale(0%)"
                                      : "grayscale(100%)",
                                  }}
                                  aria-label="customers"
                                  src={
                                    x.customerPic
                                      ? x.customerPic
                                      : x.gender
                                      ? x.gender === "male"
                                        ? PF +
                                          "frontEnd/default/man-default-customer-image.jpg"
                                        : x.gender === "female" &&
                                          PF +
                                            "frontEnd/default/women-default-customer-image.jpg"
                                      : "https://picsum.photos/200/300"
                                  }
                                />
                              </Tooltip>
                            </StyledBadge>
                          }
                          action={
                            <Tooltip
                              arrow
                              placement="left"
                              title={translate2("Delete")}
                            >
                              <IconButton
                                aria-label="remove-customer"
                                onClick={() => handleCustomerRemove(x._id)}
                              >
                                <CloseIcon color="error" />
                              </IconButton>
                            </Tooltip>
                          }
                          title={
                            <Typography
                              variant="body1"
                              color="initial"
                              sx={{ fontSize: !smUp ? ".8rem" : "1rem" }}
                            >
                              {x.name.toUpperCase()}{" "}
                              <span>
                                <img
                                  loading="lazy"
                                  width="20"
                                  src={`https://flagcdn.com/w20/${
                                    x.geoInfo
                                      ? x.geoInfo.country_code.toLowerCase() ===
                                        "en"
                                        ? "gb"
                                        : x.geoInfo.country_code.toLowerCase()
                                      : "gb"
                                  }.png`}
                                  alt="admins"
                                />
                              </span>
                            </Typography>
                          }
                          subheader={
                            <Typography
                              variant="subtitle2"
                              color="initial"
                              sx={{ fontSize: ".8rem" }}
                            >
                              {getCreated(x.createdAt)}
                              <Tooltip
                                arrow
                                placement="top"
                                title={`${translate2(
                                  "Created"
                                )}: ${getExactTime(x.createdAt)}`}
                              >
                                <IconButton
                                  size="small"
                                  onClick={() => handleShowDate(x.createdAt)}
                                >
                                  <InfoIcon color="info" />
                                </IconButton>
                              </Tooltip>
                              <br />
                              {showDate.includes(x.createdAt) &&
                                getExactTime(x.createdAt)}
                            </Typography>
                          }
                        />
                        <CardContent>
                          <Grid container spacing={0}>
                            <Grid item xs={11}>
                              <Tooltip
                                arrow
                                placement="top"
                                title={translate2("Send Email")}
                              >
                                <Button
                                  variant="text"
                                  size="small"
                                  color="inherit"
                                  sx={{ ml: -0.6, textTransform: "none" }}
                                  onClick={() =>
                                    window.open(`mailto: ${x.email}`)
                                  }
                                >
                                  {smUp && `${translate1("Email")}: `}
                                  <span
                                    style={{
                                      fontWeight: 900,
                                      marginLeft: smUp ? 5 : 0,
                                    }}
                                  >
                                    {x.email}
                                  </span>
                                </Button>
                              </Tooltip>
                            </Grid>
                            <Grid item xs={1}>
                              <Tooltip
                                arrow
                                title={translate2("Copy")}
                                placement="top"
                              >
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    copyToClipBoard(dispatch, x.email)
                                  }
                                  sx={{ mt: 0.5 }}
                                >
                                  <ContentCopyIcon
                                    color="primary"
                                    sx={{ width: 14, height: 14 }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </Grid>

                          <Typography
                            gutterBottom
                            variant="body2"
                            component="div"
                          >
                            {translate1("Address")}:{" "}
                            <span style={{ fontWeight: 900 }}>{x.address}</span>
                          </Typography>
                          <Grid container spacing={0}>
                            <Grid item xs={11}>
                              <Tooltip arrow placement="top" title="Call">
                                <Button
                                  variant="text"
                                  size="small"
                                  color="inherit"
                                  sx={{ ml: -0.6 }}
                                  onClick={() => window.open(`tel: ${x.phone}`)}
                                >
                                  {smUp && `${translate1("Phone")}: `}
                                  <span
                                    style={{
                                      fontWeight: 900,
                                      marginLeft: smUp ? 5 : 0,
                                    }}
                                  >
                                    {x.phone}
                                  </span>
                                </Button>
                              </Tooltip>
                            </Grid>
                            <Grid item xs={1}>
                              <Tooltip
                                arrow
                                title={translate2("Copy")}
                                placement="top"
                              >
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    copyToClipBoard(dispatch, x.phone)
                                  }
                                  sx={{ mt: 0.5 }}
                                >
                                  <ContentCopyIcon
                                    color="primary"
                                    sx={{ width: 14, height: 14 }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </CardContent>
                        <CardActions disableSpacing>
                          <Tooltip
                            arrow
                            placement="top"
                            title={translate1("Go to location in map")}
                          >
                            <IconButton
                              aria-label="go-to-location"
                              onClick={() => goToLocation(i)}
                            >
                              <LocationOnIcon color="error" />
                            </IconButton>
                          </Tooltip>

                          {/* Sub Block Menu */}
                          {!subBlockMenuBuffer.includes(i) && (
                            <Tooltip
                              arrow
                              placement="top"
                              title={
                                !x.block
                                  ? `${translate1("Block")}: ${x.name}`
                                  : `${translate2("Unblock")}: ${x.name}`
                              }
                            >
                              <Badge
                                badgeContent={
                                  subBlockMenuBuffer.includes(i)
                                    ? 0
                                    : x.unblockMessage.length
                                }
                                color="primary"
                              >
                                <IconButton
                                  size="small"
                                  aria-label="block"
                                  onClick={() => {
                                    // setSubBlockMenu(true);
                                    setSubBlockMenuBuffer([
                                      ...subBlockMenuBuffer,
                                      i,
                                    ]);
                                  }}
                                >
                                  {x.block ? (
                                    <img
                                      src="https://img.icons8.com/fluency/48/000000/unlock-2.png"
                                      alt="unblock"
                                      style={{
                                        height: 20,
                                        width: 20,
                                        objectFit: "cover",
                                        color: "grey",
                                      }}
                                    />
                                  ) : (
                                    <BlockIcon
                                      color={
                                        subBlockMenuBuffer.includes(i)
                                          ? "inherit"
                                          : "error"
                                      }
                                    />
                                  )}
                                </IconButton>
                              </Badge>
                            </Tooltip>
                          )}
                          {subBlockMenuBuffer.includes(i) && (
                            <Box
                              sx={{
                                border: ".05rem solid blue",
                                borderRadius: ".25rem",
                              }}
                            >
                              {x.block && (
                                <Tooltip
                                  arrow
                                  placement="top"
                                  title={translate2(
                                    "View Unblock request Messages"
                                  )}
                                >
                                  <Slide
                                    direction="right"
                                    in={subBlockMenuBuffer.includes(i)}
                                    mountOnEnter
                                    unmountOnExit
                                  >
                                    <Badge
                                      badgeContent={x.unblockMessage.length}
                                      color="primary"
                                    >
                                      <IconButton
                                        size="small"
                                        onClick={() => {
                                          setUnblockMessageDlg(true);
                                          setUnblockMessageBuffer(
                                            x.unblockMessage
                                          );
                                        }}
                                      >
                                        <MessageIcon
                                          color="primary"
                                          sx={{ width: 20, height: 20 }}
                                        />
                                      </IconButton>
                                    </Badge>
                                  </Slide>
                                </Tooltip>
                              )}
                              <Tooltip
                                arrow
                                placement="top"
                                title={translate2("Confirm")}
                              >
                                <Slide
                                  direction="right"
                                  in={subBlockMenuBuffer.includes(i)}
                                  mountOnEnter
                                  unmountOnExit
                                >
                                  <IconButton
                                    size="small"
                                    onClick={() =>
                                      handleBlockConfirm(x._id, x.block)
                                    }
                                  >
                                    <CheckIcon
                                      color="success"
                                      sx={{ width: 20, height: 20 }}
                                    />
                                  </IconButton>
                                </Slide>
                              </Tooltip>
                              <Tooltip
                                arrow
                                placement="top"
                                title={translate2("Close")}
                              >
                                <Slide
                                  direction="right"
                                  in={subBlockMenuBuffer.includes(i)}
                                  mountOnEnter
                                  unmountOnExit
                                >
                                  <IconButton
                                    size="small"
                                    onClick={() => {
                                      // setSubBlockMenu(false);
                                      setSubBlockMenuBuffer(
                                        [...subBlockMenuBuffer].filter(
                                          (fi) => fi !== i
                                        )
                                      );
                                    }}
                                  >
                                    <CloseIcon
                                      color="error"
                                      sx={{ width: 20, height: 20 }}
                                    />
                                  </IconButton>
                                </Slide>
                              </Tooltip>
                            </Box>
                          )}
                          {/* Sub Block Menu End */}

                          {!subBlockMenuBuffer.includes(i) && (
                            <>
                              {/* Prescription */}
                              <Tooltip
                                arrow
                                placement="top"
                                title={translate1("Prescription")}
                              >
                                <IconButton
                                  size="small"
                                  aria-label="prescription"
                                  onClick={() => {
                                    setPrescriptionDlg(true);
                                    setPrescriptionBuffer(x);
                                  }}
                                >
                                  <MedicationIcon color="info" />
                                </IconButton>
                              </Tooltip>

                              {/* Prescription Dialog */}
                              {prescriptionDlg &&
                                prescriptionBuffer._id === x._id && (
                                  <ViewCustomerPrescription
                                    prescriptionDlg={prescriptionDlg}
                                    setPrescriptionDlg={setPrescriptionDlg}
                                    prescriptionBuffer={prescriptionBuffer}
                                    lang={lang}
                                  />
                                )}

                              {/* Order */}
                              <Tooltip
                                arrow
                                placement="top"
                                title={translate1("Order History")}
                              >
                                <IconButton
                                  size="small"
                                  aria-label="order History"
                                  onClick={() => {
                                    setShowOrderHistoryBuffer(
                                      getOrderHistory(x._id)
                                    );
                                    setShowOrderHistoryDlg(true);
                                  }}
                                  disabled={getOrderHistory(x._id).length < 1}
                                >
                                  <Badge
                                    badgeContent={getOrderHistory(x._id).length}
                                    color="info"
                                  >
                                    <DescriptionOutlinedIcon
                                      color={
                                        getOrderHistory(x._id) < 1
                                          ? "inherit"
                                          : "warning"
                                      }
                                    />
                                  </Badge>
                                </IconButton>
                              </Tooltip>

                              {/* Cart */}
                              <Tooltip
                                arrow
                                placement="top"
                                title={translate1("Cart")}
                              >
                                <IconButton
                                  size="small"
                                  aria-label="cart"
                                  onClick={() => {
                                    setShowCartBuffer(
                                      getCartInfo(x._id).cartList
                                    );
                                    setShowCartDlg(true);
                                  }}
                                  disabled={getCartInfo(x._id).count < 1}
                                >
                                  <Badge
                                    badgeContent={getCartInfo(x._id).count}
                                    color="info"
                                  >
                                    <ShoppingCartCheckoutIcon
                                      color={
                                        getCartInfo(x._id).count < 1
                                          ? "inherit"
                                          : "primary"
                                      }
                                    />
                                  </Badge>
                                </IconButton>
                              </Tooltip>

                              <Tooltip
                                arrow
                                placement="top"
                                title={translate1("Price Requests")}
                              >
                                <IconButton
                                  size="small"
                                  aria-label="price requests"
                                  onClick={() => {
                                    // setShowOrderHistoryBuffer(x.orderHistory || []);
                                    // setShowOrderHistoryDlg(true);
                                    // getAskPriceListByCustomer(x._id);
                                    handleOpenAskPriceDlg(x._id);
                                  }}
                                  disabled={
                                    getAskPriceListByCustomer(x._id).length < 1
                                  }
                                >
                                  <Badge
                                    badgeContent={getAskPriceByCustomerBadge(
                                      x._id
                                    )}
                                    color="primary"
                                  >
                                    <RequestQuoteIcon
                                      color={
                                        getAskPriceListByCustomer(x._id)
                                          .length < 1
                                          ? "inherit"
                                          : "success"
                                      }
                                    />
                                  </Badge>
                                </IconButton>
                              </Tooltip>
                            </>
                          )}
                          {askPriceBuffer === x._id && (
                            <Dialog
                              open={askPriceDlg}
                              // onClose={handleAskPriceDlgClose}
                              aria-labelledby={"ask-price-dialogue"}
                              TransitionComponent={Transition}
                              keepMounted
                              fullWidth
                              maxWidth={"sm"}
                            >
                              <DialogTitle>
                                {translate3("Assign Price")}
                              </DialogTitle>
                              <DialogContent>
                                <AskPriceListByCustomerDialog
                                  askPriceList={askPriceList}
                                  customerId={x._id}
                                />
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={() => setAskPriceDlg(false)}
                                  color="error"
                                >
                                  {translate3("Cancel")}
                                </Button>
                              </DialogActions>
                            </Dialog>
                          )}
                          <ExpandMore
                            expand={expanded[i]}
                            onClick={() => handleExpandClick(i)}
                            aria-expanded={expanded[i]}
                            aria-label="show more"
                          >
                            <ExpandMoreIcon />
                          </ExpandMore>
                        </CardActions>
                        <Collapse in={expanded[i]} timeout="auto" unmountOnExit>
                          <CardContent sx={{ background: "yellow" }}>
                            <Button
                              fullWidth
                              variant={
                                additionalInfoShowArr.includes(i)
                                  ? "outlined"
                                  : "contained"
                              }
                              size="small"
                              sx={{ my: 0.5 }}
                              color="warning"
                              onClick={() => showAdditionalInfo(i)}
                            >
                              {translate1("Additional Info")}
                            </Button>
                            {additionalInfoShowArr.includes(i) && (
                              <>
                                <Typography
                                  gutterBottom
                                  variant="body2"
                                  component="div"
                                >
                                  {translate1("Subscription Status")}:{" "}
                                  {x.subscribed ? (
                                    <Tooltip
                                      arrow
                                      placement="top"
                                      title={translate1("Subscribed")}
                                      sx={{ mt: -0.8 }}
                                    >
                                      <IconButton size="small">
                                        <CheckCircleIcon color="success" />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    <Tooltip
                                      arrow
                                      placement="top"
                                      title={translate1("Unsubscribed")}
                                      sx={{ mt: -0.8 }}
                                    >
                                      <IconButton size="small">
                                        <CancelIcon color="error" />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </Typography>
                                <Typography
                                  gutterBottom
                                  variant="body2"
                                  component="div"
                                >
                                  {translate1("Gender")}:{" "}
                                  {x.gender && x.gender === "male" ? (
                                    <Tooltip
                                      arrow
                                      placement="right"
                                      title={x.gender.toUpperCase()}
                                    >
                                      <IconButton size="small">
                                        <MaleIcon sx={{ color: "black" }} />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    <Tooltip
                                      arrow
                                      placement="right"
                                      title={x.gender.toUpperCase()}
                                    >
                                      <IconButton size="small">
                                        <FemaleIcon sx={{ color: "black" }} />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </Typography>
                              </>
                            )}
                            <Button
                              fullWidth
                              variant={
                                clientInfoArr.includes(i)
                                  ? "outlined"
                                  : "contained"
                              }
                              size="small"
                              sx={{ my: 0.5 }}
                              color="secondary"
                              onClick={() => showClientInfo(i)}
                            >
                              {translate1("Client Info")}
                            </Button>
                            {clientInfoArr.includes(i) && (
                              <>
                                <Typography
                                  gutterBottom
                                  variant="body2"
                                  component="div"
                                >
                                  {translate1("Ip")}:{" "}
                                  <span style={{ fontWeight: 900 }}>
                                    {x.geoInfo && x.geoInfo.IPv4}
                                  </span>
                                </Typography>
                                <Typography
                                  gutterBottom
                                  variant="body2"
                                  component="div"
                                >
                                  {translate1("Country")}:{" "}
                                  <span style={{ fontWeight: 900 }}>
                                    {x.geoInfo && x.geoInfo.country_name}
                                  </span>
                                </Typography>
                                <Typography
                                  gutterBottom
                                  variant="body2"
                                  component="div"
                                >
                                  {translate1("Browser")}:{" "}
                                  <span style={{ fontWeight: 900 }}>
                                    {x.browserInfo}
                                  </span>
                                </Typography>
                              </>
                            )}
                            <Button
                              fullWidth
                              variant={
                                socialMediaArr.includes(i)
                                  ? "outlined"
                                  : "contained"
                              }
                              size="small"
                              onClick={() => showSocialMedia(i)}
                            >
                              {translate1("Social Media")}
                            </Button>
                            {socialMediaArr.includes(i) && (
                              <>
                                <Button
                                  variant="text"
                                  startIcon={
                                    <WhatsAppIcon sx={{ color: "#4ccf7a" }} />
                                  }
                                  disabled={
                                    getSocialLink("whatsApp", x).length < 1
                                  }
                                  size="small"
                                  fullWidth
                                  sx={{
                                    justifyContent: "flex-start",
                                    color: "inherit",
                                    textAlign: "left",
                                    textTransform: "none",
                                  }}
                                  onClick={() =>
                                    getSocialLink("whatsApp", x).includes(
                                      "https://"
                                    )
                                      ? window.open(
                                          getSocialLink("whatsApp", x),
                                          "_blank"
                                        )
                                      : copyToClipBoard(
                                          dispatch,
                                          getSocialLink("whatsApp")
                                        )
                                  }
                                >
                                  {getSocialLink("whatsApp", x)}
                                </Button>
                                <Button
                                  variant="text"
                                  startIcon={
                                    <img
                                      src="https://img.icons8.com/doodle/48/000000/weixing.png"
                                      alt="WeChat"
                                      style={{ height: "1.15rem" }}
                                    />
                                  }
                                  disabled={
                                    getSocialLink("weChat", x).length < 1
                                  }
                                  size="small"
                                  fullWidth
                                  sx={{
                                    justifyContent: "flex-start",
                                    color: "inherit",
                                    textAlign: "left",
                                    textTransform: "none",
                                  }}
                                  onClick={() =>
                                    getSocialLink("weChat", x).includes(
                                      "https://"
                                    )
                                      ? window.open(
                                          getSocialLink("weChat", x),
                                          "_blank"
                                        )
                                      : copyToClipBoard(
                                          dispatch,
                                          getSocialLink("weChat", x)
                                        )
                                  }
                                >
                                  {getSocialLink("weChat", x)}
                                </Button>
                                <Button
                                  variant="text"
                                  startIcon={
                                    <img
                                      src="https://img.icons8.com/color/48/000000/skype--v1.png"
                                      alt="Skype"
                                      style={{ height: "1.15rem" }}
                                    />
                                  }
                                  disabled={
                                    getSocialLink("skype", x).length < 1
                                  }
                                  size="small"
                                  fullWidth
                                  sx={{
                                    justifyContent: "flex-start",
                                    color: "inherit",
                                    textAlign: "left",
                                    textTransform: "none",
                                  }}
                                  onClick={() =>
                                    getSocialLink("skype", x).includes(
                                      "https://"
                                    )
                                      ? window.open(
                                          getSocialLink("skype", x),
                                          "_blank"
                                        )
                                      : copyToClipBoard(
                                          dispatch,
                                          getSocialLink("skype", x)
                                        )
                                  }
                                >
                                  {getSocialLink("skype", x)}
                                </Button>
                                <Button
                                  variant="text"
                                  startIcon={
                                    <FacebookIcon sx={{ color: "navy" }} />
                                  }
                                  size="small"
                                  disabled={
                                    getSocialLink("facebook", x).length < 1
                                  }
                                  fullWidth
                                  sx={{
                                    justifyContent: "flex-start",
                                    color: "inherit",
                                    textAlign: "left",
                                    textTransform: "none",
                                  }}
                                  onClick={() =>
                                    getSocialLink("facebook", x).includes(
                                      "https://"
                                    )
                                      ? window.open(
                                          getSocialLink("facebook", x),
                                          "_blank"
                                        )
                                      : copyToClipBoard(
                                          dispatch,
                                          getSocialLink("facebook", x)
                                        )
                                  }
                                >
                                  {getSocialLink("facebook")}
                                </Button>
                                {getAdditionalSocialLink(x).map((y, j) => (
                                  <Button
                                    variant="text"
                                    key={i}
                                    size="small"
                                    fullWidth
                                    sx={{
                                      justifyContent: "flex-start",
                                      color: "inherit",
                                      textAlign: "left",
                                      textTransform: "none",
                                    }}
                                    onClick={() =>
                                      y.socialLink.includes("https://")
                                        ? window.open(y.socialLink, "_blank")
                                        : copyToClipBoard(
                                            dispatch,
                                            y.socialLink
                                          )
                                    }
                                  >
                                    {y.socialName}: {y.socialLink}
                                  </Button>
                                ))}
                              </>
                            )}
                            <Button
                              fullWidth
                              variant={
                                loginInfoArr.includes(i)
                                  ? "outlined"
                                  : "contained"
                              }
                              size="small"
                              sx={{ my: 0.5 }}
                              color="warning"
                              onClick={() => showLoginInfo(i)}
                            >
                              {translate1("Login Information")}
                            </Button>
                            {loginInfoArr.includes(i) && (
                              <>
                                <Typography
                                  gutterBottom
                                  variant="body2"
                                  component="div"
                                  sx={{ color: "green" }}
                                >
                                  {translate1("Last Login")}:{" "}
                                  <span
                                    style={{
                                      fontWeight: 900,
                                      display: !smUp && "block",
                                    }}
                                  >
                                    {x.latestLoginTime &&
                                      getCreated(x.latestLoginTime)}
                                    <Tooltip
                                      arrow
                                      placement="top"
                                      title={getExactTime(x.latestLoginTime)}
                                    >
                                      <IconButton
                                        size="small"
                                        onClick={() =>
                                          handleShowDate(x.latestLoginTime)
                                        }
                                      >
                                        <InfoIcon color="info" />
                                      </IconButton>
                                    </Tooltip>
                                    <br />
                                    {showDate.includes(x.latestLoginTime) &&
                                      getExactTime(x.latestLoginTime)}
                                  </span>
                                </Typography>
                                <Typography
                                  gutterBottom
                                  variant="body2"
                                  component="div"
                                  color="error"
                                >
                                  {translate1("Last Log Out")}:{" "}
                                  <span
                                    style={{
                                      fontWeight: 900,
                                      display: !smUp && "block",
                                    }}
                                  >
                                    {x.latestLogOutTime &&
                                      getCreated(x.latestLogOutTime)}
                                    <Tooltip
                                      arrow
                                      placement="top"
                                      title={getExactTime(x.latestLogOutTime)}
                                    >
                                      <IconButton
                                        size="small"
                                        onClick={() =>
                                          handleShowDate(x.latestLogOutTime)
                                        }
                                      >
                                        <InfoIcon color="info" />
                                      </IconButton>
                                    </Tooltip>
                                    <br />
                                    {showDate.includes(x.latestLogOutTime) &&
                                      getExactTime(x.latestLogOutTime)}
                                  </span>
                                </Typography>
                                <Typography
                                  gutterBottom
                                  variant="body2"
                                  component="div"
                                  sx={{
                                    color: x.loginStatus ? "green" : "red",
                                  }}
                                >
                                  {translate1("Current Login Status")}:{" "}
                                  <span style={{ fontWeight: 900 }}>
                                    {x.loginStatus ? "Online" : "Offline"}
                                  </span>
                                </Typography>
                                <Typography
                                  gutterBottom
                                  variant="body2"
                                  component="div"
                                >
                                  {translate1("Total Logged In Count")}:{" "}
                                  <span style={{ fontWeight: 900 }}>
                                    {x.loginCount}
                                  </span>
                                </Typography>
                                <Typography
                                  gutterBottom
                                  variant="body2"
                                  component="div"
                                >
                                  {translate1("Total Logged In Time")}:{" "}
                                  <span
                                    style={{
                                      fontWeight: 900,
                                      display: "block",
                                    }}
                                  >
                                    {getTotalLoginTime(
                                      x.totalLoggedIn || 0,
                                      x.loginStatus,
                                      x.latestLogOutTime,
                                      x.latestLoginTime
                                    )}
                                  </span>
                                </Typography>
                              </>
                            )}
                          </CardContent>
                        </Collapse>
                      </Card>
                    </Grid>
                  </Fragment>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {/* Remove Customer Dialog */}
      <Dialog
        open={removeCustomerDlg}
        onClose={() => {
          setRemoveCustomerDlg(false);
          setRemoveCustomerBuffer("");
        }}
      >
        <DialogTitle>Remove Customer</DialogTitle>
        <DialogContent>
          <Typography variant="body2" color="initial">
            Are you sure you want to remove "
            <span style={{ color: "red" }}>{removeCustomerBuffer.name}</span>"
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setRemoveCustomerDlg(false);
              setRemoveCustomerBuffer("");
            }}
            color="primary"
          >
            Cancel
          </Button>
          <LoadingButton
            loading={removeCustomerLoading}
            onClick={handleRemoveCustomer}
            color="error"
          >
            Remove
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {/* Customer Order History */}
      <Dialog open={showOrderHistoryDlg} fullWidth maxWidth={"lg"}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setShowOrderHistoryDlg(false);
                setShowOrderHistoryBuffer([]);
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Order History
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Grid container spacing={1}>
            {showOrderHistoryBuffer.length < 1 ? (
              <Grid item>
                <Alert severity="warning">No Order</Alert>
              </Grid>
            ) : (
              showOrderHistoryBuffer.map((x, i) => (
                <Grid item key={i} xs={12} sm={6} md={4}>
                  <OrderCard
                    x={x}
                    lang={lang}
                    fromCustomer={true}
                    customerId={x.customerId}
                    // orderBackends={orderBackends}
                  />
                </Grid>
              ))
            )}
          </Grid>
        </DialogContent>
      </Dialog>
      {/* Block Message */}
      <Dialog
        open={blockReasonDlg}
        onClose={() => {
          setBlockReasonDlg(false);
          setBlockReasonBuffer({});
          setBlockReason("");
        }}
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle>Reason For Block</DialogTitle>
        <DialogContent>
          <TextField
            label="Reason"
            value={blockReason}
            onChange={(e) => setBlockReason(e.target.value)}
            variant="standard"
            size="small"
            fullWidth
            multiline
            minRows={3}
            helperText="Message will be sent as Email to the customer"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              // setBlockReasonDlg(false);
              // setBlockReasonBuffer({});
              // setBlockReason("");
              handleBlockConfirmWithReason("Suspicious activity detected");
            }}
            color="primary"
          >
            Skip
          </Button>
          <LoadingButton
            onClick={() => handleBlockConfirmWithReason(blockReason)}
            color="error"
            loading={blockLoading}
            disabled={blockReason.length < 1 ? true : false}
          >
            Send
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={unblockMessageDlg}
        onClose={() => {
          setUnblockMessageDlg(false);
          setUnblockMessageBuffer([]);
        }}
      >
        <DialogTitle>Unblock Requests Sent By Customer</DialogTitle>
        <DialogContent>
          {unblockMessageBuffer.length > 0 ? (
            unblockMessageBuffer.map((x, i) => (
              <Alert severity="warning">{x}</Alert>
            ))
          ) : (
            <Alert severity="error">No Request</Alert>
          )}
        </DialogContent>
      </Dialog>
      {/* AskPriceDialog */}
      {/* View Cart Dialog */}
      {showCartDlg && (
        <ViewCartByCustomer
          showCartDlg={showCartDlg}
          setShowCartDlg={setShowCartDlg}
          showCartBuffer={showCartBuffer}
          setShowCartBuffer={setShowCartBuffer}
        />
      )}
    </>
  );
}
