import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  createFilterOptions,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  Tooltip,
  Divider,
  Badge,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import AddLangDialog from "../../medicines/dialog/AddLangDialog";

// Icons
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import InfoIcon from "@mui/icons-material/Info";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { useDispatch, useSelector } from "react-redux";
import AddPaymentMethods from "./AddPaymentMethods";
import LoadingBox from "../../../../components/misc/Loading";
import {
  getAllPaymentMethods,
  removePaymentMethods,
} from "../../../../reduxToolkit/features/adminPanel/payment";
import EditPaymentMethods from "./EditPaymentMethods";
import { getAdmins } from "../../../../reduxToolkit/features/adminPanel/admin";
import { getCreated, getExactTime } from "../../../../hooks/getCreatedData";
import { countries } from "../../../../utils/countryList";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { PF } from "../../../../config";
import { getNotification } from "../../../../reduxToolkit/features/adminPanel/notification";

// Limit options to show in autocomplete
const OPTIONS_LIMIT = 5;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) => {
  return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
};

export default function ManagePayment() {
  const dispatch = useDispatch();

  const [loadingMain, setLoadingMain] = useState(false);
  const [lang, setLang] = useState("");
  const [langId, setLangId] = useState("");

  const [paymentList, setPaymentList] = useState([]);
  const [searchPaymentList, setSearchPaymentList] = useState([]);
  const [searchingPaymentListByMethod, setSearchingPaymentListByMethod] =
    useState([]);
  const [searchingPaymentListByCountry, setSearchingPaymentListByCountry] =
    useState([]);
  const [searchingPaymentList, setSearchingPaymentList] = useState([]);
  const [noMethodFound, setNoMethodFound] = useState(false);
  const [showPaymentBuffer, setShowPaymentBuffer] = useState([]);
  const [paymentListEn, setPaymentListEn] = useState([]);

  const [showPropertiesBuffer, setShowPropertiesBuffer] = useState([]);
  const [showAccountsBuffer, setShowAccountsBuffer] = useState([]);
  const [showCountryBuffer, setShowCountryBuffer] = useState([]);

  // Dialog
  const [addLangDlg, setAddLangDlg] = useState(false);
  const [addPaymentDlg, setAddPaymentDlg] = useState(false);

  const [deleteDlg, setDeleteDlg] = useState(false);
  const [deleteLoadingBuffer, setDeleteLoadingBuffer] = useState("");
  const [deleteBuffer, setDeleteBuffer] = useState({});

  const [editDlg, setEditDlg] = useState(false);
  const [editBuffer, setEditBuffer] = useState({});

  // LocalData
  const localLangData = JSON.parse(localStorage.getItem("lang"));
  const localAdminData = () => {
    let adminData = JSON.parse(localStorage.getItem("adminLoginInfo"));
    return adminData;
  };

  // store
  const { langs, activeLang } = useSelector((state) => state.misc);
  const { paymentMethods } = useSelector((state) => state.payment);
  let { admins } = useSelector((state) => state.admin);

  // Functions
  const handleChangeLang = (e) => {
    setLang(e.target.value);
    const L = langs.find((l) => l.langCode === e.target.value);
    setLangId(L._id);
  };

  const handleAddPayment = () => {
    setAddPaymentDlg(true);
  };

  const handleSearch = (e, newValue) => {
    setSearchingPaymentListByMethod(newValue);
  };

  const handleCountrySearch = (e, newValue) => {
    let finalArr = [];
    newValue.forEach((n) => {
      let dArr = [];
      if (searchingPaymentListByMethod.length > 0)
        dArr = [...searchingPaymentListByMethod];
      else dArr = [...paymentList];
      dArr.forEach((d) => {
        d.countryArr.forEach((c) => {
          if (c.label === n.label) finalArr.push(d);
        });
      });
    });
    setSearchingPaymentListByCountry(finalArr);
    if (newValue.length > 0 && finalArr.length < 1) setNoMethodFound(true);
    else setNoMethodFound(false);
  };

  const getEnMethodName = (paymentDetectorId) => {
    let enMethod = "";
    if (paymentDetectorId) {
      const D = paymentMethods.find((d) => d._id === paymentDetectorId);
      if (D) {
        enMethod = D.methodName;
      }
    }
    return enMethod;
  };

  // const getCountryStr = (countryArr) => {
  //   let str = "";
  //   let arr = [];
  //   if (countryArr && countryArr.length > 0) {
  //     countryArr.forEach((c) => {
  //       arr.push(c.label);
  //     });
  //     str = arr.join(", ");
  //   }
  //   return str;
  // };

  const handlePaymentSummeryShow = (id) => {
    let arr = [...showPaymentBuffer];
    if (arr.includes(id)) {
      arr = arr.filter((a) => a !== id);
    } else {
      arr.push(id);
    }
    setShowPaymentBuffer(arr);
  };

  const handlePropertiesShow = (id) => {
    let arr = [...showPropertiesBuffer];
    if (arr.includes(id)) {
      arr = arr.filter((a) => a !== id);
    } else {
      arr.push(id);
    }
    setShowPropertiesBuffer(arr);
  };

  const handleAccountsShow = (id) => {
    let arr = [...showAccountsBuffer];
    if (arr.includes(id)) {
      arr = arr.filter((a) => a !== id);
    } else {
      arr.push(id);
    }
    setShowAccountsBuffer(arr);
  };

  const handleCountryShow = (id) => {
    let arr = [...showCountryBuffer];
    if (arr.includes(id)) {
      arr = arr.filter((a) => a !== id);
    } else {
      arr.push(id);
    }
    setShowCountryBuffer(arr);
  };

  const handleDeleteMethod = (id) => {
    setDeleteDlg(false);
    setDeleteLoadingBuffer(id);
    if (localAdminData()._id) {
      dispatch(
        removePaymentMethods({ paymentId: id, creatorId: localAdminData()._id })
      );
    }
    setTimeout(() => {
      setDeleteLoadingBuffer("");
      setDeleteBuffer({});
      dispatch(getAllPaymentMethods());
      dispatch(getNotification());
    }, 1000);
  };

  const getCreatorName = (id) => {
    let name = "";
    const A = admins.find((a) => a._id === id);
    if (A) name = A.adminName;
    return name;
  };

  // Fixed date
  const [showDate, setShowDate] = useState([]);

  const handleShowDate = (date) => {
    let arr = [...showDate];
    if (arr.includes(date)) {
      arr = arr.filter((a) => a !== date);
    } else {
      arr.push(date);
    }
    setShowDate(arr);
  };

  // UseEffect
  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setLang(activeLang.langCode);
      setLangId(activeLang._id);
    } else {
      if (localLangData) {
        setLang(localLangData.langCode);
        setLangId(localLangData._id);
      }
    }
  }, [activeLang]);

  // set Payment Method
  useEffect(() => {
    if (paymentMethods.length > 0 && langs.length > 0) {
      const D = paymentMethods.filter((d) => d.langId === langId);
      setPaymentList(D);
      setSearchPaymentList([...D, ...countries]);
      if (lang !== "en") {
        const L = langs.find((l) => l.langCode === "en");
        const DEn = paymentMethods.filter((d) => d.langId === L._id);
        setPaymentListEn(DEn);
      }
    }
  }, [paymentMethods, langId, lang, langs]);

  useEffect(() => {
    let arr = [
      ...searchingPaymentListByCountry,
      ...searchingPaymentListByMethod,
    ];
    const unique = [
      ...new Map(arr.map((item) => [item["_id"], item])).values(),
    ];
    setSearchingPaymentList(unique);
  }, [searchingPaymentListByCountry, searchingPaymentListByMethod]);

  useEffect(() => {
    setLoadingMain(true);
    dispatch(getAllPaymentMethods());
    dispatch(getAdmins());
    setTimeout(() => {
      setLoadingMain(false);
    }, 1000);
  }, []);

  // ! translate
  const [siteLang, setSiteLang] = useState("");
  const [langSettings, setLangSettings] = useState([]);
  const title1 = "Manage Payment Method";
  const title2 = "Misc Words";
  const title3 = "Payment Settings";
  const title4 = "Order Card Backend";
  const title5 = "Manage Delivery Method";

  useEffect(() => {
    if (langs && langs.length > 0 && siteLang !== "") {
      const L = langs.find((l) => l.langCode === siteLang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      let arrLang = [];
      const A = arr.find((l) => l._title === title1);
      if (A) arrLang = [...arrLang, ...A.data];
      const B = arr.find((l) => l._title === title2);
      if (B) arrLang = [...arrLang, ...B.data];
      const C = arr.find((l) => l._title === title3);
      if (C) arrLang = [...arrLang, ...C.data];
      const D = arr.find((l) => l._title === title4);
      if (D) arrLang = [...arrLang, ...D.data];
      const E = arr.find((l) => l._title === title5);
      if (E) arrLang = [...arrLang, ...E.data];
      setLangSettings(arrLang);
    }
  }, [siteLang, langs]);

  const translate = (String) => {
    let translation = "";
    let settings = [...langSettings];
    const T = settings.find((ls) => ls.title === String);
    if (T && T.value) {
      let obj = { ...T };
      if (T.value === "") obj.value = T.title;
      translation = obj.value;
    }
    return translation;
  };

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) setSiteLang(activeLang.langCode);
    else if (localLangData) setSiteLang(localLangData.langCode);
  }, [activeLang]);
  // ! translate

  return (
    <>
      <Box>
        <Grid
          container
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
        >
          {/* Select Language */}
          <Grid item xs={12} md={4}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={2} sm={1} md={1.5}>
                <IconButton>
                  <AddCircleOutlineOutlinedIcon
                    color="info"
                    size="large"
                    onClick={() => setAddLangDlg(true)}
                  />
                </IconButton>
                <AddLangDialog
                  addLangDlg={addLangDlg}
                  setAddLangDlg={setAddLangDlg}
                  setLang={setLang}
                />
              </Grid>
              <Grid item xs={10} sm={4} md={5}>
                <FormControl fullWidth>
                  <Select
                    value={lang}
                    label="language"
                    variant="standard"
                    onChange={handleChangeLang}
                    size="small"
                  >
                    {langs.map((x, i) => (
                      <MenuItem key={i} value={x.langCode} dense>
                        <img
                          alt={x.langCode}
                          src={`https://flagcdn.com/w20/${
                            x.langCode === "en" ? "gb" : x.langCode
                          }.png`}
                        />
                        <Button color="inherit" size="small">
                          {x.langName}
                        </Button>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* Add Payment Method */}
              <Grid item xs={12} sm={7} md={4.5}>
                <Button
                  variant="contained"
                  size="small"
                  color="error"
                  fullWidth
                  onClick={handleAddPayment}
                >
                  {translate("Add Method")}
                </Button>
                {addPaymentDlg && (
                  <AddPaymentMethods
                    lang={lang}
                    langId={langId}
                    addPaymentDlg={addPaymentDlg}
                    setAddPaymentDlg={setAddPaymentDlg}
                    paymentListEn={paymentListEn}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          {/* Search */}
          <Grid item xs={12} md={4} sx={{ mt: { md: -2, xs: 0 } }}>
            {loadingMain ? (
              <></>
            ) : (
              <Autocomplete
                multiple
                onChange={(e, newValue) => handleSearch(e, newValue)}
                disablePortal
                options={paymentList}
                filterOptions={filterOptions}
                getOptionLabel={(option) =>
                  option.methodName
                    ? option.methodName +
                      (lang !== "en"
                        ? " (" + getEnMethodName(option.paymentDetectorId) + ")"
                        : "")
                    : option.label
                }
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option.methodName}{" "}
                    {lang !== "en"
                      ? " (" + getEnMethodName(option.paymentDetectorId) + ")"
                      : ""}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={translate("Search by method") + "..."}
                    variant="standard"
                    size="small"
                    fullWidth
                  />
                )}
              />
            )}
          </Grid>

          {/* Country Search */}
          <Grid item xs={12} md={4} sx={{ mt: { md: -2, xs: 0 } }}>
            {loadingMain ? (
              <></>
            ) : (
              <Autocomplete
                multiple
                onChange={(e, newValue) => handleCountrySearch(e, newValue)}
                disablePortal
                options={countries}
                filterOptions={filterOptions}
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option.label}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={translate("Search by country") + "..."}
                    variant="standard"
                    size="small"
                    fullWidth
                  />
                )}
              />
            )}
          </Grid>
        </Grid>
        {/* Payment Method List */}
        <Button
          size="small"
          variant="contained"
          fullWidth
          sx={{ my: 1, pointerEvents: "none" }}
        >
          {translate("Payment")} ({paymentList.length})
        </Button>
      </Box>
      {loadingMain ? (
        <LoadingBox w={"100%"} />
      ) : paymentList.length < 1 || noMethodFound ? (
        <Alert
          severity="warning"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          {translate("No Data Available")}
        </Alert>
      ) : (
        <>
          <Box
            sx={{
              maxHeight: { xs: "60vh", sm: "57vh" },
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <Grid container spacing={0} xs={12}>
              {(searchingPaymentList.length > 0
                ? searchingPaymentList
                : paymentList
              ).map((x, i) => (
                <Grid item key={i} xs={12} sm={6} md={3}>
                  {deleteLoadingBuffer === x._id ? (
                    <LoadingBox w={"100%"} h={"100%"} />
                  ) : (
                    <Card sx={{ m: 1 }}>
                      <CardContent>
                        {/* <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <LazyLoadImage
                            effect="blur"
                            src={
                              x.barcodeImg && x.barcodeImg !== ""
                                ? PF + "/adminPanel/barcode/" + x.barcodeImg
                                : PF +
                                  "/adminPanel/medicines/no-medicine-image.jpg"
                            }
                            alt={x.methodName}
                            style={{
                              objectFit: "cover",
                              width: "200px",
                            }}
                          />
                        </Box> */}
                        <Typography gutterBottom variant="h5" component="div">
                          {x.methodName}{" "}
                          {lang !== "en"
                            ? " (" + getEnMethodName(x.paymentDetectorId) + ")"
                            : ""}
                        </Typography>

                        <Typography variant="body2" color="initial">
                          {translate("Transaction Fee")}:{" "}
                          {`${x.transactionFee}${
                            x.transactionUnit === 0 ? "%" : " (flat)"
                          }`}
                        </Typography>

                        {/* Country Section */}
                        <Button
                          variant={
                            showCountryBuffer.includes(x._id)
                              ? "contained"
                              : "outlined"
                          }
                          size="small"
                          fullWidth
                          endIcon={
                            showCountryBuffer.includes(x._id) ? (
                              <ArrowDropUpIcon />
                            ) : (
                              <ArrowDropDownCircleIcon />
                            )
                          }
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            my: 1,
                          }}
                          onClick={() => handleCountryShow(x._id)}
                          disabled={x.countryArr.length < 1}
                        >
                          {translate("Countries")} (
                          {x.countryArr ? x.countryArr.length : 0})
                        </Button>
                        {showCountryBuffer.includes(x._id) && (
                          <Box sx={{ height: "20vh", overflowY: "scroll" }}>
                            {x.countryArr.length > 0 &&
                              x.countryArr.map((y, j) => (
                                <Button size="small" variant="text" fullWidth>
                                  {y.label}
                                </Button>
                              ))}
                          </Box>
                        )}
                        <Button
                          variant={
                            showPaymentBuffer.includes(x._id)
                              ? "contained"
                              : "outlined"
                          }
                          size="small"
                          fullWidth
                          endIcon={
                            showPaymentBuffer.includes(x._id) ? (
                              <ArrowDropUpIcon />
                            ) : (
                              <ArrowDropDownCircleIcon />
                            )
                          }
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            my: 1,
                          }}
                          onClick={() => handlePaymentSummeryShow(x._id)}
                          disabled={x.paymentSummery.length < 1}
                        >
                          {translate("Instruction")} (
                          {x.paymentSummery ? x.paymentSummery.length : 0})
                        </Button>
                        {showPaymentBuffer.includes(x._id) &&
                          x.paymentSummery.map((y, j) => (
                            <Typography variant="body2" color="text.secondary">
                              {j + 1}.{y}
                            </Typography>
                          ))}
                        <Button
                          variant={
                            showAccountsBuffer.includes(x._id)
                              ? "contained"
                              : "outlined"
                          }
                          size="small"
                          fullWidth
                          disabled={x.accountArr.length < 1}
                          endIcon={
                            showAccountsBuffer.includes(x._id) ? (
                              <ArrowDropUpIcon />
                            ) : (
                              <ArrowDropDownCircleIcon />
                            )
                          }
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            my: 1,
                          }}
                          onClick={() => handleAccountsShow(x._id)}
                        >
                          {translate("Accounts")} (
                          {x.accountArr ? x.accountArr.length : 0})
                        </Button>
                        {showAccountsBuffer.includes(x._id) &&
                          x.accountArr.map((y, j) => (
                            <Fragment key={j}>
                              <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <LazyLoadImage
                                      effect="blur"
                                      src={
                                        y.barcodeImg && y.barcodeImg !== ""
                                          ? PF +
                                            "/adminPanel/barcode/" +
                                            y.barcodeImg
                                          : PF +
                                            "/adminPanel/medicines/no-medicine-image.jpg"
                                      }
                                      alt={y.accountTitle}
                                      style={{
                                        objectFit: "cover",
                                        width: 70,
                                      }}
                                    />
                                  </Box>
                                </Grid>
                                <Grid item>
                                  <Badge
                                    badgeContent={
                                      y.active ? "Active" : "Inactive"
                                    }
                                    color={y.active ? "success" : "error"}
                                    variant="dot"
                                    anchorOrigin={{
                                      vertical: "top",
                                      horizontal: "left",
                                    }}
                                    sx={{ mt: 1 }}
                                  >
                                    <Typography
                                      gutterBottom
                                      variant="h5"
                                      component="div"
                                    >
                                      {y.accountTitle}
                                    </Typography>
                                  </Badge>
                                  <Typography
                                    variant="body2"
                                    color={y.active ? "green" : "error"}
                                  >
                                    Active: {y.active ? "Yes" : "No"}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    component="pre"
                                  >
                                    {y.accountDetails}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Divider />
                            </Fragment>
                          ))}
                        <Divider sx={{ my: 1 }} />
                        {/* Properties */}
                        <Button
                          variant={
                            showPropertiesBuffer.includes(x._id)
                              ? "contained"
                              : "outlined"
                          }
                          size="small"
                          fullWidth
                          endIcon={
                            showPropertiesBuffer.includes(x._id) ? (
                              <ArrowDropUpIcon />
                            ) : (
                              <ArrowDropDownCircleIcon />
                            )
                          }
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            my: 1,
                          }}
                          onClick={() => handlePropertiesShow(x._id)}
                        >
                          {translate("Creation Info")}
                        </Button>
                        {showPropertiesBuffer.includes(x._id) && (
                          <>
                            <Typography
                              gutterBottom
                              variant="body2"
                              color="primary"
                            >
                              {translate("Created By")}:{" "}
                              {getCreatorName(x.creatorId)}
                            </Typography>
                            <Typography
                              gutterBottom
                              variant="body2"
                              color="error"
                            >
                              {translate("Created")}: {getCreated(x.createdAt)}
                              <Tooltip
                                arrow
                                placement="top"
                                title={`Created: ${getExactTime(x.createdAt)}`}
                              >
                                <IconButton
                                  size="small"
                                  onClick={() => handleShowDate(x.createdAt)}
                                >
                                  <InfoIcon color="info" />
                                </IconButton>
                              </Tooltip>
                              <br />
                              {showDate.includes(x.createdAt) &&
                                getExactTime(x.createdAt)}
                            </Typography>
                            <Typography
                              gutterBottom
                              variant="body2"
                              sx={{ color: "orange" }}
                            >
                              {translate("Updated")}: {getCreated(x.updatedAt)}
                              <Tooltip
                                arrow
                                placement="top"
                                title={`Created: ${getExactTime(x.updatedAt)}`}
                              >
                                <IconButton
                                  size="small"
                                  onClick={() => handleShowDate(x.updatedAt)}
                                >
                                  <InfoIcon color="info" />
                                </IconButton>
                              </Tooltip>
                              <br />
                              {showDate.includes(x.updatedAt) &&
                                getExactTime(x.updatedAt)}
                            </Typography>
                            <Divider sx={{ my: 1 }} />
                          </>
                        )}
                      </CardContent>
                      <CardActions>
                        <Button
                          size="small"
                          color="warning"
                          startIcon={<EditIcon />}
                          onClick={() => {
                            setEditDlg(true);
                            setEditBuffer(x);
                          }}
                        >
                          {translate("Edit")}
                        </Button>
                        <Button
                          size="small"
                          color="error"
                          startIcon={<DeleteIcon />}
                          onClick={() => {
                            setDeleteDlg(true);
                            setDeleteBuffer(x);
                          }}
                        >
                          {translate("Delete")}
                        </Button>
                      </CardActions>
                    </Card>
                  )}
                </Grid>
              ))}
            </Grid>
          </Box>
        </>
      )}
      <Dialog
        open={deleteDlg}
        onClose={() => {
          setDeleteDlg(false);
          setDeleteBuffer({});
        }}
      >
        <DialogTitle>
          Do you want to delete{" "}
          <span style={{ color: "red" }}>"{deleteBuffer.methodName}"</span> ?
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setDeleteDlg(false);
              setDeleteBuffer({});
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleDeleteMethod(deleteBuffer._id)}
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* Edit */}
      {editDlg && (
        <EditPaymentMethods
          lang={lang}
          langId={langId}
          editPaymentDlg={editDlg}
          setEditPaymentDlg={setEditDlg}
          paymentListEn={paymentListEn}
          editBuffer={editBuffer}
        />
      )}
    </>
  );
}
