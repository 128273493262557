import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {
  Box,
  Grid,
  InputAdornment,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useDispatch } from "react-redux";
import {
  getOfficeExpense,
  storeOfficeExpense,
} from "../../../../reduxToolkit/features/adminPanel/expense";
import LoadingButton from "@mui/lab/LoadingButton";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const AddOfficeExpenseDlg = ({
  open,
  setOpen,
  lastSelectedDate,
  setLastSelectedDate,
}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const initialState = {
    date: lastSelectedDate,
    desc: "",
    calculation: "",
    total: "",
    isPlus: false,
  };

  const [newExpense, setNewExpense] = useState(initialState);

  const handleAdd = () => {
    setLoading(true);
    let obj = { ...newExpense };
    dispatch(storeOfficeExpense(obj));
    setTimeout(() => {
      setLoading(false);
      setNewExpense(initialState);
      dispatch(getOfficeExpense());
      setOpen(false);
    }, 3000);
  };

  const handleExpenseChange = (e, key, numeric) => {
    let obj = { ...newExpense };
    if (numeric) {
      obj[key] = e.target.value.replace(/\D/g, "");
    } else {
      obj[key] = e.target.value;
    }
    setNewExpense(obj);
  };

  return (
    <Dialog
      open={open}
      aria-labelledby={"add-office-expense"}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Add Office Expense</DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="stretch"
          flexDirection="column"
          fullWidth
          sx={{ mt: 0.5 }}
        >
          <Grid item>
            <Box>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="Date"
                  value={newExpense.date}
                  onChange={(newValue) => {
                    setLastSelectedDate(newValue);
                    let obj = { ...newExpense };
                    obj.date = newValue;
                    setNewExpense(obj);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      fullWidth
                      disabled={loading}
                      sx={{ my: 1 }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
          </Grid>
          <Grid item>
            <TextField
              label="Description"
              size="small"
              variant="outlined"
              fullWidth
              disabled={loading}
              multiline
              minRows={2}
              value={newExpense.desc}
              onChange={(e) => handleExpenseChange(e, "desc")}
            />
          </Grid>
          <Grid item>
            <TextField
              label="Calculation"
              size="small"
              variant="outlined"
              fullWidth
              disabled={loading}
              multiline
              minRows={2}
              value={newExpense.calculation}
              onChange={(e) => handleExpenseChange(e, "calculation")}
            />
          </Grid>
          <Grid item>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography color={!newExpense.isPlus ? "#e74c3c" : "inherit"}>
                Minus
              </Typography>
              <AntSwitch
                checked={newExpense.isPlus}
                onChange={(e) => {
                  let obj = { ...newExpense };
                  obj.isPlus = e.target.checked;
                  setNewExpense(obj);
                }}
                inputProps={{ "aria-label": "ant design" }}
                disabled={loading}
              />
              <Typography color={newExpense.isPlus ? "#27ae60" : "inherit"}>
                Plus
              </Typography>
            </Stack>
          </Grid>
          <Grid item>
            <TextField
              label="Total"
              size="small"
              variant="outlined"
              fullWidth
              disabled={loading}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">৳</InputAdornment>
                ),
              }}
              value={newExpense.total}
              onChange={(e) => handleExpenseChange(e, "total", true)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} disabled={loading} color="error">
          Close
        </Button>
        <LoadingButton loading={loading} onClick={handleAdd} color="success">
          Add
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddOfficeExpenseDlg;
