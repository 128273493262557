import AddShoppingCart from "@mui/icons-material/AddShoppingCart";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { PF } from "../../config";
import { makeUrlFriendly } from "../../hooks/makeUrlFriendly";

import {
  addToWishlist,
  removeFromWishlist,
} from "../../reduxToolkit/features/frontend/wishlist.js";

// Icons
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import CompareIcon from "@mui/icons-material/Compare";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";

// Media Query
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getSeoTags } from "../../reduxToolkit/features/adminPanel/medicine";
import {
  addToCompare,
  removeFromCompare,
} from "../../reduxToolkit/features/frontend/compare";
import {
  askPrice,
  getAskPriceListByCustomer,
} from "../../reduxToolkit/features/frontend/checkout";
import { promptLogin } from "../../reduxToolkit/features/auth";
import { getNotificationByCustomer } from "../../reduxToolkit/features/miscFront";
import LoadingButton from "@mui/lab/LoadingButton";

export default function ProductLarge({
  x,
  i,
  view,
  catList,
  lang,
  setCompareOpen,
}) {
  const dispatch = useDispatch();
  // Responsive
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  // use State
  const [companyList, setCompanyList] = useState([]);

  // const [showAllDosage, setShowAllDosage] = useState([]);

  const [wishListExist, setWishListExist] = useState(false);
  const [compareListExist, setCompareListExist] = useState(false);

  const [dosageList, setDosageList] = useState([]);

  // Dialogs
  const [smallAskClicked, setSmallAskClicked] = useState(false);
  const [askPriceDlg, setAskPriceDlg] = useState(false);
  const [askPriceBuffer, setAskPriceBuffer] = useState("");
  const [askPriceVariant, setAskPriceVariant] = useState("");
  const [askPriceLoading, setAskPriceLoading] = useState(false);
  const [askPriceDisable, setAskPriceDisable] = useState(false);
  const [enableCart, setEnableCart] = useState(false);

  // LocalData
  const localCustomerData = JSON.parse(localStorage.getItem("loginInfo"));

  // Store
  const { medVariants, companies, seoTags, dosages } = useSelector(
    (state) => state.medicine
  );
  const { compareList } = useSelector((state) => state.compare);
  const { wishlist } = useSelector((state) => state.wishlist);
  const { langs } = useSelector((state) => state.misc);
  const { askPriceListByCustomer, askPriceResponse } = useSelector(
    (state) => state.checkout
  );
  const { webSettings } = useSelector((state) => state.webSettings);

  // Functions
  const getDefaultImage = () => {
    const I = x.image.find((i) => i.default);
    if (I) return I;
    else return { src: "" };
  };

  const getCat = (subCatId) => {
    let cat = "";
    let subCat = "";
    catList.map((x) => {
      let Sc = x.subCatList.find(
        (sc) =>
          (lang && lang !== "en" ? sc.subCatDetectorId : sc.fixedSubCatId) ===
          subCatId
      );
      if (Sc) {
        subCat = Sc.subCatName;
        cat = x.catName;
      }
      return 0;
    });
    return { cat, subCat };
  };

  const getVariant = (variantId) => {
    let variant = {};
    if (medVariants.length > 0) {
      variant = medVariants.find((mv) => mv.fixedVariantId === variantId);
    }
    return variant;
  };

  const getCompany = (companyId) => {
    if (companyList.length > 0) {
      let cName = "";
      let cImage = "";
      let C = companyList.find(
        (c) =>
          (lang && lang === "en" ? c.fixedCompanyId : c.companyDetectorId) ===
          companyId
      );
      if (C) {
        cName = C.companyName;
        cImage = C.companyLogo;
      }
      return { cName, cImage };
    }
  };

  const getTag = (tagsId) => {
    let obj = { tagName: "", tagDetails: "" };
    if (seoTags.length > 0) {
      const St = seoTags.find((s) => s.lang === lang);
      if (St) {
        const T = St.data.find(
          (t) => (lang !== "en" ? t.tagDetectorId : t.fixedId) === tagsId
        );
        if (T) obj = T;
      }
    }
    return obj;
  };

  const purifyWithMedInfo = (medicineInfo, str) => {
    let catArr = [];
    let subCatArr = [];
    medicineInfo.subCats.forEach((x) => {
      subCatArr.push(getCat(x.subCatId).subCat);
      catArr.push(getCat(x.subCatId).cat);
    });
    const subCatStr = subCatArr.join(", ");
    var unique = [...new Set(catArr)];
    const catStr = unique.join(", ");
    str = str.replace(/{_medicine_name}/g, medicineInfo.genericBrand);
    str = str.replace(/{_sub_category_name}/g, subCatStr);
    str = str.replace(/{_category_name}/g, catStr);
    return str;
  };
  const getVariantDetails = (variants) => {
    let dosageArr = [];
    let strArr = [];
    let packArr = [];
    variants.length > 0 &&
      variants.forEach((x) => {
        dosageArr.push(getVariant(x.variantId).dosageForm);
        strArr.push(getVariant(x.variantId).strength);
        packArr.push(getVariant(x.variantId).packTypes);
      });
    var uniqueDosageArr = [...new Set(dosageArr)];
    var uniqueStrArr = [...new Set(strArr)];
    var uniquePackArr = [...new Set(packArr)];
    let obj = {
      dosage: uniqueDosageArr.join(", "),
      str: uniqueStrArr.join(", "),
      pack: uniquePackArr.join(", "),
    };
    return obj;
  };

  const handleWishlist = () => {
    let obj = {};
    if (lang !== "en") obj.medId = x.medicineDetectorId;
    else obj.medId = x.fixedMedicineId;
    if (localCustomerData && localCustomerData._id)
      obj.customerId = localCustomerData._id;
    if (
      wishlist.includes(
        lang !== "en" ? x.medicineDetectorId : x.fixedMedicineId
      )
    )
      dispatch(removeFromWishlist(obj));
    else dispatch(addToWishlist(obj));
  };

  const handleCompare = () => {
    let obj = {};
    if (lang !== "en") obj.medId = x.medicineDetectorId;
    else obj.medId = x.fixedMedicineId;
    if (localCustomerData && localCustomerData._id)
      obj.customerId = localCustomerData._id;
    if (
      compareList.includes(
        lang !== "en" ? x.medicineDetectorId : x.fixedMedicineId
      )
    )
      dispatch(removeFromCompare(obj));
    else dispatch(addToCompare(obj));
  };

  const getDosageInfo = (dosageId) => {
    let dosageName = "";
    const D = dosageList.find((d) =>
      lang !== "en" ? d.dosageDetectorId : d.fixedDosageId === dosageId
    );
    if (D && D.dosageName) dosageName = D.dosageName;

    return dosageName;
  };

  const handleAskPriceDlgClose = () => {
    // setAskPriceBuffer("");
    setAskPriceVariant("");
    setSmallAskClicked(false);
  };
  const getLangId = (langCode) => {
    const L = langs.find((l) => l.langCode === langCode);
    if (L && L._id) return L._id;
    else return 0;
  };

  const handleAskForPrice = (variantId) => {
    if (localCustomerData && localCustomerData._id) {
      let obj = {
        customerId: localCustomerData._id,
        medId: lang !== "en" ? x.medicineDetectorId : x.fixedMedicineId,
        medName: x.genericBrand,
        url: makeUrlFriendly(x.genericBrand),
        variantId:
          lang !== "en"
            ? getVariant(variantId).variantDetectorId
            : getVariant(variantId).fixedVariantId,
        langId: getLangId(lang),
        email: localCustomerData.email,
      };
      dispatch(askPrice(obj));
      setAskPriceLoading(true);
      setTimeout(() => {
        handleAskPriceDlgClose();
        setAskPriceLoading(false);
      }, 1000);
    } else {
      dispatch(promptLogin());
    }
  };

  const checkIfAsked = (variantId) => {
    let foundVariant = false;
    foundVariant = askPriceListByCustomer.some(
      (el) =>
        el.variantId ===
        (lang !== "en"
          ? getVariant(variantId).variantDetectorId
          : getVariant(variantId).fixedVariantId)
    );
    return foundVariant;
  };

  // Get Alt Tag
  const getSubCatString = (subCatArr) => {
    let subCatNameArr = [];

    const arr = [...subCatArr];
    arr.forEach((a) => {
      catList.forEach((x) => {
        let Sc = x.subCatList.find(
          (sc) =>
            (lang && lang !== "en" ? sc.subCatDetectorId : sc.fixedSubCatId) ===
            a.subCatId
        );
        if (Sc) {
          subCatNameArr.push(Sc.subCatName);
        }
      });
    });

    return subCatNameArr.join(", ") || "";
  };

  // Use Effect
  useEffect(() => {
    if (companies && companies.length > 0) {
      let C = companies.find((c) => c.lang === lang);
      if (C) setCompanyList(C.data);
    }
  }, [companies, lang]);
  useEffect(() => {
    dispatch(getSeoTags());
  }, []);

  // Check if in wishlist
  useEffect(() => {
    if (wishlist) {
      wishlist.includes(
        lang !== "en" ? x.medicineDetectorId : x.fixedMedicineId
      )
        ? setWishListExist(true)
        : setWishListExist(false);
    }
  }, [wishlist]);

  // Check if in compareList
  useEffect(() => {
    if (compareList) {
      compareList.includes(
        lang !== "en" ? x.medicineDetectorId : x.fixedMedicineId
      )
        ? setCompareListExist(true)
        : setCompareListExist(false);
    }
  }, [compareList]);

  // set Dosage List
  useEffect(() => {
    if (lang !== "" && dosages && dosages.length > 0) {
      const D = dosages.find((d) => d.lang === lang);
      if (D && D.data) setDosageList(D.data);
      else setDosageList([]);
    }
  }, [dosages, lang]);

  // check For Ask Price
  useEffect(() => {
    if (askPriceListByCustomer && x) {
      const foundMed = askPriceListByCustomer.some(
        (el) =>
          el.medId ===
          (lang !== "en" ? x.medicineDetectorId : x.fixedMedicineId)
      );
      if (foundMed) {
        let counter = 0;
        x.variants.forEach((y) => {
          const foundVariant = askPriceListByCustomer.some(
            (el) =>
              el.variantId ===
              (lang !== "en"
                ? getVariant(y.variantId).variantDetectorId
                : getVariant(y.variantId).fixedVariantId)
          );
          if (foundVariant) counter++;
        });
        if (counter === x.variants.length) {
          setAskPriceDisable(true);
          if (askPriceListByCustomer.priceAdded) {
            setEnableCart(true);
          } else {
            setEnableCart(false);
          }
        } else {
          setAskPriceDisable(false);
        }
      } else setAskPriceDisable(false);
    }
  }, [askPriceListByCustomer, x]);

  // check Ask Price Response
  useEffect(() => {
    if (askPriceResponse.message && localCustomerData) {
      if (!askPriceResponse.failed) {
        if (askPriceBuffer === x._id) {
          setAskPriceDlg(true);
        }
        dispatch(getAskPriceListByCustomer(localCustomerData._id));
        dispatch(getNotificationByCustomer(localCustomerData._id));
      }
    }
  }, [askPriceResponse.message]);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate

  return (
    <>
      {x && (
        <Box>
          <Zoom in={true} style={{ transitionDelay: `${100 * i + 1}ms` }}>
            <Card
              sx={{
                mx: 1,
              }}
            >
              <Link
                to={`/our-medicines/${makeUrlFriendly(x.genericBrand)}`}
                onClick={() => setCompareOpen && setCompareOpen(false)}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <LazyLoadImage
                    src={
                      getDefaultImage().src !== ""
                        ? PF +
                          "adminPanel/medicines/img/" +
                          getDefaultImage().src
                        : PF + "adminPanel/medicines/no-medicine-image.jpg"
                    }
                    alt={getSubCatString(x.subCats) + " medicine " + x.generic}
                    effect="blur"
                    placeholderSrc="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png"
                    style={{
                      objectFit: "stretch",
                      height: view === 0 ? 140 : null,
                      width: view === 0 ? null : "100%",
                    }}
                  />
                </Box>
              </Link>

              <CardContent>
                {/* <Box
                sx={
                  mdUp && {
                    maxHeight: 260,
                    overflow: "auto",
                  }
                }
              > */}
                <Grid
                  container
                  spacing={0}
                  flexDirection="column"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  sx={{
                    minHeight: view === 0 && 360,
                  }}
                >
                  <Grid item>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      align="center"
                      sx={{ ml: 0.5 }}
                    >
                      {x.genericBrand.toUpperCase()}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Divider />
                  </Grid>
                  <Grid item>
                    <Typography
                      gutterBottom
                      variant="body2"
                      component="div"
                      align="center"
                      sx={{ ml: 0.5 }}
                    >
                      {translateFront("Generic")}:{" "}
                      {x.generic ? `${x.generic.toUpperCase()}` : ``}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Divider />
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body2"
                      // color="initial"
                      sx={{ fontSize: ".8rem", ml: 0.5 }}
                    >
                      {translateFront("Dosage")}:{" "}
                      {getDosageInfo(getVariantDetails(x.variants).dosage)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body2"
                      // color="initial"
                      sx={{ fontSize: ".8rem", ml: 0.5 }}
                    >
                      {translateFront("Strength")}:{" "}
                      {getVariantDetails(x.variants).str}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body2"
                      // color="initial"
                      sx={{ fontSize: ".8rem", ml: 0.5 }}
                    >
                      {translateFront("Pack Types")}:{" "}
                      {getVariantDetails(x.variants).pack}
                    </Typography>
                  </Grid>
                  <Grid item sx={{ borderBottom: "1rem solid black" }}>
                    {/* <Divider sx={{ border: "1rem solid black" }} fullWidth /> */}
                    <Typography
                      variant="body2"
                      sx={{ borderBottom: "1px solid red" }}
                    ></Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle1"
                      color="initial"
                      sx={{ ml: 0.5, textDecoration: "underline" }}
                    >
                      {translateFront("Category")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {x.subCats.map(
                      (y, j) =>
                        getCat(y.subCatId).subCat && (
                          <Button
                            variant="text"
                            color="secondary"
                            size="small"
                            fullWidth
                            sx={{
                              pointerEvents: "none",
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                            key={j}
                          >
                            <Typography
                              variant="body2"
                              color="initial"
                              sx={{ fontSize: ".7rem" }}
                            >
                              {getCat(y.subCatId).subCat +
                                " ( " +
                                getCat(y.subCatId).cat +
                                " )"}
                            </Typography>
                          </Button>
                        )
                    )}
                  </Grid>

                  <Grid item sx={{ mt: 2 }}>
                    {webSettings.showOriginator && x.originator && (
                      <Typography
                        variant="subtitle2"
                        size="small"
                        color="secondary"
                        fullWidth
                        sx={{
                          // pointerEvents: "none",
                          ml: 0.5,
                        }}
                      >
                        {" "}
                        {`${translateFront("Originator")}: ${
                          x.originator && x.originator.toUpperCase()
                        }`}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item>
                    <Divider />
                  </Grid>
                  <Grid item>
                    {getCompany(x.companyId) &&
                      getCompany(x.companyId).cName && (
                        <Button
                          fullWidth
                          variant="text"
                          size="small"
                          color="error"
                          sx={{
                            pointerEvents: "none",
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                          endIcon={
                            <img
                              src={
                                getCompany(x.companyId).cImage &&
                                PF +
                                  "/adminPanel/companies/" +
                                  getCompany(x.companyId).cImage
                              }
                              alt={
                                getCompany(x.companyId).cName &&
                                getCompany(x.companyId).cName
                              }
                              style={{
                                height: "25px",
                                objectFit: "cover",
                                borderRadius: ".25rem",
                              }}
                            />
                          }
                        >
                          {`${getCompany(x.companyId).cName}`}
                          {/* {addToCompany(getCompany(x.companyId).cName)} */}
                        </Button>
                      )}
                  </Grid>
                  <Grid item>
                    <Divider orientation="horizontal" />
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle1"
                      color="initial"
                      sx={{ ml: 0.5, textDecoration: "underline" }}
                    >
                      {translateFront("Safety Advise")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <List dense>
                      {x.tags.length > 0 &&
                        x.tags.map((y, j) => (
                          <ListItem key={j}>
                            <Tooltip
                              arrow
                              placement="top"
                              title={
                                getTag(y.tagsId).tagDetails &&
                                purifyWithMedInfo(
                                  x,
                                  getTag(y.tagsId).tagDetails
                                )
                              }
                            >
                              <Button
                                variant="text"
                                color={getTag(y.tagsId).severity}
                                size="small"
                                fullWidth
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                }}
                              >
                                {"#" +
                                  (getTag(y.tagsId).tagName
                                    ? getTag(y.tagsId).tagName.toUpperCase()
                                    : "")}
                              </Button>
                            </Tooltip>
                          </ListItem>
                        ))}
                    </List>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Grid container spacing={0} justifyContent="flex-end">
                  <Grid item>
                    <Tooltip
                      arrow
                      placement="top"
                      title={
                        compareListExist
                          ? translateFront("Remove From Compare List")
                          : translateFront("Add To Compare")
                      }
                    >
                      <IconButton size="small" onClick={() => handleCompare()}>
                        <CompareIcon
                          color={compareListExist ? "error" : "info"}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip
                      arrow
                      placement="top"
                      title={
                        wishListExist
                          ? translateFront("Remove From Wishlist")
                          : translateFront("Add To Wishlist")
                      }
                    >
                      <IconButton size="small" onClick={() => handleWishlist()}>
                        {wishListExist ? (
                          <FavoriteIcon color="error" />
                        ) : (
                          <FavoriteBorderIcon color="primary" />
                        )}
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  {/* <Grid item>
                    {!enableCart ? (
                      <Tooltip
                        arrow
                        placement="top"
                        title={translateFront("Ask For Price")}
                      >
                        <IconButton
                          size="small"
                          onClick={() => {
                            if (x.variants.length > 0) {
                              setAskPriceBuffer(x._id);
                              let arr = [];
                              x.variants.forEach((v) => {
                                if (!checkIfAsked(v.variantId))
                                  arr.push(v.variantId);
                              });
                              if (arr.length > 0) {
                                setAskPriceVariant(arr[0]);
                              }
                              if (x.variants.length > 1) {
                                setSmallAskClicked(true);
                              } else {
                                handleAskForPrice(x.variants[0].variantId);
                              }
                            }
                          }}
                          disabled={x.variants.length < 1 || askPriceDisable}
                        >
                          <RequestQuoteIcon
                            color={
                              x.variants.length < 1 || askPriceDisable
                                ? "inherit"
                                : "success"
                            }
                          />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        arrow
                        placement="top"
                        title={translateFront("Add To Cart")}
                      >
                        <IconButton size="small">
                          <AddShoppingCart color="info" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid> */}
                  {/* <Grid item>
                    <IconButton title="Add To Cart">
                      <AddShoppingCart />
                    </IconButton>
                  </Grid> */}
                </Grid>
              </CardActions>
            </Card>
          </Zoom>
          {smallAskClicked && (
            <Dialog
              open={smallAskClicked}
              onClose={handleAskPriceDlgClose}
              aria-labelledby={"Ask-For-Price-Dialog"}
              fullWidth
              maxWidth={"sm"}
            >
              <DialogTitle sx={{ textAlign: "center" }}>
                {translateFront("Ask For Price")}
              </DialogTitle>
              <DialogContent>
                <FormControl
                  sx={{ m: 1, minWidth: 120 }}
                  size="small"
                  // variant="standard"
                  fullWidth
                  color="success"
                >
                  <InputLabel id="ask-price-variant">
                    {translateFront("Variant")}
                  </InputLabel>
                  <Select
                    autoFocus
                    labelId="ask-price-variant"
                    value={askPriceVariant}
                    label="Variant"
                    onChange={(e) => setAskPriceVariant(e.target.value)}
                  >
                    {x.variants.map((y, j) => (
                      <MenuItem
                        value={y.variantId}
                        key={j}
                        disabled={checkIfAsked(y.variantId)}
                      >
                        {getDosageInfo(getVariant(y.variantId).dosageForm)}{" "}
                        {getVariant(y.variantId).strength}{" "}
                        {getVariant(y.variantId).packTypes}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <LoadingButton
                  size="small"
                  variant="text"
                  color="success"
                  onClick={() => handleAskForPrice(askPriceVariant)}
                >
                  {translateFront("Ask Price")}
                </LoadingButton>
              </DialogActions>
            </Dialog>
          )}
          {localCustomerData && askPriceBuffer === x._id && askPriceDlg && (
            <Dialog
              open={askPriceDlg}
              onClose={() => {
                setAskPriceDlg(false);
                setAskPriceBuffer("");
              }}
              aria-labelledby={"asked-price-successfully"}
              fullWidth
              maxWidth={"md"}
            >
              <DialogContent>
                <Card>
                  <CardContent>
                    <Typography gutterBottom variant="h5" align="center">
                      {translateFront("Price Request Has Been Submitted")}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      align="center"
                    >
                      {translateFront(
                        "Please Wait, You will receive price information in email "
                      )}{" "}
                      "
                      <span style={{ color: "navy" }}>
                        {localCustomerData.email}
                      </span>
                      "
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      align="center"
                    >
                      {translateFront(
                        "You can also keep an eye on notification"
                      )}
                    </Typography>
                  </CardContent>
                </Card>
              </DialogContent>
            </Dialog>
          )}
        </Box>
      )}
    </>
  );
}
