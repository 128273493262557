import React from "react";
import { useSelector } from "react-redux";
import SocialMediaContact from "./SocialMediaContact";
import SocialMediaPages from "./SocialMediaPages";

export default function ManageSocialMedia() {
  //! Translate
  // Store
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate
  return (
    <>
      <SocialMediaPages translate0={translate0} />
      <SocialMediaContact translate0={translate0} />
    </>
  );
}
