export const changeLog = [
  {
    changeTitle: "com/ org review does not submit - done",
    changeDate: new Date(2022, 10, 1),
  },
  {
    changeTitle: "review text limit increase - done",
    changeDate: new Date(2022, 10, 1),
  },
  {
    changeTitle: "admin changes made timing add - done",
    changeDate: new Date(2022, 10, 1),
  },
  {
    changeTitle: "admin changes made timing add - done",
    changeDate: new Date(2022, 10, 1),
  },
  {
    changeTitle: "admin image load fast - done",
    changeDate: new Date(2022, 10, 1),
  },
  {
    changeTitle: "mg replace with 毫克 , мг -done",
    changeDate: new Date(2022, 10, 1),
  },
  {
    changeTitle: "originator from med edit -done",
    changeDate: new Date(2022, 10, 1),
  },
  {
    changeTitle: "payment details add all country - done",
    changeDate: new Date(2022, 10, 2),
  },
  {
    changeTitle: "payment details transaction fee - done",
    changeDate: new Date(2022, 10, 2),
  },
  {
    changeTitle: "delivery details add all country - done",
    changeDate: new Date(2022, 10, 2),
  },
  {
    changeTitle: "manual review add - done",
    changeDate: new Date(2022, 10, 2),
  },
  {
    changeTitle: "medicine view count all 3 language",
    changeDate: new Date(2022, 10, 3),
  },
  {
    changeTitle: "testimonial height fix mobile",
    changeDate: new Date(2022, 10, 3),
  },
  {
    changeTitle: "additional page section in create/ edit medicine",
    changeDate: new Date(2022, 10, 3),
  },
  {
    changeTitle: "mobile dosage not open",
    changeDate: new Date(2022, 10, 5),
  },
  {
    changeTitle: "fixed testimonial, how to order, contact us scroll to id ",
    changeDate: new Date(2022, 10, 5),
  },
  {
    changeTitle: "custom scroll bar added ",
    changeDate: new Date(2022, 10, 5),
  },
  {
    changeTitle: "fixed scroll issues ",
    changeDate: new Date(2022, 10, 5),
  },
  {
    changeTitle: "dosage in cn and ru fix",
    changeDate: new Date(2022, 10, 5),
  },
  {
    changeTitle: "add medicine view count in report - delayed",
    changeDate: new Date(2022, 10, 7),
  },
  {
    changeTitle: "front end notification count does not show properly",
    changeDate: new Date(2022, 10, 7),
  },
  {
    changeTitle: " customer registration double verification code fix",
    changeDate: new Date(2022, 10, 7),
  },
  {
    changeTitle: "customer new registration notification does not update fix",
    changeDate: new Date(2022, 10, 7),
  },
  {
    changeTitle: "Review Section Custom Image for medicine did not show fix  ",
    changeDate: new Date(2022, 10, 7),
  },
  {
    changeTitle: "Changes Made section fixed",
    changeDate: new Date(2022, 10, 7),
  },
  {
    changeTitle: "Admin Home/ DashBoard page added",
    changeDate: new Date(2022, 10, 7),
  },
  {
    changeTitle: "add/ Mange SEO tags",
    changeDate: new Date(2022, 10, 11),
  },
  {
    changeTitle: "manage payment page english variant show ",
    changeDate: new Date(2022, 10, 11),
  },
  {
    changeTitle: "Blog Single page Create",
    changeDate: new Date(2022, 10, 11),
  },
  {
    changeTitle: "Payment settings active account option",
    changeDate: new Date(2022, 10, 11),
  },
  {
    changeTitle: "QOL improvement in add & edit medicine ",
    changeDate: new Date(2022, 10, 11),
  },
  {
    changeTitle: "managed content (About Us) section in webSettings",
    changeDate: new Date(2022, 10, 11),
  },
  {
    changeTitle: "managed content (How To Order) section in webSettings",
    changeDate: new Date(2022, 10, 11),
  },
  {
    changeTitle: "About us Video",
    changeDate: new Date(2022, 10, 12),
  },
  {
    changeTitle: "fixed home page title",
    changeDate: new Date(2022, 10, 12),
  },
  {
    changeTitle: "How To Order Video",
    changeDate: new Date(2022, 10, 12),
  },
  {
    changeTitle: "Med search both eng and current",
    changeDate: new Date(2022, 10, 14),
  },
  {
    changeTitle: "Whats app phone number ",
    changeDate: new Date(2022, 10, 14),
  },
  {
    changeTitle: "Social media link create from admin panel",
    changeDate: new Date(2022, 10, 14),
  },
  {
    changeTitle: "Time another format - Done",
    changeDate: new Date(2022, 10, 14),
  },

  {
    changeTitle: "Play button in video - Done",
    changeDate: new Date(2022, 10, 14),
  },
  {
    changeTitle: "Pack size select",
    changeDate: new Date(2022, 10, 14),
  },
  {
    changeTitle: "Discount",
    changeDate: new Date(2022, 10, 14),
  },
  {
    changeTitle: "Tags add med info",
    changeDate: new Date(2022, 10, 14),
  },
  {
    changeTitle: "Generic brand or originator for meta keyword",
    changeDate: new Date(2022, 10, 14),
  },
  {
    changeTitle: "Add med eng loading",
    changeDate: new Date(2022, 10, 14),
  },
  {
    changeTitle: "Strength mg",
    changeDate: new Date(2022, 10, 14),
  },
  {
    changeTitle: "Assign price specific for a client",
    changeDate: new Date(2022, 10, 14),
  },

  {
    changeTitle: "Contact Info",
    changeDate: new Date(2022, 10, 17),
  },
  {
    changeTitle: "Footer Text",
    changeDate: new Date(2022, 10, 17),
  },
  {
    changeTitle:
      "Additional information and faq choose from previous -  not required",
    changeDate: new Date(2022, 10, 17),
  },
  {
    changeTitle: "Url translate - url can only be english",
    changeDate: new Date(2022, 10, 17),
  },
  {
    changeTitle: "Select from multiple variant - Not possible",
    changeDate: new Date(2022, 10, 17),
  },
  {
    changeTitle: "browser info using javascript",
    changeDate: new Date(2022, 10, 17),
  },
  {
    changeTitle: "get ip while login -  too many request for free tier",
    changeDate: new Date(2022, 10, 17),
  },
  {
    changeTitle: "Email Template Section (Email Verification)  ",
    changeDate: new Date(2022, 10, 19),
  },
  {
    changeTitle: "Email Template Section (registration success)  ",
    changeDate: new Date(2022, 10, 20),
  },
  {
    changeTitle: "Email Template Section (Ask Price)  ",
    changeDate: new Date(2022, 10, 20),
  },
  {
    changeTitle: "Email Template Section (Assign Price)  ",
    changeDate: new Date(2022, 10, 20),
  },
  {
    changeTitle: "Email Template Section (Update Price)  ",
    changeDate: new Date(2022, 10, 20),
  },
  {
    changeTitle: "Email Template Section (Order Created)  ",
    changeDate: new Date(2022, 10, 22),
  },
];
