import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
// Icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ManagePayment from "./components/ManagePayment";
import PaymentSettings from "./components/PaymentSettings";

export default function PaymentScreen() {
  const [expanded, setExpanded] = React.useState(false);

  // Store
  const { panel } = useSelector((state) => state.notification);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // handle Panel Based on Notification
  useEffect(() => {
    setExpanded(panel);
  }, [panel]);

  // ! translate
  const [siteLang, setSiteLang] = useState("");
  // Local Store
  const localLangData = JSON.parse(localStorage.getItem("lang"));
  // Store
  const { langs, activeLang } = useSelector((state) => state.misc);
  const [langSettings, setLangSettings] = useState([]);
  const title1 = "Manage Payment Method";
  const title2 = "Misc Words";
  const title3 = "Payment Settings";

  useEffect(() => {
    if (langs && langs.length > 0 && siteLang !== "") {
      const L = langs.find((l) => l.langCode === siteLang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      let arrLang = [];
      const A = arr.find((l) => l._title === title1);
      if (A) arrLang = [...arrLang, ...A.data];
      const B = arr.find((l) => l._title === title2);
      if (B) arrLang = [...arrLang, ...B.data];
      const C = arr.find((l) => l._title === title3);
      if (C) arrLang = [...arrLang, ...C.data];
      setLangSettings(arrLang);
    }
  }, [siteLang, langs]);

  const translate = (String) => {
    let translation = "";
    let settings = [...langSettings];
    const T = settings.find((ls) => ls.title === String);
    if (T && T.value) {
      let obj = { ...T };
      if (T.value === "") obj.value = T.title;
      translation = obj.value;
    }
    return translation;
  };

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setSiteLang(activeLang.langCode);
    } else {
      if (localLangData) {
        setSiteLang(localLangData.langCode);
      }
    }
  }, [activeLang]);
  // ! translate

  return (
    <Fragment>
      <Typography
        variant="h4"
        color="initial"
        align="center"
        sx={{ borderBottom: ".2rem solid black", mb: 2 }}
      >
        {translate("Manage Payment Method")}
      </Typography>
      {deliveryAcMenu.map((x, i) => (
        <Accordion
          key={i}
          expanded={expanded === deliveryAcMenu[i].panelName}
          onChange={handleChange(deliveryAcMenu[i].panelName)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={deliveryAcMenu[i].panelName}
            id={deliveryAcMenu[i].panelName}
          >
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <Typography>
                  {translate(deliveryAcMenu[i].headPrimary)}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={{ color: "text.secondary" }}>
                  {translate(deliveryAcMenu[i].headSecondary)}
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          {expanded === deliveryAcMenu[i].panelName && (
            <AccordionDetails>{deliveryAcMenu[i].component}</AccordionDetails>
          )}
        </Accordion>
      ))}
    </Fragment>
  );
}

const deliveryAcMenu = [
  {
    panelName: "manage-payment-methods",
    headPrimary: "Manage Payment Method",
    headSecondary: "Add/ Manage Payment Method",
    component: <ManagePayment />,
  },
  {
    panelName: "payment-settings",
    headPrimary: "Payment Settings",
    headSecondary: "Configure Payment Settings",
    component: <PaymentSettings />,
  },
];
