import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { censorEmail, censorPhone } from "../../hooks/censor";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Rating,
  Slide,
  Typography,
  TextField,
  AppBar,
  Toolbar,
  IconButton,
  FormControlLabel,
  Checkbox,
  Tooltip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";

// Icons
import CloseIcon from "@mui/icons-material/Close";
import CircleIcon from "@mui/icons-material/Circle";

// lazy load image
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { PF } from "../../config";

// Media Query
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useState } from "react";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import LoadingButton from "@mui/lab/LoadingButton";
import { useEffect } from "react";
import { getReqRule } from "../../reduxToolkit/features/adminPanel/review";
import LoadingBox from "./Loading";
import { emailVerify } from "../../reduxToolkit/features/auth";
import EmailVerificationDialog from "../EmailVerificationDialog";
import { submitReq } from "../../reduxToolkit/features/customerOnProduct";
import CaptchaTikTakToe from "./CaptchaTikTakToe";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ContactReviewer({
  reviewerDlg,
  setReviewerDlg,
  reviewerBuffer,
  setReviewerBuffer,
  customers,
  lang,
  genericBrand,
}) {
  const dispatch = useDispatch();
  // Media Query
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  //   useState
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [phone, setPhone] = useState("");
  const [requestSent, setRequestSent] = useState(false);

  const [btnDisable, setBtnDisable] = useState(true);

  const [ruleList, setRuleList] = useState([]);
  const [ruleLoading, setRuleLoading] = useState(false);

  // const [captchaDlg, setCaptchaDlg] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  // const [skipEmailVerify, setSkipEmailVerify] = useState(false);
  const [verifyEmailLoading, setVerifyEmailLoading] = useState(false);
  const [vCode, setVCode] = useState("");
  const [verifyDlg, setVerifyDlg] = useState(false);
  const [verifyCodeInput, setVerifyCodeInput] = useState("");

  // Count down
  const [countDownStart, setCountDownStart] = React.useState("");

  // Local Store
  const localLoginData = JSON.parse(localStorage.getItem("loginInfo"));
  const localLangData = JSON.parse(localStorage.getItem("langFront"));

  // Store
  const { langs } = useSelector((state) => state.misc);
  const { reviewRequestRules } = useSelector((state) => state.review);
  const { webSettings } = useSelector((state) => state.webSettings);
  const { emailResponse } = useSelector((state) => state.auth);

  // Functions
  const getCustomerInfo = (customerId) => {
    const C = customers.find((c) => c._id === customerId);
    if (C) return C;
    return {};
  };

  const handleRequest = () => {
    if (!emailVerified) {
      handleVerifyEmail();
    } else {
      if (requestSent) {
        handleSubmitReviewerInfoReq();
        handleReviewerClose();
      } else {
        if (ruleList.length > 0) {
          setRequestSent(true);
        } else {
          handleSubmitReviewerInfoReq();
        }
      }
    }
  };

  const handleSubmitReviewerInfoReq = () => {
    setLoading(true);
    const obj = {
      reviewId: reviewerBuffer._id,
      requesterEmail: email,
      requesterPhone: phone,
      genericBrand,
    };
    if (localLoginData._id) {
      obj.customerId = localLoginData._id;
    }
    if (localLangData._id) {
      obj.langId = localLangData._id;
    }
    dispatch(submitReq(obj));
    setTimeout(() => {
      setLoading(false);
      handleReviewerClose();
    }, 1000);
  };

  const handleVerifyEmail = () => {
    setCountDownStart(
      new Date().getTime() + (webSettings.verificationInterval || 1) * 60000
    );
    let v = Math.floor(Math.random() * (999999 - 100000 + 1) + 100000);
    setVCode(v);
    v = v * 3875412698;
    dispatch(emailVerify({ v, email, langId: getLangId(lang) }));
    setVerifyEmailLoading(true);
  };

  const handleReviewerClose = () => {
    setReviewerDlg(false);
    setReviewerBuffer({});
  };

  const getLangId = (langCode) => {
    let langId = "";
    const L = langs.find((l) => l.langCode === langCode);
    if (L) langId = L._id;
    return langId;
  };

  //   UseEffect
  useEffect(() => {
    dispatch(getReqRule());
  }, []);

  // Set Rules
  useEffect(() => {
    // langs
    if (reviewRequestRules && reviewRequestRules.length > 0) {
      setRuleLoading(true);
      const R = reviewRequestRules.filter((r) => r.langId === getLangId(lang));
      if (R && R.length) setRuleList(R);
      setTimeout(() => {
        setRuleLoading(false);
      }, 1000);
    }
  }, [reviewRequestRules]);

  // Check if login
  useEffect(() => {
    if (localLoginData && localLoginData.email) {
      // setEmailVerified(true);
      // setCaptchaDlg(true);
      setEmail(localLoginData.email);
      setPhone(localLoginData.phone);
    }
  }, []);

  useEffect(() => {
    let errCounter = 0;
    if (email.length < 1) errCounter++;
    if (!phone) errCounter++;
    if (phone && phone.length < 1) errCounter++;
    if (!email.includes("@")) errCounter++;
    errCounter > 0 ? setBtnDisable(true) : setBtnDisable(false);
  }, [email, phone]);

  // check Email Response
  useEffect(() => {
    if (emailResponse.message) {
      if (!emailResponse.failed) setVerifyDlg(true);
      setVerifyEmailLoading(false);
    }
  }, [emailResponse]);

  // Check Verification Code
  useEffect(() => {
    setVerifyEmailLoading(true);
    if (vCode === Number(verifyCodeInput)) {
      setVerifyDlg(false);
      setEmailVerified(true);
      // setCaptchaDlg(true);
      setVerifyCodeInput("");
    }
    setVerifyEmailLoading(false);
  }, [verifyCodeInput]);

  useEffect(() => {
    if (emailVerified) handleRequest();
  }, [emailVerified]);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate

  return (
    <>
      <Dialog
        open={reviewerDlg}
        aria-labelledby={"contact-reviewer-dialog"}
        TransitionComponent={Transition}
        fullScreen={!smUp}
        fullWidth={smUp}
        maxWidth={"sm"}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Grid
              container
              spacing={0}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item sx={{ flexGrow: 1 }}>
                <Typography
                  //   sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                >
                  {translateFront("Contact Reviewer")}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleReviewerClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Card>
            {loading ? (
              <LoadingBox w={"100%"} h={"400px"} />
            ) : (
              <CardContent>
                {!requestSent ? (
                  <>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <LazyLoadImage
                        effect="blur"
                        src={
                          reviewerBuffer.customerId
                            ? getCustomerInfo(reviewerBuffer.customerId).gender
                              ? getCustomerInfo(reviewerBuffer.customerId)
                                  .gender === "male"
                                ? PF +
                                  "frontEnd/default/man-default-customer-image.jpg"
                                : getCustomerInfo(reviewerBuffer.customerId)
                                    .gender === "female" &&
                                  PF +
                                    "frontEnd/default/women-default-customer-image.jpg"
                              : "https://picsum.photos/200/300"
                            : "https://as1.ftcdn.net/v2/jpg/00/57/04/58/1000_F_57045887_HHJml6DJVxNBMqMeDqVJ0ZQDnotp5rGD.jpg"
                        }
                        alt={`customer`}
                        style={{
                          height: 300,
                          objectFit: "cover",
                          borderRadius: "50%",
                          marginBottom: 10,
                        }}
                      />
                    </Box>
                    <Typography variant="body2" color="text.secondary">
                      {reviewerBuffer.customerId
                        ? `${censorEmail(
                            getCustomerInfo(reviewerBuffer.customerId).email
                          )}`
                        : `${censorEmail(reviewerBuffer.customerEmail)}`}
                    </Typography>
                    <Typography gutterBottom variant="body2">
                      {reviewerBuffer.customerId
                        ? `${censorPhone(
                            getCustomerInfo(reviewerBuffer.customerId).phone
                          )}`
                        : `${censorPhone(reviewerBuffer.customerPhone)}`}
                    </Typography>
                    <Typography variant="h6">
                      {reviewerBuffer.review}
                    </Typography>
                    <Rating
                      value={reviewerBuffer.rating}
                      precision={0.5}
                      readOnly
                    />
                    <TextField
                      label={translateFront("Your Email")}
                      placeholder={translateFront("Enter Your Email...")}
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setInvalidEmail(false);
                      }}
                      onBlur={() =>
                        !email.includes("@") && setInvalidEmail(true)
                      }
                      size="small"
                      variant="standard"
                      fullWidth
                      helperText={
                        invalidEmail && (
                          <Typography variant="body2" color="error">
                            {translateFront("Invalid Email")}
                          </Typography>
                        )
                      }
                    />
                    <Typography variant="body2" color="inherit" sx={{ mt: 1 }}>
                      {translateFront("Your Phone")} *
                    </Typography>
                    <PhoneInputWithCountrySelect
                      placeholder={translateFront("Enter Phone Number")}
                      international
                      value={phone}
                      defaultCountry="US"
                      onChange={setPhone}
                    />
                  </>
                ) : (
                  <List dense>
                    {ruleLoading ? (
                      <LoadingBox w={"100%"} />
                    ) : (
                      ruleList.length > 0 &&
                      ruleList.map((x, i) => (
                        <>
                          <ListItem>
                            <ListItemIcon>
                              <CircleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={x.rule.toUpperCase()} />
                          </ListItem>
                          <Divider variant="middle" />
                        </>
                      ))
                    )}
                  </List>
                )}
                <Grid container spacing={1} justifyContent="space-between">
                  {requestSent && (
                    <Grid item xs={12} sm={3}>
                      <Button
                        size="small"
                        variant="contained"
                        color="warning"
                        fullWidth
                        onClick={() => setRequestSent(false)}
                      >
                        {" "}
                        {translateFront("Back")}
                      </Button>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={requestSent ? 9 : 12}>
                    <LoadingButton
                      variant="contained"
                      size="small"
                      loading={verifyEmailLoading}
                      fullWidth
                      onClick={handleRequest}
                      disabled={btnDisable ? true : false}
                    >
                      {requestSent
                        ? translateFront("Agree & Submit")
                        : translateFront("Request")}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </CardContent>
            )}
          </Card>
        </DialogContent>
      </Dialog>
      {/* Verification dialog */}
      <EmailVerificationDialog
        verifyDlg={verifyDlg}
        setVerifyDlg={setVerifyDlg}
        verifyCodeInput={verifyCodeInput}
        setVerifyCodeInput={setVerifyCodeInput}
        countDownStart={countDownStart}
        setCountDownStart={setCountDownStart}
        logic={handleVerifyEmail}
      />
      {/* Captcha Dialog */}
      {/* <Dialog
        open={captchaDlg}
        onClose={() => setCaptchaDlg(false)}
        aria-labelledby={"register-user"}
      >
        <DialogContent>
          <CaptchaTikTakToe
            loginFunc={() => setEmailVerified(true)}
            setCaptchaDlg={setCaptchaDlg}
          />
        </DialogContent>
      </Dialog> */}
    </>
  );
}
