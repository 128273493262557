import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Slide,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Badge,
  Divider,
  Autocomplete,
  createFilterOptions,
  TextField,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useDispatch, useSelector } from "react-redux";
import { PF } from "../../../../config";
import { ExpandMore } from "@mui/icons-material";
import {
  getCompanies,
  getDosages,
  getMedCategories,
  getMedicines,
  getSeoTags,
} from "../../../../reduxToolkit/features/adminPanel/medicine";
import { getSuppliers } from "../../../../reduxToolkit/features/adminPanel/supplier";
import {
  getCurrency,
  getLangs,
  updateCurrency,
} from "../../../../reduxToolkit/features/adminPanel/misc";
import { useRef } from "react";
import LoadingBox from "../../../../components/misc/Loading";
import getSymbolFromCurrency from "currency-symbol-map";
import LoadingButton from "@mui/lab/LoadingButton";

// Icons
import UpdateIcon from "@mui/icons-material/Update";

// Limit options to show in autocomplete
const OPTIONS_LIMIT = 8;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) => {
  return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
};

export default function ViewProductDetails({ medId, varId, lang }) {
  const dispatch = useDispatch();

  // varId = false;

  // Ref
  const identificationRef = useRef();
  const priceRef = useRef();
  const moreRef = useRef();

  const [loading, setLoading] = useState(false);
  const [loadingMain, setLoadingMain] = useState(false);
  const [medObj, setMedObj] = useState({});
  const [activeVariant, setActiveVariant] = useState(0);

  // Currency
  const [currencySymbol, setCurrencySymbol] = useState("USA");
  const [selectedCurrency, setSelectedCurrency] = useState(1);

  // Store
  const { medicines, categories, companies, medVariants, dosages } =
    useSelector((state) => state.medicine);
  const { langs, currencyList } = useSelector((state) => state.misc);

  // ? Functions
  const getLangCode = (langId) => {
    const L = langs.find((l) => l._id === langId);
    if (L && L.langCode) return L.langCode;
    else return "en";
  };

  const scrollToRef = (ref) =>
    setTimeout(() => ref.current.scrollIntoView({ behavior: "smooth" }), 250);

  const getCat = (subCatId) => {
    let cat = "";
    let subCat = "";
    let catList = [];
    if (lang !== "" && categories && categories.length > 0) {
      let C = categories.find((c) => c.lang === lang);
      if (C) catList = C.data;
      catList.map((x) => {
        let Sc = x.subCatList.find(
          (sc) =>
            (lang && lang !== "en" ? sc.subCatDetectorId : sc.fixedSubCatId) ===
            subCatId
        );
        if (Sc) {
          subCat = Sc.subCatName;
          cat = x.catName;
        }
        return 0;
      });
    }
    return { cat, subCat };
  };

  const getDosageInfo = (dosageId, langCode) => {
    let dosageName = "";
    if (dosages && dosages.length > 0) {
      const dosageList = dosages.find((d) => d.lang === langCode);
      if (dosageList && dosageList.data) {
        const D = dosageList.data.find((d) =>
          langCode !== "en" ? d.dosageDetectorId : d.fixedDosageId === dosageId
        );
        if (D && D.dosageName) dosageName = D.dosageName;
      }
    }
    return dosageName;
  };
  const getVariantInfo = (variantId) => {
    let obj = {};
    if (medVariants && medVariants.length > 0) {
      const V = medVariants.find(
        (v) =>
          (getLangCode(lang) !== "en"
            ? v.variantDetectorId
            : v.fixedVariantId) === variantId
      );

      if (V) {
        let str = "";
        str =
          str +
          " ( " +
          getDosageInfo(V.dosageForm, lang) +
          " - " +
          V.strength +
          " - " +
          V.packTypes +
          " ) ";
        obj.title = str;
        obj.sellingPrice = V.sellingPrice;
        obj.MOQ = V.minimumOrderQty;
        obj.avgPriceOfOriginator = V.avgPriceOfOriginator;
        obj.companyMRP = V.companyMRP;
        obj.companyPatientSellingPriceOld = V.companyPatientSellingPriceOld;
        obj.companyPatientSellingPriceUpdated =
          V.companyPatientSellingPriceUpdated;
        obj.dosageForm = getDosageInfo(V.dosageForm, lang);
        obj.packTypes = V.packTypes;
        obj.strength = V.strength;
        obj.vipSellingPrice = V.vipSellingPrice;
        obj.weight = V.weight;
      } else {
        obj.title = "";
        obj.sellingPrice = "";
        obj.MOQ = "";
        obj.avgPriceOfOriginator = "";
        obj.companyMRP = "";
        obj.companyPatientSellingPriceOld = "";
        obj.companyPatientSellingPriceUpdated = "";
        obj.dosageForm = "";
        obj.packTypes = "";
        obj.strength = "";
        obj.vipSellingPrice = "";
        obj.weight = V.weight;
      }
    }

    return obj;
  };

  //   update Currency
  const handleUpdateCurrency = () => {
    setLoading(true);
    dispatch(updateCurrency());
    dispatch(getCurrency());
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  //   handle Currency Change
  const handleCurrencyChange = (e, newValue) => {
    setSelectedCurrency(newValue.exchangeRate);
    setCurrencySymbol(newValue.currencyName);
  };

  // ? UseEffect
  useEffect(() => {
    setLoadingMain(true);
    if (!langs) dispatch(getLangs());
    if (!medicines) dispatch(getMedicines());
    if (!dosages) dispatch(getDosages());
    if (!categories) dispatch(getMedCategories());
    if (!companies) dispatch(getCompanies());
    // dispatch(getSuppliers());
    // dispatch(getSeoTags());
    if (!currencyList) dispatch(getCurrency());
    setTimeout(() => {
      setLoadingMain(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (!loadingMain) {
      setLoading(true);
      let obj = {};
      if (medicines) {
        const M = medicines.find((m) => m.lang === lang);
        if (M && M.data) {
          const Med = M.data.find(
            (med) =>
              (lang === "en" ? med.fixedMedicineId : med.medicineDetectorId) ===
              medId
          );
          if (Med) {
            obj.genericBrand = Med.genericBrand;
            obj.generic = Med.generic;
            obj.originator = Med.originator;
            // Set Company
            if (companies && companies.length > 0) {
              const C = companies.find((c) => c.lang === lang);
              if (C && C.data) {
                const company = C.data.find(
                  (c) =>
                    (lang !== "en" ? c.companyDetectorId : c.fixedCompanyId) ===
                    Med.companyId
                );
                if (company) {
                  obj.company = company.companyName;
                  obj.companyLogo = company.companyLogo;
                }
              }
            }
            // Set Category
            let catArr = [];
            if (Med.subCats && Med.subCats.length > 0) {
              Med.subCats.forEach((x) => {
                catArr.push(
                  getCat(x.subCatId).subCat +
                    " (" +
                    getCat(x.subCatId).cat +
                    ")"
                );
              });
            }
            obj.cat = catArr.join(", ");
            // Set Image
            if (Med.image && Med.image.length > 0) {
              const image = Med.image.find((i) => i.default);
              obj.image = image.src;
            }
            // Set Stock
            obj.stock = Med.stock || 0;
            // Set More
            obj.visible = Med.visible ? true : false;
            obj.prescribed = Med.prescribed ? true : false;
            obj.topSeller = Med.topSeller ? true : false;
            obj.dealOfTheWeek = Med.dealOfTheWeek ? true : false;
            obj.new = Med.new ? true : false;
          }
          if (varId) {
            if (medVariants && medVariants.length > 0) {
              const V = medVariants.find(
                (v) =>
                  (getLangCode(lang) !== "en"
                    ? v.variantDetectorId
                    : v.fixedVariantId) === varId
              );
              if (V) {
                obj.sellingPrice = V.sellingPrice;
                obj.MOQ = V.minimumOrderQty;
                obj.avgPriceOfOriginator = V.avgPriceOfOriginator;
                obj.companyMRP = V.companyMRP;
                obj.companyPatientSellingPriceOld =
                  V.companyPatientSellingPriceOld;
                obj.companyPatientSellingPriceUpdated =
                  V.companyPatientSellingPriceUpdated;
                obj.dosageForm = getDosageInfo(V.dosageForm, lang);
                obj.packTypes = V.packTypes;
                obj.strength = V.strength;
                obj.vipSellingPrice = V.vipSellingPrice;
                obj.weight = V.weight;
              }
            }
          } else {
            obj.title = "";
            obj.sellingPrice = "";
            obj.MOQ = "";
            obj.avgPriceOfOriginator = "";
            obj.companyMRP = "";
            obj.companyPatientSellingPriceOld = "";
            obj.companyPatientSellingPriceUpdated = "";
            obj.dosageForm = "";
            obj.packTypes = "";
            obj.strength = "";
            obj.vipSellingPrice = "";
            obj.weight = "";

            obj.variantArr = Med.variants || [];
          }
        }
      }
      setMedObj({ ...obj });
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [lang, loadingMain]);

  //   Set USD as default currency
  useEffect(() => {
    if (currencyList.length > 0) {
      const C = currencyList.find((x) => x.currencyName === "USD");
      setSelectedCurrency(C.exchangeRate);
      setCurrencySymbol(C.currencyName);
    }
  }, [currencyList]);

  // ! translate
  const [langSettings1, setLangSettings1] = useState([]);
  const [langSettings2, setLangSettings2] = useState([]);
  const title1 = "Product Details Card";
  const title2 = "Misc Words";

  useEffect(() => {
    if (langs && langs.length > 0 && lang !== "") {
      const L = langs.find((l) => l.langCode === lang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      const A = arr.find((l) => l._title === title1);
      if (A) setLangSettings1(A.data);
      const B = arr.find((l) => l._title === title2);
      if (B) setLangSettings2(B.data);
    }
  }, [lang, langs]);

  const translate1 = (String) => translation(String, title1, 1);
  const translate2 = (String) => translation(String, title2, 2);

  const translation = (String, title, index) => {
    let translation = "";
    let settings = [];
    if (index === 1) {
      settings = langSettings1;
    } else if (index === 2) {
      settings = langSettings2;
    }
    const T = settings.find((ls) => ls.title === String);
    if (T && T.value) translation = T.value;
    else {
      let arr = [];
      if (langs && langs.length > 0 && lang !== "") {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.settings && LEn.settings.length > 0) arr = LEn.settings;
        const S = arr.find((l) => l._title === title);
        if (S) {
          const T = S.data.find((ls) => ls.title === String);
          if (T && T.value) translation = T.value;
        }
      }
    }
    return translation;
  };
  // ! translate

  return (
    <>
      {loading ? (
        <LoadingBox w={"100%"} />
      ) : (
        <Slide direction="left" in={true} mountOnEnter unmountOnExit>
          <Card sx={{ mb: 1 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LazyLoadImage
                src={
                  medObj.image !== ""
                    ? PF + "adminPanel/medicines/img/" + medObj.image
                    : PF + "adminPanel/medicines/no-medicine-image.jpg"
                }
                alt="main"
                effect="blur"
                style={{
                  objectFit: "cover",
                  width: 250,
                  cursor: "pointer",
                }}
              />
            </Box>
            <CardContent>
              <Grid
                container
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item xs={12} sm={4.5}>
                  <Badge
                    // badgeContent={`${medObj.stock}`}
                    badgeContent={medObj.stock < 1 ? "out of stock" : 0}
                    color={
                      medObj.stock < 1
                        ? "error"
                        : medObj.stock < 10
                        ? "warning"
                        : "primary"
                    }
                    // max={999999}
                  >
                    <Typography gutterBottom variant="h6">
                      {medObj.genericBrand && medObj.genericBrand.toUpperCase()}{" "}
                    </Typography>
                  </Badge>
                  {varId && (
                    <Typography gutterBottom variant="body2">
                      {getVariantInfo(varId).title}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={2.5}>
                  {varId && (
                    <Button
                      variant="outlined"
                      size="small"
                      fullWidth
                      color="secondary"
                      sx={{ pointerEvents: "none" }}
                    >
                      {translate1("Price")}: {medObj.sellingPrice}
                    </Button>
                  )}
                </Grid>
                <Grid item xs={12} sm={5}>
                  {varId && (
                    <Button
                      variant="outlined"
                      size="small"
                      fullWidth
                      sx={{ pointerEvents: "none" }}
                    >
                      {translate1("Minimum Order Qty")}: {medObj.MOQ}
                    </Button>
                  )}
                </Grid>
                {/* <Grid item xs={12} sm={5}>
                  {varId && (
                    <Button
                      variant="outlined"
                      size="small"
                      fullWidth
                      sx={{ pointerEvents: "none" }}
                    >
                      Weight: {medObj.weight}
                    </Button>
                  )}
                </Grid> */}
              </Grid>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-label="identification-section"
                  aria-controls="identification-content"
                  id="identification-header"
                  ref={identificationRef}
                  onClick={() => scrollToRef(identificationRef)}
                >
                  <Typography>{translate1("Identification Info")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography gutterBottom variant="body2">
                    <span style={{ fontWeight: 900 }}>
                      {translate1("Generic")}:
                    </span>{" "}
                    {medObj.generic}
                  </Typography>
                  <Typography gutterBottom variant="body2">
                    <span style={{ fontWeight: 900 }}>
                      {translate1("Originator")}:
                    </span>{" "}
                    {medObj.originator}
                  </Typography>

                  <Typography gutterBottom variant="body2">
                    <span style={{ fontWeight: 900 }}>
                      {translate1("Mfg Brand")}:{" "}
                    </span>{" "}
                    {medObj.company}{" "}
                    <LazyLoadImage
                      src={
                        medObj.companyLogo &&
                        PF + "/adminPanel/companies/" + medObj.companyLogo
                      }
                      alt={medObj.company && medObj.company}
                      effect="blur"
                      style={{
                        height: "25px",
                        objectFit: "cover",
                        borderRadius: ".25rem",
                        // marginTop: 2,
                      }}
                    />
                  </Typography>

                  <Typography gutterBottom variant="body2">
                    <span style={{ fontWeight: 900 }}>
                      {translate1("Category")}
                    </span>
                    : {medObj.cat}
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-label="price-section"
                  aria-controls="price-content"
                  id="price-header"
                  ref={priceRef}
                  onClick={() => scrollToRef(priceRef)}
                >
                  <Typography>{translate1("Price Info")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12} sm={12} md={6}>
                      {currencyList.length > 0 && (
                        <Autocomplete
                          size="small"
                          onChange={(e, newValue) =>
                            handleCurrencyChange(e, newValue)
                          }
                          disablePortal
                          options={currencyList}
                          defaultValue={currencyList.find(
                            (c) => c.currencyName === "USD"
                          )}
                          filterOptions={filterOptions}
                          getOptionLabel={(option) =>
                            "(" +
                            getSymbolFromCurrency(option.currencyName) +
                            ") " +
                            option.currencyName +
                            " [" +
                            option.exchangeRate +
                            "]"
                          }
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{
                                "& > img": { mr: 2, flexShrink: 0 },
                              }}
                              {...props}
                            >
                              ({getSymbolFromCurrency(option.currencyName)}){" "}
                              {option.currencyName} [{option.exchangeRate}]
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={translate1("Select Currency")}
                              variant="standard"
                              size="small"
                              fullWidth
                            />
                          )}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <LoadingButton
                        onClick={handleUpdateCurrency}
                        variant="contained"
                        size="small"
                        fullWidth
                        loading={loading}
                        endIcon={<UpdateIcon />}
                        color="success"
                      >
                        {translate1("Update Currency")}
                      </LoadingButton>
                    </Grid>
                  </Grid>
                  <Divider sx={{ my: 1 }} />
                  {varId ? (
                    <>
                      <Typography gutterBottom variant="body2">
                        <span style={{ fontWeight: 900 }}>
                          {translate1("Dosage Form")}:
                        </span>{" "}
                        {medObj.dosageForm}
                      </Typography>
                      <Typography gutterBottom variant="body2">
                        <span style={{ fontWeight: 900 }}>
                          {translate1("Pack Types")}:
                        </span>{" "}
                        {medObj.packTypes}
                      </Typography>
                      <Typography gutterBottom variant="body2">
                        <span style={{ fontWeight: 900 }}>
                          {translate1("Strength")}:
                        </span>{" "}
                        {medObj.strength}
                      </Typography>
                      <Typography gutterBottom variant="body2">
                        <span style={{ fontWeight: 900 }}>
                          {translate1("Selling Price")}:
                        </span>{" "}
                        {getSymbolFromCurrency(currencySymbol)}
                        {medObj.sellingPrice > 0
                          ? (
                              Number(medObj.sellingPrice) * selectedCurrency
                            ).toFixed(2)
                          : 0}
                      </Typography>
                      <Typography gutterBottom variant="body2">
                        <span style={{ fontWeight: 900 }}>
                          {translate1("Minimum Order Qty")}:
                        </span>{" "}
                        {medObj.MOQ}
                      </Typography>
                      <Typography gutterBottom variant="body2">
                        <span style={{ fontWeight: 900 }}>
                          {translate1("Weight")}:
                        </span>{" "}
                        {medObj.weight}
                      </Typography>
                      <Typography gutterBottom variant="body2">
                        <span style={{ fontWeight: 900 }}>
                          {translate1("Avg Price of originator")}:
                        </span>{" "}
                        {getSymbolFromCurrency(currencySymbol)}
                        {medObj.avgPriceOfOriginator > 0
                          ? (
                              Number(medObj.avgPriceOfOriginator) *
                              selectedCurrency
                            ).toFixed(2)
                          : 0}
                      </Typography>
                      <Typography gutterBottom variant="body2">
                        <span style={{ fontWeight: 900 }}>
                          {translate1("Company MRP")}:
                        </span>{" "}
                        {getSymbolFromCurrency(currencySymbol)}
                        {medObj.companyMRP > 0
                          ? (
                              Number(medObj.companyMRP) * selectedCurrency
                            ).toFixed(2)
                          : 0}
                      </Typography>
                      <Typography gutterBottom variant="body2">
                        <span style={{ fontWeight: 900 }}>
                          {translate1("Company Patient Selling Price Old")}:
                        </span>{" "}
                        {getSymbolFromCurrency(currencySymbol)}
                        {medObj.companyPatientSellingPriceOld > 0
                          ? (
                              Number(medObj.companyPatientSellingPriceOld) *
                              selectedCurrency
                            ).toFixed(2)
                          : 0}
                      </Typography>
                      <Typography gutterBottom variant="body2">
                        <span style={{ fontWeight: 900 }}>
                          {translate1("Company Patient Selling Price Updated")}:
                        </span>{" "}
                        {getSymbolFromCurrency(currencySymbol)}
                        {medObj.companyPatientSellingPriceUpdated > 0
                          ? (
                              Number(medObj.companyPatientSellingPriceUpdated) *
                              selectedCurrency
                            ).toFixed(2)
                          : 0}
                      </Typography>
                      <Typography gutterBottom variant="body2">
                        <span style={{ fontWeight: 900 }}>
                          {translate1("VIP Selling Price")}:
                        </span>{" "}
                        {getSymbolFromCurrency(currencySymbol)}
                        {medObj.vipSellingPrice > 0
                          ? (
                              Number(medObj.vipSellingPrice) * selectedCurrency
                            ).toFixed(2)
                          : 0}
                      </Typography>
                    </>
                  ) : (
                    <>
                      {medObj.variantArr && (
                        <>
                          <Grid container spacing={1}>
                            {medObj.variantArr &&
                              medObj.variantArr.map((x, i) => (
                                <Grid item key={i} xs={12} sm={6}>
                                  <Button
                                    variant={
                                      activeVariant === i
                                        ? "contained"
                                        : "outlined"
                                    }
                                    size="small"
                                    fullWidth
                                    onClick={() => setActiveVariant(i)}
                                    sx={{
                                      fontSize: { xs: ".6rem", sm: ".8rem" },
                                    }}
                                  >
                                    {getVariantInfo(x.variantId).title}
                                  </Button>
                                </Grid>
                              ))}
                          </Grid>
                          <Divider sx={{ my: 1 }} />
                          <Typography gutterBottom variant="body2">
                            <span style={{ fontWeight: 900 }}>
                              {translate1("Dosage Form")}:
                            </span>{" "}
                            {
                              getVariantInfo(
                                medObj.variantArr[activeVariant].variantId
                              ).dosageForm
                            }
                          </Typography>
                          <Typography gutterBottom variant="body2">
                            <span style={{ fontWeight: 900 }}>
                              {translate1("Pack Types")}:
                            </span>{" "}
                            {
                              getVariantInfo(
                                medObj.variantArr[activeVariant].variantId
                              ).packTypes
                            }
                          </Typography>
                          <Typography gutterBottom variant="body2">
                            <span style={{ fontWeight: 900 }}>
                              {translate1("Strength")}:
                            </span>{" "}
                            {
                              getVariantInfo(
                                medObj.variantArr[activeVariant].variantId
                              ).strength
                            }
                          </Typography>
                          <Typography gutterBottom variant="body2">
                            <span style={{ fontWeight: 900 }}>
                              {translate1("Selling Price")}:
                            </span>{" "}
                            {getSymbolFromCurrency(currencySymbol)}
                            {getVariantInfo(
                              medObj.variantArr[activeVariant].variantId
                            ).sellingPrice > 0
                              ? (
                                  Number(
                                    getVariantInfo(
                                      medObj.variantArr[activeVariant].variantId
                                    ).sellingPrice
                                  ) * selectedCurrency
                                ).toFixed(2)
                              : 0}
                          </Typography>
                          <Typography gutterBottom variant="body2">
                            <span style={{ fontWeight: 900 }}>
                              {translate1("Minimum Order Qty")}:
                            </span>{" "}
                            {
                              getVariantInfo(
                                medObj.variantArr[activeVariant].variantId
                              ).MOQ
                            }
                          </Typography>
                          <Typography gutterBottom variant="body2">
                            <span style={{ fontWeight: 900 }}>
                              {translate1("Weight")}:
                            </span>{" "}
                            {
                              getVariantInfo(
                                medObj.variantArr[activeVariant].variantId
                              ).weight
                            }
                          </Typography>
                          <Typography gutterBottom variant="body2">
                            <span style={{ fontWeight: 900 }}>
                              {translate1("Avg Price of originator")}:
                            </span>{" "}
                            {getSymbolFromCurrency(currencySymbol)}
                            {getVariantInfo(
                              medObj.variantArr[activeVariant].variantId
                            ).avgPriceOfOriginator > 0
                              ? (
                                  Number(
                                    getVariantInfo(
                                      medObj.variantArr[activeVariant].variantId
                                    ).avgPriceOfOriginator
                                  ) * selectedCurrency
                                ).toFixed(2)
                              : 0}
                          </Typography>
                          <Typography gutterBottom variant="body2">
                            <span style={{ fontWeight: 900 }}>
                              {translate1("Company MRP")}:
                            </span>{" "}
                            {getSymbolFromCurrency(currencySymbol)}
                            {getVariantInfo(
                              medObj.variantArr[activeVariant].variantId
                            ).companyMRP > 0
                              ? (
                                  Number(
                                    getVariantInfo(
                                      medObj.variantArr[activeVariant].variantId
                                    ).companyMRP
                                  ) * selectedCurrency
                                ).toFixed(2)
                              : 0}
                          </Typography>
                          <Typography gutterBottom variant="body2">
                            <span style={{ fontWeight: 900 }}>
                              {translate1("Company Patient Selling Price Old")}:
                            </span>{" "}
                            {getSymbolFromCurrency(currencySymbol)}
                            {getVariantInfo(
                              medObj.variantArr[activeVariant].variantId
                            ).companyPatientSellingPriceOld > 0
                              ? (
                                  Number(
                                    getVariantInfo(
                                      medObj.variantArr[activeVariant].variantId
                                    ).companyPatientSellingPriceOld
                                  ) * selectedCurrency
                                ).toFixed(2)
                              : 0}
                          </Typography>
                          <Typography gutterBottom variant="body2">
                            <span style={{ fontWeight: 900 }}>
                              {translate1(
                                "Company Patient Selling Price Updated"
                              )}
                              :
                            </span>{" "}
                            {getSymbolFromCurrency(currencySymbol)}
                            {getVariantInfo(
                              medObj.variantArr[activeVariant].variantId
                            ).companyPatientSellingPriceUpdated > 0
                              ? (
                                  Number(
                                    getVariantInfo(
                                      medObj.variantArr[activeVariant].variantId
                                    ).companyPatientSellingPriceUpdated
                                  ) * selectedCurrency
                                ).toFixed(2)
                              : 0}
                          </Typography>
                          <Typography gutterBottom variant="body2">
                            <span style={{ fontWeight: 900 }}>
                              {translate1("VIP Selling Price")}:
                            </span>{" "}
                            {getSymbolFromCurrency(currencySymbol)}
                            {getVariantInfo(
                              medObj.variantArr[activeVariant].variantId
                            ).vipSellingPrice > 0
                              ? (
                                  Number(
                                    getVariantInfo(
                                      medObj.variantArr[activeVariant].variantId
                                    ).vipSellingPrice
                                  ) * selectedCurrency
                                ).toFixed(2)
                              : 0}
                          </Typography>
                        </>
                      )}
                    </>
                  )}
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-label="more-section"
                  aria-controls="more-content"
                  id="more-header"
                  ref={moreRef}
                  onClick={() => scrollToRef(moreRef)}
                >
                  <Typography>{translate1("More")}...</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormGroup>
                    <FormControlLabel
                      sx={{ pointerEvents: "none" }}
                      control={
                        <Checkbox checked={medObj.visible ? true : false} />
                      }
                      label={translate1("Visible")}
                    />
                    <FormControlLabel
                      sx={{ pointerEvents: "none" }}
                      control={
                        <Checkbox checked={medObj.prescribed ? true : false} />
                      }
                      label={translate1("Prescribed")}
                    />
                    <FormControlLabel
                      sx={{ pointerEvents: "none" }}
                      control={
                        <Checkbox checked={medObj.topSeller ? true : false} />
                      }
                      label={translate1("Best Seller")}
                    />
                    <FormControlLabel
                      sx={{ pointerEvents: "none" }}
                      control={
                        <Checkbox
                          checked={medObj.dealOfTheWeek ? true : false}
                        />
                      }
                      label={translate1("Deal of the week")}
                    />
                    <FormControlLabel
                      sx={{ pointerEvents: "none" }}
                      control={<Checkbox checked={medObj.new ? true : false} />}
                      label={translate1("New")}
                    />
                    <FormControlLabel
                      sx={{ pointerEvents: "none" }}
                      control={
                        <Checkbox checked={medObj.stock < 1 ? false : true} />
                      }
                      label={translate1("In Stock")}
                    />
                  </FormGroup>
                </AccordionDetails>
              </Accordion>
            </CardContent>
          </Card>
        </Slide>
      )}
    </>
  );
}
