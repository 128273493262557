import LoadingButton from "@mui/lab/LoadingButton";
import {
  Autocomplete,
  Box,
  Button,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  editCat,
  getMedCategories,
} from "../../../../reduxToolkit/features/adminPanel/medicine";
import { getNotification } from "../../../../reduxToolkit/features/adminPanel/notification";
// Icon
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

// Limit options to show in autocomplete
const OPTIONS_LIMIT = 5;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) => {
  return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
};

export default function EditCategoryDialog({
  lang,
  editCategoryDlg,
  setEditCategoryDlg,
  editCatBuffer,
  setEditCatBuffer,
  categoryListEn,
}) {
  // Use State
  const [catName, setCatName] = useState("");
  const [catNameExist, setCatNameExist] = useState(false);

  const [catAddLoading, setCatAddLoading] = useState(false);
  const [activeCat, setActiveCat] = useState(null);
  const [subCatAddLoading, setSubCatAddLoading] = useState(false);
  const [subCatName, setSubCatName] = useState("");
  const [activeSubCat, setActiveSubCat] = useState(null);
  const [subCatEnId, setSubCatEnId] = useState("");
  const [addSubCatTxt, setAddSubCatTxt] = useState(false);
  const [subCatList, setSubCatList] = useState([]);
  const [subCatListEn, setSubCatListEn] = useState([]);
  const [categoryDetectorId, setCategoryDetectorId] = useState([]);
  const [editSubCatBuffer, setEditSubCatBuffer] = useState({
    index: -1,
    value: "",
  });
  const [updatedSubCatName, setUpdatedSubCatName] = useState("");

  //   dlg
  const [loadingDlg, setLoadingDlg] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);

  // LocalData
  const localAdminData = JSON.parse(localStorage.getItem("adminLoginInfo"));
  //   Store
  const { editCatResponse } = useSelector((state) => state.medicine);

  //   Use effect
  // add tag dlg submit disable
  useEffect(() => {
    if (catName === "" || editSubCatBuffer.index !== -1) setDisableSubmit(true);
    else setDisableSubmit(false);
  }, [catName, editSubCatBuffer, subCatName]);

  // handle prev Edit data
  useEffect(() => {
    setCatName(editCatBuffer.catName);
    let arr = [];
    if (editCatBuffer.subCatList && editCatBuffer.subCatList.length > 0) {
      editCatBuffer.subCatList.map((x, i) => {
        const obj = {
          subCatName: x.subCatName,
        };
        if (x.subCatDetectorId) obj.subCatDetectorId = x.subCatDetectorId;
        if (x.fixedSubCatId) obj.fixedSubCatId = x.fixedSubCatId;
        if (lang !== "en") {
          const S = subCatListEn.find(
            (s) => s.fixedSubCatId === x.subCatDetectorId
          );
          if (S) obj.subCatEnName = S.subCatName;
        }
        arr.push(obj);
      });
    }
    setSubCatList(arr);
  }, [editCatBuffer, lang, subCatListEn, editCategoryDlg]);

  // set prev active category en
  useEffect(() => {
    if (editCategoryDlg) {
      setCatAddLoading(true);
      const C = categoryListEn.find(
        (c) => c.fixedCatId === editCatBuffer.categoryDetectorId
      );
      setTimeout(() => {
        C ? setActiveCat(C) : setActiveCat(null);
        setCatAddLoading(false);
      }, 100);
    }
  }, [categoryListEn, editCatBuffer.categoryDetectorId, editCategoryDlg]);

  // Check update category success
  useEffect(() => {
    if (editCatResponse && editCatResponse.failed) {
      setLoadingDlg(false);
      setCatNameExist(true);
    } else {
      setLoadingDlg(false);
      setCatNameExist(false);
      setCatName("");
      setSubCatName("");
      setAddSubCatTxt(false);
      setSubCatList([]);
      setEditCategoryDlg(false);
      setCategoryDetectorId([]);
      setEditSubCatBuffer({ index: -1, value: "" });
    }
  }, [editCatResponse]);

  // Concat sub categories
  useEffect(() => {
    let subCats = [];
    categoryListEn.map((x, i) => (subCats = [...subCats, ...x.subCatList]));
    setSubCatListEn(subCats);
  }, [categoryListEn, lang]);

  //   Functions
  const dispatch = useDispatch();

  const handleUpdateCategory = () => {
    setLoadingDlg(true);
    const f = {
      lang,
      id: editCatBuffer._id,
      data: [
        {
          catName,
          categoryDetectorId,
          subCatList,
          creatorId: localAdminData._id,
        },
      ],
    };
    dispatch(editCat(f));
    setTimeout(() => {
      dispatch(getNotification());
      dispatch(getMedCategories());
    }, 1000);
  };

  const handleAddSubCategory = () => {
    setSubCatAddLoading(true);
    let arr = [...subCatList.reverse()];
    if (lang !== "en" && subCatEnId) {
      const S = subCatListEn.find((s) => s.fixedSubCatId === subCatEnId);
      S
        ? arr.push({
            subCatName,
            subCatDetectorId: subCatEnId,
            subCatEnName: S.subCatName,
          })
        : arr.push({ subCatName, subCatDetectorId: subCatEnId });
    } else {
      arr.push({ subCatName });
    }
    setSubCatName("");
    setSubCatEnId("");
    setSubCatList(arr.reverse());
    setTimeout(() => {
      setSubCatAddLoading(false);
    }, 100);
  };

  const handleCatName = (e) => {
    setCatName(e.target.value);
    setCatNameExist(false);
  };

  const handleEditSubCat = (i) => {
    setUpdatedSubCatName(subCatList[i].subCatName);
    const S = subCatListEn.find(
      (s) => s.fixedSubCatId === subCatList[i].subCatDetectorId
    );
    if (S) {
      setActiveSubCat(S);
      setSubCatEnId(subCatList[i].subCatDetectorId);
    }
    setEditSubCatBuffer({ index: i, value: i });
  };

  const handleUpdateSubCat = (i) => {
    setEditSubCatBuffer({ index: -1, value: "" });
    let arr = [...subCatList];

    if (lang !== "en" && subCatEnId) {
      const S = subCatListEn.find((s) => s.fixedSubCatId === subCatEnId);
      arr[i] = {
        subCatName: updatedSubCatName,
        subCatDetectorId: subCatEnId,
      };
      if (S) arr[i].subCatEnName = S.subCatName;
    } else {
      arr[i] = { ...subCatList[i], ...{ subCatName: updatedSubCatName } };
    }
    setSubCatList(arr);
    setUpdatedSubCatName("");
  };

  const handleDeleteSubCat = (i) => {
    let arr = [...subCatList];
    arr.splice(i, 1);
    setSubCatList(arr);
  };

  const handleDlgClose = () => {
    setCatName("");
    setSubCatName("");
    setAddSubCatTxt(false);
    setSubCatList([]);
    setEditCategoryDlg(false);
    setCatNameExist(false);
    setCategoryDetectorId([]);
    setEditSubCatBuffer({ index: -1, value: "" });
    setEditCatBuffer({});
  };

  // English Variant
  const handleEnglishVariant = (e, newValue) => {
    setCategoryDetectorId(newValue.fixedCatId);
  };
  const handleSubCatEnglishVariant = (e, newValue) => {
    newValue ? setSubCatEnId(newValue.fixedSubCatId) : setSubCatEnId("");
  };

  // ! translate
  const [siteLang, setSiteLang] = useState("");
  // Local Store
  const localLangData = JSON.parse(localStorage.getItem("lang"));
  // Store
  const { langs, activeLang } = useSelector((state) => state.misc);
  const [langSettings1, setLangSettings1] = useState([]);
  const [langSettings2, setLangSettings2] = useState([]);
  const [langSettings3, setLangSettings3] = useState([]);
  const title1 = "Add Sub Category Dialog";
  const title2 = "Misc Words";
  const title3 = "Manage Category";

  useEffect(() => {
    if (langs && langs.length > 0 && siteLang !== "") {
      const L = langs.find((l) => l.langCode === siteLang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      const A = arr.find((l) => l._title === title1);
      if (A) setLangSettings1(A.data);
      const B = arr.find((l) => l._title === title2);
      if (B) setLangSettings2(B.data);
      const C = arr.find((l) => l._title === title3);
      if (C) setLangSettings3(C.data);
    }
  }, [siteLang, langs]);

  const translate1 = (String) => translation(String, title1, 1);
  const translate2 = (String) => translation(String, title2, 2);
  const translate3 = (String) => translation(String, title3, 3);

  const translation = (String, title, index) => {
    let translation = "";
    let settings = [];
    if (index === 1) {
      settings = langSettings1;
    } else if (index === 2) {
      settings = langSettings2;
    } else if (index === 3) {
      settings = langSettings3;
    }
    const T = settings.find((ls) => ls.title === String);
    if (T && T.value) translation = T.value;
    else {
      let arr = [];
      if (langs && langs.length > 0 && siteLang !== "") {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.settings && LEn.settings.length > 0) arr = LEn.settings;
        const S = arr.find((l) => l._title === title);
        if (S) {
          const T = S.data.find((ls) => ls.title === String);
          if (T && T.value) translation = T.value;
        }
      }
    }
    return translation;
  };

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setSiteLang(activeLang.langCode);
    } else {
      if (localLangData) {
        setSiteLang(localLangData.langCode);
      }
    }
  }, [activeLang]);
  // ! translate
  return (
    <Dialog
      open={editCategoryDlg}
      onClose={() => setEditCategoryDlg(false)}
      aria-labelledby={"edit-tag-dialog"}
      fullWidth
      maxWidth={"sm"}
    >
      <DialogTitle>{translate3("Edit Category")}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1} flexDirection="column">
          <Grid item>
            <TextField
              variant="standard"
              size="small"
              fullWidth
              label={translate1("Category Name")}
              value={catName}
              error={catNameExist ? true : false}
              onChange={handleCatName}
              required
              autoFocus
              helperText={catNameExist && editCatResponse.message}
            />
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              fullWidth
              onClick={() => setAddSubCatTxt(true)}
              disabled={addSubCatTxt ? true : false}
            >
              {translate1("Add Sub Category")}
            </Button>
            {addSubCatTxt && (
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} sm={lang !== "en" ? 5 : 10}>
                  <TextField
                    variant="standard"
                    size="small"
                    fullWidth
                    label={translate1("Add Sub Category")}
                    value={subCatName}
                    onChange={(e) => setSubCatName(e.target.value)}
                    required
                  />
                </Grid>
                {lang !== "en" &&
                  subCatListEn.length > 0 &&
                  !subCatAddLoading && (
                    <Grid item xs={12} sm={5} sx={{ mt: { sm: 1 } }}>
                      <Autocomplete
                        size="small"
                        onChange={(e, newValue) =>
                          handleSubCatEnglishVariant(e, newValue)
                        }
                        disablePortal
                        options={subCatListEn}
                        filterOptions={filterOptions}
                        filterSelectedOptions
                        getOptionLabel={(option) => option.subCatName}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{
                              "& > img": { mr: 2, flexShrink: 0 },
                            }}
                            {...props}
                          >
                            {option.subCatName}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={translate1("English Variant")}
                            variant="outlined"
                            size="small"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                  )}
                <Grid item xs={12} sm={2}>
                  <Grid container spacing={0} justifyContent="center">
                    <Grid item>
                      <Tooltip
                        title={translate2("Confirm")}
                        arrow
                        placement="left"
                      >
                        <IconButton
                          size="small"
                          onClick={handleAddSubCategory}
                          disabled={subCatName === "" ? true : false}
                        >
                          <CheckIcon
                            color={subCatName === "" ? "disabled" : "success"}
                          />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Tooltip
                        title={translate2("Close")}
                        arrow
                        placement="right"
                      >
                        <IconButton
                          size="small"
                          onClick={() => setAddSubCatTxt(false)}
                        >
                          <CloseIcon color="error" />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Divider sx={{ my: 1 }} />

            <Box
              sx={{
                maxHeight: { xs: "30vh", sm: "20vh" },
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <Grid container spacing={1} flexDirection="column">
                {subCatList.map((x, i) => (
                  <Fragment>
                    <Grid item key={i}>
                      <Grid
                        container
                        spacing={0}
                        justifyContent="space-between"
                      >
                        <Grid
                          item
                          xs={editSubCatBuffer.index === i ? 10 : 12}
                          sm={10}
                        >
                          {/* Edit  */}

                          {editSubCatBuffer.index === i ? (
                            <Grid
                              container
                              spacing={1}
                              alignItems="center"
                              sx={{ mt: 0.5 }}
                            >
                              <Grid item xs={12} sm={lang !== "en" ? 6 : 12}>
                                <TextField
                                  variant="standard"
                                  value={updatedSubCatName}
                                  onChange={(e) =>
                                    setUpdatedSubCatName(e.target.value)
                                  }
                                  size="small"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                {lang !== "en" &&
                                  subCatListEn.length > 0 &&
                                  !subCatAddLoading && (
                                    <Autocomplete
                                      size="small"
                                      onChange={(e, newValue) =>
                                        handleSubCatEnglishVariant(e, newValue)
                                      }
                                      disablePortal
                                      options={subCatListEn}
                                      defaultValue={
                                        activeSubCat && activeSubCat
                                      }
                                      filterOptions={filterOptions}
                                      getOptionLabel={(option) =>
                                        option.subCatName
                                      }
                                      renderOption={(props, option) => (
                                        <Box
                                          component="li"
                                          sx={{
                                            "& > img": { mr: 2, flexShrink: 0 },
                                          }}
                                          {...props}
                                        >
                                          {option.subCatName}
                                        </Box>
                                      )}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label={translate1("English Variant")}
                                          variant="outlined"
                                          size="small"
                                          fullWidth
                                        />
                                      )}
                                    />
                                  )}
                              </Grid>
                            </Grid>
                          ) : (
                            <Grid container spacing={1}>
                              <Grid item xs={12} sm={lang !== "en" ? 6 : 12}>
                                <Button
                                  variant="outlined"
                                  color="warning"
                                  size="small"
                                  fullWidth
                                  sx={{ pointerEvents: "none" }}
                                >
                                  {x.subCatName}
                                </Button>
                              </Grid>
                              {lang !== "en" && (
                                <Grid item xs={12} sm={6}>
                                  <Button
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    fullWidth
                                    sx={{ pointerEvents: "none" }}
                                  >
                                    {x.subCatEnName}
                                  </Button>
                                </Grid>
                              )}
                            </Grid>
                          )}
                        </Grid>
                        {editSubCatBuffer.index === i ? (
                          <Grid item xs={2} sm={1} sx={{ mt: 1 }}>
                            <Tooltip title="Confirm" arrow placement="right">
                              <IconButton
                                size="small"
                                onClick={() => handleUpdateSubCat(i)}
                              >
                                <CheckIcon color="success" />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        ) : (
                          <Grid item xs={12} sm={2}>
                            <Grid container spacing={0} justifyContent="center">
                              <Grid item xs={2} sm={4}>
                                <Tooltip title="Edit" arrow placement="left">
                                  <IconButton
                                    size="small"
                                    onClick={() => handleEditSubCat(i)}
                                    disabled={
                                      editSubCatBuffer.index === -1
                                        ? false
                                        : true
                                    }
                                  >
                                    <EditIcon
                                      color={
                                        editSubCatBuffer.index === -1
                                          ? "info"
                                          : "disabled"
                                      }
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                              <Grid item xs={2} sm={4}>
                                <Tooltip title="Delete" arrow placement="right">
                                  <IconButton
                                    size="small"
                                    onClick={() => handleDeleteSubCat(i)}
                                    disabled={
                                      editSubCatBuffer.index === -1
                                        ? false
                                        : true
                                    }
                                  >
                                    <DeleteIcon
                                      color={
                                        editSubCatBuffer.index === -1
                                          ? "error"
                                          : "disabled"
                                      }
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Fragment>
                ))}
              </Grid>
            </Box>
          </Grid>
          <Grid item>
            {lang !== "en" && !catAddLoading && (
              <Autocomplete
                onChange={(e, newValue) => handleEnglishVariant(e, newValue)}
                disablePortal
                options={categoryListEn}
                defaultValue={activeCat && activeCat}
                filterOptions={filterOptions}
                getOptionLabel={(option) => option.catName}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option.catName}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={translate1("English Variant")}
                    variant="standard"
                    size="small"
                    fullWidth
                  />
                )}
              />
            )}
          </Grid>
          <Grid item>
            <LoadingButton
              onClick={handleUpdateCategory}
              loadingPosition="end"
              endIcon={<AddCircleOutlinedIcon />}
              loading={loadingDlg}
              disabled={disableSubmit}
              variant="contained"
              size="small"
              fullWidth
              color="warning"
            >
              {translate3("Update")}
            </LoadingButton>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDlgClose} color="error">
          {translate2("Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
