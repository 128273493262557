import Banner from "../components/home/Banner";
import BestSelling from "../components/home/BestSelling";
import Blog from "../components/home/blog/Blog";
import Brands from "../components/home/Brands";
import BlogSection from "../components/home/BlogSection";
import TestimonialsNContactUs from "../components/home/TestimonialsNContactUs";
import Deal from "../components/home/Deal";
import Focus from "../components/home/Focus";
import NewProducts from "../components/home/NewProducts";
import Offer from "../components/home/Offer";

export default function Home() {
  return (
    <>
      <Banner />
      <Focus />
      <Deal />
      <BestSelling />
      <NewProducts />
      {/* <Offer /> */}
      <BlogSection />
      <Brands />
      {/* <Blog /> */}
      <TestimonialsNContactUs />
    </>
  );
}
