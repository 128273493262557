import React, { useEffect, useState } from "react";
import OrderCard from "../../order/OrderCard";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Box,
} from "@mui/material";
import { getExactTime } from "../../../../hooks/getCreatedData";

export default function OrderReport({ startDate, endDate }) {
  const [orderList, setOrderList] = useState([]);
  const [orderDlg, setOrderDlg] = useState(false);
  const [orderShowBuffer, setOrderShowBuffer] = useState({});
  const { orders } = useSelector((state) => state.checkout);

  useEffect(() => {
    if (orders && orders.length && startDate && endDate) {
      var resultProductData = orders.filter((a) => {
        var date = new Date(a.createdAt);
        return date >= startDate && date <= endDate;
      });
      setOrderList(resultProductData);
    }
  }, []);
  return (
    <>
      {startDate && endDate && (
        <>
          <Box
            sx={{
              maxHeight: { xs: "40vh", sm: "40vh" },
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <Grid container spacing={1}>
              {orderList.length < 1 ? (
                <Grid item xs={12}>
                  <Alert severity="warning">No Data Found...</Alert>
                </Grid>
              ) : (
                orderList.map((x, i) => (
                  <Grid item key={i} xs={12} sm={6}>
                    <Button
                      variant="outlined"
                      size="small"
                      fullWidth
                      onClick={() => {
                        setOrderDlg(true);
                        setOrderShowBuffer(x);
                      }}
                    >
                      <Typography>
                        #{x.orderNumber} <br />
                        <span style={{ fontSize: ".6rem", color: "gray" }}>
                          {getExactTime(x.createdAt)}
                        </span>
                      </Typography>
                    </Button>
                  </Grid>
                ))
              )}
            </Grid>
            {orderShowBuffer.orderNumber && orderDlg && (
              <Dialog
                open={orderDlg}
                onClose={() => setOrderDlg(false)}
                fullWidth
                maxWidth={"md"}
              >
                <DialogContent>
                  <OrderCard x={orderShowBuffer} lang={"en"} />
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setOrderDlg(false)} color="primary">
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            )}
          </Box>
        </>
      )}
    </>
  );
}
