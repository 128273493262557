import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import LoadingBox from "../../../../../components/misc/Loading";
import { format } from "date-fns";
// import { currencyFormatter } from "../../../../../hooks/currencyFormatter";
import { Button } from "@mui/material";

export default function PreviewInvoiceNew({
  open,
  setOpen,
  invoiceDate,
  invoiceNo,
  paymentMedia,
  companyPhone,
  clientInfo,
  paymentAmount,
}) {
  const [loading, setLoading] = useState(false);
  const [htmlToPreview, setHtmlToPreview] = useState(``);

  useEffect(() => {
    let html =
      templateStyle +
      `
      <div class="wrapper">
  <div class="container">
    <div class="header">
      <p class="header-left">Invoice</p>
      <p class="header-right">BISMIB ENTERPRISE L.L.C-FZ</p>
    </div>
    <table class="invoice-info">
      <tr >
        <th class="invoice-info-table-head">Invoice number</th>
        <th>${invoiceNo}</th>
      </tr>
      <tr>
        <td>Date paid</td>
        <td>${format(invoiceDate, "PPP")}</td>
      </tr>
      <tr>
        <td>Payment media</td>
        <td class="payment-media">${paymentMedia}</td>
      </tr>
    </table>
    <div class="info-container">
      <div class="company-info">
        <p>
          <span class="company-info-head">
            BISMIB ENTERPRISE L.L.C-FZ
            </span>
          <br/>
          ${companyPhone}
        </p>
      </div>
      <div class="billing-info">
        <p class="company-info-head">
            Bill to
        </p>

          ${clientInfo}
          
      </div>
    </div>
    <p class="payment-info">${paymentAmount} paid on ${format(
        invoiceDate,
        "PPP"
      )}</p>
    <table class="main-table">
      <tr >
        <th class="th-first">Description</th>
        <th  class="th-second">Qty</th>
        <th  class="th-third">Unit Price</th>
        <th class="th-last">Amount</th>
      </tr>
    </table>
    <hr/>
    <table class="main-table">
      <tr>
        <td class="th-first padding-b">Product and Service payment</td>
        <td class="th-second padding-b">1</td>
        <td class="th-third padding-b">${paymentAmount}</td>
        <td class="th-last padding-b">${paymentAmount}</td>
      </tr>
      <tr>
        <td class="th-first"></td>
        <td class="th-second subtotal padding-y">Subtotal</td>
        <td class="th-third subtotal padding-y"></td>
        <td class="th-last subtotal padding-y">${paymentAmount}</td>
      </tr>
      <tr>
        <td class="th-first"></td>
        <td class="th-second subtotal padding-y">Total</td>
        <td class="th-third subtotal padding-y"></td>
        <td class="th-last subtotal padding-y">${paymentAmount}</td>
      </tr>
      <tr class="bold">
        <td class="th-first"></td>
        <td class="th-second subtotal padding-y">Amount paid</td>
        <td class="th-third subtotal padding-y"></td>
        <td class="th-last subtotal padding-y">${paymentAmount}</td>
      </tr>
    </table>
     <div class="footer">
      <div class="footer-container">
        <p>${invoiceNo} · ${paymentAmount} paid on ${format(
        invoiceDate,
        "PPP"
      )}</p>
        <p>Page 1 of 1</p>
      </div>
    </div>
  </div>
</div>           
        `;
    setHtmlToPreview(html);
  }, []);

  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullScreen>
        {loading ? (
          <LoadingBox w={"100%"} h="100vh" />
        ) : (
          <DialogContent>
            <Button
              onClick={() => setOpen(false)}
              sx={{ m: 0, p: 0 }}
              color="error"
              variant="text"
              fullWidth
              size="small"
            >
              Click here to close (Do not click back from browser)
            </Button>
            {loading ? (
              <LoadingBox w={"100%"} />
            ) : (
              <>
                <div dangerouslySetInnerHTML={{ __html: htmlToPreview }} />
              </>
            )}
          </DialogContent>
        )}
      </Dialog>
    </>
  );
}

const templateStyle = `
<style>
.wrapper{
  margin: auto;
  background-color: #ececec;
  width: 40%;
  height: 95vh;
  position: relative;
}

.container{
  padding: 10px 40px;
  height: 100v
}

.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 25px;
}

.header > .header-right{
  color: gray
}

.invoice-info{
  text-align: left; 
  font-size: 14px;
}

.invoice-info-table-head {
  padding-right: 10px;
}

.info-container{
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.company-info{
  font-size: 14px;
  flex: 1; 
}

.company-info-head{
  font-weight: 700;
}

.billing-info{
  flex: 1;
}

.payment-info{
  margin-top: 20px;
  font-size: 24px;
  font-weight: 900;
}

.payment-media{
  line-height: 0%;
}

.main-table{
  width: 100%;
}

.main-table th{
  font-weight: 200;
  font-size: 14px;
  
}

.th-first{
  text-align: left;
  width: 50%;
}

.th-second{
  text-align: left;
  width: 15%;
}

.th-third{
  text-align: center;
  width: 15%;
}

.th-last{
  text-align: right;
  width: 20%;
}

.padding-y{
  padding: 10px 0px;
}

.padding-b{
  padding-bottom: 10px;
}

.subtotal{
  border-top: 1px dotted gray;
}

.bold{
  font-weight: 900;
}

.footer-container{
  margin-top: 30px;
  border-top: 1px solid gray;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}

.footer{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0px 20px;
}

</style>
`;
