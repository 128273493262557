import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import {
  Autocomplete,
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Drawer,
  IconButton,
  TextField,
  Typography,
  Avatar,
  Alert,
  Tooltip,
  DialogTitle,
  DialogContentText,
  Slide,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Link,
  Backdrop,
  CircularProgress,
  createFilterOptions,
} from "@mui/material";

// Icons
import ChatIcon from "@mui/icons-material/Chat";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CompareIcon from "@mui/icons-material/Compare";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import MoreCallOptions from "../ContactUs/MoreCallOptions";
import ProfileDrawer from "./appBarDrawers/ProfileDrawer";
import CartDrawer from "./appBarDrawers/CartDrawer";
import WishListDrawer from "./appBarDrawers/WishListDrawer";
import { useDispatch, useSelector } from "react-redux";
import LoadingBox from "../misc/Loading";
import { PF } from "../../config";
import {
  getNotificationByCustomer,
  setIdToScroll,
  triggerPreloader,
} from "../../reduxToolkit/features/miscFront";
import CompareDlg from "../customerInteraction/CompareDlg";
import { getCompareList } from "../../reduxToolkit/features/frontend/compare";
import { getWishlist } from "../../reduxToolkit/features/frontend/wishlist";
import { getAskPriceListByCustomer } from "../../reduxToolkit/features/frontend/checkout";
import CheckoutMain from "../checkout/CheckoutMain";
import OrderHistoryDialog from "../OrderHistoryDialog";
import medicine, {
  getMedicines,
} from "../../reduxToolkit/features/adminPanel/medicine";
import { useNavigate } from "react-router-dom";
import { makeUrlFriendly } from "../../hooks/makeUrlFriendly";

// Limit options to show in autocomplete
const OPTIONS_LIMIT = 12;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) => {
  return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
};

export default function SearchBar() {
  // Responsive
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Search Responsive Button
  // UseState
  const [messageOpen, setMessageOpen] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);
  const [wishOpen, setWishOpen] = useState(false);
  const [compareOpen, setCompareOpen] = useState(false);
  const [cartOpen, setCartOpen] = useState(false);
  const [checkoutDlg, setCheckoutDlg] = useState(false);

  const [readNotiCount, setReadNotiCount] = useState(0);
  const [unReadNotiCount, setUnReadNotiCount] = useState(0);

  const [lang, setLang] = useState("en");

  const [medLoading, setMedLoading] = useState(false);
  const [medList, setMedList] = useState([]);
  const [medListEn, setMedListEn] = useState([]);

  const [isHome, setIsHome] = useState(false);

  // Dialogs
  const [menuDlg, setMenuDlg] = useState(false);
  const [activeMenu, setActiveMenu] = useState("home");

  // Local Store
  const localData = () => {
    let obj = {};
    const O = JSON.parse(localStorage.getItem("loginInfo"));
    if (O) obj = O;
    return obj;
  };
  const localLangData = JSON.parse(localStorage.getItem("langFront"));

  // store
  const { pending, error } = useSelector((state) => state.auth);
  const { customerNotifications, openProfileDrawerDlg, orderHistoryDlg } =
    useSelector((state) => state.miscFront);
  const { compareList } = useSelector((state) => state.compare);
  const { wishlist } = useSelector((state) => state.wishlist);
  const { carts } = useSelector((state) => state.cart);
  const { webSettings } = useSelector((state) => state.webSettings);
  const { medicines, viewCountArr } = useSelector((state) => state.medicine);
  const { activeLangFront } = useSelector((state) => state.miscFront);

  // Functions
  // const handleMessage = () => {
  //   setMessageOpen(true);
  // };

  const getGenericBrand = (id) => {
    let genericBrand = "";
    const M = medicines.find((m) => m.lang === "en");
    if (M) {
      const med = M.data.find((x) => x.fixedMedicineId === id);
      if (med) genericBrand = med.genericBrand;
    }
    return genericBrand;
  };

  const handleSearchClick = (e, newValue) => {
    setMedLoading(true);
    navigate(
      `/our-medicines/${makeUrlFriendly(
        getGenericBrand(
          lang !== "en" ? newValue.medicineDetectorId : newValue.fixedMedicineId
        )
      )}`
    );
    setTimeout(() => {
      setMedLoading(false);
    }, 100);
  };

  // get MEd Name En
  const getMedNameEn = (id) => {
    const M = medListEn.find((m) => m.fixedMedicineId === id);
    if (M) return M.genericBrand;
  };

  // get MEd Generic Name En
  const getMedGenericNameEn = (id) => {
    const M = medListEn.find((m) => m.fixedMedicineId === id);
    if (M) return M.generic;
  };

  const handleMenu = async (menuProp) => {
    await navigate(menuProp === "home" ? "/" : `/${menuProp}`);
    setActiveMenu(menuProp);
    setMenuDlg(false);
    setTimeout(() => {
      scroll();
    }, 250);
  };

  const scroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // BackDrop
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  // // Clearable
  // setTimeout(async () => {
  //   const close = await document.getElementsByClassName(
  //     "MuiAutocomplete-clearIndicator"
  //   )[0];
  //   close.addEventListener("click", () => {
  //   });
  // }, 100);

  // useEffect
  useEffect(() => {
    dispatch(getCompareList());
    dispatch(getWishlist());
    if (localData() && localData()._id) {
      dispatch(getNotificationByCustomer(localData()._id));
      dispatch(getAskPriceListByCustomer(localData()._id));
    }
    // if (!(medicines && medicines.length > 0)) dispatch(getMedicines());
  }, []);

  // Read Not Count use Effect
  useEffect(() => {
    if (
      customerNotifications &&
      customerNotifications.length > 0 &&
      localData()._id
    ) {
      const readArr = customerNotifications.filter((o) => o.read);
      setUnReadNotiCount(customerNotifications.length - readArr.length);
      setReadNotiCount(readArr.length);
    }
  }, [customerNotifications]);

  // set medList
  useEffect(() => {
    setMedLoading(true);
    if (medicines && medicines.length > 1) {
      const M = medicines.find((m) => m.lang === lang);
      let returnMedicines = [];
      if (M && M.data) {
        const validMeds = M.data.filter((m) => m.visible === true);
        returnMedicines = validMeds;
      }
      const MEn = medicines.find((m) => m.lang === "en");
      if (MEn && MEn.data) {
        setMedListEn(MEn.data);
      }

      let returnMedicinesWithViewCount = [];

      returnMedicines.forEach((x) => {
        let obj = { ...x };
        const C = viewCountArr.find(
          (c) =>
            c.medicineIdEn ===
            (lang !== "en" ? x.medicineDetectorId : x.fixedMedicineId)
        );
        if (C) obj.viewCount = C.viewCount;
        else obj.viewCount = 0;
        returnMedicinesWithViewCount.push(obj);
      });

      const sortedReturnMedicinesWithViewCount = [
        ...returnMedicinesWithViewCount,
      ].sort((a, b) => (b.viewCount > a.viewCount ? 1 : -1));

      setMedList([...sortedReturnMedicinesWithViewCount]);
    }
    setTimeout(() => {
      setMedLoading(false);
    }, 1000);
  }, [lang, medicines]);

  // Set Language
  useEffect(() => {
    if (activeLangFront) setLang(activeLangFront.langCode);
  }, [activeLangFront]);

  // useEffect(() => {
  //   setProfileOpen(openProfileDrawerDlg);
  // }, [openProfileDrawerDlg]);

  // Check current url
  var currentUrl = window.location.href;
  useEffect(() => {
    if (currentUrl.includes("our-medicine")) {
      setActiveMenu("our-medicines");
      setIsHome(false);
    } else if (currentUrl.includes("blog")) {
      setActiveMenu("blog");
      setIsHome(false);
    } else {
      setActiveMenu("home");
      setIsHome(true);
    }
  }, [currentUrl]);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate

  return (
    <>
      {error ? (
        <Alert severity="error">
          {translateFront("Something Went Wrong...")}
        </Alert>
      ) : (
        <>
          <Grid
            container
            spacing={{ xs: 0, sm: 0 }}
            justifyContent="flex-end"
            alignItems="center"
            sx={{
              background: webSettings.siteColorTheme || "lightblue",
              position: smUp && "sticky",
              top: smUp && 0,
              zIndex: smUp && 11,
            }}
          >
            {/* logo */}
            <Grid item>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  mx: 1,

                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/");
                  // dispatch(triggerPreloader());
                }}
              >
                <LazyLoadImage
                  alt="Black"
                  effect="blur"
                  src={
                    webSettings.siteLogo !== ""
                      ? PF + "/misc/" + webSettings.siteLogo
                      : "https://mfw.com.bd/wp-content/uploads/2021/05/getImage.png"
                  }
                  style={{
                    objectFit: "cover",
                    // width: smUp && "100%",
                    maxHeight: !smUp ? "50px" : "50px",
                    display: mdUp ? "block" : "none",
                    // width: "100%",
                    margin: "0px 1px",
                    // borderRadius: "5rem",
                  }}
                />
              </Box>
            </Grid>
            {/* Search Bar */}
            <Grid item flex={1}>
              {!medLoading && (
                <Autocomplete
                  sx={{ mx: 1 }}
                  filterOptions={filterOptions}
                  disablePortal
                  options={medList}
                  getOptionLabel={(option) =>
                    `${option.genericBrand} (${option.generic}) ${
                      lang !== "en"
                        ? " - " +
                          getMedNameEn(option.medicineDetectorId) +
                          " (" +
                          getMedGenericNameEn(option.medicineDetectorId) +
                          ")"
                        : ""
                    }`
                  }
                  onChange={(e, newValue) => handleSearchClick(e, newValue)}
                  onKeyPress={(e, newValue) =>
                    e.key === "Enter" && handleSearchClick(e, newValue)
                  }
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        src={
                          option.image.length > 0
                            ? option.image
                                .find((i) => i.default)
                                .src.includes("http")
                              ? option.image.find((i) => i.default).src
                              : PF +
                                "adminPanel/medicines/img/" +
                                option.image.find((i) => i.default).src
                            : PF + "adminPanel/medicines/no-medicine-image.jpg"
                        }
                        alt={option.genericBrand}
                      />
                      {option.genericBrand} {" (" + option.generic + ") "}
                      {lang !== "en"
                        ? " - " + getMedNameEn(option.medicineDetectorId)
                        : ""}
                      {lang !== "en"
                        ? " (" +
                          getMedGenericNameEn(option.medicineDetectorId) +
                          ")"
                        : ""}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      color="secondary"
                      label={translateFront("Search Products...")}
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              )}
            </Grid>

            {/* Menus */}
            {smUp && (
              <Grid item sx={{ mt: 1 }}>
                <Box
                  sx={{
                    // position: !smUp && "sticky",
                    // top: !smUp && 0,
                    // zIndex: !smUp && 11,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {localData() && localData()._id ? (
                    <Tooltip
                      arrow
                      placement="top"
                      title={translateFront("Profile")}
                    >
                      <IconButton onClick={() => setProfileOpen(true)}>
                        <Badge
                          badgeContent={unReadNotiCount || 0}
                          color="error"
                        >
                          <Avatar
                            alt={localData().name}
                            src={
                              localData().customerPic
                                ? localData().customerPic
                                : localData().gender
                                ? localData().gender === "male"
                                  ? PF +
                                    "frontEnd/default/man-default-customer-image.jpg"
                                  : localData().gender === "female" &&
                                    PF +
                                      "frontEnd/default/women-default-customer-image.jpg"
                                : "https://picsum.photos/200/300"
                            }
                            sx={{ width: 24, height: 24 }}
                          />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <IconButton disabled>
                      <PersonIcon />
                    </IconButton>
                  )}
                  <Tooltip
                    arrow
                    placement="top"
                    title={translateFront("Wishlist")}
                  >
                    <IconButton
                      onClick={() => setWishOpen(true)}
                      disabled={wishlist && wishlist.length < 1}
                    >
                      <Badge
                        badgeContent={wishlist && wishlist.length}
                        color="primary"
                      >
                        <FavoriteIcon
                          sx={{
                            color:
                              wishlist && wishlist.length > 0
                                ? "black"
                                : "grey",
                          }}
                        />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    arrow
                    placement="top"
                    title={translateFront("Comparison")}
                  >
                    <IconButton
                      onClick={() => setCompareOpen(true)}
                      disabled={compareList && compareList.length < 1}
                      // disabled
                    >
                      <Badge
                        badgeContent={compareList ? compareList.length : 0}
                        color="error"
                      >
                        <CompareIcon
                          sx={{
                            color:
                              compareList && compareList.length > 0
                                ? "black"
                                : "grey",
                          }}
                        />
                      </Badge>
                    </IconButton>
                  </Tooltip>

                  {localData() && localData()._id && (
                    <Tooltip
                      arrow
                      placement="top"
                      title={translateFront("Cart")}
                    >
                      <IconButton
                        onClick={() => setCartOpen(true)}
                        disabled={carts && carts.length < 1}
                      >
                        <Badge
                          badgeContent={carts ? carts.length : 0}
                          color="error"
                        >
                          <ShoppingCartIcon
                            sx={{
                              color:
                                carts && carts.length > 0 ? "black" : "grey",
                            }}
                          />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                  )}
                  <IconButton onClick={() => setMenuDlg(true)} size="small">
                    <MenuIcon sx={{ color: "black" }} />
                  </IconButton>
                  <Drawer
                    anchor={"right"}
                    open={menuDlg}
                    onClose={() => setMenuDlg(false)}
                  >
                    <List>
                      <ListItem disablePadding>
                        <ListItemButton
                          selected={activeMenu === "home"}
                          onClick={() => handleMenu("home")}
                        >
                          <ListItemText primary={translateFront("Home")} />
                        </ListItemButton>
                      </ListItem>
                      <Link
                        to="/our-medicines"
                        style={{
                          color: "inherit",
                          textDecoration: "none",
                          width: "100%",
                        }}
                      >
                        <ListItem disablePadding>
                          <ListItemButton
                            selected={activeMenu === "our-medicines"}
                            onClick={() => handleMenu("our-medicines")}
                          >
                            <ListItemText
                              primary={translateFront("Our Medicine")}
                            />
                          </ListItemButton>
                        </ListItem>
                      </Link>
                      <ListItem disablePadding>
                        <Link
                          to="/blog"
                          style={{
                            color: "inherit",
                            textDecoration: "none",
                            width: "100%",
                          }}
                        >
                          <ListItem disablePadding>
                            <ListItemButton
                              selected={activeMenu === "blog"}
                              onClick={() => handleMenu("blog")}
                            >
                              <ListItemText primary={translateFront("Blog")} />
                            </ListItemButton>
                          </ListItem>
                        </Link>
                      </ListItem>
                      <ListItem disablePadding>
                        {/* <Link
                          to="/testimonials"
                          style={{
                            color: "inherit",
                            textDecoration: "none",
                            width: "100%",
                          }}
                        > */}
                        <ListItem disablePadding>
                          <ListItemButton
                            selected={activeMenu === "testimonials"}
                            // onClick={() => handleMenu("testimonials")}
                            onClick={() => {
                              navigate("/");
                              setOpen(true);
                              setMenuDlg(false);
                              setTimeout(
                                () => {
                                  dispatch(setIdToScroll("testimonial"));
                                  setOpen(false);
                                },
                                isHome ? 100 : 3000
                              );
                            }}
                          >
                            <ListItemText
                              primary={translateFront("Testimonial")}
                            />
                          </ListItemButton>
                        </ListItem>
                        {/* </Link> */}
                      </ListItem>
                      <ListItem disablePadding>
                        {/* <Link
                          to="/contact-us"
                          style={{
                            color: "inherit",
                            textDecoration: "none",
                            width: "100%",
                          }}
                        > */}
                        <ListItem disablePadding>
                          <ListItemButton
                            selected={activeMenu === "contact-us"}
                            // onClick={() => handleMenu("contact-us")}
                            onClick={() => {
                              setOpen(true);
                              navigate("/");
                              setMenuDlg(false);
                              setTimeout(
                                () => {
                                  dispatch(setIdToScroll("contact-us"));
                                  setOpen(false);
                                },
                                isHome ? 100 : 3000
                              );
                            }}
                          >
                            <ListItemText
                              primary={translateFront("Contact Us")}
                            />
                          </ListItemButton>
                        </ListItem>
                        {/* </Link> */}
                      </ListItem>
                      <ListItem disablePadding>
                        {/* <Link
                          to="/how-to-order"
                          style={{
                            color: "inherit",
                            textDecoration: "none",
                            width: "100%",
                          }}
                        > */}
                        <ListItem disablePadding>
                          <ListItemButton
                            selected={activeMenu === "how-to-order"}
                            // onClick={() => handleMenu("how-to-order")}
                            onClick={() => {
                              navigate("/");
                              setOpen(true);
                              setMenuDlg(false);
                              setTimeout(
                                () => {
                                  dispatch(setIdToScroll("how-to-order"));
                                  setOpen(false);
                                },
                                isHome ? 100 : 3000
                              );
                            }}
                          >
                            <ListItemText
                              primary={translateFront("How To Order")}
                            />
                          </ListItemButton>
                        </ListItem>
                        {/* </Link> */}
                      </ListItem>
                    </List>
                  </Drawer>
                </Box>
              </Grid>
            )}

            {/* Live Chat Button */}
            {/* <Grid item xs={2} sm={0.5} md={2} sx={{ pl: { xs: 2, sm: 0 } }}> */}
            {/* <> */}
            {/* <Grid item xs={4}> */}
            {/* <Button
                startIcon={<Call />}
                variant="contained"
                size="small"
                color="error"
                onClick={handleCall}
              >
                Call
              </Button> */}
            {/* </Grid> */}
            {/* <Grid item xs={2}> */}

            {/* </Grid> */}
            {/* </> */}
            {/* <Button
                variant="contained"
                color="secondary"
                startIcon={<ChatIcon />}
                size="small"
                onClick={handleMessage}
              >
                {matches ? (
                  <Typography variant="body2">
                    Live Chat with Pharmacist
                  </Typography>
                ) : (
                  <Typography variant="body2">Chat</Typography>
                )}
              </Button> */}
            {/* </Grid> */}
          </Grid>
          {/* wishlist drawer */}
          {wishOpen && (
            <Drawer
              anchor="left"
              open={wishOpen}
              onClose={() => setWishOpen(false)}
            >
              <WishListDrawer wishOpen={wishOpen} setWishOpen={setWishOpen} />
            </Drawer>
          )}
          {/* Cart Drawer */}
          {cartOpen && (
            <Drawer
              anchor="right"
              open={cartOpen}
              // onClose={() => setCartOpen(false)}
            >
              <CartDrawer
                setCartOpen={setCartOpen}
                setCheckoutDlg={setCheckoutDlg}
              />
            </Drawer>
          )}
          {/* profile dialog menu */}
          <Drawer
            anchor="top"
            open={profileOpen}
            onClose={() => setProfileOpen(false)}
          >
            <ProfileDrawer
              readNotiCount={readNotiCount}
              setProfileOpen={setProfileOpen}
            />
          </Drawer>

          {/* chat dialog */}
          {/* <Dialog
            open={messageOpen}
            onClose={() => setMessageOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <MoreCallOptions contactMedium="Message" />
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                fullWidth
                color="error"
                size="small"
                onClick={() => setMessageOpen(false)}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog> */}

          {/* Compare Dialog */}
          {compareOpen && (
            <CompareDlg
              compareOpen={compareOpen}
              setCompareOpen={setCompareOpen}
              lang={localLangData ? localLangData.langCode : "en"}
            />
          )}

          {/* CheckOut Dlg */}
          {checkoutDlg && (
            <CheckoutMain
              checkoutDlg={checkoutDlg}
              setCheckoutDlg={setCheckoutDlg}
            />
          )}

          {orderHistoryDlg && <OrderHistoryDialog />}

          {/* Back Drop */}
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
      )}
    </>
  );
}
