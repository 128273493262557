import React, { Fragment, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  Avatar,
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";

// lazy load image
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { getCreated, getExactTime } from "../hooks/getCreatedData";
import {
  getNotificationByCustomer,
  openOrderHistoryDialog,
  readNotificationByCustomer,
  setActiveLangFront,
  setIdToScroll,
} from "../reduxToolkit/features/miscFront";
import LoadingBox from "./misc/Loading";
import {
  getLangFromSpecificURL,
  getProductNameFromSpecificURL,
} from "../hooks/getProductNameFromURL";
import { useNavigate } from "react-router-dom";
import useLazyLoad from "../hooks/useLazyLoad";
import { useRef } from "react";
import { makeUrlFriendly } from "../hooks/makeUrlFriendly";
import { getMedicines } from "../reduxToolkit/features/adminPanel/medicine";

export default function CustomerNotificationDlg({
  readNotiCount,
  customerNotiDlg,
  setCustomerNotiDlg,
  setEditProfileDlg,
  setProfileOpen,
  // setOrderHistoryDlg,
}) {
  const navigate = useNavigate();

  // use State
  const [notiLoading, setNotiLoading] = useState(false);
  const [notiList, setNotiList] = useState([]);
  const [noMoreLoad, setNoMoreLoad] = useState(false);
  const [markAllClicked, setMarkAllClicked] = useState(false);

  // Local Store
  const localData = JSON.parse(localStorage.getItem("loginInfo"));
  const localLangData = JSON.parse(localStorage.getItem("langFront"));

  // Store
  const { customerNotifications } = useSelector((state) => state.miscFront);
  const { langs } = useSelector((state) => state.misc);
  // Store
  const { medicines, medVariants, dosages } = useSelector(
    (state) => state.medicine
  );

  //   Functions
  const dispatch = useDispatch();
  const triggerRef = useRef();

  const getDosageInfo = (dosageId, langCode) => {
    let dosageName = "";
    if (dosages && dosages.length > 0) {
      const dosageList = dosages.find((d) => d.lang === langCode);
      if (dosageList && dosageList.data) {
        const D = dosageList.data.find((d) =>
          langCode !== "en" ? d.dosageDetectorId : d.fixedDosageId === dosageId
        );
        if (D && D.dosageName) dosageName = D.dosageName;
      }
    }
    return dosageName;
  };

  const getMedUrlName = (medId) => {
    let medUrlName = "";
    const M = medicines.find((m) => m.lang === localLangData.langCode);
    if (M && M.data) {
      const Med = M.data.find(
        (med) =>
          (localLangData.langCode !== "en"
            ? med.medicineDetectorId
            : med.fixedMedicineId) === medId
      );
      if (Med) {
        if (localLangData.langCode !== "en") {
          const Men = medicines.find((men) => men.lang === "en");
          if (Men && Men.data) {
            const MedEn = Men.data.find(
              (medEn) => medEn.fixedMedicineId === Med.medicineDetectorId
            );
            if (MedEn) medUrlName = makeUrlFriendly(MedEn.genericBrand);
          } else medUrlName = makeUrlFriendly(Med.genericBrand);
        } else medUrlName = makeUrlFriendly(Med.genericBrand);
      }
    }
    return medUrlName;
  };

  const handleNotiTitle = (x) => {
    let str = "";
    // Assigned Price
    if (
      x.change.title === "assigned-price" ||
      x.change.title === "unassigned-price" ||
      x.change.title === "update-assigned-price" ||
      x.change.title === "cancel-price"
    ) {
      if (
        localLangData &&
        localLangData.langCode &&
        medicines &&
        medicines.length > 0
      ) {
        if (x.change.title === "assigned-price")
          str += "Price now available for  ";
        else if (x.change.title === "cancel-price")
          str += "Price request cancelled for  ";
        else if (x.change.title === "update-assigned-price")
          str += "Price request Updated for  ";
        else str += "Price removed for  ";
        const M = medicines.find((m) => m.lang === localLangData.langCode);
        if (M && M.data) {
          const Med = M.data.find(
            (med) =>
              (localLangData.langCode !== "en"
                ? med.medicineDetectorId
                : med.fixedMedicineId) === x.change.medId
          );
          if (Med) {
            str += Med.genericBrand || "";
            if (medVariants && medVariants.length > 0) {
              const V = medVariants.find(
                (v) =>
                  (localLangData.langCode !== "en"
                    ? v.variantDetectorId
                    : v.fixedVariantId) === x.change.variantId
              );
              if (V) {
                const dosageInfo = `(${getDosageInfo(
                  V.dosageForm,
                  localLangData.langCode
                )}-${V.strength}-${V.packTypes})`;

                str += dosageInfo || "";
              }
            }
          }
        }
      }
      // }
    } else {
      str = x.title;
    }
    return str;
  };

  const handleReadAllNotification = () => {
    setMarkAllClicked(true);
    customerNotifications.forEach((x) => {
      if (!x.read) {
        dispatch(readNotificationByCustomer(x._id));
      }
    });
    setTimeout(() => {
      dispatch(getNotificationByCustomer(localData._id));
    }, 1000);
  };

  const handleNotiClick = (read, link, change, notiId) => {
    setNotiLoading(true);
    if (read && read) {
    } else dispatch(readNotificationByCustomer(notiId));
    if (!link)
      setTimeout(() => {
        if (change.title) {
          if (
            change.title === "assigned-price" ||
            change.title === "unassigned-price" ||
            change.title === "update-assigned-price" ||
            change.title === "cancel-price"
          ) {
            let productName = getMedUrlName(change.medId);
            setTimeout(() => {
              navigate(`/our-medicines/${productName}`);
              setProfileOpen(false);
            }, 500);
          }
          if (change.title === "order-created")
            dispatch(openOrderHistoryDialog(true));
        } else {
          if (change === "password-update" || change === "profile-update")
            setEditProfileDlg(true);
          if (change.includes("review-submitted")) {
            const productName = getProductNameFromSpecificURL(change);
            navigate(`/our-medicines/${productName}`);
            setProfileOpen(false);
          }
          if (change.includes("price-request")) {
            const productName = getProductNameFromSpecificURL(change);
            const langId = getLangFromSpecificURL(change);
            if (langs && langId) {
              const L = langs.find((l) => l._id === langId);
              if (L) {
                localStorage.setItem("langFront", JSON.stringify(L));
                dispatch(setActiveLangFront(L));
              }
            }
            setTimeout(() => {
              navigate(`/our-medicines/${productName}`);
              setProfileOpen(false);
            }, 500);
          }
          if (change === "reviewer-info-request") {
            //  onClick={() => {
            navigate("/");
            dispatch(setIdToScroll("testimonial"));
            // setMenuDlg(false);
            // }}
            setProfileOpen(false);
          }
        }
        setNotiLoading(false);
        setCustomerNotiDlg(false);
        dispatch(getNotificationByCustomer(localData._id));
      }, 1000);
  };

  // Handle lazy load
  const NUM_PER_PAGE = 11;
  const noti = customerNotifications.slice(0, NUM_PER_PAGE);
  const TOTAL_PAGES = customerNotifications.length / NUM_PER_PAGE;

  const onGrabData = (currentPage) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const noti = customerNotifications;
        const notiData = noti.slice(
          ((currentPage - 1) % TOTAL_PAGES) * NUM_PER_PAGE,
          NUM_PER_PAGE * (currentPage % TOTAL_PAGES)
        );
        resolve(notiData);
      }, 2000);
    });
  };

  const { data, loading: loadingNoti } = useLazyLoad({
    triggerRef,
    onGrabData,
  });

  useEffect(() => {
    if (markAllClicked) {
      setMarkAllClicked(false);
    }
    if (customerNotifications.length > NUM_PER_PAGE) {
      if (data.length <= customerNotifications.length) {
        if (data && data.length > 0) {
          setNotiList(data);
        } else setNotiList(noti);
      } else {
        setNoMoreLoad(true);
      }
    } else setNotiList(noti);
  }, [data]);

  useEffect(() => {
    // if (!(medicines && medicines.length > 0)) dispatch(getMedicines());
  }, []);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate

  return (
    <>
      <Dialog
        open={customerNotiDlg}
        onClose={() => setCustomerNotiDlg(false)}
        aria-labelledby={"customer-notification"}
        fullWidth
        maxWidth={"sm"}
        sx={{
          "& .MuiDialog-container": {
            justifyContent: "flex-end",
            alignItems: "flex-start",
          },
        }}
      >
        <DialogTitle align="center">
          {translateFront("Notifications")} ({customerNotifications.length} )
        </DialogTitle>
        {notiLoading ? (
          <LoadingBox w={"100%"} />
        ) : notiList && notiList.length < 1 ? (
          <Alert severity="warning">
            {translateFront("No Data Available")}
          </Alert>
        ) : (
          <>
            <List dense sx={{ width: "100%", bgcolor: "background.paper" }}>
              {notiList.map((x, i) => (
                <Fragment key={i}>
                  <ListItem
                    sx={{
                      background: markAllClicked
                        ? "white"
                        : x.read
                        ? "white"
                        : "lightgrey",
                    }}
                    ref={triggerRef}
                  >
                    <ListItemButton
                      onClick={() =>
                        handleNotiClick(x.read, x.link, x.change, x._id)
                      }
                      // disabled={!noMoreLoad && loadingNoti}
                    >
                      <ListItemAvatar>
                        <Avatar sx={{ background: "white" }}>
                          <LazyLoadImage
                            effect="blur"
                            src={x.notificationImage}
                            alt={x.title}
                            style={{
                              width: 32,
                              height: 32,
                              objectFit: "fill",
                            }}
                          />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={handleNotiTitle(x)}
                        secondary={
                          <Typography variant="body2" color="initial">
                            {getCreated(x.createdAt)} <br />
                            {getExactTime(x.createdAt)}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                </Fragment>
              ))}
              {/* {unreadNotifications.length > 0 && ( */}
              {/* )} */}
            </List>
            <Typography
              variant="body1"
              align="center"
              color="primary"
              onClick={handleReadAllNotification}
              sx={{
                textDecoration: "underline",
                cursor: "pointer",
                position: "sticky",
                bottom: 0,
                background: "white",
                zIndex: 1,
                pb: 1,
              }}
            >
              {translateFront("Mark all read")}
            </Typography>
          </>
        )}
        {!noMoreLoad &&
          loadingNoti &&
          customerNotifications.length > NUM_PER_PAGE && (
            <Box>
              <LoadingBox w={"100%"} />
            </Box>
          )}
      </Dialog>
    </>
  );
}
