import {
  Button,
  Card,
  CardContent,
  Divider,
  Typography,
  Grid,
  Box,
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
  IconButton,
  Tooltip,
  DialogTitle,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCreated, getExactTime } from "../../../hooks/getCreatedData";

// Icons
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { useState } from "react";
import medicine, {
  getDosages,
  getMedicines,
} from "../../../reduxToolkit/features/adminPanel/medicine";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { PF } from "../../../config";
import LoadingBox from "../../../components/misc/Loading";
import ViewProductDetails from "../medicines/components/ViewProductDetails";
import { getAllDeliveryMethods } from "../../../reduxToolkit/features/adminPanel/delivery";
import { getAllPaymentMethods } from "../../../reduxToolkit/features/adminPanel/payment";
import OrderHistoryFromCustomer from "../../customers/dialogs/OrderHistoryFromCustomer";
import getSymbolFromCurrency from "currency-symbol-map";
import { currencyFormatter } from "../../../hooks/currencyFormatter";
import EditOrder from "./EditOrder";
import LoadingButton from "@mui/lab/LoadingButton";
import { deleteOrder } from "../../../reduxToolkit/features/adminPanel/order";
import { getNotification } from "../../../reduxToolkit/features/adminPanel/notification";
import { getAllOrders } from "../../../reduxToolkit/features/frontend/checkout";

export default function OrderCard({
  x,
  lang,
  fromCustomer,
  customerId,
  // orderBackends,
}) {
  const dispatch = useDispatch();
  // use State
  // const [openDetails, setOpenDetails] = useState(false);
  const [showShipping, setShowShipping] = useState([]);
  const [showProduct, setShowProduct] = useState([]);
  const [viewMedDlg, setViewMedDlg] = useState(false);
  const [viewMedBuffer, setViewMedBuffer] = useState(false);

  const [orderComplete, setOrderComplete] = useState(false);

  // Dialogs
  const [loadingDlt, setLoadingDlt] = useState(false);
  const [dltDlgOpen, setDltDlgOpen] = useState(false);
  const [editDisable, setEditDisable] = useState(false);
  const [editDlgOpen, setEditDlgOpen] = useState(false);

  //   Local Data
  const localAdminData = () => {
    let obj = {};
    const admin = JSON.parse(localStorage.getItem("adminLoginInfo"));
    if (admin) obj = admin;
    return obj;
  };
  //

  // Store
  const { customers } = useSelector((state) => state.auth);
  const { deliveryMethods } = useSelector((state) => state.delivery);
  const { langs } = useSelector((state) => state.misc);
  const { paymentMethods } = useSelector((state) => state.payment);
  const { medicines, medVariants, dosages } = useSelector(
    (state) => state.medicine
  );

  // Functions
  const getLangId = (langCode) => {
    let langId = "62a0848ca2ad3bd3035130f2";
    if (langs) {
      const L = langs.find((l) => l.langCode === langCode);
      if (L) langId = L._id;
    }
    return langId;
  };

  const getDeliverMethod = (id) => {
    const DbyLang = deliveryMethods.filter((d) => d.langId === getLangId(lang));
    if (DbyLang) {
      const D = DbyLang.find(
        (d) => (lang === "en" ? d._id : d.deliveryDetectorId) === id
      );
      if (D) return D;
    }
    return {};
  };

  const getPaymentMethod = (id) => {
    const PbyLang = paymentMethods.filter((p) => p.langId === getLangId(lang));
    if (PbyLang) {
      const P = PbyLang.find(
        (p) => (lang === "en" ? p._id : p.paymentDetectorId) === id
      );
      if (P) return P;
    }
    return {};
  };

  const getCustomerInfo = (id) => {
    if (customers && customers.length > 0) {
      const C = customers.find((c) => c._id === id);
      if (C) return C;
    }
    return {};
  };

  const handleShowShipping = (id) => {
    let arr = [...showShipping];
    if (showShipping.includes(id)) arr = arr.filter((a) => a !== id);
    else arr.push(id);
    setShowShipping(arr);
  };

  const handleShowProduct = (id) => {
    let arr = [...showProduct];
    if (showProduct.includes(id)) arr = arr.filter((a) => a !== id);
    else arr.push(id);
    setShowProduct(arr);
  };

  const getDosageInfo = (dosageId, langCode) => {
    let dosageName = "";
    if (dosages && dosages.length > 0) {
      const dosageList = dosages.find((d) => d.lang === langCode);
      if (dosageList && dosageList.data) {
        const D = dosageList.data.find((d) =>
          langCode !== "en" ? d.dosageDetectorId : d.fixedDosageId === dosageId
        );
        if (D && D.dosageName) dosageName = D.dosageName;
      }
    }
    return dosageName;
  };

  const getVariantInfo = (varId) => {
    let obj = {
      dosage: "",
      strength: "",
      packTypes: "",
    };
    if (medVariants && medVariants.length > 0) {
      const V = medVariants.find(
        (v) =>
          (lang !== "en" ? v.variantDetectorId : v.fixedVariantId) === varId
      );
      if (V) {
        obj.dosage = getDosageInfo(V.dosageForm, lang);
        obj.strength = V.strength;
        obj.packTypes = V.packTypes;
      }
    }
    return obj;
  };

  const getMedInfo = (medId) => {
    let obj = {
      medImg: "",
      genericBrand: "",
    };
    if (medicines && medicines.length > 0) {
      const M = medicines.find((m) => m.lang === lang);
      if (M && M.data) {
        const Med = M.data.find(
          (med) =>
            (lang !== "en" ? med.medicineDetectorId : med.fixedMedicineId) ===
            medId
        );
        if (Med) {
          obj.genericBrand = Med.genericBrand;
          if (Med.fixedMedicineId) obj.fixedMedicineId = Med.fixedMedicineId;
          if (Med.medicineDetectorId)
            obj.medicineDetectorId = Med.medicineDetectorId;
          const medImg = Med.image.find((i) => i.default);
          if (medImg) obj.medImg = medImg;
        }
      }
    }
    return obj;
  };

  const getTotalPrice = () => {
    let total = 0;
    x.orderedItems.forEach((c, i) => {
      total += Number(c.price) * Number(c.moq);
    });
    total = Math.ceil(total.toFixed(2));
    return total;
  };

  const handleDeleteOrder = () => {
    setLoadingDlt(true);
    let obj = {
      orderObj: x,
    };

    if (localAdminData()._id) obj.creatorId = localAdminData()._id;
    dispatch(deleteOrder(obj));
    setTimeout(() => {
      dispatch(getAllOrders());
      dispatch(getNotification());
      setLoadingDlt(false);
      setDltDlgOpen(false);
    }, 1000);
  };

  // Fixed date
  const [showDate, setShowDate] = useState([]);

  const handleShowDate = (date) => {
    let arr = [...showDate];
    if (arr.includes(date)) {
      arr = arr.filter((a) => a !== date);
    } else {
      arr.push(date);
    }
    setShowDate(arr);
  };

  //   Use Effect
  useEffect(() => {
    if (
      !(
        medicines &&
        medicine.length > 0 &&
        medVariants &&
        medVariants.length > 0
      )
    )
      dispatch(getMedicines());
    if (!(dosages && dosages.length > 0)) dispatch(getDosages());
    if (!(deliveryMethods && deliveryMethods.length > 0))
      dispatch(getAllDeliveryMethods());
    if (!(paymentMethods && paymentMethods.length > 0))
      dispatch(getAllPaymentMethods());
  }, []);

  // ! translate
  // Store
  // const { langs } = useSelector((state) => state.misc);
  const [langSettings1, setLangSettings1] = useState([]);
  const [langSettings2, setLangSettings2] = useState([]);
  const title1 = "Order Card Backend";
  const title2 = "Misc Words";

  useEffect(() => {
    if (langs && langs.length > 0 && lang !== "") {
      const L = langs.find((l) => l.langCode === lang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      const A = arr.find((l) => l._title === title1);
      if (A) setLangSettings1(A.data);
      const B = arr.find((l) => l._title === title2);
      if (B) setLangSettings2(B.data);
    }
  }, [lang, langs]);

  const translate1 = (String) => translation(String, title1, 1);
  const translate2 = (String) => translation(String, title2, 2);

  const translation = (String, title, index) => {
    let translation = "";
    let settings = [];
    if (index === 1) {
      settings = langSettings1;
    } else if (index === 2) {
      settings = langSettings2;
    }
    const T = settings.find((ls) => ls.title === String);
    if (T && T.value) translation = T.value;
    else {
      let arr = [];
      if (langs && langs.length > 0 && lang !== "") {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.settings && LEn.settings.length > 0) arr = LEn.settings;
        const S = arr.find((l) => l._title === title);
        if (S) {
          const T = S.data.find((ls) => ls.title === String);
          if (T && T.value) translation = T.value;
        }
      }
    }
    return translation;
  };

  // ! translate

  //! Translate
  // Store
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate

  return (
    <>
      <Card sx={{ background: orderComplete && "lightgreen" }}>
        <CardContent>
          <Grid container spacing={0}>
            <Grid item flex={1}>
              <Typography gutterBottom variant="h5" component="div">
                #{x.orderNumber}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                size="small"
                onClick={() => setEditDlgOpen(true)}
                disabled={editDisable}
              >
                <EditIcon color={editDisable ? "inherit" : "secondary"} />
              </IconButton>
              {editDlgOpen && (
                <EditOrder
                  editDlgOpen={editDlgOpen}
                  setEditDlgOpen={setEditDlgOpen}
                  x={x}
                  getDeliverMethod={getDeliverMethod}
                  getPaymentMethod={getPaymentMethod}
                  lang={lang}
                />
              )}
            </Grid>
            <Grid item>
              <IconButton size="small" onClick={() => setDltDlgOpen(true)}>
                <DeleteIcon color="error" />
              </IconButton>
              {dltDlgOpen && (
                <Dialog open={dltDlgOpen}>
                  <DialogTitle>
                    {translate0("Do you really want to delete")}?
                  </DialogTitle>
                  <DialogActions>
                    <Button
                      onClick={() => setDltDlgOpen(false)}
                      color="primary"
                    >
                      {translate0("Cancel")}
                    </Button>
                    <LoadingButton
                      loading={loadingDlt}
                      onClick={handleDeleteOrder}
                      color="error"
                    >
                      {translate0("Confirm")}
                    </LoadingButton>
                  </DialogActions>
                </Dialog>
              )}
            </Grid>
          </Grid>
          <Typography variant="body2" color="text.secondary">
            {getCustomerInfo(x.customerId).name.toUpperCase()}
          </Typography>
          <Typography variant="body2" color="error">
            {getCreated(x.updatedAt)}
            <Tooltip
              arrow
              placement="top"
              title={`Created: ${getExactTime(x.createdAt)}`}
            >
              <IconButton
                size="small"
                onClick={() => handleShowDate(x.createdAt)}
              >
                <InfoIcon color="info" />
              </IconButton>
            </Tooltip>
            <br />
            {showDate.includes(x.createdAt) && getExactTime(x.createdAt)}
          </Typography>
          {/* <Divider /> */}
          <Button
            variant="outlined"
            size="small"
            fullWidth
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              pointerEvents: "none",
            }}
          >
            {translate1("Delivery")}
          </Button>
          <Typography variant="body2" color="text.secondary">
            {translate1("Preferred Delivery")}:{" "}
            <span style={{ color: "black" }}>
              {getDeliverMethod(x.deliveryMethodId).methodName}
            </span>
          </Typography>
          <Typography variant="body2" color="text.secondary" component="pre">
            {translate1("Delivery Note")}:{" "}
            <span style={{ color: "black" }}>{x.deliveryNote}</span>
          </Typography>
          {/* <Divider /> */}
          <Button
            variant="outlined"
            size="small"
            fullWidth
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              pointerEvents: "none",
            }}
          >
            {translate1("Payment")}
          </Button>
          <Typography variant="body2" color="text.secondary">
            {translate2("Preferred Payment")}:{" "}
            <span style={{ color: "black" }}>
              {getPaymentMethod(x.paymentMethodId).methodName}
            </span>
          </Typography>
          <Typography variant="body2" color="text.secondary" component="pre">
            {translate1("Payment Note")}:{" "}
            <span style={{ color: "black" }}>{x.paymentNote}</span>
          </Typography>
          {/* <Divider /> */}
          <Button
            variant={showShipping.includes(x._id) ? "contained" : "outlined"}
            size="small"
            fullWidth
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
            endIcon={
              showShipping.includes(x._id) ? (
                <ArrowDropUpIcon />
              ) : (
                <ArrowDropDownCircleIcon />
              )
            }
            onClick={() => handleShowShipping(x._id)}
          >
            {translate1("Shipping Address")}
          </Button>
          {showShipping.includes(x._id) && (
            <>
              <Typography
                variant="body2"
                color="text.secondary"
                component="pre"
              >
                {translate2("First Name")}:{" "}
                <span style={{ color: "black" }}>
                  {x.shippingDetails.firstName}
                </span>
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                component="pre"
              >
                {translate2("Last Name")}:{" "}
                <span style={{ color: "black" }}>
                  {x.shippingDetails.lastName}
                </span>
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                component="pre"
              >
                {translate2("Address")}:{" "}
                <span style={{ color: "black" }}>
                  <br />
                  {x.shippingDetails.address1}
                  <br />
                  {x.shippingDetails.address2 ? x.shippingDetails.address2 : ""}
                </span>
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                component="pre"
              >
                {translate2("City")}:{" "}
                <span style={{ color: "black" }}>{x.shippingDetails.city}</span>
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                component="pre"
              >
                {translate2("State")}:{" "}
                <span style={{ color: "black" }}>
                  {x.shippingDetails.state}
                </span>
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                component="pre"
              >
                {translate2("Postal Code")}:{" "}
                <span style={{ color: "black" }}>
                  {x.shippingDetails.postal}
                </span>
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                component="pre"
              >
                {translate2("Country")}:{" "}
                <span style={{ color: "black" }}>
                  {x.shippingDetails.country}
                </span>
              </Typography>
            </>
          )}
          {/* <Typography variant="body2" color="text.secondary" component="pre">
            Shipping Cost:{" "}
            <span style={{ color: "black" }}>${x.shippingCost}</span>
          </Typography> */}
          <Typography variant="body2" color="text.secondary" component="pre">
            {translate1("Total")}:{" "}
            <span style={{ color: "black" }}>
              ${currencyFormatter("USA", getTotalPrice())}
            </span>
          </Typography>
          <Typography variant="body2" color="text.secondary" component="pre">
            {translate1("Shipping Cost")}:{" "}
            <span style={{ color: "black" }}>
              ${currencyFormatter("USA", Number(x.shippingCost))}
            </span>
          </Typography>
          <Typography variant="body2" color="text.secondary" component="pre">
            {translate1("Transaction Fee")}:{" "}
            <span style={{ color: "black" }}>
              $
              {Number(
                getPaymentMethod(x.paymentMethodId).transactionUnit === 0
                  ? getTotalPrice() *
                      Number(
                        getPaymentMethod(x.paymentMethodId).transactionFee / 100
                      )
                  : Number(getPaymentMethod(x.paymentMethodId).transactionFee)
              )}
            </span>
          </Typography>
          <Typography variant="body2" color="text.secondary" component="pre">
            {translate1("Total With Shipping Cost")}:{" "}
            <span style={{ color: "black" }}>
              $
              {currencyFormatter(
                "USA",
                Number(x.shippingCost) +
                  getTotalPrice() +
                  Number(
                    getPaymentMethod(x.paymentMethodId).transactionUnit === 0
                      ? getTotalPrice() *
                          Number(
                            getPaymentMethod(x.paymentMethodId).transactionFee /
                              100
                          )
                      : Number(
                          getPaymentMethod(x.paymentMethodId).transactionFee
                        )
                  )
              )}
            </span>
          </Typography>
          <Button
            variant={showProduct.includes(x._id) ? "contained" : "outlined"}
            size="small"
            fullWidth
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: 1,
            }}
            endIcon={
              showProduct.includes(x._id) ? (
                <ArrowDropUpIcon />
              ) : (
                <ArrowDropDownCircleIcon />
              )
            }
            onClick={() => handleShowProduct(x._id)}
          >
            {translate1("Product Details")} (
            {x.orderedItems ? x.orderedItems.length : 0})
          </Button>
          {showProduct.includes(x._id) &&
            x.orderedItems &&
            x.orderedItems.map((y, j) => (
              <>
                <Grid container spacing={0}>
                  <Grid item xs={3}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setViewMedDlg(true);
                        setViewMedBuffer(y);
                      }}
                    >
                      <LazyLoadImage
                        src={
                          getMedInfo(y.medId).medImg.src !== ""
                            ? PF +
                              "adminPanel/medicines/img/" +
                              getMedInfo(y.medId).medImg.src
                            : PF + "adminPanel/medicines/no-medicine-image.jpg"
                        }
                        alt={getMedInfo(y.medId).medImg.alt}
                        effect="blur"
                        placeholderSrc="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png"
                        style={{
                          objectFit: "stretch",
                          height: 30,
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body2" color="initial">
                      {getMedInfo(y.medId).genericBrand.toUpperCase()}
                      <br />
                      {getVariantInfo(y.variantId).dosage}
                      {" - "}
                      {getVariantInfo(y.variantId).strength}
                      {" - "}
                      {getVariantInfo(y.variantId).packTypes}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ my: 1 }} />
              </>
            ))}
          {/* <Button
            variant={openDetails ? "outlined" : "contained"}
            size="small"
            fullWidth
            onClick={() => setOpenDetails(!openDetails)}
          >
            Open
          </Button> */}
          {/* {openDetails && (
            <> */}
          {fromCustomer ? (
            <OrderHistoryFromCustomer
              x={x}
              lang={lang}
              customerId={customerId}
              // orderBackends={orderBackends}
              setOrderComplete={setOrderComplete}
              translate1={translate1}
              translate2={translate2}
              setEditDisable={setEditDisable}
            />
          ) : (
            <OrderHistoryFromCustomer
              x={x}
              lang={lang}
              customerId={x.customerId}
              setOrderComplete={setOrderComplete}
              // orderBackends={orderBackends}
              translate1={translate1}
              translate2={translate2}
              setEditDisable={setEditDisable}
            />
          )}
          {/* </>
          )} */}
        </CardContent>
      </Card>

      {viewMedDlg && (
        <Dialog
          open={viewMedDlg}
          onClose={() => {
            setViewMedDlg(false);
            setViewMedBuffer({});
          }}
          fullWidth
          maxWidth={"sm"}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  setViewMedDlg(false);
                  setViewMedBuffer({});
                }}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {translate1("Product Details")}
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <ViewProductDetails
              medId={viewMedBuffer.medId}
              varId={viewMedBuffer.variantId}
              lang={lang}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
