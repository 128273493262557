import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { ExpandMore } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Policy({
  policyOpen,
  setPolicyOpen,
  policyName,
  innerPolicyDlg,
  setInnerPolicyDlg,
  innerPolicyName,
  contentSettings,
}) {
  const [policyTitle, setPolicyTitle] = useState("");
  const [policyBuffer, setPolicyBuffer] = useState([]);

  const handlePolicyClose = () => {
    setPolicyOpen(false);
    setInnerPolicyDlg(false);
    setPolicyTitle("");
    setPolicyBuffer([]);
  };
  useEffect(() => {
    if (contentSettings) {
      if (policyName === "about-us" || innerPolicyName === "about-us") {
        if (contentSettings.aboutUs && contentSettings.aboutUs.length > 0) {
          setPolicyBuffer(contentSettings.aboutUs);
          setPolicyTitle("About Us");
        }
      }
      if (policyName === "disclaimer" || innerPolicyName === "disclaimer") {
        if (
          contentSettings.disclaimer &&
          contentSettings.disclaimer.length > 0
        ) {
          setPolicyBuffer(contentSettings.disclaimer);
          setPolicyTitle("Disclaimer");
        }
      }
      if (
        policyName === "terms-and-conditions" ||
        innerPolicyName === "terms-and-conditions"
      ) {
        if (contentSettings.terms && contentSettings.terms.length > 0) {
          setPolicyBuffer(contentSettings.terms);
          setPolicyTitle("Terms & Conditions");
        }
      }
      if (
        policyName === "shipping-policy" ||
        innerPolicyName === "shipping-policy"
      ) {
        if (
          contentSettings.shippingPolicy &&
          contentSettings.shippingPolicy.length > 0
        ) {
          setPolicyBuffer(contentSettings.shippingPolicy);
          setPolicyTitle("Shipping Policy");
        }
      }
      if (
        policyName === "review-policy" ||
        innerPolicyName === "review-policy"
      ) {
        if (
          contentSettings.reviewPolicy &&
          contentSettings.reviewPolicy.length > 0
        ) {
          setPolicyBuffer(contentSettings.reviewPolicy);
          setPolicyTitle("Review Policy");
        }
      }
      if (
        policyName === "privacy-policy" ||
        innerPolicyName === "privacy-policy"
      ) {
        if (
          contentSettings.privacyPolicy &&
          contentSettings.privacyPolicy.length > 0
        ) {
          setPolicyBuffer(contentSettings.privacyPolicy);
          setPolicyTitle("Privacy Policy");
        }
      }
    }
  }, [contentSettings, innerPolicyName, policyName]);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate
  return (
    <>
      <Dialog
        open={policyOpen || innerPolicyDlg}
        onClose={handlePolicyClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
      >
        <DialogTitle>
          <Typography
            variant="h1"
            align="center"
            sx={{ fontSize: "2.5rem", textDecoration: "underline" }}
          >
            {translateFront(policyTitle).toUpperCase()}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {policyBuffer &&
          policyBuffer.length > 0 &&
          policyBuffer.length < 2 ? (
            <DialogContentText
              id="alert-dialog-slide-description"
              sx={{ textAlign: "justify" }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: `${policyBuffer[0].tabDetails}`,
                }}
              ></div>
            </DialogContentText>
          ) : (
            policyBuffer.map((x, i) => (
              <Accordion key={i}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-label={`${i}Expand`}
                  aria-controls={`${i}-content`}
                  id={`${i}-header`}
                >
                  <Typography>{x.tabTitle}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${x.tabDetails}`,
                    }}
                  ></div>
                </AccordionDetails>
              </Accordion>
            ))
          )}
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={handlePolicyClose}
            endIcon={<CloseIcon />}
            color="error"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
