import { ExpandMore } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfoTut from "../../../components/InfoTut";
import { getNotification } from "../../../reduxToolkit/features/adminPanel/notification";

import LangNTitleBar from "./LangNTitleBar";
import {
  getManagedContentSettings,
  saveManagedContentSettings,
} from "../../../reduxToolkit/features/adminPanel/managedContentSettings";

export default function SiteSEO({ expanded, handleChange, translate0 }) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [lang, setLang] = useState("");
  const [langId, setLangId] = useState("");

  const [activeTab, setActiveTab] = useState(0);

  const [siteSeo, setSiteSeo] = useState([
    {
      siteName: "",
      siteDesc: "",
      siteCompanyName: "",
    },
    {
      siteName: "",
      siteDesc: "",
      siteCompanyName: "",
    },
  ]);

  // LocalData
  // const localLangData = JSON.parse(localStorage.getItem("lang"));
  const localData = JSON.parse(localStorage.getItem("adminLoginInfo"));

  //   Store
  const { managedContentSettings } = useSelector(
    (state) => state.managedContentSettings
  );

  const handleSaveSiteSEO = () => {
    setLoading(true);
    dispatch(
      saveManagedContentSettings({
        langId,
        changedSection: "Site Seo",
        siteSeoSettings: siteSeo,
        creatorId: localData._id,
      })
    );
    setTimeout(() => {
      setLoading(false);
      dispatch(getNotification());
      dispatch(getManagedContentSettings());
    }, 1000);
  };

  useEffect(() => {
    if (!(managedContentSettings && managedContentSettings.length > 0))
      dispatch(getManagedContentSettings());
  }, []);

  // Set Settings
  useEffect(() => {
    if (managedContentSettings && managedContentSettings.length > 0) {
      const M = managedContentSettings.find((m) => m.langId === langId);
      if (M && M.siteSeoSettings) {
        setSiteSeo(M.siteSeoSettings);
      } else {
        setSiteSeo([
          {
            siteName: "",
            siteDesc: "",
            siteCompanyName: "",
          },
          {
            siteName: "",
            siteDesc: "",
            siteCompanyName: "",
          },
        ]);
      }
    }
  }, [langId, managedContentSettings]);

  return (
    <>
      <Accordion
        expanded={expanded === "site-seo"}
        onChange={handleChange("site-seo")}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-label="Expand"
          aria-controls="Head-Title-content"
          id="site-seo"
        >
          <Typography>{translate0("Site SEO")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <LangNTitleBar
            lang={lang}
            setLang={setLang}
            langId={langId}
            setLangId={setLangId}
            title={translate0("Site SEO")}
          />
          <Grid
            container
            spacing={1}
            justifyContent="center"
            alignItems="center"
            sx={{ my: 1 }}
          >
            <Grid item xs={6}>
              <Button
                variant={activeTab === 0 ? "contained" : "outlined"}
                size="small"
                fullWidth
                onClick={() => setActiveTab(0)}
              >
                {translate0("Home Page")}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant={activeTab === 1 ? "contained" : "outlined"}
                size="small"
                fullWidth
                onClick={() => setActiveTab(1)}
              >
                {translate0("Blog Page")}
              </Button>
            </Grid>
          </Grid>
          {siteSeo[activeTab] && (
            <>
              <TextField
                label={translate0("Site Name")}
                value={siteSeo[activeTab].siteName}
                onChange={(e) => {
                  let arr = [...siteSeo];
                  let obj = { ...arr[activeTab] };
                  obj.siteName = e.target.value;
                  arr[activeTab] = obj;
                  setSiteSeo(arr);
                }}
                size="small"
                fullWidth
                variant="standard"
                helperText={<InfoTut img="tut-site-title.png" />}
              />

              <TextField
                label={translate0("Meta Desc") + " (Home Page SEO)"}
                multiline
                minRows={3}
                value={siteSeo[activeTab].siteDesc || ""}
                onChange={(e) => {
                  let arr = [...siteSeo];
                  let obj = { ...arr[activeTab] };
                  obj.siteDesc = e.target.value;
                  arr[activeTab] = obj;
                  setSiteSeo(arr);
                }}
                size="small"
                fullWidth
                variant="standard"
                helperText={
                  <Box sx={{ display: "flex" }}>
                    <InfoTut img="tut-meta-desc-home.png" />
                    <Typography
                      variant="body2"
                      color="primary"
                      align="right"
                      sx={{ flex: 1 }}
                    >
                      <span
                        style={{
                          color:
                            siteSeo[activeTab].siteDesc &&
                            siteSeo[activeTab].siteDesc.length > 150
                              ? "red"
                              : "inherit",
                        }}
                      >
                        {siteSeo[activeTab].siteDesc &&
                          siteSeo[activeTab].siteDesc.length}
                      </span>
                      /150
                    </Typography>
                  </Box>
                }
              />
              <TextField
                label={translate0("Author Name for Twitter")}
                value={siteSeo[activeTab].siteCompanyName}
                onChange={(e) => {
                  let arr = [...siteSeo];
                  let obj = { ...arr[activeTab] };
                  obj.siteCompanyName = e.target.value;
                  arr[activeTab] = obj;
                  setSiteSeo(arr);
                }}
                size="small"
                fullWidth
                variant="standard"
                helperText={<InfoTut img="tut-twitter-creator.png" />}
              />
            </>
          )}
          <LoadingButton
            variant="contained"
            loading={loading}
            fullWidth
            size="small"
            color="success"
            onClick={handleSaveSiteSEO}
          >
            {translate0("Save")}
          </LoadingButton>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
