import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../config";

// Save Settings
export const saveManagedContentSettings = createAsyncThunk(
  "managedContent/saveContentSettings",
  async (data) => {
    const res = await axiosInstance.post(
      "/api/managed-content-settings/save-managed-content/",
      data
    );
    return res.data;
  }
);

// Get Settings
export const getManagedContentSettings = createAsyncThunk(
  "managedContent/getContentSettings",
  async () => {
    const res = await axiosInstance.get("/api/managed-content-settings/");
    return res.data;
  }
);

// Initial State
const initialState = {
  pending: false,
  error: false,
  managedContentSettings: {},
  snackNotiManagedContentSettings: {},
};

// Slice
export const managedContentSettingsSlice = createSlice({
  name: "managedContentSettings",
  initialState,
  reducers: {
    resetSnkManagedContentSettings(state) {
      state.snackNotiManagedContentSettings = {};
    },
  },
  extraReducers: {
    [saveManagedContentSettings.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [saveManagedContentSettings.fulfilled]: (state, action) => {
      state.pending = false;
      if (!action.payload.failed) {
        state.snackNotiManagedContentSettings = {
          message: action.payload.message,
          severity: action.payload.severity,
        };
      }
    },
    [saveManagedContentSettings.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [getManagedContentSettings.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getManagedContentSettings.fulfilled]: (state, action) => {
      state.pending = false;
      state.managedContentSettings = action.payload.settings;
    },
    [getManagedContentSettings.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
  },
});

export const { resetSnkManagedContentSettings } =
  managedContentSettingsSlice.actions;
export default managedContentSettingsSlice.reducer;
