import React from "react";
import BreadcrumbsTemplate from "../components/misc/BreadcrumbsTemplate";

export default function SingleMedicineVariation2() {
  return (
    <>
      {/* BreadCrumb */}
      <BreadcrumbsTemplate
        b2="our-medicines"
        l2="/our-medicines"
        b3="single-medicine"
      />
    </>
  );
}
