import { Alert, Divider, List, ListItem } from "@mui/material";
import React, { Fragment } from "react";
import { knowIssue } from "./data/knownIssueList";

export default function KnownIssues() {
  return (
    <List>
      {knowIssue.map((x, i) => (
        <Fragment key={i}>
          <ListItem disablePadding>
            <Alert
              severity={x.issueSeverity ? x.issueSeverity : "error"}
              sx={{
                width: "100%",
              }}
            >
              {x.issueTitle && x.issueTitle.toUpperCase()} <br />
              {x.issueDetails}
            </Alert>
          </ListItem>
          <Divider />
        </Fragment>
      ))}
    </List>
  );
}
