import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {
  Button,
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Alert,
} from "@mui/material";
import { useState } from "react";

// Icons
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { useDispatch, useSelector } from "react-redux";
import {
  createReqRule,
  getReqRule,
  removeReqRule,
  updateReqRule,
} from "../../../../reduxToolkit/features/adminPanel/review";
import LoadingBox from "../../../../components/misc/Loading";
import AddLangDialog from "../../medicines/dialog/AddLangDialog";
import { getNotification } from "../../../../reduxToolkit/features/adminPanel/notification";
import LoadingButton from "@mui/lab/LoadingButton";

export default function ReviewerRequestRules() {
  const [loadingMain, setLoadingMain] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rule, setRule] = useState("");
  const [ruleList, setRuleList] = useState([]);

  const [lang, setLang] = useState("");
  const [langId, setLangId] = useState("");

  const [editReqRuleDlg, setEditReqRuleDlg] = useState(false);
  const [editReqRuleBuffer, setEditReqRuleBuffer] = useState({});
  const [editReqRule, setEditReqRule] = useState("");
  const [editLoading, setEditLoading] = useState(false);

  // Dialog
  const [addLangDlg, setAddLangDlg] = useState(false);

  // LocalData
  const localLangData = JSON.parse(localStorage.getItem("lang"));
  const localAdminData = JSON.parse(localStorage.getItem("adminLoginInfo"));

  // store
  const { langs, activeLang } = useSelector((state) => state.misc);
  const { reviewRequestRules } = useSelector((state) => state.review);

  // Functions
  const dispatch = useDispatch();
  const handleCreateReqRule = () => {
    setLoading(true);
    dispatch(createReqRule({ langId, rule, creatorId: localAdminData._id }));
    setTimeout(() => {
      dispatch(getReqRule());
      setRule("");
      setLoading(false);
    }, 1000);
  };

  const getLangId = (langCode) => {
    let id = "";
    const L = langs.find((l) => l.langCode === langCode);
    if (L) id = L._id;
    return id;
  };

  const handleChangeLang = (e) => {
    setLangId(getLangId(e.target.value));
    setLang(e.target.value);
  };

  const handleDeleteReqRule = (obj) => {
    setLoadingMain(true);
    let newObj = { ...obj };
    newObj.creatorId = localAdminData._id;
    dispatch(removeReqRule(newObj));
    setTimeout(() => {
      dispatch(getNotification());
      dispatch(getReqRule());
      setLoadingMain(false);
    }, 1000);
  };

  const handleEditReqRule = (obj) => {
    setEditReqRuleDlg(true);
    setEditReqRuleBuffer(obj);
    setEditReqRule(obj.rule);
  };

  const handleEditConfirm = () => {
    let obj = { ...editReqRuleBuffer };
    obj.newRule = editReqRule;
    obj.creatorId = localAdminData._id;
    dispatch(updateReqRule(obj));
    setEditLoading(true);
    setTimeout(() => {
      dispatch(getNotification());
      dispatch(getReqRule());
      setEditReqRuleDlg(false);
      setEditReqRule("");
      setEditLoading(false);
    }, 1000);
  };

  // Use Effect
  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setLang(activeLang.langCode);
      setLangId(activeLang._id);
    } else {
      if (localLangData) {
        setLang(localLangData.langCode);
        setLangId(localLangData._id);
      }
    }
  }, [activeLang]);

  useEffect(() => {
    dispatch(getReqRule());
  }, []);

  // ! translate
  const [siteLang, setSiteLang] = useState("");
  const [langSettings1, setLangSettings1] = useState([]);
  const [langSettings2, setLangSettings2] = useState([]);
  const title1 = "Reviewer Info Request";
  const title2 = "Misc Words";

  useEffect(() => {
    if (langs && langs.length > 0 && siteLang !== "") {
      const L = langs.find((l) => l.langCode === siteLang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      const A = arr.find((l) => l._title === title1);
      if (A) setLangSettings1(A.data);
      const B = arr.find((l) => l._title === title2);
      if (B) setLangSettings2(B.data);
    }
  }, [siteLang, langs]);

  const translate1 = (String) => translation(String, title1, 1);
  const translate2 = (String) => translation(String, title2, 2);

  const translation = (String, title, index) => {
    let translation = "";
    let settings = [];
    if (index === 1) {
      settings = langSettings1;
    } else if (index === 2) {
      settings = langSettings2;
    }
    const T = settings.find((ls) => ls.title === String);
    if (T && T.value) translation = T.value;
    else {
      let arr = [];
      if (langs && langs.length > 0 && siteLang !== "") {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.settings && LEn.settings.length > 0) arr = LEn.settings;
        const S = arr.find((l) => l._title === title);
        if (S) {
          const T = S.data.find((ls) => ls.title === String);
          if (T && T.value) translation = T.value;
        }
      }
    }
    return translation;
  };

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setSiteLang(activeLang.langCode);
    } else {
      if (localLangData) {
        setSiteLang(localLangData.langCode);
      }
    }
  }, [activeLang]);
  // ! translate

  // Set Rule List
  useEffect(() => {
    if (reviewRequestRules && reviewRequestRules.length > 0) {
      setLoadingMain(true);
      const R = reviewRequestRules.filter((r) => r.langId === langId);
      setRuleList(R);
      setTimeout(() => {
        setLoadingMain(false);
      }, 1000);
    }
  }, [reviewRequestRules, langId]);

  return (
    <>
      <Grid
        container
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={2} sm={1} md={0.25}>
          <IconButton>
            <AddCircleOutlineOutlinedIcon
              color="info"
              size="large"
              onClick={() => setAddLangDlg(true)}
            />
          </IconButton>
          <AddLangDialog
            addLangDlg={addLangDlg}
            setAddLangDlg={setAddLangDlg}
            setLang={setLang}
          />
        </Grid>
        <Grid item xs={10} sm={4} md={2}>
          <FormControl fullWidth>
            <Select
              value={lang}
              label="language"
              variant="standard"
              onChange={handleChangeLang}
              size="small"
            >
              {langs.map((x, i) => (
                <MenuItem key={i} value={x.langCode} dense>
                  <img
                    alt={x.langCode}
                    src={`https://flagcdn.com/w20/${
                      x.langCode === "en" ? "gb" : x.langCode
                    }.png`}
                  />
                  <Button color="inherit" size="small">
                    {x.langName}
                  </Button>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={9.5}>
          <TextField
            label={translate1("Rule")}
            placeholder={translate1("Write Rule")}
            value={rule}
            onChange={(e) => setRule(e.target.value)}
            variant="outlined"
            fullWidth
            size="small"
            autoFocus
            onKeyPress={(e) =>
              e.key === "Enter" && rule.length > 0 && handleCreateReqRule()
            }
          />
        </Grid>
        <Grid item xs={0.25}>
          {loading ? (
            <LoadingBox w={"100%"} />
          ) : (
            <Tooltip arrow placement="top" title={translate2("Add")}>
              <IconButton
                size="small"
                onClick={handleCreateReqRule}
                disabled={rule.length < 1 ? true : false}
              >
                <AddCircleOutlineRoundedIcon
                  color={rule.length < 1 ? "inherit" : "success"}
                />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
        <Grid item xs={12}>
          {loadingMain ? (
            <LoadingBox w={"100%"} h={"100%"} />
          ) : ruleList.length < 1 ? (
            <Alert severity="warning">{translate2("No Data Available")}</Alert>
          ) : (
            ruleList.map((x, i) => (
              <Grid
                key={i}
                container
                spacing={0}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={11.5}>
                  <Typography variant="body1" color="initial">
                    {x.rule}
                  </Typography>
                  <Divider />
                </Grid>
                <Grid item xs={0.25}>
                  <Tooltip
                    arrow
                    placement="top"
                    title={translate1("Edit Rule")}
                  >
                    <IconButton
                      size="small"
                      onClick={() => handleEditReqRule(x)}
                    >
                      <EditIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={0.25}>
                  <Tooltip
                    arrow
                    placement="top"
                    title={translate1("Delete Rule")}
                  >
                    <IconButton
                      size="small"
                      onClick={() => handleDeleteReqRule(x)}
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            ))
          )}
        </Grid>
      </Grid>
      <Dialog
        open={editReqRuleDlg}
        onClose={() => {
          setEditReqRuleDlg(false);
          setEditReqRule("");
          setEditReqRuleBuffer({});
        }}
      >
        <DialogTitle>Edit Request Rule</DialogTitle>
        <DialogContent>
          <TextField
            label="Request Rule"
            value={editReqRule}
            onChange={(e) => setEditReqRule(e.target.value)}
            size="small"
            variant="standard"
            onKeyPress={(e) =>
              e.key === "Enter" &&
              editReqRule.length > 0 &&
              editReqRuleBuffer.rule !== editReqRule &&
              handleEditConfirm()
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setEditReqRuleDlg(false);
              setEditReqRule("");
              setEditReqRuleBuffer({});
            }}
            color="primary"
          >
            Cancel
          </Button>
          <LoadingButton
            color="warning"
            loading={editLoading}
            disabled={
              editReqRule.length < 1 || editReqRuleBuffer.rule === editReqRule
            }
            onClick={handleEditConfirm}
          >
            Update
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
