import React, { useEffect } from "react";

import HeadTitle from "./HeadTitle";
import SiteIdentity from "./SiteIdentity";
import DisclaimerSettings from "./DisclaimerSettings";
import Terms from "./Terms";
import ShippingPolicy from "./ShippingPolicy";
import ReviewPolicy from "./ReviewPolicy";
import PrivacyPolicy from "./PrivacyPolicy";
import AboutUsSettings from "./AboutUsSettings";
import HowToOrderSettings from "./HowToOrderSettings";
import SiteSEO from "./SiteSEO";
import { useSelector } from "react-redux";

export default function ManageSiteContent() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // Store
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate

  return (
    <>
      <HeadTitle
        expanded={expanded}
        handleChange={handleChange}
        translate0={translate0}
      />
      <SiteIdentity
        expanded={expanded}
        handleChange={handleChange}
        translate0={translate0}
      />
      <SiteSEO
        expanded={expanded}
        handleChange={handleChange}
        translate0={translate0}
      />
      <AboutUsSettings
        expanded={expanded}
        handleChange={handleChange}
        translate0={translate0}
      />
      <DisclaimerSettings
        expanded={expanded}
        handleChange={handleChange}
        translate0={translate0}
      />
      <Terms
        expanded={expanded}
        handleChange={handleChange}
        translate0={translate0}
      />
      <ShippingPolicy
        expanded={expanded}
        handleChange={handleChange}
        translate0={translate0}
      />
      <ReviewPolicy
        expanded={expanded}
        handleChange={handleChange}
        translate0={translate0}
      />
      <PrivacyPolicy
        expanded={expanded}
        handleChange={handleChange}
        translate0={translate0}
      />
      <HowToOrderSettings
        expanded={expanded}
        handleChange={handleChange}
        translate0={translate0}
      />
    </>
  );
}
