import {
  Autocomplete,
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { countries } from "../../../../../utils/countryList";

// Ck Editor
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import {
  createNoti,
  uploadTempPic,
} from "../../../../../reduxToolkit/features/adminPanel/misc";
import { PF } from "../../../../../config";
import ConsigneeAdditionalInfo from "./ConsigneeAdditionalInfo";

export default function ConsigneeInfo({
  consigneeInfoObj,
  setConsigneeInfoObj,
}) {
  // ! CK Editor Image Upload

  const dispatch = useDispatch();
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          let tempName = Math.floor(Math.random() * (10000 - 0) + 0).toString();
          loader.file
            .then((file) => {
              const data = new FormData();
              if (file) {
                tempName = tempName + "." + file.name.split(".").pop();
                data.append("name", tempName);
                data.append("tempImg", file);
                dispatch(uploadTempPic(data));
                dispatch(
                  createNoti({
                    message: "Pls click on the image Confirm Image Upload",
                    severity: "warning",
                  })
                );
              }
            })
            .then(() => {
              resolve({
                default: `${PF}temp/${tempName}`,
              });
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }
  // ! CK Editor Image Upload

  const handleAddress = (e, editor) => {
    let obj = { ...consigneeInfoObj };
    const data = editor.getData();
    obj.address = data;
    setConsigneeInfoObj(obj);
  };

  //! Translate
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate
  return (
    <>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          size="small"
          sx={{ pointerEvents: "none", mb: 1 }}
          fullWidth
          color="secondary"
        >
          {translate0("Consignee Info")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label={translate0("Name")}
          value={consigneeInfoObj.name}
          onChange={(e) => {
            let obj = { ...consigneeInfoObj };
            obj.name = e.target.value;
            setConsigneeInfoObj(obj);
          }}
          size="small"
          fullWidth
          variant="standard"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label={translate0("Email")}
          value={consigneeInfoObj.email}
          onChange={(e) => {
            let obj = { ...consigneeInfoObj };
            obj.email = e.target.value;
            setConsigneeInfoObj(obj);
          }}
          size="small"
          fullWidth
          variant="standard"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1" color="initial">
          {translate0("Address")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {/* <TextField
          multiline
          minRows={3}
          label={translate0("Address")}
          value={consigneeInfoObj.address}
          onChange={(e) => {
            let obj = { ...consigneeInfoObj };
            obj.address = e.target.value;
            setConsigneeInfoObj(obj);
          }}
          size="small"
          fullWidth
          variant="standard"
          helperText="Add the code </p><p> for line break"
        /> */}

        <CKEditor
          editor={Editor}
          data={consigneeInfoObj.address}
          config={{
            extraPlugins: [uploadPlugin],
          }}
          onBlur={(e, editor) => handleAddress(e, editor)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label={translate0("City")}
          value={consigneeInfoObj.city}
          onChange={(e) => {
            let obj = { ...consigneeInfoObj };
            obj.city = e.target.value;
            setConsigneeInfoObj(obj);
          }}
          size="small"
          fullWidth
          variant="standard"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label={translate0("Post Code")}
          value={consigneeInfoObj.postCode}
          onChange={(e) => {
            let obj = { ...consigneeInfoObj };
            obj.postCode = e.target.value;
            setConsigneeInfoObj(obj);
          }}
          size="small"
          fullWidth
          variant="standard"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          id="country-select-demo"
          options={countries}
          onChange={(e, newValue) => {
            let obj = { ...consigneeInfoObj };
            obj.country = newValue.label;
            obj.phoneCode = newValue.phone;
            setConsigneeInfoObj(obj);
          }}
          getOptionLabel={(option) => `${option.label}`}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                alt="country-img"
              />
              {option.label}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label={translate0("Choose a country")}
              variant="standard"
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label={translate0("Phone")}
          value={consigneeInfoObj.phone}
          onChange={(e) => {
            let obj = { ...consigneeInfoObj };
            // const value = e.target.value.replace(/\D/g, "");
            // obj.phone = value;
            obj.phone = e.target.value;
            setConsigneeInfoObj(obj);
          }}
          // InputProps={{
          //   startAdornment: (
          //     <InputAdornment position="start">
          //       +{consigneeInfoObj.phoneCode}
          //     </InputAdornment>
          //   ),
          // }}
          size="small"
          fullWidth
          variant="standard"
          // disabled={consigneeInfoObj.country === ""}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1" color="initial">
          {translate0("Additional Consignee Info")}
        </Typography>
        <ConsigneeAdditionalInfo
          consigneeInfoObj={consigneeInfoObj}
          setConsigneeInfoObj={setConsigneeInfoObj}
        />
      </Grid>
    </>
  );
}
