import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Grid,
  IconButton,
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import WhatsApp from "@mui/icons-material/WhatsApp";
import Twitter from "@mui/icons-material/Twitter";
import Instagram from "@mui/icons-material/Instagram";
import Telegram from "@mui/icons-material/Telegram";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CaptchaTikTakToe from "../misc/CaptchaTikTakToe";
import {
  emailVerify,
  saveSubscribeEmail,
} from "../../reduxToolkit/features/auth";
import EmailVerificationDialog from "../EmailVerificationDialog";
import LoadingButton from "@mui/lab/LoadingButton";

export default function ContactUs({ contentSettings }) {
  const dispatch = useDispatch();
  const [subScriptionStatus, setSubscriptionStatus] = useState(false);
  const [subscribeEmail, setSubscribeEmail] = useState("");
  const [agreeSub, setAgreeSub] = useState(true);

  const [formLoading, setFormLoading] = React.useState(false);
  const [customerFormLoading, setCustomerFormLoading] = useState(false);
  const [captchaDlg, setCaptchaDlg] = useState(false);
  const [vCode, setVCode] = useState("");
  const [verifyDlg, setVerifyDlg] = useState(false);
  const [verifyCodeInput, setVerifyCodeInput] = useState("");

  const [isCustomer, setIsCustomer] = useState(false);

  // Count down
  const [countDownStart, setCountDownStart] = React.useState("");

  const [phoneDlg, setPhoneDlg] = useState(false);

  // Store
  const { webSettings } = useSelector((state) => state.webSettings);
  const { authData, emailResponse } = useSelector((state) => state.auth);

  // LocalData
  const localCustomerData = () => {
    const c = JSON.parse(localStorage.getItem("loginInfo"));
    return c ? c : {};
  };

  const localLangData = () => {
    const c = JSON.parse(localStorage.getItem("langFront"));
    return c ? c : {};
  };

  const handlePhone = () => {
    if (
      contentSettings.footerContactInfo &&
      contentSettings.footerContactInfo.contactNumber &&
      contentSettings.footerContactInfo.contactNumber.length > 0
    ) {
      if (contentSettings.footerContactInfo.contactNumber.length > 1) {
        setPhoneDlg(true);
      } else {
        window.open(
          "tel:" + contentSettings.footerContactInfo.contactNumber[0],
          "_blank"
        );
      }
    }
  };

  const handleSubscriptionClick = () => {
    if (
      localCustomerData()._id &&
      localCustomerData().email === subscribeEmail
    ) {
      setIsCustomer(true);
      setCustomerFormLoading(true);
      handleSubscribe(true);
    } else {
      setIsCustomer(false);
      setCaptchaDlg(true);
    }
  };

  const subscribeUserFunc = () => {
    setCountDownStart(
      new Date().getTime() + (webSettings.verificationInterval || 1) * 60000
    );
    let v = Math.floor(Math.random() * (999999 - 100000 + 1) + 100000);
    setVCode(v);
    v = v * 3875412698;
    dispatch(
      emailVerify({ v, email: subscribeEmail, langId: localLangData()._id })
    );
    setFormLoading(true);
  };

  const handleSubscribe = (isCustomer) => {
    let obj = {
      email: subscribeEmail,
      isCustomer,
      langId: localLangData()._id,
    };
    dispatch(saveSubscribeEmail(obj));
    setTimeout(() => {
      setIsCustomer(false);
      setFormLoading(false);
      setCustomerFormLoading(false);
      setSubscribeEmail("");
    }, 1000);
  };

  // Use Effect
  useEffect(() => {
    if (localCustomerData()._id) {
      if (localCustomerData().subscribed) {
        setSubscriptionStatus(true);
        setSubscribeEmail("");
      } else {
        setSubscriptionStatus(false);
        setSubscribeEmail(localCustomerData().email);
      }
    }
  }, [authData]);

  // Check verification Code
  useEffect(() => {
    if (vCode === Number(verifyCodeInput)) {
      setVerifyDlg(false);
      setFormLoading(true);
      setVerifyCodeInput("");
      handleSubscribe(false);
    }
  }, [vCode, verifyCodeInput]);

  // check Email Response
  useEffect(() => {
    if (emailResponse.message) {
      if (!emailResponse.failed) setVerifyDlg(true);
      setFormLoading(false);
    }
  }, [emailResponse]);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate

  return (
    <>
      <Typography
        variant="h6"
        color="initial"
        sx={{ borderBottom: "2px solid black", mt: 1 }}
      >
        {translateFront("Contact Us")}
      </Typography>
      <List dense>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() =>
              window.open(
                contentSettings.footerContactInfo
                  ? contentSettings.footerContactInfo.addressMapLink
                  : "",
                "_blank"
              )
            }
          >
            <ListItemIcon>
              <HomeWorkIcon sx={{ color: "darkred" }} />
            </ListItemIcon>
            <ListItemText
              primary={
                contentSettings.footerContactInfo
                  ? contentSettings.footerContactInfo.address
                  : ""
              }
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={handlePhone}>
            <ListItemIcon>
              <LocalPhoneIcon sx={{ color: "darkred" }} />
            </ListItemIcon>
            <ListItemText
              primary={
                contentSettings.footerContactInfo &&
                contentSettings.footerContactInfo.contactNumber &&
                contentSettings.footerContactInfo.contactNumber.length > 0
                  ? contentSettings.footerContactInfo.contactNumber.join(", ")
                  : ""
              }
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() =>
              window.open(
                contentSettings.footerContactInfo
                  ? "mailto:" +
                      contentSettings.footerContactInfo.contactEmailLink
                  : "",
                "_blank"
              )
            }
          >
            <ListItemIcon>
              <EmailIcon sx={{ color: "darkred" }} />
            </ListItemIcon>
            <ListItemText
              primary={
                contentSettings.footerContactInfo
                  ? contentSettings.footerContactInfo.contactEmail
                  : ""
              }
            />
          </ListItemButton>
        </ListItem>
      </List>
      <Grid container spacing={0} justifyContent="center">
        {webSettings.socialMediaContacts &&
          webSettings.socialMediaContacts.whatsApp !== "" && (
            <Grid item xs={1}>
              <IconButton
                onClick={() =>
                  window.open(
                    webSettings.socialMediaContacts.whatsApp,
                    "_blank"
                  )
                }
              >
                <WhatsApp sx={{ color: "green" }} />
              </IconButton>
            </Grid>
          )}
        {webSettings.socialMediaContacts &&
          webSettings.socialMediaContacts.twitter !== "" && (
            <Grid item xs={1}>
              <IconButton
                onClick={() =>
                  window.open(webSettings.socialMediaContacts.twitter, "_blank")
                }
              >
                <Twitter color="info" />
              </IconButton>
            </Grid>
          )}
        {webSettings.socialMediaContacts &&
          webSettings.socialMediaContacts.instagram !== "" && (
            <Grid item xs={1}>
              <IconButton
                onClick={() =>
                  window.open(
                    webSettings.socialMediaContacts.instagram,
                    "_blank"
                  )
                }
              >
                <Instagram color="error" />
              </IconButton>
            </Grid>
          )}
        {webSettings.socialMediaContacts &&
          webSettings.socialMediaContacts.telegram !== "" && (
            <Grid item xs={1}>
              <IconButton
                onClick={() =>
                  window.open(
                    webSettings.socialMediaContacts.telegram,
                    "_blank"
                  )
                }
              >
                <Telegram color="info" />
              </IconButton>
            </Grid>
          )}
        {webSettings.socialMediaContacts &&
          webSettings.socialMediaContacts.email !== "" && (
            <Grid item xs={1}>
              <IconButton
                onClick={() =>
                  window.open(webSettings.socialMediaContacts.email, "_blank")
                }
              >
                <EmailIcon color="error" />
              </IconButton>
            </Grid>
          )}
        {webSettings.socialMediaContacts &&
          webSettings.socialMediaContacts.localPhone !== "" && (
            <Grid item xs={1}>
              <IconButton
                onClick={() =>
                  window.open(
                    webSettings.socialMediaContacts.localPhone,
                    "_blank"
                  )
                }
              >
                <LocalPhoneIcon color="success" />
              </IconButton>
            </Grid>
          )}

        {webSettings.additionalSocialContacts &&
          webSettings.additionalSocialContacts.length > 0 &&
          webSettings.additionalSocialContacts.map((x, i) => (
            <Grid item xs={1} key={i}>
              <IconButton onClick={() => window.open(x.pageLink, "_blank")}>
                <img
                  alt={x.pageName}
                  src={x.iconUrl}
                  style={{ height: "1.5rem" }}
                />
              </IconButton>
            </Grid>
          ))}
      </Grid>
      {!subScriptionStatus && (
        <>
          <Typography
            variant="h6"
            color="initial"
            sx={{ borderBottom: "2px solid black", mt: 1 }}
          >
            {translateFront("Get Updated...")}
          </Typography>
          <Box component="form" sx={{ mx: 1, mb: 1 }}>
            <TextField
              color="secondary"
              variant="outlined"
              margin="dense"
              label={translateFront("Enter your email...")}
              size="small"
              fullWidth
              value={subscribeEmail}
              onChange={(e) => setSubscribeEmail(e.target.value)}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={agreeSub}
                  defaultChecked
                  onChange={(e) => setAgreeSub(e.target.checked)}
                />
              }
              label={translateFront(
                "I agree to receive updates & promotional emails"
              )}
            />
            <LoadingButton
              loading={formLoading || customerFormLoading}
              fullWidth
              variant="contained"
              color="secondary"
              onClick={handleSubscriptionClick}
              disabled={!agreeSub || subscribeEmail === "" ? true : false}
            >
              {translateFront("Subscribe")}
            </LoadingButton>
          </Box>
        </>
      )}
      {phoneDlg && (
        <Dialog
          open={phoneDlg}
          onClose={() => setPhoneDlg(false)}
          aria-labelledby={"choose-phone-to-call"}
        >
          <DialogContent>
            {contentSettings.footerContactInfo &&
              contentSettings.footerContactInfo.contactNumber &&
              contentSettings.footerContactInfo.contactNumber.length > 0 &&
              contentSettings.footerContactInfo.contactNumber.map((x, i) => (
                <ListItem disablePadding key={i}>
                  <ListItemButton
                    onClick={() => window.open("tel:" + x, "_blank")}
                  >
                    <ListItemIcon>
                      <LocalPhoneIcon sx={{ color: "darkred" }} />
                    </ListItemIcon>
                    <ListItemText primary={x} />
                  </ListItemButton>
                </ListItem>
              ))}
          </DialogContent>
        </Dialog>
      )}
      {captchaDlg && (
        <Dialog
          open={captchaDlg}
          onClose={() => setCaptchaDlg(false)}
          aria-labelledby={"subscribe-user"}
        >
          <DialogContent>
            <CaptchaTikTakToe
              loginFunc={subscribeUserFunc}
              setCaptchaDlg={setCaptchaDlg}
            />
          </DialogContent>
        </Dialog>
      )}

      {/* Verification dialog */}
      {verifyDlg && (
        <EmailVerificationDialog
          verifyDlg={verifyDlg}
          setVerifyDlg={setVerifyDlg}
          verifyCodeInput={verifyCodeInput}
          setVerifyCodeInput={setVerifyCodeInput}
          countDownStart={countDownStart}
          setCountDownStart={setCountDownStart}
          logic={subscribeUserFunc}
        />
      )}
    </>
  );
}
