import React from "react";
import Grid from "@mui/material/Grid";
import { Avatar, Button, Typography } from "@mui/material";

// Icons
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import EditIcon from "@mui/icons-material/Edit";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
// import LogoutIcon from "@mui/icons-material/Logout";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { PF } from "../../../config";
import { useState } from "react";
import ViewCustomerProfile from "../../ViewCustomerProfile";
import EditCustomerProfile from "../../EditCustomerProfile";
import CustomerNotificationDlg from "../../CustomerNotificationDlg";
import OrderHistoryDialog from "../../OrderHistoryDialog";
import { openOrderHistoryDialog } from "../../../reduxToolkit/features/miscFront";
import { useDispatch, useSelector } from "react-redux";

export default function ProfileDrawer({ readNotiCount, setProfileOpen }) {
  const dispatch = useDispatch();
  // Local Store
  const localData = JSON.parse(localStorage.getItem("loginInfo"));

  // useState
  // Dialogs
  const [viewProfileDlg, setViewProfileDlg] = useState(false);
  const [editProfileDlg, setEditProfileDlg] = useState(false);
  const [customerNotiDlg, setCustomerNotiDlg] = useState(false);
  // const [orderHistoryDlg, setOrderHistoryDlg] = useState(false);

  const { webSettings } = useSelector((state) => state.webSettings);
  const { customerNotifications } = useSelector((state) => state.miscFront);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate

  return (
    <>
      {localData && (
        <Grid
          container
          spacing={1}
          flexDirection="column"
          alignItems="flex-end"
          sx={{
            background: webSettings.siteColorTheme || "lightblue",
          }}
        >
          <Grid item>
            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              sx={{ pr: 2 }}
            >
              <Grid item>
                <Avatar
                  alt={localData.name}
                  src={
                    localData.customerPic
                      ? localData.customerPic
                      : localData.gender
                      ? localData.gender === "male"
                        ? PF + "frontEnd/default/man-default-customer-image.jpg"
                        : localData.gender === "female" &&
                          PF +
                            "frontEnd/default/women-default-customer-image.jpg"
                      : "https://picsum.photos/200/300"
                  }
                  sx={{ width: 24, height: 24 }}
                />
              </Grid>
              <Grid item>
                <Typography variant="body1" color="initial">
                  {translateFront("Hello")},{" "}
                  {localData.name.split(" ")[0].toUpperCase()}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              spacing={1}
              justifyContent="flex-end"
              alignItems="center"
              sx={{ p: 0.5 }}
            >
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  fullWidth
                  startIcon={<AccountBoxIcon />}
                  sx={{ fontSize: { xs: ".6rem", sm: ".8rem" } }}
                  onClick={() => {
                    setViewProfileDlg(true);
                  }}
                >
                  {translateFront("View Profile")}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  fullWidth
                  startIcon={<NotificationsNoneIcon />}
                  sx={{ fontSize: { xs: ".6rem", sm: ".8rem" } }}
                  onClick={() => setCustomerNotiDlg(true)}
                >
                  {translateFront("Notifications")} (
                  {customerNotifications.length || 0})
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="info"
                  size="small"
                  fullWidth
                  startIcon={<EditIcon />}
                  sx={{ fontSize: { xs: ".6rem", sm: ".8rem" } }}
                  onClick={() => setEditProfileDlg(true)}
                >
                  {translateFront("Edit Profile")}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  fullWidth
                  startIcon={<ShoppingBagIcon />}
                  sx={{ fontSize: { xs: ".6rem", sm: ".8rem" } }}
                  onClick={() => dispatch(openOrderHistoryDialog(true))}
                >
                  {translateFront("Order History")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <ViewCustomerProfile
            viewProfileDlg={viewProfileDlg}
            setViewProfileDlg={setViewProfileDlg}
            editProfileDlg={editProfileDlg}
            setEditProfileDlg={setEditProfileDlg}
            localData={localData}
          />
          {editProfileDlg && (
            <EditCustomerProfile
              editProfileDlg={editProfileDlg}
              setEditProfileDlg={setEditProfileDlg}
            />
          )}
          {customerNotiDlg && (
            <CustomerNotificationDlg
              setProfileOpen={setProfileOpen}
              readNotiCount={readNotiCount}
              customerNotiDlg={customerNotiDlg}
              setCustomerNotiDlg={setCustomerNotiDlg}
              setEditProfileDlg={setEditProfileDlg}
            />
          )}
        </Grid>
      )}
    </>
  );
}
