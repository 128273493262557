import "../home/deal.css";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Rating,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { Autoplay, Navigation, Scrollbar, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReviews } from "../../reduxToolkit/features/customerOnProduct";
import { getAllCustomers } from "../../reduxToolkit/features/auth";
import { PF } from "../../config";
import { censorEmail, censorPhone } from "../../hooks/censor";

// Icons
import ContactMailIcon from "@mui/icons-material/ContactMail";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import ContactReviewer from "../misc/ContactReviewer";
import { getMedicines } from "../../reduxToolkit/features/adminPanel/medicine";
import { useNavigate } from "react-router-dom";
import { makeUrlFriendly } from "../../hooks/makeUrlFriendly";

export default function Testimonials() {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [testimonialList, setTestimonialList] = useState([]);
  const [lang, setLang] = useState("en");

  // Dialogs
  const [reviewerDlg, setReviewerDlg] = useState(false);
  const [reviewerBuffer, setReviewerBuffer] = useState(false);

  const [showTestimonial, setShowTestimonial] = useState("");

  // Local Store
  const localCustomerData = JSON.parse(localStorage.getItem("loginInfo"));

  // ? Store
  const { reviews } = useSelector((state) => state.customerOnProduct);
  const { customers } = useSelector((state) => state.auth);
  const { medicines } = useSelector((state) => state.medicine);
  const { activeLangFront } = useSelector((state) => state.miscFront);

  // Functions
  const getCustomerInfo = (customerId) => {
    if (customers && customers.length > 0) {
      const C = customers.find((c) => c._id === customerId);
      if (C) return C;
    }
    return {};
  };

  const getMedInfo = (medId) => {
    if (medicines && medicines.length > 0) {
      const M = medicines.find((m) => m.lang === lang);
      if (M && M.data) {
        const medInfo = M.data.find(
          (m) =>
            (lang !== "en" ? m.medicineDetectorId : m.fixedMedicineId) === medId
        );
        if (medInfo) return medInfo;
      }
    }
    return {};
  };

  const getGenericBrand = (id) => {
    let genericBrand = "";
    if (medicines && medicines.length > 0) {
      const M = medicines.find((m) => m.lang === "en");
      if (M) {
        const med = M.data.find((x) => x.fixedMedicineId === id);
        if (med) genericBrand = med.genericBrand;
      }
    }
    return genericBrand;
  };

  // Set Language
  useEffect(() => {
    if (activeLangFront) setLang(activeLangFront.langCode);
  }, [activeLangFront]);

  useEffect(() => {
    if (!(customers && customers.length > 0)) dispatch(getAllCustomers());
    if (!(reviews && reviews.length > 0)) dispatch(getReviews());
    // if (!(medicines && medicines.length > 0)) dispatch(getMedicines());
  }, []);

  useEffect(() => {
    if (reviews && reviews.length > 0) {
      let testimonials = reviews.filter(
        (r) => r.approval && r.rating >= 4 && !r.manualReview
      );
      let unique = [
        ...new Map(
          testimonials.map((item) => [item["medicineId"], item])
        ).values(),
      ];
      let testimonialsCustom = reviews.filter(
        (r) => r.approval && r.manualReview
      );
      setTestimonialList([...testimonialsCustom, ...unique]);
    }
  }, [reviews]);
  return (
    <>
      <Swiper
        slidesPerView={1}
        // direction="vertical"
        grabCursor
        modules={[Navigation, Scrollbar, Autoplay]}
        autoplay={{ delay: 4000 }}
        // loop={{ loop: true }}
        spaceBetween={4}
        navigation={mdUp ? true : false}
        // scrollbar={!smUp ? true : false}
        // onSlideChange={() => console.log("slide change")}
        // onSwiper={(swiper) => console.log(swiper)}
        // pagination={{ clickable: true }}
        className="deal"
      >
        {testimonialList.map((x, i) => (
          <SwiperSlide key={i}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{
                minHeight: { xs: 100, sm: 450, md: 530 },
                // display: "flex",
                // flexDirection: "column",
                // height: 530,
                // height: "100vh",
                // border: "1px solid red",
              }}
            >
              <Grid item xs={11.75} sm={10.5}>
                <Card sx={{ minHeight: { xs: 300, md: 500 } }}>
                  <Grid container spacing={0} flexDirection="column">
                    <Grid item md={12}>
                      {x.manualReview ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <LazyLoadImage
                            alt="Black"
                            effect="blur"
                            src={
                              x.medicineId
                                ? getMedInfo(x.medicineId).image &&
                                  getMedInfo(x.medicineId).image.length > 0
                                  ? PF +
                                    "adminPanel/medicines/img/" +
                                    getMedInfo(x.medicineId).image[0].src
                                  : PF +
                                    "adminPanel/medicines/no-medicine-image.jpg"
                                : x.reviewImg && x.reviewImg !== ""
                                ? PF + "adminPanel/review/" + x.reviewImg
                                : PF +
                                  "adminPanel/medicines/no-medicine-image.jpg"
                            }
                            style={{
                              // height: !smUp ? 150 : 250,
                              // width: !mdUp ? "100vw" : !xlUp ? 700 : 950,
                              objectFit: "contain",
                              height: !smUp ? 80 : 150,
                              width: !smUp ? 80 : 150,
                              // borderRadius: "50%",
                              cursor: "pointer",
                              // border: "1px solid black",
                            }}
                          />
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                          onClick={() =>
                            navigate(
                              `/our-medicines/${makeUrlFriendly(
                                getGenericBrand(
                                  lang !== "en"
                                    ? getMedInfo(x.medicineId)
                                        .medicineDetectorId
                                    : getMedInfo(x.medicineId).fixedMedicineId
                                )
                              )}`
                            )
                          }
                        >
                          <LazyLoadImage
                            alt="Black"
                            effect="blur"
                            src={
                              getMedInfo(x.medicineId).image &&
                              getMedInfo(x.medicineId).image.length > 0
                                ? PF +
                                  "adminPanel/medicines/img/" +
                                  getMedInfo(x.medicineId).image[0].src
                                : PF +
                                  "adminPanel/medicines/no-medicine-image.jpg"
                            }
                            style={{
                              // height: !smUp ? 150 : 250,
                              // width: !mdUp ? "100vw" : !xlUp ? 700 : 950,
                              objectFit: "contain",
                              height: !smUp ? 100 : 180,
                              width: !smUp ? 100 : 180,
                              // borderRadius: "50%",
                              cursor: "pointer",
                              // border: "1px solid black",
                            }}
                          />
                        </Box>
                      )}
                    </Grid>
                    <Grid item md={12}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          flexDirection: "column",
                        }}
                      >
                        <Rating value={x.rating} precision={0.5} readOnly />
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{
                            fontSize: {
                              xs: "1rem",
                              sm: x.review.length > 150 ? "1rem" : "1rem",
                              md: x.review.length > 200 ? "1rem" : "1rem",
                            },
                            mx: 4,
                            mt: {
                              xs: 4,
                              sm: 6,
                              md: 8,
                            },
                          }}
                          align="justify"
                        >
                          {/* <IconButton size="small">
                            <FormatQuoteIcon
                              sx={{ color: "black", transform: "scaleX(-1)" }}
                            />
                          </IconButton> */}

                          {!mdUp && x.review.length > 150
                            ? x.review.substring(0, 150) + "..."
                            : x.review}

                          {/* <sub
                            style={{
                              border: "1px solid red",
                              borderRadius: "50%",
                            }}
                          >
                            {!smUp && x.review.length > 150 && (
                              <MoreHorizIcon color="error" sx={{ pt: 0 }} />
                            )}
                          </sub> */}

                          {/* {!mdUp && x.review.length > 150 && (
                            <Button
                              variant="text"
                              size="small"
                              color="primary"
                              onClick={() => setShowTestimonial(x.review)}
                              onClick={() => {
                                setShowMore(!showMore);
                                let arr = [...viewMode];
                                if (!showMore) arr.push(x._id);
                                else arr = arr.filter((a) => a !== x._id);
                                setViewMode(arr);
                                setTimeout(
                                  () =>
                                    blogRef.current.scrollIntoView({
                                      behavior: "smooth",
                                    }),
                                  250
                                );
                              }}
                            >
                              {showMore ? "Less" : "More"}
                              More
                            </Button>
                          )} */}

                          {/* <IconButton size="small">
                            <FormatQuoteIcon sx={{ color: "black" }} />
                          </IconButton> */}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <CardContent>
                    {/* <Grid container spacing={1}>
                      <Grid item>
                        <Avatar
                          sx={{ width: 40, height: 40 }}
                          alt={`customer ${i}`}
                          src={
                            x.customerId
                              ? getCustomerInfo(x.customerId).gender
                                ? getCustomerInfo(x.customerId).gender ===
                                  "male"
                                  ? PF +
                                    "frontEnd/default/man-default-customer-image.jpg"
                                  : getCustomerInfo(x.customerId).gender ===
                                      "female" &&
                                    PF +
                                      "frontEnd/default/women-default-customer-image.jpg"
                                : "https://picsum.photos/200/300"
                              : "https://as1.ftcdn.net/v2/jpg/00/57/04/58/1000_F_57045887_HHJml6DJVxNBMqMeDqVJ0ZQDnotp5rGD.jpg"
                          }
                        />
                      </Grid>
                      <Grid item>
                        <Grid container spacing={0} flexDirection="column">
                          <Grid item>
                            <Typography
                              variant="subtitle2"
                              sx={{
                                pointerEvents: "none",
                              }}
                              color="black"
                            >
                              {x.customerId
                                ? `${censorEmail(
                                    getCustomerInfo(x.customerId).email
                                  )}`
                                : `${censorEmail(x.customerEmail)}`}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="subtitle2"
                              sx={{
                                pointerEvents: "none",
                              }}
                            >
                              {x.customerId
                                ? `${censorPhone(
                                    getCustomerInfo(x.customerId).phone
                                  )}`
                                : `${censorPhone(x.customerPhone)}`}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Rating value={x.rating} precision={0.5} readOnly />
                          </Grid>
                        </Grid>
                      </Grid>
                      {smUp
                        ? (!localCustomerData ||
                            x.customerId !== localCustomerData._id) && (
                            <Grid item>
                              <Tooltip
                                arrow
                                placement="top"
                                title="Request Reviewer Information"
                              >
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    setReviewerDlg(true);
                                    setReviewerBuffer(x);
                                  }}
                                >
                                  <ContactMailIcon color="warning" />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          )
                        : (!localCustomerData ||
                            x.customerId !== localCustomerData._id) && (
                            <Button
                              variant="contained"
                              fullWidth
                              size="small"
                              color="warning"
                              onClick={() => {
                                setReviewerDlg(true);
                                setReviewerBuffer(x);
                              }}
                            >
                              Request Reviewer Info
                            </Button>
                          )}
                    </Grid> */}

                    {/* {smUp && <Divider />} */}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </SwiperSlide>
        ))}
      </Swiper>
      {/* <Box
        sx={{ height: 200, background: "green", borderRadius: ".25rem" }}
      ></Box> */}
      {reviewerDlg && (
        <ContactReviewer
          reviewerDlg={reviewerDlg}
          setReviewerDlg={setReviewerDlg}
          reviewerBuffer={reviewerBuffer}
          setReviewerBuffer={setReviewerBuffer}
          customers={customers || {}}
          lang={lang || "en"}
          genericBrand=""
        />
      )}

      {showTestimonial !== "" && (
        <Dialog
          open={showTestimonial}
          onClose={() => setShowTestimonial("")}
          aria-labelledby={"view-testimonial"}
        >
          <DialogContent>
            <DialogContentText>{showTestimonial}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowTestimonial("")} color="error">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
