import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import React from "react";

export default function TemplateCard({
  x,
  handleEditClick,
  handleDeleteClick,
  editable,
  translate1,
  translate2,
}) {
  return (
    <>
      <Button
        variant="contained"
        size="small"
        fullWidth
        color="primary"
        sx={{ pointerEvents: "none" }}
      >
        {x.title}
      </Button>
      <Card>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {x.subject}
          </Typography>
          <Box
            sx={{
              height: { xs: "40vh", sm: "20vh" },
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: `${x.body}`,
              }}
            ></div>
          </Box>
        </CardContent>
        {editable && (
          <CardActions>
            <Button size="small" onClick={() => handleEditClick(x)}>
              {translate1("Edit")}
            </Button>
            <Button
              size="small"
              color="error"
              onClick={() => handleDeleteClick(x)}
            >
              {translate1("Delete")}
            </Button>
          </CardActions>
        )}
      </Card>
    </>
  );
}
