import {
  Avatar,
  Badge,
  Card,
  CardHeader,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import { PF } from "../../config";

// Icons
// import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import LaunchIcon from "@mui/icons-material/Launch";

// Media Query
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getCreated, getExactTime } from "../../hooks/getCreatedData";
import { useSelector } from "react-redux";

// Styled Avatar
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

export default function LatestCustomers({ latestCustomerList, adminUrl }) {
  // Media Query
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  // Fixed date
  const [showDate, setShowDate] = useState([]);

  const handleShowDate = (date) => {
    let arr = [...showDate];
    if (arr.includes(date)) {
      arr = arr.filter((a) => a !== date);
    } else {
      arr.push(date);
    }
    setShowDate(arr);
  };

  //! Translate
  // Store
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate

  return (
    <Grid container spacing={1}>
      {latestCustomerList.map((x, i) => (
        <Grid item key={i} xs={12} md={6}>
          <Card
            sx={{
              // maxWidth: 345,
              background:
                x.gender && x.gender === "male" ? "lightgreen" : "#f7c6eb",
            }}
          >
            <CardHeader
              avatar={
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  variant={x.loginStatus && "dot"}
                >
                  <Tooltip
                    arrow
                    title={x.loginStatus ? "online" : "offline"}
                    placement="top"
                  >
                    <Avatar
                      sx={{
                        bgcolor: red[500],
                        filter: x.loginStatus
                          ? "grayscale(0%)"
                          : "grayscale(100%)",
                      }}
                      aria-label="customers"
                      src={
                        x.customerPic
                          ? x.customerPic
                          : x.gender
                          ? x.gender === "male"
                            ? PF +
                              "frontEnd/default/man-default-customer-image.jpg"
                            : x.gender === "female" &&
                              PF +
                                "frontEnd/default/women-default-customer-image.jpg"
                          : "https://picsum.photos/200/300"
                      }
                    />
                  </Tooltip>
                </StyledBadge>
              }
              action={
                <Tooltip
                  arrow
                  placement="left"
                  title={translate0("Go To Customer")}
                >
                  <IconButton
                    aria-label="remove-customer"
                    onClick={() =>
                      window.open(
                        `/${adminUrl}/customer${"?search=" + x._id}`,
                        "_blank"
                      )
                    }
                  >
                    <LaunchIcon color="primary" />
                  </IconButton>
                </Tooltip>
              }
              title={
                <Typography
                  variant="body1"
                  color="initial"
                  sx={{ fontSize: !smUp ? ".8rem" : "1rem" }}
                >
                  {x.name.toUpperCase()}{" "}
                  <span>
                    {x.geoInfo && (
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${
                          x.geoInfo.country_code.toLowerCase() === "en"
                            ? "gb"
                            : x.geoInfo.country_code.toLowerCase()
                        }.png`}
                        alt="admins"
                      />
                    )}
                  </span>
                </Typography>
              }
              subheader={
                <Typography
                  variant="subtitle2"
                  color="initial"
                  sx={{ fontSize: ".8rem" }}
                >
                  {getCreated(x.createdAt)}
                  <Tooltip
                    arrow
                    placement="top"
                    title={`Created: ${getExactTime(x.createdAt)}`}
                  >
                    <IconButton
                      size="small"
                      onClick={() => handleShowDate(x.createdAt)}
                    >
                      <InfoIcon color="info" />
                    </IconButton>
                  </Tooltip>
                  <br />
                  {showDate.includes(x.createdAt) && getExactTime(x.createdAt)}
                </Typography>
              }
            />
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
