import React, { useState } from "react";
// ICON
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {
  Button,
  Input,
  Link,
  Typography,
  Grid,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { uploadGenericFile } from "../../../../reduxToolkit/features/adminPanel/misc";
import { PF } from "../../../../config";
import { copyToClipBoard } from "../../../../hooks/copyToClipBoard";

// Icon
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { useEffect } from "react";

// Media Query
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { getUnusedFiles } from "../../../../reduxToolkit/features/adminPanel/medicine";
import { formatByteSize } from "../../../../hooks/formatByteSize";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FileUpload({ translate1 }) {
  // Media Query
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  // Use State
  const [fileName, setFileName] = useState("");
  const [prevFiles, setPrevFiles] = useState([]);

  // Dialog
  const [chooseFromUploadedDlg, setChooseFromUploadedDlg] = useState(false);

  // ? Store
  const { unusedFiles } = useSelector((state) => state.medicine);

  //   Functions
  const dispatch = useDispatch();
  const handleFileUpload = (e) => {
    const data = new FormData();
    const ext = e.target.files[0].name.split(".");
    const filename = Date.now() + "-" + "-generic-file." + ext.slice(-1);
    data.append("name", filename);
    data.append("genericFile", e.target.files[0]);
    setFileName(filename);
    dispatch(uploadGenericFile(data));
  };

  // ? Use Effect
  useEffect(() => {
    dispatch(getUnusedFiles());
  }, []);

  useEffect(() => {
    if (unusedFiles && unusedFiles.data) {
      unusedFiles.data.length > 0 && setPrevFiles(unusedFiles.data);
    }
  }, [unusedFiles]);

  return (
    <>
      <Grid container spacing={0} flexDirection="column" alignItems="center">
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <Button
                size="small"
                variant="contained"
                fullWidth
                color="success"
                startIcon={<FileCopyIcon />}
                onClick={() => setChooseFromUploadedDlg(true)}
              >
                {translate1("Choose from uploaded")}
              </Button>
            </Grid>
            <Grid item xs={12} sm={12}>
              <label htmlFor="file-upload">
                <Input
                  id="file-upload"
                  type="file"
                  sx={{ display: "none" }}
                  onChange={handleFileUpload}
                />
                <Button
                  variant="contained"
                  size="small"
                  fullWidth
                  component="span"
                  color="error"
                  startIcon={<FileUploadIcon />}
                >
                  {translate1("File upload")}
                </Button>
              </label>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {fileName !== "" && (
            <>
              <Link href={PF + "files/" + fileName} target="_blank">
                {fileName}{" "}
              </Link>
              <Tooltip title="Copy Link" arrow placement="top">
                <IconButton
                  size="small"
                  onClick={() =>
                    copyToClipBoard(dispatch, PF + "files/" + fileName)
                  }
                >
                  <ContentCopyIcon color="info" />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Grid>
      </Grid>
      <Dialog
        open={chooseFromUploadedDlg}
        onClose={() => setChooseFromUploadedDlg(false)}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle>
          <Typography
            variant="h5"
            align="center"
            sx={{ textDecoration: "underline" }}
          >
            {" "}
            {translate1("Choose from uploaded")}{" "}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={0} flexDirection="column">
            {prevFiles.length > 0 &&
              prevFiles.map((x, i) => (
                <Grid item key={i}>
                  <Grid container spacing={0} justifyContent="center">
                    <Grid item>
                      <Link href={PF + "files/" + x.fileName} target="_blank">
                        <Typography
                          variant="body2"
                          color="info"
                          sx={{ fontSize: "1rem" }}
                        >
                          {x.fileName}{" "}
                          {smUp && "( " + formatByteSize(x.stat.size) + " )"}
                        </Typography>
                      </Link>
                    </Grid>
                    <Grid item>
                      <Tooltip title="Copy Link" arrow placement="left">
                        <IconButton
                          size="small"
                          onClick={() =>
                            copyToClipBoard(
                              dispatch,
                              PF + "files/" + x.fileName
                            )
                          }
                        >
                          <ContentCopyIcon
                            color="info"
                            style={{ height: 15, width: 15 }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
