import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  checkIfBlockCustomer,
  forgotPass,
  loginUser,
  resetForgotPassResponse,
  updateCustomerPass,
} from "../../reduxToolkit/features/auth";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@mui/material";
// Icons
import EmailIcon from "@mui/icons-material/Email";
import CaptchaTikTakToe from "../misc/CaptchaTikTakToe";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useEffect } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import { getSettings } from "../../reduxToolkit/features/adminPanel/webSettings";
import {
  compareShiftLocalToCustomer,
  getCompareList,
} from "../../reduxToolkit/features/frontend/compare";
import {
  getWishlist,
  wishlistShiftLocalToCustomer,
} from "../../reduxToolkit/features/frontend/wishlist";
import { getAskPriceListByCustomer } from "../../reduxToolkit/features/frontend/checkout";
import { getNotificationByCustomer } from "../../reduxToolkit/features/miscFront";

const theme = createTheme();

export default function SignIn({
  setLoginStatus,
  setSignInOpen,
  setSignUpOpen,
}) {
  const dispatch = useDispatch();

  // Use states
  const [formLoading, setFormLoading] = React.useState(false);
  const [formEnable, setFormEnable] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [recoveryEmail, setRecoveryEmail] = React.useState("");
  const [invalidEmail, setInvalidEmail] = React.useState(false);
  const [emailFailed, setEmailFailed] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [passFailed, setPassFailed] = React.useState(false);
  const [showPass, setShowPass] = React.useState(false);
  const [remember, setRemember] = React.useState(false);

  // Dialog
  const [captchaDlg, setCaptchaDlg] = React.useState(false);

  // Forgot Pass Section
  const [forgotDlg, setForgotDlg] = React.useState(false);
  const [forgotLoading, setForgotLoading] = React.useState(false);
  const [recoveryCodePortionActive, setRecoveryCodePortionActive] =
    React.useState(false);
  const [clientRecoveryCode, setClientRecoveryCode] = React.useState("");
  const [recoveryCode, setRecoveryCode] = React.useState("");
  // Count down
  const [countDown, setCountDown] = React.useState("");
  const [countDownStart, setCountDownStart] = React.useState("");

  const [renewPassPortion, setRenewPassPortion] = React.useState(false);
  const [renewPassLoading, setRenewPassLoading] = React.useState(false);
  const [newPass, setNewPass] = React.useState("");
  const [newConfirmPass, setNewConfirmPass] = React.useState("");
  const [showNewPass, setShowNewPass] = React.useState(false);
  const [showNewConfirmPass, setShowNewConfirmPass] = React.useState(false);
  const [newPassMatch, setNewPassMatch] = React.useState(false);

  // Store
  const { authData, forgotPassResponse } = useSelector((state) => state.auth);
  const { webSettings } = useSelector((state) => state.webSettings);

  // Use effect
  useEffect(() => {
    email === "" || password === ""
      ? setFormEnable(false)
      : setFormEnable(true);
  }, [email, password]);

  // Check SignIn Response
  useEffect(() => {
    if (authData.failed) {
      setFormLoading(false);
      if (authData.passwordFailed) {
        setPassFailed(true);
      }
      if (authData.emailFailed) {
        setEmailFailed(true);
      }
    } else {
      if (authData.customerInfo) {
        setForgotDlg(false);
        setRecoveryCodePortionActive(false);
        setRecoveryCode("");
        setFormLoading(false);
        setLoginStatus(true);
        setSignInOpen(false);
        dispatch(checkIfBlockCustomer(authData.customerInfo._id));
        dispatch(compareShiftLocalToCustomer(authData.customerInfo._id));
        dispatch(wishlistShiftLocalToCustomer(authData.customerInfo._id));
        dispatch(getAskPriceListByCustomer(authData.customerInfo._id));
        dispatch(getNotificationByCustomer(authData.customerInfo._id));
        setTimeout(() => {
          dispatch(getCompareList());
          dispatch(getWishlist());
        }, 1000);
      }
    }
  }, [authData]);

  // Check Forgot Pass Response
  useEffect(() => {
    if (forgotPassResponse.message) {
      if (forgotPassResponse.failed) {
        setForgotLoading(false);
        setForgotDlg(false);
        setEmailFailed(true);
      } else {
        setTimeout(() => {
          setForgotLoading(false);
          setRecoveryCodePortionActive(true);
          setCountDownStart(
            new Date().getTime() +
              (webSettings.verificationInterval || 1) * 60000
          );
          dispatch(resetForgotPassResponse());
        }, 2000);
      }
    }
  }, [forgotPassResponse]);

  // Check Recovery Code
  useEffect(() => {
    if (clientRecoveryCode === Number(recoveryCode)) {
      setRecoveryCodePortionActive(false);
      setRenewPassPortion(true);
    }
  }, [clientRecoveryCode, recoveryCode]);

  // Check new Password Match
  useEffect(() => {
    let errorCounter = 0;
    [...Array(newConfirmPass.length)].map((x, i) => {
      newConfirmPass[i] !== newPass[i] && errorCounter++;
      return 0;
    });
    if (newConfirmPass !== "" && errorCounter > 0) {
      setNewPassMatch(false);
    } else setNewPassMatch(true);
  }, [newConfirmPass, newPass]);

  // Functions

  const handleSubmit = (event) => {
    event.preventDefault();
    setCaptchaDlg(true);
  };
  // Email Validity Check
  const emailValidityCheck = () => {
    email.length > 0 &&
      (email.includes("@") ? setInvalidEmail(false) : setInvalidEmail(true));
  };

  const loginUserFunc = () => {
    setFormLoading(true);

    dispatch(
      loginUser({
        email,
        password,
        remember,
        expireInterval: webSettings.unRememberInterval || 720,
      })
    );
  };

  const goToSignUp = () => {
    setSignInOpen(false);
    setSignUpOpen(true);
  };

  const handleForgot = () => {
    setForgotLoading(true);
    let v = Math.floor(Math.random() * (999999 - 100000 + 1) + 100000);
    setClientRecoveryCode(v);
    v = v * 3875412698;
    dispatch(forgotPass({ email, recoveryEmail, v }));
  };

  // Countdown
  var x = setInterval(function () {
    if (countDownStart !== "") {
      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownStart - now;

      // Time calculations for minutes and seconds
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Display the result in the element with id="demo"
      setCountDown(minutes + "m : " + seconds + "s ");

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x);
        setCountDownStart("");
        setCountDown(0);
      }
    }
  }, 1000);

  // Handle Recovery Code resend
  const handleResendRecoveryCode = () => {
    setCountDownStart(
      new Date().getTime() + (webSettings.verificationInterval || 1) * 60000
    );
    let v = Math.floor(Math.random() * (999999 - 100000 + 1) + 100000);
    setClientRecoveryCode(v);
    v = v * 3875412698;
    dispatch(forgotPass({ email, recoveryEmail, v }));
  };

  // Handle Renew Password
  const handleRenewPass = () => {
    setRenewPassLoading(true);
    dispatch(updateCustomerPass({ password: newPass, email }));
    setTimeout(() => {
      setRenewPassLoading(false);
    }, 1000);
  };

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {translateFront("Sign In")}
          </Typography>
          <Box sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              label={translateFront("Email Address")}
              value={email}
              error={email.length > 0 && (invalidEmail || emailFailed)}
              onBlur={emailValidityCheck}
              onChange={(e) => {
                setEmail(e.target.value);
                setInvalidEmail(false);
                setEmailFailed(false);
              }}
              helperText={
                email.length > 0 &&
                (invalidEmail ? (
                  <Typography variant="body2" color="error">
                    {translateFront("Invalid Email")}
                  </Typography>
                ) : (
                  emailFailed && (
                    <Typography variant="body2" color="error">
                      {translateFront("Email Does Not Exist")}
                    </Typography>
                  )
                ))
              }
              autoFocus
            />
            <TextField
              required
              fullWidth
              label={translateFront("Password")}
              type={showPass ? "text" : "password"}
              autoComplete="new-password"
              value={password}
              error={password.length > 0 && passFailed}
              onChange={(e) => {
                setPassword(e.target.value);
                setPassFailed(false);
              }}
              helperText={
                password.length > 0 &&
                passFailed && (
                  <Typography variant="body2" color="error">
                    {translateFront("Wrong Password")}
                  </Typography>
                )
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      size="small"
                      onClick={() => setShowPass(!showPass)}
                    >
                      {showPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={remember}
                  color="primary"
                  onChange={(e) => setRemember(e.target.checked)}
                />
              }
              label={translateFront("Remember Me")}
            />
            <LoadingButton
              onClick={handleSubmit}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              loading={formLoading}
              disabled={!formEnable ? true : false}
            >
              {translateFront("Sign In")}
            </LoadingButton>
            <Grid container>
              <Grid item xs={6}>
                {email.length > 0 && email.includes("@") && (
                  <Typography
                    variant="body2"
                    sx={{ textDecoration: "underline", cursor: "pointer" }}
                    color="secondary"
                    onClick={() => {
                      setForgotDlg(true);
                      setRecoveryEmail(email);
                    }}
                  >
                    {translateFront("Forgot Password")}?
                  </Typography>
                )}
                <Dialog
                  open={forgotDlg}
                  onClose={() => {
                    setForgotDlg(false);
                    setRecoveryCodePortionActive(false);
                    setRecoveryCode("");
                  }}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth
                  maxWidth={"sm"}
                >
                  <DialogTitle id="alert-dialog-title">
                    {`${translateFront("Sign In")}?`}
                  </DialogTitle>
                  <DialogContent>
                    {!renewPassPortion ? (
                      <>
                        <TextField
                          variant="standard"
                          placeholder="Email"
                          required
                          type="email"
                          disabled={recoveryCodePortionActive ? true : false}
                          value={recoveryEmail}
                          helperText={
                            !recoveryCodePortionActive && (
                              <Typography variant="body2" color="primary">
                                {translateFront(
                                  "Type the email you want to receive recovery code"
                                )}
                              </Typography>
                            )
                          }
                          fullWidth
                          onChange={(e) => setRecoveryEmail(e.target.value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <EmailIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                        {recoveryCodePortionActive && (
                          <TextField
                            label={translateFront("Recovery Code")}
                            value={recoveryCode}
                            onChange={(e) => setRecoveryCode(e.target.value)}
                            size="small"
                            fullWidth
                            variant="standard"
                            helperText={
                              <Typography
                                variant="body1"
                                color="secondary"
                                align="right"
                              >
                                {countDown !== 0 ? (
                                  countDown
                                ) : (
                                  <Tooltip arrow placement="top" title="Resend">
                                    <IconButton
                                      size="small"
                                      onClick={handleResendRecoveryCode}
                                    >
                                      <RefreshIcon color="secondary" />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </Typography>
                            }
                          />
                        )}
                      </>
                    ) : (
                      <>
                        <TextField
                          label={translateFront("New Password")}
                          value={newPass}
                          onChange={(e) => setNewPass(e.target.value)}
                          size="small"
                          variant="standard"
                          type={showNewPass ? "text" : "password"}
                          fullWidth
                          autoFocus
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                <IconButton
                                  size="small"
                                  onClick={() => setShowNewPass(!showNewPass)}
                                >
                                  {showNewPass ? (
                                    <VisibilityIcon />
                                  ) : (
                                    <VisibilityOffIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          label={translateFront("Confirm Password")}
                          value={newConfirmPass}
                          onChange={(e) => setNewConfirmPass(e.target.value)}
                          size="small"
                          variant="standard"
                          type={showNewConfirmPass ? "text" : "password"}
                          fullWidth
                          error={
                            newPass !== "" && (newPassMatch ? false : true)
                          }
                          helperText={
                            newPass !== "" &&
                            !newPassMatch &&
                            translateFront("Password Not Matched")
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    setShowNewConfirmPass(!showNewConfirmPass)
                                  }
                                >
                                  {showNewConfirmPass ? (
                                    <VisibilityIcon />
                                  ) : (
                                    <VisibilityOffIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        setForgotDlg(false);
                        setRecoveryCodePortionActive(false);
                        setRecoveryCode("");
                      }}
                    >
                      {translateFront("Cancel")}
                    </Button>
                    {!recoveryCodePortionActive && !renewPassPortion && (
                      <LoadingButton
                        autoFocus
                        color="error"
                        disabled={recoveryEmail.length > 0 ? false : true}
                        onClick={handleForgot}
                        loading={forgotLoading}
                      >
                        {translateFront("Submit")}
                      </LoadingButton>
                    )}
                    {renewPassPortion && (
                      <LoadingButton
                        autoFocus
                        color="warning"
                        disabled={
                          newConfirmPass.length > 0 &&
                          newConfirmPass === newPass
                            ? false
                            : true
                        }
                        onClick={handleRenewPass}
                        loading={renewPassLoading}
                      >
                        {translateFront("Update")}
                      </LoadingButton>
                    )}
                  </DialogActions>
                </Dialog>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  sx={{ textDecoration: "underline", cursor: "pointer" }}
                  color="primary"
                  onClick={goToSignUp}
                  align="right"
                >
                  {translateFront("Sign up")}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <Dialog
        open={captchaDlg}
        onClose={() => setCaptchaDlg(false)}
        aria-labelledby={"login-user"}
      >
        <DialogContent>
          <CaptchaTikTakToe
            loginFunc={loginUserFunc}
            setCaptchaDlg={setCaptchaDlg}
          />
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
}
