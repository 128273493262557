import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../config";

//   Ask price
export const addToCart = createAsyncThunk("cart/addToCart", async (data) => {
  const res = await axiosInstance.post("/api/cart/add-to-cart", data);
  return res.data;
});

// Remove from Cart
export const removeFromCart = createAsyncThunk(
  "cart/removeFromCart",
  async (data) => {
    const res = await axiosInstance.post("/api/cart/remove-from-cart", data);
    return res.data;
  }
);

// Remove from Cart By Customer
export const removeFromCartByCustomer = createAsyncThunk(
  "cart/removeFromCartByCustomer",
  async (data) => {
    const res = await axiosInstance.post(
      "/api/cart/remove-from-cart-by-customer",
      data
    );
    return res.data;
  }
);

// Update from Cart
export const updateCart = createAsyncThunk("cart/updateCart", async (data) => {
  const res = await axiosInstance.post("/api/cart/update-cart", data);
  return res.data;
});

// Get Cart info
export const getCartInfoByCustomer = createAsyncThunk(
  "cart/getCartInfoByCustomer",
  async (customerId) => {
    const res = await axiosInstance.get(
      `/api/cart/get-cart-info/${customerId}`
    );
    return res.data;
  }
);

// get all Cart info
export const getAllCartInfo = createAsyncThunk(
  "cart/getAlCartInfo",
  async () => {
    const res = await axiosInstance.get(`/api/cart/get-all-cart-info/`);
    return res.data;
  }
);

// Initial State
const initialState = {
  pending: false,
  error: false,
  snackNotiCart: {},
  allCarts: [],
  carts: [],
};

// Slice
export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    resetSnkCart(state) {
      state.snackNotiCart = {};
    },
  },
  extraReducers: {
    [addToCart.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [addToCart.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiCart = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [addToCart.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [removeFromCart.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [removeFromCart.fulfilled]: (state, action) => {
      state.pending = false;
      state.carts = action.payload.data;
      state.snackNotiCart = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [removeFromCart.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [removeFromCartByCustomer.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [removeFromCartByCustomer.fulfilled]: (state, action) => {
      state.pending = false;
      state.carts = action.payload.data;
      state.snackNotiCart = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [removeFromCartByCustomer.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [updateCart.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [updateCart.fulfilled]: (state, action) => {
      state.pending = false;
      state.carts = action.payload.data;
      state.snackNotiCart = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [updateCart.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [getCartInfoByCustomer.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getCartInfoByCustomer.fulfilled]: (state, action) => {
      state.pending = false;
      state.carts = action.payload;
    },
    [getCartInfoByCustomer.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [getAllCartInfo.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getAllCartInfo.fulfilled]: (state, action) => {
      state.pending = false;
      state.allCarts = action.payload;
    },
    [getAllCartInfo.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
  },
});
//
export const { resetSnkCart } = cartSlice.actions;
export default cartSlice.reducer;
