import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";

// Icons
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

export default function SelectionDosage({
  dosages,
  medVariants,
  medList,
  siteLang,
  setSelectedForPreview,
}) {
  const [dosageSelection, setDosageSelection] = useState([]);
  const [selectedDosageSelection, setSelectedDosageSelection] = useState([]);
  const [viewCatDetailsBuffer, setViewCatDetailsBuffer] = useState(undefined);

  const getVariantInfo = (id) => {
    const MV = medVariants.find((mv) => mv.fixedVariantId === id);
    return MV;
  };

  useEffect(() => {
    if (dosages && dosages.length > 0) {
      const D = dosages.find((d) => d.lang === siteLang);
      let dArr = [];
      if (D && D.data) {
        D.data.forEach((x) => {
          let mArr = [];
          medList.forEach((y) => {
            y.variants.forEach((z) => {
              if (
                getVariantInfo(z.variantId)?.dosageForm ===
                (siteLang === "en" ? x.fixedDosageId : x.dosageDetectorId)
              ) {
                mArr.push({ ...y, dosageForm: x.dosageForm });
              }
            });
          });

          const unique = [
            ...new Map(mArr.map((item) => [item["_id"], item])).values(),
          ];
          dArr.push({ data: [...unique], dosageName: x.dosageName });
        });
      }
      setDosageSelection(dArr);
    }
  }, [dosages, medList, siteLang]);

  useEffect(() => {
    let arr = [];
    selectedDosageSelection.forEach((x) => {
      const Cs = dosageSelection.find((cs) => cs.dosageName === x);
      arr.push(...Cs.data);
    });
    const unique = [
      ...new Map(arr.map((item) => [item["_id"], item])).values(),
    ];
    setSelectedForPreview(unique);
  }, [dosageSelection, selectedDosageSelection]);

  return (
    <>
      <Grid container flexDirection={"column"}>
        <Grid item xs={12}>
          <Box
            sx={{
              minHeight: "20vh",
              overflow: "auto",
              border: "1px solid lightblue",
              borderRadius: ".25rem",
              my: 1,
              p: 1,
            }}
          >
            <Grid container spacing={1}>
              {dosageSelection.map((x, i) => (
                <Grid item key={i} xs={12}>
                  <Grid container sx={{ width: "100%" }}>
                    <Grid item flex={1}>
                      <Button
                        variant={
                          selectedDosageSelection.includes(x.dosageName)
                            ? "contained"
                            : "outlined"
                        }
                        size="small"
                        fullWidth
                        sx={{
                          justifyContent: "flex-start",
                        }}
                        onClick={() => setViewCatDetailsBuffer(x)}
                      >
                        {x.dosageName} ({x.data.length})
                      </Button>
                    </Grid>
                    <Grid item>
                      {selectedDosageSelection.includes(x.dosageName) ? (
                        <IconButton
                          size="small"
                          onClick={() => {
                            let arr = [...selectedDosageSelection];
                            arr = arr.filter((a) => a !== x.dosageName);
                            setSelectedDosageSelection(arr);
                          }}
                        >
                          <RemoveCircleIcon color="error" />
                        </IconButton>
                      ) : (
                        <IconButton
                          size="small"
                          onClick={() => {
                            let arr = [...selectedDosageSelection];
                            arr.push(x.dosageName);
                            setSelectedDosageSelection(arr);
                          }}
                        >
                          <AddCircleIcon color="success" />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {viewCatDetailsBuffer && (
        <Dialog
          open={viewCatDetailsBuffer !== undefined ? true : false}
          onClose={() => setViewCatDetailsBuffer(undefined)}
        >
          <DialogTitle>{viewCatDetailsBuffer.dosageName}</DialogTitle>
          <DialogContent>
            <Grid container spacing={1}>
              {viewCatDetailsBuffer?.data?.map((x, i) => (
                <Grid item key={i} xs={12}>
                  <Button
                    variant="outlined"
                    fullWidth
                    size="small"
                    sx={{ justifyContent: "flex-start", pointerEvents: "none" }}
                  >
                    {x.genericBrand}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
