import { IconButton, Tooltip } from "@mui/material";
import { format, formatDistanceToNow } from "date-fns";
import InfoIcon from "@mui/icons-material/Info";

// Get Creation Date
export const getCreated = (time) => {
  const a = formatDistanceToNow(new Date(time), {
    addSuffix: true,
  });

  return a;
};

export const getExactTime = (time) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const a = format(new Date(time), "Pp", { timeZone });
  return `(${a})`;
};

export const getExactTimePreDateFormatted = (time) => {
  const a = format(time, "P");
  return `(${a})`;
};
