import { ExpandMore } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfoTut from "../../../components/InfoTut";
import { getNotification } from "../../../reduxToolkit/features/adminPanel/notification";
import {
  getHeadTitle,
  saveHeadTitle,
  saveSiteSettings,
} from "../../../reduxToolkit/features/adminPanel/webSettings";
import AddLangDialog from "../../adminPanel/medicines/dialog/AddLangDialog";

// Icons
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import LangNTitleBar from "./LangNTitleBar";

export default function HeadTitle({ expanded, handleChange, translate0 }) {
  const dispatch = useDispatch();

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  const [headTitle, setHeadTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [lang, setLang] = useState("");
  const [langId, setLangId] = useState("");

  // Dialog
  const [addLangDlg, setAddLangDlg] = useState(false);

  // LocalData
  const localLangData = JSON.parse(localStorage.getItem("lang"));
  const localData = JSON.parse(localStorage.getItem("adminLoginInfo"));

  //   Store
  const { headTitles } = useSelector((state) => state.webSettings);
  const { langs, activeLang } = useSelector((state) => state.misc);

  // Functions
  const handleChangeLang = (e) => {
    setLang(e.target.value);
    const L = langs.find((l) => l.langCode === e.target.value);
    setLangId(L._id);
  };

  const handleSaveHeadTitle = () => {
    setLoading(true);
    dispatch(
      saveHeadTitle({
        title: headTitle,
        langId,
        creatorId: localData._id,
      })
    );
    setTimeout(() => {
      setLoading(false);
      dispatch(getNotification());
      dispatch(getHeadTitle());
    }, 1000);
  };

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setLang(activeLang.langCode);
      setLangId(activeLang._id);
    } else {
      if (localLangData) {
        setLang(localLangData.langCode);
        setLangId(localLangData._id);
      }
    }
  }, [activeLang]);

  useEffect(() => {
    dispatch(getHeadTitle());
  }, []);

  useEffect(() => {
    if (headTitles && headTitles.length > 0) {
      const H = headTitles.find((h) => h.langId === langId);
      if (H) setHeadTitle(H.title);
      else setHeadTitle("");
    }
  }, [headTitles, langId]);
  return (
    <>
      <Accordion
        expanded={expanded === "Head-Title-header"}
        onChange={handleChange("Head-Title-header")}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-label="Expand"
          aria-controls="Head-Title-content"
          id="Head-Title-header"
        >
          <Typography>{translate0("Head Title")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <LangNTitleBar
            lang={lang}
            setLang={setLang}
            langId={langId}
            setLangId={setLangId}
            title={translate0("Manage Head Title")}
          />
          <TextField
            label={translate0("Head Title")}
            value={headTitle}
            onChange={(e) => setHeadTitle(e.target.value)}
            variant="standard"
            size="small"
            fullWidth
            helperText={<InfoTut img="tut-head-title.png" />}
            sx={{ mt: smUp && 3.5 }}
          />
          <LoadingButton
            variant="contained"
            loading={loading}
            fullWidth
            size="small"
            color="success"
            onClick={handleSaveHeadTitle}
          >
            {translate0("Save")}
          </LoadingButton>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
