import { ExpandMore, WhatsApp } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  TextField,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputAdornment,
  IconButton,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";

// iCons
import Facebook from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TelegramIcon from "@mui/icons-material/Telegram";
import InstagramIcon from "@mui/icons-material/Instagram";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

import { useDispatch, useSelector } from "react-redux";
import {
  getSettings,
  saveSiteSettings,
} from "../../../reduxToolkit/features/adminPanel/webSettings";
import { getNotification } from "../../../reduxToolkit/features/adminPanel/notification";

export default function SocialMediaContact({ translate0 }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [socialPages, setSocialPages] = useState({
    whatsApp: "",
    twitter: "",
    instagram: "",
    telegram: "",
    email: "",
    localPhone: "",
  });

  const [additionalSocialPages, setAdditionalSocialPages] = useState([]);

  const [additionalSocialPage, setAdditionalSocialPage] = useState({
    iconUrl: "",
    pageName: "",
    pageLink: "",
  });

  const [addSocialDisabled, setAddSocialDisabled] = useState(true);

  // Dialogs
  const [addSocialDlg, setAddSocialDlg] = useState(false);
  const [editSocialDlg, setEditSocialDlg] = useState("");

  // LocalData
  const localData = JSON.parse(localStorage.getItem("adminLoginInfo"));

  // Store
  const { webSettings } = useSelector((state) => state.webSettings);

  const handleAddSocial = () => {
    setAdditionalSocialPage({
      iconUrl: "",
      pageName: "",
      pageLink: "",
    });
    setAddSocialDlg(true);
  };

  const confirmSocialPage = () => {
    let arr = [...additionalSocialPages];
    arr.push(additionalSocialPage);
    setAdditionalSocialPages(arr);
    setAddSocialDlg(false);
  };

  const confirmEditSocialPage = (index) => {
    let arr = [...additionalSocialPages];
    arr[index] = additionalSocialPage;
    setAdditionalSocialPages(arr);
    setAdditionalSocialPage({
      iconUrl: "",
      pageName: "",
      pageLink: "",
    });
    setEditSocialDlg("");
  };

  const handleRemoveAdditionalSocialPage = (index) => {
    let arr = [...additionalSocialPages];
    arr = arr.filter((a, i) => i !== index);
    setAdditionalSocialPages(arr);
  };

  const handleEditAdditionalSocialPage = (index) => {
    setEditSocialDlg(index);
    let arr = [...additionalSocialPages];
    setAdditionalSocialPage(arr[index]);
  };

  const handleSocialPage = () => {
    setLoading(true);
    let obj = {
      socialMediaContacts: socialPages,
      additionalSocialContacts: additionalSocialPages,
      creatorId: localData._id,
    };

    dispatch(saveSiteSettings(obj));

    setTimeout(() => {
      setLoading(false);
      setTimeout(() => {
        dispatch(getNotification());
        dispatch(getSettings());
      }, 1000);
    }, 1000);
  };

  useEffect(() => {
    let errCount = 0;
    additionalSocialPage.iconUrl === "" && errCount++;
    additionalSocialPage.pageName === "" && errCount++;
    additionalSocialPage.pageLink === "" && errCount++;
    if (errCount > 0) {
      setAddSocialDisabled(true);
    } else {
      setAddSocialDisabled(false);
    }
  }, [
    additionalSocialPage.iconUrl,
    additionalSocialPage.pageLink,
    additionalSocialPage.pageName,
  ]);

  useEffect(() => {
    if (!(webSettings && webSettings.length > 0)) dispatch(getSettings());
  }, []);

  // set Settings Data
  useEffect(() => {
    if (webSettings && webSettings.socialMediaContacts) {
      setSocialPages(webSettings.socialMediaContacts);
    } else {
      setSocialPages({
        whatsApp: "",
        twitter: "",
        instagram: "",
        telegram: "",
        email: "",
        localPhone: "",
      });
    }
    if (
      webSettings &&
      webSettings.additionalSocialContacts &&
      webSettings.additionalSocialContacts.length > 0
    ) {
      setAdditionalSocialPages(webSettings.additionalSocialContacts);
    } else {
      setAdditionalSocialPages([]);
    }
  }, [webSettings]);

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-label="Pages"
        aria-controls="pages-content"
        id="pages-header"
      >
        <Typography>{translate0("Social Media Contact Medium")}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <TextField
              label={translate0("WhatsApp")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <WhatsApp sx={{ color: "green" }} />
                  </InputAdornment>
                ),
              }}
              value={socialPages.whatsApp}
              variant="standard"
              fullWidth
              onChange={(e) => {
                let obj = { ...socialPages };
                obj.whatsApp = e.target.value;
                setSocialPages(obj);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label={translate0("Twitter")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <TwitterIcon color="info" />
                  </InputAdornment>
                ),
              }}
              value={socialPages.twitter}
              variant="standard"
              fullWidth
              onChange={(e) => {
                let obj = { ...socialPages };
                obj.twitter = e.target.value;
                setSocialPages(obj);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label={translate0("Telegram")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <TelegramIcon color="info" />
                  </InputAdornment>
                ),
              }}
              value={socialPages.telegram}
              variant="standard"
              fullWidth
              onChange={(e) => {
                let obj = { ...socialPages };
                obj.telegram = e.target.value;
                setSocialPages(obj);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label={translate0("Instagram")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <InstagramIcon color="error" />
                  </InputAdornment>
                ),
              }}
              value={socialPages.instagram}
              variant="standard"
              fullWidth
              onChange={(e) => {
                let obj = { ...socialPages };
                obj.instagram = e.target.value;
                setSocialPages(obj);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label={translate0("Email")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon color="error" />
                  </InputAdornment>
                ),
              }}
              value={socialPages.email}
              variant="standard"
              fullWidth
              onChange={(e) => {
                let obj = { ...socialPages };
                obj.email = e.target.value;
                setSocialPages(obj);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label={translate0("Local Phone")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocalPhoneIcon color="success" />
                  </InputAdornment>
                ),
              }}
              value={socialPages.localPhone}
              variant="standard"
              fullWidth
              onChange={(e) => {
                let obj = { ...socialPages };
                obj.localPhone = e.target.value;
                setSocialPages(obj);
              }}
            />
          </Grid>
          {additionalSocialPages.length > 0 &&
            additionalSocialPages.map((x, i) => (
              <Grid item xs={12} sm={6} key={i}>
                <Grid container spacing={0} alignItems="center">
                  <Grid item sx={{ flex: 1 }}>
                    <TextField
                      label={x.pageName}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={x.iconUrl}
                              alt={x.pageName}
                              style={{ height: "1.5rem" }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      value={x.pageLink}
                      variant="standard"
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item>
                    <IconButton
                      size="small"
                      onClick={() => handleEditAdditionalSocialPage(i)}
                    >
                      <EditIcon color="info" />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton
                      size="small"
                      onClick={() => handleRemoveAdditionalSocialPage(i)}
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            ))}
        </Grid>
        <Button
          fullWidth
          variant="contained"
          size="small"
          color="primary"
          sx={{ my: 1 }}
          onClick={handleAddSocial}
        >
          {translate0("Add Social Contact")}
        </Button>
        <LoadingButton
          variant="contained"
          loading={loading}
          fullWidth
          size="small"
          color="success"
          onClick={handleSocialPage}
        >
          {translate0("Save")}
        </LoadingButton>
        {/* Add */}
        {addSocialDlg && (
          <Dialog open={addSocialDlg}>
            <DialogTitle>{translate0("Add Social Contact")}</DialogTitle>
            <DialogContent>
              <TextField
                label={translate0("Icon Img Url")}
                value={additionalSocialPage.iconUrl}
                variant="standard"
                fullWidth
                onChange={(e) => {
                  let obj = { ...additionalSocialPage };
                  obj.iconUrl = e.target.value;
                  setAdditionalSocialPage(obj);
                }}
              />
              <TextField
                label={translate0("Platform Name")}
                value={additionalSocialPage.pageName}
                variant="standard"
                fullWidth
                onChange={(e) => {
                  let obj = { ...additionalSocialPage };
                  obj.pageName = e.target.value;
                  setAdditionalSocialPage(obj);
                }}
              />
              <TextField
                label={translate0("Link")}
                value={additionalSocialPage.pageLink}
                variant="standard"
                fullWidth
                onChange={(e) => {
                  let obj = { ...additionalSocialPage };
                  obj.pageLink = e.target.value;
                  setAdditionalSocialPage(obj);
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setAddSocialDlg(false);
                  setAdditionalSocialPage({
                    iconUrl: "",
                    pageName: "",
                    pageLink: "",
                  });
                }}
                color="error"
              >
                {translate0("Close")}
              </Button>
              <Button
                onClick={confirmSocialPage}
                color="primary"
                disabled={addSocialDisabled}
              >
                {translate0("Add")}
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {/* Edit */}
        {editSocialDlg !== "" && (
          <Dialog open={editSocialDlg !== ""}>
            <DialogTitle>
              {translate0("Edit")} {translate0("Social Media Contact Medium")}
            </DialogTitle>
            <DialogContent>
              <TextField
                label={translate0("Icon Img Url")}
                value={additionalSocialPage.iconUrl}
                variant="standard"
                fullWidth
                onChange={(e) => {
                  let obj = { ...additionalSocialPage };
                  obj.iconUrl = e.target.value;
                  setAdditionalSocialPage(obj);
                }}
              />
              <TextField
                label={translate0("Platform Name")}
                value={additionalSocialPage.pageName}
                variant="standard"
                fullWidth
                onChange={(e) => {
                  let obj = { ...additionalSocialPage };
                  obj.pageName = e.target.value;
                  setAdditionalSocialPage(obj);
                }}
              />
              <TextField
                label={translate0("Link")}
                value={additionalSocialPage.pageLink}
                variant="standard"
                fullWidth
                onChange={(e) => {
                  let obj = { ...additionalSocialPage };
                  obj.pageLink = e.target.value;
                  setAdditionalSocialPage(obj);
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setEditSocialDlg("");
                  setAdditionalSocialPage({
                    iconUrl: "",
                    pageName: "",
                    pageLink: "",
                  });
                }}
                color="error"
              >
                {translate0("Close")}
              </Button>
              <Button
                onClick={() => confirmEditSocialPage(editSocialDlg)}
                color="warning"
                disabled={addSocialDisabled}
              >
                {translate0("Update")}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </AccordionDetails>
    </Accordion>
  );
}
