import React, { useEffect, useState } from "react";
import BreadcrumbsTemplate from "../components/misc/BreadcrumbsTemplate";
import Grid from "@mui/material/Grid";
import "react-lazy-load-image-component/src/effects/blur.css";
// Trying Image Zoom
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Divider,
  Typography,
} from "@mui/material";
import MainInfo from "../components/singleMedicine.jsx/MainInfo";
import MedicineImage from "../components/singleMedicine.jsx/MedicineImage";
import MedicineDetails from "../components/singleMedicine.jsx/MedicineDetails";
import RelatedProducts from "../components/singleMedicine.jsx/RelatedProducts";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MedicineDetailsMobile from "../components/singleMedicine.jsx/MedicineDetailsMobile";
import { useDispatch, useSelector } from "react-redux";
import {
  getMedicines,
  getSeoTags,
  getViewCount,
  postViewCount,
} from "../reduxToolkit/features/adminPanel/medicine";
import { makeUrlFriendly } from "../hooks/makeUrlFriendly";
import LoadingBox from "../components/misc/Loading";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import { getProductNameFromURL } from "../hooks/getProductNameFromURL";
import ReviewSection from "../components/singleMedicine.jsx/ReviewSection";
import Seo from "../utils/Seo";
import { PF } from "../config";

export default function SingleMedicine() {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  // const meta = {
  //   title: "Some Meta Title",
  //   description: "I am a description, and I can create multiple tags",
  //   canonical: "http://example.com/path/to/page",
  //   meta: {
  //     charset: "utf-8",
  //     name: {
  //       keywords: "react,meta,document,html,tags",
  //     },
  //   },
  // };

  const navigate = useNavigate();

  // Use State
  const [loadingMain, setLoadingMain] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [lang, setLang] = useState("");
  const [medicineInfo, setMedicineInfo] = useState(undefined);
  const [enableAdditionalContent, setEnableAdditionalContent] = useState(false);
  const [reviewDialogue, setReviewDialogue] = useState(false);
  const [reviewChar, setReviewChar] = useState("");

  // Store
  const { medicines } = useSelector((state) => state.medicine);
  const { activeLangFront } = useSelector((state) => state.miscFront);
  const { webSettings } = useSelector((state) => state.webSettings);

  // // Functions
  // const getProductNameFromURL = () => {
  //   // Setting Menu
  //   var currentUrl = window.location.href;
  //   var pId = currentUrl.split("/");
  //   return pId[pId.length - 1] || "";
  // };

  // UseEffects
  const dispatch = useDispatch();
  useEffect(() => {
    if (!(medicines && medicines.length > 0)) dispatch(getMedicines());
    dispatch(getSeoTags());
  }, []);

  // Set Language
  useEffect(() => {
    if (activeLangFront) setLang(activeLangFront.langCode);
  }, [activeLangFront]);

  useEffect(() => {
    setLoadingMain(true);
    let medicineInfoObj = {};
    if (lang !== "" && medicines && medicines.length > 0) {
      // let M = medicines.find((m) => m.lang === lang);
      medicines.forEach((m) => {
        if (m && m.data) {
          const SingleM = m.data.find(
            (x) => makeUrlFriendly(x.genericBrand) === getProductNameFromURL()
          );
          if (SingleM && SingleM.genericBrand) {
            if (m.lang === lang) {
              if (SingleM.visible === true) medicineInfoObj = { ...SingleM };
            } else {
              let M = medicines.find((m2) => m2.lang === lang);

              if (M && M.data) {
                const Med = M.data.find(
                  (med) =>
                    (lang === "en"
                      ? med.fixedMedicineId
                      : med.medicineDetectorId) ===
                    (m.lang === "en"
                      ? SingleM.fixedMedicineId
                      : SingleM.medicineDetectorId)
                );

                if (Med) {
                  // document.title = Med.pageTitle;
                  if (Med.visible === true) medicineInfoObj = { ...Med };
                }
              }
            }
          }
        }
      });
    }
    setMedicineInfo(medicineInfoObj);
    if (medicines.length && lang) {
      setTimeout(() => {
        scroll();
        setLoadingMain(false);
      }, 2500);
    }
  }, [lang, medicines, window.location.href]);

  // useEffect(() => {
  // alert("Code ran");
  // scroll();
  // }, []);
  const scroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (medicineInfo && medicineInfo.additionalSections) {
      let counter = 0;
      medicineInfo.additionalSections.forEach((x) => {
        x.content !== "" && counter++;
      });
      if (counter < 1) {
        setEnableAdditionalContent(false);
      } else {
        setEnableAdditionalContent(true);
      }
    }
  }, [medicineInfo]);

  useEffect(() => {
    if (medicineInfo && firstLoad) {
      dispatch(
        postViewCount({
          medicineIdEn:
            lang !== "en"
              ? medicineInfo.medicineDetectorId
              : medicineInfo.fixedMedicineId,
        })
      );
      setTimeout(() => {
        setFirstLoad(false);
        dispatch(getViewCount());
      }, 1000);
    }
  }, [medicineInfo, firstLoad]);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate

  return (
    <>
      {medicineInfo === undefined ? (
        <LoadingBox w={"100%"} h={"60vh"} />
      ) : (
        <>
          <Seo
            title={medicineInfo.metaTitle || "Elifesaving Medicine"}
            description={
              medicineInfo.metaDesc
                ? medicineInfo.metaDesc.replace(
                    "medicineforworld@gmail.com",
                    "info@elifesavingmedicine.com"
                  )
                : false ||
                  "Online lifesaving medicine supplying service | Elifesaving Medicine | Email: info@elifesavingmedicine.com | WhatsApp/ Wechat/ Skype: +8801719278350."
            }
            name={webSettings.companyName || "Elifesaving Medicine"}
            type="website"
            url={window.location.href || ""}
            imageUrl={
              medicineInfo.image && medicineInfo.image.length > 0
                ? medicineInfo.image.find((i) => i.default)
                : "https://mfw.com.bd/wp-content/uploads/2021/05/getImage.png"
            }
            langCode={lang}
            keyword={medicineInfo.metaKeyword}
          />
          {/* BreadCrumb */}
          <BreadcrumbsTemplate
            b2={translateFront("Our Medicine")}
            l2="/our-medicines"
            b3={
              medicineInfo && medicineInfo.genericBrand
                ? medicineInfo.genericBrand
                : ""
            }
          />
          <Divider sx={{ mb: 2 }} />
          {loadingMain ? (
            <LoadingBox w={"100%"} h={"60vh"} />
          ) : !medicineInfo.genericBrand ? (
            <Grid
              container
              sx={{ minHeight: "60vh" }}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <Card sx={{ mx: 5 }}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <LazyLoadImage
                      effect="blur"
                      src="https://thumbs.dreamstime.com/b/grunge-textured-not-available-stamp-seal-not-available-stamp-seal-watermark-distress-style-blue-vector-rubber-print-not-138792800.jpg"
                      alt="Not available"
                      style={{
                        align: "center",
                        height: 300,
                        objectFit: "cover",
                      }}
                    />
                  </Box>
                  <CardContent>
                    <Typography
                      variant={!smUp ? "h6" : "h4"}
                      color="error"
                      align="center"
                      sx={{ textTransform: "uppercase" }}
                    >
                      {translateFront(
                        "This Medicine is not yet Available in '"
                      )}
                      {activeLangFront.langName}{" "}
                      <img
                        src={`https://flagcdn.com/w20/${
                          activeLangFront.langCode === "en"
                            ? "gb"
                            : activeLangFront.langCode
                        }.png`}
                        alt="Flag"
                        style={{
                          height: !smUp ? 16 : 28,
                          objectFit: "cover",
                        }}
                      />
                      "
                    </Typography>
                  </CardContent>
                  <CardActionArea>
                    <Button
                      variant="contained"
                      size="small"
                      color="warning"
                      fullWidth
                      onClick={() => navigate("/our-medicines")}
                    >
                      {translateFront("Go Back")}{" "}
                    </Button>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid container spacing={0} justifyContent="center">
                {/* Image portion */}
                <Grid item xs={12} lg={5}>
                  <MedicineImage
                    medicineInfo={medicineInfo}
                    lang={lang}
                    medicineImageInfo={medicineInfo.image || []}
                    medicineVidInfo={medicineInfo.video || []}
                    medicineNew={medicineInfo.new || false}
                    loadingMain={loadingMain}
                  />
                </Grid>
                {/* Item Info */}
                <Grid item xs={10} lg={5} sx={{ mt: 1 }}>
                  <MainInfo
                    medicineInfo={medicineInfo}
                    loadingMain={loadingMain}
                    lang={lang}
                    reviewDialogue={reviewDialogue}
                    setReviewDialogue={setReviewDialogue}
                    reviewChar={reviewChar}
                    setReviewChar={setReviewChar}
                  />
                </Grid>
              </Grid>
              <Divider variant="middle" sx={{ my: 1, mx: 20 }} />
              {/* details */}
              {enableAdditionalContent && (
                <>
                  {mdUp ? (
                    <MedicineDetails
                      medicineAdditionInfo={medicineInfo.additionalSections}
                    />
                  ) : (
                    <MedicineDetailsMobile
                      medicineAdditionInfo={medicineInfo.additionalSections}
                    />
                  )}
                </>
              )}
              <Divider variant="middle" sx={{ my: 1, mx: 20 }} />
              <ReviewSection
                medicineInfo={medicineInfo}
                lang={lang}
                // reviewDialogue={reviewDialogue}
                setReviewDialogue={setReviewDialogue}
                // reviewChar={reviewChar}
                setReviewChar={setReviewChar}
              />
              <Divider variant="middle" sx={{ my: 1, mx: 20 }} />
              {/* Related products */}
              <RelatedProducts
                lang={lang}
                subCatInfo={medicineInfo.subCats}
                fixedMedicineId={medicineInfo.fixedMedicineId}
                genericInfo={medicineInfo.generic}
              />
            </>
          )}
        </>
      )}
    </>
  );
}
