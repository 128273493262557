import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Rating,
  Slide,
  TextField,
  Tooltip,
} from "@mui/material";

// Icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContactMailIcon from "@mui/icons-material/ContactMail";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReviews } from "../../reduxToolkit/features/customerOnProduct";
import { useState } from "react";
import { getAllCustomers } from "../../reduxToolkit/features/auth";
import { censorEmail, censorPhone } from "../../hooks/censor";
import { PF } from "../../config";
import ContactReviewer from "../misc/ContactReviewer";

export default function ReviewSection({
  medicineInfo,
  lang,
  setReviewDialogue,
  setReviewChar,
}) {
  const dispatch = useDispatch();

  const [tempReview, setTempReview] = useState("");
  const [reviewList, SetReviewList] = useState([]);

  // Dialogs
  const [reviewerDlg, setReviewerDlg] = useState(false);
  const [reviewerBuffer, setReviewerBuffer] = useState(false);

  // Local Store
  const localCustomerData = JSON.parse(localStorage.getItem("loginInfo"));
  // Store
  const { reviews } = useSelector((state) => state.customerOnProduct);
  const { customers } = useSelector((state) => state.auth);

  // Functions
  const getCustomerInfo = (customerId) => {
    if (customers && customers.length > 0) {
      const C = customers.find((c) => c._id === customerId);
      if (C) return C;
    }
    return {};
  };

  const handleOpenReview = () => {
    setReviewChar(tempReview);
    setReviewDialogue(true);
    setTempReview("");
  };

  useEffect(() => {
    dispatch(getReviews());
    dispatch(getAllCustomers());
  }, []);

  // Set ReviewList
  useEffect(() => {
    if (reviews && reviews.length > 0) {
      let R = reviews.filter(
        (r) =>
          r.medicineId ===
          (lang !== "en"
            ? medicineInfo.medicineDetectorId
            : medicineInfo.fixedMedicineId)
      );
      R = R.filter((r) => r.approval);
      SetReviewList(R);
    }
  }, [reviews]);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={10} sx={{ mx: 1 }}>
          <Slide direction="up" in={true} mountOnEnter unmountOnExit>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    {translateFront("Review")} ({reviewList.length})
                  </AccordionSummary>
                  <AccordionDetails>
                    <List>
                      {reviewList.map((x, i) => (
                        <ListItem disablePadding sx={{ mb: 2 }} key={i}>
                          <Grid container spacing={0}>
                            <Grid item xs={12}>
                              <ListItemIcon>
                                <Grid container spacing={1}>
                                  <Grid item>
                                    <Avatar
                                      sx={{ width: 40, height: 40 }}
                                      alt={`customer ${i}`}
                                      src={
                                        x.customerId
                                          ? getCustomerInfo(x.customerId).gender
                                            ? getCustomerInfo(x.customerId)
                                                .gender === "male"
                                              ? PF +
                                                "frontEnd/default/man-default-customer-image.jpg"
                                              : getCustomerInfo(x.customerId)
                                                  .gender === "female" &&
                                                PF +
                                                  "frontEnd/default/women-default-customer-image.jpg"
                                            : "https://picsum.photos/200/300"
                                          : "https://as1.ftcdn.net/v2/jpg/00/57/04/58/1000_F_57045887_HHJml6DJVxNBMqMeDqVJ0ZQDnotp5rGD.jpg"
                                      }
                                    />
                                  </Grid>
                                  <Grid item>
                                    <Grid
                                      container
                                      spacing={0}
                                      flexDirection="column"
                                    >
                                      <Grid item>
                                        <Typography
                                          variant="subtitle2"
                                          sx={{
                                            pointerEvents: "none",
                                          }}
                                          color="black"
                                        >
                                          {x.customerId
                                            ? `${censorEmail(
                                                getCustomerInfo(x.customerId)
                                                  .email
                                              )}`
                                            : `${censorEmail(x.customerEmail)}`}
                                        </Typography>
                                      </Grid>
                                      <Grid item>
                                        <Typography
                                          variant="subtitle2"
                                          sx={{
                                            pointerEvents: "none",
                                          }}
                                        >
                                          {x.customerId
                                            ? `${censorPhone(
                                                getCustomerInfo(x.customerId)
                                                  .phone
                                              )}`
                                            : `${censorPhone(x.customerPhone)}`}
                                        </Typography>
                                      </Grid>
                                      <Grid item>
                                        <Rating
                                          value={x.rating}
                                          precision={0.5}
                                          readOnly
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  {(!localCustomerData ||
                                    x.customerId !== localCustomerData._id) && (
                                    <Grid item>
                                      <Tooltip
                                        arrow
                                        placement="top"
                                        title={translateFront(
                                          "Request Reviewer Information"
                                        )}
                                      >
                                        <IconButton
                                          size="small"
                                          onClick={() => {
                                            setReviewerDlg(true);
                                            setReviewerBuffer(x);
                                          }}
                                        >
                                          <ContactMailIcon color="warning" />
                                        </IconButton>
                                      </Tooltip>
                                    </Grid>
                                  )}
                                </Grid>
                              </ListItemIcon>
                            </Grid>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                            <Grid item xs={12}>
                              <ListItemText primary={x.review} />
                            </Grid>
                          </Grid>
                        </ListItem>
                      ))}

                      <ListItem disablePadding>
                        <Grid
                          container
                          spacing={1}
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid
                            item
                            xs={12}
                            sm={10}
                            md={11}
                            xl={11}
                            sx={{ order: { xs: 1, sm: 1 } }}
                          >
                            <TextField
                              fullWidth
                              label={translateFront("Write Review")}
                              aria-label="review-writing-box"
                              variant="standard"
                              placeholder={translateFront(
                                "Share Your Experience"
                              )}
                              value={tempReview}
                              onChange={(e) => setTempReview(e.target.value)}
                              onBlur={() => setReviewChar(tempReview)}
                              onKeyPress={(e) =>
                                e.key === "Enter" && handleOpenReview()
                              }
                              size="small"
                            />
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={2}
                            md={1}
                            xl={1}
                            sx={{ order: { xs: 3, sm: 2 } }}
                          >
                            <Button
                              fullWidth
                              variant="contained"
                              color="secondary"
                              size="small"
                              disabled={tempReview.length > 0 ? false : true}
                              onClick={handleOpenReview}
                            >
                              {translateFront("Post")}
                            </Button>
                          </Grid>
                        </Grid>
                      </ListItem>
                    </List>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Slide>
        </Grid>
      </Grid>
      {reviewerDlg && (
        <ContactReviewer
          reviewerDlg={reviewerDlg}
          setReviewerDlg={setReviewerDlg}
          reviewerBuffer={reviewerBuffer}
          setReviewerBuffer={setReviewerBuffer}
          customers={customers || {}}
          lang={lang || "en"}
          genericBrand={medicineInfo.genericBrand}
        />
      )}
    </>
  );
}
