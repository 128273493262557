import React from "react";
import ContactUsForm from "../components/forms/ContactUsForm";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// Icons
import MoreCallOptions from "../components/ContactUs/MoreCallOptions";
import BreadcrumbsTemplate from "../components/misc/BreadcrumbsTemplate";

export default function ContactUsScreen() {
  // Media Query
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Grid
      container
      spacing={2}
      justifyContent="space-around"
      alignItems="center"
    >
      <Grid item xs={12} sm={8} md={8} xl={8}>
        <BreadcrumbsTemplate b3="Contact Us" />
        <Typography
          variant="h4"
          align="center"
          sx={{
            my: 2,
            background: "navy",
            color: "white",
            borderRadius: ".5rem",
            mx: 1,
            fontSize: { xs: "1.5rem", sm: "2rem" },
          }}
        >
          Feel Free To Talk To Us
        </Typography>
        <ContactUsForm />
      </Grid>
      <Grid item xs={11.5} sm={3.5} md={2.5} xl={1.5}>
        {!smUp ? (
          <MoreCallOptions contactMedium="Message" />
        ) : !mdUp ? (
          <MoreCallOptions contactMedium="Call" />
        ) : (
          mdUp && <MoreCallOptions contactMedium="Message" />
        )}
      </Grid>
    </Grid>
  );
}
