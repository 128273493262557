import LocalHospital from "@mui/icons-material/LocalHospital";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Slide,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import HowToOrder from "../home/HowToOrder";
import { useSelector } from "react-redux";

export default function MedicineDetailsMobile({ medicineAdditionInfo }) {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  // Store
  const { webSettings } = useSelector((state) => state.webSettings);

  const [activeTab, setActiveTab] = useState(0);
  const [additionalSecArr, setAdditionalSecArr] = useState([]);
  useEffect(() => {
    if (medicineAdditionInfo && medicineAdditionInfo.length > 0) {
      let arr = [];
      medicineAdditionInfo.forEach((x, i) => {
        let obj = { ...x };
        if (i < iconArr.length) obj.icon = iconArr[i];
        else
          obj.icon =
            "https://img.icons8.com/material-sharp/24/000000/plus--v1.png";
        arr.push(obj);
      });
      setAdditionalSecArr(arr);
      setActiveTab(medicineAdditionInfo[0]);
    }
  }, [medicineAdditionInfo]);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate
  return (
    <Slide direction="up" in={activeTab} mountOnEnter unmountOnExit>
      <Box>
        <Grid container spacing={2} sx={{ px: 2 }}>
          <Grid item xs={12} sm={12}>
            <Grid
              container
              spacing={1}
              justifyContent="center"
              // alignItems="center"
              // flexDirection="column"
            >
              {additionalSecArr.length > 0 &&
                additionalSecArr.map(
                  (x, i) =>
                    x.content !== "" && (
                      <>
                        <Grid
                          item
                          key={i}
                          // sx={{ borderRight: "3px solid black" }}
                        >
                          <Box
                            onClick={() => setActiveTab(x)}
                            sx={
                              activeTab._id === x._id
                                ? {
                                    backgroundColor:
                                      webSettings.siteColorTheme || "lightblue",
                                    padding: "2px",
                                    borderRadius: ".5rem",
                                  }
                                : { cursor: "pointer" }
                            }
                          >
                            <Grid
                              container
                              spacing={0}
                              // justifyContent="center"
                              alignItems="center"
                            >
                              <Grid item>
                                <IconButton size="small">
                                  <LazyLoadImage
                                    src={x.icon}
                                    alt={translateFront(x.sectionName)}
                                    style={{
                                      width: 25,
                                      height: 25,
                                    }}
                                  />
                                </IconButton>
                              </Grid>
                              {smUp && activeTab._id === x._id && (
                                <Grid item sx={{ pr: 2 }}>
                                  <Typography variant="body2" color="initial">
                                    {translateFront(x.sectionName)}
                                  </Typography>
                                </Grid>
                              )}
                            </Grid>
                          </Box>
                        </Grid>
                        {/* {activeTab._id === x._id && (
                          <Grid item sx={{ borderRight: "3px solid black" }}>
                            <Divider
                              variant="fullWidth"
                              sx={{
                                background: "black",
                                // borderBottom: "3px solid black",
                              }}
                            />
                          </Grid>
                        )} */}
                      </>
                    )
                )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Grid container justifyContent="center">
              {!smUp && (
                <Grid item>
                  <Typography
                    variant="h6"
                    color="primary"
                    sx={{ textDecoration: "underline" }}
                  >
                    {translateFront(activeTab.sectionName)}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={11}>
                <Card>
                  <CardContent>
                    <Typography variant="body2" color="text.secondary">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `${activeTab.content}`,
                        }}
                      ></div>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Slide>
  );
}

const iconArr = [
  "https://img.icons8.com/doodle/48/000000/hospital--v1.png",
  "https://img.icons8.com/external-flaticons-lineal-color-flat-icons/64/000000/external-side-effect-cbd-oil-flaticons-lineal-color-flat-icons.png",
  "https://img.icons8.com/external-wanicon-solid-wanicon/64/000000/external-prescription-health-checkup-wanicon-solid-wanicon.png",
  "https://img.icons8.com/emoji/48/000000/information-emoji.png",
  "https://img.icons8.com/external-bearicons-outline-color-bearicons/64/000000/external-faq-frequently-asked-questions-faq-bearicons-outline-color-bearicons-1.png",
  "https://cdn-icons-png.flaticon.com/512/4228/4228956.png",
];
