import {
  Badge,
  Box,
  Button,
  CardMedia,
  Divider,
  Grid,
  Slide,
  Zoom,
} from "@mui/material";
import React, { Fragment } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
// import ReactImageZoom from "react-image-zoom";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { PF } from "../../config";
import { useState } from "react";
import { useEffect } from "react";
import ReactPlayer from "react-player";

import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch, useSelector } from "react-redux";
import { getViewCount } from "../../reduxToolkit/features/adminPanel/medicine";

export default function MedicineImage({
  medicineImageInfo,
  medicineVidInfo,
  medicineNew,
  loadingMain,
  medicineInfo,
  lang,
}) {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));

  // use State

  const [focusImg, setFocusImg] = useState("");
  // view Count
  const [viewCount, setViewCount] = useState(0);

  // Store
  const { viewCountArr } = useSelector((state) => state.medicine);
  // Store
  const { categories } = useSelector((state) => state.medicine);

  // TODO Trying Image Zoom
  // const props = {
  //   width: 600,
  //   scale: 1.8,
  //   zoomPosition: "original",
  //   zoomWidth: 300,
  //   img: "https://picsum.photos/600/450?random=100",
  //   height: 450,
  // };

  // Functions
  const handleImgClick = (x) => {
    let obj = { ...x };
    obj.mediaType = "img";
    setFocusImg(obj);
  };

  const handleVidClick = (x) => {
    let obj = { ...x };
    obj.mediaType = "vid";
    setFocusImg(obj);
  };

  // Use Effect
  const dispatch = useDispatch();
  useEffect(() => {
    if (!(viewCountArr && viewCountArr.length > 0)) dispatch(getViewCount());
  }, []);

  // Set Focus Image
  useEffect(() => {
    let obj = { src: "" };
    if (medicineVidInfo.length > 0) {
      const V = medicineVidInfo.find((v) => v.default);
      if (V) {
        obj = { ...V };
        obj.mediaType = "vid";
      }
    } else if (medicineImageInfo.length > 0) {
      const I = medicineImageInfo.find((i) => i.default);
      if (I) {
        obj = { ...I };
        obj.mediaType = "img";
      }
    } else {
      obj.mediaType = "img";
    }
    setFocusImg(obj);
  }, [medicineImageInfo, medicineVidInfo]);

  useEffect(() => {
    if (viewCountArr && viewCountArr.length > 0) {
      const C = viewCountArr.find(
        (c) =>
          c.medicineIdEn ===
          (lang !== "en"
            ? medicineInfo.medicineDetectorId
            : medicineInfo.fixedMedicineId)
      );
      if (C) {
        setViewCount(C.viewCount);
      }
    }
  }, [viewCountArr]);

  const [catList, setCatList] = useState([]);

  // set Category
  useEffect(() => {
    if (lang !== "" && categories && categories.length > 0) {
      let C = categories.find((c) => c.lang === lang);
      if (C) setCatList(C.data);
      else setCatList([]);
    }
  }, [categories, lang]);

  const getSubCatString = (subCatArr) => {
    let subCatNameArr = [];

    const arr = [...subCatArr];
    arr.forEach((a) => {
      catList.forEach((x) => {
        let Sc = x.subCatList.find(
          (sc) =>
            (lang && lang !== "en" ? sc.subCatDetectorId : sc.fixedSubCatId) ===
            a.subCatId
        );
        if (Sc) {
          subCatNameArr.push(Sc.subCatName);
        }
      });
    });

    return subCatNameArr.join(", ") || "";
  };

  return (
    <>
      <Box
        sx={{
          display: !xlUp ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          mx: 2,
          mb: 1,
          pointerEvents: "none",
        }}
      >
        <Button
          variant="contained"
          size="small"
          startIcon={<VisibilityIcon sx={{ color: "white" }} />}
          sx={{ color: "white" }}
          fullWidth
          color="info"
        >
          {viewCount}
        </Button>
      </Box>
      <Slide direction="right" in={!loadingMain} mountOnEnter unmountOnExit>
        <Grid container spacing={0} flexDirection="column" alignItems="center">
          {/* // TODO Trying Image Zoom */}
          {/* main Image */}
          <Grid item xs={12}>
            {/* <Badge badgeContent={medicineNew ? "New" : 0} color="error"> */}
            <Badge
              badgeContent={
                xlUp ? (
                  <Button
                    variant="text"
                    size="small"
                    startIcon={<VisibilityIcon sx={{ color: "black" }} />}
                    sx={{ color: "white", pointerEvents: "none" }}
                  >
                    {viewCount}
                  </Button>
                ) : (
                  0
                )
              }
              color="info"
            >
              <Box
                sx={{
                  height: mdUp && 500,
                  display: "flex",
                  alignItems: "center",
                  mb: 2,
                  maxWidth: "98vw",
                }}
              >
                {focusImg.mediaType && focusImg.mediaType === "img" && (
                  <LazyLoadImage
                    src={
                      focusImg.src !== ""
                        ? PF + "adminPanel/medicines/img/" + focusImg.src
                        : PF + "adminPanel/medicines/no-medicine-image.jpg"
                    }
                    alt={
                      focusImg.alt !== "" &&
                      focusImg.alt !== medicineInfo.genericBrand
                        ? focusImg.alt
                        : getSubCatString(medicineInfo.subCats) +
                          " medicine " +
                          medicineInfo.generic
                    }
                    effect="blur"
                    style={{
                      objectFit: "cover",
                      width: smUp ? 500 : 240,
                      maxHeight: smUp && 500,
                    }}
                  />
                )}
                {focusImg.mediaType &&
                  focusImg.mediaType === "vid" &&
                  (focusImg.src !== "" && focusImg.externalLink ? (
                    <ReactPlayer
                      url={focusImg.src}
                      controls
                      width={
                        !smUp
                          ? "90vw"
                          : smUp && !mdUp
                          ? 620
                          : mdUp && !xlUp
                          ? 500
                          : 620
                      }
                    />
                  ) : (
                    <CardMedia
                      component="video"
                      height={!smUp ? "100%" : 350}
                      image={PF + "adminPanel/medicines/vid/" + focusImg.src}
                      sx={{ px: 2 }}
                      controls
                    />
                  ))}
              </Box>
            </Badge>
          </Grid>
          {/* alt images */}
          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent="center">
              {medicineVidInfo.map((y, j) => (
                <Grid item key={j}>
                  <>
                    {j < 4 && (
                      <Box onClick={() => handleVidClick(y)}>
                        {y.externalLink ? (
                          <CardMedia
                            component="video"
                            sx={{
                              height: "50px",
                              width: !smUp ? "90px" : "80px",
                              border:
                                focusImg.src === y.src && "3px solid navy",
                              borderRadius: focusImg.src === y.src && ".25rem",
                            }}
                            image={PF + "adminPanel/medicines/vid/" + y.src}
                            muted
                            poster="https://www.nicepng.com/png/detail/7-75606_play-button-png-image-instagram.png"
                          />
                        ) : (
                          // <ReactPlayer
                          //   url={y.src}
                          //   muted
                          //   height="50px"
                          //   width={!smUp ? "90px" : "50px"}
                          //   playIcon="https://www.nicepng.com/png/detail/7-75606_play-button-png-image-instagram.png"
                          //   style={{
                          //     pointerEvents: "none",
                          //     cursor: "pointer",
                          //     border:
                          //       focusImg.src === y.src && "3px solid navy",
                          //     borderRadius: focusImg.src === y.src && ".25rem",
                          //   }}
                          // />
                          <CardMedia
                            component="video"
                            sx={{
                              height: "50px",
                              width: !smUp ? "90px" : "80px",
                              border:
                                focusImg.src === y.src && "3px solid navy",
                              borderRadius: focusImg.src === y.src && ".25rem",
                            }}
                            image={PF + "adminPanel/medicines/vid/" + y.src}
                            muted
                            poster="https://www.nicepng.com/png/detail/7-75606_play-button-png-image-instagram.png"
                          />
                        )}
                      </Box>
                    )}
                  </>
                </Grid>
              ))}
              {medicineVidInfo.length > 0 && (
                <Grid item>
                  <Divider orientation="vertical" />
                </Grid>
              )}
              {medicineImageInfo.map((x, i) => (
                <Grid item key={i}>
                  <LazyLoadImage
                    src={
                      x.src !== ""
                        ? PF + "adminPanel/medicines/img/" + x.src
                        : PF + "adminPanel/medicines/no-medicine-image.jpg"
                    }
                    alt={
                      x.alt !== "" && x.alt !== medicineInfo.genericBrand
                        ? x.alt
                        : getSubCatString(medicineInfo.subCats) +
                          " medicine " +
                          medicineInfo.generic
                    }
                    effect="blur"
                    style={{
                      objectFit: "cover",
                      height: 50,
                      cursor: "pointer",
                      border: focusImg.src === x.src && "3px solid navy",
                      borderRadius: focusImg.src === x.src && ".25rem",
                    }}
                    onClick={() => handleImgClick(x)}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Slide>
    </>
  );
}
