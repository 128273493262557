import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  DialogContentText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { paymentMedia } from "../../data/paymentMedia";
import AddCircleIcon from "@mui/icons-material/AddCircle";

// Ck Editor
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import {
  createNoti,
  uploadTempPic,
} from "../../../../../reduxToolkit/features/adminPanel/misc";
import { PF } from "../../../../../config";

export default function PaymentInfo({
  paymentInfoObj,
  setPaymentInfoObj,
  paymentInfoArr,
  setPaymentInfoArr,
  paymentInfo,
  setPaymentInfo,
}) {
  // ! CK Editor Image Upload

  const dispatch = useDispatch();
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          let tempName = Math.floor(Math.random() * (10000 - 0) + 0).toString();
          loader.file
            .then((file) => {
              const data = new FormData();
              if (file) {
                tempName = tempName + "." + file.name.split(".").pop();
                data.append("name", tempName);
                data.append("tempImg", file);
                dispatch(uploadTempPic(data));
                dispatch(
                  createNoti({
                    message: "Pls click on the image Confirm Image Upload",
                    severity: "warning",
                  })
                );
              }
            })
            .then(() => {
              resolve({
                default: `${PF}temp/${tempName}`,
              });
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }
  // ! CK Editor Image Upload

  const handlePaymentInfo = (e, editor) => {
    // let obj = { ...paymentInfo };
    const data = editor.getData();
    // obj.declaration = data;
    setPaymentInfo(data);
  };

  // const [addPaymentFieldDlg, setAddPaymentFieldDlg] = useState(false);
  // const [newField, setNewField] = useState("");
  // const [newPaymentField, setNewPaymentField] = useState("");
  // const [addPaymentMediaDlg, setAddPaymentMediaDlg] = useState(false);

  // const handleAddPaymentField = () => {
  //   let obj = { ...paymentInfoObj };
  //   let arr = [...obj.fields];
  //   arr.push({
  //     title: newField,
  //     value: "",
  //     order: arr.length,
  //   });
  //   setNewField("");
  //   obj.fields = arr;
  //   setPaymentInfoObj(obj);
  //   setAddPaymentFieldDlg(false);
  // };

  // const handleAddPaymentMedia = () => {
  //   let arr = [...paymentInfoArr];
  //   arr.push({
  //     paymentMedia: newPaymentField,
  //     name: newPaymentField.toLowerCase(),
  //     fields: [
  //       {
  //         title: "Account Number",
  //         value: "",
  //         order: 0,
  //       },
  //       {
  //         title: "Transaction Id",
  //         value: "",
  //         order: 1,
  //       },
  //     ],
  //   });
  //   setNewPaymentField("");
  //   setPaymentInfoArr(arr);
  //   setAddPaymentMediaDlg(false);
  // };

  //! Translate
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate

  return (
    <>
      {/* <Grid item xs={12}>
        <Button
          variant="outlined"
          size="small"
          sx={{ pointerEvents: "none", mb: 1 }}
          fullWidth
          color="warning"
        >
          {translate0("Payment Info")}
        </Button>
      </Grid> */}
      <Grid item xs={12}>
        <Typography variant="subtitle1" color="initial">
          {translate0("Payment Info")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CKEditor
          editor={Editor}
          data={paymentInfo}
          config={{
            extraPlugins: [uploadPlugin],
          }}
          onBlur={(e, editor) => handlePaymentInfo(e, editor)}
        />
      </Grid>
      {/* <Grid item xs={12}>
        <Grid container spacing={0}>
          <Grid item flex={1}>
            <FormControl sx={{ minWidth: 120 }} size="small" fullWidth>
              <InputLabel id="demo-select-small">
                {translate0("Payment Media")}
              </InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={paymentInfoObj}
                label={translate0("Payment Media")}
                onChange={(e) => setPaymentInfoObj(e.target.value)}
              >
                {paymentInfoArr.length &&
                  paymentInfoArr.map((media) => (
                    <MenuItem value={media} key={media.name}>
                      {media.paymentMedia}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <IconButton
              size="small"
              onClick={() => setAddPaymentMediaDlg(true)}
            >
              <AddCircleIcon color="success" sx={{ mt: 0.5 }} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid> */}
      {/* {paymentInfoObj?.fields.length &&
        paymentInfoObj?.fields.map((field, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <TextField
              label={field.title}
              value={field.value}
              onChange={(e) => {
                let obj = { ...paymentInfoObj };
                let obj1 = { ...obj.fields[index] };
                obj1.value = e.target.value;
                obj.fields[index] = obj1;
                // obj.postCode = e.target.value;
                setPaymentInfoObj(obj);
              }}
              size="small"
              fullWidth
              variant="standard"
              helperText={
                field.title.toLowerCase().includes("address")
                  ? "Add the code </p><p> for line break"
                  : ""
              }
            />
          </Grid>
        ))} */}
      {/* <Grid item xs={12} sm={6}>
        <Button
          variant="contained"
          size="small"
          fullWidth
          color="success"
          onClick={() => setAddPaymentFieldDlg(true)}
        >
          {translate0("Add Field")}
        </Button>
        {addPaymentFieldDlg && (
          <Dialog open={addPaymentFieldDlg}>
            <DialogTitle>{translate0("Add Payment Field")}</DialogTitle>
            <DialogContent>
              <TextField
                sx={{ mt: 1 }}
                label={translate0("Field Name")}
                value={newField}
                onChange={(e) => setNewField(e.target.value)}
                size="small"
                fullWidth
                variant="standard"
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setAddPaymentFieldDlg(false)}
                color="error"
              >
                {translate0("Close")}
              </Button>
              <Button
                onClick={handleAddPaymentField}
                color="success"
                disabled={newField === ""}
              >
                {translate0("Add")}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Grid> */}
      {/* {addPaymentMediaDlg && (
        <Dialog open={addPaymentMediaDlg}>
          <DialogTitle>Add Payment Media</DialogTitle>
          <DialogContent>
            <TextField
              sx={{ mt: 1 }}
              label={translate0("Payment Media Name")}
              value={newPaymentField}
              onChange={(e) => setNewPaymentField(e.target.value)}
              size="small"
              fullWidth
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setAddPaymentMediaDlg(false)} color="error">
              {translate0("Close")}
            </Button>
            <Button
              onClick={handleAddPaymentMedia}
              color="success"
              disabled={newPaymentField === ""}
            >
              {translate0("Add")}
            </Button>
          </DialogActions>
        </Dialog>
      )} */}
    </>
  );
}
