import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
// Icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ManageBlogs from "./components/ManageBlogs";
import Seo from "../../../utils/Seo";
import { PF } from "../../../config";

// Media Query
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function BlogScreen() {
  const [expanded, setExpanded] = React.useState(false);

  // Media Query
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  // Store
  const { panel } = useSelector((state) => state.notification);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // handle Panel Based on Notification
  useEffect(() => {
    setExpanded(panel);
  }, [panel]);

  //! Translate
  // Store
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate

  return (
    <Box
      sx={{
        maxWidth: !smUp ? "75vw" : !mdUp ? "85vw" : undefined,
      }}
    >
      <Typography
        variant="h4"
        color="initial"
        align="center"
        sx={{ borderBottom: ".2rem solid black", mb: 2 }}
      >
        {translate0("Blog")}
      </Typography>
      {supplierAcMenu.map((x, i) => (
        <Accordion
          key={i}
          expanded={expanded === supplierAcMenu[i].panelName}
          onChange={handleChange(supplierAcMenu[i].panelName)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={supplierAcMenu[i].panelName}
            id={supplierAcMenu[i].panelName}
          >
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <Typography>
                  {translate0(supplierAcMenu[i].headPrimary)}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={{ color: "text.secondary" }}>
                  {translate0(supplierAcMenu[i].headSecondary)}
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          {expanded === supplierAcMenu[i].panelName && (
            <AccordionDetails>{supplierAcMenu[i].component}</AccordionDetails>
          )}
        </Accordion>
      ))}
    </Box>
  );
}

const supplierAcMenu = [
  {
    panelName: "manage-blog",
    headPrimary: "Manage Blog",
    headSecondary: "Create/ Manage Blog",
    component: <ManageBlogs />,
  },
];
