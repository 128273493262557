import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../config";

// Create Request Rule
export const createReqRule = createAsyncThunk(
  "revReq/createRevReq",
  async (data) => {
    const res = await axiosInstance.post(
      "/api/reviewer-req-rule/createReviewReq",
      data
    );
    return res.data;
  }
);

// Get Request Rule
export const getReqRule = createAsyncThunk("revReq/getReqRule", async () => {
  const res = await axiosInstance.get("/api/reviewer-req-rule/get-req-rule");
  return res.data;
});

// Delete Request Rule
export const removeReqRule = createAsyncThunk(
  "revReq/removeReqRule",
  async (data) => {
    const res = await axiosInstance.post(
      "/api/reviewer-req-rule/remove-req-rule",
      data
    );
    return res.data;
  }
);

// Update Request Rule
export const updateReqRule = createAsyncThunk(
  "revReq/updateReqRule",
  async (data) => {
    const res = await axiosInstance.post(
      "/api/reviewer-req-rule/update-req-rule",
      data
    );
    return res.data;
  }
);

// Get Requests
export const getReq = createAsyncThunk("revReq/getReq", async () => {
  const res = await axiosInstance.get("/api/reviewer-req/");
  return res.data;
});

// Remove Requests
export const removeReq = createAsyncThunk("revReq/removeReq", async (data) => {
  const res = await axiosInstance.post("/api/reviewer-req/remove/", data);
  return res.data;
});

// Initial State
const initialState = {
  pending: false,
  error: false,
  reviewRequestRules: [],
  requests: [],
  snackNotiReview: {},
};

// Slice
export const reviewSlice = createSlice({
  name: "review",
  initialState,
  reducers: {
    resetSnkReview(state) {
      state.snackNotiReview = {};
    },
  },
  extraReducers: {
    [createReqRule.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [createReqRule.fulfilled]: (state, action) => {
      state.pending = false;
      if (!action.payload.failed) {
        state.snackNotiReview = {
          message: action.payload.message,
          severity: action.payload.severity,
        };
      }
    },
    [createReqRule.rejected]: (state, action) => {
      state.pending = false;
      state.error = true;
    },
    [getReqRule.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getReqRule.fulfilled]: (state, action) => {
      state.pending = false;
      state.reviewRequestRules = action.payload;
    },
    [getReqRule.rejected]: (state, action) => {
      state.pending = false;
      state.error = true;
    },
    [removeReqRule.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [removeReqRule.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiReview = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [removeReqRule.rejected]: (state, action) => {
      state.pending = false;
      state.error = true;
    },

    [getReq.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getReq.fulfilled]: (state, action) => {
      state.pending = false;
      state.requests = action.payload;
    },
    [getReq.rejected]: (state, action) => {
      state.pending = false;
      state.error = true;
    },
    [removeReq.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [removeReq.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiReview = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [removeReq.rejected]: (state, action) => {
      state.pending = false;
      state.error = true;
    },
    [updateReqRule.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [updateReqRule.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiReview = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [updateReqRule.rejected]: (state, action) => {
      state.pending = false;
      state.error = true;
    },
  },
});

export const { resetSnkReview } = reviewSlice.actions;
export default reviewSlice.reducer;
