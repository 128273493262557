import {
  Card,
  CardContent,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tooltip,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import LoadingBox from "../../../../components/misc/Loading";
import { PF } from "../../../../config";
import { getCreated, getExactTime } from "../../../../hooks/getCreatedData";

import InfoIcon from "@mui/icons-material/Info";
import { useSelector } from "react-redux";

export default function ViewAdmin({
  viewProfileDlg,
  setViewProfileDlg,
  adminId,
  adminList,
  singleAdmin,
}) {
  const [admin, setAdmin] = useState("");
  const [loading, setLoading] = useState(false);

  // Use Effect
  useEffect(() => {
    setLoading(true);
    adminList && adminId
      ? setAdmin(adminList.find((o) => o._id === adminId))
      : singleAdmin && setAdmin(singleAdmin);
    setLoading(false);
  }, [adminId, adminList, singleAdmin]);

  // Fixed date
  const [showDate, setShowDate] = useState([]);

  const handleShowDate = (date) => {
    let arr = [...showDate];
    if (arr.includes(date)) {
      arr = arr.filter((a) => a !== date);
    } else {
      arr.push(date);
    }
    setShowDate(arr);
  };

  // ! translate
  const [lang, setLang] = useState("");
  const [langId, setLangId] = useState("");
  // Local Store
  const localLangData = JSON.parse(localStorage.getItem("lang"));
  // Store
  const { langs, activeLang } = useSelector((state) => state.misc);
  const [langSettings1, setLangSettings1] = useState([]);
  const [langSettings2, setLangSettings2] = useState([]);
  const title1 = "Add Admin Dialog";
  const title2 = "Manage Admin";

  useEffect(() => {
    if (langs && langs.length > 0 && lang !== "") {
      const L = langs.find((l) => l.langCode === lang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      const A = arr.find((l) => l._title === title1);
      if (A) setLangSettings1(A.data);
      const B = arr.find((l) => l._title === title2);
      if (B) setLangSettings2(B.data);
    }
  }, [lang, langs]);

  const translate1 = (String) => translation(String, title1, 1);
  const translate2 = (String) => translation(String, title2, 2);

  const translation = (String, title, index) => {
    let translation = "";
    let settings = [];
    if (index === 1) {
      settings = langSettings1;
    } else if (index === 2) {
      settings = langSettings2;
    }
    const T = settings.find((ls) => ls.title === String);
    if (T && T.value) translation = T.value;
    else {
      let arr = [];
      if (langs && langs.length > 0 && lang !== "") {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.settings && LEn.settings.length > 0) arr = LEn.settings;
        const S = arr.find((l) => l._title === title);
        if (S) {
          const T = S.data.find((ls) => ls.title === String);
          if (T && T.value) translation = T.value;
        }
      }
    }
    return translation;
  };

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setLang(activeLang.langCode);
      setLangId(activeLang._id);
    } else {
      if (localLangData) {
        setLang(localLangData.langCode);
        setLangId(localLangData._id);
      }
    }
  }, [activeLang]);
  // ! translate
  return (
    <>
      <Dialog open={viewProfileDlg} onClose={() => setViewProfileDlg(false)}>
        <DialogTitle></DialogTitle>
        <>
          {admin && (
            <DialogContent>
              {loading ? (
                <LoadingBox />
              ) : (
                <>
                  <Card sx={{ maxWidth: 345 }}>
                    <LazyLoadImage
                      src={
                        !admin.adminPic.includes("http")
                          ? PF + "/adminPanel/admins/" + admin.adminPic
                          : "https://cdn.commercialandlegal-legalservices.com.au/wp-content/uploads/media/2018/03/Unknown-Person-Image-1-e1562653526174.png"
                      }
                      alt={admin.adminName}
                      style={{
                        height: 300,
                        width: "100%",
                        objectFit: "contain",
                      }}
                      effect="blur"
                    />

                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="subtitle1"
                        component="div"
                      >
                        {translate1("User Name")}:{" "}
                        {admin.adminName.toUpperCase()}
                      </Typography>
                      <Button variant="outlined" size="small" color="warning">
                        {admin.roleName.toUpperCase()}
                      </Button>
                      <Typography gutterBottom variant="body2" component="div">
                        {translate1("Email")}: {admin.email}
                      </Typography>
                      <Typography gutterBottom variant="body2" component="div">
                        {translate2("Changes Made")}: {admin.changesMade}
                      </Typography>
                      <Typography gutterBottom variant="body2" component="div">
                        {translate2("Created")}: {getCreated(admin.createdAt)}
                        <Tooltip
                          arrow
                          placement="top"
                          title={getExactTime(admin.createdAt)}
                        >
                          <IconButton
                            size="small"
                            onClick={() => handleShowDate(admin.createdAt)}
                          >
                            <InfoIcon color="info" />
                          </IconButton>
                        </Tooltip>
                        <br />
                        {showDate.includes(admin.createdAt) &&
                          getExactTime(admin.createdAt)}
                      </Typography>
                    </CardContent>
                  </Card>
                </>
              )}
            </DialogContent>
          )}
        </>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button onClick={() => setViewProfileDlg(false)} color="error">
            {translate1("Close")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
