import {
  Button,
  Card,
  CardContent,
  Divider,
  Typography,
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  AppBar,
  Toolbar,
  IconButton,
  Tooltip,
  Stepper,
  Step,
  StepLabel,
  Input,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Icons
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import PhotoCamera from "@mui/icons-material/PhotoCamera";

import { useState } from "react";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import {
  getDosages,
  getMedicines,
} from "../reduxToolkit/features/adminPanel/medicine";
import { PF } from "../config";
import { getCreated } from "../hooks/getCreatedData";
import LoadingBox from "./misc/Loading";
import { ExpandMore } from "@mui/icons-material";
import {
  confirmCustomerOrderComplete,
  getOrderBackEndByCustomer,
  submitPaymentReceiptImg,
  uploadDeliveryReceivedImg,
  uploadPaymentReceiptImg,
} from "../reduxToolkit/features/adminPanel/order";
import { currencyFormatter } from "../hooks/currencyFormatter";
import getSymbolFromCurrency from "currency-symbol-map";
import { getNotificationByCustomer } from "../reduxToolkit/features/miscFront";

const steps = ["Payment Confirmation", "On The Way", "Shipped"];

export default function OrderCardCustomerView({ x, lang, langId }) {
  const dispatch = useDispatch();
  // use State

  const [showShipping, setShowShipping] = useState([]);
  const [showProduct, setShowProduct] = useState([]);
  const [showReviewOrder, setShowReviewOrder] = useState([]);
  const [orderBackendObj, setOrderBackendObj] = useState({
    customerId: "",
    orderId: "",
    paymentAccountId: "",
  });
  const [loading, setLoading] = useState(false);
  const [loadingPaymentImg, setLoadingPaymentImg] = useState(false);
  const [loadingDeliveryImg, setLoadingDeliveryImg] = useState(false);

  // Currency
  const [exchangeRate, setExchangeRate] = useState(1);
  const [currencyName, setCurrencyName] = useState("USA");

  // Payment Image
  const [paymentReceiptImg, setPaymentReceiptImg] = useState("");

  const [deliveryReceivedImg, setDeliveryReceivedImg] = useState("");

  const [payRcptInstructionArr, setPayRcptInstructionArr] = useState([]);

  // Shipping document DLg
  const [shippingDocumentImgViewDlg, setShippingDocumentImgViewDlg] =
    useState(false);

  // Local Store
  const localCustomerData = () => {
    const c = JSON.parse(localStorage.getItem("loginInfo"));
    return c ? c : {};
  };

  // Store
  const { deliveryMethods } = useSelector((state) => state.delivery);
  const { paymentMethods } = useSelector((state) => state.payment);
  const { medicines, medVariants, dosages } = useSelector(
    (state) => state.medicine
  );
  const { orderBackendsByCustomer } = useSelector((state) => state.order);
  const { activeCurrencyFront } = useSelector((state) => state.miscFront);
  const { paymentSettings } = useSelector((state) => state.webSettings);

  // Functions
  const getDeliverMethod = (id) => {
    const D = deliveryMethods.find(
      (d) => (lang === "en" ? d._id : d.deliveryDetectorId) === id
    );
    if (D) return D;
    return {};
  };

  const getPaymentMethod = (id) => {
    const P = paymentMethods.find(
      (p) => (lang === "en" ? p._id : p.paymentDetectorId) === id
    );
    if (P) return P;
    return {};
  };

  const handleShowShipping = (id) => {
    let arr = [...showShipping];
    if (showShipping.includes(id)) arr = arr.filter((a) => a !== id);
    else arr.push(id);
    setShowShipping(arr);
  };

  const handleShowProduct = (id) => {
    let arr = [...showProduct];
    if (showProduct.includes(id)) arr = arr.filter((a) => a !== id);
    else arr.push(id);
    setShowProduct(arr);
  };

  const handleShowReviewOrder = (id) => {
    let arr = [...showReviewOrder];
    if (showReviewOrder.includes(id)) arr = arr.filter((a) => a !== id);
    else arr.push(id);
    setShowReviewOrder(arr);
  };

  const getDosageInfo = (dosageId, langCode) => {
    let dosageName = "";
    if (dosages && dosages.length > 0) {
      const dosageList = dosages.find((d) => d.lang === langCode);
      if (dosageList && dosageList.data) {
        const D = dosageList.data.find((d) =>
          langCode !== "en" ? d.dosageDetectorId : d.fixedDosageId === dosageId
        );
        if (D && D.dosageName) dosageName = D.dosageName;
      }
    }
    return dosageName;
  };

  const getVariantInfo = (varId) => {
    let obj = {
      dosage: "",
      strength: "",
      packTypes: "",
    };
    if (medVariants && medVariants.length > 0) {
      const V = medVariants.find(
        (v) =>
          (lang !== "en" ? v.variantDetectorId : v.fixedVariantId) === varId
      );
      if (V) {
        obj.dosage = getDosageInfo(V.dosageForm, lang);
        obj.strength = V.strength;
        obj.packTypes = V.packTypes;
      }
    }
    return obj;
  };

  const getMedInfo = (medId) => {
    let obj = {
      medImg: "",
      genericBrand: "",
    };
    if (medicines && medicines.length > 0) {
      const M = medicines.find((m) => m.lang === lang);
      if (M && M.data) {
        const Med = M.data.find(
          (med) =>
            (lang !== "en" ? med.medicineDetectorId : med.fixedMedicineId) ===
            medId
        );
        if (Med) {
          obj.genericBrand = Med.genericBrand;
          if (Med.fixedMedicineId) obj.fixedMedicineId = Med.fixedMedicineId;
          if (Med.medicineDetectorId)
            obj.medicineDetectorId = Med.medicineDetectorId;
          const medImg = Med.image.find((i) => i.default);
          if (medImg) obj.medImg = medImg;
        }
      }
    }
    return obj;
  };

  const getPaymentAccountInfo = (id) => {
    let account = {
      barcodeImg: "",
      accountTitle: "",
      accountDetails: "",
      paymentReceiptImg: "",
    };
    if (getPaymentMethod(x.paymentMethodId).accountArr) {
      const Ac = getPaymentMethod(x.paymentMethodId).accountArr.find(
        (ac) => ac._id === id
      );
      if (Ac) account = Ac;
    }
    return account;
  };

  const handlePaymentImgUpload = () => {
    setLoadingPaymentImg(true);
    let obj = {
      paymentReceiptImg: "",
      orderId: orderBackendObj.orderId,
    };
    if (paymentReceiptImg !== "") {
      const data = new FormData();
      const ext = paymentReceiptImg.name.split(".");
      const filename =
        Date.now() +
        "-" +
        x.orderNumber.replace(/\s+/g, "") +
        "-payment-receipt-img." +
        ext.slice(-1);
      data.append("name", filename);
      data.append("paymentReceiptImg", paymentReceiptImg);
      obj.paymentReceiptImg = filename;
      dispatch(uploadPaymentReceiptImg(data));
    }
    dispatch(submitPaymentReceiptImg(obj));
    setTimeout(() => {
      setLoadingPaymentImg(false);
      if (localCustomerData()._id) {
        dispatch(getNotificationByCustomer(localCustomerData()._id));
        dispatch(
          getOrderBackEndByCustomer({ customerId: localCustomerData()._id })
        );
      }
    }, 1000);
  };

  const handleCustomerDeliveryComplete = () => {
    setLoadingDeliveryImg(true);
    let obj = {
      deliveryReceivedImg: "",
      orderId: orderBackendObj.orderId,
      step: 3,
    };
    if (deliveryReceivedImg !== "") {
      const data = new FormData();
      const ext = deliveryReceivedImg.name.split(".");
      const filename =
        Date.now() +
        "-" +
        x.orderNumber.replace(/\s+/g, "") +
        "-delivery-received-img." +
        ext.slice(-1);
      data.append("name", filename);
      data.append("deliveryReceivedImg", deliveryReceivedImg);
      obj.deliveryReceivedImg = filename;
      dispatch(uploadDeliveryReceivedImg(data));
    }
    dispatch(confirmCustomerOrderComplete(obj));
    setTimeout(() => {
      setLoadingDeliveryImg(false);
      if (localCustomerData()._id) {
        dispatch(getNotificationByCustomer(localCustomerData()._id));
        dispatch(
          getOrderBackEndByCustomer({ customerId: localCustomerData()._id })
        );
      }
    }, 1000);
  };

  const getTotalPrice = () => {
    let total = 0;
    x.orderedItems.forEach((c, i) => {
      total += Number(c.price) * exchangeRate * Number(c.moq);
    });
    total = Math.ceil(total.toFixed(2));
    return total;
  };

  const getPercentTransactionFee = () => {
    let tf = 0;
    tf =
      (getTotalPrice() * getPaymentMethod(x.paymentMethodId).transactionFee) /
      100;

    return tf;
  };

  //   Use Effect
  useEffect(() => {
    // setLoading(true);
    // if (!(medicines && medicines.length > 0)) dispatch(getMedicines());
    // if (!(dosages && dosages.length > 0)) dispatch(getDosages());
    // setTimeout(() => {
    //   setLoading(false);
    // }, 5000);
  }, []);

  useEffect(() => {
    const Ob = orderBackendsByCustomer.find((ob) => ob.orderId === x._id);
    if (Ob) {
      let obj = Ob;
      setOrderBackendObj(obj);
    }
  }, [orderBackendsByCustomer]);

  // set currency
  useEffect(() => {
    setExchangeRate(activeCurrencyFront.exchangeRate);
    setCurrencyName(activeCurrencyFront.currencyName);
  }, [activeCurrencyFront]);

  // Set payment instruction
  useEffect(() => {
    if (paymentSettings && paymentSettings.length > 0) {
      const P = paymentSettings.find((p) => p.langId === langId);
      let arr = [];
      if (P && P.payRcptInstructionArr)
        P.payRcptInstructionArr.forEach((i) => arr.push({ instruction: i }));
      setPayRcptInstructionArr(arr);
    }
  }, [paymentSettings, langId]);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation || String;
  };
  //! Translate

  return (
    <>
      {loading ? (
        <LoadingBox w={"100%"} />
      ) : (
        x && (
          <Card
            sx={{
              background: orderBackendObj.step === 3 && "lightgreen",
            }}
          >
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                #{x.orderNumber}
              </Typography>
              <Typography variant="body2" color="inherit">
                {getCreated(x.updatedAt)}
              </Typography>
              {/* <Divider /> */}
              <Button
                variant={
                  showReviewOrder.includes(x._id) ? "contained" : "outlined"
                }
                size="small"
                fullWidth
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  my: 1,
                }}
                endIcon={
                  showReviewOrder.includes(x._id) ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownCircleIcon />
                  )
                }
                color="inherit"
                onClick={() => handleShowReviewOrder(x._id)}
              >
                {translateFront("Review Order")}
              </Button>
              {showReviewOrder.includes(x._id) && (
                <>
                  <Button
                    variant={
                      showProduct.includes(x._id) ? "contained" : "outlined"
                    }
                    size="small"
                    fullWidth
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mb: 1,
                    }}
                    endIcon={
                      showProduct.includes(x._id) ? (
                        <ArrowDropUpIcon />
                      ) : (
                        <ArrowDropDownCircleIcon />
                      )
                    }
                    onClick={() => handleShowProduct(x._id)}
                  >
                    {translateFront("Product Details")} (
                    {x.orderedItems ? x.orderedItems.length : 0})
                  </Button>
                  {showProduct.includes(x._id) &&
                    x.orderedItems &&
                    x.orderedItems.map((y, j) => (
                      <>
                        <Grid container spacing={0}>
                          <Grid item xs={3}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                              }}
                              onClick={() => {}}
                            >
                              <LazyLoadImage
                                src={
                                  getMedInfo(y.medId).medImg.src !== ""
                                    ? PF +
                                      "adminPanel/medicines/img/" +
                                      getMedInfo(y.medId).medImg.src
                                    : PF +
                                      "adminPanel/medicines/no-medicine-image.jpg"
                                }
                                alt={getMedInfo(y.medId).medImg.alt}
                                effect="blur"
                                placeholderSrc="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png"
                                style={{
                                  objectFit: "stretch",
                                  height: 30,
                                }}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={9}>
                            <Typography variant="body2" color="initial">
                              {getMedInfo(y.medId).genericBrand.toUpperCase()}
                              <br />
                              {getVariantInfo(y.variantId).dosage}
                              {" - "}
                              {getVariantInfo(y.variantId).strength}
                              {" - "}
                              {getVariantInfo(y.variantId).packTypes}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Divider sx={{ my: 1 }} />
                      </>
                    ))}
                  <Button
                    variant="outlined"
                    size="small"
                    fullWidth
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      pointerEvents: "none",
                    }}
                  >
                    Delivery
                  </Button>
                  <Typography variant="body2" color="text.secondary">
                    {translateFront("Preferred Delivery")}:{" "}
                    <span style={{ color: "black" }}>
                      {getDeliverMethod(x.deliveryMethodId).methodName}
                    </span>
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    component="pre"
                  >
                    {translateFront("Delivery Note")}:{" "}
                    <span style={{ color: "black" }}>{x.deliveryNote}</span>
                  </Typography>
                  {/* <Divider /> */}
                  <Button
                    variant="outlined"
                    size="small"
                    fullWidth
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      pointerEvents: "none",
                    }}
                  >
                    {translateFront("Payment")}
                  </Button>
                  <Typography variant="body2" color="text.secondary">
                    {translateFront("Preferred Payment")}:{" "}
                    <span style={{ color: "black" }}>
                      {getPaymentMethod(x.paymentMethodId).methodName}
                    </span>
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    component="pre"
                  >
                    {translateFront("Payment Note")}:{" "}
                    <span style={{ color: "black" }}>{x.paymentNote}</span>
                  </Typography>
                  {/* <Divider /> */}
                  <Button
                    variant={
                      showShipping.includes(x._id) ? "contained" : "outlined"
                    }
                    size="small"
                    fullWidth
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    endIcon={
                      showShipping.includes(x._id) ? (
                        <ArrowDropUpIcon />
                      ) : (
                        <ArrowDropDownCircleIcon />
                      )
                    }
                    onClick={() => handleShowShipping(x._id)}
                  >
                    {translateFront("Shipping Address")}
                  </Button>
                  {showShipping.includes(x._id) && (
                    <>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        component="pre"
                      >
                        {translateFront("First Name")}:{" "}
                        <span style={{ color: "black" }}>
                          {x.shippingDetails.firstName}
                        </span>
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        component="pre"
                      >
                        {translateFront("Last Name")}:{" "}
                        <span style={{ color: "black" }}>
                          {x.shippingDetails.lastName}
                        </span>
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        component="pre"
                      >
                        {translateFront("Address")}:{" "}
                        <span style={{ color: "black" }}>
                          {x.shippingDetails.address1}
                          {x.shippingDetails.address2
                            ? <br /> + x.shippingDetails.address2
                            : ""}
                        </span>
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        component="pre"
                      >
                        {translateFront("City")}:{" "}
                        <span style={{ color: "black" }}>
                          {x.shippingDetails.city}
                        </span>
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        component="pre"
                      >
                        {translateFront("State")}:{" "}
                        <span style={{ color: "black" }}>
                          {x.shippingDetails.state}
                        </span>
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        component="pre"
                      >
                        {translateFront("Postal Code")}:{" "}
                        <span style={{ color: "black" }}>
                          {x.shippingDetails.postal}
                        </span>
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        component="pre"
                      >
                        {translateFront("Country")}:{" "}
                        <span style={{ color: "black" }}>
                          {x.shippingDetails.country}
                        </span>
                      </Typography>
                    </>
                  )}
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    component="pre"
                  >
                    {translateFront("Total")}:{" "}
                    <span style={{ color: "black" }}>
                      {getSymbolFromCurrency(currencyName)}
                      {currencyFormatter(currencyName, getTotalPrice())}
                    </span>
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    component="pre"
                  >
                    {translateFront("Shipping Cost")}:{" "}
                    <span style={{ color: "black" }}>
                      {getSymbolFromCurrency(currencyName)}
                      {(Number(x.shippingCost) * exchangeRate).toFixed(2)}
                    </span>
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    component="pre"
                  >
                    {translateFront("Transaction Fee")}:{" "}
                    <span style={{ color: "black" }}>
                      {getSymbolFromCurrency(currencyName)}
                      {/* {(Number(x.shippingCost) * exchangeRate).toFixed(2)} */}
                      {Number(
                        getPaymentMethod(x.paymentMethodId).transactionUnit ===
                          0
                          ? getPercentTransactionFee()
                          : Number(
                              getPaymentMethod(x.paymentMethodId).transactionFee
                            ) * exchangeRate
                      ).toFixed(2)}
                    </span>
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    component="pre"
                  >
                    {translateFront("Total With Shipping Cost")}:{" "}
                    <span style={{ color: "black" }}>
                      {getSymbolFromCurrency(currencyName)}
                      {currencyFormatter(
                        currencyName,
                        (
                          Number(x.shippingCost) * exchangeRate +
                          getTotalPrice() +
                          Number(
                            getPaymentMethod(x.paymentMethodId)
                              .transactionUnit === 0
                              ? getTotalPrice() *
                                  Number(
                                    getPaymentMethod(x.paymentMethodId)
                                      .transactionFee / 100
                                  )
                              : Number(
                                  getPaymentMethod(x.paymentMethodId)
                                    .transactionFee
                                ) * exchangeRate
                          )
                        ).toFixed(2)
                      )}
                    </span>
                  </Typography>
                </>
              )}
              {orderBackendObj.paymentAccountId === "" ? (
                <Button
                  variant="outlined"
                  color="warning"
                  fullWidth
                  size="small"
                  sx={{ pointerEvents: "none" }}
                >
                  {translateFront("Awaiting Approval")}
                </Button>
              ) : loadingPaymentImg ? (
                <LoadingBox w={"100%"} h={"400px"} />
              ) : orderBackendObj.paymentAccountId !== "" &&
                !(
                  orderBackendObj.paymentReceiptImg &&
                  orderBackendObj.paymentReceiptImg !== ""
                ) ? (
                <>
                  <Card>
                    <CardContent>
                      <Button
                        variant="outlined"
                        color="primary"
                        fullWidth
                        size="small"
                        sx={{ pointerEvents: "none" }}
                      >
                        {translateFront("Payment Account")}
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        fullWidth
                        size="small"
                        sx={{ pointerEvents: "none", my: 1 }}
                      >
                        {getSymbolFromCurrency(currencyName)}
                        {currencyFormatter(
                          currencyName,
                          (
                            Number(x.shippingCost) * exchangeRate +
                            getTotalPrice()
                          ).toFixed(2)
                        )}
                      </Button>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMore />}
                          aria-label="Expand"
                          aria-controls="-content"
                          id="-header"
                        >
                          <Grid container spacing={0} alignItems="center">
                            <Grid item xs={12} sm={4}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {getPaymentAccountInfo(
                                  orderBackendObj.paymentAccountId
                                ).barcodeImg !== "" && (
                                  <LazyLoadImage
                                    effect="blur"
                                    src={
                                      getPaymentAccountInfo(
                                        orderBackendObj.paymentAccountId
                                      ).barcodeImg !== ""
                                        ? PF +
                                          "/adminPanel/barcode/" +
                                          getPaymentAccountInfo(
                                            orderBackendObj.paymentAccountId
                                          ).barcodeImg
                                        : PF +
                                          "/adminPanel/medicines/no-medicine-image.jpg"
                                    }
                                    alt="payment-img"
                                    style={{
                                      objectFit: "cover",
                                      width: "100px",
                                    }}
                                  />
                                )}
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                              <Typography
                                variant="body2"
                                color="initial"
                                component="pre"
                              >
                                {
                                  getPaymentAccountInfo(
                                    orderBackendObj.paymentAccountId
                                  ).accountDetails
                                }
                              </Typography>
                            </Grid>
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Divider sx={{ my: 1 }} />
                          {payRcptInstructionArr.map((y, j) => (
                            <Typography variant="body2" color="initial">
                              {j + 1}. {y.instruction}
                            </Typography>
                          ))}
                        </AccordionDetails>
                      </Accordion>
                      <Box sx={{ my: 1 }} />
                      <Grid
                        container
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item>
                          <LazyLoadImage
                            effect="blur"
                            src={
                              paymentReceiptImg !== ""
                                ? URL.createObjectURL(paymentReceiptImg)
                                : PF +
                                  "/adminPanel/medicines/no-medicine-image.jpg"
                            }
                            alt="payment-img"
                            style={{
                              objectFit: "cover",
                              width: "200px",
                            }}
                          />
                        </Grid>
                        <Grid item position="relative">
                          <Box>
                            <label htmlFor={`icon-button-file-${x._id}`}>
                              <Input
                                accept="image/*"
                                id={`icon-button-file-${x._id}`}
                                required
                                type="file"
                                sx={{ display: "none" }}
                                onChange={(e) => {
                                  setPaymentReceiptImg(e.target.files[0]);
                                }}
                              />
                              <IconButton
                                color="primary"
                                aria-label={`upload-picture-${x._id}`}
                                component="span"
                              >
                                <PhotoCamera />
                              </IconButton>
                            </label>
                          </Box>
                        </Grid>
                        <Grid item>
                          {paymentReceiptImg !== "" && (
                            <Button
                              variant="outlined"
                              size="small"
                              color="error"
                              onClick={() => {
                                setPaymentReceiptImg("");
                              }}
                            >
                              {translateFront("Remove Image")}
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        size="small"
                        sx={{ mt: 1 }}
                        onClick={handlePaymentImgUpload}
                        disabled={paymentReceiptImg === ""}
                      >
                        {translateFront("Upload Payment Receipt Image")}
                      </Button>
                    </CardContent>
                  </Card>
                </>
              ) : (
                <>
                  <Button
                    variant="outlined"
                    size="small"
                    fullWidth
                    color="inherit"
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      pointerEvents: "none",
                    }}
                  >
                    {translateFront("Order Status")}
                  </Button>
                  <Box sx={{ my: 1 }}>
                    <Stepper activeStep={orderBackendObj.step} alternativeLabel>
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Box>
                  {orderBackendObj.step === 1 && orderBackendObj.trackId && (
                    <Button
                      variant="outlined"
                      fullWidth
                      color="inherit"
                      size="small"
                      sx={{ pointerEvents: "none" }}
                    >
                      {translateFront("Tracking ID")} :{" "}
                      {orderBackendObj.trackId}
                    </Button>
                  )}

                  {orderBackendObj.shippingDocumentImg &&
                    orderBackendObj.shippingDocumentImg !== "" && (
                      <>
                        <Button
                          variant="outlined"
                          fullWidth
                          color="inherit"
                          size="small"
                          sx={{ pointerEvents: "none", my: 1 }}
                        >
                          {translateFront("Shipping Document")}
                        </Button>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() => setShippingDocumentImgViewDlg(true)}
                        >
                          <LazyLoadImage
                            effect="blur"
                            src={
                              orderBackendObj.shippingDocumentImg !== ""
                                ? PF +
                                  "/adminPanel/orders/deliveryReceived/" +
                                  orderBackendObj.shippingDocumentImg
                                : PF +
                                  "/adminPanel/medicines/no-medicine-image.jpg"
                            }
                            alt="shippingDocumentImg"
                            style={{
                              objectFit: "cover",
                              width: "150px",
                              cursor: "pointer",
                              marginLeft: 2,
                              // border: "1px solid navy",
                              borderRadius: ".25rem",
                              padding: 1,
                            }}
                          />
                        </Box>
                      </>
                    )}

                  {loadingDeliveryImg ? (
                    <LoadingBox w={"100%"} h={"400px"} />
                  ) : (
                    orderBackendObj.step === 2 &&
                    orderBackendObj.trackId && (
                      <Card>
                        <CardContent>
                          <Typography variant="body2" color="inherit">
                            {translateFront(
                              "Your delivery has been shipped. Please confirm if you have received properly"
                            )}
                          </Typography>
                          <Grid
                            container
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Grid item>
                              <LazyLoadImage
                                effect="blur"
                                src={
                                  deliveryReceivedImg !== ""
                                    ? URL.createObjectURL(deliveryReceivedImg)
                                    : PF +
                                      "/adminPanel/medicines/no-medicine-image.jpg"
                                }
                                alt="delivery-img"
                                style={{
                                  objectFit: "cover",
                                  width: "200px",
                                }}
                              />
                            </Grid>
                            <Typography
                              variant="body2"
                              color="inherit"
                              align="center"
                            >
                              {translateFront(
                                "Upload image of the Delivery Package (Optional)"
                              )}
                            </Typography>
                            <Grid item position="relative">
                              <Box>
                                <label
                                  htmlFor={`delivery-button-file-${x._id}`}
                                >
                                  <Input
                                    accept="image/*"
                                    id={`delivery-button-file-${x._id}`}
                                    required
                                    type="file"
                                    sx={{ display: "none" }}
                                    onChange={(e) => {
                                      setDeliveryReceivedImg(e.target.files[0]);
                                    }}
                                  />

                                  <IconButton
                                    color="primary"
                                    aria-label={`upload-picture-as-${x._id}`}
                                    component="span"
                                  >
                                    <PhotoCamera />
                                  </IconButton>
                                </label>
                              </Box>
                            </Grid>
                            <Grid item>
                              {deliveryReceivedImg !== "" && (
                                <Button
                                  variant="contained"
                                  size="small"
                                  color="error"
                                  onClick={() => {
                                    setDeliveryReceivedImg("");
                                  }}
                                >
                                  {translateFront("Remove Image")}
                                </Button>
                              )}
                            </Grid>
                          </Grid>
                          <Button
                            variant="contained"
                            fullWidth
                            size="small"
                            sx={{ mt: 1 }}
                            onClick={handleCustomerDeliveryComplete}
                          >
                            {translateFront("Received Image")}
                          </Button>
                        </CardContent>
                      </Card>
                    )
                  )}
                </>
              )}
            </CardContent>
          </Card>
        )
      )}
      {shippingDocumentImgViewDlg && (
        <Dialog
          open={shippingDocumentImgViewDlg}
          onClose={() => setShippingDocumentImgViewDlg(false)}
        >
          <DialogContent>
            <LazyLoadImage
              effect="blur"
              src={
                orderBackendObj.shippingDocumentImg !== ""
                  ? PF +
                    "/adminPanel/orders/deliveryReceived/" +
                    orderBackendObj.shippingDocumentImg
                  : PF + "/adminPanel/medicines/no-medicine-image.jpg"
              }
              alt="shippingDocumentImg"
              style={{
                objectFit: "cover",
                width: "260px",
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() =>
                window.open(
                  `${PF}/adminPanel/orders/deliveryReceived/${orderBackendObj.shippingDocumentImg}`,
                  "_blank"
                )
              }
              color="primary"
              disabled={orderBackendObj.shippingDocumentImg === ""}
            >
              {translateFront("Download")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
