import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Rating,
  Slide,
  TextField,
  Tooltip,
  Typography,
  Checkbox,
  FormControlLabel,
  Badge,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
} from "@mui/material";
import React, { useEffect, useState } from "react";
// ICons
import CloseIcon from "@mui/icons-material/CancelPresentationRounded";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailIcon from "@mui/icons-material/Mail";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import CompareIcon from "@mui/icons-material/Compare";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

import LoadingButton from "@mui/lab/LoadingButton";
// Media Query
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompanies,
  getDosages,
  getMedCategories,
  getSeoTags,
  getViewCount,
  postViewCount,
} from "../../reduxToolkit/features/adminPanel/medicine";
import { PF } from "../../config";

import "react-lazy-load-image-component/src/effects/blur.css";
import { copyToClipBoardFront } from "../../hooks/copyToClipBoard";
import {
  getComplainScopes,
  getReviews,
  publishRating,
} from "../../reduxToolkit/features/customerOnProduct";
import { checkForLink } from "../../hooks/checkForLink";
import CaptchaTikTakToe from "../misc/CaptchaTikTakToe";
import LoadingBox from "../misc/Loading";
import { emailVerify, promptLogin } from "../../reduxToolkit/features/auth";
import EmailVerificationDialog from "../EmailVerificationDialog";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import { makeUrlFriendly } from "../../hooks/makeUrlFriendly";
import { getNotificationByCustomer } from "../../reduxToolkit/features/miscFront";
import {
  addToCompare,
  removeFromCompare,
} from "../../reduxToolkit/features/frontend/compare";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../reduxToolkit/features/frontend/wishlist";
import {
  askPrice,
  getAskPriceListByCustomer,
} from "../../reduxToolkit/features/frontend/checkout";
import { getAssignedPriceByCustomer } from "../../reduxToolkit/features/adminPanel/assignPrice";
import { discountCalc } from "../../hooks/discountCalc";
import getSymbolFromCurrency from "currency-symbol-map";
import { currencyFormatter, getDecimal } from "../../hooks/currencyFormatter";
import { useRef } from "react";
import {
  addToCart,
  getCartInfoByCustomer,
  removeFromCart,
  updateCart,
} from "../../reduxToolkit/features/frontend/cart";
import { formatMedicinePackSize } from "../../hooks/formatMedicinePackSize";

const labels = {
  0.5: "Useless",
  1: "Useless+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

export default function MainInfo({
  medicineInfo,
  loadingMain,
  lang,
  reviewDialogue,
  setReviewDialogue,
  reviewChar,
  setReviewChar,
}) {
  const qtyRef = useRef();
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  // Use State

  const [captcha, setCaptcha] = useState(false);

  const [shareDialogue, setShareDialogue] = useState(false);

  const [complainScopeList, setComplainScopeList] = useState([]);

  const [dosageList, setDosageList] = useState([]);

  const [seoTagList, setSeoTagList] = useState([]);

  const [customerEmail, setCustomerEmail] = useState("");
  const [emailVerified, setEmailVerified] = useState(false);
  const [verifyEmailLoading, setVerifyEmailLoading] = useState(false);
  const [noCustomerEmail, setNoCustomerEmail] = useState(false);
  const [invalidCustomerEmail, setInvalidCustomerEmail] = useState(false);

  const [vCode, setVCode] = useState("");
  const [verifyDlg, setVerifyDlg] = useState(false);
  const [verifyCodeInput, setVerifyCodeInput] = useState("");

  const [phone, setPhone] = useState("");

  const [reviewDisable, setReviewDisable] = useState(false);
  const [reviewLoading, setReviewLoading] = useState(false);
  // const [reviewDialogue, setReviewDialogue] = useState(false);
  // const [reviewChar, setReviewChar] = useState("");
  const [tempReviewChar, setTempReviewChar] = useState("");

  const [rating, setRating] = useState(5);
  const [ratingHover, setRatingHover] = useState(5);

  const [shortComings, setShortComings] = useState([]);
  const [suggest, setSuggest] = useState("");

  const [catList, setCatList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [activeVariant, setActiveVariant] = useState({});

  const [wishListExist, setWishListExist] = useState(false);
  const [compareListExist, setCompareListExist] = useState(false);

  const [exchangeRate, setExchangeRate] = useState(1);
  const [currencyName, setCurrencyName] = useState("USA");

  const [askPriceLoading, setAskPriceLoading] = useState(false);
  const [askPriceDisable, setAskPriceDisable] = useState(false);
  const [askPriceDlg, setAskPriceDlg] = useState(false);
  // const [enableCart, setEnableCart] = useState(false);

  // Cart
  const [cartLoading, setCartLoading] = useState(false);
  const [qtyLoading, setQtyLoading] = useState(false);
  const [qty, setQty] = useState(0);

  const [enableUpdate, setEnableUpdate] = useState(false);

  // Count down
  const [countDownStart, setCountDownStart] = React.useState("");

  // LocalData
  const localCustomerData = () => {
    const c = JSON.parse(localStorage.getItem("loginInfo"));
    return c ? c : {};
  };

  const localCurrencyDataFront = JSON.parse(
    localStorage.getItem("currencyFront")
  );
  // Local Store
  const localLangData = JSON.parse(localStorage.getItem("langFront"));

  // Store
  const { medVariants, categories, companies, dosages } = useSelector(
    (state) => state.medicine
  );

  const { compareList } = useSelector((state) => state.compare);
  const { wishlist } = useSelector((state) => state.wishlist);
  const { askPriceListByCustomer, askPriceResponse } = useSelector(
    (state) => state.checkout
  );
  const { assignedPriceList } = useSelector((state) => state.assignPrice);

  const { emailResponse } = useSelector((state) => state.auth);
  const { webSettings } = useSelector((state) => state.webSettings);
  const { complainScopes } = useSelector((state) => state.customerOnProduct);
  const { langs } = useSelector((state) => state.misc);
  const { activeCurrencyFront } = useSelector((state) => state.miscFront);
  const { seoTags } = useSelector((state) => state.medicine);
  const { carts } = useSelector((state) => state.cart);

  // Functions
  const getCat = (subCatId) => {
    let cat = "";
    let subCat = "";
    catList.map((x) => {
      let Sc = x.subCatList.find(
        (sc) =>
          (lang && lang !== "en" ? sc.subCatDetectorId : sc.fixedSubCatId) ===
          subCatId
      );
      if (Sc) {
        subCat = Sc.subCatName;
        cat = x.catName;
      }
      return 0;
    });
    return { cat, subCat };
  };

  const getCompany = (companyId) => {
    if (companyList.length > 0) {
      let cName = "";
      let cImage = "";
      let C = companyList.find(
        (c) =>
          (lang && lang === "en" ? c.fixedCompanyId : c.companyDetectorId) ===
          companyId
      );
      if (C) {
        cName = C.companyName;
        cImage = C.companyLogo;
      }
      return { cName, cImage };
    }
    return "";
  };

  const getVariant = (variantId) => {
    let variant = {};
    if (medVariants.length > 0) {
      variant = medVariants.find((mv) => mv.fixedVariantId === variantId);
    }
    return variant;
  };

  const getTag = (tagsId) => {
    let obj = {};
    if (seoTags.length > 0) {
      const St = seoTags.find((s) => s.lang === lang);
      if (St) {
        const T = St.data.find(
          (t) => (lang !== "en" ? t.tagDetectorId : t.fixedId) === tagsId
        );
        if (T) obj = T;
      }
    }
    return obj;
  };

  const purifyWithMedInfo = (str) => {
    let catArr = [];
    let subCatArr = [];
    medicineInfo.subCats.forEach((x) => {
      subCatArr.push(getCat(x.subCatId).subCat);
      catArr.push(getCat(x.subCatId).cat);
    });
    const subCatStr = subCatArr.join(", ");
    var unique = [...new Set(catArr)];
    const catStr = unique.join(", ");
    str = str.replace(/{_medicine_name}/g, medicineInfo.genericBrand);
    str = str.replace(/{_sub_category_name}/g, subCatStr);
    str = str.replace(/{_category_name}/g, catStr);
    return str;
  };

  const handleReviewClose = () => {
    setReviewChar("");
    setTempReviewChar("");
    setReviewDialogue(false);
    setRating(5);
    setCustomerEmail("");
    setPhone("");
    setSuggest("");
    setNoCustomerEmail(false);
    setInvalidCustomerEmail(false);
    setEmailVerified(false);
  };

  const handleReview = () => {
    setReviewLoading(true);

    let obj = {
      review: tempReviewChar,
      rating,
      url: makeUrlFriendly(medicineInfo.genericBrand),
    };
    if (localCustomerData() && localCustomerData()._id) {
      obj.customerId = localCustomerData()._id;
      obj.customerRegistered = true;
    } else {
      obj.customerEmail = customerEmail;
      obj.customerPhone = phone;
      obj.customerRegistered = false;
    }
    if (lang === "en") {
      obj.medicineId = medicineInfo.fixedMedicineId;
    } else obj.medicineId = medicineInfo.medicineDetectorId;
    if (shortComings.length > 0) obj.shortComings = shortComings;
    if (suggest.length > 0) obj.suggestion = suggest;
    obj.langId = localLangData._id;
    dispatch(publishRating(obj));
    setTimeout(() => {
      if (localCustomerData() && localCustomerData()._id)
        dispatch(getNotificationByCustomer(localCustomerData()._id));
      dispatch(getReviews());
      setReviewDialogue(false);
      setReviewLoading(false);
      handleReviewClose();
      setShortComings([]);
    }, 2000);
  };

  const handleShortComings = (e, scopeName) => {
    let arr = [...shortComings];
    if (e.target.checked) arr.push(scopeName);
    else arr = arr.filter((a) => a !== scopeName);
    setShortComings(arr);
  };

  const handleVerifyEmail = () => {
    setCountDownStart(
      new Date().getTime() + (webSettings.verificationInterval || 1) * 60000
    );
    let v = Math.floor(Math.random() * (999999 - 100000 + 1) + 100000);
    setVCode(v);
    v = v * 3875412698;
    dispatch(emailVerify({ v, email: customerEmail, langId: getLangId(lang) }));
    setVerifyEmailLoading(true);
  };

  const handleCompare = () => {
    let obj = {};
    if (lang !== "en") obj.medId = medicineInfo.medicineDetectorId;
    else obj.medId = medicineInfo.fixedMedicineId;
    if (localCustomerData() && localCustomerData()._id)
      obj.customerId = localCustomerData()._id;
    if (
      compareList.includes(
        lang !== "en"
          ? medicineInfo.medicineDetectorId
          : medicineInfo.fixedMedicineId
      )
    )
      dispatch(removeFromCompare(obj));
    else dispatch(addToCompare(obj));
  };

  const handleWishlist = () => {
    let obj = {};
    if (lang !== "en") obj.medId = medicineInfo.medicineDetectorId;
    else obj.medId = medicineInfo.fixedMedicineId;
    if (localCustomerData() && localCustomerData()._id)
      obj.customerId = localCustomerData()._id;
    if (
      wishlist.includes(
        lang !== "en"
          ? medicineInfo.medicineDetectorId
          : medicineInfo.fixedMedicineId
      )
    )
      dispatch(removeFromWishlist(obj));
    else dispatch(addToWishlist(obj));
  };

  const getLangId = (langCode) => {
    const L = langs.find((l) => l.langCode === langCode);
    if (L && L._id) return L._id;
    else return 0;
  };

  const getDosageInfo = (dosageId) => {
    let dosageName = "";
    const D = dosageList.find(
      (d) => (lang !== "en" ? d.dosageDetectorId : d.fixedDosageId) === dosageId
    );
    if (D && D.dosageName) dosageName = D.dosageName;

    return dosageName;
  };

  const handleAskForPrice = () => {
    if (localCustomerData() && localCustomerData()._id) {
      let obj = {
        customerId: localCustomerData()._id,
        medId:
          lang !== "en"
            ? medicineInfo.medicineDetectorId
            : medicineInfo.fixedMedicineId,
        medName: medicineInfo.genericBrand,
        url: makeUrlFriendly(medicineInfo.genericBrand),
        variantId:
          lang !== "en"
            ? activeVariant.variantDetectorId
            : activeVariant.fixedVariantId,
        langId: getLangId(lang),
        email: localCustomerData().email,
      };
      dispatch(askPrice(obj));
      setAskPriceLoading(true);
      setTimeout(() => {
        setAskPriceLoading(false);
      }, 1000);
    } else {
      dispatch(promptLogin());
    }
  };

  // Cart

  // const handleFixNonEngCart = (variantId) => {
  //   if (lang !== "en" && medVariants && medVariants.length) {
  //     const Mv = medVariants.find((mv) => mv.fixedVariantId === variantId);
  //     if (Mv && Mv.variantDetectorId) variantId = Mv.variantDetectorId;
  //   }
  //   return variantId;
  // };

  const cartExist = (variant) => {
    let obj = { exist: false };
    if (carts && carts.length > 0) {
      const C = carts.find(
        (c) =>
          c.variantId ===
          (lang !== "en" ? variant.variantDetectorId : variant.fixedVariantId)
      );
      if (C) {
        obj.exist = true;
        obj.moq = C.moq;
      }
    }

    return obj;
  };

  const handleAddToCart = () => {
    if (localCustomerData()._id) {
      let obj = {
        price:
          Number(priceExist(activeVariant).sellingPrice || 0) -
          Number(priceExist(activeVariant).discount || 0),
        minMoq: Number(priceExist(activeVariant).moq),
      };
      if (!mdUp) {
        obj.moq = Number(qty);
      } else {
        obj.moq = Number(qtyRef.current.value);
      }
      obj.medId =
        lang !== "en"
          ? medicineInfo.medicineDetectorId
          : medicineInfo.fixedMedicineId;
      obj.variantId =
        lang !== "en"
          ? activeVariant.variantDetectorId
          : activeVariant.fixedVariantId;

      obj.customerId = localCustomerData()._id;

      dispatch(addToCart(obj));
      setCartLoading(true);
      setTimeout(() => {
        localCustomerData()._id &&
          dispatch(getCartInfoByCustomer(localCustomerData()._id));
        setCartLoading(false);
      }, 1000);
    } else dispatch(promptLogin());
  };

  const handleRemoveFromCart = () => {
    setCartLoading(true);
    setQtyLoading(true);
    const variantId =
      lang !== "en"
        ? activeVariant.variantDetectorId
        : activeVariant.fixedVariantId;
    localCustomerData() &&
      dispatch(
        removeFromCart({ customerId: localCustomerData()._id, variantId })
      );
    setTimeout(() => {
      setQtyLoading(false);
      setCartLoading(false);
    }, 1000);
  };

  const handleUpdateCart = () => {
    setCartLoading(true);
    let obj = {
      price:
        Number(priceExist(activeVariant).sellingPrice || 0) -
        Number(priceExist(activeVariant).discount || 0),
      minMoq: Number(priceExist(activeVariant).moq),
    };
    if (!mdUp) {
      obj.moq = Number(qty);
    } else {
      obj.moq = Number(qtyRef.current.value);
    }
    obj.medId =
      lang !== "en"
        ? medicineInfo.medicineDetectorId
        : medicineInfo.fixedMedicineId;
    obj.variantId =
      lang !== "en"
        ? activeVariant.variantDetectorId
        : activeVariant.fixedVariantId;
    if (localCustomerData()._id) obj.customerId = localCustomerData()._id;
    localCustomerData()._id && dispatch(updateCart(obj));
    setTimeout(() => {
      setEnableUpdate(false);
      setQtyLoading(false);
      setCartLoading(false);
    }, 1000);
  };

  const priceExist = (variant) => {
    let obj = {
      sellingPrice: undefined,
      moq: undefined,
      discount: undefined,
      note: undefined,
      exist: false,
    };
    if (
      variant.sellingPrice &&
      variant.sellingPrice > 0 &&
      webSettings.showPrice
    ) {
      obj.sellingPrice = variant.sellingPrice;
      obj.moq = variant.minimumOrderQty > 1 ? variant.minimumOrderQty : 1;
      obj.discount = variant.discount;
      obj.exist = true;
    }
    if (assignedPriceList && assignedPriceList.length > 0) {
      const A = assignedPriceList.find(
        (a) =>
          a.variantId ===
          (lang === "en" ? variant.fixedVariantId : variant.variantDetectorId)
      );
      if (A) {
        obj.sellingPrice = A.sellingPrice || undefined;
        obj.moq = A.moq || undefined;
        obj.discount = A.discount || undefined;
        obj.note = A.note || undefined;
        obj.exist = true;
      }
    }
    return obj;
  };

  const handleAddQty = () => {
    if (cartExist(activeVariant).exist) {
      setEnableUpdate(true);
    }
    setQty(Number(qty) + 1);
  };

  const handleRemoveQty = () => {
    if (cartExist(activeVariant).exist) {
      setEnableUpdate(true);
    }
    if (Number(qty) - 1 >= Number(priceExist(activeVariant).moq))
      setQty(Number(qty) - 1);
  };

  // Use Effect
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMedCategories());
    dispatch(getCompanies());
    dispatch(getComplainScopes());
    dispatch(getSeoTags());
    dispatch(getDosages());
    if (localCustomerData())
      dispatch(getAssignedPriceByCustomer(localCustomerData()._id));
    if (localCustomerData()._id) {
      dispatch(getCartInfoByCustomer(localCustomerData()._id));
    }
    // dispatch(getCompareList());
  }, []);

  // useEffect(() => {
  //   if (medicineInfo) {
  //     dispatch(
  //       postViewCount({
  //         medicineIdEn:
  //           lang !== "en"
  //             ? medicineInfo.medicineDetectorId
  //             : medicineInfo.fixedMedicineId,
  //       })
  //     );
  //     setTimeout(() => {
  //       dispatch(getViewCount());
  //     }, 1000);
  //   }
  // }, [medicineInfo]);

  // set currency
  useEffect(() => {
    setExchangeRate(activeCurrencyFront.exchangeRate);
    setCurrencyName(activeCurrencyFront.currencyName);
  }, [activeCurrencyFront]);

  useEffect(() => {
    if (complainScopes && complainScopes.length > 0) {
      if (langs.length > 0) {
        const L = langs.find((l) => l.langCode === lang);
        let arr = complainScopes.filter((a) => a.langId === L._id);
        setComplainScopeList(arr);
      }
    }
  }, [complainScopes]);

  // Use Effect
  useEffect(() => {
    if (companies && companies.length > 0) {
      let C = companies.find((c) => c.lang === lang);
      if (C) setCompanyList(C.data);
    }
  }, [companies, lang]);

  // set Category
  useEffect(() => {
    if (lang !== "" && categories && categories.length > 0) {
      let C = categories.find((c) => c.lang === lang);
      if (C) setCatList(C.data);
      else setCatList([]);
    }
  }, [categories, lang]);

  useEffect(() => {
    if (medicineInfo.variants && medicineInfo.variants.length > 0) {
      const eligibleVariants = medicineInfo.variants.filter(
        (v) => getVariant(v.variantId).showInFront
      );
      if (eligibleVariants.length > 0) {
        const a = getVariant(eligibleVariants[0].variantId);
        if (a) setActiveVariant(a);
      }
    }
  }, [medicineInfo]);

  useEffect(() => {
    reviewChar !== "" && setTempReviewChar(reviewChar);
  }, [reviewChar]);

  useEffect(() => {
    let errCounter = 0;
    if (reviewDialogue) {
      tempReviewChar.length < 1 && errCounter++;
      tempReviewChar.length > 500 && errCounter++;
      checkForLink(["http", ".com", ".org", ".xyz"], tempReviewChar) &&
        errCounter++;
      if (!localCustomerData()._id) {
        customerEmail.length < 1 && errCounter++;
        !customerEmail.includes("@") && errCounter++;
        !emailVerified && errCounter++;
        if (phone && phone.length < 1) errCounter++;
      }
      if (rating < 2.5) {
        shortComings.length < 1 && errCounter++;
        suggest.length < 1 && errCounter++;
      }
    }
    errCounter > 0 ? setReviewDisable(true) : setReviewDisable(false);
  }, [
    customerEmail,
    // localCustomerData,
    tempReviewChar,
    reviewDialogue,
    emailVerified,
    rating,
    shortComings.length,
    suggest.length,
    phone,
  ]);

  // check Email Response
  useEffect(() => {
    if (emailResponse.message) {
      if (!emailResponse.failed) setVerifyDlg(true);
      setVerifyEmailLoading(false);
    }
  }, [emailResponse]);

  // Check Verification Code
  useEffect(() => {
    setVerifyEmailLoading(true);
    if (vCode === Number(verifyCodeInput)) {
      setVerifyDlg(false);
      setEmailVerified(true);
      setVerifyCodeInput("");
    }
    setVerifyEmailLoading(false);
  }, [verifyCodeInput]);

  // Check if in compareList
  useEffect(() => {
    if (compareList && compareList.length > 0) {
      compareList.includes(
        lang !== "en"
          ? medicineInfo.medicineDetectorId
          : medicineInfo.fixedMedicineId
      )
        ? setCompareListExist(true)
        : setCompareListExist(false);
    }
  }, [compareList]);

  // Check if in wishlist
  useEffect(() => {
    if (wishlist) {
      wishlist.includes(
        lang !== "en"
          ? medicineInfo.medicineDetectorId
          : medicineInfo.fixedMedicineId
      )
        ? setWishListExist(true)
        : setWishListExist(false);
    }
  }, [wishlist]);

  // check For Ask Price
  useEffect(() => {
    if (askPriceListByCustomer && askPriceListByCustomer.length > 0) {
      const foundMed = askPriceListByCustomer.some(
        (el) =>
          el.medId ===
          (lang !== "en"
            ? medicineInfo.medicineDetectorId
            : medicineInfo.fixedMedicineId)
      );
      if (foundMed) {
        const foundVariant = askPriceListByCustomer.some(
          (el) =>
            el.variantId ===
            (lang !== "en"
              ? activeVariant.variantDetectorId
              : activeVariant.fixedVariantId)
        );
        if (foundVariant) {
          setAskPriceDisable(true);
          // if (askPriceListByCustomer.priceAdded) {
          //   setEnableCart(true);
          // } else {
          //   setEnableCart(false);
          // }
        } else {
          setAskPriceDisable(false);
        }
      }
    }
  }, [askPriceListByCustomer, activeVariant]);

  // set Dosage List
  useEffect(() => {
    if (lang !== "" && dosages && dosages.length > 0) {
      const D = dosages.find((d) => d.lang === lang);
      if (D && D.data) setDosageList(D.data);
      else setDosageList([]);
    }
  }, [dosages, lang]);

  // Set Seo Tags List
  useEffect(() => {
    let arr = [];
    if (medicineInfo.tags && medicineInfo.tags.length) {
      // let arr = [...medicineInfo.tags]
      medicineInfo.tags.forEach((element) => {
        arr.push(getTag(element.tagsId));
      });
      const successTags = arr.filter((a) => a.severity === "success");
      const infoTags = arr.filter((a) => a.severity === "info");
      const warningTags = arr.filter((a) => a.severity === "warning");
      const errorTags = arr.filter((a) => a.severity === "error");
      const finalArr = [
        ...successTags,
        ...infoTags,
        ...warningTags,
        ...errorTags,
      ];
      setSeoTagList(finalArr);
    }
  }, [medicineInfo.tags]);

  // check Ask Price Response
  useEffect(() => {
    if (askPriceResponse.message && localCustomerData()) {
      if (!askPriceResponse.failed) {
        setAskPriceDlg(true);
        dispatch(getAskPriceListByCustomer(localCustomerData()._id));
        dispatch(getNotificationByCustomer(localCustomerData()._id));
      }
    }
  }, [askPriceResponse]);

  useEffect(() => {
    setQtyLoading(true);
    // let quantity = cartExist(activeVariant).exist
    //   ? Number(cartExist(activeVariant).moq)
    //   : Number(priceExist(activeVariant).moq);
    setTimeout(() => {
      setQtyLoading(false);
    }, 600);
  }, [activeVariant]);

  useEffect(() => {
    if (priceExist(activeVariant).moq) {
      //   setQty(priceExist(activeVariant).moq);
      let quantity = cartExist(activeVariant).exist
        ? Number(cartExist(activeVariant).moq)
        : Number(priceExist(activeVariant).moq);
      setQty(quantity);
    }
  }, [activeVariant, qtyLoading]);

  // Detect Cart change
  useEffect(() => {
    setQtyLoading(true);
    setTimeout(() => {
      setQtyLoading(false);
    }, 1000);
  }, [carts]);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate

  return (
    <Slide direction="left" in={!loadingMain} mountOnEnter unmountOnExit>
      <Box component="div">
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: "1rem", sm: "1.5rem" },
            lineHeight: "2.5rem",
            fontWeight: 500,
            textTransform: "uppercase",
          }}
          color={"#2196f3"}
        >
          {medicineInfo.genericBrand
            ? medicineInfo.genericBrand.toUpperCase()
            : ""}{" "}
          ( {translateFront("Generic")}{" "}
          {medicineInfo.generic ? medicineInfo.generic.toUpperCase() : ""})
          <sup
            style={{
              borderRadius: ".45rem",
              padding: "0 5px",
              background: "#2196f3",
              color: "white",
              fontSize: ".9rem",
              position: "relative",
              top: -10,
              fontWeight: 600,
            }}
          >
            Rx
          </sup>
        </Typography>
        <Divider />

        <Grid container spacing={0} flexDirection="column">
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <Grid container spacing={0}>
              <Grid item>
                <Typography
                  variant="body2"
                  color="initial"
                  sx={{
                    fontSize: "1rem",
                    fontWeight: 100,
                    mr: 1,
                  }}
                >
                  {translateFront("Category")}:
                </Typography>
              </Grid>
              {medicineInfo.subCats &&
                medicineInfo.subCats.map(
                  (y, j) =>
                    getCat(y.subCatId).subCat && (
                      <Grid item key={j}>
                        <Typography
                          variant="body2"
                          color="initial"
                          sx={{
                            fontSize: "1rem",
                            fontWeight: 100,
                            mr: 1,
                            textTransform: "capitalize",
                          }}
                          component="span"
                        >
                          {getCat(y.subCatId).subCat +
                            " ( " +
                            getCat(y.subCatId).cat +
                            " )" +
                            (j + 1 < medicineInfo.subCats.length ? "," : "")}
                        </Typography>
                      </Grid>
                    )
                )}
            </Grid>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <Typography
                  variant="body2"
                  color="initial"
                  sx={{ fontSize: "1rem", fontWeight: 100 }}
                >
                  {translateFront("Mfg Company")}:
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  // fullWidth
                  variant="text"
                  size="small"
                  color="inherit"
                  sx={{
                    pointerEvents: "none",
                    fontSize: "1rem",
                    fontWeight: 100,
                    ml: -0.8,
                  }}
                  endIcon={
                    <img
                      src={
                        getCompany(medicineInfo.companyId).cImage &&
                        PF +
                          "/adminPanel/companies/" +
                          getCompany(medicineInfo.companyId).cImage
                      }
                      alt={
                        getCompany(medicineInfo.companyId).cName &&
                        getCompany(medicineInfo.companyId).cName
                      }
                      style={{
                        height: "25px",
                        objectFit: "cover",
                        borderRadius: ".25rem",
                      }}
                    />
                  }
                >
                  {getCompany(medicineInfo.companyId).cName}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>

          <Grid item>
            <Divider />
          </Grid>

          <Grid item>
            <Grid container spacing={1} sx={{ my: 0.1 }}>
              {medicineInfo.variants &&
                medicineInfo.variants.map(
                  (y, j) =>
                    getVariant(y.variantId).showInFront &&
                    getDosageInfo(getVariant(y.variantId).dosageForm) && (
                      <Grid item key={j}>
                        <Button
                          variant={
                            getVariant(y.variantId)._id === activeVariant._id
                              ? "contained"
                              : "outlined"
                          }
                          size="small"
                          color="info"
                          onClick={() =>
                            setActiveVariant(
                              getVariant(medicineInfo.variants[j].variantId)
                            )
                          }
                        >
                          {getDosageInfo(getVariant(y.variantId).dosageForm) ||
                            ""}{" "}
                          {getVariant(y.variantId).strength || ""}{" "}
                          {formatMedicinePackSize(
                            getVariant(y.variantId).packTypes
                          ) || ""}
                        </Button>
                      </Grid>
                    )
                )}
            </Grid>
          </Grid>
          <Grid item sx={{ mt: 1 }}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              color="initial"
              sx={{ fontSize: "1rem", fontWeight: 100 }}
            >
              {translateFront("Dosage Form")}:{" "}
              {getDosageInfo(activeVariant.dosageForm) || ""}
            </Typography>
          </Grid>
          <Grid item sx={{ mb: 0.5 }}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              color="initial"
              sx={{ fontSize: "1rem", fontWeight: 100 }}
            >
              {translateFront("Pack Types")}:{" "}
              {formatMedicinePackSize(activeVariant.packTypes) || ""}
            </Typography>
          </Grid>
          <Grid item sx={{ mb: 0.5 }}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              color="initial"
              sx={{ fontSize: "1rem", fontWeight: 100 }}
            >
              {translateFront("Strength")}: {activeVariant.strength || ""}
            </Typography>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          {webSettings.showOriginator && (
            <Grid item xs={12}>
              <Typography
                variant="body2"
                color="initial"
                sx={{ fontSize: "1rem", fontWeight: 100 }}
              >
                {translateFront("Originator")}: {medicineInfo.originator || ""}
              </Typography>
            </Grid>
          )}
          <Grid item sx={{ mb: 0.5 }}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              color="initial"
              sx={{ fontSize: "1rem", fontWeight: 100 }}
            >
              {translateFront("Safety Advice")}:
            </Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={1} sx={{ my: 0.1 }}>
              {seoTagList &&
                seoTagList.map(
                  (y, j) =>
                    y.tagName && (
                      <Grid item key={j} xs={12} sm={6}>
                        <Button
                          variant="text"
                          size="large"
                          color={y.severity}
                          sx={{ pointerEvents: "none", ml: -1 }}
                        >
                          {"#" + y.tagName ? y.tagName.toUpperCase() : ""}
                        </Button>
                        <Divider />
                        <Typography variant="body2" color="text.secondary">
                          {purifyWithMedInfo(y.tagDetails)}
                        </Typography>
                      </Grid>
                    )
                )}
            </Grid>
          </Grid>
          <Grid item sx={{ my: 1 }}>
            <Divider />
          </Grid>
          {priceExist(activeVariant).exist && (
            <>
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    {priceExist(activeVariant).discount &&
                    Number(priceExist(activeVariant).discount) > 0 ? (
                      <Typography variant="body2" color="inherit">
                        <span style={{ fontSize: "2rem", fontWeight: 100 }}>
                          {getSymbolFromCurrency(currencyName)}
                          {currencyFormatter(
                            currencyName,
                            Math.round(
                              (Number(priceExist(activeVariant).sellingPrice) -
                                Number(priceExist(activeVariant).discount)) *
                                exchangeRate
                            )
                          )}
                        </span>
                        <sup
                          style={{
                            fontSize: ".8rem",
                            fontWeight: 100,
                            position: "relative",
                            top: -10,
                          }}
                        >
                          {getDecimal(
                            Number(priceExist(activeVariant).sellingPrice) *
                              exchangeRate
                          )}
                        </sup>
                        <sub>
                          <del
                            style={{
                              color: "grey",
                              fontSize: "1rem",
                              fontWeight: 100,
                            }}
                          >
                            {getSymbolFromCurrency(currencyName)}
                            {currencyFormatter(
                              currencyName,
                              (
                                Number(priceExist(activeVariant).sellingPrice) *
                                exchangeRate
                              ).toFixed(2)
                            )}
                          </del>{" "}
                        </sub>
                      </Typography>
                    ) : (
                      <Typography variant="body2" color="inherit">
                        <span style={{ fontSize: "2rem", fontWeight: 100 }}>
                          {getSymbolFromCurrency(currencyName)}
                          {currencyFormatter(
                            currencyName,
                            Math.round(
                              Number(priceExist(activeVariant).sellingPrice) *
                                exchangeRate
                            )
                          )}
                        </span>
                        <sup
                          style={{
                            fontSize: ".8rem",
                            fontWeight: 100,
                            position: "relative",
                            top: -10,
                          }}
                        >
                          {getDecimal(
                            Number(priceExist(activeVariant).sellingPrice) *
                              exchangeRate
                          )}
                        </sup>
                      </Typography>
                    )}
                  </Grid>
                  {smUp &&
                    priceExist(activeVariant).discount &&
                    Number(priceExist(activeVariant).discount) > 0 && (
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="primary"
                          startIcon={<SellOutlinedIcon />}
                          size="small"
                          sx={{ pointerEvents: "none" }}
                        >
                          {translateFront("Save")}{" "}
                          {discountCalc(
                            Number(priceExist(activeVariant).sellingPrice),
                            Number(priceExist(activeVariant).discount)
                          )}
                          %
                        </Button>
                      </Grid>
                    )}
                </Grid>
              </Grid>
              <Grid item>
                <Box sx={{ height: 70 }}>
                  {qtyLoading ? (
                    <LoadingBox />
                  ) : !mdUp ? (
                    <>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item>
                          <IconButton
                            size="small"
                            onClick={handleRemoveQty}
                            disabled={
                              Number(qty) - 1 <
                              Number(priceExist(activeVariant).moq)
                            }
                          >
                            <RemoveCircleOutlineIcon
                              color={
                                Number(qty) - 1 <
                                Number(priceExist(activeVariant).moq)
                                  ? "inherit"
                                  : "error"
                              }
                            />
                          </IconButton>
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="body2"
                            color="initial"
                            sx={{ fontSize: "1rem" }}
                          >
                            {qty}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <IconButton size="small" onClick={handleAddQty}>
                            <AddCircleOutlineIcon color="success" />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <Typography
                        variant="body2"
                        color="initial"
                        sx={{ fontSize: ".7rem" }}
                      >
                        {translateFront("Minimum Order Qty")} :{" "}
                        {priceExist(activeVariant).moq || 0}
                      </Typography>
                    </>
                  ) : (
                    <TextField
                      label="Qty"
                      type="number"
                      variant="standard"
                      size="small"
                      // name="qty"
                      inputRef={qtyRef}
                      InputProps={{
                        inputProps: {
                          min: Number(priceExist(activeVariant).moq),
                        },
                      }}
                      defaultValue={
                        cartExist(activeVariant).exist
                          ? Number(cartExist(activeVariant).moq)
                          : Number(priceExist(activeVariant).moq)
                      }
                      onChange={(e) => {
                        if (cartExist(activeVariant).exist) {
                          setEnableUpdate(true);
                        }
                        setQty(e.target.value);
                      }}
                      onKeyPress={(e) => {
                        e.preventDefault();
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      helperText={`${translateFront(
                        "Minimum Order Qty"
                      )} ${Number(priceExist(activeVariant).moq)}`}
                    />
                  )}
                </Box>
              </Grid>
              <Grid item sx={{ my: 1 }}>
                <Divider />
              </Grid>
            </>
          )}

          {priceExist(activeVariant).exist && (
            <>
              {qtyLoading ? (
                <LoadingBox />
              ) : (
                <Grid item>
                  <Typography variant="body2" color="inherit">
                    <span style={{ fontSize: "2rem", fontWeight: 100 }}>
                      {translateFront("Total")}:
                      {getSymbolFromCurrency(currencyName)}
                      {currencyFormatter(
                        currencyName,
                        Math.round(
                          Number(
                            priceExist(activeVariant).sellingPrice -
                              priceExist(activeVariant).discount
                          ) *
                            exchangeRate *
                            // (cartExist(activeVariant).exist
                            //   ? Number(cartExist(activeVariant).moq)
                            //   : Number(priceExist(activeVariant).moq))
                            Number(qty)
                        )
                      )}
                    </span>
                    <sup
                      style={{
                        fontSize: ".8rem",
                        fontWeight: 100,
                        position: "relative",
                        top: -10,
                      }}
                    >
                      {getDecimal(
                        Number(priceExist(activeVariant).sellingPrice) *
                          exchangeRate
                      )}
                    </sup>
                  </Typography>
                </Grid>
              )}

              <Grid item sx={{ my: 1 }}>
                <Divider />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            {priceExist(activeVariant).exist ? (
              <>
                {!cartExist(activeVariant).exist ? (
                  <LoadingButton
                    fullWidth
                    variant="contained"
                    color="info"
                    size="small"
                    onClick={() => handleAddToCart()}
                    loading={cartLoading}
                    sx={{
                      textTransform: "capitalize",
                    }}
                    startIcon={<AddShoppingCartIcon />}
                  >
                    {translateFront("Add To Cart")}{" "}
                    {smUp && (
                      <>
                        {"(" +
                          (getDosageInfo(activeVariant.dosageForm) || "") +
                          "-" +
                          (activeVariant.strength || "") +
                          "-" +
                          (formatMedicinePackSize(activeVariant.packTypes) ||
                            "") +
                          " )"}
                      </>
                    )}
                  </LoadingButton>
                ) : (
                  <>
                    {enableUpdate ? (
                      <LoadingButton
                        fullWidth
                        variant="contained"
                        color="warning"
                        size="small"
                        onClick={() => handleUpdateCart()}
                        loading={cartLoading}
                      >
                        {translateFront("Update Cart")} {!smUp && <br />}
                        {"( " +
                          (getDosageInfo(activeVariant.dosageForm) || "") +
                          "-" +
                          (activeVariant.strength || "") +
                          "-" +
                          (formatMedicinePackSize(activeVariant.packTypes) ||
                            "") +
                          " )"}
                      </LoadingButton>
                    ) : (
                      <LoadingButton
                        fullWidth
                        variant="contained"
                        color="error"
                        size="small"
                        onClick={() => handleRemoveFromCart()}
                        loading={cartLoading}
                      >
                        {translateFront("Remove From Cart")} {!smUp && <br />}
                        {"( " +
                          (getDosageInfo(activeVariant.dosageForm) || "") +
                          "-" +
                          (activeVariant.strength || "") +
                          "-" +
                          (formatMedicinePackSize(activeVariant.packTypes) ||
                            "") +
                          " )"}
                      </LoadingButton>
                    )}
                  </>
                )}
              </>
            ) : (
              <LoadingButton
                fullWidth
                variant="contained"
                color="success"
                size="small"
                onClick={() => handleAskForPrice()}
                loading={askPriceLoading}
                disabled={localCustomerData()._id && askPriceDisable}
              >
                {translateFront("Ask For Price")} {!smUp && <br />}
                {"( " +
                  (getDosageInfo(activeVariant.dosageForm) || "") +
                  "-" +
                  (activeVariant.strength || "") +
                  "-" +
                  (formatMedicinePackSize(activeVariant.packTypes) || "") +
                  " )"}
              </LoadingButton>
            )}
          </Grid>
          {/* Review, wishlist, compare */}
          <Grid item sx={{ mt: 1 }}>
            <Grid container spacing={2} justifyContent="space-around">
              <Grid item xs={12} md={9.8}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  onClick={() => setReviewDialogue(true)}
                  size="small"
                >
                  {translateFront("Write A Review")}
                </Button>
                {reviewDialogue && (
                  // <></>
                  <Dialog
                    open={reviewDialogue}
                    onClose={handleReviewClose}
                    aria-labelledby="review-dialog-title"
                    aria-describedby="review-dialog-description"
                    fullWidth
                    maxWidth={"lg"}
                  >
                    {smUp && (
                      <DialogTitle>
                        {translateFront("Write A Review")}
                      </DialogTitle>
                    )}
                    <DialogContent>
                      {smUp && (
                        <DialogContentText>
                          {translateFront(
                            "We value your opinion more than anything. Please feel free to write us your experience with us or about the product."
                          )}
                        </DialogContentText>
                      )}
                      {!localCustomerData()._id && (
                        <>
                          <Grid
                            container
                            spacing={1}
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Grid item xs={12} sm={10} md={11}>
                              <TextField
                                placeholder={translateFront(
                                  "Enter Your Email..."
                                )}
                                label={translateFront("Email")}
                                variant="standard"
                                fullWidth
                                size="small"
                                disabled={emailVerified ? true : false}
                                value={customerEmail}
                                onChange={(e) => {
                                  setCustomerEmail(e.target.value);
                                  setNoCustomerEmail(false);
                                  setInvalidCustomerEmail(false);
                                }}
                                error={
                                  noCustomerEmail || invalidCustomerEmail
                                    ? true
                                    : false
                                }
                                onBlur={() => {
                                  customerEmail.length < 1 &&
                                    setNoCustomerEmail(true);
                                  !customerEmail.includes("@") &&
                                    setInvalidCustomerEmail(true);
                                }}
                                helperText={
                                  noCustomerEmail ? (
                                    <Typography variant="body2" color="error">
                                      {translateFront("Email is required")}
                                    </Typography>
                                  ) : (
                                    invalidCustomerEmail && (
                                      <Typography variant="body2" color="error">
                                        {translateFront("Invalid Email")}
                                      </Typography>
                                    )
                                  )
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={1.5} md={1}>
                              {verifyEmailLoading ? (
                                <LoadingBox w={"100%"} />
                              ) : (
                                <Tooltip
                                  arrow
                                  placement="left"
                                  title={
                                    emailVerified
                                      ? translateFront("Verified")
                                      : translateFront("Verify Email")
                                  }
                                >
                                  <Button
                                    variant="contained"
                                    size="small"
                                    fullWidth={!smUp ? true : false}
                                    onClick={() => handleVerifyEmail()}
                                    sx={{
                                      pointerEvents: emailVerified && "none",
                                    }}
                                    color={
                                      customerEmail.length < 1 ||
                                      !customerEmail.includes("@")
                                        ? "inherit"
                                        : emailVerified
                                        ? "success"
                                        : "warning"
                                    }
                                    disabled={
                                      customerEmail.length > 0 &&
                                      customerEmail.includes("@")
                                        ? false
                                        : true
                                    }
                                  >
                                    {emailVerified
                                      ? translateFront("Verified")
                                      : translateFront("Verify Email")}
                                  </Button>
                                </Tooltip>
                              )}
                              {/* Verification dialog */}
                              <EmailVerificationDialog
                                verifyDlg={verifyDlg}
                                setVerifyDlg={setVerifyDlg}
                                verifyCodeInput={verifyCodeInput}
                                setVerifyCodeInput={setVerifyCodeInput}
                                countDownStart={countDownStart}
                                setCountDownStart={setCountDownStart}
                                logic={handleVerifyEmail}
                              />
                            </Grid>
                          </Grid>
                          <Typography
                            variant="body2"
                            color="inherit"
                            sx={{ mt: 1 }}
                          >
                            {translateFront("Phone")} *
                          </Typography>
                          <PhoneInputWithCountrySelect
                            placeholder={translateFront("Enter Phone Number")}
                            international
                            value={phone}
                            defaultCountry="US"
                            onChange={setPhone}
                          />
                        </>
                      )}
                      <TextField
                        autoFocus
                        margin="dense"
                        multiline
                        minRows={4}
                        label={translateFront("Write A Review") + "..."}
                        type="text"
                        fullWidth
                        variant="standard"
                        value={tempReviewChar}
                        onChange={(e) => setTempReviewChar(e.target.value)}
                        onBlur={() => setReviewChar(tempReviewChar)}
                        helperText={
                          checkForLink(["http"], tempReviewChar) ? (
                            <Typography variant="body2" color="error">
                              {translateFront(
                                "You are not allowed to post links"
                              )}
                            </Typography>
                          ) : (
                            <Typography
                              align="right"
                              variant="body2"
                              color={
                                tempReviewChar.length < 1001
                                  ? "primary"
                                  : "error"
                              }
                            >
                              {tempReviewChar.length}/1000
                            </Typography>
                          )
                        }
                      />
                      <Grid container>
                        <Grid item>
                          <Rating
                            value={rating}
                            precision={0.5}
                            onChange={(e, newValue) => setRating(newValue)}
                            onChangeActive={(event, newHover) => {
                              setRatingHover(newHover);
                            }}
                          />
                        </Grid>
                        <Grid item>
                          {rating !== null && (
                            <Box sx={{ ml: 2 }}>
                              {
                                labels[
                                  ratingHover !== -1 ? ratingHover : rating
                                ]
                              }
                            </Box>
                          )}
                        </Grid>
                      </Grid>
                      {rating < 2.5 && (
                        <>
                          <Typography
                            variant="h6"
                            color="initial"
                            sx={{ textDecoration: "underline" }}
                          >
                            {translateFront(
                              "We Are very sorry for your bad experience."
                            )}
                          </Typography>
                          <Typography variant="body1" color="initial">
                            {translateFront(
                              "Your satisfaction is our goal. Please let us know our shortcomings"
                            )}
                          </Typography>
                          <Grid container spacing={0}>
                            {complainScopeList.length < 1 ? (
                              <>
                                <Grid item>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        value={shortComings.includes(0)}
                                        onChange={(e) =>
                                          handleShortComings(e, 0)
                                        }
                                      />
                                    }
                                    label="Others"
                                  />
                                </Grid>
                              </>
                            ) : (
                              complainScopeList.map((x, i) => (
                                <Grid item key={i}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        value={shortComings.includes(
                                          x.scopeName
                                        )}
                                        onChange={(e) =>
                                          handleShortComings(e, x.scopeName)
                                        }
                                      />
                                    }
                                    label={
                                      x.scopeName && x.scopeName.toUpperCase()
                                    }
                                  />
                                </Grid>
                              ))
                            )}
                          </Grid>

                          {shortComings.length > 0 && (
                            <>
                              <Typography
                                variant="h6"
                                color="initial"
                                sx={{ textDecoration: "underline" }}
                              >
                                {translateFront(
                                  "Tell us how can we improve your experience"
                                )}
                              </Typography>
                              <TextField
                                label={translateFront("Suggest Improvement")}
                                placeholder={translateFront(
                                  "Please tell us how can we improve your experience"
                                )}
                                value={suggest}
                                onChange={(e) => setSuggest(e.target.value)}
                                variant="standard"
                                fullWidth
                                size="small"
                                multiline
                                minRows={3}
                              />
                            </>
                          )}
                        </>
                      )}
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleReviewClose} color="error">
                        {translateFront("Cancel")}
                      </Button>
                      <LoadingButton
                        onClick={() =>
                          localCustomerData()._id && rating > 2
                            ? handleReview()
                            : setCaptcha(true)
                        }
                        loading={reviewLoading}
                        disabled={reviewDisable}
                      >
                        {translateFront("Submit")}
                      </LoadingButton>
                    </DialogActions>
                    <Dialog
                      open={captcha}
                      onClose={() => setCaptcha(false)}
                      aria-labelledby={"Captcha"}
                    >
                      <DialogContent>
                        <CaptchaTikTakToe
                          loginFunc={handleReview}
                          setCaptchaDlg={setCaptcha}
                        />
                      </DialogContent>
                    </Dialog>
                  </Dialog>
                )}
              </Grid>
              <Grid item xs={2} md={1}>
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    wishListExist
                      ? translateFront("Remove From Wishlist")
                      : translateFront("Add To Wishlist")
                  }
                >
                  <IconButton size="small" onClick={() => handleWishlist()}>
                    {wishListExist ? (
                      <FavoriteIcon color="error" />
                    ) : (
                      <FavoriteBorderIcon color="primary" />
                    )}
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={2} md={1}>
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    compareListExist
                      ? translateFront("Remove From Compare List")
                      : translateFront("Add To Compare")
                  }
                >
                  <IconButton size="small" onClick={() => handleCompare()}>
                    <CompareIcon color={compareListExist ? "error" : "info"} />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>

          {/* Share */}
          <Grid item xs={12} md={8} sx={{ mt: 1 }}>
            {!shareDialogue ? (
              <Button
                fullWidth
                variant="outlined"
                color="info"
                onClick={() => setShareDialogue(true)}
                size="small"
              >
                {translateFront("Share")}
              </Button>
            ) : (
              <>
                <Divider />
                <Grid
                  container
                  spacing={0}
                  alignItems="center"
                  justifyContent={mdUp ? "center" : "flex-start"}
                >
                  <Grid item>
                    <Tooltip
                      arrow
                      placement="top"
                      title={translateFront("Share On Twitter")}
                    >
                      <IconButton
                        onClick={() =>
                          window.open(
                            `https://twitter.com/intent/tweet?url=${window.location.href}`,
                            "_blank"
                          )
                        }
                        size="small"
                      >
                        <TwitterIcon color="info" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip
                      arrow
                      placement="top"
                      title={translateFront("Share On WhatsApp")}
                    >
                      <IconButton
                        onClick={() =>
                          window.open(
                            `https://api.whatsapp.com/send?text=${window.location.href}`,
                            "_blank"
                          )
                        }
                        size="small"
                      >
                        <WhatsAppIcon sx={{ color: "green" }} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip
                      arrow
                      placement="top"
                      title={translateFront("Share On Email")}
                    >
                      <IconButton
                        onClick={() =>
                          window.open(
                            `mailto:?subject=I wanted to share this link with you. &body=Check out this site ${window.location.href}`
                          )
                        }
                        size="small"
                      >
                        <MailIcon color="primary" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip
                      arrow
                      placement="top"
                      title={translateFront("Share On LinkedIn")}
                    >
                      <IconButton
                        onClick={() =>
                          window.open(
                            `https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`,
                            "_blank"
                          )
                        }
                        size="small"
                      >
                        <LinkedInIcon sx={{ color: "black" }} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip
                      arrow
                      placement="top"
                      title={translateFront("Copy to clipboard")}
                    >
                      <IconButton
                        onClick={() =>
                          copyToClipBoardFront(dispatch, window.location.href)
                        }
                        size="small"
                      >
                        <ContentCopyIcon color="success" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item sx={{ borderLeft: "0px solid red" }}>
                    <Tooltip arrow placement="top" title="Close">
                      <IconButton
                        size="small"
                        onClick={() => setShareDialogue(false)}
                      >
                        <CloseIcon color="error" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        {localCustomerData()._id && (
          <Dialog
            open={askPriceDlg}
            onClose={() => setAskPriceDlg(false)}
            aria-labelledby={"asked-price-successfully"}
            fullWidth
            maxWidth={"md"}
          >
            <DialogContent>
              <Card>
                <CardContent>
                  <Typography gutterBottom variant="h5" align="center">
                    {translateFront("Price Request Has Been Submitted")}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    align="center"
                  >
                    {translateFront(
                      "Please Wait, You will receive price information in email "
                    )}
                    <span style={{ color: "navy" }}>
                      {localCustomerData().email}
                    </span>
                    "
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    align="center"
                  >
                    {translateFront("You can also keep an eye on notification")}
                  </Typography>
                </CardContent>
              </Card>
            </DialogContent>
          </Dialog>
        )}
      </Box>
    </Slide>
  );
}
