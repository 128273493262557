import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Typography,
  TextField,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import React, { useState } from "react";

// Icon
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ProductListEntry from "../ProductListEntry";
import { useDispatch } from "react-redux";
import { getNotification } from "../../../../../reduxToolkit/features/adminPanel/notification";
import {
  getSuppliers,
  removeSuppliers,
  updateSuppliers,
} from "../../../../../reduxToolkit/features/adminPanel/supplier";
import EditProductList from "../EditProductList";
import EditCourierList from "../EditCourierList";
import CourierListEntry from "../CourierListEntry";
import LoadingButton from "@mui/lab/LoadingButton";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const SupplierCard = ({ supplier }) => {
  // Media Query
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));

  const dispatch = useDispatch();
  const [loadingMain, setLoadingMain] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [dealerName, setDealerName] = useState(supplier.dealerName);
  const [editMode, setEditMode] = useState(false);
  const [deleteDlg, setDeleteDlg] = useState(false);

  // local data
  const localData = JSON.parse(localStorage.getItem("adminLoginInfo"));

  const [productListEntries, setProductListEntries] = useState(
    supplier.productList || []
  );

  const [courierListEntries, setCourierListEntries] = useState(
    supplier.courierList || []
  );

  const handleUpdateProductList = () => {
    setLoadingMain(true);
    const obj = {
      creatorId: localData._id,
      _id: supplier._id,
      dealerName,
      productList: productListEntries,
      courierList: courierListEntries,
    };
    dispatch(updateSuppliers(obj));
    setTimeout(() => {
      dispatch(getNotification());
      dispatch(getSuppliers());
      setDealerName("");
      setLoadingMain(false);
    }, 3000);
  };

  const handleRemoveSupplier = () => {
    setLoadingMain(true);
    if (!loadingMain) {
      const obj = {
        creatorId: localData._id,
        _id: supplier._id,
      };
      dispatch(removeSuppliers(obj));
      setTimeout(() => {
        dispatch(getNotification());
        dispatch(getSuppliers());
        setDealerName("");
        setLoadingMain(false);
      }, 300);
    }
  };

  return (
    <>
      <Card sx={{ m: 2 }}>
        <CardContent>
          {editMode ? (
            <>
              <TextField
                label="Dealer Name"
                value={dealerName}
                onChange={(e) => setDealerName(e.target.value)}
                size="small"
                variant="outlined"
                fullWidth
              />
              <Divider sx={{ my: 2 }} />
              <Grid
                container
                spacing={1}
                justifyContent="space-between"
                alignItems="center"
                sx={{ mb: 2 }}
              >
                <Grid item xs={6}>
                  <Button
                    variant={selectedTab === 0 ? "contained" : "outlined"}
                    size="small"
                    fullWidth
                    onClick={() => setSelectedTab(0)}
                  >
                    Product {xlUp && "List"}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant={selectedTab === 1 ? "contained" : "outlined"}
                    size="small"
                    fullWidth
                    onClick={() => setSelectedTab(1)}
                  >
                    Courier {xlUp && "List"}
                  </Button>
                </Grid>
              </Grid>
              {selectedTab === 1 ? (
                <EditCourierList
                  courierListEntries={courierListEntries}
                  setCourierListEntries={setCourierListEntries}
                />
              ) : (
                <EditProductList
                  productListEntries={productListEntries}
                  setProductListEntries={setProductListEntries}
                />
              )}
            </>
          ) : (
            <>
              <Typography
                sx={{ textTransform: "capitalize", textDecoration: "bold" }}
                color="text.primary"
                variant="h6"
              >
                {supplier.dealerName}
              </Typography>
              <Divider sx={{ my: 2 }} />
              <Box
                sx={{
                  mb: 2,
                  height: "610px",
                  overflowX: "hidden",
                  overflowY: "auto",
                }}
              >
                <Grid
                  container
                  spacing={1}
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mb: 2 }}
                >
                  <Grid item xs={6}>
                    <Button
                      variant={selectedTab === 0 ? "contained" : "outlined"}
                      size="small"
                      fullWidth
                      onClick={() => setSelectedTab(0)}
                    >
                      Product {xlUp && "List"}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant={selectedTab === 1 ? "contained" : "outlined"}
                      size="small"
                      fullWidth
                      onClick={() => setSelectedTab(1)}
                    >
                      Courier {xlUp && "List"}
                    </Button>
                  </Grid>
                </Grid>
                {selectedTab === 1 ? (
                  <CourierListEntry courierListEntries={supplier.courierList} />
                ) : (
                  <ProductListEntry productListEntries={supplier.productList} />
                )}
              </Box>
            </>
          )}
        </CardContent>
        <CardActions>
          <Grid container justifyContent="end" alignItems="end" spacing={2}>
            <Grid item>
              {editMode ? (
                <IconButton
                  color="error"
                  size="small"
                  onClick={() => setEditMode(false)}
                  disabled={loadingMain}
                >
                  <CancelIcon />
                </IconButton>
              ) : (
                <IconButton
                  color="error"
                  size="small"
                  onClick={() => setDeleteDlg(true)}
                >
                  <DeleteRoundedIcon />
                </IconButton>
              )}
            </Grid>
            <Grid item>
              {editMode ? (
                <IconButton
                  color="success"
                  size="small"
                  onClick={handleUpdateProductList}
                  disabled={loadingMain}
                >
                  <CheckCircleIcon />
                </IconButton>
              ) : (
                <IconButton
                  color="info"
                  size="small"
                  onClick={() => setEditMode(true)}
                >
                  <EditRoundedIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </CardActions>
      </Card>
      {/* Delete Database */}
      {deleteDlg && (
        <Dialog open={deleteDlg}>
          <DialogTitle>
            Do you really want to remove supplier{" "}
            <span style={{ color: "red", textTransform: "capitalize" }}>
              '{supplier.dealerName}'
            </span>
          </DialogTitle>

          <DialogActions>
            <Button
              onClick={() => {
                setDeleteDlg(false);
              }}
              color="primary"
            >
              Close
            </Button>
            <LoadingButton
              loading={loadingMain}
              onClick={handleRemoveSupplier}
              color="error"
            >
              Confirm
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default SupplierCard;
