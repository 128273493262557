import {
  Alert,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getBlogs } from "../../reduxToolkit/features/adminPanel/blog";
import BlogCard from "../../screens/adminPanel/blog/components/BlogCard";
import LoadingBox from "../misc/Loading";
import { Fragment } from "react";

export default function BlogSection() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loadingMain, setLoadingMain] = useState(false);
  const [blogList, setBlogList] = useState([]);
  const [blogListEn, setBlogListEn] = useState([]);

  const [lang, setLang] = useState("en");
  const [langId, setLangId] = useState("");

  const [viewMode, setViewMode] = useState([]);
  // Store
  const { webSettings } = useSelector((state) => state.webSettings);
  const { langs } = useSelector((state) => state.misc);
  const { blogs } = useSelector((state) => state.blog);
  const { activeLangFront } = useSelector((state) => state.miscFront);

  // Functions
  const getLangId = (langCode) => {
    let id = "";
    if (langs) {
      const L = langs.find((l) => l.langCode === langCode);
      if (L) id = L._id;
    }
    return id;
  };

  // const getEnInfo = (detectorId) => {
  //   let obj = {
  //     title: "",
  //   };
  //   const B = blogListEn.find((b) => b._id === detectorId);
  //   if (B) obj = { ...B };
  //   return obj;
  // };

  useEffect(() => {
    if (blogs && blogs.length > 0) {
      setLoadingMain(true);
      const B = blogs.filter((b) => b.langId === langId);
      if (B) {
        setBlogList(B);
      }
      const BEn = blogs.filter((b) => b.langId === getLangId("en"));
      if (BEn) setBlogListEn(BEn);
      if (blogs) {
        setTimeout(() => {
          setLoadingMain(false);
        }, 1000);
      }
    }
  }, [blogs, langId]);

  // Set Language
  useEffect(() => {
    if (activeLangFront) {
      setLang(activeLangFront.langCode);
      setLangId(activeLangFront._id);
    }
  }, [activeLangFront]);

  useEffect(() => {
    if (blogs && blogs.length < 1) dispatch(getBlogs());
  }, []);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate

  return (
    <>
      {loadingMain ? (
        <LoadingBox w={"100%"} h={"200px"} />
      ) : (
        <Card sx={{ mx: 1, minHeight: { xs: 1700, sm: 0 } }}>
          <CardContent>
            <Typography
              variant="h4"
              align="center"
              sx={{
                background: webSettings.siteColorTheme || "lightblue",
                fontSize: { xs: "1.8rem", sm: "2rem" },
                mb: 1,
                pb: 1,
              }}
            >
              {translateFront("Blogs")}{" "}
              <Button
                size="small"
                variant="outlined"
                color="inherit"
                onClick={() => navigate("/blog")}
              >
                {translateFront("View All")}
              </Button>
            </Typography>
            <Grid container spacing={1} sx={{ mt: 1 }}>
              {blogList.length < 1 ? (
                <Grid item xs={12}>
                  <Alert
                    severity="warning"
                    fullWidth
                    sx={{ justifyContent: "center" }}
                  >
                    {translateFront("No Blogs...")}
                  </Alert>
                </Grid>
              ) : (
                blogList.map((x, i) => (
                  <Fragment key={i}>
                    {i < 4 && (
                      <Grid
                        item
                        key={i}
                        xs={12}
                        md={viewMode.includes(x._id) ? 12 : 3}
                      >
                        <BlogCard
                          x={x}
                          blogListEn={blogListEn}
                          lang={lang}
                          frontEnd={true}
                          setViewMode={setViewMode}
                          viewMode={viewMode}
                          fromHome={true}
                        />
                      </Grid>
                    )}
                  </Fragment>
                ))
              )}
            </Grid>
          </CardContent>
        </Card>
      )}
    </>
  );
}
