import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../config";

// TODO Manage Rating SECTION ---------------------------------------------------------------
export const publishRating = createAsyncThunk(
  "customer-on-product/publish-rating",
  async (data) => {
    const res = await axiosInstance.post(
      "/api/customer-on-product/publish-rating",
      data
    );
    return res.data;
  }
);

export const getReviews = createAsyncThunk(
  "customer-on-product/getReview",
  async () => {
    const res = await axiosInstance.get("/api/customer-on-product/get-review");
    return res.data;
  }
);

export const getComplainScopes = createAsyncThunk(
  "customer-on-product/getReviewSettings",
  async () => {
    const res = await axiosInstance.get("/api/review-settings/get-scope");
    return res.data;
  }
);

// Submit Request
export const submitReq = createAsyncThunk("revReq/submitReq", async (data) => {
  const res = await axiosInstance.post(
    "/api/reviewer-req/submit-request",
    data
  );
  return res.data;
});

// Review Img Upload
export const uploadReviewImg = createAsyncThunk(
  "multer/uploadReviewImg",
  async (data) => {
    const res = await axiosInstance.post("/api/multer/singleReviewImg", data);
    return res.data;
  }
);

// Initial State
const initialState = {
  pending: false,
  checkPassResponse: {},
  error: false,
  publishRatingResponse: {},
  reviews: [],

  snackNotiCustomerOnProduct: {},
};
// Slice
export const customerOnProductSlice = createSlice({
  name: "customerOnProduct",
  initialState,
  reducers: {
    resetSnkCustomerOnProduct(state) {
      state.snackNotiCustomerOnProduct = {};
      state.publishRatingResponse = {};
    },
  },
  extraReducers: {
    // TODO Manage Rating SECTION ---------------------------------------------------------------
    [publishRating.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [publishRating.fulfilled]: (state, action) => {
      state.pending = false;
      state.publishRatingResponse = action.payload;
      state.snackNotiCustomerOnProduct = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [publishRating.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [getReviews.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getReviews.fulfilled]: (state, action) => {
      state.pending = false;
      state.reviews = action.payload;
    },
    [getReviews.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [getComplainScopes.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getComplainScopes.fulfilled]: (state, action) => {
      state.pending = false;
      state.complainScopes = action.payload.data;
    },
    [getComplainScopes.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [submitReq.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [submitReq.fulfilled]: (state, action) => {
      state.pending = false;
      state.snackNotiCustomerOnProduct = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [submitReq.rejected]: (state, action) => {
      state.pending = false;
      state.error = true;
    },

    [uploadReviewImg.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [uploadReviewImg.fulfilled]: (state) => {
      state.pending = false;
    },
    [uploadReviewImg.rejected]: (state, action) => {
      state.pending = false;
      state.error = true;
    },
  },
});
//
export const { resetSnkCustomerOnProduct } = customerOnProductSlice.actions;
export default customerOnProductSlice.reducer;
