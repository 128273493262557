import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {
  Alert,
  Autocomplete,
  Box,
  createFilterOptions,
  Grid,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  createDosage,
  getDosages,
} from "../../../../reduxToolkit/features/adminPanel/medicine";
import { getNotification } from "../../../../reduxToolkit/features/adminPanel/notification";
import LoadingButton from "@mui/lab/LoadingButton";

// Limit options to show in autocomplete
const OPTIONS_LIMIT = 3;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) => {
  return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
};

export default function AddDosageDialog({
  addDosageDlg,
  setAddDosageDlg,
  lang,
}) {
  const dispatch = useDispatch();

  const [addDosageLoading, setAddDosageLoading] = useState(false);
  const [addDosageError, setAddDosageError] = useState(false);
  const [dosageName, setDosageName] = useState("");
  const [dosageDetector, setDosageDetector] = useState("");
  const [dosageListEn, setDosageListEn] = useState([]);

  const [addButtonEnable, setAddButtonEnable] = useState(false);

  //   Local Data
  const localAdminData = JSON.parse(localStorage.getItem("adminLoginInfo"));

  //   Store
  const { dosages, createDosagesResponse } = useSelector(
    (state) => state.medicine
  );

  // function
  const handleDosageEnglishVariant = (e, newValue) => {
    setDosageDetector(newValue.fixedDosageId);
  };

  const handleCreateDosage = () => {
    setAddDosageLoading(true);
    const data = {
      dosageName,
      creatorId: localAdminData._id,
    };
    if (dosageDetector.length > 0) data.dosageDetectorId = dosageDetector;
    dispatch(createDosage({ lang, data }));
    setTimeout(() => {
      dispatch(getDosages());
      dispatch(getNotification());
    }, 1000);
  };

  // useEffect
  useEffect(() => {
    if (dosageName.length > 0) {
      if (lang !== "en" && dosageDetector.length < 1) setAddButtonEnable(false);
      else setAddButtonEnable(true);
    } else setAddButtonEnable(false);
  }, [dosageDetector.length, dosageName.length, lang]);

  useEffect(() => {
    if (lang !== "" && dosages && dosages.length > 0) {
      const D = dosages.find((d) => d.lang === "en");
      if (D) setDosageListEn(D.data);
      else setDosageListEn([]);
    }
  }, [dosages, lang]);

  //   Check Response
  useEffect(() => {
    if (createDosagesResponse.message) {
      if (createDosagesResponse.failed) {
        setAddDosageLoading(false);
        setAddDosageError(true);
      } else {
        setTimeout(() => {
          setAddDosageLoading(false);
          setAddDosageDlg(false);
        }, 1000);
      }
    }
  }, [createDosagesResponse]);

  // ! translate
  const [siteLang, setSiteLang] = useState("");
  // Local Store
  const localLangData = JSON.parse(localStorage.getItem("lang"));
  // Store
  const { langs, activeLang } = useSelector((state) => state.misc);
  const [langSettings1, setLangSettings1] = useState([]);
  const [langSettings2, setLangSettings2] = useState([]);
  const title1 = "Add Dosage Form Dialog";
  const title2 = "Misc Words";

  useEffect(() => {
    if (langs && langs.length > 0 && siteLang !== "") {
      const L = langs.find((l) => l.langCode === siteLang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      const A = arr.find((l) => l._title === title1);
      if (A) setLangSettings1(A.data);
      const B = arr.find((l) => l._title === title2);
      if (B) setLangSettings2(B.data);
    }
  }, [siteLang, langs]);

  const translate1 = (String) => translation(String, title1, 1);
  const translate2 = (String) => translation(String, title2, 2);

  const translation = (String, title, index) => {
    let translation = "";
    let settings = [];
    if (index === 1) {
      settings = langSettings1;
    } else if (index === 2) {
      settings = langSettings2;
    }
    const T = settings.find((ls) => ls.title === String);
    if (T && T.value) translation = T.value;
    else {
      let arr = [];
      if (langs && langs.length > 0 && siteLang !== "") {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.settings && LEn.settings.length > 0) arr = LEn.settings;
        const S = arr.find((l) => l._title === title);
        if (S) {
          const T = S.data.find((ls) => ls.title === String);
          if (T && T.value) translation = T.value;
        }
      }
    }
    return translation;
  };

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setSiteLang(activeLang.langCode);
    } else {
      if (localLangData) {
        setSiteLang(localLangData.langCode);
      }
    }
  }, [activeLang]);
  // ! translate

  return (
    <Dialog
      open={addDosageDlg}
      onClose={() => setAddDosageDlg()}
      fullWidth
      maxWidth={"sm"}
    >
      <DialogTitle>
        {translate1("Add")} {translate1("Dosage")}
      </DialogTitle>
      <DialogContent>
        {addDosageError ? (
          <Alert severity="error">Something Went Wrong...</Alert>
        ) : (
          <Grid container spacing={3} flexDirection="column" sx={{ pt: 2 }}>
            {lang !== "en" && (
              <Grid item>
                <Autocomplete
                  size="small"
                  onChange={(e, newValue) =>
                    handleDosageEnglishVariant(e, newValue)
                  }
                  disablePortal
                  options={dosageListEn}
                  filterOptions={filterOptions}
                  filterSelectedOptions
                  getOptionLabel={(option) => option.dosageName}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{
                        "& > img": { mr: 2, flexShrink: 0 },
                      }}
                      {...props}
                    >
                      {option.dosageName}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={translate1("English Variant")}
                      variant="outlined"
                      size="small"
                      fullWidth
                      required
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item>
              <TextField
                label={translate1("Dosage")}
                value={dosageName}
                onChange={(e) => setDosageName(e.target.value)}
                fullWidth
                variant="standard"
                size="small"
                required
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setAddDosageDlg(false)} color="error">
          {translate1("Cancel")}
        </Button>
        <LoadingButton
          onClick={handleCreateDosage}
          color="primary"
          disabled={!addButtonEnable ? true : false}
          loading={addDosageLoading ? true : false}
        >
          {translate1("Add")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
