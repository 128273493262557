export const paymentMedia = [
  {
    paymentMedia: "Bank",
    name: "bank",
    fields: [
      {
        title: "Name",
        value: "",
        order: -1,
      },
      {
        title: "Bank Name",
        value: "",
        order: 0,
      },
      {
        title: "IBAN",
        value: "",
        order: 1,
      },
      {
        title: "BIC/ SWIFT",
        value: "",
        order: 2,
      },
      {
        title: "Account Number",
        value: "",
        order: 3,
      },
      {
        title: "Routing Number",
        value: "",
        order: 4,
      },
      {
        title: "Bank IFSC",
        value: "",
        order: 5,
      },
      {
        title: "Business Address",
        value: "",
        order: 6,
      },
    ],
  },
  {
    paymentMedia: "Paypal",
    name: "paypal",
    fields: [
      {
        title: "Account Info",
        value: "",
        order: 0,
      },
      {
        title: "Transaction Id",
        value: "",
        order: 1,
      },
    ],
  },
  {
    paymentMedia: "Unistream",
    name: "unistream",
    fields: [
      {
        title: "Account Info",
        value: "",
        order: 0,
      },
      {
        title: "Transaction Id",
        value: "",
        order: 1,
      },
    ],
  },
  {
    paymentMedia: "Ali Pay",
    name: "alipay",
    fields: [
      {
        title: "Account Info",
        value: "",
        order: 0,
      },
      {
        title: "Transaction Id",
        value: "",
        order: 1,
      },
    ],
  },
  {
    paymentMedia: "Bkash",
    name: "bkash",
    fields: [
      {
        title: "Account Number",
        value: "",
        order: 0,
      },
      {
        title: "Transaction Id",
        value: "",
        order: 1,
      },
    ],
  },
  {
    paymentMedia: "Nagad",
    name: "nagad",
    fields: [
      {
        title: "Account Number",
        value: "",
        order: 0,
      },
      {
        title: "Transaction Id",
        value: "",
        order: 1,
      },
    ],
  },
  {
    paymentMedia: "Rocket",
    name: "rocket",
    fields: [
      {
        title: "Account Number",
        value: "",
        order: 0,
      },
      {
        title: "Transaction Id",
        value: "",
        order: 1,
      },
    ],
  },
  {
    paymentMedia: "Cash On Delivery",
    name: "cashOnDelivery",
    fields: [
      {
        title: "Address",
        value: "",
        order: 0,
      },
      {
        title: "Name",
        value: "",
        order: 1,
      },
      {
        title: "Phone",
        value: "",
        order: 1,
      },
    ],
  },
  {
    paymentMedia: "MoneyGram",
    name: "moneygram",
    fields: [
      {
        title: "Account Number",
        value: "",
        order: 0,
      },
      {
        title: "Transaction Id",
        value: "",
        order: 1,
      },
    ],
  },
  {
    paymentMedia: "Western Union",
    name: "westernUnion",
    fields: [
      {
        title: "Account Number",
        value: "",
        order: 0,
      },
      {
        title: "Transaction Id",
        value: "",
        order: 1,
      },
    ],
  },
  {
    paymentMedia: "Wechat Payment",
    name: "weChatPayment",
    fields: [
      {
        title: "Account Number",
        value: "",
        order: 0,
      },
      {
        title: "Transaction Id",
        value: "",
        order: 1,
      },
    ],
  },
  {
    paymentMedia: "Stripe",
    name: "stripePayment",
    fields: [
      {
        title: "Account Number",
        value: "",
        order: 0,
      },
      {
        title: "Transaction Id",
        value: "",
        order: 1,
      },
    ],
  },
];
