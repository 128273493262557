import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../config";

const localCompareListData = () =>
  JSON.parse(localStorage.getItem("localCompareListData"));

const localCustomerData = () => JSON.parse(localStorage.getItem("loginInfo"));

//   Shift localCompare data to customerdb
export const compareShiftLocalToCustomer = createAsyncThunk(
  "compare/shiftLocalToCustomer",
  async (customerId) => {
    let arr = [];
    if (localCompareListData()) {
      localCompareListData().forEach((x) => {
        let obj = { medId: x, customerId };
        arr.push(obj);
      });
    }
    const res = await axiosInstance.post("/api/compare/merge-with-local", arr);
    localStorage.removeItem("localCompareListData");
    return res.data;
  }
);

// Add to compare
export const addToCompare = createAsyncThunk(
  "compare/addToCompare",
  async (data) => {
    let arr = [data];
    // if (localCompareListData) {
    //   localCompareListData().forEach((x) => {
    //     let obj = { medId: x, customerId: data.customerId };
    //     arr.push(obj);
    //   });
    // }
    if (data.customerId) {
      const res = await axiosInstance.post("/api/compare/add-to-compare", arr);
      return res.data;
    } else {
      let arr = [];
      if (!localCompareListData()) {
        arr.push(data.medId);
      } else {
        arr = localCompareListData();
        arr.push(data.medId);
      }
      localStorage.setItem("localCompareListData", JSON.stringify(arr));
      return {
        message: "Added to compare list",
        severity: "success",
        data: arr,
      };
    }
  }
);

export const removeFromCompare = createAsyncThunk(
  "compare/removeFromCompare",
  async (data) => {
    if (data.customerId) {
      const res = await axiosInstance.post(
        "/api/compare/remove-from-compare",
        data
      );
      return res.data;
    } else {
      let arr = [...localCompareListData()];
      if (localCompareListData()) {
        arr = arr.filter((a) => a !== data.medId);
      }
      localStorage.setItem("localCompareListData", JSON.stringify(arr));
      return {
        message: "Removed from compare list",
        severity: "error",
        data: arr,
      };
    }
  }
);

export const getCompareList = createAsyncThunk(
  "compare/getCompareList",
  async () => {
    if (localCustomerData() && localCustomerData()._id) {
      const res = await axiosInstance.get(
        `/api/compare/get-compare-from-db/${localCustomerData()._id}`
      );
      return {
        message: "Fetched compare list",
        severity: "success",
        data: res.data,
      };
    } else {
      let arr = [];
      if (localCompareListData()) {
        arr = localCompareListData();
      }
      return {
        message: "Fetched compare list",
        severity: "success",
        data: [...arr],
      };
    }
  }
);

// Initial State
const initialState = {
  pending: false,
  error: false,
  compareList: [],
  snackNotiCompare: {},
};

// Slice
export const compareSlice = createSlice({
  name: "compare",
  initialState,
  reducers: {
    resetSnkCompare(state) {
      state.snackNotiCompare = {};
    },
  },
  extraReducers: {
    [compareShiftLocalToCustomer.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [compareShiftLocalToCustomer.fulfilled]: (state, action) => {
      state.pending = false;
      state.compareList = action.payload.data;
      //   state.snackNotiCompare = {
      //     message: action.payload.message,
      //     severity: action.payload.severity,
      //   };
    },
    [compareShiftLocalToCustomer.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [addToCompare.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [addToCompare.fulfilled]: (state, action) => {
      state.pending = false;
      state.compareList = action.payload.data;
      state.snackNotiCompare = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [addToCompare.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
    [removeFromCompare.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [removeFromCompare.fulfilled]: (state, action) => {
      state.pending = false;
      state.compareList = action.payload.data;
      state.snackNotiCompare = {
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
    [removeFromCompare.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },

    [getCompareList.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [getCompareList.fulfilled]: (state, action) => {
      state.pending = false;
      state.compareList = action.payload.data;
    },
    [getCompareList.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
  },
});
//
export const { resetSnkCompare } = compareSlice.actions;
export default compareSlice.reducer;
