import { Alert, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingBox from "../../components/misc/Loading";
import { getAllCustomers } from "../../reduxToolkit/features/auth";
import { getAllOrders } from "../../reduxToolkit/features/frontend/checkout";
import OrderCard from "./order/OrderCard";

export default function LatestOrders() {
  const dispatch = useDispatch();

  const [loadingMain, setLoadingMain] = useState(false);
  const [lang, setLang] = useState("");
  const [langId, setLangId] = useState("");
  const [orderList, setOrderList] = useState([]);

  // LocalData
  const localLangData = JSON.parse(localStorage.getItem("lang"));

  // Store
  const { orders } = useSelector((state) => state.checkout);
  const { langs, activeLang } = useSelector((state) => state.misc);
  // Store
  const { customers } = useSelector((state) => state.auth);

  // Use Effect
  useEffect(() => {
    if (!(orders && orders.length > 0)) dispatch(getAllOrders());
    if (!(customers && customers.length > 0)) dispatch(getAllCustomers());
  }, []);

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setLang(activeLang.langCode);
      setLangId(activeLang._id);
    } else {
      if (localLangData) {
        setLang(localLangData.langCode);
        setLangId(localLangData._id);
      }
    }
  }, [activeLang]);

  useEffect(() => {
    if (orders && orders.length > 0) {
      const O = orders.filter((o) => o.orderNumber);
      if (O && O.length > 0) setOrderList(O.slice(0, 2));
    }
  }, [orders]);

  useEffect(() => {
    setLoadingMain(true);
    setTimeout(() => {
      setLoadingMain(false);
    }, 1000);
  }, []);
  return (
    <Grid container spacing={1}>
      {loadingMain ? (
        <LoadingBox w={"100%"} />
      ) : orderList.length < 1 ? (
        <Grid item xs={12}>
          <Alert severity="warning" sx={{ justifyContent: "center" }}>
            No Orders...
          </Alert>
        </Grid>
      ) : (
        orderList.map((x, i) => (
          <Grid item key={i} xs={12} md={6}>
            <OrderCard x={x} lang={lang} />
          </Grid>
        ))
      )}
    </Grid>
  );
}
