import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {
  Alert,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
// Icon
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import {
  createLang,
  getLangs,
} from "../../../../reduxToolkit/features/adminPanel/misc";
export default function AddLangDialog({ addLangDlg, setAddLangDlg, setLang }) {
  const [loading, setLoading] = useState(false);
  const [formClicked, SetFormClicked] = useState(false);
  const [langCode, setLangCode] = useState("");
  const [langName, setLangName] = useState("");
  const [alert, setAlert] = useState("");

  const [langMain, setLangMain] = useState("");
  const [langId, setLangId] = useState("");

  // local Data
  const localData = JSON.parse(localStorage.getItem("adminLoginInfo"));
  const localLangData = JSON.parse(localStorage.getItem("lang"));

  // Store
  const { createLangResponse } = useSelector((state) => state.misc);
  const { langs, activeLang } = useSelector((state) => state.misc);

  const dispatch = useDispatch();
  useEffect(() => {
    if (formClicked) {
      if (createLangResponse.message && createLangResponse.failed) {
        setTimeout(() => {
          setLoading(false);
          setAlert(createLangResponse.message);
        }, 1000);
      } else {
        setTimeout(() => {
          setLoading(false);
          setAddLangDlg(false);
          setAlert("");
          setLangCode("");
          setLangName("");
          if (createLangResponse.langData) {
            localStorage.setItem(
              "lang",
              JSON.stringify(createLangResponse.langData)
            );
            setLang(createLangResponse.langData.langCode);
          }
          setTimeout(() => {
            dispatch(getLangs());
          }, 500);
        }, 1000);
      }
    }
  }, [createLangResponse, formClicked, setAddLangDlg]);

  // Functions
  const handleCreateLanguage = (e) => {
    setLoading(true);
    SetFormClicked(true);
    e.preventDefault();
    dispatch(
      createLang({
        langCode,
        langName,
        creatorId: localData._id,
        // _id: "62a0848ca2ad3bd3035130f2",
      })
    );
  };

  const handleLangCode = (e) => {
    setLangCode(e.target.value);
    setAlert("");
  };

  const handleLangName = (e) => {
    setLangName(e.target.value);
    setAlert("");
  };

  // translate
  const [langSettings, setLangSettings] = useState([]);
  const title1 = "Add Language Dialog";

  useEffect(() => {
    if (langs && langs.length > 0 && langMain !== "") {
      const L = langs.find((l) => l.langCode === langMain);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      const addLangDlg = arr.find((l) => l._title === title1);
      if (addLangDlg) setLangSettings(addLangDlg.data);
    }
  }, [langMain, langs]);

  const translate1 = (String) => translation(String, title1, 1);

  const translation = (String, title, index) => {
    let translation = "";
    let settings = [];
    if (index === 1) {
      settings = langSettings;
    }
    const T = settings.find((ls) => ls.title === String);
    if (T && T.value) translation = T.value;
    else {
      let arr = [];
      let lang = langMain;
      if (langs && langs.length > 0 && lang !== "") {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.settings && LEn.settings.length > 0) arr = LEn.settings;
        const dashboard = arr.find((l) => l._title === title);
        if (dashboard) {
          const T = dashboard.data.find((ls) => ls.title === String);
          if (T && T.value) translation = T.value;
        }
      }
    }
    return translation;
  };

  // Set Language
  useEffect(() => {
    if (activeLang && activeLang.langCode) {
      setLangMain(activeLang.langCode);
      setLangId(activeLang._id);
    } else {
      if (localLangData) {
        setLangMain(localLangData.langCode);
        setLangId(localLangData._id);
      }
    }
  }, [activeLang]);
  return (
    <Dialog
      open={addLangDlg}
      onClose={() => setAddLangDlg(false)}
      aria-labelledby={"add-lang-dlg"}
    >
      <DialogTitle>{translate1("Add Language Dialog")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <form onSubmit={handleCreateLanguage}>
            <FormControl variant="standard" fullWidth required>
              <InputLabel htmlFor="input-with-icon-adornment">
                {translate1("Language Code")}
              </InputLabel>
              <Input
                id="input-with-icon-adornment"
                onChange={handleLangCode}
                value={langCode}
                startAdornment={
                  <InputAdornment position="start">
                    <img
                      src={`https://flagcdn.com/w20/${
                        langCode === "en" ? "gb" : langCode
                      }.png`}
                      alt="Flag"
                    />
                  </InputAdornment>
                }
              />
            </FormControl>
            <TextField
              label={translate1("Language Name")}
              variant="standard"
              required
              fullWidth
              onChange={handleLangName}
            />
            {alert !== "" && <Alert severity="warning">{alert}</Alert>}

            <LoadingButton
              type="submit"
              variant="contained"
              size="small"
              fullWidth
              loading={loading}
              endIcon={<AddIcon />}
              sx={{ my: 1 }}
            >
              {translate1("Create Language")}
            </LoadingButton>
          </form>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setAddLangDlg(false)} color="error">
          {translate1("Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
