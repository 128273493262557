import { Container, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

// Icons
import CircleIcon from "@mui/icons-material/Circle";

export default function HowToOrder() {
  const [contentSettings, setContentSettings] = useState([]);
  const [langId, setLangId] = useState("");

  // Store
  const { managedContentSettings } = useSelector(
    (state) => state.managedContentSettings
  );
  const { activeLangFront } = useSelector((state) => state.miscFront);

  // Set Language
  useEffect(() => {
    if (activeLangFront) setLangId(activeLangFront._id);
  }, [activeLangFront]);

  // Set Settings
  useEffect(() => {
    if (
      managedContentSettings &&
      managedContentSettings.length > 0 &&
      langId !== ""
    ) {
      const M = managedContentSettings.find((m) => m.langId === langId);
      if (M) setContentSettings(M);
    }
  }, [langId, managedContentSettings]);
  return (
    <>
      <Container>
        {contentSettings.howToOrderSteps &&
          contentSettings.howToOrderSteps.length > 0 &&
          contentSettings.howToOrderSteps.map((x, i) => (
            <Typography
              variant="subtitle1"
              color="initial"
              sx={{ my: 1 }}
              key={i}
            >
              <IconButton size="small">
                <CircleIcon color="secondary" />
              </IconButton>
              {x}
            </Typography>
          ))}
      </Container>
    </>
  );
}
