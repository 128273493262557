import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Helmet } from "react-helmet-async";
import {
  Autocomplete,
  Avatar,
  Badge,
  Button,
  createFilterOptions,
  Grid,
  TextField,
  Dialog,
  Snackbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogContent,
} from "@mui/material";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { adminMenuList } from "./data/adminMenuList";
import Administration from "./administration/Administration";
import AdminHome from "./AdminHome";
import Profile from "./administration/dilogs/Profile";
import NotificationDlg from "./administration/dilogs/NotificationDlg";
import { useDispatch, useSelector } from "react-redux";
import { getNotification } from "../../reduxToolkit/features/adminPanel/notification";
import { getSingleAdmin } from "../../reduxToolkit/features/adminPanel/admin";
import { PF } from "../../config";
import AdminSignIn from "./AdminSIgnIn";
import SnackNotification from "../../components/misc/SnackNotification";
import MedicinesScreen from "./medicines/MedicinesScreen";
import {
  changeLang,
  getLangs,
} from "../../reduxToolkit/features/adminPanel/misc";
import { LazyLoadImage } from "react-lazy-load-image-component";
import SupplierScreen from "./Supplier/SupplierScreen";
import WebSettingsScreen from "../webSettings/WebSettingsScreen";
import CustomersScreen from "../customers/CustomersScreen";
import ReviewScreen from "./review/ReviewScreen";
import DeliveryScreen from "./delivery/DeliveryScreen";
import OrderScreen from "./order/OrderScreen";
import PaymentScreen from "./payment/PaymentScreen";
import ReportScreen from "./report/ReportScreen";
import BlogScreen from "./blog/BlogScreen";
import ExpenseCalcScreen from "./expense/ExpenseCalcScreen";
import { useState } from "react";
import { useEffect } from "react";
import LangImport from "./LangImport";
import PreviewLeafletPage from "./medicines/components/leaflet/PreviewLeafletPage";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

// Avatar
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
}));

// Limit options to show in autocomplete
const OPTIONS_LIMIT = 3;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) => {
  return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
};

export default function DashBoard({ adminUrl }) {
  // Responsive
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  const [open, setOpen] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState("");
  const [loginStatus, setLoginStatus] = React.useState(false);
  const [lang, setLang] = React.useState("en");

  // SnackBar
  const [openSnk, setOpenSnk] = React.useState(false);
  const [snkMessage, setSnkMessage] = React.useState("");
  const [snkSeverity, setSnkSeverity] = React.useState("");

  // Dialogs
  const [profileDlg, setProfileDlg] = React.useState(false);
  const [notificationDlg, setNotificationDlg] = React.useState(false);
  const [languageDlg, setLanguageDlg] = React.useState(false);

  // Notification
  const [unreadNotifications, setUnreadNotifications] = React.useState([]);

  // localData
  const localData = JSON.parse(localStorage.getItem("adminLoginInfo"));
  const localLangData = JSON.parse(localStorage.getItem("lang"));

  // store
  const { notifications, error, snackNoti } = useSelector(
    (state) => state.notification
  );
  const { snackNotiRole } = useSelector((state) => state.role);
  const { singleAdmin, updateAdminResponse, snackNotiAdmin } = useSelector(
    (state) => state.admin
  );
  const { snackNotiMisc } = useSelector((state) => state.misc);
  const { snackNotiAssignPrice } = useSelector((state) => state.assignPrice);
  const { snackNotiMedicine } = useSelector((state) => state.medicine);
  const { snackNotiSupplier } = useSelector((state) => state.supplier);
  const { snackNotiReview } = useSelector((state) => state.review);
  const { snackNotiOrderBackend } = useSelector((state) => state.order);
  const { snackNotiDelivery } = useSelector((state) => state.delivery);
  const { snackNotiPayment } = useSelector((state) => state.payment);
  const { snackNotiWebSettings } = useSelector((state) => state.webSettings);
  const { snackNotiManagedContentSettings } = useSelector(
    (state) => state.managedContentSettings
  );
  const { snackNotiBlog } = useSelector((state) => state.blog);
  const { snackNotiCustomerBackend } = useSelector((state) => state.auth);
  const { snackExpense } = useSelector((state) => state.expense);
  const { langs } = useSelector((state) => state.misc);

  // UseEffect
  const dispatch = useDispatch();
  // snackbar
  React.useEffect(() => {
    if (snackNotiRole && snackNotiRole.message && snackNoti) {
      setOpenSnk(true);
      setSnkMessage(snackNotiRole.message);
      setSnkSeverity(snackNotiRole.severity);
    }
    if (snackNotiAdmin && snackNotiAdmin.message && snackNoti) {
      setOpenSnk(true);
      setSnkMessage(snackNotiAdmin.message);
      setSnkSeverity(snackNotiAdmin.severity);
    }
    if (snackNotiMisc && snackNotiMisc.message && snackNoti) {
      setOpenSnk(true);
      setSnkMessage(snackNotiMisc.message);
      setSnkSeverity(snackNotiMisc.severity);
    }
    if (snackNotiMedicine && snackNotiMedicine.message && snackNoti) {
      setOpenSnk(true);
      setSnkMessage(snackNotiMedicine.message);
      setSnkSeverity(snackNotiMedicine.severity);
    }
    if (snackNotiSupplier && snackNotiSupplier.message && snackNoti) {
      setOpenSnk(true);
      setSnkMessage(snackNotiSupplier.message);
      setSnkSeverity(snackNotiSupplier.severity);
    }
    if (snackNotiReview && snackNotiReview.message && snackNoti) {
      setOpenSnk(true);
      setSnkMessage(snackNotiReview.message);
      setSnkSeverity(snackNotiReview.severity);
    }

    if (snackNotiOrderBackend && snackNotiOrderBackend.message && snackNoti) {
      setOpenSnk(true);
      setSnkMessage(snackNotiOrderBackend.message);
      setSnkSeverity(snackNotiOrderBackend.severity);
    }

    if (snackNotiDelivery && snackNotiDelivery.message && snackNoti) {
      setOpenSnk(true);
      setSnkMessage(snackNotiDelivery.message);
      setSnkSeverity(snackNotiDelivery.severity);
    }
    if (snackNotiPayment && snackNotiPayment.message && snackNoti) {
      setOpenSnk(true);
      setSnkMessage(snackNotiPayment.message);
      setSnkSeverity(snackNotiPayment.severity);
    }

    if (snackNotiWebSettings && snackNotiWebSettings.message && snackNoti) {
      setOpenSnk(true);
      setSnkMessage(snackNotiWebSettings.message);
      setSnkSeverity(snackNotiWebSettings.severity);
    }

    if (
      snackNotiManagedContentSettings &&
      snackNotiManagedContentSettings.message &&
      snackNoti
    ) {
      setOpenSnk(true);
      setSnkMessage(snackNotiManagedContentSettings.message);
      setSnkSeverity(snackNotiManagedContentSettings.severity);
    }
    if (snackNotiAssignPrice && snackNotiAssignPrice.message && snackNoti) {
      setOpenSnk(true);
      setSnkMessage(snackNotiAssignPrice.message);
      setSnkSeverity(snackNotiAssignPrice.severity);
    }
    if (snackNotiBlog && snackNotiBlog.message && snackNoti) {
      setOpenSnk(true);
      setSnkMessage(snackNotiBlog.message);
      setSnkSeverity(snackNotiBlog.severity);
    }
    if (
      snackNotiCustomerBackend &&
      snackNotiCustomerBackend.message &&
      snackNoti
    ) {
      setOpenSnk(true);
      setSnkMessage(snackNotiCustomerBackend.message);
      setSnkSeverity(snackNotiCustomerBackend.severity);
    }
    if (snackExpense && snackExpense.message && snackNoti) {
      setOpenSnk(true);
      setSnkMessage(snackExpense.message);
      setSnkSeverity(snackExpense.severity);
    }
  }, [
    snackNoti,
    snackNotiAdmin,
    snackNotiRole,
    snackNotiMisc,
    snackNotiMedicine,
    snackNotiSupplier,
    snackNotiReview,
    snackNotiDelivery,
    snackNotiPayment,
    snackNotiWebSettings,
    snackNotiManagedContentSettings,
    snackNotiAssignPrice,
    snackNotiOrderBackend,
    snackNotiBlog,
    snackNotiCustomerBackend,
    snackExpense,
  ]);

  // check localdata to see if logged in
  React.useEffect(() => {
    localData && setLoginStatus(true);
  }, []);

  // handle local data after update
  React.useEffect(() => {
    updateAdminResponse &&
      updateAdminResponse._id &&
      dispatch(getSingleAdmin(updateAdminResponse._id));
  }, [updateAdminResponse]);

  React.useEffect(() => {
    if (singleAdmin._id && singleAdmin._id === localData._id) {
      localStorage.setItem("adminLoginInfo", JSON.stringify(singleAdmin));
      setLoginStatus(true);
    }
  }, [singleAdmin]);

  // get notification
  React.useEffect(() => {
    dispatch(getNotification());
    dispatch(getLangs());
    localData && localData._id && dispatch(getSingleAdmin(localData._id));
  }, []);

  React.useEffect(() => {
    dispatch(getNotification());
  }, [loginStatus]);

  React.useEffect(() => {
    if (notifications && localData && notifications.length > 0) {
      setUnreadNotifications(
        notifications.filter(
          (item) => !item.adminsWhoRead.includes(localData._id)
        )
      );
    }
  }, [notifications]);

  // Setting Menu
  var currentUrl = window.location.href;

  // useEffect
  React.useEffect(() => {
    adminMenuList.map((x, i) => {
      return currentUrl.includes(x.link) && setActiveTab(x.link);
    });
  }, [currentUrl]);

  // Setting Lang From LocalData
  React.useEffect(() => {
    if (localLangData) {
      setLang(localLangData.langCode);
    } else {
      if (langs.length > 0) {
        const L = langs.find((l) => l.langCode === "en");
        localStorage.setItem("lang", JSON.stringify(L));
      }
    }
  }, [langs, localLangData]);

  // Functions
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();
  const handleAdminMenu = (link) => {
    setActiveTab(link);
    navigate(`/${adminUrl}/${link}`);
  };
  const handleAdminHome = () => {
    setActiveTab("");
    navigate(`/${adminUrl}`);
  };

  // Use Effect
  React.useEffect(() => {
    !smUp && handleDrawerClose();
  }, [smUp]);

  // Functions
  const handleAdminProfile = () => {
    setProfileDlg(true);
  };

  // Setting Language
  const handleLanguageChange = (e) => {
    setLang(e.target.value);
    const L = langs.find((l) => l.langCode === e.target.value);
    localStorage.setItem("lang", JSON.stringify(L));
    dispatch(changeLang(L));
    setLanguageDlg(false);
  };

  // translate
  const [langSettings, setLangSettings] = useState([]);
  const titleDashboard = "Dashboard Top Bar";

  useEffect(() => {
    if (langs && langs.length > 0 && lang !== "") {
      const L = langs.find((l) => l.langCode === lang);
      let arr = [];
      if (L && L.langSettings && L.langSettings.length > 0)
        arr = L.langSettings;
      else {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
      }
      const dashboard = arr.find((l) => l._title === titleDashboard);
      if (dashboard) setLangSettings(dashboard.data);
    }
  }, [lang, langs]);

  const [menuLoading, setMenuLoading] = useState(false);
  const handleMenuChange = (e, newValue) => {
    setMenuLoading(true);
    navigate(`/${adminUrl}/${newValue.link}`);
    setTimeout(() => {
      setMenuLoading(false);
    }, 100);
  };

  const getAdminMenuName = (activeTabLink) => {
    let str = "";
    const M = adminMenuList.find((m) => m.link === activeTabLink);
    if (M && M.name) {
      str = translate0(M.name);
    }
    return str;
  };

  const translate = (String) => {
    let translation = "";
    const T = langSettings.find((ls) => ls.title === String);
    if (T && T.value) translation = T.value;
    else {
      let arr = [];
      if (langs && langs.length > 0 && lang !== "") {
        const LEn = langs.find((l) => l.langCode === "en");
        if (LEn && LEn.langSettings && LEn.langSettings.length > 0)
          arr = LEn.langSettings;
        const dashboard = arr.find((l) => l._title === titleDashboard);
        if (dashboard) {
          const T = dashboard.data.find((ls) => ls.title === String);
          if (T && T.value) translation = T.value;
        }
      }
    }
    return translation;
  };

  //! Translate
  // Store
  const { langArrFromClientSide } = useSelector((state) => state.misc);
  const translate0 = (String) => {
    let translation = "";
    if (langArrFromClientSide && langArrFromClientSide.length > 0) {
      const T = langArrFromClientSide.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      } else if (T === undefined) translation = String;
    }
    return translation;
  };
  //! Translate

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {translate("Dashboard")} |{" "}
          {activeTab && activeTab !== ""
            ? getAdminMenuName(activeTab).toUpperCase()
            : translate("MFW")}
        </title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      <LangImport front={false} />
      {langs.length > 0 && (
        <>
          {!loginStatus ? (
            <AdminSignIn setLoginStatus={setLoginStatus} />
          ) : (
            <Box sx={{ display: "flex" }}>
              <CssBaseline />
              <AppBar position="fixed" open={open}>
                <Toolbar>
                  {smUp && (
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={handleDrawerOpen}
                      edge="start"
                      sx={{
                        marginRight: 5,
                        ...(open && { display: "none" }),
                      }}
                    >
                      <MenuIcon />
                    </IconButton>
                  )}
                  <Grid
                    container
                    spacing={0}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {smUp && (
                      <Grid item sm={3} md={1.5}>
                        <Box
                          sx={{
                            cursor: "pointer",
                          }}
                        >
                          <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            onClick={handleAdminHome}
                          >
                            {translate("Dashboard")}
                          </Typography>
                        </Box>
                      </Grid>
                    )}
                    {/* Search Bar */}

                    <Grid item xs={5.5} sm={5} md={8.5}>
                      {!menuLoading && (
                        <Autocomplete
                          disablePortal
                          options={adminMenuList}
                          onChange={(e, newValue) =>
                            handleMenuChange(e, newValue)
                          }
                          // filterOptions={filterOptions}
                          getOptionLabel={(option) =>
                            ` ${
                              lang !== "en"
                                ? translate0(option.name) +
                                  " (" +
                                  option.name +
                                  ")"
                                : option.name
                            }`
                          }
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              <img
                                loading="lazy"
                                width="20"
                                src={option.icon}
                                alt="admins"
                              />
                              {`${
                                lang !== "en"
                                  ? translate0(option.name) +
                                    " (" +
                                    option.name +
                                    ")"
                                  : option.name
                              }`}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={translate("Search") + "..."}
                              variant="outlined"
                              size="small"
                              fullWidth
                            />
                          )}
                        />
                      )}
                    </Grid>

                    <Grid item xs={6} sm={4} md={2}>
                      <Grid
                        container
                        spacing={0}
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Grid item>
                          <Link
                            to="/"
                            style={{ textDecoration: "none" }}
                            target="_blank"
                          >
                            {!smUp ? (
                              <IconButton
                                size="small"
                                sx={{
                                  border: "1px solid white",
                                  borderRadius: ".25rem",
                                }}
                              >
                                <AutorenewIcon />
                              </IconButton>
                            ) : (
                              <Button
                                variant="contained"
                                color="error"
                                size="small"
                              >
                                {translate("Frontend")}
                              </Button>
                            )}
                          </Link>
                        </Grid>
                        {/* Language */}
                        <Grid item>
                          <IconButton
                            onClick={() => setLanguageDlg(true)}
                            size="small"
                          >
                            {lang.toUpperCase()}
                          </IconButton>
                        </Grid>
                        <Dialog
                          open={languageDlg}
                          onClose={() => setLanguageDlg(false)}
                          sx={{
                            "& .MuiDialog-container": {
                              justifyContent: "flex-end",
                              alignItems: "flex-start",
                            },
                          }}
                        >
                          <DialogContent>
                            <FormControl fullWidth size="small">
                              <InputLabel>Language</InputLabel>
                              <Select
                                value={lang}
                                label="lang"
                                variant="standard"
                                onChange={handleLanguageChange}
                              >
                                {langs.length > 0 &&
                                  langs.map((x, i) => (
                                    <MenuItem key={i} value={x.langCode}>
                                      <img
                                        src={`https://flagcdn.com/w20/${
                                          x.langCode === "en"
                                            ? "gb"
                                            : x.langCode
                                        }.png`}
                                        alt={x.langName}
                                      />
                                      &nbsp;
                                      {"   "}
                                      {x.langName
                                        ? x.langName.toUpperCase()
                                        : ""}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </DialogContent>
                        </Dialog>
                        {/* Notification */}
                        <Grid item>
                          <IconButton
                            size="small"
                            onClick={() => setNotificationDlg(true)}
                          >
                            <Badge
                              badgeContent={
                                unreadNotifications &&
                                unreadNotifications.length
                              }
                              color="error"
                            >
                              <NotificationsNoneIcon />
                            </Badge>
                          </IconButton>
                        </Grid>
                        <Grid item>
                          {/* Notification Dialog */}
                          {notificationDlg && (
                            <Dialog
                              open={notificationDlg}
                              onClose={() => setNotificationDlg(false)}
                              sx={{
                                "& .MuiDialog-container": {
                                  justifyContent: "flex-end",
                                  alignItems: "flex-start",
                                },
                              }}
                            >
                              <NotificationDlg
                                unreadNotifications={unreadNotifications}
                                notifications={notifications}
                                error={error}
                                setNotificationDlg={setNotificationDlg}
                                adminUrl={adminUrl}
                              />
                            </Dialog>
                          )}
                          {/* Profile */}
                          <IconButton size="small" onClick={handleAdminProfile}>
                            <StyledBadge
                              overlap="circular"
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              variant="dot"
                            >
                              <SmallAvatar
                                alt={localData.adminName}
                                src={
                                  localData.adminPic
                                    ? localData.adminPic.includes("http")
                                      ? localData.adminPic
                                      : PF +
                                        "adminPanel/admins/" +
                                        localData.adminPic
                                    : "https://cdn2.iconfinder.com/data/icons/picons-basic-1/57/basic1-112_user_add_new-512.png"
                                }
                              />
                            </StyledBadge>
                          </IconButton>
                          {/* Profile Dialog */}
                          <Dialog
                            open={profileDlg}
                            onClose={() => setProfileDlg(false)}
                            // fullWidth
                            // maxWidth={"xs"}
                            sx={{
                              "& .MuiDialog-container": {
                                justifyContent: "flex-end",
                                alignItems: "flex-start",
                              },
                            }}
                          >
                            <Profile
                              setLoginStatus={setLoginStatus}
                              adminUrl={adminUrl}
                              setProfileDlg={setProfileDlg}
                            />
                          </Dialog>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Toolbar>
              </AppBar>
              <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                  <IconButton onClick={handleDrawerClose}>
                    {theme.direction === "rtl" ? (
                      <ChevronRightIcon />
                    ) : (
                      <ChevronLeftIcon />
                    )}
                  </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                  {adminMenuList.map((x, index) => (
                    <ListItem
                      key={index}
                      disablePadding
                      selected={activeTab === x.link ? true : false}
                      sx={{ display: "block" }}
                      onClick={() => handleAdminMenu(x.link)}
                    >
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={x.icon}
                            alt={x.name}
                            style={{ height: "25px", p: 1 }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={lang !== "en" ? translate0(x.name) : x.name}
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Drawer>
              <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
                {/* Routes */}
                <Routes>
                  <Route index element={<AdminHome />} />
                  <Route path="/administration" element={<Administration />} />
                  <Route
                    path="/customer"
                    element={<CustomersScreen adminUrl={adminUrl} />}
                  />
                  <Route path="/medicines" element={<MedicinesScreen />} />
                  <Route path="/review" element={<ReviewScreen />} />
                  <Route path="/orders" element={<OrderScreen />} />
                  <Route path="/delivery" element={<DeliveryScreen />} />
                  <Route path="/payment" element={<PaymentScreen />} />
                  <Route path="/reports" element={<ReportScreen />} />
                  <Route path="/blog" element={<BlogScreen />} />
                  <Route path="/supplier" element={<SupplierScreen />} />
                  <Route path="/expense-calc" element={<ExpenseCalcScreen />} />
                  <Route path="/web-settings" element={<WebSettingsScreen />} />
                </Routes>
              </Box>
            </Box>
          )}
        </>
      )}
      <SnackNotification
        message={snkMessage}
        setMessage={setSnkMessage}
        severity={snkSeverity}
        setSeverity={setSnkSeverity}
        openSnk={openSnk}
        setOpenSnk={setOpenSnk}
      />
    </>
  );
}
