import * as React from "react";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import {
  getDosages,
  getMedicines,
} from "../../reduxToolkit/features/adminPanel/medicine";
import { getCartInfoByCustomer } from "../../reduxToolkit/features/frontend/cart";
import { Divider, ListItemIcon } from "@mui/material";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { PF } from "../../config";
import getSymbolFromCurrency from "currency-symbol-map";
import { currencyFormatter, getDecimal } from "../../hooks/currencyFormatter";
import LoadingBox from "../misc/Loading";
import { getAllDeliveryMethods } from "../../reduxToolkit/features/adminPanel/delivery";
import { getAllPaymentMethods } from "../../reduxToolkit/features/adminPanel/payment";

export default function OrderReview({ addressFormObj, paymentData }) {
  const dispatch = useDispatch();

  // useState
  const [cartList, setCartList] = React.useState([]);
  const [loadingMain, setLoadingMain] = React.useState(false);

  // Currency
  const [exchangeRate, setExchangeRate] = React.useState(1);
  const [currencyName, setCurrencyName] = React.useState("USA");

  // Local Store
  const localCustomerData = () => {
    const c = JSON.parse(localStorage.getItem("loginInfo"));
    return c ? c : {};
  };

  const localLangData = () => {
    const l = JSON.parse(localStorage.getItem("langFront"));
    return l ? l : {};
  };

  // Store
  const { medicines, medVariants, dosages } = useSelector(
    (state) => state.medicine
  );
  const { carts } = useSelector((state) => state.cart);
  const { activeCurrencyFront } = useSelector((state) => state.miscFront);
  // Store
  const { deliveryMethods } = useSelector((state) => state.delivery);
  const { paymentMethods } = useSelector((state) => state.payment);

  // Functions
  //   Functions
  const getDosageInfo = (dosageId, langCode) => {
    let dosageName = "";
    if (dosages && dosages.length > 0) {
      const dosageList = dosages.find((d) => d.lang === langCode);
      if (dosageList && dosageList.data) {
        const D = dosageList.data.find((d) =>
          langCode !== "en" ? d.dosageDetectorId : d.fixedDosageId === dosageId
        );
        if (D && D.dosageName) dosageName = D.dosageName;
      }
    }
    return dosageName;
  };

  const getVariantInfo = (varId) => {
    let obj = {
      dosage: "",
      strength: "",
      packTypes: "",
    };
    if (medVariants && medVariants.length > 0) {
      const V = medVariants.find(
        (v) =>
          (localLangData().langCode !== "en"
            ? v.variantDetectorId
            : v.fixedVariantId) === varId
      );
      if (V) {
        obj.dosage = getDosageInfo(V.dosageForm, localLangData().langCode);
        obj.strength = V.strength;
        obj.packTypes = V.packTypes;
      }
    }
    return obj;
  };

  const getMedInfo = (medId) => {
    let obj = {
      medImg: "",
      genericBrand: "",
    };
    if (medicines && medicines.length > 0) {
      const M = medicines.find(
        (m) => m.lang === (localLangData().langCode || "en")
      );
      if (M && M.data) {
        const Med = M.data.find(
          (med) =>
            (localLangData().langCode !== "en"
              ? med.medicineDetectorId
              : med.fixedMedicineId) === medId
        );
        if (Med) {
          obj.genericBrand = Med.genericBrand;
          if (Med.fixedMedicineId) obj.fixedMedicineId = Med.fixedMedicineId;
          if (Med.medicineDetectorId)
            obj.medicineDetectorId = Med.medicineDetectorId;
          const medImg = Med.image.find((i) => i.default);
          if (medImg) obj.medImg = medImg;
        }
      }
    }
    return obj;
  };

  const getTotalPrice = () => {
    let total = 0;
    cartList.forEach((c, i) => {
      total += Number(c.price) * exchangeRate * Number(c.moq);
    });
    total = Math.ceil(total.toFixed(2));
    return total;
  };

  const getDeliveryMethod = (deliveryMethodId) => {
    if (deliveryMethods.length > 0) {
      let methodName = "";
      const D = deliveryMethods.find((d) => d._id === deliveryMethodId);
      if (D) methodName = D.methodName;
      return methodName;
    }
  };

  const getPaymentMethod = (paymentMethodId) => {
    let obj = { methodName: "", transactionUnit: 0 };
    if (paymentMethods.length) {
      const P = paymentMethods.find((p) => p._id === paymentMethodId);
      if (P) {
        obj = P;
      }
    }
    console.log(obj);
    return obj;
  };

  // set currency
  React.useEffect(() => {
    setExchangeRate(activeCurrencyFront.exchangeRate);
    setCurrencyName(activeCurrencyFront.currencyName);
  }, [activeCurrencyFront]);

  React.useEffect(() => {
    // if (!(medicines && medicines.length > 0)) dispatch(getMedicines());
    if (localCustomerData()._id) {
      dispatch(getCartInfoByCustomer(localCustomerData()._id));
    }
    if (!(dosages && dosages.length > 0)) dispatch(getDosages());
    if (!(deliveryMethods && deliveryMethods.length > 0))
      dispatch(getAllDeliveryMethods());
    if (!(paymentMethods && paymentMethods.length > 0))
      dispatch(getAllPaymentMethods());
  }, []);

  React.useEffect(() => {
    setLoadingMain(true);
    if (carts && carts.length > 0) {
      setCartList(carts);
    } else {
      setCartList([]);
    }
    setTimeout(() => {
      setLoadingMain(false);
    }, 1000);
  }, [carts]);

  //! Translate
  // Store
  const { langArrFromClientSideFrontend } = useSelector(
    (state) => state.miscFront
  );
  const translateFront = (String) => {
    let translation = "";
    if (
      langArrFromClientSideFrontend &&
      langArrFromClientSideFrontend.length > 0
    ) {
      const T = langArrFromClientSideFrontend.find((l) => l.title === String);
      if (T && T.value) {
        let obj = { ...T };
        if (T.value === "") obj.value = T.title;
        translation = T.value;
      }
    }
    return translation;
  };
  //! Translate
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {translateFront("Order Summary")}
      </Typography>
      <List disablePadding>
        {loadingMain ? (
          <LoadingBox w={"100%"} />
        ) : (
          cartList.map((x, i) => (
            <>
              <ListItem key={i} sx={{ py: 1, px: 0 }}>
                <ListItemIcon
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LazyLoadImage
                    src={
                      getMedInfo(x.medId).medImg.src !== ""
                        ? PF +
                          "adminPanel/medicines/img/" +
                          getMedInfo(x.medId).medImg.src
                        : PF + "adminPanel/medicines/no-medicine-image.jpg"
                    }
                    alt={getMedInfo(x.medId).medImg.alt}
                    effect="blur"
                    placeholderSrc="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png"
                    style={{
                      objectFit: "stretch",
                      height: 30,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="body2"
                      color="initial"
                      sx={{ fontSize: ".8rem" }}
                    >
                      {getMedInfo(x.medId).genericBrand.toUpperCase()}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      variant="body2"
                      color="inherit"
                      sx={{ fontSize: ".7rem", color: "grey", fontWeight: 100 }}
                    >
                      {" (" +
                        getVariantInfo(x.variantId).dosage +
                        " - " +
                        getVariantInfo(x.variantId).strength +
                        " - " +
                        getVariantInfo(x.variantId).packTypes +
                        ") "}
                    </Typography>
                  }
                />
                <Typography variant="body2" align="right">
                  <span
                    style={{
                      fontSize: ".8rem",
                      fontWeight: 100,
                      color: "grey",
                    }}
                  >
                    {getSymbolFromCurrency(currencyName)}{" "}
                    {currencyFormatter(
                      currencyName,
                      Math.ceil(Number(x.price * exchangeRate))
                    )}{" "}
                    <span style={{ color: "black", fontWeight: 300 }}>
                      {" "}
                      X {x.moq}
                    </span>
                  </span>
                  <br /> {getSymbolFromCurrency(currencyName)}{" "}
                  {currencyFormatter(
                    currencyName,
                    Math.ceil(Number(x.price) * exchangeRate * Number(x.moq))
                  )}
                </Typography>
              </ListItem>
              <Divider />
            </>
          ))
        )}
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary={translateFront("Total")} />
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
            {getSymbolFromCurrency(currencyName)}{" "}
            {currencyFormatter("USA", getTotalPrice())}
          </Typography>
        </ListItem>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary={translateFront("Shipping Cost")} />
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
            {getSymbolFromCurrency(currencyName)}{" "}
            {currencyFormatter(
              "USA",
              Math.round(Number(addressFormObj.shippingCost) * exchangeRate)
            )}
          </Typography>
        </ListItem>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary={translateFront("Transaction Fee")} />
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
            {getSymbolFromCurrency(currencyName)}{" "}
            {getPaymentMethod(paymentData.selectedPaymentMethod) &&
            getPaymentMethod(paymentData.selectedPaymentMethod)
              .transactionUnit === 0
              ? (
                  getTotalPrice() *
                  Number(
                    getPaymentMethod(paymentData.selectedPaymentMethod)
                      .transactionFee / 100
                  )
                ).toFixed(2) +
                "( " +
                getPaymentMethod(paymentData.selectedPaymentMethod)
                  .transactionFee +
                "% )"
              : currencyFormatter(
                  currencyName,
                  (
                    Number(
                      getPaymentMethod(paymentData.selectedPaymentMethod)
                        .transactionFee
                    ) * exchangeRate
                  ).toFixed(0)
                ) + "( flat )"}
          </Typography>
        </ListItem>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary={translateFront("Grand Total")} />
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
            {getSymbolFromCurrency(currencyName)}{" "}
            {currencyFormatter(
              "USA",
              Math.round(
                Number(getTotalPrice()) +
                  Number(addressFormObj.shippingCost * exchangeRate) +
                  Number(
                    getPaymentMethod(paymentData.selectedPaymentMethod) &&
                      getPaymentMethod(paymentData.selectedPaymentMethod)
                        .transactionUnit === 0
                      ? getTotalPrice() *
                          Number(
                            getPaymentMethod(paymentData.selectedPaymentMethod)
                              .transactionFee / 100
                          )
                      : Number(
                          getPaymentMethod(paymentData.selectedPaymentMethod)
                            .transactionFee
                        ) * exchangeRate
                  )
              )
            )}
          </Typography>
        </ListItem>
      </List>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            {translateFront("Shipping")}
          </Typography>
          <Typography gutterBottom>
            {addressFormObj.firstName + " " + addressFormObj.lastName}
          </Typography>
          <Typography gutterBottom>
            {addressFormObj.address1 +
              (addressFormObj.address2 !== ""
                ? ", " + addressFormObj.address2
                : "")}
          </Typography>
          {addressFormObj.state !== "" && (
            <Typography gutterBottom>{addressFormObj.state}</Typography>
          )}
          <Typography gutterBottom>
            {addressFormObj.city + " - " + addressFormObj.postal}
          </Typography>
          <Typography gutterBottom>{addressFormObj.country}</Typography>
        </Grid>
        <Grid item container direction="column" xs={12} sm={6}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            {translateFront("Delivery Method")}
          </Typography>
          <Typography gutterBottom>
            {getDeliveryMethod(addressFormObj.selectedDeliveryMethod)}
          </Typography>
          <Typography gutterBottom variant="body2" sx={{ color: "gray" }}>
            {translateFront("Note")}: {addressFormObj.deliveryNote}
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            {translateFront("Payment Details")}
          </Typography>
          <Typography gutterBottom>
            {getPaymentMethod(paymentData.selectedPaymentMethod).methodName}
          </Typography>
          <Typography gutterBottom variant="body2" sx={{ color: "gray" }}>
            {translateFront("Note")}: {paymentData.paymentNote}
          </Typography>

          {/* <Grid container>
            {payments.map((payment) => (
              <React.Fragment key={payment.name}>
                <Grid item xs={6}>
                  <Typography gutterBottom>{payment.name}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>{payment.detail}</Typography>
                </Grid>
              </React.Fragment>
            ))}
          </Grid> */}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
